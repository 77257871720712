import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { CALL_API } from '../middleware/Middleware';
export const SEND_INVITATIONS_ATTEMPT = 'ys/invitations/send/ATTEMPT';
export const SEND_INVITATIONS_SUCCESS = 'ys/invitations/send/SUCCESS';
export const SEND_INVITATIONS_FAILURE = 'ys/invitations/send/FAILURE';
export const sendInvitations = (emails, campaignId) => dispatch => {
  const endpoint = '/api/invitation/invitations';
  const withCampaign = campaignId ? {
    campaign: campaignId
  } : {};
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [SEND_INVITATIONS_ATTEMPT, SEND_INVITATIONS_SUCCESS, SEND_INVITATIONS_FAILURE],
      options: {
        method: 'POST',
        data: {
          properties: _objectSpread({}, withCampaign),
          emails
        }
      },
      getResponse: res => res.data
    }
  });
};
export const SEND_INVITATIONS_RESET = 'ys/invitations/send/RESET';
export const resetSentInvitations = () => dispatch => dispatch({
  type: SEND_INVITATIONS_RESET
});