import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { InvestmentSignStatusCodesError, InvestmentSignStatusCodesSuccess } from './offerings.model';
const subscribeInvestmentUpdatesResponseBase = {
  AZ0WYA: {
    success: true,
    investment: {
      id: '848966a0-17da-11ed-bd88-bb727f022d17',
      waitlisted: false,
      rollover: false,
      status: 'SGND',
      amount: 50000,
      noteUrlHash: 'AZ0WYA',
      timestamp: 1660046299731,
      investorAccountId: '394423'
    },
    paymentMethod: 'SYNP',
    investmentFundingSource: {
      bank_account_id: '508532',
      amount: '0'
    },
    offering: {
      noteUrlHash: 'AZ0WYA',
      title: 'AccreditedSelf Internal Fund Cypress',
      spvBankAccountName: 'AccreditedSelf Internal Fund Cypress',
      spvBankAccountNumber: '654654894213'
    },
    message: 'Investment request 848966a0-17da-11ed-bd88-bb727f022d17 successfully signed.'
  },
  Vj2QcQ: {
    success: true,
    investment: {
      id: '84893f91-17da-11ed-bd88-bb727f022d17',
      waitlisted: false,
      rollover: false,
      status: 'SGND',
      amount: 50000,
      noteUrlHash: 'Vj2QcQ',
      timestamp: 1660046299735,
      investorAccountId: '394423'
    },
    paymentMethod: 'SYNP',
    investmentFundingSource: {
      bank_account_id: '508532',
      amount: '0'
    },
    offering: {
      noteUrlHash: 'Vj2QcQ',
      title: 'New BPDN Offering',
      spvBankAccountName: 'BPDN 1',
      spvBankAccountNumber: '6542317'
    },
    message: 'Investment request 84893f91-17da-11ed-bd88-bb727f022d17 successfully signed.'
  },
  nuuXDg: {
    success: true,
    investment: {
      id: '84893f93-17da-11ed-bd88-bb727f022d17',
      waitlisted: false,
      rollover: false,
      status: 'SGND',
      amount: 5000,
      noteUrlHash: 'nuuXDg',
      timestamp: 1660046299738,
      investorAccountId: '394423'
    },
    paymentMethod: 'SYNP',
    investmentFundingSource: {
      bank_account_id: '508532',
      amount: '0'
    },
    offering: {
      noteUrlHash: 'nuuXDg',
      title: 'AccreditedSelf Offering BPDN Cypress I',
      spvBankAccountName: 'BPDN 1',
      spvBankAccountNumber: '273898849372'
    },
    message: 'Investment request 84893f93-17da-11ed-bd88-bb727f022d17 successfully signed.'
  },
  X4jMzg: {
    success: true,
    investment: {
      id: '84893f92-17da-11ed-bd88-bb727f022d17',
      waitlisted: false,
      rollover: false,
      status: 'SGND',
      amount: 10000,
      noteUrlHash: 'X4jMzg',
      timestamp: 1660046299738,
      investorAccountId: '394423'
    },
    paymentMethod: 'SYNP',
    investmentFundingSource: {
      bank_account_id: '508532',
      amount: '0'
    },
    offering: {
      noteUrlHash: 'X4jMzg',
      title: 'New BPDN Fund Offering',
      spvBankAccountName: 'BPDN 1',
      spvBankAccountNumber: '564144'
    },
    message: 'Investment request 84893f92-17da-11ed-bd88-bb727f022d17 successfully signed.'
  }
};
const subscribeInvestmentUpdatesResponseError = {};
for (const noteUrlHash in subscribeInvestmentUpdatesResponseBase) {
  subscribeInvestmentUpdatesResponseError[noteUrlHash] = _objectSpread(_objectSpread({}, subscribeInvestmentUpdatesResponseBase[noteUrlHash]), {}, {
    code: InvestmentSignStatusCodesError.BankAccountNotFound
  });
}
const subscribeInvestmentUpdatesResponseSuccess = {};
for (const noteUrlHash in subscribeInvestmentUpdatesResponseBase) {
  subscribeInvestmentUpdatesResponseSuccess[noteUrlHash] = _objectSpread(_objectSpread({}, subscribeInvestmentUpdatesResponseBase[noteUrlHash]), {}, {
    code: InvestmentSignStatusCodesSuccess.Created
  });
}
const mockLiquidationPayload = {
  urlHash: 'test',
  disclaimer: 'test',
  tenderId: 'test',
  investorAccountId: 'test',
  investorInitials: 'test'
};
const mockGetInvestorPositionPayload = {
  noteUrlHash: 'L_o387',
  investorAccountId: 1577874
};
export { subscribeInvestmentUpdatesResponseError, subscribeInvestmentUpdatesResponseSuccess, mockLiquidationPayload, mockGetInvestorPositionPayload };