import { BadgeType } from '@yieldstreet/platform-kit';
import { genTestIds } from '@yieldstreet/tool-kit';
import { theme2 } from '../../themes';
export const {
  getTestIds,
  useTestIds
} = genTestIds(['badge', 'icon']);
export const BadgeConfiguration = {
  [BadgeType.PERSONAL]: {
    backgroundColor: theme2.colors.badge_personal,
    textColor: theme2.colors.badge_personal_text
  },
  [BadgeType.DEFAULT]: {
    textColor: 'default',
    backgroundColor: theme2.colors.badge_regular
  }
};