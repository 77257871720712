var __jsx = React.createElement;
import React, { useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { Tags } from './Tags';
import { ExtraTags } from './ExtraTags';
export const TagsList = _ref => {
  let {
    tags = [],
    hasLegacyOfferingTags
  } = _ref;
  const {
    0: extraTagsHidden,
    1: setExtraTagsHidden
  } = useState(true);
  const onShowMore = event => {
    event.stopPropagation();
    event.preventDefault();
    setExtraTagsHidden(!extraTagsHidden);
  };
  let visibleTags = [];
  let hiddenTags = [];
  const maxVisibleTags = 2;
  const maxVisibleCharacters = 26;
  let totalCharacters = hasLegacyOfferingTags ? tags.reduce((acc, _ref2) => {
    let {
      length
    } = _ref2;
    return acc + length;
  }, 0) : tags.reduce((acc, obj) => acc + obj.name.length, 0);
  if (maxVisibleCharacters >= totalCharacters) {
    visibleTags = [...tags];
  } else if (hasLegacyOfferingTags) {
    let amountOfVisibleCharacters = 0;
    const [visTags, hidTags] = tags.reduce((acc, tag) => {
      const [visible] = acc;
      if (visible.length < maxVisibleTags && amountOfVisibleCharacters + tag.length < maxVisibleCharacters) {
        amountOfVisibleCharacters += tag.length;
        acc[0].push(tag);
      } else {
        acc[1].push(tag);
      }
      return acc;
    }, [[], []]);
    visibleTags = [...visTags];
    hiddenTags = [...hidTags];
  } else {
    let amountOfVisibleCharacters = 0;
    const [visTags, hidTags] = tags.reduce((acc, tag) => {
      const [visible] = acc;
      if (visible.length < maxVisibleTags && amountOfVisibleCharacters + tag.name.length < maxVisibleCharacters) {
        amountOfVisibleCharacters += tag.name.length;
        acc[0].push(tag);
      } else {
        acc[1].push(tag);
      }
      return acc;
    }, [[], []]);
    visibleTags = [...visTags];
    hiddenTags = [...hidTags];
  }
  const hasMoreTags = !isEmpty(hiddenTags);
  return __jsx(React.Fragment, null, __jsx(Tags, {
    "data-cy": "past-offerings-card-summary-tags",
    tags: visibleTags,
    hasMoreTags: hasMoreTags,
    onShowMore: onShowMore,
    hasLegacyOfferingTags: hasLegacyOfferingTags
  }), __jsx(ExtraTags, {
    "data-cy": "past-offerings-card-extra-tags",
    tags: [...visibleTags, ...hiddenTags],
    isHidden: extraTagsHidden,
    onClose: onShowMore,
    hasLegacyOfferingTags: hasLegacyOfferingTags
  }));
};