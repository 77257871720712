import { useMemo } from 'react';
import { FundType, LegalEntityType, offeringApi, OfferingDocuments, TenderOfferType } from '@yieldstreet/platform-kit';
import { TenderOfferPagesCopy } from './tenderOfferPageCopy.model';
import { isRegAPlus } from '../../offering';
const {
  useDetailsQuery
} = offeringApi;
export const useTenderOfferPageCopy = _ref => {
  let {
    urlHash,
    screen
  } = _ref;
  const PageCopy = TenderOfferPagesCopy[screen];
  const {
    offering,
    isOfferingLoading
  } = useDetailsQuery([urlHash], {
    selectFromResult: _ref2 => {
      var _data$urlHash;
      let {
        data,
        isLoading,
        isUninitialized
      } = _ref2;
      return {
        offering: data === null || data === void 0 ? void 0 : (_data$urlHash = data[urlHash]) === null || _data$urlHash === void 0 ? void 0 : _data$urlHash.note,
        isOfferingLoading: isLoading || isUninitialized
      };
    }
  });
  const docs = useMemo(() => {
    let docs = [OfferingDocuments.OFTP, OfferingDocuments.TFAQ, OfferingDocuments.LOTL];
    if (isRegAPlus(offering === null || offering === void 0 ? void 0 : offering.fundType)) {
      docs = [OfferingDocuments.OFTP, OfferingDocuments.TFAQ];
    }
    if ((offering === null || offering === void 0 ? void 0 : offering.fundType) === FundType.ThirdPartyFund && (offering === null || offering === void 0 ? void 0 : offering.legalEntityType) === LegalEntityType.FUND) {
      docs = [OfferingDocuments.TFAQ, OfferingDocuments.PRPM, OfferingDocuments.OPER];
    }
    return docs;
  }, [offering]);
  const {
    type,
    copy
  } = useMemo(() => {
    if (isOfferingLoading || !offering) {
      return {
        type: 'default',
        copy: PageCopy.default
      };
    }
    if ((offering === null || offering === void 0 ? void 0 : offering.fundType) === FundType.ThirdPartyFund && (offering === null || offering === void 0 ? void 0 : offering.legalEntityType) === LegalEntityType.FUND) {
      return {
        type: TenderOfferType.ENHANCED_CRYPTO,
        copy: PageCopy[TenderOfferType.ENHANCED_CRYPTO]
      };
    }
    return {
      type: (offering === null || offering === void 0 ? void 0 : offering.fundType) || 'default',
      copy: PageCopy[(offering === null || offering === void 0 ? void 0 : offering.fundType) || 'default'] || PageCopy.default
    };
  }, [PageCopy, offering, isOfferingLoading]);
  return {
    type,
    offering,
    isOfferingLoading,
    copy,
    docs
  };
};