import { useCallback, useMemo } from 'react';
import { PortfolioStrategyValues, PortfolioFilters } from '@yieldstreet/platform-kit';
import { isBrowser } from 'utils/env';
import { ALL } from 'pages/portfolio/shared/PortfolioFilters';
import downloadIcon from 'pages/portfolio/common/assets/download.svg';
export const useTransactionsActions = _ref => {
  let {
    disabled,
    filters,
    investorId,
    userId,
    managementStrategy = PortfolioStrategyValues.Self
  } = _ref;
  const handleExport = useCallback(() => {
    if (!isBrowser) {
      return;
    }
    let filterStr = ``;
    if (managementStrategy) {
      filterStr += `&managementStrategy=${managementStrategy}`;
    }
    if (investorId && investorId !== PortfolioFilters.ALL) {
      filterStr += `&investorAccountId=${investorId}`;
    }
    if (filters !== null && filters !== void 0 && filters.noteUrlHash && filters.noteUrlHash !== ALL) {
      filterStr += `&noteUrlHash=${filters.noteUrlHash}`;
    }
    if (filters !== null && filters !== void 0 && filters.paymentType && filters.paymentType !== ALL) {
      filterStr += `&paymentType=${filters.paymentType}`;
    }
    if (filters !== null && filters !== void 0 && filters.year && filters.year !== ALL) {
      filterStr += `&year=${filters.year}`;
    }
    if (filters !== null && filters !== void 0 && filters.status && filters.status !== ALL) {
      filterStr += `&status=${filters.status}`;
    }
    window.open(`/a/api/portfolio/payments/${userId}/export?format=xlsx${filterStr}`, '_blank');
  }, [filters, investorId, userId, managementStrategy]);
  return useMemo(() => [{
    disabled,
    icon: downloadIcon,
    label: 'Export',
    onClick: handleExport
  }], [disabled, handleExport]);
};