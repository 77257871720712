var __jsx = React.createElement;
import React from 'react';
import { SurfaceType } from '@yieldstreet/ui-kit';
import { PageSurfaceWrapper } from './PageSurface.style';
export const PageSurface = _ref => {
  let {
    center,
    children,
    className,
    testId,
    type = SurfaceType.Outline,
    withPadding = false,
    noBorder = false
  } = _ref;
  return __jsx(PageSurfaceWrapper, {
    center: center,
    className: className,
    testId: testId,
    type: type,
    withPadding: withPadding,
    noBorder: noBorder
  }, children);
};