import { User2FAMethod } from '@yieldstreet/platform-kit';
import { genTestIds } from '@yieldstreet/tool-kit';
export const AuthenticatorName = {
  [User2FAMethod.AUTHY_APP]: 'Authy',
  [User2FAMethod.OTP_APP]: 'Authenticator',
  [User2FAMethod.TEXT_MESSAGE]: 'SMS'
};
const {
  getTestIds
} = genTestIds(['icon', 'title', 'txtDescription', 'authyDescription', 'newAuthyMethodDescription', 'anchorButton', 'phoneInput', 'submitButton', 'smsSwitchButton', 'intercomLink', 'loader', 'otpCode']);
export const testIds = getTestIds('edit-method-modal');