import _Box from "@mui/material/Box";
import _Typography from "@mui/material/Typography";
import _Link from "@mui/material/Link";
var __jsx = React.createElement;
import React from 'react';
export const ReCaptchaDisclaimer = () => __jsx(_Box, {
  component: "div",
  sx: {
    mt: 7,
    textAlign: 'center'
  }
}, __jsx(_Typography, {
  variant: "caption"
}, "This site is protected by reCAPTCHA and the", __jsx("br", null), "Google", ' ', __jsx(_Link, {
  href: "https://policies.google.com/privacy",
  target: "_blank"
}, "Privacy Policy"), ' ', "and", ' ', __jsx(_Link, {
  href: "https://policies.google.com/terms",
  target: "_blank"
}, "Terms of Service"), ' ', "apply."));