import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { setSegmentPage } from 'utils/thirdPartyCode';
const Segment = () => {
  const location = useLocation();
  useEffect(() => {
    setSegmentPage();
  }, [location]);
  return null;
};
export default Segment;