import { genTestIds } from '@yieldstreet/tool-kit';
import { PRE_INVESTMENT_STEPS } from 'pages/newCreateInvestorAccount/CreateInvestorAccount.model';
export const CITIZEN = 'CITIZEN';
export const NON_RESIDENT = 'NON_RESIDENT';
export const US_COUNTRY = 'US';
const TEST_ID_KEY = 'kyc-citizenship';
const {
  getTestIds
} = genTestIds(['citizenshipCountryField', 'deleteButton', 'addButton', 'submitButton', 'pageLoader']);
export const testIds = getTestIds(TEST_ID_KEY);
export const pageStepContent = {
  title: 'Complete your profile',
  description: 'To open a Yieldstreet 360 account, we’re required to ask the following questions to ensure this solution is suitable for you.',
  cta: {
    text: 'Continue',
    type: 'primary',
    callback: () => {}
  },
  step: {
    current: 2,
    items: PRE_INVESTMENT_STEPS
  }
};