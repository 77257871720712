import { combineReducers } from 'redux';
import * as actions from 'store/actions/ira';
import createAPIReducer from './common/createAPIReducer';
const penscoCustodians = createAPIReducer(actions, 'GET_PENSCO_CUSTODIANS', {
  initData: {
    custodians: []
  },
  successHandler: _ref => {
    let {
      response
    } = _ref;
    return {
      custodians: {
        $set: response
      }
    };
  }
});
const penscoFundTerms = createAPIReducer(actions, 'GET_FUNDING_TERMS', {
  initData: {
    purchaseTerms: {},
    fundingTerms: {}
  },
  successHandler: _ref2 => {
    let {
      response
    } = _ref2;
    return {
      purchaseTerms: {
        id: {
          $set: response.purchaseTermsId
        },
        link: {
          $set: response.purchaseTermsLink
        }
      },
      fundingTerms: {
        id: {
          $set: response.fundingTermsId
        },
        link: {
          $set: response.fundingTermsLink
        }
      }
    };
  }
});
const penscoAcctTerms = createAPIReducer(actions, 'GET_ACCT_TERMS', {
  initData: {
    accountTerms: {}
  },
  successHandler: _ref3 => {
    let {
      response
    } = _ref3;
    return {
      accountTerms: {
        id: {
          $set: response.id
        },
        link: {
          $set: response.link
        }
      }
    };
  }
});
export default combineReducers({
  penscoCustodians,
  penscoFundTerms,
  penscoAcctTerms
});