import _Stack from "@mui/material/Stack";
var __jsx = React.createElement;
import React from 'react';
import { ErrorView } from 'pages/portfolio/common/ErrorView';
import { TransactionsListCard } from 'pages/portfolio/Transactions/TransactionsList/TransactionsListData/TransactionsListMobile/TransactionsListCard';
export const ReturnPaymentsMobileBody = _ref => {
  let {
    returnedTransactions,
    isFetching,
    isError
  } = _ref;
  if (!isFetching && isError) {
    return __jsx(ErrorView, {
      asPage: true
    });
  }
  if (!isError && isFetching) {
    __jsx(_Stack, {
      gap: 4
    }, Array(4).fill('').map((_, idx) => __jsx(TransactionsListCard, {
      key: idx,
      isFetching: true
    })));
  }
  return __jsx(_Stack, {
    gap: 4
  }, returnedTransactions === null || returnedTransactions === void 0 ? void 0 : returnedTransactions.map((transaction, idx) => __jsx(TransactionsListCard, {
    transaction: transaction,
    key: idx,
    isFetching: isFetching,
    showFooter: false
  })));
};