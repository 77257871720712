import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
const userId = 220951;
export const userStateLoggedOut = {
  loggedIn: false
};
export const userState = {
  authToken: 'bd7eeb9c-514c-4391-9d86-7df4a630baed',
  timeToLive: 3600,
  id: userId,
  domainId: null,
  hashedId: 'Ak70ataOtwge9MnTdCWeIw',
  nickname: 'john.doe',
  firstName: 'John',
  lastName: 'Doe',
  displayName: 'John Doe',
  emailAddress: 'johndoe@yieldstreet.com',
  remoteAddress: '10.29.1.58',
  userAgent: '',
  deviceId: '',
  createdAt: 1602149783765,
  roles: ['investor.email.verified', 'INVESTOR'],
  remainingTimeToLive: 3600,
  fullyAuthenticated: true,
  accreditedSignUp: false,
  accreditationStatusSubmitted: true,
  source: 'login',
  features: ['STATEMENTS_V2'],
  loggedIn: true,
  success: true,
  _version: 3,
  isSoyUser: false,
  isAdmin: false,
  isInvestor: true,
  castleSignature: '8df8531f0508730c044ac57051046dc0543be5f2de8ae7c606602ab41ab17412'
};
export const userStateSignup = _objectSpread(_objectSpread({}, userState), {}, {
  roles: [],
  source: 'registration'
});
export const userStateUnverifiedEmail = _objectSpread(_objectSpread({}, userState), {}, {
  roles: []
});
export const userStateAdmin = _objectSpread(_objectSpread({}, userState), {}, {
  roles: [...userState.roles, 'YS_ADMIN'],
  isAdmin: true
});
export const userStateAccredited = _objectSpread(_objectSpread({}, userState), {}, {
  accreditedSignUp: true
});
export const userStateAccreditedSignup = _objectSpread(_objectSpread({}, userStateSignup), {}, {
  accreditedSignUp: true
});
export const userStateAccreditedAdmin = _objectSpread(_objectSpread({}, userState), {}, {
  roles: [...userState.roles, 'YS_ADMIN'],
  isAdmin: true
});
export const userStateAccreditationMissing = _objectSpread(_objectSpread({}, userState), {}, {
  accreditationStatusSubmitted: false
});