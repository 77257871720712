import { useMemo } from 'react';
import { useAllInvestorEntities } from '../use-investor-entity';
import { getInvestorAccount, getInvestorAccountsByStrategy } from './useInvestorAccount.util';
export const useInvestorAccount = (investorEntityId, managementStrategy) => {
  const {
    investorEntities: allInvestorEntities,
    isLoading: investorEntitiesLoading,
    refetch
  } = useAllInvestorEntities();
  const filteredEntity = useMemo(() => getInvestorAccount(investorEntityId, allInvestorEntities), [allInvestorEntities, investorEntityId]);
  const account = useMemo(() => {
    var _getInvestorAccountsB;
    if (!filteredEntity) {
      return;
    }
    return (_getInvestorAccountsB = getInvestorAccountsByStrategy(filteredEntity.investorAccounts, managementStrategy)) === null || _getInvestorAccountsB === void 0 ? void 0 : _getInvestorAccountsB[0];
  }, [filteredEntity, managementStrategy]);
  return {
    account,
    isLoading: investorEntitiesLoading,
    refetch
  };
};