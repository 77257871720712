import { useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import includes from "lodash/includes";
import { FundProvider, InvestmentEngine, overviewApi } from '@yieldstreet/platform-kit';
import { getDatesForRange, DateRanges } from '../../../utils';
import { isFortyActFund, isRegAPlus } from '../../../offering';
import { ALL, FundBalanceCalculationTypes, FundCapitalBalanceReductionTypes, YSFundProviders } from './useInvestmentSummary.model';
export const useInvestmentSummary = _ref => {
  let {
    investorId,
    noteUrlHash,
    userId
  } = _ref;
  const [startDate, endDate] = getDatesForRange({
    range: DateRanges.LifeTime
  });
  const portfolioOverviewQueryConfig = useMemo(() => {
    if (!endDate || !startDate || !investorId || !userId) {
      return skipToken;
    }
    return {
      end: endDate,
      includeRepaidCapital: true,
      includeRepaidPrincipal: true,
      investorAccountId: investorId === ALL ? undefined : investorId,
      noteUrlHash,
      start: startDate,
      userId
    };
  }, [endDate, startDate, investorId, noteUrlHash, userId]);
  const {
    data,
    isError,
    isFetching
  } = overviewApi.useGetPortfolioOverviewQuery(portfolioOverviewQueryConfig);
  const {
    accountSummary,
    capital,
    investmentDetail: investmentDetails,
    investmentStrategies
  } = data || {};
  const {
    isAtomicFund,
    isDebtDeal,
    isDefaultInvestment,
    isFund,
    isPrismFund,
    isReitFund,
    isSscFund,
    isTheoremFund,
    isYieldstreetCapitalIncomeFund,
    isYieldstreetFund
  } = useMemo(() => {
    var _accountSummary$funds, _accountSummary$funds2;
    const fundConfig = accountSummary === null || accountSummary === void 0 ? void 0 : (_accountSummary$funds = accountSummary.funds) === null || _accountSummary$funds === void 0 ? void 0 : (_accountSummary$funds2 = _accountSummary$funds[0]) === null || _accountSummary$funds2 === void 0 ? void 0 : _accountSummary$funds2.fundConfig;
    const isYieldstreetFund = includes(YSFundProviders, investmentDetails === null || investmentDetails === void 0 ? void 0 : investmentDetails.fundProvider);
    const isCapitalIncomeFund = includes(FundBalanceCalculationTypes, fundConfig === null || fundConfig === void 0 ? void 0 : fundConfig.balanceCalculationType) || includes(FundCapitalBalanceReductionTypes, fundConfig === null || fundConfig === void 0 ? void 0 : fundConfig.capitalBalanceReductionType);
    return {
      isAtomicFund: (investmentDetails === null || investmentDetails === void 0 ? void 0 : investmentDetails.fundProvider) === FundProvider.Atomic,
      isCapitalIncomeFund,
      isDebtDeal: (investmentDetails === null || investmentDetails === void 0 ? void 0 : investmentDetails.engine) === InvestmentEngine.Pesto || (investmentDetails === null || investmentDetails === void 0 ? void 0 : investmentDetails.engine) === InvestmentEngine.Pflex,
      isDefaultInvestment: !!(accountSummary !== null && accountSummary !== void 0 && accountSummary.balanceInDefault),
      isFund: (investmentDetails === null || investmentDetails === void 0 ? void 0 : investmentDetails.engine) === InvestmentEngine.Funds,
      isPrismFund: isFortyActFund(investmentDetails === null || investmentDetails === void 0 ? void 0 : investmentDetails.fundType),
      isReitFund: isRegAPlus(investmentDetails === null || investmentDetails === void 0 ? void 0 : investmentDetails.fundType),
      isSscFund: (investmentDetails === null || investmentDetails === void 0 ? void 0 : investmentDetails.fundProvider) === FundProvider.Ssc,
      isTheoremFund: (investmentDetails === null || investmentDetails === void 0 ? void 0 : investmentDetails.fundProvider) === FundProvider.Theorem,
      isYieldstreetCapitalIncomeFund: isYieldstreetFund && isCapitalIncomeFund || (investmentDetails === null || investmentDetails === void 0 ? void 0 : investmentDetails.fundProvider) === FundProvider.Geneva,
      isYieldstreetFund
    };
  }, [accountSummary === null || accountSummary === void 0 ? void 0 : accountSummary.balanceInDefault, accountSummary === null || accountSummary === void 0 ? void 0 : accountSummary.funds, investmentDetails === null || investmentDetails === void 0 ? void 0 : investmentDetails.engine, investmentDetails === null || investmentDetails === void 0 ? void 0 : investmentDetails.fundProvider, investmentDetails === null || investmentDetails === void 0 ? void 0 : investmentDetails.fundType]);
  const isLoaded = useMemo(() => !isFetching && !isError, [isFetching, isError]);
  return {
    accountSummary,
    capital,
    investmentDetails,
    investmentStrategies,
    isAtomicFund,
    isDebtDeal,
    isDefaultInvestment,
    isError,
    isFetching,
    isFund,
    isLoaded,
    isPrismFund,
    isReitFund,
    isSscFund,
    isTheoremFund,
    isYieldstreetCapitalIncomeFund,
    isYieldstreetFund
  };
};