import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { bankAccountApi, BankAccountExternalPermission, BankAccountProvider, BankAccountProvisionState, BankAccountType } from '@yieldstreet/platform-kit';
import { testIds } from './useBankAccounts.model';
import { useRouteInvestorEntityId } from 'pages/investorEntity/utils';
import { BankAccountItemSlot } from './useBankAccounts.util';
export const useBankAccounts = selectedInvestorEntityId => {
  const paramsInvestorEntityId = useRouteInvestorEntityId();
  const investorEntityId = selectedInvestorEntityId || paramsInvestorEntityId;
  const {
    data,
    isFetching,
    refetch
  } = bankAccountApi.useGetEntityBankAccountsQuery(investorEntityId ? {
    investorEntityId
  } : skipToken);
  const filteredBankAccounts = useMemo(() => {
    var _data$bankAccounts;
    return data === null || data === void 0 ? void 0 : (_data$bankAccounts = data.bankAccounts) === null || _data$bankAccounts === void 0 ? void 0 : _data$bankAccounts.filter(bankAccount => bankAccount.accountType !== BankAccountType.YWLT);
  }, [data]);
  const degradedBankAccounts = useMemo(() => filteredBankAccounts === null || filteredBankAccounts === void 0 ? void 0 : filteredBankAccounts.filter(bankAccount => bankAccount.provisionState === BankAccountProvisionState.DEGRADED), [filteredBankAccounts]);
  const inactiveOrLockedBankAccounts = useMemo(() => filteredBankAccounts === null || filteredBankAccounts === void 0 ? void 0 : filteredBankAccounts.filter(bankAccount => [BankAccountExternalPermission.LOCKED, BankAccountExternalPermission.INACTIVE].includes(bankAccount.externalPermission)), [filteredBankAccounts]);
  const microDepositBankAccounts = useMemo(() => filteredBankAccounts === null || filteredBankAccounts === void 0 ? void 0 : filteredBankAccounts.filter(bankAccount => bankAccount.provisionState === BankAccountProvisionState.PENDING), [filteredBankAccounts]);

  /*
    Eliminate all micro deposit accounts which are not confirmed
   */
  const confirmedBankAccounts = useMemo(() => filteredBankAccounts && filteredBankAccounts.filter(_ref => {
    let {
      provisionState
    } = _ref;
    return provisionState === BankAccountProvisionState.CONFIRMED;
  }), [filteredBankAccounts]);
  const canChangeDefaultBank = useMemo(() => confirmedBankAccounts && (confirmedBankAccounts.length > 1 || confirmedBankAccounts.find(_ref2 => {
    let {
      defaultAccount
    } = _ref2;
    return !defaultAccount;
  })), [confirmedBankAccounts]);
  const bankAccounts = useMemo(() => {
    return (filteredBankAccounts || []).filter(acc => acc.provider !== BankAccountProvider.SYNAPSE).map(acc => _objectSpread(_objectSpread({}, acc), {}, {
      label: (acc === null || acc === void 0 ? void 0 : acc.provisionState) === BankAccountProvisionState.PENDING ? acc === null || acc === void 0 ? void 0 : acc.nickname : acc === null || acc === void 0 ? void 0 : acc.bankAccountNameWithLast4Digits,
      description: BankAccountItemSlot(acc),
      value: `${acc === null || acc === void 0 ? void 0 : acc.id}`,
      dataCy: `${testIds.options.id}-${acc === null || acc === void 0 ? void 0 : acc.id}`,
      disabled: (acc === null || acc === void 0 ? void 0 : acc.provisionState) === BankAccountProvisionState.PENDING,
      displayName: `${acc === null || acc === void 0 ? void 0 : acc.bankName} - ${acc === null || acc === void 0 ? void 0 : acc.accountTypeDisplayName}`
    }));
  }, [filteredBankAccounts]);

  /**
   * Active default bank account but non degraded. To get
   * the default bank account only use `defaultAccount`.
   */
  const currentDefaultBank = useMemo(() => bankAccounts.find(bankAccount => bankAccount.defaultAccount && bankAccount.provisionState !== BankAccountProvisionState.DEGRADED), [bankAccounts]);
  const nonDegradedBankAccounts = useMemo(() => bankAccounts === null || bankAccounts === void 0 ? void 0 : bankAccounts.filter(bankAccount => bankAccount.provisionState !== BankAccountProvisionState.DEGRADED), [bankAccounts]);
  const isDefaultBankAccountDegraded = useMemo(() => degradedBankAccounts === null || degradedBankAccounts === void 0 ? void 0 : degradedBankAccounts.some(bankAccount => bankAccount.defaultAccount), [degradedBankAccounts]);
  return {
    bankAccounts,
    canAddEntityBankAccounts: data === null || data === void 0 ? void 0 : data.canAdd,
    canChangeDefaultBank,
    confirmedBankAccounts,
    currentDefaultBank,
    degradedBankAccounts,
    inactiveOrLockedBankAccounts,
    isDefaultBankAccountDegraded,
    isEntityBankAccountsLoading: isFetching,
    microDepositBankAccounts,
    nonDegradedBankAccounts,
    refetchEntityBankAccounts: refetch
  };
};