import { offeringApi } from '@yieldstreet/platform-kit';
const {
  useDetailsQuery
} = offeringApi;
export const useIsUpfrontCapitalCallOffering = _ref => {
  let {
    urlHash
  } = _ref;
  const {
    offering
  } = useDetailsQuery([urlHash], {
    selectFromResult: _ref2 => {
      var _data$urlHash;
      let {
        data,
        isLoading,
        isUninitialized
      } = _ref2;
      return {
        data,
        offering: data === null || data === void 0 ? void 0 : (_data$urlHash = data[urlHash]) === null || _data$urlHash === void 0 ? void 0 : _data$urlHash.note,
        isLoadingOffering: isLoading || isUninitialized
      };
    }
  });
  return {
    isUpfrontCapitalCall: ((offering === null || offering === void 0 ? void 0 : offering.capitalDrawnPercentage) || 0) > 0
  };
};