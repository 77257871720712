import _Link from "@mui/material/Link";
var __jsx = React.createElement;
import React from 'react';
import { openConsentManager } from '@segment/consent-manager';
export const SegmentConsentLink = _ref => {
  let {} = _ref;
  return __jsx("span", null, __jsx(_Link, {
    variant: "caption2",
    onClick: () => openConsentManager()
  }, "Website Data Collection Preferences"));
};