import { HttpMethods } from '../../../enums';
import { restApi } from '../../common';
export const userPasswordApi = restApi.injectEndpoints({
  endpoints: builder => {
    const setPassword = builder.mutation({
      query: data => {
        return {
          url: '/api/auth/set-password',
          method: HttpMethods.Post,
          data
        };
      }
    });
    const changePassword = builder.mutation({
      query: data => {
        return {
          url: '/a/api/user/password',
          method: HttpMethods.Put,
          data
        };
      }
    });
    const resetPassword = builder.mutation({
      query: _ref => {
        let {
          email
        } = _ref;
        return {
          url: '/a/api/user/resetPassword',
          method: HttpMethods.Post,
          data: {
            email
          }
        };
      },
      transformResponse: response => {
        return response;
      }
    });
    return {
      setPassword,
      changePassword,
      resetPassword
    };
  }
});
export const resetPasswordApi = userPasswordApi;