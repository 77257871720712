import _Link from "@mui/material/Link";
var __jsx = React.createElement;
import React, { useEffect, useState } from 'react';
import { TRUSTARC_CMP_SCRIPT } from './TrustArcConsent.model';
import useScript from '@charlietango/use-script';
import { getConsentCountry, launchConsentPreferences } from './TrustArcConsent.utils';
export const TrustArcConsentLink = _ref => {
  let {} = _ref;
  const {
    0: consentAvailable,
    1: setConsentAvailable
  } = useState(false);
  const [consentReady] = useScript(TRUSTARC_CMP_SCRIPT);
  useEffect(() => {
    if (consentReady) {
      const consentCountry = getConsentCountry();
      if (consentCountry && consentCountry !== 'others') {
        setConsentAvailable(true);
      }
    }
  }, [consentReady]);
  return consentAvailable ? __jsx("span", null, __jsx(_Link, {
    variant: "caption2",
    onClick: launchConsentPreferences
  }, "Website Data Collection Preferences")) : null;
};