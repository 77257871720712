import _defineProperty from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
var __jsx = React.createElement;
import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import { Paragraph, Heading, Button, SectionContainer, media } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
import ysLogo from '@yieldstreet/ui-kit/assets/logos/white_ys_logo_mobile.svg';
import { setSegmentTrack } from 'utils/thirdPartyCode';
const ChunkLoadError = 'ChunkLoadError';
class SentryBoundaryError extends Component {
  constructor(props) {
    super(props);
    _defineProperty(this, "redirectBack", () => {
      const {
        location
      } = this.props;
      setSegmentTrack({
        event: 'oopsError.tryAgainButton.clicked'
      });
      window.location.replace(location.pathname);
    });
    _defineProperty(this, "contactSupport", () => {
      var _window, _window$adaEmbed;
      setSegmentTrack({
        event: 'oopsError.supportButton.clicked'
      });
      return (_window = window) === null || _window === void 0 ? void 0 : (_window$adaEmbed = _window.adaEmbed) === null || _window$adaEmbed === void 0 ? void 0 : _window$adaEmbed.toggle();
    });
    this.state = {
      eventId: null,
      hasError: false,
      error: null,
      errorInfo: null
    };
  }
  static getDerivedStateFromError() {
    return {
      hasError: true
    };
  }
  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    if (error.name === ChunkLoadError || error.message.includes(ChunkLoadError)) {
      window.location.reload(true);
      return;
    }
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      scope.setTag('Oops error', 'true');
      const eventId = Sentry.captureException(error);
      this.setState({
        eventId
      });
    });
    setSegmentTrack({
      event: 'oopsError.viewed',
      properties: {
        errorInfo: errorInfo,
        sentryEventId: Sentry.captureException(error)
      }
    });
  }
  render() {
    const {
      hasError,
      error
    } = this.state;
    if (hasError) {
      if ((error === null || error === void 0 ? void 0 : error.name) === ChunkLoadError) {
        return null;
      }
      return __jsx(Wrapper, null, __jsx(SSectionContainer, null, __jsx(YSLogo, {
        src: ysLogo
      }), __jsx(HeadingWrapper, null, __jsx(Heading, {
        type: 3,
        inverse: true
      }, "Oops, looks like something has gone wrong.")), __jsx(ParagraphWrapper, null, __jsx(Paragraph, {
        inverse: true
      }, __jsx(React.Fragment, null, "Our engineering team has already been notified."))), __jsx(ButtonWrapper, null, __jsx(SButton, {
        buttonType: "secondary",
        inverse: true,
        onClick: () => this.redirectBack()
      }, "Try again"), __jsx(SButton, {
        onClick: () => this.contactSupport()
      }, "Contact support"))));
    }
    return this.props.children;
  }
}
const Wrapper = styled.div.withConfig({
  displayName: "SentryBoundaryError__Wrapper",
  componentId: "sc-9zjyte-0"
})(["background-color:", ";position:absolute;height:100%;width:100%;"], props => props.theme.colors.background_inverse);
const SSectionContainer = styled(SectionContainer).withConfig({
  displayName: "SentryBoundaryError__SSectionContainer",
  componentId: "sc-9zjyte-1"
})(["padding-top:100px;", ";"], media.tablet`
    padding-top: 140px;
  `);
const HeadingWrapper = styled.div.withConfig({
  displayName: "SentryBoundaryError__HeadingWrapper",
  componentId: "sc-9zjyte-2"
})(["margin-bottom:20px;max-width:740px;"]);
const ParagraphWrapper = styled.div.withConfig({
  displayName: "SentryBoundaryError__ParagraphWrapper",
  componentId: "sc-9zjyte-3"
})(["margin-bottom:64px;max-width:620px;"]);
const SButton = styled(Button).withConfig({
  displayName: "SentryBoundaryError__SButton",
  componentId: "sc-9zjyte-4"
})(["margin:10px 0;", ";"], media.phoneLandscape`
    margin: 0 10px;
  `);
const ButtonWrapper = styled.div.withConfig({
  displayName: "SentryBoundaryError__ButtonWrapper",
  componentId: "sc-9zjyte-5"
})(["display:flex;flex-direction:column-reverse;margin:-10px 0;", ";"], media.phoneLandscape`
    flex-direction: row;
    margin: 0 -10px;
  `);
const YSLogo = styled.img.withConfig({
  displayName: "SentryBoundaryError__YSLogo",
  componentId: "sc-9zjyte-6"
})(["display:block;height:45px;position:relative;left:-6px;margin-bottom:64px;"]);
export default SentryBoundaryError;