import { useEffect, useState } from 'react';
import { useThrottledDebounce } from '../../../hooks';
export const useActivityNotification = _ref => {
  let {
    debounceDelay,
    notificationSnackbarFn,
    throttleDelay,
    unseenCount
  } = _ref;
  const {
    0: unseenMessageCount,
    1: setUnseenMessageCount
  } = useState(0);
  const setCount = count => setUnseenMessageCount(count);
  const notificationDebounceHandler = useThrottledDebounce({
    debounceFn: notificationSnackbarFn,
    debounceDelay,
    throttleDelay
  });
  const unseenMessageCountHandler = useThrottledDebounce({
    debounceFn: setUnseenMessageCount,
    debounceDelay,
    throttleDelay
  });
  useEffect(() => {
    if (unseenCount > unseenMessageCount) {
      notificationDebounceHandler({
        counter: unseenCount
      });
      unseenMessageCountHandler(unseenCount);
    }
    if (unseenCount === unseenMessageCount) {
      notificationDebounceHandler.cancel();
      unseenMessageCountHandler.cancel();
    }
  }, [unseenCount, unseenMessageCount, notificationDebounceHandler, unseenMessageCountHandler]);
  return {
    setCount,
    unseenMessageCount
  };
};