import styled from 'styled-components';
import { media } from '../../themes';
import { Container } from '../../layout';
export const Wrapper = styled(Container).withConfig({
  displayName: "MarketingSideCarouselContentstyles__Wrapper",
  componentId: "sc-1pd0kem-0"
})(["display:flex;overflow:hidden;justify-content:space-evenly;flex-direction:column;", ""], media.desktop`
    flex-direction: ${props => props.reverse ? 'row-reverse' : 'row'};
  `);
export const CarouselContainer = styled.div.withConfig({
  displayName: "MarketingSideCarouselContentstyles__CarouselContainer",
  componentId: "sc-1pd0kem-1"
})(["width:50%;"]);
export const TextWrapper = styled.div.withConfig({
  displayName: "MarketingSideCarouselContentstyles__TextWrapper",
  componentId: "sc-1pd0kem-2"
})(["margin-top:24px;max-width:440px;z-index:5;", ""], media.desktop`
    margin-top: 0;
  `);