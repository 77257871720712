import { useMemo } from 'react';
import { useBankAccounts } from 'pages/investorEntity/screens/Management/routes/BankAccounts/hooks/useBankAccounts';
import { getModalContent } from './useDefaultBank.util';
import { useDefaultBankScreenType } from '../useDefaultBankScreenType';
import { DefaultBankScreenType } from 'sharedComponents/DefaultBank/DefaultBank.model';
export const useDefaultBank = (investorEntityId, showSuccessModal) => {
  const {
    nonDegradedBankAccounts,
    currentDefaultBank,
    isEntityBankAccountsLoading
  } = useBankAccounts(investorEntityId);
  const defaultBankType = useDefaultBankScreenType(investorEntityId);
  const contentConfig = useMemo(() => {
    if (!defaultBankType) {
      return;
    }
    if (showSuccessModal) {
      return getModalContent({
        bankName: (currentDefaultBank === null || currentDefaultBank === void 0 ? void 0 : currentDefaultBank.displayName) || '',
        type: DefaultBankScreenType.DefaultBank
      });
    }
    return getModalContent({
      bankName: (currentDefaultBank === null || currentDefaultBank === void 0 ? void 0 : currentDefaultBank.displayName) || '',
      type: defaultBankType
    });
  }, [currentDefaultBank === null || currentDefaultBank === void 0 ? void 0 : currentDefaultBank.displayName, defaultBankType, showSuccessModal]);
  const {
    initialValues,
    isInitialValid
  } = useMemo(() => {
    var _nonDegradedBankAccou;
    if ((nonDegradedBankAccounts === null || nonDegradedBankAccounts === void 0 ? void 0 : nonDegradedBankAccounts.length) === 1 && !((_nonDegradedBankAccou = nonDegradedBankAccounts[0]) !== null && _nonDegradedBankAccou !== void 0 && _nonDegradedBankAccou.disabled)) {
      var _nonDegradedBankAccou2;
      return {
        initialValues: {
          defaultBank: (currentDefaultBank === null || currentDefaultBank === void 0 ? void 0 : currentDefaultBank.value) || ((_nonDegradedBankAccou2 = nonDegradedBankAccounts[0]) === null || _nonDegradedBankAccou2 === void 0 ? void 0 : _nonDegradedBankAccou2.value)
        },
        isInitialValid: true
      };
    }
    return {
      initialValues: {
        defaultBank: currentDefaultBank === null || currentDefaultBank === void 0 ? void 0 : currentDefaultBank.value
      },
      isInitialValid: true
    };
  }, [nonDegradedBankAccounts, currentDefaultBank === null || currentDefaultBank === void 0 ? void 0 : currentDefaultBank.value]);
  const formOptions = {
    initialValues,
    isInitialValid,
    options: nonDegradedBankAccounts
  };
  return {
    formOptions,
    content: contentConfig,
    isDefaultLoading: isEntityBankAccountsLoading
  };
};