import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { usePollCheck } from '../poll';
import { ApiQueryPollCheckConfig } from './useApiQueryPollCheck.model';
export const useApiQueryPollCheck = (apiResult, checkCallback, skipAutoCheck, pollConfigOverride) => {
  const {
    data: apiData,
    isLoading: isApiLoading,
    isFetching: isApiFetching,
    refetch
  } = apiResult;
  const pollCheckState = usePollCheck(() => refetch().unwrap(), checkCallback, isApiLoading || skipAutoCheck, apiData, _objectSpread(_objectSpread({}, ApiQueryPollCheckConfig), pollConfigOverride));
  const {
    isChecking,
    isCheckDone
  } = pollCheckState;
  const isFetching = isApiFetching || isChecking;
  const isLoading = (isApiLoading || isChecking) && !isCheckDone;
  const data = !isLoading ? apiData : undefined;
  return _objectSpread({
    data,
    isLoading,
    isFetching
  }, pollCheckState);
};