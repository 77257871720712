import { getGateData } from '../getGateData';
export const getInvestmentNavigationParams = _ref => {
  var _gate$irServiceInvest;
  let {
    record,
    recordGates,
    origin
  } = _ref;
  const {
    investorAccountId,
    noteTitle,
    noteUrlHash
  } = record;
  const gate = getGateData({
    record,
    recordGates
  });
  return {
    investorAccountId,
    irServiceInvestmentId: (_gate$irServiceInvest = gate === null || gate === void 0 ? void 0 : gate.irServiceInvestmentId) !== null && _gate$irServiceInvest !== void 0 ? _gate$irServiceInvest : '',
    noteTitle,
    noteUrlHash,
    origin
  };
};