// Main Path
export const PORTFOLIO_PATH = '/portfolio';

// Screen Params
export const PORTFOLIO_STRATEGY_PARAM = ':strategy';
export const PORTFOLIO_INVESTOR_ID_PARAM = ':investorId';
export const PORTFOLIO_SCREEN_PARAM = ':screen';
export const PORTFOLIO_NOTE_URL_HASH_PARAM = ':urlHash';
export const PORTFOLIO_ACCOUNT_ID_PARAM = ':accountId';
export const PORTFOLIO_TRANSFER_TYPE_PARAM = ':type';
export const PORTFOLIO_STATEMENT_PROVIDER_PARAM = ':provider';
export const PORTFOLIO_BASE_PATH = `${PORTFOLIO_PATH}/${PORTFOLIO_STRATEGY_PARAM}`;

// Portfolio Summary Paths
export const PORTFOLIO_SUMMARY_PATH = `summary`;

// Portfolio Strategy Paths
export const PORTFOLIO_SD_PATH = 'self-directed';
export const PORTFOLIO_MD_PATH = 'managed';
export const PORTFOLIO_SD_BASE_PATH = `${PORTFOLIO_PATH}/${PORTFOLIO_SD_PATH}`;
export const PORTFOLIO_MD_BASE_PATH = `${PORTFOLIO_PATH}/${PORTFOLIO_MD_PATH}`;

// Portfolio Root Paths
export const PORTFOLIO_ROOT_PATH = `${PORTFOLIO_BASE_PATH}/${PORTFOLIO_SCREEN_PARAM}?/${PORTFOLIO_INVESTOR_ID_PARAM}?`;
export const PORTFOLIO_ROOT_PATH_SCREEN = `${PORTFOLIO_BASE_PATH}/${PORTFOLIO_SCREEN_PARAM}?`;
export const PORTFOLIO_ROOT_PATH_LEGACY = `${PORTFOLIO_PATH}/${PORTFOLIO_SCREEN_PARAM}?/${PORTFOLIO_INVESTOR_ID_PARAM}?`;
export const PORTFOLIO_GRAPH_PATH = `${PORTFOLIO_BASE_PATH}/graph/${PORTFOLIO_SCREEN_PARAM}?/${PORTFOLIO_INVESTOR_ID_PARAM}?`;

// Portfolio Screen Paths
export const PORTFOLIO_SUMMARY_SCREEN_PATH = `${PORTFOLIO_PATH}/${PORTFOLIO_SUMMARY_PATH}`;
export const PORTFOLIO_OVERVIEW_SCREEN_PATH = `${PORTFOLIO_BASE_PATH}/overview/${PORTFOLIO_INVESTOR_ID_PARAM}?`;
export const PORTFOLIO_TRANSACTIONS_SCREEN_PATH = `${PORTFOLIO_BASE_PATH}/transactions/${PORTFOLIO_INVESTOR_ID_PARAM}?`;
export const PORTFOLIO_INVESTMENTS_SCREEN_PATH = `${PORTFOLIO_BASE_PATH}/investments/${PORTFOLIO_INVESTOR_ID_PARAM}?`;
export const PORTFOLIO_STATEMENTS_SCREEN_PATH = `${PORTFOLIO_BASE_PATH}/statements/${PORTFOLIO_INVESTOR_ID_PARAM}?`;
export const PORTFOLIO_STATEMENTS_DETAILS_SCREEN_PATH = `${PORTFOLIO_BASE_PATH}/statements/:year/:type/:range/${PORTFOLIO_STATEMENT_PROVIDER_PARAM}?/${PORTFOLIO_INVESTOR_ID_PARAM}?`;
export const PORTFOLIO_TAX_CENTER_SCREEN_PATH = `${PORTFOLIO_BASE_PATH}/tax-center/${PORTFOLIO_INVESTOR_ID_PARAM}?`;
export const PORTFOLIO_INVESTMENTS_DETAILS_PENDING_DEBT_SCREEN_PATH = `${PORTFOLIO_BASE_PATH}/investment-details-pending/${PORTFOLIO_NOTE_URL_HASH_PARAM}/${PORTFOLIO_ACCOUNT_ID_PARAM}`;
export const PORTFOLIO_INVESTMENTS_DETAILS_DEBT_SCREEN_PATH = `${PORTFOLIO_BASE_PATH}/investment-details/${PORTFOLIO_NOTE_URL_HASH_PARAM}/${PORTFOLIO_ACCOUNT_ID_PARAM}`;
export const PORTFOLIO_INVESTMENTS_DETAILS_PAST_DEBT_SCREEN_PATH = `${PORTFOLIO_BASE_PATH}/investment-details-past/${PORTFOLIO_NOTE_URL_HASH_PARAM}/${PORTFOLIO_ACCOUNT_ID_PARAM}`;
export const PORTFOLIO_INVESTMENTS_DETAILS_PENDING_FUND_SCREEN_PATH = `${PORTFOLIO_BASE_PATH}/investment-details-pending-fund/${PORTFOLIO_NOTE_URL_HASH_PARAM}/${PORTFOLIO_ACCOUNT_ID_PARAM}`;
export const PORTFOLIO_INVESTMENTS_DETAILS_FUND_SCREEN_PATH = `${PORTFOLIO_BASE_PATH}/investment-details-fund/${PORTFOLIO_NOTE_URL_HASH_PARAM}/${PORTFOLIO_ACCOUNT_ID_PARAM}`;
export const PORTFOLIO_INVESTMENTS_DETAILS_PAST_FUND_SCREEN_PATH = `${PORTFOLIO_BASE_PATH}/investment-details-past-fund/${PORTFOLIO_NOTE_URL_HASH_PARAM}/${PORTFOLIO_ACCOUNT_ID_PARAM}`;

// Portfolio Strategy Specific Screen Paths
export const PORTFOLIO_SD_OVERVIEW_SCREEN_PATH = `${PORTFOLIO_PATH}/${PORTFOLIO_SD_PATH}/overview/${PORTFOLIO_INVESTOR_ID_PARAM}?`;
export const PORTFOLIO_MD_OVERVIEW_SCREEN_PATH = `${PORTFOLIO_PATH}/${PORTFOLIO_MD_PATH}/overview/${PORTFOLIO_INVESTOR_ID_PARAM}?`;

// Portfolio Self Directed Paths
export const PORTFOLIO_SD_OVERVIEW_PATH = `${PORTFOLIO_PATH}/${PORTFOLIO_SD_PATH}/overview`;
export const PORTFOLIO_SD_TRANSACTIONS_PATH = `${PORTFOLIO_PATH}/${PORTFOLIO_SD_PATH}/transactions`;
export const PORTFOLIO_SD_INVESTMENTS_PATH = `${PORTFOLIO_PATH}/${PORTFOLIO_SD_PATH}/investments`;
export const PORTFOLIO_SD_STATEMENTS_PATH = `${PORTFOLIO_PATH}/${PORTFOLIO_SD_PATH}/statements`;
export const PORTFOLIO_SD_TAX_CENTER_PATH = `${PORTFOLIO_PATH}/${PORTFOLIO_SD_PATH}/tax-center`;

// Portfolio Self Managed Portfolio Paths
export const PORTFOLIO_MD_OVERVIEW_PATH = `${PORTFOLIO_PATH}/${PORTFOLIO_MD_PATH}/overview`;
export const PORTFOLIO_MD_TRANSACTIONS_PATH = `${PORTFOLIO_PATH}/${PORTFOLIO_MD_PATH}/transactions`;
export const PORTFOLIO_MD_INVESTMENTS_PATH = `${PORTFOLIO_PATH}/${PORTFOLIO_MD_PATH}/investments`;
export const PORTFOLIO_MD_STATEMENTS_PATH = `${PORTFOLIO_PATH}/${PORTFOLIO_MD_PATH}/statements`;
export const PORTFOLIO_MD_TAX_CENTER_PATH = `${PORTFOLIO_PATH}/${PORTFOLIO_MD_PATH}/tax-center`;
export const PORTFOLIO_MD_DOCUMENTS_PATH = `${PORTFOLIO_PATH}/${PORTFOLIO_MD_PATH}/documents/${PORTFOLIO_INVESTOR_ID_PARAM}?`;
export const PORTFOLIO_MD_CHART_TARGET_ALLOCATION_PATH = `${PORTFOLIO_PATH}/${PORTFOLIO_MD_PATH}/graph/target-allocation/${PORTFOLIO_INVESTOR_ID_PARAM}?`;
export const chartColorsByData = {
  accrued: '6',
  cashDistribution: '6',
  fundExpensesPaid: '5',
  income: '7',
  interestPaid: '7',
  liquidation: '8',
  modified: '2',
  nav: '7',
  outstanding: '7',
  reinvestedDistributions: '2',
  repaid: '6',
  writeoff: '3'
};
export const errorMockResponse = 'error mock response';