import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import isEmpty from "lodash/isEmpty";
import { allToken, AudiencePresetTypes, marketplaceApi, overviewApi, PortfolioStrategyValues, userApi } from '@yieldstreet/platform-kit';
import { useModifiedBalance } from '../overview';
import { DateRanges, getDatesForRange, getInvestorAccountId } from '../../utils';
import { useInvestmentsBalanceBreakdown } from '../overview/useInvestmentsBalanceBreakdown';
import { balanceByPerformanceStatusEmpty } from './useAccountSummary.model';
import { extractAccountSummaryAttributes, extractWalletInterestRate } from './useAccountSummary.utils';
export const useAccountSummary = _ref => {
  var _portfolioOverviewDat;
  let {
    investorId,
    managementStrategy = PortfolioStrategyValues.Self,
    userId,
    config
  } = _ref;
  const {
    data: audiencesData,
    isLoading: audiencesIsLoading,
    isFetching: audiencesIsFetching,
    isError: audiencesIsError,
    refetch: audiencesRefetch
  } = marketplaceApi.useGetResolveAudiencesQuery(AudiencePresetTypes.PORTFOLIO);
  const audiencesTier = useMemo(() => {
    var _audiencesData$audien, _audiencesData$audien2;
    return audiencesData && (_audiencesData$audien = audiencesData.audiences) !== null && _audiencesData$audien !== void 0 && _audiencesData$audien.TIER && (_audiencesData$audien2 = audiencesData.audiences) !== null && _audiencesData$audien2 !== void 0 && _audiencesData$audien2.TIER.active ? audiencesData.audiences.TIER.meta : null;
  }, [audiencesData]);
  const {
    data: userStateData,
    isLoading: userStateIsLoading,
    isFetching: userStateIsFetching,
    isError: userStateIsError,
    refetch: userStateRefetch
  } = userApi.useGetUserStateQuery(allToken);
  const [lifeTimeStart, lifeTimeEnd] = getDatesForRange({
    range: DateRanges.LifeTime
  });
  const portfolioOverviewQueryConfig = useMemo(() => {
    if (!userId) {
      return skipToken;
    }
    return {
      end: lifeTimeEnd,
      includeRepaidCapital: true,
      includeRepaidPrincipal: true,
      investorAccountId: investorId ? getInvestorAccountId(investorId, managementStrategy) : undefined,
      managementStrategy,
      start: lifeTimeStart,
      userId
    };
  }, [investorId, lifeTimeEnd, lifeTimeStart, managementStrategy, userId]);
  const {
    data: portfolioOverviewData,
    isError: portfolioOverviewIsError,
    isLoading: portfolioOverviewIsLoading,
    isFetching: portfolioOverviewIsFetching,
    refetch: portfolioOverviewRefetch
  } = overviewApi.useGetPortfolioOverviewQuery(portfolioOverviewQueryConfig, config);
  const balanceByPerformanceStatus = portfolioOverviewData === null || portfolioOverviewData === void 0 ? void 0 : (_portfolioOverviewDat = portfolioOverviewData.accountSummary) === null || _portfolioOverviewDat === void 0 ? void 0 : _portfolioOverviewDat.balanceByPerformanceStatus;
  const {
    balancePerformance
  } = useModifiedBalance(balanceByPerformanceStatus || balanceByPerformanceStatusEmpty);
  const investmentsBalanceBreakdown = useInvestmentsBalanceBreakdown(portfolioOverviewData === null || portfolioOverviewData === void 0 ? void 0 : portfolioOverviewData.accountSummary);
  const accountSummaryRefetch = () => {
    audiencesRefetch();
    userStateRefetch();
    portfolioOverviewRefetch();
  };
  const accountSummary = useMemo(() => _objectSpread(_objectSpread({}, extractAccountSummaryAttributes(portfolioOverviewData === null || portfolioOverviewData === void 0 ? void 0 : portfolioOverviewData.accountSummary, balancePerformance)), {}, {
    interestRate: extractWalletInterestRate(portfolioOverviewData === null || portfolioOverviewData === void 0 ? void 0 : portfolioOverviewData.wallets),
    hasWallets: !isEmpty(portfolioOverviewData === null || portfolioOverviewData === void 0 ? void 0 : portfolioOverviewData.wallets)
  }, investmentsBalanceBreakdown), [portfolioOverviewData, balancePerformance, investmentsBalanceBreakdown]);
  const accountSummaryIsFetching = audiencesIsFetching || portfolioOverviewIsFetching || userStateIsFetching;
  const accountSummaryIsError = audiencesIsError || portfolioOverviewIsError || userStateIsError;
  const accountSummaryIsDataLoaded = !accountSummaryIsFetching && !accountSummaryIsError && !isEmpty(userStateData) && !isEmpty(portfolioOverviewData) && !isEmpty(audiencesData);
  const accountSummaryIsLoading = audiencesIsLoading || portfolioOverviewIsLoading || userStateIsLoading;
  return {
    accountSummaryIsLoading,
    accountSummaryIsFetching,
    accountSummaryIsError,
    accountSummaryIsDataLoaded,
    accountSummaryRefetch,
    accountSummary,
    audiencesTier,
    userState: userStateData
  };
};
export default useAccountSummary;