import styled from 'styled-components';
import { Paragraph } from '../../content';
export const RateWrapper = styled.div.withConfig({
  displayName: "Awardstyle__RateWrapper",
  componentId: "sc-dd2apc-0"
})(["align-items:center;display:flex;"]);
export const RateAmount = styled(Paragraph).withConfig({
  displayName: "Awardstyle__RateAmount",
  componentId: "sc-dd2apc-1"
})(["display:inline;color:", ";"], props => props.theme.colors.input_disabled_text);
export const Rate = styled.div.withConfig({
  displayName: "Awardstyle__Rate",
  componentId: "sc-dd2apc-2"
})(["display:flex;column-gap:", ";margin-right:", ";"], props => props.theme.spacing.xs, props => props.theme.spacing.sm);
export const AwardContainer = styled.div.withConfig({
  displayName: "Awardstyle__AwardContainer",
  componentId: "sc-dd2apc-3"
})(["display:flex;flex-direction:column;justify-content:center;row-gap:", ";width:270px;min-height:227px;align-items:center;padding:", ";border:solid 1px ", ";border-radius:", ";"], props => props.theme.spacing.ml, props => props.theme.spacing.sm, props => props.theme.colors.border_default, props => props.theme.spacing.xs);
export const TextWrapper = styled.div.withConfig({
  displayName: "Awardstyle__TextWrapper",
  componentId: "sc-dd2apc-4"
})(["display:flex;flex-direction:column;row-gap:", ";align-items:center;"], props => props.theme.spacing.xxs);