import { PortfolioStrategyValues } from '@yieldstreet/platform-kit';
import { getDatesForRange } from '@yieldstreet/tool-kit';
import { CALL_API } from '../middleware/Middleware';
export const OVERVIEW_ATTEMPT = 'ys/portfolioOverview/ATTEMPT';
export const OVERVIEW_SUCCESS = 'ys/portfolioOverview/SUCCESS';
export const OVERVIEW_FAILURE = 'ys/portfolioOverview/FAILURE';
export const TIER_OPT_IN_ATTEMPT = 'ys/portfolioOverview/tierOptIn/ATTEMPT';
export const TIER_OPT_IN_SUCCESS = 'ys/portfolioOverview/tierOptIn/SUCCESS';
export const TIER_OPT_IN_FAILURE = 'ys/portfolioOverview/tierOptIn/FAILURE';
export const getPortfolioOverview = function (userId, investorAccountId, timePeriod) {
  let managementStrategy = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : PortfolioStrategyValues.Self;
  return dispatch => {
    const [start, end] = getDatesForRange({
      range: timePeriod
    });
    const investorAccountIdParam = `&investorAccountId=${investorAccountId}`;
    const endpoint = `/api/portfolio/overview/${userId}?start=${start}&end=${end}${investorAccountId === 'ALL' ? '' : investorAccountIdParam}&managementStrategy=${managementStrategy}`;
    return dispatch({
      [CALL_API]: {
        endpoint,
        types: [OVERVIEW_ATTEMPT, OVERVIEW_SUCCESS, OVERVIEW_FAILURE],
        successPayload: {
          userId,
          investorAccountId,
          timePeriod
        },
        failurePayload: {
          userId,
          investorAccountId,
          timePeriod
        },
        getResponse: res => res.data
      }
    });
  };
};
export const updateTierOptIn = (userId, tierLevel) => dispatch => {
  const endpoint = `/api/portfolio/${userId}/tiers/${tierLevel}/optIn`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [TIER_OPT_IN_ATTEMPT, TIER_OPT_IN_SUCCESS, TIER_OPT_IN_FAILURE],
      options: {
        method: 'POST'
      },
      getResponse: res => res.data
    }
  });
};