var __jsx = React.createElement;
import React from 'react';
import { LegalEntityType } from '@yieldstreet/platform-kit';
import { ACCREDITATION_RULES, DocumentCheckboxName } from '../investment.model';
export const getAccreditedRequirement = (renderLink, Components) => __jsx(Components.TextComponent, null, "I certify that I am an Accredited Investor according to", ' ', renderLink(ACCREDITATION_RULES.rule501.url, ACCREDITATION_RULES.rule501.label), ".");
export const getSpvRequirements = (OPER, SUBS, PRSP, renderLink, Components) => {
  return [{
    name: DocumentCheckboxName.Rule501,
    label: getAccreditedRequirement(renderLink, Components)
  }, {
    name: DocumentCheckboxName.Subscription,
    label: __jsx(Components.TextComponent, null, "I have read and agree to the ", renderLink(OPER, 'Operating Agreement'), " and the", ' ', renderLink(SUBS, 'Subscription Agreement'), ", including without limitation, all", ' ', __jsx(Components.BoldText, null, "risk factors"), " and", ' ', __jsx(Components.BoldText, null, "conflicts of interest"), " set forth therein, and also this", ' ', renderLink('/support/article/important-additional-investor-information-when-investing', 'Important Additional Investor Information.'))
  }, {
    name: DocumentCheckboxName.Operating,
    label: __jsx(Components.TextComponent, null, "I have read and understood the ", renderLink(PRSP, 'Investment Memorandum'), ".")
  }];
};
export const getFundRequirements = (PRSP, spvName, SUBS, renderLink, Components) => {
  return [{
    name: DocumentCheckboxName.Subscription,
    label: __jsx(Components.TextComponent, null, "I have received, carefully read and understood the ", renderLink(PRSP, 'Prospectus'), " and any supplements thereto, including without limitation, all", ' ', __jsx(Components.BoldText, null, "risk factors"), " and", ' ', __jsx(Components.BoldText, null, "conflicts of interest"), " set forth therein, and I am thoroughly familiar with the proposed business, operations and financial condition of", ' ', spvName)
  }, {
    name: DocumentCheckboxName.Operating,
    label: __jsx(Components.TextComponent, null, "I have received, carefully read and agree to the", ' ', renderLink(SUBS, 'Subscription Agreement'), ".")
  }];
};
export const getBpdnOrShtnRequirements = (legalEntityType, SUBS, IFON, BPDN, PRPM, PRSP, renderLink, Components) => {
  const entityName = legalEntityType === LegalEntityType.BPDN ? 'Borrower Payment Dependent' : 'Short Term';
  return [{
    name: DocumentCheckboxName.Rule501,
    label: getAccreditedRequirement(renderLink, Components)
  }, {
    name: DocumentCheckboxName.Subscription,
    label: __jsx(Components.TextComponent, null, "I have read and agree to the terms of the ", renderLink(SUBS, 'Subscription Agreement'), ", the ", renderLink(IFON, 'Indenture'), ", and the", ' ', renderLink(BPDN, `Form of ${entityName} Note`), " attached thereto.")
  }, {
    name: DocumentCheckboxName.Operating,
    label: __jsx(Components.TextComponent, null, "I have read and understood the ", renderLink(PRPM, 'Private Placement Memorandum'), " and the", ' ', renderLink(PRSP, 'Series Note Supplement'), ", including without limitation, all", ' ', __jsx(Components.BoldText, null, "risk factors"), " and", ' ', __jsx(Components.BoldText, null, "conflicts of interest"), " set forth therein, and also this", ' ', renderLink('/support/article/important-additional-investor-information-when-investing', 'Important Additional Investor Information.'))
  }];
};
export const getAvenueRequirements = (PRPM, spvName, SUBS, renderLink, Components) => {
  return [{
    name: DocumentCheckboxName.Rule501,
    label: __jsx(Components.TextComponent, null, "I certify that I am an Accredited Investor according to", ' ', renderLink(ACCREDITATION_RULES.rule501.url, ACCREDITATION_RULES.rule501.label), ", a Qualified Purchaser according to", ' ', renderLink(ACCREDITATION_RULES['section2.a.51'].url, ACCREDITATION_RULES['section2.a.51'].label), ' ', "of the Investment Company Act of 1940 and a Qualified Client according to", ' ', renderLink(ACCREDITATION_RULES['rule205-3.d.1'].url, ACCREDITATION_RULES['rule205-3.d.1'].label), ' ', "of the Investment Advisers Act of 1940.")
  }, {
    name: DocumentCheckboxName.Subscription,
    label: __jsx(Components.TextComponent, null, "I have received, carefully read and understood the", ' ', renderLink(PRPM, 'Private Placement Memorandum'), " and any supplements and exhibits thereto, including without limitation, all", ' ', __jsx(Components.BoldText, null, "risk factors"), " and", ' ', __jsx(Components.BoldText, null, "conflict of interest"), " set forth therein, and I am thoroughly familiar with the proposed business, operations and financial condition of", ' ', spvName)
  }, {
    name: DocumentCheckboxName.Operating,
    label: __jsx(Components.TextComponent, null, "I have received, carefully read and agree to the", ' ', renderLink(SUBS, 'Subscription Agreement'), ".")
  }];
};
export const getAltFundRequirements = (OPER, SUBS, PRPM, renderLink, Components) => {
  return [{
    name: DocumentCheckboxName.Rule501,
    label: __jsx(Components.TextComponent, null, "I certify that I am an Accredited Investor according to", ' ', renderLink(ACCREDITATION_RULES.rule501.url, ACCREDITATION_RULES.rule501.label), " and a Qualified Purchaser according to", ' ', renderLink('https://www.law.cornell.edu/uscode/text/15/80a-2', 'Section 2(a)(51)'), " of the Investment Company Act of 1940.")
  }, {
    name: DocumentCheckboxName.Subscription,
    label: __jsx(Components.TextComponent, null, "I have read and agree to the ", renderLink(OPER, 'Operating Agreement'), " and the", ' ', renderLink(SUBS, 'Subscription Agreement'), " and any supplements and exhibits thereto.")
  }, {
    name: DocumentCheckboxName.Operating,
    label: __jsx(Components.TextComponent, null, "I have read and understood the ", renderLink(PRPM, 'Private Placement Memorandum'), ", and any supplements, annexes and exhibits thereto, including without limitation, all risk factors and conflicts of interest set forth therein.")
  }];
};
export const getOfferingRequirements = (legalEntityType, spvName, docs, renderLink, Components) => {
  const {
    SUBS,
    OPER,
    PRSP,
    IFON,
    PRPM,
    BPDN
  } = docs;
  let requirements = [];
  if (legalEntityType === LegalEntityType.SPVM) {
    requirements = getSpvRequirements(OPER, SUBS, PRSP, renderLink, Components);
  } else if (legalEntityType === LegalEntityType.FUND) {
    requirements = getFundRequirements(PRSP, spvName, SUBS, renderLink, Components);
  } else {
    requirements = getBpdnOrShtnRequirements(legalEntityType, SUBS, IFON, BPDN, PRPM, PRSP, renderLink, Components);
  }
  return requirements;
};