/**
 *  Legacy Signups
 */
export const LEGACY_SIGNUP_QUIZ_PATH = '/signup-quiz';
const LEGACY_SIGNUP_QUIZ_ALTERNATIVE_PATH = '/signupQuiz';
const LEGACY_SIGNUP_INVITATION = `/signup-invitation`;
export const LEGACY_SIGNUP_GET_STARTED_PATH = '/get-started';
export const LEGACY_SIGNUP_QUIZ_PATHS = [LEGACY_SIGNUP_QUIZ_PATH, LEGACY_SIGNUP_QUIZ_ALTERNATIVE_PATH, LEGACY_SIGNUP_INVITATION];
export const LEGACY_SIGNUP_PATHS = [...LEGACY_SIGNUP_QUIZ_PATHS, LEGACY_SIGNUP_GET_STARTED_PATH];
export const SIGNUP_BASE_PATH = '/signup';
export const SIGNUP_TYPE_PARAM = ':step';
export const SIGNUP_SCREEN_PATH = `${SIGNUP_BASE_PATH}/${SIGNUP_TYPE_PARAM}?`;
export const SIGNUP_PATHS = [SIGNUP_BASE_PATH, SIGNUP_SCREEN_PATH, ...LEGACY_SIGNUP_PATHS];