import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import React from "react";
var __jsx = React.createElement;
import { useParams } from 'react-router';
import { atomicGatewayApi } from '@yieldstreet/platform-kit';
import { Notification, PageLoader } from '@yieldstreet/ui-kit';
import { CompletionContainer, CompletionIframe } from './ManagedPortfolioCompletion.style';
import { testIds } from './ManagedPortfolioCompletion.model';
export const ManagedPortfolioCompletion = () => {
  const {
    investorEntityId
  } = useParams();
  const {
    data,
    isFetching,
    isLoading,
    isError
  } = atomicGatewayApi.useGetCompletionLinkQuery({
    investorEntityId
  });
  const completionLink = data === null || data === void 0 ? void 0 : data.completionLink;
  if (isLoading || isFetching) {
    return __jsx(PageLoader, null);
  }
  if (completionLink && !isError) {
    return __jsx(CompletionContainer, testIds.container.attr, __jsx(CompletionIframe, _extends({
      id: "completionLink"
    }, testIds.iframe.attr, {
      title: "Managed Portfolio Completion Link",
      width: "75%",
      height: "700",
      src: completionLink
    })));
  }
  return __jsx(CompletionContainer, null, __jsx(Notification, {
    inline: true,
    severity: "error",
    title: "We encountered a problem while loading this page.",
    testId: testIds.error.id
  }));
};