import styled from 'styled-components';
export const SliderWrapper = styled.div.withConfig({
  displayName: "ProductTourSlidestyle__SliderWrapper",
  componentId: "sc-1hyo3sb-0"
})(["display:flex;flex-direction:column;margin:0 auto;padding:", " 0;text-align:", ";"], _ref => {
  let {
    theme
  } = _ref;
  return theme.spacing.l;
}, props => props.alignCentre ? 'center' : 'left');
export const ImgWrapper = styled.div.withConfig({
  displayName: "ProductTourSlidestyle__ImgWrapper",
  componentId: "sc-1hyo3sb-1"
})(["height:300px;position:absolute;left:0;top:0;width:100%;img{height:100%;object-fit:cover;width:100%;}"]);
export const SliderDotsWrapper = styled.div.withConfig({
  displayName: "ProductTourSlidestyle__SliderDotsWrapper",
  componentId: "sc-1hyo3sb-2"
})(["display:flex;flex-direction:row;justify-content:flex-start;margin-bottom:", ";"], _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.spacing.m;
});
export const SliderDot = styled.div.withConfig({
  displayName: "ProductTourSlidestyle__SliderDot",
  componentId: "sc-1hyo3sb-3"
})(["background-color:", ";border-radius:50%;height:8px;margin-right:10px;width:8px;"], props => props.isActive ? props.theme.colors.border_outline : props.theme.colors.border_default);