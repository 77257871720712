import styled from 'styled-components';
import { Heading } from '../../content';
import { backgroundVariant } from './Accordion.model';
export const Container = styled.div.withConfig({
  displayName: "Accordionstyle__Container",
  componentId: "sc-1gkd33p-0"
})(["margin-bottom:10px;padding:20px;background:", ";border-radius:8px;"], props => props.theme.colors[backgroundVariant[props.background]]);
export const IconHolder = styled.div.withConfig({
  displayName: "Accordionstyle__IconHolder",
  componentId: "sc-1gkd33p-1"
})(["padding:0 10px 0 0;display:flex;align-items:center;"]);
export const Title = styled.div.withConfig({
  displayName: "Accordionstyle__Title",
  componentId: "sc-1gkd33p-2"
})(["display:flex;justify-content:space-between;cursor:pointer;"]);
export const HeadingWrapper = styled.div.withConfig({
  displayName: "Accordionstyle__HeadingWrapper",
  componentId: "sc-1gkd33p-3"
})(["width:80%;"]);
export const AnimatedContent = styled.div.withConfig({
  displayName: "Accordionstyle__AnimatedContent",
  componentId: "sc-1gkd33p-4"
})(["display:grid;transition:grid-template-rows 0.7s ease;grid-template-rows:", ";"], props => props.show ? '1fr' : '0fr');
export const Description = styled.div.withConfig({
  displayName: "Accordionstyle__Description",
  componentId: "sc-1gkd33p-5"
})(["padding-top:20px;overflow:hidden;", ""], props => props.addParagraphMargin && `
    p {
      margin-bottom: 10px;
    }
  `);
export const HeadingIcon = styled.img.withConfig({
  displayName: "Accordionstyle__HeadingIcon",
  componentId: "sc-1gkd33p-6"
})(["margin-right:16px;"]);
export const AccordionHeading = styled(Heading).withConfig({
  displayName: "Accordionstyle__AccordionHeading",
  componentId: "sc-1gkd33p-7"
})(["display:flex;flex-direction:row;align-items:center;justify-content:flex-start;"]);