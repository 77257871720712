import _Box from "@mui/material/Box";
import _Typography from "@mui/material/Typography";
import React from "react";
var __jsx = React.createElement;
import HiddenInterestCaretIcon from 'assets/images/images/hidden-interest-caret.svg';
export const MetricValue = _ref => {
  let {
    value,
    isConcise,
    isHiddenValue,
    dataCy
  } = _ref;
  if (isConcise) {
    return __jsx(React.Fragment, null, __jsx(_Typography, {
      component: "p",
      variant: "body1",
      "data-cy": dataCy,
      sx: theme => ({
        display: 'none',
        color: theme.palette.text.paragraphPrimary.inverse,
        [theme.breakpoints.up('md')]: {
          display: 'block'
        }
      })
    }, value), __jsx(_Typography, {
      component: "span",
      variant: "body2",
      "data-cy": dataCy,
      sx: theme => ({
        color: theme.palette.text.paragraphPrimary.inverse,
        ml: 2,
        display: 'block',
        [theme.breakpoints.up('md')]: {
          display: 'none'
        }
      })
    }, value));
  }
  return __jsx(_Typography, {
    component: "p",
    variant: "body1",
    "data-cy": dataCy,
    sx: theme => ({
      color: theme.palette.text.paragraphPrimary.inverse,
      alignSelf: 'flex-start',
      fontSize: '16px',
      ml: theme.spacing(2.5),
      fontWeight: 600,
      [theme.breakpoints.up('md')]: {
        fontWeight: 'normal',
        alignSelf: 'unset',
        fontSize: '24px',
        ml: 'unset'
      }
    })
  }, isHiddenValue ? __jsx(_Box, {
    component: "img",
    "data-cy": `${dataCy}-hidden-value`,
    src: HiddenInterestCaretIcon,
    sx: theme => ({
      [theme.breakpoints.up('md')]: {
        objectPosition: 'unset',
        marginBottom: 0
      }
    })
  }) : value);
};