import { PaymentStatus } from '@yieldstreet/platform-kit';
export const handlePaymentStatusLabel = status => {
  switch (status) {
    case PaymentStatus.Cleared:
      return 'Settled';
    case PaymentStatus.Returned:
    case PaymentStatus.Fail:
      return 'Returned';
    case PaymentStatus.Approved:
    case PaymentStatus.Hold:
    case PaymentStatus.Pending:
    case PaymentStatus.Qued:
    case PaymentStatus.Processing:
    default:
      return 'Processing';
  }
};