import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var __jsx = React.createElement;
import React, { useCallback, useMemo } from 'react';
import Lottie from 'react-lottie';
import isString from "lodash/isString";
import isObject from "lodash/isObject";
import find from "lodash/find";
import { ExternalLink } from '../../../content/link/ExternalLink';
import { NavigationIcon, NavigationIconAnimated, NavigationIconWrapper } from './NavigationLink.style';
import { getNavigationValue, getSizeVariation, renderSizeVariation } from '../../header/common';
export const useNavItemProps = props => {
  const {
    audiences,
    tier,
    userState,
    isMobile
  } = props;
  return useMemo(() => ({
    audiences,
    tier,
    userState,
    isMobile
  }), [audiences, tier, userState, isMobile]);
};
export const getNavItemVisible = (item, props) => {
  const {
    userState,
    isMobile
  } = props;
  const loggedIn = userState && userState.loggedIn;
  const isAccredited = userState && userState.accreditedSignUp;
  const isAdmin = loggedIn && userState && userState.isSoyUser;
  if (item.private && !loggedIn || item.guestOnly && loggedIn || item.adminOnly && !isAdmin || loggedIn && (item.accreditedOnly && !isAccredited || item.nonAccreditedOnly && isAccredited)) {
    return false;
  }
  return getSizeVariation(getNavigationValue(item.visible || true, props, item), isMobile);
};
export const getNavItemIcon = (item, props) => {
  const icon = getNavigationValue(item.icon, props, item);
  if (isString(icon)) {
    return __jsx(NavigationIcon, {
      src: icon
    });
  }
  if (isObject(icon)) {
    return __jsx(NavigationIconAnimated, null, __jsx(Lottie, {
      options: {
        loop: true,
        autoplay: true,
        animationData: icon
      },
      width: 30,
      height: 'auto'
    }));
  }
  return null;
};
export const getNavItemTitle = (item, props) => {
  const title = getNavigationValue(item.title, props, item);
  return renderSizeVariation(title, renderTitle => {
    return item.icon ? __jsx(NavigationIconWrapper, null, getNavItemIcon(item, props), renderTitle) : renderTitle;
  });
};
export const getNavItemRoute = (item, props) => {
  const {
    route
  } = item;
  return getNavigationValue(route, props, item);
};
export const getNavItemSubMenu = (item, props) => {
  const {
    items
  } = item;
  return getNavigationValue(items, props, item);
};
export const getNavItemSelected = (itemRoute, currentPath) => {
  return itemRoute ? !!(itemRoute && currentPath) && currentPath.startsWith(itemRoute) : false;
};
export const findActiveNavItem = (items, props, currentPath) => {
  if (!currentPath) {
    return;
  }
  let selectedItem = items.filter(item => {
    const itemRoute = getNavItemRoute(item, props);
    const itemSelected = getNavItemSelected(itemRoute, currentPath);
    const itemSubMenu = getNavItemSubMenu(item, props);
    const selectedSubItem = itemSubMenu && findActiveNavItem(itemSubMenu, props, currentPath);
    return itemSelected || selectedSubItem;
  });
  if (selectedItem.length > 1 && find(selectedItem, 'route')) {
    selectedItem = selectedItem.filter(item => {
      const itemRoute = getNavItemRoute(item, props);
      return itemRoute === null || itemRoute === void 0 ? void 0 : itemRoute.includes(currentPath);
    });
  }
  return selectedItem[0];
};
export const NavigationLink = props => {
  const {
    inverse,
    children,
    navItem,
    staticPage,
    renderLink,
    className,
    raw,
    title,
    innerRef
  } = props;
  const {
    id,
    callback,
    forceExternal,
    newTab,
    staticRoute,
    queryParamsString
  } = navItem;
  const finalRoute = getNavItemRoute(navItem, props);
  const finalTitle = children || getNavItemTitle(navItem, props);
  const finalNavItem = useMemo(() => _objectSpread(_objectSpread({}, navItem), {}, {
    title: finalTitle,
    route: `${finalRoute}${queryParamsString || ''}`
  }), [finalRoute, finalTitle, navItem, queryParamsString]);
  const onClick = useCallback(e => {
    !finalRoute && e.preventDefault();
    callback && callback(finalNavItem);
  }, [finalRoute, callback, finalNavItem]);
  if (!finalTitle) {
    return null;
  }
  if (!forceExternal && (staticRoute && staticPage || !staticRoute && !staticPage) && renderLink && renderLink(_objectSpread(_objectSpread({}, props), {}, {
    navItem: finalNavItem
  }))) {
    return renderLink(_objectSpread(_objectSpread({}, props), {}, {
      navItem: finalNavItem
    }));
  } else {
    if (!finalRoute) {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        __jsx("a", {
          ref: innerRef,
          id: id,
          "data-cy": `navigation-${id}`,
          className: className,
          onClick: onClick
        }, finalTitle)
      );
    }
    const targetProps = newTab ? {
      target: '_blank',
      rel: 'noreferrer'
    } : {
      target: '_self',
      rel: ''
    };
    if (raw) {
      return __jsx("a", _extends({
        ref: innerRef,
        id: id,
        "data-cy": `navigation-raw-${id}`,
        className: className,
        href: finalRoute,
        onClick: onClick,
        title: title,
        tabIndex: 0
      }, targetProps), finalTitle);
    }
    return __jsx(ExternalLink, {
      id: `navigation-external-link-${id}`,
      inverse: inverse,
      className: className,
      href: finalRoute,
      onClick: onClick,
      rel: forceExternal ? 'noopener noreferrer' : '',
      target: newTab ? '_blank' : ''
    }, finalTitle);
  }
};