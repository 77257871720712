var __jsx = React.createElement;
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

// Use this component if a redirect is happening on the server side and you want it to be a 301 (permanent redirect)
// If you want your redirect to be a 302 simply use the default <Redirect> component from 'react-router-dom'

const PermanentRedirect = props => {
  return __jsx(Route, {
    render: _ref => {
      let {
        staticContext
      } = _ref;
      // there is no `staticContext` on the client (only on the server)
      // we need to guard against that here
      if (staticContext) staticContext.permanent = true;
      return __jsx(Redirect, props);
    }
  });
};
export default PermanentRedirect;