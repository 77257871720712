import _Box from "@mui/material/Box";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["offeringHero", "labelFallback", "valueFallback", "anchorValue", "isHiddenValue", "dataCy", "handleAnchorClick"],
  _excluded2 = ["offeringHero", "labelFallback", "value", "valueFallback", "dataCy", "handleAnchorClick"],
  _excluded3 = ["isPublicFund", "offering"];
var __jsx = React.createElement;
import React, { useCallback, useMemo } from 'react';
import { OfferingStatus } from '@yieldstreet/platform-kit';
import scroll from 'sharedComponents/utils/scroll';
import handleNumber from 'sharedComponents/utils/handleNumber';
import TieredMetricTree from '../TieredMetricTree/TieredMetricTree';
import { MetricValue } from '../Metric/MetricValue';
import { MetricLabel } from '../Metric/MetricLabel';
import LiquidityDescription from '../LiquidityDescription/LiquidityDescription';
import TinyAnchor from 'sharedComponents/TinyAnchor/TinyAnchor';
import { MetricInnerWrapper, ActiveMetricWrapper } from './ActiveMetrics.style';
import { METRIC_TYPE } from './ActiveMetrics.model';
const Metric = _ref => {
  let {
    isConcise,
    dataCy,
    label,
    labelFallback,
    value,
    valueFallback,
    isHiddenValue,
    anchorValue,
    handleAnchorClick,
    children,
    tree,
    modalType
  } = _ref;
  return __jsx(React.Fragment, null, __jsx(ActiveMetricWrapper, {
    isConcise: isConcise
  }, __jsx(MetricInnerWrapper, null, __jsx(MetricLabel, {
    label: label || labelFallback,
    isConcise: isConcise,
    dataCy: `${dataCy}-label`
  }, !!anchorValue && __jsx(TinyAnchor, {
    dataCy: `${dataCy}-annual-interest-anchor`,
    elementId: "sup3",
    offset: true,
    offsetSize: 559,
    tight: isConcise,
    onClick: handleAnchorClick
  }, anchorValue)), __jsx(MetricValue, {
    value: value || valueFallback,
    dataCy: `${dataCy}-value`,
    isConcise: isConcise,
    isHiddenValue: isHiddenValue
  }), children), !!tree && !isConcise && __jsx(TieredMetricTree, {
    dataCy: `${dataCy}-tree`,
    data: tree,
    modalType: modalType
  })));
};
export const RateMetric = _ref2 => {
  let {
      offeringHero,
      labelFallback,
      valueFallback,
      anchorValue,
      isHiddenValue,
      dataCy,
      handleAnchorClick
    } = _ref2,
    props = _objectWithoutProperties(_ref2, _excluded);
  return Metric(_objectSpread({
    dataCy: `${dataCy}-interest`,
    label: offeringHero === null || offeringHero === void 0 ? void 0 : offeringHero.customRateLabel,
    value: offeringHero === null || offeringHero === void 0 ? void 0 : offeringHero.customRateString,
    labelFallback,
    valueFallback,
    anchorValue,
    isHiddenValue,
    handleAnchorClick,
    modalType: METRIC_TYPE.rate
  }, props));
};
export const TermMetric = _ref3 => {
  let {
      offeringHero,
      labelFallback,
      value,
      valueFallback,
      dataCy,
      handleAnchorClick
    } = _ref3,
    props = _objectWithoutProperties(_ref3, _excluded2);
  return Metric(_objectSpread({
    dataCy: `${dataCy}-term`,
    label: offeringHero === null || offeringHero === void 0 ? void 0 : offeringHero.customTermLabel,
    value,
    labelFallback,
    valueFallback,
    handleAnchorClick,
    modalType: METRIC_TYPE.term
  }, props));
};
export const GenericMetric = _ref4 => {
  let {
    isConcise,
    offeringHero,
    liquidityDescription,
    isLoggedIn,
    interestRate,
    term,
    termSuffix,
    status,
    onClick,
    tieredTree
  } = _ref4;
  const prefix = 'offering-hero-generic-metric';
  const isActive = useMemo(() => {
    return [OfferingStatus.open, OfferingStatus.preoffering, OfferingStatus.pending, OfferingStatus.unfinished].includes(status);
  }, [status]);
  const isMaturing = !isActive && status !== OfferingStatus.repaid;
  const isMatured = status === OfferingStatus.repaid;
  const termMetricProps = useMemo(() => {
    const children = (liquidityDescription === null || liquidityDescription === void 0 ? void 0 : liquidityDescription.title) && isLoggedIn && !isConcise && __jsx(LiquidityDescription, {
      description: liquidityDescription
    });
    return {
      isConcise,
      anchorValue: 3,
      offeringHero,
      handleAnchorClick: onClick,
      children,
      tree: tieredTree === null || tieredTree === void 0 ? void 0 : tieredTree.tieredTermTree
    };
  }, [isConcise, offeringHero, isLoggedIn, onClick, tieredTree, liquidityDescription]);
  return __jsx(React.Fragment, null, RateMetric({
    dataCy: `${prefix}-annual`,
    labelFallback: 'Annual interest',
    valueFallback: `${handleNumber(interestRate)}%`,
    isConcise,
    anchorValue: 3,
    offeringHero,
    isHiddenValue: !isLoggedIn,
    handleAnchorClick: onClick,
    tree: tieredTree === null || tieredTree === void 0 ? void 0 : tieredTree.tieredRateTree
  }), isActive && TermMetric(_objectSpread({
    dataCy: `${prefix}-term`,
    labelFallback: 'Term',
    valueFallback: `${term} ${termSuffix}`
  }, termMetricProps)), isMaturing && TermMetric(_objectSpread({
    dataCy: `${prefix}-remaining`,
    labelFallback: 'Term remaining',
    valueFallback: `${term} ${termSuffix}`
  }, termMetricProps)), isMatured && TermMetric(_objectSpread({
    dataCy: `${prefix}-final`,
    labelFallback: 'Final term',
    value: `${term} ${termSuffix}`
  }, termMetricProps)));
};
export const PublicFundMetric = _ref5 => {
  let {
    isConcise,
    offeringHero,
    distributionRate,
    term,
    termSuffix,
    isFortyActFund,
    isLoggedIn,
    onClick,
    tieredTree
  } = _ref5;
  const onFundAnchor = useCallback(() => {
    scroll('fund-footnotes', 'start', true);
    onClick();
  }, [onClick]);
  const prefix = `offering-hero-public-fund`;
  return __jsx(React.Fragment, null, RateMetric({
    dataCy: `${prefix}-fund-metric`,
    labelFallback: `Distribution rate`,
    valueFallback: `${distributionRate}%`,
    anchorValue: 1,
    offeringHero,
    isHiddenValue: !isLoggedIn,
    isConcise,
    handleAnchorClick: onFundAnchor,
    tree: tieredTree === null || tieredTree === void 0 ? void 0 : tieredTree.tieredRateTree
  }), !isFortyActFund && __jsx(React.Fragment, null, isConcise && __jsx(_Box, {
    sx: theme => ({
      display: 'block',
      color: theme.palette.text.labelPrimary.inverse,
      [theme.breakpoints.up('sm')]: {
        display: 'none'
      }
    })
  }, "\u2022"), TermMetric({
    dataCy: `${prefix}-term-metric`,
    labelFallback: 'Fund termination date',
    valueFallback: `${term} ${termSuffix}`,
    anchorValue: 2,
    isHiddenValue: false,
    isConcise,
    handleAnchorClick: onFundAnchor,
    tree: tieredTree === null || tieredTree === void 0 ? void 0 : tieredTree.tieredTermTree
  })));
};
export const ActiveMetrics = _ref6 => {
  let {
      isPublicFund,
      offering
    } = _ref6,
    props = _objectWithoutProperties(_ref6, _excluded3);
  const metricProps = useMemo(() => {
    const {
      interestRate,
      distributionRate,
      offeringHero,
      liquidityDescription
    } = offering;
    return _objectSpread(_objectSpread({}, props), {}, {
      interestRate,
      distributionRate,
      offeringHero,
      liquidityDescription
    });
  }, [offering, props]);
  if (isPublicFund) {
    return __jsx(React.Fragment, null, isPublicFund && PublicFundMetric(metricProps));
  }
  return GenericMetric(metricProps);
};