import _Link from "@mui/material/Link";
import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React, { useMemo, useCallback } from 'react';
import map from "lodash/map";
import { CheckboxInput } from '@yieldstreet/ui-kit';
import { FilterTypeEnum } from '@yieldstreet/tool-kit';
import { TEST_IDS } from './FilterView.model';
import { FilterHeader, FilterHeaderItem, MultiSelectFilterWrapper } from './FilterView.style';
export const MultiSelectFilter = _ref => {
  var _filter$dropdownConfi;
  let {
    name = '',
    onChange = () => {},
    value,
    filter
  } = _ref;
  const {
    description = '',
    options
  } = filter;
  const onOptionChange = useCallback((checked, optionValue) => {
    const newValue = value ? new Array(...value) : [];
    if (checked) {
      onChange([...newValue, optionValue]);
    } else {
      onChange(newValue.filter(item => item !== optionValue));
    }
  }, [value, onChange]);
  const reset = useCallback(() => {
    onChange([]);
  }, [onChange]);
  const renderOptions = useMemo(() => {
    if (!options) {
      return null;
    }
    return map(options, option => {
      const key = option.id.toString();
      const isChecked = value && value.indexOf(key) > -1;
      return __jsx("div", {
        key: `${name}-${key}`
      }, __jsx(CheckboxInput, {
        name: `${name}-${key}`,
        label: option.label,
        value: !!isChecked,
        onChange: checked => {
          onOptionChange(checked, key);
        }
      }));
    });
  }, [name, onOptionChange, options, value]);
  return __jsx("div", {
    "data-cy": TEST_IDS[FilterTypeEnum.MultiSelect]
  }, __jsx(FilterHeader, null, __jsx(FilterHeaderItem, null, __jsx(_Typography, {
    variant: "body2"
  }, description)), __jsx(FilterHeaderItem, null, __jsx(_Typography, {
    variant: "body2"
  }, __jsx(_Link, {
    onClick: reset
  }, "Reset")))), __jsx(MultiSelectFilterWrapper, {
    columns: filter === null || filter === void 0 ? void 0 : (_filter$dropdownConfi = filter.dropdownConfig) === null || _filter$dropdownConfi === void 0 ? void 0 : _filter$dropdownConfi.columns
  }, renderOptions));
};