import { PortfolioStrategyValues } from '@yieldstreet/platform-kit';
import { CALL_API } from '../middleware/Middleware';
export const PORTFOLIO_GET_TRANSACTIONS_PAYMENTS_ATTEMPT = 'ys/portfolioTransaction/getTransactionsPayments/ATTEMPT';
export const PORTFOLIO_GET_TRANSACTIONS_PAYMENTS_SUCCESS = 'ys/portfolioTransaction/getTransactionsPayments/SUCCESS';
export const PORTFOLIO_GET_TRANSACTIONS_PAYMENTS_FAILURE = 'ys/portfolioTransaction/getTransactionsPayments/FAILURE';
export const getPayments = function (userId) {
  let filters = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  let noteUrlHash = arguments.length > 2 ? arguments[2] : undefined;
  let managementStrategy = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : PortfolioStrategyValues.Self;
  let status = arguments.length > 4 ? arguments[4] : undefined;
  let page = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : 1;
  let pageSize = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : 10000;
  return (dispatch, getStore) => {
    if (noteUrlHash) {
      filters['noteUrlHash'] = noteUrlHash;
    }
    if (status) {
      filters['status'] = status;
    }
    if (page) {
      filters['page'] = page;
    }
    if (pageSize) {
      filters['pageSize'] = pageSize;
    }
    if (managementStrategy) {
      filters['managementStrategy'] = managementStrategy;
    }
    const filterStr = filters && Object.keys(filters).reduce((p, c) => {
        if (filters[c]) {
          p = `${p}&${c}=${filters[c]}`;
        }
        return p;
      }, '') || '',
      endpoint = `/a/api/portfolio/payments/${userId}?${filterStr}&status[]=PROCESSING&status[]=CLEARED`;
    const filtersJson = JSON.stringify(filters);
    const paymentsUpdate = Object.assign({}, getStore().portfolioTransactions.payments);
    return dispatch({
      [CALL_API]: {
        endpoint,
        types: [PORTFOLIO_GET_TRANSACTIONS_PAYMENTS_ATTEMPT, PORTFOLIO_GET_TRANSACTIONS_PAYMENTS_SUCCESS, PORTFOLIO_GET_TRANSACTIONS_PAYMENTS_FAILURE],
        getResponse: res => {
          if (!paymentsUpdate[userId]) {
            paymentsUpdate[userId] = {};
          }
          paymentsUpdate[userId][filtersJson] = Object.assign({}, res.data);
          return paymentsUpdate;
        }
      }
    });
  };
};