var __jsx = React.createElement;
import React from 'react';
import { Paragraph } from '../../content';
import { PageSection } from '../../layout';
import { BannerContainer, ContentHolder, SHeading, ButtonContainer, ImageContainer } from './PhotoBanner.style';
export const PhotoBanner = _ref => {
  let {
    title,
    content,
    image,
    button,
    dataCy
  } = _ref;
  return __jsx(PageSection, null, __jsx(BannerContainer, {
    "data-cy": dataCy || 'photo-banner-container'
  }, __jsx(ContentHolder, null, __jsx("div", null, __jsx(SHeading, {
    type: 2,
    inverse: true
  }, title), __jsx(Paragraph, {
    secondary: true,
    inverse: true
  }, content), button ? __jsx(ButtonContainer, null, button) : null), __jsx(ImageContainer, null, image))));
};