import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var __jsx = React.createElement;
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import isString from 'lodash/isString';
import { NavigationLink, getNavItemSubMenu, getNavItemVisible, useNavItemProps, getNavItemTitle } from '../../common';
import { getSizeVariation, getNavigationValue } from '../common';
import { SubmenuSection } from '../SubmenuSection/SubmenuSection';
import { ArrowIcon, MenuItem, MenuLinkContainer, NotificationIcon, NotificationIndicator, SButton, SParagraph, SubMenu, SubMenuItem, Title } from './HeaderItemLoggedOut.style';
export const HeaderItemLoggedOut = props => {
  const {
    menuItem,
    activeItem,
    isMobile,
    isSidebarItem,
    desktopAlignment = 'left',
    notificationGroup,
    setDropdownOpen,
    setSideMenuOpen
  } = props;
  const menuLinkElement = useRef(null);
  const onClickOutside = useCallback(e => {
    var _menuLinkElement$curr;
    if (e.target && !(menuLinkElement !== null && menuLinkElement !== void 0 && (_menuLinkElement$curr = menuLinkElement.current) !== null && _menuLinkElement$curr !== void 0 && _menuLinkElement$curr.contains(e.target))) {
      setMenuOpen(false);
    }
  }, []);
  useEffect(() => {
    if (window) {
      window.addEventListener('click', onClickOutside);
      return () => window.removeEventListener('click', onClickOutside);
    }
  }, [onClickOutside]);
  const {
    0: menuOpen,
    1: setMenuOpen
  } = useState(false);
  const navProps = useNavItemProps(props);
  const itemSelected = menuItem.key === (activeItem === null || activeItem === void 0 ? void 0 : activeItem.key);
  const itemInteractive = useMemo(() => {
    const renderTitle = getSizeVariation(getNavigationValue(menuItem.title, navProps, menuItem), isMobile);
    return isString(renderTitle);
  }, [isMobile, menuItem, navProps]);
  const itemTitle = useMemo(() => {
    return getNavItemTitle(menuItem, navProps);
  }, [menuItem, navProps]);
  const itemVisible = useMemo(() => {
    return getNavItemVisible(menuItem, navProps);
  }, [menuItem, navProps]);
  const itemSubMenu = useMemo(() => {
    return getNavItemSubMenu(menuItem, navProps);
  }, [menuItem, navProps]);
  return itemVisible ? __jsx(MenuItem, {
    key: `menu-item-${menuItem.key}`,
    "data-js-header-item-menu-item": true,
    isSidebarItem: isSidebarItem,
    seperator: menuItem.seperator
  }, __jsx(Title, {
    onClick: () => {
      let dropdownOpen = false;
      if (itemSubMenu) {
        setMenuOpen(!menuOpen);
        dropdownOpen = !menuOpen;
      }
      setDropdownOpen && setDropdownOpen(dropdownOpen);
    },
    isSidebarItem: isSidebarItem
  }, __jsx(MenuLinkContainer, {
    ref: menuLinkElement,
    isSidebarItem: isSidebarItem,
    active: menuOpen,
    isButton: (menuItem === null || menuItem === void 0 ? void 0 : menuItem.type) === 'button',
    selected: itemSelected,
    interactive: itemInteractive
  }, itemSubMenu ? __jsx("a", {
    href: "#/",
    onClick: e => e.preventDefault(),
    "data-cy": `header-${menuItem === null || menuItem === void 0 ? void 0 : menuItem.key}-mobile`
  }, itemTitle) : __jsx(NavigationLink, _extends({
    navItem: _objectSpread(_objectSpread({}, menuItem), {}, {
      id: `header-${menuItem === null || menuItem === void 0 ? void 0 : menuItem.key}`,
      callback: () => {
        setSideMenuOpen && setSideMenuOpen(false);
      }
    }),
    raw: true
  }, props), (menuItem === null || menuItem === void 0 ? void 0 : menuItem.type) === 'button' ? __jsx(SButton, {
    "data-cy": "header-loggedout-action",
    size: "small",
    variant: "contained",
    color: "secondary"
  }, itemTitle) : __jsx(React.Fragment, null, itemTitle)), itemSubMenu && (isMobile || menuItem.itemsDropdown) && __jsx(ArrowIcon, {
    isSidebarItem: isSidebarItem,
    menuOpen: menuOpen
  }), __jsx(NotificationIndicator, null, notificationGroup === null || notificationGroup === void 0 ? void 0 : notificationGroup.map(group => group.menu === menuItem.key && group.value ? __jsx(NotificationIcon, null) : null))), itemSubMenu && __jsx(SubMenu, {
    isSidebarItem: isSidebarItem,
    desktopAlignment: desktopAlignment,
    menuOpen: menuOpen
  }, itemSubMenu.map(submenu => {
    if (submenu.items) {
      return __jsx(SubmenuSection, {
        item: submenu,
        key: `submenu-section-${submenu.key}`,
        navProps: navProps
      });
    }
    return __jsx(HeaderSubMenuItem, _extends({}, props, {
      key: `subItem-${submenu.key}`,
      menuItem: submenu,
      notificationGroup: notificationGroup,
      seperator: submenu.seperator
    }));
  })))) : null;
};
export const HeaderSubMenuItem = props => {
  const {
    menuItem,
    activeSubItem,
    notificationGroup,
    isSidebarItem,
    seperator,
    inverse
  } = props;
  const navProps = useNavItemProps(props);
  const itemSelected = menuItem.key === (activeSubItem === null || activeSubItem === void 0 ? void 0 : activeSubItem.key);
  const itemVisible = useMemo(() => {
    return getNavItemVisible(menuItem, navProps);
  }, [menuItem, navProps]);
  return itemVisible ? __jsx(SubMenuItem, {
    seperator: seperator,
    key: `submenu-${menuItem.key}`
  }, __jsx(MenuLinkContainer, {
    isSubItem: true,
    isSidebarItem: isSidebarItem,
    selected: itemSelected,
    interactive: true,
    inverse: inverse
  }, __jsx(NavigationLink, _extends({
    navItem: _objectSpread(_objectSpread({}, menuItem), {}, {
      id: `header-${menuItem === null || menuItem === void 0 ? void 0 : menuItem.key}`
    }),
    raw: true,
    inverse: true
  }, props)), __jsx(NotificationIndicator, null, notificationGroup === null || notificationGroup === void 0 ? void 0 : notificationGroup.map(group => group.subMenu === menuItem.key && group.value ? __jsx(NotificationIcon, {
    subMenu: true,
    isBigNumber: group.value > 99
  }, __jsx(SParagraph, null, group.value > 99 ? '99+' : group.value)) : null)))) : null;
};