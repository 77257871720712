import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useCallback, useMemo } from 'react';
import { allToken, investorEntityApi, investorEntityStatusApi } from '@yieldstreet/platform-kit';
import { skipToken } from '@reduxjs/toolkit/query';
import { useAuth } from '../../auth';
export const useAllInvestorEntities = () => {
  const {
    isAuthenticated
  } = useAuth();
  const token = isAuthenticated ? allToken : skipToken;
  const {
    data: {
      entities: investorEntities = null,
      individualEntityLimitReached
    } = {},
    refetch: refetchEntities
  } = investorEntityApi.useGetInvestorEntityQuery(token);
  const {
    data: investorEntityStatuses,
    refetch: refetchStatus
  } = investorEntityStatusApi.useGetAllInvestorEntityStatusQuery(token);
  const refetch = useCallback(() => {
    refetchEntities();
    refetchStatus();
  }, [refetchEntities, refetchStatus]);
  return useMemo(() => {
    const result = investorEntities && investorEntityStatuses && {
      investorEntities,
      investorEntityStatuses
    };
    const isLoading = !result;
    return _objectSpread(_objectSpread({
      isLoading
    }, result), {}, {
      refetch,
      individualEntityLimitReached
    });
  }, [individualEntityLimitReached, investorEntities, investorEntityStatuses, refetch]);
};