import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["className", "onChange", "value", "mimicPassword"];
var __jsx = React.createElement;
import React, { useState } from 'react';
import { TextInput } from './TextInput';
import { useMimicPassword } from 'react-mimic-password-hook';
import { withFormikWeb } from '../common';
import { ShowHideToggle } from './helpers';
export const PasswordTextInput = _ref => {
  let {
      className,
      onChange,
      value,
      mimicPassword
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  const {
    0: showPassword,
    1: setShowPassword
  } = useState(false);
  const [mimicValue, mimicPresentation, onPasswordChange] = useMimicPassword({
    mode: 'persymbol',
    handleChange: onChange
  });
  if (mimicPassword) {
    return __jsx(TextInput, _extends({}, props, {
      className: `fs-mask ${className}`,
      type: "text",
      onChange: (_value, e) => onPasswordChange(e),
      value: showPassword ? mimicValue : mimicPresentation,
      suffix: __jsx(ShowHideToggle, {
        name: "password",
        show: showPassword,
        onChange: setShowPassword
      })
    }));
  }
  return __jsx(TextInput, _extends({}, props, {
    className: `fs-mask ${className}`,
    type: showPassword ? 'text' : 'password',
    onChange: e => onChange && onChange(e),
    value: value,
    suffix: __jsx(ShowHideToggle, {
      name: "password",
      show: showPassword,
      onChange: setShowPassword
    })
  }));
};
export const FormPasswordTextInput = withFormikWeb(PasswordTextInput);