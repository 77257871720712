import { PortfolioStrategyValues } from '@yieldstreet/platform-kit';
import { useParams } from 'react-router-dom';
export const MANAGED = PortfolioStrategyValues.Discretionary;
export const SELF_DIRECTED = PortfolioStrategyValues.Self;
export const CREATE_ACCOUNT_PATH = '/create-investor-account';
export const ACCOUNT_TYPE = 'accountType';
export const ID_PARAM = 'investorEntityId';
export const STEP = 'step';
export const NEW_ACCOUNT_STEP = 'new';
export const MANAGEMENT_STRATEGY = 'managementStrategy';
export const CREATE_ACCOUNT_ROOT_PATH = `${CREATE_ACCOUNT_PATH}/:${ACCOUNT_TYPE}?/:${MANAGEMENT_STRATEGY}?/:${ID_PARAM}?/:${STEP}?`;
export const NAME_STEP = `name`;
export const ACCOUNT_CREATION_BLOCKED_STEP = `account-creation-blocked`;
export const ADDRESS_STEP = `address`;
export const PHONE_STEP = `phone`;
export const DETAILS_STEP = `details`;
export const CITIZENSHIP_STEP = 'citizenship';
export const TAX_RESIDENCE_STEP = 'tax-residence';
export const EMPLOYMENT_INFORMATION_STEP = 'employment-information';
export const ATOMIC_FORK_STEP = 'atomic-fork';
export const POLITICALLY_EXPOSED_PERSON_STEP = 'politically-exposed-person';
export const FINRA_AFFILIATED_STEP = 'finra-affiliated';
export const CONTROL_CORPORATIONS_STEP = 'control-corporations';
export const EXCHANGES_STEP = 'exchanges';
export const INELIGIBLE_STEP = 'ineligible-account';
export const YS_ACCOUNT_CREATION_BLOCKED = [{
  stepKey: NAME_STEP
}, {
  stepKey: PHONE_STEP
}, {
  stepKey: ACCOUNT_CREATION_BLOCKED_STEP
}];
export const YS_FLOW_SELF = [{
  stepKey: NAME_STEP
}, {
  stepKey: ADDRESS_STEP
}, {
  stepKey: PHONE_STEP
}, {
  stepKey: DETAILS_STEP
}];
export const YS_FLOW_MANAGED = [...YS_FLOW_SELF, {
  stepKey: CITIZENSHIP_STEP
}, {
  stepKey: TAX_RESIDENCE_STEP
}, {
  stepKey: EMPLOYMENT_INFORMATION_STEP
}, {
  stepKey: ATOMIC_FORK_STEP
}];
export const YS_FLOW_ATOMIC = [{
  stepKey: POLITICALLY_EXPOSED_PERSON_STEP
}, {
  stepKey: FINRA_AFFILIATED_STEP
}, {
  stepKey: CONTROL_CORPORATIONS_STEP
}, {
  stepKey: EXCHANGES_STEP
}];
export const useOnUrlChange = investorEntityId => {
  const params = useParams();
  const onUrlChange = changeTo => `${CREATE_ACCOUNT_PATH}/${params[ACCOUNT_TYPE]}/${params[MANAGEMENT_STRATEGY]}/${investorEntityId || params[ID_PARAM]}/${changeTo}`;
  return onUrlChange;
};
export const PRE_INVESTMENT_STEPS = ['Verify identity', 'Complete profile', 'Set goals', 'Activate portfolio'];