var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { Heading, Label } from '../../../content/text';
import { ButtonContainer } from '../../../content/button/ButtonContainer';
import { media } from '../../../themes/media';
export const InfoCard = props => {
  const {
    title,
    subtitle,
    image,
    children,
    cta
  } = props;
  return __jsx(OuterContainer, null, __jsx(TopContainer, null, __jsx(ImageHolder, null, image && __jsx(ImageWrapper, null, image)), __jsx(ContentHolder, null, __jsx(TitleHolder, null, title && __jsx(Headline, {
    type: 4
  }, title), subtitle ? __jsx(SLabel, null, subtitle) : ''))), __jsx(ContentHolder, null, __jsx(TextHolder, null, children, cta && __jsx(CTAHolder, null, cta))));
};
const OuterContainer = styled.div.withConfig({
  displayName: "InfoCard__OuterContainer",
  componentId: "sc-1bhz2xk-0"
})(["background:", ";box-shadow:", ";padding:40px;flex:none;flex:1 0;text-align:left;margin:0 0 20px 0;max-width:475px;", ""], props => props.theme.colors.background_card, props => props.theme.shadows.shadow_default, media.desktop`
    margin: 0 0 0 20px;
  `);
const TopContainer = styled.div.withConfig({
  displayName: "InfoCard__TopContainer",
  componentId: "sc-1bhz2xk-1"
})(["flex-direction:column;display:flex;"]);
const TitleHolder = styled.div.withConfig({
  displayName: "InfoCard__TitleHolder",
  componentId: "sc-1bhz2xk-2"
})([""]);
const Headline = styled(Heading).withConfig({
  displayName: "InfoCard__Headline",
  componentId: "sc-1bhz2xk-3"
})(["padding:0;margin-bottom:10px;"]);
const ImageHolder = styled.div.withConfig({
  displayName: "InfoCard__ImageHolder",
  componentId: "sc-1bhz2xk-4"
})(["width:80px;img{width:100%;}margin:20px auto 40px auto;"]);
const ContentHolder = styled.div.withConfig({
  displayName: "InfoCard__ContentHolder",
  componentId: "sc-1bhz2xk-5"
})(["display:flex;flex:1;flex-direction:column;justify-content:space-between;"]);
const CTAHolder = styled(ButtonContainer).withConfig({
  displayName: "InfoCard__CTAHolder",
  componentId: "sc-1bhz2xk-6"
})([""]);
const ImageWrapper = styled.div.withConfig({
  displayName: "InfoCard__ImageWrapper",
  componentId: "sc-1bhz2xk-7"
})([""]);
const SLabel = styled(Label).withConfig({
  displayName: "InfoCard__SLabel",
  componentId: "sc-1bhz2xk-8"
})(["margin-bottom:10px;display:inline-block;padding:5px;background:", ";"], props => props.theme.colors.background_page);
const TextHolder = styled.div.withConfig({
  displayName: "InfoCard__TextHolder",
  componentId: "sc-1bhz2xk-9"
})(["margin-bottom:20px;ul{li{padding:20px 0;border-top:1px solid ", ";&:last-child{border-bottom:1px solid ", ";}}}p{&:not(:last-child){padding:0 0 10px 0;}}"], props => props.theme.colors.border_default, props => props.theme.colors.border_default);