import React from "react";
var __jsx = React.createElement;
import { useMemo } from 'react';
import find from "lodash/find";
import { useHistory } from 'react-router-dom';
import { SectionWrapper } from 'sharedComponents/PageLayout';
import { InvestorAccountContainer } from './InvestorAccounts';
import { PortfolioHeaderWrapper } from './PortfolioHeader.style';
import { PortfolioHeaderNav } from './PortfolioHeaderNav';
import { portfolioNavLinks } from './PortfolioHeaderNav/PortfolioHeaderNav.util';
export const PortfolioHeader = _ref => {
  var _history$location2;
  let {
    userId,
    hasInvestorAccounts,
    investorId,
    isDesktop,
    managementStrategy,
    managementStrategyRouteParam,
    onChange
  } = _ref;
  const history = useHistory();
  const isExactRoute = useMemo(() => find(portfolioNavLinks(investorId, managementStrategyRouteParam), item => {
    var _history$location;
    return (item === null || item === void 0 ? void 0 : item.route) === (history === null || history === void 0 ? void 0 : (_history$location = history.location) === null || _history$location === void 0 ? void 0 : _history$location.pathname);
  }), [history === null || history === void 0 ? void 0 : (_history$location2 = history.location) === null || _history$location2 === void 0 ? void 0 : _history$location2.pathname, investorId, managementStrategyRouteParam]);
  if (!isExactRoute || !hasInvestorAccounts) {
    return null;
  }
  if (isDesktop) {
    return __jsx(PortfolioHeaderWrapper, null, __jsx(InvestorAccountContainer, {
      userId: userId,
      investorId: investorId,
      onChange: onChange,
      managementStrategy: managementStrategy
    }), __jsx(PortfolioHeaderNav, null));
  }
  return __jsx(React.Fragment, null, __jsx(SectionWrapper, null, __jsx(InvestorAccountContainer, {
    userId: userId,
    investorId: investorId,
    onChange: onChange,
    managementStrategy: managementStrategy
  })), __jsx(PortfolioHeaderNav, null));
};