import styled from 'styled-components';
import { ParagraphSmall } from '@yieldstreet/ui-kit';
export const CheckboxSecondaryWrapper = styled.div.withConfig({
  displayName: "Detailsstyle__CheckboxSecondaryWrapper",
  componentId: "sc-p5ejgt-0"
})(["margin-top:40px;text-align:left;"]);
export const InputSecondaryTextWrapper = styled.div.withConfig({
  displayName: "Detailsstyle__InputSecondaryTextWrapper",
  componentId: "sc-p5ejgt-1"
})(["display:flex;flex-direction:column;margin:auto;max-width:100%;"]);
export const CondensedParagraph = styled.div.withConfig({
  displayName: "Detailsstyle__CondensedParagraph",
  componentId: "sc-p5ejgt-2"
})(["", ""], ParagraphSmall);
export const CheckboxFlexWrapper = styled.div.withConfig({
  displayName: "Detailsstyle__CheckboxFlexWrapper",
  componentId: "sc-p5ejgt-3"
})(["display:flex;", ""], props => props.hidden && `flex-direction: column;`);
export const CheckboxText = styled.span.withConfig({
  displayName: "Detailsstyle__CheckboxText",
  componentId: "sc-p5ejgt-4"
})(["", " padding-top:20px;"], ParagraphSmall);
export const ParagraphListWrapper = styled.div.withConfig({
  displayName: "Detailsstyle__ParagraphListWrapper",
  componentId: "sc-p5ejgt-5"
})(["padding-left:20px;"]);
export const ParagraphListPadding = styled.div.withConfig({
  displayName: "Detailsstyle__ParagraphListPadding",
  componentId: "sc-p5ejgt-6"
})(["padding-bottom:20px;"]);