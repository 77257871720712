import numeral from 'numeral';
export const formatThousandShortNotation = function (value) {
  let symbol = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '$';
  if (!value) {
    return '';
  }
  if (value < 1000) return `${symbol}${value}`;
  if (value >= 1000) return `${symbol}${+(value / 1000).toFixed(1) + 'K'}`;
  return `${value}`;
};
export const formatCurrencyWithoutDecimal = function (value) {
  let symbol = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '$';
  if (!value) {
    return '';
  }
  return `${symbol}${Math.round(value)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export const formatCurrency = value => numeral(value).format('$0,0[.][0000]');