var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { Paragraph } from '../../content';
export const ListItem = _ref => {
  let {
    label,
    value,
    borderBottom
  } = _ref;
  return __jsx(ListItemContainer, {
    borderBottom: borderBottom
  }, __jsx(Paragraph, null, label), __jsx(Paragraph, {
    semiBold: true
  }, value));
};
const ListItemContainer = styled.div.withConfig({
  displayName: "ListItem__ListItemContainer",
  componentId: "sc-ll1mld-0"
})(["", " display:flex;width:100%;flex:1;align-items:stretch;justify-content:space-between;padding:4px 0;"], props => props.borderBottom && `border-bottom: solid 1px ${props.theme.colors.border_default};`);