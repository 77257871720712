import styled from 'styled-components';
export const Detail = styled.dd.withConfig({
  displayName: "TieredPricingItemstyle__Detail",
  componentId: "sc-9rt0w2-0"
})(["font-weight:bold;text-align:", ";"], props => props.center ? 'center' : 'left');
export const List = styled.dl.withConfig({
  displayName: "TieredPricingItemstyle__List",
  componentId: "sc-9rt0w2-1"
})(["display:flex;flex-direction:column;gap:4px;"]);
export const Title = styled.dt.withConfig({
  displayName: "TieredPricingItemstyle__Title",
  componentId: "sc-9rt0w2-2"
})(["font-weight:regular;color:", ";"], props => props.theme.colors.text_label_inverse);
export const Container = styled.article.withConfig({
  displayName: "TieredPricingItemstyle__Container",
  componentId: "sc-9rt0w2-3"
})(["padding:1em;display:flex;flex-direction:row;justify-content:space-between;width:100%;border-radius:6px;font-size:12px;line-height:14px;background:", " box-shadow:", ";opacity:", ";", "{color:", ";}"], props => {
  switch (props.type) {
    case 'active':
      return 'linear-gradient(90deg, #37393B 0%, #555A5E 51.72%, #3C3E40 98.37%);';
    case 'inactive':
      return 'none';
    default:
      return 'linear-gradient(90deg, #3E4040 0.93%, rgba(62, 62, 64, 0) 105.3%);';
  }
}, props => {
  switch (props.type) {
    case 'active':
      return `0 0 0 1px  ${props.theme.colors.text_header_inverse}`;
    case 'inactive':
      return 'none';
    default:
      return `0 0 0 1px #5E6268`;
  }
}, props => props.type === 'inactive' ? 0.5 : 1, Detail, props => {
  switch (props.type) {
    case 'idle':
      return props.theme.colors.text_label_inverse;
    default:
      return 'inherit';
  }
});