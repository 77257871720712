import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { Form, Formik } from 'formik';
import { Button, FormTextInput, Heading, NewModal, useModalContext } from '@yieldstreet/ui-kit';
import { bankAccountApi } from '@yieldstreet/platform-kit';
import { EDIT_BANK_NAME } from 'constants/modal.const';
import { ButtonWrapper, Content, TextInputWrapper, Wrapper } from './EditBankNicknameModel.style';
import { testIds, validationSchema } from './EditBankNicknameModel.model';
const {
  useUpdateBankNicknameMutation
} = bankAccountApi;
export const EditBankNicknameModal = () => {
  const {
    hideModal,
    passProps
  } = useModalContext();
  // ModelContext wont be typed for now
  const {
    bankAccount
  } = passProps;
  const [updateBankNickname, {
    isLoading
  }] = useUpdateBankNicknameMutation();
  const onSubmit = async _ref => {
    var _update$data;
    let {
      nickname
    } = _ref;
    const update = await updateBankNickname({
      bankAccount,
      nickname
    });
    if (!('error' in update) && (_update$data = update.data) !== null && _update$data !== void 0 && _update$data.success) {
      hideModal();
    }
  };
  return __jsx(NewModal, {
    modalId: EDIT_BANK_NAME
  }, __jsx(Wrapper, null, __jsx(Content, null, __jsx(Heading, {
    type: 3
  }, "Edit external account name"), __jsx(Formik, {
    initialValues: {
      nickname: bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.nickname
    },
    validationSchema: validationSchema,
    onSubmit: onSubmit
  }, formikProps => {
    return __jsx(Form, null, __jsx(TextInputWrapper, null, __jsx(FormTextInput, {
      type: "text",
      name: "nickname",
      label: "External account name",
      formikProps: formikProps,
      testId: testIds.textInput.id,
      fullWidth: true
    })), __jsx(ButtonWrapper, null, __jsx(Button, _extends({}, testIds.cancelButton.attr, {
      mobileLimit: true,
      buttonType: "secondary",
      onClick: () => hideModal(),
      className: "cancelButton"
    }), "Cancel"), __jsx(Button, _extends({}, testIds.confirmButton.attr, {
      mobileLimit: true,
      disabled: isLoading,
      loading: isLoading,
      type: "submit"
    }), "Save")));
  }))));
};