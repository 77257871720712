import { FULL_TRACKER_CONSENT, ZERO_TRACKER_CONSENT } from './TrustArcConsent.model';
export const getConsentState = () => {
  const result = getConsentDecision();
  if (result) {
    // TrustArc returns [0] when the user has not made a decision
    // irrespective of the implied consent for that location

    // The following is logic to normalize the output to a consent state
    // irrespective of whether the user has made an explicit decision or not

    // Check if the user has made an explicit consent decision
    if (isConsentState(result.consentDecision)) {
      // If so, return the decision
      return result.consentDecision;
    }

    // If no explicit decision has been made, fall back to the default behaviour as per location
    if (getConsentBehaviour() !== 'eu') {
      // If the default behaviour is not EU (i.e. implied consent), return full tracker consent
      return FULL_TRACKER_CONSENT;
    }
  }

  // If no decision has been made and the default behaviour is EU, return zero tracker consent
  return ZERO_TRACKER_CONSENT;
};
export const getConsentDecision = () => {
  var _window$truste, _window$truste$cma;
  return (_window$truste = window.truste) === null || _window$truste === void 0 ? void 0 : (_window$truste$cma = _window$truste.cma) === null || _window$truste$cma === void 0 ? void 0 : _window$truste$cma.callApi('getGDPRConsentDecision', 'yieldstreet.com');
};
export const getConsentCountry = () => {
  var _window$truste2, _window$truste2$eu, _window$truste2$eu$bi;
  return (_window$truste2 = window.truste) === null || _window$truste2 === void 0 ? void 0 : (_window$truste2$eu = _window$truste2.eu) === null || _window$truste2$eu === void 0 ? void 0 : (_window$truste2$eu$bi = _window$truste2$eu.bindMap) === null || _window$truste2$eu$bi === void 0 ? void 0 : _window$truste2$eu$bi.country;
};
export const getConsentBehaviour = () => {
  var _window$truste3, _window$truste3$eu, _window$truste3$eu$bi;
  return (_window$truste3 = window.truste) === null || _window$truste3 === void 0 ? void 0 : (_window$truste3$eu = _window$truste3.eu) === null || _window$truste3$eu === void 0 ? void 0 : (_window$truste3$eu$bi = _window$truste3$eu.bindMap) === null || _window$truste3$eu$bi === void 0 ? void 0 : _window$truste3$eu$bi.behaviorManager;
};
export const isConsentState = consentDecision => !consentDecision.includes(0);
export const isConsentCategoryAllowed = function (consentCategory) {
  let consentState = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : getConsentState();
  return consentState && consentState.includes(consentCategory);
};
export const launchConsentPreferences = () => {
  var _window$truste4, _window$truste4$eu, _window$truste4$eu$cl;
  (_window$truste4 = window.truste) === null || _window$truste4 === void 0 ? void 0 : (_window$truste4$eu = _window$truste4.eu) === null || _window$truste4$eu === void 0 ? void 0 : (_window$truste4$eu$cl = _window$truste4$eu.clickListener) === null || _window$truste4$eu$cl === void 0 ? void 0 : _window$truste4$eu$cl.call(_window$truste4$eu);
};
export const addTrustArcConsentListener = onConsent => {
  const onConsentHandler = () => onConsent(getConsentState());
  document.body.addEventListener('privacyApiListener', onConsentHandler);
  document.body.addEventListener('truste-consent-gtm', onConsentHandler);
};