import { DistributionOption } from '../../../../enums/investment/distribution-option';
export const portfolioSummaryFunds = [{
  investorAccountId: '63856',
  noteUrlHash: 'gDLMYw',
  fundCode: 'gDLMYw',
  fundConfig: {
    capitalBalanceReductionType: null,
    balanceCalculationType: null,
    fees: {},
    feesBalanceProviders: {}
  },
  fundId: '02aa4710-c287-11ec-bba8-619a2280f6da',
  total: 0,
  nav: 10,
  navDate: '2022-08-22',
  sharesCount: 0,
  canToggleDistributionOption: false,
  dividendDistributionOption: DistributionOption.Cash,
  commitmentBreakdown: {
    // capital call
    funded: 0,
    unfunded: 0,
    total: 0
  },
  commitmentDetails: []
}, {
  investorAccountId: '63856',
  noteUrlHash: '5xyutg',
  fundCode: '5xyutg',
  fundConfig: {
    balanceCalculationType: null,
    capitalBalanceReductionType: null,
    fees: {},
    feesBalanceProviders: {}
  },
  fundId: '812439d0-8375-11ec-a94b-a50a4d6186a3',
  total: 0,
  nav: 10,
  navDate: '2022-08-22',
  sharesCount: 0,
  canToggleDistributionOption: false,
  dividendDistributionOption: DistributionOption.ReInvest,
  commitmentBreakdown: null,
  commitmentDetails: []
}, {
  investorAccountId: '63856',
  noteUrlHash: 'jWfUFw',
  fundCode: 'jWfUFw',
  fundConfig: {
    capitalBalanceReductionType: null,
    balanceCalculationType: null,
    fees: {},
    feesBalanceProviders: {}
  },
  fundId: '908897b0-ee4f-11eb-a129-b9e6d6d47454',
  total: 20000,
  nav: 10,
  navDate: '2022-08-22',
  sharesCount: 2000,
  canToggleDistributionOption: false,
  dividendDistributionOption: DistributionOption.Cash,
  commitmentBreakdown: null,
  commitmentDetails: []
}];