import * as Yup from 'yup';
import dayjs from 'dayjs';
import get from 'lodash/get';
import { formRules } from './formRules';
const defaultDateFormat = 'MM/DD/YYYY';
const dateFn = function (format) {
  let parseStrict = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  return this.transform((value, originalValue) => {
    value = dayjs(originalValue, format, parseStrict);
    return value.isValid() ? value.toDate() : new Date('');
  });
};
Yup.addMethod(Yup.date, 'format', dateFn);
const conditionalRequiredString = isRequired => isRequired ? Yup.string().required(defaultRequiredText) : Yup.string().notRequired();
const {
  rules,
  defaultRequiredText,
  digitOnlyText,
  getAmountLimitText,
  getExactCharText,
  getExactDigitText,
  getMinAmountText,
  getMinCharText,
  getMaxCharText,
  getMinDigitText,
  getMaxDigitText,
  stringOnlyText
} = formRules;
const {
  accountNumber,
  address,
  amount,
  amount1T,
  bankRoutingNumber,
  country,
  crdDocument,
  dateEntity,
  dateOfRecord,
  idDocument,
  entityType,
  extendedName,
  initials,
  name,
  password,
  phone,
  state,
  textArea,
  zipCode
} = rules;
export const accountNumberValidation = () => {
  const {
    max
  } = accountNumber;
  return Yup.string().required(defaultRequiredText).max(max, getMaxDigitText(max)).matches(/^\d+$/, {
    excludeEmptyString: true,
    message: digitOnlyText
  });
};
export const addressValidation = _ref => {
  let {
    isRequired
  } = _ref;
  const {
    max
  } = address;
  return Yup.string().concat(conditionalRequiredString(isRequired)).max(max, getMaxCharText(max));
};
export const amountValidation = _ref2 => {
  let {
    fieldName
  } = _ref2;
  const {
    min,
    validation
  } = amount;
  return Yup.string().test(fieldName, getMinAmountText(min), validation);
};
export const amount1TValidation = _ref3 => {
  let {
    fieldName,
    isRequired
  } = _ref3;
  const {
    max,
    validation
  } = amount1T;
  return Yup.string().concat(conditionalRequiredString(isRequired)).test(fieldName, getAmountLimitText(max), validation);
};
export const bankRoutingNumberValidation = () => {
  const {
    length
  } = bankRoutingNumber;
  return Yup.string().required(defaultRequiredText).length(length, getExactDigitText(length)).matches(/^\d+$/, {
    excludeEmptyString: true,
    message: digitOnlyText
  });
};
export const countryValidation = _ref4 => {
  let {
    fieldName,
    isRequired
  } = _ref4;
  const {
    length,
    validation
  } = country;
  return Yup.string().concat(conditionalRequiredString(isRequired)).length(length, getExactCharText(length)).test(fieldName, 'Country must be US.', val => {
    if (val) {
      return validation(val);
    }
    return !val && !isRequired;
  });
};
export const crdDocumentValidation = () => {
  const {
    min,
    max
  } = crdDocument;
  return Yup.string().required(defaultRequiredText).min(min, getMinDigitText(min)).max(max, getMaxDigitText(max));
};
export const dateEntityValidation = () => {
  const {
    min,
    max
  } = dateEntity;
  return Yup.date().required(defaultRequiredText)
  // @ts-ignore
  .format(defaultDateFormat).max(dayjs().add(max, 'day'), 'Date cannot be in the future.').min(dayjs().subtract(min, 'year'), `Cannot be established more than ${min} years ago.`).typeError('Please fill a valid date.err');
};
export const dateOfRecordValidation = () => {
  const {
    min,
    max
  } = dateOfRecord;
  return Yup.date().required(defaultRequiredText)
  // @ts-ignore
  .format(defaultDateFormat).min(dayjs().subtract(min, 'year'), `This field must be ${min} years or lower.`).max(dayjs().subtract(max, 'year'), `This field must be at least ${max} years old.`).typeError('Please fill a valid date.');
};
export const documentValidation = () => {
  const {
    length
  } = idDocument;
  return Yup.string().required(defaultRequiredText).length(length, getExactDigitText(length));
};
export const emailValidation = () => Yup.string().required(defaultRequiredText).email('Your email should be valid.');
export const entityTypeValidation = _ref5 => {
  let {
    isRequired
  } = _ref5;
  const {
    min,
    max
  } = entityType;
  return Yup.string().concat(conditionalRequiredString(isRequired)).min(min, getMinCharText(min)).max(max, getMaxCharText(max));
};
export const extendedNameValidation = () => {
  const {
    min,
    max
  } = extendedName;
  return Yup.string().required(defaultRequiredText).min(min, getMinCharText(min)).max(max, getMaxCharText(max));
};
export const initialsValidation = () => {
  const {
    min,
    max
  } = initials;
  return Yup.string().required(defaultRequiredText).min(min, getMinCharText(min)).max(max, getMaxCharText(max));
};
export const nameValidation = _ref6 => {
  let {
    fieldName,
    isRequired
  } = _ref6;
  const {
    min,
    max,
    validation
  } = name;
  return Yup.string().concat(conditionalRequiredString(isRequired)).min(min, getMinCharText(min)).max(max, getMaxCharText(max)).test(fieldName, stringOnlyText, validation);
};
export const passwordValidation = () => {
  const {
    min
  } = password;
  return Yup.string().required(defaultRequiredText).min(min, getMinCharText(min)).matches(/[a-z]/, 'This field must have a lowercase letter.').matches(/[A-Z]/, 'This field must have an uppercase letter.');
};
export const phoneValidation = () => {
  const {
    length,
    validation
  } = phone;
  return Yup.string().required(defaultRequiredText).test('phone', getExactDigitText(length), validation);
};
export const stateValidation = _ref7 => {
  let {
    isRequired
  } = _ref7;
  const {
    length
  } = state;
  return Yup.string().length(length, getExactCharText(length)).concat(conditionalRequiredString(isRequired));
};
export const textAreaValidation = _ref8 => {
  let {
    isRequired
  } = _ref8;
  const {
    min,
    max
  } = textArea;
  return Yup.string().concat(conditionalRequiredString(isRequired)).min(min, getMinCharText(min)).max(max, getMaxCharText(max));
};
export const zipCodeValidation = _ref9 => {
  let {
    isRequired
  } = _ref9;
  const {
    min,
    max
  } = zipCode;
  return Yup.string().min(min, getMinCharText(min)).max(max, getMaxCharText(max)).concat(conditionalRequiredString(isRequired));
};
export const defaultStringValidation = _ref10 => {
  let {
    isRequired
  } = _ref10;
  return Yup.string().concat(conditionalRequiredString(isRequired));
};
export const defaultNumberValidation = _ref11 => {
  let {
    isRequired
  } = _ref11;
  return Yup.number().concat(isRequired ? Yup.number().required(defaultRequiredText) : Yup.number().notRequired().nullable());
};
export const defaultObjectValidation = _ref12 => {
  let {
    isRequired
  } = _ref12;
  return Yup.object().concat(isRequired ? Yup.object().required(defaultRequiredText) : Yup.object().notRequired().nullable());
};
export const defaultBooleanValidation = _ref13 => {
  let {
    isRequired
  } = _ref13;
  return Yup.boolean().concat(isRequired ? Yup.boolean().required(defaultRequiredText).oneOf([true], defaultRequiredText) // without oneOf false will be accepted
  : Yup.boolean().notRequired().nullable());
};
export const defaultArrayValidation = _ref14 => {
  let {
    isRequired
  } = _ref14;
  return Yup.array().concat(isRequired ? Yup.array().required(defaultRequiredText) : Yup.array().notRequired().nullable());
};
export const defaultDateValidation = () => Yup.date().required(defaultRequiredText)
// @ts-ignore
.format(defaultDateFormat).typeError('Please fill a valid date.');
const validateField = _ref15 => {
  let {
    fieldName,
    fieldConfig
  } = _ref15;
  // checking if the fieldConfig is a custom Yup validation

  if (fieldConfig && fieldConfig.__isYupSchema__) {
    return fieldConfig;
  }
  const validationType = get(fieldConfig, 'validationType', '');
  const isRequired = get(fieldConfig, 'isRequired', true);
  switch (validationType) {
    case 'phone':
      return phoneValidation();
    case 'address':
      return addressValidation({
        isRequired
      });
    case 'state':
      return stateValidation({
        isRequired
      });
    case 'zip-code':
      return zipCodeValidation({
        isRequired
      });
    case 'email':
      return emailValidation();
    case 'name':
      return nameValidation({
        fieldName,
        isRequired
      });
    case 'extended-name':
      return extendedNameValidation();
    case 'initials':
      return initialsValidation();
    case 'amount':
      return amountValidation({
        fieldName
      });
    case 'amount-1T':
      return amount1TValidation({
        fieldName,
        isRequired
      });
    case 'document':
      return documentValidation();
    case 'crd-document':
      return crdDocumentValidation();
    case 'country':
      return countryValidation({
        fieldName,
        isRequired
      });
    case 'bank-routing-number':
      return bankRoutingNumberValidation();
    case 'entity-type':
      return entityTypeValidation({
        isRequired
      });
    case 'text-area':
      return textAreaValidation({
        isRequired
      });
    case 'password':
      return passwordValidation();
    case 'account-number':
      return accountNumberValidation();
    case 'date-of-record':
      return dateOfRecordValidation();
    case 'date-entity':
      return dateEntityValidation();
    case 'default-object':
      return defaultObjectValidation({
        isRequired
      });
    case 'default-boolean':
      return defaultBooleanValidation({
        isRequired
      });
    case 'default-number':
      return defaultNumberValidation({
        isRequired
      });
    case 'default-array':
      return defaultArrayValidation({
        isRequired
      });
    case 'default-string':
    default:
      return defaultStringValidation({
        isRequired
      });
  }
};
const buildFieldValidation = configObj => {
  let schema = {};
  Object.keys(configObj).map(fieldName => schema[fieldName] = validateField({
    fieldName,
    fieldConfig: configObj[fieldName]
  }));
  return schema;
};
export const createYupObjectSchema = configObj => Yup.object().shape(buildFieldValidation(configObj));