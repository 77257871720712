import { PortfolioStrategyValues } from '@yieldstreet/platform-kit';
import { getInvestorEntity } from '../hooks';
import { getEligibilityGateContent } from './getEligibilityGateContent';
export const mapGatesToEntities = (gates, investorEntities, investorEntityStatuses) => {
  var _gates$investorEntity;
  const investorEntityId = gates === null || gates === void 0 ? void 0 : (_gates$investorEntity = gates.investorEntity) === null || _gates$investorEntity === void 0 ? void 0 : _gates$investorEntity.investorEntityId;
  const checkGates = investorEntityId && investorEntities && investorEntityStatuses;
  return checkGates ? getInvestorEntity(investorEntityId, investorEntities, investorEntityStatuses) : null;
};
export const mapInvestorEntityGatesToEligibilityGate = function (gates, entity) {
  let managementStrategy = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : PortfolioStrategyValues.Discretionary;
  const checkGates = (entity === null || entity === void 0 ? void 0 : entity.investorEntity) && (entity === null || entity === void 0 ? void 0 : entity.investorEntityStatus) && gates;
  return checkGates ? getEligibilityGateContent(managementStrategy, gates, entity.investorEntity, entity.investorEntityStatus) : null;
};
export const getInvestorEntityGates = function (gates, investorEntities, investorEntityStatuses) {
  let managementStrategy = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : PortfolioStrategyValues.Discretionary;
  let entity = mapGatesToEntities(gates, investorEntities, investorEntityStatuses);
  return mapInvestorEntityGatesToEligibilityGate(gates, entity, managementStrategy);
};