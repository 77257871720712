var __jsx = React.createElement;
import React, { useContext } from 'react';
import { SegmentConsentManager } from './vendors/segment';
import { TrustArcConsentManager } from './vendors';
import { FlagsContext, useFlags } from '@yieldstreet/tool-kit';
export const ConsentManager = props => {
  const {
    isLoading
  } = useContext(FlagsContext);
  const {
    newConsentManager
  } = useFlags({
    newConsentManager: false
  });
  if (isLoading) {
    return null;
  }
  return newConsentManager ? __jsx(TrustArcConsentManager, props) : __jsx(SegmentConsentManager, props);
};