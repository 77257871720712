var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { media } from '../../themes/media';
import { TextBlock } from '../../sections/text-block-component';
import { theme2 } from '../../themes';
import { SectionContainer } from '../../layout';
const getBgType = background => {
  switch (background) {
    case 'primary':
      return theme2.gradients.radial_primary;
    case 'secondary':
      return theme2.gradients.radial_secondary;
    default:
      return 'transparent';
  }
};
export const MarketingReengagementBanner = _ref => {
  let {
    inverse,
    background,
    label,
    heading,
    content,
    mobileAlign = 'center',
    button,
    largeWidth
  } = _ref;
  return __jsx(SectionContainer, null, __jsx(Background, {
    inverse: inverse,
    background: background
  }, __jsx(TextBlockContainer, {
    largeWidth: largeWidth
  }, __jsx(TextBlock, {
    label: label,
    heading: heading,
    content: content,
    align: "center",
    mobileAlign: mobileAlign,
    inverse: !inverse,
    button: button
  }))));
};
const Background = styled.div.withConfig({
  displayName: "MarketingReengagementBanner__Background",
  componentId: "sc-19rgmsk-0"
})(["display:flex;flex-direction:column;padding:0px 24px 0 24px;background:", ";border-radius:8px;", ""], props => props.inverse ? props.theme.colors.background_light : getBgType(props.background), media.desktop`
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0px;
  `);
const TextBlockContainer = styled.div.withConfig({
  displayName: "MarketingReengagementBanner__TextBlockContainer",
  componentId: "sc-19rgmsk-1"
})(["display:flex;justify-content:center;width:100%;margin:48px 0px 48px 0px;", ""], media.desktop`
    width: ${props => props.largeWidth ? '720px' : '490px'};
  `);