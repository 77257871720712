import isFunction from "lodash/isFunction";
import { isBrowser } from 'utils/env';
const asyncScrollTo = function (elementId) {
  let behavior = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'smooth';
  let offset = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  let offsetSize = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 100;
  let position = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 'center';
  if (!elementId || !isBrowser) {
    return;
  }
  const el = document.getElementById(elementId);
  if (offset && el && isFunction(window.scroll)) {
    return window.scroll({
      left: 0,
      top: el.offsetTop - offsetSize,
      behavior
    });
  }
  if (el && isFunction(el.scrollIntoView)) {
    el.scrollIntoView({
      behavior,
      block: position
    });
  }
};
export const scrollToQuery = function (query) {
  let position = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'start';
  if (!query || !isBrowser) {
    return;
  }
  let tries = 0;
  const asyncIntervalScroll = () => {
    const queryEl = document.querySelector(query);
    if (!!queryEl) {
      if (isFunction(queryEl.scrollIntoView)) {
        queryEl.scrollIntoView({
          behavior: 'smooth',
          block: position
        });
      }
      clearInterval(waitForElement);
    }
    if (tries >= 5) {
      clearInterval(waitForElement);
    }
    tries++;
  };
  const waitForElement = setInterval(asyncIntervalScroll, 100);
};
export default asyncScrollTo;