import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { Tr, TBody } from 'pages/portfolio/common/Table';
import { Transaction } from 'pages/portfolio/Transactions/TransactionsList/TransactionsListData/TransactionsListDesktop/TransactionsListDesktopTableBody';
import { ReturnPaymentsDesktopTableBodyLoading } from '../ReturnPaymentsDesktopTableBodyLoading';
import { testIds } from './ReturnPaymentsDesktopTableBody.model';
export const ReturnPaymentsDesktopTableBody = _ref => {
  let {
    returnedTransactions,
    isFetching
  } = _ref;
  if (isFetching) {
    return __jsx(ReturnPaymentsDesktopTableBodyLoading, {
      testAttr: testIds.loader.attr
    });
  }
  return __jsx(TBody, testIds.base.attr, returnedTransactions === null || returnedTransactions === void 0 ? void 0 : returnedTransactions.map((transaction, idx) => __jsx(Tr, _extends({
    key: idx,
    id: `${idx}-${transaction.noteUrlHash}`
  }, testIds.row.attr), __jsx(Transaction, {
    transaction: transaction
  }))));
};