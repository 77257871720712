var __jsx = React.createElement;
import React from 'react';
import styled, { css } from 'styled-components';
import { media } from '../../themes/media';
import { OptionDescription } from './optionStyles';
import { H5 } from '../../content/text/Heading';
import arrow from '../../assets/graphics/icons/arrow.svg';
import { FormErrorContainer } from '../../form/common/FormError';
export const withQuestion = (inputComponentClass, questionTheme) => {
  return props => {
    const InputComponent = inputComponentClass;
    return __jsx(QuestionInputWrapper, {
      className: props === null || props === void 0 ? void 0 : props.className,
      questionTheme: questionTheme
    }, __jsx(InputComponent, props));
  };
};
const Chevron = css(["&::after{display:block;animation:fadeIn ease 1s;background:url(", ") no-repeat;transform:rotate(-90deg);content:'';position:absolute;width:11px;height:7px;z-index:1;top:calc(50% - (11 / 2));right:20px;", "}"], arrow, media.desktop`
      right: 30px;
    `);
const Active = css(["&::after{filter:invert(100%);}", ";background-color:", ";div,p{color:", ";}"], media.desktop`
    &::after {
      display: none;
    }
  `, props => props.theme.colors.background_inverse, props => props.theme.colors.text_secondary_paragraph_inverse);
const Disabled = css(["background-color:", ";opacity:0.3;div,p{color:", ";}"], props => props.theme.colors.background_card, props => props.theme.colors.text_header);
const ChevronTheme = css(["div{", "}.MuiFormControlLabel-root{transition:background-color 0.25s linear;position:relative;box-shadow:", ";border-radius:4px;.MuiButtonBase-root{display:none;}}[data-radio='true']{.MuiFormControlLabel-root{", ";", ";&:hover{", ";}&:active{", ";}}}.MuiFormControlLabel-root{transition:0.15s all ease;&:active{", ";}}[data-checked='true'][data-radio='true']{.MuiFormControlLabel-root{", ";", ";}}[data-disabled='true']{.MuiFormControlLabel-root{", ";}}[data-checked='true'][data-checkbox='true']{.MuiFormControlLabel-root{", ";position:relative;padding:10px 30px 10px 40px;&::before{animation:fadeIn ease 1s;top:calc(50% - 7px);left:21px;content:'';position:absolute;transform:rotate(45deg);height:10px;width:5px;border-bottom:2px solid ", ";border-right:2px solid ", ";}", ";}}"], H5, props => props.theme.shadows.shadow_default, Chevron, media.desktop`
      &::after {
        display: none;
      }
  `, Chevron, Chevron, Active, Active, Chevron, Disabled, Active, props => props.theme.colors.text_paragraph_inverse, props => props.theme.colors.text_paragraph_inverse, media.desktop`
        padding: 20px 30px 20px 40px;
      `);
export const PrimaryTheme = css([".MuiButtonBase-root{margin-right:5px;margin-left:-5px;}.MuiFormControlLabel-root{background-color:", ";margin:0;margin-bottom:10px;padding:18px;", ";}"], props => props.theme.colors.background_card, media.phoneLandscape`
      margin-bottom: 20px;
    `);
export const SecondaryTheme = css(["", " .MuiButtonBase-root{margin-right:5px;margin-left:-5px;}.MuiFormControlLabel-root{background-color:", ";margin:0;margin-bottom:10px;padding:10px 30px 10px 10px;", ";}.option-control-label:hover{", "}"], ChevronTheme, props => props.theme.colors.background_card, media.phoneLandscape`
      margin-bottom: 20px;
      padding: 20px 40px 20px 20px;
    `, media.desktop`
      background-color: ${props => props.theme.colors.text_paragraph};
      .option-label,
      p {
        color: ${props => props.theme.colors.text_paragraph_inverse}!important;
      }
      &:after {
        filter: invert(1);
      }
    `);
export const TertiaryTheme = css(["", " .MuiFormControlLabel-root{background-color:", ";margin:0;margin-bottom:10px;padding:10px;}.MuiButtonBase-root{padding:0;margin:0;}width:100%;"], ChevronTheme, props => props.theme.colors.background_card);
export const TagsTheme = css(["", " & ", "{all:unset;}[data-checkbox-group-options='true']{flex-flow:row wrap;gap:16px;}.MuiFormControlLabel-root{padding:8px 16px 8px 16px;margin-left:0;margin-right:0;border:1px solid ", ";background-color:", ";[data-checkbox-label='true']{font-size:14px;line-height:14px;font-weight:700;color:", ";}}[data-checkbox='true']{width:auto;&[data-checked='true'] .MuiFormControlLabel-root{padding:8px 16px 8px 28px;::before{left:10px;}}:not([data-checked='true']):not([data-disabled='true']) .MuiFormControlLabel-root:hover{", "}}"], ChevronTheme, FormErrorContainer, _ref => {
  let {
    theme
  } = _ref;
  return theme.colors.border_strong;
}, _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.colors.background_default;
}, _ref3 => {
  let {
    theme
  } = _ref3;
  return theme.colors.text_label;
}, media.desktop`
          background-color: ${_ref4 => {
  let {
    theme
  } = _ref4;
  return theme.colors.text_secondary_paragraph_inverse;
}};
          border-color: ${_ref5 => {
  let {
    theme
  } = _ref5;
  return theme.colors.border_extra_strong;
}};

          [data-checkbox-label='true'] {
            color: ${_ref6 => {
  let {
    theme
  } = _ref6;
  return theme.colors.text_secondary_paragraph;
}};
          }
        `);
const QuestionInputWrapper = styled.div.withConfig({
  displayName: "withQuestion__QuestionInputWrapper",
  componentId: "sc-1l785c8-0"
})([".MuiFormControl-root{width:100%;padding:0;> .MuiFormLabel-root{margin-bottom:10px;font-size:20px;color:", ";}}", "{margin-top:5px;margin-bottom:0;}", " @keyframes fadeIn{0%{opacity:0;}100%{opacity:1;}}"], props => props.theme.colors.text_paragraph, OptionDescription, props => themeSelector(props.questionTheme));
export const themeSelector = themeName => {
  switch (themeName) {
    case 'tags':
      return TagsTheme;
    case 'tertiary':
      return TertiaryTheme;
    case 'secondary':
      return SecondaryTheme;
    case 'primary':
    default:
      return PrimaryTheme;
  }
};