import styled, { css } from 'styled-components';
export const ButtonDisabledStyles = css(["background-color:", ";border-color:", ";color:", ";text-decoration:none;cursor:not-allowed;"], _ref => {
  let {
    theme
  } = _ref;
  return theme.colors.input_disabled_background;
}, _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.colors.border_default;
}, _ref3 => {
  let {
    theme
  } = _ref3;
  return theme.colors.input_disabled_text;
});
export const ButtonBasicStyles = css(["background-color:", ";border-color:", ";color:", " !important;&:hover{background-color:", ";border-color:", ";}&:active{background-color:", ";border-color:", ";}"], _ref4 => {
  let {
    inverse,
    theme
  } = _ref4;
  return inverse ? theme.colors.input_button_basic_inverse_background : theme.colors.input_button_basic_background;
}, _ref5 => {
  let {
    inverse,
    theme
  } = _ref5;
  return inverse ? theme.colors.border_inverse : theme.colors.border_outline;
}, _ref6 => {
  let {
    inverse,
    theme
  } = _ref6;
  return inverse ? theme.colors.input_button_basic_inverse_text : theme.colors.input_button_basic_text;
}, _ref7 => {
  let {
    inverse,
    theme
  } = _ref7;
  return inverse ? theme.colors.input_button_basic_inverse_background_hover : theme.colors.input_button_basic_background_hover;
}, _ref8 => {
  let {
    inverse,
    theme
  } = _ref8;
  return inverse ? theme.colors.input_button_basic_inverse_background_hover : theme.colors.input_button_basic_background_hover;
}, _ref9 => {
  let {
    inverse,
    theme
  } = _ref9;
  return inverse ? theme.colors.input_button_basic_inverse_background_active : theme.colors.input_button_basic_background_active;
}, _ref10 => {
  let {
    inverse,
    theme
  } = _ref10;
  return inverse ? theme.colors.input_button_basic_inverse_background_active : theme.colors.input_button_basic_background_active;
});
export const ButtonSecondaryStyles = css(["background-color:", ";border-color:", ";color:", " !important;&:hover{background-color:", ";border-color:", ";}&:active{background-color:", ";color:", " !important;}"], _ref11 => {
  let {
    inverse,
    theme
  } = _ref11;
  return inverse ? theme.colors.input_button_secondary_background : theme.colors.input_button_secondary_background;
}, _ref12 => {
  let {
    inverse,
    theme
  } = _ref12;
  return inverse ? theme.colors.border_inverse : theme.colors.border_outline;
}, _ref13 => {
  let {
    inverse,
    theme
  } = _ref13;
  return inverse ? theme.colors.input_button_secondary_inverse_text : theme.colors.input_button_secondary_text;
}, _ref14 => {
  let {
    inverse,
    theme
  } = _ref14;
  return inverse ? theme.colors.input_button_secondary_inverse_background_hover : theme.colors.input_button_secondary_background_hover;
}, _ref15 => {
  let {
    inverse,
    theme
  } = _ref15;
  return inverse ? theme.colors.border_inverse : theme.colors.border_outline;
}, _ref16 => {
  let {
    inverse,
    theme
  } = _ref16;
  return inverse ? theme.colors.input_button_secondary_inverse_background_active : theme.colors.input_button_secondary_background_active;
}, _ref17 => {
  let {
    inverse,
    theme
  } = _ref17;
  return inverse ? theme.colors.input_button_secondary_text : theme.colors.input_button_secondary_inverse_text;
});
export const ButtonPrimaryStyles = css(["background-color:", ";border-color:", ";color:", ";&:hover{background-color:", ";border-color:", ";color:", ";}&:active{background-color:", ";border-color:", ";color:", ";}"], _ref18 => {
  let {
    theme
  } = _ref18;
  return theme.colors.input_button_background;
}, _ref19 => {
  let {
    theme
  } = _ref19;
  return theme.colors.input_button_background;
}, _ref20 => {
  let {
    theme
  } = _ref20;
  return theme.colors.input_button_text;
}, _ref21 => {
  let {
    theme
  } = _ref21;
  return theme.colors.input_button_background_hover;
}, _ref22 => {
  let {
    theme
  } = _ref22;
  return theme.colors.input_button_background_hover;
}, _ref23 => {
  let {
    theme
  } = _ref23;
  return theme.colors.input_button_text;
}, _ref24 => {
  let {
    theme
  } = _ref24;
  return theme.colors.input_button_background_active;
}, _ref25 => {
  let {
    theme
  } = _ref25;
  return theme.colors.input_button_background_active;
}, _ref26 => {
  let {
    theme
  } = _ref26;
  return theme.colors.input_button_text;
});
export const ButtonContainer = styled.button.withConfig({
  displayName: "Buttonstyle__ButtonContainer",
  componentId: "sc-8g16l6-0"
})(["position:relative;min-width:", ";max-width:", ";width:", ";padding:", ";border:solid 1px transparent;border-radius:15rem;font-family:", ";font-size:", ";line-height:22px;font-weight:500;text-align:center;outline:none;cursor:pointer;transition:all 0.2s ease-in-out;", ""], _ref27 => {
  let {
    small
  } = _ref27;
  return small ? '95px' : '180px';
}, _ref28 => {
  let {
    mobileLimit
  } = _ref28;
  return mobileLimit && '340px';
}, _ref29 => {
  let {
    fullWidth
  } = _ref29;
  return fullWidth && '100%';
}, _ref30 => {
  let {
    reducedPadding,
    theme
  } = _ref30;
  return reducedPadding ? `${theme.spacing.xs} ${theme.spacing.sm}` : `${theme.spacing.s} ${theme.spacing.m}`;
}, _ref31 => {
  let {
    theme
  } = _ref31;
  return theme.typography.primary;
}, _ref32 => {
  let {
    small
  } = _ref32;
  return small ? '14px' : '16px';
}, props => {
  if (props.disabled) {
    return ButtonDisabledStyles;
  }
  if (props.buttonType === 'secondary') {
    return ButtonSecondaryStyles;
  }
  if (props.buttonType === 'basic') {
    return ButtonBasicStyles;
  } else {
    return ButtonPrimaryStyles;
  }
});
export const ButtonContent = styled.span.withConfig({
  displayName: "Buttonstyle__ButtonContent",
  componentId: "sc-8g16l6-1"
})(["display:flex;flex-direction:row;justify-content:center;align-items:center;visibility:", ";"], _ref33 => {
  let {
    buttonLoading
  } = _ref33;
  return buttonLoading ? 'hidden' : 'visible';
});
export const ButtonText = styled.span.withConfig({
  displayName: "Buttonstyle__ButtonText",
  componentId: "sc-8g16l6-2"
})([""]);
export const ButtonIcon = styled.div.withConfig({
  displayName: "Buttonstyle__ButtonIcon",
  componentId: "sc-8g16l6-3"
})(["position:absolute;top:50%;", ";transform:translateY(-50%);margin:0 20px;opacity:", ";display:flex;"], props => props.iconSide === 'left' ? css(["left:0;"]) : css(["right:0;"]), _ref34 => {
  let {
    disabled
  } = _ref34;
  return disabled ? 0.3 : 1;
});
export const SpinnerWrapper = styled.div.withConfig({
  displayName: "Buttonstyle__SpinnerWrapper",
  componentId: "sc-8g16l6-4"
})(["position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);"]);