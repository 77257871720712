var __jsx = React.createElement;
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';
import { setCurrentLocation, resetLocationRedirect } from 'store/actions/location';
import PermanentRedirect from 'sharedComponents/PermanentRedirect';
import useActions from 'utils/hooks/useActions';
const actions = {
  setCurrentLocation,
  resetLocationRedirect
};
// A component used to trigger a redirect from within a redux action.
// This is needed because to trigger a redirect that is supported in server-side rendering.
// E.g. if a request returns 401/403 this component is used to redirect to /login (see store/middleware/Middleware.js)

const LocationHandler = () => {
  const location = useLocation();
  const {
    setCurrentLocation,
    resetLocationRedirect
  } = useActions(actions);
  const {
    currentPathname,
    currentSearch,
    redirectUrl,
    redirectPermanent
  } = useSelector(_ref => {
    let {
      location
    } = _ref;
    return location;
  });
  useEffect(() => {
    if (location.pathname !== currentPathname || location.search !== currentSearch) {
      setCurrentLocation(location.pathname, location.search);
    }
  }, [location.pathname, location.search, currentPathname, currentSearch, setCurrentLocation]);
  useEffect(() => {
    if (redirectUrl) {
      resetLocationRedirect();
    }
  }, [redirectUrl, resetLocationRedirect]);
  if (redirectUrl) {
    return redirectPermanent ? __jsx(PermanentRedirect, {
      to: redirectUrl
    }) : __jsx(Redirect, {
      to: redirectUrl
    });
  }
  return null;
};
export default LocationHandler;