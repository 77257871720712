import styled from 'styled-components';
import { Heading, Paragraph } from '../../../../content/text';
import { media } from '../../../../themes';
export const OuterContainer = styled.div.withConfig({
  displayName: "AssetClassTotalstyle__OuterContainer",
  componentId: "sc-15887hp-0"
})(["background:", ";display:flex;flex-direction:column;position:relative;text-align:left;min-height:392px;margin:20px 0 0 0;padding:0 20px;flex-grow:1;flex-basis:0;", ""], _ref => {
  let {
    theme
  } = _ref;
  return theme.colors.background_default;
}, media.desktop`
    margin: 0;
    min-height: 466px;
    max-width: 221px;

    &:not(:last-child) {
      margin: 0 20px 0 0;
    }
  `);
export const Headline = styled(Heading).withConfig({
  displayName: "AssetClassTotalstyle__Headline",
  componentId: "sc-15887hp-1"
})(["padding:0 0 20px 0;margin:30px 0 20px 0;min-height:68px;box-sizing:content-box;border-bottom:1px solid ", ";"], _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.colors.border_default;
});
export const Title = styled(Paragraph).withConfig({
  displayName: "AssetClassTotalstyle__Title",
  componentId: "sc-15887hp-2"
})(["color:", ";"], _ref3 => {
  let {
    theme
  } = _ref3;
  return theme.colors.text_label;
});
export const Number = styled(Paragraph).withConfig({
  displayName: "AssetClassTotalstyle__Number",
  componentId: "sc-15887hp-3"
})(["&:not(:last-child){margin-bottom:20px;}"]);
export const ImageHolder = styled.div.withConfig({
  displayName: "AssetClassTotalstyle__ImageHolder",
  componentId: "sc-15887hp-4"
})(["margin-top:auto;margin-left:auto;"]);
export const Image = styled.img.withConfig({
  displayName: "AssetClassTotalstyle__Image",
  componentId: "sc-15887hp-5"
})(["width:80px;"]);