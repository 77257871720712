import { Paragraph, Label, friendlyMedia } from '@yieldstreet/ui-kit';
import styled, { css } from 'styled-components';
const separatorStyles = css(["border-top:1px solid ", ";"], props => props.theme.colors.border_default);
export const Wrapper = styled.div.withConfig({
  displayName: "KYCMigrationBankDetailsstyle__Wrapper",
  componentId: "sc-1a84jnc-0"
})(["display:flex;flex-direction:column;flex-wrap:nowrap;justify-content:flex-start;align-content:stretch;align-items:stretch;background:", ";padding:", ";text-align:left;gap:", ";", " ", ""], props => props.theme.colors.background_card, props => props.theme.spacing.sm, props => props.theme.spacing.sm, props => props.separator && separatorStyles, friendlyMedia.desktop`
    flex-direction: row;
  `);
export const Details = styled.div.withConfig({
  displayName: "KYCMigrationBankDetailsstyle__Details",
  componentId: "sc-1a84jnc-1"
})(["flex-grow:", ";min-width:0;", ""], props => props.greedy ? 1 : 0, friendlyMedia.desktop`
    max-width: ${props => props.greedy ? '50%' : '180px'};
  `);
export const DetailsLabel = styled(Label).withConfig({
  displayName: "KYCMigrationBankDetailsstyle__DetailsLabel",
  componentId: "sc-1a84jnc-2"
})(["white-space:nowrap;"]);
export const DetailsValue = styled(Paragraph).withConfig({
  displayName: "KYCMigrationBankDetailsstyle__DetailsValue",
  componentId: "sc-1a84jnc-3"
})(["overflow:hidden;white-space:nowrap;text-overflow:ellipsis;"]);
export const DetailsButton = styled(DetailsValue).attrs({
  size: 'small'
}).withConfig({
  displayName: "KYCMigrationBankDetailsstyle__DetailsButton",
  componentId: "sc-1a84jnc-4"
})(["cursor:pointer;text-decoration:underline;"]);