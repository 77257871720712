import { useSelector } from 'react-redux';
export const useAnalyticsIds = () => {
  const {
    analyticsUserId: userId,
    analyticsAnonymousId: anonymousId
  } = useSelector(_ref => {
    let {
      user
    } = _ref;
    return user;
  });
  return {
    userId,
    anonymousId
  };
};