var __jsx = React.createElement;
import React from 'react';
import { Heading, Label } from '../../content/text';
import { BackgroundImage, Container, LeftContainer, RightContainer, SHeading, SParagraph, SSectionContainer, StatItem, StatsWrapper } from './StatsView.style';
export const StatsView = _ref => {
  let {
    children,
    image,
    imageComponent,
    inverse,
    label,
    noMobilePadding,
    rightContainerWidth,
    stats,
    subTitle,
    title
  } = _ref;
  const hasImage = Boolean(imageComponent || image);
  return __jsx(SSectionContainer, {
    noMobilePadding: noMobilePadding
  }, __jsx(Container, null, hasImage && __jsx(LeftContainer, null, imageComponent ? imageComponent : __jsx(BackgroundImage, {
    image: image
  })), __jsx(RightContainer, {
    width: rightContainerWidth,
    inverse: inverse,
    centerAlign: !hasImage
  }, __jsx(Heading, {
    type: 2,
    inverse: inverse
  }, title), __jsx(SParagraph, {
    marginTop: true,
    inverse: inverse
  }, subTitle), stats && __jsx(StatsWrapper, {
    hasImage: hasImage,
    "test-id": "stats-wrapper"
  }, stats === null || stats === void 0 ? void 0 : stats.map(stat => __jsx(StatItem, {
    "data-cy": "stat-item",
    hasImage: hasImage,
    key: stat.label
  }, __jsx(SHeading, {
    inverse: inverse,
    type: 2,
    tagType: 3
  }, stat.value), __jsx(SParagraph, {
    inverse: inverse
  }, stat.label)))), children, label && __jsx(Label, {
    inverse: inverse
  }, label))));
};