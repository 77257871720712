import find from "lodash/find";
import { FilterTypeEnum } from '@yieldstreet/tool-kit';
export const getValue = (key, search) => {
  const getSearchValue = (key, i) => {
    const {
      [key]: value
    } = search;
    if (value === 'true') {
      return true;
    }
    if (!value && i !== undefined) {
      return i > 0 ? undefined : undefined;
    }
    return value;
  };
  if (Array.isArray(key)) {
    return key === null || key === void 0 ? void 0 : key.map((k, i) => getSearchValue(k, i));
  }
  return getSearchValue(key);
};
export const getFormattedValueString = _ref => {
  let {
    value,
    type,
    config,
    options
  } = _ref;
  if (!value) {
    return '';
  }
  switch (type) {
    case FilterTypeEnum.Range:
      if (Array.isArray(value)) {
        const [min, max] = value;
        if (min || max) {
          if (+min === (config === null || config === void 0 ? void 0 : config.min) && +max === (config === null || config === void 0 ? void 0 : config.max)) {
            return 'All';
          }
          return value.filter(v => !!v).map(v => v ? config.formatter(v) : undefined).join(' - ');
        }
        return '';
      }
      return '';
    case FilterTypeEnum.MultiSelect:
      if ((value === null || value === void 0 ? void 0 : value.length) === 1) {
        var _Object$values$find;
        return (_Object$values$find = Object.values(options).find(option => option.id.toString() === (value === null || value === void 0 ? void 0 : value[0].toString()))) === null || _Object$values$find === void 0 ? void 0 : _Object$values$find.label;
      }
      return `${value === null || value === void 0 ? void 0 : value.length} selected`;
    case FilterTypeEnum.SingleSelect:
      const selected = find(options, option => (option === null || option === void 0 ? void 0 : option.id) === value);
      return (selected === null || selected === void 0 ? void 0 : selected.label) || value;
    default:
      return value ? `On` : ``;
  }
};
export const getKeyString = key => Array.isArray(key) ? key.join('-') : key;