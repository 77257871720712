import { Paragraph, media } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
export const Wrapper = styled.div.withConfig({
  displayName: "DisclaimerSectionstyle__Wrapper",
  componentId: "sc-14gt3k1-0"
})(["margin:0 auto;"]);
export const InnerWrapper = styled.div.withConfig({
  displayName: "DisclaimerSectionstyle__InnerWrapper",
  componentId: "sc-14gt3k1-1"
})(["padding:30px 0;", ";", ""], media.tablet`
    padding: 30px 0 80px;
  `, media.large`
    margin: auto;
  `);
export const DisclaimerBlock = styled(Paragraph).withConfig({
  displayName: "DisclaimerSectionstyle__DisclaimerBlock",
  componentId: "sc-14gt3k1-2"
})(["display:block;margin-bottom:10px;color:", ";"], props => props.theme.colors.text_label);