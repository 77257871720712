var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';
import { CSSTransition } from 'react-transition-group';
import { media } from '../../themes';
import { MobileOnly, DesktopOnly } from '../../utils';
export const AnimatedBanner = _ref => {
  let {
    image,
    imageComponent,
    children,
    containerBgColor,
    trackRecordBgColor,
    rightContainerWidth
  } = _ref;
  return __jsx(React.Fragment, null, __jsx(DesktopOnly, null, __jsx(BarHolder, {
    trackRecordBgColor: trackRecordBgColor,
    containerBgColor: containerBgColor
  }, __jsx(LeftContainer, null), __jsx(RightContainer, {
    width: rightContainerWidth
  }, __jsx(VisibilitySensor, null, _ref2 => {
    let {
      isVisible
    } = _ref2;
    return __jsx(CSSTransition, {
      className: "CSSTransition",
      in: isVisible,
      timeout: 100,
      classNames: "bars"
    }, __jsx(Bars, null, __jsx(Bar, {
      className: "first"
    }), __jsx(Bar, {
      className: "second"
    }), __jsx(Bar, {
      className: "third"
    }), __jsx(Bar, {
      containerBgColor: containerBgColor,
      className: "forth"
    })));
  })))), __jsx(Container, {
    trackRecordBgColor: trackRecordBgColor
  }, __jsx(LeftContainer, null, imageComponent ? imageComponent : __jsx(BackgroundImage, {
    className: "animated-banner-image",
    image: image
  })), __jsx(RightContainer, {
    width: rightContainerWidth
  }, __jsx(MobileOnly, null, __jsx(VisibilitySensor, null, _ref3 => {
    let {
      isVisible
    } = _ref3;
    return __jsx(CSSTransition, {
      in: isVisible,
      timeout: 400,
      classNames: "bars"
    }, __jsx(Bars, null, __jsx(Bar, {
      className: "first"
    }), __jsx(Bar, {
      className: "second"
    }), __jsx(Bar, {
      className: "third"
    }), __jsx(Bar, {
      trackRecordBgColor: trackRecordBgColor,
      className: "forth"
    })));
  })), children)));
};
const Container = styled.div.withConfig({
  displayName: "AnimatedBanner__Container",
  componentId: "sc-y7kf0u-0"
})(["background-color:", ";display:flex;flex-direction:column;", ";"], props => props.trackRecordBgColor ? props.theme.colors[props.trackRecordBgColor] : props.theme.colors.background_page, media.desktop`
    flex-direction: row;
  `);
const LeftContainer = styled.div.withConfig({
  displayName: "AnimatedBanner__LeftContainer",
  componentId: "sc-y7kf0u-1"
})(["width:100%;div{height:230px;background-position:center;background-size:cover;}", ";"], media.desktop`
    width: 45%;
    div {
      height: 100%;
    }
  `);
const BackgroundImage = styled.div.withConfig({
  displayName: "AnimatedBanner__BackgroundImage",
  componentId: "sc-y7kf0u-2"
})(["background:url('", "')};background-position:center top;"], props => props.image);
const RightContainer = styled.div.withConfig({
  displayName: "AnimatedBanner__RightContainer",
  componentId: "sc-y7kf0u-3"
})(["padding:50px 20px 40px;position:relative;", ""], media.desktop`
    padding: 120px 20px 40px 80px;
    width: ${props => props.width ? props.width : `45%`};
  `);
const BarHolder = styled(Container).withConfig({
  displayName: "AnimatedBanner__BarHolder",
  componentId: "sc-y7kf0u-4"
})(["display:flex;height:30px;", "{background:", ";}background-color:", ";"], LeftContainer, props => props.containerBgColor ? props.theme.colors[props.containerBgColor] : props.theme.colors.background_inverse_secondary, props => props.trackRecordBgColor ? props.theme.colors[props.trackRecordBgColor] : props.theme.colors.background_page);
const Bars = styled.div.withConfig({
  displayName: "AnimatedBanner__Bars",
  componentId: "sc-y7kf0u-5"
})(["width:100%;height:90px;display:flex;position:absolute;left:0;top:-60px;", ";&.bars-enter-done{div{max-height:30px;margin-top:0;}}"], media.desktop`
    top: -30px;
  `);
const Bar = styled.div.withConfig({
  displayName: "AnimatedBanner__Bar",
  componentId: "sc-y7kf0u-6"
})(["transition:all 0.5s ease;max-height:0;margin-top:30px;position:absolute;width:calc(100% / 3);height:30px;&.first{bottom:0;left:0;background:", ";}&.second{transition-delay:0.2s;top:30px;left:calc(100% / 3);background:", ";}&.third{transition-delay:0.3s;top:0;right:0;background:", ";}&.forth{max-height:30px;margin-top:0;top:30px;right:0;background-color:", ";", ";}"], props => props.theme.colors.accent_light, props => props.theme.colors.accent_medium, props => props.theme.colors.accent_strong, props => props.trackRecordBgColor ? props.theme.colors[props.trackRecordBgColor] : props.theme.colors.background_card, media.desktop`
      left: 0;
      background: ${props => props.containerBgColor ? props.theme.colors[props.containerBgColor] : props.theme.colors.background_inverse_secondary};
    `);