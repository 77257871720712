import styled from 'styled-components';
import { HeroBanner, media } from '@yieldstreet/ui-kit';
export const Background = styled.div.withConfig({
  displayName: "Glancestyle__Background",
  componentId: "sc-4kasif-0"
})(["border-radius:", ";background-color:", ";padding:", ";", ""], _ref => {
  let {
    theme
  } = _ref;
  return theme.radius.xl;
}, _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.colors.background_inverse_secondary;
}, _ref3 => {
  let {
    theme
  } = _ref3;
  return `${theme.spacing.ml} 0`;
}, media.desktop`
    padding: ${_ref4 => {
  let {
    theme
  } = _ref4;
  return `0 0 0 ${theme.spacing.ml}`;
}};
  `);
export const IconImg = styled.img.withConfig({
  displayName: "Glancestyle__IconImg",
  componentId: "sc-4kasif-1"
})(["width:48px;height:48px;"]);
export const SHeroBanner = styled(HeroBanner).withConfig({
  displayName: "Glancestyle__SHeroBanner",
  componentId: "sc-4kasif-2"
})([".hero-banner-content-holder{margin:0;", "}.hero-banner-content-wrapper{min-height:fit-content;", " .hero-banner-content-holder{padding:0;}}.hero-banner-side-content{padding:", ";", "}"], media.desktop`
      margin: auto 0;
    `, media.desktop`
      min-height: 360px;
      width: 40%;
    `, _ref5 => {
  let {
    theme
  } = _ref5;
  return `${theme.spacing.sm} 0 0 0`;
}, media.desktop`
      padding: 20px;
      width: 100%;
    `);
export const ItemsWrapper = styled.div.withConfig({
  displayName: "Glancestyle__ItemsWrapper",
  componentId: "sc-4kasif-3"
})(["display:flex;flex-direction:column;word-break:keep-all;row-gap:", ";", ""], _ref6 => {
  let {
    theme
  } = _ref6;
  return theme.spacing.l;
}, media.desktop`
    flex-direction: row;
    column-gap: ${_ref7 => {
  let {
    theme
  } = _ref7;
  return theme.spacing.m;
}};
  `);