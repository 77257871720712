var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { PageSection } from '../../layout/page-section';
import { Heading, Paragraph } from '../../content/text';
export const ArticleContent = _ref => {
  let {
    title,
    tagType = 3,
    children
  } = _ref;
  return __jsx(PageSection, null, __jsx(Container, null, __jsx(Title, {
    type: 3,
    tagType: tagType
  }, title), __jsx(Content, null, children)));
};
const Container = styled.div.withConfig({
  displayName: "ArticleContent__Container",
  componentId: "sc-6vmit6-0"
})(["padding-top:40px;"]);
const Title = styled(Heading).withConfig({
  displayName: "ArticleContent__Title",
  componentId: "sc-6vmit6-1"
})(["padding:20px 0;position:relative;&::before{content:'';position:absolute;top:0;left:0;width:28px;height:2px;background:", ";}"], props => `${props.theme.colors.accent_strong}`);
const Content = styled(Paragraph).withConfig({
  displayName: "ArticleContent__Content",
  componentId: "sc-6vmit6-2"
})(["p{padding-top:20px;}"]);