import { useCallback, useMemo } from 'react';
import { allToken, investorEntityApi } from '@yieldstreet/platform-kit';
import { useSDIRAUser } from './useSDIRAUser';
import { InvestorEntityProviderTagMatchers } from './useSDIRAInvestorEntity.model';
export const useProviderInvestorEntity = () => {
  const {
    provider,
    issuer,
    tags = {}
  } = useSDIRAUser();

  // check whether the user connected value and the issuer of the session match
  // this will indicate that the session was initiate by the provider and therefor
  // we can assume that the data for account creation is available in the session tags
  const sessionDataAvailable = provider && provider === issuer;
  const {
    data: investorEntities,
    isLoading: isLoadingInvestorEntity,
    isUninitialized: isUninitializedInvestorEntity
  } = investorEntityApi.useGetInvestorEntityQuery(allToken);

  // check whether an investor account was created using the connected user
  // user session will contain tags that carry over info from the provider authentication
  // these tags are compared to provider-specific data to check whether a matching investor account already exists
  const investorEntityCheck = useCallback(_ref => {
    let {
      provider: {
        type = null,
        data = null
      } = {}
    } = _ref;
    // check whether user connected provider matches investor account provider type
    if (type === provider && data) {
      const providerTagMatcher = InvestorEntityProviderTagMatchers[type];
      if (providerTagMatcher) {
        // check whether provider specific tag match provider data within investor account
        return providerTagMatcher(tags, data);
      }
    }
    return false;
  }, [provider, tags]);
  const investorEntity = useMemo(() => {
    if (investorEntities && sessionDataAvailable) {
      return investorEntities.entities.find(investorEntityCheck);
    }
    return null;
  }, [investorEntityCheck, investorEntities, sessionDataAvailable]);

  // creation is only allowed when required data is available in the session tags
  // and if investor account matching the session provider data already exists
  const isLoading = isLoadingInvestorEntity || isUninitializedInvestorEntity;
  const createBlocked = !sessionDataAvailable || !isLoading && investorEntity;
  return {
    provider,
    isLoading,
    createBlocked,
    investorEntity
  };
};