import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { HttpMethods } from '../../enums/http';
import { Tags, restApi } from '../common';
const BASE_URL = '/api/support-center';
export const supportCenterApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.SupportCenter]
}).injectEndpoints({
  endpoints: builder => {
    const getSupportCenterCategories = builder.query({
      query: queryParams => ({
        url: `${BASE_URL}/categories`,
        method: HttpMethods.Get,
        params: _objectSpread({
          pageSize: 100,
          pageIndex: 1
        }, queryParams)
      }),
      transformResponse: data => data.content,
      providesTags: [Tags.SupportCenter]
    });
    const getSupportCenterCategoryById = builder.query({
      query: _ref => {
        let {
          categoryId
        } = _ref;
        return {
          url: `${BASE_URL}/category/${categoryId}`,
          method: HttpMethods.Get
        };
      },
      providesTags: [Tags.SupportCenter]
    });
    const getSupportCenterArticles = builder.query({
      query: params => ({
        url: `${BASE_URL}/articles`,
        method: HttpMethods.Get,
        params
      }),
      transformResponse: data => data.content,
      providesTags: [Tags.SupportCenter]
    });
    const getSupportCenterArticleById = builder.query({
      query: _ref2 => {
        let {
          articleId
        } = _ref2;
        return {
          url: `${BASE_URL}/articles/${articleId}`,
          method: HttpMethods.Get
        };
      },
      providesTags: [Tags.SupportCenter]
    });
    const getSupportCenterArticlesRelated = builder.query({
      query: _ref3 => {
        let {
          articleId
        } = _ref3;
        return {
          url: `${BASE_URL}/articles/${articleId}/related`,
          method: HttpMethods.Get
        };
      },
      providesTags: [Tags.SupportCenter]
    });
    return {
      getSupportCenterCategories,
      getSupportCenterCategoryById,
      getSupportCenterArticles,
      getSupportCenterArticleById,
      getSupportCenterArticlesRelated
    };
  }
});