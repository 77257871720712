import YSLogo from '@yieldstreet/ui-kit/assets/logos/black_ys_logo.svg';
import PlaidLogo from '@yieldstreet/ui-kit/assets/logos/plaid.svg';
import { genTestIds } from '@yieldstreet/tool-kit';
export const content = {
  externalProviderTitle: "To setup your Yieldstreet account, you'll need to link an external bank account",
  externalProviderSubtitle:
  // eslint-disable-next-line no-multi-str
  "You'll be redirected to our partner, Plaid, to complete this process.\
    Your bank account credentials will be used to instantly link your external accounts.\
    If your bank doesn't support that method, you'll be asked to confirm that test deposits to your account\
    were received.",
  externalProviderFromIcon: {
    icon: YSLogo,
    alt: 'Yieldstreet'
  },
  externalProviderToIcon: {
    icon: PlaidLogo,
    alt: 'Plaid',
    backgroundColor: 'black'
  },
  externalProviderButton: 'Continue'
};
const {
  getTestIds
} = genTestIds();
export const testIds = getTestIds('bankLinkingIntro');