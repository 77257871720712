import { PerformanceStatus } from '../../../../enums';
import { InvestmentEngine, InvestmentStatusTypes, InvestmentTypes, TenderStatus } from '../../../../types';
export const portfolioOverviewDebtActive = {
  userId: 4,
  accountSummary: {
    outstandingPrincipal: 10000.0,
    outstandingPrincipalPreDefault: 10000.0,
    outstandingPrincipalPostDefault: 0.0,
    accruedInvestmentInterest: 45.53,
    accruedInvestmentInterestPreDefault: 45.53,
    accruedInvestmentInterestPostDefault: 0.0,
    balanceInDefault: 0.0,
    balanceByPerformanceStatus: {
      DEFAULT: 0.0,
      MODIFIED_OUTLOOK: 0.0,
      PARTIAL_DEFAULT: 0.0,
      PERFORMING: 10045.53,
      MARINE_DEFAULT: 0.0
    },
    investmentBalance: 10045.53,
    pendingInvestments: 0.0,
    availableBalance: 0.0,
    clearedBalance: 0.0,
    pendingDeposits: 0.0,
    reserveBalance: 0,
    total: 10045.53,
    weightedInvestmentRate: 8.52,
    irr: 5.13,
    interestEarnedLast30: 195.86,
    fundsTotal: 0,
    funds: []
  },
  capital: null,
  investorEntities: [],
  interest: {
    beginningAccruedInterest: 0,
    interestEarned: 1116.09,
    compoundInterestEarned: 0.0,
    interestPaid: 1070.56,
    fexpPaid: 21.65,
    fexpBalance: 0,
    netPaid: 1048.91,
    walletInterestPaid: 0.0,
    compoundInterestPaid: 0.0,
    endingAccruedInterest: 45.53,
    interestEarnedPreDefault: 1116.09,
    interestEarnedPostDefault: 0,
    earningsTimeline: [{
      date: '2022-06-30',
      accruedPreDefault: 0,
      accruedPostDefault: 0,
      totalPaid: 0,
      netPaid: 0,
      fexpPaid: 0,
      walletInterestPaid: 0,
      value: 0
    }, {
      date: '2022-07-31',
      accruedPreDefault: 36.1,
      accruedPostDefault: 0.0,
      totalPaid: 0.0,
      netPaid: 0.0,
      fexpPaid: 0.0,
      walletInterestPaid: 0.0,
      value: 36.1
    }, {
      date: '2022-08-31',
      accruedPreDefault: 127.0,
      accruedPostDefault: 0.0,
      totalPaid: 0.0,
      netPaid: 0.0,
      fexpPaid: 0.0,
      walletInterestPaid: 0.0,
      value: 127.0
    }, {
      date: '2022-09-30',
      accruedPreDefault: 87.48,
      accruedPostDefault: 0.0,
      totalPaid: 130.06,
      netPaid: 127.0,
      fexpPaid: 3.06,
      walletInterestPaid: 0.0,
      value: 217.54
    }, {
      date: '2022-10-31',
      accruedPreDefault: 92.8,
      accruedPostDefault: 0.0,
      totalPaid: 222.67,
      netPaid: 217.54,
      fexpPaid: 5.13,
      walletInterestPaid: 0.0,
      value: 315.47
    }, {
      date: '2022-11-30',
      accruedPreDefault: 93.18,
      accruedPostDefault: 0.0,
      totalPaid: 322.76,
      netPaid: 315.47,
      fexpPaid: 7.29,
      walletInterestPaid: 0.0,
      value: 415.94
    }, {
      date: '2022-12-31',
      accruedPreDefault: 62.19,
      accruedPostDefault: 0.0,
      totalPaid: 425.32,
      netPaid: 415.94,
      fexpPaid: 9.38,
      walletInterestPaid: 0.0,
      value: 487.51
    }, {
      date: '2023-01-31',
      accruedPreDefault: 79.76,
      accruedPostDefault: 0.0,
      totalPaid: 516.51,
      netPaid: 505.34,
      fexpPaid: 11.17,
      walletInterestPaid: 0.0,
      value: 596.27
    }, {
      date: '2023-02-28',
      accruedPreDefault: 68.72,
      accruedPostDefault: 0.0,
      totalPaid: 627.43,
      netPaid: 614.1,
      fexpPaid: 13.33,
      walletInterestPaid: 0.0,
      value: 696.15
    }, {
      date: '2023-03-31',
      accruedPreDefault: 78.3,
      accruedPostDefault: 0.0,
      totalPaid: 729.23,
      netPaid: 713.97,
      fexpPaid: 15.26,
      walletInterestPaid: 0.0,
      value: 807.53
    }, {
      date: '2023-04-30',
      accruedPreDefault: 73.75,
      accruedPostDefault: 0.0,
      totalPaid: 842.78,
      netPaid: 825.36,
      fexpPaid: 17.42,
      walletInterestPaid: 0.0,
      value: 916.53
    }, {
      date: '2023-05-31',
      accruedPreDefault: 77.24,
      accruedPostDefault: 0.0,
      totalPaid: 953.85,
      netPaid: 934.35,
      fexpPaid: 19.5,
      walletInterestPaid: 0.0,
      value: 1031.09
    }, {
      date: '2023-06-23',
      accruedPreDefault: 45.53,
      accruedPostDefault: 0.0,
      totalPaid: 1070.56,
      netPaid: 1048.91,
      fexpPaid: 21.65,
      walletInterestPaid: 0.0,
      value: 1116.09
    }]
  },
  principal: {
    beginningOutstandingPrincipal: 0,
    totalInvested: 10000.0,
    repaidPrincipal: 0.0,
    endingOutstandingPrincipal: 10000.0,
    endingOutstandingPrincipalPreDefault: 10000.0,
    endingOutstandingPrincipalPostDefault: 0.0,
    liquidationPaid: 0.0,
    liquidationDiscount: 0.0,
    isInDefault: false,
    principalTimeline: [{
      date: '2022-06-30',
      paid: 0,
      outstandingPreDefault: 0,
      outstandingPostDefault: 0,
      principalWriteOff: 0,
      liquidationPaid: 0,
      liquidationDiscount: 0,
      value: 0
    }, {
      date: '2022-07-31',
      paid: 0.0,
      outstandingPreDefault: 10000.0,
      outstandingPostDefault: 0.0,
      principalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 10000.0
    }, {
      date: '2022-08-31',
      paid: 0.0,
      outstandingPreDefault: 10000.0,
      outstandingPostDefault: 0.0,
      principalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 10000.0
    }, {
      date: '2022-09-30',
      paid: 0.0,
      outstandingPreDefault: 10000.0,
      outstandingPostDefault: 0.0,
      principalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 10000.0
    }, {
      date: '2022-10-31',
      paid: 0.0,
      outstandingPreDefault: 10000.0,
      outstandingPostDefault: 0.0,
      principalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 10000.0
    }, {
      date: '2022-11-30',
      paid: 0.0,
      outstandingPreDefault: 10000.0,
      outstandingPostDefault: 0.0,
      principalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 10000.0
    }, {
      date: '2022-12-31',
      paid: 0.0,
      outstandingPreDefault: 10000.0,
      outstandingPostDefault: 0.0,
      principalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 10000.0
    }, {
      date: '2023-01-31',
      paid: 0.0,
      outstandingPreDefault: 10000.0,
      outstandingPostDefault: 0.0,
      principalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 10000.0
    }, {
      date: '2023-02-28',
      paid: 0.0,
      outstandingPreDefault: 10000.0,
      outstandingPostDefault: 0.0,
      principalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 10000.0
    }, {
      date: '2023-03-31',
      paid: 0.0,
      outstandingPreDefault: 10000.0,
      outstandingPostDefault: 0.0,
      principalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 10000.0
    }, {
      date: '2023-04-30',
      paid: 0.0,
      outstandingPreDefault: 10000.0,
      outstandingPostDefault: 0.0,
      principalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 10000.0
    }, {
      date: '2023-05-31',
      paid: 0.0,
      outstandingPreDefault: 10000.0,
      outstandingPostDefault: 0.0,
      principalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 10000.0
    }, {
      date: '2023-06-23',
      paid: 0.0,
      outstandingPreDefault: 10000.0,
      outstandingPostDefault: 0.0,
      principalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 10000.0
    }]
  },
  investmentDetail: {
    fundId: null,
    fundType: null,
    noteUrlHash: 'QCRiBw',
    title: 'Scottsdale Resort Financing',
    distributionRate: 0,
    assetClass: 'REAL',
    assetClassDescription: 'Real Estate',
    paymentType: 'Monthly',
    termPhrase: '22 Mo.',
    advisor: null,
    subAdvisor: null,
    interestRate: 8.52,
    taxDocument: '1099-INT',
    taxMonth: 'JANUARY',
    firstYearExpense: 100,
    annualFlatExpense: 30,
    irr: 0.0,
    investedDate: '2022-07-17',
    activeDate: '2022-07-19',
    launchedDate: '2022-04-14',
    terminationDate: '2025-03-30',
    interestDefaultedDate: null,
    principalDefaultedDate: null,
    dateOfFirstDefault: null,
    originator: 'Cottonwood Management',
    dividendDistributionOption: null,
    canToggleDistributionOption: null,
    performanceStatus: PerformanceStatus.PERFORMING,
    performanceStatusNote: '',
    spvType: null,
    offeringData: {},
    investments: [{
      type: InvestmentTypes.Investment,
      dateInvested: '2022-07-17',
      dateActive: '2022-07-19',
      shareCount: 0,
      sharePrice: 0,
      status: InvestmentStatusTypes.Confirmed,
      amount: 10000.0
    }],
    tenderOfferStatus: TenderStatus.UNAVAILABLE,
    tenderOfferUserEligible: false,
    tenderOfferId: null,
    totalInvestments: 10000.0,
    totalRepaid: 1070.56,
    netTotalRepaid: 1048.91,
    fundProvider: null,
    engine: InvestmentEngine.Pflex
  },
  investmentGatesDetail: {
    requiresUserAttention: false,
    gates: []
  },
  wallets: [],
  investmentStrategies: []
};