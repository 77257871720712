var __jsx = React.createElement;
import React from 'react';
import { PageFlow, Paragraph, ProcessInfo, ProcessStatus, StatusIcon } from '@yieldstreet/ui-kit';
import { Link } from 'sharedComponents/Links/Links';
import { testIds } from './AccountCreationBlocked.model';
export const AccountCreationBlocked = () => {
  return __jsx(PageFlow, {
    showLogo: false
  }, __jsx(ProcessInfo, {
    title: "Apologies for the inconvenience",
    icon: __jsx(StatusIcon, {
      status: ProcessStatus.WARNING,
      testId: testIds.icon.id
    }),
    iconCentered: true,
    testId: testIds.base.id
  }, __jsx(Paragraph, null, "Yieldstreet's technical service provider is currently experiencing a temporary disruption. We are working to resolve this matter as quickly as possible. In the meantime, if you have any questions please contact\xA0", __jsx(Link, {
    id: "intercom-link",
    to: "#"
  }, "Investor Relations."))));
};