export const ACTIVE = {
  // Active
  mint: {
    50: '#f6fef9',
    100: '#e2fced',
    200: '#d5fae4',
    300: '#c1f8d8',
    400: '#b5f7d1',
    500: '#a3f5c5',
    600: '#94dfb3',
    700: '#74ae8c',
    800: '#5a876c',
    900: '#446753'
  },
  chartreuse: {
    50: '#fcffed',
    100: '#f5fdc6',
    200: '#f0fdaa',
    300: '#eafc83',
    400: '#e5fb6b',
    500: '#dffa46',
    600: '#cbe440',
    700: '#9eb232',
    800: '#7b8a27',
    900: '#5e691d'
  }
};