import _Button from "@mui/material/Button";
import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useCallback } from 'react';
import { ButtonContainer } from '../../content';
import { ModalTrigger, Triggers } from '../../utils';
import { Container, Icon } from './PromoPopOver.style';
import { testIds } from './PromoPopOver.model';
import trustLevelIcon from './assets/trust-level.svg';
export const PromoPopOver = _ref => {
  let {
    buttonText,
    description,
    onClick,
    onClose,
    storageId,
    title
  } = _ref;
  const handleOnClick = useCallback(() => {
    if (typeof onClick === 'function') {
      onClick();
    }
  }, [onClick]);
  return __jsx(ModalTrigger, {
    height: 'auto',
    onClose: onClose,
    storageId: storageId,
    triggerType: Triggers.LOAD,
    width: 'auto'
  }, __jsx(Container, null, __jsx(Icon, {
    src: trustLevelIcon
  }), __jsx(_Typography, _extends({
    component: "h3",
    variant: "h3"
  }, testIds.title.attr), title), __jsx(_Typography, _extends({
    component: "p",
    variant: "body1"
  }, testIds.description.attr), description), __jsx(ButtonContainer, null, __jsx(_Button, _extends({
    onClick: handleOnClick
  }, testIds.button.attr), buttonText))));
};