import { ADVICE_RECOMMENDATIONS_PATH } from 'pages/advice/adviceRecommendations/AdviceRecommendations.model';
const ADVICE_CONFIRM_RECOMMENDATIONS_PATH = `${ADVICE_RECOMMENDATIONS_PATH}/confirm`;
const ADVICE_CONFIRM_BUTTON_TEXT = {
  back: 'Back',
  confirm: 'Invest now'
};
const ADVICE_CONFIRM_STRINGS = {
  title: 'Confirm investment allocations',
  mainParagraph: "Please confirm the recommended investments you selected are correctly listed below and read any relevant notes before you proceed. Once you've submitted your request for these investments, you will be able to track them in your Portfolio.",
  tableHeaders: {
    offering: 'Offering',
    additionalNotes: 'Additional notes',
    commitment: 'Commitment'
  },
  footerCaption: 'Total investment:',
  finePrint: 'The investment offerings recommended by Yieldstreet are highly speculative and entail substantial risks, including the fact that such investments would be illiquid and be subject to significant restrictions on transferability and that all or a substantial amount of the principal invested may be lost. No amount of diversification can eliminate such risks. Such investment opportunities may not be suitable for all investors, and potential investors must make an independent assessment of the appropriateness of any investment in light of their own objectives and circumstances.',
  submitError: 'We encountered an error when saving your selections.'
};
const ADVICE_CONFIRM_IDS = {
  title: 'advice-confirm-recommendations-title',
  mainParagraph: 'advice-confirm-recommendations-main-paragraph',
  finePrint: 'advice-confirm-recommendations-fine-print',
  content: 'advice-confirm-recommendations-content',
  cardRows: 'advice-confirm-card-rows'
};
export { ADVICE_CONFIRM_RECOMMENDATIONS_PATH, ADVICE_CONFIRM_BUTTON_TEXT, ADVICE_CONFIRM_STRINGS, ADVICE_CONFIRM_IDS };