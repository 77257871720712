import styled from 'styled-components';
import { Paragraph, Button, media } from '@yieldstreet/ui-kit';
import HeroImg from '../assets/Hero.png';
export const Background = styled.div.withConfig({
  displayName: "Herostyle__Background",
  componentId: "sc-1aln0kj-0"
})(["background:url('", "') no-repeat;background-position:top;background-size:cover;border-radius:32px;", ""], HeroImg, media.large`
    height: 490px;
  `);
export const Content = styled.div.withConfig({
  displayName: "Herostyle__Content",
  componentId: "sc-1aln0kj-1"
})(["padding-top:50%;", ""], media.large`
    padding: ${_ref => {
  let {
    theme
  } = _ref;
  return `${theme.spacing.l} ${theme.spacing.ml}`;
}};
  `);
export const SParagraph = styled(Paragraph).withConfig({
  displayName: "Herostyle__SParagraph",
  componentId: "sc-1aln0kj-2"
})(["margin-bottom:", ";", ""], _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.spacing.m;
}, media.large`
    margin-bottom: ${_ref3 => {
  let {
    theme
  } = _ref3;
  return theme.spacing.l;
}};
  `);
export const SButton = styled(Button).withConfig({
  displayName: "Herostyle__SButton",
  componentId: "sc-1aln0kj-3"
})(["margin-bottom:", ";"], _ref4 => {
  let {
    theme
  } = _ref4;
  return theme.spacing.s;
});
export const ButtonContainer = styled.div.withConfig({
  displayName: "Herostyle__ButtonContainer",
  componentId: "sc-1aln0kj-4"
})(["text-align:center;", ""], media.large`
    max-width: 250px;
  `);