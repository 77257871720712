export const defaultMediaCards = {
  type: 'mediaCards',
  resources: [],
  content: [{
    type: 'custom',
    title: 'Podcast',
    description: 'A podcast by our investments team that explains why we originated this deal.',
    iconName: 'podcast',
    customUrl: 'https://spotify.com/changeme',
    previewPdfPage: ''
  }, {
    type: 'mainDocument',
    title: 'BPDN Doc',
    description: 'Download here',
    iconName: 'download',
    customUrl: '',
    previewPdfPage: ''
  }, {
    type: 'mainDocumentPreview',
    title: 'View risk factors',
    description: 'View the risks specific to this offering in addition to the risk factors set forth in the PPM.',
    iconName: 'risk',
    customUrl: '',
    previewPdfPage: '23'
  }]
};
export const allResourcesMediaCards = {
  type: 'mediaCards',
  resources: ['all'],
  content: []
};
export const standAloneDocResourcesMediaCards = {
  type: 'mediaCards',
  resources: ['BPDN Doc'],
  content: []
};
export const missingDocType = {
  type: 'mediaCards',
  resources: [],
  content: [{
    type: 'custom',
    title: 'Podcast',
    description: 'A podcast by our investments team that explains why we originated this deal.',
    iconName: 'podcast',
    customUrl: 'https://spotify.com/changeme',
    previewPdfPage: ''
  }, {
    type: 'mainDocument',
    title: 'BPDN Doc',
    description: 'Download here',
    iconName: 'download',
    customUrl: '',
    previewPdfPage: ''
  }, {
    type: '',
    title: 'View risk factors',
    description: 'View the risks specific to this offering in addition to the risk factors set forth in the PPM.',
    iconName: 'risk',
    customUrl: ''
  }]
};