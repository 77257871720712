import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["name", "label", "onChange", "placeholder", "format"];
var __jsx = React.createElement;
import React, { useState, useMemo } from 'react';
import { useMimicPassword } from 'react-mimic-password-hook';
import { TextInput } from './TextInput';
import { MaskTextInput } from './MaskTextInput';
import { ShowHideToggle } from './helpers';
import { withFormikWeb } from '../common';
export const DocumentTextInput = _ref => {
  let {
      name,
      label,
      onChange,
      placeholder,
      format
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  const maxLength = useMemo(() => format.split('#').length - 1, [format]);
  const formatPlaceholder = useMemo(() => format.replace(/#/g, 'X'), [format]);
  const {
    0: showDocument,
    1: setShowDocument
  } = useState(false);
  const [value, presentation, onPasswordChange] = useMimicPassword({
    mode: 'persymbol',
    handleChange: onChange
  });
  return !showDocument ? __jsx(TextInput, _extends({}, props, {
    name: name,
    label: label,
    value: presentation,
    onChange: (_value, e) => onPasswordChange(e),
    onKeyPress: e => {
      const isNumber = /^[0-9]$/i.test(e.key);
      if (!isNumber) {
        e.preventDefault();
      }
    },
    autoComplete: "new-password",
    type: "text",
    inputMode: "numeric",
    placeholder: placeholder || formatPlaceholder,
    maxLength: maxLength,
    suffix: __jsx(ShowHideToggle, {
      name: label === null || label === void 0 ? void 0 : label.toString(),
      show: showDocument,
      onChange: setShowDocument
    })
  })) : __jsx(MaskTextInput, _extends({}, props, {
    name: name,
    label: label,
    value: value,
    onChange: (_value, e) => onPasswordChange({
      target: _objectSpread(_objectSpread({}, e.target), {}, {
        setSelectionRange: () => null
      })
    }),
    autoComplete: "new-password",
    type: "text",
    mask: "_",
    placeholder: placeholder || formatPlaceholder,
    format: format,
    suffix: __jsx(ShowHideToggle, {
      name: label === null || label === void 0 ? void 0 : label.toString(),
      show: showDocument,
      onChange: setShowDocument
    })
  }));
};
export const FormDocumentTextInput = withFormikWeb(DocumentTextInput);