var __jsx = React.createElement;
import React, { useCallback } from 'react';
import { NewestModal } from '@yieldstreet/ui-kit';
import { Modals } from 'constants/modal.model';
import { BankLinking } from 'pages/bankLinking/BankLinking';
import { useDefaultBankModal } from 'sharedComponents/DefaultBank/hooks/useDefaultBankModal';
import { useRouteInvestorEntityId } from 'pages/investorEntity/utils';
export const BankAccountsFlowModal = _ref => {
  let {
    investorEntityId,
    onClose,
    onSuccess
  } = _ref;
  const routeInvestorEntityId = useRouteInvestorEntityId();
  const selectedInvestorEntityId = investorEntityId || routeInvestorEntityId;
  const defaultBankModal = useDefaultBankModal({
    investorEntityId: selectedInvestorEntityId
  });
  const handleSuccess = useCallback(() => {
    onSuccess && onSuccess();
    defaultBankModal.open();
  }, [defaultBankModal, onSuccess]);
  return __jsx(NewestModal, {
    modalId: Modals.BankLinking,
    onClose: onClose
  }, __jsx(BankLinking, {
    investorEntityId: selectedInvestorEntityId,
    onLinkSuccess: handleSuccess
  }));
};