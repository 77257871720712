import { PortfolioStrategy, PortfolioStrategyValues } from '@yieldstreet/platform-kit';
import { InvestmentsListOriginTypes } from '../../../../useInvestmentsList.model';
import { ActiveUrls, MaturedUrls, PendingUrls } from './getInvestmentDetailsUrl.model';

/**
 * Returns investment url for debt and fund deals.
 */
export const getInvestmentDetailsUrl = _ref => {
  let {
    origin,
    record,
    managementStrategy
  } = _ref;
  const BASE_URL = managementStrategy === PortfolioStrategyValues.Discretionary ? `/portfolio/${PortfolioStrategy.Managed}` : `/portfolio/${PortfolioStrategy.SelfDirected}`;
  const TRAILING_URL = `${record.noteUrlHash}/${record.investorAccountId}`;
  const isFundInvestment = !!record.fundType;
  let pageSlug;
  switch (origin) {
    case InvestmentsListOriginTypes.Active:
      pageSlug = isFundInvestment ? ActiveUrls.Fund : ActiveUrls.Default;
      break;
    case InvestmentsListOriginTypes.Matured:
      pageSlug = isFundInvestment ? MaturedUrls.Fund : MaturedUrls.Default;
      break;
    case InvestmentsListOriginTypes.Pending:
      pageSlug = isFundInvestment ? PendingUrls.Fund : PendingUrls.Default;
      break;
    default:
      pageSlug = '';
  }
  return `${BASE_URL}/${pageSlug}/${TRAILING_URL}`;
};