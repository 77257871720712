export const mockPaymentActivityFundYs = {
  userId: 4,
  activity: [{
    date: '2021-05-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 5.65,
    cash: 5.65,
    cashLegacyFunds: 5.65,
    reInvest: 0,
    total: 5.65,
    totalNetFexpNetKnownCapital: 5.65,
    investmentsPerGraphItem: {
      MjZzZg: {
        status: 'ACTIVE',
        noteUrlHash: 'MjZzZg',
        title: 'Aviation Fund',
        assetClass: 'TRPT',
        launchDate: '2021-02-17',
        amount: 5.65,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2021-06-30',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 120.35,
    cash: 120.35,
    cashLegacyFunds: 120.35,
    reInvest: 0,
    total: 120.35,
    totalNetFexpNetKnownCapital: 120.35,
    investmentsPerGraphItem: {
      MjZzZg: {
        status: 'ACTIVE',
        noteUrlHash: 'MjZzZg',
        title: 'Aviation Fund',
        assetClass: 'TRPT',
        launchDate: '2021-02-17',
        amount: 120.35,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2021-07-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 99.7,
    cash: 99.7,
    cashLegacyFunds: 99.7,
    reInvest: 0,
    total: 99.7,
    totalNetFexpNetKnownCapital: 99.7,
    investmentsPerGraphItem: {
      MjZzZg: {
        status: 'ACTIVE',
        noteUrlHash: 'MjZzZg',
        title: 'Aviation Fund',
        assetClass: 'TRPT',
        launchDate: '2021-02-17',
        amount: 99.7,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2021-08-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 116.32,
    cash: 116.32,
    cashLegacyFunds: 116.32,
    reInvest: 0,
    total: 116.32,
    totalNetFexpNetKnownCapital: 116.32,
    investmentsPerGraphItem: {
      MjZzZg: {
        status: 'ACTIVE',
        noteUrlHash: 'MjZzZg',
        title: 'Aviation Fund',
        assetClass: 'TRPT',
        launchDate: '2021-02-17',
        amount: 116.32,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2021-09-30',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 117.82,
    cash: 117.82,
    cashLegacyFunds: 117.82,
    reInvest: 0,
    total: 117.82,
    totalNetFexpNetKnownCapital: 117.82,
    investmentsPerGraphItem: {
      MjZzZg: {
        status: 'ACTIVE',
        noteUrlHash: 'MjZzZg',
        title: 'Aviation Fund',
        assetClass: 'TRPT',
        launchDate: '2021-02-17',
        amount: 117.82,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2021-10-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 110.24,
    cash: 110.24,
    cashLegacyFunds: 110.24,
    reInvest: 0,
    total: 110.24,
    totalNetFexpNetKnownCapital: 110.24,
    investmentsPerGraphItem: {
      MjZzZg: {
        status: 'ACTIVE',
        noteUrlHash: 'MjZzZg',
        title: 'Aviation Fund',
        assetClass: 'TRPT',
        launchDate: '2021-02-17',
        amount: 110.24,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2021-11-30',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 113.92,
    cash: 113.92,
    cashLegacyFunds: 113.92,
    reInvest: 0,
    total: 113.92,
    totalNetFexpNetKnownCapital: 113.92,
    investmentsPerGraphItem: {
      MjZzZg: {
        status: 'ACTIVE',
        noteUrlHash: 'MjZzZg',
        title: 'Aviation Fund',
        assetClass: 'TRPT',
        launchDate: '2021-02-17',
        amount: 113.92,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2021-12-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 110.24,
    cash: 110.24,
    cashLegacyFunds: 110.24,
    reInvest: 0,
    total: 110.24,
    totalNetFexpNetKnownCapital: 110.24,
    investmentsPerGraphItem: {
      MjZzZg: {
        status: 'ACTIVE',
        noteUrlHash: 'MjZzZg',
        title: 'Aviation Fund',
        assetClass: 'TRPT',
        launchDate: '2021-02-17',
        amount: 110.24,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2022-01-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 101.41,
    cash: 101.41,
    cashLegacyFunds: 101.41,
    reInvest: 0,
    total: 101.41,
    totalNetFexpNetKnownCapital: 101.41,
    investmentsPerGraphItem: {
      MjZzZg: {
        status: 'ACTIVE',
        noteUrlHash: 'MjZzZg',
        title: 'Aviation Fund',
        assetClass: 'TRPT',
        launchDate: '2021-02-17',
        amount: 101.41,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2022-02-28',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 113.69,
    cash: 113.69,
    cashLegacyFunds: 113.69,
    reInvest: 0,
    total: 113.69,
    totalNetFexpNetKnownCapital: 113.69,
    investmentsPerGraphItem: {
      MjZzZg: {
        status: 'ACTIVE',
        noteUrlHash: 'MjZzZg',
        title: 'Aviation Fund',
        assetClass: 'TRPT',
        launchDate: '2021-02-17',
        amount: 113.69,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2022-03-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 113.67,
    cash: 113.67,
    cashLegacyFunds: 113.67,
    reInvest: 0,
    total: 113.67,
    totalNetFexpNetKnownCapital: 113.67,
    investmentsPerGraphItem: {
      MjZzZg: {
        status: 'ACTIVE',
        noteUrlHash: 'MjZzZg',
        title: 'Aviation Fund',
        assetClass: 'TRPT',
        launchDate: '2021-02-17',
        amount: 113.67,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2022-04-30',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 102.28,
    cash: 102.28,
    cashLegacyFunds: 102.28,
    reInvest: 0,
    total: 102.28,
    totalNetFexpNetKnownCapital: 102.28,
    investmentsPerGraphItem: {
      MjZzZg: {
        status: 'ACTIVE',
        noteUrlHash: 'MjZzZg',
        title: 'Aviation Fund',
        assetClass: 'TRPT',
        launchDate: '2021-02-17',
        amount: 102.28,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2022-05-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 98.98,
    cash: 98.98,
    cashLegacyFunds: 98.98,
    reInvest: 0,
    total: 98.98,
    totalNetFexpNetKnownCapital: 98.98,
    investmentsPerGraphItem: {
      MjZzZg: {
        status: 'ACTIVE',
        noteUrlHash: 'MjZzZg',
        title: 'Aviation Fund',
        assetClass: 'TRPT',
        launchDate: '2021-02-17',
        amount: 98.98,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2022-06-30',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 102.28,
    cash: 102.28,
    cashLegacyFunds: 102.28,
    reInvest: 0,
    total: 102.28,
    totalNetFexpNetKnownCapital: 102.28,
    investmentsPerGraphItem: {
      MjZzZg: {
        status: 'ACTIVE',
        noteUrlHash: 'MjZzZg',
        title: 'Aviation Fund',
        assetClass: 'TRPT',
        launchDate: '2021-02-17',
        amount: 102.28,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2022-07-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 0,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 0,
    totalNetFexpNetKnownCapital: 0,
    investmentsPerGraphItem: {}
  }, {
    date: '2022-08-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 188.02,
    cash: 188.02,
    cashLegacyFunds: 188.02,
    reInvest: 0,
    total: 188.02,
    totalNetFexpNetKnownCapital: 188.02,
    investmentsPerGraphItem: {
      MjZzZg: {
        status: 'ACTIVE',
        noteUrlHash: 'MjZzZg',
        title: 'Aviation Fund',
        assetClass: 'TRPT',
        launchDate: '2021-02-17',
        amount: 188.02,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2022-09-30',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 0,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 0,
    totalNetFexpNetKnownCapital: 0,
    investmentsPerGraphItem: {}
  }, {
    date: '2022-10-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 182.91,
    cash: 182.91,
    cashLegacyFunds: 182.91,
    reInvest: 0,
    total: 182.91,
    totalNetFexpNetKnownCapital: 182.91,
    investmentsPerGraphItem: {
      MjZzZg: {
        status: 'ACTIVE',
        noteUrlHash: 'MjZzZg',
        title: 'Aviation Fund',
        assetClass: 'TRPT',
        launchDate: '2021-02-17',
        amount: 182.91,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2022-11-30',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 91.0,
    cash: 91.0,
    cashLegacyFunds: 91.0,
    reInvest: 0,
    total: 91.0,
    totalNetFexpNetKnownCapital: 91.0,
    investmentsPerGraphItem: {
      MjZzZg: {
        status: 'ACTIVE',
        noteUrlHash: 'MjZzZg',
        title: 'Aviation Fund',
        assetClass: 'TRPT',
        launchDate: '2021-02-17',
        amount: 91.0,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2022-12-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 86.5,
    cash: 86.5,
    cashLegacyFunds: 86.5,
    reInvest: 0,
    total: 86.5,
    totalNetFexpNetKnownCapital: 86.5,
    investmentsPerGraphItem: {
      MjZzZg: {
        status: 'ACTIVE',
        noteUrlHash: 'MjZzZg',
        title: 'Aviation Fund',
        assetClass: 'TRPT',
        launchDate: '2021-02-17',
        amount: 86.5,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2023-01-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 0,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 0,
    totalNetFexpNetKnownCapital: 0,
    investmentsPerGraphItem: {}
  }, {
    date: '2023-02-28',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 178.82,
    cash: 178.82,
    cashLegacyFunds: 178.82,
    reInvest: 0,
    total: 178.82,
    totalNetFexpNetKnownCapital: 178.82,
    investmentsPerGraphItem: {
      MjZzZg: {
        status: 'ACTIVE',
        noteUrlHash: 'MjZzZg',
        title: 'Aviation Fund',
        assetClass: 'TRPT',
        launchDate: '2021-02-17',
        amount: 178.82,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2023-03-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 0,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 0,
    totalNetFexpNetKnownCapital: 0,
    investmentsPerGraphItem: {}
  }, {
    date: '2023-04-30',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 0,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 0,
    totalNetFexpNetKnownCapital: 0,
    investmentsPerGraphItem: {}
  }, {
    date: '2023-05-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 0,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 0,
    totalNetFexpNetKnownCapital: 0,
    investmentsPerGraphItem: {}
  }, {
    date: '2023-06-22',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 0,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 0,
    totalNetFexpNetKnownCapital: 0,
    investmentsPerGraphItem: {}
  }],
  total: {
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 2153.8,
    totalNetFexpNetKnownCapital: 2153.8,
    cash: 2153.8,
    cashLegacyFunds: 2153.8,
    reInvest: 0,
    total: 2153.8
  },
  investments: [{
    status: 'ACTIVE',
    noteUrlHash: 'MjZzZg',
    title: 'Aviation Fund',
    assetClass: 'TRPT',
    launchDate: '2021-02-17',
    amount: 2153.8,
    investmentType: 'FUND'
  }]
};