import update from 'immutability-helper';
import * as actions from 'store/actions/glossary';
const initialState = {
  terms: [],
  isFetchingTerms: false,
  fetchedTerms: false
};
const glossary = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case actions.GET_TERMS_ATTEMPT:
      {
        return update(state, {
          isFetchingTerms: {
            $set: true
          },
          fetchedTerms: {
            $set: false
          }
        });
      }
    case actions.GET_TERMS_SUCCESS:
      {
        const terms = action.response.map(item => {
          return {
            title: item.title.rendered,
            excerpt: item.excerpt.rendered,
            content: item.content.rendered,
            slug: item.slug,
            highlight: false
          };
        });
        return update(state, {
          isFetchingTerms: {
            $set: false
          },
          fetchedTerms: {
            $set: true
          },
          terms: {
            $set: terms
          }
        });
      }
    case actions.GET_TERMS_FAILURE:
      {
        return update(state, {
          isFetchingTerms: {
            $set: false
          },
          fetchedTerms: {
            $set: true
          }
        });
      }
    default:
      return state;
  }
};
export default glossary;