import styled from 'styled-components';
import { Heading, Label } from '../../content';
import { Card } from '../card';
export const OfferingThumb = styled.img.withConfig({
  displayName: "OfferingCardV2style__OfferingThumb",
  componentId: "sc-vdfyl2-0"
})(["border-radius:6px;min-height:140px;object-fit:cover;width:100%;transition:all 0.4s;"]);
export const SCard = styled(Card).withConfig({
  displayName: "OfferingCardV2style__SCard",
  componentId: "sc-vdfyl2-1"
})(["transition:all 0.4s;&:hover{box-shadow:", ";", "{scale:1.1;opacity:0.9;}}"], props => props.theme.shadows.shadow_hover_card, OfferingThumb);
export const BadgesContainer = styled.div.withConfig({
  displayName: "OfferingCardV2style__BadgesContainer",
  componentId: "sc-vdfyl2-2"
})(["display:flex;flex-direction:row;gap:", ";max-height:30px;width:100%;"], props => props.theme.spacing.xs);
export const TitleContainer = styled.div.withConfig({
  displayName: "OfferingCardV2style__TitleContainer",
  componentId: "sc-vdfyl2-3"
})(["align-items:flex-start;display:flex;flex-direction:column;gap:", ";width:100%;"], props => props.theme.spacing.xxs);
export const MainContainer = styled.div.withConfig({
  displayName: "OfferingCardV2style__MainContainer",
  componentId: "sc-vdfyl2-4"
})(["align-items:flex-start;display:flex;flex-direction:column;justify-content:space-between;height:115px;"]);
export const AssetClass = styled(Label).withConfig({
  displayName: "OfferingCardV2style__AssetClass",
  componentId: "sc-vdfyl2-5"
})(["color:", " !important;font-size:12px;letter-spacing:", ";margin-bottom:", ";text-transform:uppercase;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;max-width:100%;"], props => props.theme.colors.text_label_inverse, props => props.theme.spacing.xxs, props => props.theme.spacing.xxs);
export const OfferingTitle = styled(Heading).withConfig({
  displayName: "OfferingCardV2style__OfferingTitle",
  componentId: "sc-vdfyl2-6"
})(["max-width:100%;line-height:26px;height:100%;display:-webkit-box;-webkit-line-clamp:2;-webkit-box-orient:vertical;overflow:hidden;"]);
export const OfferingTitleWrapper = styled.div.withConfig({
  displayName: "OfferingCardV2style__OfferingTitleWrapper",
  componentId: "sc-vdfyl2-7"
})(["overflow:hidden;"]);
export const ContentContainer = styled.div.withConfig({
  displayName: "OfferingCardV2style__ContentContainer",
  componentId: "sc-vdfyl2-8"
})(["display:flex;flex-direction:column;height:100%;width:100%;"]);
export const MetricWrapper = styled.div.withConfig({
  displayName: "OfferingCardV2style__MetricWrapper",
  componentId: "sc-vdfyl2-9"
})(["display:flex;border-bottom:1px solid ", ";flex-direction:row;justify-content:space-between;padding:6px 0;width:100%;&:last-child{border:0;}&:first-child{padding-top:0;}"], props => props.theme.colors.border_default);