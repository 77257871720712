var __jsx = React.createElement;
import React, { useEffect, useRef, useState } from 'react';
import { PageLoader, PageSheet } from '@yieldstreet/ui-kit';
import { testIds } from './PlaidKYC.model';
import { useRouteInvestorEntityId } from '../../../../utils';
import { useKYCNextStep } from '../../utils';
import { identityApi, plaidApi } from '@yieldstreet/platform-kit';
import { usePlaidLink } from 'react-plaid-link';
import { useLegacyEffect } from '@yieldstreet/tool-kit';
export const PlaidKYC = () => {
  const investorEntityId = useRouteInvestorEntityId();
  const {
    useCreatePlaidKYCLinkTokenMutation
  } = plaidApi;
  const {
    useRefreshIdentityVerificationStatusMutation
  } = identityApi;
  const [createPlaidToken, {
    data,
    isLoading: isPlaidTokenLoading
  }] = useCreatePlaidKYCLinkTokenMutation();
  const [refreshPlaidStatus] = useRefreshIdentityVerificationStatusMutation();
  const {
    0: isDone,
    1: setIsDone
  } = useState(false);
  const isDoneRef = useRef(false);
  useLegacyEffect(() => {
    if (investorEntityId) {
      createPlaidToken({
        investorEntityId
      });
    }
  }, [investorEntityId, createPlaidToken]);
  const {
    isLoading: isNextStepLoading,
    redirectToNextStep
  } = useKYCNextStep(!isDone);
  const isLoading = isPlaidTokenLoading || isNextStepLoading;
  const {
    ready,
    open,
    exit,
    error
  } = usePlaidLink({
    token: (data === null || data === void 0 ? void 0 : data.linkToken) || '',
    onSuccess: () => {},
    onEvent: event => {
      // eslint-disable-next-line no-console
      console.log('Plaid KYC event', event);
      if (['IDENTITY_VERIFICATION_PASS_SESSION', 'IDENTITY_VERIFICATION_FAIL_SESSION', 'IDENTITY_VERIFICATION_PENDING_REVIEW_SESSION'].includes(event)) {
        setIsDone(true);
        isDoneRef.current = true;
        refreshPlaidStatus({
          investorEntityId
        });
      }
      if (event === 'IDENTITY_VERIFICATION_CLOSE_UI' && isDoneRef.current) {
        redirectToNextStep();
      }
    }
  });
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('Plaid KYC Error', error);
  }, [error]);
  useEffect(() => {
    return () => exit();
  }, [exit]);
  useEffect(() => {
    if (ready && investorEntityId) {
      open();
    }
  }, [open, ready, exit, investorEntityId]);
  return __jsx(PageSheet, {
    title: "Yieldstreet KYC",
    testId: testIds.base.id
  }, isLoading && __jsx(PageLoader, {
    testId: testIds.loader.id
  }));
};