import { isInvestorEntityStepDone, isAccreditedInvestorEntityStepDone } from './useInvestorEntityStepDone';
export const isInvestorEntityComplete = (entityStatus, hasSelfPortfolio) => {
  if (!entityStatus) {
    return false;
  }
  const {
    identity,
    bankAccounts,
    accreditation,
    accreditedSignUp,
    readyToInvest
  } = entityStatus;
  return readyToInvest && isInvestorEntityStepDone(identity) && isInvestorEntityStepDone(bankAccounts) && isAccreditedInvestorEntityStepDone(accreditation, accreditedSignUp) && hasSelfPortfolio;
};