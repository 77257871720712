import _Stack from "@mui/material/Stack";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import isEmpty from "lodash/isEmpty";
import { PageLoader } from '@yieldstreet/ui-kit/layouts';
import { allToken } from '@yieldstreet/platform-kit';
import { investorEntityApi, investorEntityStatusApi } from '@yieldstreet/platform-kit';
import { NewAccountsBlock } from './InvestorEntitySelection.style';
import { EmptyScreen } from '../../components';
import { EntityBlock } from '../../components';
import plusIcon from '../../assets/Plus.svg';
import { NEW_ACCOUNT_ROUTE, testIds } from './InvestorEntitySelection.model';
export const InvestorEntitySelection = () => {
  const history = useHistory();
  const {
    data: {
      entities = [],
      entitiesLimitReached
    } = {},
    isLoading: isLoadingInvestorEntities
  } = investorEntityApi.useGetInvestorEntityQuery(allToken, {
    refetchOnMountOrArgChange: true
  });
  const {
    data: allEntitiesStatus = {},
    isLoading: isLoadingEntityStatus,
    refetch
  } = investorEntityStatusApi.useGetAllInvestorEntityStatusQuery(allToken, {
    refetchOnMountOrArgChange: true
  });
  useEffect(() => {
    // This is needed as a temporary fix to update Investor Account Status ( Eventual consistancy ) sc-138591
    const timeout = setTimeout(refetch, 2000);
    return () => clearTimeout(timeout);
  }, [refetch]);
  const isLoading = isLoadingInvestorEntities || isLoadingEntityStatus;
  const entitiesBlock = useMemo(() => {
    return entities.map((entity, key) => __jsx(EntityBlock, {
      investorEntity: entity,
      key: key,
      allEntitiesStatus: allEntitiesStatus,
      refetch: refetch
    }));
  }, [allEntitiesStatus, entities, refetch]);
  if (isLoading) {
    return __jsx(PageLoader, {
      testId: testIds.pageLoader.id
    });
  }
  return __jsx(React.Fragment, null, isEmpty(entitiesBlock) ? __jsx(EmptyScreen, {
    heading: "You have not yet created an investor account",
    paragraph: "Please complete your account set-up to invest on Yieldstreet.",
    buttonText: "Get started",
    testId: testIds.getStarted.id,
    buttonAction: () => history.push(NEW_ACCOUNT_ROUTE)
  }) : __jsx(React.Fragment, null, __jsx(_Typography, {
    variant: "body1",
    sx: {
      mb: 8
    }
  }, "Select an account to view details, or create a new account from scratch."), __jsx(_Stack, {
    direction: {
      sm: 'row'
    },
    flexWrap: "wrap",
    gap: {
      xs: 8,
      sm: 4
    }
  }, entitiesBlock, entitiesLimitReached ? null : __jsx(NewAccountsBlock, _extends({}, testIds.createNewEntity.attr, {
    onClick: () => history.push(NEW_ACCOUNT_ROUTE)
  }), __jsx("img", _extends({
    width: 40,
    height: 40,
    src: plusIcon,
    alt: "Plus icon"
  }, testIds.plusIcon.attr))))));
};