import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["title", "subtitle", "acceptedFiles", "rejectedFiles", "showImagePreview", "onRemoveClick", "disableRemove"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { Heading, Paragraph } from '../../content/text';
import FileDropzone from './FileDropzone';
import FilePreviewImage from './FilePreviewImage';
import FilePreviewDocument from './FilePreviewDocument';
import { useTestIds } from './FileUpload.model';
export const FileUpload = props => {
  const {
      title,
      subtitle,
      acceptedFiles,
      rejectedFiles,
      showImagePreview = true,
      onRemoveClick,
      disableRemove = false
    } = props,
    otherProps = _objectWithoutProperties(props, _excluded);
  const hideUploadzone = !props.multiple && (acceptedFiles.length > 0 || rejectedFiles.length > 0);
  const testIds = useTestIds(props);
  return __jsx("div", testIds.base.attr, title && __jsx(TitleWrapper, null, __jsx(Heading, _extends({
    type: 5
  }, testIds.title.attr), title)), subtitle && __jsx(SubtitleWrapper, null, __jsx(Paragraph, testIds.subtitle.attr, subtitle)), !hideUploadzone && __jsx(FileDropzone, _extends({}, otherProps, {
    testId: testIds.dropzone.id
  })), acceptedFiles.length > 0 && acceptedFiles.map((file, idx) => {
    if (showImagePreview) {
      return __jsx(FilePreviewImage, {
        key: idx,
        file: file,
        onRemoveClick: () => !disableRemove && onRemoveClick ? onRemoveClick(file, 'ACCEPTED') : null,
        testId: testIds.imagePreviewAccepted.id
      });
    }
    return __jsx(FilePreviewDocument, {
      key: idx,
      file: file,
      hideUploadzone: hideUploadzone,
      onRemoveClick: () => !disableRemove && onRemoveClick ? onRemoveClick(file, 'ACCEPTED') : null,
      testId: testIds.documentPreviewAccepted.id
    });
  }), rejectedFiles.length > 0 && rejectedFiles.map((file, idx) => {
    if (showImagePreview) {
      return __jsx(FilePreviewImage, {
        key: idx,
        file: file,
        onRemoveClick: () => !disableRemove && onRemoveClick ? onRemoveClick(file, 'REJECTED') : null,
        testId: testIds.imagePreviewRejected.id
      });
    }
    return __jsx(FilePreviewDocument, {
      key: idx,
      file: file,
      hideUploadzone: hideUploadzone,
      onRemoveClick: () => !disableRemove && onRemoveClick ? onRemoveClick(file, 'REJECTED') : null,
      testId: testIds.documentPreviewRejected.id
    });
  }));
};
const TitleWrapper = styled.div.withConfig({
  displayName: "FileUpload__TitleWrapper",
  componentId: "sc-1sra4nt-0"
})(["margin-bottom:10px;"]);
const SubtitleWrapper = styled.div.withConfig({
  displayName: "FileUpload__SubtitleWrapper",
  componentId: "sc-1sra4nt-1"
})(["margin-bottom:20px;"]);