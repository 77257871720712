import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
export const useActions = function (actions) {
  let deps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  const dispatch = useDispatch();
  return useMemo(() => {
    if (Array.isArray(actions)) {
      return actions.map(a => bindActionCreators(a, dispatch));
    }
    return bindActionCreators(actions, dispatch);
  }, /* eslint-disable react-hooks/exhaustive-deps */
  [dispatch, actions, ...deps]);
};
export default useActions;