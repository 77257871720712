import { BankAccountsFlowModal } from 'sharedComponents/ModalList';
import { CapitalCallsModal } from 'sharedComponents/CapitalCallsModal';
import { DefaultBankModal } from 'sharedComponents/DefaultBank/DefaultBankModal';
import { ETCProductTour } from 'pages/marketplace/Marketplace/ETCProductTour';
import { FiltersMobileModal } from 'sharedComponents/Filters/FiltersMobileModal';
import { IraModal } from 'sharedComponents/IraModal';
import { OfferingModal } from 'sharedComponents/invest/OfferingModal';
import { PortfolioSimulatorModal } from 'sharedComponents/PortfolioSimulatorModal';
import { ReturnPaymentsModal } from 'pages/portfolio/Transactions/TransactionsList/ReturnPayments/ReturnPaymentsModal';
import { SortMobileModal } from 'sharedComponents/Filters/SortMobileModal';
import { TiersLetterModal } from 'pages/portfolio/Overview/OverviewSelfDirected/TiersLetter';
import { Modals } from './modal.model';
export const modalConfig = {
  [Modals.BankLinking]: {
    component: BankAccountsFlowModal
    // we can add more properties in the future, such as prop types
  },
  [Modals.CapitalCallInfo]: {
    component: CapitalCallsModal
  },
  [Modals.DefaultBank]: {
    component: DefaultBankModal
  },
  [Modals.ETCProductTour]: {
    component: ETCProductTour
  },
  [Modals.FiltersMobile]: {
    component: FiltersMobileModal
  },
  [Modals.IRA]: {
    component: IraModal
  },
  [Modals.PortfolioSimulator]: {
    component: PortfolioSimulatorModal
  },
  [Modals.ReturnPayments]: {
    component: ReturnPaymentsModal
  },
  [Modals.SortMobile]: {
    component: SortMobileModal
  },
  [Modals.TiersLetter]: {
    component: TiersLetterModal
  },
  // TODO: These can be one modal that accepts params
  // since the same modal component is being used.
  [Modals.OfferingModal]: {
    component: OfferingModal
  },
  [Modals.Rollover]: {
    component: OfferingModal
  },
  [Modals.Advice]: {
    component: OfferingModal
  }
};

// Moved from old modal.const.js
// TODO: Cleanup in https://app.shortcut.com/yieldstreet/story/143074
export const ADVICE_HOW_IT_WORKS_VIDEO = 'advice-how-it-works-video';
export const DELETE_BANK_ACCOUNT = 'DELETE_BANK_ACCOUNT';
export const DELETE_RECURRING_DEPOSIT = 'delete-recurring-deposit';
export const EDIT_BANK_NAME = 'EDIT_BANK_NAME';
export const HIGHLIGHT_SECTION = 'highlight-section';
export const INFO_TOOLTIP = 'info-tooltip';
export const NEW_BANK_ACCOUNT = 'new-bank-account';