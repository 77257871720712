var __jsx = React.createElement;
import React, { useState } from 'react';
import styled from 'styled-components';
import { PageSection } from '../../layout/page-section';
import { Heading, Paragraph } from '../../content/text';
import { CallbackLink } from '../../content/link';
import { media } from '../../themes';
export const Manifesto = _ref => {
  let {
    title,
    summaryTitle,
    summary,
    children
  } = _ref;
  const {
    0: expanded,
    1: setExpanded
  } = useState(false);
  const expandedClick = () => {
    setExpanded(!expanded);
  };
  return __jsx(PageSection, null, __jsx(Container, null, __jsx(TitleHolder, null, __jsx(Title, {
    type: 3
  }, title)), __jsx(ContentHolder, null, __jsx(SummaryHolder, null, summaryTitle && __jsx(SubTitle, {
    semiBold: true
  }, summaryTitle), summary && __jsx(Text, null, summary)), __jsx(Content, {
    expanded: expanded
  }, children), __jsx(CallbackLink, {
    callback: () => expandedClick()
  }, expanded ? `Collapse` : `Expand`))));
};
const Container = styled.div.withConfig({
  displayName: "Manifesto__Container",
  componentId: "sc-1q31irr-0"
})(["padding:20px;display:flex;flex-wrap:wrap;flex-direction:'column';", ""], media.desktop`
    flex-direction: 'row';
    padding: 0 130px 100px 130px;
  `);
const TitleHolder = styled.div.withConfig({
  displayName: "Manifesto__TitleHolder",
  componentId: "sc-1q31irr-1"
})(["width:100%;", ""], media.desktop`
    margin: 60px 0 0 0;
    width: 50%;
  `);
const ContentHolder = styled.div.withConfig({
  displayName: "Manifesto__ContentHolder",
  componentId: "sc-1q31irr-2"
})(["width:100%;margin:20px 0 0 0;", " p{margin:0 0 20px 0;}"], media.desktop`
    width: 50%;
    margin: 75px 0 0 0;
  `);
const Title = styled(Heading).withConfig({
  displayName: "Manifesto__Title",
  componentId: "sc-1q31irr-3"
})(["width:100%;", ""], media.desktop`
    width: 60%;
  `);
const Content = styled.div.withConfig({
  displayName: "Manifesto__Content",
  componentId: "sc-1q31irr-4"
})(["max-height:", ";transition:max-height 0.5s ", ";overflow:hidden;height:auto;"], props => props.expanded ? `2000px` : 0, props => props.expanded ? `ease-in` : `ease-out`);
const SummaryHolder = styled.div.withConfig({
  displayName: "Manifesto__SummaryHolder",
  componentId: "sc-1q31irr-5"
})([""]);
const SubTitle = styled(Paragraph).withConfig({
  displayName: "Manifesto__SubTitle",
  componentId: "sc-1q31irr-6"
})([""]);
const Text = styled(Paragraph).withConfig({
  displayName: "Manifesto__Text",
  componentId: "sc-1q31irr-7"
})([""]);