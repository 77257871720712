import { HttpMethods } from '../../../enums';
import { restApi, Tags } from '../../common';
export const documentsApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.PortfolioDocuments, Tags.PortfolioDocumentsYears]
}).injectEndpoints({
  endpoints: build => {
    const getDocumentsYears = build.query({
      query: _ref => {
        let {
          investorId,
          noteUrlHash,
          userId,
          managementStrategy
        } = _ref;
        return {
          url: `/a/api/portfolio/documents/available-years`,
          method: HttpMethods.Get,
          params: {
            account: investorId,
            urlHash: noteUrlHash,
            user: userId,
            managementStrategy
          }
        };
      },
      providesTags: [Tags.PortfolioDocumentsYears],
      transformResponse: _ref2 => {
        let {
          years
        } = _ref2;
        return {
          years
        };
      }
    });
    const getTaxDocuments = build.query({
      query: _ref3 => {
        let {
          investorId,
          noteUrlHash,
          userId,
          year,
          managementStrategy
        } = _ref3;
        return {
          url: `/a/api/portfolio/documents`,
          method: HttpMethods.Get,
          params: {
            account: investorId,
            urlHash: noteUrlHash,
            user: userId,
            year,
            managementStrategy
          }
        };
      },
      providesTags: [Tags.PortfolioDocuments],
      transformResponse: _ref4 => {
        let {
          documents
        } = _ref4;
        return {
          documents
        };
      }
    });
    const downloadTaxDocuments = build.mutation({
      query: _ref5 => {
        let {
          userId,
          taxDocumentIds
        } = _ref5;
        return {
          url: '/a/api/portfolio/documents/download-list',
          method: HttpMethods.Post,
          data: {
            user: userId,
            taxDocumentIds
          },
          responseType: 'blob'
        };
      },
      transformResponse: (response, headers) => {
        return {
          file: response,
          headers
        };
      }
    });
    const getTaxDocumentsList = build.query({
      query: _ref6 => {
        let {
          account,
          urlHashes,
          user,
          years,
          managementStrategy,
          taxDocumentTypes
        } = _ref6;
        return {
          url: `/a/api/portfolio/documents/list`,
          method: HttpMethods.Get,
          params: {
            account,
            urlHashes,
            user,
            years,
            managementStrategy,
            taxDocumentTypes
          }
        };
      },
      providesTags: [Tags.PortfolioDocuments],
      transformResponse: _ref7 => {
        let {
          documents
        } = _ref7;
        return {
          documents
        };
      }
    });
    return {
      getDocumentsYears,
      getTaxDocuments,
      downloadTaxDocuments,
      getTaxDocumentsList
    };
  }
});