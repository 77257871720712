import { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { useAuth } from '@yieldstreet/tool-kit';
import { trackLoginComplete, trackSignupComplete } from './useAuth.utils';
export const useAuthTracking = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const {
    userState,
    isAuthenticated,
    isAccreditationSubmitted,
    isSignupSession
  } = useAuth();
  const {
    0: authTrack,
    1: setAuthTrack
  } = useState();
  useEffect(() => {
    if (isAuthenticated && isAccreditationSubmitted && authTrack) {
      // Redirect to into the logged in experience after checking redirect rules
      if (isSignupSession) {
        // Signup
        trackSignupComplete(userState);
      } else {
        // Login
        trackLoginComplete();
      }
    }
  }, [dispatch, history, location, userState, authTrack, isAuthenticated, isAccreditationSubmitted, isSignupSession]);
  return useCallback(() => setAuthTrack(true), [setAuthTrack]);
};