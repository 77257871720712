import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { InvestorType } from '@yieldstreet/platform-kit';
import { createYupObjectSchema, extendedNameValidation } from '@yieldstreet/tool-kit';
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import { getAccountPayload } from '../../CreateInvestorAccount.utils';
import { formNames, pageStepContent } from './Name.model';
export const buildPostData = _ref => {
  let {
    investorType,
    values,
    iraType,
    contact,
    retirementAccount = false
  } = _ref;
  const specificAttributes = getAccountPayload({
    values,
    investorType,
    iraType,
    retirementAccount,
    contact
  });
  const {
    name,
    firstName,
    lastName,
    type,
    entityType,
    isIra,
    iraAccountType
  } = specificAttributes;
  const postData = {
    name,
    firstName,
    lastName,
    type,
    entityType,
    isIra,
    iraAccountType,
    retirementAccount
  };
  return pickBy(postData, identity);
};
const getFormValidation = accountType => {
  let validationObj;
  if (accountType === InvestorType.INDIVIDUAL) {
    validationObj = {
      name: extendedNameValidation({
        fieldName: formNames.name,
        isRequired: true
      })
    };
  } else {
    validationObj = {
      firstName: extendedNameValidation()
    };
  }
  if (accountType !== InvestorType.INDIVIDUAL && accountType !== InvestorType.IRA) {
    validationObj = _objectSpread({}, validationObj);
  }
  return _objectSpread({}, validationObj);
};
export const getFormNameConfig = (initialValues, accountType) => {
  return {
    fieldNames: [formNames.firstName, formNames.lastName],
    validation: createYupObjectSchema(getFormValidation(accountType)),
    formikConfig: {
      initialValues: initialValues
    }
  };
};
export const getPageStepContent = _ref2 => {
  let {
    onClick
  } = _ref2;
  return _objectSpread(_objectSpread({}, pageStepContent), {}, {
    cta: _objectSpread(_objectSpread({}, pageStepContent.cta), {}, {
      callback: onClick
    })
  });
};