import { TransactionsListLabel, genTestIds } from '@yieldstreet/tool-kit';
export const headerItems = [{
  label: TransactionsListLabel.Date
}, {
  label: TransactionsListLabel.From
}, {
  label: TransactionsListLabel.To
}, {
  label: TransactionsListLabel.Type
}, {
  label: TransactionsListLabel.Status
}, {
  label: TransactionsListLabel.Amount
}];
export const {
  getTestIds
} = genTestIds(['headerItem']);
export const testIds = getTestIds('transactions-list-desktop-table-header');