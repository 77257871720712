import { Paragraph, Heading, media } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
export const Wrapper = styled.div.withConfig({
  displayName: "MetricsCardstyle__Wrapper",
  componentId: "sc-19vnwc0-0"
})(["width:100%;background:", ";border:1px solid ", ";border-radius:8px;", ";"], props => props.theme.colors.background_card, props => props.theme.colors.border_default, media.tablet`
    max-width: 1180px;
    flex: 1;
  `);
export const Title = styled(Heading).withConfig({
  displayName: "MetricsCardstyle__Title",
  componentId: "sc-19vnwc0-1"
})(["padding:", ";", ";"], props => props.theme.spacing.sm, props => props.top && `padding-top: ${props.top}px`);
export const Seperator = styled.div.withConfig({
  displayName: "MetricsCardstyle__Seperator",
  componentId: "sc-19vnwc0-2"
})(["display:block;width:100%;height:1px;background-color:", ";"], props => props.theme.colors.border_default);
export const MetricWrapper = styled.dd.withConfig({
  displayName: "MetricsCardstyle__MetricWrapper",
  componentId: "sc-19vnwc0-3"
})(["display:flex;flex-direction:row;justify-content:space-between;font-size:14px;line-height:24px;padding:0 ", ";margin:", ";"], props => props.theme.spacing.sm, props => props.extraSpace ? `
    ${props.theme.spacing.ml} 0 ${props.theme.spacing.sm}
  ` : `
    ${props.theme.spacing.sm} 0
  `);
export const TooltipWrapper = styled.div.withConfig({
  displayName: "MetricsCardstyle__TooltipWrapper",
  componentId: "sc-19vnwc0-4"
})(["margin-left:", ";display:inline-flex;white-space:normal;"], props => props.theme.spacing.xxs);
const getLabelColor = (color, theme, fallback) => {
  switch (color) {
    case 'dark':
      return theme.colors.text_header;
    case 'light':
      return theme.colors.text_label;
    default:
      return fallback;
  }
};
export const Label = styled(Paragraph).withConfig({
  displayName: "MetricsCardstyle__Label",
  componentId: "sc-19vnwc0-5"
})(["display:flex;white-space:nowrap;color:", ";", ";"], props => getLabelColor(props === null || props === void 0 ? void 0 : props.color, props.theme, props.theme.colors.text_label), props => props.size === 'small' && `
    font-size: 14px;
    line-height: ${props.theme.spacing.sm};
  `);
export const Description = styled(Paragraph).withConfig({
  displayName: "MetricsCardstyle__Description",
  componentId: "sc-19vnwc0-6"
})(["text-align:right;color:", ";", ";"], props => getLabelColor(props === null || props === void 0 ? void 0 : props.color, props.theme, props.theme.colors.text_secondary_paragraph), props => props.size === 'small' && `
    font-size: 14px;
    line-height: ${props.theme.spacing.sm};
  `);