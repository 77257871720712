import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["bank", "separator"];
var __jsx = React.createElement;
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useModalContext } from '@yieldstreet/ui-kit';
import { Details, DetailsButton, DetailsLabel, DetailsValue, Wrapper } from './KYCMigrationBankDetails.style';
import { useTestIds } from './KYCMigrationBankDetails.model';
import { BankAccountProvider } from '@yieldstreet/platform-kit';
export const KYCMigrationBankDetails = _ref => {
  let {
      bank,
      separator
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  const testIds = useTestIds(props);
  const history = useHistory();
  const {
    hideModal
  } = useModalContext();
  const handleOnClick = useCallback(bank => {
    hideModal();
    history.push(`/investor/manage/${bank.holderId}/bank-accounts`);
  }, [hideModal, history]);
  if (!bank || bank.provider === BankAccountProvider.SYNAPSE) {
    return null;
  }
  return __jsx(Wrapper, _extends({
    separator: separator
  }, testIds.base.attr), __jsx(Details, _extends({
    greedy: true
  }, testIds.cell.attr), __jsx(DetailsLabel, _extends({
    small: true
  }, testIds.accountLabel.attr), "External account name"), __jsx(DetailsValue, _extends({
    size: "chart"
  }, testIds.account.attr), bank === null || bank === void 0 ? void 0 : bank.nickname)), __jsx(Details, testIds.cell.attr, __jsx(DetailsLabel, _extends({
    small: true
  }, testIds.bankNameLabel.attr), "Bank name"), __jsx(DetailsValue, _extends({
    size: "chart"
  }, testIds.bankName.attr), bank === null || bank === void 0 ? void 0 : bank.bankName)), __jsx(Details, testIds.cell.attr, __jsx(DetailsLabel, _extends({
    small: true
  }, testIds.accountNoLabel.attr), "Account number"), __jsx(DetailsValue, _extends({
    size: "chart"
  }, testIds.accountNo.attr), bank === null || bank === void 0 ? void 0 : bank.maskedAccountNumber.replaceAll('X', '*'))), __jsx(Details, testIds.cell.attr, __jsx(DetailsValue, {
    size: "small"
  }, __jsx(DetailsButton, {
    onClick: () => handleOnClick(bank)
  }, "Change"))));
};