import { FundProvider, FundType } from '../../types';
import { LegalEntityType, PerformanceStatus } from '../../enums';
export const portfolioInvestmentsByStatus = {
  userId: 4,
  byStatus: {
    ACTIVE: {
      investments: [{
        noteUrlHash: 'RUadew',
        noteTitle: 'Vessel Deconstruction VI',
        investorAccountId: '1',
        legalEntityType: LegalEntityType.BPDN,
        launchedDate: '2019-09-29',
        terminationDate: '2020-03-14',
        termPhrase: 'N/A',
        netAssetValue: 0,
        sharesCount: 0,
        investmentAmount: 9000,
        irr: 0,
        interestRate: 10.25,
        distributionRate: 0,
        reinvestedFundDistributions: 0,
        principalOutstanding: 9000,
        principalOutstandingPreDefault: 0,
        principalOutstandingPostDefault: 9000,
        principalRepaid: 0,
        interestAccruedPreDefault: 0,
        interestAccruedPostDefault: 1585.56,
        interestAccrued: 1585.56,
        interestEarned: 2052.5,
        interestPaid: 466.94,
        netInterestPaid: 0,
        fexpPaid: 0,
        currentValue: 10585.56,
        netTotalRepaid: 0,
        totalRepaid: 466.94,
        performanceStatus: PerformanceStatus.DEFAULT,
        performanceStatusNote: 'On March 6, 2020, YS GM MarFin X LLC (“YS SPV” or “Lender”) issued a notice of default and acceleration, cancelling the facility and demanding full and immediate repayment of the outstanding loan.',
        returnsPaid: 366.94,
        requiresUserAction: false,
        fundType: null,
        fundProvider: null
      }],
      totals: {
        irr: 0,
        interestRate: 8.87,
        investmentAmount: 208358.88,
        principalOutstanding: 166404.66,
        principalOutstandingPreDefault: 136240.94,
        principalOutstandingPostDefault: 30163.72,
        principalRepaid: 32994.22,
        interestAccruedPreDefault: 13826.13,
        interestAccruedPostDefault: 6528.34,
        interestAccrued: 20354.47,
        interestEarned: 29234.41,
        interestPaid: 8879.94,
        netInterestPaid: 0,
        fexpPaid: 0,
        currentValue: 193759.13,
        totalRepaid: 41874.16,
        netTotalRepaid: 0,
        returnsPaid: 7639.94
      }
    },
    PENDING: {
      investments: [{
        noteUrlHash: 'irovFQ',
        noteTitle: 'Alternative Fund Via IR Service',
        investorAccountId: '1',
        legalEntityType: LegalEntityType.FUND,
        launchedDate: '2020-07-15',
        terminationDate: null,
        termPhrase: '0 Mo.',
        netAssetValue: 0,
        sharesCount: 0,
        investmentAmount: 10000,
        irr: 0,
        interestRate: 0,
        distributionRate: 0,
        reinvestedFundDistributions: 0,
        principalOutstanding: 0,
        principalOutstandingPreDefault: 0,
        principalOutstandingPostDefault: 0,
        principalRepaid: 0,
        interestAccruedPreDefault: 0,
        interestAccruedPostDefault: 0,
        interestAccrued: 0,
        interestEarned: 0,
        interestPaid: 0,
        netInterestPaid: 0,
        fexpPaid: 0,
        currentValue: 0,
        netTotalRepaid: 0,
        totalRepaid: 0,
        performanceStatus: PerformanceStatus.NONE,
        performanceStatusNote: null,
        returnsPaid: 0,
        requiresUserAction: false,
        fundType: FundType.FeederFund,
        fundProvider: FundProvider.Ys
      }],
      totals: {
        irr: 0,
        interestRate: 9.36,
        investmentAmount: 829234,
        principalOutstanding: 0,
        principalOutstandingPreDefault: 0,
        principalOutstandingPostDefault: 0,
        principalRepaid: 0,
        interestAccruedPreDefault: 0,
        interestAccruedPostDefault: 0,
        interestAccrued: 0,
        interestEarned: 0,
        interestPaid: 0,
        netInterestPaid: 0,
        fexpPaid: 0,
        currentValue: 0,
        totalRepaid: 0,
        netTotalRepaid: 0,
        returnsPaid: 0
      }
    },
    MATURED: {
      investments: [{
        noteUrlHash: '1206',
        noteTitle: 'Diversified Pre-settlement Portfolio IX',
        investorAccountId: '1',
        legalEntityType: LegalEntityType.SPVM,
        launchedDate: '2016-08-30',
        terminationDate: '2019-08-30',
        termPhrase: '33 Mo',
        netAssetValue: 0,
        sharesCount: 0,
        investmentAmount: 5000,
        irr: 13.69,
        interestRate: 13,
        distributionRate: 0,
        reinvestedFundDistributions: 0,
        principalOutstanding: 0,
        principalOutstandingPreDefault: 0,
        principalOutstandingPostDefault: 0,
        principalRepaid: 5000,
        interestAccruedPreDefault: 0,
        interestAccruedPostDefault: 0,
        interestAccrued: 0,
        interestEarned: 962.53,
        interestPaid: 962.53,
        netInterestPaid: 962.53,
        fexpPaid: 0,
        currentValue: 0,
        netTotalRepaid: 5962.53,
        totalRepaid: 5962.53,
        performanceStatus: PerformanceStatus.NONE,
        performanceStatusNote: null,
        returnsPaid: 962.53,
        requiresUserAction: false,
        fundType: null,
        fundProvider: null
      }],
      totals: {
        irr: 12.24,
        interestRate: 0,
        investmentAmount: 130010,
        principalOutstanding: 10,
        principalOutstandingPreDefault: 10,
        principalOutstandingPostDefault: 0,
        principalRepaid: 130000,
        interestAccruedPreDefault: 0.11,
        interestAccruedPostDefault: 0,
        interestAccrued: 0.11,
        interestEarned: 16488.09,
        interestPaid: 16488.09,
        netInterestPaid: 16288.09,
        fexpPaid: 200,
        currentValue: 0,
        totalRepaid: 146488.09,
        netTotalRepaid: 146288.09,
        returnsPaid: 16288.09
      }
    }
  }
};