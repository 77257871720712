import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { SelectInput } from '../select-input';
const SelectFilterContainer = styled.div.withConfig({
  displayName: "SelectGroupInput__SelectFilterContainer",
  componentId: "sc-1jjq8hd-0"
})([".MuiFormControl-root{", "}"], _ref => {
  let {
    styling
  } = _ref;
  return styling;
});
const Wrapper = _ref2 => {
  let {
    fullWidth,
    children,
    styling
  } = _ref2;
  return fullWidth ? children : __jsx(SelectFilterContainer, {
    styling: styling
  }, children);
};
export const SelectGroupInput = props => {
  const {
    options,
    styling
  } = props;
  return __jsx(React.Fragment, null, options === null || options === void 0 ? void 0 : options.map((option, index) => __jsx(Wrapper, {
    fullWidth: option.fullWidth,
    key: `${option.label}${index}`,
    styling: styling
  }, __jsx(SelectInput, _extends({
    testId: (option === null || option === void 0 ? void 0 : option.name) || 'select',
    key: `${option.label}-${index}`
  }, option)))));
};