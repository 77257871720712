import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["background", "children", "id", "light", "testId"];
var __jsx = React.createElement;
import React from 'react';
import { PageContainerContent, PageContainerWrapper } from './PageContainer.style';
import { useTestIds } from './PageContainer.model';
export const PageContainer = _ref => {
  let {
      background,
      children,
      id,
      light = false,
      testId
    } = _ref,
    containerProps = _objectWithoutProperties(_ref, _excluded);
  const testIds = useTestIds({
    testId: testId || id || ''
  });
  return __jsx(PageContainerWrapper, _extends({
    background: background,
    light: light
  }, testIds.base.attr), __jsx(PageContainerContent, containerProps, children));
};