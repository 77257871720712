import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["noteUrlHash"],
  _excluded2 = ["noteUrlHash", "investmentId"],
  _excluded3 = ["noteUrlHash", "investmentId"],
  _excluded4 = ["groupingByInvestmentId"],
  _excluded5 = ["noteUrlHash", "investmentRequestId"],
  _excluded6 = ["noteUrlHash", "investmentRequestId"],
  _excluded7 = ["urlHash"],
  _excluded8 = ["urlHash", "investmentRequestId"];
import { HttpMethods } from '../../../enums/http';
import { SocketManagerReconnectionStrategy, websocketManager } from '../../../websocket';
import { Tags, restApi } from '../../common';
import { selectConfig } from '../../config';
const BASE_URL = '/a/api/investment-request/offerings';
const SOCKET_URL = '/a/api/investment-request/ws/offerings/investments';
export const investmentRequestOfferingsApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.InvestmentRequest, Tags.InvestmentRequestBulk, Tags.InvestmentSign, Tags.InvestmentSignBulk, Tags.InvestmentUpdatesSubscription, Tags.InvestmentTrackingUpdatesSubscription, Tags.InvestmentTrackingDetailedUpdatesSubscription, Tags.InvestmentLiquidation, Tags.InvestmentTrackingSubmitTransfer]
}).injectEndpoints({
  endpoints: builder => {
    /**
     * POST a single pre-investment requests
     */
    const preCreate = builder.mutation({
      query: _ref => {
        let {
            noteUrlHash
          } = _ref,
          data = _objectWithoutProperties(_ref, _excluded);
        return {
          url: `${BASE_URL}/${noteUrlHash}/investments/pre`,
          method: HttpMethods.Post,
          data
        };
      },
      invalidatesTags: (_result, _error) => [{
        type: Tags.InvestmentRequest
      }]
    });

    /**
     * Get Investment Request
     * (POST due to BE restrictions)
     */
    const getInvestmentRequest = builder.mutation({
      query: data => ({
        url: `${BASE_URL}/investments`,
        method: HttpMethods.Post,
        data
      }),
      invalidatesTags: (_result, _error) => [{
        type: Tags.InvestmentRequest
      }],
      transformResponse: response => response.investments
    });

    /**
     * Bulk pre-create
     */
    const bulkPreCreate = builder.mutation({
      query: data => ({
        url: `${BASE_URL}/investments/pre/bulk`,
        method: HttpMethods.Post,
        data
      }),
      invalidatesTags: (_result, _error) => [{
        type: Tags.InvestmentRequestBulk
      }]
    });

    /**
     * Create
     */
    const create = builder.mutation({
      query: _ref2 => {
        let {
            noteUrlHash,
            investmentId
          } = _ref2,
          data = _objectWithoutProperties(_ref2, _excluded2);
        const investmentIdUrl = investmentId ? `/${investmentId}` : '';
        return {
          url: `${BASE_URL}/${noteUrlHash}/investments${investmentIdUrl}`,
          method: HttpMethods.Post,
          data
        };
      },
      invalidatesTags: (_result, _error) => [{
        type: Tags.InvestmentRequest
      }]
    });

    /**
     * Bulk create
     */
    const bulkCreate = builder.mutation({
      query: data => ({
        url: `${BASE_URL}/investments/bulk`,
        method: HttpMethods.Post,
        data
      }),
      invalidatesTags: (_result, _error) => [{
        type: Tags.InvestmentRequestBulk
      }]
    });

    /**
     * Sign
     */
    const sign = builder.mutation({
      query: _ref3 => {
        let {
            noteUrlHash,
            investmentId
          } = _ref3,
          data = _objectWithoutProperties(_ref3, _excluded3);
        return {
          url: `${BASE_URL}/${noteUrlHash}/investments/${investmentId}`,
          method: HttpMethods.Put,
          data
        };
      },
      invalidatesTags: (_result, _error) => [{
        type: Tags.InvestmentSign
      }]
    });

    /**
     * Bulk sign
     */
    const bulkSign = builder.mutation({
      query: data => ({
        url: `${BASE_URL}/investments/bulk`,
        method: HttpMethods.Put,
        data
      }),
      invalidatesTags: (_result, _error) => [{
        type: Tags.InvestmentSignBulk
      }]
    });

    /**
     * Subscribe to status updates
     */
    const subscribeInvestmentUpdates = builder.query({
      queryFn: () => ({
        data: {}
      }),
      providesTags: [Tags.InvestmentUpdatesSubscription],
      async onCacheEntryAdded(_ref4, _ref5) {
        let {
          cacheDataLoaded,
          cacheEntryRemoved,
          getState,
          updateCachedData
        } = _ref5;
        let {
            groupingByInvestmentId
          } = _ref4,
          payload = _objectWithoutProperties(_ref4, _excluded4);
        const {
          wssHost
        } = selectConfig(getState());
        const onMessage = (_, ev) => {
          const data = JSON.parse(ev.data);
          updateCachedData(draft => {
            var _data$investment, _data$investment2;
            return groupingByInvestmentId ? Object.assign(draft, {
              [data === null || data === void 0 ? void 0 : (_data$investment = data.investment) === null || _data$investment === void 0 ? void 0 : _data$investment.id]: data
            }) : Object.assign(draft, {
              [data === null || data === void 0 ? void 0 : (_data$investment2 = data.investment) === null || _data$investment2 === void 0 ? void 0 : _data$investment2.noteUrlHash]: data
            });
          });
        };
        const onOpen = () => {
          websocketManager.send(Tags.InvestmentUpdatesSubscription, JSON.stringify(payload));
        };
        try {
          await cacheDataLoaded;
          websocketManager.remove(Tags.InvestmentUpdatesSubscription);
          websocketManager.add(Tags.InvestmentUpdatesSubscription, {
            url: `${wssHost}${SOCKET_URL}`,
            reconnectionStrategy: SocketManagerReconnectionStrategy.retry
          }, {
            onMessage,
            onOpen
          });
          await cacheEntryRemoved;
        } finally {
          websocketManager.unsubscribe(Tags.InvestmentUpdatesSubscription);
        }
      }
    });
    const subscribeTrackingInvestmentUpdates = builder.query({
      queryFn: () => ({
        data: {}
      }),
      providesTags: [Tags.InvestmentTrackingUpdatesSubscription],
      async onCacheEntryAdded(_ref6, _ref7) {
        let {
          cacheDataLoaded,
          cacheEntryRemoved,
          getState,
          updateCachedData
        } = _ref7;
        let {
            noteUrlHash,
            investmentRequestId
          } = _ref6,
          payload = _objectWithoutProperties(_ref6, _excluded5);
        const {
          wssHost
        } = selectConfig(getState());
        const onMessage = (_, ev) => {
          const data = JSON.parse(ev.data);
          updateCachedData(draft => Object.assign(draft, {
            [investmentRequestId]: data
          }));
        };
        const onOpen = () => {
          websocketManager.send(Tags.InvestmentTrackingUpdatesSubscription, JSON.stringify(payload));
        };
        try {
          await cacheDataLoaded;
          websocketManager.remove(Tags.InvestmentTrackingUpdatesSubscription);
          let path = `/a/api/investment-request/ws/offerings/${noteUrlHash}/investments/${investmentRequestId}`;
          websocketManager.add(Tags.InvestmentTrackingUpdatesSubscription, {
            url: `${wssHost}${path}`,
            reconnectionStrategy: SocketManagerReconnectionStrategy.retry
          }, {
            onMessage,
            onOpen
          });
          await cacheEntryRemoved;
        } finally {
          websocketManager.unsubscribe(Tags.InvestmentTrackingUpdatesSubscription);
        }
      }
    });
    const subscribeTrackingInvestmentDetailedUpdates = builder.query({
      queryFn: () => ({
        data: {}
      }),
      providesTags: [Tags.InvestmentTrackingDetailedUpdatesSubscription],
      async onCacheEntryAdded(_ref8, _ref9) {
        let {
          cacheDataLoaded,
          cacheEntryRemoved,
          getState,
          updateCachedData
        } = _ref9;
        let {
            noteUrlHash,
            investmentRequestId
          } = _ref8,
          payload = _objectWithoutProperties(_ref8, _excluded6);
        const {
          wssHost
        } = selectConfig(getState());
        const onMessage = (_, ev) => {
          const data = JSON.parse(ev.data);
          updateCachedData(draft => Object.assign(draft, {
            [investmentRequestId]: data
          }));
        };
        const onOpen = () => {
          websocketManager.send(Tags.InvestmentTrackingUpdatesSubscription, JSON.stringify(payload));
        };
        try {
          await cacheDataLoaded;
          websocketManager.remove(Tags.InvestmentTrackingDetailedUpdatesSubscription);
          let path = `/a/api/investment-request/ws/offerings/${noteUrlHash}/investments/${investmentRequestId}/detailed`;
          websocketManager.add(Tags.InvestmentTrackingDetailedUpdatesSubscription, {
            url: `${wssHost}${path}`,
            reconnectionStrategy: SocketManagerReconnectionStrategy.retry
          }, {
            onMessage,
            onOpen
          });
          await cacheEntryRemoved;
        } finally {
          websocketManager.unsubscribe(Tags.InvestmentTrackingDetailedUpdatesSubscription);
        }
      }
    });
    const getInvestorPosition = builder.query({
      query: _ref10 => {
        let {
          noteUrlHash,
          investorAccountId
        } = _ref10;
        return {
          url: `${BASE_URL}/${noteUrlHash}/investoraccount/${investorAccountId}/current-position`,
          method: HttpMethods.Get
        };
      },
      transformResponse: response => response.investorPosition
    });
    const liquidation = builder.mutation({
      query: _ref11 => {
        let {
            urlHash
          } = _ref11,
          payload = _objectWithoutProperties(_ref11, _excluded7);
        return {
          url: `${BASE_URL}/${urlHash}/liquidations`,
          method: HttpMethods.Post,
          data: payload
        };
      },
      invalidatesTags: (_result, _error) => [{
        type: Tags.InvestmentLiquidation
      }]
    });
    const submitTransfer = builder.mutation({
      query: _ref12 => {
        let {
            urlHash,
            investmentRequestId
          } = _ref12,
          payload = _objectWithoutProperties(_ref12, _excluded8);
        return {
          url: `${BASE_URL}/${urlHash}/investments/${investmentRequestId}/post`,
          method: HttpMethods.Post,
          data: payload
        };
      },
      invalidatesTags: (_result, _error) => [{
        type: Tags.InvestmentTrackingSubmitTransfer
      }]
    });
    return {
      liquidation,
      preCreate,
      bulkPreCreate,
      create,
      bulkCreate,
      sign,
      bulkSign,
      subscribeInvestmentUpdates,
      subscribeTrackingInvestmentUpdates,
      subscribeTrackingInvestmentDetailedUpdates,
      getInvestmentRequest,
      getInvestorPosition,
      submitTransfer
    };
  }
});