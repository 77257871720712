var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { theme2, media } from '../../../themes';
import { Heading, ParagraphSmall, Paragraph } from '../../../content/text';
import { Currency } from './common/currency';
export const AssetClassTotalCompact = _ref => {
  let {
    title,
    image,
    imageAlt,
    amount,
    label = 'Funded to date:',
    inverse,
    tag
  } = _ref;
  return __jsx(OuterContainer, null, __jsx(HeadingHolder, null, __jsx(ImageHolder, null, image && __jsx(Image, {
    alt: imageAlt,
    src: image
  })), __jsx(Headline, {
    type: 5,
    inverse: inverse
  }, title)), __jsx(Title, {
    small: true,
    inverse: inverse
  }, label), __jsx(Number, {
    inverse: inverse
  }, __jsx(Currency, amount)), tag && __jsx(Tag, null, tag));
};
const OuterContainer = styled.div.withConfig({
  displayName: "AssetClassTotalCompact__OuterContainer",
  componentId: "sc-1929kod-0"
})(["background:", ";border-top:2px solid ", ";position:relative;text-align:left;margin:20px 0 0 0;padding:15px;box-shadow:0px 4px 8px rgba(0,0,0,0.1);flex-basis:100%;", ""], props => props.theme.colors.background_default, theme2.colors.accent_strong, media.desktop`
    flex-basis: 30%;
    margin: 0 10px 20px 10px;
  `);
const HeadingHolder = styled.div.withConfig({
  displayName: "AssetClassTotalCompact__HeadingHolder",
  componentId: "sc-1929kod-1"
})(["display:flex;align-items:center;margin-bottom:10px;"]);
const Headline = styled(Heading).withConfig({
  displayName: "AssetClassTotalCompact__Headline",
  componentId: "sc-1929kod-2"
})(["padding:5px 10px;box-sizing:content-box;"]);
const Title = styled(Paragraph).withConfig({
  displayName: "AssetClassTotalCompact__Title",
  componentId: "sc-1929kod-3"
})(["color:", ";"], theme2.colors.text_label);
const Number = styled(Paragraph).withConfig({
  displayName: "AssetClassTotalCompact__Number",
  componentId: "sc-1929kod-4"
})([""]);
const ImageHolder = styled.div.withConfig({
  displayName: "AssetClassTotalCompact__ImageHolder",
  componentId: "sc-1929kod-5"
})(["width:34px;"]);
const Image = styled.img.withConfig({
  displayName: "AssetClassTotalCompact__Image",
  componentId: "sc-1929kod-6"
})(["width:34px;"]);
const Tag = styled.span.withConfig({
  displayName: "AssetClassTotalCompact__Tag",
  componentId: "sc-1929kod-7"
})(["", " background:", ";position:absolute;bottom:0;right:0;padding:4px 10px;font-size:12px;"], ParagraphSmall, props => props.theme.colors.accent_strong_background);