export const SET_LOCATION_REDIRECT = 'ys/location/REDIRECT/SET';
export const setLocationRedirect = (redirectUrl, redirectPermanent) => dispatch => dispatch({
  type: SET_LOCATION_REDIRECT,
  redirectUrl,
  redirectPermanent
});
export const RESET_LOCATION_REDIRECT = 'ys/location/REDIRECT/RESET';
export const resetLocationRedirect = () => dispatch => dispatch({
  type: RESET_LOCATION_REDIRECT
});
export const SET_CURRENT_LOCATION = 'ys/location/CURRENT/SET';
export const setCurrentLocation = (currentPathname, currentSearch) => dispatch => dispatch({
  type: SET_CURRENT_LOCATION,
  currentPathname,
  currentSearch
});