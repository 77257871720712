import { InvestorEntityStepVerificationStatus } from '@yieldstreet/platform-kit';
import { AppAction, appActionManager } from '../../../app-actions';
import { useInvestorEntity } from '../../../hooks';
export const getInvestorBankAccountsAppAction = (investorEntityStatus, managementStrategy) => {
  const {
    id: investorEntityId,
    bankAccounts
  } = investorEntityStatus;
  switch (bankAccounts.status) {
    case InvestorEntityStepVerificationStatus.MISSING:
      return appActionManager.getAction(AppAction.LINK_BANK_ACCOUNT, {
        investorEntityId,
        managementStrategy
      });
    case InvestorEntityStepVerificationStatus.PENDING:
      return appActionManager.getAction(AppAction.VERIFY_MICRO_DEPOSITS, {
        investorEntityId
      });
    default:
      return null;
  }
};
export const useInvestorBankAccountsAppAction = (investorEntityId, managementStrategy) => {
  const {
    isLoading,
    investorEntityStatus
  } = useInvestorEntity(investorEntityId);
  const bankAccountsAppAction = investorEntityStatus && getInvestorBankAccountsAppAction(investorEntityStatus, managementStrategy);
  return {
    isLoading,
    bankAccountsAppAction
  };
};