import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { storeTrackingMeta, parseQueryTrackingParams } from '@yieldstreet/tool-kit';
const TrackPageView = () => {
  const {
    search
  } = useLocation();
  useEffect(() => {
    storeTrackingMeta(parseQueryTrackingParams(search));
  }, [search]);
  return null;
};
export default TrackPageView;