import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import CloseIcon from '../../assets/graphics/icons/close.svg';
import CloseIconWhite from '../../assets/graphics/icons/close_white.svg';
import { PageSection } from '../../layout';
import { testIds } from './SidePhotoContent.model';
import { Close, Container, Content, ImageContainer, MainImage, RightContainer, SplashImage, TextContainer, Title } from './SidePhotoContent.style';
export const SidePhotoContent = _ref => {
  let {
    backgroundImage,
    children,
    dismiss,
    imageBorder,
    imagePosition,
    inverse,
    mainImage,
    removeFooterSpacing,
    removeHeaderSpacing,
    removeImageMargin,
    removePaddingSide,
    reverseMobile,
    small,
    splashImage,
    title
  } = _ref;
  return __jsx(PageSection, null, __jsx(Container, {
    reverseMobile: reverseMobile,
    imagePosition: imagePosition,
    removeFooterSpacing: removeFooterSpacing,
    removeHeaderSpacing: removeHeaderSpacing,
    removePaddingSide: removePaddingSide,
    backgroundImage: backgroundImage
  }, __jsx(TextContainer, {
    removePaddingSide: removePaddingSide,
    small: small,
    imagePosition: imagePosition
  }, __jsx(Title, _extends({
    inverse: inverse,
    sx: {
      m: 5,
      mr: {
        md: 0
      }
    },
    variant: small ? 'h4' : 'h3'
  }, testIds.title.attr), title), __jsx(Content, testIds.content.attr, children)), __jsx(RightContainer, {
    removeImageMargin: removeImageMargin,
    small: small
  }, __jsx(ImageContainer, null, !backgroundImage && __jsx(React.Fragment, null, __jsx(MainImage, _extends({
    imageBorder: imageBorder
  }, testIds.mainImage.attr), mainImage), splashImage && __jsx(SplashImage, _extends({
    imagePosition: imagePosition
  }, testIds.splashImage.attr), splashImage)))), dismiss && __jsx(Close, {
    src: inverse ? CloseIconWhite : CloseIcon,
    onClick: dismiss
  })));
};