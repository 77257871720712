import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { TextInput } from './TextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
export const SearchTextInput = props => {
  const {
    showClearIcon,
    onClearSearch
  } = props;
  return __jsx(TextInput, _extends({}, props, {
    hiddenLabel: true,
    prefix: __jsx(SFontAwesomeIcon, {
      icon: faSearch,
      size: "sm",
      title: "search icon magnifying glass"
    }),
    suffix: showClearIcon && __jsx(ClearIcon, {
      icon: faTimes,
      size: "sm",
      title: "clear search icon",
      onClick: onClearSearch
    })
  }));
};
const SFontAwesomeIcon = styled(FontAwesomeIcon).withConfig({
  displayName: "SearchTextInput__SFontAwesomeIcon",
  componentId: "sc-racsfw-0"
})(["max-height:14px;"]);
const ClearIcon = styled(SFontAwesomeIcon).withConfig({
  displayName: "SearchTextInput__ClearIcon",
  componentId: "sc-racsfw-1"
})(["cursor:pointer;"]);