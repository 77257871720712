var __jsx = React.createElement;
import React from 'react';
import { ExternalLink } from '../../content/link/ExternalLink';
import { MediaCard } from './MediaCard';
import { CardsWrapper, InnerWrapper, Login, Wrapper } from './MediaCardsSection.style';
export const MediaCardsSection = _ref => {
  let {
    documents,
    history,
    section,
    status,
    userState
  } = _ref;
  const {
    content,
    resources
  } = section;
  const {
    pathname
  } = history.location;
  const isPastOffering = ['PNFL', 'CLSD', 'PAYO', 'MATD'].includes(status);
  return __jsx(Wrapper, null, __jsx(InnerWrapper, null, !userState.loggedIn && !isPastOffering && __jsx(Login, {
    className: "media-card-login-message",
    "data-cy": "media-card-login-message"
  }, "Please", ' ', __jsx(ExternalLink, {
    href: `/login?redirect=${encodeURIComponent(pathname)}`
  }, "log in"), ' ', "or ", __jsx(ExternalLink, {
    href: "/signup-quiz"
  }, "sign up"), " to view additional Investment Documents for this offering."), __jsx(CardsWrapper, null, resources !== null && resources !== void 0 && resources.includes('all') ? documents === null || documents === void 0 ? void 0 : documents.map((doc, idx) => {
    const {
      name,
      type,
      url
    } = doc;
    return type !== 'preview' && __jsx(MediaCard, {
      isPastOffering: isPastOffering,
      key: `${name}-${idx}`,
      title: name,
      type: type,
      url: url,
      userState: userState
    });
  }) : resources === null || resources === void 0 ? void 0 : resources.map((resource, idx) => {
    return __jsx(MediaCard, {
      documents: documents,
      isPastOffering: isPastOffering,
      key: `${resource}-${idx}`,
      title: resource,
      type: "standAloneDoc",
      userState: userState
    });
  }), content === null || content === void 0 ? void 0 : content.map((card, idx) => {
    const {
      customUrl,
      description,
      iconName,
      title,
      type
    } = card;
    return __jsx(MediaCard, {
      description: description,
      documents: documents,
      iconName: iconName,
      isPastOffering: isPastOffering,
      key: `${title}-${idx}`,
      title: title,
      type: type,
      url: customUrl,
      userState: userState
    });
  }))));
};