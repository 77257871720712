import styled from 'styled-components';
export const DropdownMenuWrapper = styled.div.withConfig({
  displayName: "DropdownMenustyle__DropdownMenuWrapper",
  componentId: "sc-l8nx83-0"
})(["position:relative;display:inline-flex;"]);
export const DropdownMenuHeader = styled.div.withConfig({
  displayName: "DropdownMenustyle__DropdownMenuHeader",
  componentId: "sc-l8nx83-1"
})(["display:flex;flex-direction:row;align-items:center;cursor:pointer;"]);
export const DropdownMenuIcon = styled.img.withConfig({
  displayName: "DropdownMenustyle__DropdownMenuIcon",
  componentId: "sc-l8nx83-2"
})(["margin-left:", ";"], _ref => {
  let {
    theme
  } = _ref;
  return theme.spacing.xs;
});
export const DropdownMenuList = styled.ul.withConfig({
  displayName: "DropdownMenustyle__DropdownMenuList",
  componentId: "sc-l8nx83-3"
})(["position:absolute;top:100%;right:0;min-width:240px;padding:16px;background:", ";border:1px solid ", ";box-shadow:0px 2px 5px -1px rgba(50,50,93,0.2);transition:all 300ms;opacity:", ";transform:translateY(", ");pointer-events:", ";"], _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.colors.background_card;
}, _ref3 => {
  let {
    theme
  } = _ref3;
  return theme.colors.border_default;
}, _ref4 => {
  let {
    open
  } = _ref4;
  return open ? '1' : '0';
}, _ref5 => {
  let {
    open,
    theme
  } = _ref5;
  return open ? theme.spacing.sm : '0';
}, _ref6 => {
  let {
    open
  } = _ref6;
  return open ? 'all' : 'none';
});
export const DropdownMenuItem = styled.li.withConfig({
  displayName: "DropdownMenustyle__DropdownMenuItem",
  componentId: "sc-l8nx83-4"
})(["white-space:nowrap;padding:12px 20px;border-radius:3px;cursor:pointer;color:rgb(26,27,29);transition:all 0.2s ease 0s;&:hover{background:", ";}"], _ref7 => {
  let {
    theme
  } = _ref7;
  return theme.colors.border_default;
});