var __jsx = React.createElement;
import React from 'react';
import Countdown from 'react-countdown';
const renderCountdown = _ref => {
  let {
    completed,
    seconds,
    onComplete
  } = _ref;
  if (completed) {
    onComplete();
    return null;
  }
  return __jsx(React.Fragment, null, seconds, " ", seconds === 1 ? 'second' : 'seconds');
};
export const SecondsCountdown = _ref2 => {
  let {
    seconds,
    onComplete
  } = _ref2;
  const miliseconds = seconds * 1000;
  return __jsx(Countdown, {
    date: Date.now() + miliseconds,
    renderer: _ref3 => {
      let {
        completed,
        seconds
      } = _ref3;
      return renderCountdown({
        completed,
        seconds,
        onComplete
      });
    }
  });
};