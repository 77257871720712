var __jsx = React.createElement;
import React from 'react';
import { SurfaceImageWrapper } from './SurfaceImage.style';
export const SurfaceImage = _ref => {
  let {
    alt,
    src,
    testId
  } = _ref;
  return __jsx(SurfaceImageWrapper, {
    testId: testId,
    withPadding: false
  }, __jsx("img", {
    alt: alt,
    src: src
  }));
};