import _FormControl from "@mui/material/FormControl";
import _Select from "@mui/material/Select";
import _Box from "@mui/material/Box";
import _InputLabel from "@mui/material/InputLabel";
var __jsx = React.createElement;
import React, { useCallback, useMemo, useState } from 'react';
import { FilterTypeEnum } from '@yieldstreet/tool-kit';
import { FilterView } from './FilterView';
import { useFilters, useQueryFilter } from './hooks';
import { getFormattedValueString, getValue } from '../../utils/filters/filters';
export const FilterDropdown = _ref => {
  let {
    filter,
    queryFilterParams,
    setPagination
  } = _ref;
  const {
    0: open,
    1: setOpen
  } = useState(false);
  const {
    key,
    type,
    options,
    config
  } = filter;
  const {
    query
  } = useQueryFilter(queryFilterParams);
  const {
    updateSearch
  } = useFilters({
    query
  });
  const value = useMemo(() => {
    return getValue(key, query);
  }, [key, query]);
  const formattedValueString = useMemo(() => {
    return getFormattedValueString({
      value,
      type,
      config,
      options
    });
  }, [value, type, config, options]);
  const handleFilterChange = useCallback(item => {
    updateSearch(item);

    /* 
      TODO: Currently our filter's hooks don't handle with pagination,
            since for now only transactions screen has it.
            This will be added and we can remove the `setPagination` property.
    */

    if (setPagination) {
      setPagination('', 0);
    }
    if (filter.type === FilterTypeEnum.MultiSelect) {
      setOpen(true);
    }
  }, [filter, updateSearch, setPagination]);
  return __jsx(_FormControl, {
    sx: {
      m: 1,
      minWidth: 160
    },
    key: `${filter.key}`,
    "data-cy": `${filter.key}-context-menu`,
    size: "small"
  }, __jsx(_InputLabel, {
    id: "filter-dropdown-label",
    size: "small"
  }, filter.label), __jsx(_Select, {
    labelId: "filter-dropdown-label",
    autoWidth: true
    /**
     * Initial value must be an empty string in order to correctly trigger the initial onChange.
     * Checks for the regular filters that are just strings/undefined and the multi filters,
     * where the initial value is an array of undefined - [undefined, undefined]
     */,
    value: Array.isArray(value) && value.every(v => v === undefined) || value === undefined ? '' : value,
    renderValue: () => formattedValueString,
    open: open,
    onOpen: () => setOpen(true),
    onClose: event => {
      var _event$target;
      /**
       * Avoid closing the Range filters on mouse up
       */
      if ([...(event === null || event === void 0 ? void 0 : (_event$target = event.target) === null || _event$target === void 0 ? void 0 : _event$target.classList)].some(className => className.includes(['MuiSlider']))) {
        return;
      }
      setOpen(false);
    }
  }, __jsx(_Box, {
    sx: {
      p: 5
    }
  }, __jsx(FilterView, {
    filter: filter,
    value: value,
    onChange: handleFilterChange
  }))));
};