import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { genTestIds } from '@yieldstreet/tool-kit';
import { TierLevels } from '@yieldstreet/platform-kit';
import ys_diamond from '@yieldstreet/ui-kit/assets/logos/ys_diamond.png';
import ys_gold from '@yieldstreet/ui-kit/assets/logos/ys_gold.png';
import ys_platinum from '@yieldstreet/ui-kit/assets/logos/ys_platinum.png';
import { BENEFITS_PATH } from 'pages/profile/Benefits/Benefits.model';
import { tierImageMapper } from 'utils/tiers';
const {
  getTestIds
} = genTestIds(['cta', 'description', 'icon', 'link', 'logo', 'title', 'welcome']);
export const testIds = getTestIds('tier-modal');
export const content = {
  [TierLevels.DIAMOND]: _objectSpread(_objectSpread({}, tierImageMapper[TierLevels.DIAMOND]), {}, {
    icon: ys_diamond,
    title: 'Welcome to Yieldstreet Diamond',
    description: ['Congratulations and welcome to our most elite membership tier.', 'Thank you for being one of our most valued investors.'],
    cta: {
      label: 'Explore your Diamond benefits',
      link: BENEFITS_PATH
    },
    link: {
      label: 'Review later'
    }
  }),
  [TierLevels.PLATINUM]: _objectSpread(_objectSpread({}, tierImageMapper[TierLevels.PLATINUM]), {}, {
    icon: ys_platinum,
    title: 'Welcome to Yieldstreet Platinum',
    description: ['Congratulations on achieving Platinum membership status.', 'We appreciate your continued loyalty.'],
    cta: {
      label: 'Explore your Platinum benefits',
      link: BENEFITS_PATH
    },
    link: {
      label: 'Review later'
    }
  }),
  [TierLevels.GOLD]: _objectSpread(_objectSpread({}, tierImageMapper[TierLevels.GOLD]), {}, {
    icon: ys_gold,
    title: 'Welcome to Yieldstreet Gold',
    description: ['Congratulations on achieving Gold membership status.', 'We appreciate your loyalty.'],
    cta: {
      label: 'Explore your Gold benefits',
      link: BENEFITS_PATH
    },
    link: {
      label: 'Review later'
    }
  })
};