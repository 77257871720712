import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';
import { media } from '../../themes/media';
export const TooltipDiv = styled.button.withConfig({
  displayName: "Tooltipstyle__TooltipDiv",
  componentId: "sc-1a6pqxr-0"
})(["cursor:pointer;width:fit-content;background:none;border:none;align-self:center;display:inline-flex;padding:0;"]);
export const TooltipContent = styled.div.withConfig({
  displayName: "Tooltipstyle__TooltipContent",
  componentId: "sc-1a6pqxr-1"
})(["display:flex;flex-direction:column-reverse;"]);
export const CloseIcon = styled.img.withConfig({
  displayName: "Tooltipstyle__CloseIcon",
  componentId: "sc-1a6pqxr-2"
})(["height:14px;margin-bottom:5px;", ";"], media.tablet`
    display: none;
  `);
export const StyledReactTooltip = styled(ReactTooltip).withConfig({
  displayName: "Tooltipstyle__StyledReactTooltip",
  componentId: "sc-1a6pqxr-3"
})(["border:2px solid ", " !important;max-width:250px !important;padding:10px 12px !important;border-radius:0 !important;opacity:0;box-shadow:", ";background-color:", " !important;font-size:12px !important;font-weight:400;line-height:20px !important;color:", " !important;&.place-top{&:after{bottom:-5px !important;border-left-color:", ";}}&.place-right{&:after{left:-5px !important;border-left-color:", " !important;}}&.place-bottom{&:after{bottom:-18px !important;}&:before{border-bottom:8px solid ", " !important;}}&.place-left{&:after{right:-5px !important;border-left-color:", " !important;}}&.show{opacity:1 !important;}white-space:initial;"], _ref => {
  let {
    theme
  } = _ref;
  return theme.colors.border_outline;
}, _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.shadows.shadow_default;
}, _ref3 => {
  let {
    inverse,
    theme
  } = _ref3;
  return inverse ? theme.colors.background_inverse : theme.colors.background_card;
}, _ref4 => {
  let {
    theme
  } = _ref4;
  return theme.colors.text_paragraph;
}, _ref5 => {
  let {
    theme
  } = _ref5;
  return theme.colors.border_outline;
}, _ref6 => {
  let {
    inverse,
    theme
  } = _ref6;
  return inverse ? theme.colors.border_outline : theme.colors.background_card;
}, _ref7 => {
  let {
    theme
  } = _ref7;
  return theme.colors.background_inverse;
}, _ref8 => {
  let {
    inverse,
    theme
  } = _ref8;
  return inverse ? theme.colors.border_outline : theme.colors.background_card;
});