export const mockPaymentActivityFundYsCapitalIncome = {
  userId: 492947,
  activity: [{
    date: '2023-06-30',
    interest: 0,
    netInterest: 0,
    netIncome: 432.84,
    fexp: 0,
    totalWithoutFexp: 933.84,
    cash: 933.84,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 933.84,
    totalNetFexpNetKnownCapital: 432.84,
    investmentsPerGraphItem: {
      LuMVuQ: {
        status: 'ACTIVE',
        noteUrlHash: 'LuMVuQ',
        title: 'Distribution Split Test 3',
        assetClass: 'ARTF',
        launchDate: '2023-05-19',
        amount: 933.84,
        investmentType: 'FUND'
      }
    }
  }],
  total: {
    interest: 0,
    netInterest: 0,
    netIncome: 432.84,
    fexp: 0,
    totalWithoutFexp: 933.84,
    totalNetFexpNetKnownCapital: 432.84,
    cash: 933.84,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 933.84
  },
  investments: [{
    status: 'ACTIVE',
    noteUrlHash: 'LuMVuQ',
    title: 'Distribution Split Test 3',
    assetClass: 'ARTF',
    launchDate: '2023-05-19',
    amount: 933.84,
    investmentType: 'FUND'
  }]
};