import { useMemo } from 'react';
import { useBankAccounts } from '../useBankAccounts';
import { BankAccountNotificationStates, getNotificationStates } from './useBankAccountNotifications.model';
export const useBankAccountNotifications = () => {
  const notificationList = [];
  const {
    canAddEntityBankAccounts,
    degradedBankAccounts,
    inactiveOrLockedBankAccounts,
    isDefaultBankAccountDegraded,
    microDepositBankAccounts
  } = useBankAccounts();
  if (!canAddEntityBankAccounts) {
    notificationList.push(getNotificationStates({
      notificationState: BankAccountNotificationStates.BANK_ACCOUNT_LIMIT_REACHED
    }));
  }
  if (isDefaultBankAccountDegraded) {
    notificationList.push(getNotificationStates({
      notificationState: BankAccountNotificationStates.BANK_ACCOUNT_DEGRADED,
      isMulti: degradedBankAccounts && degradedBankAccounts.length > 1,
      degradedBankAccounts
    }));
  }
  const inactiveOrLockedNotification = useMemo(() => inactiveOrLockedBankAccounts !== null && inactiveOrLockedBankAccounts !== void 0 && inactiveOrLockedBankAccounts.length ? inactiveOrLockedBankAccounts.map(bankAccount => getNotificationStates({
    notificationState: BankAccountNotificationStates.BANK_ACCOUNT_LOCKED_OR_INACTIVE,
    bankAccount,
    degradedBankAccounts
  })) : [], [inactiveOrLockedBankAccounts, degradedBankAccounts]);
  const microDepositNotification = useMemo(() => microDepositBankAccounts !== null && microDepositBankAccounts !== void 0 && microDepositBankAccounts.length ? microDepositBankAccounts.map(bankAccount => getNotificationStates({
    notificationState: BankAccountNotificationStates.BANK_ACCOUNT_MICRO_DEPOSIT_VERIFICATION,
    bankAccount
  })) : [], [microDepositBankAccounts]);
  return [...notificationList, ...inactiveOrLockedNotification, ...microDepositNotification];
};