import update from 'immutability-helper';
import * as actions from 'store/actions/fundDistributionOption';
export const initialState = {
  data: {},
  isFetching: false,
  error: false
};
const fundDistributionOption = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case actions.FUND_DISTRIBUTION_OPTION_ATTEMPT:
      {
        return update(state, {
          isFetching: {
            $set: true
          },
          error: {
            $set: false
          }
        });
      }
    case actions.FUND_DISTRIBUTION_OPTION_SUCCESS:
      {
        const {
          response
        } = action;
        return update(state, {
          data: {
            $set: response
          },
          isFetching: {
            $set: false
          },
          error: {
            $set: false
          }
        });
      }
    case actions.FUND_DISTRIBUTION_OPTION_FAILURE:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          error: {
            $set: true
          }
        });
      }
    default:
      return state;
  }
};
export default fundDistributionOption;