import { HttpMethods } from '../../../enums';
import { restApi, Tags } from '../../common';
export const getDocumentsApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.PortfolioGetDocuments]
}).injectEndpoints({
  endpoints: build => {
    const getInvestmentDocuments = build.query({
      query: _ref => {
        let {
          investorAccountId,
          noteUrlHash,
          userId
        } = _ref;
        return {
          url: `/a/api/portfolio/get-documents/${noteUrlHash}/${userId}`,
          method: HttpMethods.Get,
          params: {
            investorAccountId
          }
        };
      },
      providesTags: [Tags.PortfolioGetDocuments]
    });
    const getDocuments = build.query({
      query: _ref2 => {
        let {
          userId,
          type
        } = _ref2;
        return {
          url: `/a/api/portfolio/get-documents/${userId}`,
          method: HttpMethods.Get,
          params: {
            type
          }
        };
      },
      providesTags: [Tags.PortfolioGetDocuments]
    });
    const getInvestorEntityDocuments = build.query({
      query: _ref3 => {
        let {
          investorEntityId,
          type
        } = _ref3;
        return {
          url: `/a/api/portfolio/get-documents/user/investor-entity/${investorEntityId}`,
          method: HttpMethods.Get,
          params: {
            type
          }
        };
      },
      providesTags: [Tags.PortfolioGetDocuments]
    });
    return {
      getDocuments,
      getInvestmentDocuments,
      getInvestorEntityDocuments
    };
  }
});