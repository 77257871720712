import { Label, Heading, media } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
export const Wrapper = styled.div.withConfig({
  displayName: "MediaCardstyle__Wrapper",
  componentId: "sc-1w3d0lj-0"
})(["height:fit-content;cursor:pointer;overflow-wrap:break-word;border:1px solid ", ";align-items:center;display:flex;margin:10px 0;padding:20px;max-height:144px;flex-wrap:wrap;&:hover{box-shadow:0 4px 8px rgba(0,0,0,0.1);}", ";", ";"], props => props.theme.colors.border_default, media.tablet`
    flex: 1;
    margin: 20px 25px;
  `, media.large`
    margin: 20px 10px;
    flex-basis: 33.33%;
    max-width: 380px;
  `);
export const TextWrapper = styled.div.withConfig({
  displayName: "MediaCardstyle__TextWrapper",
  componentId: "sc-1w3d0lj-1"
})(["width:100%;height:100%;display:flex;flex-direction:column;"]);
export const TitleContainer = styled.div.withConfig({
  displayName: "MediaCardstyle__TitleContainer",
  componentId: "sc-1w3d0lj-2"
})(["display:flex;flex-direction:row;overflow-wrap:anywhere;align-items:center;.iconImage{margin:auto 0;}cursor:pointer;"]);
export const Title = styled(Heading).withConfig({
  displayName: "MediaCardstyle__Title",
  componentId: "sc-1w3d0lj-3"
})(["margin-left:", ";"], props => (props === null || props === void 0 ? void 0 : props.icon) && `15px`);
export const Description = styled(Label).withConfig({
  displayName: "MediaCardstyle__Description",
  componentId: "sc-1w3d0lj-4"
})(["cursor:pointer;"]);