import _Box from "@mui/material/Box";
import _Stack from "@mui/material/Stack";
import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React from 'react';
import { IndicatorIndex, IndicatorLine } from './Timeline.style';
export const Timeline = _ref => {
  let {
    items
  } = _ref;
  return __jsx(_Box, null, items.map((item, index) => __jsx(_Box, {
    key: index,
    sx: {
      display: 'flex'
    }
  }, __jsx(_Box, {
    sx: {
      display: 'flex',
      position: 'relative'
    }
  }, __jsx(_Stack, {
    sx: {
      pt: 1
    }
  }, __jsx(IndicatorIndex, null, __jsx(_Typography, {
    variant: "caption",
    sx: theme => ({
      color: theme.palette.text.header.inverse
    })
  }, index + 1))), index + 1 !== items.length ? __jsx(IndicatorLine, {
    sx: {
      top: 4
    }
  }) : null), __jsx(_Box, {
    sx: {
      ml: 4
    }
  }, item.content))));
};