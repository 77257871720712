import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
const _excluded = ["_data"];
import { useState, useCallback } from 'react';
import { investmentApi } from '@yieldstreet/platform-kit';
import sum from "lodash/sum";
import reduce from "lodash/reduce";
import find from "lodash/find";
/**
 * @typedef {Object} InvestmentEligibilityConfig
 * @property {{ amount: number, urlHash: string }[]} investments investment-request objects used to parse totalInvestmentAmounr and urlHashes
 * @property {string[] | undefined} rolloverInvestmentIds
 * @property {'NON_DISCRETIONARY'|undefined} investmentType used to determine if investment is a bulk request or not.
 * @property {number | undefined} totalFundedNowAmount the total investment amount that considers when one or more of the investments is a capital call
 */
const useGetInvestmentEligibility = () => {
  const {
    0: parsedData,
    1: setParsedData
  } = useState();
  const [bulkInvestmentEligibilityMutation, _ref] = investmentApi.useBulkInvestmentEligibilityMutation();

  /**
   * @function getInvestmentEligibility
   * @param {...InvestmentEligibilityConfig} params
   */
  const {
      _data
    } = _ref,
    apiHandles = _objectWithoutProperties(_ref, _excluded);
  const getInvestmentEligibility = useCallback(async function () {
    let {
      investments,
      rolloverInvestmentIds,
      investmentType,
      totalFundedNowAmount
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    const {
      eligibility
    } = await bulkInvestmentEligibilityMutation({
      investments,
      rolloverInvestmentIds
    }).unwrap();
    const {
      investorData,
      userGate
    } = eligibility;
    const isEligibleToInvest = !userGate;
    const actionableInvestorAccount = find(investorData, account => !!account.action);
    let gateData = {
      investorData,
      userGate
    };
    if (actionableInvestorAccount) {
      gateData = _objectSpread(_objectSpread({}, gateData), {}, {
        /** bringing vital data to top-level to decrease object drilling in other places */
        actionableAccount: _objectSpread(_objectSpread({}, actionableInvestorAccount), {}, {
          investorAccountId: actionableInvestorAccount.investorAccountId,
          gateCode: actionableInvestorAccount.gateData.gateCode,
          actionCode: actionableInvestorAccount.action.code
        })
      });
    }
    const data = {
      isEligibleToInvest,
      gateData,
      preInvestmentState: makePreInvestmentState({
        investments,
        investmentType,
        totalFundedNowAmount
      })
    };
    setParsedData(data);
    return data;
  }, [bulkInvestmentEligibilityMutation]);
  return [getInvestmentEligibility, _objectSpread(_objectSpread({}, apiHandles), {}, {
    // formatted data from mutation response
    data: parsedData
  })];
};

/**
 * @function makePreInvestmentState
 * @description Creates an object of necessary KVP's (key-value pairs) for bulk investment requests
 * @param {...InvestmentEligibilityConfig} params
 */
function makePreInvestmentState(_ref2) {
  let {
    investments,
    investmentType,
    totalFundedNowAmount
  } = _ref2;
  const {
    amounts,
    urlHashes
  } = reduce(investments, (prev, next) => ({
    amounts: [...prev.amounts, +next.amount],
    urlHashes: [...prev.urlHashes, next.urlHash]
  }), {
    amounts: [],
    urlHashes: []
  });
  return {
    investmentType,
    investments,
    totalInvestmentAmount: sum(amounts),
    totalFundedNowAmount,
    urlHashes
  };
}
export const INVESTMENT_TYPE = {
  NON_DISCRETIONARY: 'NON_DISCRETIONARY'
};
export { useGetInvestmentEligibility, makePreInvestmentState };