import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useEffect } from 'react';
import { useFlow } from './useFlow';
import { useForm } from './useForm';
const defaultSubmitSuccess = _ref => {
  let {
    goToNextStep
  } = _ref;
  return goToNextStep();
};
export const useFormFlow = _ref2 => {
  let {
    formConfig,
    flowConfig,
    flowState,
    submitConfig,
    submitCallback,
    onSubmitSuccess = defaultSubmitSuccess,
    onSubmitError
  } = _ref2;
  const flowOutput = useFlow({
    flowConfig,
    flowState
  });
  const {
    isSuccess,
    isError
  } = submitConfig;
  useEffect(() => {
    if (isSuccess && onSubmitSuccess) {
      onSubmitSuccess(flowOutput, submitConfig);
    }
  }, [isSuccess, flowOutput, onSubmitSuccess]);
  useEffect(() => {
    if (isError && onSubmitError) {
      onSubmitError(flowOutput, submitConfig);
    }
  }, [isSuccess, flowOutput, onSubmitError]);
  const {
    Form
  } = useForm({
    formConfig,
    submitConfig,
    submitCallback
  });
  return _objectSpread(_objectSpread({}, flowOutput), {}, {
    Form
  });
};