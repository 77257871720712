import { media, Paragraph, Link, Label } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
import background from 'assets/backgroundImages/images/tiered-pricing-background.png';
export const TieredModalWrapper = styled.div.withConfig({
  displayName: "TieredPricingModalstyle__TieredModalWrapper",
  componentId: "sc-1kal968-0"
})(["background:", ";width:100vw;height:100vh;display:flex;flex-direction:column;", ";", ""], props => props.theme.colors.background_card, media.phoneLandscape`
    min-width: 500px;
    height: auto;
  `, media.desktop`
    max-width: 800px;
  `);
export const HeaderWrapper = styled.div.withConfig({
  displayName: "TieredPricingModalstyle__HeaderWrapper",
  componentId: "sc-1kal968-1"
})(["width:100%;background:", ";flex-grow:1;"], props => props.theme.gradients.radial_primary);
export const BackgroundWrapper = styled.div.withConfig({
  displayName: "TieredPricingModalstyle__BackgroundWrapper",
  componentId: "sc-1kal968-2"
})(["background-position:calc(100% + 25px) calc(100% + 50px);background-size:", "px ", "px;background-image:url(", ");background-repeat:no-repeat;padding:56px 20px 31px;height:100%;", ""], 275 / 2, 450 / 2, background, media.desktop`
  background-position: calc(100% + 50px) 100px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 40px;
  `);
export const InfoWrapper = styled.div.withConfig({
  displayName: "TieredPricingModalstyle__InfoWrapper",
  componentId: "sc-1kal968-3"
})(["width:100%;display:flex;flex-direction:column;"]);
export const LabelInfo = styled(Label).withConfig({
  displayName: "TieredPricingModalstyle__LabelInfo",
  componentId: "sc-1kal968-4"
})(["letter-spacing:4px;margin-bottom:12px;"]);
export const ParagrapHeader = styled(Paragraph).withConfig({
  displayName: "TieredPricingModalstyle__ParagrapHeader",
  componentId: "sc-1kal968-5"
})(["margin-top:8px;"]);
export const IconWrapper = styled.div.withConfig({
  displayName: "TieredPricingModalstyle__IconWrapper",
  componentId: "sc-1kal968-6"
})(["width:48px;height:48px;border-radius:100%;background-color:rgba(255,255,255,0.15);display:flex;margin-bottom:12px;justify-content:center;align-items:center;border:1px solid ", ";"], props => props.theme.colors.border_default);
export const ExclusiveIcon = styled.img.withConfig({
  displayName: "TieredPricingModalstyle__ExclusiveIcon",
  componentId: "sc-1kal968-7"
})(["width:28px;"]);
export const TieredPricingWrapper = styled.div.withConfig({
  displayName: "TieredPricingModalstyle__TieredPricingWrapper",
  componentId: "sc-1kal968-8"
})(["margin-top:32px;"]);
export const BottomWrapper = styled.div.withConfig({
  displayName: "TieredPricingModalstyle__BottomWrapper",
  componentId: "sc-1kal968-9"
})(["display:flex;padding:31px 20px;flex-grow:0;", ""], media.desktop`
    padding: 24px 40px;
  `);
export const BannerParagraph = styled(Paragraph).withConfig({
  displayName: "TieredPricingModalstyle__BannerParagraph",
  componentId: "sc-1kal968-10"
})(["max-width:275px;", " color:", ";"], media.desktop`
    margin-top: 4px;
  `, props => props.theme.colors.text_label_inverse);
export const LinkParagraph = styled(Link).withConfig({
  displayName: "TieredPricingModalstyle__LinkParagraph",
  componentId: "sc-1kal968-11"
})(["box-shadow:", " !important;color:", " !important;background-image:", ";font-size:14px;"], props => props.theme.colors.text_paragraph_inverse, props => props.theme.colors.text_paragraph_inverse, props => `linear-gradient( to right, ${props.theme.colors.text_paragraph_inverse} 45%, transparent 45%, transparent 55%, ${props.theme.colors.text_paragraph_inverse} 55% ) !important`);