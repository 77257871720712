import { Tags, restApi } from '../common';
import { HttpMethods } from '../../enums/http';
export const adviceEngineApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.AdviceSuggestions, Tags.AdviceManagedPortfolio, Tags.ManagedPortfolioEligibility]
}).injectEndpoints({
  overrideExisting: true,
  endpoints: builder => {
    const assignStrategy = builder.mutation({
      query: data => ({
        url: `/api/advice-engine/strategy/assign`,
        method: HttpMethods.Post,
        data
      }),
      invalidatesTags: [Tags.AdviceSuggestions, Tags.ManagedPortfolioEligibility]
    });
    const getSuggestions = builder.query({
      query: _ref => {
        let {
          investorEntityId
        } = _ref;
        return {
          url: `/api/advice-engine/strategy/${investorEntityId}/suggested`,
          method: HttpMethods.Get
        };
      },
      transformResponse: res => res.response,
      providesTags: [Tags.AdviceSuggestions]
    });
    const getManagedPortfolio = builder.query({
      query: _ref2 => {
        let {
          investorEntityId
        } = _ref2;
        return {
          url: `/api/advice-engine/managed-portfolio/${investorEntityId}`,
          method: HttpMethods.Get
        };
      },
      transformResponse: res => {
        return res.response;
      },
      providesTags: [Tags.AdviceManagedPortfolio]
    });
    return {
      assignStrategy,
      getManagedPortfolio,
      getSuggestions
    };
  }
});