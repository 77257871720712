import { HttpMethods } from '../../enums/http';
import { restApi, Tags } from '../common';
const BASE_URL = '/api/investor/public/investor-entity';
export const plaidApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.PlaidKYCVerificationStatus, Tags.InvestorEntityStatus, Tags.BankAccount]
}).injectEndpoints({
  endpoints: builder => {
    const createPlaidKYCLinkToken = builder.mutation({
      query: _ref => {
        let {
          investorEntityId
        } = _ref;
        return {
          url: `${BASE_URL}/${investorEntityId}/identity/plaid/link-token`,
          method: HttpMethods.Post
        };
      }
    });
    return {
      createPlaidKYCLinkToken
    };
  }
});