import { restApi, Tags } from '../../common';
import { HttpMethods } from '../../../enums';
export const allocationApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.PortfolioAllocationTimeline]
}).injectEndpoints({
  endpoints: build => {
    const getAllocationTimeline = build.query({
      query: _ref => {
        let {
          end,
          start,
          userId,
          investorAccountId,
          managementStrategy
        } = _ref;
        return {
          url: `/api/portfolio/${userId}/allocation-time-line`,
          method: HttpMethods.Get,
          params: {
            end,
            investorAccountId,
            start,
            managementStrategy
          }
        };
      },
      providesTags: [Tags.PortfolioAllocationTimeline]
    });
    return {
      getAllocationTimeline
    };
  }
});