import _Typography from "@mui/material/Typography";
import React from "react";
var __jsx = React.createElement;
export const MetricLabel = _ref => {
  let {
    label,
    dataCy,
    children,
    isConcise
  } = _ref;
  if (isConcise) {
    return __jsx(_Typography, {
      "data-cy": dataCy,
      component: "span",
      variant: "body2",
      sx: theme => ({
        color: theme.palette.text.labelPrimary.inverse
      })
    }, label, children);
  }
  return __jsx(_Typography, {
    "data-cy": dataCy,
    component: "p",
    variant: "body1",
    sx: theme => ({
      color: theme.palette.text.paragraphPrimary.inverse,
      fontWeight: 600,
      [theme.breakpoints.up('md')]: {
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px'
      }
    })
  }, label, children);
};