var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { media } from '../../themes';
import { Heading, Paragraph } from '../../content/text';
import { PageSection } from '../../layout/page-section';
export const BioContainer = _ref => {
  let {
    quote,
    quoteBy,
    children
  } = _ref;
  return __jsx(PageSection, null, __jsx(Container, null, __jsx(ContentHolder, null, children), __jsx(QuoteHolder, null, __jsx(Quote, {
    type: 4
  }, quote), __jsx(QuoteBy, {
    semiBold: true
  }, quoteBy))));
};
const Container = styled.div.withConfig({
  displayName: "BioContainer__Container",
  componentId: "sc-1jzym0o-0"
})(["padding:20px;display:flex;flex-wrap:wrap;flex-direction:column;", ""], media.desktop`
    flex-direction: row;
    padding: 40px 130px 100px 130px;
  `);
const QuoteHolder = styled.div.withConfig({
  displayName: "BioContainer__QuoteHolder",
  componentId: "sc-1jzym0o-1"
})(["width:100%;", ""], media.desktop`
    margin: 20px 0 0 0;
    width: 40%;
  `);
const ContentHolder = styled.div.withConfig({
  displayName: "BioContainer__ContentHolder",
  componentId: "sc-1jzym0o-2"
})(["width:100%;margin:20px 0 0 0;", ""], media.desktop`
    width: 60%;
  `);
const Quote = styled(Heading).withConfig({
  displayName: "BioContainer__Quote",
  componentId: "sc-1jzym0o-3"
})(["width:100%;"]);
const QuoteBy = styled(Paragraph).withConfig({
  displayName: "BioContainer__QuoteBy",
  componentId: "sc-1jzym0o-4"
})(["margin:40px 0 0 0;"]);