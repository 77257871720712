import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { genTestIds, createYupObjectSchema } from '@yieldstreet/tool-kit';
export const addressFormModel = {
  addressLine1: {
    validationType: 'address'
  },
  addressLine2: {
    validationType: 'address',
    isRequired: false
  },
  country: {
    validationType: 'country'
  },
  city: {
    validationType: 'address'
  },
  stateStr: {
    validationType: 'state'
  },
  postalCode: {
    validationType: 'zip-code'
  }
};
export const formValidation = createYupObjectSchema(_objectSpread({}, addressFormModel));
export const getInitialValues = data => {
  var _data$address, _data$address2, _data$address3, _data$address4, _data$address5;
  return {
    addressLine1: data === null || data === void 0 ? void 0 : (_data$address = data.address) === null || _data$address === void 0 ? void 0 : _data$address.addressLine1,
    addressLine2: data === null || data === void 0 ? void 0 : (_data$address2 = data.address) === null || _data$address2 === void 0 ? void 0 : _data$address2.addressLine2,
    city: data === null || data === void 0 ? void 0 : (_data$address3 = data.address) === null || _data$address3 === void 0 ? void 0 : _data$address3.city,
    stateStr: data === null || data === void 0 ? void 0 : (_data$address4 = data.address) === null || _data$address4 === void 0 ? void 0 : _data$address4.state,
    postalCode: data === null || data === void 0 ? void 0 : (_data$address5 = data.address) === null || _data$address5 === void 0 ? void 0 : _data$address5.postalCode
  };
};
export const TEST_ID_KEY = 'create-investor-account-address';
const {
  getTestIds
} = genTestIds(['pageLoader', 'submitButton']);
export const testIds = getTestIds(TEST_ID_KEY);