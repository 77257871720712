import styled from 'styled-components';
import { media } from '@yieldstreet/ui-kit';
export const Header = styled.header.withConfig({
  displayName: "NewYS360style__Header",
  componentId: "sc-38lsx5-0"
})(["align-items:flex-start;display:flex;flex-direction:column;", ""], media.desktop`
    align-items: flex-start;
    flex-direction: row;
    position: relative;
  `);
export const BackNavigation = styled.div.withConfig({
  displayName: "NewYS360style__BackNavigation",
  componentId: "sc-38lsx5-1"
})(["align-items:center;cursor:pointer;display:flex;height:30px;position:relative;z-index:10;"]);
export const Arrow = styled.img.withConfig({
  displayName: "NewYS360style__Arrow",
  componentId: "sc-38lsx5-2"
})(["margin:0 10px 0 0;width:10px;"]);
export const LogoContainer = styled.div.withConfig({
  displayName: "NewYS360style__LogoContainer",
  componentId: "sc-38lsx5-3"
})(["align-items:center;display:flex;height:30px;justify-content:center;text-align:center;position:absolute;left:50%;transform:translateX(-50%);", ""], media.desktop`
    width: 500px;
  `);
export const DesktopLogoImg = styled.img.withConfig({
  displayName: "NewYS360style__DesktopLogoImg",
  componentId: "sc-38lsx5-4"
})(["display:none;", ""], media.desktop`
    display: block;
    max-width: 380px;
    height: auto;
  `);
export const MobileLogoImg = styled.img.withConfig({
  displayName: "NewYS360style__MobileLogoImg",
  componentId: "sc-38lsx5-5"
})(["display:block;height:auto;", ""], media.desktop`
    display: none;
  `);
export const ContentHolder = styled.div.withConfig({
  displayName: "NewYS360style__ContentHolder",
  componentId: "sc-38lsx5-6"
})(["display:flex;flex-direction:column;margin-left:auto;margin-right:auto;margin-top:", ";text-align:center;row-gap:", ";", ""], _ref => {
  let {
    theme
  } = _ref;
  return theme.spacing.m;
}, _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.spacing.sm;
}, media.desktop`
    margin-top: ${_ref3 => {
  let {
    theme
  } = _ref3;
  return theme.spacing.l;
}};
    row-gap: ${_ref4 => {
  let {
    theme
  } = _ref4;
  return theme.spacing.l;
}};
  `);
export const ButtonWrapper = styled.div.withConfig({
  displayName: "NewYS360style__ButtonWrapper",
  componentId: "sc-38lsx5-7"
})(["margin:", ";", ";"], _ref5 => {
  let {
    theme
  } = _ref5;
  return `${theme.spacing.m} 0 ${theme.spacing['4xl']}`;
}, media.desktop`
    margin: ${_ref6 => {
  let {
    theme
  } = _ref6;
  return `${theme.spacing.l} auto ${theme.spacing['4xl']}`;
}};
  `);