var __jsx = React.createElement;
import React from 'react';
import { Paragraph, Label } from '../../../content';
import { Tooltip } from '../../tooltip/Tooltip';
import { TextContent, HeaderContent } from './CalculatorHeader.style';
export const CalculatorHeader = _ref => {
  let {
    title,
    subtitle,
    tooltip
  } = _ref;
  return __jsx(TextContent, {
    className: "calculatorHeader"
  }, __jsx(HeaderContent, null, __jsx(Paragraph, {
    semiBold: true
  }, title), tooltip ? __jsx("div", null, __jsx(Tooltip, {
    testId: "calculator-header-tooltip",
    customIcon: tooltip === null || tooltip === void 0 ? void 0 : tooltip.icon
  }, tooltip.message)) : null), __jsx(Label, null, subtitle));
};