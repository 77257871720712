export const audiences = {
  success: true,
  resolution: {
    EXPERIENCED_WITH_ALTS: false,
    NO_INV_PREF: false,
    ALL_NAI: false,
    SAI_FIRST_TIME_INVESTORS: false,
    TIER: true
  },
  audiences: {
    TIER: {
      active: true,
      meta: {
        startDate: '2023-06-02',
        portfolioValue: 500000,
        percentageInTier: 100,
        leftForNextTier: 0,
        isOptedIn: false,
        level: 'DIAMOND',
        expiry: null,
        rangeStart: 500000,
        rangeEnd: -1
      }
    }
  }
};
export const audiencesPlatinum = {
  success: true,
  resolution: {
    EXPERIENCED_WITH_ALTS: false,
    NO_INV_PREF: false,
    ALL_NAI: false,
    SAI_FIRST_TIME_INVESTORS: false,
    TIER: true
  },
  audiences: {
    TIER: {
      active: true,
      meta: {
        startDate: '2023-06-02',
        portfolioValue: 250000,
        percentageInTier: 0,
        leftForNextTier: 250000,
        isOptedIn: false,
        level: 'PLATINUM',
        expiry: null,
        rangeStart: 250000,
        rangeEnd: 500000
      }
    }
  }
};
export const audiencesGold = {
  success: true,
  resolution: {
    EXPERIENCED_WITH_ALTS: false,
    NO_INV_PREF: false,
    ALL_NAI: false,
    SAI_FIRST_TIME_INVESTORS: false,
    TIER: true
  },
  audiences: {
    TIER: {
      active: true,
      meta: {
        startDate: '2023-06-02',
        portfolioValue: 100000,
        percentageInTier: 0,
        leftForNextTier: 150000,
        isOptedIn: false,
        level: 'GOLD',
        expiry: null,
        rangeStart: 100000,
        rangeEnd: 250000
      }
    }
  }
};
export const audiencesDefault = {
  success: true,
  resolution: {
    EXPERIENCED_WITH_ALTS: false,
    NO_INV_PREF: false,
    ALL_NAI: false,
    SAI_FIRST_TIME_INVESTORS: false,
    TIER: true
  },
  audiences: {
    TIER: {
      active: true,
      meta: {
        startDate: '2023-05-07',
        portfolioValue: 50000,
        percentageInTier: 50,
        leftForNextTier: 50000,
        isOptedIn: false,
        level: 'DEFAULT',
        expiry: null,
        rangeStart: -1,
        rangeEnd: 100000
      }
    }
  }
};
export const audiencesNoTier = {
  success: true,
  resolution: {
    EXPERIENCED_WITH_ALTS: false,
    NO_INV_PREF: false,
    ALL_NAI: false,
    SAI_FIRST_TIME_INVESTORS: false,
    TIER: true
  },
  audiences: {}
};