import orderBy from "lodash/orderBy";
import { InvestmentsListSortTypes } from '../../useInvestmentsList.model';
import { orderByLastCommentary, orderByPerformanceStatus } from './util';
export const sortData = _ref => {
  var _data$investments$sta, _data$investments, _data$lastCommentary;
  let {
    data,
    sortKey,
    orderKey,
    status
  } = _ref;
  const collection = (_data$investments$sta = data === null || data === void 0 ? void 0 : (_data$investments = data.investments) === null || _data$investments === void 0 ? void 0 : _data$investments[status]) !== null && _data$investments$sta !== void 0 ? _data$investments$sta : [];
  switch (sortKey) {
    case InvestmentsListSortTypes.CurrentValue:
    case InvestmentsListSortTypes.InvestmentAmount:
    case InvestmentsListSortTypes.Irr:
    case InvestmentsListSortTypes.NoteTitle:
    case InvestmentsListSortTypes.TermRemaining:
    case InvestmentsListSortTypes.RequiresUserAction:
      return orderBy(collection, sortKey, orderKey);
    case InvestmentsListSortTypes.PerformanceStatus:
      return orderByPerformanceStatus({
        collection,
        orderKey
      });
    case InvestmentsListSortTypes.LastCommentary:
      return orderByLastCommentary({
        collection,
        lastCommentary: (_data$lastCommentary = data === null || data === void 0 ? void 0 : data.lastCommentary) !== null && _data$lastCommentary !== void 0 ? _data$lastCommentary : {},
        orderKey
      });
    default:
      return collection;
  }
};