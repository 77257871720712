import { css } from 'styled-components';
import { media } from '../../themes/media';
import InverseCloseIcon from '../../assets/graphics/icons/close-icon-inverse.svg';
import CloseIcon from '../../assets/graphics/icons/close.svg';
import CryptoBg from '../../assets/graphics/backgrounds/crypto-bg.svg';
import FreeSharesBg from '../../assets/graphics/backgrounds/free-shares.png';
const cryptoBg = css(["background:url(", ");background-size:cover;background-repeat:no-repeat;background-position:center;"], CryptoBg);
export const customThemeObj = {
  1: {
    container: css(["flex-direction:column;padding:", ";", ""], _ref => {
      let {
        theme
      } = _ref;
      return theme.spacing.l;
    }, cryptoBg),
    buttonWrapper: css(["margin:", " 0 0;"], _ref2 => {
      let {
        theme
      } = _ref2;
      return theme.spacing.m;
    }),
    titleType: 3,
    closeIcon: InverseCloseIcon,
    buttonType: 'primary',
    isInverse: true
  },
  2: {
    container: css(["flex-direction:row;padding:", ";justify-content:space-between;", ""], _ref3 => {
      let {
        theme
      } = _ref3;
      return theme.spacing.sm;
    }, cryptoBg),
    buttonWrapper: css(["margin:0 40px 0;align-self:center;"]),
    titleType: 4,
    closeIcon: InverseCloseIcon,
    buttonType: 'primary',
    isInverse: true
  },
  3: {
    container: css(["flex-direction:column;padding:", ";justify-content:center;background-color:", ";", ""], _ref4 => {
      let {
        theme
      } = _ref4;
      return theme.spacing.sm;
    }, _ref5 => {
      let {
        theme
      } = _ref5;
      return theme.colors.active_alt;
    }, media.tablet`
        flex-direction: row;
      `),
    buttonWrapper: css(["align-self:center;", ""], media.tablet`
        margin-left: ${_ref6 => {
      let {
        theme
      } = _ref6;
      return theme.spacing.xs;
    }};
      `),
    iconStyle: css(["top:0;bottom:0;margin:auto;right:10px;", ""], media.tablet`
        right: 15px;
      `),
    textWrapper: css(["text-align:center;"]),
    closeIcon: CloseIcon,
    buttonType: 'link',
    isInverse: false
  },
  4: {
    image: FreeSharesBg,
    titleType: 4,
    closeIcon: InverseCloseIcon,
    buttonType: 'primary'
  }
};