import { genTestIds } from '@yieldstreet/tool-kit';
export const items = [{
  title: 'Minimum',
  description: 'Get started with a $25K minimum investment.'
}, {
  title: 'Liquidity',
  description: 'After your portfolio has been active for one year, you can begin taking distributions — or request to start liquidating your account.',
  tooltipText: 'Despite a request for termination of the account, your investments are illiquid and subject to significant restrictions on withdrawal, redemption, and transfer. Therefore, you may remain invested and we may continue to manage the account for a significant period of time. Termination of the account, or a withdrawal of a portion of the account, may impair the achievement of your investment objectives. Please see the Advisory Agreement for all details relating to liquidity.'
}, {
  title: 'Fees',
  description: 'Yieldstreet 360 has a flat 1.25% advisory fee and approximately 0.175% in expenses.',
  tooltipText: 'Investments included in Managed Portfolios have additional expenses and fees described in each investment’s prospectus. Please see the Advisory Agreement for all details relating to management fees and see the Brokerage Agreement and Custodian Agreement for all details relating to expenses.'
}];
const {
  getTestIds
} = genTestIds(['heroBanner', 'itemsWrapper', 'item', 'itemTitle', 'itemTooltip', 'itemParagraph']);
export const testIds = getTestIds('ys360-essentials');