import { InvestorEntityStepProvider } from '@yieldstreet/platform-kit';
import UnitLogo from '@yieldstreet/ui-kit/assets/logos/unit.svg';
import ETCLogo from '@yieldstreet/ui-kit/assets/logos/etc.svg';
import PlaidLogo from '@yieldstreet/ui-kit/assets/logos/plaid.svg';
import FootprintLogo from '@yieldstreet/ui-kit/assets/logos/footprint.svg';
import { INVESTOR_ENTITY_KYB_PROVIDER_PATH, INVESTOR_ENTITY_KYC_PROVIDER_PATH, CREATE_INVESTOR_ENTITY_KYC_PROVIDER_PATH } from '../KYCContainer.model';
import { MANAGEMENT_STRATEGY_PARAM } from '../../../utils';
export const CREATE_INVESTOR_ENTITY_KYC_PROVIDER_ETC_INTRO_PATH = `${CREATE_INVESTOR_ENTITY_KYC_PROVIDER_PATH}/intro`;
export const CREATE_INVESTOR_ENTITY_KYC_PROVIDER_ETC_PATH = `${CREATE_INVESTOR_ENTITY_KYC_PROVIDER_PATH}/:iraType`;
export const CREATE_INVESTOR_ENTITY_KYC_PROVIDER_ETC_AGREEMENT_PATH = `${CREATE_INVESTOR_ENTITY_KYC_PROVIDER_PATH}/agreement/:iraType`;
export const INVESTOR_ENTITY_KYC_PROVIDER_ETC_PATH = `${INVESTOR_ENTITY_KYC_PROVIDER_PATH}/etc`;
export const INVESTOR_ENTITY_KYC_PROVIDER_PLAID_PATH = `${INVESTOR_ENTITY_KYC_PROVIDER_PATH}/plaid`;
export const INVESTOR_ENTITY_KYC_PROVIDER_PLAID_DISCRETIONARY_PATH = `${INVESTOR_ENTITY_KYC_PROVIDER_PATH}/plaid/${MANAGEMENT_STRATEGY_PARAM}?`;
export const INVESTOR_ENTITY_KYB_PROVIDER_FOOTPRINT_PATH = `${INVESTOR_ENTITY_KYB_PROVIDER_PATH}/footprint`;
export const INVESTOR_ENTITY_KYC_PROVIDER_UNIT_PATH = `${INVESTOR_ENTITY_KYC_PROVIDER_PATH}/unit`;
export const INVESTOR_ENTITY_KYB_PROVIDER_CONFIG = {
  [InvestorEntityStepProvider.FOOTPRINT]: {
    name: 'Footprint',
    logo: FootprintLogo,
    route: INVESTOR_ENTITY_KYB_PROVIDER_FOOTPRINT_PATH
  }
};
export const INVESTOR_ENTITY_KYC_PROVIDER_CONFIG = {
  [InvestorEntityStepProvider.UNIT]: {
    name: 'Unit',
    logo: UnitLogo,
    route: INVESTOR_ENTITY_KYC_PROVIDER_UNIT_PATH
  },
  [InvestorEntityStepProvider.ETC]: {
    name: 'ETC',
    logo: ETCLogo,
    route: INVESTOR_ENTITY_KYC_PROVIDER_ETC_PATH
  },
  [InvestorEntityStepProvider.PLAID]: {
    name: 'Plaid',
    logo: PlaidLogo,
    route: INVESTOR_ENTITY_KYC_PROVIDER_PLAID_DISCRETIONARY_PATH
  }
};