import React from "react";
var __jsx = React.createElement;
import { genTestIds } from '@yieldstreet/tool-kit';
import { PortfolioStrategyValues } from '@yieldstreet/platform-kit';
import { MANAGEMENT_STRATEGY } from 'pages/newCreateInvestorAccount/CreateInvestorAccount.model';
import prismBean from './NonAccredited/prism-bean.svg';
import reitBean from './NonAccredited/reit-bean.svg';
export const FORK_PAGE_PATH = '/investment-path';
export const TEST_ID_BASE = 'advice-fork-page';
const {
  getTestIds
} = genTestIds(['title', 'subtitle', 'dashboardLink', 'accreditationLink', 'intercom', 'saiPage', 'naiPage']);
export const testIds = getTestIds(TEST_ID_BASE);
export const links = {
  dashboard: '/dashboard',
  accreditation: '/support/article/what-is-an-accredited-investor-1',
  reit: '/offering/e5jyfw/growth-income-reit',
  prism: 'https://yieldstreetalternativeincomefund.com'
};
export const getSAIOptions = managedPortfolio => {
  const options = [{
    label: 'Browse all investments',
    value: 'EXPLORE',
    description: 'I want to build my own investment portfolio',
    link: '/dashboard'
  }, {
    label: 'Get recommendations',
    value: 'GET_RECOMMENDATIONS',
    description: 'I’d like to build my own portfolio with investment recommendations informed by my goals.',
    link: '/create-investor-account',
    routeState: {
      isAdviceFlow: true
    }
  }];
  if (managedPortfolio) {
    options.push({
      label: 'Open a Managed Portfolio',
      value: 'MANAGED_PORTFOLIOS',
      description: __jsx(React.Fragment, null, "I'd like Yieldstreet to build and manage a portfolio based on my investment objectives.", __jsx("br", null), __jsx("br", null), "Individual accounts only | Minimum: $25K"),
      link: `/create-investor-account?${MANAGEMENT_STRATEGY}=${PortfolioStrategyValues.Discretionary}`
    });
  }
  return options;
};
export const getNAIOptions = history => [{
  title: 'Yieldstreet Alternative Income Fund',
  img: prismBean,
  onClick: () => window.location.replace(links.prism)
}, {
  title: 'Growth and Income REIT',
  img: reitBean,
  onClick: () => history.push(links.reit)
}];