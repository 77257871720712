import { useMemo } from 'react';
import dayjs from 'dayjs';
import compact from "lodash/compact";
import find from "lodash/find";
import orderBy from "lodash/orderBy";
import reduce from "lodash/reduce";
import Big from 'big.js';
import { skipToken } from '@reduxjs/toolkit/query';
import { AssetClassType, investmentsApi } from '@yieldstreet/platform-kit';
import { AllocationType } from './useTargetAllocation.model';
import { formatAssetClass, formatTargetAllocations } from './useTargetAllocation.utils';
export const useTargetAllocation = _ref => {
  let {
    userId,
    investorAccountId,
    managementStrategy,
    percentageDecimalPoints = 1,
    asOfDate = dayjs().format('YYYY-MM-DD'),
    config
  } = _ref;
  const assetConfig = useMemo(() => {
    if (!userId || !investorAccountId) {
      return skipToken;
    }
    return {
      userId,
      investorAccountId,
      managementStrategy,
      percentageDecimalPoints,
      asOfDate
    };
  }, [userId, investorAccountId, managementStrategy, percentageDecimalPoints, asOfDate]);
  const strategyConfig = useMemo(() => {
    if (!userId || !investorAccountId) {
      return skipToken;
    }
    return {
      userId,
      investorAccountId
    };
  }, [userId, investorAccountId]);
  const {
    data: assetData,
    isError: assetIsError,
    isFetching: assetIsFetching,
    isUninitialized: assetIsUninitialized
  } = investmentsApi.useGetInvestmentsByAssetClassQuery(assetConfig, config);
  const {
    data: strategyData,
    isError: strategyIsError,
    isFetching: strategyIsFetching,
    isUninitialized: strategyIsUninitialized
  } = investmentsApi.useGetInvestmentStrategyQuery(strategyConfig, config);
  const targetAllocations = useMemo(() => {
    var _strategyData$investm, _strategyData$investm2, _strategyData$investm3;
    const assetClassAllocations = (strategyData === null || strategyData === void 0 ? void 0 : (_strategyData$investm = strategyData.investmentStrategyDetails) === null || _strategyData$investm === void 0 ? void 0 : _strategyData$investm.assetClassAllocations) || [];
    const allocationsList = [...assetClassAllocations];
    const reserve = (_strategyData$investm2 = strategyData === null || strategyData === void 0 ? void 0 : (_strategyData$investm3 = strategyData.investmentStrategyDetails) === null || _strategyData$investm3 === void 0 ? void 0 : _strategyData$investm3.reserve) !== null && _strategyData$investm2 !== void 0 ? _strategyData$investm2 : 0;
    allocationsList.push({
      allocationPercentage: reserve,
      assetClass: AssetClassType.Cash
    });
    return formatTargetAllocations(orderBy(allocationsList, 'allocationPercentage', 'desc'));
  }, [strategyData]);
  const currentAllocations = useMemo(() => compact(targetAllocations === null || targetAllocations === void 0 ? void 0 : targetAllocations.map(targetAllocation => {
    var _assetData$currentVal;
    return assetData === null || assetData === void 0 ? void 0 : (_assetData$currentVal = assetData.currentValueBreakdown) === null || _assetData$currentVal === void 0 ? void 0 : _assetData$currentVal.find(currentAllocation => (currentAllocation === null || currentAllocation === void 0 ? void 0 : currentAllocation.title) === (targetAllocation === null || targetAllocation === void 0 ? void 0 : targetAllocation.assetClass));
  })), [targetAllocations, assetData]);
  const assetList = useMemo(() => Array.from(new Set(targetAllocations === null || targetAllocations === void 0 ? void 0 : targetAllocations.map(_ref2 => {
    let {
      assetClass
    } = _ref2;
    return formatAssetClass(assetClass);
  }).concat(compact(currentAllocations === null || currentAllocations === void 0 ? void 0 : currentAllocations.map(currentAllocation => currentAllocation === null || currentAllocation === void 0 ? void 0 : currentAllocation.title))))), [targetAllocations, currentAllocations]);
  const allocations = useMemo(() => reduce(assetList, (allocationObj, asset) => {
    const current = find(currentAllocations, {
      title: asset
    }) || {
      assetClass: asset,
      title: asset,
      percentage: 0
    };
    const target = find(targetAllocations, {
      assetClass: asset
    }) || {
      assetClass: asset,
      title: asset,
      allocationPercentage: 0
    };
    if (current && target) {
      return {
        [AllocationType.Current]: [...allocationObj[AllocationType.Current], {
          assetClass: current.assetClass,
          title: current.title,
          percentage: current.percentage
        }],
        [AllocationType.Target]: [...allocationObj[AllocationType.Target], {
          assetClass: current.assetClass,
          title: asset,
          percentage: target.allocationPercentage
        }],
        [AllocationType.Diff]: [...allocationObj[AllocationType.Diff], {
          assetClass: current.assetClass,
          title: current.title,
          percentage: new Big(current.percentage).minus(target.allocationPercentage).toNumber()
        }]
      };
    }
    return allocationObj;
  }, {
    [AllocationType.Current]: [],
    [AllocationType.Target]: [],
    [AllocationType.Diff]: []
  }), [assetList, currentAllocations, targetAllocations]);
  const isLoading = assetIsFetching || strategyIsFetching || assetIsUninitialized || strategyIsUninitialized;
  const isError = assetIsError || strategyIsError;
  return {
    isLoading,
    isError,
    targetAllocations,
    currentAllocations,
    assetList,
    allocations
  };
};