import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React from 'react';
import { FilterTypeEnum } from '@yieldstreet/tool-kit';
import { ToggleInput } from '@yieldstreet/ui-kit';
import { TEST_IDS } from './FilterView.model';
import { FilterHeader, FilterHeaderItem, SwitchFilterWrapper } from './FilterView.style';
export const SwitchFilter = _ref => {
  let {
    name,
    value,
    onChange,
    filter
  } = _ref;
  const {
    description,
    copy
  } = filter;
  return __jsx("div", {
    "data-cy": TEST_IDS[FilterTypeEnum.Boolean]
  }, __jsx(FilterHeader, null, __jsx(FilterHeaderItem, null, __jsx(_Typography, {
    variant: "body1"
  }, description, (copy === null || copy === void 0 ? void 0 : copy.title) && __jsx(React.Fragment, null, __jsx("br", null), __jsx(_Typography, {
    component: "span",
    variant: "body2"
  }, copy === null || copy === void 0 ? void 0 : copy.title)))), __jsx(FilterHeaderItem, null, __jsx(ToggleInput, {
    name: name,
    value: value,
    onChange: v => {
      onChange(v ? 'false' : undefined);
    }
  }))), (copy === null || copy === void 0 ? void 0 : copy.content) && __jsx(SwitchFilterWrapper, null, __jsx(_Typography, {
    component: "span",
    variant: "body2"
  }, copy === null || copy === void 0 ? void 0 : copy.content)));
};