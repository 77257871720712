import { anonymizeAddress } from './common/address';
import { anonymizeUserState, anonymizeUserProfile, anonymizeUserContact, anonymizeUser2FAStatus } from './user';
import { anonymizeInvestorAccount, anonymizeInvestorAccountAiq, anonymizeInvestorAccountAiqDocument } from './investor-account';
import { anonymizeBankAccount } from './bank-account';
import { anonymizeRecurringPayment, anonymizePayment } from './payment';
import { anonymizeBeneficialOwner } from './beneficial-owner';
import { anonymizeBeneficialOwnerSummary } from './beneficial-owner/beneficial-owner-summary';
import { anonymizeTaxDocument } from './tax-document';
import { anonymizeInvestor } from './investor';
export const anonymizerRegister = {
  address: anonymizeAddress,
  userState: anonymizeUserState,
  userProfile: anonymizeUserProfile,
  userContact: anonymizeUserContact,
  user2FAStatus: anonymizeUser2FAStatus,
  bankAccount: anonymizeBankAccount,
  investorAccount: anonymizeInvestorAccount,
  investorAccountAiq: anonymizeInvestorAccountAiq,
  investorAccountAiqDocument: anonymizeInvestorAccountAiqDocument,
  recurringPayment: anonymizeRecurringPayment,
  beneficialOwner: anonymizeBeneficialOwner,
  beneficialOwnerSummary: anonymizeBeneficialOwnerSummary,
  taxDocument: anonymizeTaxDocument,
  payment: anonymizePayment,
  investor: anonymizeInvestor
};