import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useMemo } from 'react';
import camelCase from "lodash/camelCase";
export const TEST_ID_ATTR = 'data-cy';

/**
 * Converts a hyphenated string to camelCase format with a maximum length constraint.
 *
 * @template S The input string to be converted to camelCase.
 * @template MaxLength The maximum length of the output string. Defaults to 50. This is to prevent `Type instantiation is excessively deep and possibly infinite.`
 *
 * @param S The input string to be converted to camelCase.
 *
 * @returns The input string converted to camelCase format with a maximum length constraint.
 *
 * @example TestKeyCamelCase<'header-left-icon'>; // "headerLeftIcon"
 */

/**
 * ConcatenatedTestId is a string that is either a concatenation of the testId and the key
 *
 * @typeParam TestKey - key to construct the concatenation string
 * @typeParam TestID - testId to construct the concatenation string
 *
 * @example
 * TestID = 'test' and TestKey = 'key' // 'test-key'
 */

/**
 * MaybeConcatenatedTestId is a string that is either a concatenation of the testId and the key or just the testId
 *
 * @typeParam TestKey - key to construct the concatenation string
 * @typeParam TestID - testId to construct the concatenation string
 *
 * @example
 * TestID = 'test' and TestKey = 'key' // 'test-key'
 *
 * @example - or just the TestID if the key is not defined
 * TestID = 'test' and TestKey = undefined // 'test'
 */

/**
 * TestIDValue is a string that is either a concatenation of the testId and the key or just the testId or empty string
 *
 * @typeParam TestKey - key to construct the concatenation string
 * @typeParam TestID - testId to construct the concatenation string
 *
 * @example
 * TestID = 'test' and TestKey = 'key' // 'test-key'
 *
 * @example - or just the TestID if the key is not defined
 * TestID = 'test' and TestKey = undefined // 'test'
 *
 * @example - or just empty string if the testId is not defined
 * TestID = undefined and TestKey = undefined // ''
 */

/**
 * TestIdAttrType defines an object that has a key that is a concatenation of the testId and the key
 *
 * @typeParam TestKey - key to construct the testId Object
 * @typeParam TestID - testId to construct the testId Object
 *
 * @example
 * TestID = 'test' and TestKey = 'key' // { 'data-cy': 'test-key' }
 *
 * @example - or just the TestID if the key is not defined
 * TestID = 'test' and TestKey = undefined // { 'data-cy': 'test' }
 *
 * @example - or just empty string if the testId is not defined
 * TestID = testId = undefined and key = 'key' // attr: { 'data-cy': '' }
 */

/**
 * TestIDSelector is a string that can be used with querySelector to find the testId element
 *
 * @typeParam TestKey - key to construct the concatenation string
 * @typeParam TestID - testId to construct the concatenation string
 *
 * @example
 * TestID = 'test' and TestKey = 'key' // '[data-cy="test-key"]'
 *
 * @example - or just the TestID if the key is not defined
 * TestID = 'test' and TestKey = undefined // '[data-cy="test"]'
 *
 * @example - or just empty string if the testId is not defined
 * TestID = undefined and TestKey = undefined // '[data-cy=""]'
 */

/**
 * TestIdObjType defines an object that has 2 keys that are a concatenation of the testId and the key
 *
 * @typeParam TestKey - key to construct the testId Object
 * @typeParam TestID - testId to construct the testId Object
 *
 * @example
 * TestID = 'test' and TestKey = 'key' // { id: 'test-key', attr: { 'data-cy': 'test-key' } }
 *
 * @example - or just the TestID if the key is not defined
 * TestID = 'test' and TestKey = undefined // { id: 'test', attr: { 'data-cy': 'test' } }
 *
 * @example - or just empty string if the testId is not defined
 * TestID = testId = undefined and key = 'key' // { id: '', attr: { 'data-cy': '' } }
 */

/**
 * TestIdObjKeyPairType defines an object that has a key that is a concatenation of the testId and the key
 *
 * @typeParam TestKey - key to construct the testId Object
 * @typeParam TestID - testId to construct the testId Object
 *
 * @example
 * TestID = 'test' and TestKey = 'key' => { key: { id: 'test-key', attr: { 'data-cy': 'test-key' } } }
 *
 * @example - or just empty string if the testId is not defined
 * TestID = undefined and TestKey = 'key' => { key: { id: '', attr: { 'data-cy': '' } } }
 */

/**
 * getTestIds is a function that generates a memoized list of TestIdObjKeyPairType objects
 *
 * @warning Do not use this function directly, the hook generated by genTestIds instead
 *
 * @internal
 * @param {array} keys - an array of keys
 * @param {string} testId - string used to generate the id
 *
 * @returns {TestIdObjKeyPairType} - an array of TestIdObjKeyPairType objects
 *
 * @example
 * const { getTestIds } genTestIds(['key1', 'key2']);
 * const testIds = getTestIds('test'); // { base: {...}, key1: {...}, key2: {...} }
 * testIds.base.id // 'test'
 * testIds.base.attr // { 'data-cy': 'test' }
 */
const getTestIds = (keys, testId) => {
  /**
   * getTestIdObj is a function that returns an object that has a key object pair
   *
   * @internal
   *
   * @param {string | undefined} key - a key
   * @param {string | undefined} id - string used to generate the id
   *
   * @returns {TestIdObjType} - an object that has a key object pair
   *
   * @example
   * getTestIdObj() // { id: '', attr: { 'data-cy': '' } }
   * getTestIdObj('test') // { id: 'test', attr: { 'data-cy': 'test' } }
   * getTestIdObj('test', 'key') // { id: 'test-key', attr: { 'data-cy': 'test-key' } }
   */
  const getTestIdObj = (id, key) => {
    let testId = ``;
    if (id) {
      testId = id;
      if (key) {
        testId = `${id}-${key}`;
      }
    } else if (key) {
      testId = `${key}`;
    }
    return {
      id: testId,
      attr: {
        [TEST_ID_ATTR]: testId
      },
      selector: `[${TEST_ID_ATTR}="${testId}"]`
    };
  };
  const keyValues = keys.reduce((acc, key) => {
    const csKey = camelCase(key);
    acc[csKey] = getTestIdObj(testId, key);
    return acc;
  }, {});
  return _objectSpread({
    base: getTestIdObj(testId)
  }, keyValues);
};

/**
 * useTestIds is a hook that extracts the testId for the component props
 * and generates a memoized list of TestIdObjKeyPairType objects
 * @warning Do not use this function directly, the hook generated by genTestIds instead
 *
 * @internal
 * @param {array} keys - an array of keys
 * @param {TestProps} props - components prop that must contain testId
 *
 * @returns {TestIdObjKeyPairType} - an array of TestIdObjKeyPairType objects
 *
 * @example
 * const { useTestIds } genTestIds(['key1', 'key2']);
 * const testIds = useTestIds(props); // { base: {...}, key1: {...}, key2: {...} }
 * testIds.base.id // 'test'
 * testIds.base.attr // { 'data-cy': 'test' }
 **/
const useTestIds = (keys, props) => {
  const {
    testId
  } = props;
  return useMemo(() => getTestIds(keys, testId), [testId]);
};

/**
 * genTestIds is a generator function that return a get function and a hook function
 * @param {array} keys - an array of keys that will be used to create the returns a
 * function that returns an array of object that has a key object pair
 *
 * @example
 * const testId = 'test';
 * const { getTestIds, useTestIds } genTestIds(['key1', 'key2']);
 * getTestIds(testId).base // { id: 'test', attr: { 'data-cy': 'test' } }
 * getTestIds(testId).key1 // { id: 'test-key1', attr: { 'data-cy': 'test-key1' } }
 * getTestIds(testId).key2 // { id: 'test-key2', attr: { 'data-cy': 'test-key2' } }
 */
export const genTestIds = function () {
  let keys = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return {
    getTestIds: testId => getTestIds(keys, testId),
    useTestIds: props => {
      return useTestIds(keys, {
        testId: (props === null || props === void 0 ? void 0 : props.testId) || (props === null || props === void 0 ? void 0 : props[TEST_ID_ATTR]) || (props === null || props === void 0 ? void 0 : props.dataCy) || ''
      });
    }
  };
};