import _Link from "@mui/material/Link";
import _Box from "@mui/material/Box";
var __jsx = React.createElement;
import React from 'react';
import { PageLoader } from '@yieldstreet/ui-kit/layouts';
import { Communication } from 'sharedComponents/Communication';
import MetaData from 'sharedComponents/MetaData';
import ReservedOffering from './ReservedOffering/ReservedOffering';
import OfferingV5 from './v5/OfferingV5';
const Offering = _ref => {
  let {
    offering,
    isOfferingLoading,
    userState,
    assetClasses,
    metrics,
    history,
    isLoadingError,
    rollover
  } = _ref;
  const reservedOffering = (offering === null || offering === void 0 ? void 0 : offering.reserved) || false;
  const note506B = (offering === null || offering === void 0 ? void 0 : offering.note506RuleType) === 'R_506B';
  const metaData = {
    title: `${offering === null || offering === void 0 ? void 0 : offering.title} - Yieldstreet` || 'Yieldstreet Offering',
    image: offering === null || offering === void 0 ? void 0 : offering.thumbnailImageUrl,
    description: `Explore the ${offering === null || offering === void 0 ? void 0 : offering.title} offering. ${offering === null || offering === void 0 ? void 0 : offering.summary}. Learn more about this investment opportunity.`,
    canonicalUrl: `/offering/${offering === null || offering === void 0 ? void 0 : offering.urlHash}/${offering === null || offering === void 0 ? void 0 : offering.SEOTitle}/`,
    meta: [{
      name: 'twitter:card',
      content: 'summary_large_image'
    }, {
      name: 'twitter:site',
      content: '@yieldstreet'
    }, {
      name: 'twitter:title',
      content: offering === null || offering === void 0 ? void 0 : offering.title
    }, {
      name: 'twitter:description',
      content: offering === null || offering === void 0 ? void 0 : offering.summary
    }, {
      name: 'twitter:image',
      content: offering === null || offering === void 0 ? void 0 : offering.heroImageUrl
    }]
  };
  if (reservedOffering === true) {
    return __jsx(ReservedOffering, {
      title: "Reserved",
      subtitle: "This offering is currently reserved but may open up to additional segments in the future.",
      cta: "View open offerings",
      destination: "/offerings",
      metaData: metaData
    });
  }
  if (note506B && !userState.loggedIn) {
    return __jsx(ReservedOffering, {
      title: "Offering locked",
      subtitle: "This offering is only accessible to logged in members.",
      cta: "Sign up / Login today",
      destination: `/login?redirect=/offering/${offering === null || offering === void 0 ? void 0 : offering.urlHash}`,
      metaData: metaData
    });
  }
  const loader = __jsx(_Box, {
    className: "main-offering-empty",
    testId: "main-offering-empty",
    sx: {
      mt: 10
    }
  }, __jsx(PageLoader, {
    center: true
  }));
  if (isOfferingLoading) {
    return loader;
  } else if (offering) {
    return __jsx(_Box, {
      className: "main-offering-loaded",
      sx: {
        background: 'background.default'
      }
    }, __jsx(Communication, {
      location: "offering_page"
    }), __jsx(MetaData, {
      metaData: metaData
    }), __jsx(OfferingV5, {
      offering: offering,
      userState: userState,
      assetClasses: assetClasses,
      metrics: metrics,
      history: history,
      rollover: rollover
    }));
  } else if (isLoadingError) {
    return __jsx(_Box, {
      sx: {
        background: 'background.default',
        p: 10
      }
    }, "An error has occurred. Please try again, and if the problem persists,", ' ', __jsx(_Link, {
      href: "mailto:investments@yieldstreet.com"
    }, " contact us"), ".");
  } else {
    return loader;
  }
};
export default Offering;