var __jsx = React.createElement;
import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { TBody, Tr, Td } from 'pages/portfolio/common/Table';
const SkeletonLine = () => __jsx(Skeleton, {
  variant: "text",
  sx: {
    fontSize: '14px'
  }
});
export const ReturnPaymentsDesktopTableBodyLoading = _ref => {
  let {
    testAttr
  } = _ref;
  return __jsx(TBody, testAttr, Array(3).fill('').map((_, idx) => __jsx(Tr, {
    key: idx
  }, __jsx(Td, null, __jsx(SkeletonLine, null)), __jsx(Td, null, __jsx(SkeletonLine, null)), __jsx(Td, null, __jsx(SkeletonLine, null)), __jsx(Td, null, __jsx(SkeletonLine, null)), __jsx(Td, null, __jsx(SkeletonLine, null)), __jsx(Td, null, __jsx(SkeletonLine, null)))));
};