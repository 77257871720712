var __jsx = React.createElement;
import React, { useCallback, useMemo } from 'react';
import { Paragraph } from '@yieldstreet/ui-kit';
import { NumericFormat } from 'react-number-format';
import { MetricWrapper, PastMetricWrapper, TextWrapper, PastMetricItem, PastMetric, RecentlyFunded } from './PastMetrics.style';
export const PastMetrics = _ref => {
  let {
    metric
  } = _ref;
  const {
    available,
    fullyRepaid,
    availableInDays,
    interestEarnedAmount,
    totalPaymentAmount,
    irr
  } = metric || {};
  const dataCyKey = `past-offering`;
  const metricTitle = () => {
    if (available && !fullyRepaid) {
      return 'Active metrics';
    } else if (available && fullyRepaid) {
      return 'Final metrics';
    }
    return 'Recently funded';
  };

  /**
   *
   * @param {label, value, prefix, suffix} items
   * @returns
   */
  const metricItem = useCallback(items => {
    const renderMetricItems = items.map(item => {
      return __jsx(PastMetricItem, {
        "data-cy": `${dataCyKey}-${item.key}`,
        key: item.key
      }, __jsx(Paragraph, {
        inverse: true,
        small: true
      }, item.label), __jsx(Paragraph, {
        inverse: true,
        small: true
      }, __jsx(NumericFormat, {
        value: item.value,
        displayType: "text",
        thousandSeparator: true,
        prefix: item.prefix,
        suffix: item.suffix,
        decimalScale: item.decimalScale
      })));
    });
    return __jsx(MetricWrapper, null, __jsx(PastMetric, null, renderMetricItems));
  }, [dataCyKey]);
  const renderRecentlyFunded = useCallback(() => {
    return __jsx(RecentlyFunded, {
      "data-cy": `${dataCyKey}-recently-funded`,
      inverse: true,
      small: true
    }, "This offering was recently closed. ", __jsx("br", null), "Metrics are expected in ", availableInDays, " ", availableInDays > 1 ? 'days' : 'day', ".");
  }, [availableInDays, dataCyKey]);
  const renderMetrics = useMemo(() => {
    if (!available && availableInDays) {
      return renderRecentlyFunded();
    }
    if (available && !fullyRepaid) {
      const data = [{
        key: 'interest-earned',
        label: 'Interest earned',
        value: Math.round(interestEarnedAmount),
        prefix: '$'
      }, {
        key: 'principal-interest-paid',
        label: 'Principal + interest paid',
        value: Math.round(totalPaymentAmount),
        prefix: '$'
      }];
      return metricItem(data);
    }
    if (available && fullyRepaid) {
      const data = [{
        label: 'IRR',
        value: irr * 100,
        suffix: '%',
        decimalScale: 2
      }, {
        label: 'Principal + interest paid',
        value: Math.round(totalPaymentAmount),
        prefix: '$',
        decimalScale: 0
      }];
      return metricItem(data);
    }
    return null;
  }, [available, availableInDays, fullyRepaid, irr, totalPaymentAmount, interestEarnedAmount, metricItem, renderRecentlyFunded]);
  return __jsx(PastMetricWrapper, {
    "data-cy": `${dataCyKey}-metric-wrapper`
  }, __jsx(TextWrapper, null, __jsx(Paragraph, {
    inverse: true,
    small: true,
    secondary: true,
    semiBold: true,
    "data-cy": `${dataCyKey}-subtitle`
  }, metricTitle(), renderMetrics)));
};