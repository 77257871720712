import { useCallback, useEffect, useState } from 'react';
import { usePreInvestmentConfig } from 'utils/investmentEligibility';
import { useHistory } from 'react-router';
import { InvestorAccountActionCode, InvestorEntityStepProvider, InvestorEntityStepVerificationStatus } from '@yieldstreet/platform-kit';
import { AppAction, appActionManager, isInvestorEntityStatusActionable, usePortfolioEligibilityCheck, useProviderInvestorEntityMigration } from '@yieldstreet/tool-kit';
import { INVESTOR_ENTITY_KYC_DISCRETIONARY_PATH } from 'pages/investorEntity/InvestorEntityContainer.model';
import { getInvestorEntityRoute, useRouteInvestorEntityId, useRouteInvestorEntityStatusPollCheck, useRouteManagementStrategy } from '../../../utils';
import { INVESTOR_ENTITY_MNGT_ROOT_PATH } from '../../Management';
import { BANK_FLOW_LINK_A_BANK_ACCOUNT } from '../../../../bankLinking/BankLinkingPageFlow.model';
export const useKYCNextStep = (skipPollChecks, defaultHandlerOverride) => {
  const {
    0: proceedToNextStep,
    1: setProceedToNextStep
  } = useState(false);
  const investorEntityId = useRouteInvestorEntityId();
  const managementStrategy = useRouteManagementStrategy();
  const history = useHistory();
  const {
    isLoading: isProviderMigrationLoading,
    isMigrationOngoing
  } = useProviderInvestorEntityMigration(investorEntityId);

  // poll identity status in case of pending to update accordingly to other final states in case of async
  const {
    isLoading: isStatusCheckLoading,
    isCheckFailed: isStatusCheckFailed,
    data: {
      identity,
      bankAccounts
    } = {}
  } = useRouteInvestorEntityStatusPollCheck(_ref => {
    let {
      identity
    } = _ref;
    return !isInvestorEntityStatusActionable(identity);
  }, skipPollChecks);
  const {
    action: portfolioEligibilityAction,
    isLoading: isPortfolioEligibilityLoading
  } = usePortfolioEligibilityCheck({
    investorEntityId,
    requiredActions: [AppAction.CONTINUE_KYC, AppAction.SUBMIT_EXTRA_KYC_INFO, AppAction.LINK_BANK_ACCOUNT],
    config: {
      skipPollChecks
    }
  });
  const isLoading = isProviderMigrationLoading || isStatusCheckLoading || isPortfolioEligibilityLoading;
  const isCheckFailed = isStatusCheckFailed;
  const {
    handlePreInvestRedirect
  } = usePreInvestmentConfig(investorEntityId, InvestorAccountActionCode.SET_KYC_2);
  const defaultHandler = useCallback(() => {
    if (portfolioEligibilityAction) {
      return appActionManager.callAction(portfolioEligibilityAction.action, portfolioEligibilityAction.params);
    }
    if (defaultHandlerOverride !== null && defaultHandlerOverride !== void 0 && defaultHandlerOverride()) {
      return;
    }
    history.push(getInvestorEntityRoute(investorEntityId, BANK_FLOW_LINK_A_BANK_ACCOUNT, managementStrategy));
  }, [defaultHandlerOverride, history, investorEntityId, portfolioEligibilityAction, managementStrategy]);
  useEffect(() => {
    if (isLoading || !identity || !proceedToNextStep) {
      return;
    }
    if (identity.status === InvestorEntityStepVerificationStatus.CONFIRMED && (bankAccounts === null || bankAccounts === void 0 ? void 0 : bankAccounts.status) !== InvestorEntityStepVerificationStatus.CONFIRMED) {
      history.push(getInvestorEntityRoute(investorEntityId, BANK_FLOW_LINK_A_BANK_ACCOUNT, managementStrategy));
      return;
    }
    if (identity.status !== InvestorEntityStepVerificationStatus.CONFIRMED || isCheckFailed) {
      if (identity.provider === InvestorEntityStepProvider.YS) {
        history.push(getInvestorEntityRoute(investorEntityId, INVESTOR_ENTITY_MNGT_ROOT_PATH, managementStrategy));
        return;
      }
      history.push(getInvestorEntityRoute(investorEntityId, INVESTOR_ENTITY_KYC_DISCRETIONARY_PATH, managementStrategy));
      return;
    }
    handlePreInvestRedirect(defaultHandler);
  }, [history, isLoading, isCheckFailed, proceedToNextStep, isMigrationOngoing, investorEntityId, handlePreInvestRedirect, defaultHandler, identity, bankAccounts, managementStrategy]);
  const redirectToNextStep = useCallback(() => setProceedToNextStep(true), [setProceedToNextStep]);
  return {
    isLoading,
    redirectToNextStep
  };
};