import _Stack from "@mui/material/Stack";
import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React from 'react';
import CloseIcon from '../../assets/graphics/icons/close.svg';
import WarningIcon from '../../assets/graphics/icons/warning.svg';
import { Close, Container, Description, Icon, SExternalLink, TextWrapper } from './TopNotification.style';
export const TopNotification = _ref => {
  let {
    backgroundColor,
    description,
    dismissAction,
    dismissible,
    externalUrl,
    icon = true,
    linkText,
    path,
    title
  } = _ref;
  return __jsx(Container, {
    backgroundColor: backgroundColor
  }, __jsx(TextWrapper, null, __jsx(_Stack, {
    direction: "row"
  }, icon && __jsx(Icon, {
    src: WarningIcon
  }), title && __jsx(_Typography, {
    semiBold: true
  }, title)), __jsx(Description, null, description), linkText && __jsx(SExternalLink, {
    href: path || externalUrl,
    target: externalUrl ? '_blank' : '',
    rel: "noopener noreferrer"
  }, linkText)), dismissible && __jsx(Close, {
    src: CloseIcon,
    onClick: dismissAction
  }));
};