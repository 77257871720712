import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import throttle from 'lodash/throttle';
export default (function () {
  let options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const defaultOptions = {
    threshold: 20,
    maxDisplays: 1,
    eventThrottle: 200,
    onExitIntent: () => {}
  };
  return (() => {
    const config = _objectSpread(_objectSpread({}, defaultOptions), options);
    const eventListeners = new Map();
    let displays = 0;
    const addEvent = (eventName, callback) => {
      document.addEventListener(eventName, callback, false);
      eventListeners.set(`document:${eventName}`, {
        eventName,
        callback
      });
    };
    const removeEvent = key => {
      const {
        eventName,
        callback
      } = eventListeners.get(key);
      document.removeEventListener(eventName, callback);
      eventListeners.delete(key);
    };
    const shouldDisplay = position => {
      if (position <= config.threshold && displays < config.maxDisplays) {
        displays++;
        return true;
      }
      return false;
    };
    const mouseDidMove = event => {
      if (shouldDisplay(event.clientY)) {
        config.onExitIntent();
        if (displays >= config.maxDisplays) {
          removeEvents();
        }
      }
    };
    const removeEvents = () => {
      eventListeners.forEach((_value, key, _map) => removeEvent(key));
    };
    addEvent('mousemove', throttle(mouseDidMove, config.eventThrottle));
    return removeEvents;
  })();
});