import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectDestructuringEmpty from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectDestructuringEmpty.js";
var __jsx = React.createElement;
import React, { useState, useEffect } from 'react';
import { responsive } from 'sharedComponents/utils/responsive';
export const withViewPort = WrappedComponent => _ref => {
  let props = Object.assign({}, (_objectDestructuringEmpty(_ref), _ref));
  const {
    0: viewPort,
    1: setViewPort
  } = useState(null);
  useEffect(() => {
    responsive(setViewPort, true);
  }, []);
  return __jsx(WrappedComponent, _extends({}, props, {
    viewPort: viewPort
  }));
};