import { InvestmentPerformanceStatus } from '@yieldstreet/platform-kit';
import { genTestIds } from '@yieldstreet/tool-kit';
import { useTheme } from 'styled-components';
import ArrowIcon from './assets/arrow_forward.svg';
import SuccessIcon from './assets/success.svg';
export const {
  useTestIds
} = genTestIds(['label', 'icon']);
export const StatusTagInfo = status => {
  const theme = useTheme();
  switch (status) {
    case InvestmentPerformanceStatus.Active:
      return {
        color: theme.colors.background_success,
        icon: SuccessIcon
      };
    case InvestmentPerformanceStatus.Warning:
      return {
        color: theme.colors.background_warning,
        icon: ArrowIcon
      };
    case InvestmentPerformanceStatus.Default:
      return {
        color: theme.colors.background_error,
        icon: ArrowIcon
      };
    case InvestmentPerformanceStatus.Pending:
    default:
      return {
        color: theme.colors.input_disabled_background,
        icon: ArrowIcon
      };
  }
};