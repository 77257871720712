import { HttpMethods } from '../../../enums';
import { Tags, restApi } from '../../common';
export const twoFactorAuthApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.UserTwoFactorAuthStatus, Tags.UserState]
}).injectEndpoints({
  endpoints: builder => {
    const status = builder.query({
      query: () => {
        return {
          url: '/a/api/user/twofa/status',
          method: HttpMethods.Get
        };
      },
      providesTags: [Tags.UserTwoFactorAuthStatus]
    });
    const login = builder.mutation({
      query: _ref => {
        let {
          token
        } = _ref;
        return {
          url: '/a/api/user/twofa/login',
          method: HttpMethods.Post,
          data: {
            token
          }
        };
      },
      invalidatesTags: [Tags.UserState, Tags.UserTwoFactorAuthStatus]
    });
    const register = builder.mutation({
      query: _ref2 => {
        let {
          phone,
          method
        } = _ref2;
        return {
          url: '/a/api/user/twofa/register',
          method: HttpMethods.Post,
          data: {
            phone,
            method
          }
        };
      },
      invalidatesTags: [Tags.UserTwoFactorAuthStatus]
    });
    const confirm = builder.mutation({
      query: _ref3 => {
        let {
          token
        } = _ref3;
        return {
          url: '/a/api/user/twofa/confirm',
          method: HttpMethods.Post,
          data: {
            token
          }
        };
      },
      invalidatesTags: [Tags.UserTwoFactorAuthStatus, Tags.UserState]
    });
    const resend = builder.mutation({
      query: () => {
        return {
          url: '/a/api/user/twofa/resend',
          method: HttpMethods.Post
        };
      },
      invalidatesTags: [Tags.UserTwoFactorAuthStatus]
    });
    const confirmEmail = builder.mutation({
      query: _ref4 => {
        let {
          token
        } = _ref4;
        return {
          url: '/a/api/user/twofa/confirm-email',
          method: HttpMethods.Post,
          data: {
            token
          }
        };
      },
      invalidatesTags: [Tags.UserTwoFactorAuthStatus]
    });
    const createFactor = builder.mutation({
      query: () => {
        return {
          url: '/a/api/user/twofa/factor',
          method: HttpMethods.Post
        };
      },
      invalidatesTags: [Tags.UserTwoFactorAuthStatus]
    });
    return {
      status,
      login,
      register,
      confirm,
      resend,
      confirmEmail,
      createFactor
    };
  }
});