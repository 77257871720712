import { isApiResponseError } from '@yieldstreet/platform-kit';
export const DEFAULT_ERROR = 'Something went wrong. Please try again and contact us at investments@yieldstreet.com if this issue continues.';
export const getApiErrorMessage = function (error) {
  let defaultMessage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : DEFAULT_ERROR;
  if (error && isApiResponseError(error) && error.response) {
    const {
      data
    } = error.response;
    return (data === null || data === void 0 ? void 0 : data.error) || (data === null || data === void 0 ? void 0 : data.message) || defaultMessage;
  }
  return defaultMessage;
};