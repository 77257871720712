import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["items", "step", "scrollRef"];
var __jsx = React.createElement;
import React, { useEffect } from 'react';
import { ItemContainer, ItemTitle, ProgressBar, ProgressDot, ProgressLine, StepProgressBarContainer } from './StepProgressBar.style';
export const StepProgressBar = _ref => {
  let {
      items,
      step = 1,
      scrollRef
    } = _ref,
    rest = _objectWithoutProperties(_ref, _excluded);
  const totalSteps = (items === null || items === void 0 ? void 0 : items.length) || 0;
  const progress = (step - 1) / (totalSteps - 1) * 100;
  const getTextPosition = index => index / (totalSteps - 1) * 100;
  const progressBaseId = 'progress-';
  useEffect(() => {
    const scrollElement = scrollRef === null || scrollRef === void 0 ? void 0 : scrollRef.current;
    if (scrollElement && step > 1) {
      for (let i = 1; i < step; i++) {
        const element = document.getElementById(`${progressBaseId}${i}`);
        if (element) {
          scrollElement.scrollLeft += element.offsetWidth;
        }
      }
    }
  }, [step, progressBaseId, scrollRef]);
  if (!totalSteps) {
    return null;
  }
  return __jsx(StepProgressBarContainer, rest, __jsx(ProgressDot, {
    left: progress
  }), __jsx(ProgressLine, null, __jsx(ProgressBar, {
    width: progress
  }), items === null || items === void 0 ? void 0 : items.map((item, index) => __jsx(ItemContainer, {
    key: index,
    left: getTextPosition(index)
  }, __jsx(ItemTitle, {
    id: `${progressBaseId}${index + 1}`,
    semiBold: step === index + 1
  }, item)))));
};