import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { quizApi } from '@yieldstreet/platform-kit';
import isArray from "lodash/isArray";
import keyBy from "lodash/keyBy";
const keyAnswers = answers => keyBy(answers, 'questionKey');
export const useQuizState = _ref => {
  let {
    quizKey,
    questionKeys,
    userId
  } = _ref;
  const {
    0: quizAnswers,
    1: setQuizAnswers
  } = useState({});
  const {
    location
  } = useHistory();
  const [getQuizAnswersByUserId, {
    isUninitialized,
    isFetching
  }] = quizApi.useLazyGetQuizAnswersByUserIdQuery();
  const fetchQuizAnswers = useCallback(async () => {
    const {
      data
    } = await getQuizAnswersByUserId({
      userId,
      quizKey,
      questionKeys
    });
    setQuizAnswers(keyAnswers(data.answers));
  }, [getQuizAnswersByUserId, questionKeys, quizKey, userId]);
  useEffect(() => {
    if (userId && isUninitialized) {
      fetchQuizAnswers();
    }
  }, [fetchQuizAnswers, userId, isUninitialized, location.state]);
  const setQuizAnswer = useCallback(async (questionKey, answers) => {
    const parsedAnswers = isArray(answers) ? answers : [answers];
    setQuizAnswers(prevQuizAnswers => _objectSpread(_objectSpread({}, prevQuizAnswers), {}, {
      [questionKey]: _objectSpread(_objectSpread({}, prevQuizAnswers[questionKey]), {}, {
        answers: parsedAnswers,
        questionKey
      })
    }));
  }, [setQuizAnswers]);
  return {
    setQuizAnswer,
    quizAnswers,
    isFetchingQuizAnswers: isUninitialized || isFetching
  };
};