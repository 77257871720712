import { useMemo } from 'react';
import flatten from "lodash/flatten";
import isEmpty from "lodash/isEmpty";
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { PortfolioStrategyValues, documentsApi, PortfolioFilters } from '@yieldstreet/platform-kit';
import { getInvestorAccountId } from '../../utils';
import { sortData } from './util/sortData';
import { TaxListQueryParams } from './useTaxList.model';
export const useTaxList = _ref => {
  let {
    investorId,
    managementStrategy = PortfolioStrategyValues.Self,
    params,
    userId
  } = _ref;
  const getDocumentsParams = useMemo(() => {
    var _params$TaxListQueryP, _params$TaxListQueryP2, _params$TaxListQueryP3;
    if (!userId || isEmpty(params)) {
      return skipToken;
    }
    return {
      account: getInvestorAccountId(investorId, managementStrategy),
      managementStrategy,
      taxDocumentTypes: (_params$TaxListQueryP = params[TaxListQueryParams.Type]) !== null && _params$TaxListQueryP !== void 0 && _params$TaxListQueryP.includes(PortfolioFilters.ALL) ? undefined : params[TaxListQueryParams.Type],
      urlHashes: (_params$TaxListQueryP2 = params[TaxListQueryParams.NoteUrlHash]) !== null && _params$TaxListQueryP2 !== void 0 && _params$TaxListQueryP2.includes(PortfolioFilters.ALL) ? undefined : params[TaxListQueryParams.NoteUrlHash],
      user: userId,
      years: (_params$TaxListQueryP3 = params[TaxListQueryParams.Year]) !== null && _params$TaxListQueryP3 !== void 0 && _params$TaxListQueryP3.includes(PortfolioFilters.ALL) ? undefined : params[TaxListQueryParams.Year]
    };
  }, [investorId, managementStrategy, params, userId]);
  const {
    data,
    isError,
    isFetching
  } = documentsApi.useGetTaxDocumentsListQuery(getDocumentsParams);
  const documents = useMemo(() => {
    if (!data || !(data !== null && data !== void 0 && data.documents)) {
      return;
    }
    const docs = flatten(Object.values(data === null || data === void 0 ? void 0 : data.documents));
    return sortData({
      data: docs,
      orderKey: params[TaxListQueryParams.Order],
      sortKey: params[TaxListQueryParams.Sort]
    });
  }, [data, params]);
  return {
    documents,
    isError,
    isFetching
  };
};