import { anonymizeObject } from '../helpers';
export const anonymizeInvestorAccount = value => anonymizeObject(value, {
  name: 'string',
  firstName: 'string',
  lastName: 'string',
  addressLine1: 'string',
  addressLine2: 'string',
  city: 'string',
  state: 'string',
  postalCode: 'string',
  aiq: ['array', 'investorAccountAiq']
});