import { useMemo } from 'react';
import { quizApi } from '@yieldstreet/platform-kit';
import { skipToken } from '@reduxjs/toolkit/dist/query';
export const useQuizAnswers = _ref => {
  let {
    userId,
    investorEntityId,
    quizId
  } = _ref;
  const {
    data: quizAnswersByUserId,
    isFetching: isFetchingAnswersByUserId,
    isLoading: isLoadingAnswersByUserId,
    isError: isGetAnswersByUserIdError,
    error: getQuizAnswersByUserIdError
  } = quizApi.useGetQuizAnswersByUserIdQuery(userId ? {
    userId
  } : skipToken);
  const {
    data: quizAnswersByInvestorEntityId,
    isFetching: isFetchingAnswersByInvestorEntityId,
    isLoading: isLoadingAnswersByInvestorEntityId,
    isError: isGetAnswersByInvestorEntityIdError,
    error: getQuizAnswersByInvestorEntityIdError
  } = quizApi.useGetQuizAnswersByInvestorEntityIdQuery(investorEntityId ? {
    investorEntityId
  } : skipToken);
  const {
    data: quizAnswersByQuizId,
    isFetching: isFetchingAnswersByQuizId,
    isLoading: isLoadingAnswersByQuizId,
    isError: isGetAnswersByQuizIdError,
    error: getQuizAnswersByQuizIdError
  } = quizApi.useGetQuizAnswersByQuizIdQuery(quizId ? {
    quizId
  } : skipToken);
  const quizAnswers = useMemo(() => {
    if (!quizAnswersByUserId && !quizAnswersByInvestorEntityId && !quizAnswersByQuizId) {
      return;
    }
    const byInvestorEntityId = (quizAnswersByInvestorEntityId === null || quizAnswersByInvestorEntityId === void 0 ? void 0 : quizAnswersByInvestorEntityId.answers) || [];
    const byUserId = (quizAnswersByUserId === null || quizAnswersByUserId === void 0 ? void 0 : quizAnswersByUserId.answers) || [];
    const byQuizId = (quizAnswersByQuizId === null || quizAnswersByQuizId === void 0 ? void 0 : quizAnswersByQuizId.answers) || [];
    const answers = [...byUserId, ...byInvestorEntityId, ...byQuizId];
    return {
      answers
    };
  }, [quizAnswersByUserId, quizAnswersByInvestorEntityId, quizAnswersByQuizId]);
  return {
    quizAnswers,
    isFetchingAnswers: isFetchingAnswersByUserId || isFetchingAnswersByInvestorEntityId || isFetchingAnswersByQuizId,
    isLoadingAnswers: isLoadingAnswersByUserId || isLoadingAnswersByInvestorEntityId || isLoadingAnswersByQuizId,
    isGetAnswersError: isGetAnswersByUserIdError || isGetAnswersByInvestorEntityIdError || isGetAnswersByQuizIdError,
    getQuizAnswersError: getQuizAnswersByUserIdError || getQuizAnswersByInvestorEntityIdError || getQuizAnswersByQuizIdError
  };
};