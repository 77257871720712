import sortBy from "lodash/sortBy";
import { AssetClassType } from '@yieldstreet/platform-kit';
const MANAGED_ASSETS_ORDERING = [AssetClassType.RealEstate, AssetClassType.PrivateCredit, AssetClassType.PrivateEquity, AssetClassType.Cash];
export const sortManagedAssets = data => {
  return sortBy(data, [item => {
    if (MANAGED_ASSETS_ORDERING.includes(item === null || item === void 0 ? void 0 : item.assetClass)) {
      return MANAGED_ASSETS_ORDERING.indexOf(item === null || item === void 0 ? void 0 : item.assetClass);
    }
    return null;
  }]);
};