import { genTestIds } from '@yieldstreet/tool-kit';
const {
  getTestIds
} = genTestIds(['loader', 'icon', 'bank-link-info', 'failure-info', 'pending-info', 'contact-ir-button', 'link-bank-button', 'do-it-later-button']);
export const testIds = getTestIds('migration');
export const copy = {
  title: 'Please link an external bank account',
  cta: 'Link a bank',
  cta_secondary: 'Do it later',
  text: `Links to your bank accounts have been removed. Please connect your bank account to your investment account.`
};