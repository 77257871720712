import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useMemo } from 'react';
import { scrollTo } from '@yieldstreet/ui-kit';
import { NAVIGATION_OFFSET, NAVIGATION_ANCHOR_ID } from './Navigation.model';
export const getContainerNavigationProps = container => {
  const {
    navigation = {}
  } = container;
  return _objectSpread({
    title: container === null || container === void 0 ? void 0 : container.title,
    slug: container === null || container === void 0 ? void 0 : container.key
  }, navigation);
};
export const scrollToContainer = slug => {
  scrollTo(slug, 'start', true, NAVIGATION_OFFSET);
};
export const scrollToTop = () => {
  scrollTo(NAVIGATION_ANCHOR_ID, 'end', false);
};
export const useOfferingNavigation = offering => {
  const {
    v5Data
  } = offering;
  const containers = (v5Data === null || v5Data === void 0 ? void 0 : v5Data.containers) || [];
  return useMemo(() => {
    const shouldShowNavigation = (containers === null || containers === void 0 ? void 0 : containers.length) && containers.some(container => {
      var _container$navigation;
      return container === null || container === void 0 ? void 0 : (_container$navigation = container.navigation) === null || _container$navigation === void 0 ? void 0 : _container$navigation.directNavigation;
    });
    const navigation = containers.filter(container => {
      var _container$navigation2;
      return container === null || container === void 0 ? void 0 : (_container$navigation2 = container.navigation) === null || _container$navigation2 === void 0 ? void 0 : _container$navigation2.directNavigation;
    }).map(container => getContainerNavigationProps(container));
    return {
      shouldShowNavigation,
      navigation
    };
  }, [containers]);
};