import ContentLoader from 'react-content-loader';
import { Label, Heading, friendlyMedia } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
export const InvestorAccountWrapper = styled.div.withConfig({
  displayName: "InvestorAccountContainerstyle__InvestorAccountWrapper",
  componentId: "sc-zgsyvl-0"
})(["width:100%;", ""], friendlyMedia.tablet`
      width: 330px;
      height: 100%;
  `);
export const InvestorAccountLoading = styled(ContentLoader).attrs({
  speed: 2,
  height: 100,
  width: 100,
  viewBox: '0 0 100 18',
  primaryColor: 'rgba(216, 220, 226, 0.4)',
  secondaryColor: 'rgb(241, 243, 246)'
}).withConfig({
  displayName: "InvestorAccountContainerstyle__InvestorAccountLoading",
  componentId: "sc-zgsyvl-1"
})([""]);
export const InvestorAccountDesktopWrapper = styled.div.withConfig({
  displayName: "InvestorAccountContainerstyle__InvestorAccountDesktopWrapper",
  componentId: "sc-zgsyvl-2"
})(["display:flex;position:absolute;justify-content:center;flex-direction:column;padding:18px;max-width:375px;background:", ";box-shadow:0px 3px 7px -3px rgba(0,0,0,0.12),0px 6px 12px -2px rgba(50,50,93,0.2);border-radius:4px;gap:10px;margin-top:10px;text-transform:unset;letter-spacing:unset;z-index:10;"], props => props.theme.colors.background_card);
export const InvestorAccountMobileWrapper = styled.div.withConfig({
  displayName: "InvestorAccountContainerstyle__InvestorAccountMobileWrapper",
  componentId: "sc-zgsyvl-3"
})(["padding:72px 20px 20px;height:100vh;overflow:scroll;background:", ";text-transform:unset;letter-spacing:unset;", ";"], props => props.theme.colors.background_page, _ref => {
  let {
    isLoading
  } = _ref;
  return isLoading && `
    padding: 0 20px;
    display: flex;
    align-items: center;
  `;
});
export const InvestorAccountViewingAs = styled(Label).withConfig({
  displayName: "InvestorAccountContainerstyle__InvestorAccountViewingAs",
  componentId: "sc-zgsyvl-4"
})(["display:block;margin-bottom:", ";letter-spacing:0.5px;"], _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.spacing.xxs;
});
export const InvestorAccountSelectWrapper = styled.div.withConfig({
  displayName: "InvestorAccountContainerstyle__InvestorAccountSelectWrapper",
  componentId: "sc-zgsyvl-5"
})([""]);
export const InvestorAccountDropdownWrapper = styled.div.withConfig({
  displayName: "InvestorAccountContainerstyle__InvestorAccountDropdownWrapper",
  componentId: "sc-zgsyvl-6"
})(["display:flex;align-items:center;gap:12px;", ";"], _ref3 => {
  let {
    hasMultipleInvestorAccounts
  } = _ref3;
  return hasMultipleInvestorAccounts && `
    cursor: pointer;
  `;
});
export const InvestorAccountDropdownSelector = styled.div.withConfig({
  displayName: "InvestorAccountContainerstyle__InvestorAccountDropdownSelector",
  componentId: "sc-zgsyvl-7"
})(["background:", ";width:25px;height:25px;border-radius:100%;align-items:center;justify-content:center;display:flex;cursor:pointer;"], props => props.theme.colors.input_button_basic_background);
export const InvestorAccountDropdownChevron = styled.div.withConfig({
  displayName: "InvestorAccountContainerstyle__InvestorAccountDropdownChevron",
  componentId: "sc-zgsyvl-8"
})(["&::before{border-style:solid;border-width:2px 2px 0 0;color:", ";content:'';display:flex;height:5px;width:5px;position:relative;transform:", ";top:", ";vertical-align:top;}"], props => props.theme.colors.icon_inverse, _ref4 => {
  let {
    isOpen
  } = _ref4;
  return isOpen ? 'rotate(-45deg)' : 'rotate(135deg)';
}, _ref5 => {
  let {
    isOpen
  } = _ref5;
  return isOpen ? '1px' : '-1px';
});
export const InvestorAccountSelectedInvestor = styled(Heading).attrs({
  type: 3
}).withConfig({
  displayName: "InvestorAccountContainerstyle__InvestorAccountSelectedInvestor",
  componentId: "sc-zgsyvl-9"
})(["text-overflow:ellipsis;max-width:400px;white-space:nowrap;overflow:hidden;position:relative;font-weight:400;"]);
export const InvestorAccountSelectedInvestorBorder = styled.div.withConfig({
  displayName: "InvestorAccountContainerstyle__InvestorAccountSelectedInvestorBorder",
  componentId: "sc-zgsyvl-10"
})(["border-bottom:1px solid ", ";max-width:280px;"], props => props.theme.colors.text_label_inverse);
export const InvestorAccountHeading = styled(Heading).attrs({
  type: 2
}).withConfig({
  displayName: "InvestorAccountContainerstyle__InvestorAccountHeading",
  componentId: "sc-zgsyvl-11"
})(["padding-bottom:20px;"]);