import update from 'immutability-helper';
import * as actions from 'store/actions/offeringDetails';
const initialState = {
  offeringList: {},
  offeringAvailability: {},
  offeringAvailabilityFetching: false,
  isFetching: false,
  offeringFeatures: null,
  offeringFeaturesFetching: false,
  confirmInvestmentExtraData: {},
  offeringEligibility: {},
  offeringEligibilityFetched: undefined,
  investorPosition: {},
  investorPositionFetching: false,
  investorLiquidation: {},
  investorLiquidationFetching: false,
  tenderOfferConfig: {},
  tenderOfferConfigFetching: false
};
const offeringDetails = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case actions.GET_OFFERING_ATTEMPT:
      {
        return update(state, {
          isFetching: {
            $set: true
          }
        });
      }
    case actions.GET_OFFERING_SUCCESS:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          offeringList: {
            $merge: {
              [action.urlHash]: action.response
            }
          }
        });
      }
    case actions.GET_OFFERING_FAILURE:
      {
        return update(state, {
          isFetching: {
            $set: false
          }
        });
      }
    case actions.GET_OFFERING_AVAILABILITY_ATTEMPT:
      {
        return update(state, {
          offeringAvailabilityFetching: {
            $set: true
          },
          offeringAvailability: {
            $set: {}
          }
        });
      }
    case actions.GET_OFFERING_AVAILABILITY_SUCCESS:
      {
        return update(state, {
          offeringAvailabilityFetching: {
            $set: false
          },
          offeringAvailability: {
            $set: action.response
          }
        });
      }
    case actions.GET_OFFERING_AVAILABILITY_FAILURE:
      {
        return update(state, {
          offeringAvailabilityFetching: {
            $set: false
          }
        });
      }
    case actions.GET_OFFERING_FEATURES_ATTEMPT:
      {
        return update(state, {
          offeringFeaturesFetching: {
            $set: true
          }
        });
      }
    case actions.GET_OFFERING_FEATURES_SUCCESS:
      {
        return update(state, {
          offeringFeaturesFetching: {
            $set: false
          },
          offeringFeatures: {
            $set: action.response
          }
        });
      }
    case actions.GET_OFFERING_FEATURES_FAILURE:
      {
        return update(state, {
          offeringFeaturesFetching: {
            $set: false
          },
          offeringFeatures: {
            $set: null
          }
        });
      }
    case actions.SAVE_CONFIRM_INVESTMENT_EXTRA_DATA:
      {
        return update(state, {
          confirmInvestmentExtraData: {
            $merge: action.data
          }
        });
      }
    case actions.GET_OFFERING_ELIGIBILITY_ATTEMPT:
      {
        return update(state, {
          offeringEligibilityFetched: {
            $set: false
          },
          offeringEligibility: {
            $set: {}
          }
        });
      }
    case actions.GET_OFFERING_ELIGIBILITY_SUCCESS:
      {
        return update(state, {
          offeringEligibilityFetched: {
            $set: true
          },
          offeringEligibility: {
            $set: action.response
          }
        });
      }
    case actions.GET_OFFERING_ELIGIBILITY_FAILURE:
      {
        return update(state, {
          offeringEligibilityFetched: {
            $set: true
          }
        });
      }
    case actions.GET_INVESTOR_POSITION_ATTEMPT:
      {
        return update(state, {
          investorPositionFetching: {
            $set: true
          },
          investorPosition: {
            $set: {}
          }
        });
      }
    case actions.GET_INVESTOR_POSITION_SUCCESS:
      {
        return update(state, {
          investorPositionFetching: {
            $set: false
          },
          investorPosition: {
            $set: action.response
          }
        });
      }
    case actions.GET_INVESTOR_POSITION_FAILURE:
      {
        return update(state, {
          investorPositionFetching: {
            $set: false
          }
        });
      }
    case actions.GET_INVESTOR_LIQUIDATION_ATTEMPT:
      {
        return update(state, {
          investorLiquidationFetching: {
            $set: true
          },
          investorLiquidation: {
            $set: {}
          }
        });
      }
    case actions.GET_INVESTOR_LIQUIDATION_SUCCESS:
      {
        return update(state, {
          investorLiquidationFetching: {
            $set: false
          },
          investorLiquidation: {
            $set: action.response
          }
        });
      }
    case actions.GET_INVESTOR_LIQUIDATION_FAILURE:
      {
        return update(state, {
          investorLiquidationFetching: {
            $set: false
          }
        });
      }
    case actions.GET_TENDER_OFFER_CONFIG_ATTEMPT:
      {
        return update(state, {
          tenderOfferConfigFetching: {
            $set: true
          },
          tenderOfferConfig: {
            $set: {}
          }
        });
      }
    case actions.GET_TENDER_OFFER_CONFIG_SUCCESS:
      {
        return update(state, {
          tenderOfferConfigFetching: {
            $set: false
          },
          tenderOfferConfig: {
            $set: action.response
          }
        });
      }
    case actions.GET_TENDER_OFFER_CONFIG_FAILURE:
      {
        return update(state, {
          tenderOfferConfigFetching: {
            $set: false
          }
        });
      }
    default:
      return state;
  }
};
export default offeringDetails;