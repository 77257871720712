import { HttpMethods } from '../../enums/http';
import { restApi, Tags } from '../common';
export const offeringEligibilityApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.OfferingEligibility]
}).injectEndpoints({
  endpoints: builder => {
    const eligibility = builder.mutation({
      query: _ref => {
        let {
          offerings
        } = _ref;
        return {
          url: `/a/api/user/resolve-offering-eligibility`,
          method: HttpMethods.Post,
          data: {
            offerings
          }
        };
      },
      invalidatesTags: (_result, _error) => [{
        type: Tags.OfferingEligibility
      }],
      transformResponse: result => (result === null || result === void 0 ? void 0 : result.offerings) || []
    });
    return {
      eligibility
    };
  }
});