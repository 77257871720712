import { Container } from '@yieldstreet/ui-kit';
import { Wrapper } from 'sharedComponents/JsonPages/ContainerSection.style';
import styled from 'styled-components';
export const ContentContainer = styled.div.withConfig({
  displayName: "OfferingV5style__ContentContainer",
  componentId: "sc-1gbccjw-0"
})(["padding:", ";"], props => props.modalView ? '15px' : 0);
export const ContentInner = styled.div.withConfig({
  displayName: "OfferingV5style__ContentInner",
  componentId: "sc-1gbccjw-1"
})([""]);
export const VisibilityDiv = styled.div.withConfig({
  displayName: "OfferingV5style__VisibilityDiv",
  componentId: "sc-1gbccjw-2"
})(["width:1px;height:1px;"]);
export const BannerWrapper = styled(Wrapper).withConfig({
  displayName: "OfferingV5style__BannerWrapper",
  componentId: "sc-1gbccjw-3"
})(["background:", ";"], props => props.theme.colors.background_light);
export const BannerInner = styled(Container).withConfig({
  displayName: "OfferingV5style__BannerInner",
  componentId: "sc-1gbccjw-4"
})(["padding-top:", ";padding-bottom:", ";"], props => props.theme.spacing.m, props => props.theme.spacing.m);