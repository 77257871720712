import { CALL_API } from '../middleware/Middleware';
export const GET_PENSCO_CUSTODIANS_ATTEMPT = 'ys/ira/pensco-custodians/ATTEMPT';
export const GET_PENSCO_CUSTODIANS_SUCCESS = 'ys/ira/pensco-custodians/SUCCESS';
export const GET_PENSCO_CUSTODIANS_FAILURE = 'ys/ira/pensco-custodians/FAILURE';
export const getPenscoCustodians = () => dispatch => {
  const endpoint = `/pensco-service/custodians`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_PENSCO_CUSTODIANS_ATTEMPT, GET_PENSCO_CUSTODIANS_SUCCESS, GET_PENSCO_CUSTODIANS_FAILURE],
      getResponse: res => res.data.custodians
    }
  });
};
export const GET_FUNDING_TERMS_ATTEMPT = 'ys/ira/funding-terms/ATTEMPT';
export const GET_FUNDING_TERMS_SUCCESS = 'ys/ira/funding-terms/SUCCESS';
export const GET_FUNDING_TERMS_FAILURE = 'ys/ira/funding-terms/FAILURE';
export const getPenscoFundingTerms = () => dispatch => {
  const endpoint = `/pensco-service/funding/terms-of-service`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_FUNDING_TERMS_ATTEMPT, GET_FUNDING_TERMS_SUCCESS, GET_FUNDING_TERMS_FAILURE],
      getResponse: res => res.data.termsOfService
    }
  });
};
export const GET_ACCT_TERMS_ATTEMPT = 'ys/ira/acct-terms/ATTEMPT';
export const GET_ACCT_TERMS_SUCCESS = 'ys/ira/acct-terms/SUCCESS';
export const GET_ACCT_TERMS_FAILURE = 'ys/ira/acct-terms/FAILURE';
export const getPenscoAccountTerms = accountType => dispatch => {
  const endpoint = `/pensco-service/account/terms-of-service`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_ACCT_TERMS_ATTEMPT, GET_ACCT_TERMS_SUCCESS, GET_ACCT_TERMS_FAILURE],
      options: {
        params: {
          accountType
        }
      },
      getResponse: res => res.data.termsOfService
    }
  });
};