var __jsx = React.createElement;
import React from 'react';
import { Route, Switch, useLocation } from 'react-router';
import { INVESTOR_ENTITY_MANAGEMENT_PATH } from 'pages/investorEntity/InvestorEntityContainer.model';
import AuthRoute from 'sharedComponents/utils/authRoute';
import { AdvicePreferences } from 'pages/accountSettings/advice/AdvicePreferences';
import { INVESTOR_ENTITY_MNGT_ROOT_PATH, INVESTOR_ENTITY_MNGT_INFO_PATH, INVESTOR_ENTITY_MNGT_PORTFOLIOS_PATH, INVESTOR_ENTITY_MNGT_ACCREDITATION_PATH, INVESTOR_ENTITY_MNGT_BANK_ACT_PATH, INVESTOR_ENTITY_MNGT_ADVICE_PATH } from './ManagementContainer.model';
import { InvestorEntitySelection } from './routes/InvestorEntitySelection';
import { AccountLayout, AccountNavigation } from './components';
import { Information, Accreditation, BankAccounts, Portfolios, EmptyRoute } from './routes';
import { ManagementWrapper } from './ManagementContainer.style';
export const ManagementContainer = () => {
  const location = useLocation();
  return __jsx(ManagementWrapper, {
    light: true
  }, __jsx(Switch, {
    location: location
  }, __jsx(Route, {
    exact: true,
    path: [INVESTOR_ENTITY_MANAGEMENT_PATH, INVESTOR_ENTITY_MNGT_ADVICE_PATH]
  }, __jsx(AccountNavigation, null, __jsx(Switch, null, __jsx(Route, {
    exact: true,
    path: INVESTOR_ENTITY_MANAGEMENT_PATH,
    component: InvestorEntitySelection
  }), __jsx(AuthRoute
  // @ts-expect-error
  // component to typed yet
  , {
    requiresAccreditedSignUp: true,
    audienceRedirect: INVESTOR_ENTITY_MANAGEMENT_PATH,
    path: INVESTOR_ENTITY_MNGT_ADVICE_PATH,
    component: AdvicePreferences
  })))), __jsx(Route, {
    path: [INVESTOR_ENTITY_MNGT_ROOT_PATH]
  }, __jsx(AccountLayout, null, __jsx(Switch, null, __jsx(Route, {
    path: INVESTOR_ENTITY_MNGT_PORTFOLIOS_PATH,
    component: Portfolios
  }), __jsx(Route, {
    path: INVESTOR_ENTITY_MNGT_INFO_PATH,
    component: Information
  }), __jsx(Route, {
    path: INVESTOR_ENTITY_MNGT_ACCREDITATION_PATH,
    component: Accreditation
  }), __jsx(Route, {
    path: INVESTOR_ENTITY_MNGT_BANK_ACT_PATH,
    component: BankAccounts
  }), __jsx(Route, {
    path: INVESTOR_ENTITY_MNGT_ROOT_PATH,
    component: EmptyRoute
  }))))));
};