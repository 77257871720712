import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { media } from '../../themes/media';
export const Header = styled.header.withConfig({
  displayName: "PageFlowstyle__Header",
  componentId: "sc-1dbody3-0"
})(["align-items:flex-start;display:flex;flex-direction:column;min-height:", ";", ""], props => props.minHeight, media.desktop`
    align-items: flex-start;
    flex-direction: row;
    position:relative;
  `);
export const LogoImg = styled.img.withConfig({
  displayName: "PageFlowstyle__LogoImg",
  componentId: "sc-1dbody3-1"
})(["display:block;max-width:185px;height:auto;"]);
export const BackNavigation = styled.div.withConfig({
  displayName: "PageFlowstyle__BackNavigation",
  componentId: "sc-1dbody3-2"
})(["align-items:center;cursor:pointer;display:flex;height:30px;position:relative;z-index:10;"]);
export const LogoContainer = styled.div.withConfig({
  displayName: "PageFlowstyle__LogoContainer",
  componentId: "sc-1dbody3-3"
})(["align-items:center;cursor:pointer;display:flex;height:30px;justify-content:center;text-align:center;position:absolute;left:50%;transform:translateX(-50%);", ""], media.desktop`
    width: 500px;
  `);
export const Counter = styled.div.withConfig({
  displayName: "PageFlowstyle__Counter",
  componentId: "sc-1dbody3-4"
})(["align-items:center;cursor:pointer;height:30px;margin-top:15px;", ""], media.desktop`
  display:flex;
  justify-content: flex-end;
  margin-left: auto;
  margin-top: 0;
  `);
export const ContentHolder = styled.div.withConfig({
  displayName: "PageFlowstyle__ContentHolder",
  componentId: "sc-1dbody3-5"
})(["display:flex;align-items:center;justify-content:flex-start;flex-direction:column;flex:1;margin-top:", ";width:100%;text-align:left;", ";", ""], props => props.$showLogo ? '20px' : 0, media.tablet`
    margin-left: auto;
    margin-right: auto;
    max-width: ${props => props.$maxWidth};
    text-align: center;
  `, media.desktop`
    margin-top: ${props => props.$showLogo ? '0' : `-${props.$minHeight}`}
  `);
export const Arrow = styled(FontAwesomeIcon).withConfig({
  displayName: "PageFlowstyle__Arrow",
  componentId: "sc-1dbody3-6"
})(["margin:0 10px 0 0;width:10px;"]);
export const Footer = styled.footer.withConfig({
  displayName: "PageFlowstyle__Footer",
  componentId: "sc-1dbody3-7"
})(["margin-top:auto;padding-top:30px;"]);