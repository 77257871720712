import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { externalProviderTestIds } from './ExternalProviderVisual.model';
import Box from '@mui/material/Box';
import { Arrow, ExternalProviderWrapper } from './ExternalProviderVisual.style';
import { ExternalProviderIcon } from './ExternalProviderIcon';
export const ExternalProviderVisual = _ref => {
  let {
    fromIcon,
    toIcon,
    showArrow
  } = _ref;
  return __jsx(ExternalProviderWrapper, externalProviderTestIds.base.attr, fromIcon && __jsx(ExternalProviderIcon, _extends({
    src: fromIcon.icon,
    alt: (fromIcon === null || fromIcon === void 0 ? void 0 : fromIcon.alt) || '',
    backgroundColor: fromIcon.backgroundColor
  }, externalProviderTestIds.fromIcon.attr)), showArrow && __jsx(Box, _extends({
    sx: {
      pr: 1
    }
  }, externalProviderTestIds.arrows.attr), __jsx(Arrow, null), __jsx(Arrow, null), __jsx(Arrow, null), __jsx(Arrow, null)), toIcon && __jsx(ExternalProviderIcon, _extends({
    src: toIcon.icon,
    alt: (toIcon === null || toIcon === void 0 ? void 0 : toIcon.alt) || '',
    backgroundColor: toIcon.backgroundColor
  }, externalProviderTestIds.toIcon.attr)));
};