var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { media } from '../../themes';
import closeIcon from './closeIcon.svg';
export const CloseButton = _ref => {
  let {
    onClick,
    'data-cy': dataCy,
    className
  } = _ref;
  return __jsx(Wrapper, {
    "data-cy": dataCy,
    onClick: onClick,
    className: className
  }, __jsx(CloseIcon, {
    src: closeIcon,
    alt: "close-icon"
  }));
};
const Wrapper = styled.button.withConfig({
  displayName: "CloseButton__Wrapper",
  componentId: "sc-kerj3m-0"
})(["background:", ";border:none;border-radius:8px;cursor:pointer;height:36px;width:36px;position:fixed;top:", ";right:", ";box-shadow:", ";z-index:2000;", ""], _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.colors.input_background;
}, _ref3 => {
  let {
    theme
  } = _ref3;
  return theme.spacing.m;
}, _ref4 => {
  let {
    theme
  } = _ref4;
  return theme.spacing.m;
}, props => props.theme.shadows.elevation_1, media.phoneLandscape`
    margin: unset;
    margin-right: -50px;
    top: unset;
    right: unset;
  `);
const CloseIcon = styled.img.withConfig({
  displayName: "CloseButton__CloseIcon",
  componentId: "sc-kerj3m-1"
})(["vertical-align:middle;"]);