import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["tagType", "children", "className", "mainTitle"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { Heading } from '../../content/text';
export const Title = _ref => {
  let {
      tagType,
      children,
      className,
      mainTitle
    } = _ref,
    otherProps = _objectWithoutProperties(_ref, _excluded);
  const type = mainTitle ? 2 : 3;
  return __jsx(TitleHeading, _extends({
    mainTitle: mainTitle,
    type: type,
    tagType: tagType || type,
    className: className
  }, otherProps), children);
};
const TitleHeading = styled(Heading).withConfig({
  displayName: "Title__TitleHeading",
  componentId: "sc-663gd6-0"
})(["margin-bottom:", "px;margin-right:auto;"], props => props.mainTitle ? 35 : 20);