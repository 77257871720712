import { CONFIGS } from '../const';
export const CONFIG_PROD = {
  KEY: CONFIGS.PROD,
  CASTLE_APP_ID: '421894221519416',
  LAUNCHDARKLY_SDK_KEY: 'sdk-768a2a5f-3d6e-4dc8-aa4a-0b551ff1d750',
  LAUNCHDARKLY_CLIENT_ID: '627bcc00efd2f015588e5c04',
  SENTRY_DSN: 'https://a76ae1b77f154a92aae61150a20865cb@sentry.io/244705',
  GOOGLE_CLIENT_ID: '735310356717-8d8tnafsj4nplbtg1a8n9m8uda1fbojc.apps.googleusercontent.com',
  GOOGLE_PLACES_KEY: 'AIzaSyDd0QgapeK4Ap0pMAJ-mN1hh9WEH4wiEhE',
  APPLE_CLIENT_ID: 'com.yieldstreet.YieldstreetWeb',
  RECAPTCHA_CLIENT_ID: '6Lcuiu0gAAAAAPfmWZNen_NPd0S17nKIUm2PPTtL',
  SEGMENT_WRITE_KEY: 'xUD6CfwtzPHYj9g2tctWM31gvr9wCCjc',
  DEBUG: false,
  BRAZE_API_KEY: '14814a72-efd5-4f9b-8391-bc2a8ddbe3fc',
  BRAZE_SDK_URL: 'sdk.iad-05.braze.com',
  // Datadog RUM setup keys
  DATADOG_RUM_APPLICATION_ID: 'ab7c500b-160f-4a1c-979c-f7d4208a8fa6',
  DATADOG_RUM_CLIENT_TOKEN: 'pub50493d97de3a901fc782f6e3069c0741',
  // Docusign setup keys
  DOCUSIGN_ENV: 'https://na2.docusign.net',
  DOCUSIGN_ACCOUNT_ID: '11cebad8-ae5c-4ead-a947-52abbc5239da',
  DOCUSIGN_SCRIPT_URL: 'https://na2.docusign.net/clickapi/sdk/latest/docusign-click.js',
  /**
   * Docusign document keys - clickwrapId
   */
  // ETC agreement on complete invest flow
  DOCUSIGN_CLICKWRAP_ID_COMPLETE_INVEST: '68ba19ca-555c-4c3f-bbd1-ec432185833d',
  // agreements on entity creation
  DOCUSIGN_CLICKWRAP_ID_ROTH_IRA: '18a49e94-2023-40a4-aaf0-d1c8876cab53',
  DOCUSIGN_CLICKWRAP_ID_SEP_IRA: '18a49e94-2023-40a4-aaf0-d1c8876cab53',
  DOCUSIGN_CLICKWRAP_ID_SIMPLE_IRA: '89c6e2e8-0409-4207-aee4-ffdfcbb5fbf0',
  DOCUSIGN_CLICKWRAP_ID_TRADITIONAL_IRA: '18a49e94-2023-40a4-aaf0-d1c8876cab53'
};