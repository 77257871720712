var __jsx = React.createElement;
import React from 'react';
import { PortfolioFiltersButton } from '../PortfolioFiltersButton';
export const PortfolioFiltersActions = _ref => {
  let {
    actions,
    isMobile
  } = _ref;
  if (!(actions !== null && actions !== void 0 && actions.length)) {
    return null;
  }
  return actions.map(action => __jsx(PortfolioFiltersButton, {
    buttonType: "primary",
    disabled: action.disabled,
    isMobile: isMobile,
    key: action.id,
    onClick: action.onClick
  }, isMobile ? __jsx("img", {
    src: action.icon
  }) : action.label));
};