import { HttpMethods } from '../../enums/http';
import { Tags, restApi } from '../common';
export const atomicGatewayApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.AtomicGatewayCompletionLink]
}).injectEndpoints({
  endpoints: builder => {
    const getCompletionLink = builder.query({
      query: _ref => {
        let {
          investorEntityId
        } = _ref;
        return {
          url: `/api/atomic-gateway/completion-link/investorEntity/${investorEntityId}`,
          method: HttpMethods.Get
        };
      },
      providesTags: [Tags.AtomicGatewayCompletionLink],
      transformResponse: data => data.response
    });
    const getLegalAgreementDocs = builder.query({
      query: _ref2 => {
        let {
          investorId,
          documentSubType
        } = _ref2;
        return {
          url: `/api/atomic-gateway/legal-agreement/${investorId}/${documentSubType}`,
          method: HttpMethods.Get
        };
      },
      transformResponse: data => data.response
    });
    return {
      getCompletionLink,
      getLegalAgreementDocs
    };
  }
});