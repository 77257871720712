var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { theme2, media } from '../../../themes';
import { Heading } from '../../../content/text';
export const AssetClassContent = _ref => {
  let {
    title,
    children,
    inverse
  } = _ref;
  return __jsx(OuterContainer, null, __jsx(Headline, {
    type: 4,
    inverse: inverse
  }, title), __jsx(ContentHolder, null, children));
};
const OuterContainer = styled.div.withConfig({
  displayName: "AssetClassContent__OuterContainer",
  componentId: "sc-fhymg-0"
})(["background:", ";position:relative;text-align:left;min-height:392px;margin:20px 0 0 0;padding:0 20px 20px 20px;flex-grow:1;flex-basis:0;", ""], props => props.theme.colors.background_default, media.desktop`
    margin: 0;
    &:not(:last-child) {
      margin: 0 20px 0 0;
    }
    max-width: 221px;
  `);
const Headline = styled(Heading).withConfig({
  displayName: "AssetClassContent__Headline",
  componentId: "sc-fhymg-1"
})(["padding:0 0 20px 0;margin:30px 0 20px 0;min-height:68px;box-sizing:content-box;border-bottom:1px solid ", ";"], theme2.colors.border_default);
const ContentHolder = styled.div.withConfig({
  displayName: "AssetClassContent__ContentHolder",
  componentId: "sc-fhymg-2"
})(["p:not(:last-child){margin-bottom:20px;}"]);