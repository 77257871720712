import { media, Button, friendlyMedia } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
const FormHeaderWrapper = styled.div.withConfig({
  displayName: "Formsstyle__FormHeaderWrapper",
  componentId: "sc-f7zwuc-0"
})(["display:flex;flex-direction:column;align-items:flex-start;justify-content:center;width:100%;margin-bottom:24px;"]);
const ViewRecommendationsButton = styled(Button).withConfig({
  displayName: "Formsstyle__ViewRecommendationsButton",
  componentId: "sc-f7zwuc-1"
})(["", " ", ""], friendlyMedia.mobile`
    display: ${props => props.footerButton ? 'block' : 'none'};
  `, friendlyMedia.tablet`
    ${props => !props.footerButton ? ` margin-left: auto; min-width: 220px; ` : `display: none;`}
  `);
const SubmitButtonWrapper = styled.div.withConfig({
  displayName: "Formsstyle__SubmitButtonWrapper",
  componentId: "sc-f7zwuc-2"
})(["display:flex;flex-direction:column;align-items:center;justify-content:flex-start;gap:32px;", ""], media.tablet`
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 36px;
  `);
const SubmitButton = styled(Button).withConfig({
  displayName: "Formsstyle__SubmitButton",
  componentId: "sc-f7zwuc-3"
})(["width:100%;", ""], media.tablet`
    width: auto;
  `);
const FormBody = styled.div.withConfig({
  displayName: "Formsstyle__FormBody",
  componentId: "sc-f7zwuc-4"
})(["margin-bottom:40px;", ""], media.large`
    margin-bottom: 0;
  `);
const Wrapper = styled.div.withConfig({
  displayName: "Formsstyle__Wrapper",
  componentId: "sc-f7zwuc-5"
})(["background:", ";width:100%;margin-bottom:40px;"], props => props.theme.colors.gray7);
export { FormHeaderWrapper, ViewRecommendationsButton, SubmitButtonWrapper, SubmitButton, FormBody, Wrapper };