export const opacityRGBA = function () {
  let color = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  let opacity = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  const regex = /rgb\(([0-9]+), ([0-9]+), ([0-9]+)\)/;
  let colors = color.match(regex);
  if (colors && colors.length > 3) {
    colors.shift();
    return `rgba(${colors[0]}, ${colors[1]}, ${colors[2]}, ${opacity})`;
  }
  return `rgba(255, 255, 255)`;
};
export const opacity = (color, opacity) => {
  const intValue = Math.round(opacity / 1 * 255); // map percent to nearest integer (0 - 255)
  const hexValue = intValue.toString(16); // get hexadecimal representation
  return color + hexValue.padStart(2, '0').toUpperCase(); // format with leading 0 and upper case characters
};