import { useMemo, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { DEFAULT_ROUTER_PARAM } from './useFlowLocation.model';
export const useFlowLocation = _ref => {
  let {
    useOnUrlChange,
    stepRouterParam = DEFAULT_ROUTER_PARAM
  } = _ref;
  const history = useHistory();
  const params = useParams();
  const onUrlChange = useOnUrlChange();
  const step = useMemo(() => params === null || params === void 0 ? void 0 : params[stepRouterParam], [params, stepRouterParam]);
  const onChangeStep = useCallback(function (changeTo) {
    let skip = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    const newUrl = onUrlChange(changeTo);
    if (skip) {
      return history.replace(newUrl);
    }
    return history.push(newUrl);
  }, [history, onUrlChange]);
  const flowLocationResult = useMemo(() => ({
    step,
    onChangeStep
  }), [onChangeStep, step]);
  return flowLocationResult;
};