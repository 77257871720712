import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { css } from 'styled-components';
import isArray from 'lodash/isArray';
export const SizeOptions = ['reallySmall', 'mobile', 'phoneLandscape', 'tablet', 'desktop', 'large', 'larger', 'fullscreen', 'fullHd', 'ultrawide'];
export const FriendlySizeOptions = ['mobile', 'tablet', 'desktop'];
export const otherProps = {
  handheld: '(pointer:none), (pointer:coarse)',
  print: 'print'
};
export const sizeProps = {
  reallySmall: ['max', 380],
  mobile: ['min', 480],
  phoneLandscape: ['min', 576],
  tablet: ['min', 768],
  desktop: ['min', 992],
  large: ['min', 1200],
  larger: ['min', 1440],
  fullscreen: ['min', 1600],
  fullHd: ['min', 1920],
  ultrawide: ['min', 2560]
};
export const friendlySizeProps = {
  mobile: ['max', 576],
  tablet: ['min', 576],
  desktop: ['min', 1200]
};
export const sizes = Object.keys(sizeProps);
export const friendlySizes = Object.keys(friendlySizeProps);
const generateQueries = propsMap => Object.keys(propsMap).reduce((acc, size) => {
  const props = propsMap[size];
  const query = isArray(props) ? `(${props[0]}-width: ${props[1]}px)` : props;
  acc[size] = query;
  return acc;
}, {});
export const queries = generateQueries(_objectSpread(_objectSpread({}, sizeProps), otherProps));
export const friendlyQueries = generateQueries(_objectSpread(_objectSpread({}, friendlySizeProps), otherProps));
export const generateMedia = queryMap => Object.keys(queryMap).reduce((acc, size) => {
  const query = queryMap[size];
  acc[size] = function (first) {
    for (var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      args[_key - 1] = arguments[_key];
    }
    return css(["@media ", "{", ";}"], query, css(first, ...args));
  };
  return acc;
}, {});
export const media = generateMedia(queries);
export const friendlyMedia = generateMedia(friendlyQueries);