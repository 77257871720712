import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["children", "className", "textAlign", "as"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { TextBaseStyle, TextAlignEnum } from './TextBase';
export const LegalText = _ref => {
  let {
      children,
      className,
      textAlign = TextAlignEnum.Center,
      as = 'p'
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  return __jsx(SmallLegalElement, _extends({
    as: as,
    className: className,
    textAlign: textAlign
  }, props), children);
};
const SmallLegalElement = styled.p.withConfig({
  displayName: "LegalText__SmallLegalElement",
  componentId: "sc-160lsjh-0"
})(["", ";font-family:", ";font-weight:400;font-size:10px;line-height:14px;text-align:", ";color:#9098a2;", ""], TextBaseStyle, props => props.theme.typography.primary, props => props.textAlign, props => props.nowrap && `white-space: nowrap;`);