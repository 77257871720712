import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import moment from 'moment';
import { InvestorType } from '@yieldstreet/platform-kit';
import { createYupObjectSchema, dateOfRecordValidation, dateEntityValidation, defaultStringValidation } from '@yieldstreet/tool-kit';
import { getAccountPayload } from '../../CreateInvestorAccount.utils';
const getFormValidation = accountType => {
  let validationObj;
  switch (accountType) {
    case InvestorType.INDIVIDUAL:
      validationObj = {
        dateOfRecord: dateOfRecordValidation()
      };
      break;
    case InvestorType.IRA:
      validationObj = {
        dateOfRecord: dateOfRecordValidation(),
        employerName: defaultStringValidation({
          isRequired: true
        })
      };
      break;
    default:
      validationObj = {
        dateOfRecord: dateEntityValidation()
      };
      break;
  }
  return _objectSpread(_objectSpread({}, validationObj), {}, {
    documentNumber: {
      validationType: 'document'
    },
    legalAgreement: {
      validationType: 'default-boolean'
    }
  });
};
export const getFormConfig = accountType => ({
  fieldNames: ['dateOfRecord', 'employerName', 'documentNumber', 'legalAgreement'],
  validation: createYupObjectSchema(getFormValidation(accountType)),
  formikConfig: {
    initialValues: {
      dateOfRecord: '',
      employerName: '',
      documentNumber: '',
      legalAgreement: false
    }
  }
});
export const buildPostData = _ref => {
  var _values$dateOfRecord;
  let {
    investorType,
    values,
    userEmailAddress,
    contact
  } = _ref;
  const specificAttributes = getAccountPayload({
    values,
    investorType,
    userEmailAddress,
    contact
  });
  const {
    documentType,
    employerName,
    trustEmail,
    trustPhone,
    entityScope,
    beneficialOwners
  } = specificAttributes;
  return {
    documentType,
    employerName,
    dateOfRecord: values !== null && values !== void 0 && values.dateOfRecord ? moment((values === null || values === void 0 ? void 0 : (_values$dateOfRecord = values.dateOfRecord) === null || _values$dateOfRecord === void 0 ? void 0 : _values$dateOfRecord.trim().length) === 10 && (values === null || values === void 0 ? void 0 : values.dateOfRecord), 'MM/DD/YYYY').format('YYYY-MM-DD') : null,
    documentNumber: values !== null && values !== void 0 && values.documentNumber ? values === null || values === void 0 ? void 0 : values.documentNumber : null,
    documentNumberMasked: values !== null && values !== void 0 && values.documentNumber ? 'XXX-XX-' + (values === null || values === void 0 ? void 0 : values.documentNumber.slice(-4)) : null,
    trustEmail,
    trustPhone,
    entityScope,
    beneficialOwners
  };
};