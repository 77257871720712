var __jsx = React.createElement;
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import kebabCase from "lodash/kebabCase";
import isEmpty from "lodash/isEmpty";
import { MODAL_CONTEXT_INITIAL_STATE } from './Modal.const';
import { ModalContext } from './Modal.context';
export const ModalProvider = _ref => {
  let {
    history,
    children
  } = _ref;
  const {
    0: currentModalId,
    1: setCurrentModalId
  } = useState(MODAL_CONTEXT_INITIAL_STATE.currentModalId);
  const {
    0: passProps,
    1: setPassProps
  } = useState(MODAL_CONTEXT_INITIAL_STATE.passProps);
  useEffect(() => {
    const handleLocation = () => {
      const {
        hash
      } = (history === null || history === void 0 ? void 0 : history.location) || {};
      if (hash) {
        const modalIdFormatted = kebabCase(hash.substring(1));
        setCurrentModalId(modalIdFormatted);
      } else if (!hash && currentModalId) {
        setCurrentModalId(MODAL_CONTEXT_INITIAL_STATE.currentModalId);
      }
    };
    handleLocation();
    const unsubNavListener = history === null || history === void 0 ? void 0 : history.listen(handleLocation);
    return () => {
      unsubNavListener();
    };
  }, [history, currentModalId]);
  const showModal = useCallback(function (modalId) {
    let modalProps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    const {
      pathname,
      search
    } = history.location;
    const modalIdFormatted = kebabCase(modalId);
    const url = `${pathname}${search}#${modalIdFormatted}`;
    history.navigate ? history.navigate(url) : history.push(url);
    if (!isEmpty(modalProps)) {
      setPassProps(modalProps);
    }
  }, [history]);
  const hideModal = useCallback(modalId => {
    if (!modalId || modalId && modalId === currentModalId) {
      const url = history.location.pathname + history.location.search;
      history.navigate ? history.navigate(url) : history.push(url);
      setPassProps(MODAL_CONTEXT_INITIAL_STATE.passProps);
    }
  }, [history, currentModalId]);
  const value = useMemo(() => ({
    currentModalId,
    hideModal,
    isModalVisible: !!currentModalId,
    passProps,
    showModal
  }), [currentModalId, hideModal, passProps, showModal]);
  return __jsx(ModalContext.Provider, {
    value: value
  }, children);
};