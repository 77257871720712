import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["defaultBankAccount"];
var __jsx = React.createElement;
import React, { useCallback, useEffect, useMemo } from 'react';
import { useProviderMigration } from '@yieldstreet/tool-kit';
import { InvestorEntityActionList } from 'pages/investorEntity/components/InvestorEntityActionList';
import { getMigrationActionButton, getMigrationActionConfig } from './KYCMigration.utils';
import { investorEntityProviderMigrationApi } from '@yieldstreet/platform-kit';
import { getInvestorEntityRoute } from 'pages/investorEntity/utils';
import { useHistory } from 'react-router';
import { Button, ExternalProviderVisual, Paragraph, ProcessInfo, ProcessStatus, StatusIcon, useModalContext, ModalContainer, ModalLoader, Wrapper } from '@yieldstreet/ui-kit';
import PlaidLogo from '@yieldstreet/ui-kit/assets/logos/plaid.svg';
import { copy, KYC_MIGRATION_PATH, testIds } from './KYCMigration.model';
import { KYCMigrationBankDetails } from './components/KYCMigrationBankDetails';
import { KYC_MIGRATION_MODAL } from './KYCMigrationModal.model';
export const KYCMigration = () => {
  const {
    hideModal
  } = useModalContext();
  const {
    migrationInvestorEntities,
    isMigrationFinal,
    isMigrationPending,
    isMigrationProblem,
    isLoading
  } = useProviderMigration();
  const history = useHistory();
  const [startMigration, startMigrationState] = investorEntityProviderMigrationApi.useStartProviderMigrationMutation();
  const launchMigrationRoute = useCallback((investorEntityId, route) => {
    history.push(getInvestorEntityRoute(investorEntityId, route));
    hideModal(KYC_MIGRATION_MODAL);
  }, [history, hideModal]);
  const {
    isSuccess,
    originalArgs
  } = startMigrationState;
  useEffect(() => {
    if (isSuccess && originalArgs) {
      launchMigrationRoute(originalArgs.investorEntityId, KYC_MIGRATION_PATH);
    }
  }, [isSuccess, originalArgs, launchMigrationRoute]);
  const multipleMigrations = ((migrationInvestorEntities === null || migrationInvestorEntities === void 0 ? void 0 : migrationInvestorEntities.length) || 0) > 1;
  const migrationInvestorList = useMemo(() => {
    if (!migrationInvestorEntities) {
      return null;
    }
    const items = migrationInvestorEntities.map(migrationConfig => {
      return getMigrationActionConfig({
        migrationConfig,
        startMigration,
        startMigrationState,
        launchMigrationRoute
      });
    });
    const singleAccount = (items === null || items === void 0 ? void 0 : items.length) === 1;
    return __jsx(Wrapper, {
      marginTop: "m"
    }, __jsx(InvestorEntityActionList, {
      items: items,
      testId: testIds.migrationList.id,
      hideMain: singleAccount,
      renderAdditional: _ref => {
        let {
            defaultBankAccount
          } = _ref,
          props = _objectWithoutProperties(_ref, _excluded);
        return __jsx(KYCMigrationBankDetails, _extends({
          bank: defaultBankAccount,
          separator: !singleAccount
        }, props));
      }
    }));
  }, [migrationInvestorEntities, startMigration, startMigrationState, launchMigrationRoute]);
  const migrationActionButton = useMemo(() => {
    const migrationConfig = migrationInvestorEntities === null || migrationInvestorEntities === void 0 ? void 0 : migrationInvestorEntities[0];
    if (!migrationConfig || multipleMigrations) {
      return null;
    }
    return getMigrationActionButton({
      migrationConfig,
      startMigration,
      startMigrationState,
      launchMigrationRoute
    });
  }, [multipleMigrations, migrationInvestorEntities, startMigration, startMigrationState, launchMigrationRoute]);
  const migrationContent = useMemo(() => {
    if (isLoading) {
      return __jsx(ModalLoader, {
        testId: testIds.loader.id
      });
    }
    if (isMigrationPending) {
      return __jsx(ProcessInfo, {
        testId: testIds.pendingInfo.id,
        icon: __jsx(StatusIcon, {
          status: ProcessStatus.PENDING,
          testId: testIds.icon.id
        }),
        title: multipleMigrations ? copy.pending.title.multiple : copy.pending.title.single,
        buttons: __jsx(Button, {
          buttonType: "primary",
          onClick: () => hideModal()
        }, copy.pending.button)
      }, __jsx(Paragraph, {
        secondary: true,
        size: "small"
      }, multipleMigrations ? copy.pending.description.multiple : copy.pending.description.single), migrationInvestorList);
    }
    if (isMigrationFinal) {
      return __jsx(ProcessInfo, {
        testId: testIds.successInfo.id,
        icon: __jsx(StatusIcon, {
          status: ProcessStatus.SUCCESS,
          testId: testIds.icon.id
        }),
        title: copy.final.title,
        buttons: __jsx(Button, {
          buttonType: "primary",
          onClick: () => hideModal()
        }, copy.final.button)
      }, __jsx(Paragraph, {
        secondary: true,
        size: "small"
      }, multipleMigrations ? copy.final.description.multiple : copy.final.description.single), migrationInvestorList);
    }
    if (isMigrationProblem) {
      return __jsx(ProcessInfo, {
        testId: testIds.failureInfo.id,
        icon: __jsx(StatusIcon, {
          status: ProcessStatus.FAILURE,
          testId: testIds.icon.id
        }),
        title: multipleMigrations ? copy.problem.title.multiple : copy.problem.title.single,
        buttons: __jsx(React.Fragment, null, __jsx(Button, {
          buttonType: "basic",
          onClick: () => {
            var _Intercom, _ref2;
            return (_Intercom = (_ref2 = window).Intercom) === null || _Intercom === void 0 ? void 0 : _Intercom.call(_ref2, 'show');
          }
        }, copy.problem.irCTA))
      }, __jsx(Paragraph, {
        secondary: true,
        size: "small"
      }, copy.problem.irText), migrationInvestorList);
    }
    return __jsx(ProcessInfo, {
      testId: testIds.migrationInfo.id,
      iconCentered: true,
      icon: __jsx(ExternalProviderVisual, {
        toIcon: {
          alt: 'Plaid',
          icon: PlaidLogo
        }
      }),
      title: copy.migration.title,
      buttons: __jsx(React.Fragment, null, migrationActionButton)
    }, __jsx(Paragraph, {
      size: "small"
    }, copy.migration.description), migrationInvestorList);
  }, [hideModal, isLoading, isMigrationFinal, isMigrationPending, isMigrationProblem, migrationActionButton, migrationInvestorList, multipleMigrations]);
  return __jsx(ModalContainer, null, migrationContent);
};