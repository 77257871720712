import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["label1", "value1", "label2", "value2"];
import { RegulatoryTypes, LegalEntityType } from '@yieldstreet/platform-kit';
import { isFortyActFund, getOfferingTerm } from '@yieldstreet/tool-kit';
import { useMemo } from 'react';
const getTree = param => {
  const _ref = param || {},
    {
      label1,
      value1,
      label2,
      value2
    } = _ref,
    params = _objectWithoutProperties(_ref, _excluded);
  const tree = [];
  if (label1) {
    tree.push({
      label: label1,
      value: value1,
      tooltip: params
    });
  }
  if (label2) {
    tree.push({
      label: label2,
      value: value2
    });
  }
  if (!tree.length) {
    return;
  }
  return {
    tree,
    params
  };
};
export const useOfferingHero = offering => {
  return useMemo(() => {
    if (!offering) {
      return {};
    }
    const {
      legalEntityType,
      regulatoryType,
      fundType
    } = offering;
    const isPublicFund = legalEntityType === LegalEntityType.FUND && regulatoryType === RegulatoryTypes.public;
    const tieredRateTree = getTree(offering.tieredRateTree);
    const tieredTermTree = getTree(offering.tieredTermTree);
    let tieredTree;
    if (tieredRateTree || tieredTermTree) {
      tieredTree = {
        tieredRateTree,
        tieredTermTree
      };
    }
    const {
      term,
      termSuffix
    } = getOfferingTerm(offering);
    return {
      isPublicFund,
      isFortyActFund: isFortyActFund(fundType),
      tieredTree,
      term,
      termSuffix
    };
  }, [offering]);
};