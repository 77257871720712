export const abbreviate = function (number) {
  let maxPlaces = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 3;
  let forcePlaces = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  let forceLetter = arguments.length > 3 ? arguments[3] : undefined;
  if (forceLetter) {
    return annotate(number, maxPlaces, forcePlaces, forceLetter);
  }
  let abbr;
  if (number >= 1e12) {
    abbr = 'T';
  } else if (number >= 1e9) {
    abbr = 'B';
  } else if (number >= 1e6) {
    abbr = 'M';
  } else if (number >= 1e3) {
    abbr = 'K';
  } else {
    abbr = '';
  }
  return annotate(number, maxPlaces, forcePlaces, abbr);
};
export const annotate = (number, maxPlaces, forcePlaces, abbr) => {
  // set places to 0 to not round
  let rounded = 0;
  let roundedNumber = '';
  switch (abbr) {
    case 'T':
      rounded = number / 1e12;
      break;
    case 'B':
      rounded = number / 1e9;
      break;
    case 'M':
      rounded = number / 1e6;
      break;
    case 'K':
      rounded = number / 1e3;
      break;
    default:
      rounded = number;
      break;
  }
  if (maxPlaces) {
    var test = new RegExp('\\.\\d{' + (maxPlaces + 1) + ',}$');
    if (test.test('' + rounded)) {
      roundedNumber = rounded.toFixed(maxPlaces);
    }
  }
  roundedNumber = rounded.toFixed(forcePlaces);
  return roundedNumber + abbr;
};