import _Alert from "@mui/material/Alert";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _Link from "@mui/material/Link";
var __jsx = React.createElement;
import React from 'react';
import { useModalContext } from '@yieldstreet/ui-kit';
import { useAllInvestorEntities, useProviderMigration } from '@yieldstreet/tool-kit';
import { BankAccountProvisionState, ExternalIdentityProvider } from '@yieldstreet/platform-kit';
import { KYC_MIGRATION_MODAL, testIds } from '../modal/KYCMigrationModal.model';
export const KYCMigrationNotification = () => {
  const {
    showModal
  } = useModalContext();
  const {
    isMigrationActionable,
    isMigrationEligible,
    isMigrationFinal,
    bankLinkInvestors
  } = useProviderMigration();
  const {
    investorEntities
  } = useAllInvestorEntities();
  const bankLinkDetails = investorEntities === null || investorEntities === void 0 ? void 0 : investorEntities.map(entity => {
    var _bankLinkInvestors$en;
    return (_bankLinkInvestors$en = bankLinkInvestors[entity.id]) === null || _bankLinkInvestors$en === void 0 ? void 0 : _bankLinkInvestors$en.defaultBankAccount;
  });
  const confirmedBankAccount = bankLinkDetails === null || bankLinkDetails === void 0 ? void 0 : bankLinkDetails.find(bankLink => (bankLink === null || bankLink === void 0 ? void 0 : bankLink.provisionState) === BankAccountProvisionState.CONFIRMED && (bankLink === null || bankLink === void 0 ? void 0 : bankLink.externalIdentityProvider) === ExternalIdentityProvider.PLAID);
  if (!isMigrationEligible) {
    return null;
  }
  if (isMigrationFinal && !confirmedBankAccount) {
    return __jsx(_Alert, _extends({}, testIds.base.attr, {
      severity: "info"
    }), "Account update: Distribution payments will be sent directly to the bank account you use for your investment account.", ' ', __jsx(_Link, {
      onClick: () => showModal(KYC_MIGRATION_MODAL)
    }, "View details"));
  }
  if (isMigrationActionable) {
    return __jsx(_Alert, _extends({}, testIds.base.attr, {
      severity: "warning"
    }), "Action required: Yieldstreet has switched to Plaid for payment processing. Please link your bank account. ", __jsx(_Link, {
      onClick: () => showModal(KYC_MIGRATION_MODAL)
    }, "Reactivate account"));
  }
  return null;
};