import styled from 'styled-components';
import Switch from '@mui/material/Switch';
export const SSwitch = styled(Switch).withConfig({
  displayName: "styles__SSwitch",
  componentId: "sc-1ote21u-0"
})(["& .MuiSwitch-track{background-color:", " !important;width:40px;opacity:1 !important;}& .MuiSwitch-switchBase.Mui-checked{color:", ";}& .MuiSwitch-switchBase.Mui-disabled{color:", ";}& .MuiSwitch-switchBase{color:", ";}"], _ref => {
  let {
    theme
  } = _ref;
  return theme.colors.border_default;
}, _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.colors.active;
}, _ref3 => {
  let {
    theme
  } = _ref3;
  return theme.colors.border_default;
}, _ref4 => {
  let {
    theme
  } = _ref4;
  return theme.colors.border_strong;
});