import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import isEmpty from "lodash/isEmpty";
import { ADVICE_QUIZ_KEY } from 'utils/advice';
export const getInitialValuesFromAnswers = (data, questionKeysToInclude) => {
  if (!data || isEmpty(data)) return {};
  let answers = data.filter(ans => questionKeysToInclude.includes(ans.questionKey));
  answers = answers.reduce((obj, _ref) => {
    let {
      questionKey,
      answers
    } = _ref;
    return _objectSpread(_objectSpread({}, obj), {}, {
      // if length > 1, it is a multi-answer question (e.g. asset classes)
      [questionKey]: answers.length > 1 ? answers : answers[0]
    });
  }, {});
  return answers;
};

// We gotta parse individually each form value to match Backend expectations
export const parseBulkPreferences = _ref2 => {
  let {
    userId,
    quizId,
    quizKey = ADVICE_QUIZ_KEY,
    anonymousUserId,
    formAnswers
  } = _ref2;
  if (!formAnswers || isEmpty(formAnswers)) return null;
  // It is first filtering for answers that are not empty to remove fields that are NOT required
  const keys = Object.keys(formAnswers);
  const filledAnswers = keys.filter(key => !!formAnswers[key]);
  const parsedBulkPreferences = filledAnswers.map(key => {
    const answers = formAnswers[key];
    return {
      userId: userId.toString(),
      quizId,
      quizKey,
      anonymousUserId,
      questionKey: key,
      // Then, if value is typeof string we are parsing it to be string[] instead; otherwise
      // we keep it normally which will be already normalized to string[] from BE
      answers: typeof answers === 'string' ? [answers] : answers
    };
  });
  return parsedBulkPreferences;
};