import styled from 'styled-components';
export const PageSheetActionsDesktopWrapper = styled.div.withConfig({
  displayName: "PageSheetActionsstyle__PageSheetActionsDesktopWrapper",
  componentId: "sc-z2tgv8-0"
})(["display:flex;flex-direction:row;align-items:center;justify-content:flex-end;gap:", ";"], _ref => {
  let {
    theme
  } = _ref;
  return theme.spacing.m;
});
export const PageSheetActionsMobileWrapper = styled.div.withConfig({
  displayName: "PageSheetActionsstyle__PageSheetActionsMobileWrapper",
  componentId: "sc-z2tgv8-1"
})(["display:flex;align-items:center;justify-content:flex-end;width:", ";height:", ";cursor:pointer;"], _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.spacing.l;
}, _ref3 => {
  let {
    theme
  } = _ref3;
  return theme.spacing.l;
});