var __jsx = React.createElement;
import React, { useContext } from 'react';
import { TrustArcConsentLink, SegmentConsentLink } from './vendors';
import { FlagsContext, useFlags } from '@yieldstreet/tool-kit';
export const ConsentLink = () => {
  const {
    isLoading
  } = useContext(FlagsContext);
  const {
    newConsentManager
  } = useFlags({
    newConsentManager: false
  });
  if (isLoading) {
    return null;
  }
  return newConsentManager ? __jsx(TrustArcConsentLink, null) : __jsx(SegmentConsentLink, null);
};