import { countriesOptions } from '../../KYC.utils';
export const getCitizenshipOptions = _ref => {
  let {
    formikProps,
    index
  } = _ref;
  // Create a new array with all the countries.
  const options = [...countriesOptions];

  // Get the selected countries from the formik values. Exclude the
  // selected country for the current dropdown. If we do not exclude
  // the selected country for the current dropdown, it will be blank.
  const selectedCountries = formikProps.values.citizenshipCountries.filter((country, idx) => idx !== index).map(cnty => cnty.country);

  // Return the list of options including the one that the current dropdown is
  // showing and excluding the ones that are already selected in other dropdowns.
  return options.filter(option => !selectedCountries.includes(option.value));
};