import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { ADVICE_ONRAMP_CONTENT } from './advice.constants';
import { useAdvice } from './useAdvice';
const {
  SETUP_PREFERENCES,
  VIEW_RECOMMENDATIONS,
  CREATE_INVESTOR_ACCOUNT
} = ADVICE_ONRAMP_CONTENT;
const useAdviceOnramp = () => {
  const {
    hasAdviceButNeedsAccount,
    isAdviceReady
  } = useAdvice();
  const {
    heading,
    subHeading,
    widgetImage,
    cardImage,
    offeringCardImage,
    cta
  } = useMemo(() => {
    if (isAdviceReady) {
      return VIEW_RECOMMENDATIONS;
    } else if (hasAdviceButNeedsAccount) {
      return CREATE_INVESTOR_ACCOUNT;
    } else {
      return SETUP_PREFERENCES;
    }
  }, [isAdviceReady, hasAdviceButNeedsAccount]);
  const history = useHistory();
  const handleCtaClick = useCallback(() => history.push(cta.link, {
    isAdviceFlow: true
  }), [history, cta]);
  return {
    heading,
    subHeading,
    widgetImage,
    cta: _objectSpread(_objectSpread({}, cta), {}, {
      handleOnClick: handleCtaClick
    }),
    handleCtaClick,
    cardImage,
    offeringCardImage
  };
};
export { useAdviceOnramp };