import { AccreditationType } from '../../enums';
import { aiqDocument } from './investor-account-aiq-document';
export const aiqIncome = {
  id: 19788,
  investorAccountId: 201924,
  investorAccountName: 'John Doe',
  investorFirstName: 'John',
  userId: 220951,
  userDisplayName: 'John Doe',
  accreditationType: AccreditationType.INCOME,
  createDate: 1594900727000,
  verified: false,
  expired: true,
  details: '123',
  expirationDate: 1602633600000,
  actionNeeded: false,
  active: true,
  documents: [aiqDocument],
  actionNeededTag: 'user:220951:account:201924:action:needed',
  actionNeededReminderTag: 'user:220951:account:201924:action:needed-reminder'
};