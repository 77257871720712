import { CommunicationMessageCategory, CommunicationMessageType, ActivityEvent, ActivityEventType, ActivityMessageTemplateId } from '../../apis';
const today = new Date().toISOString();
export const ActivityCommunicationMessages = [{
  messageId: 'e8f6d080-1d72-11ed-b879-e5ed4bbe1e9b',
  messageSpecId: 'e7971970-1d72-11ed-8ab0-85076f47df21',
  userId: '4',
  startAt: today,
  publishedAt: today,
  sortDate: today,
  messageType: CommunicationMessageType.Activity,
  configuration: {
    version: '1',
    userIds: '4',
    isMigrated: false,
    activityEvent: ActivityEvent.CustomActivity,
    activityEventType: ActivityEventType.Default,
    category: CommunicationMessageCategory.InvestmentProgress,
    title: 'Mock Message 1',
    text: 'Mock Message 1 Text'
  },
  read: true,
  dismissed: false,
  messageTemplateId: null,
  messageTemplateConfiguration: null,
  lastUpdateId: 'e8f6d080-1d72-11ed-b879-e5ed4bbe1e9b',
  sortOrder: null,
  sortKey: '~31556888203741713'
}, {
  messageId: 'e8f6d080-1d72-11ed-b879-e5ed4bbe1e9c',
  messageSpecId: 'e7971970-1d72-11ed-8ab0-85076f47df22',
  userId: '4',
  startAt: today,
  publishedAt: today,
  sortDate: today,
  messageType: CommunicationMessageType.Activity,
  configuration: {
    version: '1',
    userIds: '4',
    isMigrated: false,
    activityEvent: ActivityEvent.CustomActivity,
    activityEventType: ActivityEventType.Default,
    category: CommunicationMessageCategory.InvestmentProgress,
    title: 'Mock Message 2',
    text: 'Mock Message 2 Text'
  },
  read: true,
  dismissed: false,
  messageTemplateId: null,
  messageTemplateConfiguration: null,
  lastUpdateId: 'e8f6d080-1d72-11ed-b879-e5ed4bbe1e9c',
  sortOrder: null,
  sortKey: '~31556888203741713'
}, {
  messageId: '6a84d1c1-1d72-11ed-b879-e5ed4bbe1e9d',
  messageSpecId: '69aa5040-1d72-11ed-b879-e5ed4bbe1e9d',
  userId: '4',
  startAt: '2022-06-16T14:47:54.899Z',
  publishedAt: '2022-06-16T14:47:55.690989571Z',
  sortDate: '2022-06-16T14:47:56Z',
  messageType: CommunicationMessageType.Activity,
  configuration: {
    version: '1',
    userIds: '4',
    isMigrated: false,
    activityEvent: ActivityEvent.CustomActivity,
    activityEventType: ActivityEventType.Default,
    category: CommunicationMessageCategory.InvestmentProgress,
    title: 'Mock Message 3',
    text: 'Mock Message 3 Text'
  },
  read: true,
  dismissed: false,
  messageTemplateId: null,
  messageTemplateConfiguration: null,
  lastUpdateId: '6a84d1c1-1d72-11ed-b879-e5ed4bbe1e9d',
  sortOrder: null,
  sortKey: '~31556888203741925'
}, {
  messageId: '6a84d1c1-1d72-11ed-b879-e5ed4bbe1e9e',
  messageSpecId: '69aa5040-1d72-11ed-b879-e5ed4bbe1e9e',
  userId: '4',
  startAt: '2022-06-16T14:47:54.899Z',
  publishedAt: '2022-06-16T14:47:55.690989571Z',
  sortDate: '2022-06-16T14:47:56Z',
  messageType: CommunicationMessageType.Activity,
  configuration: {
    version: '1',
    userIds: '4',
    isMigrated: false,
    activityEvent: ActivityEvent.CustomActivity,
    activityEventType: ActivityEventType.Default,
    category: CommunicationMessageCategory.InvestmentProgress,
    title: 'Mock Message 4',
    text: 'Mock Message 4 Text'
  },
  read: true,
  dismissed: false,
  messageTemplateId: null,
  messageTemplateConfiguration: null,
  lastUpdateId: '6a84d1c1-1d72-11ed-b879-e5ed4bbe1e9e',
  sortOrder: null,
  sortKey: '~31556888203741925'
}];
export const ActivityCommunicationQps = {
  messageId: 'e8f6d080-1d72-11ed-b879-e5ed4bbe1e9b',
  messageSpecId: 'e7971970-1d72-11ed-8ab0-85076f47df21',
  userId: '4',
  startAt: '',
  publishedAt: '',
  sortDate: new Date().toISOString(),
  messageType: CommunicationMessageType.Activity,
  configuration: {
    version: '1',
    userIds: '4',
    isMigrated: false,
    activityEvent: ActivityEvent.CustomUpdate,
    activityEventType: ActivityEventType.QuarterlySnapshot,
    category: CommunicationMessageCategory.Important,
    title: 'Mock Message 1 QPS',
    text: 'Mock Message 1 QPS Text'
  },
  read: false,
  dismissed: false,
  messageTemplateId: null,
  messageTemplateConfiguration: null,
  lastUpdateId: 'e8f6d080-1d72-11ed-b879-e5ed4bbe1e9b',
  sortOrder: null,
  sortKey: '~31556888203741713',
  seen: false
};
export const ActivityCommunicationCustomUpdateDefault = {
  messageId: 'e8f6d080-1d72-11ed-b879-e5ed4bbe1e9b',
  messageSpecId: 'e7971970-1d72-11ed-8ab0-85076f47df21',
  userId: '4',
  startAt: '',
  publishedAt: '',
  sortDate: new Date().toISOString(),
  messageType: CommunicationMessageType.Activity,
  configuration: {
    version: '1',
    userIds: '4',
    isMigrated: false,
    activityEvent: ActivityEvent.CustomUpdate,
    activityEventType: ActivityEventType.Default,
    category: null,
    title: 'Mock Message Custom Update Default',
    text: 'Mock Message Custom Update Default Text'
  },
  read: false,
  dismissed: false,
  messageTemplateId: null,
  messageTemplateConfiguration: null,
  lastUpdateId: 'e8f6d080-1d72-11ed-b879-e5ed4bbe1e9b',
  sortOrder: null,
  sortKey: '~31556888203741713',
  seen: false
};
export const ActivityCommunicationWalletBalancePromo = {
  messageId: 'e8f6d080-1d72-11ed-b879-e5ed4bbe1e9b',
  messageSpecId: 'e7971970-1d72-11ed-8ab0-85076f47df21',
  userId: '4',
  startAt: '',
  publishedAt: '',
  sortDate: new Date().toISOString(),
  messageType: CommunicationMessageType.Activity,
  configuration: {
    version: '1',
    userIds: '4',
    isMigrated: false,
    activityEvent: ActivityEvent.CustomUpdate,
    activityEventType: ActivityEventType.WalletBalancePromo,
    category: CommunicationMessageCategory.Important,
    title: 'Mock Message Wallet Balance Promo',
    text: 'Mock Message Wallet Balance Promo Text'
  },
  read: true,
  dismissed: false,
  messageTemplateId: null,
  messageTemplateConfiguration: null,
  lastUpdateId: 'e8f6d080-1d72-11ed-b879-e5ed4bbe1e9b',
  sortOrder: null,
  sortKey: '~31556888203741713'
};
export const ActivityCommunicationMomentsOfJoyFirstTimeInvestment = {
  messageId: 'e8f6d080-1d72-11ed-b879-e5ed4bbe1e9b',
  messageSpecId: 'e7971970-1d72-11ed-8ab0-85076f47df21',
  userId: '4',
  startAt: '',
  publishedAt: '',
  sortDate: new Date().toISOString(),
  messageType: CommunicationMessageType.Activity,
  configuration: {
    version: '1',
    userIds: '4',
    isMigrated: false,
    activityEvent: ActivityEvent.MomentsOfJoy,
    category: CommunicationMessageCategory.Important,
    title: 'Mock Message Moments of Joy First Time Investment',
    text: 'Mock Message Moments of Joy First Time Investment'
  },
  read: true,
  dismissed: false,
  messageTemplateId: ActivityMessageTemplateId.MomentsOfJoyFirstTimeInvestment,
  messageTemplateConfiguration: null,
  lastUpdateId: 'e8f6d080-1d72-11ed-b879-e5ed4bbe1e9b',
  sortOrder: null,
  sortKey: '~31556888203741713'
};
export const ActivityCommunicationMomentsOfJoyRegistration = {
  messageId: 'e8f6d080-1d72-11ed-b879-e5ed4bbe1e9b',
  messageSpecId: 'e7971970-1d72-11ed-8ab0-85076f47df21',
  userId: '4',
  startAt: '',
  publishedAt: '',
  sortDate: new Date().toISOString(),
  messageType: CommunicationMessageType.Activity,
  configuration: {
    version: '1',
    userIds: '4',
    isMigrated: false,
    activityEvent: ActivityEvent.MomentsOfJoy,
    category: CommunicationMessageCategory.Important,
    title: 'Mock Message Moments of Joy Registration',
    text: 'Mock Message Moments of Joy Registration Text'
  },
  messageTemplateId: ActivityMessageTemplateId.MomentsOfJoyRegistration,
  read: true,
  dismissed: false,
  messageTemplateConfiguration: null,
  lastUpdateId: 'e8f6d080-1d72-11ed-b879-e5ed4bbe1e9b',
  sortOrder: null,
  sortKey: '~31556888203741713'
};
export const ActivityCommunicationDealMatured = {
  messageId: 'e8f6d080-1d72-11ed-b879-e5ed4bbe1e9b',
  messageSpecId: 'e7971970-1d72-11ed-8ab0-85076f47df21',
  userId: '4',
  startAt: '',
  publishedAt: '',
  sortDate: new Date().toISOString(),
  messageType: CommunicationMessageType.Activity,
  configuration: {
    version: '1',
    userIds: '4',
    isMigrated: false,
    activityEvent: ActivityEvent.DealMatured,
    category: CommunicationMessageCategory.Payments,
    title: 'Mock Message Deal Matured',
    text: 'Mock Message Deal Matured Text'
  },
  messageTemplateId: null,
  read: true,
  dismissed: false,
  messageTemplateConfiguration: null,
  lastUpdateId: 'e8f6d080-1d72-11ed-b879-e5ed4bbe1e9b',
  sortOrder: null,
  sortKey: '~31556888203741713'
};
export const ActivityCommunicationInvestmentUpdate = {
  messageId: 'e8f6d080-1d72-11ed-b879-e5ed4bbe1e9b',
  messageSpecId: 'e7971970-1d72-11ed-8ab0-85076f47df21',
  userId: '4',
  startAt: '',
  publishedAt: '',
  sortDate: new Date().toISOString(),
  messageType: CommunicationMessageType.Activity,
  configuration: {
    version: '1',
    userIds: '4',
    isMigrated: false,
    activityEvent: ActivityEvent.InvestmentUpdate,
    category: CommunicationMessageCategory.InvestmentUpdates,
    title: 'Mock Message Investment Update',
    text: 'Mock Message Investment Update Text'
  },
  messageTemplateId: null,
  read: true,
  dismissed: false,
  messageTemplateConfiguration: null,
  lastUpdateId: 'e8f6d080-1d72-11ed-b879-e5ed4bbe1e9b',
  sortOrder: null,
  sortKey: '~31556888203741713'
};