var __jsx = React.createElement;
import React, { useCallback, useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { bankAccountApi } from '@yieldstreet/platform-kit';
import { Button, FormQuestionRadioGroupInput, Heading, ModalLoader, Paragraph } from '@yieldstreet/ui-kit';
import { useDefaultBank } from './hooks/useDefaultBank';
import { useDefaultBankScreenType } from './hooks/useDefaultBankScreenType';
import { ActionsWrapper, Content, ContentWrapper } from './DefaultBank.style';
import { DefaultBankScreenType, testIds, validationSchema } from './DefaultBank.model';
export const DefaultBank = _ref => {
  let {
    investorEntityId,
    onAction
  } = _ref;
  const {
    0: showSuccessModal,
    1: setShowSuccessModal
  } = useState(false);
  const [submitDefaultBank, {
    isLoading: isSubmitting,
    isSuccess
  }] = bankAccountApi.useSetDefaultBankAccountMutation();
  const {
    isDefaultLoading,
    content,
    formOptions
  } = useDefaultBank(investorEntityId, showSuccessModal);
  const screenType = useDefaultBankScreenType(investorEntityId, showSuccessModal);
  const handleOnSubmit = useCallback(async _ref2 => {
    let {
      defaultBank
    } = _ref2;
    await submitDefaultBank({
      bankAccountId: defaultBank,
      investorEntityId
    });
  }, [investorEntityId, submitDefaultBank]);
  useEffect(() => {
    if (isSuccess) {
      setShowSuccessModal(true);
    }
  }, [isSuccess]);
  const handleOnClick = useCallback(actionType => {
    return onAction(actionType);
  }, [onAction]);
  return __jsx(ContentWrapper, null, isDefaultLoading || !screenType ? __jsx(ModalLoader, {
    testId: "default-bank-modal-loader"
  }) : __jsx(Content, null, __jsx(Heading, {
    marginBottom: "sm",
    type: 4
  }, content === null || content === void 0 ? void 0 : content.title), __jsx(Paragraph, {
    marginBottom: "sm",
    secondary: true
  }, content === null || content === void 0 ? void 0 : content.description), __jsx(Paragraph, {
    marginBottom: "l",
    secondary: true
  }, content === null || content === void 0 ? void 0 : content.note), screenType === DefaultBankScreenType.DefaultBank ? __jsx(ActionsWrapper, null, content === null || content === void 0 ? void 0 : content.buttons.map(btn => __jsx(Button, {
    key: btn.label,
    buttonType: btn.buttonType,
    fullWidth: true,
    onClick: () => (btn === null || btn === void 0 ? void 0 : btn.action) && handleOnClick(btn.action),
    trackingId: btn.testId
  }, btn.label))) : __jsx(Formik, {
    initialValues: formOptions.initialValues,
    isInitialValid: formOptions.isInitialValid,
    validationSchema: validationSchema,
    onSubmit: handleOnSubmit
  }, formikProps => __jsx(Form, null, __jsx(FormQuestionRadioGroupInput, {
    testId: testIds.options.id,
    name: "defaultBank",
    formikProps: formikProps,
    options: formOptions.options
  }), __jsx(ActionsWrapper, null, content === null || content === void 0 ? void 0 : content.buttons.map(btn => __jsx(Button, {
    key: btn.label,
    buttonType: btn.buttonType,
    disabled: btn.type !== 'button' && (!formikProps.isValid || isSubmitting),
    fullWidth: true,
    loading: btn.type !== 'button' && isSubmitting,
    onClick: () => (btn === null || btn === void 0 ? void 0 : btn.action) && handleOnClick(btn.action),
    trackingId: btn.testId,
    type: btn.type
  }, btn.label)))))));
};