import { CONFIG_VARS } from './vars';
let config = {};
const mergeConfig = currentEnv => {
  Object.assign(config, currentEnv);
};

/**
 * This sets the config object based on an
 * override host or the runtime host that we receive
 * from `server.js` or `client.js`.
 * @param {String} host
 */
export const initEnvConfig = configHost => {
  /**
   * `PROXY_HOST` is used to force a specific host during development.
   */
  let configVars = CONFIG_VARS.PROD;
  if (!configHost.endsWith('yieldstreet.com')) {
    configVars = CONFIG_VARS.TEST;
  }
  mergeConfig(configVars);
  config.HOST = configHost;
};
export default config;