import _defineProperty from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
var __jsx = React.createElement;
import React, { Component } from 'react';
import styled from 'styled-components';
import ArrowIcon from '../../assets/graphics/icons/arrow.svg';
import { CallbackLink } from '../../content/link';
export class Breadcrumb extends Component {
  constructor() {
    super(...arguments);
    _defineProperty(this, "render", () => {
      const {
        breadcrumbText,
        onClickLinkFn
      } = this.props;
      return __jsx(BreadcrumbComponent, {
        breadcrumbText: breadcrumbText,
        onClickLinkFn: onClickLinkFn
      });
    });
  }
}
const BreadcrumbComponent = _ref => {
  let {
    onClickLinkFn,
    breadcrumbText
  } = _ref;
  return __jsx(BreadcrumbWrapper, null, __jsx(Arrow, {
    alt: "Arrow to return to investment page",
    src: ArrowIcon,
    onClick: e => {
      e.preventDefault();
      onClickLinkFn && onClickLinkFn();
    }
  }), __jsx(CallbackLink, {
    callback: onClickLinkFn,
    isBreadcrumb: true
  }, __jsx("span", null, breadcrumbText && breadcrumbText)));
};
const BreadcrumbWrapper = styled.div.withConfig({
  displayName: "Breadcrumb__BreadcrumbWrapper",
  componentId: "sc-1q5lj5t-0"
})(["display:flex;"]);
const Arrow = styled.img.withConfig({
  displayName: "Breadcrumb__Arrow",
  componentId: "sc-1q5lj5t-1"
})(["transform:rotate(90deg);margin-right:8px;cursor:pointer;"]);