import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["children"];
var __jsx = React.createElement;
import React from 'react';
import styled, { css } from 'styled-components';
import { TextBaseStyle } from './TextBase';
export const SubTitle = _ref => {
  let {
      children
    } = _ref,
    knobs = _objectWithoutProperties(_ref, _excluded);
  return __jsx(SubTitleElement, _extends({
    as: "span"
  }, knobs), children);
};
export const SubTitleBase = css(["font-family:", ";font-weight:500;color:", ";"], props => props.theme.typography.primary, props => props.inverse ? props.theme.colors.text_paragraph_inverse : props.theme.colors.text_paragraph);
export const SubTitleStyle = css(["font-size:16px;line-height:22px;", ""], SubTitleBase);
const SubTitleElement = styled.span.withConfig({
  displayName: "SubTitle__SubTitleElement",
  componentId: "sc-1b8u50d-0"
})(["", ";", ";"], SubTitleStyle, TextBaseStyle);