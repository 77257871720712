import _Button from "@mui/material/Button";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useState, useMemo, useCallback } from 'react';
import { RouterLink } from '@yieldstreet/ui-kit/navigation';
import { WelcomeButtonsWrapper, Wrapper, ControlsWrapper, Controls } from './ProductTourModal.style';
import { content, testIds } from './ProductTourModal.model';
import { ProductTourSlide } from './components/ProductTourSlide';
export const ProductTourModal = _ref => {
  let {
    slides,
    hideModal
  } = _ref;
  const {
    0: currentStep,
    1: setCurrentStep
  } = useState(0);
  const handleOnClose = useCallback(() => {
    hideModal();
  }, [hideModal]);
  const handleOnNext = useCallback(() => {
    if (currentStep + 1 === (slides === null || slides === void 0 ? void 0 : slides.length)) {
      return hideModal();
    }
    return setCurrentStep(currentStep + 1);
  }, [currentStep, hideModal, slides === null || slides === void 0 ? void 0 : slides.length]);
  const handleOnPrevious = useCallback(() => setCurrentStep(currentStep - 1), [setCurrentStep, currentStep]);
  const item = useMemo(() => slides === null || slides === void 0 ? void 0 : slides[currentStep], [currentStep, slides]);
  const slideConfig = useMemo(() => ({
    currentSlide: currentStep - 1,
    sliderDots: (slides === null || slides === void 0 ? void 0 : slides.length) - 1
  }), [currentStep, slides]);
  return __jsx(Wrapper, testIds.base.attr, __jsx(ProductTourSlide, {
    item: item,
    config: slideConfig
  }), !currentStep && __jsx(WelcomeButtonsWrapper, testIds.welcomeControls.attr, __jsx(_Button, _extends({
    color: "secondary",
    onClick: handleOnNext
  }, testIds.btnShowButton.attr, {
    sx: {
      w: '240px',
      m: '0 auto'
    }
  }), content.showButton), __jsx(RouterLink, _extends({
    to: "#",
    onClick: handleOnClose
  }, testIds.btnManualExplore.attr, {
    sx: theme => ({
      margin: theme.spacing(6, 'auto', 0),
      width: 'fit-content'
    })
  }), content.manualExplore)), currentStep > 0 && __jsx(ControlsWrapper, testIds.sliderControls.attr, __jsx(Controls, null, __jsx(_Button, _extends({
    variant: "text",
    onClick: handleOnPrevious
  }, testIds.btnPreviousSlide.attr), content.controls.previousButton), __jsx(_Button, _extends({
    color: "secondary",
    onClick: handleOnNext
  }, testIds.btnNextSlide.attr), content.controls.nextButton))));
};