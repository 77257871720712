import styled, { css } from 'styled-components';
import { media } from '../../themes/media';
import { NotificationTypeEnum } from './Notification.model';
const getSeverityCss = severity => {
  switch (severity) {
    case NotificationTypeEnum.SUCCESS:
      return css(["background-color:", ";"], props => props.theme.colors.notice_success);
    case NotificationTypeEnum.WARNING:
      return css(["background-color:", ";"], props => props.theme.colors.notice_warning_background);
    case NotificationTypeEnum.ERROR:
      return css(["background-color:", ";"], props => props.theme.colors.notice_error);
    case 'info':
    default:
      return css(["background-color:", ";"], props => props.theme.colors.border_default);
  }
};
export const NotificationIcon = styled.img.withConfig({
  displayName: "Notificationstyle__NotificationIcon",
  componentId: "sc-ebjyub-0"
})(["height:20px;"]);
export const NotificationAction = styled.div.withConfig({
  displayName: "Notificationstyle__NotificationAction",
  componentId: "sc-ebjyub-1"
})([""]);
export const NotificationCloseButton = styled.div.withConfig({
  displayName: "Notificationstyle__NotificationCloseButton",
  componentId: "sc-ebjyub-2"
})([""]);
export const NotificationText = styled.div.withConfig({
  displayName: "Notificationstyle__NotificationText",
  componentId: "sc-ebjyub-3"
})(["display:flex;flex-direction:column;margin:auto;color:", ";font-family:", ";font-size:16px;span{padding-right:5px;}#notification-external-link{width:fit-content;}"], props => props.theme.colors.text_header, props => props.theme.typography.primary);
export const NotificationTextFlex = styled.div.withConfig({
  displayName: "Notificationstyle__NotificationTextFlex",
  componentId: "sc-ebjyub-4"
})(["display:inline-flex;flex-flow:row wrap;font-size:14px;word-break:break-word;", ""], media.desktop`
    display: inline;
    font-size: 16px;
    padding-top: 0;
  `);
export const NotificationTitle = styled.span.withConfig({
  displayName: "Notificationstyle__NotificationTitle",
  componentId: "sc-ebjyub-5"
})(["font-weight:500;"]);
export const NotificationDescription = styled.span.withConfig({
  displayName: "Notificationstyle__NotificationDescription",
  componentId: "sc-ebjyub-6"
})([""]);
export const NotificationInner = styled.div.withConfig({
  displayName: "Notificationstyle__NotificationInner",
  componentId: "sc-ebjyub-7"
})(["display:flex;flex-direction:row;flex-wrap:nowrap;justify-content:center;align-content:center;align-items:center;gap:10px;"]);
export const NotificationWrapper = styled.div.withConfig({
  displayName: "Notificationstyle__NotificationWrapper",
  componentId: "sc-ebjyub-8"
})(["border-radius:0;padding:", ";margin:", ";display:flex;flex-direction:row;flex-wrap:nowrap;", " ", " ", ";", "{align-items:", ";", "}", "{align-self:", ";", "}", "{display:flex;border:none;cursor:pointer;}"], props => props.theme.spacing.m, props => props.noMargin ? 0 : '0 auto 20px', props => props !== null && props !== void 0 && props.inline ? `
    justify-content: center;
    align-content: center;
    align-items: center;
    ` : `
    justify-content: space-between;
    align-content: center;
    align-items: center;
  `, props => props !== null && props !== void 0 && props.severity ? getSeverityCss(props.severity) : '', props => props.width ? `width: ${props.width}` : `max-width: ${props.inline ? '1440px' : '1180px'}`, NotificationIcon, props => props.iconAlignment, props => props.inline ? media.desktop` margin-left: auto;` : '', NotificationAction, props => props.iconAlignment, props => props.iconAlignment !== 'center' && `
        margin: 0;
        padding-left: 5px;
    `, NotificationCloseButton);