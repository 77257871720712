import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useEffect, useMemo } from 'react';
import { twoFactorAuthApi, User2FAMethod } from '@yieldstreet/platform-kit';
import { BusinessEvent, eventManager, useApiErrorMessage, createYupObjectSchema } from '@yieldstreet/tool-kit';
import { Paragraph, FormPhoneTextInput, Heading, PageLoader } from '@yieldstreet/ui-kit';
import { Formik, Form } from 'formik';
import { TWOFA_STEPS } from 'sharedComponents/TwoFaModal/TwoFaModalContainer.model';
import { useTwoFaStatus } from 'sharedComponents/TwoFaModal/hooks/useTwoFaStatus';
import { QRCode } from 'sharedComponents/QRCode';
import twoFAIcon from '../../../assets/Setup.svg';
import { Icon, ParagraphWrapper, SupportLink } from '../../../TwoFaModal.style';
import { AnchorButton, SLabelError, ButtonContainer, SButton, Line, CenteredButton, OtpCode } from './EditMethodStep.style';
import { AuthenticatorName, testIds } from './EditMethodStep.model';
import { ClipboardText } from 'sharedComponents/ClipboardText';
const PhoneSchema = createYupObjectSchema({
  phone: {
    validationType: 'phone'
  }
});
const {
  useRegisterMutation,
  useCreateFactorMutation
} = twoFactorAuthApi;
export const EditMethodStep = _ref => {
  let {
    method,
    setStep,
    setMethod
  } = _ref;
  const {
    phone,
    availableMethods,
    method: savedMethod
  } = useTwoFaStatus();
  const [register, registerResult] = useRegisterMutation();
  const [createQRCode, qrCodeResult] = useCreateFactorMutation({});
  const registerError = useApiErrorMessage(registerResult);
  const qrCodeError = useApiErrorMessage(qrCodeResult);
  const {
    data: {
      bindingUri = ''
    } = {},
    isLoading: generatingQRCode
  } = qrCodeResult;
  const altMethod = useMemo(() => availableMethods.find(availableMethod => availableMethod !== method), [method, availableMethods]);
  useEffect(() => {
    if (method && registerResult.isSuccess) {
      setStep(TWOFA_STEPS.CONFIRM);
      eventManager.track(BusinessEvent.USER_LOGIN_REGISTER_2FA, {
        authMethod: method
      });
    }
  }, [method, registerResult, setStep, savedMethod]);
  useEffect(() => {
    if (registerError) {
      eventManager.trackFailure(BusinessEvent.USER_LOGIN_REGISTER_2FA, {
        error: registerError,
        authMethod: method || savedMethod
      });
    }
  }, [registerError, method, savedMethod]);
  const handleModalTextMethod = useMemo(() => {
    if (method && method !== savedMethod) {
      return __jsx(Paragraph, testIds.newAuthyMethodDescription.attr, "You\u2019re changing your authentication method to use the ", AuthenticatorName[method], ".", ' ', method === User2FAMethod.AUTHY_APP && 'You’ll receive a text with a link to download the Authy app or use your current Authy account.', method === User2FAMethod.OTP_APP && 'Scan the QR Code below and click continue once you’re ready', ' ', "Once set up, you\u2019ll enter a verification code on the next screen.");
    }
    if (method && altMethod) {
      const handleAuthAppLinkClick = () => {
        createQRCode({});
        setMethod(altMethod);
      };
      return method === User2FAMethod.TEXT_MESSAGE ? __jsx(Paragraph, testIds.txtDescription.attr, "If you'd like to use SMS authentication, we'll send you a text with a verification code. We also support", ' ', __jsx(AnchorButton, _extends({}, testIds.anchorButton.attr, {
        onClick: handleAuthAppLinkClick
      }), AuthenticatorName[altMethod], " app for verification"), ", which is the most secure method.") : __jsx(Paragraph, testIds.authyDescription.attr, AuthenticatorName[method], " is your current two-factor authentication method. If you\u2019d rather use ", AuthenticatorName[altMethod], " for verification, select the option below to get started.");
    }
  }, [altMethod, method, savedMethod, setMethod]);
  const handleModalContentMethod = useMemo(() => {
    if (method !== User2FAMethod.TEXT_MESSAGE && savedMethod === method) {
      return __jsx(SButton, _extends({}, testIds.smsSwitchButton.attr, {
        type: "button",
        buttonType: "secondary",
        onClick: () => setMethod(User2FAMethod.TEXT_MESSAGE)
      }), "Switch to SMS verification");
    }
    if (method === User2FAMethod.OTP_APP) {
      if (generatingQRCode) {
        return __jsx(PageLoader, {
          testId: testIds.loader.id
        });
      }
      const handleContinueClick = () => {
        setStep(TWOFA_STEPS.CONFIRM);
      };
      return __jsx(React.Fragment, null, __jsx("div", {
        className: "fs-exclude"
      }, __jsx(QRCode, {
        value: bindingUri
      })), __jsx(OtpCode, {
        className: "fs-mask"
      }, __jsx(ClipboardText, {
        value: bindingUri,
        label: "Two-factor text code",
        testId: testIds.otpCode.id
      })), qrCodeError && __jsx(SLabelError, null, " ", qrCodeError, " "), __jsx(CenteredButton, null, __jsx(SButton, {
        onClick: handleContinueClick
      }, "Continue")));
    }
    return __jsx(Formik, {
      key: "phoneForm",
      enableReinitialize: true,
      initialValues: {
        phone: phone !== null && phone !== void 0 ? phone : ''
      },
      onSubmit: values => {
        register({
          phone: values.phone,
          method: method
        });
      },
      validationSchema: PhoneSchema
    }, formikProps => __jsx(Form, null, __jsx(FormPhoneTextInput, {
      label: "Mobile phone number",
      name: "phone",
      testId: testIds.phoneInput.id,
      formikProps: formikProps,
      min: 2,
      fullWidth: true
    }), registerError && __jsx(SLabelError, null, " ", registerError, " "), __jsx(ButtonContainer, null, __jsx(SButton, _extends({}, testIds.submitButton.attr, {
      loading: registerResult.isLoading,
      type: "submit",
      disabled: registerResult.isLoading || method === savedMethod
    }), method === User2FAMethod.AUTHY_APP ? 'Verify new number' : 'Send verification code'))));
  }, [bindingUri, createQRCode, generatingQRCode, method, phone, qrCodeError, register, registerError, registerResult.isLoading, savedMethod, setMethod, setStep]);
  return __jsx(React.Fragment, null, __jsx(Icon, _extends({
    src: twoFAIcon
  }, testIds.icon.attr)), __jsx(Heading, _extends({
    type: 4
  }, testIds.title.attr), savedMethod ? 'Edit two-factor authentication' : 'Secure your account with two-factor authentication'), __jsx(ParagraphWrapper, null, handleModalTextMethod), handleModalContentMethod, __jsx(Line, null), __jsx(SupportLink, _extends({
    to: "#",
    id: "intercom-link"
  }, testIds.intercomLink.attr), "Contact support"));
};