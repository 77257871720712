import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["hideLogin", "hideSignup", "hideUserNav", "preContent", "postContent", "customSignUpButton", "logoAlt"];
var __jsx = React.createElement;
import React from 'react';
import { LoginArea } from '../LoginArea';
import { Logo } from '../Logo';
import { NavigationLink } from '../../common';
import { Wrapper, Container, Flex, LogoWrap } from '../styles';
export const SimpleHeader = _ref => {
  let {
      hideLogin,
      hideSignup,
      hideUserNav,
      preContent,
      postContent,
      customSignUpButton,
      logoAlt
    } = _ref,
    otherProps = _objectWithoutProperties(_ref, _excluded);
  return __jsx(Wrapper, {
    "data-cy": "simple-header"
  }, preContent, __jsx(Container, null, __jsx(Flex, null, __jsx(LogoWrap, null, __jsx(NavigationLink, _extends({
    navItem: {
      key: 'home',
      route: '/',
      staticRoute: true
    },
    title: logoAlt,
    raw: true
  }, otherProps), __jsx(Logo, {
    inverse: true
  })))), __jsx(LoginArea, {
    hideLogin: hideLogin,
    hideSignup: hideSignup,
    hideUserNav: hideUserNav,
    customSignUpButton: customSignUpButton
  })), postContent);
};