import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { AssetClassType } from '@yieldstreet/platform-kit';
export const lightAssetColors = {
  [AssetClassType.RealEstate]: 'rgb(179, 239, 228)',
  [AssetClassType.RealAssets]: 'rgb(179, 239, 228)',
  [AssetClassType.PrivateCredit]: 'rgb(188, 227, 244)',
  [AssetClassType.PrivateEquity]: 'rgb(183, 206, 202)',
  [AssetClassType.Cash]: `rgb(239, 219, 38)`
};
export const assetColorsRGBValues = {
  [AssetClassType.RealEstate]: '133, 241, 183',
  [AssetClassType.RealAssets]: '30, 161, 217',
  [AssetClassType.PrivateCredit]: '15, 92, 78',
  [AssetClassType.PrivateEquity]: '2, 201, 165',
  [AssetClassType.Cash]: '239, 219, 38'
};
export const strokeAssetColors = {
  [AssetClassType.RealEstate]: `rgb(${assetColorsRGBValues[AssetClassType.RealAssets]})`,
  [AssetClassType.RealAssets]: `rgb(${assetColorsRGBValues[AssetClassType.RealAssets]})`,
  [AssetClassType.PrivateCredit]: `rgb(${assetColorsRGBValues[AssetClassType.PrivateCredit]})`,
  [AssetClassType.PrivateEquity]: `rgb(${assetColorsRGBValues[AssetClassType.PrivateEquity]})`,
  [AssetClassType.Cash]: `rgb(${assetColorsRGBValues[AssetClassType.Cash]})`
};
const GRADIENT_START = 'GRADIENT_START';
const GRADIENT_END = 'GRADIENT_END';
const assetColorsOpacityValues = {
  GRADIENT_START: '0.52',
  GRADIENT_END: '0.21'
};
const buildRGBA = (rgb, opacity) => `rgba(${rgb}, ${opacity})`;
const buildColorKey = (assetClass, suffix) => `${assetClass}_${suffix}`;
const getColors = function (assetClass) {
  let opacity = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  return {
    [assetClass]: buildRGBA(assetColorsRGBValues[assetClass], opacity),
    [buildColorKey(assetClass, GRADIENT_START)]: buildRGBA(assetColorsRGBValues[assetClass], assetColorsOpacityValues.GRADIENT_START),
    [buildColorKey(assetClass, GRADIENT_END)]: buildRGBA(assetColorsRGBValues[assetClass], assetColorsOpacityValues.GRADIENT_END)
  };
};
export const getAssetColors = function () {
  let opacity = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({}, getColors(AssetClassType.RealEstate, opacity)), getColors(AssetClassType.RealAssets, opacity)), getColors(AssetClassType.PrivateCredit, opacity)), getColors(AssetClassType.PrivateEquity, opacity)), getColors(AssetClassType.Cash, opacity));
};