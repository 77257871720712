var __jsx = React.createElement;
import React from 'react';
import { QuestionSecondaryRadioGroupInput } from '@yieldstreet/ui-kit';
import { iraTypes, testIds } from './IraType.model';
import { Wrapper, ErrorMessage } from './IraType.style';
export const IraType = _ref => {
  let {
    handleCreateIra,
    error
  } = _ref;
  return __jsx(Wrapper, testIds.wrapper.attr, error && __jsx(ErrorMessage, null, error.message), __jsx(QuestionSecondaryRadioGroupInput, {
    name: "iraType",
    onChange: handleCreateIra,
    options: iraTypes,
    testId: testIds.radioGroup.id
  }));
};