import { genTestIds } from '@yieldstreet/tool-kit';
const {
  getTestIds
} = genTestIds(['title', 'getStarted', 'learnMore']);
export const testIds = getTestIds('ira-modal');
export const benefits = [{
  title: 'Broad range of alternatives',
  description: 'Diversify your retirement portfolio with real estate, private credit, private equity, Short Term Notes, and more.'
}, {
  title: 'Support for all major accounts',
  description: 'Roll over a 401(k); transfer all, or a portion of a traditional IRA, Roth IRA, SEP IRA, or SIMPLE IRA; or contribute new funding.'
}, {
  title: 'White-glove onboarding',
  description: 'Your dedicated representative will handle everything needed to set up and fund your account.'
}];