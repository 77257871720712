import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { mapToTestIds } from './Accordion.model';
import { AccordionHeading, AnimatedContent, Container, Description, HeadingIcon, HeadingWrapper, IconHolder, Title } from './Accordion.style';
export const Accordion = _ref => {
  let {
    addParagraphMargin = true,
    animated,
    background = 'primary',
    children,
    headingIcon,
    headingType = 4,
    showIcon = true,
    startOpen,
    subHeading,
    tagType = 4,
    title,
    testId,
    toggleVisibilityCallback
  } = _ref;
  const {
    0: show,
    1: setShow
  } = useState(startOpen || false);
  const toggleShow = () => {
    setShow(!show);
    if (toggleVisibilityCallback) {
      toggleVisibilityCallback();
    }
  };
  const testIds = mapToTestIds(testId);
  return __jsx(Container, _extends({
    background: background
  }, testIds.base.attr), __jsx(Title, {
    onClick: toggleShow
  }, __jsx(HeadingWrapper, null, __jsx(AccordionHeading, {
    type: headingType,
    tagType: tagType
  }, headingIcon && __jsx(HeadingIcon, {
    src: headingIcon
  }), title), subHeading), showIcon && __jsx(React.Fragment, null, __jsx(IconHolder, null, show && __jsx(FontAwesomeIcon, {
    icon: faChevronUp
  }), !show && __jsx(FontAwesomeIcon, {
    icon: faChevronDown
  })))), animated && __jsx(AnimatedContent, {
    show: show
  }, __jsx(Description, {
    addParagraphMargin: addParagraphMargin
  }, children)), !animated && show && __jsx(Description, {
    addParagraphMargin: addParagraphMargin
  }, children));
};