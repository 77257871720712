var __jsx = React.createElement;
import React, { useCallback } from 'react';
import { investorEntityApi, InvestorEntityStepVerificationStatus } from '@yieldstreet/platform-kit';
import { Label, Spinner } from '@yieldstreet/ui-kit';
import { useInvestorEntityFlags } from '@yieldstreet/tool-kit';
import tractionNumbers from '../../../../../../../static-files/tractionNumbers.json';
import { useRouteInvestorEntity } from 'pages/investorEntity/utils';
import { testIds } from './Portfolios.model';
import { DetailInfo, LinkLabel, LabelWrapper } from './Portfolios.style';
import { useInvestorEntityWallet } from '../../hooks';
import { useInvestorEntityAction, useInvestorEntityActionHandler } from '../../utils';
const {
  useUpdateInvestorEntityMutation
} = investorEntityApi;
export const AccountInfo = () => {
  const [updateInvestorAccount, {
    isLoading: walletSetupLoading
  }] = useUpdateInvestorEntityMutation();
  const {
    investorEntity,
    investorEntityStatus,
    refetch
  } = useRouteInvestorEntity();
  const {
    selfDirectedWallet
  } = useInvestorEntityWallet(investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.id);
  const {
    isETCAccount,
    isLegacyIRA
  } = useInvestorEntityFlags(investorEntity);
  const {
    nextAction
  } = useInvestorEntityAction({
    investorEntity,
    investorEntityStatus,
    onStepSuccess: refetch
  });
  const identityConfirmed = (investorEntityStatus === null || investorEntityStatus === void 0 ? void 0 : investorEntityStatus.identity.status) === InvestorEntityStepVerificationStatus.CONFIRMED;
  const {
    handleOnActionClick
  } = useInvestorEntityActionHandler();
  const createWallet = useCallback(() => {
    if (investorEntity) {
      updateInvestorAccount({
        investorEntityId: investorEntity.id,
        walletEnabled: true
      });
    }
  }, [investorEntity, updateInvestorAccount]);
  if (!selfDirectedWallet && (isETCAccount || isLegacyIRA)) {
    return __jsx(Label, testIds.walletNotAvailableIra.attr, "Wallets are not currently available for IRA accounts.");
  }
  if (!selfDirectedWallet && !isETCAccount && !(investorEntity !== null && investorEntity !== void 0 && investorEntity.walletEnabled)) {
    return __jsx(DetailInfo, null, walletSetupLoading ? __jsx(Spinner, {
      small: true
    }) : __jsx(React.Fragment, null, __jsx(Label, null, "Setup your cash wallet today to receive payments, earn $", tractionNumbers.walletInterestRate, " interest and invest more quickly."), __jsx(LinkLabel, {
      onClick: createWallet
    }, "Set up Wallet")));
  }
  if (!identityConfirmed) {
    return __jsx(LabelWrapper, null, __jsx(DetailInfo, null, __jsx(Label, null, "Once your account has been set up, a Cash Wallet will automatically be created for you."), __jsx(LinkLabel, {
      onClick: () => {
        handleOnActionClick({
          action: nextAction === null || nextAction === void 0 ? void 0 : nextAction.action,
          link: nextAction === null || nextAction === void 0 ? void 0 : nextAction.link,
          investorEntityId: investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.id
        });
      }
    }, "Finish your account setup")));
  }
  return __jsx(React.Fragment, null);
};