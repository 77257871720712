import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
const _excluded = ["userId"],
  _excluded2 = ["userId"];
import orderBy from "lodash/orderBy";
import { HttpMethods } from '../../../enums';
import { Tags, restApi } from '../../common';
export const investmentsApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.PortfolioInvestments, Tags.PortfolioInvestmentsActivityYears, Tags.PortfolioInvestmentsByAssetClass, Tags.PortfolioInvestmentsByOffering, Tags.PortfolioInvestmentsByStatus, Tags.PortfolioInvestmentStrategy]
}).injectEndpoints({
  endpoints: build => {
    const getInvestmentsActivityYears = build.query({
      query: _ref => {
        let {
          investorId,
          userId,
          managementStrategy
        } = _ref;
        return {
          url: `/api/portfolio/${userId}/investments/activity-years`,
          method: HttpMethods.Get,
          params: {
            investorAccountId: investorId,
            managementStrategy
          }
        };
      },
      providesTags: [Tags.PortfolioInvestmentsActivityYears],
      transformResponse: _ref2 => {
        let {
          availableYears
        } = _ref2;
        return {
          years: availableYears
        };
      }
    });
    const getInvestmentsByAssetClass = build.query({
      query: _ref3 => {
        let {
          asOfDate,
          investorAccountId,
          percentageDecimalPoints,
          showFirst,
          status,
          userId,
          managementStrategy
        } = _ref3;
        return {
          url: `/api/portfolio/${userId}/investments/by-asset-class`,
          method: HttpMethods.Get,
          params: {
            asOfDate,
            investorAccountId,
            percentageDecimalPoints,
            showFirst,
            status,
            managementStrategy
          }
        };
      },
      providesTags: [Tags.PortfolioInvestmentsByAssetClass],
      transformResponse: response => {
        // TODO: Remove codeblock once sorting/ordering is provided by backend - https://app.shortcut.com/yieldstreet/story/102060
        const currentValueBreakdown = orderBy(response === null || response === void 0 ? void 0 : response.currentValueBreakdown, item => item === null || item === void 0 ? void 0 : item.percentage, ['desc']);
        return _objectSpread(_objectSpread({}, response), {}, {
          currentValueBreakdown
        });
      }
    });
    const getInvestmentsByOffering = build.query({
      query: _ref4 => {
        let {
            userId
          } = _ref4,
          params = _objectWithoutProperties(_ref4, _excluded);
        return {
          url: `/api/portfolio/${userId}/investments/by-offering`,
          method: HttpMethods.Get,
          params
        };
      },
      providesTags: [Tags.PortfolioInvestmentsByOffering]
    });
    const getInvestmentsByStatus = build.query({
      query: _ref5 => {
        let {
            userId
          } = _ref5,
          params = _objectWithoutProperties(_ref5, _excluded2);
        return {
          url: `/api/portfolio/${userId}/investments/by-status`,
          method: HttpMethods.Get,
          params
        };
      },
      providesTags: [Tags.PortfolioInvestmentsByStatus]
    });
    const updateFundReinvestmentStatus = build.mutation({
      query: _ref6 => {
        let {
          fundId,
          investorAccountId,
          reinvestmentChoice
        } = _ref6;
        return {
          url: `/api/fund/funds/${fundId}/accounts/${investorAccountId}`,
          method: HttpMethods.Patch,
          data: reinvestmentChoice
        };
      }
    });
    const getInvestmentStrategy = build.query({
      query: _ref7 => {
        let {
          investorAccountId,
          userId
        } = _ref7;
        return {
          url: `/api/portfolio/${userId}/investment-strategy/${investorAccountId}`,
          method: HttpMethods.Get
        };
      },
      providesTags: [Tags.PortfolioInvestmentStrategy]
    });
    return {
      getInvestmentsActivityYears,
      getInvestmentsByAssetClass,
      getInvestmentsByOffering,
      getInvestmentsByStatus,
      updateFundReinvestmentStatus,
      getInvestmentStrategy
    };
  }
});