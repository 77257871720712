import styled, { css } from 'styled-components';
import { SpacingStyle, getSizeVar } from '../../base-styles';
import { SkeletonTextAlign } from './Skeleton.model';
const skeletonTextCommon = css(["> *{&:last-child{min-width:80%;}&:only-child{min-width:100%;}}"]);
const getAlignStyles = align => {
  switch (align) {
    case SkeletonTextAlign.Left:
      return css(["align-items:flex-start;", ""], skeletonTextCommon);
    case SkeletonTextAlign.Right:
      return css(["align-items:flex-end;", ""], skeletonTextCommon);
    case SkeletonTextAlign.Center:
      return css(["align-items:center;", ""], skeletonTextCommon);
    default:
      return css(["align-items:stretch;"]);
  }
};
const CommonStyles = css(["", ""], props => props.filled ? `
    background: ${props.theme.colors.background_page};
  ` : `
    background: ${props.theme.colors.background_default};
    border: 1px solid ${props.theme.colors.border_default};
  `);
const ActiveStyles = css(["@keyframes active{0%{opacity:1;}50%{opacity:0.4;}100%{opacity:1;}}animation:1.5s ease-in-out 0.5s infinite normal none running active;"]);
export const StyledSkeletonThumbnail = styled.div.withConfig({
  displayName: "Skeletonstyle__StyledSkeletonThumbnail",
  componentId: "sc-qxysr3-0"
})(["", ";", ";", " border-radius:", ";aspect-ratio:auto ", ";", ";"], CommonStyles, props => props.active && ActiveStyles, props => props.size ? `
    max-width: var(${getSizeVar(props === null || props === void 0 ? void 0 : props.size)});
  ` : `
    width: 100%;
  `, props => props.rounded ? '50%' : '2px', props => props.ratioX && props.ratioY && `${props.ratioX} / ${props.ratioY}`, SpacingStyle);
export const StyledSkeletonLine = styled.span.withConfig({
  displayName: "Skeletonstyle__StyledSkeletonLine",
  componentId: "sc-qxysr3-1"
})(["", ";", ";display:inline-block;height:auto;min-width:", ";border-radius:2px;&::before{content:'\\00a0';}"], CommonStyles, props => props.active && ActiveStyles, props => props !== null && props !== void 0 && props.spread ? `${props.spread / 12 * 100}%` : '100%');
export const StyledSkeletonText = styled.span.withConfig({
  displayName: "Skeletonstyle__StyledSkeletonText",
  componentId: "sc-qxysr3-2"
})(["display:flex;flex-direction:column;flex-wrap:nowrap;justify-content:flex-start;align-content:stretch;align-items:stretch;gap:0.4rem;margin:", " 0;", ""], props => props.theme.spacing.s, props => getAlignStyles(props === null || props === void 0 ? void 0 : props.align));
export const StyledSkeletonContainer = styled.div.withConfig({
  displayName: "Skeletonstyle__StyledSkeletonContainer",
  componentId: "sc-qxysr3-3"
})(["", ";display:inline-block;min-width:", ";border-radius:2px;", " ", ";"], CommonStyles, props => props !== null && props !== void 0 && props.spread ? `${props.spread / 12 * 100}%` : '100%', props => props.filled && props.active ? ActiveStyles : '', SpacingStyle);