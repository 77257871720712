export const ARTICLE_TYPE = 222;
export const VIDEO_TYPE = 223;
export const INFOGRAPHIC_TYPE = 238;
export const PRESS_CATEGORYID = 215;
export const YIELDSTREET_DEFAULT_TYPE = 415;
export const ADVANCED_INVESTING = 437;
export const ALTERNATIVE_INVESTING = 5;
export const ASSET_CLASS = 369;
export const DEFINITIONS = 13;
export const FAQ = 7;
export const INVESTING101 = 436;
export const INVESTING_IN_ALTERNATIVES = 438;
export const INVESTMENT_BASICS = 4;
export const INVESTOR_TOOLKIT = 367;
export const MARKET_INSIGHTS = 439;
export const LT_CATEGORY_TAG = 368;