import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["title", "image", "imageAlt", "imageWidth", "imageHeight", "alignment", "rate", "children", "inverse", "rateStyle", "fontBig", "isLabel", "rateAlignment", "small"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { media } from '../../../themes';
import { Heading, Paragraph } from '../../../content/text';
export const Rating = _ref => {
  let {
      title,
      image,
      imageAlt,
      imageWidth,
      imageHeight,
      alignment = 'left',
      rate,
      children,
      inverse,
      rateStyle,
      fontBig = false,
      isLabel,
      rateAlignment,
      small = false
    } = _ref,
    otherProps = _objectWithoutProperties(_ref, _excluded);
  return __jsx(OuterContainer, _extends({}, otherProps, {
    alignment: alignment
  }), __jsx(Content, {
    semiBold: true,
    inverse: inverse,
    fontBig: fontBig,
    isLabel: isLabel
  }, children), title && __jsx(Headline, {
    type: 5,
    inverse: inverse
  }, title), __jsx(ImageHolder, null, image && __jsx(ImageWrapper, null, __jsx(Image, {
    alt: imageAlt,
    src: image,
    width: imageWidth,
    height: imageHeight
  }))), __jsx(RateWrapper, {
    rateAlignment: rateAlignment
  }, __jsx(RateAmount, {
    semiBold: true,
    rateStyle: rateStyle,
    small: small
  }, small ? `${rate} / 5` : rate), __jsx(Rate, {
    rate: rate,
    rateStyle: rateStyle,
    small: small
  })));
};
const getRateStyle = rateStyle => {
  switch (rateStyle) {
    case 'signup':
      return {
        gradient1: 'accent_medium',
        gradient2: 'border_strong',
        gradientAplha: 1,
        letterSpacing: '3px',
        marginLeft: '6px',
        amountColor: 'accent_medium'
      };
    case 'gray':
      return {
        gradient1: 'text_label',
        gradient2: 'text_label_inverse',
        gradientAplha: 1,
        letterSpacing: '3px',
        marginLeft: '6px',
        amountColor: 'text_label'
      };
    case '':
    default:
      return {
        gradient1: 'accent_medium',
        gradient2: 'accent_medium',
        gradientAplha: 0.3,
        letterSpacing: '1px',
        marginLeft: '0',
        amountColor: 'accent_medium'
      };
  }
};
const OuterContainer = styled.div.withConfig({
  displayName: "Rating__OuterContainer",
  componentId: "sc-26owmy-0"
})(["display:flex;text-align:", ";position:relative;flex-direction:column;flex-grow:1;flex-basis:0;&:not(:last-child){border-right:1px solid ", ";}", ""], props => props.alignment, props => rgba(props.theme.colors.accent_medium, 0.2), media.desktop`
    padding: 0 20px;
  `);
const Headline = styled(Heading).withConfig({
  displayName: "Rating__Headline",
  componentId: "sc-26owmy-1"
})(["padding:0;margin:10px 0;"]);
const Content = styled(Paragraph).withConfig({
  displayName: "Rating__Content",
  componentId: "sc-26owmy-2"
})(["", " ", ""], props => props.isLabel && `
      color: ${props.theme.colors.text_label};
      font-size: 14px;
      font-weight: 400;
      `, props => props.fontBig ? `
      font-size: 26px;
      line-height: 32px;
      margin: 15px 0 50px;
  ` : `margin: 25px 0`);
const ImageHolder = styled.div.withConfig({
  displayName: "Rating__ImageHolder",
  componentId: "sc-26owmy-3"
})(["margin-bottom:10px;"]);
const ImageWrapper = styled.div.withConfig({
  displayName: "Rating__ImageWrapper",
  componentId: "sc-26owmy-4"
})([""]);
const Image = styled.img.withConfig({
  displayName: "Rating__Image",
  componentId: "sc-26owmy-5"
})([""]);
const RateWrapper = styled.div.withConfig({
  displayName: "Rating__RateWrapper",
  componentId: "sc-26owmy-6"
})(["align-items:center;display:flex;justify-content:", ";"], props => props.rateAlignment);
const RateAmount = styled(Paragraph).withConfig({
  displayName: "Rating__RateAmount",
  componentId: "sc-26owmy-7"
})(["margin-right:4px;display:inline;color:", ";font-size:", ";"], props => props.theme.colors[getRateStyle(props.rateStyle || '').amountColor], props => props.small && '10px');
const Rate = styled.div.withConfig({
  displayName: "Rating__Rate",
  componentId: "sc-26owmy-8"
})(["display:inline-block;font-size:", ";font-family:Times;line-height:1;&::before{will-change:auto;content:'\u2605\u2605\u2605\u2605\u2605';letter-spacing:", ";margin-left:", ";background:linear-gradient( 90deg,", " calc(", " / 5 * 100%),", " 0 );background-size:20% 50%;animation:Animate 1s ease forwards;animation-iteration-count:1;background-clip:text;-webkit-background-clip:text;color:transparent;-webkit-text-fill-color:transparent;}@keyframes Animate{to{background-size:100% 100%;}}"], props => props.small ? '12px' : '20px', props => getRateStyle(props.rateStyle || '').letterSpacing, props => getRateStyle(props.rateStyle || '').marginLeft, props => props.theme.colors[getRateStyle(props.rateStyle || '').gradient1], props => props.rate, props => rgba(props.theme.colors[getRateStyle(props.rateStyle || '').gradient2], getRateStyle(props.rateStyle || '').gradientAplha));