var __jsx = React.createElement;
import React from 'react';
import { abbreviate } from './Currency.util';
export const Currency = _ref => {
  let {
    code = '$',
    doNotRound,
    forcePlaces = 0,
    maxPlaces,
    postSign,
    value
  } = _ref;
  return __jsx("span", null, code, doNotRound ? value.toLocaleString('en-US', {
    minimumFractionDigits: forcePlaces
  }) : abbreviate(value, maxPlaces, forcePlaces), postSign);
};