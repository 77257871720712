import styled from 'styled-components';
import { Paragraph } from '../../content/text';
import { SFormControl, SFormControlLabel } from './formStyles';
export const OptionControl = styled(SFormControl).withConfig({
  displayName: "optionStyles__OptionControl",
  componentId: "sc-1o4t30x-0"
})(["&.MuiFormControl-root{margin-bottom:0;padding-top:5px;padding-bottom:5px;}"]);
export const OptionControlLabel = styled(SFormControlLabel).withConfig({
  displayName: "optionStyles__OptionControlLabel",
  componentId: "sc-1o4t30x-1"
})(["& .MuiButtonBase-root{align-self:start;padding-top:0;padding-bottom:0;padding:5px;margin:-4px 4px;color:", " !important;}& .MuiTypography-root{color:", ";}"], _ref => {
  let {
    disabled,
    theme
  } = _ref;
  return disabled ? theme.colors.input_disabled_text : theme.colors.input_background_selected;
}, props => props.theme.colors.text_paragraph);
export const OptionLabel = styled.div.withConfig({
  displayName: "optionStyles__OptionLabel",
  componentId: "sc-1o4t30x-2"
})(["align-items:center;display:flex;flex-direction:row,margin-top:4px;"]);
export const OptionDescription = styled(Paragraph).withConfig({
  displayName: "optionStyles__OptionDescription",
  componentId: "sc-1o4t30x-3"
})(["color:", ";"], props => props.theme.colors.text_label);