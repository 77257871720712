import { CALL_API } from '../middleware/Middleware';
export const GET_ASSETCLASSES_ATTEMPT = 'ys/assetClasses/ATTEMPT';
export const GET_ASSETCLASSES_SUCCESS = 'ys/assetClasses/SUCCESS';
export const GET_ASSETCLASSES_FAILURE = 'ys/assetClasses/FAILURE';
export const getAssetClasses = () => dispatch => {
  return dispatch({
    [CALL_API]: {
      endpoint: '/a/api/dic/note/assetClasses',
      types: [GET_ASSETCLASSES_ATTEMPT, GET_ASSETCLASSES_SUCCESS, GET_ASSETCLASSES_FAILURE],
      getResponse: res => res.data.assetClasses
    }
  });
};