var __jsx = React.createElement;
import React from 'react';
import { Wrapper } from './Overlay.style';
export const Overlay = _ref => {
  let {
    hideOnMobile,
    onClick,
    'data-cy': dataCy
  } = _ref;
  return __jsx(Wrapper, {
    "data-cy": dataCy,
    onClick: onClick,
    hideOnMobile: hideOnMobile
  });
};