var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import Asset from '@yieldstreet/ui-kit/assets/graphics/icons/asset.svg';
import Cash from '@yieldstreet/ui-kit/assets/graphics/icons/commercial_finance.svg';
import Download from '@yieldstreet/ui-kit/assets/graphics/icons/file_download.svg';
import Flexibility from '@yieldstreet/ui-kit/assets/graphics/icons/flexibility.svg';
import Holdings from '@yieldstreet/ui-kit/assets/graphics/icons/holdings.svg';
import Note from '@yieldstreet/ui-kit/assets/graphics/icons/note.svg';
import Podcast from '@yieldstreet/ui-kit/assets/graphics/icons/podcast.svg';
import Risk from '@yieldstreet/ui-kit/assets/graphics/icons/risk.svg';
import SourcesUses from '@yieldstreet/ui-kit/assets/graphics/icons/sources_uses.svg';
import Structure from '@yieldstreet/ui-kit/assets/graphics/icons/structure.svg';
const iconSelection = name => {
  switch (name) {
    case 'podcast':
      return Podcast;
    case 'cash':
    case 'cashflow':
      return Cash;
    case 'download':
      return Download;
    case 'risk':
      return Risk;
    case 'bank':
    case 'collateral':
      return Asset;
    case 'structure':
      return Structure;
    case 'note':
      return Note;
    case 'flexibility':
      return Flexibility;
    case 'sourcesuses':
      return SourcesUses;
    case 'holdings':
      return Holdings;
    default:
      return Note;
  }
};
const OfferingIcon = _ref => {
  let {
    iconName,
    dataCy
  } = _ref;
  return __jsx(IconContainer, {
    className: "iconImage"
  }, __jsx(IconImage, {
    "data-cy": dataCy,
    src: iconSelection(iconName.toLowerCase())
  }));
};
const IconContainer = styled.div.withConfig({
  displayName: "IconComponent__IconContainer",
  componentId: "sc-1108944-0"
})([""]);
const IconImage = styled.img.withConfig({
  displayName: "IconComponent__IconImage",
  componentId: "sc-1108944-1"
})(["max-width:30px;max-height:30px;height:30px;width:30px;"]);
export default OfferingIcon;