var __jsx = React.createElement;
import React from 'react';
import { useDiscretionaryFlags } from 'hooks';
import { Wrapper, CardsWrapper } from './Portfolios.style';
import { SelfDirectPortfolio } from './SelfDirectPortfolio';
import { ManagedPortfolio } from './ManagedPortfolio';
import { RouteDetails } from '../../components/RouteDetails';
export const Portfolios = () => {
  const {
    showManagedPortfolio
  } = useDiscretionaryFlags();
  return __jsx(Wrapper, null, __jsx(RouteDetails, {
    headingTitle: showManagedPortfolio ? 'Portfolios' : 'Wallets'
  }), __jsx(CardsWrapper, null, __jsx(SelfDirectPortfolio, null), showManagedPortfolio && __jsx(ManagedPortfolio, null)));
};