var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { IconPicker } from '../../utils/icon-picker';
import { Label, Paragraph } from '../../content/text';
import { media } from '../../themes';
export const MediaCard = _ref => {
  let {
    title,
    url,
    description,
    iconName,
    type,
    documents,
    userState,
    isPastOffering
  } = _ref;
  const getUrl = type => {
    var _documents$find, _documents$find2, _documents$find3;
    switch (type) {
      case 'custom':
      case 'main':
      case 'other':
        return url;
      case 'mainDocument':
        return documents === null || documents === void 0 ? void 0 : (_documents$find = documents.find(doc => doc.type === 'main')) === null || _documents$find === void 0 ? void 0 : _documents$find.url;
      case 'mainDocumentPreview':
        return documents === null || documents === void 0 ? void 0 : (_documents$find2 = documents.find(doc => doc.type === 'preview')) === null || _documents$find2 === void 0 ? void 0 : _documents$find2.url;
      case 'standAloneDoc':
        return documents === null || documents === void 0 ? void 0 : (_documents$find3 = documents.find(doc => doc.name === title)) === null || _documents$find3 === void 0 ? void 0 : _documents$find3.url;
      default:
        return;
    }
  };
  if (type === 'main' || type === 'other' || type === 'standAloneDoc') {
    iconName = 'download';
  }
  if (userState.loggedIn && !isPastOffering || (!userState.loggedIn || isPastOffering) && type === 'custom') {
    return __jsx(Wrapper, null, __jsx("a", {
      className: "media-card-link",
      "data-cy": "media-card-link",
      href: getUrl(type),
      target: "_blank",
      rel: "noopener noreferrer"
    }, __jsx(TextWrapper, null, __jsx(TitleContainer, null, iconName && __jsx(IconPicker, {
      className: "media-card-icon",
      "data-cy": "media-card-icon",
      iconName: iconName
    }), __jsx(Title, {
      className: "media-card-title",
      "data-cy": "media-card-title",
      icon: iconName
    }, title)), description && __jsx(Description, {
      className: "media-card-description",
      "data-cy": "media-card-description"
    }, __jsx("span", {
      dangerouslySetInnerHTML: {
        __html: description
      }
    })))));
  } else return null;
};
const Wrapper = styled.div.withConfig({
  displayName: "MediaCard__Wrapper",
  componentId: "sc-z4c0km-0"
})(["cursor:pointer;overflow-wrap:break-word;border-bottom:2px solid ", ";box-shadow:0px 4px 8px rgba(0,0,0,0.1);margin:10px 0;padding:20px;max-height:144px;flex-wrap:wrap;background-color:", ";", ";", ";"], props => props.theme.colors.chart_1, props => props.theme.colors.background_card, media.tablet`
    flex: 1;
    margin: 20px 10px;
  `, media.large`
    margin: 20px 10px;
    flex-basis: 33.33%;
    max-width: 380px;
  `);
const TextWrapper = styled.div.withConfig({
  displayName: "MediaCard__TextWrapper",
  componentId: "sc-z4c0km-1"
})(["width:100%;height:100%;"]);
const TitleContainer = styled.div.withConfig({
  displayName: "MediaCard__TitleContainer",
  componentId: "sc-z4c0km-2"
})(["display:flex;flex-direction:row;overflow-wrap:anywhere;.iconImage{margin:auto 0;}cursor:pointer;"]);
const Title = styled(Paragraph).withConfig({
  displayName: "MediaCard__Title",
  componentId: "sc-z4c0km-3"
})(["padding-bottom:10px;margin-left:", ";"], props => props.icon && `20px`);
const Description = styled(Label).withConfig({
  displayName: "MediaCard__Description",
  componentId: "sc-z4c0km-4"
})(["cursor:pointer;"]);