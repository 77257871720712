import { allToken, userApi } from '@yieldstreet/platform-kit';
import { useFrontload } from 'react-frontload';
export const useServerPreload = async (key, serverPreload) => {
  const [getUserState] = userApi.useLazyGetUserStateQuery();
  return useFrontload(key, async () => {
    const userState = await getUserState(allToken).unwrap();
    if (userState !== null && userState !== void 0 && userState.authToken) {
      return Promise.resolve(null);
    }
    await serverPreload();
    return Promise.resolve(true);
  });
};