export const setClientError = function (event) {
  var _window;
  let eventName = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'client-error';
  const {
    errorMessage
  } = event;
  if (errorMessage && window && (_window = window) !== null && _window !== void 0 && _window.analytics) {
    var _window2, _window2$analytics;
    (_window2 = window) === null || _window2 === void 0 ? void 0 : (_window2$analytics = _window2.analytics) === null || _window2$analytics === void 0 ? void 0 : _window2$analytics.track(eventName, event);
  }
};