var __jsx = React.createElement;
import React, { useState } from 'react';
import styled from 'styled-components';
import { media } from '../../themes/media';
// import { Heading } from '../../content/text';
import { PageSection } from '../../layout/page-section';
import { Heading } from '../../content/text/Heading';
import { Paragraph } from '../../content';
export const SliderSidePhotoContent = props => {
  const {
    heading,
    items
  } = props;
  const {
    0: section,
    1: setSection
  } = useState(0);
  const sectionChange = function (newSelection) {
    let isMobile = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    if (isMobile && section === newSelection) {
      setSection(undefined);
      return;
    }
    setSection(newSelection);
  };
  const list = items.map((item, key) => __jsx(ListItem, {
    key: key
  }, __jsx(Title, {
    onClick: () => sectionChange(key)
  }, __jsx(Paragraph, {
    semiBold: true
  }, item.title)), __jsx(ListContent, {
    small: true,
    active: section === key
  }, item.content)));
  const image = items.map((item, key) => __jsx(ImageHolder, {
    key: key,
    active: section === key
  }, item.image));
  return __jsx(PageSection, null, __jsx(Container, {
    items: items
  }, __jsx(LeftContainer, null, heading && __jsx(Heading, {
    type: 3
  }, heading), __jsx(List, null, list)), __jsx(RightContainer, null, image)));
};
const Container = styled.div.withConfig({
  displayName: "SliderSidePhotoContent__Container",
  componentId: "sc-17yjm6m-0"
})(["margin:20px 0;display:flex;flex-wrap:wrap;flex-direction:column;position:relative;", " ", ""], media.tablet`
      margin: 40px 0;
      flex-direction: row;
  `, media.desktop`
    padding: 0 65px;
  `);
const LeftContainer = styled.div.withConfig({
  displayName: "SliderSidePhotoContent__LeftContainer",
  componentId: "sc-17yjm6m-1"
})(["width:100%;padding:0 20px;", " ", ""], media.tablet`
    margin: 20px 0 0 0;
    width: 50%; 
    padding: 0 20px;
  `, media.desktop`
    padding: 0 60px;
  `);
const RightContainer = styled.div.withConfig({
  displayName: "SliderSidePhotoContent__RightContainer",
  componentId: "sc-17yjm6m-2"
})(["width:100%;", " ", ""], media.tablet`
    margin: 20px 0 0 0;
    width: 50%;
    padding: 0 20px;
  `, media.desktop`
    padding: 0 60px;
  `);
const List = styled.ul.withConfig({
  displayName: "SliderSidePhotoContent__List",
  componentId: "sc-17yjm6m-3"
})(["margin-top:20px;"]);
const ListItem = styled.li.withConfig({
  displayName: "SliderSidePhotoContent__ListItem",
  componentId: "sc-17yjm6m-4"
})(["padding:20px 0;border-top:1px solid ", ";border-bottom:1px solid ", ";", ""], props => props.theme.colors.border_default, props => props.theme.colors.border_default, media.tablet`
    max-width: 375px;
  `);
const Title = styled.div.withConfig({
  displayName: "SliderSidePhotoContent__Title",
  componentId: "sc-17yjm6m-5"
})(["", ""], media.tablet`
    cursor: pointer;
  `);
const ListContent = styled(Paragraph).withConfig({
  displayName: "SliderSidePhotoContent__ListContent",
  componentId: "sc-17yjm6m-6"
})(["display:block;margin-top:10px;", ""], media.tablet`
    display: ${props => props.active ? 'block' : 'none'};
  `);
const ImageHolder = styled.div.withConfig({
  displayName: "SliderSidePhotoContent__ImageHolder",
  componentId: "sc-17yjm6m-7"
})(["display:none;", ";"], media.tablet`
    margin: 0 auto;
    text-align: center;
    display: ${props => props.active ? 'block' : 'none'};
  `);