import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { LoaderContainer } from '../page-loader';
import { useTestIds } from './ModalLoader.model';
import styled from 'styled-components';
import { Spinner } from '../spinner';
import { friendlyMedia } from '../../themes';
export const ModalLoader = props => {
  const testIds = useTestIds(props);
  return __jsx(ModalLoaderContainer, _extends({
    center: true,
    role: "progressbar"
  }, testIds.base.attr), __jsx(Spinner, null));
};
const ModalLoaderContainer = styled(LoaderContainer).withConfig({
  displayName: "ModalLoader__ModalLoaderContainer",
  componentId: "sc-yodomg-0"
})(["min-height:calc(100vh - 60px);display:flex;", ""], friendlyMedia.tablet`
    min-height: 50vh;
  `);