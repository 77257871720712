var __jsx = React.createElement;
import React from 'react';
import { TitleTag, Heading, Label } from '../../content/text';
import { TextContainer, HeadingContainer, ParagraphContainer, StatsContainer, ButtonContainer, StatItem } from './TextBlock.styles';
export const TextBlock = _ref => {
  let {
    id,
    label,
    heading,
    content,
    compact,
    align = 'start',
    mobileAlign,
    inverse,
    button,
    children,
    headingTagType,
    headingType = compact ? 3 : 2,
    stats
  } = _ref;
  return __jsx(TextContainer, {
    mobileAlign: mobileAlign,
    align: align
  }, label ? __jsx(TitleTag, {
    inverse: inverse,
    "data-cy": `text-block-label-${id}`
  }, label) : null, heading ? __jsx(HeadingContainer, {
    compact: compact
  }, __jsx(Heading, {
    "data-cy": `text-block-heading-${id}`,
    type: headingType,
    tagType: headingTagType,
    inverse: inverse
  }, heading)) : null, content ? __jsx(ParagraphContainer, {
    inverse: inverse
  }, content) : null, stats && __jsx(StatsContainer, null, stats.map(stat => __jsx(StatItem, {
    key: stat.label
  }, __jsx(Label, {
    inverse: inverse
  }, stat.label), __jsx(Heading, {
    type: 3,
    tagType: 3,
    inverse: inverse
  }, stat.value)))), button ? __jsx(ButtonContainer, {
    compact: compact
  }, button) : null, children);
};