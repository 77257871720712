import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useCallback } from 'react';
import { NewModal, Paragraph, useModalContext } from '@yieldstreet/ui-kit';
import { tieredPricingContent } from '@yieldstreet/tool-kit';
import exclusiveIcon from 'assets/icons/images/exclusive.svg';
import { TieredPricingModal } from '../Modal/TieredPricingModal';
import { TieredCardWrapper, BannerParagraph, LinkParagraph, ExclusiveIcon, IconWrapper } from './TieredPricingCard.style';
import { tieredTestIds } from '../../TieredPricing.model';
export const TieredPricingCard = () => {
  const {
    showModal
  } = useModalContext();
  const modalKey = `tiered-pricing-modal`;
  const handleInfoModal = useCallback(() => {
    showModal(modalKey);
  }, [showModal, modalKey]);
  return __jsx(React.Fragment, null, __jsx(TieredCardWrapper, tieredTestIds.wrapper.attr, __jsx(IconWrapper, null, __jsx(ExclusiveIcon, {
    src: exclusiveIcon
  })), __jsx(Paragraph, {
    inverse: true,
    size: "small",
    semiBold: true
  }, tieredPricingContent.cardTitle), __jsx(BannerParagraph, {
    inverse: true,
    size: "small"
  }, __jsx(LinkParagraph, _extends({
    to: "#",
    onClick: handleInfoModal
  }, tieredTestIds.learnMoreButton.attr), "Learn more"), ' ', "about reduced management fees.")), __jsx(NewModal, {
    modalId: modalKey
  }, __jsx(TieredPricingModal, null)));
};