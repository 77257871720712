var __jsx = React.createElement;
import React, { useState, Fragment, useEffect, useMemo } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { Paragraph, Markdown } from '@yieldstreet/ui-kit';
import { withViewPort } from 'hocCreators/withViewPort';
import { Wrapper, InnerWrapper, ControlWrapper, SlideControl, DesktopContentWrapper, MobileContentWrapper, MobileCarouselHeadingWrapper, MobileCarouselHeading, MobileCarouselContent, SCarousel, ControlHeading } from './CarouselSection.style';
const setActiveSlide = (setCurrentSlide, idx, setAutoplayEnabled) => {
  setCurrentSlide(idx);
  setAutoplayEnabled(false);
};
const CarouselSection = _ref => {
  let {
    section,
    viewPort
  } = _ref;
  const {
    title,
    autoplayInterval,
    content
  } = section;
  const {
    0: currentSlide,
    1: setCurrentSlide
  } = useState(0);
  const {
    0: isContainerVisible,
    1: setIsContainerVisible
  } = useState(false);
  const {
    0: isAutoplayEnabled,
    1: setAutoplayEnabled
  } = useState(true);
  useEffect(() => {
    if (!isContainerVisible && !isAutoplayEnabled) {
      setAutoplayEnabled(true);
    }
  }, [isContainerVisible, isAutoplayEnabled]);
  const renderContent = useMemo(() => content.map((slide, idx) => __jsx(SlideControl, {
    type: 5,
    key: `slide-control-${slide === null || slide === void 0 ? void 0 : slide.slideTitle}-${idx}`,
    onClick: () => setActiveSlide(setCurrentSlide, idx, setAutoplayEnabled),
    isActive: currentSlide === idx
  }, slide === null || slide === void 0 ? void 0 : slide.slideTitle)), [currentSlide, content]);
  return __jsx(VisibilitySensor, {
    partialVisibility: true,
    scrollCheck: true,
    onChange: isVisible => setIsContainerVisible(isVisible)
  }, __jsx(Wrapper, {
    "data-cy": "json-pages-section-carousel-wrapper"
  }, __jsx(InnerWrapper, null, viewPort !== 'mobile' && __jsx(ControlWrapper, {
    "data-cy": "json-pages-section-carousel-control-wrapper"
  }, __jsx(ControlHeading, {
    type: 3,
    "data-cy": "json-pages-section-carousel-control-title"
  }, title), renderContent), __jsx(SCarousel, {
    "data-cy": "json-pages-section-carousel-slide-wrapper",
    slideIndex: currentSlide,
    afterSlide: slideIndex => setCurrentSlide(slideIndex),
    wrapAround: true,
    dragging: true,
    slidesToShow: 1,
    framePadding: "10px",
    cellSpacing: 400,
    cellAlign: "center",
    heightMode: "current",
    initialSlideHeight: 400,
    autoplay: isAutoplayEnabled,
    autoplayInterval: autoplayInterval ? autoplayInterval : 60000,
    vertical: viewPort !== 'mobile',
    disableAnimation: true
  }, content.map((slide, idx) => {
    const {
      slideTitle,
      slideText
    } = slide;
    return __jsx(Fragment, {
      key: `slide-content-${slideTitle}-${idx}`
    }, viewPort !== 'mobile' && __jsx(DesktopContentWrapper, {
      "data-cy": "json-pages-section-carousel-slide-content-desktop"
    }, __jsx(Paragraph, null, __jsx(Markdown, {
      source: slideText
    }))), viewPort === 'mobile' && __jsx(MobileContentWrapper, {
      "data-cy": "json-pages-section-carousel-slide-content-mobile"
    }, __jsx(MobileCarouselHeadingWrapper, null, __jsx(MobileCarouselHeading, {
      type: 4
    }, slideTitle)), __jsx(MobileCarouselContent, null, __jsx(Markdown, {
      parserOptions: {
        commonmark: true
      },
      source: slideText,
      linkTarget: "_blank"
    }))));
  })))));
};
export default withViewPort(CarouselSection);