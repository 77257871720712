import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["value", "active", "withBorder", "label", "inline", "fullWidth", "maxWidth", "responsive", "testId", "type"];
var __jsx = React.createElement;
import React from 'react';
import { LayoutGroup } from 'framer-motion';
import arrow from '../../assets/graphics/icons/arrow.svg';
import { mapToTestId } from './DropDown.model';
import { Field, FieldMain, FieldLabel, FieldValue, FieldLayoutGroup, FieldIcon, fieldValueAnimation, fieldIconAnimation } from './DropDown.style';
export const DropDown = _ref => {
  let {
      value,
      active,
      withBorder,
      label,
      inline,
      fullWidth,
      maxWidth,
      responsive,
      testId,
      type
    } = _ref,
    rest = _objectWithoutProperties(_ref, _excluded);
  const testIds = mapToTestId(testId);
  return __jsx(Field, _extends({
    type: type,
    active: active,
    withBorder: withBorder,
    fullWidth: fullWidth,
    responsive: responsive
  }, testIds.base.attr, rest), __jsx(FieldMain, {
    fullWidth: fullWidth,
    maxWidth: maxWidth
  }, __jsx(LayoutGroup, null, __jsx(FieldLayoutGroup, {
    inline: inline
  }, __jsx(FieldLabel, testIds.label.attr, label, value ? ': ' : ''), value && __jsx(FieldValue, _extends({}, fieldValueAnimation(inline), testIds.value.attr), ` ${value}`)))), __jsx(FieldIcon, _extends({
    src: arrow
  }, fieldIconAnimation(active), testIds.icon.attr)));
};