import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useCallback, useEffect, useState } from 'react';
import { Formik } from 'formik';
import { BusinessEvent, eventManager, useApiErrorMessage } from '@yieldstreet/tool-kit';
import { Button, FormTextInput, Paragraph, Heading, Label } from '@yieldstreet/ui-kit';
import { twoFactorAuthApi, User2FAMethod } from '@yieldstreet/platform-kit';
import SecondsCountdown from 'sharedComponents/SecondsCountdown';
import { useTwoFaStatus } from 'sharedComponents/TwoFaModal/hooks/useTwoFaStatus';
import { TWOFA_STEPS } from 'sharedComponents/TwoFaModal/TwoFaModalContainer.model';
import twoFAIcon from '../../../assets/Setup.svg';
import { Icon, ParagraphWrapper, SupportLink } from '../../../TwoFaModal.style';
import { TwoFASchema, testIds, tryAgain } from './ConfirmationStep.model';
import { Asterisk, SForm, SLabelError, SButtonContainer, Separator, ActionsWrapper, AnchorButton, SLink } from './ConfirmationStep.style';
export const getPhoneText = lastFourPhoneDigits => __jsx(React.Fragment, null, "Please enter the verification code we sent to ", __jsx(Asterisk, null), lastFourPhoneDigits, ".", __jsx("br", null), "It may take a few minutes to receive the code.");
const {
  useConfirmMutation,
  useResendMutation
} = twoFactorAuthApi;
export const ConfirmationStep = _ref => {
  let {
    method,
    setStep
  } = _ref;
  const {
    phone
  } = useTwoFaStatus();
  const {
    0: resent,
    1: setResent
  } = useState(false);
  const lastFourPhoneDigits = (phone === null || phone === void 0 ? void 0 : phone.slice(-4)) || '';
  const [confirm, confirmResult] = useConfirmMutation();
  const [resend] = useResendMutation();
  const confirmError = useApiErrorMessage(confirmResult);
  useEffect(() => {
    if (confirmResult.isSuccess) {
      setStep(TWOFA_STEPS.SUCCESS);
      eventManager.track(BusinessEvent.USER_LOGIN_VERIFY_2FA, {
        authMethod: method
      });
    }
  }, [setStep, method, confirmResult]);
  useEffect(() => {
    if (confirmError) {
      eventManager.trackFailure(BusinessEvent.USER_LOGIN_VERIFY_2FA, {
        error: confirmError,
        authMethod: method
      });
    }
  }, [confirmError, method]);
  const handleResend = useCallback(() => {
    setResent(true);
    resend({});
  }, [resend]);
  return __jsx(React.Fragment, null, __jsx(Icon, _extends({
    src: twoFAIcon
  }, testIds.icon.attr)), __jsx(Heading, _extends({
    type: 4
  }, testIds.title.attr), "Confirm your verification code"), __jsx(ParagraphWrapper, null, __jsx(Paragraph, testIds.description.attr, method === User2FAMethod.TEXT_MESSAGE ? getPhoneText(lastFourPhoneDigits) : 'Open the Authy Authenticator app and enter the Yieldstreet verification code below.')), __jsx(Formik, {
    key: "2FAForm",
    enableReinitialize: true,
    initialValues: {
      twoFACode: ''
    },
    onSubmit: values => {
      confirm({
        token: values.twoFACode
      });
    },
    validationSchema: TwoFASchema
  }, formikProps => __jsx(SForm, null, __jsx(FormTextInput, {
    name: "twoFACode",
    label: "Enter 6-digit code",
    autoComplete: "off",
    fullWidth: true,
    maxLength: 6,
    width: 200,
    formikProps: formikProps,
    testId: testIds.codeField.id
  }), confirmError && __jsx(SLabelError, null, confirmError), __jsx(SButtonContainer, null, __jsx(Button, _extends({}, testIds.button.attr, {
    loading: confirmResult.isLoading,
    type: "submit"
  }), "Confirm verification")))), __jsx(Separator, null), __jsx(ActionsWrapper, null, resent && __jsx(Label, _extends({}, testIds.countdown.attr, {
    small: true
  }), `${tryAgain} `, __jsx(SecondsCountdown, {
    seconds: 30,
    onComplete: () => setResent(false)
  }), "."), method === User2FAMethod.TEXT_MESSAGE && !resent && __jsx(AnchorButton, _extends({
    onClick: handleResend
  }, testIds.resendAnchor.attr), __jsx(SLink, {
    to: "#"
  }, "Resend code")), __jsx(SupportLink, _extends({
    to: "#",
    id: "intercom-link"
  }, testIds.intercomLink.attr), "Contact support")));
};