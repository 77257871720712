import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _defineProperty from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
const logEvent = '☰ TRACK EVENT';
const logEventFailure = `${logEvent} FAILURE`;
class EventManager {
  constructor() {
    _defineProperty(this, "debugMode", false);
    _defineProperty(this, "globalProps", void 0);
    _defineProperty(this, "onTrack", void 0);
    _defineProperty(this, "init", (onTrack, globalProps, debugMode) => {
      this.onTrack = onTrack;
      this.debugMode = debugMode || false;
      this.globalProps = globalProps;
    });
    _defineProperty(this, "log", (event, props, failure) => {
      const log = failure ? logEventFailure : logEvent;
      // eslint-disable-next-line no-console
      this.debugMode && console.info(log, event, props);
    });
    _defineProperty(this, "track", (event, props) => {
      const eventProps = _objectSpread(_objectSpread({}, this.globalProps || {}), props);
      this.log(event, eventProps);
      this.onTrack && this.onTrack(event, eventProps);
    });
    _defineProperty(this, "trackFailure", (event, props) => {
      const eventName = `${event}.failed`;
      const eventProps = _objectSpread(_objectSpread({}, this.globalProps || {}), props);
      this.log(eventName, eventProps, true);
      this.onTrack && this.onTrack(eventName, eventProps);
    });
  }
}
export const eventManager = new EventManager();