import { DefaultBankScreenType, DefaultBankButtonAction } from '../../DefaultBank.model';
export const getModalContent = _ref => {
  let {
    bankName,
    type
  } = _ref;
  switch (type) {
    case DefaultBankScreenType.DefaultBank:
      return {
        title: 'Preferred bank',
        description: `The linked bank account ${bankName} is now set as your preferred destination for future payments. Any failed payments will also be automatically retried and sent to your preferred bank account. To change your preferred destination, please link a new bank account.`,
        testId: DefaultBankScreenType.DefaultBank,
        type: DefaultBankScreenType.DefaultBank,
        buttons: [{
          buttonType: 'primary',
          type: 'button',
          label: 'Continue',
          testId: 'Continue',
          action: DefaultBankButtonAction.DEFAULT_BANK_CONTINUE
        }, {
          buttonType: 'secondary',
          type: 'button',
          label: 'Link another bank',
          testId: 'Link another bank',
          action: DefaultBankButtonAction.DEFAULT_BANK_LINK_ANOTHER_BANK
        }]
      };
    case DefaultBankScreenType.ChangeDefaultBank:
      return {
        title: 'Update preferred bank account',
        description: `Your current preferred bank account for receiving future payments is shown below. You can update your preferred bank account now or at any time in the future.`,
        note: `Please note that your selection cannot be applied to payments that are already in progress.`,
        testId: DefaultBankScreenType.ChangeDefaultBank,
        type: DefaultBankScreenType.ChangeDefaultBank,
        buttons: [{
          buttonType: 'primary',
          type: 'submit',
          label: 'Continue',
          testId: 'Continue'
        }]
      };
    case DefaultBankScreenType.SetDefaultBank:
      return {
        title: 'Select preferred bank account',
        description: 'Choose the bank account where you want to receive future payments. Please note that your selection cannot be applied to payments that are already in progress.',
        testId: DefaultBankScreenType.SetDefaultBank,
        type: DefaultBankScreenType.SetDefaultBank,
        buttons: [{
          buttonType: 'primary',
          type: 'submit',
          label: 'Continue',
          testId: 'Continue'
        }]
      };
    default:
      break;
  }
};