import { parse } from 'query-string';
import { AccreditationStatus, SegmentSpecEvent, eventManager, getTrackingMeta } from '@yieldstreet/tool-kit';
import { setTaboola, setBingAds, setYahooGemini, setPodSights, setSegmentTrack, setQuora, setNextdoor } from 'utils/thirdPartyCode';
import { SIGNUP_TRACKING_REDIRECTS, SIGNUP_TRACKING_META } from './useAuth.model';
export const getLocationRedirect = location => {
  const searchParams = parse(location.search);
  if (searchParams.redirect) {
    return searchParams.redirect + location.hash;
  }
  return null;
};
export const getTrackingRedirect = () => {
  const trackingMeta = getTrackingMeta(SIGNUP_TRACKING_META);
  const redirectConfig = SIGNUP_TRACKING_REDIRECTS.find(_ref => {
    let {
      metaKey,
      metaValue
    } = _ref;
    return trackingMeta.find(_ref2 => {
      let {
        key,
        value
      } = _ref2;
      return metaKey === key && (metaValue && metaValue === value || !metaValue);
    });
  });
  return redirectConfig === null || redirectConfig === void 0 ? void 0 : redirectConfig.redirectUrl;
};
export const trackLoginComplete = () => {
  eventManager.track(SegmentSpecEvent.SIGNED_IN, {});
};
export const trackSignupComplete = _ref3 => {
  let {
    accreditedSignUp,
    hashedId
  } = _ref3;
  const type = accreditedSignUp ? AccreditationStatus.ACCREDITED : AccreditationStatus.NON_ACCREDITED;

  // User hashedId from the userState is being used as a unique identifier
  // for this signup transaction to be used when Segment destinations
  // (e.g. Google Enhanced Conversions) required this identifier dedupe
  // events being sent both on the client side and on the server side
  eventManager.track(SegmentSpecEvent.SIGNED_UP, {
    type,
    orderId: hashedId
  });
  if (accreditedSignUp) {
    eventManager.track(SegmentSpecEvent.SIGNED_UP_ACCREDITED, {
      orderId: hashedId
    });
  }
  if (accreditedSignUp) {
    // Pixel events for ONLY accredited leads

    setTaboola({
      notify: 'event',
      name: 'AccreditedLead',
      id: 1048846
    });
    setBingAds({
      eventAction: 'Accredited Signup'
    });
    setYahooGemini({
      event: {
        projectId: '10000',
        properties: {
          pixelId: '10048246',
          qstrings: {
            ea: 'accreditedsignup',
            et: 'custom'
          }
        }
      }
    });
    setPodSights({
      eventName: 'product'
    });
    setNextdoor({
      type: 'track',
      event: 'CONVERSION'
    });
  }

  // Pixel events for ALL leads

  setTaboola({
    notify: 'event',
    name: 'lead',
    id: 1048846
  });
  setBingAds({
    eventAction: 'Signup'
  });
  setYahooGemini({
    event: {
      projectId: '10000',
      properties: {
        pixelId: '10048246',
        qstrings: {
          ea: 'allsignup',
          et: 'custom'
        }
      }
    }
  });
  setQuora({
    type: 'track',
    eventName: 'CompleteRegistration'
  });
  setPodSights({
    eventName: 'lead',
    extraConfig: {
      type
    }
  });
  setSegmentTrack({
    event: accreditedSignUp ? 'ai.user.signup.completed' : 'nai.user.signup.completed'
  });
};