import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useMemo } from 'react';
import { allToken, InvestorEntityTypeName, investorEntityProviderMigrationApi } from '@yieldstreet/platform-kit';
import { skipToken } from '@reduxjs/toolkit/query';
import { isInvestorEntityStatusActionable, useAllInvestorEntities, useInvestorEntityStatusPollCheck } from '../../hooks';
import keyBy from "lodash/keyBy";
import { getMigrationStatus } from './useProviderMigration.utils';
import { statusTypes } from './useProviderMigration.model';
import { useApiQueryPollCheck } from '../../async-effects';
import { useAuth } from '../../auth';
import { useFlags } from '../../flags';
export const useProviderMigration = function () {
  let {
    checkInvestorKYCMigrated,
    checkInvestorBankLinkMigrated
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const {
    isAuthenticated
  } = useAuth();
  const {
    genieMigration,
    genieMigrationBusiness
  } = useFlags({
    genieMigration: false,
    genieMigrationBusiness: false
  });
  const token = isAuthenticated && (genieMigration || genieMigrationBusiness) ? allToken : skipToken;
  const {
    isLoading: isInvestorEntitesLoading,
    investorEntities,
    investorEntityStatuses
  } = useAllInvestorEntities();
  const getKYCProviderMigrationState = investorEntityProviderMigrationApi.useGetKYCProviderMigrationStatusQuery(token);
  const getBankLinkingProviderMigrationState = investorEntityProviderMigrationApi.useGetBankLinkProviderMigrationStatusQuery(token);
  const {
    refetch: refetchKYCStatus
  } = getKYCProviderMigrationState;
  const {
    refetch: refetchBankLinkStatus
  } = getBankLinkingProviderMigrationState;

  // in case any of checkInvestorKYCMigrated or checkInvestorBankLinkMigrated are set
  // start a poll check on the investor entity status endpoint until the identity status or
  // the bank account status for that investor entity is non-pending (depending on which was set)
  const {
    isLoading: isInvestorEntityStatusLoading
  } = useInvestorEntityStatusPollCheck(checkInvestorKYCMigrated || checkInvestorBankLinkMigrated, _ref => {
    let {
      identity,
      bankAccounts
    } = _ref;
    return (!checkInvestorKYCMigrated || !!checkInvestorKYCMigrated && !isInvestorEntityStatusActionable(identity)) && (!checkInvestorBankLinkMigrated || !!checkInvestorBankLinkMigrated && bankAccounts && !isInvestorEntityStatusActionable(bankAccounts));
  }, !checkInvestorKYCMigrated && !checkInvestorBankLinkMigrated);

  // in case checkInvestorKYCMigrated is set start a KYC migration poll check
  // until the Unit application status of that investor entity is non-pending
  // which would mean that KYC migration is either completed or not actionable
  const {
    isLoading: isKYCMigrationLoading,
    data: {
      eligibleInvestorEntities: kycInvestorEntities = []
    } = {}
  } = useApiQueryPollCheck(getKYCProviderMigrationState, _ref2 => {
    let {
      eligibleInvestorEntities
    } = _ref2;
    const {
      identity
    } = (eligibleInvestorEntities === null || eligibleInvestorEntities === void 0 ? void 0 : eligibleInvestorEntities.find(_ref3 => {
      let {
        investorEntityId
      } = _ref3;
      return checkInvestorKYCMigrated === investorEntityId;
    })) || {};
    return !!identity && !isInvestorEntityStatusActionable(identity);
  }, !checkInvestorKYCMigrated);

  // in case checkInvestorBankLinkMigrated is set start a bank link migration poll check
  // until at least one Unit linked bank account if found for that investor entity
  // which would mean that bank linking migration is completed
  const {
    isLoading: isBankLinkMigrationLoading,
    data: {
      eligibleInvestorEntities: bankLinkInvestorEntities = []
    } = {}
  } = useApiQueryPollCheck(getBankLinkingProviderMigrationState, _ref4 => {
    let {
      eligibleInvestorEntities
    } = _ref4;
    const {
      plaidLinkedBankAccounts,
      defaultBankAccount
    } = (eligibleInvestorEntities === null || eligibleInvestorEntities === void 0 ? void 0 : eligibleInvestorEntities.find(_ref5 => {
      let {
        investorEntityId
      } = _ref5;
      return checkInvestorBankLinkMigrated === investorEntityId;
    })) || {};
    return !!plaidLinkedBankAccounts && !!defaultBankAccount;
  }, !checkInvestorBankLinkMigrated);
  const kycInvestorDetails = useMemo(() => keyBy(kycInvestorEntities, kycInvestorEntity => kycInvestorEntity.investorEntityId), [kycInvestorEntities]);
  const bankLinkInvestorDetails = useMemo(() => keyBy(bankLinkInvestorEntities, kycInvestorEntity => kycInvestorEntity.investorEntityId), [bankLinkInvestorEntities]);
  const isLoading = isInvestorEntitesLoading || isKYCMigrationLoading || isBankLinkMigrationLoading || isInvestorEntityStatusLoading || (investorEntities === null || investorEntities === void 0 ? void 0 : investorEntities.length) !== Object.keys(investorEntityStatuses || {}).length;
  const migrationInvestorEntities = useMemo(() => {
    if (!isLoading && investorEntities && investorEntityStatuses && kycInvestorDetails && bankLinkInvestorDetails) {
      return investorEntities.map(investorEntity => {
        const {
          id,
          name,
          type: {
            name: type
          }
        } = investorEntity;
        const investorEntityStatus = investorEntityStatuses[id];
        const kycDetails = kycInvestorDetails[id];
        const bankLinkDetails = bankLinkInvestorDetails[id];
        const status = getMigrationStatus(investorEntity, investorEntityStatus, kycDetails, bankLinkDetails);
        const ongoing = statusTypes.ongoing.includes(status);
        const actionable = statusTypes.actionable.includes(status);
        const eligible = statusTypes.eligible.includes(status);
        const final = statusTypes.final.includes(status);
        const pending = statusTypes.pending.includes(status);
        const problem = statusTypes.problem.includes(status);
        return {
          id: id.toString(),
          name,
          type: InvestorEntityTypeName[type],
          status,
          ongoing,
          actionable,
          eligible,
          problem,
          pending,
          final,
          kycDetails,
          bankLinkDetails
        };
      });
    }
  }, [isLoading, investorEntities, investorEntityStatuses, kycInvestorDetails, bankLinkInvestorDetails]);
  const migrationState = useMemo(() => {
    // true if any investor entity's migration is started but not completed yet
    let isMigrationOngoing = false;
    // true if any investor entity's migration is in a user actionable state
    let isMigrationActionable = false;
    // true if any investor entity is eligible for migration (both completed or not)
    let isMigrationEligible = false;
    // true if any investor entity has either a failed KYC or an error
    let isMigrationProblem = false;
    // true if any investor entity has a pending micro deposit
    let isMigrationPending = false;
    // true if all investor entities have either completed migration or do not need it
    let isMigrationFinal = true;
    migrationInvestorEntities === null || migrationInvestorEntities === void 0 ? void 0 : migrationInvestorEntities.forEach(_ref6 => {
      let {
        ongoing,
        actionable,
        eligible,
        problem,
        pending,
        final
      } = _ref6;
      isMigrationOngoing = ongoing || isMigrationOngoing;
      isMigrationActionable = actionable || isMigrationActionable;
      isMigrationEligible = eligible || isMigrationEligible;
      isMigrationProblem = problem || isMigrationProblem;
      isMigrationPending = pending || isMigrationPending;
      isMigrationFinal = !final ? final : isMigrationFinal;
    });
    return {
      isMigrationActionable,
      isMigrationEligible,
      isMigrationFinal,
      isMigrationProblem,
      isMigrationPending
    };
  }, [migrationInvestorEntities]);
  return _objectSpread(_objectSpread({
    migrationInvestorEntities,
    isLoading
  }, migrationState), {}, {
    refetchKYCStatus,
    refetchBankLinkStatus,
    bankLinkInvestors: bankLinkInvestorDetails
  });
};