import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
export const transformAllInvestorEntityStatusResponse = response => {
  var _response$investorEnt;
  return (_response$investorEnt = response.investorEntities) === null || _response$investorEnt === void 0 ? void 0 : _response$investorEnt.reduce((accountStatus, _ref) => {
    let {
      id,
      status,
      type,
      typeDescription
    } = _ref;
    return _objectSpread(_objectSpread({}, accountStatus), {}, {
      [id]: _objectSpread(_objectSpread({}, status), {}, {
        id,
        type,
        typeDescription
      })
    });
  }, {});
};