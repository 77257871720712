export const CapitalCallFAQ = [{
  question: 'What is a capital call?',
  answer: ['A capital call is the process by which a fund manager asks the fund investors to contribute their pro rata portion of their fund commitments.', `A capital call is how a GP collects capital from their fund's LPs. GPs make a capital call when the fund needs more money. Capital calls usually happen when a fund plans to make a new investment or needs to pay expenses.`]
}, {
  question: 'How does it work?',
  answer: [`Say you commit $100k to a $100M fund. The LPA states that the initial drawdown is 30%—that is, you must contribute $30k to the fund now and hold onto your remaining $70k until it's called. The GP invests the initial $30M drawdown from all LPs in several early-stage companies.`, `Six months after the initial drawdown, the GP decides to call another $20M. Since that’s 20% of the fund’s committed capital, LPs must send 20% of their initial committed capital within ten days (or whatever time frame the LPA specifies) of the capital call notice. Because you committed $100k, you must send $20k.`, `Your uncalled capital now stands at $50k.`]
}, {
  question: 'How often will you issue a capital call?',
  answer: ['The simple answer is that GPs call capital whenever they think the fund needs it. However, the GP is likely to take into account additional considerations when timing capital calls.']
}, {
  question: 'Why does this benefit me?',
  answer: ['As an investor, you will be able to commit to an investment but not be required to fund the entirety of the allocation right away. This gives you flexibility to invest your capital elsewhere until it is called.']
}, {
  question: 'What happens if I can’t fulfill a capital call?',
  answer: ['An investor who is unable to fulfill a capital call will be considered in default and is subject to potential penalties.']
}];