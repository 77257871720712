import { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import isArray from "lodash/isArray";
import isNumber from "lodash/isNumber";
import isString from "lodash/isString";
import { extractParams } from './useQuery.util';
export const useQuery = paramKeys => {
  var _history$location;
  const history = useHistory();
  const search = history === null || history === void 0 ? void 0 : (_history$location = history.location) === null || _history$location === void 0 ? void 0 : _history$location.search;

  /**
   * Create a new search param object when search value changes.
   */
  const urlSearchParams = useMemo(() => new URLSearchParams(search), [search]);

  /**
   * Applies the current url search object to the url.
   */
  const navigateWithQuery = useCallback(() => {
    const currentQuery = urlSearchParams.toString();
    history.push(`${history.location.pathname}?${currentQuery}`);
  }, [history, urlSearchParams]);

  /**
   * Extract target params.
   */
  const queryParams = useMemo(() => isArray(paramKeys) ? extractParams(paramKeys, urlSearchParams) : {}, [paramKeys, urlSearchParams]);

  /**
   * Remove multiple query params from array of param keys.
   */
  const removeQueryParams = useCallback(params => {
    if (!isArray(params)) {
      return;
    }
    params.forEach(param => {
      if (isString(param)) {
        urlSearchParams.delete(param);
      }
    });
  }, [urlSearchParams]);

  /**
   * Add multiple query params from array of key/value pairs.
   */
  const setQueryParams = useCallback(params => {
    if (!isArray(params)) {
      return;
    }
    params.forEach(_ref => {
      let {
        key,
        value
      } = _ref;
      if (isString(key) && (isString(value) || isNumber(value))) {
        urlSearchParams.set(key, value);
      }
    });
  }, [urlSearchParams]);
  return {
    navigateWithQuery,
    queryParams,
    removeQueryParams,
    setQueryParams,
    urlSearchParams
  };
};