var __jsx = React.createElement;
import React from 'react';
import { PortfolioConfig } from '@yieldstreet/tool-kit';
import { NewestModal, PortfolioSimulator } from '@yieldstreet/ui-kit';
import { Modals } from 'constants/modal.model';
export const PortfolioSimulatorModal = _ref => {
  let {
    onClose
  } = _ref;
  return __jsx(NewestModal, {
    modalId: Modals.PortfolioSimulator,
    onClose: onClose
  }, __jsx(PortfolioSimulator, {
    portfolioConfig: PortfolioConfig,
    withPadding: true
  }));
};