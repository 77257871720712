var __jsx = React.createElement;
import React, { useCallback, useMemo, useState } from 'react';
import { useModalContext, ModalContainer, ModalLoader } from '@yieldstreet/ui-kit';
import { useBankAccounts } from 'pages/investorEntity/screens/Management/routes/BankAccounts/hooks/useBankAccounts';
import { usePlaidBankAccountModal } from 'pages/accountSettings/NewBankAccount/plaidBankAccount/usePlaidBankAccountModal';
import { BankLinkingIntro } from './components';
import { testIds } from './BankLinking.model';
import { BankLinkingWrapper } from './BankLinking.style';
export const BankLinking = _ref => {
  let {
    investorEntityId,
    onLinkSuccess
  } = _ref;
  const {
    hideModal
  } = useModalContext();
  const {
    isEntityBankAccountsLoading
  } = useBankAccounts();
  const {
    0: isLinking,
    1: setIsLinking
  } = useState(false);
  const onCloseCallback = useCallback(() => {
    setIsLinking(false);
    hideModal();
  }, [hideModal]);
  const onLinkSuccessCallback = useCallback(() => {
    setIsLinking(false);
    onLinkSuccess();
  }, [onLinkSuccess]);
  const {
    ready: isPlaidReady,
    open: openPlaidModal
  } = usePlaidBankAccountModal({
    investorEntityId,
    onSuccess: onLinkSuccessCallback,
    onClose: onCloseCallback
  });
  const onIntroClick = () => {
    setIsLinking(true);
    openPlaidModal();
  };
  const isLoading = useMemo(() => !isPlaidReady || isEntityBankAccountsLoading, [isPlaidReady, isEntityBankAccountsLoading]);
  const isLinkable = useMemo(() => isPlaidReady && !isLoading, [isPlaidReady, isLoading]);
  return __jsx(BankLinkingWrapper, null, __jsx(ModalContainer, null, isLoading && __jsx(ModalLoader, {
    testId: testIds.loadingView.id
  }), isLinkable && __jsx(BankLinkingIntro, {
    isReady: isPlaidReady && !isLinking,
    onClick: onIntroClick
  })));
};