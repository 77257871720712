import isEmpty from "lodash/isEmpty";
import isFunction from "lodash/isFunction";
import qs from 'qs';
export const getPortalHostNode = hostNode => {
  const isBrowser = typeof document !== 'undefined';

  /**
   * Used for testing.
   *
   * If a hostNode is provided, append the host to the body of the current
   * rendering context and create portals into that element.
   */
  if (hostNode) {
    if (isBrowser) {
      document.body.appendChild(hostNode);
    }
    return hostNode;
  }
  return isBrowser && document.getElementById('modal-root');
};
export const handleDocumentOverflow = currentModal => {
  if (!document) {
    return;
  }
  const scrollState = currentModal ? 'hidden' : 'auto';
  document.body.style.overflow = scrollState;
};
export const handleOpenModalNavigation = _ref => {
  let {
    history,
    modalId,
    props
  } = _ref;
  let url = `${history.location.pathname}${history.location.search}#${modalId}`;
  if (!isEmpty(props)) {
    url = `${url}?${qs.stringify(props)}`;
  }
  history.push(url);
};
export const handleCloseModalNavigation = history => {
  history.push(`${history.location.pathname}${history.location.search}`);
};
export const handleOnCloseCallback = _ref2 => {
  var _registeredModals$mod;
  let {
    modalId,
    registeredModals
  } = _ref2;
  const onCloseCallback = (_registeredModals$mod = registeredModals[modalId]) === null || _registeredModals$mod === void 0 ? void 0 : _registeredModals$mod.onClose;
  if (isFunction(onCloseCallback)) {
    onCloseCallback();
  }
};
export const handleOnSuccessCallback = _ref3 => {
  var _registeredModals$mod2;
  let {
    modalId,
    registeredModals
  } = _ref3;
  const onSuccessCallback = (_registeredModals$mod2 = registeredModals[modalId]) === null || _registeredModals$mod2 === void 0 ? void 0 : _registeredModals$mod2.onSuccess;
  if (isFunction(onSuccessCallback)) {
    onSuccessCallback();
  }
};