/**
 * Returns url for investments with action items.
 */
export const getRolloverInvestmentUrl = _ref => {
  let {
    record
  } = _ref;
  if (!record || !(record !== null && record !== void 0 && record.allowRollover)) {
    return '';
  }
  return `/rollover/${record === null || record === void 0 ? void 0 : record.noteUrlHash}/${record === null || record === void 0 ? void 0 : record.investorAccountId}`;
};