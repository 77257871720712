var __jsx = React.createElement;
import React from 'react';
import { CloseButton } from '../CloseButton/CloseButton';
import { Overlay } from '../Overlay/Overlay';
import { Content, Modal, ModalWrapper } from './Modal.style';
import { MODAL_BODY, MODAL_CLOSE_BUTTON, MODAL_CONTENT, MODAL_OVERLAY } from './Modal.model';
export const NewestModal = _ref => {
  let {
    align,
    children,
    dataCy,
    disableCloseOnClickOutside = false,
    hideCloseButton = false,
    modalId,
    onClose = () => {}
  } = _ref;
  const overlayDataCy = `${dataCy || modalId}-${MODAL_OVERLAY}`;
  const modalDataCy = `${dataCy || modalId}-${MODAL_BODY}`;
  const contentDataCy = `${dataCy || modalId}-${MODAL_CONTENT}`;
  const closeButtonDataCy = `${dataCy || modalId}-${MODAL_CLOSE_BUTTON}`;
  return __jsx(ModalWrapper, {
    id: modalId || 'global-modal',
    align: align
  }, __jsx(Overlay, {
    "data-cy": overlayDataCy,
    onClick: disableCloseOnClickOutside ? undefined : onClose
  }), __jsx(Modal, {
    "data-cy": modalDataCy
  }, __jsx(Content, {
    "data-cy": contentDataCy,
    className: "modal-content"
  }, children, hideCloseButton ? null : __jsx(CloseButton, {
    "data-cy": closeButtonDataCy,
    onClick: onClose
  }))));
};