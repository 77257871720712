import { HttpMethods } from '../../../enums';
import { Tags, restApi } from '../../common';
export const emailVerifyApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.UserState]
}).injectEndpoints({
  endpoints: builder => {
    const emailVerify = builder.mutation({
      query: _ref => {
        let {
          verification_code
        } = _ref;
        return {
          url: '/a/api/user/emailVerify',
          method: HttpMethods.Post,
          data: {
            verification_code
          }
        };
      },
      invalidatesTags: [Tags.UserState]
    });
    return {
      emailVerify
    };
  }
});