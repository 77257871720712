export const penscoServiceFundResponseMock = {
  message: 'Success',
  success: true,
  result: {
    currentPage: 1,
    totalCount: 0,
    totalPages: 0,
    items: [{
      clearDate: 1567083600000,
      date: 'August 29, 2019',
      destName: 'destName',
      effectiveDate: 1517407200000,
      interest: null,
      investmentPending: null,
      investorAccountId: 77017,
      loanId: 2541,
      noteUrlHash: 'noteUrlHash',
      principal: null,
      processDate: 'August 28, 2019',
      sourceName: 'sourceName',
      totalAmount: 10.0,
      transactionType: 'FUND_EXPENSES',
      transactionTypeDisplayName: 'transaction type',
      transferDate: 'January 31, 2018'
    }, {
      clearDate: 1567083600000,
      date: 'August 29, 2019',
      destName: 'destName',
      effectiveDate: 1517407200000,
      interest: null,
      investmentPending: null,
      investorAccountId: 77017,
      loanId: 2541,
      noteUrlHash: 'noteUrlHash',
      principal: null,
      processDate: 'August 28, 2019',
      sourceName: 'sourceName',
      totalAmount: 10.0,
      transactionType: 'INVESTMENT',
      transactionTypeDisplayName: 'transaction type',
      transferDate: 'January 31, 2018'
    }, {
      clearDate: 1567083600000,
      date: 'August 29, 2019',
      destName: 'destName',
      effectiveDate: 1517407200000,
      interest: null,
      investmentPending: null,
      investorAccountId: 77017,
      loanId: 2541,
      noteUrlHash: 'noteUrlHash',
      principal: null,
      processDate: 'August 28, 2019',
      sourceName: 'sourceName',
      totalAmount: 10.0,
      transactionType: 'PAYMENT',
      transactionTypeDisplayName: 'transaction type',
      transferDate: 'January 31, 2018'
    }, {
      clearDate: 1567083600000,
      date: 'August 29, 2019',
      destName: 'destName',
      effectiveDate: 1517407200000,
      interest: null,
      investmentPending: null,
      investorAccountId: 77017,
      loanId: 2541,
      noteUrlHash: 'noteUrlHash',
      principal: null,
      processDate: 'August 28, 2019',
      sourceName: 'sourceName',
      totalAmount: 10.0,
      transactionType: 'DEPOSIT',
      transactionTypeDisplayName: 'transaction type',
      transferDate: 'January 31, 2018'
    }, {
      clearDate: 1567083600000,
      date: 'August 29, 2019',
      destName: 'destName',
      effectiveDate: 1517407200000,
      interest: null,
      investmentPending: null,
      investorAccountId: 77017,
      loanId: 2541,
      noteUrlHash: 'noteUrlHash',
      principal: null,
      processDate: 'August 28, 2019',
      sourceName: 'sourceName',
      totalAmount: 10.0,
      transactionType: 'WITHDRAWAL',
      transactionTypeDisplayName: 'transaction type',
      transferDate: 'January 31, 2018'
    }]
  }
};