import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { HttpMethods } from '../../../enums';
import { restApi, Tags } from '../../common';
export const statementsApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.PortfolioStatements, Tags.PortfolioStatementsProviders]
}).injectEndpoints({
  endpoints: build => {
    const getStatement = build.query({
      query: _ref => {
        let {
          rangeType,
          rangeValue,
          userId,
          year,
          provider,
          investorAccountId
        } = _ref;
        return {
          url: `/a/api/portfolios/${userId}/statements`,
          method: HttpMethods.Get,
          params: {
            rangeType,
            rangeValue,
            year,
            provider,
            investorAccountId
          }
        };
      },
      providesTags: [Tags.PortfolioStatements],
      transformResponse: _ref2 => {
        let {
          statement
        } = _ref2;
        return _objectSpread({}, statement);
      }
    });
    const getStatementProviders = build.query({
      query: _ref3 => {
        let {
          userId,
          managementStrategy,
          investorAccountId
        } = _ref3;
        return {
          url: `/a/api/user/${userId}/reports/provider-list`,
          method: HttpMethods.Get,
          params: {
            investorAccountId,
            managementStrategy
          }
        };
      },
      providesTags: [Tags.PortfolioStatementsProviders]
    });
    const getSynapseStatement = build.query({
      query: _ref4 => {
        let {
          investorAccountId,
          yearMonth
        } = _ref4;
        return {
          url: `/a/api/managed/portfolio/statement/synapse/${investorAccountId}/${yearMonth}`,
          method: HttpMethods.Get,
          responseType: 'blob'
        };
      }
    });
    return {
      getStatement,
      getStatementProviders,
      getSynapseStatement
    };
  }
});