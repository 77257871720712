var __jsx = React.createElement;
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { BusinessEvent, ContentCardsPlaceholderPlatform, eventManager } from '@yieldstreet/tool-kit';
import ENV_CONFIG from 'utils/env/config';
import { useUserState } from 'utils/hooks';
import { ContentCardsContext } from './ContentCardsContext';
export const ContentCardsProvider = _ref => {
  let {
    children
  } = _ref;
  const {
    0: contentCards,
    1: setContentCards
  } = useState([]);
  const {
    0: isSdkReady,
    1: setIsSdkReady
  } = useState(false);
  const sdk = useRef();
  const userState = useUserState();
  const subscribeToContentCards = useCallback(() => {
    var _sdk$current;
    return (_sdk$current = sdk.current) === null || _sdk$current === void 0 ? void 0 : _sdk$current.subscribeToContentCardsUpdates(_ref2 => {
      let {
        cards
      } = _ref2;
      setContentCards(cards);
    });
  }, []);
  const refreshContentCards = useCallback(() => {
    var _sdk$current2;
    return (_sdk$current2 = sdk.current) === null || _sdk$current2 === void 0 ? void 0 : _sdk$current2.requestContentCardsRefresh();
  }, []);
  useEffect(() => {
    // Dynamic import required to solve SSR issue, not supported by Braze
    import('@braze/web-sdk').then(braze => {
      sdk.current = braze;
      sdk.current.initialize(ENV_CONFIG.BRAZE_API_KEY, {
        baseUrl: ENV_CONFIG.BRAZE_SDK_URL
      });
      setIsSdkReady(true);
      const subscriptionGuid = subscribeToContentCards();
      refreshContentCards();
      return () => {
        var _sdk$current3, _sdk$current3$removeS;
        (_sdk$current3 = sdk.current) === null || _sdk$current3 === void 0 ? void 0 : (_sdk$current3$removeS = _sdk$current3.removeSubscription) === null || _sdk$current3$removeS === void 0 ? void 0 : _sdk$current3$removeS.call(_sdk$current3, subscriptionGuid);
      };
    });
  }, [refreshContentCards, subscribeToContentCards]);

  // Sets braze user in case ys user is logged in
  useEffect(() => {
    var _sdk$current4, _sdk$current4$getUser;
    if (!isSdkReady) {
      return;
    }
    const brazeUserId = (_sdk$current4 = sdk.current) === null || _sdk$current4 === void 0 ? void 0 : (_sdk$current4$getUser = _sdk$current4.getUser()) === null || _sdk$current4$getUser === void 0 ? void 0 : _sdk$current4$getUser.getUserId();
    const hashedId = userState === null || userState === void 0 ? void 0 : userState.hashedId;
    if (hashedId && hashedId !== brazeUserId) {
      var _sdk$current5, _sdk$current6;
      (_sdk$current5 = sdk.current) === null || _sdk$current5 === void 0 ? void 0 : _sdk$current5.changeUser(hashedId);
      (_sdk$current6 = sdk.current) === null || _sdk$current6 === void 0 ? void 0 : _sdk$current6.openSession();
    }
  }, [isSdkReady, userState === null || userState === void 0 ? void 0 : userState.hashedId]);
  const mapToContentCard = useCallback((card, page, position) => {
    var _card$extras;
    return {
      id: card.id || '',
      imageUrl: card.imageUrl || '',
      page,
      position,
      platform: (_card$extras = card.extras) === null || _card$extras === void 0 ? void 0 : _card$extras.platform,
      title: card.title || '',
      url: card.url || '',
      description: card.description || '',
      linkText: card.linkText || ''
    };
  }, []);

  // Returns the list of cards based on the section placeholder
  const getSectionContentCards = useCallback((page, position) => contentCards === null || contentCards === void 0 ? void 0 : contentCards.reduce((cards, card) => {
    var _extras$platform;
    const {
      extras
    } = card;
    const isMatch = (extras === null || extras === void 0 ? void 0 : extras[page.toLowerCase()]) === (position === null || position === void 0 ? void 0 : position.toLowerCase());
    const platform = extras === null || extras === void 0 ? void 0 : (_extras$platform = extras.platform) === null || _extras$platform === void 0 ? void 0 : _extras$platform.toLowerCase();
    const isWebCampaign = platform === ContentCardsPlaceholderPlatform.Web || platform === ContentCardsPlaceholderPlatform.All;
    if (isMatch && isWebCampaign) {
      cards.push(mapToContentCard(card, page, position));
    }
    return cards;
  }, []), [mapToContentCard, contentCards]);
  const getContentCardById = useCallback(cardId => {
    const card = contentCards.find(card => card.id === cardId);
    return card;
  }, [contentCards]);

  // Register click events on Braze, our EventManager
  // Navigate to card url (internal/external)
  const onContentCardClick = useCallback(cardId => {
    const card = getContentCardById(cardId);
    if (card) {
      var _sdk$current7;
      (_sdk$current7 = sdk.current) === null || _sdk$current7 === void 0 ? void 0 : _sdk$current7.logContentCardClick(card);
      eventManager.track(BusinessEvent.USER_PROMOCARD_CLICKED, card);
      if (card.url) {
        window.location.href = card.url;
      }
    }
  }, [getContentCardById]);

  // Register visualization events on Braze, our EventManager
  const onContentCardView = useCallback((isVisible, cardId) => {
    if (isVisible) {
      const card = getContentCardById(cardId);
      if (card) {
        var _sdk$current8;
        (_sdk$current8 = sdk.current) === null || _sdk$current8 === void 0 ? void 0 : _sdk$current8.logContentCardImpressions([card]);
        eventManager.track(BusinessEvent.USER_PROMOCARD_VIEWED, card);
      }
    }
  }, [getContentCardById]);
  const value = useMemo(() => ({
    contentCards,
    getSectionContentCards,
    onContentCardClick,
    onContentCardView,
    refreshContentCards
  }), [getSectionContentCards, onContentCardClick, onContentCardView, contentCards, refreshContentCards]);
  return __jsx(ContentCardsContext.Provider, {
    value: value
  }, children);
};