import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["children"];
var __jsx = React.createElement;
import React from 'react';
import { useHistory } from 'react-router-dom';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { Link } from '../../content';
import { BackLink } from './BackButton.style';
import { useTestIds } from './BackButton.model';
export const BackButton = _ref => {
  let {
      children = 'Back'
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  const testIds = useTestIds(props);
  const history = useHistory();
  const onClick = () => {
    history.goBack();
  };
  return __jsx(BackLink, _extends({
    onClick: onClick
  }, testIds.base.attr), __jsx(KeyboardArrowLeftIcon, testIds.arrow.attr), __jsx(Link, testIds.label.attr, children));
};