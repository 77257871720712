import { useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { reportsApi } from '@yieldstreet/platform-kit';
import { StatementsListQueryParams } from './useStatementsList.model';
import { getFilteredProviders, sortData } from './useStatementsList.util';
export const useStatementsList = _ref => {
  let {
    investorId,
    managementStrategy,
    params,
    userId
  } = _ref;
  const getReportsParams = useMemo(() => {
    if (!userId) {
      return skipToken;
    }
    return {
      investorId: investorId === 'ALL' ? undefined : investorId,
      managementStrategy,
      reportType: params.reportType === 'ALL' ? undefined : params.reportType,
      userId,
      year: params.year === 'ALL' ? undefined : params.year,
      providers: getFilteredProviders(managementStrategy)
    };
  }, [investorId, managementStrategy, params.reportType, params.year, userId]);
  const {
    data: reportsData,
    isError,
    isFetching
  } = reportsApi.useGetReportsQuery(getReportsParams);
  const reports = useMemo(() => {
    return sortData({
      data: (reportsData === null || reportsData === void 0 ? void 0 : reportsData.reports) || [],
      orderKey: params[StatementsListQueryParams.Order],
      sortKey: params[StatementsListQueryParams.Sort]
    });
  }, [params, reportsData]);
  return {
    isError,
    isFetching,
    reports
  };
};