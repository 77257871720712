import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { Tracer } from '@yieldstreet/tool-kit';
import { setActivityNotificationCount } from 'store/actions/portfolioActivity';
import { setUserState } from 'store/actions/user';
import configureStore from 'store/configureStore';
import { isServer } from 'utils/env';
import { initState } from './configureStore';
import { setAnalyticsIds } from 'utils/analytics/setAnalyticsIds';
export const loadState = key => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState) {
      return JSON.parse(serializedState);
    }
  } catch (e) {
    return;
  }
};
export const saveState = (key, value) => {
  try {
    const serializedState = JSON.stringify(value);
    localStorage.setItem(key, serializedState);
  } catch (e) {
    return;
  }
};
export const createStore = (request, response, axiosInstance) => {
  let persistedState = {};
  if (!isServer) {
    if (window.__INITIAL_STATE__) {
      // Being done to decode __INITIAL_STATE__ that was decoded in server.js
      const stateBuffer = Buffer.from(window.__INITIAL_STATE__, 'base64');
      const decodedState = new TextDecoder().decode(stateBuffer);
      persistedState = JSON.parse(decodedState);
    }
    const appPersist = loadState('appPersist') || {};
    persistedState = _objectSpread(_objectSpread({}, persistedState), {}, {
      appPersist
    });
  }
  const store = configureStore(_objectSpread(_objectSpread({}, persistedState), initState), request, response, axiosInstance);
  if (!isServer) {
    var _initState$user;
    const userState = loadState('userState');
    if (userState && !((_initState$user = initState.user) !== null && _initState$user !== void 0 && _initState$user.userState)) {
      store.dispatch(setUserState(userState));
    }
    const unreadMessages = loadState('unreadMessages');
    if (unreadMessages) {
      store.dispatch(setActivityNotificationCount(unreadMessages));
    }

    // Everything needs to be moved inside appPersist.
    store.subscribe(() => {
      saveState('userState', store.getState().user.userState);
      saveState('unreadMessages', store.getState().portfolioActivity.unreadMessages);
      saveState('audiences', store.getState().audiences);
      saveState('appPersist', store.getState().appPersist);
    });
  }
  store.subscribe(() => {
    const user = store.getState().user;
    Tracer.setGlobalAttributes({
      userId: user.userState.id,
      analyticsAnonymousId: user.analyticsAnonymousId,
      analyticsUserId: user.analyticsUserId
    });
  });
  const userState = store.getState().user;
  setAnalyticsIds(store.dispatch, userState);
  return store;
};
export default createStore;