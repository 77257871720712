import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["userId", "intercomUserHash"];
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
/* eslint-disable react-hooks-ssr/react-hooks-global-ssr */
// Taboola
export const setTaboola = _ref => {
  let {
    notify,
    name,
    id
  } = _ref;
  window['_tfa'] && window['_tfa'].push({
    notify,
    name,
    id
  });
};

// Yahoo Gemini
export const setYahooGemini = _ref2 => {
  let {
    event
  } = _ref2;
  window['dotq'] = window['dotq'] || [];
  window['dotq'].push(event);
};

// Nextdoor
export const setNextdoor = _ref3 => {
  let {
    type,
    event
  } = _ref3;
  window['ndp'] && window['ndp'](type, event);
};

// Google Ads
export const setGoogleAds = _ref4 => {
  let {
    type,
    eventName,
    extraConfig
  } = _ref4;
  window['gtag'] && window['gtag'](type, eventName, extraConfig);
};

// Bing Ads
export const setBingAds = _ref5 => {
  let {
    eventCategory,
    eventAction,
    eventLabel,
    eventValue
  } = _ref5;
  window['uetq'] = window['uetq'] || [];
  window['uetq'].push(pickBy({
    ec: eventCategory,
    ea: eventAction,
    el: eventLabel,
    ev: eventValue
  }, identity));
};

// Taboola
export const setPodSights = _ref6 => {
  let {
    eventName,
    extraConfig
  } = _ref6;
  window['pdst'] && window['pdst'](eventName, extraConfig);
};

// Quora
export const setQuora = _ref7 => {
  let {
    type,
    eventName
  } = _ref7;
  window['qp'] && window['qp'](type, eventName);
};
export const setSegmentReady = callback => {
  const analytics = getSegment();
  analytics && analytics.ready(callback);
};
export const setSegmentTrack = _ref8 => {
  let {
    event,
    properties = {}
  } = _ref8;
  const analytics = getSegment();
  const user = (analytics === null || analytics === void 0 ? void 0 : analytics.user) && analytics.user();
  const traits = user === null || user === void 0 ? void 0 : user.traits();
  const context = traits ? {
    traits
  } : undefined;
  analytics && analytics.track(event, properties, context);
};
export const setSegmentIdentify = _ref9 => {
  let {
      userId,
      intercomUserHash
    } = _ref9,
    traits = _objectWithoutProperties(_ref9, _excluded);
  const analytics = getSegment();
  if (analytics) {
    if (userId) {
      const options = {
        Intercom: {
          user_hash: intercomUserHash
        }
      };
      analytics.identify(userId, traits, options);
    } else {
      analytics.identify(traits);
    }
  }
};
export const setSegmentPage = () => {
  const analytics = getSegment();
  analytics && analytics.page();
};
export const setSegmentAnonymousId = anonymousId => {
  const analytics = getSegment();
  analytics && analytics.setAnonymousId(anonymousId);
};
export const getSegment = () => window['analytics'];
export const onFSReady = callback => {
  window['_fs_ready'] = () => {
    callback();
  };
};
export const getFSUrl = now => {
  window['FS'] && window['FS'].getCurrentSessionURL(now);
};
export const shutdownFS = () => {
  window['FS'] && window['FS'].shutdown();
};
export const restartFS = () => {
  window['FS'] && window['FS'].restart();
};
export const setPaypalPixel = () => {
  const i = new Image();
  i.src = 'https://www.paypal.com/en_US/i/scr/pixel.gif';
};