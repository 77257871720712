import { Label, Paragraph, friendlyMedia } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
export const InvestorAccountDropdownWrapper = styled.div.withConfig({
  displayName: "InvestorAccountDropdownstyle__InvestorAccountDropdownWrapper",
  componentId: "sc-1tjpt2g-0"
})(["gap:5px;display:flex;flex-direction:column;", ""], friendlyMedia.tablet`
     width: 300px;
   `);
export const InvestorAccountDropdownListItem = styled.div.withConfig({
  displayName: "InvestorAccountDropdownstyle__InvestorAccountDropdownListItem",
  componentId: "sc-1tjpt2g-1"
})(["border-radius:2px;display:flex;padding:16px;cursor:pointer;min-width:0;background:", ";&:hover{background:", ";}"], props => props !== null && props !== void 0 && props.isActive ? `${props.theme.colors.text_header}` : `${props.theme.colors.background_light}`, props => props !== null && props !== void 0 && props.isActive ? '' : `${props.theme.colors.input_button_secondary_background_hover}`);
export const InvestorAccountDropdownListItemParagraph = styled(Paragraph).withConfig({
  displayName: "InvestorAccountDropdownstyle__InvestorAccountDropdownListItemParagraph",
  componentId: "sc-1tjpt2g-2"
})(["white-space:nowrap;overflow:hidden;text-overflow:ellipsis;flex:1;user-select:none;"]);
export const DropdownTotalLabel = styled(Label).withConfig({
  displayName: "InvestorAccountDropdownstyle__DropdownTotalLabel",
  componentId: "sc-1tjpt2g-3"
})(["white-space:nowrap;text-align:end;margin:auto;", ";"], _ref => {
  let {
    isActive,
    theme
  } = _ref;
  return isActive && `color: ${theme.colors.text_header_inverse} !important`;
});