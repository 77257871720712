import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useEffect, useMemo } from 'react';
import { useLocation, withRouter } from 'react-router-dom';
import { Header, HeaderLoggedOut, SimpleHeader, Footer } from '@yieldstreet/ui-kit';
import { useMessages } from '@yieldstreet/tool-kit';
import { parse } from 'query-string';
import { useAudiences, useAudiencesTier } from 'hooks/useAudiences';
import { setActivityNotificationCount } from 'store/actions/portfolioActivity';
import { loadState } from 'store/createStore';
import { renderLink } from 'sharedComponents/Links/Links';
import { useIsMobileApp } from 'utils/mobileApp';
import { ACTIVITY_MESSAGES_LIMIT, testIds } from './BaseLayout.model';
import { useUserState } from 'utils/hooks';
import { PostNavigationContent, PreNavigationContent } from './components';
import { LayoutContainer, Wrapper } from './BaseLayout.style';
import useActions from 'utils/hooks/useActions';
const actions = {
  setActivityNotificationCount
};
const BaseLayout = _ref => {
  var _localUserState$featu;
  let {
    children,
    condensedFooter,
    hideLogin,
    hideLoginOnUTM,
    hideSignup,
    showMainHeader,
    showSimpleHeader
  } = _ref;
  const location = useLocation();
  const audiences = useAudiences();
  const tier = useAudiencesTier();
  const userState = useUserState();
  const {
    unseenMessages
  } = useMessages({
    fetchConfig: {
      limit: ACTIVITY_MESSAGES_LIMIT,
      authToken: userState === null || userState === void 0 ? void 0 : userState.authToken
    }
  });
  const {
    setActivityNotificationCount
  } = useActions(actions);
  useEffect(() => {
    setActivityNotificationCount(unseenMessages);
  }, [unseenMessages, setActivityNotificationCount]);
  const {
    search
  } = location;
  const isEmbedded = useMemo(() => {
    const searchParams = parse(search);
    if (searchParams !== null && searchParams !== void 0 && searchParams.embed) {
      return true;
    }
    return false;
  }, [search]);
  const localUserState = loadState('userState') || {};
  const isYieldstreetDiamondUser = (localUserState === null || localUserState === void 0 ? void 0 : (_localUserState$featu = localUserState.features) === null || _localUserState$featu === void 0 ? void 0 : _localUserState$featu.indexOf('DIAMOND_USER')) !== -1;
  const notificationGroup = [{
    menu: 'activity',
    value: unseenMessages === null || unseenMessages === void 0 ? void 0 : unseenMessages.length
  }, {
    menu: 'profile',
    value: isYieldstreetDiamondUser
  }];
  const isMobileApp = useIsMobileApp();
  return __jsx(Wrapper, _extends({
    isEmbedded: isEmbedded
  }, testIds.base.attr), !isMobileApp && !isEmbedded && __jsx(React.Fragment, null, showSimpleHeader && __jsx(SimpleHeader, {
    hideLogin: hideLogin || hideLoginOnUTM && search && search.indexOf('utm') !== -1,
    hideSignup: hideSignup
  }), showMainHeader && __jsx(React.Fragment, null, !(userState !== null && userState !== void 0 && userState.loggedIn) ? __jsx(HeaderLoggedOut, {
    audiences: audiences,
    tier: tier,
    userState: userState,
    renderLink: renderLink,
    currentPath: location.pathname,
    notificationGroup: notificationGroup
  }) : __jsx(Header, {
    audiences: audiences,
    tier: tier,
    userState: userState,
    renderLink: renderLink,
    currentPath: location.pathname,
    notificationGroup: notificationGroup,
    preContent: __jsx(PreNavigationContent, null),
    postContent: __jsx(PostNavigationContent, null)
  }))), __jsx(LayoutContainer, _extends({
    isMobileApp: isMobileApp
  }, testIds.main.attr), children), !isMobileApp && !isEmbedded && __jsx(Footer, {
    renderLink: renderLink,
    condensed: condensedFooter
  }));
};
export default withRouter(BaseLayout);