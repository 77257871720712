var __jsx = React.createElement;
import React from 'react';
import { ActionCellIcon } from '../ActionCell.style';
export const ArrowIcon = _ref => {
  let {
    color
  } = _ref;
  return __jsx(ActionCellIcon, {
    width: "20",
    height: "20",
    viewBox: "0 0 20 20",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __jsx("mask", {
    id: "mask0_5648_8728",
    "mask-type": "alpha",
    maskUnits: "userSpaceOnUse",
    x: "0",
    y: "0",
    width: "20",
    height: "20"
  }, __jsx("rect", {
    width: "20",
    height: "20",
    fill: "#D9D9D9"
  })), __jsx("g", {
    mask: "url(#mask0_5648_8728)"
  }, __jsx("path", {
    d: "M13.125 10.75H4V9.25H13.125L8.9375 5.0625L10 4L16 10L10 16L8.9375 14.9375L13.125 10.75Z",
    fill: color
  })));
};