import { getFlagsContext } from '@yieldstreet/tool-kit';
import { getLDFlags } from '@yieldstreet/tool-kit/src/flags/vendor/launch-darkly/server';
import ENV_CONFIG from 'utils/env/config';
export const FETCH_FLAGS_ATTEMPT = 'ys/flags/fetch/ATTEMPT';
export const FETCH_FLAGS_SUCCESS = 'ys/flags/fetch/SUCCESS';
export const FETCH_FLAGS_FAILURE = 'ys/flags/fetch/FAILURE';
export const fetchFlags = () => async (dispatch, getState) => {
  dispatch({
    type: FETCH_FLAGS_ATTEMPT
  });
  try {
    const {
      analyticsAnonymousId: anonymousId,
      analyticsUserId: userId
    } = getState().user;
    const context = getFlagsContext({
      anonymousId,
      userId,
      host: ENV_CONFIG.HOST
    });
    const flags = await getLDFlags(ENV_CONFIG.LAUNCHDARKLY_SDK_KEY, context);
    return dispatch({
      response: flags.toJSON(),
      type: FETCH_FLAGS_SUCCESS
    });
  } catch (error) {
    dispatch({
      type: FETCH_FLAGS_FAILURE,
      error
    });
    throw error;
  }
};