import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React from 'react';
import asyncScrollTo from 'sharedComponents/utils/scroll';
const TinyAnchor = _ref => {
  let {
    children,
    elementId,
    position,
    offset,
    offsetSize,
    behavior,
    tight,
    dataCy = 'tiny-anchor-default',
    onClick
  } = _ref;
  return __jsx(_Typography, {
    component: "span",
    variant: "body2",
    "data-cy": dataCy,
    onClick: () => {
      asyncScrollTo(elementId, position, offset, offsetSize, behavior);
      onClick && onClick();
    },
    sx: theme => ({
      cursor: 'pointer',
      fontSize: '10px',
      lineHeight: '9px',
      pl: tight ? theme.spacing(0.5) : theme.spacing(1),
      verticalAlign: 'super'
    })
  }, children);
};
export default TinyAnchor;