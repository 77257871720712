var __jsx = React.createElement;
import React from 'react';
import { Wrapper, BoxContent, SSliderInput } from './CalculatorSlider.style';
import { CalculatorHeader } from './CalculatorHeader';
import kebabCase from "lodash/kebabCase";
export const CalculatorSlider = _ref => {
  let {
    title,
    subtitle,
    tooltip,
    value,
    inputField,
    min,
    max,
    onChange
  } = _ref;
  return __jsx(Wrapper, null, __jsx(BoxContent, null, __jsx(CalculatorHeader, {
    title: title,
    subtitle: subtitle,
    tooltip: tooltip
  }), inputField), __jsx("div", null, __jsx(SSliderInput, {
    label: kebabCase(title),
    name: "slider",
    min: min,
    max: max,
    value: value,
    fullWidth: true,
    onChange: value => onChange && onChange(value),
    formatValue: () => ''
  })));
};