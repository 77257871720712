import { useMemo } from 'react';
import { PortfolioStrategyValues, allToken, investmentApi } from '@yieldstreet/platform-kit';
import { useEligibilityGates } from './useManagedPortfolioEligibility';
import { useAllInvestorEntities } from '../hooks';
import { mapGatesToEntities, mapInvestorEntityGatesToEligibilityGate } from './managedPortfolioEligibility.util';
export const useEligibilityContent = (managementStrategy, config, eligibility) => {
  const gates = useEligibilityGates(config, eligibility);
  const {
    investorEntities,
    investorEntityStatuses
  } = useAllInvestorEntities();
  const entity = useMemo(() => mapGatesToEntities(gates, investorEntities, investorEntityStatuses), [gates, investorEntities, investorEntityStatuses]);
  return useMemo(() => mapInvestorEntityGatesToEligibilityGate(gates, entity, managementStrategy), [entity, gates, managementStrategy]);
};
export const useManagedPortfolioEligibilityContent = config => {
  const {
    isLoading,
    data: {
      eligibility
    } = {}
  } = investmentApi.useManagedPortfolioEligibilityQuery(allToken);
  return {
    gateContent: useEligibilityContent(PortfolioStrategyValues.Discretionary, config, eligibility),
    isLoading
  };
};