import _Box from "@mui/material/Box";
var __jsx = React.createElement;
import React from 'react';
import kebabCase from "lodash/kebabCase";
import numeral from 'numeral';
import { DesktopOnly, MobileOnly } from '../../../../utils';
import { AmountSelector } from '../AmountSelector';
import { AmountSelectorHolder, AmountSliderHolder, AmountTitle, Label, LineValue, Paragraph, SliderContainer, SSliderInput } from './ExpandedAmountSelector.style';

// FIX ME...
const color = '#000';
export const ExpandedAmountSelector = _ref => {
  let {
    amount,
    annualizedYield,
    max = 100000,
    min = 0,
    onChange,
    onTouchEnd,
    onTouchMove,
    step = 1000,
    term,
    title
  } = _ref;
  return __jsx(React.Fragment, null, __jsx(MobileOnly, null, __jsx(AmountSelector, {
    title: title,
    annualizedYield: annualizedYield,
    term: term,
    amount: amount,
    step: step,
    min: min,
    max: max,
    onChange: onChange,
    onTouchMove: onTouchMove,
    onTouchEnd: onTouchEnd,
    color: color
  })), __jsx(DesktopOnly, null, __jsx(AmountSelectorHolder, null, __jsx(AmountTitle, null, __jsx(Paragraph, {
    semiBold: true
  }, title)), __jsx(LineValue, null, __jsx(Label, null, "Target annualized yield", __jsx("sup", null, "3")), __jsx(Paragraph, {
    semiBold: true
  }, __jsx(React.Fragment, null, annualizedYield, "%"))), __jsx(LineValue, null, __jsx(Label, null, "Target term", __jsx("sup", null, "3")), __jsx(Paragraph, {
    semiBold: true
  }, term)), __jsx(AmountSliderHolder, null, __jsx(_Box, {
    sx: {
      mr: 6
    }
  }, __jsx(Label, null, "Total investment"), __jsx(Paragraph, {
    semiBold: true
  }, numeral(amount).format('$0,0'))), __jsx(SliderContainer, {
    onTouchMove: onTouchMove,
    onTouchEnd: onTouchEnd
  }, __jsx(SSliderInput, {
    label: kebabCase(title),
    name: "slider",
    min: min,
    max: max,
    value: amount,
    fullWidth: true,
    onChange: value => onChange && onChange(value),
    formatValue: () => '',
    step: step,
    trackColor: color
  }))))));
};