var __jsx = React.createElement;
import React from 'react';
import { Paragraph } from '../../../../content/text';
import { Currency } from '../common/currency';
import { testIds } from './AssetClassTotal.model';
import { Headline, Image, ImageHolder, Number, OuterContainer, Title } from './AssetClassTotal.style';
export const AssetClassTotal = _ref => {
  let {
    amount,
    averageNoteAmount,
    cta,
    dataCy,
    image,
    imageAlt,
    inverse,
    title
  } = _ref;
  return __jsx(OuterContainer, {
    "data-cy": dataCy
  }, __jsx(Headline, {
    "data-cy": testIds.headline,
    inverse: inverse,
    type: 4
  }, title), __jsx(Title, {
    inverse: inverse,
    small: true
  }, "Amount invested to date:"), __jsx(Number, {
    "data-cy": testIds.amount,
    inverse: inverse
  }, __jsx(Currency, amount)), __jsx(Title, {
    inverse: inverse,
    small: true
  }, "Average offering size:"), __jsx(Number, {
    "data-cy": testIds.avgNoteAmount,
    inverse: inverse
  }, __jsx(Currency, averageNoteAmount)), cta && __jsx(Paragraph, {
    "data-cy": testIds.cta
  }, cta), __jsx(ImageHolder, null, image && __jsx(Image, {
    alt: imageAlt,
    "data-cy": testIds.image,
    src: image
  })));
};