import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { useRive } from 'rive-react';
import { genTestIds } from '@yieldstreet/tool-kit';
import { LoadingLogoType, logoVariations } from './LoadingLogo.model';
import { LogoWrapper } from './LoadingLogo.style';
const {
  useTestIds
} = genTestIds();
export const LoadingLogo = _ref => {
  let {
    testId,
    size,
    type = LoadingLogoType.WHITE
  } = _ref;
  const testIds = useTestIds({
    testId: testId || 'loadingLogo'
  });
  const {
    RiveComponent
  } = useRive({
    src: logoVariations[type],
    autoplay: true
  });
  return __jsx(LogoWrapper, _extends({
    dimensions: size
  }, testIds.base.attr), __jsx(RiveComponent, {
    className: "rive-canvas-size"
  }));
};