import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useContext } from 'react';
import { ModalContext } from '../../modal.context';
/**
 * Get access to the context object.
 */
const useModalContext = () => useContext(ModalContext);

/**
 * Hook to subscribe to modals.
 */
export const useModal = (modalId, config) => {
  const {
    onClose,
    onSuccess,
    props
  } = config || {};
  const {
    currentModal,
    subscribeModal
  } = useModalContext();
  return _objectSpread(_objectSpread({}, subscribeModal({
    modalId,
    onClose,
    onSuccess,
    props
  })), {}, {
    isOpen: currentModal === modalId
  });
};