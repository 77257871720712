const MockContent = {
  short: 'Eget eget amet, habitant arcu sit ut leo.',
  mid: 'Eget eget amet, habitant arcu sit ut leo. Viverra cursus quam vitae consectetur pellentesque duis leo. Viverra lectus magna sit laoreet sollicitudin turpis. Accumsan sit metus, nec praesent enim, eget enim praesent.',
  long: 'Eget eget amet, habitant arcu sit ut leo. Viverra cursus quam vitae consectetur pellentesque duis leo. Viverra lectus magna sit laoreet sollicitudin turpis. Accumsan sit metus, nec praesent enim, eget enim praesent.\nEget eget amet, habitant arcu sit ut leo. Viverra cursus quam vitae consectetur pellentesque duis leo.\nViverra lectus magna sit laoreet sollicitudin turpis. Accumsan sit metus, nec praesent enim, eget enim praesent. Eget eget amet, habitant arcu sit ut leo. Viverra cursus quam vitae consectetur pellentesque duis leo. Viverra lectus magna sit laoreet sollicitudin turpis. Accumsan sit metus, nec praesent enim, eget enim praesent.\nEget eget amet, habitant arcu sit ut leo. Viverra cursus quam vitae consectetur pellentesque duis leo. Viverra lectus magna sit laoreet sollicitudin turpis. Accumsan sit metus, nec praesent enim, eget enim praesent. Eget eget amet, habitant arcu sit ut leo. Viverra cursus quam vitae consectetur pellentesque duis leo. Viverra lectus magna sit laoreet sollicitudin turpis. Accumsan sit metus, nec praesent enim, eget enim praesent. Eget eget amet, habitant arcu sit ut leo. Viverra cursus quam vitae consectetur pellentesque duis leo.',
  markdown: '# This is a title\n**Eget eget amet** habitant arcu sit ut leo. Viverra cursus quam vitae consectetur pellentesque duis leo. Viverra lectus magna sit laoreet sollicitudin turpis. Accumsan sit metus, nec praesent enim, eget enim praesent.\nEget eget amet, habitant arcu sit ut leo. Viverra cursus quam vitae consectetur pellentesque duis leo.\nViverra lectus magna sit laoreet sollicitudin turpis. Accumsan sit metus, nec praesent enim, eget enim praesent. Eget eget amet, habitant arcu sit ut leo. Viverra cursus quam vitae consectetur pellentesque duis leo. Viverra lectus magna sit laoreet sollicitudin turpis. Accumsan sit metus, nec praesent enim, eget enim praesent.\nEget eget amet, habitant arcu sit ut leo. Viverra cursus quam vitae consectetur pellentesque duis leo. Viverra lectus magna sit laoreet sollicitudin turpis. Accumsan sit metus, nec praesent enim, eget enim praesent. Eget eget amet, habitant arcu sit ut leo. Viverra cursus quam vitae consectetur pellentesque duis leo. Viverra lectus magna sit laoreet sollicitudin turpis. Accumsan sit metus, nec praesent enim, eget enim praesent. Eget eget amet, habitant arcu sit ut leo. Viverra cursus quam vitae consectetur pellentesque duis leo.'
};
export const videoSection = {
  type: 'paragraphBox',
  title: 'Summary of transaction',
  video: 'https://player.vimeo.com/video/430433955',
  image: '',
  imageCaption: '',
  interactiveMap: '',
  charLimit: 1000,
  content: ['YS REL IV LLC (“YS SPV”) provided a $4.2M mezzanine loan (the “Mezzanine Loan” or “Loan”) to a special purpose vehicle (the “Borrower”) controlled by a Washington DC-based real estate developer and manager (the “Sponsor”). The Loan is secured by the pledge of the Borrower’s equity interest in its subsidiary, which owns the leasehold interest in a 99-unit limited service hotel in Downtown Washington DC (the “Property”). The Sponsor used the Loan, alongside an $18.0M senior loan.']
};
export const imageSection = {
  type: 'paragraphBox',
  title: 'Cash flow',
  subtitle: 'How do I get paid?',
  iconName: 'Structure',
  video: '',
  image: 'https://imageuploaded.com/projected-cash-flow.png',
  imageCaption: 'Cash Flow is cool',
  interactiveMap: '',
  charLimit: 1000,
  content: [MockContent.short, MockContent.short, MockContent.long]
};
export const mapSection = {
  type: 'paragraphBox',
  title: 'Assets',
  subtitle: '',
  iconName: 'bank',
  video: '',
  image: '',
  imageCaption: '',
  interactiveMap: 'https://www.google.com/maps/embed?origin=mfe&pb=!1m3!2m1!1s12.927923,77.627108!6i15',
  charLimit: 1000,
  content: [MockContent.mid, MockContent.mid]
};
export const readMoreSection = {
  type: 'paragraphBox',
  title: 'Capital structure',
  subtitle: 'Where do I lie in terms of priority?',
  iconName: 'cash',
  video: '',
  image: 'https://imageuploaded.com/positions.png',
  imageCaption: '',
  interactiveMap: '',
  charLimit: 1000,
  content: [MockContent.long, MockContent.mid, MockContent.mid]
};
export const markdownSection = {
  type: 'paragraphBox',
  title: 'Capital structure',
  subtitle: 'Where do I lie in terms of priority?',
  iconName: 'cash',
  video: '',
  image: 'https://imageuploaded.com/positions.png',
  imageCaption: '',
  interactiveMap: '',
  charLimit: 1000,
  content: [MockContent.markdown]
};
export const noMediaSection = {
  type: 'paragraphBox',
  title: 'Cash flow',
  subtitle: 'How do I get paid?',
  iconName: 'Structure',
  video: '',
  image: '',
  imageCaption: 'Cash Flow is cool',
  interactiveMap: '',
  charLimit: 1000,
  content: [MockContent.mid]
};
export const paragraphCarouselSection = {
  type: 'paragraphBox',
  title: 'Cash flow',
  subtitle: 'How do I get paid?',
  iconName: 'Structure',
  video: '',
  image: '',
  imageCaption: '',
  interactiveMap: '',
  charLimit: 1000,
  content: [MockContent.short, MockContent.short, MockContent.long],
  carousel: [{
    imageUrl: 'https://imageuploaded.com/projected-cash-flow.png',
    imageCaption: 'test caption'
  }, {
    imageUrl: 'https://imageuploaded.com/projected-cash-flow.png',
    imageCaption: 'test caption 2'
  }]
};
export const paragraphCarouselSectionSingle = {
  type: 'paragraphBox',
  title: 'Cash flow',
  subtitle: 'How do I get paid?',
  iconName: 'Structure',
  video: '',
  image: '',
  imageCaption: '',
  interactiveMap: '',
  charLimit: 1000,
  content: [MockContent.short, MockContent.short, MockContent.long],
  carousel: [{
    imageUrl: 'https://imageuploaded.com/projected-cash-flow.png',
    imageCaption: 'test caption'
  }]
};