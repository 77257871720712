import update from 'immutability-helper';
import * as actions from 'store/actions/portfolioOverview';
export const initialState = {
  data: {},
  isFetching: false,
  error: false,
  portfolioTier: {
    isOptedIn: false
  }
};
const portfolioOverview = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case actions.OVERVIEW_ATTEMPT:
      {
        return update(state, {
          isFetching: {
            $set: true
          },
          error: {
            $set: false
          }
        });
      }
    case actions.OVERVIEW_SUCCESS:
      {
        const {
          response,
          userId,
          investorAccountId,
          timePeriod
        } = action;
        const data = {
          [`${userId}_${investorAccountId}_${timePeriod}`]: response
        };
        return update(state, {
          data: {
            $merge: data
          },
          isFetching: {
            $set: false
          },
          error: {
            $set: false
          }
        });
      }
    case actions.OVERVIEW_FAILURE:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          error: {
            $set: true
          }
        });
      }
    case actions.TIER_OPT_IN_ATTEMPT:
      {
        return update(state, {
          portfolioTier: {
            isOptedIn: {
              $set: true
            }
          }
        });
      }
    default:
      return state;
  }
};
export default portfolioOverview;