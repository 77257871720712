var __jsx = React.createElement;
import React, { useEffect, useState } from 'react';
import { fomoMetricsApi } from '@yieldstreet/platform-kit';
import { ToastNotification } from '@yieldstreet/ui-kit';
import moment from 'moment';
import TrendingOfferingIcon from '@yieldstreet/ui-kit/assets/graphics/icons/trending-offering.svg';
import { NumberFormat } from 'sharedComponents/NumberFormat/NumberFormat';
import { Animation } from './TrendingOffering.style';
const AUTO_HIDE_TIME = 10000;
const renderTrendingOffering = message => __jsx(Animation, null, __jsx(ToastNotification, {
  className: "trending-offering",
  autoHideDuration: AUTO_HIDE_TIME,
  title: "Trending offering",
  text: [message],
  transitionDuration: 0,
  icon: __jsx("img", {
    src: TrendingOfferingIcon,
    alt: "Trending offering",
    "aria-hidden": true
  })
}));
const TrendingOffering = function () {
  let {
    noteUrlHash,
    delayToShow = 5000
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const {
    0: isShowing,
    1: setIsShowing
  } = useState(!parseInt(delayToShow) > 0);
  const metricsTo = moment().format('YYYY-MM-DD');
  const metricsFrom = moment().subtract(7, 'days').format('YYYY-MM-DD');
  const {
    useGetMetricNumberQuery
  } = fomoMetricsApi;
  const {
    data: numOfViews
  } = useGetMetricNumberQuery({
    metricName: 'numOfViews',
    noteId: noteUrlHash,
    from: metricsFrom,
    to: metricsTo
  });
  const {
    data: numOfInvestors
  } = useGetMetricNumberQuery({
    metricName: 'numOfInvestors',
    noteId: noteUrlHash,
    from: metricsFrom,
    to: metricsTo
  });
  useEffect(() => {
    if (delayToShow > 0 && numOfInvestors >= 0 && numOfViews >= 0) {
      setTimeout(() => {
        setIsShowing(true);
      }, delayToShow);
    }
  }, [numOfInvestors, numOfViews, delayToShow]);
  const trendingOfferingMessages = {
    VIEWS: __jsx(React.Fragment, null, __jsx(NumberFormat, {
      value: numOfViews,
      displayType: "text",
      thousandSeparator: true
    }), " views in the last week"),
    INVESTORS: __jsx(React.Fragment, null, __jsx(NumberFormat, {
      value: numOfInvestors,
      displayType: "text",
      thousandSeparator: true
    }), " investors in the last week")
  };
  if (numOfInvestors >= 100 && isShowing) {
    return renderTrendingOffering(trendingOfferingMessages.INVESTORS);
  } else if (numOfInvestors < 100 && numOfViews > 500 && isShowing) {
    return renderTrendingOffering(trendingOfferingMessages.VIEWS);
  }
  return null;
};
export default TrendingOffering;