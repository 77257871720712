import { HttpMethods } from '../../enums';
import { Tags, restApi } from '../common';
export const userNameApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.UserState, Tags.UserContact]
}).injectEndpoints({
  endpoints: builder => {
    const updateUserName = builder.mutation({
      query: _ref => {
        let {
          userId,
          data
        } = _ref;
        return {
          url: `/a/api/user/name/${userId}`,
          method: HttpMethods.Patch,
          data
        };
      },
      invalidatesTags: [Tags.UserState, Tags.UserContact],
      transformResponse: res => res.data
    });
    return {
      updateUserName
    };
  }
});