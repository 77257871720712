var __jsx = React.createElement;
import React from 'react';
import { StepIconContainer } from './ProgressBarV2.style';
import { getTestIds, ProgressBarV2Status } from './ProgressBarV2.model';
import { IconType } from '../icon/Icon.model';
import { Icon } from '../icon';
import { theme2 } from '../../themes';
export const getStepIcon = props => {
  const {
    status,
    testId
  } = props;
  const testIds = getTestIds(testId);
  const iconMap = {
    [ProgressBarV2Status.ACTIVE]: {
      icon: IconType.CIRCLE,
      color: theme2.colors.progress_bar_primary
    },
    [ProgressBarV2Status.CLEARED]: {
      icon: IconType.CIRCLE_CHECK,
      color: theme2.colors.progress_bar_primary
    },
    [ProgressBarV2Status.FAILED]: {
      icon: IconType.CIRCLE_ALERT,
      color: theme2.colors.text_error_main
    },
    [ProgressBarV2Status.IDLE]: {
      icon: IconType.CIRCLE,
      color: theme2.colors.border_default
    }
  };
  return __jsx(StepIconContainer, testIds.icon.attr, __jsx(Icon, {
    iconType: iconMap[status].icon,
    color: iconMap[status].color
  }));
};
export const checkNextStepActive = (steps, idx) => {
  var _steps;
  return [ProgressBarV2Status.ACTIVE, ProgressBarV2Status.CLEARED].includes((_steps = steps[idx + 1]) === null || _steps === void 0 ? void 0 : _steps.status);
};
export const isCompleteStatus = status => [ProgressBarV2Status.ACTIVE, ProgressBarV2Status.CLEARED].includes(status);