import { PerformanceStatus, DistributionOption } from '../../../../enums';
import { InvestmentEngine, InvestmentStatusTypes, FundProvider, gateType, TenderStatus, InvestmentTypes, ReinvestmentDistributionStatusTypes } from '../../../../types';
export const portfolioOverviewFundSscActive = {
  userId: 109,
  accountSummary: {
    outstandingPrincipal: 0,
    outstandingPrincipalPreDefault: 0,
    outstandingPrincipalPostDefault: 0,
    accruedInvestmentInterest: 0,
    accruedInvestmentInterestPreDefault: 0,
    accruedInvestmentInterestPostDefault: 0,
    balanceInDefault: 0,
    balanceByPerformanceStatus: {
      PERFORMING: 42251.14,
      MODIFIED_OUTLOOK: 0.0,
      DEFAULT: 0.0,
      MARINE_DEFAULT: 0.0,
      PARTIAL_DEFAULT: 0.0
    },
    investmentBalance: 42251.14,
    pendingInvestments: 0.0,
    availableBalance: 0.0,
    clearedBalance: 0.0,
    pendingDeposits: 0.0,
    reserveBalance: 0,
    total: 42251.14,
    weightedInvestmentRate: 8.0,
    irr: 6.65,
    interestEarnedLast30: 0.0,
    fundsTotal: 42251.14,
    funds: [{
      investorAccountId: '57',
      noteUrlHash: 'LJouqg',
      fundCode: '4321',
      fundId: 'bc456e10-4e6d-11ea-acad-218c699d1427',
      total: 42251.14,
      nav: 9.2256,
      navDate: '2023-06-23',
      sharesCount: 4579.772,
      canToggleDistributionOption: true,
      dividendDistributionOption: DistributionOption.ReInvest,
      commitmentBreakdown: null,
      fundInvestmentStatus: 'CONFIRMED',
      fundConfig: {
        fees: {
          MANAGEMENT_FEE: {
            rate: 1.5
          }
        },
        feesBalanceProviders: {},
        balanceCalculationType: null,
        capitalBalanceReductionType: null,
        positionsEffectiveDistributionImpact: null
      },
      commitmentDetails: []
    }]
  },
  capital: null,
  investorEntities: [],
  interest: {
    beginningAccruedInterest: 0,
    interestEarned: 0,
    compoundInterestEarned: 0,
    interestPaid: 0,
    fexpPaid: 0,
    fexpBalance: 0,
    netPaid: 0,
    walletInterestPaid: 0,
    compoundInterestPaid: 0,
    endingAccruedInterest: 0,
    interestEarnedPreDefault: 0,
    interestEarnedPostDefault: 0,
    earningsTimeline: []
  },
  principal: {
    beginningOutstandingPrincipal: 0,
    totalInvested: 0,
    repaidPrincipal: 0,
    endingOutstandingPrincipal: 0,
    endingOutstandingPrincipalPreDefault: 0,
    endingOutstandingPrincipalPostDefault: 0,
    liquidationPaid: 0,
    liquidationDiscount: 0,
    isInDefault: false,
    principalTimeline: []
  },
  investmentDetail: {
    fundId: 'bc456e10-4e6d-11ea-acad-218c699d1427',
    fundType: 'FORTY_ACT_FUND',
    noteUrlHash: 'LJouqg',
    title: 'Yieldstreet Prism Fund',
    distributionRate: 8.0,
    assetClass: 'FUND',
    assetClassDescription: 'Multi-Asset Class Fund',
    paymentType: 'Quarterly',
    termPhrase: '-',
    advisor: 'YieldStreet Management LLC',
    subAdvisor: '-',
    interestRate: 0,
    taxDocument: '1099-DIV',
    taxMonth: 'MARCH',
    firstYearExpense: 0,
    annualFlatExpense: 0,
    irr: 0.0,
    investedDate: '2020-02-21',
    activeDate: '2022-08-30',
    launchedDate: '2020-10-21',
    terminationDate: '',
    interestDefaultedDate: null,
    principalDefaultedDate: null,
    dateOfFirstDefault: null,
    originator: null,
    dividendDistributionOption: DistributionOption.ReInvest,
    canToggleDistributionOption: true,
    performanceStatus: PerformanceStatus.PERFORMING,
    performanceStatusNote: 'The Fund continues to perform in line with expectations',
    spvType: null,
    offeringData: {},
    investments: [{
      type: InvestmentTypes.ReinvestDistribution,
      dateInvested: '2023-06-14',
      dateActive: '2023-06-14',
      shareCount: 97.328,
      sharePrice: 9.211,
      status: ReinvestmentDistributionStatusTypes.Cleared,
      amount: 896.49
    }, {
      type: InvestmentTypes.ReinvestDistribution,
      dateInvested: '2023-02-15',
      dateActive: '2023-02-15',
      shareCount: 96.437,
      sharePrice: 9.0961,
      status: ReinvestmentDistributionStatusTypes.Cleared,
      amount: 877.2
    }, {
      type: InvestmentTypes.ReinvestDistribution,
      dateInvested: '2022-12-15',
      dateActive: '2022-12-15',
      shareCount: 92.968,
      sharePrice: 9.2355,
      status: ReinvestmentDistributionStatusTypes.Cleared,
      amount: 858.61
    }, {
      type: InvestmentTypes.ReinvestDistribution,
      dateInvested: '2022-09-15',
      dateActive: '2022-09-15',
      shareCount: 90.243,
      sharePrice: 9.3144,
      status: ReinvestmentDistributionStatusTypes.Cleared,
      amount: 840.56
    }, {
      type: InvestmentTypes.Investment,
      dateInvested: '2022-08-23',
      dateActive: '2022-08-30',
      shareCount: 1581.778,
      sharePrice: 9.483,
      status: InvestmentStatusTypes.Confirmed,
      amount: 15000.0
    }, {
      type: InvestmentTypes.ReinvestDistribution,
      dateInvested: '2022-06-16',
      dateActive: '2022-06-16',
      shareCount: 54.959,
      sharePrice: 9.338,
      status: ReinvestmentDistributionStatusTypes.Cleared,
      amount: 513.21
    }, {
      type: InvestmentTypes.Investment,
      dateInvested: '2022-06-10',
      dateActive: '2022-06-21',
      shareCount: 106.993,
      sharePrice: 9.3464,
      status: InvestmentStatusTypes.Confirmed,
      amount: 1000.0
    }, {
      type: InvestmentTypes.ReinvestDistribution,
      dateInvested: '2022-02-15',
      dateActive: '2022-02-15',
      shareCount: 53.112,
      sharePrice: 9.4628,
      status: ReinvestmentDistributionStatusTypes.Cleared,
      amount: 502.59
    }, {
      type: InvestmentTypes.ReinvestDistribution,
      dateInvested: '2021-12-09',
      dateActive: '2021-12-09',
      shareCount: 51.7,
      sharePrice: 9.5212,
      status: ReinvestmentDistributionStatusTypes.Cleared,
      amount: 492.25
    }, {
      type: InvestmentTypes.ReinvestDistribution,
      dateInvested: '2021-09-09',
      dateActive: '2021-09-09',
      shareCount: 49.362,
      sharePrice: 9.7723,
      status: ReinvestmentDistributionStatusTypes.Cleared,
      amount: 482.38
    }, {
      type: InvestmentTypes.ReinvestDistribution,
      dateInvested: '2021-06-10',
      dateActive: '2021-06-10',
      shareCount: 48.462,
      sharePrice: 9.7536,
      status: ReinvestmentDistributionStatusTypes.Cleared,
      amount: 472.68
    }, {
      type: InvestmentTypes.ReinvestDistribution,
      dateInvested: '2021-03-11',
      dateActive: '2021-03-11',
      shareCount: 47.433,
      sharePrice: 9.7654,
      status: ReinvestmentDistributionStatusTypes.Cleared,
      amount: 463.2
    }, {
      type: InvestmentTypes.ReinvestDistribution,
      dateInvested: '2020-12-10',
      dateActive: '2020-12-10',
      shareCount: 40.338,
      sharePrice: 9.8725,
      status: ReinvestmentDistributionStatusTypes.Cleared,
      amount: 398.24
    }, {
      type: InvestmentTypes.ReinvestDistribution,
      dateInvested: '2020-09-11',
      dateActive: '2020-09-11',
      shareCount: 39.494,
      sharePrice: 9.9087,
      status: ReinvestmentDistributionStatusTypes.Cleared,
      amount: 391.33
    }, {
      type: InvestmentTypes.Investment,
      dateInvested: '2020-07-13',
      dateActive: '2020-07-27',
      shareCount: 99.999,
      sharePrice: 10.0001,
      status: InvestmentStatusTypes.Confirmed,
      amount: 1000.0
    }, {
      type: InvestmentTypes.Investment,
      dateInvested: '2020-06-23',
      dateActive: '2020-07-01',
      shareCount: 100.819,
      sharePrice: 9.9188,
      status: InvestmentStatusTypes.Confirmed,
      amount: 1000.0
    }, {
      type: InvestmentTypes.ReinvestDistribution,
      dateInvested: '2020-06-11',
      dateActive: '2020-06-11',
      shareCount: 35.34,
      sharePrice: 9.9037,
      status: ReinvestmentDistributionStatusTypes.Cleared,
      amount: 350.0
    }, {
      type: InvestmentTypes.Investment,
      dateInvested: '2020-02-21',
      dateActive: '2020-03-11',
      shareCount: 2000.0,
      sharePrice: 10.0,
      status: InvestmentStatusTypes.Confirmed,
      amount: 20000.0
    }],
    tenderOfferStatus: TenderStatus.CLOSED,
    tenderOfferUserEligible: false,
    tenderOfferId: null,
    totalInvestments: 45538.74,
    totalRepaid: 7538.74,
    netTotalRepaid: 7538.74,
    fundProvider: FundProvider.Ssc,
    engine: InvestmentEngine.Funds
  },
  investmentGatesDetail: {
    requiresUserAttention: false,
    gates: [{
      id: '67063243-2318-11ed-a5b6-713a76ce3843',
      irServiceInvestmentId: '43285f61-2318-11ed-aeb5-519024f5e958',
      externalInvestmentId: 184971,
      noteUrlHash: 'LJouqg',
      investorAccountId: '57',
      adminUserId: '',
      visibility: 'ALL',
      priority: 51,
      timestamp: 1661913290,
      date: '2022-08-31',
      type: gateType.requestNotActive,
      status: 'CLEARED',
      clearReason: 'RESOLVED',
      owner: 'YS'
    }]
  },
  wallets: [],
  investmentStrategies: []
};