import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { createApi } from '@reduxjs/toolkit/query/react';
import { BASE_GRAPHQL_CONFIG } from './base-graphql-config';
import { customSerializeQuery } from './custom-serialize-query';

/**
 * Create a base API to inject endpoints into elsewhere.
 * Components using this API should import from the injected site,
 * in order to get the appropriate types,
 * and to ensure that the file injecting the endpoints is loaded
 */

export const graphqlApi = createApi(_objectSpread(_objectSpread({}, BASE_GRAPHQL_CONFIG), {}, {
  serializeQueryArgs: customSerializeQuery,
  /**
   * `reducerPath` is optional and will not be required by most users.
   * This is useful if you have multiple API definitions,
   * e.g. where each has a different domain, with no interaction between endpoints.
   * Otherwise, a single API definition should be used in order to support tag invalidation,
   * among other features
   */
  reducerPath: 'graphqlApi',
  /**
   * Tag types must be defined in the original API definition
   * for any tags that would be provided by injected endpoints
   */
  tagTypes: [],
  /**
   * This api has endpoints injected in adjacent files,
   * which is why no endpoints are shown below.
   * If you want all endpoints defined in the same file, they could be included here instead
   */
  endpoints: () => ({})
  // commenting this function out to fix the build temporarily 2023/07/24
  // extractRehydrationInfo(action, { reducerPath }) {
  //   if (action.type === 'persist/REHYDRATE') {
  //     return action.payload[reducerPath];
  //   }
  // },
}));