import { useProviderMigration } from './useProviderMigration';
import { useAuth } from '../../auth';
import { useEffect, useState } from 'react';
export const useProviderMigrationLaunch = (onLaunch, forceLaunch) => {
  const {
    isJustAuthenticated,
    isAuthenticated,
    isAuthReady
  } = useAuth();
  const {
    isMigrationActionable
  } = useProviderMigration();
  const {
    0: launched,
    1: setLaunched
  } = useState(false);
  const launchMigration = (isJustAuthenticated || forceLaunch) && isAuthReady && isMigrationActionable && !launched;
  useEffect(() => {
    if (launchMigration) {
      onLaunch();
      setLaunched(true);
    }
  }, [launchMigration, onLaunch, setLaunched]);
  useEffect(() => {
    if (!isAuthenticated && launched) {
      setLaunched(false);
    }
  }, [isAuthenticated, launched]);
  return launchMigration;
};