import CarlyleLogo from '../assets/Carlyle.png';
import GoldmanLogo from '../assets/Goldman.png';
import StepstoneLogo from '../assets/Stepstone.png';
export const logos = [{
  image: CarlyleLogo,
  alt: 'Carlyle',
  width: 260
}, {
  image: GoldmanLogo,
  alt: 'Goldman Sachs',
  width: 260
}, {
  image: StepstoneLogo,
  alt: 'Stepstone',
  width: 260
}];