export const autocompleteTable = {
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  sublocality_level_1: 'long_name',
  administrative_area_level_1: 'short_name',
  administrative_area_level_2: 'short_name',
  country: 'short_name',
  postal_code: 'short_name'
};
export const AddressFields = {
  ADDRESS_LINE_1: 'addressLine1',
  ADDRESS_LINE_2: 'addressLine2',
  CITY: 'city',
  POSTAL_CODE: 'postalCode',
  STATE: 'state',
  COUNTRY: 'country'
};