import { twoFactorAuthApi, User2FAMethod } from '@yieldstreet/platform-kit';
const {
  useStatusQuery
} = twoFactorAuthApi;
export const useTwoFaStatus = () => {
  const {
    data: {
      status,
      availableMethods
    } = {}
  } = useStatusQuery({});
  const {
    enabled,
    method,
    phone,
    available
  } = status || {};
  return {
    availableMethods: availableMethods || [User2FAMethod.AUTHY_APP, User2FAMethod.TEXT_MESSAGE],
    enabled,
    method,
    phone,
    available
  };
};