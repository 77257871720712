import _TextField from "@mui/material/TextField";
import styled from 'styled-components';
import { InputLabelStyles, FilledInputStyles } from '../text-input/styles';
/**
 * All DatePickers implement Keyboard Accessibility
 * https://mui.com/material-ui/migration/pickers-migration/#imports
 */
export const SDatePicker = styled(_TextField).withConfig({
  displayName: "styles__SDatePicker",
  componentId: "sc-tmuixy-0"
})(["& .MuiFormLabel-root{", "}& .MuiInputBase-root{", "}"], InputLabelStyles, FilledInputStyles);
export const PickerPopover = styled.div.withConfig({
  displayName: "styles__PickerPopover",
  componentId: "sc-tmuixy-1"
})(["&.MuiPaper-root{box-shadow:none;border-radius:2px;border:1px solid ", ";font-family:", ";.MuiPickersDay-root.MuiPickersDay-today{background-color:", ";border:none;}.MuiPickersDay-root.Mui-selected{background-color:", ";}}"], props => props.theme.colors.border_default, props => props.theme.typography.secondary, props => props.theme.colors.background_page, props => props.theme.colors.background_inverse);