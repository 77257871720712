var __jsx = React.createElement;
import React, { useCallback } from 'react';
import { NewestModal } from '@yieldstreet/ui-kit';
import { tiersApi } from '@yieldstreet/platform-kit';
import { Modals } from 'constants/modal.model';
import { useUserState } from 'utils/hooks';
import { content } from './TiersLetter.model';
import { TiersLetter } from './TiersLetter';
import { useTiersLetter } from './hooks/useTiersLetter';
export const TiersLetterModal = _ref => {
  let {
    onClose
  } = _ref;
  const {
    id: userId
  } = useUserState();
  const [tierOptIn] = tiersApi.useTierOptInMutation();
  const {
    setHasOptedIn,
    tierLevel
  } = useTiersLetter(content);
  const handleOnClose = useCallback(() => {
    tierOptIn({
      userId,
      tierLevel
    });
    onClose();
    setHasOptedIn(true);
  }, [onClose, setHasOptedIn, tierLevel, tierOptIn, userId]);
  return __jsx(NewestModal, {
    modalId: Modals.TiersLetter,
    onClose: handleOnClose
  }, __jsx(TiersLetter, {
    handleOnClose: handleOnClose
  }));
};