var __jsx = React.createElement;
import React from 'react';
import { AdviceQuestionKeys } from '@yieldstreet/platform-kit';
import { Label, Link } from '@yieldstreet/ui-kit';
const QualifiedPurchaserTooltip = __jsx(Label, {
  small: true
}, "The Qualified Purchaser definition for individuals is: any natural person (including any person who holds a joint, community property, or other similar shared ownership interest in an issuer that is excepted under section 3(c)(7) of the Investment Company Act with that person's qualified purchaser spouse) who owns not less than $5,000,000 in \"investments,\" as defined by the SEC. There are other definitions, including for companies and trusts that can be read in full ", __jsx(Link, {
  href: "https://www.law.cornell.edu/uscode/text/15/80a-2"
}, "here"));
const QualifiedClientTooltip = __jsx(Label, {
  small: true
}, "According to", ' ', __jsx(Link, {
  href: "https://www.law.cornell.edu/cfr/text/17/275.205-3"
}, "Rule 205-3(d)(1)"), " under the Investment Advisers Act of 1940, you are a \u201Cqualified client\u201D if you currently have a net worth (if you are an individual, together with assets held jointly with a spouse) in excess of $2.2 million. General rules for determining your net worth for this purpose: do not include your primary residence as an asset; and include only indebtedness secured by your primary residence in excess of the estimated fair market value as a liability.");
export const adviceTooltips = {
  [AdviceQuestionKeys.QualifiedClient]: QualifiedClientTooltip,
  [AdviceQuestionKeys.QualifiedPurchaser]: QualifiedPurchaserTooltip
};