var __jsx = React.createElement;
import React from 'react';
import identity from "lodash/identity";
import { genTestIds } from '@yieldstreet/tool-kit';
import accreditationIcon from '../../assets/Accreditation.svg';
import bankAccountsIcon from '../../assets/BankAccounts.svg';
import investorIcon from '../../assets/Investor.svg';
import portfoliosIcon from '../../assets/Portfolios.svg';
import { INVESTOR_ENTITY_MNGT_ACCREDITATION_PATH, INVESTOR_ENTITY_MNGT_BANK_ACT_PATH, INVESTOR_ENTITY_MNGT_INFO_PATH, INVESTOR_ENTITY_MNGT_PORTFOLIOS_PATH } from '../../ManagementContainer.model';
import { getInvestorEntityRoute } from 'pages/investorEntity/utils';
export const linksIds = {
  portfolios: 'tab-portfolios',
  information: 'tab-my-information',
  accreditation: 'tab-accreditation',
  bankAccounts: 'tab-bank-accounts',
  recurringDeposits: 'tab-recurring-deposits'
};
export const links = (investorEntityId, managedPortfolio, isEntityWalletVisible) => {
  const navLinks = [{
    id: linksIds.information,
    key: 'my-information',
    title: 'My information',
    route: getInvestorEntityRoute(investorEntityId, INVESTOR_ENTITY_MNGT_INFO_PATH),
    icon: __jsx("img", {
      src: investorIcon,
      alt: "My information"
    }),
    testId: linksIds.information
  }, isEntityWalletVisible && {
    id: linksIds.portfolios,
    key: 'portfolios',
    title: managedPortfolio ? 'Portfolios' : 'Wallets',
    route: getInvestorEntityRoute(investorEntityId, INVESTOR_ENTITY_MNGT_PORTFOLIOS_PATH),
    icon: __jsx("img", {
      src: portfoliosIcon,
      alt: "Portfolios"
    }),
    testId: linksIds.portfolios
  }, {
    id: linksIds.accreditation,
    key: 'accreditation',
    title: 'Accreditation',
    route: getInvestorEntityRoute(investorEntityId, INVESTOR_ENTITY_MNGT_ACCREDITATION_PATH),
    icon: __jsx("img", {
      src: accreditationIcon,
      alt: "Accreditation"
    }),
    testId: linksIds.accreditation,
    accreditedOnly: true
  }, {
    id: linksIds.bankAccounts,
    key: 'bank-accounts',
    title: 'Bank accounts',
    route: getInvestorEntityRoute(investorEntityId, INVESTOR_ENTITY_MNGT_BANK_ACT_PATH),
    icon: __jsx("img", {
      src: bankAccountsIcon,
      alt: "Bank accounts"
    }),
    testId: linksIds.bankAccounts
  }
  // Recurring Deposits has been excluded from the active navigation links to the current ACH Issues.
  // This is executed as part of project-wallet-genie-initiative.
  // {
  //   id: linksIds.recurringDeposits,
  //   key: 'recurring-deposits',
  //   title: 'Recurring deposits',
  //   route: getInvestorEntityRoute(investorEntityId, INVESTOR_ENTITY_MNGT_RECURRING_DEPOSITS_PATH),
  //   icon: <img src={recurringDepositsIcon} alt="Recurring deposits" />,
  //   testId: linksIds.recurringDeposits,
  // },
  ].filter(identity);
  return navLinks;
};
const {
  getTestIds
} = genTestIds(['accountType', 'accountTypeInfo', 'backButton', 'createdDate', 'createdInfo', 'heading', 'notification']);
export const testIds = getTestIds('account-layout');