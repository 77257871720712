import { HttpMethods } from '../../enums/http';
import { Tags, restApi } from '../common';
import { updateQuizAnswersQueries, updateQuizAnswerQueries } from './quiz.utils';
export const quizApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.Quiz, Tags.ManagedPortfolioEligibility]
}).injectEndpoints({
  endpoints: builder => {
    const getQuizAnswersByUserId = builder.query({
      query: params => {
        return {
          url: `/api/quiz/user/${params.userId}`,
          method: HttpMethods.Get,
          params: {
            quizKey: params === null || params === void 0 ? void 0 : params.quizKey,
            questionKeys: params === null || params === void 0 ? void 0 : params.questionKeys
          }
        };
      },
      providesTags: [Tags.Quiz]
    });
    const getQuizAnswersByInvestorEntityId = builder.query({
      query: params => {
        return {
          url: `/api/quiz/user/investor-entity/${params.investorEntityId}`,
          method: HttpMethods.Get,
          params: {
            quizKey: params === null || params === void 0 ? void 0 : params.quizKey,
            questionKeys: params === null || params === void 0 ? void 0 : params.questionKeys
          }
        };
      },
      providesTags: [Tags.Quiz]
    });
    const getQuizAnswersByQuizId = builder.query({
      query: params => {
        return {
          url: `/api/quiz/${params.quizId}`,
          method: HttpMethods.Get,
          params: {
            quizKey: params === null || params === void 0 ? void 0 : params.quizKey,
            questionKeys: params === null || params === void 0 ? void 0 : params.questionKeys
          }
        };
      },
      providesTags: [Tags.Quiz]
    });
    const postAnswers = builder.mutation({
      query: _ref => {
        let {
          answers
        } = _ref;
        return {
          url: `/api/quiz/answers`,
          method: HttpMethods.Post,
          data: {
            answers
          }
        };
      },
      // Using onQueryStarted for optimistic cache updates to compensate for eventual consistency on the backend.
      // These definitions are verbose and not testable in this form, but I don't see
      // a way to simplify them or make them more generic.
      async onQueryStarted(_ref2, _ref3) {
        let {
          answers
        } = _ref2;
        let {
          dispatch,
          queryFulfilled
        } = _ref3;
        const patchResults = updateQuizAnswersQueries(answers, dispatch);
        try {
          await queryFulfilled;
        } catch {
          patchResults.forEach(patchResult => patchResult.undo());
        }
      },
      transformResponse: result => result.data,
      invalidatesTags: [Tags.ManagedPortfolioEligibility]
    });
    const postAnswer = builder.mutation({
      query: answer => {
        return {
          url: `/api/quiz/answer`,
          method: HttpMethods.Post,
          data: answer
        };
      },
      async onQueryStarted(answer, _ref4) {
        let {
          dispatch,
          queryFulfilled
        } = _ref4;
        const patchResults = updateQuizAnswerQueries(answer, dispatch);
        try {
          await queryFulfilled;
        } catch {
          patchResults.forEach(patchResult => patchResult.undo());
        }
      },
      invalidatesTags: [Tags.ManagedPortfolioEligibility]
    });
    return {
      getQuizAnswersByUserId,
      getQuizAnswersByInvestorEntityId,
      getQuizAnswersByQuizId,
      postAnswers,
      postAnswer
    };
  }
});