import _Box from "@mui/material/Box";
import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React from 'react';
import { PortfolioCardLabel, PortfolioCardValueLoader } from '../PortfolioCard.style';
export const PortfolioCardItem = _ref => {
  let {
    children,
    isLoading,
    label
  } = _ref;
  return __jsx(_Box, null, __jsx(PortfolioCardLabel, null, label), __jsx(_Typography, {
    variant: "body2"
  }, isLoading ? __jsx(PortfolioCardValueLoader, {
    variant: "text"
  }) : children));
};