import _FormHelperText from "@mui/material/FormHelperText";
import styled from 'styled-components';
export const CheckboxesWrapper = styled.div.withConfig({
  displayName: "MultipleCheckboxesstyle__CheckboxesWrapper",
  componentId: "sc-s6ttis-0"
})(["background:", ";padding:", ";text-align:left;margin-bottom:", ";"], props => props.theme.colors.background_card, props => props.theme.spacing.sm, props => props.theme.spacing.sm);
export const CheckboxesWrapperTtitle = styled.div.withConfig({
  displayName: "MultipleCheckboxesstyle__CheckboxesWrapperTtitle",
  componentId: "sc-s6ttis-1"
})(["display:flex;flex-direction:column;margin-bottom:", ";"], props => props.theme.spacing.sm);
export const FormErrorWrapper = styled(_FormHelperText).withConfig({
  displayName: "MultipleCheckboxesstyle__FormErrorWrapper",
  componentId: "sc-s6ttis-2"
})(["&.MuiFormHelperText-root{font-family:", ";font-size:12px;line-height:14px;color:", ";&.MuiFormHelperText-contained{margin-top:0;margin-left:0;margin-right:0;}}"], props => props.theme.typography.secondary, _ref => {
  let {
    theme
  } = _ref;
  return theme.colors.notice_error;
});