import { templates, TypeOfInvestors } from './useInvestmentChips.model';
export const rules = [{
  rule: (InvestorType, minInvestment, maxInvestment) => InvestorType === TypeOfInvestors.FirstTime && minInvestment === 5000 && maxInvestment === 250000,
  template: templates.template3
}, {
  rule: (InvestorType, minInvestment, maxInvestment) => InvestorType === TypeOfInvestors.FirstTime && minInvestment === 5000 && maxInvestment === 500000,
  template: templates.template3
}, {
  rule: (InvestorType, minInvestment, maxInvestment) => InvestorType === TypeOfInvestors.FirstTime && minInvestment === 5000 && maxInvestment === 1000000,
  template: templates.template3
}, {
  rule: (InvestorType, minInvestment, maxInvestment) => InvestorType === TypeOfInvestors.FirstTime && minInvestment === 10000 && maxInvestment === 250000,
  template: templates.template3
}, {
  rule: (InvestorType, minInvestment, maxInvestment) => InvestorType === TypeOfInvestors.FirstTime && minInvestment === 10000 && maxInvestment === 1000000,
  template: templates.template3
}, {
  rule: (InvestorType, minInvestment, maxInvestment) => InvestorType === TypeOfInvestors.FirstTime && minInvestment === 10000 && (!maxInvestment || maxInvestment === 500000),
  template: templates.template4
}, {
  rule: (InvestorType, minInvestment, maxInvestment) => InvestorType === TypeOfInvestors.FirstTime && minInvestment === 10000 && (!maxInvestment || maxInvestment === 2500000),
  template: templates.template4
}, {
  rule: (InvestorType, minInvestment, maxInvestment) => InvestorType === TypeOfInvestors.FirstTime && minInvestment === 15000 && (!maxInvestment || maxInvestment === 500000),
  template: templates.template4
}, {
  rule: (InvestorType, minInvestment, maxInvestment) => InvestorType === TypeOfInvestors.FirstTime && minInvestment === 15000 && (!maxInvestment || maxInvestment === 1000000),
  template: templates.template4
}, {
  rule: (InvestorType, minInvestment, maxInvestment) => InvestorType === TypeOfInvestors.FirstTime && minInvestment === 20000 && (!maxInvestment || maxInvestment === 500000),
  template: templates.template4
}, {
  rule: (InvestorType, minInvestment, maxInvestment) => InvestorType === TypeOfInvestors.FirstTime && minInvestment === 25000 && (!maxInvestment || maxInvestment === 100000),
  template: templates.template4
}, {
  rule: (InvestorType, minInvestment, maxInvestment) => InvestorType === TypeOfInvestors.FirstTime && minInvestment === 25000 && (!maxInvestment || maxInvestment === 500000),
  template: templates.template5
}, {
  rule: (InvestorType, minInvestment, maxInvestment) => InvestorType === TypeOfInvestors.FirstTime && minInvestment === 25000 && (!maxInvestment || maxInvestment === 1000000),
  template: templates.template5
}, {
  rule: (InvestorType, minInvestment, maxInvestment) => InvestorType === TypeOfInvestors.Repeat && minInvestment === 100 && (!maxInvestment || maxInvestment === 250000),
  template: templates.template1
}, {
  rule: (InvestorType, minInvestment, maxInvestment) => InvestorType === TypeOfInvestors.Repeat && minInvestment === 1000 && (!maxInvestment || maxInvestment === 250000),
  template: templates.template2
}, {
  rule: (InvestorType, minInvestment, maxInvestment) => InvestorType === TypeOfInvestors.Repeat && minInvestment === 1000 && (!maxInvestment || maxInvestment === 500000),
  template: templates.template2
}, {
  rule: (InvestorType, minInvestment, maxInvestment) => InvestorType === TypeOfInvestors.Repeat && minInvestment === 1000 && (!maxInvestment || maxInvestment === 1000000),
  template: templates.template2
}, {
  rule: (InvestorType, minInvestment, maxInvestment) => InvestorType === TypeOfInvestors.Repeat && minInvestment === 1000 && (!maxInvestment || maxInvestment === 2500000),
  template: templates.template2
}];