import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import update from 'immutability-helper';
import * as actions from 'store/actions/quiz';
const initialState = {
  isFetching: false,
  isSubmitting: false
};
const quiz = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case actions.FETCH_QUIZ_ANSWERS_ATTEMPT:
    case actions.FETCH_USER_QUIZ_ANSWERS_ATTEMPT:
      {
        return update(state, {
          isFetching: {
            $set: true
          }
        });
      }
    case actions.FETCH_QUIZ_ANSWERS_SUCCESS:
    case actions.FETCH_USER_QUIZ_ANSWERS_SUCCESS:
      {
        const answerList = action.response;
        const quizAnswers = answerList.reduceRight((answerMap, _ref) => {
          let {
            questionKey,
            answers
          } = _ref;
          return _objectSpread({
            [questionKey]: answers
          }, answerMap);
        }, {});
        const latestAnswer = answerList.pop();
        const quizId = latestAnswer === null || latestAnswer === void 0 ? void 0 : latestAnswer.quizId;
        return update(state, {
          isFetching: {
            $set: false
          },
          quizAnswers: {
            $set: quizAnswers
          },
          quizId: {
            $set: quizId
          }
        });
      }
    case actions.FETCH_QUIZ_ANSWERS_FAILURE:
    case actions.FETCH_USER_QUIZ_ANSWERS_FAILURE:
      {
        return update(state, {
          isFetching: {
            $set: false
          }
        });
      }
    case actions.SUBMIT_QUIZ_ANSWERS_ATTEMPT:
      {
        const {
          questionKey,
          answers
        } = action;
        return update(state, {
          isSubmitting: {
            $set: true
          },
          quizAnswers: state.quizAnswers ? {
            $merge: {
              [questionKey]: answers
            }
          } : {
            $set: {
              [questionKey]: answers
            }
          }
        });
      }
    case actions.SUBMIT_QUIZ_ANSWERS_SUCCESS:
      {
        return update(state, {
          isSubmitting: {
            $set: false
          }
        });
      }
    case actions.SUBMIT_QUIZ_ANSWERS_FAILURE:
      {
        return update(state, {
          isSubmitting: {
            $set: false
          }
        });
      }
    default:
      return state;
  }
};
export default quiz;