import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["trackingId", "buttonType", "loading", "icon", "iconSide"];
var __jsx = React.createElement;
import React from 'react';
import { Spinner } from '../../layout/spinner';
import { ButtonContainer, ButtonContent, ButtonIcon, ButtonText, SpinnerWrapper } from './Button.style';
export const Button = props => {
  const {
      trackingId,
      buttonType = 'primary',
      loading,
      icon,
      iconSide = 'left'
    } = props,
    allProps = _objectWithoutProperties(props, _excluded);
  return __jsx(ButtonContainer, _extends({
    "data-cy": `ui-kit-button-container${trackingId ? `-${trackingId}` : ''}`,
    id: trackingId,
    buttonType: buttonType
  }, allProps), __jsx(ButtonContent, {
    "data-cy": "ui-kit-button-content",
    className: "ui-kit-button-content",
    buttonLoading: loading
  }, icon && __jsx(ButtonIcon, {
    iconSide: iconSide,
    disabled: props.disabled
  }, icon), __jsx(ButtonText, {
    tabIndex: -1,
    className: "ui-kit-button-text"
  }, props.children)), loading && __jsx(SpinnerWrapper, null, __jsx(Spinner, {
    className: "spinner",
    small: true,
    inverse: buttonType === 'basic' ? !props.inverse : props.inverse,
    disabled: props.disabled
  })));
};