import { ConsoleSpanExporter, SimpleSpanProcessor, BatchSpanProcessor } from '@opentelemetry/sdk-trace-base';
import * as api from '@opentelemetry/api';
import { OTLPTraceExporter } from '@opentelemetry/exporter-trace-otlp-http';
import { W3CTraceContextPropagator } from '@opentelemetry/core';
import { Resource } from '@opentelemetry/resources';
import { SEMRESATTRS_SERVICE_NAME, SEMRESATTRS_SERVICE_VERSION, SEMRESATTRS_HOST_NAME } from '@opentelemetry/semantic-conventions';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { isLocal, isServer } from 'utils/env';
import ENV_CONFIG from './utils/env/config';
const tracingEnabled = false;
// Enable/disable tracing throughout the application

const tracingDebugConsole = false;
// Enable this for tracing debugging in the browser/node consoles
// Spans will be pushed to console in real time

export const tracingDebugCollectorUrl = isLocal ?
// Enable this for tracing debugging in a local Open Telemetry Collector
// For this to work, you also need to run `yarn docker:jaeger`
// Then go to `http://localhost:16686/` to access Jaeger UI and debug traces
`http://localhost:4318/v1/traces` :
// The datadog agent will only be accessible from within the hosted cluster
// Therefor this won't work locally
`http://datadog-agent.controllers:4318/v1/traces`;
const pushSpansToConsole = tracingEnabled && isLocal && tracingDebugConsole;
const pushSpansToCollector = tracingEnabled && isLocal && tracingDebugCollectorUrl;
export const traceResource = new Resource({
  [SEMRESATTRS_SERVICE_NAME]: 'chimichurri',
  [SEMRESATTRS_SERVICE_VERSION]: '1.0',
  [SEMRESATTRS_HOST_NAME]: ENV_CONFIG.HOST
});
export default tracerProvider => {
  api.trace.setGlobalTracerProvider(tracerProvider);
  tracerProvider.register({
    contextManager: new ZoneContextManager(),
    propogator: new W3CTraceContextPropagator()
  });
  if (pushSpansToConsole) {
    tracerProvider.addSpanProcessor(new SimpleSpanProcessor(new ConsoleSpanExporter()));
  }
  if (pushSpansToCollector) {
    tracerProvider.addSpanProcessor(new BatchSpanProcessor(new OTLPTraceExporter({
      url: isServer ? tracingDebugCollectorUrl : '/traces'
    }), {
      bufferSize: 100,
      bufferTimeout: 5000
    }));
  }
};