import styled from 'styled-components';
import { media } from '../../themes/media';
import { Heading } from '../../content/text';
export const Container = styled.div.withConfig({
  displayName: "Traitsstyle__Container",
  componentId: "sc-11571jx-0"
})(["margin:0 auto;padding-top:", ";padding-bottom:", ";width:100%;", ""], _ref => {
  let {
    theme
  } = _ref;
  return theme.spacing.l;
}, _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.spacing.m;
}, media.desktop`
    padding-left: 110px;
    padding-right: 110px;
  `);
export const ContentHolder = styled.div.withConfig({
  displayName: "Traitsstyle__ContentHolder",
  componentId: "sc-11571jx-1"
})(["display:flex;justify-content:center;text-align:center;"]);
export const TraitItemWrapper = styled.div.withConfig({
  displayName: "Traitsstyle__TraitItemWrapper",
  componentId: "sc-11571jx-2"
})(["display:flex;flex-wrap:wrap;width:100%;justify-content:center;margin-top:", ";", " ", ""], _ref3 => {
  let {
    theme
  } = _ref3;
  return theme.spacing.xl;
}, props => props.blocks ? null : `column-gap: ${props.theme.spacing.m};`, media.desktop`
    justify-content: initial;
    row-gap: ${props => props.blocks ? `${props.theme.spacing.xl}` : `${props.theme.spacing['3xl']}`};
  `);
export const TextWrapper = styled.div.withConfig({
  displayName: "Traitsstyle__TextWrapper",
  componentId: "sc-11571jx-3"
})(["max-width:700px;"]);
export const SHeading = styled(Heading).withConfig({
  displayName: "Traitsstyle__SHeading",
  componentId: "sc-11571jx-4"
})(["margin-bottom:", ";"], _ref4 => {
  let {
    theme
  } = _ref4;
  return theme.spacing.sm;
});
export const TraitItem = styled.div.withConfig({
  displayName: "Traitsstyle__TraitItem",
  componentId: "sc-11571jx-5"
})(["display:flex;flex-direction:column;align-items:center;text-align:center;width:100%;", " ", ""], props => props.blocks ? `
      background-color: ${props.theme.colors.background_light};
      border: 1px solid ${props.theme.colors.border_default};
      border-radius: 4px;
      padding: ${props.theme.spacing.ml};
       ` : null, media.desktop`
    width: auto;
    flex-grow: 1;
    flex-basis: 0;
    flex: 30%;
  `);
export const TraitIcon = styled.img.withConfig({
  displayName: "Traitsstyle__TraitIcon",
  componentId: "sc-11571jx-6"
})(["width:48px;height:48px;"]);
export const Bubble = styled.div.withConfig({
  displayName: "Traitsstyle__Bubble",
  componentId: "sc-11571jx-7"
})(["display:flex;justify-content:center;align-items:center;background:linear-gradient(284.31deg,#85f1b7 10.16%,#02c9a5 90.64%);border-radius:48px;width:48px;height:48px;"]);
export const ItemHeading = styled(Heading).withConfig({
  displayName: "Traitsstyle__ItemHeading",
  componentId: "sc-11571jx-8"
})(["margin:", ";"], _ref5 => {
  let {
    theme
  } = _ref5;
  return `${theme.spacing.m} 0 ${theme.spacing.xs}`;
});
export const TraitLine = styled.div.withConfig({
  displayName: "Traitsstyle__TraitLine",
  componentId: "sc-11571jx-9"
})(["width:1px;height:156px;background-color:#d9d9d9;align-self:center;&:last-child{display:none;}"]);
export const BlocksLine = styled.div.withConfig({
  displayName: "Traitsstyle__BlocksLine",
  componentId: "sc-11571jx-10"
})(["width:1px;height:20px;border:1px solid ", ";align-self:center;&:last-child{display:none;}", ""], _ref6 => {
  let {
    theme
  } = _ref6;
  return theme.colors.border_default;
}, media.desktop`
    width: 20px;
    height: 0px;
  `);