var __jsx = React.createElement;
import React from 'react';
import { genTestIds } from '@yieldstreet/tool-kit';
import { Link } from 'sharedComponents/Links/Links';
const {
  getTestIds
} = genTestIds(['pageLoader', 'microDepositNotification', 'errorNotification', 'addButton', 'accountLimitNotification', 'externalBanksTooltip', 'ysEmail', 'add-bank-account', 'update-button', 'update-button-preferred']);
export const testIds = getTestIds('bank-accounts');
export const ACCOUNTS_MANAGEMENT_BANK_ACCOUNTS_FLOW = '/investor/manage/:investorEntityId/bank-accounts';
export const ACCOUNTS_MANAGEMENT_BANK_ACCOUNTS_FLOW_SET_DEFAULT = `${ACCOUNTS_MANAGEMENT_BANK_ACCOUNTS_FLOW}`;
export const copy = {
  empty: {
    heading: 'You have not yet linked a bank account.',
    message: 'Please complete your account set-up to invest on Yieldstreet'
  },
  pendingKyc: {
    heading: 'Account status pending',
    message: __jsx("p", null, "Please", ' ', __jsx(Link, {
      to: "#",
      id: "intercom-link"
    }, "contact Investor Relations"), ' ', "to resolve")
  },
  etcAccount: {
    heading: 'You do not need a linked bank account',
    message: 'Please contact Equity Trust to deposit or withdraw funds from your retirement account'
  }
};