import { Tags, restApi } from '../../common';
export const deleteAccountRequestApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.DeleteAccountRequest]
}).injectEndpoints({
  endpoints: builder => {
    const deleteAccountRequest = builder.mutation({
      query: () => {
        return {
          url: '/a/api/user/request-delete-account',
          method: 'POST'
        };
      },
      invalidatesTags: [Tags.DeleteAccountRequest],
      transformResponse: response => {
        return response;
      }
    });
    return {
      deleteAccountRequest
    };
  }
});