import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import trashIcon from './icons/trash.svg';
import { Paragraph } from '../../content/text';
import { Spinner } from '../../layout/spinner';
import { Notification } from '../../elements/notification';
import { useTestIds } from './FilePreviewDocument.model';
export const FilePreviewDocument = props => {
  const {
    file,
    onRemoveClick,
    hideUploadzone
  } = props;
  const testIds = useTestIds(props);
  return __jsx(React.Fragment, null, __jsx(PreviewWrapper, _extends({}, testIds.base.attr, {
    key: file.id || file.name,
    error: file.error,
    hideUploadzone: hideUploadzone
  }), __jsx(FileName, {
    small: true
  }, file.name), file.sending ? __jsx("div", null, __jsx(Spinner, {
    small: true,
    testId: testIds.loading.id
  })) : __jsx(TrashIcon, _extends({
    src: trashIcon,
    alt: "",
    onClick: onRemoveClick
  }, testIds.remove.attr))), file.error && __jsx(Notification, {
    testId: testIds.error.id,
    inline: true,
    severity: "error",
    title: file.errorTitle || '',
    description: file.errorDescription || 'An error has occurred. Please try again, and if the problem persists, contact us.'
  }));
};
const FileName = styled(Paragraph).withConfig({
  displayName: "FilePreviewDocument__FileName",
  componentId: "sc-1wv17o7-0"
})(["max-width:310px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;"]);
const PreviewWrapper = styled.div.withConfig({
  displayName: "FilePreviewDocument__PreviewWrapper",
  componentId: "sc-1wv17o7-1"
})(["display:flex;justify-content:space-between;background:", ";padding:20px;margin-bottom:10px;&:last-child{margin-bottom:0;}", " ", ""], props => props.theme.colors.background_card, props => props.error && `border: 2px solid ${props.theme.colors.notice_error};`, props => !props.hideUploadzone && 'margin-top: 10px;');
const TrashIcon = styled.img.withConfig({
  displayName: "FilePreviewDocument__TrashIcon",
  componentId: "sc-1wv17o7-2"
})(["cursor:pointer;vertical-align:middle;"]);
export default FilePreviewDocument;