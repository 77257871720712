import { useSelector } from 'react-redux';
import forOwn from "lodash/forOwn";
export const useAudiences = () => {
  const audiences = useSelector(_ref => {
    var _audiences$data;
    let {
      audiences
    } = _ref;
    return audiences === null || audiences === void 0 ? void 0 : (_audiences$data = audiences.data) === null || _audiences$data === void 0 ? void 0 : _audiences$data.audiences;
  });
  const mapAudiences = audiences => {
    const flatAudiences = {};
    audiences && forOwn(audiences, (value, key) => {
      flatAudiences[key] = value.active;
    });
    return flatAudiences;
  };
  return mapAudiences(audiences);
};
export default useAudiences;