import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useMemo, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { FormSelectInput, Button } from '@yieldstreet/ui-kit';
import { investorEntityApi } from '@yieldstreet/platform-kit';
import { USStatesSelectOptions } from 'sharedComponents/FormAddressInputGroup/StateSelection.model';
import { FormWrapper, ButtonContainer } from '../../../CreateInvestorAccount.style';
import { countriesOptions, RenderFlowWrapper } from '../KYC.utils';
import { taxFormValidation } from './TaxResidence.utils';
import { testIds } from './TaxResidence.model';
const {
  useGetInvestorEntityQuery,
  useUpdateInvestorEntityMutation
} = investorEntityApi;
export const TaxResidence = _ref => {
  var _entityData$address2;
  let {
    flowConfig
  } = _ref;
  const {
    investorEntityId
  } = useParams();
  const history = useHistory();
  const [updateInvestorEntity, updateInvestorEntityResult] = useUpdateInvestorEntityMutation();
  const {
    data: entitiesResponse,
    isLoading: isLoadingInvestorEntity,
    isFetching: isFetchingInvestorEntity
  } = useGetInvestorEntityQuery();
  const entityData = useMemo(() => {
    var _entitiesResponse$ent;
    return entitiesResponse === null || entitiesResponse === void 0 ? void 0 : (_entitiesResponse$ent = entitiesResponse.entities) === null || _entitiesResponse$ent === void 0 ? void 0 : _entitiesResponse$ent.find(entity => entity.id.toString() === investorEntityId);
  }, [entitiesResponse === null || entitiesResponse === void 0 ? void 0 : entitiesResponse.entities, investorEntityId]);
  const isLoading = isFetchingInvestorEntity || isLoadingInvestorEntity || updateInvestorEntityResult.isLoading;
  const renderForm = formikProps => {
    var _formikProps$values, _formikProps$values2, _formikProps$values3;
    const isUSCountry = ((_formikProps$values = formikProps.values) === null || _formikProps$values === void 0 ? void 0 : _formikProps$values.taxResidenceCountry) === 'US';
    return __jsx(FormWrapper, null, __jsx(FormSelectInput, {
      label: "Country of tax residence",
      placeholder: "Country of tax residence",
      name: "taxResidenceCountry",
      formikProps: formikProps,
      options: countriesOptions,
      testId: testIds.taxResidenceCountryField.id,
      fullWidth: true
    }), isUSCountry ? __jsx(FormSelectInput, {
      label: "State of tax residence",
      placeholder: "State of tax residence",
      formikProps: formikProps,
      testId: testIds.taxResidenceStateField.id,
      name: "taxResidenceState",
      options: USStatesSelectOptions,
      fullWidth: true,
      allowNone: true
    }) : null, __jsx(ButtonContainer, null, __jsx(Button, _extends({
      type: "submit",
      fullWidth: true,
      loading: updateInvestorEntityResult.isLoading,
      disabled: !((_formikProps$values2 = formikProps.values) !== null && _formikProps$values2 !== void 0 && _formikProps$values2.taxResidenceCountry) || isUSCountry && !((_formikProps$values3 = formikProps.values) !== null && _formikProps$values3 !== void 0 && _formikProps$values3.taxResidenceState) || isLoading
    }, testIds.submitButton.attr), "Continue")));
  };
  const submitCallback = useCallback(values => {
    const taxResidenceBody = values.taxResidenceCountry === 'US' && {
      stateOfTaxResidence: values.taxResidenceState
    };
    updateInvestorEntity(_objectSpread({
      investorEntityId,
      countryOfTaxResidence: values.taxResidenceCountry
    }, taxResidenceBody));
  }, [investorEntityId, updateInvestorEntity]);
  const taxResidenceConfig = useMemo(() => {
    var _entityData$address;
    return {
      validation: taxFormValidation,
      formikConfig: {
        initialValues: {
          taxResidenceCountry: (entityData === null || entityData === void 0 ? void 0 : entityData.countryOfTaxResidence) || 'US',
          taxResidenceState: (entityData === null || entityData === void 0 ? void 0 : entityData.stateOfTaxResidence) || (entityData === null || entityData === void 0 ? void 0 : (_entityData$address = entityData.address) === null || _entityData$address === void 0 ? void 0 : _entityData$address.state) || ''
        },
        enableReinitialize: false
      }
    };
  }, [entityData === null || entityData === void 0 ? void 0 : entityData.countryOfTaxResidence, entityData === null || entityData === void 0 ? void 0 : entityData.stateOfTaxResidence, entityData === null || entityData === void 0 ? void 0 : (_entityData$address2 = entityData.address) === null || _entityData$address2 === void 0 ? void 0 : _entityData$address2.state]);
  return __jsx(RenderFlowWrapper, {
    formConfig: taxResidenceConfig,
    flowConfig: flowConfig,
    submitConfig: updateInvestorEntityResult,
    submitCallback: submitCallback,
    isLoading: isLoading,
    testIds: testIds,
    history: history
  }, formikProps => renderForm(formikProps));
};