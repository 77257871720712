var __jsx = React.createElement;
import React, { useMemo } from 'react';
import get from "lodash/get";
import filter from "lodash/filter";
import { CommunicationMessageType, FetchMessagesUpdateStrategy } from '@yieldstreet/platform-kit';
import { useMessages } from '@yieldstreet/tool-kit';
import { useUserState } from 'utils/hooks';
import { Banner } from './components/Banner';
import { FeatureBanner } from './components/FeatureBanner';
import { OfferingAnnouncement } from './components/OfferingAnnouncement';
import { PopOver } from './components/PopOver';
import { SidedPhotoDesktop, SidedPhotoMobile } from './components/SidedPhoto';
import { Survey } from './components/Survey';
import { COMMUNICATION_MESSAGES_LIMIT } from './Communication.model';
const filterMessageLocation = (messages, location) => {
  return filter(messages, msg => {
    var _msg$configuration;
    return (msg === null || msg === void 0 ? void 0 : (_msg$configuration = msg.configuration) === null || _msg$configuration === void 0 ? void 0 : _msg$configuration.location) === location && !(msg !== null && msg !== void 0 && msg.dismissed);
  });
};
const getComponent = (messages, location, forceComponentType, dismissMessages) => {
  const filteredMessages = filterMessageLocation(messages, location);
  if (!filteredMessages.length) {
    return null;
  }
  const type = forceComponentType || get(filteredMessages, [0, 'configuration', 'componentType'], '');
  switch (type) {
    case 'sidedPhoto':
      return __jsx(SidedPhotoDesktop, {
        messages: filteredMessages,
        dismissMessages: dismissMessages,
        small: true
      });
    case 'sidedPhotoLarge':
      return __jsx(SidedPhotoDesktop, {
        messages: filteredMessages,
        dismissMessages: dismissMessages
      });
    case 'mobilePromoLarge':
      return __jsx(SidedPhotoMobile, {
        messages: filteredMessages,
        dismissMessages: dismissMessages
      });
    case 'mobilePromo':
      return __jsx(SidedPhotoMobile, {
        messages: filteredMessages,
        dismissMessages: dismissMessages,
        small: true
      });
    case 'offeringAnnouncement':
      return __jsx(OfferingAnnouncement, {
        messages: filteredMessages,
        dismissMessages: dismissMessages
      });
    case 'surveyBox':
      return __jsx(Survey, {
        messages: filteredMessages,
        dismissMessages: dismissMessages
      });
    case 'popOver':
      return __jsx(PopOver, {
        messages: filteredMessages,
        dismissMessages: dismissMessages
      });
    case 'featureBanner':
      return __jsx(FeatureBanner, {
        messages: filteredMessages,
        dismissMessages: dismissMessages
      });
    default:
      return __jsx(Banner, {
        messages: filteredMessages,
        dismissMessages: dismissMessages
      });
  }
};
export const Communication = _ref => {
  let {
    location = '',
    forceComponentType
  } = _ref;
  const userState = useUserState();
  const {
    authToken
  } = userState || {};
  const {
    messages,
    dismissMessages
  } = useMessages({
    fetchConfig: {
      types: [CommunicationMessageType.PlatformPromotion],
      limit: COMMUNICATION_MESSAGES_LIMIT,
      authToken,
      dismissed: false,
      updates: FetchMessagesUpdateStrategy.AGGREGATE
    },
    hookConfig: {
      autoRead: true,
      autoReadDelay: 1000
    }
  });
  const Component = useMemo(() => getComponent(messages, location, forceComponentType, dismissMessages), [messages, location, forceComponentType, dismissMessages]);
  if (!Component) {
    return null;
  }
  return __jsx(React.Fragment, null, Component);
};