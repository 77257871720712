var __jsx = React.createElement;
import React, { useMemo } from 'react';
import { Label, Paragraph } from '../../content';
import fullStar from './assets/Star.svg';
import halfStar from './assets/HalfStar.svg';
import { RateWrapper, RateAmount, Rate, AwardContainer, TextWrapper } from './Award.style';
export const Awards = _ref => {
  let {
    image,
    dataCy,
    title,
    additionalInfo,
    score = 0
  } = _ref;
  const finalStars = useMemo(() => {
    const roundedScore = Math.floor(score);
    let stars = [];
    stars = Array(roundedScore).fill(__jsx("img", {
      src: fullStar,
      alt: "full star"
    }));
    score % 1 !== 0 && stars.push(__jsx("img", {
      src: halfStar,
      alt: "half star"
    }));
    return stars;
  }, [score]);
  return __jsx(AwardContainer, {
    "data-cy": dataCy
  }, image, score ? __jsx(RateWrapper, null, __jsx(Rate, {
    "data-cy": "award-container-score-image"
  }, finalStars), __jsx(RateAmount, {
    big: true
  }, score)) : null, __jsx(TextWrapper, null, title ? __jsx(Paragraph, {
    semiBold: true,
    "data-cy": "award-container-title"
  }, title) : null, additionalInfo ? __jsx(Label, {
    "data-cy": "award-container-additional-info"
  }, additionalInfo) : null));
};