import { CALL_API } from '../middleware/Middleware';
export const GET_ALL_INVESTMENTS_ATTEMPT = 'ys/investments/ATTEMPT';
export const GET_ALL_INVESTMENTS_SUCCESS = 'ys/investments/SUCCESS';
export const GET_ALL_INVESTMENTS_FAILURE = 'ys/investments/FAILURE';
export const getAllInvestments = () => dispatch => {
  const endpoint = '/a/api/investment/all';
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_ALL_INVESTMENTS_ATTEMPT, GET_ALL_INVESTMENTS_SUCCESS, GET_ALL_INVESTMENTS_FAILURE],
      getResponse: res => res.data.investments
    }
  });
};
export const RESET_ALL_INVESTMENTS = 'ys/investments/RESET';
export const reset = () => dispatch => dispatch({
  type: RESET_ALL_INVESTMENTS
});