import { useMemo } from 'react';
import { useBankAccounts } from 'pages/investorEntity/screens/Management/routes/BankAccounts/hooks/useBankAccounts';
import { DefaultBankScreenType } from '../../DefaultBank.model';
import { usePrevious } from '@yieldstreet/tool-kit';
export const useDefaultBankScreenType = (investorEntityId, showSuccessModal) => {
  const {
    currentDefaultBank,
    confirmedBankAccounts,
    nonDegradedBankAccounts,
    microDepositBankAccounts
  } = useBankAccounts(investorEntityId);
  const prevMicroDepositAccounts = usePrevious(microDepositBankAccounts);
  return useMemo(() => {
    const bankAccountsLength = nonDegradedBankAccounts === null || nonDegradedBankAccounts === void 0 ? void 0 : nonDegradedBankAccounts.length;
    if (!bankAccountsLength) {
      return;
    }
    if (prevMicroDepositAccounts && !currentDefaultBank && (prevMicroDepositAccounts === null || prevMicroDepositAccounts === void 0 ? void 0 : prevMicroDepositAccounts.length) !== (microDepositBankAccounts === null || microDepositBankAccounts === void 0 ? void 0 : microDepositBankAccounts.length)) {
      return DefaultBankScreenType.MicroDeposit;
    }
    if ((confirmedBankAccounts === null || confirmedBankAccounts === void 0 ? void 0 : confirmedBankAccounts.length) === 1 && currentDefaultBank || showSuccessModal) {
      return DefaultBankScreenType.DefaultBank;
    }
    return currentDefaultBank ? DefaultBankScreenType.ChangeDefaultBank : DefaultBankScreenType.SetDefaultBank;
  }, [showSuccessModal, currentDefaultBank, confirmedBankAccounts === null || confirmedBankAccounts === void 0 ? void 0 : confirmedBankAccounts.length, microDepositBankAccounts === null || microDepositBankAccounts === void 0 ? void 0 : microDepositBankAccounts.length, nonDegradedBankAccounts === null || nonDegradedBankAccounts === void 0 ? void 0 : nonDegradedBankAccounts.length, prevMicroDepositAccounts]);
};