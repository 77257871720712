import { PerformanceStatus, DistributionOption } from '../../../../enums';
import { FundCapitalBalanceReductionType, FundBalanceCalculationType } from '../models';
import { InvestmentEngine, FundProvider, gateType, TenderStatus, InvestmentTypes, InvestmentStatusTypes } from '../../../../types';
export const portfolioOverviewFundYsCapitalIncomeActive = {
  userId: 492947,
  accountSummary: {
    outstandingPrincipal: 0,
    outstandingPrincipalPreDefault: 0,
    outstandingPrincipalPostDefault: 0,
    accruedInvestmentInterest: 0,
    accruedInvestmentInterestPreDefault: 0,
    accruedInvestmentInterestPostDefault: 0,
    balanceInDefault: 0,
    balanceByPerformanceStatus: {
      MODIFIED_OUTLOOK: 0.0,
      DEFAULT: 0.0,
      PERFORMING: 9149.0,
      MARINE_DEFAULT: 0.0,
      PARTIAL_DEFAULT: 0.0
    },
    investmentBalance: 9149.0,
    pendingInvestments: 0.0,
    availableBalance: 0.0,
    clearedBalance: 0.0,
    pendingDeposits: 0.0,
    reserveBalance: 0,
    total: 9149.0,
    weightedInvestmentRate: 10.0,
    irr: -98.93,
    interestEarnedLast30: 0.0,
    fundsTotal: 9149.0,
    funds: [{
      investorAccountId: '394628',
      noteUrlHash: 'LuMVuQ',
      fundCode: 'LuMVuQ',
      fundId: '37ca42a0-f667-11ed-9344-7902e6a013a4',
      total: 9149.0,
      nav: 9.149,
      navDate: '2023-07-04',
      sharesCount: 1000.0,
      canToggleDistributionOption: false,
      dividendDistributionOption: DistributionOption.Cash,
      commitmentBreakdown: null,
      fundInvestmentStatus: 'CONFIRMED',
      fundConfig: {
        fees: {
          MANAGEMENT_FEE: {
            rate: 10
          }
        },
        feesBalanceProviders: {},
        balanceCalculationType: FundBalanceCalculationType.InvestedMinusCapital,
        capitalBalanceReductionType: FundCapitalBalanceReductionType.CapitalDistributed,
        positionsEffectiveDistributionImpact: 'D_PLUS_1'
      },
      commitmentDetails: []
    }]
  },
  investorEntities: [],
  interest: {
    beginningAccruedInterest: 0,
    interestEarned: 0,
    compoundInterestEarned: 0,
    interestPaid: 0,
    fexpPaid: 0,
    fexpBalance: 0,
    netPaid: 0,
    walletInterestPaid: 0,
    compoundInterestPaid: 0,
    endingAccruedInterest: 0,
    interestEarnedPreDefault: 0,
    interestEarnedPostDefault: 0,
    earningsTimeline: []
  },
  principal: {
    beginningOutstandingPrincipal: 0,
    totalInvested: 0,
    repaidPrincipal: 0,
    endingOutstandingPrincipal: 0,
    endingOutstandingPrincipalPreDefault: 0,
    endingOutstandingPrincipalPostDefault: 0,
    liquidationPaid: 0,
    liquidationDiscount: 0,
    isInDefault: false,
    principalTimeline: []
  },
  capital: {
    beginningOutstandingCapital: 0,
    totalInvested: 9499.0,
    repaidCapital: 501.0,
    endingOutstandingCapital: 9499.0,
    endingOutstandingCapitalPreDefault: 9499.0,
    endingOutstandingCapitalPostDefault: 0.0,
    liquidationPaid: 0.0,
    liquidationDiscount: 0.0,
    isInDefault: false,
    capitalTimeline: [{
      date: '2022-12-31',
      paid: 0,
      outstandingPreDefault: 0,
      outstandingPostDefault: 0,
      capitalWriteOff: 0,
      liquidationPaid: 0,
      liquidationDiscount: 0,
      value: 0
    }, {
      date: '2023-01-31',
      paid: 0.0,
      outstandingPreDefault: 10000.0,
      outstandingPostDefault: 0.0,
      capitalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 10000.0
    }, {
      date: '2023-02-28',
      paid: 0.0,
      outstandingPreDefault: 10000.0,
      outstandingPostDefault: 0.0,
      capitalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 10000.0
    }, {
      date: '2023-03-31',
      paid: 0.0,
      outstandingPreDefault: 10000.0,
      outstandingPostDefault: 0.0,
      capitalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 10000.0
    }, {
      date: '2023-04-30',
      paid: 0.0,
      outstandingPreDefault: 10000.0,
      outstandingPostDefault: 0.0,
      capitalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 10000.0
    }, {
      date: '2023-05-31',
      paid: 0.0,
      outstandingPreDefault: 10000.0,
      outstandingPostDefault: 0.0,
      capitalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 10000.0
    }, {
      date: '2023-06-30',
      paid: 501.0,
      outstandingPreDefault: 9499.0,
      outstandingPostDefault: 0.0,
      capitalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 10000.0
    }, {
      date: '2023-07-05',
      paid: 501.0,
      outstandingPreDefault: 9499.0,
      outstandingPostDefault: 0.0,
      capitalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 10000.0
    }]
  },
  investmentDetail: {
    fundId: '37ca42a0-f667-11ed-9344-7902e6a013a4',
    fundType: 'INTERNAL_FUND',
    noteUrlHash: 'LuMVuQ',
    title: 'Distribution Split Test 3',
    distributionRate: 10.0,
    assetClass: 'ARTF',
    assetClassDescription: 'Art',
    paymentType: 'Event-Based',
    termPhrase: '10 Mo.',
    advisor: 'YieldStreet Management LLC',
    subAdvisor: null,
    interestRate: 0,
    taxDocument: '1099-INT',
    taxMonth: 'JANUARY',
    firstYearExpense: 10,
    annualFlatExpense: 10,
    irr: 0.0,
    investedDate: '2023-01-01',
    activeDate: '2023-01-01',
    launchedDate: '2023-05-19',
    terminationDate: '2024-05-01',
    interestDefaultedDate: null,
    principalDefaultedDate: null,
    dateOfFirstDefault: null,
    originator: null,
    dividendDistributionOption: DistributionOption.Cash,
    canToggleDistributionOption: false,
    performanceStatus: PerformanceStatus.PERFORMING,
    performanceStatusNote: '',
    spvType: null,
    offeringData: {},
    investments: [{
      type: InvestmentTypes.Investment,
      dateInvested: '2023-05-19',
      dateActive: '2023-01-01',
      shareCount: 1000.0,
      sharePrice: 10,
      status: InvestmentStatusTypes.Confirmed,
      amount: 10000.0
    }],
    tenderOfferStatus: TenderStatus.UNAVAILABLE,
    tenderOfferUserEligible: false,
    tenderOfferId: null,
    totalInvestments: 10000.0,
    totalRepaid: 432.84,
    netTotalRepaid: 432.84,
    fundProvider: FundProvider.Ys,
    engine: InvestmentEngine.Funds
  },
  investmentGatesDetail: {
    requiresUserAttention: false,
    gates: [{
      id: 'f1e58a50-f667-11ed-8ae0-bbf9adaaee93',
      irServiceInvestmentId: 'eaca3c20-f667-11ed-8ae0-bbf9adaaee93',
      externalInvestmentId: 154854,
      noteUrlHash: 'LuMVuQ',
      investorAccountId: '394628',
      adminUserId: '',
      visibility: 'ALL',
      priority: 51,
      timestamp: 1684516520,
      date: '2023-05-19',
      type: gateType.requestNotActive,
      status: 'CLEARED',
      clearReason: 'RESOLVED',
      owner: 'YS'
    }]
  },
  wallets: [],
  investmentStrategies: []
};