var __jsx = React.createElement;
import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { fetchFlags } from 'store/actions/flags';
import { isServer } from 'utils/env';
import { useServerPreload } from '../hooks/useServerPreload';
export const withFlagsPrefetch = WrappedComponent => props => {
  const PrefetchComponent = useCallback(() => {
    const dispatch = useDispatch();
    useServerPreload('fetch-flags', async () => {
      if (isServer) {
        return await dispatch(fetchFlags());
      }
      return await Promise.resolve();
    });
    return __jsx(WrappedComponent, props);
  }, [props]);
  return PrefetchComponent();
};