import styled from 'styled-components';
import { LinkStyles } from '@yieldstreet/ui-kit';
export const ContentWrapper = styled.div.withConfig({
  displayName: "ETCAgreementstyle__ContentWrapper",
  componentId: "sc-1hs163c-0"
})(["margin:auto;"]);
export const TextWrapper = styled.div.withConfig({
  displayName: "ETCAgreementstyle__TextWrapper",
  componentId: "sc-1hs163c-1"
})(["display:flex;flex-direction:column;gap:20px;"]);
export const SupportLink = styled.a.withConfig({
  displayName: "ETCAgreementstyle__SupportLink",
  componentId: "sc-1hs163c-2"
})(["", ""], LinkStyles);
export const ButtonWrapper = styled.div.withConfig({
  displayName: "ETCAgreementstyle__ButtonWrapper",
  componentId: "sc-1hs163c-3"
})(["margin:", " auto 0 auto;display:flex;flex-direction:column;gap:", ";max-width:300px;"], _ref => {
  let {
    theme
  } = _ref;
  return theme.spacing.ml;
}, _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.spacing.sm;
});