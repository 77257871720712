export const trackInvestmentCopy = {
  amount: {
    label: 'Amount'
  },
  accountName: {
    label: 'Account name'
  },
  accountNumber: {
    label: 'Account number'
  },
  noteFieldShortId: {
    label: 'Memo'
  },
  routingNumber: {
    label: 'Routing number',
    content: '026-073-066'
  },
  bankInformation: {
    label: 'Bank information',
    content: 'Esquire Bank, 100 Jericho Quadrangle, Jericho, NY 11753'
  },
  accountAddress: {
    label: 'Account address',
    content: 'Yieldstreet, 300 Park Ave, 15th Floor, New York, NY 10022'
  },
  transferAmount: {
    label: 'Transfer amount'
  },
  aCopyOf: 'A copy of these instructions will be sent to {{email}}.',
  footerText: 'If you wish to modify your funding method, please reach out to our Investor Relations team at',
  to: {
    label: 'To',
    achMessage: 'Your Yieldstreet Wallet'
  },
  from: {
    label: 'From'
  },
  ACH: {
    footnote1: `You have authorized Yieldstreet to debit the account indicated for the amount noted on {{ACHInitiationDate}}, or thereafter, subject to the investment confirmation cadence for this investment. 
    Please ensure that required funds are available in your account.`,
    footnote2: `If you wish to modify or cancel this transaction, or have any questions, email us ASAP at investments@yieldstreet.com or call (844) 943-5378.`,
    footnote3: `
    You have agreed to be bound by all applicable terms and provisions of the NACHA Rules as they pertain to these transactions. 
    Transactions made after 3:00 PM ET or on a weekend or holiday will be processed the next business day. 
    It is recommended that you print a copy of this authorization and maintain it for your records.`
  },
  linkingBankRequired: 'Linking a bank is required before submitting your transfer.'
};