import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["leftHeading", "rightHeading", "rowData"];
var __jsx = React.createElement;
import React, { useMemo } from 'react';
import camelCase from "lodash/camelCase";
import Stack from '@mui/material/Stack';
import { ProgressTableRow } from './components/progress-table-row';
import { useTestIds, getTestIds } from './ProgressTable.model';
import { HeaderRow, LeftHeaderElement, RightHeaderElement } from './ProgressTable.style';
export const ProgressTable = _ref => {
  let {
      leftHeading,
      rightHeading,
      rowData
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  const testIds = useTestIds(props);
  const renderRows = useMemo(() => {
    return rowData.map((row, idx) => {
      const lastRow = idx === rowData.length - 1;
      return __jsx(ProgressTableRow, _extends({}, row, {
        lastRow: lastRow,
        key: idx,
        testId: `${testIds.row.id}-${camelCase(row.title)}`
      }));
    });
  }, [rowData, testIds.row.id]);
  return __jsx(Stack, testIds.base.attr, __jsx(HeaderRow, testIds.header.attr, __jsx(LeftHeaderElement, _extends({
    variant: "body1"
  }, testIds.headerLeft.attr), leftHeading), __jsx(RightHeaderElement, _extends({
    variant: "body1",
    color: "textQuaternary"
  }, testIds.headerRight.attr), rightHeading)), renderRows);
};
ProgressTable.getTestIds = getTestIds;