var __jsx = React.createElement;
import React, { useMemo } from 'react';
import { SkeletonThumbnail } from '../../elements/skeleton/SkeletonThumbnail';
import { SkeletonLine } from '../../elements/skeleton/SkeletonLine';
import { OfferingCardBase } from './base-layout/OfferingCardBase';
import { getTestIds } from './OfferingCardV2Skeleton.model';
import { AssetClassWrapperSkeleton, BadgeContainerSkeleton, BadgeSkeleton, ContentContainerSkeleton, MainContainerSkeleton, OfferingThumbSkeleton, TitleContainerSkeleton } from './OfferingCardV2Skeleton.style';
export const OfferingCardV2Skeleton = () => {
  const testIds = getTestIds('cardSkeleton');
  const ThumbComponent = useMemo(() => {
    return __jsx(OfferingThumbSkeleton, null, __jsx(SkeletonThumbnail, {
      active: true
    }));
  }, []);
  const MainComponent = useMemo(() => __jsx(MainContainerSkeleton, null, __jsx(TitleContainerSkeleton, null, __jsx(AssetClassWrapperSkeleton, null, __jsx(SkeletonLine, {
    filled: true,
    active: true
  })), __jsx(SkeletonLine, {
    filled: true,
    active: true
  })), __jsx(BadgeContainerSkeleton, null, __jsx(BadgeSkeleton, null, __jsx(SkeletonLine, {
    filled: true,
    active: true
  })))), []);
  const ContentComponent = useMemo(() => {
    return __jsx(ContentContainerSkeleton, null, __jsx(SkeletonLine, {
      filled: true,
      active: true
    }), __jsx(SkeletonLine, {
      filled: true,
      active: true
    }), __jsx(SkeletonLine, {
      filled: true,
      active: true
    }));
  }, []);
  return __jsx(OfferingCardBase, {
    topComponent: ThumbComponent,
    mainComponent: MainComponent,
    contentComponent: ContentComponent,
    testId: testIds.base.id
  });
};