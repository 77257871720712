import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["onClick", "children", "href", "newTab", "bgColor", "className"];
var __jsx = React.createElement;
import React from 'react';
import { useTestIds } from './Card.model';
import { CardBaseWrapper } from './Card.style';
export const Card = _ref => {
  let {
      onClick,
      children,
      href,
      newTab,
      bgColor,
      className
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  const isLink = !!href;
  const testIds = useTestIds(props);
  return __jsx(CardBaseWrapper, _extends({
    className: className
  }, testIds.card.attr, {
    isLink: isLink,
    href: href,
    clickable: isLink || !!onClick,
    target: newTab ? '_blank' : '_self',
    onClick: () => {
      onClick && onClick();
    },
    bgColor: bgColor
  }), children);
};