var __jsx = React.createElement;
import React from 'react';
import { CallbackLink } from './CallbackLink';
export const AdaAssistantLink = _ref => {
  let {
    children,
    className
  } = _ref;
  return __jsx(CallbackLink, {
    id: "intercom-link",
    className: className
  }, children);
};