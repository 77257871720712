import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { CALL_API } from '../middleware/Middleware';
export const GET_QUARTERLY_REPORT_ATTEMPT = 'ys/quarterly/ATTEMPT';
export const GET_QUARTERLY_REPORT_SUCCESS = 'ys/quarterly/SUCCESS';
export const GET_QUARTERLY_REPORT_FAILURE = 'ys/quarterly/FAILURE';
export const getReport = (year, quarter, urlHash) => dispatch => {
  const endpoint = `/a/api/quarterly-report/${year}/${quarter}/${urlHash}`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_QUARTERLY_REPORT_ATTEMPT, GET_QUARTERLY_REPORT_SUCCESS, GET_QUARTERLY_REPORT_FAILURE],
      getResponse: res => res.data
    }
  });
};

// Selectors (this is legacy, since we don't use selectors lib will leave it here)

const toPerc = number => Math.round(number * 100);
export const getOfferingStatus = state => {
  return {
    deal: state.deal,
    offeringQuarter: state.quarter,
    offeringYear: state.year,
    dateFunded: state.summary.fundingDate,
    investmentSize: state.summary.investmentSize,
    duration: state.summary.duration,
    annualTargetInterest: state.summary.annualTargetInterest,
    caseLocations: state.summary.casesLocations,
    type: state.reportType,
    portfolioComponents: state.portfolioComponents
  };
};
export const getOfferingProgress = state => {
  return {
    offeringQuarter: state.quarter,
    interestPaidQuarter: state.progress.interest.quarter,
    interestPaidTotal: state.progress.interest.total,
    numberOfPayments: state.progress.interest.payments
  };
};
export const getExpectedTermChartData = state => {
  return {
    total: state.progress.term.chartLabel,
    completeness: toPerc(state.progress.term.percentComplete)
  };
};
export const getTotalPrincipalChartData = (state, theme) => {
  const principalPayment = [{
    name: `PAST QUARTERS - ${state.progress.principal.pastQuartersLabel}`,
    y: state.progress.principal.pastQuartersPercent,
    color: theme.colors.primaryAlt
  }, {
    name: `${state.quarter} ${state.year} - ${state.progress.principal.quarterLabel}`,
    y: state.progress.principal.quarterPercent,
    color: theme.colors.tertiary
  }, {
    name: '',
    y: state.progress.principal.remainingPercent,
    color: theme.colors.gray5
  }];
  return {
    total: state.progress.principal.chartLabel,
    timeserie: principalPayment
  };
};
export const getTotalCasesChartData = state => {
  const pComp = state.portfolioComponents;
  return {
    total: pComp.totalCases,
    timeserie: [{
      name: 'Settled',
      y: toPerc(pComp.settledCasesPastQuartersPercent),
      color: 'rgba(43, 184, 192, 1)',
      numberOfCases: `- ${pComp.settledCasesPastQuarters} Cases`
    }, {
      name: `${state.quarter}`,
      y: toPerc(pComp.settledCasesThisQuarterPercent),
      color: 'rgba(229, 207, 10, 1)',
      numberOfCases: `- ${pComp.settledCasesThisQuarter} Cases`
    }, {
      name: 'Open',
      y: toPerc(pComp.openCasesPercent),
      color: 'rgba(121, 76, 205, 1)',
      numberOfCases: `- ${pComp.openCases} Cases`
    }]
  };
};
const chartColors = idx => {
  const colors = [{
    color: 'rgba(229, 207, 10, 1)'
  }, {
    color: 'rgba(121, 76, 205, 1)'
  }, {
    color: 'rgba(43, 184, 192, 1)'
  }, {
    color: 'rgba(208, 2, 27, 1)'
  }, {
    color: 'rgba(121, 76, 205, 1)'
  }];
  return colors[idx % colors.length];
};
export const getCaseLocationChartData = state => {
  const pComp = state.summary.caseLocations || {};
  return Object.keys(pComp).map((key, idx) => _objectSpread({
    name: `${key}`,
    y: toPerc(pComp[key])
  }, chartColors(idx)));
};
export const getCaseLocationDetailsChartData = state => {
  const pComp = state.summary.locations || {};
  return Object.keys(pComp).map((key, idx) => ({
    name: `${key}`,
    img: pComp[key]
  }));
};
const getCases = (label, prop, state) => {
  const keys = Object.keys(state.portfolioComponents[prop]);
  const statistics = keys.map(key => {
    return {
      key,
      value: toPerc(state.portfolioComponents[prop][key])
    };
  });
  return {
    label,
    statistics
  };
};
const getLocations = (label, prop, state) => {
  const keys = Object.keys(state.summary[prop]);
  const statistics = keys.map(key => {
    return {
      key,
      value: toPerc(state.summary[prop][key])
    };
  });
  return {
    label,
    statistics
  };
};
export const getTotalCasesSettled = state => getCases('Total Cases Settled (By Type)', 'settledCasesTypes', state);
export const getTotalCasesOpen = state => getCases('Total Active Cases (By Type)', 'openCasesTypes', state);
export const getCasesLocations = state => getLocations('Case Location Breakdown', 'caseLocations', state);