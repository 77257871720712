import _Box from "@mui/material/Box";
import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React from 'react';
import { ModalWrapper } from './InfoModal.style';
export const InfoModal = _ref => {
  let {
    tooltip
  } = _ref;
  return __jsx(ModalWrapper, {
    "data-cy": "tiered-rate-modal"
  }, __jsx(_Typography, {
    component: "h1",
    variant: "h4"
  }, tooltip === null || tooltip === void 0 ? void 0 : tooltip.tooltipTitle), (tooltip === null || tooltip === void 0 ? void 0 : tooltip.tooltipSubtitle) && __jsx(_Typography, {
    component: "span",
    variant: "body2"
  }, tooltip.tooltipSubtitle), (tooltip === null || tooltip === void 0 ? void 0 : tooltip.tooltipImage) && __jsx(_Box, {
    component: "img",
    src: tooltip.tooltipImage,
    sx: {
      width: '100%',
      marginTop: theme => theme.spacing(7.5),
      aspectRatio: '3 / 2',
      objectFit: 'contain'
    }
  }), (tooltip === null || tooltip === void 0 ? void 0 : tooltip.tooltipDescription) && __jsx(_Typography, {
    component: "p",
    variant: "body2",
    sx: {
      mt: 7.5
    }
  }, tooltip.tooltipDescription));
};