import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { Button, PageFlow, Paragraph, Title } from '@yieldstreet/ui-kit';
import { BusinessEvent, eventManager } from '@yieldstreet/tool-kit';
import { campaignApi, CampaignStatus } from '@yieldstreet/platform-kit';
import { useUserState } from 'utils/hooks';
import { skipToken } from '@reduxjs/toolkit/query';
import { useHistory } from 'react-router';
import { Form, Formik } from 'formik';
import forEach from "lodash/forEach";
import { testIds } from './ManagementFeeConsentVote.model';
import { ContentWrapper } from '../ManagementFeeConsentStart/ManagementFeeConsentStart.style';
import InvestmentVoteList from './InvestmentVoteList/InvestmentVoteList';
import InvestmentVoteListLoading from './InvestmentVoteList/InvestmentVoteListLoading';
import { ButtonWrapper, ButtonContainer } from './ManagementFeeConsentVote.style';
import ManagementConsentVoteSubmitted from '../ManagementFeeConsentVoteSubmitted/ManagementFeeConsentVoteSubmitted';
import { VOTE_VALUES } from './InvestmentVote/InvestmentVote.model';
const {
  useGetAllCampaignEligibilityQuery,
  useSubmitAllCampaignVotesMutation
} = campaignApi;
const ManagementFeeConsentVote = () => {
  const {
    id: userId = null
  } = useUserState();
  const history = useHistory();
  const [submitVotes, {
    isLoading: isSubmitting,
    isSuccess: isSubmitted
  }] = useSubmitAllCampaignVotesMutation();
  const {
    votes,
    isLoading,
    isSuccess
  } = useGetAllCampaignEligibilityQuery(userId ? {
    userId
  } : skipToken, {
    selectFromResult: _ref => {
      var _data$votes;
      let {
        data,
        isLoading,
        isSuccess
      } = _ref;
      const votes = [];
      data === null || data === void 0 ? void 0 : (_data$votes = data.votes) === null || _data$votes === void 0 ? void 0 : _data$votes.forEach(vote => {
        if (vote.status === CampaignStatus.Computed || votes.findIndex(x => x.campaignId === vote.campaignId) > -1) {
          return;
        }
        return votes.push(vote);
      });
      return {
        isLoading,
        isSuccess,
        votes
      };
    }
  });
  const handleOnSubmit = values => {
    const submittingVotes = [],
      trackingVotes = [];
    forEach(votes, eligibleVote => {
      const voted = values[eligibleVote.campaignId];
      submittingVotes.push({
        campaignId: eligibleVote.campaignId,
        value: (voted === null || voted === void 0 ? void 0 : voted.vote) || VOTE_VALUES.reject
      });
      trackingVotes.push({
        noteUrlHash: eligibleVote.noteUrlHash,
        value: (voted === null || voted === void 0 ? void 0 : voted.vote) || VOTE_VALUES.reject
      });
    });
    submitVotes({
      userId: userId || 0,
      votes: submittingVotes
    });
    eventManager.track(BusinessEvent.USER_CAMPAIGN_VOTE, {
      voteList: trackingVotes,
      campaignAggegateId: votes === null || votes === void 0 ? void 0 : votes[0].campaignAggregateId
    });
  };
  if (isSubmitted || !(votes !== null && votes !== void 0 && votes.length) && isSuccess) {
    return __jsx(ManagementConsentVoteSubmitted, null);
  }
  return __jsx(PageFlow, {
    backNavigation: true,
    backNavigationCallback: () => history.goBack(),
    tabletMaxWidth: "700px"
  }, __jsx(ContentWrapper, null, isLoading && __jsx(InvestmentVoteListLoading, null), !isLoading && __jsx(React.Fragment, null, __jsx(Title, testIds.title.attr, "Submit your vote"), __jsx(Paragraph, {
    marginBottom: true
  }, "We believe the proposed amendment puts Yieldstreet in the best possible operating position. Although we hope you will approve it, the decision to vote for or against any proposed amendment is always your personal choice."), __jsx(Paragraph, {
    marginBottom: true
  }, "To view a comparison of the new Operating Agreement terms to the old, click the investment name below."), __jsx(Formik, {
    initialValues: {},
    enableReinitialize: true,
    onSubmit: handleOnSubmit
  }, formikProps => __jsx(Form, null, __jsx(InvestmentVoteList, {
    formikProps: formikProps,
    votes: votes || []
  }), __jsx(ButtonContainer, null, __jsx(ButtonWrapper, null, __jsx(Button, _extends({
    loading: isSubmitting
  }, testIds.submitVoteButton.attr, {
    type: "submit"
  }), "Submit vote"))))))));
};
export default ManagementFeeConsentVote;