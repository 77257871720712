import axios from 'axios';
import update from 'immutability-helper';

// Actions
export const FETCH = 'ys/portfolio/FETCH';
export const FETCHED = 'ys/portfolio/FETCHED';
export const FETCHING = 'ys/portfolio/FETCHING';
export const RESET_INVESTMENTS = 'ys/portfolio/RESET_INVESTMENTS';

// Initial State
export const initialState = {
  allInvestmentsById: {},
  allInvestmentsByIdFetching: false,
  investments: [],
  investorUpdates: {},
  isFetchingInvestorUpdates: false,
  isFetching: false,
  activeInvestments: null,
  activeInvestmentsFetching: false,
  pendingInvestments: null,
  pendingInvestmentsFetching: false,
  scheduledInvestments: null,
  assetClass: {},
  userWallet: {},
  userWalletFetching: true
};
export const portfolioAnonymizer = {
  paymentsFilters: ['object', {
    investors: ['array', 'investor']
  }]
};

// Reducer
export default function reducer() {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  let {
    type,
    data
  } = action;
  switch (type) {
    case FETCHING:
      return update(state, {
        isFetching: {
          $set: data
        }
      });
    case FETCHED:
      {
        return update(state, {
          $merge: data
        });
      }
    case RESET_INVESTMENTS:
      return update(state, {
        allInvestmentsById: {
          $set: {}
        }
      });
    default:
      return state;
  }
}

// Action creators
export function fetched(data) {
  return {
    type: FETCHED,
    data
  };
}
export function fetchingStatus(data) {
  return {
    type: FETCHING,
    data
  };
}
export const isFetching = () => fetchingStatus(true);
export const reset = () => fetched(initialState);
export const getActiveInvestmentDetails = function (userId, investorId, accountId, urlHash) {
  let startString = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
  let endString = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : null;
  const hasFilters = startString && endString,
    filterKey = hasFilters ? `${userId}_${investorId}_${startString}_${endString}` : 'default',
    url = hasFilters ? '/a/api/portfolio/details/' + encodeURI(`${urlHash}|${accountId}?start=${startString}&end=${endString}`) : '/a/api/portfolio/details/' + encodeURI(`${urlHash}|${accountId}`);
  return function (dispatch, getStore) {
    let store = getStore(),
      activeInvestments = Object.assign({}, store.portfolio.activeInvestments);
    dispatch(fetched({
      activeInvestmentsFetching: true
    }));
    return axios.get(url).then(res => {
      if (!activeInvestments) activeInvestments = {};
      if (!activeInvestments[accountId]) activeInvestments[accountId] = {};
      if (!activeInvestments[accountId || 'ALL']) activeInvestments[accountId || 'ALL'] = {};
      activeInvestments[accountId][filterKey] = res.data;
      dispatch(fetched({
        activeInvestments,
        activeInvestmentsFetching: false
      }));
      return res.data;
    });
  };
};