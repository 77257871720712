import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
const excludedKeys = ['ad_id', 'intercom_user_hash_web', 'intercom_user_hash_android', 'intercom_user_hash_ios', 'signup_quiz_id', 'campaign_id', 'segment_anonymous_id'];
const buildMetaData = userMetaData => {
  const metaKeys = Object.entries(userMetaData);
  const filteredMetaData = metaKeys.filter(_ref => {
    let [key] = _ref;
    return !excludedKeys.includes(key);
  });
  return Object.fromEntries(filteredMetaData);
};
const getUserMetaData = userMetaData => {
  if (!userMetaData) {
    return {};
  }
  return {
    meta: buildMetaData(userMetaData)
  };
};

/**
 * ensure that the date is in the correct format "RFC-3339"
 * https://docs.launchdarkly.com/sdk/concepts/flag-types#representing-datetime-values
 */

const formatDate = date => date && new Date(date).toISOString();
export const getExtraUserContext = userState => {
  if (!userState) {
    return {};
  }
  return _objectSpread(_objectSpread({}, getUserMetaData(userState === null || userState === void 0 ? void 0 : userState.userMeta)), {}, {
    createdAt: formatDate(userState === null || userState === void 0 ? void 0 : userState.createdAt)
  });
};