import styled from 'styled-components';
import { Button } from '@yieldstreet/ui-kit';
export const FiltersButton = styled(Button).withConfig({
  displayName: "PortfolioFiltersButtonstyle__FiltersButton",
  componentId: "sc-1369rzp-0"
})(["display:flex;align-items:center;justify-content:center;margin-left:8px;border:1px solid ", ";background:", ";&:first-child{margin-left:0;fill:", ";}&:last-child{border:none;}", " > span{display:flex;align-items:center;justify-content:center;> span{display:flex;align-items:center;justify-content:center;}}"], _ref => {
  let {
    theme
  } = _ref;
  return theme.colors.border_default;
}, _ref2 => {
  let {
    buttonType,
    theme
  } = _ref2;
  return buttonType === 'secondary' ? theme.colors.background_card : '';
}, _ref3 => {
  let {
    theme
  } = _ref3;
  return theme.colors.icon_default;
}, _ref4 => {
  let {
    isMobile,
    theme
  } = _ref4;
  return isMobile && `
    border-radius: 12px;
    min-width: unset;
    width: 44px;
    height: 44px;
    padding: 0;

    &:first-child:hover {
      border-color: ${theme.colors.border_default} !important;
    }
  `;
});