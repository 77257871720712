import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useEffect } from 'react';
import { LoginResultStatus } from '@yieldstreet/platform-kit';
import { useAuth } from '@yieldstreet/tool-kit';
import { useSignupQuiz } from 'pages/signup/signupQuiz/hooks/useSignupQuiz';
import { LOGIN_LINK_IDENTITY_ROUTE, LOGIN_TWO_FA_ROUTE, WELCOME_ROUTE } from './useAuth.model';
import { useAuthWelcome } from './useAuthWelcome';
import { useAuthRedirect } from './useAuthRedirect';
import { useAuthCompleted } from './useAuthCompleted';
import { useAuthTracking } from './useAuthTracking';
export const useAuthFlow = authResult => {
  const authState = useAuth();
  const {
    isAuthLoading,
    isAuthenticated,
    isSignupSession
  } = authState;
  const isAuthenticating = authResult || isAuthLoading;
  const {
    clearSignupQuiz
  } = useSignupQuiz();
  const welcome = useAuthWelcome();
  const redirectTo = useAuthRedirect();
  const completeAuth = useAuthCompleted();
  const trackAuth = useAuthTracking();
  useEffect(() => {
    if (authResult) {
      const {
        require2FA,
        code
      } = authResult;
      if (isAuthenticated) {
        if (isSignupSession) {
          clearSignupQuiz();
        }

        // Check if user to be shown welcome page
        if (welcome) {
          // Redirect to welcome page
          const welcomeRoute = `${WELCOME_ROUTE}/${welcome}`;
          redirectTo(welcomeRoute, true);
        } else {
          // Redirect to YS experience
          completeAuth();
          trackAuth();
        }
      } else {
        // User not logged in therefor check if 2FA required
        // Redirect to login in this case

        if (code === LoginResultStatus.LIMITED) {
          redirectTo(LOGIN_LINK_IDENTITY_ROUTE, true);
        } else if (require2FA) {
          redirectTo(LOGIN_TWO_FA_ROUTE, true);
        }
      }
    }
  }, [redirectTo, completeAuth, trackAuth, welcome, authResult, isAuthenticated, isSignupSession, clearSignupQuiz]);
  return _objectSpread({
    isAuthenticating
  }, authState);
};