var __jsx = React.createElement;
import React from 'react';
import { Link } from 'react-router-dom';
import inverseLock from '@yieldstreet/ui-kit/assets/graphics/icons/lock_encrypted_inverse.svg';
import { Interpolation } from '@yieldstreet/tool-kit';
import { AdaAssistantLink, Heading, Paragraph } from '@yieldstreet/ui-kit';
import { Lock, ReservedMessage, ReservedTitle } from '../OfferingHeroV5.style';
import { ADVICE_PREFERENCES_PATH } from 'pages/accountSettings/advice';
import { gateTexts, testIds } from './Gate.model';
const getInterpolatedGateCopy = (copy, attributes) => {
  const {
    investorEntityId = ''
  } = attributes || {};
  return __jsx(Interpolation, {
    template: copy,
    values: {
      createInvestorAccount: __jsx(Link, {
        to: "/investor/manage"
      }, gateTexts.createInvestorAccount),
      verifyIdentity: __jsx(Link, {
        to: `/investor/manage/${investorEntityId}`
      }, gateTexts.verifyIdentity),
      addBankAccount: __jsx(Link, {
        to: `/investor/manage/${investorEntityId}/bank-accounts`
      }, gateTexts.addBankAccount),
      verifyMicroDeposit: __jsx(Link, {
        to: `/investor/manage/${investorEntityId}/bank-accounts`
      }, gateTexts.verifyMicroDeposit),
      investmentPreferencesLink: __jsx(Link, {
        to: ADVICE_PREFERENCES_PATH
      }, gateTexts.investmentPreferencesLink),
      contactIR: __jsx(AdaAssistantLink, null, gateTexts.contactIR),
      usStateName: attributes === null || attributes === void 0 ? void 0 : attributes.usStateName,
      accreditationStatusLink: __jsx(Link, {
        to: "/resources/article/how-to-become-an-accredited-investor"
      }, gateTexts.accreditationStatusLink),
      contactSupport: __jsx(AdaAssistantLink, null, gateTexts.contactSupport)
    }
  });
};
export const Gate = _ref => {
  let {
    altStyling,
    gateCopy,
    attributes
  } = _ref;
  return __jsx(ReservedMessage, {
    backgroundColor: altStyling && 'background_inverse',
    "data-cy": testIds.gateMessage
  }, __jsx(ReservedTitle, {
    "data-cy": testIds.gateTitle
  }, __jsx(Lock, {
    src: inverseLock
  }), __jsx(Heading, {
    inverse: true,
    type: altStyling ? 5 : 4
  }, gateCopy.title)), __jsx(Paragraph, {
    small: true,
    inverse: true,
    "data-cy": testIds.gatingMessageDescription
  }, getInterpolatedGateCopy(gateCopy.description, attributes)));
};