import { useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import isEmpty from "lodash/isEmpty";
import { paymentsApi } from '@yieldstreet/platform-kit';
import { getDatesForRange, DateRanges } from '../../utils';
import { ALL_TOKEN } from './usePaymentActivity.model';
export const usePaymentActivity = _ref => {
  let {
    userId,
    investorAccountId,
    noteUrlHash
  } = _ref;
  const [startDate, endDate] = getDatesForRange({
    range: DateRanges.LifeTime
  });
  const paymentActivityQueryConfig = useMemo(() => {
    if (!investorAccountId || !userId || !noteUrlHash) {
      return skipToken;
    }
    return {
      userId,
      investorId: investorAccountId === ALL_TOKEN ? undefined : investorAccountId,
      start: startDate,
      noteUrlHash,
      end: endDate
    };
  }, [endDate, startDate, investorAccountId, noteUrlHash, userId]);
  const {
    data,
    isLoading,
    isFetching,
    isError
  } = paymentsApi.useGetPaymentActivityQuery(paymentActivityQueryConfig);
  const isLoaded = useMemo(() => !isFetching && !isError, [isFetching, isError]);
  const isDataEmpty = useMemo(() => isLoaded && isEmpty(data === null || data === void 0 ? void 0 : data.activity), [isLoaded, data]);
  return {
    data,
    isLoading,
    isFetching,
    isError,
    isLoaded,
    isEmpty: isDataEmpty
  };
};