import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var __jsx = React.createElement;
import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useParams, useLocation, useHistory } from 'react-router';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import { PageFlow, Paragraph, Button, PageLoader, ProcessInfo, StatusIcon, ProcessStatus } from '@yieldstreet/ui-kit';
import { allToken, userApi, investorEntityApi } from '@yieldstreet/platform-kit';
import { Link } from 'sharedComponents/Links/Links';
import { testIds } from './ETCAgreement.model';
import { getEtcAccountTypeAgreement } from './ETCAgreement.utils';
import DocusignModal from 'sharedComponents/DocusignModal';
import { ContentWrapper, TextWrapper, SupportLink, ButtonWrapper } from './ETCAgreement.style';
export const ETCAgreement = () => {
  const history = useHistory();
  const location = useLocation();
  const {
    iraType
  } = useParams();
  const {
    0: isDocusignModalVisible,
    1: setIsDocusignModalVisible
  } = useState(false);
  const {
    0: docusignModalData,
    1: setDocusignModalData
  } = useState();
  const {
    0: hasAgreed,
    1: setHasAgreed
  } = useState(false);
  const {
    useGetUserStateQuery
  } = userApi;
  const {
    useCreateProviderInvestorEntityMutation
  } = investorEntityApi;
  const [createInvestorEntity, createInvestorEntityState] = useCreateProviderInvestorEntityMutation();
  const {
    data: userState,
    isFetching: isFetchingUserState
  } = useGetUserStateQuery(allToken);

  /**
   * If this page is loaded direct, without state, it may not be in the entity creation flow
   * Redirect to entities list
   */
  useEffect(() => {
    if (!location.state) {
      history.replace('/investor/manage');
    }
  }, [history, location.state]);

  /**
   * clientUserId is the key used to link the agreement with form data submitted to etc
   * it needs to be a random/new value for each account opening request sent to etc.
   */
  const clientUserId = useMemo(() => uuidv4(), []);

  /**
   * Wait to have userState loaded and build data to show Docusign modal
   */
  useEffect(() => {
    if (!!(userState !== null && userState !== void 0 && userState.emailAddress) && !docusignModalData) {
      var _location$state, _location$state$provi;
      const {
        firstName,
        lastName
      } = ((_location$state = location.state) === null || _location$state === void 0 ? void 0 : (_location$state$provi = _location$state.providerData) === null || _location$state$provi === void 0 ? void 0 : _location$state$provi.owner) || {};
      const etcAccountTypeAgreement = getEtcAccountTypeAgreement();
      setDocusignModalData({
        clickwrapId: etcAccountTypeAgreement[iraType],
        clientUserId,
        documentData: {
          fullName: `${firstName} ${lastName}`,
          date: dayjs().format('MM-DD-YYYY'),
          email: userState.emailAddress
        }
      });
    }
  }, [clientUserId, docusignModalData, iraType, isDocusignModalVisible, location.state, userState]);
  useEffect(() => {
    if (createInvestorEntityState.isSuccess) {
      history.replace(`/setup-an-ira/${iraType}/signed/success`);
    }
  }, [createInvestorEntityState, history, iraType]);

  /**
   * This will be called when user closes the docusign modal
   */
  const onSignFinished = useCallback(() => {
    var _location$state2;
    createInvestorEntity(_objectSpread(_objectSpread({}, location.state), {}, {
      providerData: _objectSpread(_objectSpread({}, (_location$state2 = location.state) === null || _location$state2 === void 0 ? void 0 : _location$state2.providerData), {}, {
        clickWrap: {
          clientUserId
        }
      })
    }));
    setIsDocusignModalVisible(false);
  }, [clientUserId, createInvestorEntity, location.state]);
  const docusignModal = useMemo(() => {
    if (docusignModalData && isDocusignModalVisible) {
      return __jsx(DocusignModal, _extends({}, docusignModalData, {
        onAgree: () => setHasAgreed(true),
        onSignFinished: onSignFinished
      }));
    }
  }, [docusignModalData, isDocusignModalVisible, onSignFinished]);
  if (isFetchingUserState || createInvestorEntityState.isLoading) {
    return __jsx(PageLoader, {
      testId: testIds.loading.id
    });
  }
  if (createInvestorEntityState.isError) {
    return __jsx(PageFlow, {
      showLogo: false,
      showFooter: false,
      backNavigation: true,
      backNavigationCallback: () => history.replace(`/investor/kyc/provider/etc/${iraType}?iraAccount=true`, _objectSpread({}, location.state))
    }, __jsx(ProcessInfo, {
      title: "Unfortunately, there was a problem with your submission",
      iconCentered: true,
      icon: __jsx(StatusIcon, {
        status: ProcessStatus.WARNING,
        testId: testIds.errorIcon.id
      }),
      testId: testIds.errorInfo.id
    }, __jsx(TextWrapper, null, __jsx(Paragraph, null, "Please make sure you have verified your email address, as this is a required step to create an investor account. Once you have verified your email,", ' ', __jsx(Link, {
      to: "/create-investor-account?iraAccount=true"
    }, "please restart the process.")), __jsx(Paragraph, null, "If your email address was already verified, please", ' ', __jsx(SupportLink, {
      id: "intercom-link"
    }, "contact Investor Relations"), " , and we will look into the issue further."))));
  }
  return __jsx(PageFlow, {
    showLogo: false,
    showFooter: false,
    backNavigation: true,
    backNavigationCallback: () => history.replace(`/investor/kyc/provider/etc/${iraType}?iraAccount=true`, _objectSpread({}, location.state))
  }, __jsx(ContentWrapper, null, __jsx(ProcessInfo, {
    title: "Complete agreements",
    testId: testIds.completeAgreement.id
  }, __jsx(Paragraph, testIds.agreementInfo.attr, "On the next screen, you will be prompted to review and sign Equity Trust\u2019s account agreements."), !hasAgreed && __jsx(ButtonWrapper, null, __jsx(Button, _extends({
    type: "submit",
    loading: isDocusignModalVisible,
    disabled: isDocusignModalVisible,
    onClick: () => setIsDocusignModalVisible(true)
  }, testIds.viewAgreement.attr), "View account agreements")))), docusignModal);
};