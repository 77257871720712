import _Button from "@mui/material/Button";
import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React from 'react';
import { useHistory } from 'react-router-dom';
import { CardContainer, Tag, ImageContainer, ContentContainer, DescriptionContainer, HeadingWrapper, ButtonContainer } from './AdviceOfferingCard.style';
export const AdviceOfferingCard = _ref => {
  let {
    tagText,
    title,
    description,
    cardCTAText,
    highlightImage,
    redirectPath
  } = _ref;
  const history = useHistory();
  return __jsx(CardContainer, null, tagText && __jsx(Tag, null, __jsx(_Typography, {
    component: "p",
    variant: "body2"
  }, __jsx("span", null, tagText))), __jsx(ImageContainer, {
    mobileSrc: highlightImage === null || highlightImage === void 0 ? void 0 : highlightImage.mobile,
    desktopSrc: highlightImage === null || highlightImage === void 0 ? void 0 : highlightImage.desktop
  }), __jsx(ContentContainer, null, __jsx(DescriptionContainer, null, __jsx(HeadingWrapper, null, __jsx(_Typography, {
    component: "h1",
    variant: "h4"
  }, title)), __jsx(_Typography, {
    component: "p",
    variant: "body1"
  }, description)), __jsx(ButtonContainer, null, __jsx(_Button, {
    fullWidth: true,
    onClick: () => history.push(redirectPath)
  }, cardCTAText))));
};