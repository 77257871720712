import styled from 'styled-components';
export const SpinnerWrapper = styled.div.withConfig({
  displayName: "Spinnerstyle__SpinnerWrapper",
  componentId: "sc-owxcke-0"
})(["position:relative;animation:spin 0.6s infinite linear;font-size:", ";margin:0 auto;text-indent:-9999em;width:4em;height:4em;mask-image:-webkit-gradient(linear,0 50%,0 90%,from(rgba(0,0,0,1)),to(rgba(0,0,0,0)));&::after{width:calc(100% - 6px);height:calc(100% - 6px);content:'';position:absolute;left:50%;top:50%;background-color:transparent;border-style:solid;border-width:", ";border-color:", ";border-radius:50%;transform:translate(-50%,-50%) rotate(135deg);}@keyframes spin{0%{transform:rotate(0deg);}100%{transform:rotate(360deg);}}"], _ref => {
  let {
    small
  } = _ref;
  return small ? '5px' : '10px';
}, _ref2 => {
  let {
    small
  } = _ref2;
  return small ? '2px' : '3px';
}, _ref3 => {
  let {
    disabled,
    inverse,
    theme
  } = _ref3;
  // eslint-disable-next-line no-nested-ternary
  const color = disabled ? theme.colors.input_disabled_text : inverse ? theme.colors.text_paragraph_inverse : theme.colors.text_paragraph;
  return `transparent ${color} ${color} ${color}`;
});