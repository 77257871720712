var __jsx = React.createElement;
import React from 'react';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { PortfolioCardBody, PortfolioCardItem } from 'pages/portfolio/common/PortfolioCard';
import { useTransactionDetails } from '../../../../hooks';
export const TransactionsListCardBody = _ref => {
  let {
    transaction,
    isFetching
  } = _ref;
  const transactionDetails = useTransactionDetails({
    transaction
  });
  const {
    fieldDate,
    isRollover,
    notes,
    showNotes,
    transactionTypeDisplayName
  } = transactionDetails;
  return __jsx(PortfolioCardBody, null, __jsx(PortfolioCardItem, {
    isLoading: isFetching,
    label: "Date"
  }, fieldDate), __jsx(PortfolioCardItem, {
    isLoading: isFetching,
    label: "Type"
  }, showNotes && notes ? __jsx(DescriptionOutlinedIcon, {
    sx: {
      fontSize: 14,
      mr: 1
    }
  }) : null, isRollover ? 'Rollover' : transactionTypeDisplayName));
};