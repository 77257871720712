var __jsx = React.createElement;
import React, { useMemo, useState } from 'react';
import { InvestorLevelGateCode, UserLevelGateCode, OfferingStatus } from '@yieldstreet/platform-kit';
import { formatOfferingHeroStatus, formatProgressPercentRemaining, formatOfferingHeroStatusMessage, OfferingHeroLabels, hasTieredPricing } from '@yieldstreet/tool-kit';
import { Label, Heading, Spinner, MobileOnly, DesktopOnly } from '@yieldstreet/ui-kit';
import { AssetClassOriginatorWrapper, ContentWrapper, DetailsWrapper, Dot, Image, InvestButton, LargeSpinnerWrapper, LoginParagraph, MarketingRulesLabel, MarketingRulesLink, MarketingRulesWrapper, MetricsWrapper, MinMaxInvestment, RemainingBox, SpinnerWrapper, Status, StatusBox, StatusMessage, StatusWrapper, TextWrapper, Title, TitleAndMetricsWrapper, TitleBadgeWrapper, WhiteLabel, QuarterWidthButton, AnimationDuration, GradientBg } from './OfferingHeroV5.style';
import isEmpty from "lodash/isEmpty";
import VisibilitySensor from 'react-visibility-sensor';
import { CSSTransition } from 'react-transition-group';
import { useLocation } from 'react-router-dom';
import { Link } from 'sharedComponents/Links/Links';
import { TieredPricingCard } from 'sharedComponents/TieredPricing';
import { NumberFormat } from 'sharedComponents/NumberFormat/NumberFormat';
import handleNumber from 'sharedComponents/utils/handleNumber';
import OfferingSimulator, { SimulatorModal, SimulatorButton } from '../../OfferingSimulator/OfferingSimulator';
import { PastMetrics } from './PastMetrics/PastMetrics';
import { ActiveMetrics } from './ActiveMetrics/ActiveMetrics';
import { Gate } from './Gate';
import { useOfferingHero } from './hooks/useOfferingHero';
const isWaitlisted = remainingInvestment => remainingInvestment <= 0;
const marketingRulesDisclaimer = location => __jsx(MarketingRulesWrapper, {
  "data-cy": "marketing-rules-wrapper"
}, __jsx(MarketingRulesLabel, null, "Per the amended marketing rules adopted by the SEC, some investment details can only be shown to certain", ' ', __jsx(MarketingRulesLink, {
  href: `/login?redirect=${location.pathname}`
}, "logged-in"), ' ', "members."));
const OfferingHeroV5 = _ref => {
  let {
    offering,
    loggedIn,
    history,
    pastMetric,
    gate,
    status,
    rawPercentage,
    remainingInvestment,
    offeringEligibilityFetched,
    isLoadingPercentage,
    isFooterInView
  } = _ref;
  const location = useLocation();
  const isActiveOffering = useMemo(() => [OfferingStatus.open, OfferingStatus.preoffering, OfferingStatus.pending, OfferingStatus.unfinished].includes(status), [status]);
  const isOpenOrPreoffering = useMemo(() => [OfferingStatus.open, OfferingStatus.preoffering].includes(status), [status]);
  const {
    assetClassDisplayName,
    originatorName,
    title,
    messages,
    originatorNickname,
    paymentFrequency,
    minimumPurchase,
    maximumPurchase,
    interestRate,
    offeringHero,
    fundType,
    thumbnailImageUrl
  } = offering;
  const {
    isPublicFund,
    isFortyActFund,
    tieredTree,
    term,
    termSuffix
  } = useOfferingHero(offering);
  const {
    0: openModal,
    1: setOpenModal
  } = useState(false);
  const {
    0: isHeroVisible,
    1: setIsHeroVisible
  } = useState(false);
  let statusMessage;
  const statusValues = useMemo(() => {
    return formatOfferingHeroStatus({
      status: status,
      percentageRemaining: rawPercentage,
      waitlistOpen: isWaitlisted(remainingInvestment),
      fundType
    });
  }, [status, rawPercentage, remainingInvestment, fundType]);
  if (messages) {
    statusMessage = formatOfferingHeroStatusMessage(messages, statusValues);
  }
  const handleInvestClick = () => {
    history === null || history === void 0 ? void 0 : history.push(`/input-investment/${offering.urlHash}`);
  };
  const offeringSimulatorRender = useMemo(() => {
    return __jsx(React.Fragment, null, __jsx(SimulatorButton, {
      buttonType: "basic",
      inverse: true,
      "data-cy": "offering-simulator-button",
      onClick: () => setOpenModal(true)
    }, "Visualize the returns"), openModal ? __jsx(SimulatorModal, {
      "data-cy": "offering-simulator-modal",
      clickClose: () => setOpenModal(false)
    }, __jsx(OfferingSimulator, {
      "data-cy": "offering-simulator",
      paymentFrequency: paymentFrequency,
      investment: {
        name: title,
        defaultAmount: 10000,
        rate: handleNumber(interestRate),
        term: term,
        termSuffix: termSuffix,
        min: minimumPurchase,
        max: maximumPurchase
      }
    })) : null);
  }, [openModal, paymentFrequency, interestRate, term, termSuffix, title, minimumPurchase, maximumPurchase]);
  const roundedPercentage = useMemo(() => {
    let finalPercentage = rawPercentage;
    if (finalPercentage && finalPercentage < 0) finalPercentage = 0;
    return formatProgressPercentRemaining(finalPercentage, status);
  }, [rawPercentage, status]);
  const renderMinMaxInvestment = useMemo(() => {
    return [OfferingStatus.preoffering, OfferingStatus.unfinished].includes(status) ? __jsx(MinMaxInvestment, {
      small: true,
      "data-cy": "min-max-message-coming-soon"
    }, __jsx(NumberFormat, {
      value: offering.minimumPurchase,
      displayType: "text",
      thousandSeparator: true,
      prefix: "$"
    }), ' - ', __jsx(NumberFormat, {
      value: offering.maximumPurchase,
      displayType: "text",
      thousandSeparator: true,
      prefix: "$"
    }), ` `, "investment") : (offering === null || offering === void 0 ? void 0 : offering.statusDisplayName) !== 'Fully Subscribed' && __jsx(MinMaxInvestment, {
      small: true,
      "data-cy": "min-max-message-open"
    }, "Accepting", ' ', __jsx(NumberFormat, {
      value: offering.autoInvestMinimumPurchase || offering.minimumPurchase,
      thousandSeparator: true,
      prefix: "$",
      displayType: "text"
    }), ' - ', __jsx(NumberFormat, {
      value: offering.autoInvestMaximumPurchase || offering.maximumPurchase,
      thousandSeparator: true,
      prefix: "$",
      displayType: "text"
    }), ' ', "investments");
  }, [status, offering]);
  const renderInvestButton = function () {
    let topMargin = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
    if (![OfferingStatus.preoffering, OfferingStatus.open].includes(status) && offering.statusDisplayName !== 'Fully Subscribed') {
      return null;
    }
    if (!loggedIn) {
      return __jsx(React.Fragment, null, __jsx(InvestButton, {
        "data-cy": "offering-v5-hero-login-button",
        trackingId: "offering-invest",
        className: "offering-v5-hero-login-button",
        onClick: () => history === null || history === void 0 ? void 0 : history.push(`/signup`),
        topMargin: topMargin
      }, "Start investing"), offeringHero !== null && offeringHero !== void 0 && offeringHero.showSimulator ? __jsx(MobileOnly, null, offeringSimulatorRender) : '');
    }
    if (!offeringEligibilityFetched) {
      return __jsx(LargeSpinnerWrapper, {
        className: "offering-eligibility-loader"
      }, __jsx(Spinner, {
        inverse: true
      }));
    }
    if (status === OfferingStatus.preoffering) {
      return null;
    }
    const {
      gateCopy,
      gateCode,
      gateAttributes
    } = gate || {};
    if (gateCopy) {
      let altStyling;
      if (gateCode) {
        altStyling = [UserLevelGateCode.NEVER, InvestorLevelGateCode.ACCOUNT_TYPE, InvestorLevelGateCode.INVESTOR_ENTITY_PROVIDER_ACTION].includes(gateCode);
      }
      return __jsx(Gate, {
        altStyling: altStyling,
        gateCopy: gateCopy,
        attributes: gateAttributes
      });
    } else {
      return __jsx(InvestButton, {
        "data-cy": "offering-v5-hero-invest-button",
        onClick: handleInvestClick,
        trackingId: "offering-invest",
        className: "offering-v5-hero-invest-button",
        topMargin: topMargin
      }, isWaitlisted(remainingInvestment) ? 'Join waitlist' : 'Invest now');
    }
  };
  const showOriginator = useMemo(() => {
    return !isEmpty(originatorName) && !['Private', 'Raistone Capital'].includes(originatorName);
  }, [originatorName]);
  const onVisibilityChange = isVisible => {
    setIsHeroVisible(!isVisible);
  };
  const enableTieredPricing = useMemo(() => {
    return hasTieredPricing(offering);
  }, [offering]);
  return __jsx(React.Fragment, null, __jsx(VisibilitySensor, {
    offset: {
      top: -400
    },
    scrollCheck: true,
    onChange: onVisibilityChange
  }, __jsx(Image, {
    backgroundImage: thumbnailImageUrl
  }, __jsx(ContentWrapper, {
    "data-cy": "offering-hero-content"
  }, __jsx(DetailsWrapper, null, __jsx(AssetClassOriginatorWrapper, null, __jsx(WhiteLabel, null, assetClassDisplayName), showOriginator && __jsx(React.Fragment, null, __jsx(Dot, null, "\u2022"), __jsx(Label, {
    inverse: true
  }, __jsx(Link, {
    to: {
      pathname: '/originator/' + originatorNickname + '/',
      state: {
        title: originatorName
      }
    }
  }, originatorName)))), __jsx(TitleAndMetricsWrapper, null, __jsx(TitleBadgeWrapper, null, __jsx(Title, {
    type: 2,
    tagType: 1,
    inverse: true
  }, title)), __jsx(MetricsWrapper, {
    hasTree: !!tieredTree
  }, __jsx(ActiveMetrics, {
    isPublicFund: isPublicFund,
    isFortyActFund: isFortyActFund,
    isConcise: false,
    offering: offering,
    term: term,
    termSuffix: termSuffix,
    isLoggedIn: loggedIn,
    status: status,
    tieredTree: tieredTree
  }))), !loggedIn && marketingRulesDisclaimer(location), offeringHero !== null && offeringHero !== void 0 && offeringHero.showSimulator ? __jsx(DesktopOnly, null, offeringSimulatorRender) : ''), __jsx(StatusWrapper, null, __jsx(StatusBox, {
    barColor: statusValues.webColor,
    statusMessage: statusMessage
  }, __jsx(TextWrapper, null, __jsx(Status, null, "Status"), __jsx(Heading, {
    type: 4,
    inverse: true,
    className: "offering-hero-v5-status",
    "data-cy": "offering-hero-status"
  }, statusValues.label)), isOpenOrPreoffering && statusValues.label === OfferingHeroLabels.almostGone && __jsx(RemainingBox, null, __jsx(TextWrapper, null, __jsx(Status, null, "Remaining"), !isLoadingPercentage ? __jsx(Heading, {
    type: 4,
    inverse: true,
    "data-cy": "offering-hero-v5-remaining-percentage",
    className: "offering-hero-v5-remaining-percentage"
  }, roundedPercentage) : __jsx(SpinnerWrapper, null, __jsx(Spinner, {
    inverse: true,
    small: true
  }))))), statusMessage && __jsx(StatusMessage, {
    barColor: statusValues.webColor,
    "data-cy": "offering-hero-v5-status-message"
  }, statusMessage), !isActiveOffering && __jsx(PastMetrics, {
    metric: pastMetric
  }), __jsx(MobileOnly, null, renderMinMaxInvestment), renderInvestButton(true), isHeroVisible && isFooterInView && renderInvestButton() ? __jsx(MobileOnly, null, __jsx(CSSTransition, {
    in: true,
    timeout: AnimationDuration,
    classNames: "fade",
    appear: true,
    unmountOnExit: true
  }, __jsx(GradientBg, null, __jsx(QuarterWidthButton, null, renderInvestButton(false))))) : null, !loggedIn && __jsx(MobileOnly, null, __jsx(LoginParagraph, {
    inverse: true,
    "data-cy": "offering-hero-login-button"
  }, "Have an account?", ' ', __jsx(Link, {
    to: `/login?redirect=${location === null || location === void 0 ? void 0 : location.pathname}`
  }, "Log in"))), enableTieredPricing && __jsx(DesktopOnly, {
    "data-cy": "tiered-pricing-card"
  }, __jsx(TieredPricingCard, null)), __jsx(DesktopOnly, null, renderMinMaxInvestment))))), enableTieredPricing && __jsx(MobileOnly, null, __jsx(TieredPricingCard, null)));
};
export default OfferingHeroV5;