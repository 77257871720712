import styled, { css } from 'styled-components';
import { ParagraphBase } from '../../content';
import { media } from '../../themes';
export const TabContainer = styled.div.withConfig({
  displayName: "Tabstyle__TabContainer",
  componentId: "sc-1yqaa6h-0"
})(["", ";display:flex;", " ", " ", " cursor:", ";"], ParagraphBase, media.desktop`
    ${props => props.vertical && `
      &:hover {
        border-left: 2px solid ${props.theme.colors.border_outline};
      }

      ${props.isActive ? `border-left: 2px solid ${props.theme.colors.border_outline};` : 'border-left: 2px solid transparent;'};
    `}
  `, media.large`
    ${props => props.vertical && `
      padding: ${props.theme.spacing.xs};
      color: ${props.isActive ? props.theme.colors.text_paragraph : props.theme.colors.text_label};

    `}
  `, props => props.vertical ? `
        color: ${props.theme.colors.text_paragraph};
        padding: ${props.theme.spacing.sm} 0;
      ` : `
      padding: ${props.theme.spacing.xs} ${props.theme.spacing.sm};
      border: 1px solid ${props.theme.colors.border_default};
      background: ${props.isActive ? props.theme.colors.background_inverse : props.theme.colors.background_card};
      color: ${props.isActive ? props.theme.colors.text_paragraph_inverse : props.theme.colors.text_label};
      transition: all 0.2s ease-in-out;
      border-radius: 15rem;

      img {
        filter: ${props.isActive && !(props !== null && props !== void 0 && props.disableIconInverse) ? css(["-webkit-filter:invert(1);filter:invert(1);"]) : css(["-webkit-filter:invert(0);filter:invert(0);"])};
        opacity: ${props.isActive && props !== null && props !== void 0 && props.disableIconInverse ? 1 : 0.6};
      }

      &:hover {
        background: ${props.isActive ? props.theme.colors.background_inverse : props.theme.colors.input_button_basic_inverse_background_hover};

        color: ${props.isActive ? props.theme.colors.text_paragraph_inverse : props.theme.colors.text_label};
      }
  `, props => props.isActive ? 'default' : 'pointer');
export const TabContent = styled.div.withConfig({
  displayName: "Tabstyle__TabContent",
  componentId: "sc-1yqaa6h-1"
})(["display:flex;flex-direction:row;align-items:center;position:relative;width:100%;justify-content:space-between;", ""], media.large`
    width: fit-content;
  `);
export const TextContent = styled.div.withConfig({
  displayName: "Tabstyle__TextContent",
  componentId: "sc-1yqaa6h-2"
})(["display:flex;flex-direction:row;"]);
export const TabIcon = styled.div.withConfig({
  displayName: "Tabstyle__TabIcon",
  componentId: "sc-1yqaa6h-3"
})(["display:flex;align-items:center;justify-content:center;margin:0 10px 0 0;img{display:flex;opacity:1;transition:all 0.2s ease-in-out;}"]);
export const TabTitle = styled.div.withConfig({
  displayName: "Tabstyle__TabTitle",
  componentId: "sc-1yqaa6h-4"
})(["font-weight:", ";", ""], props => props.vertical ? 400 : 500, props => props.textWrap && `white-space: ${props.textWrap};`);
export const TabNumericBadge = styled.div.withConfig({
  displayName: "Tabstyle__TabNumericBadge",
  componentId: "sc-1yqaa6h-5"
})(["width:", ";height:20px;border-radius:10px;padding:4px 0;background-color:", ";color:", ";text-align:center;font-size:12px;line-height:12px;font-weight:500;margin-left:8px;"], props => props !== null && props !== void 0 && props.isLarge ? '34px' : '20px', props => props !== null && props !== void 0 && props.isActive ? props.theme.colors.text_header_inverse : props.theme.colors.text_header, props => props !== null && props !== void 0 && props.isActive ? props.theme.colors.text_header : props.theme.colors.text_header_inverse);
export const Icon = styled.img.withConfig({
  displayName: "Tabstyle__Icon",
  componentId: "sc-1yqaa6h-6"
})(["transform:rotate(180deg);"]);