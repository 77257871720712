import { combineReducers } from 'redux';
import * as actions from 'store/actions/categoryFilter';
import createAPIReducer from './common/createAPIReducer';
export const createLTPostReducer = actionKey => {
  return createAPIReducer(actions, actionKey, {
    initData: {
      posts: []
    },
    successHandler: _ref => {
      let {
        response
      } = _ref;
      return {
        posts: {
          $set: response.data || []
        },
        postsPageAmount: {
          $set: response.headers['x-wp-totalpages']
        }
      };
    }
  });
};
const blogLTArticles = createLTPostReducer('GET_BLOG_LT_ARTICLES');
export const createFeaturedReducer = actionKey => {
  return createAPIReducer(actions, actionKey, {
    initData: {
      featuredPosts: []
    },
    successHandler: _ref2 => {
      let {
        response
      } = _ref2;
      return {
        featuredPosts: {
          $set: response.data
        }
      };
    }
  });
};
const featuredArticles = createFeaturedReducer('GET_FEATURED_ARTICLES');
export const createLTCategoriesReducer = actionKey => {
  return createAPIReducer(actions, actionKey, {
    initData: {
      categories: []
    },
    successHandler: _ref3 => {
      let {
        response
      } = _ref3;
      return {
        categories: {
          $set: response.data ? response.data : []
        }
      };
    }
  });
};
const blogLTCategories = createLTCategoriesReducer('GET_BLOG_LT_CATEGORIES');
export default combineReducers({
  blogLTArticles,
  featuredArticles,
  blogLTCategories
});