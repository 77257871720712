import _Alert from "@mui/material/Alert";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _AlertTitle from "@mui/material/AlertTitle";
var __jsx = React.createElement;
import React from 'react';
import { useProviderInvestorEntityMigration } from '@yieldstreet/tool-kit';
import { useRouteInvestorEntityId } from 'pages/investorEntity/utils';
import { testIds } from './KYCMigrationBankAccountsNotification.model';
export const KYCMigrationBankAccountsNotification = () => {
  const investorEntityId = useRouteInvestorEntityId();
  const {
    isMigrationOngoing,
    bankLinkDetails
  } = useProviderInvestorEntityMigration(investorEntityId);
  if (isMigrationOngoing && bankLinkDetails) {
    return __jsx(_Alert, _extends({}, testIds.base.attr, {
      severity: "warning"
    }), __jsx(_AlertTitle, null, "Banking services update:"), "We have unlinked your bank accounts from our previous provider. Please verify your identity and link your bank account(s) via Plaid to continue funding investment requests and receiving distributions.");
  }
  return null;
};