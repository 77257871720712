import { media, Paragraph, Link } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
export const TieredCardWrapper = styled.div.withConfig({
  displayName: "TieredPricingCardstyle__TieredCardWrapper",
  componentId: "sc-1awp3o9-0"
})(["background:", ";display:grid;grid-template-columns:50px 1fr;grid-template-rows:1fr 1fr;align-items:center;", " padding:16px 20px;"], props => props.theme.gradients.radial_primary, media.desktop`
    margin-top: 10px;
    background: ${props => props.theme.colors.background_inverse};
    border-radius: 4px;
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  `);
export const IconWrapper = styled.div.withConfig({
  displayName: "TieredPricingCardstyle__IconWrapper",
  componentId: "sc-1awp3o9-1"
})(["grid-column:1;grid-row:span 2;width:40px;height:40px;border-radius:100%;background-color:rgba(255,255,255,0.15);display:flex;justify-content:center;align-items:center;border:1px solid ", ";", ""], props => props.theme.colors.border_default, media.desktop`
    background-color: transparent;
    border: none;
  `);
export const ExclusiveIcon = styled.img.withConfig({
  displayName: "TieredPricingCardstyle__ExclusiveIcon",
  componentId: "sc-1awp3o9-2"
})(["width:23px;", ""], media.desktop`
    width: 28px;
  `);
export const BannerParagraph = styled(Paragraph).withConfig({
  displayName: "TieredPricingCardstyle__BannerParagraph",
  componentId: "sc-1awp3o9-3"
})(["max-width:275px;", " color:", ";"], media.desktop`
    margin-top: 4px;
  `, props => props.theme.colors.text_label_inverse);
export const LinkParagraph = styled(Link).withConfig({
  displayName: "TieredPricingCardstyle__LinkParagraph",
  componentId: "sc-1awp3o9-4"
})(["box-shadow:", " !important;color:", " !important;background-image:", ";font-size:14px;"], props => props.theme.colors.text_paragraph_inverse, props => props.theme.colors.text_paragraph_inverse, props => `linear-gradient( to right, ${props.theme.colors.text_paragraph_inverse} 45%, transparent 45%, transparent 55%, ${props.theme.colors.text_paragraph_inverse} 55% ) !important`);