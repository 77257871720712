import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var __jsx = React.createElement;
import React, { useMemo, useCallback } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { NavigationTabList, Tab } from '@yieldstreet/ui-kit';
import topIconSrc from './assets/top.svg';
import { NavigationContainer, NavigationInner, NavigationLinkIcon, NavigationLinks, NavigationAction, NavigationAnchor } from './Navigation.style';
import { useOfferingNavigation, scrollToTop, scrollToContainer } from './Navigation.utils';
import { testIds, NAVIGATION_ANCHOR_ID, navIcons } from './Navigation.model';
export const Navigation = _ref => {
  let {
    offering,
    showBackToTop,
    sectionInView
  } = _ref;
  const {
    shouldShowNavigation,
    navigation
  } = useOfferingNavigation(offering);
  const history = useHistory();
  const {
    location
  } = history;
  const activeItem = sectionInView;
  const handleBackToTop = useCallback(() => {
    history.push(_objectSpread(_objectSpread({}, location), {}, {
      hash: ''
    }));
    scrollToTop();
  }, [location, history]);
  const navItems = useMemo(() => {
    return navigation.map(item => ({
      title: item.title,
      icon: __jsx("img", {
        src: navIcons[item.slug],
        role: "none"
      }),
      route: item.slug
    }));
  }, [navigation]);
  return shouldShowNavigation ? __jsx(React.Fragment, null, __jsx(NavigationAnchor, {
    id: NAVIGATION_ANCHOR_ID
  }), __jsx(NavigationContainer, {
    isSticky: true,
    "data-cy": testIds.container
  }, __jsx(NavigationInner, null, __jsx(NavigationLinks, null, __jsx(NavigationTabList, {
    navigationConfig: navItems,
    currentPath: activeItem,
    renderLink: _ref2 => {
      let {
        navItem,
        children
      } = _ref2;
      return __jsx(Link, {
        to: _objectSpread(_objectSpread({}, location), {}, {
          hash: navItem.route
        }),
        active: activeItem === navItem.route,
        "data-cy": testIds.link,
        onClick: () => scrollToContainer(navItem.route)
      }, children);
    }
  })), showBackToTop && __jsx(NavigationAction, null, __jsx(Tab, {
    icon: __jsx(NavigationLinkIcon, {
      src: topIconSrc,
      forceIcon: true
    }),
    onClick: handleBackToTop,
    textWrap: "nowrap",
    title: "Back to top",
    testId: testIds.backToTop
  }))))) : null;
};