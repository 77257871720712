import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["linkTarget"];
var __jsx = React.createElement;
import React from 'react';
import { StyledReactMarkdown } from './Markdown.style';
export const Markdown = _ref => {
  let {
      linkTarget = '_blank'
    } = _ref,
    allProps = _objectWithoutProperties(_ref, _excluded);
  return __jsx(StyledReactMarkdown, _extends({
    linkTarget: linkTarget
  }, allProps));
};