var __jsx = React.createElement;
import React from 'react';
import Helmet from 'react-helmet';
import { withRouter } from 'react-router';
const defaultTitle = 'Yieldstreet | Online Alternative Investments';
const defaultDescription = 'Yieldstreet is an alternative investment platform changing the way wealth is created. Access opportunities across legal, marine, real estate and commercial finance. 8-15% target returns, 1-4 year terms. Start building your portfolio today!';
const defaultImage = 'https://www.yieldstreet.com/gatsby-assets/images/yieldstreet-og-image.jpg';
const MetaData = _ref => {
  let {
    metaData,
    location
  } = _ref;
  metaData = metaData || {};
  return __jsx(Helmet, {
    htmlAttributes: {
      lang: metaData.lang || 'en-US'
    },
    title: metaData.title || defaultTitle,
    meta: [{
      name: `description`,
      property: `description`,
      content: metaData.description || defaultDescription
    }, {
      property: `og:title`,
      content: metaData.title || defaultTitle
    }, {
      property: `og:description`,
      content: metaData.description || defaultDescription
    }, {
      property: `og:type`,
      content: `website`
    }, {
      property: `og:image`,
      content: metaData.image || defaultImage
    }, {
      property: `og:image:secure_url`,
      content: metaData.image || defaultImage
    }, {
      property: `og:url`,
      content: `https://www.yieldstreet.com${metaData.url || location.pathname}`
    }, {
      name: `twitter:card`,
      property: `twitter:card`,
      content: 'summary_large_image'
    }, {
      name: `twitter:site`,
      property: `twitter:site`,
      content: '@yieldstreet'
    }, {
      name: `twitter:image`,
      property: `twitter:image`,
      content: metaData.image || defaultImage
    }, ...(metaData.meta || [])]
  }, metaData.canonicalUrl && __jsx("link", {
    rel: "canonical",
    href: `https://www.yieldstreet.com${metaData.canonicalUrl}`
  }), metaData.structuredData && __jsx("script", {
    type: "application/ld+json"
  }, metaData.structuredData));
};
export default withRouter(MetaData);