import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { restApi, BASE_REST_CONFIG } from '../../common';
const BASE_URL = '/a/api/investment-request';
export const legacyRolloverApi = restApi.injectEndpoints(_objectSpread(_objectSpread({}, BASE_REST_CONFIG), {}, {
  endpoints: builder => {
    const getEligibleRollover = builder.query({
      query: () => ({
        url: `${BASE_URL}/investments/eligible-rollover`,
        method: 'GET'
      })
    });
    return {
      getEligibleRollover
    };
  }
}));