var __jsx = React.createElement;
import React from 'react';
import { Heading, Label } from '@yieldstreet/ui-kit';
import exclusiveIcon from 'assets/icons/images/exclusive.svg';
import { TieredPricingList } from '../../TieredPricingContainer/TieredPricingContainer.style';
import { TieredPricingItem } from '../../TieredPricingItem/TieredPricingItem';
import { TieredModalWrapper, ExclusiveIcon, IconWrapper, HeaderWrapper, InfoWrapper, LabelInfo, ParagrapHeader, BottomWrapper, TieredPricingWrapper, BackgroundWrapper } from './TieredPricingModal.style';
import { tierData, tieredPricingContent } from '@yieldstreet/tool-kit';
export const TieredPricingModal = () => {
  return __jsx(TieredModalWrapper, null, __jsx(HeaderWrapper, null, __jsx(BackgroundWrapper, null, __jsx(InfoWrapper, null, __jsx(IconWrapper, null, __jsx(ExclusiveIcon, {
    src: exclusiveIcon
  })), __jsx(LabelInfo, {
    inverse: true,
    small: true
  }, tieredPricingContent.ModalTitle.toUpperCase()), __jsx(Heading, {
    type: 5,
    inverse: true
  }, tieredPricingContent.ModalIntro), __jsx(ParagrapHeader, {
    inverse: true,
    size: "small"
  }, tieredPricingContent.ModalInfo)), __jsx(TieredPricingWrapper, null, __jsx(TieredPricingList, null, tierData.map((tier, idx) => __jsx(TieredPricingItem, {
    key: idx,
    type: tier.type,
    amount: tier.amount,
    reduction: tier.reduction
  })))))), __jsx(BottomWrapper, null, __jsx(Label, {
    inverse: true,
    small: true
  }, tieredPricingContent.ModalDisclaimer)));
};