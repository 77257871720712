import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["onChange", "defaultValue", "format", "useFormattedValue", "allowLeadingZeros"];
var __jsx = React.createElement;
import React from 'react';
import { PatternFormat } from 'react-number-format';
import { withFormikWeb } from '../common';
import { TextInput } from './TextInput';
const MaskFormatInput = props => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
      onChange,
      defaultValue,
      format,
      useFormattedValue,
      allowLeadingZeros
    } = props,
    otherProps = _objectWithoutProperties(props, _excluded);
  return __jsx(PatternFormat, _extends({}, otherProps, {
    // allowLeadingZeros={allowLeadingZeros}
    onValueChange: _ref => {
      let {
        value,
        formattedValue
      } = _ref;
      onChange && onChange({
        target: {
          value: useFormattedValue ? formattedValue : value
        }
      });
    },
    format: format
  }));
};
export const MaskTextInput = props => {
  return __jsx(TextInput, _extends({}, props, {
    maskClassName: props.value ? 'MuiInputLabel-shrink MuiInputLabel-filled Mui-focused Mui-focused' : '',
    inputComponent: MaskFormatInput
  }));
};
export const FormMaskTextInput = withFormikWeb(MaskTextInput);