import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import axios from 'axios';
import AxiosMockAdapter from 'axios-mock-adapter';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import isArray from 'lodash/isArray';
import { graphqlApi, restApi } from '../apis';
export const getRTKMockStoreExtraMiddlewares = () => {
  return [restApi.middleware, graphqlApi.middleware];
};
export const getMockEndpoint = (api, endpoint, data) => {
  return {
    api,
    endpoints: [{
      endpoint,
      queryFn: () => ({
        data
      })
    }]
  };
};
export const getMockErrorEndpoint = (api, endpoint, errorData) => {
  return {
    api,
    endpoints: [{
      endpoint,
      queryFn: () => ({
        error: {
          response: {
            data: typeof errorData === 'string' ? {
              message: errorData
            } : errorData
          }
        }
      })
    }]
  };
};
export const getMockLoadingEndpoint = (api, endpoint) => {
  return {
    api,
    endpoints: [{
      endpoint,
      queryFn: () => new Promise(() => {})
    }]
  };
};
export function mockApi(queries) {
  if (!(queries !== null && queries !== void 0 && queries.length)) {
    return;
  }
  return map(queries, query => {
    let enhancedEndpoints = {};
    const {
      api,
      endpoints
    } = query;
    forEach(endpoints, e => {
      Object.assign(enhancedEndpoints, {
        [e.endpoint]: {
          query: null,
          queryFn: e.queryFn
        }
      });
    });
    const extendedApi = api.enhanceEndpoints({
      endpoints: enhancedEndpoints
    });
    return extendedApi;
  });
}
export function setupApiStore(apis, extraReducers, extraMiddlewares, mockQueries) {
  /*
   * Modified version of RTK Query's helper function:
   * https://github.com/reduxjs/redux-toolkit/blob/master/packages/toolkit/src/query/tests/helpers.tsx
   */

  let extendedApis = mockApi(mockQueries || []);
  let apiReducers = {};
  forEach([...(extendedApis || []), ...(apis || [])], api => {
    Object.assign(apiReducers, {
      [api.reducerPath]: api.reducer
    });
  });
  let apiMiddlewares = [];
  if (apis) {
    apiMiddlewares = apis === null || apis === void 0 ? void 0 : apis.map(query => query.middleware);
  } else {
    apiMiddlewares = mockQueries === null || mockQueries === void 0 ? void 0 : mockQueries.map(query => {
      var _query$api;
      return (_query$api = query.api) === null || _query$api === void 0 ? void 0 : _query$api.middleware;
    });
  }
  const getStore = () => configureStore({
    reducer: combineReducers(_objectSpread(_objectSpread({}, apiReducers || {}), extraReducers || {})),
    middleware: gdm => gdm({
      serializableCheck: false,
      immutableCheck: false
    }).concat(...(apiMiddlewares || []), ...(extraMiddlewares || []))
  });
  const initialStore = getStore();
  const refObj = {
    apis: apis || extendedApis,
    store: initialStore
  };
  return refObj;
}
export function configureRTKMockStore(apis, extraReducers, extraMiddlewares, axiosConfig, isMockingQueryFn) {
  const mockAxios = new AxiosMockAdapter(axios);

  /*
    DO NOT TOUCH THE FOR LOOP
    THE FOR IS USED ON PURPOSE FOR API BLOCKING - SYNCHRONOUS QUEUED
   */
  if (isArray(axiosConfig)) {
    for (const config of axiosConfig) {
      if (config.type === 'GET') {
        mockAxios.onGet(config.url, {
          params: config === null || config === void 0 ? void 0 : config.params
        }).reply(() => {
          return config.data;
        });
      }
      if (config.type === 'POST') {
        mockAxios.onPost(config.url).reply(() => {
          return config.data;
        });
      }
    }
  }
  let queryFnMocks = {}; // for cases where we want to verify if the endpoint was called with a certain params or not
  let apisWithQueryFnMock;
  if (isMockingQueryFn) {
    apisWithQueryFnMock = apis.map((api, idx) => {
      return _objectSpread(_objectSpread({}, api), {}, {
        endpoints: apis[idx].endpoints.map(endpointObj => {
          queryFnMocks = _objectSpread(_objectSpread({}, queryFnMocks), {}, {
            [endpointObj.endpoint]: jest.fn()
          });
          return _objectSpread(_objectSpread({}, endpointObj), {}, {
            queryFn: params => {
              queryFnMocks[endpointObj.endpoint](params);
              return endpointObj.queryFn(params);
            }
          });
        })
      });
    });
  }
  const setup = setupApiStore(null, extraReducers, isArray(extraMiddlewares) ? extraMiddlewares : [extraMiddlewares], isMockingQueryFn ? apisWithQueryFnMock : apis);
  return _objectSpread({
    mockAxios,
    queryFnMocks
  }, setup);
}