var __jsx = React.createElement;
import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';
export const ScrollReveal = props => {
  const {
    children,
    delay = 100,
    forceRender = false
  } = props;
  const {
    0: childrenVisible,
    1: setChildrenVisible
  } = useState(forceRender);
  const detectorVisible = useRef(false);
  const delayPassed = useRef(false);
  useEffect(() => {
    setTimeout(() => {
      delayPassed.current = true;
      setChildrenVisible(detectorVisible.current);
    }, delay);
  }, [delay]);
  return __jsx(VisibilitySensor, {
    partialVisibility: true,
    delayedCall: true,
    onChange: isVisible => {
      if (!childrenVisible) {
        detectorVisible.current = isVisible;
        if (delayPassed.current) {
          setChildrenVisible(isVisible);
        }
      }
    }
  }, __jsx(RevealDetector, {
    visible: childrenVisible
  }, __jsx(RevealContainer, {
    visible: childrenVisible
  }, childrenVisible && children)));
};
const RevealDetector = styled.div.withConfig({
  displayName: "ScrollReveal__RevealDetector",
  componentId: "sc-1ve7o71-0"
})(["padding-bottom:", ";"], props => props.visible ? `0` : `10px`);
const RevealContainer = styled.div.withConfig({
  displayName: "ScrollReveal__RevealContainer",
  componentId: "sc-1ve7o71-1"
})(["overflow:hidden;transition:transform 1s ease-in-out;transition:opacity 1s ease-out;height:auto;transform:scaleY(0);opacity:0;transform-origin:top;", ""], props => props.visible ? `
    transform: scaleY(1);
    opacity: 1;
  ` : ``);
export default ScrollReveal;