var __jsx = React.createElement;
import React from 'react';
import { Button, friendlyMedia, Heading, Label, media, NewModal, useModalContext } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
import QuestionIconFilled from '@yieldstreet/ui-kit/assets/graphics/icons/question-icon-filled.svg';
import QuestionIcon from '@yieldstreet/ui-kit/assets/graphics/icons/question-icon.svg';
import { INFO_TOOLTIP } from 'constants/modal.const';
const ToggleTooltip = _ref => {
  let {
    stepLabel,
    stepInfo,
    stepTrackingId,
    onClick,
    testId
  } = _ref;
  const {
    showModal,
    hideModal
  } = useModalContext();
  return __jsx(StepInfo, {
    "data-cy": testId
  }, __jsx(SLabel, {
    onClick: onClick || (() => showModal(INFO_TOOLTIP)),
    "data-cy": stepTrackingId
  }, __jsx(Tooltip, {
    className: "tooltip"
  }), " ", stepLabel), __jsx(NewModal, {
    modalId: INFO_TOOLTIP
  }, __jsx(ModalWrapper, null, __jsx(StepInfoContent, null, __jsx(Heading, {
    type: 4,
    paddingBottom: 'm'
  }, stepLabel), stepInfo), __jsx(StepInfoButton, {
    buttonType: 'secondary',
    onClick: () => hideModal()
  }, "Close"))));
};
export const SHeading = styled(Heading).withConfig({
  displayName: "ToggleTooltip__SHeading",
  componentId: "sc-1zghg6-0"
})(["margin-bottom:", ";"], _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.spacing.xs;
});
export const ModalWrapper = styled.div.withConfig({
  displayName: "ToggleTooltip__ModalWrapper",
  componentId: "sc-1zghg6-1"
})(["display:flex;flex-direction:column;padding:60px 20px 20px;background-color:", ";gap:40px;height:100vh;", ";"], props => props.theme.colors.background_card, friendlyMedia.tablet`
    width: 80vw;
    align-items: center;
    padding: 40px;
    max-width: 600px;
    height: auto;
  `);
const StepInfo = styled.div.withConfig({
  displayName: "ToggleTooltip__StepInfo",
  componentId: "sc-1zghg6-2"
})(["margin:40px 0;"]);
const StepInfoButton = styled(Button).withConfig({
  displayName: "ToggleTooltip__StepInfoButton",
  componentId: "sc-1zghg6-3"
})(["margin-top:auto;"]);
const SLabel = styled(Label).withConfig({
  displayName: "ToggleTooltip__SLabel",
  componentId: "sc-1zghg6-4"
})(["display:flex;align-items:center;justify-content:center;cursor:pointer;margin-bottom:30px;:hover{color:", " !important;span{background-image:url(", ");}}", ""], props => props.theme.colors.text_paragraph, QuestionIconFilled, media.desktop`
    justify-content: center;
  `);
const Tooltip = styled.span.withConfig({
  displayName: "ToggleTooltip__Tooltip",
  componentId: "sc-1zghg6-5"
})(["background-image:url(", ");display:inline-block;margin-right:8px;height:16px;width:15px;"], QuestionIcon);
const StepInfoContent = styled.div.withConfig({
  displayName: "ToggleTooltip__StepInfoContent",
  componentId: "sc-1zghg6-6"
})(["", ";"], friendlyMedia.tablet`
    text-align: center;
  `);
export default ToggleTooltip;