import { Button, media, friendlyMedia } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
import largeWave from './assets/large-wave.svg';
import smallWave from './assets/small-wave.svg';
const CardWrapperContained = `
  align-items: center;
  padding: 0;
  margin-top: 0;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`;
const CardWrapperUncontained = `
  flex-direction: row;
  padding: 0 0 0 66px;
  margin-top: 0;
  margin: 0 0 0 -34px;
`;
const CardWrapper = styled.div.withConfig({
  displayName: "AdviceCardstyle__CardWrapper",
  componentId: "sc-1r0qsxg-0"
})(["position:relative;width:100%;height:auto;display:flex;flex-direction:column;align-items:flex-start;justify-content:flex-start;padding:18px 0 0 0;margin-top:8px;max-width:1185px;", ""], media.desktop`
    flex-direction: row;
    justify-content: center;
    ${props => props.isContained ? CardWrapperContained : CardWrapperUncontained}
  `);
const ImageOverlay = styled.img.withConfig({
  displayName: "AdviceCardstyle__ImageOverlay",
  componentId: "sc-1r0qsxg-1"
})(["width:100%;max-width:180px;", ""], media.tablet`
    width: 210px;
  `);
const CardContent = styled.div.withConfig({
  displayName: "AdviceCardstyle__CardContent",
  componentId: "sc-1r0qsxg-2"
})(["display:flex;align-items:flex-start;justify-content:center;align-items:center;flex-flow:row nowrap;width:100%;height:auto;max-width:", ";padding:16px 30px;background-color:", ";background-image:url(", ");background-repeat:no-repeat;background-position:left top;background-size:875px auto;gap:30px;", " ", " ", " ", ""], props => props.isContained ? '100%' : '1112px', props => props.theme.colors.background_card, smallWave, friendlyMedia.mobile`
    flex-direction: column;
    align-items: flex-start;
  `, media.mobile`
    background-size: 915px auto;
  `, media.phoneLandscape`
    min-height: 299px;
  `, media.desktop`
    gap: 60px;
    align-items: center;
    justify-content: flex-start;
    min-height: 299px;
    background-image: url(${largeWave});
    padding: 48px 92px 48px 92px;
    ${props => props.isContained ? `background-size: 100% auto;` : `background-size: 1112px auto;`}
  `);
const TextualContent = styled.div.withConfig({
  displayName: "AdviceCardstyle__TextualContent",
  componentId: "sc-1r0qsxg-3"
})(["", ""], media.desktop`
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 660px;
  `);
const TitleRow = styled.div.withConfig({
  displayName: "AdviceCardstyle__TitleRow",
  componentId: "sc-1r0qsxg-4"
})(["display:flex;flex-flow:column nowrap;margin-bottom:20px;", ""], media.phoneLandscape`
    flex-flow: row nowrap;
  `);
const LogoWrapper = styled.div.withConfig({
  displayName: "AdviceCardstyle__LogoWrapper",
  componentId: "sc-1r0qsxg-5"
})(["max-width:243px;"]);
const Tag = styled.div.withConfig({
  displayName: "AdviceCardstyle__Tag",
  componentId: "sc-1r0qsxg-6"
})(["display:inline-flex;align-items:center;justify-content:center;flex:0;width:54px;padding:5px 10px;margin-bottom:16px;white-space:nowrap;font-size:14px;font-weight:600;background-color:", ";color:", ";text-transform:capitalize;", ""], props => props.theme.colors.chart_4, props => props.theme.colors.text_paragraph_inverse, media.phoneLandscape`
    margin-right: 16px;
    margin-bottom: 0
  `);
const Subtitle = styled.p.withConfig({
  displayName: "AdviceCardstyle__Subtitle",
  componentId: "sc-1r0qsxg-7"
})(["font-family:'Linik Sans';font-style:normal;font-weight:600;font-size:15px;line-height:22px;margin-bottom:8px;"]);
const Description = styled.div.withConfig({
  displayName: "AdviceCardstyle__Description",
  componentId: "sc-1r0qsxg-8"
})(["font-family:'Linik Sans';font-style:normal;font-weight:400;font-size:15px;line-height:22px;margin-bottom:24px;"]);
const CTAButton = styled(Button).withConfig({
  displayName: "AdviceCardstyle__CTAButton",
  componentId: "sc-1r0qsxg-9"
})(["min-width:unset;", ""], media.desktop`
    max-width: 280px;
  `);
export { CardWrapper, ImageOverlay, CardContent, TitleRow, LogoWrapper, Tag, Subtitle, Description, CTAButton, TextualContent };