import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useEffect, useCallback } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { PageLoader } from '@yieldstreet/ui-kit';
import isEmpty from 'lodash/isEmpty';
import { Communication } from 'sharedComponents/Communication';
import { TwoFAModalContainer } from 'sharedComponents/TwoFaModal/TwoFAModalContainer';
import useAllowedAudiences from 'utils/hooks/useAllowedAudiences';
import useTwoFARedirect from 'utils/hooks/useTwoFARedirect';
import { useUserState } from 'utils/hooks';
import { LOGIN_REDIRECT_ROUTE, SIGNUP_ACCREDITATION_ROUTE } from 'utils/auth';

/*
  This component will redirect users to login when they're not authenticated
  allowedAudiences: an array of audiences imported from platform-kit, inform
    the audiences that should be allowed to access the page, if any
  audienceRedirect: where an user not part of an audiences will be redirected to
    if unset, will be sent to /home
*/

const Auth = _ref => {
  let {
    component: Component,
    computedMatch,
    path,
    allowedAudiences,
    audienceRedirect,
    requiresAccreditedSignUp
  } = _ref;
  const history = useHistory();
  const location = useLocation();
  const {
    pathname,
    search,
    hash
  } = location;
  const currentUrl = pathname + search + hash;
  const userState = useUserState();
  useEffect(() => {
    // If user is not logged they should not be acessing any auth pages, redirect to login
    if (!(userState !== null && userState !== void 0 && userState.loggedIn)) {
      history.replace(`${LOGIN_REDIRECT_ROUTE}?redirect=${currentUrl}`);
      return;
    }

    // If accreditation not submitted redirect to signup accreditation question.
    // Reason why this logic is here because all user experience depends on this info being correctly submitted.
    if (userState !== null && userState !== void 0 && userState.loggedIn && !(userState !== null && userState !== void 0 && userState.accreditationStatusSubmitted)) {
      history.replace(`${SIGNUP_ACCREDITATION_ROUTE}?redirect=${currentUrl}`);
    }
  }, [userState, history, currentUrl]);
  const {
    isAllowed: audiencesAllowed,
    audiences,
    audiencesFetched
  } = useAllowedAudiences({
    allowedAudiences,
    preset: 'login'
  });
  const isAllowed = requiresAccreditedSignUp ? (userState === null || userState === void 0 ? void 0 : userState.accreditedSignUp) && audiencesAllowed : audiencesAllowed;
  const {
    redirectTo2FA
  } = useTwoFARedirect();
  const renderFunc = useCallback(props => isAllowed ? __jsx(React.Fragment, null, redirectTo2FA ? __jsx(TwoFAModalContainer, {
    shouldBeVisible: redirectTo2FA,
    disableClose: true
  }) : null, __jsx(Communication, {
    location: "pop_over"
  }), __jsx(Component, _extends({}, props, {
    match: computedMatch
  }))) : __jsx(Redirect, {
    to: audienceRedirect || '/'
  }), [Component, audienceRedirect, computedMatch, isAllowed, redirectTo2FA]);
  if (userState !== null && userState !== void 0 && userState.loggedIn && userState !== null && userState !== void 0 && userState.accreditationStatusSubmitted && (audiencesAllowed && !isEmpty(audiences) || !audiencesAllowed) && audiencesFetched) {
    return __jsx(Route, {
      path: path,
      render: renderFunc
    });
  }
  return __jsx(PageLoader, null);
};
export default withRouter(Auth);