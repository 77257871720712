import { genTestIds, createYupObjectSchema } from '@yieldstreet/tool-kit';
export const TwoFASchema = createYupObjectSchema({
  twoFACode: {
    validationType: 'default-string'
  }
});
export const tryAgain = 'Try again in';
const {
  getTestIds
} = genTestIds(['icon', 'title', 'description', 'button', 'resendAnchor', 'intercomLink', 'codeField', 'countdown']);
export const testIds = getTestIds('confirm-method-modal');