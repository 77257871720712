import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useMemo } from 'react';
import isEmpty from "lodash/isEmpty";
import { TierLevels } from '@yieldstreet/platform-kit';
import { clientGroupTitle, dedicatedClientGroupContent, exclusiveEventsContent, investmentOfferingContent, prioritySupportContent, TierBenefitsSectionIds } from './useTierBenefits.model';
export const useTierBenefits = tierLevel => {
  return useMemo(() => {
    if (!tierLevel || tierLevel === TierLevels.DEFAULT) {
      return [];
    }
    const renderPrioritySupport = [TierLevels.PLATINUM, TierLevels.DIAMOND].includes(tierLevel);
    const config = [{
      content: investmentOfferingContent[tierLevel],
      id: TierBenefitsSectionIds.InvestmentOffering,
      title: 'Exclusive investments and early access'
    }, {
      content: dedicatedClientGroupContent[tierLevel],
      id: TierBenefitsSectionIds.DedicatedClientGroup,
      title: clientGroupTitle[tierLevel]
    }, _objectSpread({}, renderPrioritySupport && {
      content: prioritySupportContent[tierLevel],
      id: TierBenefitsSectionIds.PrioritySupport,
      title: 'Priority support'
    }), {
      content: exclusiveEventsContent[tierLevel],
      id: TierBenefitsSectionIds.ExclusiveEvents,
      title: 'Premier events and experiences'
    }];
    return config.filter(item => !isEmpty(item));
  }, [tierLevel]);
};