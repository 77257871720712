import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var __jsx = React.createElement;
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { quizApi } from '@yieldstreet/platform-kit';
import { Notification } from '@yieldstreet/ui-kit';
import { Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { ADVICE_QUIZ_KEY, ADVICE_QUIZ_QUESTION_KEYS } from 'utils/advice';
import { ADVICE_PREFERENCES_FORMS } from './AdvicePreferencesForm.model';
import { getInitialValuesFromAnswers, parseBulkPreferences } from './utils/advicePreferences';
import { useUserState } from 'utils/hooks';
const AdvicePreferencesForm = _ref => {
  let {
    formName
  } = _ref;
  const {
    Form,
    initialValues,
    validationSchema,
    notification,
    questionKeys
  } = ADVICE_PREFERENCES_FORMS[formName];
  const quizId = useMemo(() => {
    return uuidv4();
  }, []);
  const {
    id: userId = null
  } = useUserState();
  const {
    user: {
      analyticsAnonymousId: anonymousUserId = null
    }
  } = useSelector(_ref2 => {
    let {
      user
    } = _ref2;
    return {
      user
    };
  });
  const {
    data
  } = quizApi.useGetQuizAnswersByUserIdQuery({
    userId,
    quizKey: ADVICE_QUIZ_KEY,
    questionKeys: ADVICE_QUIZ_QUESTION_KEYS
  }, {
    refetchOnMountOrArgChange: true
  });
  const prefilledAnswers = getInitialValuesFromAnswers(data === null || data === void 0 ? void 0 : data.answers, questionKeys);
  const formInitialValues = _objectSpread(_objectSpread({}, initialValues), prefilledAnswers);
  const [postAnswers, {
    isSuccess,
    error
  }] = quizApi.usePostAnswersMutation();
  const handleSubmit = async (values, actions) => {
    const parsedBulkPreferences = parseBulkPreferences({
      quizId,
      userId,
      anonymousUserId,
      formAnswers: values
    });
    await postAnswers({
      answers: parsedBulkPreferences
    });
    actions.resetForm(values);
    actions.setSubmitting(false);
  };
  return __jsx(React.Fragment, null, isSuccess && __jsx(Notification, _extends({
    severity: "success",
    inline: true
  }, notification.successProps)), error && __jsx(Notification, _extends({
    severity: "error",
    title: "Unable to update your preferences.",
    description: "Please try again, and if the problem persists, contact us.",
    inline: true
  }, notification.errorProps)), __jsx(Formik, {
    onSubmit: handleSubmit,
    validateOnChange: true,
    initialValues: formInitialValues,
    enableReinitialize: true,
    validationSchema: validationSchema
  }, formikProps => {
    return __jsx(Form, {
      formikProps: formikProps,
      answersData: data === null || data === void 0 ? void 0 : data.answers
    });
  }));
};
export { AdvicePreferencesForm };