import _Snackbar from "@mui/material/Snackbar";
import { styled } from '@mui/system';
import { SnackbarTypes } from '@yieldstreet/tool-kit';
const snackbarColorPicker = _ref => {
  let {
    theme,
    type
  } = _ref;
  switch (type) {
    case SnackbarTypes.Success:
      return theme.palette.success.main;
    case SnackbarTypes.Error:
      return theme.palette.error.main;
    case SnackbarTypes.Warning:
      return theme.palette.warning.main;
    case SnackbarTypes.Info:
    default:
      return theme.palette.info.main;
  }
};
export const SnackbarItem = styled(_Snackbar)(_ref2 => {
  let {
    theme,
    type
  } = _ref2;
  return {
    position: 'relative',
    animation: 'fadeIn ease 0.5s',
    '@keyframes fadeIn': {
      from: {
        opacity: 0
      },
      to: {
        opacity: 1
      }
    },
    '&:not(:first-child)': {
      marginTop: theme.spacing(2)
    },
    '.MuiSnackbar-root': {
      position: 'relative'
    },
    '.MuiSnackbarContent-message': {
      padding: 0
    },
    '.MuiPaper-elevation6': {
      boxShadow: 'none'
    },
    '.MuiSnackbarContent-root': {
      width: '100%',
      padding: theme.spacing(5),
      background: snackbarColorPicker({
        theme: theme,
        type
      }),
      borderRadius: 0,
      [theme.breakpoints.up('sm')]: {
        width: '375px'
      }
    }
  };
});
export const SnackbarItemWrapper = styled('div')(_ref3 => {
  let {
    theme
  } = _ref3;
  return {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: theme.spacing(5),
    zIndex: 100,
    [theme.breakpoints.up('sm')]: {
      top: '115px',
      bottom: 'unset',
      left: 'unset',
      right: '34px !important'
    }
  };
});