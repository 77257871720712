var __jsx = React.createElement;
import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { createGlobalStyle } from 'styled-components';
import ENV_CONFIG from 'utils/env/config';
const HideReCaptchBadge = createGlobalStyle([".grecaptcha-badge{visibility:hidden;}"]);
export const ReCaptchaProvider = _ref => {
  let {
    children
  } = _ref;
  return __jsx(React.Fragment, null, __jsx(GoogleReCaptchaProvider, {
    reCaptchaKey: ENV_CONFIG.RECAPTCHA_CLIENT_ID,
    useEnterprise: true
  }, children), __jsx(HideReCaptchBadge, null));
};