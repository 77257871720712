var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { Paragraph } from '../../content/text';
import { SectionContainer } from '../../layout/section-container';
import { PageSection } from '../../layout/page-section';
import { media } from '../../themes/media';
export const TextBanner = _ref => {
  let {
    title,
    children,
    inverse
  } = _ref;
  return __jsx(OuterContainer, null, __jsx(PageSection, null, __jsx(Container, null, __jsx(InnerContainer, null, __jsx(Title, {
    inverse: inverse,
    small: true,
    semiBold: true
  }, title), __jsx(Paragraph, {
    inverse: inverse,
    small: true
  }, children)))));
};
const OuterContainer = styled.div.withConfig({
  displayName: "TextBanner__OuterContainer",
  componentId: "sc-1mtjzk2-0"
})(["background:", ";"], props => props.theme.colors.background_default);
const InnerContainer = styled.div.withConfig({
  displayName: "TextBanner__InnerContainer",
  componentId: "sc-1mtjzk2-1"
})(["margin:0 auto;text-align:left;padding:20px 0;box-sizing:border-box;", ";"], media.desktop`
    text-align: center;
  `);
const Title = styled(Paragraph).withConfig({
  displayName: "TextBanner__Title",
  componentId: "sc-1mtjzk2-2"
})(["margin-bottom:10px;"]);
const Container = styled(SectionContainer).withConfig({
  displayName: "TextBanner__Container",
  componentId: "sc-1mtjzk2-3"
})(["padding-top:0;padding-bottom:0;", ";"], media.tablet`
    padding-top: 0;
    padding-bottom: 0;
  `);