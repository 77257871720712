export const BannerGlobalStyle = theme => ({
  '#truste-consent-track': {
    padding: `${theme.spacing(4)} !important`,
    zIndex: '2147483641 !important'
  },
  '#truste-consent-content': {
    textAlign: 'left',
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
      alignItems: 'center'
    }
  },
  '#truste-consent-text': {
    marginBottom: `0 !important`,
    fontSize: `${theme.typography.caption.fontSize} !important`,
    lineHeight: 'inherit !important',
    fontFamily: theme.typography.caption.fontFamily
  },
  '#truste-consent-buttons': {
    float: 'none',
    display: 'flex',
    flex: '1 0 auto !important',
    margin: 0,
    marginTop: theme.spacing(2),
    justifyContent: 'flex-end',
    [theme.breakpoints.up('sm')]: {
      margin: 0,
      marginLeft: theme.spacing(4)
    },
    button: {
      fontFamily: theme.typography.caption.fontFamily,
      minWidth: 'auto !important',
      width: 'auto !important',
      fontSize: `${theme.typography.caption.fontSize} !important`,
      padding: theme.spacing(2, 4),
      margin: theme.spacing(1)
    }
  }
});