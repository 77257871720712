export const ADVICE_RECOMMENDATIONS_PATH = '/advice/recommendations';
export const SAVE_FOR_LATER_SNACKBAR_AUTOHIDE_DURATION = 4000;
export const SAVE_FOR_LATER__SNACKBAR_THROTTLE = 3000;
export const SAVE_FOR_LATER_SNACKBAR_TITLE = 'Your recommendations have been saved';
export const ADVICE_RECOMMENDATIONS_IDS = {
  pageWrapper: 'advice-recommendations',
  snackbar: 'advice-recommendations-snackbar',
  loader: 'advice-recommendations-loader'
};
export const NO_RECOMMENDATIONS_TITLE = "We're still gathering your recommendations";
export const NO_RECOMMENDATIONS_DESCRIPTION = ' We’re still processing your preferences to make sure you’re getting the best recommendations available. Please check back in a few minutes.';
export const NO_RECOMMENDATIONS_PROBLEM = 'Still having problems?';
export const NO_RECOMMENDATIONS_LINK = 'Chat with an Investor Relations representative.';
export const NO_RECOMMENDATIONS_BUTTON = 'Refresh';