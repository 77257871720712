import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["fileDescription"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';
import fileIcon from './icons/upload.svg';
import { Paragraph, ParagraphLink, Label } from '../../content/text';
import { media } from '../../themes/media';
import { useTestIds } from './FileDropzone.model';
const FileDropzone = props => {
  const {
      fileDescription
    } = props,
    dropzoneProps = _objectWithoutProperties(props, _excluded);
  const testIds = useTestIds(props);
  return __jsx(Dropzone, dropzoneProps, _ref => {
    let {
      getRootProps,
      getInputProps
    } = _ref;
    return __jsx(SectionWrapper, testIds.base.attr, __jsx(Content, _extends({}, getRootProps(), testIds.root.attr), __jsx("input", _extends({}, getInputProps(), testIds.input.attr)), __jsx(FileIcon, {
      src: fileIcon,
      alt: ""
    }), __jsx(MobileParagraph, {
      small: true
    }, "Upload ", fileDescription || 'file'), __jsx(DesktopParagraph, {
      small: true
    }, "Drag & Drop"), __jsx(DesktopLabel, null, "Drag your ", fileDescription || 'file', " here or", ' ', __jsx(Underline, null, "browse for a ", fileDescription || 'file'), " to upload")));
  });
};
const MobileParagraph = styled.span.withConfig({
  displayName: "FileDropzone__MobileParagraph",
  componentId: "sc-fmhzfm-0"
})(["", ";display:block;width:fit-content;margin:auto;", ""], ParagraphLink, media.tablet`
    display: none;
  `);
const DesktopParagraph = styled(Paragraph).withConfig({
  displayName: "FileDropzone__DesktopParagraph",
  componentId: "sc-fmhzfm-1"
})(["display:none;", ""], media.tablet`
    display: block;
  `);
const DesktopLabel = styled(Label).withConfig({
  displayName: "FileDropzone__DesktopLabel",
  componentId: "sc-fmhzfm-2"
})(["display:none;", ""], media.tablet`
    display: block;
    padding: 0 10px;
  `);
const FileIcon = styled.img.withConfig({
  displayName: "FileDropzone__FileIcon",
  componentId: "sc-fmhzfm-3"
})(["margin-bottom:10px;width:40px;height:40px;", ""], media.tablet`
    margin-bottom: 20px;
  `);
const SectionWrapper = styled.section.withConfig({
  displayName: "FileDropzone__SectionWrapper",
  componentId: "sc-fmhzfm-4"
})(["background:", ";text-align:center;"], props => props.theme.colors.background_card);
const Content = styled.div.withConfig({
  displayName: "FileDropzone__Content",
  componentId: "sc-fmhzfm-5"
})(["border:none;padding:40px 0;outline:none;text-decoration:none;", ""], media.tablet`
    border: 1px dashed ${props => props.theme.colors.border_default};
    padding: 20px 0;
  `);
const Underline = styled.span.withConfig({
  displayName: "FileDropzone__Underline",
  componentId: "sc-fmhzfm-6"
})(["text-decoration:underline;"]);
export default FileDropzone;