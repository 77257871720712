import { genTestIds } from '@yieldstreet/tool-kit';
import { INVESTOR_ENTITY_KYC_PATH } from 'pages/investorEntity/InvestorEntityContainer.model';
import { INVESTOR_ENTITY_KYC_MIGRATION_BANK_LINK_PATH } from '../KYCMigrationContainer.model';
export const KYC_MIGRATION_PATH = INVESTOR_ENTITY_KYC_PATH;
export const BANK_LINK_MIGRATION_PATH = INVESTOR_ENTITY_KYC_MIGRATION_BANK_LINK_PATH;
const {
  getTestIds
} = genTestIds(['loader', 'icon', 'pending-info', 'success-info', 'failure-info', 'migration-info', 'migration-list']);
export const testIds = getTestIds('migration');
export const copy = {
  migration: {
    title: 'Action required: Banking services update',
    description: 'Yieldstreet has switched to Plaid for payment processing. To continue making investments and receiving distribution payments, please reverify your identity and connect your external bank account(s).'
  },
  final: {
    title: `Your account has been reactivated`,
    description: {
      single: 'Your account has been successfully reactivated. Your investment distribution payments will be sent to the following account.',
      multiple: 'Your accounts have been successfully reactivated. Your investment distribution payments will be sent to the following accounts.'
    },
    button: 'Done'
  },
  pending: {
    title: {
      single: `Your account has been partially reactivated.`,
      multiple: `Your accounts has been partially reactivated.`
    },
    description: {
      single: 'To fully activate your account, please verify your bank account using micro-deposits. These deposits should arrive within 1-2 business days.',
      multiple: 'One of your accounts requires bank account verification using micro-deposits. These deposits should arrive within 1-2 business days.'
    },
    button: 'Done'
  },
  problem: {
    title: {
      single: 'We were unable to connect your bank account.',
      multiple: 'An issue was encountered while reactivating one of your accounts.'
    },
    irCTA: 'Contact Investor Relations',
    irText: 'Please contact investor relations to resolve this issue.'
  }
};