import update from 'immutability-helper';
import * as actions from 'store/actions/originator';
const initialState = {
  originator: {},
  isFetchingOriginator: false,
  fetchedOriginator: false,
  isFetchingAncillary: false,
  fetchedAncillary: false,
  ancillary: null,
  originatorData: {},
  savingOriginatorData: false,
  savedOriginatorData: false
};
const originator = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case actions.GET_ORIGINATOR_ANCILLARY_ATTEMPT:
      {
        return update(state, {
          isFetchingAncillary: {
            $set: true
          },
          fetchedAncillary: {
            $set: false
          }
        });
      }
    case actions.GET_ORIGINATOR_ANCILLARY_SUCCESS:
      {
        return update(state, {
          isFetchingAncillary: {
            $set: false
          },
          fetchedAncillary: {
            $set: true
          },
          ancillary: {
            $set: action.response
          }
        });
      }
    case actions.GET_ORIGINATOR_ANCILLARY_FAILURE:
      {
        return update(state, {
          isFetchingAncillary: {
            $set: false
          },
          fetchedAncillary: {
            $set: true
          }
        });
      }
    case actions.POST_ORIGINATOR_REQUEST_ATTEMPT:
      {
        return update(state, {
          savingOriginatorData: {
            $set: true
          },
          savedOriginatorData: {
            $set: false
          }
        });
      }
    case actions.POST_ORIGINATOR_REQUEST_SUCCESS:
      {
        var _action$requestPayloa;
        const shouldClearOriginator = action === null || action === void 0 ? void 0 : (_action$requestPayloa = action.requestPayload) === null || _action$requestPayloa === void 0 ? void 0 : _action$requestPayloa.complete;
        return update(state, {
          originatorData: {
            $set: shouldClearOriginator ? null : action.response.originator
          },
          savingOriginatorData: {
            $set: false
          },
          savedOriginatorData: {
            $set: true
          }
        });
      }
    case actions.POST_ORIGINATOR_REQUEST_FAILURE:
      {
        return update(state, {
          savingOriginatorData: {
            $set: false
          },
          savedOriginatorData: {
            $set: false
          }
        });
      }
    case actions.GET_ORIGINATOR_ATTEMPT:
      {
        return update(state, {
          isFetchingOriginator: {
            $set: true
          },
          fetchedOriginator: {
            $set: false
          }
        });
      }
    case actions.GET_ORIGINATOR_SUCCESS:
      {
        return update(state, {
          isFetchingOriginator: {
            $set: false
          },
          fetchedOriginator: {
            $set: true
          }
        });
      }
    case actions.GET_ORIGINATOR_FAILURE:
      {
        return update(state, {
          isFetchingOriginator: {
            $set: false
          },
          fetchedOriginator: {
            $set: true
          }
        });
      }
    case actions.GET_ORIGINATOR_TITLE_SUCCESS:
      {
        return update(state, {
          originator: {
            $merge: {
              id: action.response.id,
              title: action.response.displayName,
              nickname: action.response.nickname,
              jsonPage: JSON.parse(action.response.customFields)
            }
          }
        });
      }
    case actions.GET_ORIGINATOR_DETAILS_SUCCESS:
      {
        const fields = ['class="summary"', 'class="highlights"', 'class="various-wrapper"', 'class="management"'];
        return update(state, {
          originator: {
            $merge: {
              desc: action.response.descriptionFields.filter(field => field.includes(fields[0]))[0],
              hLight: action.response.descriptionFields.filter(field => field.includes(fields[1]))[0],
              opOvrvw: action.response.descriptionFields.filter(field => field.includes(fields[2]))[0],
              mgmt: action.response.descriptionFields.filter(field => field.includes(fields[3]))[0]
            }
          }
        });
      }
    case actions.GET_ORIGINATOR_PAST_ACTIVITY_SUCCESS:
      {
        return update(state, {
          originator: {
            $merge: {
              pastActivity: action.response.pastActivity
            }
          }
        });
      }
    default:
      return state;
  }
};
export default originator;