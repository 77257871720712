var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { media } from '../../../themes';
import { Paragraph } from '../../../content/text';
import VeryGoodIcon from '../../../assets/graphics/icons/very-good.svg';
export const TitleWithContent = _ref => {
  let {
    title,
    image,
    titleAlignment = 'left',
    contentAlignment = 'left',
    iconPosition = 'inline',
    children,
    inverse,
    className
  } = _ref;
  return __jsx(OuterContainer, {
    className: className
  }, __jsx(TitleHolder, {
    titleAlignment: titleAlignment,
    iconPosition: iconPosition
  }, image ? image : __jsx(Icon, {
    src: VeryGoodIcon,
    alt: "check"
  }), ' ', __jsx(Title, {
    semiBold: true,
    inverse: inverse
  }, title)), __jsx(Content, {
    contentAlignment: contentAlignment,
    small: true,
    inverse: inverse
  }, children));
};
const OuterContainer = styled.div.withConfig({
  displayName: "TitleWithContent__OuterContainer",
  componentId: "sc-8z2a3n-0"
})(["flex-grow:1;flex-basis:0;", ""], media.desktop`
    max-width: '280px';
    &:first-child { 
      margin: 0 10px 0 0;
    }
    &:last-child { 
      margin: 0 0 0 10px;
    }
    &:not(:first-child):not(:last-child){
      margin: 0 10px;
    }
  `);
const Title = styled(Paragraph).withConfig({
  displayName: "TitleWithContent__Title",
  componentId: "sc-8z2a3n-1"
})([""]);
const TitleHolder = styled.div.withConfig({
  displayName: "TitleWithContent__TitleHolder",
  componentId: "sc-8z2a3n-2"
})(["text-align:", ";margin:30px 0 10px 0;display:flex;flex-direction:", ";align-items:", ";justify-content:flex-start;img{margin:", ";}", ""], props => props.titleAlignment, props => props.iconPosition === 'inline' ? 'row' : 'column', props => props.iconPosition === 'inline' ? 'center' : 'flex-start', props => props.iconPosition === 'inline' ? '0 10px 0 0' : '0 0 10px 0', media.desktop`
    margin-top: 0;
  `);
const Content = styled(Paragraph).withConfig({
  displayName: "TitleWithContent__Content",
  componentId: "sc-8z2a3n-3"
})(["margin:10px 0 0 0;text-align:", ";"], props => props.contentAlignment);
const Icon = styled.img.withConfig({
  displayName: "TitleWithContent__Icon",
  componentId: "sc-8z2a3n-4"
})([""]);