import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { genTestIds } from '@yieldstreet/tool-kit';
import { NotificationTypeEnum } from '@yieldstreet/ui-kit/legacy/elements/notification/Notification.model.ts';
import { VerificationOwner, BankAccountExternalPermission } from '@yieldstreet/platform-kit';
const {
  getTestIds
} = genTestIds(['microDeposit', 'microDepositAuto', 'accountLimitReached', 'degraded', 'inactiveOrLocked']);
export const testIds = getTestIds('bank-account-notifications');
export const BankAccountNotificationStates = {
  BANK_ACCOUNT_DEGRADED: 'BANK_ACCOUNT_DEGRADED',
  BANK_ACCOUNT_LIMIT_REACHED: 'BANK_ACCOUNT_LIMIT_REACHED',
  BANK_ACCOUNT_MICRO_DEPOSIT_VERIFICATION: 'BANK_ACCOUNT_MICRO_DEPOSIT_VERIFICATION',
  BANK_ACCOUNT_LOCKED_OR_INACTIVE: 'BANK_ACCOUNT_LOCKED_OR_INACTIVE'
};
export const getNotificationStates = _ref => {
  var _degradedBankAccounts;
  let {
    notificationState,
    bankAccount,
    isMulti,
    degradedBankAccounts
  } = _ref;
  switch (notificationState) {
    case BankAccountNotificationStates.BANK_ACCOUNT_DEGRADED:
      const isDefaultLocked = (degradedBankAccounts === null || degradedBankAccounts === void 0 ? void 0 : (_degradedBankAccounts = degradedBankAccounts.find(bankAccount => bankAccount.defaultAccount)) === null || _degradedBankAccounts === void 0 ? void 0 : _degradedBankAccounts.externalPermission) === BankAccountExternalPermission.LOCKED;
      const content = isMulti ? {
        title: 'Some of your accounts have lost their connection to Plaid',
        description: isDefaultLocked ? 'Please update your preferred bank account to continue receiving payments and funding future investments.' : 'Please delete the disconnected bank accounts and link a new one.'
      } : {
        title: 'Your preferred bank account has lost its connection to Plaid',
        description: isDefaultLocked ? 'Please update your preferred bank account to continue receiving payments and funding future investments.' : 'Your current preferred bank account will continue to receive payments. To fund future investments, please update your preferred bank account by selecting or adding a new one.'
      };
      return _objectSpread(_objectSpread({
        severity: `${NotificationTypeEnum.WARNING}`
      }, content), {}, {
        testId: testIds.degraded.id,
        notificationType: BankAccountNotificationStates.BANK_ACCOUNT_DEGRADED,
        updateBankButton: true
      });
    case BankAccountNotificationStates.BANK_ACCOUNT_LIMIT_REACHED:
      return {
        severity: `${NotificationTypeEnum.WARNING}`,
        title: 'You have reached our limit on number of bank accounts.',
        description: 'If you wish to add another external account, please delete an unused external bank account and try again or contact us at investments@yieldstreet.com for assistance.',
        testId: testIds.accountLimitReached.id,
        notificationType: BankAccountNotificationStates.BANK_ACCOUNT_LIMIT_REACHED,
        updateBankButton: false
      };
    case BankAccountNotificationStates.BANK_ACCOUNT_MICRO_DEPOSIT_VERIFICATION:
      switch (bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.verificationOwner) {
        case VerificationOwner.PLAID:
          return {
            severity: `${NotificationTypeEnum.WARNING}`,
            description: `Micro-deposit automatic verification by your bank is pending for ${bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.nickname}. No further action is required by you`,
            testId: testIds.microDepositAuto.id,
            notificationType: BankAccountNotificationStates.BANK_ACCOUNT_MICRO_DEPOSIT_VERIFICATION,
            updateBankButton: false
          };
        case VerificationOwner.USER:
        default:
          return {
            severity: `${NotificationTypeEnum.WARNING}`,
            description: `Micro-deposit confirmation needed to complete setup for ${bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.nickname}`,
            testId: testIds.microDeposit.id,
            notificationType: BankAccountNotificationStates.BANK_ACCOUNT_MICRO_DEPOSIT_VERIFICATION,
            callback: {
              action: () => bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.id,
              cta: 'Complete now'
            },
            updateBankButton: false
          };
      }
    case BankAccountNotificationStates.BANK_ACCOUNT_LOCKED_OR_INACTIVE:
      if (degradedBankAccounts !== null && degradedBankAccounts !== void 0 && degradedBankAccounts.length) {
        return {
          testId: testIds.inactiveOrLocked.id,
          updateBankButton: false
        };
      }
      return {
        severity: `${NotificationTypeEnum.ERROR}`,
        description: `Account ${bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.nickname} ${bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.externalPermission.toLowerCase()}. Contact investments@yieldstreet.com`,
        testId: testIds.inactiveOrLocked.id,
        inline: true,
        notificationType: BankAccountNotificationStates.BANK_ACCOUNT_LOCKED_OR_INACTIVE,
        updateBankButton: false
      };
    default:
      return {
        severity: `${NotificationTypeEnum.ERROR}`,
        title: `Whoops something went wrong!`,
        description: ` If this issue happens please Contact investments@yieldstreet.com`,
        testId: testIds.inactiveOrLocked.id,
        updateBankButton: false
      };
  }
};