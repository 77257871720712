import styled from 'styled-components';
export const CheckboxContainer = styled.div.withConfig({
  displayName: "EligibilityFieldsstyle__CheckboxContainer",
  componentId: "sc-vqxlfw-0"
})(["display:flex;align-items:center;.MuiFormControlLabel-root{margin-right:0 !important;}"]);
export const TooltipWrapper = styled.div.withConfig({
  displayName: "EligibilityFieldsstyle__TooltipWrapper",
  componentId: "sc-vqxlfw-1"
})(["display:flex;align-self:center;padding-left:", ";"], props => props.theme.spacing.xxs);
export const NotificationWrapper = styled.div.withConfig({
  displayName: "EligibilityFieldsstyle__NotificationWrapper",
  componentId: "sc-vqxlfw-2"
})(["display:block;position:relative;width:100%;max-width:820px;margin-bottom:20px;"]);
export const NotificationContent = styled.div.withConfig({
  displayName: "EligibilityFieldsstyle__NotificationContent",
  componentId: "sc-vqxlfw-3"
})(["display:inline-block;margin-top:5px;"]);