import { useEffect, useMemo } from 'react';
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";
import { skipToken } from '@reduxjs/toolkit/query';
import { AccreditationEligibility, AudiencePresetTypes, LegalEntityType, PerformanceStatus, PortfolioFilters, investmentsApi, mapAudienceResponse, marketplaceApi } from '@yieldstreet/platform-kit';
export const useUpcomingMaturities = _ref => {
  var _portfolioInvestments, _portfolioInvestments2;
  let {
    investorId,
    userId,
    managementStrategy
  } = _ref;
  // Load and Map Audience Resolution
  const [getResolveAudiences, getResolveAudiencesResult] = marketplaceApi.useLazyGetResolveAudiencesQuery();
  const {
    data: audiencesData,
    isLoading: audiencesIsLoading,
    isError: audiencesIsError
  } = getResolveAudiencesResult;
  useEffect(() => {
    if (!audiencesData && !audiencesIsLoading) {
      getResolveAudiences(AudiencePresetTypes.PORTFOLIO);
    }
  }, [audiencesData, audiencesIsLoading, getResolveAudiences]);
  const audiencesResolution = useMemo(() => {
    if (audiencesData) {
      return mapAudienceResponse(audiencesData.resolution);
    }
    return {};
  }, [audiencesData]);

  // Load Open Offerings upon resolving sudience
  const {
    data: openOfferings,
    isLoading: openOfferingsIsLoading,
    isFetching: openOfferingsIsFetching,
    isError: openOfferingsIsError
  } = marketplaceApi.useGetOfferingsQuery({
    offeringType: 'open',
    accreditation: audiencesResolution !== null && audiencesResolution !== void 0 && audiencesResolution.allNAI ? AccreditationEligibility.NONA : undefined
  }, {
    skip: audiencesIsLoading || audiencesIsError
  });

  // Load getInvestmentsByStatus
  const investmentsByStatusQueryConfig = useMemo(() => userId ? {
    userId,
    managementStrategy
  } : skipToken, [userId, managementStrategy]);
  const {
    data: portfolioInvestmentsByStatus,
    isLoading: portfolioInvestmentsByStatusIsLoading,
    isFetching: portfolioInvestmentsByStatusIsFetching,
    isError: portfolioInvestmentsByStatusIsError
  } = investmentsApi.useGetInvestmentsByStatusQuery(investmentsByStatusQueryConfig);
  const activeInvestments = portfolioInvestmentsByStatus === null || portfolioInvestmentsByStatus === void 0 ? void 0 : (_portfolioInvestments = portfolioInvestmentsByStatus.byStatus) === null || _portfolioInvestments === void 0 ? void 0 : (_portfolioInvestments2 = _portfolioInvestments.ACTIVE) === null || _portfolioInvestments2 === void 0 ? void 0 : _portfolioInvestments2.investments;

  /**
   * Filter ActiveInvestments as to omit investments which are NOT Performing.
   * Filter ActiveInvestments as to limit the list to 3 Items.
   */
  const activeMaturingInvestments = useMemo(() => {
    return sortBy(activeInvestments, activeInvestment => activeInvestment.terminationDate).filter(investment => (investorId === PortfolioFilters.ALL || investment.investorAccountId === investorId) && investment.performanceStatus === PerformanceStatus.PERFORMING).filter((_, index) => index < 3);
  }, [activeInvestments, investorId]);
  const hasActiveMaturingInvestments = !isEmpty(activeMaturingInvestments);

  /**
   * Filter Offerings as to omit the list from any Active Investments the user might have.
   * Filter Offerings as to omit the list from Short Term Notes.
   * Filter Offerings as to limit the list to 2 Items.
   */
  const activeRecentOfferings = useMemo(() => {
    if (openOfferings !== null && openOfferings !== void 0 && openOfferings.offerings) {
      const {
        offerings
      } = openOfferings;
      return offerings.filter((offering, index) => !find(activeInvestments, investment => investment.noteUrlHash === offering.urlHash) && offering.legalEntityType !== LegalEntityType.SHTN && index < 2);
    }
    return [];
  }, [openOfferings, activeInvestments]);
  const hasActiveRecentOfferings = !isEmpty(activeRecentOfferings);
  const upcomingMaturitiesIsLoading = audiencesIsLoading || openOfferingsIsLoading || portfolioInvestmentsByStatusIsLoading;
  const upcomingMaturitiesIsFetching = portfolioInvestmentsByStatusIsFetching || openOfferingsIsFetching;
  const upcomingMaturitiesIsError = audiencesIsError || openOfferingsIsError || portfolioInvestmentsByStatusIsError;
  const upcomingMaturitiesIsEmpty = !hasActiveMaturingInvestments && !upcomingMaturitiesIsFetching && !upcomingMaturitiesIsError;
  const upcomingMaturitiesIsDataLoaded = !upcomingMaturitiesIsFetching && !upcomingMaturitiesIsError && (hasActiveMaturingInvestments || hasActiveRecentOfferings);
  return {
    upcomingMaturitiesIsLoading,
    upcomingMaturitiesIsFetching,
    upcomingMaturitiesIsError,
    upcomingMaturitiesIsEmpty,
    upcomingMaturitiesIsDataLoaded,
    hasActiveMaturingInvestments,
    activeMaturingInvestments,
    activeRecentOfferings,
    hasActiveRecentOfferings
  };
};