import { sizeProps } from '@yieldstreet/ui-kit';
export const responsive = function (callback) {
  let friendlyResponse = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  const asyncCheckViewPort = () => {
    if (window.innerWidth >= sizeProps.ultrawide[1]) return 'ultrawide';
    if (window.innerWidth >= sizeProps.fullHd[1]) return 'fullHd';
    if (window.innerWidth >= sizeProps.large[1]) return 'large';
    if (window.innerWidth >= sizeProps.desktop[1]) return 'desktop';
    if (window.innerWidth >= sizeProps.tablet[1]) return 'tablet';
    if (window.innerWidth >= sizeProps.phoneLandscape[1]) return 'phoneLandscape';
    return 'mobile';
  };
  const asyncListen = () => {
    window.addEventListener('resize', () => {
      const currentViewPort = asyncCheckViewPort();
      if (friendlyResponse) {
        callback && callback(friendlyResponsive(currentViewPort));
      } else {
        callback && callback(currentViewPort);
      }
    }, false);
  };
  let viewPort = asyncCheckViewPort();
  if (friendlyResponse) {
    callback(friendlyResponsive(viewPort));
  } else {
    callback(viewPort);
  }
  asyncListen();
};
const friendlyResponsive = viewport => {
  switch (viewport) {
    case 'mobile':
    case 'phoneLandscape':
      {
        return 'mobile';
      }
    case 'desktop':
    case 'tablet':
      {
        return 'tablet';
      }
    case 'ultrawide':
    case 'fullHd':
    case 'large':
      {
        return 'desktop';
      }
    default:
      return viewport;
  }
};