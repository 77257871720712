import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserTwoFAStatus } from 'store/actions/user';
export const useTwoFARedirect = () => {
  const dispatch = useDispatch();
  const {
    user
  } = useSelector(state => state);
  const {
    twoFAStatus,
    twoFAStatusFetching,
    twoFAStatusError,
    userState
  } = user;
  const isRegisterSession = userState.source === 'registration';
  const isImpersonatedSession = userState.impersonated;
  useEffect(() => {
    dispatch(getUserTwoFAStatus());
  }, [dispatch]);
  const enforce2FA = twoFAStatus && (twoFAStatus === null || twoFAStatus === void 0 ? void 0 : twoFAStatus.available) && !(twoFAStatus !== null && twoFAStatus !== void 0 && twoFAStatus.enabled) && !twoFAStatusFetching && !twoFAStatusError;
  const redirectTo2FA = !isRegisterSession && !isImpersonatedSession && enforce2FA;
  return {
    enforce2FA: !!enforce2FA,
    redirectTo2FA: !!redirectTo2FA
  };
};
export default useTwoFARedirect;