import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import trashIcon from './icons/trash.svg';
import { Spinner } from '../../layout/spinner';
import { ParagraphLink } from '../../content/text';
import { Notification } from '../../elements/notification';
import { useTestIds } from './FilePreviewDocument.model';
export const FilePreviewImage = props => {
  const {
    file,
    onRemoveClick
  } = props;
  const testIds = useTestIds(props);
  return __jsx(React.Fragment, null, __jsx(PreviewWrapper, _extends({
    key: file.id || file.name
  }, testIds.base.attr), __jsx(PreviewImg, {
    src: file.preview,
    error: file.error
  }), file.sending ? __jsx(Spinner, {
    testId: testIds.loading.id
  }) : __jsx(DeleteWrapper, null, __jsx(TrashIcon, {
    src: trashIcon,
    alt: ""
  }), __jsx(DeleteLink, _extends({
    onClick: onRemoveClick
  }, testIds.remove.attr), "Upload new photo"))), file.error && file.errorDescription && __jsx(Notification, {
    testId: testIds.error.id,
    inline: true,
    severity: "error",
    title: file.errorTitle || '',
    description: file.errorDescription || 'An error has occurred. Please try again, and if the problem persists, contact us.'
  }));
};
const PreviewWrapper = styled.div.withConfig({
  displayName: "FilePreviewImage__PreviewWrapper",
  componentId: "sc-h447xk-0"
})(["margin:20px 0;&:last-child{margin-bottom:0;}"]);
const DeleteWrapper = styled.div.withConfig({
  displayName: "FilePreviewImage__DeleteWrapper",
  componentId: "sc-h447xk-1"
})(["margin-top:20px;"]);
const DeleteLink = styled.span.withConfig({
  displayName: "FilePreviewImage__DeleteLink",
  componentId: "sc-h447xk-2"
})(["", ";margin-left:10px;"], ParagraphLink);
const PreviewImg = styled.img.withConfig({
  displayName: "FilePreviewImage__PreviewImg",
  componentId: "sc-h447xk-3"
})(["display:block;max-width:400px;object-fit:contain;width:100%;", ""], props => props.error && `border: 2px solid ${props.theme.colors.notice_error};`);
const TrashIcon = styled.img.withConfig({
  displayName: "FilePreviewImage__TrashIcon",
  componentId: "sc-h447xk-4"
})(["cursor:pointer;vertical-align:middle;"]);
export default FilePreviewImage;