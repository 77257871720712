import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["testId", "label", "value", "error", "onChange", "name", "caption", "className", "disabled", "description", "descriptionCheckedOnly"];
var __jsx = React.createElement;
import React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { withFormik } from '@yieldstreet/tool-kit';
import { withQuestion, SFormHelperText, FormError, OptionControl, OptionLabel, OptionDescription, OptionControlLabel, useLabelId } from '../common';
export const CheckboxInput = props => {
  const {
      testId,
      label,
      value,
      error,
      onChange,
      name,
      caption,
      className,
      disabled,
      description,
      descriptionCheckedOnly
    } = props,
    otherProps = _objectWithoutProperties(props, _excluded);
  const labelId = useLabelId(name);
  return __jsx(OptionControl, {
    error: !!error,
    className: className,
    "data-checkbox": true,
    "data-checked": value,
    "data-disabled": disabled,
    id: labelId
  }, __jsx(OptionControlLabel, {
    disabled: disabled,
    label: __jsx(React.Fragment, null, __jsx(OptionLabel, {
      "data-checkbox-label": true
    }, label), (!descriptionCheckedOnly || value) && description && __jsx(OptionDescription, {
      small: true,
      "data-checkbox-description": true
    }, description)),
    control: __jsx(Checkbox, _extends({
      "data-checkbox-input": true,
      "data-cy": testId || 'checkbox'
    }, otherProps, {
      name: name,
      checked: value,
      onChange: onChange && ((_e, checked) => onChange(checked))
    }))
  }), error || caption ? __jsx(SFormHelperText, {
    margin: "dense"
  }, error ? __jsx(FormError, {
    testId: "error",
    type: "checkbox-input",
    name: (props === null || props === void 0 ? void 0 : props.name) || 'checkbox',
    error: error
  }) : caption) : null);
};
export const FormCheckboxInput = withFormik(CheckboxInput);
export const QuestionCheckboxInput = withQuestion(CheckboxInput, 'primary');
export const QuestionSecondaryCheckboxInput = withQuestion(CheckboxInput, 'secondary');
export const FormQuestionSecondaryCheckboxInput = withFormik(QuestionSecondaryCheckboxInput);