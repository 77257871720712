import styled from 'styled-components';
import { media } from '../../themes';
export const TabListContainer = styled.div.withConfig({
  displayName: "TabListstyle__TabListContainer",
  componentId: "sc-ed32t2-0"
})(["display:flex;flex-direction:", ";", " ", " overflow-y:scroll;scrollbar-width:none;::-webkit-scrollbar{display:none;}a{background-image:none;}a:active{background-image:none;}"], props => props.vertical ? 'column' : 'row', props => props.vertical && `
    row-gap: ${props.theme.spacing.xs};
    width: 100%;
  `, media.large`
    ${props => props.vertical && `
      row-gap: ${props.theme.spacing.m};
      width: unset;
    `}
  `);
export const TabListItem = styled.div.withConfig({
  displayName: "TabListstyle__TabListItem",
  componentId: "sc-ed32t2-1"
})(["", " ", " flex-shrink:0;&:not(:last-child){margin:0 8px 0 0;display:flex;}", " ", ""], props => props.vertical && `border-bottom: 1px solid ${props.theme.colors.border_default};`, media.large`
    border-bottom: unset;
  `, props => props.vertical ? `
      &:last-child {
        margin: 0 8px 0 0;
        display: flex;
      }
      ` : `
      &:first-child {
        margin-left: 20px;
      }

      &:last-child {
        margin-right: 20px;
      }
  `, media.large`
    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  `);