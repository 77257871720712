import isString from "lodash/isString";
import qs from 'qs';
export const getHashParams = hash => {
  if (!isString(hash) || !hash.includes('?')) {
    return {};
  }
  const hashParamsString = hash.substring(hash.indexOf('?'), hash.length);
  const hashParams = qs.parse(hashParamsString.substring(1));
  return {
    hashParams,
    hashParamsString
  };
};
export const getHashValue = hash => {
  if (!isString(hash) || !hash.includes('#')) {
    return '';
  }
  const hashValue = hash.substring(1); // remove `#`
  const hasHashParams = hashValue.includes('?');
  return hasHashParams ? hashValue.substring(0, hashValue.indexOf('?')) : hashValue;
};
export const getPageParams = search => {
  if (!isString(search) || !search.includes('?')) {
    return {};
  }
  const pageParamsString = search.substring(search.indexOf('?'), search.length);
  const pageParams = qs.parse(pageParamsString.substring(1));
  return {
    pageParams,
    pageParamsString
  };
};