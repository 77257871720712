import { useSelector } from 'react-redux';
import { useCapitalCallsCalculation } from '@yieldstreet/tool-kit';
export const useCapitalCallInfo = _ref => {
  let {
    noteUrlHash,
    amount
  } = _ref;
  const offeringDetails = useSelector(state => {
    var _state$offeringDetail;
    return (_state$offeringDetail = state.offeringDetails.offeringList) === null || _state$offeringDetail === void 0 ? void 0 : _state$offeringDetail[noteUrlHash];
  });
  const calculation = useCapitalCallsCalculation(amount, offeringDetails === null || offeringDetails === void 0 ? void 0 : offeringDetails.capitalDrawnPercentage);
  return {
    capitalCallCalculation: calculation,
    isCapitalCall: offeringDetails === null || offeringDetails === void 0 ? void 0 : offeringDetails.capitalCallEnabled
  };
};
export default useCapitalCallInfo;