import { HttpMethods } from '../../../enums/http';
import { Tags, restApi } from '../../common';
export const BASE_URL = '/a/api/portfolio/promotion';
export const promotionApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.PortfolioPromotion]
}).injectEndpoints({
  endpoints: builder => {
    const getPromotionIRA = builder.query({
      query: _ref => {
        let {
          userId
        } = _ref;
        return {
          url: `${BASE_URL}/ira/${userId}`,
          method: HttpMethods.Get
        };
      },
      providesTags: [Tags.PortfolioPromotion]
    });
    return {
      getPromotionIRA
    };
  }
});