import { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { getTrackingMeta } from '@yieldstreet/tool-kit';
import { SIGNUP_TRACKING_META } from './useAuth.model';
export const useAuthMeta = () => {
  const location = useLocation();
  return useCallback(() => {
    const queryParams = new URLSearchParams(location.search);
    const hearFromSource = queryParams.get('hearFromSource');
    const hearFromSourceExtra = queryParams.get('hearFromSourceExtra');
    return {
      hearFromSource,
      hearFromSourceExtra,
      meta: getTrackingMeta(SIGNUP_TRACKING_META)
    };
  }, [location]);
};