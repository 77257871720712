import update from 'immutability-helper';
import * as actions from 'store/actions/portfolioInvestmentDetails';
export const initialState = {
  data: {},
  isFetching: false,
  error: false,
  navData: {},
  isFetchingNavData: false,
  errorNavData: false
};
const portfolioInvestmentDetails = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case actions.INVESTMENT_DETAILS_ATTEMPT:
      {
        return update(state, {
          isFetching: {
            $set: true
          },
          error: {
            $set: false
          }
        });
      }
    case actions.INVESTMENT_DETAILS_SUCCESS:
      {
        const {
          response,
          userId,
          investorAccountId,
          timePeriod,
          noteUrlHash
        } = action;
        const data = {
          [`${userId}_${investorAccountId}_${timePeriod}_${noteUrlHash}`]: response
        };
        return update(state, {
          data: {
            $merge: data
          },
          isFetching: {
            $set: false
          },
          error: {
            $set: false
          }
        });
      }
    case actions.INVESTMENT_DETAILS_FAILURE:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          error: {
            $set: true
          }
        });
      }
    case actions.GET_NAV_CHART_ATTEMPT:
      {
        return update(state, {
          isFetchingNavData: {
            $set: true
          },
          errorNavData: {
            $set: false
          }
        });
      }
    case actions.GET_NAV_CHART_SUCCESS:
      {
        const {
          response
        } = action;
        return update(state, {
          navData: {
            $set: response
          },
          isFetchingNavData: {
            $set: false
          },
          errorNavData: {
            $set: false
          }
        });
      }
    case actions.GET_NAV_CHART_FAILURE:
      {
        return update(state, {
          isFetchingNavData: {
            $set: false
          },
          errorNavData: {
            $set: true
          }
        });
      }
    default:
      return state;
  }
};
export default portfolioInvestmentDetails;