import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var __jsx = React.createElement;
import React, { useMemo } from 'react';
import { InvestmentType } from '@yieldstreet/tool-kit';
import { DealSimulator, Modal, Button, media } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
const OfferingSimulator = _ref => {
  let {
    paymentFrequency,
    investment
  } = _ref;
  const simulationType = useMemo(() => {
    switch (paymentFrequency) {
      case 'WEEK':
      case 'BIWK':
      case 'MNTH':
        return InvestmentType.MONTHLY;
      case 'BIMN':
        return InvestmentType.BIMONTHLY;
      case 'QUAT':
        return InvestmentType.QUARTERLY;
      case 'SMAL':
        return InvestmentType.SEMIANNUAL;
      case 'ANNL':
        return InvestmentType.ANNUAL;
      case 'EVNT':
        return InvestmentType.ENDOFTERM;
      case 'UPFT':
      default:
        return null;
    }
  }, [paymentFrequency]);
  return __jsx(DealSimulator, {
    initAmount: investment.min,
    investment: _objectSpread(_objectSpread({}, investment), {}, {
      type: simulationType
    })
  });
};
export default OfferingSimulator;
export const SimulatorButton = styled(Button).withConfig({
  displayName: "OfferingSimulator__SimulatorButton",
  componentId: "sc-1byzuna-0"
})(["margin-top:15px;width:100%;", ""], media.desktop`
    margin-top: 25px;
    width: auto;
`);
export const SimulatorModal = styled(Modal).withConfig({
  displayName: "OfferingSimulator__SimulatorModal",
  componentId: "sc-1byzuna-1"
})([".modal-body-wrapper{max-width:1250px;}"]);