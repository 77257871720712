import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useEffect, useRef, useCallback } from 'react';
import { AnimatePresence } from 'framer-motion';
import { mapToTestId } from './ContextMenu.model';
import { ContextAction, ContextContainer, ContextWindow, ContextWindowAnimation } from './ContextMenu.style';
export const ContextMenu = _ref => {
  let {
    open,
    onChange,
    children,
    renderTrigger,
    onClose = () => {},
    onOpen = () => {},
    align,
    testId,
    maxHeight
  } = _ref;
  const ref = useRef(null);
  const testIds = mapToTestId(testId);
  const onDocumentClick = useCallback(e => {
    if (open && ref !== null && ref !== void 0 && ref.current && !ref.current.contains(e.target)) {
      onChange(false);
    }
  }, [open, onChange]);
  useEffect(() => {
    if (open) {
      onOpen();
    } else {
      onClose();
    }
  }, [open, onOpen, onClose]);
  useEffect(() => {
    window.document.addEventListener('mousedown', onDocumentClick);
    return () => window.document.removeEventListener('mousedown', onDocumentClick);
  }, [onDocumentClick]);
  const toggle = () => {
    onChange(!open);
  };
  return __jsx(ContextContainer, _extends({
    ref: ref
  }, testIds.base.attr), __jsx(ContextAction, _extends({
    onClick: toggle
  }, testIds.action.attr), renderTrigger), __jsx(AnimatePresence, {
    initial: false
  }, open && __jsx(ContextWindow, _extends({
    align: align,
    maxHeight: maxHeight
  }, testIds.window.attr, ContextWindowAnimation), children)));
};