export const mockPaymentActivityFundSsc = {
  userId: 8697,
  activity: [{
    date: '2020-06-30',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 875,
    cash: 875,
    cashLegacyFunds: 875,
    reInvest: 0,
    total: 875,
    totalNetFexpNetKnownCapital: 875,
    investmentsPerGraphItem: {
      LJouqg: {
        status: 'ACTIVE',
        noteUrlHash: 'LJouqg',
        title: 'Yieldstreet Prism Fund',
        assetClass: 'FUND',
        launchDate: '2020-10-21',
        amount: 875,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2020-07-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 0,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 0,
    totalNetFexpNetKnownCapital: 0,
    investmentsPerGraphItem: {}
  }, {
    date: '2020-08-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 0,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 0,
    totalNetFexpNetKnownCapital: 0,
    investmentsPerGraphItem: {}
  }, {
    date: '2020-09-30',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 875,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 875,
    total: 875,
    totalNetFexpNetKnownCapital: 875,
    investmentsPerGraphItem: {
      LJouqg: {
        status: 'ACTIVE',
        noteUrlHash: 'LJouqg',
        title: 'Yieldstreet Prism Fund',
        assetClass: 'FUND',
        launchDate: '2020-10-21',
        amount: 875,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2020-10-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 0,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 0,
    totalNetFexpNetKnownCapital: 0,
    investmentsPerGraphItem: {}
  }, {
    date: '2020-11-30',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 0,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 0,
    totalNetFexpNetKnownCapital: 0,
    investmentsPerGraphItem: {}
  }, {
    date: '2020-12-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 890.45,
    cash: 890.45,
    cashLegacyFunds: 890.45,
    reInvest: 0,
    total: 890.45,
    totalNetFexpNetKnownCapital: 890.45,
    investmentsPerGraphItem: {
      LJouqg: {
        status: 'ACTIVE',
        noteUrlHash: 'LJouqg',
        title: 'Yieldstreet Prism Fund',
        assetClass: 'FUND',
        launchDate: '2020-10-21',
        amount: 890.45,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2021-01-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 0,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 0,
    totalNetFexpNetKnownCapital: 0,
    investmentsPerGraphItem: {}
  }, {
    date: '2021-02-28',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 0,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 0,
    totalNetFexpNetKnownCapital: 0,
    investmentsPerGraphItem: {}
  }, {
    date: '2021-03-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 1017.66,
    cash: 1017.66,
    cashLegacyFunds: 1017.66,
    reInvest: 0,
    total: 1017.66,
    totalNetFexpNetKnownCapital: 1017.66,
    investmentsPerGraphItem: {
      LJouqg: {
        status: 'ACTIVE',
        noteUrlHash: 'LJouqg',
        title: 'Yieldstreet Prism Fund',
        assetClass: 'FUND',
        launchDate: '2020-10-21',
        amount: 1017.66,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2021-04-30',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 0,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 0,
    totalNetFexpNetKnownCapital: 0,
    investmentsPerGraphItem: {}
  }, {
    date: '2021-05-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 0,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 0,
    totalNetFexpNetKnownCapital: 0,
    investmentsPerGraphItem: {}
  }, {
    date: '2021-06-30',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 1017.66,
    cash: 1017.66,
    cashLegacyFunds: 1017.66,
    reInvest: 0,
    total: 1017.66,
    totalNetFexpNetKnownCapital: 1017.66,
    investmentsPerGraphItem: {
      LJouqg: {
        status: 'ACTIVE',
        noteUrlHash: 'LJouqg',
        title: 'Yieldstreet Prism Fund',
        assetClass: 'FUND',
        launchDate: '2020-10-21',
        amount: 1017.66,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2021-07-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 0,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 0,
    totalNetFexpNetKnownCapital: 0,
    investmentsPerGraphItem: {}
  }, {
    date: '2021-08-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 0,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 0,
    totalNetFexpNetKnownCapital: 0,
    investmentsPerGraphItem: {}
  }, {
    date: '2021-09-30',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 370.27,
    cash: 370.27,
    cashLegacyFunds: 370.27,
    reInvest: 0,
    total: 370.27,
    totalNetFexpNetKnownCapital: 370.27,
    investmentsPerGraphItem: {
      LJouqg: {
        status: 'ACTIVE',
        noteUrlHash: 'LJouqg',
        title: 'Yieldstreet Prism Fund',
        assetClass: 'FUND',
        launchDate: '2020-10-21',
        amount: 370.27,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2021-10-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 0,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 0,
    totalNetFexpNetKnownCapital: 0,
    investmentsPerGraphItem: {}
  }, {
    date: '2021-11-30',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 0,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 0,
    totalNetFexpNetKnownCapital: 0,
    investmentsPerGraphItem: {}
  }, {
    date: '2021-12-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 370.27,
    cash: 370.27,
    cashLegacyFunds: 370.27,
    reInvest: 0,
    total: 370.27,
    totalNetFexpNetKnownCapital: 370.27,
    investmentsPerGraphItem: {
      LJouqg: {
        status: 'ACTIVE',
        noteUrlHash: 'LJouqg',
        title: 'Yieldstreet Prism Fund',
        assetClass: 'FUND',
        launchDate: '2020-10-21',
        amount: 370.27,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2022-01-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 0,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 0,
    totalNetFexpNetKnownCapital: 0,
    investmentsPerGraphItem: {}
  }, {
    date: '2022-02-28',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 370.27,
    cash: 370.27,
    cashLegacyFunds: 370.27,
    reInvest: 0,
    total: 370.27,
    totalNetFexpNetKnownCapital: 370.27,
    investmentsPerGraphItem: {
      LJouqg: {
        status: 'ACTIVE',
        noteUrlHash: 'LJouqg',
        title: 'Yieldstreet Prism Fund',
        assetClass: 'FUND',
        launchDate: '2020-10-21',
        amount: 370.27,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2022-03-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 0,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 0,
    totalNetFexpNetKnownCapital: 0,
    investmentsPerGraphItem: {}
  }, {
    date: '2022-04-30',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 0,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 0,
    totalNetFexpNetKnownCapital: 0,
    investmentsPerGraphItem: {}
  }, {
    date: '2022-05-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 0,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 0,
    totalNetFexpNetKnownCapital: 0,
    investmentsPerGraphItem: {}
  }, {
    date: '2022-06-30',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 153.41,
    cash: 153.41,
    cashLegacyFunds: 153.41,
    reInvest: 0,
    total: 153.41,
    totalNetFexpNetKnownCapital: 153.41,
    investmentsPerGraphItem: {
      LJouqg: {
        status: 'ACTIVE',
        noteUrlHash: 'LJouqg',
        title: 'Yieldstreet Prism Fund',
        assetClass: 'FUND',
        launchDate: '2020-10-21',
        amount: 153.41,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2022-07-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 0,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 0,
    totalNetFexpNetKnownCapital: 0,
    investmentsPerGraphItem: {}
  }, {
    date: '2022-08-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 0,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 0,
    totalNetFexpNetKnownCapital: 0,
    investmentsPerGraphItem: {}
  }, {
    date: '2022-09-30',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 72.98,
    cash: 72.98,
    cashLegacyFunds: 72.98,
    reInvest: 0,
    total: 72.98,
    totalNetFexpNetKnownCapital: 72.98,
    investmentsPerGraphItem: {
      LJouqg: {
        status: 'ACTIVE',
        noteUrlHash: 'LJouqg',
        title: 'Yieldstreet Prism Fund',
        assetClass: 'FUND',
        launchDate: '2020-10-21',
        amount: 72.98,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2022-10-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 0,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 0,
    totalNetFexpNetKnownCapital: 0,
    investmentsPerGraphItem: {}
  }, {
    date: '2022-11-30',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 0,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 0,
    totalNetFexpNetKnownCapital: 0,
    investmentsPerGraphItem: {}
  }, {
    date: '2022-12-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 48.07,
    cash: 48.07,
    cashLegacyFunds: 48.07,
    reInvest: 0,
    total: 48.07,
    totalNetFexpNetKnownCapital: 48.07,
    investmentsPerGraphItem: {
      LJouqg: {
        status: 'ACTIVE',
        noteUrlHash: 'LJouqg',
        title: 'Yieldstreet Prism Fund',
        assetClass: 'FUND',
        launchDate: '2020-10-21',
        amount: 48.07,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2023-01-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 0,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 0,
    totalNetFexpNetKnownCapital: 0,
    investmentsPerGraphItem: {}
  }, {
    date: '2023-02-28',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 48.07,
    cash: 48.07,
    cashLegacyFunds: 48.07,
    reInvest: 0,
    total: 48.07,
    totalNetFexpNetKnownCapital: 48.07,
    investmentsPerGraphItem: {
      LJouqg: {
        status: 'ACTIVE',
        noteUrlHash: 'LJouqg',
        title: 'Yieldstreet Prism Fund',
        assetClass: 'FUND',
        launchDate: '2020-10-21',
        amount: 48.07,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2023-03-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 0,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 0,
    totalNetFexpNetKnownCapital: 0,
    investmentsPerGraphItem: {}
  }, {
    date: '2023-04-30',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 0,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 0,
    totalNetFexpNetKnownCapital: 0,
    investmentsPerGraphItem: {}
  }, {
    date: '2023-05-31',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 0,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 0,
    totalNetFexpNetKnownCapital: 0,
    investmentsPerGraphItem: {}
  }, {
    date: '2023-06-22',
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 34.7,
    cash: 34.7,
    cashLegacyFunds: 34.7,
    reInvest: 0,
    total: 34.7,
    totalNetFexpNetKnownCapital: 34.7,
    investmentsPerGraphItem: {
      LJouqg: {
        status: 'ACTIVE',
        noteUrlHash: 'LJouqg',
        title: 'Yieldstreet Prism Fund',
        assetClass: 'FUND',
        launchDate: '2020-10-21',
        amount: 34.7,
        investmentType: 'FUND'
      }
    }
  }],
  total: {
    interest: 0,
    netInterest: 0,
    netIncome: 0,
    fexp: 0,
    totalWithoutFexp: 6143.81,
    totalNetFexpNetKnownCapital: 6143.81,
    cash: 5268.81,
    cashLegacyFunds: 5268.81,
    reInvest: 875,
    total: 6143.81
  },
  investments: [{
    status: 'ACTIVE',
    noteUrlHash: 'LJouqg',
    title: 'Yieldstreet Prism Fund',
    assetClass: 'FUND',
    launchDate: '2020-10-21',
    amount: 6143.81,
    investmentType: 'FUND'
  }]
};