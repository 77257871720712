import { restApi, Tags } from '../common';
import { HttpMethods } from '../../enums';
export const userBeneficiariesApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.UserBeneficiaries]
}).injectEndpoints({
  endpoints: builder => {
    const deleteUserBeneficiary = builder.mutation({
      query: _ref => {
        let {
          userId
        } = _ref;
        return {
          url: `/a/api/user/${userId}/beneficiary`,
          method: HttpMethods.Delete
        };
      },
      invalidatesTags: [Tags.UserBeneficiaries]
    });
    return {
      deleteUserBeneficiary
    };
  }
});