import { useCallback, useEffect } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { bankAccountApi } from '@yieldstreet/platform-kit';
import { eventManager, BusinessEvent, useLegacyEffect } from '@yieldstreet/tool-kit';
const {
  useLazyCreatePlaidTokenQuery,
  useAddPlaidLinkBankMutation
} = bankAccountApi;
export const usePlaidBankAccountModal = _ref => {
  let {
    investorEntityId,
    bankAccountId,
    onClose,
    onSuccess
  } = _ref;
  const [createPlaidToken, {
    data
  }] = useLazyCreatePlaidTokenQuery();
  const [submitLink, {
    isSuccess
  }] = useAddPlaidLinkBankMutation();
  useLegacyEffect(() => {
    /**
     * Link bank account flow
     */
    if (investorEntityId && !bankAccountId) {
      createPlaidToken({
        investorEntityId
      });
    } else if (bankAccountId) {
      /**
       * Microdeposit flow
       */
      createPlaidToken({
        bankAccountId
      });
    }
  }, [investorEntityId, createPlaidToken, bankAccountId]);

  /**
   * TODO: this might not be firing at all.
   */
  useEffect(() => {
    if (isSuccess) {
      eventManager.track(BusinessEvent.INVESTOR_BANK_SUBMITTED, {});
      onSuccess && onSuccess();
    }
    // Make sure effect is called only once when isSuccess changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);
  const onSubmit = useCallback(plaidPublicToken => {
    /**
     * Link bank account flow
     */
    if (!bankAccountId) {
      submitLink({
        investorEntityId,
        plaidLinkToken: (data === null || data === void 0 ? void 0 : data.plaidLinkToken) || '',
        plaidPublicToken
      });
    } else if (bankAccountId) {
      /**
       * Microdeposit flow
       */
      submitLink({
        bankAccountId,
        investorEntityId
      });
    }
  }, [bankAccountId, data === null || data === void 0 ? void 0 : data.plaidLinkToken, investorEntityId, submitLink]);
  const onEvent = _event => {
    // We might need to add tracking in the future
  };
  const onExit = useCallback(error => {
    if (error) {
      eventManager.trackFailure(BusinessEvent.INVESTOR_BANK_SUBMITTED, {
        error: error.display_message
      });
    }
    onClose && onClose();
  }, [onClose]);
  return usePlaidLink({
    token: (data === null || data === void 0 ? void 0 : data.plaidLinkToken) || '',
    onSuccess: (public_token, _metadata) => {
      onSubmit(public_token);
    },
    onExit: error => {
      onExit(error);
    },
    onEvent: event => {
      onEvent(event);
    }
  });
};