import update from 'immutability-helper';
import * as actions from 'store/actions/investmentRequest';
const initialState = {
  requests: {},
  details: {},
  preRequest: undefined,
  preRequestDetails: undefined,
  preRequestError: false,
  preInvestmentRequestsFetched: false,
  preInvestmentId: null,
  isFetching: false,
  marketplaceFilterUsed: {
    activeFilter: 'All',
    searchFilters: {}
  },
  isFetchingOfferingInfo: false,
  offeringInfo: null,
  submitErrorMessage: null,
  submitErrorCode: null,
  submitTransfer: {
    loading: false,
    error: null,
    errorMessage: null,
    success: false
  }
};
export const investmentRequestAnonymizer = {
  details: ['object', {
    initials: 'string',
    investorInitials: 'string'
  }]
};
const investmentRequest = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case actions.INVESTMENT_REQUEST_ATTEMPT:
      {
        return update(state, {
          isFetching: {
            $set: true
          }
        });
      }
    case actions.INVESTMENT_REQUEST_SUCCESS:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          requests: {
            $merge: {
              [action.investmentRequestId]: action.response
            }
          },
          details: {
            $set: {
              [action.investmentRequestId]: action.details
            }
          }
        });
      }
    case actions.INVESTMENT_REQUEST_FAILURE:
      {
        var _action$beError, _action$beError$respo, _action$beError$respo2, _action$beError2, _action$beError2$resp, _action$beError2$resp2;
        return update(state, {
          isFetching: {
            $set: false
          },
          submitErrorMessage: {
            $set: (_action$beError = action.beError) === null || _action$beError === void 0 ? void 0 : (_action$beError$respo = _action$beError.response) === null || _action$beError$respo === void 0 ? void 0 : (_action$beError$respo2 = _action$beError$respo.data) === null || _action$beError$respo2 === void 0 ? void 0 : _action$beError$respo2.message
          },
          submitErrorCode: {
            $set: (_action$beError2 = action.beError) === null || _action$beError2 === void 0 ? void 0 : (_action$beError2$resp = _action$beError2.response) === null || _action$beError2$resp === void 0 ? void 0 : (_action$beError2$resp2 = _action$beError2$resp.data) === null || _action$beError2$resp2 === void 0 ? void 0 : _action$beError2$resp2.code
          }
        });
      }
    case actions.PRE_INVESTMENT_REQUEST_SUCCESS:
      {
        return update(state, {
          preRequest: {
            $set: action.response
          }
        });
      }
    case actions.PRE_INVESTMENT_REQUEST_FAILURE:
      {
        return update(state, {
          preRequestError: {
            $set: true
          }
        });
      }
    case actions.GET_INVESTMENT_REQUEST_SUCCESS:
    case actions.GET_INVESTMENT_REQUEST_FAILURE:
      {
        var _action$response, _action$response$inve, _action$response$inve2;
        return update(state, {
          preInvestmentRequestsFetched: {
            $set: true
          },
          preInvestmentId: {
            $set: (_action$response = action.response) === null || _action$response === void 0 ? void 0 : (_action$response$inve = _action$response.investments) === null || _action$response$inve === void 0 ? void 0 : (_action$response$inve2 = _action$response$inve[0]) === null || _action$response$inve2 === void 0 ? void 0 : _action$response$inve2.id
          }
        });
      }
    case actions.SET_PRE_INVESTMENT_REQUEST_DETAILS:
      {
        return update(state, {
          preRequestDetails: {
            $set: action.preInvestmentDetails
          }
        });
      }
    case actions.CLEAR_PRE_INVESTMENT_REQUEST_DETAILS:
      {
        return update(state, {
          preRequestDetails: {
            $set: undefined
          }
        });
      }
    case actions.RESET_INVESTMENT_REQUEST:
      {
        return initialState;
      }
    case actions.GET_OFFERING_INFO_ATTEMPT:
      {
        return update(state, {
          isFetchingOfferingInfo: {
            $set: true
          }
        });
      }
    case actions.GET_OFFERING_INFO_SUCCESS:
      {
        return update(state, {
          isFetchingOfferingInfo: {
            $set: false
          },
          offeringInfo: {
            $set: action.response
          }
        });
      }
    case actions.GET_OFFERING_INFO_FAILURE:
      {
        return update(state, {
          isFetchingOfferingInfo: {
            $set: false
          }
        });
      }
    case actions.SUBMIT_TRANSFER_ATTEMPT:
      {
        return update(state, {
          submitTransfer: {
            loading: {
              $set: true
            },
            error: {
              $set: false
            },
            errorMessage: {
              $set: null
            }
          }
        });
      }
    case actions.SUBMIT_TRANSFER_SUCCESS:
      {
        return update(state, {
          submitTransfer: {
            loading: {
              $set: false
            },
            success: {
              $set: true
            }
          }
        });
      }
    case actions.SUBMIT_TRANSFER_FAILURE:
      {
        var _action$error;
        return update(state, {
          submitTransfer: {
            loading: {
              $set: false
            },
            success: {
              $set: false
            },
            error: {
              $set: true
            },
            errorMessage: {
              $set: ((_action$error = action.error) === null || _action$error === void 0 ? void 0 : _action$error.message) || action.error
            }
          }
        });
      }
    default:
      return state;
  }
};
export default investmentRequest;