import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import isArray from "lodash/isArray";
import orderBy from "lodash/orderBy";
import { InvestmentsListOrderTypes, InvestmentsListSortTypes } from '../../../../useInvestmentsList.model';
export const orderByLastCommentary = _ref => {
  let {
    collection,
    lastCommentary,
    orderKey
  } = _ref;
  if (!isArray(collection) || !collection.length) {
    return [];
  }
  if (!lastCommentary || !orderKey) {
    return collection;
  }
  const noteUrlHashes = Object.keys(lastCommentary);
  const investmentsWithLastCommentary = collection.map(investment => {
    const hasCommentary = noteUrlHashes.includes(investment.noteUrlHash);

    /**
     * This is done to show the investments with dates always at the top. For one of
     * the order types, the user will see a list of dashes and will need to scroll
     * to get to the investments with last commentary date.
     */
    const nonCommentarySortIndex = orderKey === InvestmentsListOrderTypes.Ascending ? '999' : '-1';
    const sortIndex = hasCommentary ? lastCommentary[investment.noteUrlHash].sortDate.split('-').join('') // ts complains when using replaceAll
    : nonCommentarySortIndex;
    return _objectSpread(_objectSpread({}, investment), {}, {
      [InvestmentsListSortTypes.LastCommentary]: sortIndex
    });
  });
  return orderBy(investmentsWithLastCommentary, InvestmentsListSortTypes.LastCommentary, orderKey);
};