import { ManagedPortfolioStrategies } from '@yieldstreet/platform-kit';
export const investmentStrategy = {
  [ManagedPortfolioStrategies.INCOME]: {
    title: 'Income',
    subtitle: 'This strategy prioritizes income generation.'
  },
  [ManagedPortfolioStrategies.GROWTH]: {
    title: 'Growth',
    subtitle: 'This strategy prioritizes capital appreciation.'
  },
  [ManagedPortfolioStrategies.BALANCED]: {
    title: 'Balanced',
    subtitle: 'This strategy balances income generation and capital appreciation.'
  }
};