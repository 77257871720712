import { media, Heading } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
export const Wrapper = styled.div.withConfig({
  displayName: "WordPressCardstyle__Wrapper",
  componentId: "sc-1gi7cl7-0"
})(["margin:20px 0 40px;", ";"], media.phoneLandscape`
    margin: 20px 10px;
  `);
export const Container = styled.div.withConfig({
  displayName: "WordPressCardstyle__Container",
  componentId: "sc-1gi7cl7-1"
})(["flex:1;background:", ";box-shadow:0 29px 30px 0 rgba(60,63,65,0.05);z-index:2;min-height:300px;min-width:300px;position:relative;border:1px solid ", ";", ";"], props => props.theme.colors.background_card, props => props.theme.colors.border_default, media.phoneLandscape`
    max-width: 256px;
  `);
export const Image = styled.img.withConfig({
  displayName: "WordPressCardstyle__Image",
  componentId: "sc-1gi7cl7-2"
})(["display:block;width:100%;height:149px;object-fit:cover;"]);
export const ContentWrapper = styled.div.withConfig({
  displayName: "WordPressCardstyle__ContentWrapper",
  componentId: "sc-1gi7cl7-3"
})(["padding:20px;"]);
export const Title = styled(Heading).withConfig({
  displayName: "WordPressCardstyle__Title",
  componentId: "sc-1gi7cl7-4"
})(["padding-bottom:10px;", ";"], props => (props === null || props === void 0 ? void 0 : props.top) && `padding-top: ${props.top}px`);