var __jsx = React.createElement;
import React from 'react';
import { useSelector } from 'react-redux';
import { LDWebFlagsProvider } from '@yieldstreet/tool-kit/src/flags/vendor/launch-darkly/web';
import ENV_CONFIG from 'utils/env/config';
import { FlagsContextWrapper } from './FlagsContextWrapper';
import { useFlagsContext } from './useFlagsContext';
export const FlagsProvider = _ref => {
  let {
    children
  } = _ref;
  const context = useFlagsContext();
  const flags = useSelector(_ref2 => {
    let {
      flags
    } = _ref2;
    return flags.data;
  });
  return __jsx(LDWebFlagsProvider, {
    clientSideID: ENV_CONFIG.LAUNCHDARKLY_CLIENT_ID,
    context: context,
    initFlags: flags
  }, __jsx(FlagsContextWrapper, null, children));
};