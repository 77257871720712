// @ts-ignore
import footprintStyles from '@onefootprint/footprint-js/dist/footprint-js.css';
import styled from 'styled-components';
export const PageContainer = styled.div.withConfig({
  displayName: "Footprintstyles__PageContainer",
  componentId: "sc-1tvu5sg-0"
})(["", " display:flex;flex-direction:column;gap:", ";align-items:center;justify-content:center;height:100%;"], footprintStyles, props => props.theme.spacing.m);
export const FootprintWrapper = styled.div.withConfig({
  displayName: "Footprintstyles__FootprintWrapper",
  componentId: "sc-1tvu5sg-1"
})(["", ""], footprintStyles);