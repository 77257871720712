var __jsx = React.createElement;
import React, { useMemo } from 'react';
import { PROVIDER_CONFIG } from '@yieldstreet/tool-kit';
import { NewestModal, useModal } from '@yieldstreet/ui-kit';
import { ProductTourModal } from 'sharedComponents/ProductTourModal';
import { useSDIRAUser } from 'pages/providers/sdira/hooks';
import { Modals } from 'constants/modal.model';
import { getSlides } from './ETCProductTour.model';
export const ETCProductTour = () => {
  var _PROVIDER_CONFIG;
  const {
    provider
  } = useSDIRAUser();
  const {
    close
  } = useModal(Modals.ETCProductTour);
  const providerPlatform = provider && ((_PROVIDER_CONFIG = PROVIDER_CONFIG[provider]) === null || _PROVIDER_CONFIG === void 0 ? void 0 : _PROVIDER_CONFIG.platform);
  const slides = useMemo(() => getSlides(providerPlatform), [providerPlatform]);
  return __jsx(NewestModal, {
    modalId: Modals.ETCProductTour,
    onClose: close
  }, __jsx(ProductTourModal, {
    slides: slides,
    hideModal: close
  }));
};