var __jsx = React.createElement;
import React from 'react';
import { INVESTOR_ENTITY_MANAGEMENT_PATH } from 'pages/investorEntity/InvestorEntityContainer.model';
import investorAccountsIcon from '../../assets/Investor_black.svg';
import investmentPreferencesIcon from '../../assets/InvestmentPreferences.svg';
import { INVESTOR_ENTITY_MNGT_ADVICE_PATH } from '../../ManagementContainer.model';
export const accountSettingsNavLinks = [{
  id: 'header-investor-accounts',
  key: 'investor-accounts',
  title: 'Investor accounts',
  route: INVESTOR_ENTITY_MANAGEMENT_PATH,
  icon: __jsx("img", {
    src: investorAccountsIcon,
    alt: "Investor accounts"
  })
}, {
  id: 'header-advice-preferences',
  key: 'advice-preferences',
  title: 'Investment preferences',
  route: INVESTOR_ENTITY_MNGT_ADVICE_PATH,
  icon: __jsx("img", {
    src: investmentPreferencesIcon,
    alt: "Investment preferences"
  }),
  accreditedOnly: true
}];