import styled from 'styled-components';
import { media } from '@yieldstreet/ui-kit';
import { Link } from 'sharedComponents/Links/Links';
export const ModalWrapper = styled.div.withConfig({
  displayName: "TwoFaModalstyle__ModalWrapper",
  componentId: "sc-1fp40zx-0"
})(["background-color:", ";box-shadow:", ";border-radius:8px;padding:", ";height:100vh;", " ", ";"], _ref => {
  let {
    theme
  } = _ref;
  return theme.colors.background_page;
}, _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.shadows.elevation_1;
}, _ref3 => {
  let {
    theme
  } = _ref3;
  return theme.spacing.m;
}, media.tablet`
    height: 100%;
  `, media.desktop`
    padding: ${_ref4 => {
  let {
    theme
  } = _ref4;
  return theme.spacing.l;
}};
    width: 780px;
  `);
export const InnerModalContainer = styled.div.withConfig({
  displayName: "TwoFaModalstyle__InnerModalContainer",
  componentId: "sc-1fp40zx-1"
})(["display:flex;flex-direction:column;padding:", ";"], _ref5 => {
  let {
    theme
  } = _ref5;
  return theme.spacing.m;
});
export const PageLoaderWrapper = styled.div.withConfig({
  displayName: "TwoFaModalstyle__PageLoaderWrapper",
  componentId: "sc-1fp40zx-2"
})(["min-height:300px;min-width:350px;display:flex;align-items:center;justify-content:center;"]);
export const Icon = styled.img.withConfig({
  displayName: "TwoFaModalstyle__Icon",
  componentId: "sc-1fp40zx-3"
})(["max-width:100px;margin-bottom:", ";"], _ref6 => {
  let {
    theme
  } = _ref6;
  return theme.spacing.m;
});
export const ParagraphWrapper = styled.div.withConfig({
  displayName: "TwoFaModalstyle__ParagraphWrapper",
  componentId: "sc-1fp40zx-4"
})(["margin:", ";"], _ref7 => {
  let {
    theme
  } = _ref7;
  return `${theme.spacing.xs} 0 ${theme.spacing.m}`;
});
export const SupportLink = styled(Link).withConfig({
  displayName: "TwoFaModalstyle__SupportLink",
  componentId: "sc-1fp40zx-5"
})(["width:fit-content;"]);