import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEqual from "lodash/isEqual";
import { allToken, selectMarketplacePersist, updateMarketplacePersistance, userApi } from '@yieldstreet/platform-kit';
export const useMarketplaceState = (initialState, updateKey) => {
  const dispatch = useDispatch();
  const {
    data
  } = userApi.useGetUserStateQuery(allToken);
  const currentAuthToken = data === null || data === void 0 ? void 0 : data.authToken;
  const {
    [updateKey]: persistedData,
    authToken: persistedAuthToken
  } = useSelector(state => selectMarketplacePersist(state));
  const isSameToken = currentAuthToken && isEqual(currentAuthToken, persistedAuthToken);
  const initialData = useMemo(() => {
    return !persistedData || !isSameToken ? initialState : persistedData;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const {
    0: activeState,
    1: setActiveState
  } = useState(initialData);
  const update = activeData => {
    if (!currentAuthToken || isEqual(activeData, persistedData)) {
      return;
    }
    dispatch(updateMarketplacePersistance({
      [updateKey]: activeData,
      authToken: currentAuthToken
    }));
  };
  return {
    initialState: initialData,
    update,
    activeState,
    setActiveState
  };
};