import { useMemo } from 'react';
export const useFlow = _ref => {
  let {
    flowConfig,
    flowState
  } = _ref;
  const {
    steps,
    onFinish
  } = flowConfig;
  const {
    step,
    onChangeStep
  } = flowState;
  const currentStepIdx = useMemo(() => steps.findIndex(x => x.stepKey === step), [step, steps]);
  const goToStep = step => {
    onChangeStep(step);
  };
  const isFirstStep = currentStepIdx === 0;
  const goToPreviousStep = () => {
    if (isFirstStep) {
      return;
    }
    let isSkippableStep = false;
    let prevStepIdx = currentStepIdx;
    /* if going back and step is "skippable" it means the step
    was already fullfiled and should not be presented again
    */
    do {
      prevStepIdx -= 1;
      if (steps[prevStepIdx].skippable) {
        isSkippableStep = true;
      } else {
        isSkippableStep = false;
      }
    } while (isSkippableStep && prevStepIdx > 0);
    return onChangeStep(steps[prevStepIdx].stepKey);
  };
  const isLastStep = currentStepIdx === steps.length - 1;
  const goToNextStep = skip => {
    if (isLastStep) {
      return onFinish();
    }
    const nextStepKey = steps[currentStepIdx + 1].stepKey;
    return onChangeStep(nextStepKey, skip);
  };
  return {
    goToStep,
    goToPreviousStep,
    goToNextStep,
    isFirstStep,
    isLastStep
  };
};