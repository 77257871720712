import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["children", "theme"];
var __jsx = React.createElement;
import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Button } from '../button';
import { ButtonThemes, testIds } from './ThemedButton.model';
export const ThemedButton = _ref => {
  let {
      children,
      theme
    } = _ref,
    rest = _objectWithoutProperties(_ref, _excluded);
  return __jsx(ThemeProvider, {
    theme: ButtonThemes[theme]
  }, __jsx(Button, _extends({}, testIds.base.attr, rest), children));
};