import _Typography from "@mui/material/Typography";
import _Box from "@mui/material/Box";
var __jsx = React.createElement;
import React, { useCallback, useMemo, useState } from 'react';
import produce from 'immer';
import { DesktopOnly, MobileOnly } from '../../../utils';
import { AmountSelector } from '../components/AmountSelector';
import { Chart, ColumnColors } from '../components/Chart';
import { StrategySelector } from '../components/StrategySelector';
import { SwitchSelector } from '../components/SwitchSelector';
import { Strategies } from './PortfolioSimulator.model';
import { Background, ChartContainer, ControlsHolder, Disclaimer, StrategyDesc } from './PortfolioSimulator.style';
const PortfolioChart = _ref => {
  let {
    initAmount,
    portfolioConfig
  } = _ref;
  const {
    0: showTotalInvestment,
    1: setShowTotalInvestment
  } = useState(false);
  const {
    0: strategy,
    1: setStrategy
  } = useState(0);
  const {
    0: amounts,
    1: setAmounts
  } = useState(initAmount);
  const investments = useMemo(() => {
    return portfolioConfig[strategy].investments;
  }, [portfolioConfig, strategy]);
  const onAmountChange = useCallback((amount, index) => {
    setAmounts(produce(draft => {
      draft[strategy][index] = amount;
    }));
  }, [strategy, setAmounts]);
  return __jsx(React.Fragment, null, __jsx(ChartContainer, null, __jsx(Chart, {
    investments: investments,
    amounts: amounts[strategy],
    showTotalInvestment: showTotalInvestment
  })), __jsx(ControlsHolder, null, __jsx(DesktopOnly, null, __jsx(StrategySelector, {
    onClick: option => setStrategy(+option.key),
    activeKey: strategy,
    options: Strategies
  })), __jsx(MobileOnly, null, __jsx(_Box, null, __jsx(SwitchSelector, {
    tabs: Strategies,
    onClick: tab => setStrategy(+tab.key),
    activeKey: strategy
  }), __jsx(StrategyDesc, {
    variant: "body2"
  }, Strategies[strategy].desc))), investments.map((investment, index) => __jsx(AmountSelector, {
    key: index,
    title: investment.name,
    annualizedYield: investment.rate,
    term: `${investment.term} ${investment.termSuffix}`,
    min: investment.min,
    max: investment.max,
    amount: amounts[strategy][index],
    onChange: amount => onAmountChange(amount, index),
    onTouchMove: () => setShowTotalInvestment(true),
    onTouchEnd: () => setShowTotalInvestment(false),
    color: ColumnColors[index]
  }))));
};
export const PortfolioSimulator = _ref2 => {
  let {
    portfolioConfig,
    withPadding
  } = _ref2;
  const initAmount = useMemo(() => portfolioConfig.map(s => s.investments.map(i => i.defaultAmount)), [portfolioConfig]);
  return __jsx(Background, {
    withPadding: withPadding
  }, __jsx(_Typography, {
    variant: "h3"
  }, "Portfolio simulator"), __jsx(_Typography, {
    variant: "body2",
    sx: {
      my: 3
    }
  }, "See how you can benefit from building a portfolio of alternative investments."), __jsx(PortfolioChart, {
    portfolioConfig: portfolioConfig,
    initAmount: initAmount
  }), __jsx(Disclaimer, {
    variant: "body2"
  }, "All investments involve risk, including loss of principal.", ' ', __jsx("a", {
    href: "/simulator-disclosures/"
  }, "View Disclosures")));
};