import styled from 'styled-components';
import { Label } from '../../content';
import { media } from '../../themes';

// temp color
export const StepProgressBarContainer = styled.div.withConfig({
  displayName: "StepProgressBarstyle__StepProgressBarContainer",
  componentId: "sc-upoizo-0"
})(["margin:0 ", ";width:auto;background-color:#b0bebf;border-radius:", ";position:relative;display:flex;justify-content:center;align-items:center;", ""], _ref => {
  let {
    theme
  } = _ref;
  return theme.spacing.l;
}, _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.radius.md;
}, media.tablet`
    margin: 0 ${_ref3 => {
  let {
    theme
  } = _ref3;
  return theme.spacing.xl;
}};
  `);
export const ProgressLine = styled.div.withConfig({
  displayName: "StepProgressBarstyle__ProgressLine",
  componentId: "sc-upoizo-1"
})(["height:6px;display:flex;align-items:center;width:100%;"]);

// temp color
export const ProgressBar = styled.div.withConfig({
  displayName: "StepProgressBarstyle__ProgressBar",
  componentId: "sc-upoizo-2"
})(["height:100%;background-color:#b2e061;transition:width 0.3s ease-in-out;border-radius:", ";width:", "%;"], _ref4 => {
  let {
    theme
  } = _ref4;
  return theme.radius.md;
}, _ref5 => {
  let {
    width
  } = _ref5;
  return width;
});

// temp color
export const ProgressDot = styled.div.withConfig({
  displayName: "StepProgressBarstyle__ProgressDot",
  componentId: "sc-upoizo-3"
})(["position:absolute;width:16px;height:16px;background-color:#b2e061;border-radius:50%;left:", "%;margin-left:-", ";"], _ref6 => {
  let {
    left
  } = _ref6;
  return left;
}, _ref7 => {
  let {
    theme
  } = _ref7;
  return theme.spacing.xs;
});
export const ItemTitle = styled(Label).withConfig({
  displayName: "StepProgressBarstyle__ItemTitle",
  componentId: "sc-upoizo-4"
})(["white-space:nowrap;position:absolute;top:-", ";"], _ref8 => {
  let {
    theme
  } = _ref8;
  return theme.spacing.l;
});
export const ItemContainer = styled.div.withConfig({
  displayName: "StepProgressBarstyle__ItemContainer",
  componentId: "sc-upoizo-5"
})(["position:absolute;left:", "%;display:flex;justify-content:center;align-items:center;"], _ref9 => {
  let {
    left
  } = _ref9;
  return left;
});