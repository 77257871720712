import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["reverse", "inverse", "label", "heading", "content", "image", "button"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { SectionContainer } from '../../layout/section-container';
import { media } from '../../themes/media';
import { TextBlock } from '../../sections/text-block-component';
export const MarketingSidePhotoContent = _ref => {
  let {
      reverse,
      inverse,
      label,
      heading,
      content,
      image,
      button
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  return __jsx(SectionContainer, null, __jsx(Background, {
    reverse: reverse,
    inverse: inverse
  }, __jsx(TextBlockContainer, null, __jsx(TextBlock, _extends({
    label: label,
    heading: heading,
    content: content,
    inverse: inverse,
    button: button
  }, props))), __jsx(ImageContainer, null, __jsx(MainImage, null, image))));
};
const Background = styled.div.withConfig({
  displayName: "MarketingSidePhotoContent__Background",
  componentId: "sc-uqm3nj-0"
})(["display:flex;flex-direction:column-reverse;", ""], media.desktop`
    flex-direction: ${props => props.reverse ? 'row-reverse' : 'row'};
    justify-content: space-between;
    align-content: center;
  `);
const ImageContainer = styled.div.withConfig({
  displayName: "MarketingSidePhotoContent__ImageContainer",
  componentId: "sc-uqm3nj-1"
})(["margin-bottom:40px;", ""], media.desktop`
    margin-bottom: 0px;
  `);
const MainImage = styled.div.withConfig({
  displayName: "MarketingSidePhotoContent__MainImage",
  componentId: "sc-uqm3nj-2"
})(["text-align:center;video{width:100%;}img{width:100%;}", ""], media.desktop`
    img{
      width: auto;
    }
    video {
      width: auto;
    }
  `);
const TextBlockContainer = styled.div.withConfig({
  displayName: "MarketingSidePhotoContent__TextBlockContainer",
  componentId: "sc-uqm3nj-3"
})(["display:flex;width:100%;", ""], media.desktop`
    width: 410px;
  `);