var __jsx = React.createElement;
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks-ssr/react-hooks-global-ssr */
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { Tooltip, Markdown } from '@yieldstreet/ui-kit';
import { Link } from 'sharedComponents/Links/Links';
import { Wrapper, Title, Seperator, MetricWrapper, TooltipWrapper, Label, Description } from './MetricsCard.style';
const MetricsCard = _ref => {
  let {
    title,
    fields,
    isLoggedIn
  } = _ref;
  const location = useLocation();
  const renderCard = useMemo(() => fields.map((field, idx) => {
    const {
      label,
      description,
      extraSpace,
      color,
      size,
      tooltipMessage,
      tooltipPlace,
      loginRequired
    } = field;
    return __jsx(MetricWrapper, {
      key: `${title}-${idx}`,
      extraSpace: extraSpace
    }, __jsx(Label, {
      small: true,
      color: color,
      size: size
    }, label, tooltipMessage && __jsx(TooltipWrapper, null, __jsx(Tooltip, {
      testId: `${title}-${idx}-tooltip`,
      tooltipKey: `${title}-${idx}-tooltip`,
      place: tooltipPlace
    }, tooltipMessage))), loginRequired && !isLoggedIn ? __jsx(Link, {
      to: `/login?redirect=${location.pathname}`
    }, "Login to view") : __jsx(Description, {
      secondary: true,
      color: color,
      size: size
    }, __jsx(Markdown, {
      source: description
    })));
  }), [title, fields, isLoggedIn, location]);
  return __jsx(Wrapper, null, __jsx(Title, {
    type: 5
  }, title), __jsx(Seperator, null), renderCard);
};
export default MetricsCard;