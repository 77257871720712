var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { media } from '../../themes';
import { rgba } from 'polished';
export const Overlay = _ref => {
  let {
    hideOnMobile = true,
    onClick,
    'data-cy': dataCy
  } = _ref;
  return __jsx(Wrapper, {
    "data-cy": dataCy,
    onClick: onClick,
    hideOnMobile: hideOnMobile
  });
};
const getBackground = props => rgba(props.theme.colors.background_inverse, 0.8);
const Wrapper = styled.div.withConfig({
  displayName: "Overlay__Wrapper",
  componentId: "sc-gcqjmz-0"
})(["display:flex;position:fixed;top:0;bottom:0;left:0;right:0;background:", ";", ""], props => props.hideOnMobile ? 'none' : getBackground(props), media.phoneLandscape`
    background: ${props => getBackground(props)};
  `);