var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { media } from '../../../themes';
import { Heading, Paragraph } from '../../../content/text';
export const ImageWithContentWithSep = _ref => {
  let {
    title,
    image,
    imageAlt,
    alignment = 'left',
    children,
    inverse
  } = _ref;
  return __jsx(OuterContainer, {
    alignment: alignment,
    image: image,
    title: title
  }, __jsx(ImageHolder, null, image && __jsx(ImageWrapper, null, __jsx(Image, {
    alt: imageAlt,
    src: image
  }))), __jsx(Headline, {
    type: 4,
    inverse: inverse
  }, title), __jsx(Content, {
    small: true,
    inverse: inverse
  }, children));
};
const OuterContainer = styled.div.withConfig({
  displayName: "ImageWithContentWithSep__OuterContainer",
  componentId: "sc-ep1rng-0"
})(["text-align:", ";flex-grow:1;flex-basis:0;", ""], props => props.alignment, media.desktop`
    display: flex;
    flex-direction: column;
    padding: 0 20px;
  `);
const Headline = styled(Heading).withConfig({
  displayName: "ImageWithContentWithSep__Headline",
  componentId: "sc-ep1rng-1"
})(["padding:0 0 20px;margin:10px 0 20px 0;position:relative;&::after{bottom:0;left:0;height:1px;width:30px;content:'';position:absolute;background:", ";}"], props => props.theme.colors.accent_strong);
const Content = styled(Paragraph).withConfig({
  displayName: "ImageWithContentWithSep__Content",
  componentId: "sc-ep1rng-2"
})(["flex:1 0 auto;position:relative;padding-bottom:50px;a{position:absolute;bottom:0;left:0;}"]);
const ImageHolder = styled.div.withConfig({
  displayName: "ImageWithContentWithSep__ImageHolder",
  componentId: "sc-ep1rng-3"
})(["position:relative;height:70px;margin:20px 0;", ""], media.desktop`
    margin-top: 0;
  `);
const ImageWrapper = styled.div.withConfig({
  displayName: "ImageWithContentWithSep__ImageWrapper",
  componentId: "sc-ep1rng-4"
})(["position:absolute;width:100%;bottom:0;"]);
const Image = styled.img.withConfig({
  displayName: "ImageWithContentWithSep__Image",
  componentId: "sc-ep1rng-5"
})(["max-height:70px;"]);