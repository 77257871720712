import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["label", "value", "error", "onChange", "className", "caption", "disabled", "inverse", "width", "fullWidth", "formatValue", "step", "min", "max", "name", "testId"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import Slider from '@mui/material/Slider';
import { Paragraph, Label } from '../../content/text';
import isArray from 'lodash/isArray';
import { withFormikWeb, SFormGroup, SFormControl, SFormHelperText, FormError, useLabelId } from '../common';
export const SliderInput = props => {
  const {
      label,
      value,
      error,
      onChange,
      className,
      caption,
      disabled,
      inverse,
      width,
      fullWidth,
      formatValue,
      step,
      min,
      max,
      name,
      testId
    } = props,
    otherProps = _objectWithoutProperties(props, _excluded);

  // eslint-disable-next-line no-nested-ternary
  const displayValue = formatValue ? formatValue(value) : isArray(value) ? `${value[0]} - ${value[1]}` : value === null || value === void 0 ? void 0 : value.toString();
  const labelId = useLabelId(name);
  return __jsx(SFormControl, {
    "data-slider": true,
    error: !!error,
    className: className,
    disabled: disabled,
    fullWidth: fullWidth,
    style: {
      width: width || (fullWidth ? undefined : 200)
    }
  }, label && __jsx(Label, {
    inverse: inverse,
    "data-slider-label": true,
    id: labelId
  }, label), __jsx(SFormGroup, {
    "data-slider-input": true
  }, __jsx(Flex, null, displayValue && displayValue.length ? __jsx(Paragraph, {
    semiBold: true,
    inverse: inverse,
    "data-cy": `${label}-slider-input-paragraph`
  }, displayValue) : null, __jsx(SSlider, _extends({
    "data-cy": testId || `${label}-slider-input`,
    inverse: inverse,
    value: value,
    disabled: disabled,
    step: step,
    min: min,
    max: max,
    name: name,
    onChange: onChange && ((_e, value) => onChange(value)),
    "aria-labelledby": labelId
  }, otherProps)))), error || caption ? __jsx(SFormHelperText, {
    margin: "dense"
  }, error ? __jsx(FormError, {
    type: "slider-input",
    testId: "error",
    name: (props === null || props === void 0 ? void 0 : props.name) || 'slider',
    error: error
  }) : caption) : null);
};
export const FormSliderInput = withFormikWeb(SliderInput);
const Flex = styled.div.withConfig({
  displayName: "SliderInput__Flex",
  componentId: "sc-1tvgcws-0"
})(["display:flex;flex-wrap:wrap;margin:4px 0;"]);
const SSlider = styled(Slider).withConfig({
  displayName: "SliderInput__SSlider",
  componentId: "sc-1tvgcws-1"
})(["&.MuiSlider-root{color:", ";height:4px;.MuiSlider-thumb{background:", ";}}.MuiSlider-rail{height:4px;}.MuiSlider-track{height:4px;}.MuiSlider-thumb{background-color:", ";width:20px;height:20px;}.MuiSlider-thumb.Mui-disabled{background-color:", ";width:20px;height:20px;}"], props => props.theme.colors.border_default, props => props.inverse ? props.theme.colors.text_paragraph_inverse : props.theme.colors.text_paragraph, props => props.theme.colors.border_outline, props => props.theme.colors.border_default);