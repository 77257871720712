import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _Divider from "@mui/material/Divider";
import _Typography from "@mui/material/Typography";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var __jsx = React.createElement;
import React, { useCallback, useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { useHistory, useLocation } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { consentDisclosureText, useFlags } from '@yieldstreet/tool-kit';
import { Button, FormCheckboxInput, FormDocumentTextInput, FormMaskTextInput, FormPhoneTextInput, FormSelectInput, FormTextInput, Markdown, Notification, PageLoader, Paragraph } from '@yieldstreet/ui-kit';
import { InvestorEntityProvider, MappedETCIraAccountType, MarketplaceFilterQueryParameters } from '@yieldstreet/platform-kit';
import { ETC_PRODUCT_TOUR_MODAL } from 'pages/marketplace/Marketplace/ETCProductTour/ETCProductTour.model';
import FormAddressInputGroup from 'sharedComponents/FormAddressInputGroup';
import { scrollToTop } from 'sharedComponents/ScrollTop';
import { USStatesSelectOptions } from 'sharedComponents/FormAddressInputGroup/StateSelection.model';
import { NotificationWrapper } from '../ETCKYCContainer/ETCKYCContainer.style';
import { useETCKYC } from '../hooks/useETCKYC';
import { testIds, EmploymentStatus, identificationDetailsValues, accountPurposeValues, employmentStatusValues, incomeSourceValues, initialFundSourceList, ongoingFundSourceList, FormFields, countriesOptions, DUE_DILIGENCE_FIELDS, fullInfoValidationSchema, mainInfoValidationSchema, IdentificationDetails, FIELDS_EXCLUDED_FROM_API_REQUEST } from './ETCKYC.model';
import { addUSPassportField, getInitialDataFromLocation, getListOfOccupationCategoriesList, getListOfOccupationsByCategory, getValuesFromCheckboxes, mapFormFieldsToValues, parseEmployerAddress } from './ETCKYC.utils';
import { ButtonContainer, FormWrapper, SubmitWrapper } from './ETCKYC.style';
import { MultipleCheckboxes } from './components/MultipleCheckboxes/MultipleCheckboxes';
export const ETCKYC = _ref => {
  let {
    form,
    investorEntityId,
    iraType,
    occupationsList,
    consentDisclosureVersion
  } = _ref;
  const history = useHistory();
  const location = useLocation();
  const {
    providerData: initialDataFromLocation
  } = (location === null || location === void 0 ? void 0 : location.state) || {};
  const {
    retirementEntityCreationEtcDocusign: redirectToAgreementsFlow,
    useEtcOpenAccountV3: newEtcAccountOpeningForm
  } = useFlags({
    retirementEntityCreationEtcDocusign: false,
    useEtcOpenAccountV3: false
  });
  const {
    createInvestorEntity,
    etcData,
    errorMessage,
    isSubmitting,
    isSuccess,
    updateInvestorEntity
  } = useETCKYC({
    investorEntityId
  });
  const hasExistingEtcInvestor = !!etcData;
  useEffect(() => {
    if (!isSuccess) {
      return;
    }
    if (hasExistingEtcInvestor) {
      history.replace(`/offerings/?${MarketplaceFilterQueryParameters.IraEligible}=false#${ETC_PRODUCT_TOUR_MODAL}`);
    } else {
      history.push(`/setup-an-ira/${iraType}/success`);
    }
  }, [isSuccess, history, hasExistingEtcInvestor, iraType]);
  const initialValues = useMemo(() => {
    var _etcData$address, _etcData$address2, _etcData$address3, _etcData$address4, _etcData$address5, _etcData$address6;
    if (initialDataFromLocation) {
      return getInitialDataFromLocation(location);
    }
    return _objectSpread(_objectSpread({}, mapFormFieldsToValues({
      fields: DUE_DILIGENCE_FIELDS,
      defaultFieldValue: ''
    })), {}, {
      [FormFields.FirstName]: (etcData === null || etcData === void 0 ? void 0 : etcData.firstName) || '',
      [FormFields.LastName]: (etcData === null || etcData === void 0 ? void 0 : etcData.lastName) || '',
      [FormFields.AddressStreet]: (etcData === null || etcData === void 0 ? void 0 : (_etcData$address = etcData.address) === null || _etcData$address === void 0 ? void 0 : _etcData$address.addressLine1) || '',
      [FormFields.AddressLine2]: (etcData === null || etcData === void 0 ? void 0 : (_etcData$address2 = etcData.address) === null || _etcData$address2 === void 0 ? void 0 : _etcData$address2.addressLine2) || '',
      country: (etcData === null || etcData === void 0 ? void 0 : (_etcData$address3 = etcData.address) === null || _etcData$address3 === void 0 ? void 0 : _etcData$address3.countryCode) || '',
      [FormFields.AddressCity]: (etcData === null || etcData === void 0 ? void 0 : (_etcData$address4 = etcData.address) === null || _etcData$address4 === void 0 ? void 0 : _etcData$address4.city) || '',
      [FormFields.AddressState]: (etcData === null || etcData === void 0 ? void 0 : (_etcData$address5 = etcData.address) === null || _etcData$address5 === void 0 ? void 0 : _etcData$address5.state) || '',
      [FormFields.AddressZipCode]: (etcData === null || etcData === void 0 ? void 0 : (_etcData$address6 = etcData.address) === null || _etcData$address6 === void 0 ? void 0 : _etcData$address6.postalCode) || '',
      [FormFields.PersonalPhoneNumber]: (etcData === null || etcData === void 0 ? void 0 : etcData.phoneNumber) || '',
      [FormFields.PersonalDocumentNumber]: '',
      [FormFields.DateOfBirth]: '',
      [FormFields.ConsentedDisclosure]: false,
      [FormFields.InitialFundSource]: {},
      [FormFields.OngoingFundSource]: {}
    });
  }, [etcData, initialDataFromLocation, location]);
  const onSubmit = useCallback(values => {
    scrollToTop();
    const consentedDisclosures = [{
      content: consentDisclosureText,
      version: consentDisclosureVersion
    }];
    const ownerData = {
      [FormFields.FirstName]: values[FormFields.FirstName],
      [FormFields.LastName]: values[FormFields.LastName],
      [FormFields.DateOfBirth]: dayjs(values[FormFields.DateOfBirth], 'MM/DD/YYYY').format('YYYY-MM-DD'),
      ssn: values[FormFields.PersonalDocumentNumber] ? values[FormFields.PersonalDocumentNumber].replace(/-/g, '') : null,
      addresses: [{
        addressType: 'Legal',
        addressLine1: values[FormFields.AddressStreet],
        addressLine2: values[FormFields.AddressLine2],
        city: values[FormFields.AddressCity],
        state: values[FormFields.AddressState],
        zipCode: values[FormFields.AddressZipCode],
        primary: true
      }],
      phones: [{
        phoneNumber: values[FormFields.PersonalPhoneNumber],
        phoneType: 'Home',
        primary: true
      }]
    };
    const customerDueDiligence = _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, mapFormFieldsToValues({
      fields: DUE_DILIGENCE_FIELDS,
      values,
      parseDate: true,
      fieldsToIgnore: FIELDS_EXCLUDED_FROM_API_REQUEST
    })), parseEmployerAddress(values)), addUSPassportField(values)), {}, {
      [FormFields.InitialFundSource]: getValuesFromCheckboxes(values[FormFields.InitialFundSource]),
      [FormFields.OngoingFundSource]: getValuesFromCheckboxes(values[FormFields.OngoingFundSource])
    });
    const updatePayload = {
      investorEntityId,
      owner: _objectSpread({}, ownerData),
      consentedDisclosures
    };
    const createWithDueDiligence = newEtcAccountOpeningForm ? {
      customerDueDiligence
    } : {};
    const createPayload = {
      provider: InvestorEntityProvider.ETC,
      providerData: _objectSpread({
        accountType: MappedETCIraAccountType[iraType],
        consentedDisclosures,
        owner: _objectSpread({}, ownerData)
      }, createWithDueDiligence)
    };
    if (hasExistingEtcInvestor) {
      updateInvestorEntity(updatePayload);
    } else {
      if (redirectToAgreementsFlow) {
        history.replace(`/investor/kyc/provider/etc/agreement/${iraType}`, _objectSpread({}, createPayload));
      } else {
        createInvestorEntity(createPayload);
      }
    }
  }, [consentDisclosureVersion, investorEntityId, newEtcAccountOpeningForm, iraType, hasExistingEtcInvestor, updateInvestorEntity, redirectToAgreementsFlow, history, createInvestorEntity]);
  if (isSubmitting && !isSuccess) {
    return __jsx(PageLoader, {
      testId: testIds.loader.id
    });
  }
  const validationSchema = hasExistingEtcInvestor || !newEtcAccountOpeningForm ? mainInfoValidationSchema : fullInfoValidationSchema;
  return __jsx(React.Fragment, null, errorMessage ? __jsx(NotificationWrapper, null, __jsx(Notification, {
    testId: testIds.error.id,
    description: errorMessage,
    severity: "error"
  })) : null, __jsx(Formik, {
    ref: form,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: onSubmit
  }, formikProps => {
    var _formikProps$values, _formikProps$values2, _formikProps$values3, _formikProps$values4, _formikProps$values5, _formikProps$values6, _formikProps$values7, _formikProps$values7$, _formikProps$values8, _formikProps$values8$;
    return __jsx(Form, null, __jsx(FormWrapper, null, __jsx(FormTextInput, {
      type: "text",
      name: FormFields.FirstName,
      label: "Legal first name",
      formikProps: formikProps,
      testId: testIds.firstName.id,
      fullWidth: true,
      disabled: !!initialValues[FormFields.FirstName] && !initialDataFromLocation
    }), __jsx(FormTextInput, {
      type: "text",
      name: FormFields.LastName,
      label: "Legal last name",
      formikProps: formikProps,
      testId: testIds.lastName.id,
      fullWidth: true,
      disabled: !!initialValues[FormFields.LastName] && !initialDataFromLocation
    }), __jsx("div", {
      "data-cy": testIds.primaryAddress.id
    }, __jsx(FormAddressInputGroup, {
      label: "Primary address",
      formikProps: formikProps,
      testId: testIds.primaryAddress.id,
      nameMap: {
        postalCode: FormFields.AddressZipCode,
        addressLine1: FormFields.AddressStreet
      },
      disabled: !!initialValues[FormFields.AddressStreet] && !initialDataFromLocation
    })), __jsx(FormPhoneTextInput, {
      name: FormFields.PersonalPhoneNumber,
      label: "Phone number",
      formikProps: formikProps,
      testId: testIds.phoneNumber.id,
      fullWidth: true,
      disabled: !!initialValues[FormFields.PersonalPhoneNumber] && !initialDataFromLocation
    }), __jsx(FormMaskTextInput, {
      label: "Date of birth",
      name: FormFields.DateOfBirth,
      placeholder: "MM/DD/YYYY",
      format: "##/##/####",
      useFormattedValue: true,
      formikProps: formikProps,
      testId: testIds.dateOfBirth.id,
      fullWidth: true
    }), __jsx(FormDocumentTextInput, {
      className: "fs-mask",
      name: FormFields.PersonalDocumentNumber,
      formikProps: formikProps,
      label: "Social security number",
      format: "###-##-####",
      testId: testIds.documentNumber.id,
      fullWidth: true
    }), !hasExistingEtcInvestor && newEtcAccountOpeningForm && occupationsList !== undefined && __jsx(React.Fragment, null, __jsx(_Divider, {
      component: "div",
      role: "separator",
      sx: {
        mb: 4
      }
    }, __jsx(_Typography, {
      sx: {
        color: 'text.secondary',
        fontSize: 13
      }
    })), __jsx(FormSelectInput, {
      label: "Identification details",
      name: FormFields.IdentificationType,
      testId: testIds.identificationType.id,
      formikProps: formikProps,
      options: identificationDetailsValues,
      fullWidth: true
    }), __jsx(FormTextInput, {
      className: "fs-mask",
      name: FormFields.IdentificationNumber,
      testId: testIds.idNumber.id,
      formikProps: formikProps,
      label: "ID number",
      fullWidth: true
    }), [IdentificationDetails.StateId, IdentificationDetails.DriversLicense].includes((_formikProps$values = formikProps.values) === null || _formikProps$values === void 0 ? void 0 : _formikProps$values[FormFields.IdentificationType]) && __jsx(FormSelectInput, {
      label: "State of Issuance",
      name: FormFields.IdentificationStateOfIssuance,
      formikProps: formikProps,
      options: USStatesSelectOptions,
      fullWidth: true,
      testId: testIds.stateOfIssuance.id
    }), ((_formikProps$values2 = formikProps.values) === null || _formikProps$values2 === void 0 ? void 0 : _formikProps$values2[FormFields.IdentificationType]) === IdentificationDetails.OtherGovPassport && __jsx(FormSelectInput, {
      label: "Country of Issuance",
      name: FormFields.IdentificationoCountryOfIssuance,
      testId: testIds.countryOfIssuance.id,
      formikProps: formikProps,
      options: countriesOptions,
      fullWidth: true
    }), __jsx(FormMaskTextInput, {
      label: "Issue date",
      name: FormFields.IdentificationIssueDate,
      testId: testIds.issueDate.id,
      placeholder: "MM/DD/YYYY",
      format: "##/##/####",
      useFormattedValue: true,
      formikProps: formikProps,
      fullWidth: true
    }), __jsx(FormMaskTextInput, {
      label: "Expiration date",
      name: FormFields.IdentificationExpirationDate,
      testId: testIds.expirationDate.id,
      placeholder: "MM/DD/YYYY",
      format: "##/##/####",
      useFormattedValue: true,
      formikProps: formikProps,
      fullWidth: true
    }), __jsx(FormSelectInput, {
      label: "Employment Status",
      name: FormFields.EmploymentStatus,
      testId: testIds.employmentStatus.id,
      formikProps: formikProps,
      options: employmentStatusValues,
      fullWidth: true
    }), ((_formikProps$values3 = formikProps.values) === null || _formikProps$values3 === void 0 ? void 0 : _formikProps$values3[FormFields.EmploymentStatus]) === EmploymentStatus.Employed && __jsx(React.Fragment, null, __jsx(FormSelectInput, {
      label: "Industry",
      name: FormFields.EmployedOccupationIndustry,
      testId: testIds.occupationCategory.id,
      formikProps: formikProps,
      options: getListOfOccupationCategoriesList(occupationsList),
      fullWidth: true
    }), __jsx(FormSelectInput, {
      label: "Job title",
      name: FormFields.EmployedOccupationJobTitle,
      testId: testIds.occupation.id,
      formikProps: formikProps,
      fullWidth: true,
      options: getListOfOccupationsByCategory(occupationsList, (_formikProps$values4 = formikProps.values) === null || _formikProps$values4 === void 0 ? void 0 : _formikProps$values4[FormFields.EmployedOccupationIndustry])
    }), __jsx(FormTextInput, {
      name: FormFields.EmployedEmployerName,
      label: "Employer Name",
      formikProps: formikProps,
      testId: testIds.employerName.id,
      fullWidth: true
    }), __jsx("div", {
      "data-cy": testIds.employerAddress.id
    }, __jsx(FormAddressInputGroup, {
      label: "Employer Address",
      formikProps: formikProps,
      testId: testIds.employerAddress.id,
      nameMap: {
        addressLine1: FormFields.EmployedEmployerAddressLine1,
        addressLine2: FormFields.EmployedEmployerAddressLine2,
        city: FormFields.EmployedEmployerAddressCity,
        state: FormFields.EmployedEmployerAddressState,
        postalCode: FormFields.EmployedEmployerAddressZipCode
      }
    }))), [EmploymentStatus.Unemployed, EmploymentStatus.Retired].includes((_formikProps$values5 = formikProps.values) === null || _formikProps$values5 === void 0 ? void 0 : _formikProps$values5[FormFields.EmploymentStatus]) && __jsx(React.Fragment, null, __jsx(FormSelectInput, {
      label: "Income Source",
      name: FormFields.UnemployedIncomeSource,
      testId: testIds.incomeSource.id,
      formikProps: formikProps,
      options: incomeSourceValues,
      fullWidth: true
    }), __jsx(FormSelectInput, {
      label: "Prior Occupation Industry",
      name: FormFields.UnemployedPriorOccupationIndustry,
      testId: testIds.priorOccupationCategory.id,
      formikProps: formikProps,
      options: getListOfOccupationCategoriesList(occupationsList),
      fullWidth: true
    }), __jsx(FormSelectInput, {
      label: "Prior Occupation Job title",
      name: FormFields.UnemployedPriorOccupationJobTitle,
      testId: testIds.priorOccupation.id,
      formikProps: formikProps,
      fullWidth: true,
      options: getListOfOccupationsByCategory(occupationsList, (_formikProps$values6 = formikProps.values) === null || _formikProps$values6 === void 0 ? void 0 : _formikProps$values6[FormFields.UnemployedPriorOccupationIndustry])
    })), __jsx(FormSelectInput, {
      label: "Account purpose",
      name: FormFields.AccountPurpose,
      testId: testIds.accountPurpose.id,
      formikProps: formikProps,
      options: accountPurposeValues,
      fullWidth: true
    }), __jsx(MultipleCheckboxes, {
      title: "Initial fund source",
      description: "Select all that apply",
      options: initialFundSourceList,
      name: FormFields.InitialFundSource,
      formikProps: formikProps,
      testId: testIds.initialFundSource.id
    }), ((_formikProps$values7 = formikProps.values) === null || _formikProps$values7 === void 0 ? void 0 : (_formikProps$values7$ = _formikProps$values7[FormFields.InitialFundSource]) === null || _formikProps$values7$ === void 0 ? void 0 : _formikProps$values7$.other) && __jsx(FormTextInput, {
      name: FormFields.InitialFundSourceOtherDetails,
      testId: testIds.initialFundSourceOtherDetails.id,
      formikProps: formikProps,
      label: "Initial Fund Source Other Details",
      fullWidth: true
    }), __jsx(MultipleCheckboxes, {
      title: "Ongoing Fund Source",
      description: "Select all that apply",
      options: ongoingFundSourceList,
      name: FormFields.OngoingFundSource,
      formikProps: formikProps,
      testId: testIds.ongoingFundSource.id
    }), ((_formikProps$values8 = formikProps.values) === null || _formikProps$values8 === void 0 ? void 0 : (_formikProps$values8$ = _formikProps$values8[FormFields.OngoingFundSource]) === null || _formikProps$values8$ === void 0 ? void 0 : _formikProps$values8$.other) && __jsx(FormTextInput, {
      name: FormFields.OngoingFundSourceOtherDetails,
      testId: testIds.ongoingFundSourceOtherDetails.id,
      formikProps: formikProps,
      label: "Ongoing Fund Source Other Details",
      fullWidth: true
    })), __jsx(FormCheckboxInput, {
      name: FormFields.ConsentedDisclosure,
      label: __jsx(Paragraph, {
        size: "small"
      }, __jsx(Markdown, {
        parserOptions: {
          commonmark: true
        },
        source: consentDisclosureText,
        linkTarget: "_blank"
      })),
      formikProps: formikProps,
      testId: testIds.consentedDisclosure.id
    }), __jsx(SubmitWrapper, null, __jsx(ButtonContainer, null, __jsx(Button, _extends({
      type: "submit",
      loading: isSubmitting,
      disabled: isSubmitting,
      trackingId: testIds.submit.id
    }, testIds.submit.attr), hasExistingEtcInvestor ? 'Update' : 'Submit')))));
  }));
};