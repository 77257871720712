var __jsx = React.createElement;
import React from 'react';
import { Route, Switch, useLocation } from 'react-router';
import { KYCProvidersContainer } from './providers';
import { INVESTOR_ENTITY_KYB_PROVIDER_PATH, INVESTOR_ENTITY_KYC_PROVIDER_PATH, CREATE_INVESTOR_ENTITY_KYC_PROVIDER_PATH } from './KYCContainer.model';
import { INVESTOR_ENTITY_KYB_PATH, INVESTOR_ENTITY_KYC_DISCRETIONARY_PATH, INVESTOR_ENTITY_KYC_PATH } from '../../InvestorEntityContainer.model';
import { KYC } from './KYC';
export const KYCContainer = () => {
  const location = useLocation();
  return __jsx(Switch, {
    location: location
  }, __jsx(Route, {
    path: [INVESTOR_ENTITY_KYC_PROVIDER_PATH, INVESTOR_ENTITY_KYB_PROVIDER_PATH, CREATE_INVESTOR_ENTITY_KYC_PROVIDER_PATH],
    component: KYCProvidersContainer
  }), __jsx(Route, {
    path: [INVESTOR_ENTITY_KYC_PATH, INVESTOR_ENTITY_KYB_PATH, INVESTOR_ENTITY_KYC_DISCRETIONARY_PATH],
    component: KYC
  }));
};