import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { theme2 } from './theme2';
export const theme1 = {
  name: 'Default',
  version: 1,
  typography: {
    primary: 'Raleway',
    secondary: 'Gotham A, Gotham B'
  },
  colors: {
    primary: 'rgb(30, 156, 159)',
    primaryAlt: 'rgb(43, 184, 192)',
    secondary: 'rgb(44, 181, 145)',
    secondaryAlt: 'rgb(41, 171, 100)',
    tertiary: 'rgb(229, 207, 10)',
    tertiaryAlt: 'rgb(208, 2, 27)',
    tertiaryAlt2: 'rgb(121, 76, 205)',
    gray1: 'rgb(53, 56, 58)',
    // #35383A
    gray2: 'rgb(82, 87, 90)',
    // #52575a
    gray3: 'rgb(118, 129, 135)',
    // #768187
    gray4: 'rgb(171, 180, 186)',
    gray5: 'rgb(223, 227, 228)',
    // #dfe3e4
    gray6: 'rgb(238, 240, 240)',
    gray7: 'rgb(246, 246, 246)',
    white: 'rgb(255, 255, 255)',
    red1: 'rgb(208,2,27)',
    yellow1: 'rgb(213, 194, 26)',
    blue1: 'rgb(3, 156, 220)'
  },
  shadows: {},
  gradients: {},
  grid: theme2.grid,
  spacing: theme2.spacing,
  radius: theme2.radius
};

// The theme vars from theme 2 are now available on theme 1 (some of them overriden with theme 1 colors).
// This is to avoid having to completely restyle newly-built components (e.g. inputs) for the old theme.
// These components still need to look good on the old brand, temporarily, before it is phased out.

theme1.colors = _objectSpread(_objectSpread({}, theme2.colors), {}, {
  active: theme1.colors.primary,
  highlight: theme1.colors.primary,
  text_label: theme1.colors.gray2,
  input_text: theme1.colors.gray1,
  input_text_selected: theme1.colors.white,
  input_background: theme1.colors.white,
  input_background_selected: theme1.colors.primary
}, theme1.colors);