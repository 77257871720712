var __jsx = React.createElement;
import React, { useState } from 'react';
import styled from 'styled-components';
import { PageSection } from '../../layout/page-section';
import { Paragraph } from '../../content/text';
import { CallbackLink } from '../../content/link';
import { media } from '../../themes';
export const Bio = _ref => {
  let {
    title,
    subTitle,
    image,
    imageBorder,
    children
  } = _ref;
  const {
    0: expanded,
    1: setExpanded
  } = useState(false);
  const expandedClick = () => {
    setExpanded(!expanded);
  };
  return __jsx(PageSection, null, __jsx(Container, null, image && __jsx(ImageHolder, {
    imageBorder: imageBorder
  }, image), __jsx(ContentHolder, null, __jsx(Title, {
    small: true,
    semiBold: true
  }, title), subTitle && __jsx(SubTitle, {
    small: true
  }, subTitle), __jsx(Content, {
    expanded: expanded
  }, children), children && __jsx(Paragraph, null, __jsx(Link, {
    callback: () => expandedClick()
  }, expanded ? `Collapse` : `Read More`)))));
};
const Container = styled.div.withConfig({
  displayName: "Bio__Container",
  componentId: "sc-1napowx-0"
})(["margin:0 0 20px 0;display:flex;flex-wrap:nowrap;flex-direction:column;", ""], media.desktop`
    flex-direction: row;
    margin: 0 0 40px 0;
  `);
const ImageHolder = styled.div.withConfig({
  displayName: "Bio__ImageHolder",
  componentId: "sc-1napowx-1"
})(["width:100%;img{width:100%;}img{", ";}", ""], props => props.imageBorder ? `border-bottom: 10px solid ${props.theme.colors.accent_strong};` : '', media.desktop`
    width: 280px;
  `);
const ContentHolder = styled.div.withConfig({
  displayName: "Bio__ContentHolder",
  componentId: "sc-1napowx-2"
})(["width:100%;margin:20px 0 0 0;", ""], media.desktop`
    margin: 0;
    width: 50%;
    padding: 0 40px 0 20px;
  `);
const Title = styled(Paragraph).withConfig({
  displayName: "Bio__Title",
  componentId: "sc-1napowx-3"
})(["width:100%;", ""], media.desktop`
    width: 60%;
  `);
const Content = styled.div.withConfig({
  displayName: "Bio__Content",
  componentId: "sc-1napowx-4"
})(["margin-bottom:20px;", ""], media.desktop`
    p {    
      transition: max-height 0.5s ${props => props.expanded ? `ease-in` : `ease-out`};
      max-height: ${props => props.expanded ? `2000px` : `90px`};
      -webkit-line-clamp: ${props => props.expanded ? `initial` : 3};
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `);
const SubTitle = styled(Paragraph).withConfig({
  displayName: "Bio__SubTitle",
  componentId: "sc-1napowx-5"
})(["margin:0 0 20px 0;"]);
const Link = styled(CallbackLink).withConfig({
  displayName: "Bio__Link",
  componentId: "sc-1napowx-6"
})(["display:none;", ""], media.desktop`
    display: inline-block;
  `);