import _Stack from "@mui/material/Stack";
import _Box from "@mui/material/Box";
import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React from 'react';
import howItWorksImage from './assets/how-it-works-image.svg';
export const ADVICE_HOW_IT_WORKS_PATH = '/advice/how-it-works';
export const DISCLOSURE_STATEMENT_PARAGRAPHS = ['The investment offerings recommended by Yieldstreet are highly speculative and entail substantial risks, including the fact that such investments would be illiquid and be subject to significant restrictions on transferability and that all or a substantial amount of the principal invested shall be subject to the risk of loss. No amount of diversification can eliminate such risks.', 'Such investment opportunities may not be suitable for all investors, and potential investors must make an independent assessment of the appropriateness of any investment in light of their own objectives and circumstances.', `Only certain of the questions asked will have an effect on the investments recommended. The reference to "you" means the investor, whether that's an individual or an entity.`];
export const DESCRIPTION_TITLE = 'Ready to unlock your recommendations?';
export const DESCRIPTION_PARAGRAPH = 'Answer a few key questions to get a customizable set of investment recommendations in just 2-3 minutes.';
export const CTA_BUTTON_LABEL = 'Get started';
export const TIMELINE_ITEMS = [{
  label: 'First step',
  content: __jsx(_Stack, {
    gap: 3
  }, __jsx(_Typography, {
    variant: "h4"
  }, "Set your investment preferences"), __jsx(_Typography, {
    variant: "body1"
  }, "We start by understanding your preferences, such as time horizon, risk tolerance and investment style."), __jsx(_Box, {
    component: "img",
    src: howItWorksImage,
    sx: {
      width: '100%',
      minWidth: {
        sm: 230
      },
      maxWidth: {
        xs: 420,
        sm: 450,
        md: 520
      }
    }
  }))
}, {
  label: 'Algorithm',
  content: __jsx(_Stack, {
    spacing: 3,
    sx: {
      mb: 4
    }
  }, __jsx(_Typography, {
    variant: "h4"
  }, "View recommendations"), __jsx(_Typography, {
    variant: "body1"
  }, "Based on your answers our algorithm recommends offerings that our team of investment professionals has vetted."))
}, {
  label: 'Investment',
  content: __jsx(_Stack, {
    spacing: 3,
    sx: {
      mb: 4
    }
  }, __jsx(_Typography, {
    variant: "h4"
  }, "Confirm and invest"), __jsx(_Typography, {
    variant: "body1"
  }, "After exploring your recommendations, allocate funding and invest with ease."))
}];