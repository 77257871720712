import _Box from "@mui/material/Box";
import _Button from "@mui/material/Button";
import _Stack from "@mui/material/Stack";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { content, testIds } from './TiersLetter.model';
import { InverseTypography, TierIcon, TierLogo, TiersLetterContainer } from './TiersLetter.style';
import { useTiersLetter } from './hooks/useTiersLetter';
export const TiersLetter = _ref => {
  var _tierContent$descript;
  let {
    handleOnClose
  } = _ref;
  const history = useHistory();
  const {
    refetchAudiences,
    tierContent
  } = useTiersLetter(content);
  useEffect(() => {
    refetchAudiences();
  }, [refetchAudiences]);
  const viewBenefits = useCallback(() => {
    var _tierContent$cta;
    handleOnClose();
    history.replace(tierContent === null || tierContent === void 0 ? void 0 : (_tierContent$cta = tierContent.cta) === null || _tierContent$cta === void 0 ? void 0 : _tierContent$cta.link);
  }, [history, tierContent, handleOnClose]);
  return __jsx(TiersLetterContainer, null, __jsx(_Stack, {
    alignItems: "center",
    sx: {
      mb: 6
    }
  }, __jsx(TierLogo, _extends({
    alt: tierContent === null || tierContent === void 0 ? void 0 : tierContent.alt,
    src: tierContent === null || tierContent === void 0 ? void 0 : tierContent.logo
  }, testIds.logo.attr)), __jsx(TierIcon, _extends({
    alt: tierContent === null || tierContent === void 0 ? void 0 : tierContent.alt,
    src: tierContent === null || tierContent === void 0 ? void 0 : tierContent.icon
  }, testIds.icon.attr))), __jsx(_Box, {
    sx: {
      pb: 12,
      px: 12
    }
  }, __jsx(InverseTypography, _extends({
    variant: "h4"
  }, testIds.title.attr), tierContent === null || tierContent === void 0 ? void 0 : tierContent.title), __jsx(_Stack, _extends({
    gap: 2,
    sx: {
      mt: 4
    }
  }, testIds.description.attr), tierContent === null || tierContent === void 0 ? void 0 : (_tierContent$descript = tierContent.description) === null || _tierContent$descript === void 0 ? void 0 : _tierContent$descript.map((tierDescription, index) => __jsx(InverseTypography, {
    key: `${testIds.description.id}-${index}`
  }, tierDescription))), __jsx(_Stack, {
    gap: 4,
    sx: {
      mt: 8
    }
  }, __jsx(_Button, _extends({
    onClick: viewBenefits
  }, testIds.cta.attr), tierContent === null || tierContent === void 0 ? void 0 : tierContent.cta.label), __jsx(_Button, _extends({
    onClick: handleOnClose,
    variant: "text",
    color: "tertiary"
  }, testIds.link.attr), tierContent === null || tierContent === void 0 ? void 0 : tierContent.link.label))));
};