import { useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { audienceApi } from '@yieldstreet/platform-kit';
import { isInvestorEntityComplete } from './useInvestorEntityComplete';
import { useAllInvestorEntities } from './useAllInvestorEntities';
import { useAuth } from '../../auth';
export const getLatestIncompleteInvestorEntity = (investorEntities, investorEntityStatuses, hasSelfPortfolio) => {
  const incompleteInvestorEntities = investorEntities === null || investorEntities === void 0 ? void 0 : investorEntities.map(investorEntity => ({
    investorEntity,
    investorEntityStatus: investorEntityStatuses === null || investorEntityStatuses === void 0 ? void 0 : investorEntityStatuses[investorEntity.id]
  })).sort((a, b) => b.investorEntity.id - a.investorEntity.id).filter(_ref => {
    let {
      investorEntityStatus
    } = _ref;
    return investorEntityStatus && !isInvestorEntityComplete(investorEntityStatus, hasSelfPortfolio);
  });
  const {
    investorEntity,
    investorEntityStatus
  } = (incompleteInvestorEntities === null || incompleteInvestorEntities === void 0 ? void 0 : incompleteInvestorEntities.shift()) || {};
  return {
    investorEntity,
    investorEntityStatus
  };
};
export const useLatestIncompleteInvestorEntity = () => {
  const {
    isLoading,
    investorEntities,
    investorEntityStatuses
  } = useAllInvestorEntities();
  const {
    userState
  } = useAuth();
  const userConfig = userState !== null && userState !== void 0 && userState.id ? {
    userId: `${userState === null || userState === void 0 ? void 0 : userState.id}`
  } : skipToken;
  const {
    data
  } = audienceApi.useGetPortfolioSelfAudienceQuery(userConfig);
  const {
    investorEntity,
    investorEntityStatus
  } = useMemo(() => getLatestIncompleteInvestorEntity(investorEntities, investorEntityStatuses, data === null || data === void 0 ? void 0 : data.hasSelfPortfolio), [investorEntities, investorEntityStatuses, data]);
  return {
    isLoading,
    investorEntity,
    investorEntityStatus
  };
};