var __jsx = React.createElement;
import React from 'react';
import { Provider } from 'react-redux';
export const ReduxWrapper = _ref => {
  let {
    children,
    store
  } = _ref;
  return __jsx(Provider, {
    store: store
  }, children);
};