import { genTestIds, createYupObjectSchema } from '@yieldstreet/tool-kit';
export const funnelValidation = createYupObjectSchema({
  atomicFunnelInput: {
    validationType: 'default-string'
  }
});
export const POLITICALLY_VALUE = 'politicallyExposedPerson';
const FINRA_VALUE = 'affiliatedWithFINRA';
const COMPANY_VALUE = 'hasCompany';
const STOCK_VALUE = 'affiliatedWithStockExchange';
export const NONE = 'none';
const {
  getTestIds
} = genTestIds(['atomicOption', 'submitButton', 'pageLoader']);
export const testIds = getTestIds('create-investor-account-atomic-fork');
export const options = [{
  label: 'Politician or political appointee',
  value: POLITICALLY_VALUE,
  testId: `${testIds.atomicOption.id}-${POLITICALLY_VALUE}`
}, {
  label: 'Affiliated with the securities industry',
  description: 'Employee at FINRA, a registered Broker-Deal, Investment Adviser, or Securities Regulator.',
  value: FINRA_VALUE,
  testId: `${testIds.atomicOption.id}-${FINRA_VALUE}`
}, {
  label: 'Control of a public company',
  description: '10% shareholder, director, officer, or executive',
  value: COMPANY_VALUE,
  testId: `${testIds.atomicOption.id}-${COMPANY_VALUE}`
}, {
  label: 'Affiliated with a stock exchange',
  value: STOCK_VALUE,
  testId: `${testIds.atomicOption.id}-${STOCK_VALUE}`
}, {
  label: 'None of the above',
  value: NONE,
  testId: `${testIds.atomicOption.id}-${NONE}`
}];