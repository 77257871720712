import _Link from "@mui/material/Link";
var __jsx = React.createElement;
import React, { useState } from 'react';
import { PortfolioCardValueLoader, PortfolioCardFooter } from 'pages/portfolio/common/PortfolioCard';
import { TransactionsListDrawer } from 'pages/portfolio/Transactions/TransactionsList/TransactionsListDrawer';
export const TransactionsListCardFooter = _ref => {
  let {
    transaction,
    isFetching
  } = _ref;
  const {
    0: open,
    1: setOpen
  } = useState(false);
  const toggleDrawer = openDrawer => {
    setOpen(openDrawer);
  };
  if (isFetching) {
    return __jsx(PortfolioCardFooter, null, __jsx(PortfolioCardValueLoader, {
      variant: "text"
    }));
  }
  if (!transaction) {
    return null;
  }
  return __jsx(PortfolioCardFooter, null, __jsx(TransactionsListDrawer, {
    drawerData: transaction,
    open: open,
    onOpen: () => toggleDrawer(true),
    onClose: () => toggleDrawer(false)
  }), __jsx(_Link, {
    onClick: () => toggleDrawer(true)
  }, "View details"));
};