import { H5, Paragraph, media } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
export const Wrapper = styled.div.withConfig({
  displayName: "SurveySectionstyle__Wrapper",
  componentId: "sc-ww16x4-0"
})(["width:100%;padding:40px;background-color:", ";"], props => props.theme.colors.chart_8);
export const InnerWrapper = styled.div.withConfig({
  displayName: "SurveySectionstyle__InnerWrapper",
  componentId: "sc-ww16x4-1"
})(["display:flex;align-items:center;justify-content:space-between;flex-direction:column;", ";"], media.desktop`
    flex-direction: row;
  `);
export const Title = styled.h2.withConfig({
  displayName: "SurveySectionstyle__Title",
  componentId: "sc-ww16x4-2"
})(["", ";margin-bottom:10px;"], H5);
export const SubTitle = styled(Paragraph).withConfig({
  displayName: "SurveySectionstyle__SubTitle",
  componentId: "sc-ww16x4-3"
})(["color:", ";"], props => props.theme.colors.text_header_inverse);
export const ThumbsText = styled(SubTitle).withConfig({
  displayName: "SurveySectionstyle__ThumbsText",
  componentId: "sc-ww16x4-4"
})(["", ";"], media.desktop`
    margin-right: 15px;
  `);
export const TextWrapper = styled.div.withConfig({
  displayName: "SurveySectionstyle__TextWrapper",
  componentId: "sc-ww16x4-5"
})(["text-align:center;", ";"], media.desktop`
    text-align: left;
  `);
export const ThumbsWrapper = styled.div.withConfig({
  displayName: "SurveySectionstyle__ThumbsWrapper",
  componentId: "sc-ww16x4-6"
})(["display:flex;min-width:292px;margin:30px -10px 0 20px;", ";"], media.desktop`
    margin: 0 -10px 0 20px;
  `);
export const ThumbsButton = styled.img.withConfig({
  displayName: "SurveySectionstyle__ThumbsButton",
  componentId: "sc-ww16x4-7"
})(["cursor:pointer;display:block;width:24px;height:22px;margin:0 10px;"]);