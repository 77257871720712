import { useMemo } from 'react';
import isNumber from "lodash/isNumber";
import Big from 'big.js';
import { UseModifiedBalanceContent } from './useModifiedBalance.model';
export const useModifiedBalance = _ref => {
  let {
    DEFAULT = 0,
    MARINE_DEFAULT = 0,
    MODIFIED_OUTLOOK = 0,
    PARTIAL_DEFAULT = 0
  } = _ref;
  const balancePerformanceSummation = useMemo(() => {
    const values = [DEFAULT, MARINE_DEFAULT, MODIFIED_OUTLOOK, PARTIAL_DEFAULT];
    let modifiedBalance = new Big(0);
    values.forEach(value => {
      if (isNumber(value)) modifiedBalance = modifiedBalance.plus(value);
    });
    return modifiedBalance.toNumber();
  }, [DEFAULT, MARINE_DEFAULT, MODIFIED_OUTLOOK, PARTIAL_DEFAULT]);
  return {
    balancePerformance: balancePerformanceSummation,
    message: balancePerformanceSummation ? UseModifiedBalanceContent.message : ''
  };
};