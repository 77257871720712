import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React from 'react';
export const ReturnPaymentsHeader = () => __jsx(React.Fragment, null, __jsx(_Typography, {
  variant: 'h4',
  sx: {
    mb: 2
  }
}, "Returned transactions"), __jsx(_Typography, {
  variant: 'body1',
  sx: {
    mb: 6
  }
}, "The following returned payments will be retried automatically after a preferred bank account is set."));