var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import Collapse from '@mui/material/Collapse';
import closeIcon from '../../assets/graphics/icons/close-icon-small.svg';
import { Tags } from './Tags';
export const ExtraTags = _ref => {
  let {
    tags = [],
    isHidden = true,
    onClose,
    hasLegacyOfferingTags
  } = _ref;
  return __jsx(Wrapper, null, __jsx(Collapse, {
    in: !isHidden
  }, __jsx(TagsWrapper, null, __jsx(AllTagsCloseButton, {
    "data-cy": "past-offerings-card-close-all-tags",
    onClick: onClose
  }, __jsx(Icon, {
    src: closeIcon,
    alt: "Close all tags list"
  })), __jsx(Tags, {
    "data-cy": "past-offerings-card-all-tags",
    tags: tags,
    hasCloseButton: true,
    hasLegacyOfferingTags: hasLegacyOfferingTags
  }))));
};
const Wrapper = styled.div.withConfig({
  displayName: "ExtraTags__Wrapper",
  componentId: "sc-d15qwl-0"
})(["background:", ";box-shadow:0px -4px 8px rgba(0,0,0,0.06);position:absolute;bottom:0px;width:100%;left:0;"], props => props.theme.colors.background_card);
const TagsWrapper = styled.div.withConfig({
  displayName: "ExtraTags__TagsWrapper",
  componentId: "sc-d15qwl-1"
})(["position:relative;"]);
const Icon = styled.img.withConfig({
  displayName: "ExtraTags__Icon",
  componentId: "sc-d15qwl-2"
})(["padding:10px;"]);
export const AllTagsCloseButton = styled.button.withConfig({
  displayName: "ExtraTags__AllTagsCloseButton",
  componentId: "sc-d15qwl-3"
})(["cursor:pointer;position:absolute;top:0px;right:0px;line-height:0px;margin:0px;padding:0px;border:none;background:transparent;"]);