import axios from 'axios';
import { CALL_API } from '../middleware/Middleware';
export const REGISTER_PASSWORD_ATTEMPT = 'ys/register/password/ATTEMPT';
export const REGISTER_PASSWORD_SUCCESS = 'ys/register/password/SUCCESS';
export const REGISTER_PASSWORD_FAILURE = 'ys/register/password/FAILURE';
export const doPasswordRegister = payload => dispatch => {
  const endpoint = `/a/api/user/register`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [REGISTER_PASSWORD_ATTEMPT, REGISTER_PASSWORD_SUCCESS, REGISTER_PASSWORD_FAILURE],
      options: {
        method: 'POST',
        data: payload
      }
    }
  });
};
export const LOGIN_PASSWORD_ATTEMPT = 'ys/login/password/ATTEMPT';
export const LOGIN_PASSWORD_SUCCESS = 'ys/login/password/SUCCESS';
export const LOGIN_PASSWORD_FAILURE = 'ys/login/password/FAILURE';
export const doPasswordLogin = payload => dispatch => {
  const endpoint = `/a/api/user/login`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [LOGIN_PASSWORD_ATTEMPT, LOGIN_PASSWORD_SUCCESS, LOGIN_PASSWORD_FAILURE],
      options: {
        method: 'POST',
        data: payload
      }
    }
  });
};
export const doLinkEntityLogin = payload => dispatch => {
  const endpoint = `/api/auth/link-identity`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [LOGIN_PASSWORD_ATTEMPT, LOGIN_PASSWORD_SUCCESS, LOGIN_PASSWORD_FAILURE],
      options: {
        method: 'POST',
        data: payload
      }
    }
  });
};
export const LOGIN_SOCIAL_ATTEMPT = 'ys/login/social/ATTEMPT';
export const LOGIN_SOCIAL_SUCCESS = 'ys/login/social/SUCCESS';
export const LOGIN_SOCIAL_FAILURE = 'ys/login/social/FAILURE';
export const doSocialLogin = (token, payload) => dispatch => {
  const endpoint = `/api/auth/login`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [LOGIN_SOCIAL_ATTEMPT, LOGIN_SOCIAL_SUCCESS, LOGIN_SOCIAL_FAILURE],
      options: {
        method: 'POST',
        data: payload,
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    }
  });
};
export const LOGIN_VERIFY_2FA_ATTEMPT = 'ys/login/verify/2fa/ATTEMPT';
export const LOGIN_VERIFY_2FA_SUCCESS = 'ys/login/verify/2fa/SUCCESS';
export const LOGIN_VERIFY_2FA_FAILURE = 'ys/login/verify/2fa/FAILURE';
export const do2FALoginVerification = token => dispatch => {
  const endpoint = `/a/api/user/twofa/login`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [LOGIN_VERIFY_2FA_ATTEMPT, LOGIN_VERIFY_2FA_SUCCESS, LOGIN_VERIFY_2FA_FAILURE],
      options: {
        method: 'POST',
        data: {
          token
        }
      }
    }
  });
};
export const REGISTER_2FA_ATTEMPT = 'ys/login/register/2fa/ATTEMPT';
export const REGISTER_2FA_SUCCESS = 'ys/login/register/2fa/SUCCESS';
export const REGISTER_2FA_FAILURE = 'ys/login/register/2fa/FAILURE';
export const doRegister2FA = _ref => {
  let {
    phone,
    method
  } = _ref;
  return dispatch => {
    const endpoint = `/a/api/user/twofa/register`;
    return dispatch({
      [CALL_API]: {
        endpoint,
        types: [REGISTER_2FA_ATTEMPT, REGISTER_2FA_SUCCESS, REGISTER_2FA_FAILURE],
        options: {
          method: 'POST',
          data: {
            phone: phone,
            method: method
          }
        }
      }
    });
  };
};
export const CONFIRM_2FA_ATTEMPT = 'ys/login/confirm/2fa/ATTEMPT';
export const CONFIRM_2FA_SUCCESS = 'ys/login/confirm/2fa/SUCCESS';
export const CONFIRM_2FA_FAILURE = 'ys/login/confirm/2fa/FAILURE';
export const doConfirm2FA = _ref2 => {
  let {
    phone,
    token
  } = _ref2;
  return dispatch => {
    const endpoint = `/a/api/user/twofa/confirm`;
    return dispatch({
      [CALL_API]: {
        endpoint,
        types: [CONFIRM_2FA_ATTEMPT, CONFIRM_2FA_SUCCESS, CONFIRM_2FA_FAILURE],
        options: {
          method: 'POST',
          data: {
            phone: phone,
            token: token
          }
        }
      }
    });
  };
};
export const RESEND_2FA_ATTEMPT = 'ys/login/resend/2fa/ATTEMPT';
export const RESEND_2FA_SUCCESS = 'ys/login/resend/2fa/SUCCESS';
export const RESEND_2FA_FAILURE = 'ys/login/resend/2fa/FAILURE';
export const doResend2FA = _ref3 => {
  let {
    phone,
    method
  } = _ref3;
  return dispatch => {
    const endpoint = `/a/api/user/twofa/resend`;
    return dispatch({
      [CALL_API]: {
        endpoint,
        types: [RESEND_2FA_ATTEMPT, RESEND_2FA_SUCCESS, RESEND_2FA_FAILURE],
        options: {
          method: 'POST',
          data: {
            phone: phone,
            method: method
          }
        }
      }
    });
  };
};
export const LOGOUT_ATTEMPT = 'ys/logout/ATTEMPT';
export const LOGOUT_SUCCESS = 'ys/logout/SUCCESS';
export const LOGOUT_FAILURE = 'ys/logout/FAILURE';
export const doLogout = () => dispatch => {
  const endpoint = '/a/api/user/logout';
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [LOGOUT_ATTEMPT, LOGOUT_SUCCESS, LOGOUT_FAILURE]
    }
  });
};
export const LOGIN_REVIEW_ATTEMPT = 'ys/login-review/ATTEMPT';
export const LOGIN_REVIEW_SUCCESS = 'ys/login-review/SUCCESS';
export const LOGIN_REVIEW_FAILURE = 'ys/login-review/FAILURE';
export const getLoginReview = token => dispatch => {
  const endpoint = `/a/api/user/login-review/${token}`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [LOGIN_REVIEW_ATTEMPT, LOGIN_REVIEW_SUCCESS, LOGIN_REVIEW_FAILURE],
      options: {
        method: 'GET'
      },
      getResponse: res => res.data
    }
  });
};
export const LOGIN_REVIEW_UPDATE_ATTEMPT = 'ys/login-review-update/ATTEMPT';
export const LOGIN_REVIEW_UPDATE_SUCCESS = 'ys/login-review-update/SUCCESS';
export const LOGIN_REVIEW_UPDATE_FAILURE = 'ys/login-review-update/FAILURE';
export const updateLoginReview = (token, payload) => dispatch => {
  const endpoint = `/a/api/user/login-review/${token}`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [LOGIN_REVIEW_UPDATE_ATTEMPT, LOGIN_REVIEW_UPDATE_SUCCESS, LOGIN_REVIEW_UPDATE_FAILURE],
      options: {
        method: 'POST',
        data: payload
      },
      getResponse: res => res.data
    }
  });
};
export const GET_2FA_STATUS_ATTEMPT = 'ys/2faStatus/ATTEMPT';
export const GET_2FA_STATUS_SUCCESS = 'ys/2faStatus/SUCCESS';
export const GET_2FA_STATUS_FAILURE = 'ys/2faStatus/FAILURE';
export const getUserTwoFAStatus = () => dispatch => {
  const endpoint = '/a/api/user/twofa/status';
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_2FA_STATUS_ATTEMPT, GET_2FA_STATUS_SUCCESS, GET_2FA_STATUS_FAILURE],
      getResponse: res => res.data.status
    }
  });
};
export const TWOFA_STATUS_RESET = 'ys/2faStatus/RESET';
export const reset2FAStatus = () => dispatch => dispatch({
  type: TWOFA_STATUS_RESET
});
export const TWOFA_CONFIRM_STATUS_RESET = 'ys/2faConfirmStatus/RESET';
export const reset2FAConfirm = () => dispatch => dispatch({
  type: TWOFA_CONFIRM_STATUS_RESET
});
export const TWOFA_REGISTER_STATUS_RESET = 'ys/2faRegisterStatus/RESET';
export const reset2FARegister = () => dispatch => dispatch({
  type: TWOFA_REGISTER_STATUS_RESET
});
export const PASSWORD_STATE_RESET = 'ys/password/RESET';
export const resetResetPwErrors = () => dispatch => dispatch({
  type: PASSWORD_STATE_RESET
});
export const RESET_PASSWORD_ATTEMPT = 'ys/password/reset/ATTEMPT';
export const RESET_PASSWORD_SUCCESS = 'ys/password/reset/SUCCESS';
export const RESET_PASSWORD_FAILURE = 'ys/password/reset/FAILURE';
export const resetPassword = email => dispatch => {
  const endpoint = '/a/api/user/resetPassword';
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [RESET_PASSWORD_ATTEMPT, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE],
      options: {
        method: 'POST',
        data: {
          email
        }
      }
    }
  });
};
export const UPDATE_PASSWORD_ATTEMPT = 'ys/password/update/ATTEMPT';
export const UPDATE_PASSWORD_SUCCESS = 'ys/password/update/SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'ys/password/update/FAILURE';
export const updatePassword = updatePasswordData => dispatch => {
  const endpoint = '/a/api/user/passwordChange';
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [UPDATE_PASSWORD_ATTEMPT, UPDATE_PASSWORD_SUCCESS, UPDATE_PASSWORD_FAILURE],
      options: {
        method: 'POST',
        data: updatePasswordData
      }
    }
  });
};
export const GET_USER_PROFILE_ATTEMPT = 'ys/user/profile/ATTEMPT';
export const GET_USER_PROFILE_SUCCESS = 'ys/user/profile/SUCCESS';
export const GET_USER_PROFILE_FAILURE = 'ys/user/profile/FAILURE';
export const getUserProfile = () => dispatch => {
  const endpoint = '/a/api/investor/profile';
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_USER_PROFILE_ATTEMPT, GET_USER_PROFILE_SUCCESS, GET_USER_PROFILE_FAILURE],
      getResponse: res => res.data
    }
  });
};
export const EDIT_USER_NAME_ATTEMPT = 'ys/user/edit/name/ATTEMPT';
export const EDIT_USER_NAME_SUCCESS = 'ys/user/edit/name/SUCCESS';
export const EDIT_USER_NAME_FAILURE = 'ys/user/edit/name/FAILURE';
export const editUserName = (userId, data) => dispatch => {
  const endpoint = `/a/api/user/name/${userId}`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: 'PATCH',
        data
      },
      types: [EDIT_USER_NAME_ATTEMPT, EDIT_USER_NAME_SUCCESS, EDIT_USER_NAME_FAILURE],
      getResponse: res => res.data
    }
  });
};
export const GET_USER_BENEFICIARY_ATTEMPT = 'ys/user/beneficiary/ATTEMPT';
export const GET_USER_BENEFICIARY_SUCCESS = 'ys/user/beneficiary/SUCCESS';
export const GET_USER_BENEFICIARY_FAILURE = 'ys/user/beneficiary/FAILURE';
export const getUserBeneficiary = userId => dispatch => {
  const endpoint = `/a/api/user/${userId}/beneficiary`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_USER_BENEFICIARY_ATTEMPT, GET_USER_BENEFICIARY_SUCCESS, GET_USER_BENEFICIARY_FAILURE],
      getResponse: res => res.data
    }
  });
};
export const VERIFY_BENEFICIARY_ATTEMPT = 'ys/user/beneficiary/verify/ATTEMPT';
export const VERIFY_BENEFICIARY_SUCCESS = 'ys/user/beneficiary/verify/SUCCESS';
export const VERIFY_BENEFICIARY_FAILURE = 'ys/user/beneficiary/verify/FAILURE';
export const verifyBeneficiary = userId => dispatch => {
  const endpoint = `/a/api/user/${userId}/beneficiary/verified`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [VERIFY_BENEFICIARY_ATTEMPT, VERIFY_BENEFICIARY_SUCCESS, VERIFY_BENEFICIARY_FAILURE],
      options: {
        method: 'POST'
      },
      getResponse: res => res.data
    }
  });
};
export const RESET_USER_AUTH = 'ys/user/auth/RESET';
export const resetUserAuth = () => dispatch => dispatch({
  type: RESET_USER_AUTH
});
export const SET_USER_STATE = 'ys/user/state/SET';
export const setUserState = userState => dispatch => dispatch({
  type: SET_USER_STATE,
  userState
});

//Out of scope right now

export const emailVerify = token => {
  let emailVerifyData = {
    verification_code: token
  };
  return axios.post('/a/api/user/emailVerify', emailVerifyData);
};
export const RESET_USER_NAME = 'ys/reset/user-name';
export const resetUserName = () => dispatch => dispatch({
  type: RESET_USER_NAME
});
export const SET_USER_HEADERS = 'ys/user/headers/SET';
export const setUserHeaders = headers => dispatch => dispatch({
  type: SET_USER_HEADERS,
  headers
});
export const CLEAR_USER_HEADERS = 'ys/user/headers/CLEAR';
export const clearUserHeaders = () => dispatch => dispatch({
  type: CLEAR_USER_HEADERS
});
export const SET_ANALYTICS_ANONYMOUS_ID = 'ys/user/analytics/anonymous-id/SET';
export const setAnalyticsAnonymousId = anonymousId => dispatch => dispatch({
  type: SET_ANALYTICS_ANONYMOUS_ID,
  anonymousId
});
export const SET_ANALYTICS_USER_ID = 'ys/user/analytics/user-id/SET';
export const setAnalyticsUserId = userId => dispatch => dispatch({
  type: SET_ANALYTICS_USER_ID,
  userId
});
export const SHOW_USER_EMAIL_CONFIRMATION = 'ys/user/email/confirmation/show';
export const showUserEmailConfirmation = () => dispatch => dispatch({
  type: SHOW_USER_EMAIL_CONFIRMATION
});
export const HIDE_USER_EMAIL_CONFIRMATION = 'ys/user/email/confirmation/hide';
export const hideUserEmailConfirmation = () => dispatch => dispatch({
  type: HIDE_USER_EMAIL_CONFIRMATION
});
export const HIDE_USER_EMAIL_NOTIFICATION = 'ys/user/email/notification/hide';
export const GET_USER_ACCREDITATION_ELIGIBILITY_ATTEMPT = 'ys/accounts/user-accreditation-eligibility/ATTEMPT';
export const GET_USER_ACCREDITATION_ELIGIBILITY_SUCCESS = 'ys/accounts/user-accreditation-eligibility/SUCCESS';
export const GET_USER_ACCREDITATION_ELIGIBILITY_FAILURE = 'ys/accounts/user-accreditation-eligibility/FAILURE';
export const getUserAccreditationEligibility = offeringHashes => dispatch => {
  const endpoint = `/a/api/user/resolve-offering-eligibility`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_USER_ACCREDITATION_ELIGIBILITY_ATTEMPT, GET_USER_ACCREDITATION_ELIGIBILITY_SUCCESS, GET_USER_ACCREDITATION_ELIGIBILITY_FAILURE],
      options: {
        method: 'POST',
        data: {
          offerings: offeringHashes
        }
      },
      getResponse: res => res.data.offerings
    }
  });
};