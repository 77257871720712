import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _Button from "@mui/material/Button";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _Box from "@mui/material/Box";
import _Typography from "@mui/material/Typography";
const _excluded = ["asPage", "asWidget", "testId"];
var __jsx = React.createElement;
import React, { useMemo } from 'react';
import defaults from "lodash/defaults";
import { Image, PageWrapper, Text, ViewWrapper } from '../common.style';
import { WidgetSurface } from '../WidgetSurface';
import { ErrorViewDefaults, testIds } from './ErrorView.model';
const View = _ref => {
  let {
    ctaAction,
    ctaText,
    direction,
    heading,
    icon,
    testId,
    text
  } = _ref;
  const config = useMemo(() => defaults({
    icon,
    heading,
    text,
    ctaText,
    ctaAction
  }, ErrorViewDefaults), [icon, heading, text, ctaText, ctaAction]);
  return __jsx(ViewWrapper, {
    "data-cy": testId,
    direction: direction
  }, config.icon && __jsx(Image, {
    alt: config.heading,
    src: config.icon
  }), __jsx(_Box, null, config.heading && __jsx(_Typography, {
    variant: "h4"
  }, config.heading), config.text && __jsx(Text, null, config.text)), config.ctaText && __jsx(_Button, _extends({
    onClick: config.ctaAction,
    color: "tertiary",
    sx: {
      mt: 2
    },
    variant: "outlined"
  }, testIds.button.attr), config.ctaText));
};
export const ErrorView = _ref2 => {
  let {
      asPage,
      asWidget,
      testId
    } = _ref2,
    viewProps = _objectWithoutProperties(_ref2, _excluded);
  if (asPage) {
    return __jsx(PageWrapper, {
      center: true,
      testId: testId,
      withPadding: true
    }, __jsx(View, viewProps));
  }
  if (asWidget) {
    return __jsx(WidgetSurface, {
      testId: testId
    }, __jsx(View, viewProps));
  }
  return __jsx(View, _extends({
    testId: testId
  }, viewProps));
};