import _Stack from "@mui/material/Stack";
import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React from 'react';
import { FiltersMobile } from 'sharedComponents/Filters';
import { PortfolioFilterKeys } from '../usePortfolioFilters/usePortfolioFilters.model';
import { PortfolioFiltersActions, PortfolioFiltersWrapper } from '../components';
export const PortfolioFiltersMobile = _ref => {
  let {
    actions,
    filters,
    title
  } = _ref;
  return __jsx(PortfolioFiltersWrapper, null, __jsx(_Typography, {
    variant: "h4"
  }, title), __jsx(_Stack, {
    direction: "row",
    sx: {
      justifyContent: 'flex-end'
    }
  }, __jsx(FiltersMobile, {
    filterOptions: filters,
    queryFilterParams: PortfolioFilterKeys,
    testId: 'testIds.filtersMobile.id'
  }), __jsx(PortfolioFiltersActions, {
    actions: actions,
    isMobile: true
  })));
};