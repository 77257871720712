import axios from 'axios';
import forEach from 'lodash/forEach';
import { restApi, Tags } from '../common';
export const offeringApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.Offering, Tags.OfferingDetails]
}).injectEndpoints({
  endpoints: builder => {
    const details = builder.query({
      queryFn: async (urlHashes, _ref) => {
        let {
          extra
        } = _ref;
        const axiosInstance = extra || axios;
        return await Promise.all(urlHashes.map(urlHash => axiosInstance.get(`/a/api/offering/${urlHash}`, {
          headers: {
            'url-hash': urlHash
          }
        }))).then(_ref2 => {
          let [...responses] = _ref2;
          const data = {};
          forEach(responses, x => {
            var _x$request, _x$request$_headers, _x$config$headers;
            // NOTE: api does not return the urlHash when note is reserved. Note url is retrieved from headers.
            const urlHash = ((_x$request = x.request) === null || _x$request === void 0 ? void 0 : (_x$request$_headers = _x$request._headers) === null || _x$request$_headers === void 0 ? void 0 : _x$request$_headers['url-hash']) || ((_x$config$headers = x.config.headers) === null || _x$config$headers === void 0 ? void 0 : _x$config$headers['url-hash']);
            Object.assign(data, {
              [urlHash]: x.data
            });
          });
          return {
            data
          };
        }).catch(errors => {
          return {
            error: errors
          };
        });
      },
      providesTags: [Tags.OfferingDetails]
    });
    return {
      details
    };
  }
});