import { useMemo } from 'react';
import { PortfolioStrategyValues } from '@yieldstreet/platform-kit';
import { useParams } from 'react-router-dom';
import { useUserState } from 'utils/hooks';
import { useInvestorAccounts } from 'pages/portfolio/shared/PortfolioHeader';
import { useManagementStrategy } from '../useManagementStrategy';
export const usePortfolioBaseConfig = () => {
  const {
    id: userId
  } = useUserState();
  const {
    investorId
  } = useParams();
  const {
    managementStrategy
  } = useManagementStrategy();
  const {
    selectedInvestorAccount
  } = useInvestorAccounts({
    investorId,
    managementStrategy
  });
  return useMemo(() => ({
    userId,
    investorId: selectedInvestorAccount === null || selectedInvestorAccount === void 0 ? void 0 : selectedInvestorAccount.id,
    investorEntityId: selectedInvestorAccount === null || selectedInvestorAccount === void 0 ? void 0 : selectedInvestorAccount.investorEntityId,
    managementStrategy,
    config: {
      refetchOnMountOrArgChange: managementStrategy === PortfolioStrategyValues.Discretionary
    }
  }), [userId, selectedInvestorAccount === null || selectedInvestorAccount === void 0 ? void 0 : selectedInvestorAccount.id, selectedInvestorAccount === null || selectedInvestorAccount === void 0 ? void 0 : selectedInvestorAccount.investorEntityId, managementStrategy]);
};