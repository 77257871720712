import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectDestructuringEmpty from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectDestructuringEmpty.js";
var __jsx = React.createElement;
import React, { useMemo } from 'react';
import { CSSTransition } from 'react-transition-group';
import { ActiveMetrics } from '../ActiveMetrics/ActiveMetrics';
import { useOfferingHero } from '../hooks/useOfferingHero';
import { Metrics, Header, Left, ThumbnailImage, Right, CustomComponent, animationDuration } from './DockedHero.style';
const DockedHero = _ref => {
  let {
    offering,
    show,
    loggedIn,
    customComponent,
    sticky,
    status
  } = _ref;
  const _useOfferingHero = useOfferingHero(offering),
    activeMetricProps = Object.assign({}, (_objectDestructuringEmpty(_useOfferingHero), _useOfferingHero));
  const metrics = useMemo(() => {
    return __jsx(Metrics, {
      "data-cy": "docked-hero-metrics"
    }, __jsx(ActiveMetrics, _extends({}, activeMetricProps, {
      offering: offering,
      status: status,
      isConcise: true
    })));
  }, [activeMetricProps, status, offering]);
  return __jsx(React.Fragment, null, __jsx(CSSTransition, {
    in: show && loggedIn,
    timeout: animationDuration,
    classNames: "fade",
    appear: true,
    unmountOnExit: true
  }, __jsx(Header, {
    sticky: sticky
  }, __jsx(Left, null, offering.thumbnailImageUrl ? __jsx(ThumbnailImage, {
    src: offering.thumbnailImageUrl,
    "data-cy": "thumbnail_modal_image",
    alt: "Offering Image Thumbnail"
  }) : null, __jsx(_Typography, {
    component: "h1",
    variant: "h6",
    color: "textSenary",
    "data-cy": "docked-hero-title",
    sx: theme => ({
      maxWidth: 276,
      mb: 1,
      display: 'none',
      [theme.breakpoints.up('md')]: {
        mb: 'unset',
        display: 'block'
      }
    })
  }, offering.title), __jsx(_Typography, {
    color: "textSenary",
    "data-cy": "docked-hero-mobile-title",
    sx: theme => ({
      pb: 1,
      display: 'block',
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    })
  }, offering.title), metrics), __jsx(Right, null, customComponent && __jsx(CustomComponent, null, customComponent)))));
};
export default DockedHero;