var __jsx = React.createElement;
import React, { useState } from 'react';
import styled from 'styled-components';
import { media } from '../../themes/media';
import { Heading, Paragraph, ParagraphBase, ParagraphChildren } from '../../content/text';
import { Button, ButtonContainer } from '../../content/button';
import { PageSection } from '../../layout/page-section';
import PlusSign from './assets/plus-sign.svg';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export const OurOriginationApproach = () => {
  const {
    0: section,
    1: setSection
  } = useState(0);
  const sectionChange = function (newSelection) {
    let isMobile = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    if (isMobile && section === newSelection) {
      setSection(undefined);
      return;
    }
    setSection(newSelection);
  };
  return __jsx(Background, null, __jsx(PageSection, null, __jsx(Container, null, __jsx(LeftContent, null, __jsx(MainHeadline, {
    type: 3,
    inverse: true
  }, "Our origination approach"), __jsx(Content, {
    inverse: true
  }, "Evaluating opportunities from a diverse pool of originators allows us to offer investors opportunities from numerous asset classes, spread across the yield spectrum."), __jsx(ContentList, {
    inverse: true,
    small: true
  }, __jsx("ul", null, __jsx("li", null, "Asset-based collateral"), __jsx("li", null, "Low stock market correlation"), __jsx("li", null, "Experienced management"), __jsx("li", null, "Short duration (less than 5 years)"), __jsx("li", null, "Risk appropriate returns")))), __jsx(CenterContent, null, __jsx(PlusHolder, null, __jsx(StrategicPartnerships, {
    onClick: () => sectionChange(0),
    active: section === 0
  }, __jsx(Paragraph, {
    inverse: true,
    small: true
  }, "Strategic partnerships")), __jsx(JointVentures, {
    onClick: () => sectionChange(1),
    active: section === 1
  }, __jsx(Paragraph, {
    inverse: true,
    small: true
  }, "Joint ventures")), __jsx(MandA, {
    onClick: () => sectionChange(2),
    active: section === 2
  }, __jsx(Paragraph, {
    inverse: true,
    small: true
  }, "M&A")), __jsx(YieldStreetOriginated, {
    onClick: () => sectionChange(3),
    active: section === 3
  }, __jsx(Paragraph, {
    inverse: true,
    small: true
  }, "Yieldstreet originated")), __jsx(Image, {
    alt: "four elements of Yieldstreet origination approach to alternative investing",
    src: PlusSign
  }))), __jsx(RightContent, null, __jsx(SectionHolder, {
    active: section === 0
  }, __jsx(HeadingWrapper, null, __jsx(Headline, {
    type: 3,
    inverse: true
  }, "Strategic partnerships ", __jsx(ArrowIcon, {
    icon: faChevronUp
  }))), __jsx(MobileHeadingWrapper, {
    onClick: () => sectionChange(0, true)
  }, __jsx(Headline, {
    type: 3,
    inverse: true
  }, "Strategic partnerships ", __jsx(ArrowIcon, {
    icon: faChevronUp
  }))), __jsx(SectionContentHolder, null, __jsx(Content, {
    inverse: true,
    semiBold: true,
    small: true
  }, "Strategic forward flow, Simple forward flow"), __jsx(Content, {
    inverse: true,
    small: true
  }, "Agreements with select originators where Yieldstreet provides a strategic flow agreement that gives originators support to grow their business. In turn, Yieldstreet gets a minor interest in the originator and origination ROFR."), __jsx(Content, {
    inverse: true,
    semiBold: true,
    small: true
  }, "Simple Forward Flow"), __jsx(Content, {
    inverse: true,
    small: true
  }, "Agreements with select originators where Yieldstreet provides a non-binding flow agreement, but the agreement highlights a desire to memorialize the relationship. The desire is to move this from simple to strategic over time."))), __jsx(SectionHolder, {
    active: section === 1
  }, __jsx(HeadingWrapper, null, __jsx(Headline, {
    type: 3,
    inverse: true
  }, "Joint ventures ", __jsx(ArrowIcon, {
    icon: faChevronUp
  }))), __jsx(MobileHeadingWrapper, {
    onClick: () => sectionChange(1, true)
  }, __jsx(Headline, {
    type: 3,
    inverse: true
  }, "Joint ventures ", __jsx(ArrowIcon, {
    icon: faChevronUp
  }))), __jsx(SectionContentHolder, null, __jsx(Content, {
    inverse: true,
    small: true
  }, "Partnerships with originators to explore new opportunities together."), __jsx(Content, {
    inverse: true,
    small: true
  }, "There are strategic flow terms for Yieldstreet and equity benefits from the JV ownership."))), __jsx(SectionHolder, {
    active: section === 2
  }, __jsx(HeadingWrapper, null, __jsx(Headline, {
    type: 3,
    inverse: true
  }, "M&A ", __jsx(ArrowIcon, {
    icon: faChevronUp
  }))), __jsx(MobileHeadingWrapper, {
    onClick: () => sectionChange(2, true)
  }, __jsx(Headline, {
    type: 3,
    inverse: true
  }, "M&A ", __jsx(ArrowIcon, {
    icon: faChevronUp
  }))), __jsx(SectionContentHolder, null, __jsx(Content, {
    inverse: true,
    small: true
  }, "In order to continue to bring new asset classes to our investors, we leverage our technology, proprietary origination channels and efficient direct-to-consumer distribution, to take a traditional capital-intensive business and make it capital efficient."))), __jsx(SectionHolder, {
    active: section === 3
  }, __jsx(HeadingWrapper, null, __jsx(Headline, {
    type: 3,
    inverse: true
  }, "Yieldstreet originated", ' ')), __jsx(MobileHeadingWrapper, {
    onClick: () => sectionChange(3, true)
  }, __jsx(Headline, {
    type: 3,
    inverse: true
  }, "Yieldstreet originated ", __jsx(ArrowIcon, {
    icon: faChevronUp
  }))), __jsx(SectionContentHolder, null, __jsx(Content, {
    inverse: true,
    small: true
  }, "Deals are shown on a one-off basis from the originator."))), __jsx(ButtonHolder, null, __jsx("a", {
    href: "/originator-application/contact"
  }, __jsx(Button, null, "Start application")))))));
};
const Background = styled.div.withConfig({
  displayName: "OurOriginationApproach__Background",
  componentId: "sc-1dymjgy-0"
})(["background:", ";"], props => props.theme.colors.background_inverse);
const Container = styled.div.withConfig({
  displayName: "OurOriginationApproach__Container",
  componentId: "sc-1dymjgy-1"
})(["margin:0 auto;padding:40px 0 0 0;display:flex;justify-content:space-evenly;flex-wrap:nowrap;flex-direction:column;", ""], media.desktop`
    flex-direction: row;
    padding: 40px 100px 20px 100px;
  `);
const LeftContent = styled.div.withConfig({
  displayName: "OurOriginationApproach__LeftContent",
  componentId: "sc-1dymjgy-2"
})(["padding:0 20px;flex-grow:1;flex-basis:0;"]);
const CenterContent = styled.div.withConfig({
  displayName: "OurOriginationApproach__CenterContent",
  componentId: "sc-1dymjgy-3"
})(["flex-grow:1;flex-basis:0;"]);
const RightContent = styled.div.withConfig({
  displayName: "OurOriginationApproach__RightContent",
  componentId: "sc-1dymjgy-4"
})(["padding:40px 0 0 0;flex-grow:1;flex-basis:0;", ""], media.desktop`
    padding: 40px 20px 0 20px;
  `);
const Headline = styled(Heading).withConfig({
  displayName: "OurOriginationApproach__Headline",
  componentId: "sc-1dymjgy-5"
})(["padding:20px 0;", ""], media.desktop`
    color: ${props => `${props.theme.colors.text_header_inverse}`};
    border: none;
    padding: 0 0 20px 0;
  `);
const HeadingWrapper = styled.div.withConfig({
  displayName: "OurOriginationApproach__HeadingWrapper",
  componentId: "sc-1dymjgy-6"
})(["display:none;", ""], media.desktop`
    display: block;
  `);
const MobileHeadingWrapper = styled.div.withConfig({
  displayName: "OurOriginationApproach__MobileHeadingWrapper",
  componentId: "sc-1dymjgy-7"
})(["display:block;", ""], media.desktop`
    display: none;
  `);
const Content = styled(Paragraph).withConfig({
  displayName: "OurOriginationApproach__Content",
  componentId: "sc-1dymjgy-8"
})(["margin:0 0 10px 0;"]);
const ArrowIcon = styled(FontAwesomeIcon).withConfig({
  displayName: "OurOriginationApproach__ArrowIcon",
  componentId: "sc-1dymjgy-9"
})(["float:right;padding:5px;margin-top:5px;width:10px;transition:all 0.2s ease-in;transform:rotate(180deg);", ""], media.desktop`
    display: none;
  `);
const SectionHolder = styled.div.withConfig({
  displayName: "OurOriginationApproach__SectionHolder",
  componentId: "sc-1dymjgy-10"
})(["display:block;padding:0 20px;", " ", ""], props => {
  if (props.active) {
    return `
        ${SectionContentHolder} {
          max-height: 800px;
          transition: all 0.5s ease-in;
        }
        ${Headline} {
          color: ${props.theme.colors.text_header};
        }
        ${ArrowIcon} {
          transform: rotate(-0deg);
        }
        padding: 0 20px 20px 20px;
        ${Content} {
          display: block;
          color: ${props.theme.colors.text_paragraph};
        }
        background-color: ${props.theme.colors.background_card};
      `;
  } else {
    return `
        ${Headline} {
          border-bottom: 1px solid ${props.theme.colors.background_card};
        }
      `;
  }
}, media.desktop`
    padding: 0;
    ${Headline} {
      color: ${props => `${props.theme.colors.text_header_inverse}`};
    }
    ${Content} {
      color: ${props => `${props.theme.colors.text_paragraph_inverse}`};
    }
    background: none;
    display: ${props => props.active ? 'block' : 'none'};
  `);
const MainHeadline = styled(Heading).withConfig({
  displayName: "OurOriginationApproach__MainHeadline",
  componentId: "sc-1dymjgy-11"
})(["padding:0 0 20px 0;"]);
const SectionContentHolder = styled.div.withConfig({
  displayName: "OurOriginationApproach__SectionContentHolder",
  componentId: "sc-1dymjgy-12"
})(["max-height:0;overflow:hidden;height:auto;", ""], media.desktop`
    display: block;
  `);
const ButtonHolder = styled(ButtonContainer).withConfig({
  displayName: "OurOriginationApproach__ButtonHolder",
  componentId: "sc-1dymjgy-13"
})(["padding:0 20px;", ""], media.desktop`
    padding: 0;
  `);
const ContentList = styled.div.withConfig({
  displayName: "OurOriginationApproach__ContentList",
  componentId: "sc-1dymjgy-14"
})(["", " ", ""], ParagraphBase, ParagraphChildren);
const Image = styled.img.withConfig({
  displayName: "OurOriginationApproach__Image",
  componentId: "sc-1dymjgy-15"
})(["width:100%;", ""], media.desktop`
    width: auto;
  `);
const PlusHolder = styled.div.withConfig({
  displayName: "OurOriginationApproach__PlusHolder",
  componentId: "sc-1dymjgy-16"
})(["display:none;", ""], media.desktop`
    padding: 65px;
    position: relative;
    margin: 0 auto;
    max-width: 592px;
    display: block;
  `);
const PlusText = styled.div.withConfig({
  displayName: "OurOriginationApproach__PlusText",
  componentId: "sc-1dymjgy-17"
})(["p{color:", ";}cursor:pointer;position:absolute;width:80px;margin:auto;", " ", ""], props => props.active ? props.theme.colors.active : props.theme.colors.text_label, media.desktop`
    width: 100px;
    p {
      text-align: left;
      font-size: 16px;
    }
`, media.desktop`
  width: 90px;
  p {
    text-align: left;
    font-size: 16px;
    }
  `);
const StrategicPartnerships = styled(PlusText).withConfig({
  displayName: "OurOriginationApproach__StrategicPartnerships",
  componentId: "sc-1dymjgy-18"
})(["", ""], media.desktop`
    top: 95px; 
    left: 0; 
    right: 0;
  `);
const YieldStreetOriginated = styled(PlusText).withConfig({
  displayName: "OurOriginationApproach__YieldStreetOriginated",
  componentId: "sc-1dymjgy-19"
})(["", " height:45px;"], media.desktop`
    top: 0; 
    left: 90px; 
    bottom: 0; 
  `);
const JointVentures = styled(PlusText).withConfig({
  displayName: "OurOriginationApproach__JointVentures",
  componentId: "sc-1dymjgy-20"
})(["", " height:45px;"], media.desktop`
    top: 0; 
    bottom: 0; 
    right: 80px;
    width: 80px;
  `);
const MandA = styled(PlusText).withConfig({
  displayName: "OurOriginationApproach__MandA",
  componentId: "sc-1dymjgy-21"
})(["", ""], media.desktop`
    left: 0; 
    bottom: 95px; 
    right: 0;
    p {
     text-align: center;
    }
  `);