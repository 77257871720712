import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["buttonType", "children", "closeIcon", "customIcon", "customIconHeight", "customIconWidth", "inverse", "place", "clickable", "tooltipKey"];
var __jsx = React.createElement;
import React, { useState, useRef, useEffect } from 'react';
import ReactTooltip from 'react-tooltip';
import closeIconImg from '../../assets/graphics/icons/closeGrey.svg';
import { useOutsideClick } from '../../hooks/use-outside-click';
import tooltipDefault from './assets/tooltip-default.svg';
import tooltipActive from './assets/tooltip-active.svg';
import { useTestIds } from './Tooltip.model';
import { CloseIcon, StyledReactTooltip, TooltipContent, TooltipDiv } from './Tooltip.style';
export const Tooltip = _ref => {
  let {
      buttonType = 'submit',
      children,
      closeIcon,
      customIcon,
      customIconHeight,
      customIconWidth,
      inverse,
      place,
      clickable = true,
      tooltipKey
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  const {
    0: isShowing,
    1: setShowing
  } = useState(false);
  const {
    0: hasLoaded,
    1: setHasLoaded
  } = useState(false);
  const wrapperRef = useRef(null);
  const tooltipRef = useRef(null);
  useOutsideClick(wrapperRef, setShowing);
  useEffect(() => {
    setHasLoaded(true);
  }, []);
  const handleClose = () => {
    if (tooltipRef !== null && tooltipRef !== void 0 && tooltipRef.current && 'tooltipRef' in tooltipRef.current) {
      tooltipRef.current.tooltipRef = null;
    }
    ReactTooltip.hide();
  };
  useEffect(() => {
    window.addEventListener('scroll', handleClose);
    return () => {
      window.removeEventListener('scroll', handleClose);
    };
  }, []);
  let iconSrc = isShowing ? tooltipActive : tooltipDefault;
  if (customIcon) {
    iconSrc = customIcon;
  }
  const testIds = useTestIds(props);
  return __jsx(TooltipDiv, _extends({
    "data-for": tooltipKey,
    "data-tip": true,
    onClick: e => closeIcon ? e.preventDefault() : {},
    ref: wrapperRef,
    type: buttonType
  }, testIds.base.attr), __jsx("img", {
    "data-js-tooltip-icon": true,
    alt: "tooltip",
    onClick: () => {
      setShowing(true);
    },
    width: !customIcon ? '15' : customIconWidth,
    height: !customIcon ? '15' : customIconHeight,
    src: iconSrc
  }), hasLoaded && __jsx(StyledReactTooltip, {
    afterHide: () => setShowing(false),
    afterShow: () => setShowing(true),
    border: true,
    clickable: clickable,
    delayHide: 100,
    delayShow: 100,
    delayUpdate: 100,
    effect: "solid",
    id: tooltipKey,
    inverse: inverse,
    place: place,
    ref: tooltipRef,
    type: "light",
    getContent: () => __jsx(TooltipContent, testIds.content.attr, children, closeIcon && __jsx(TooltipDiv, {
      onClick: handleClose
    }, __jsx(CloseIcon, {
      src: closeIconImg
    })))
  }));
};