import update from 'immutability-helper';
import * as actions from 'store/actions/portfolioActivity';
export const initialState = {
  unreadMessages: {}
};
const portfolioActivity = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case actions.SET_ACTIVITY_NOTIFICATION_COUNT:
      {
        return update(state, {
          unreadMessages: {
            $set: action.unreadMessages
          }
        });
      }
    default:
      return state;
  }
};
export default portfolioActivity;