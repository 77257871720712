var __jsx = React.createElement;
import React from 'react';
import { Heading } from '../../content';
import whiteArrow from '../../assets/graphics/icons/white-arrow.svg';
import { Arrow, Container, Image, Title } from './BeanBox.style';
export const BeanBox = _ref => {
  let {
    id,
    title,
    img,
    onClick,
    testId
  } = _ref;
  return __jsx(Container, {
    onClick: onClick,
    "data-cy": `${testId !== null && testId !== void 0 ? testId : ''}-bean-${id !== null && id !== void 0 ? id : 'box'}`
  }, __jsx(Title, null, __jsx(Heading, {
    inverse: true,
    type: 5
  }, title)), __jsx(Arrow, {
    src: whiteArrow
  }), __jsx(Image, {
    src: img
  }));
};