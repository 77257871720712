import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { getAudiencePresetValues } from '@yieldstreet/platform-kit';
import { userApi } from '@yieldstreet/platform-kit';
import { useAuth } from '@yieldstreet/tool-kit';
import { cookiesGet } from 'redux-cookies';
import { resolvePresetAudiences } from 'store/actions/audiences';
import { setUserState, setAnalyticsUserId } from 'store/actions/user';
import { setSegmentIdentify } from 'utils/thirdPartyCode';
import { getUserAnalytics } from 'utils/analytics';
import { useServerPreload } from "utils/hooks";
import { datadogRum } from '@datadog/browser-rum';
const serverPreload = _ref => {
  let {
    cookiesGet,
    getUserState
  } = _ref;
  const authToken = cookiesGet('Auth-Token');
  if (authToken) {
    return getUserState().unwrap().then(setUserState);
  } else {
    return Promise.resolve();
  }
};
const CheckUserState = _ref2 => {
  let {
    cookiesGet,
    getUserState,
    audiences,
    audiencesFetched,
    setUserState,
    setAnalyticsUserId,
    resolvePresetAudiences
  } = _ref2;
  const {
    userState,
    userContact
  } = useAuth();
  useServerPreload('ArticlesListContainerPage', async () => {
    return serverPreload({
      cookiesGet,
      getUserState
    });
  });
  useEffect(() => {
    setUserState(userState);
  }, [userState, setUserState]);
  useEffect(() => {
    if (userState && userState.loggedIn) {
      setAnalyticsUserId(userState.hashedId);
      resolvePresetAudiences('analytics');
    }
  }, [userState, setAnalyticsUserId, resolvePresetAudiences]);
  useEffect(() => {
    if (audiencesFetched && audiences) {
      setSegmentIdentify(getAudiencePresetValues('analytics', audiences));
    }
  }, [audiences, audiencesFetched, resolvePresetAudiences]);
  useEffect(() => {
    if (userState !== null && userState !== void 0 && userState.loggedIn) {
      const {
        phoneRaw,
        address
      } = (userContact === null || userContact === void 0 ? void 0 : userContact.contact) || {};
      setSegmentIdentify(_objectSpread(_objectSpread({}, getUserAnalytics(userState)), {}, {
        phone: phoneRaw,
        address: address && {
          street: `${address.addressLine1 || ''} ${address.addressLine2 || ''}`.trim(),
          city: address.city,
          state: address.state,
          postalCode: address.postalCode,
          country: address.countryCode
        }
      }));
      datadogRum.setUser({
        id: userState.hashedId,
        name: userState.displayName,
        email: userState.emailAddress
      });
    }
  }, [userState, userContact]);
  return null;
};
const mapStateToProps = _ref3 => {
  let {
    user,
    audiences
  } = _ref3;
  return {
    audiences: audiences.data,
    audiencesFetched: audiences.fetched,
    userHeaders: user.userHeaders
  };
};
const mapDispatchToProps = dispatch => ({
  cookiesGet: cookie => dispatch(cookiesGet(cookie)),
  setAnalyticsUserId: userId => dispatch(setAnalyticsUserId(userId)),
  setUserState: userState => dispatch(setUserState(userState)),
  getUserState: () => dispatch(userApi.endpoints.getUserState.initiate()),
  resolvePresetAudiences: preset => dispatch(resolvePresetAudiences(preset))
});
export default connect(mapStateToProps, mapDispatchToProps)(CheckUserState);