import styled, { css } from 'styled-components';
import { StatusTagInfo } from './StatusTag.model';
export const Tag = styled.div.withConfig({
  displayName: "StatusTagstyle__Tag",
  componentId: "sc-16pffsd-0"
})(["background:", ";padding:4px 8px;border-radius:4px;width:fit-content;"], props => StatusTagInfo(props.status).color);
const LabelStyleSmall = css(["font-size:10px;"]);
const LabelStyle = css(["font-size:12px;"]);
export const TagLabel = styled.label.withConfig({
  displayName: "StatusTagstyle__TagLabel",
  componentId: "sc-16pffsd-1"
})(["color:", " !important;font-weight:500;line-height:14px;", ""], props => props.theme.colors.text_secondary_paragraph, props => props !== null && props !== void 0 && props.small ? LabelStyleSmall : LabelStyle);
export const TagContent = styled.div.withConfig({
  displayName: "StatusTagstyle__TagContent",
  componentId: "sc-16pffsd-2"
})(["display:flex;align-items:center;column-gap:", ";"], _ref => {
  let {
    theme
  } = _ref;
  return theme.spacing.xs;
});
export const Icon = styled.img.withConfig({
  displayName: "StatusTagstyle__Icon",
  componentId: "sc-16pffsd-3"
})(["height:20px;width:20px;"]);