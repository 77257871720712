import { media } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
export const ButtonContainer = styled.div.withConfig({
  displayName: "ManagementFeeConsentVotestyle__ButtonContainer",
  componentId: "sc-kzg75s-0"
})(["display:flex;justify-content:center;"]);
export const ButtonWrapper = styled.div.withConfig({
  displayName: "ManagementFeeConsentVotestyle__ButtonWrapper",
  componentId: "sc-kzg75s-1"
})(["display:flex;flex-direction:column;margin-top:", ";gap:", ";width:100%;", ""], props => props.theme.spacing.m, props => props.theme.spacing.m, media.large`
    width: 400px;
  `);