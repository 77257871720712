import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useInvestorEntityAppActions } from '@yieldstreet/tool-kit';
import { PortfolioStrategyValues } from '@yieldstreet/platform-kit';
import { getInvestorEntityRoute, INVESTOR_ENTITY_ID_PARAM } from 'pages/investorEntity/utils';
import { INVESTOR_ENTITY_MNGT_BANK_ACT_PATH } from '../ManagementContainer.model';
import { BANK_FLOW_LINK_A_BANK_ACCOUNT } from '../../../../bankLinking/BankLinkingPageFlow.model';
export const useInvestorEntityActionHandler = () => {
  const history = useHistory();
  const handleOnActionClick = useCallback(_ref => {
    let {
      action,
      link,
      investorEntityId
    } = _ref;
    if (action) {
      return action();
    }
    if (link) {
      /*
       * Will be cleaned up once we enable the feature flag, at that time `useInvestorEntityAction`
       * should be updated to pass the correct link to the VERIFY_MICRO_DEPOSITS action
       */
      if (link === '/management/bank-accounts') return history.push(INVESTOR_ENTITY_MNGT_BANK_ACT_PATH.replace(INVESTOR_ENTITY_ID_PARAM, `${investorEntityId}`));
      return history.push(link);
    }
    return null;
  }, [history]);
  return {
    handleOnActionClick
  };
};
export const useInvestorEntityAction = _ref2 => {
  let {
    investorEntity,
    investorEntityStatus
  } = _ref2;
  const history = useHistory();
  const action = useCallback(() => {
    if (investorEntity !== null && investorEntity !== void 0 && investorEntity.id) {
      history.push(getInvestorEntityRoute(`${investorEntity.id}`, BANK_FLOW_LINK_A_BANK_ACCOUNT));
    }
  }, [history, investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.id]);
  const nextAction = useInvestorEntityAppActions({
    action,
    investorEntity,
    investorEntityStatus,
    managementStrategy: PortfolioStrategyValues.Self
  });
  return {
    nextAction
  };
};