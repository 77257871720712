import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["children", "small", "htmlFor", "inverse"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { Label } from './Label';
export const TitleTag = _ref => {
  let {
      children,
      small,
      htmlFor,
      inverse
    } = _ref,
    rest = _objectWithoutProperties(_ref, _excluded);
  return __jsx(SLabel, _extends({}, rest, {
    small: small,
    htmlFor: htmlFor,
    inverse: inverse
  }), children);
};
const SLabel = styled(Label).withConfig({
  displayName: "TitleTag__SLabel",
  componentId: "sc-9fejqa-0"
})(["font-size:12px;text-transform:uppercase;letter-spacing:4px;"]);