var __jsx = React.createElement;
import React from 'react';
import { ExternalLink } from '../../content/link';
import CloseIcon from '../../assets/graphics/icons/close.svg';
import { CloseImg, Container, STypography, Wrapper } from './SurveyBox.style';
export const SurveyBox = _ref => {
  let {
    dismiss,
    linkText,
    onClick,
    path,
    text
  } = _ref;
  return __jsx(Wrapper, null, __jsx(Container, null, __jsx(STypography, {
    variant: "body1",
    inverse: true
  }, text), __jsx(ExternalLink, {
    inverse: true,
    href: path,
    onClick: onClick
  }, linkText), __jsx(CloseImg, {
    src: CloseIcon,
    onClick: dismiss
  })));
};