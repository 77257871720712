import { useMemo } from 'react';
import { getInvestorAccountId } from '@yieldstreet/tool-kit';
import { ALL_YEARS } from './usePortfolioYearsFilter.model';
import { getFilteredProviders } from '@yieldstreet/tool-kit/src/portfolio/useStatementsList/useStatementsList.util';
export const usePortfolioYearsFilter = _ref => {
  var _yearsOptions;
  let {
    investorId,
    query,
    userId,
    managementStrategy,
    hideAll = false
  } = _ref;
  const {
    data,
    isError,
    isFetching
  } = query({
    investorId: getInvestorAccountId(investorId, managementStrategy),
    managementStrategy,
    userId,
    providers: getFilteredProviders(managementStrategy)
  });
  const {
    years
  } = data || {};
  const hasYears = !isError && !isFetching && !!(years !== null && years !== void 0 && years.length);
  const yearsOptions = useMemo(() => {
    if (!hasYears) {
      return hideAll ? [] : [ALL_YEARS];
    }
    const availableYears = years.map(year => ({
      id: year,
      label: year,
      value: year
    }));

    /**
     * Not all years are returned in the same order.
     * This ensures they are sorted in descending order,
     * showing the most recent years at the top/first.
     */
    const sortedYears = availableYears.sort((a, b) => b.value - a.value);
    if (hideAll) {
      return sortedYears;
    }
    return [ALL_YEARS, ...sortedYears];
  }, [hasYears, years, hideAll]);
  return {
    hasYears,
    mostRecentYear: yearsOptions === null || yearsOptions === void 0 ? void 0 : (_yearsOptions = yearsOptions[hideAll ? 0 : 1]) === null || _yearsOptions === void 0 ? void 0 : _yearsOptions.value,
    yearsOptions,
    isFetchingYears: isFetching
  };
};