var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { media } from '../../themes/media';
import { TextBlock } from '../../sections/text-block-component';
import { Container } from '../../layout/container/Container';
import { MarketingContainer } from '../../layout';
import { theme2 } from '../../themes';
const getBgType = background => {
  switch (background) {
    case 'primary':
      return theme2.gradients.radial_primary;
    case 'secondary':
      return theme2.gradients.radial_secondary;
    default:
      return 'transparent';
  }
};
export const MarketingContentBannerCarousel = _ref => {
  let {
    carousel,
    reverse,
    inverse,
    label,
    heading,
    content,
    button,
    mobileAlign = 'start',
    background,
    stats
  } = _ref;
  return __jsx(MarketingContainer, null, __jsx(Wrapper, null, __jsx(Background, {
    background: background,
    reverse: reverse,
    inverse: inverse
  }, __jsx(CarouselContainer, {
    reverse: reverse
  }, carousel), __jsx(TextContainer, null, __jsx(TextBlock, {
    label: label,
    heading: heading,
    headingType: 2,
    content: content,
    align: "start",
    mobileAlign: mobileAlign,
    inverse: !inverse,
    button: button,
    stats: stats
  })))));
};
const Wrapper = styled(Container).withConfig({
  displayName: "MarketingContentBannerCarousel__Wrapper",
  componentId: "sc-yofret-0"
})(["display:flex;flex-direction:column;overflow:hidden;padding-bottom:40px;padding-top:40px;position:relative;"]);
const Background = styled.div.withConfig({
  displayName: "MarketingContentBannerCarousel__Background",
  componentId: "sc-yofret-1"
})(["display:flex;flex-direction:column;background:", ";padding:20px;", " position:relative;"], props => props.inverse ? props.theme.colors.background_light : getBgType(props.background), media.large`
    flex-direction: ${props => props.reverse ? 'row-reverse' : 'row'};
    border-radius: 8px;
    align-items: center;
    min-height: 370px;
    padding: 72px 64px;
  `);
const TextContainer = styled.div.withConfig({
  displayName: "MarketingContentBannerCarousel__TextContainer",
  componentId: "sc-yofret-2"
})(["padding:0px 24px 0 24px;", ""], media.large`
    width: 375px;
    padding: 0px;
  `);
const CarouselContainer = styled.div.withConfig({
  displayName: "MarketingContentBannerCarousel__CarouselContainer",
  componentId: "sc-yofret-3"
})(["left:0;position:relative;top:-40px;z-index:100;", ""], media.large`
    left: ${props => props.reverse ? '12%' : '50%'};
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
  `);