import { DocumentType } from '../../enums';
export const PortfolioInvestmentDocuments = {
  documents: [{
    createDate: 1614142800000,
    createDateString: '2021-02-24 17:35:12.0',
    docType: DocumentType.subscriptionAgreement,
    docTypeDescription: 'Subscription Agreement',
    id: 510155,
    name: 'Subscription_Agreement_Signed.pdf',
    notes: 'Subscription Agreement',
    url: 'https://abc.123',
    urlHash: 'bNzyJQ'
  }, {
    createDate: 1615179600000,
    createDateString: '2021-03-08 20:25:40.0',
    docType: DocumentType.other,
    docTypeDescription: 'Other',
    id: 516434,
    name: 'Cupom McDonalds.pdf',
    notes: 'Test',
    url: 'http://abc.123',
    urlHash: 'bNzyJQ'
  }]
};