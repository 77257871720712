var __jsx = React.createElement;
import React from 'react';
import { OuterContainer, Label, TabButton } from './SwitchSelector.style';
export const SwitchSelector = _ref => {
  let {
    activeKey,
    onClick,
    tabs
  } = _ref;
  return __jsx(OuterContainer, null, tabs === null || tabs === void 0 ? void 0 : tabs.map((tab, key) => __jsx(TabButton, {
    key: key,
    active: tab.key === activeKey,
    onClick: () => {
      onClick && onClick(tab);
    }
  }, tab.icon ? tab.icon : '', tab.title ? __jsx(Label, {
    semiBold: true,
    variant: "body2"
  }, tab.title) : '')));
};