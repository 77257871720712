import { InvestorEntityProvider } from '@yieldstreet/platform-kit';
export const PROVIDER_CONFIG = {
  [InvestorEntityProvider.ETC]: {
    company: 'Equity Trust',
    platform: 'myEQUITY'
  }
};
export const IRA_FUNDING_INSTRUCTIONS = `We will automatically request a wire transfer from your retirement account to fund your investment. This should take ~1-2 business days if the funds are available in your account and you have a verified accreditation.`;
export const IRA_PURCHASE_FAILED = 'The purchase request we sent to {{iraprovider}} for your investment failed. Usually, this is due to insufficient funds. Please re-check your {{iraprovider}} balance and let us know when sufficient funds have arrived. We will retry the request at that time.';
export const getIRAFundingInstructions = () => {
  return IRA_FUNDING_INSTRUCTIONS;
};
export const getIRAPurchaseFailed = investorEntity => {
  var _investorEntity$provi, _PROVIDER_CONFIG$prov;
  const provider = investorEntity === null || investorEntity === void 0 ? void 0 : (_investorEntity$provi = investorEntity.provider) === null || _investorEntity$provi === void 0 ? void 0 : _investorEntity$provi.type;
  const providerCompany = (_PROVIDER_CONFIG$prov = PROVIDER_CONFIG[provider]) === null || _PROVIDER_CONFIG$prov === void 0 ? void 0 : _PROVIDER_CONFIG$prov.company;
  if (providerCompany) {
    return IRA_PURCHASE_FAILED.replace(/{{iraprovider}}/g, providerCompany);
  }
  return;
};