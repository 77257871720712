import _Stack from "@mui/material/Stack";
import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var __jsx = React.createElement;
import React from 'react';
import { InformationBlock } from '../InformationBlock';
import { testIds } from './RouteDetails.model';
export const RouteDetails = _ref => {
  let {
    headingTitle,
    fullWidth,
    children
  } = _ref;
  return __jsx(_Stack, {
    sx: _objectSpread({
      textAlign: 'left'
    }, fullWidth && {
      width: '100%'
    })
  }, __jsx(_Typography, _extends({
    sx: {
      display: {
        xs: 'none',
        lg: 'block'
      }
    },
    variant: "h4"
  }, testIds.header.attr), headingTitle), children && __jsx(InformationBlock, null, children));
};