import _Chip from "@mui/material/Chip";
var __jsx = React.createElement;
import React from 'react';
import { PortfolioCardTitle, PortfolioCardHeader, PortfolioCardTitleLoader, PortfolioCardValueLoader } from 'pages/portfolio/common/PortfolioCard';
import { toUsCurrency } from 'utils/currency-formatters';
import { PaymentStatusColor } from '../../../TransactionsListData.model';
import { handlePaymentStatusLabel } from 'pages/portfolio/Transactions/TransactionsList/TransactionsList.utils';
export const TransactionsListCardHeader = _ref => {
  let {
    transaction,
    isFetching
  } = _ref;
  if (isFetching) {
    __jsx(PortfolioCardHeader, null, __jsx(React.Fragment, null, __jsx(PortfolioCardTitleLoader, {
      variant: "text"
    }), __jsx(PortfolioCardValueLoader, {
      variant: "text"
    })));
  }
  return __jsx(PortfolioCardHeader, null, __jsx(PortfolioCardTitle, null, transaction !== null && transaction !== void 0 && transaction.totalAmount ? toUsCurrency(transaction.totalAmount) : '-'), transaction !== null && transaction !== void 0 && transaction.status ? __jsx(_Chip, {
    label: handlePaymentStatusLabel(transaction.status),
    color: PaymentStatusColor[transaction.status]
  }) : null);
};