export const recurringPayment = {
  id: 569,
  amount: 1000,
  bankAccountId: 91094,
  basePaymentId: 1410627,
  createDt: '2023-12-07T18:47:07Z',
  displayName: 'John Doe',
  displayStartDate: '2020-11-19',
  investorAccountId: 201794,
  investorAccountName: 'John Doe',
  sourceBankAccount: 'CAPITAL ONE  N A ',
  externalAccountName: 'SynapsePay Test Checking Account - 8901',
  startDate: '2023-12-07T18:47:07Z',
  userId: 220951,
  lastPaymentDate: null,
  lastWarningDate: null
};