import styled from 'styled-components';
import { QuestionSecondaryCheckboxGroupInput, H5, media } from '@yieldstreet/ui-kit';
export const StyledGroup = styled.div.withConfig({
  displayName: "KYCstyle__StyledGroup",
  componentId: "sc-1mtq110-0"
})(["display:flex;flex-direction:column;.MuiFormGroup-root{row-gap:20px;width:100%;.MuiFormControl-root{background-color:", ";border-radius:4px;box-shadow:", ";.MuiButtonBase-root{display:none;}.MuiTypography-root{", ";}:hover{", "}.MuiFormControlLabel-root{padding:10px 30px 10px 10px;:hover{", ";}", ";}}.MuiFormControl-root[data-checked='true']{background-color:", ";.MuiTypography-root{color:", " !important;}.MuiButtonBase-root{display:initial;}.MuiFormControlLabel-root{:hover{", ";}", ";}}}"], _ref => {
  let {
    theme
  } = _ref;
  return theme.colors.background_card;
}, _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.shadows.shadow_default;
}, H5, media.desktop`
          .MuiTypography-root {
            color: ${_ref3 => {
  let {
    theme
  } = _ref3;
  return theme.colors.text_paragraph_inverse;
}}!important;
          }

          background-color: ${_ref4 => {
  let {
    theme
  } = _ref4;
  return theme.colors.background_inverse;
}};
          cursor: pointer;
        `, media.desktop`
            padding: 20px 30px 20px 40px;
          `, media.phoneLandscape`
          padding: 20px 30px 20px 40px;
        `, _ref5 => {
  let {
    theme
  } = _ref5;
  return theme.colors.background_inverse;
}, _ref6 => {
  let {
    theme
  } = _ref6;
  return theme.colors.text_paragraph_inverse;
}, media.desktop`
            padding: 20px 30px 20px 20px;
          `, media.phoneLandscape`
          padding: 20px 30px 20px 20px;
        `);
export const SFormQuestionSecondaryCheckboxGroupInput = styled(QuestionSecondaryCheckboxGroupInput).withConfig({
  displayName: "KYCstyle__SFormQuestionSecondaryCheckboxGroupInput",
  componentId: "sc-1mtq110-1"
})([".MuiFormControlLabel-root{:hover{", "}}"], media.desktop`
    .MuiTypography-root {
      & > div{
        color: ${_ref7 => {
  let {
    theme
  } = _ref7;
  return theme.colors.text_paragraph_inverse;
}}!important;
      }
    }

    background-color: ${_ref8 => {
  let {
    theme
  } = _ref8;
  return theme.colors.background_inverse;
}};
    cursor: pointer;
  `);