var __jsx = React.createElement;
import React from 'react';
import { MarketingContainer } from '../../layout';
import { TextBlock } from '../../sections/text-block-component';
import { Wrapper, CarouselContainer, TextWrapper } from './MarketingSideCarouselContent.styles';
export const MarketingSideCarouselContent = _ref => {
  let {
    carousel,
    reverse,
    label,
    heading,
    content,
    button,
    mobileAlign = 'start'
  } = _ref;
  return __jsx(MarketingContainer, null, __jsx(Wrapper, {
    reverse: reverse
  }, __jsx(CarouselContainer, null, carousel), __jsx(TextWrapper, null, __jsx(TextBlock, {
    label: label,
    heading: heading,
    headingType: 2,
    content: content,
    align: "start",
    mobileAlign: mobileAlign,
    button: button
  }))));
};