import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { quizApi } from '@yieldstreet/platform-kit';
import { v4 as uuidv4 } from 'uuid';
import { parseBulkPreferences } from 'pages/accountSettings/advice/utils/advicePreferences.js';
import { useQuizState } from '../useQuizState/';
import { formatAnswers } from './formatAnswers';
import { useUserState } from 'utils/hooks';

/**
 * A dryer version of `useQuiz`. This hook also uses the RTK `quizApi`, hence the `Api` suffix on the hook name.
 */
const useQuizApi = (quizKey, questionKeys) => {
  const quizId = useMemo(() => uuidv4(), []);
  const {
    id: userId = null
  } = useUserState();
  const {
    analyticsAnonymousId: anonymousUserId = null
  } = useSelector(_ref => {
    let {
      user
    } = _ref;
    return user;
  });
  const {
    quizAnswers,
    setQuizAnswer,
    isFetchingQuizAnswers
  } = useQuizState({
    questionKeys,
    quizKey,
    userId
  });
  const [postAnswers, {
    error: postAnswersError
  }] = quizApi.usePostAnswersMutation();
  const postQuizAnswers = useCallback(async () => {
    const formValues = Object.keys(quizAnswers).reduce((obj, questionKey) => {
      var _quizAnswers$question;
      return _objectSpread(_objectSpread({}, obj), {}, {
        [questionKey]: formatAnswers(quizAnswers === null || quizAnswers === void 0 ? void 0 : (_quizAnswers$question = quizAnswers[questionKey]) === null || _quizAnswers$question === void 0 ? void 0 : _quizAnswers$question.answers)
      });
    }, {});
    const parsedAnswers = parseBulkPreferences({
      quizId,
      userId,
      anonymousUserId,
      formAnswers: formValues
    });
    await postAnswers({
      answers: parsedAnswers
    });
  }, [postAnswers, quizAnswers, userId, anonymousUserId, quizId]);
  const getQuizAnswer = useCallback((questionKey, multipleValues, defaultValue) => {
    const question = quizAnswers === null || quizAnswers === void 0 ? void 0 : quizAnswers[questionKey];
    if (question !== null && question !== void 0 && question.answers) {
      const answers = question.answers;
      return multipleValues ? answers : answers[0];
    } else {
      return defaultValue;
    }
  }, [quizAnswers]);
  return {
    getQuizAnswer,
    quizAnswers,
    setQuizAnswer,
    postQuizAnswers,
    postAnswersError,
    isFetchingQuizAnswers
  };
};
export { useQuizApi };