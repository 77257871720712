export const CAMPAIGN_PATH = '/campaign';
export const FUND_ID_PARAM = ':fundId';
export const CAMPAIGN_FUND = `/campaign/fund/${FUND_ID_PARAM}`;
export const CampaignStatusPage = {
  StartUp: 0,
  Question1: 1,
  Question2: 2,
  Question3: 3,
  Question4: 4,
  Submitted: 5,
  Done: 6,
  NotEligible: 7
};
export const PagesWithBackNavigation = {
  [CampaignStatusPage.StartUp]: false,
  [CampaignStatusPage.Question1]: true,
  [CampaignStatusPage.Question2]: true,
  [CampaignStatusPage.Question3]: true,
  [CampaignStatusPage.Question4]: true,
  [CampaignStatusPage.Submitted]: false,
  [CampaignStatusPage.Done]: false,
  [CampaignStatusPage.NotEligible]: false
};