import _Box from "@mui/material/Box";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { useHistory } from 'react-router-dom';
import { PromoPopOver } from '@yieldstreet/ui-kit';
import { usePlatformPromo } from '../../hooks/usePlatformPromo';
export const PopOver = props => {
  const history = useHistory();
  const {
    displayedMessage,
    trackAction,
    dismiss
  } = usePlatformPromo(props);
  const redirectPath = displayedMessage.configuration.redirectPath || '/management/accounts';
  return __jsx(_Box, {
    sx: {
      mb: 4
    }
  }, __jsx(PromoPopOver, _extends({}, displayedMessage.configuration, {
    onClick: () => {
      trackAction('ClickCTA');
      history.push(redirectPath);
    },
    onClose: dismiss
  })));
};