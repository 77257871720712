import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var __jsx = React.createElement;
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, Redirect, useLocation } from 'react-router-dom';
import { useModal } from '@yieldstreet/ui-kit';
import { offeringApi, assetClassesApi, marketplaceMetricsApi, OfferingStatus } from '@yieldstreet/platform-kit';
import { setSegmentTrack } from 'utils/thirdPartyCode';
import { Modals } from 'constants/modal.model';
import Offering from './Offering';
const OfferingContainer = _ref => {
  let {
    urlHash,
    SEOTitle,
    rollover
  } = _ref;
  const {
    search
  } = useLocation();
  const history = useHistory(); // FIXME Remove this...
  const {
    useDetailsQuery
  } = offeringApi;
  const {
    useGetAssetClassesQuery
  } = assetClassesApi;
  const {
    useLazyGetSingleOfferingMetricsQuery
  } = marketplaceMetricsApi;
  const {
    isOpen: isOpenRollover
  } = useModal(Modals.Rollover);
  const {
    isOpen: isOpenAdvice
  } = useModal(Modals.Advice);
  const {
    isOpen: isOpenOfferingModal
  } = useModal(Modals.OfferingModal);
  const isModalView = isOpenRollover || isOpenAdvice || isOpenOfferingModal;
  const {
    offering,
    isOfferingLoading,
    isLoadingError
  } = useDetailsQuery([urlHash], {
    refetchOnMountOrArgChange: true,
    selectFromResult: _ref2 => {
      var _data$urlHash;
      let {
        data,
        isLoading,
        isUninitialized,
        isError
      } = _ref2;
      return {
        data,
        offering: data === null || data === void 0 ? void 0 : (_data$urlHash = data[urlHash]) === null || _data$urlHash === void 0 ? void 0 : _data$urlHash.note,
        isOfferingLoading: isLoading || isUninitialized,
        isLoadingError: isError
      };
    }
  });
  const userState = useSelector(_ref3 => {
    let {
      user
    } = _ref3;
    return user === null || user === void 0 ? void 0 : user.userState;
  });
  const {
    marketplaceFilterUsed
  } = useSelector(_ref4 => {
    let {
      investmentRequest
    } = _ref4;
    return investmentRequest;
  });
  const {
    data: assetClasses
  } = useGetAssetClassesQuery(null);
  const [getSingleOfferingMetric, {
    data: offeringMetrics
  }] = useLazyGetSingleOfferingMetricsQuery();
  useEffect(() => {
    if (!offering) {
      return;
    }
    const excludedOfferingStatuses = [OfferingStatus.open, OfferingStatus.preoffering, OfferingStatus.pending];
    if (!excludedOfferingStatuses.includes(offering.status)) {
      getSingleOfferingMetric(offering.urlHash);
    }
    setSegmentTrack({
      event: 'Product Viewed',
      properties: _objectSpread({
        urlHash: offering.urlHash,
        title: offering.title,
        accredited: userState.accreditedSignUp
      }, marketplaceFilterUsed)
    });
  }, [userState, offering, getSingleOfferingMetric, marketplaceFilterUsed]);
  if (!isModalView) {
    /**
     * Redirect if the user tries to enter inside the offering without the SEO title.
     */
    if (offering && !SEOTitle) {
      return __jsx(Redirect, {
        to: `/offering/${offering.urlHash}/${offering.SEOTitle}/${search}`
      });
    }
    const isUnsupportedOffering = (offering === null || offering === void 0 ? void 0 : offering.status) === OfferingStatus.deleted || offering && offering.version !== 5;

    /**
     * Redirect if the offering is delete or not v5
     */
    if ((offering === null || offering === void 0 ? void 0 : offering.reserved) !== true && isUnsupportedOffering) {
      return __jsx(Redirect, {
        to: `/offerings/`
      });
    }
  }
  const props = {
    offering,
    isOfferingLoading,
    userState,
    assetClasses,
    metrics: offeringMetrics,
    history,
    isModalView,
    isLoadingError,
    rollover
  };
  return __jsx(Offering, props);
};
export default OfferingContainer;