import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useMemo } from 'react';
import forEach from "lodash/forEach";
import * as Yup from 'yup';
import { AdvisoryRelationship } from '@yieldstreet/tool-kit';
import { LegalEntityType } from '@yieldstreet/platform-kit';
import { COMPLETE_INVESTMENT_TYPE, COMPLETE_INVEST_CHECKBOXES, COMPLETE_INVEST_CHECKBOX_KEYS } from './useGetCheckboxes.model';
const getPrefix = prefix => prefix ? `${prefix}-` : '';
const extractCheckboxes = (urlHash, offeringList) => {
  var _offeringList$urlHash, _offeringList$urlHash2, _offeringList$urlHash3, _offeringList$urlHash4;
  if (!(offeringList !== null && offeringList !== void 0 && (_offeringList$urlHash = offeringList[urlHash]) !== null && _offeringList$urlHash !== void 0 && (_offeringList$urlHash2 = _offeringList$urlHash.note) !== null && _offeringList$urlHash2 !== void 0 && _offeringList$urlHash2.legalAcknowledgementConfigContent)) {
    return;
  }
  const checkboxes = JSON.parse((offeringList === null || offeringList === void 0 ? void 0 : (_offeringList$urlHash3 = offeringList[urlHash]) === null || _offeringList$urlHash3 === void 0 ? void 0 : (_offeringList$urlHash4 = _offeringList$urlHash3.note) === null || _offeringList$urlHash4 === void 0 ? void 0 : _offeringList$urlHash4.legalAcknowledgementConfigContent) || '{}');
  return checkboxes;
};
const getStaticValidation = (legalEntityType, prefix, isRolloverV2) => {
  const validationSchema = {
    [`${prefix}checkOperating`]: Yup.boolean().oneOf([true], 'This field is required.'),
    [`${prefix}checkSubscription`]: Yup.boolean().oneOf([true], 'This field is required.'),
    [`${prefix}checkRule501`]: Yup.boolean().oneOf([true], 'This field is required.')
  };
  const initialValues = {
    [`${prefix}checkOperating`]: false,
    [`${prefix}checkSubscription`]: false,
    [`${prefix}checkRule501`]: legalEntityType === LegalEntityType.FUND
  };
  if (isRolloverV2) {
    validationSchema.checkRollover = Yup.boolean().oneOf([true], 'This field is required.');
    initialValues.checkRollover = false;
  }
  return {
    validationSchema,
    initialValues
  };
};
const getDynamicValidation = (checkboxesJson, prefix) => {
  if (!checkboxesJson) return {};
  let validationSchema = {};
  let initialValues = {};
  forEach(checkboxesJson.checkboxes, checkboxObj => {
    /**
     * Map checkbox names into the schema
     */
    validationSchema = _objectSpread(_objectSpread({}, validationSchema), {}, {
      [`${prefix}${checkboxObj.name}`]: Yup.boolean().oneOf([true], 'This field is required.')
    });

    /**
     * Set initial values
     */
    initialValues = _objectSpread(_objectSpread({}, initialValues), {}, {
      [`${prefix}${checkboxObj.name}`]: !!checkboxObj.defaultChecked
    });
  });
  return {
    validationSchema,
    initialValues
  };
};
const getGeneralRequirements = (investmentType, accountEligibility) => {
  const checkboxes = [],
    validationSchema = {},
    initialValues = {};
  const isNonDiscretionary = investmentType === COMPLETE_INVESTMENT_TYPE.NON_DISCRETIONARY;
  if ((accountEligibility === null || accountEligibility === void 0 ? void 0 : accountEligibility.advisoryRelationship) === AdvisoryRelationship.ADVISORY_NO_ACK && !isNonDiscretionary) {
    checkboxes.push(...[COMPLETE_INVEST_CHECKBOXES[COMPLETE_INVEST_CHECKBOX_KEYS.ADVISORY_RELATIONSHIP]]);
  }
  if (isNonDiscretionary) {
    checkboxes.push(...[COMPLETE_INVEST_CHECKBOXES[COMPLETE_INVEST_CHECKBOX_KEYS.ADVISORY_AGREEMENT], COMPLETE_INVEST_CHECKBOXES[COMPLETE_INVEST_CHECKBOX_KEYS.BROCHURE_AND_CRS]]);
  }
  forEach(checkboxes, checkbox => {
    Object.assign(validationSchema, {
      [`${checkbox.name}`]: Yup.boolean().oneOf([true], 'This field is required.')
    });
    Object.assign(initialValues, {
      [`${checkbox.name}`]: false
    });
  });
  return {
    checkboxes,
    checkboxValidation: {
      validationSchema,
      initialValues
    }
  };
};
export const useGetCheckboxes = (offeringDetails, noteUrlHashes, investmentType, usePrefix, accountEligibility, isRolloverV2) => {
  return useMemo(() => {
    if (!offeringDetails || !noteUrlHashes) {
      return {};
    }
    let initialValues = {},
      validationSchema = {},
      checkboxes = {};
    forEach(noteUrlHashes, noteUrlHash => {
      var _offeringDetails$note, _offeringDetails$note2;
      const legalEntityType = (_offeringDetails$note = offeringDetails[noteUrlHash]) === null || _offeringDetails$note === void 0 ? void 0 : (_offeringDetails$note2 = _offeringDetails$note.note) === null || _offeringDetails$note2 === void 0 ? void 0 : _offeringDetails$note2.legalEntityType;
      const prefix = usePrefix ? getPrefix(noteUrlHash) : '';
      const unsanitizedCheckboxes = extractCheckboxes(noteUrlHash, offeringDetails);
      const unsanitizedSchema = unsanitizedCheckboxes ? getDynamicValidation(unsanitizedCheckboxes, prefix) : getStaticValidation(legalEntityType, prefix, isRolloverV2);
      Object.assign(checkboxes, {
        [noteUrlHash]: unsanitizedCheckboxes
      });
      Object.assign(initialValues, unsanitizedSchema.initialValues);
      Object.assign(validationSchema, unsanitizedSchema.validationSchema);
    });
    const generalRequirements = getGeneralRequirements(investmentType, accountEligibility);
    if (generalRequirements) {
      var _generalRequirements$, _generalRequirements$2;
      Object.assign(initialValues, (_generalRequirements$ = generalRequirements.checkboxValidation) === null || _generalRequirements$ === void 0 ? void 0 : _generalRequirements$.initialValues);
      Object.assign(validationSchema, (_generalRequirements$2 = generalRequirements.checkboxValidation) === null || _generalRequirements$2 === void 0 ? void 0 : _generalRequirements$2.validationSchema);
    }
    return {
      externalCheckboxes: checkboxes,
      checkboxValidationSchema: {
        initialValues,
        validationSchema
      },
      generalCheckboxes: generalRequirements.checkboxes
    };
  }, [offeringDetails, noteUrlHashes, usePrefix, investmentType, accountEligibility]);
};