import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { configureStore as configureReduxStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { anonymizeObject, graphqlApi, restApi } from '@yieldstreet/platform-kit';
import { Tracer, traceMiddleware } from '@yieldstreet/tool-kit';
import Cookies from 'cookies'; // used for server-side
import CookiesJS from 'cookies-js'; // used for client-side
import { combineReducers } from 'redux';
import { getCookiesMiddleware } from 'redux-cookies';
import customApiMiddleware from 'store/middleware/Middleware';
import portfolio, { portfolioAnonymizer } from 'store/modules/portfolio';
import investorAccounts, { accountsAnonymizer } from 'store/reducers/accounts';
import articles from 'store/reducers/articles';
import assetClasses from 'store/reducers/assetClasses';
import audiences from 'store/reducers/audiences';
import bank, { bankAnonymizer } from 'store/reducers/bank';
import categoryFilter from 'store/reducers/categoryFilter';
import expirationTimer from 'store/reducers/expirationTimer';
import flags from 'store/reducers/flags';
import fundDistributionOption from 'store/reducers/fundDistributionOption';
import glossary from 'store/reducers/glossary';
import investmentPayment from 'store/reducers/investmentPayment';
import investmentRequest, { investmentRequestAnonymizer } from 'store/reducers/investmentRequest';
import investments from 'store/reducers/investments';
import invitations from 'store/reducers/invitations';
import ira from 'store/reducers/ira';
import location from 'store/reducers/location';
import marketplace from 'store/reducers/marketplace';
import metrics from 'store/reducers/metrics';
import offeringDetails from 'store/reducers/offeringDetails';
import originator from 'store/reducers/originator';
import pastOfferings from 'store/reducers/pastOfferings';
import portfolioActivity from 'store/reducers/portfolioActivity';
import portfolioInvestmentDetails from 'store/reducers/portfolioInvestmentDetails';
import portfolioOverview from 'store/reducers/portfolioOverview';
import portfolioPaymentActivity from 'store/reducers/portfolioPaymentActivity';
import portfolioTransactions from 'store/reducers/portfolioTransactions';
import quarterlyReports from 'store/reducers/quarterlyReports';
import quiz from 'store/reducers/quiz';
import user, { userAnonymizer } from 'store/reducers/user';
import { isServer } from 'utils/env';
import { setAnalyticsIds } from 'utils/analytics/setAnalyticsIds';
import { apiReducers } from './api.reducers';
import { configApi } from './api.config';
export const initState = {
  configApi
};
const appReducer = combineReducers(_objectSpread({
  articles,
  assetClasses,
  audiences,
  bank,
  categoryFilter,
  expirationTimer,
  flags,
  fundDistributionOption,
  glossary,
  investmentPayment,
  investmentRequest,
  investments,
  investorAccounts,
  invitations,
  ira,
  location,
  marketplace,
  metrics,
  offeringDetails,
  originator,
  pastOfferings,
  portfolio,
  portfolioActivity,
  portfolioInvestmentDetails,
  portfolioOverview,
  portfolioPaymentActivity,
  portfolioTransactions,
  quarterlyReports,
  quiz,
  user
}, apiReducers));
export const RESET_STATE = 'ys/root/RESET_STATE';
export const resetState = () => dispatch => {
  dispatch({
    type: RESET_STATE
  });
  setAnalyticsIds(dispatch);
};
const reducer = (state, action) => {
  return appReducer(action.type === RESET_STATE ? initState : state, action);
};
const anonymizer = state => anonymizeObject(state, {
  user: userAnonymizer,
  bank: bankAnonymizer,
  investorAccounts: accountsAnonymizer,
  portfolio: portfolioAnonymizer,
  investmentRequest: investmentRequestAnonymizer
});
const reduxTracer = new Tracer('redux');
export const createCookiesMiddleware = function () {
  let request = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let response = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  // Library used to read/write cookies by SSR has 'httpOnly' as true by default
  // Causes issue that an HTTPS browser page cannot read cookies generated on the server-side
  // Changing default to false prevents these issues
  Cookies.Cookie.prototype.httpOnly = false;
  return getCookiesMiddleware(isServer ? new Cookies(request, response) : CookiesJS);
};
export const getMiddleware = (request, response, axiosInstance) => {
  return [restApi.middleware, graphqlApi.middleware, createCookiesMiddleware(request, response), customApiMiddleware(axiosInstance), traceMiddleware(reduxTracer, anonymizer)];
};
const configureStore = (initialState, request, response, axiosInstance) => {
  const store = configureReduxStore({
    reducer,
    preloadedState: initialState,
    middleware: getDefaultMiddleware => {
      const middleware = [...getDefaultMiddleware({
        // TODO make these true eventually
        serializableCheck: false,
        immutableCheck: false,
        thunk: {
          extraArgument: axiosInstance
        }
      }), ...getMiddleware(request, response, axiosInstance)];
      return middleware;
    }
  });
  setupListeners(store.dispatch);
  return store;
};
export default configureStore;