import _Stack from "@mui/material/Stack";
import _Box from "@mui/material/Box";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React, { useMemo } from 'react';
import { useInvestorEntityFlags } from '@yieldstreet/tool-kit';
import { PageLoader, ActionCell, ActionType, useResponsiveFriendly } from '@yieldstreet/ui-kit';
import { InvestorEntityStepVerificationStatus } from '@yieldstreet/platform-kit';
import { useRouteInvestorEntity } from 'pages/investorEntity/utils';
import { useInvestorEntityAction, useInvestorEntityActionHandler } from '../../utils';
import { InformationBlock, RouteDetails } from '../../components';
import { etcInfo, testIds } from './Information.model';
import { RouterLink } from '@yieldstreet/ui-kit/navigation';
export const Information = () => {
  var _investorEntityStatus2;
  const isDesktop = useResponsiveFriendly() === 'desktop';
  const {
    isLoading,
    investorEntity,
    investorEntityStatus
  } = useRouteInvestorEntity();
  const {
    isETCAccount
  } = useInvestorEntityFlags(investorEntity);
  const {
    nextAction
  } = useInvestorEntityAction({
    investorEntity,
    investorEntityStatus
  });
  const {
    handleOnActionClick
  } = useInvestorEntityActionHandler();
  const identityConfirmed = useMemo(() => (investorEntityStatus === null || investorEntityStatus === void 0 ? void 0 : investorEntityStatus.identity.status) === InvestorEntityStepVerificationStatus.CONFIRMED, [investorEntityStatus === null || investorEntityStatus === void 0 ? void 0 : investorEntityStatus.identity.status]);
  const isETCConfirmed = useMemo(() => {
    var _investorEntityStatus;
    return (investorEntityStatus === null || investorEntityStatus === void 0 ? void 0 : (_investorEntityStatus = investorEntityStatus.provider) === null || _investorEntityStatus === void 0 ? void 0 : _investorEntityStatus.status) === InvestorEntityStepVerificationStatus.CONFIRMED;
  }, [investorEntityStatus === null || investorEntityStatus === void 0 ? void 0 : (_investorEntityStatus2 = investorEntityStatus.provider) === null || _investorEntityStatus2 === void 0 ? void 0 : _investorEntityStatus2.status]);
  const entityAddress = useMemo(() => {
    return investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.address;
  }, [investorEntity]);
  const fullAddress = `${entityAddress === null || entityAddress === void 0 ? void 0 : entityAddress.addressLine1} ${entityAddress === null || entityAddress === void 0 ? void 0 : entityAddress.addressLine2}`.trim();
  if (isLoading) {
    return __jsx(PageLoader, {
      testId: testIds.pageLoader.id
    });
  }
  return __jsx(_Stack, {
    sx: {
      width: '100%',
      textAlign: 'left'
    }
  }, __jsx(RouteDetails, {
    headingTitle: "My information"
  }, __jsx(_Box, testIds.nameInfo.attr, __jsx(_Typography, {
    component: "span",
    variant: "body2"
  }, "Account Name"), __jsx(_Typography, _extends({
    variant: "body1"
  }, testIds.name.attr), investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.name)), !isETCAccount && __jsx(React.Fragment, null, entityAddress !== null && entityAddress !== void 0 && entityAddress.addressComplete ? __jsx(_Box, testIds.addressInfo.attr, __jsx(_Typography, {
    component: "span",
    variant: "body2"
  }, "Address"), __jsx(_Typography, _extends({
    variant: "body1"
  }, testIds.address.attr), `${fullAddress}, ${entityAddress === null || entityAddress === void 0 ? void 0 : entityAddress.city}, ${entityAddress === null || entityAddress === void 0 ? void 0 : entityAddress.state} - ${entityAddress === null || entityAddress === void 0 ? void 0 : entityAddress.postalCode}`)) : null)), __jsx(InformationBlock, null, __jsx(_Stack, {
    direction: "row",
    justifyContent: "space-between",
    alignItems: "center"
  }, __jsx(_Typography, {
    variant: "body1",
    sx: {
      fontWeight: 500
    }
  }, "Identity"), identityConfirmed ? __jsx(ActionCell, {
    testId: testIds.status.id,
    label: "Verified",
    type: ActionType.ActionSuccess,
    showIcon: true
  }) : nextAction && __jsx(ActionCell, {
    testId: testIds.status.id,
    label: nextAction.title,
    type: ActionType.ActionRequired,
    onClick: () => handleOnActionClick({
      action: nextAction === null || nextAction === void 0 ? void 0 : nextAction.action,
      link: nextAction === null || nextAction === void 0 ? void 0 : nextAction.link,
      investorEntityId: investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.id
    }),
    showIcon: true
  }))), isETCAccount && __jsx(InformationBlock, null, __jsx(_Stack, {
    direction: "row",
    justifyContent: "space-between",
    alignItems: "center"
  }, __jsx(_Stack, null, __jsx(_Typography, {
    variant: "body1",
    sx: {
      fontWeight: 500
    }
  }, "Third party"), !isETCConfirmed && isDesktop && __jsx(_Typography, {
    component: "span",
    variant: "body2"
  }, "To start investing, finish your setup with ETC")), isETCConfirmed ? __jsx(ActionCell, {
    testId: testIds.statusThirdParty.id,
    label: etcInfo.confirmed.label,
    type: ActionType.ActionSuccess,
    showIcon: true
  }) : __jsx(ActionCell, {
    testId: testIds.statusThirdParty.id,
    label: etcInfo.pending.label,
    type: ActionType.ActionRequired,
    onClick: () => window.open(etcInfo.pending.linkURL, '_blank'),
    showIcon: true
  }))), __jsx(_Box, {
    sx: {
      mt: 8
    }
  }, __jsx(_Typography, _extends({
    component: "span",
    variant: "body2"
  }, testIds.linkLabel.attr), "To edit your account information, please visit your user", ' ', __jsx(RouterLink, _extends({
    to: "/profile/user"
  }, testIds.link.attr), "Profile."))));
};