var __jsx = React.createElement;
import React from 'react';
import { ActionCell, ActionType, StatusTag } from '@yieldstreet/ui-kit';
import { InvestmentPerformanceStatus } from '@yieldstreet/platform-kit';
export const AccreditationCell = _ref => {
  let {
    accreditationAppAction,
    label,
    onClick,
    testId
  } = _ref;
  if (accreditationAppAction) {
    return __jsx(ActionCell, {
      label: label,
      onClick: onClick,
      showIcon: true,
      testId: testId,
      type: ActionType.ActionRequired
    });
  }
  return __jsx(StatusTag, {
    label: "Verified",
    showIcon: true,
    status: InvestmentPerformanceStatus.Active,
    testId: testId
  });
};