import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useMemo } from 'react';
import { InvestorEntityStepVerificationStatus } from '@yieldstreet/platform-kit';
import { useInvestorEntityNextAction, useLatestIncompleteInvestorEntity } from '../use-investor-entity';
import { AccountProgressContentMap, AccountProgressSteps } from './useAccountProgress.model';
export const useAccountProgress = () => {
  var _investorEntityStatus, _investorEntityStatus2;
  const {
    isLoading,
    investorEntity,
    investorEntityStatus
  } = useLatestIncompleteInvestorEntity();
  const accountAction = useInvestorEntityNextAction(investorEntity, investorEntityStatus);
  const isIdentityConfirmed = (investorEntityStatus === null || investorEntityStatus === void 0 ? void 0 : (_investorEntityStatus = investorEntityStatus.identity) === null || _investorEntityStatus === void 0 ? void 0 : _investorEntityStatus.status) === InvestorEntityStepVerificationStatus.CONFIRMED;
  const isBankAccountConfirmed = (investorEntityStatus === null || investorEntityStatus === void 0 ? void 0 : (_investorEntityStatus2 = investorEntityStatus.bankAccounts) === null || _investorEntityStatus2 === void 0 ? void 0 : _investorEntityStatus2.status) === InvestorEntityStepVerificationStatus.CONFIRMED;
  const accountProgressData = useMemo(() => [_objectSpread(_objectSpread({}, AccountProgressContentMap[AccountProgressSteps.ACCOUNT_CREATION]), {}, {
    isFinished: !!investorEntity,
    showCta: !investorEntity,
    // TODO: Note this needs to be dynamically retrieved from nextAction (self) once functionality is introduced
    action: {
      link: '/create-investor-account'
    }
  }), _objectSpread(_objectSpread({}, AccountProgressContentMap[AccountProgressSteps.IDENTITY]), {}, {
    isFinished: investorEntity && isIdentityConfirmed,
    showCta: investorEntity && !isIdentityConfirmed,
    action: accountAction
  }), _objectSpread(_objectSpread({}, AccountProgressContentMap[AccountProgressSteps.BANK]), {}, {
    isFinished: investorEntity && isIdentityConfirmed && isBankAccountConfirmed,
    showCta: investorEntity && isIdentityConfirmed && !isBankAccountConfirmed,
    action: accountAction
  }), _objectSpread(_objectSpread({}, AccountProgressContentMap[AccountProgressSteps.FIRST_INVESTMENT]), {}, {
    isFinished: false,
    showCta: investorEntity && isIdentityConfirmed && isBankAccountConfirmed,
    // TODO: Note this needs to be dynamically retrieved from nextAction (self) once functionality is introduced
    action: {
      link: '/offerings'
    }
  })], [investorEntity, isIdentityConfirmed, accountAction, isBankAccountConfirmed]);
  return {
    accountProgressData,
    accountProgressIsLoading: isLoading
  };
};