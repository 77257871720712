import _GlobalStyles from "@mui/material/GlobalStyles";
var __jsx = React.createElement;
import React, { useEffect } from 'react';
import { withTrustArc } from '@trustarc/trustarc-segment-wrapper';
import { TRUSTARC_CMP_SCRIPT } from './TrustArcConsent.model';
import useScript from '@charlietango/use-script';
import { BannerGlobalStyle } from './TrustArcConsent.style';
export const TrustArcConsentManager = _ref => {
  let {
    segmentWriteKey
  } = _ref;
  useScript(TRUSTARC_CMP_SCRIPT);
  useEffect(() => {
    window.analytics.load && withTrustArc(window.analytics, {
      alwaysLoadSegment: true
    }).load(segmentWriteKey);
  }, []);
  return __jsx(React.Fragment, null, __jsx(_GlobalStyles, {
    styles: BannerGlobalStyle
  }), __jsx("div", {
    id: "consent_blackbar"
  }));
};