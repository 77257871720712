var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';

/* Utilities */
import { media } from '../../themes/media';
/* Base styles */
import { Paragraph } from '../../content/text';

/* ============================
 *    AUM statistics section
 * ============================ */

export const AUMStatsSection = _ref => {
  let {
    listItems
  } = _ref;
  // const statsSmallDescriptionCopy = '(2018 vs 2019)';
  return __jsx(StatsContainer, null, __jsx(StatsList, null, listItems.map((item, index) => {
    /* first split the phrase at ':' to separate its title & description */
    const phraseList = item.split(':');
    return __jsx(StatsListItem, {
      key: index,
      inverse: true
    }, phraseList[1] && __jsx(React.Fragment, null, __jsx(StatsListItemTitle, null, phraseList[0] + ':'), __jsx("span", null, phraseList[1])), !phraseList[1] && __jsx("span", null, phraseList[0]));
  })));
};

/* ===== Layout UI elements ===== */

const StatsContainer = styled.section.withConfig({
  displayName: "AUMStats__StatsContainer",
  componentId: "sc-kbx4m5-0"
})(["display:block;box-sizing:border-box;", ""], media.desktop`
    flex-basis:50%;
    width:50%;
    padding-left:50px;
    padding-right:90px;
  `);

/* ===== Component UI elements ===== */

const StatsList = styled.ul.withConfig({
  displayName: "AUMStats__StatsList",
  componentId: "sc-kbx4m5-1"
})(["padding:0;margin-top:0;margin-bottom:20px;", ""], media.desktop`
    padding: 0 40px 0 0;
    margin-bottom:40px;
  `);

// const StatsDescriptionContainer = styled.div`
//   margin-bottom: 30px;
// `;

// const StatsGraphic = styled.div`
//   width: 100%;
//   height: 2px;
//   background-color: ${props => props.theme.colors.background_card};
//   position: relative;
//   &:before,
//   &:after {
//     content: '';
//     position: absolute;
//     top: 50%;
//     transform: translateY(-50%);
//     height: 20px;
//     width: 20px;
//     background-color: ${props => props.theme.colors.background_card};
//   }
//   &:before {
//     left: 0;
//   }
//   &:after {
//     right: 0;
//   }
// `;

/* ===== Type styles ===== */

// const StatsGraphTitle = styled(Paragraph)``;

// const StatsSmallDescription = styled(Paragraph)`
//   ${LabelStyle}
// `;

const StatsListItemTitle = styled.span.withConfig({
  displayName: "AUMStats__StatsListItemTitle",
  componentId: "sc-kbx4m5-2"
})(["font-weight:bold;"]);
const StatsListItem = styled(Paragraph).withConfig({
  displayName: "AUMStats__StatsListItem",
  componentId: "sc-kbx4m5-3"
})(["max-width:335px;margin-bottom:2px;", ""], media.desktop`
    max-width:none;
  `);