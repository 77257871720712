import { useLocation } from 'react-router-dom';
import { getHashParams, getHashValue, getPageParams } from './useRouteSegments.util';
export const useRouteSegments = () => {
  const location = useLocation();
  const {
    hashParams,
    hashParamsString
  } = getHashParams(location.hash);
  const {
    pageParams,
    pageParamsString
  } = getPageParams(location.search);
  const hashValue = getHashValue(location.hash);
  const pathname = location.pathname;
  return {
    hashParams,
    hashParamsString,
    hashValue,
    pageParams,
    pageParamsString,
    pathname
  };
};