var __jsx = React.createElement;
import React from 'react';
import { PageSheetTitleHeading } from './PageSheetTitle.style';
export const PageSheetTitle = _ref => {
  let {
    children
  } = _ref;
  return __jsx(PageSheetTitleHeading, {
    "data-cy": "page-sheet-title",
    type: 5
  }, children);
};