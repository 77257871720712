import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { media } from '../../themes/media';
import styled from 'styled-components';
import { Pagination, EffectCards, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-cards';
import 'swiper/css/navigation';
const cardsEffect = 'cards';
export const CardsCarousel = _ref => {
  let {
    className,
    children,
    initialSlide,
    slideChange,
    showMobileControls = false
  } = _ref;
  const props = {
    navigation: true,
    effect: cardsEffect,
    grabCursor: true,
    modules: [EffectCards, Navigation, Pagination],
    className: 'swiper3d',
    cardsEffect: {
      rotate: false
    },
    slideToClickedSlide: false,
    initialSlide: initialSlide,
    preventClicks: true,
    pagination: showMobileControls,
    showMobileControls
  };
  return __jsx(CarouselWrapper, {
    className: className
  }, __jsx(SSwiper, _extends({}, props, {
    className: "swiper",
    onActiveIndexChange: slide => slideChange && slideChange(slide.activeIndex)
  }), React.Children.map(children, child => {
    return __jsx(SwiperSlide, null, child);
  })));
};
const CarouselWrapper = styled.div.withConfig({
  displayName: "CardsCarousel__CarouselWrapper",
  componentId: "sc-1x3gn8h-0"
})(["min-height:398px;margin:0 auto;max-width:100% !important;position:relative;", ""], media.desktop`
    width: 470px;
  `);
const SSwiper = styled(Swiper).withConfig({
  displayName: "CardsCarousel__SSwiper",
  componentId: "sc-1x3gn8h-1"
})(["position:relative;top:0;margin-left:0;overflow:visible !important;perspective:2000px !important;width:336px;", " ", " .swiper-slide-shadow{background:none !important;}.swiper-button-disabled{display:none !important;}.swiper-button-prev,.swiper-button-next{display:", ";background-color:", ";border-radius:5px;box-shadow:", ";height:64px;top:47%;width:20px padding:", ";&:hover{box-shadow:", ";}&:after{color:", ";font-weight:bold;font-size:12px;position:absolute;}}.swiper-button-prev{left:", ";}.swiper-button-next{right:", ";}", " .swiper-slide{position:relative;}.swiper-slide:not(.swiper-slide-active){", "}.swiper-pagination{bottom:-20px !important;max-width:100%;}.swiper-pagination-bullets{opacity:1 !important;height:10px;}.swiper-pagination-bullet-active{background-color:", ";}"], media.desktop`
    width: 1600px !important;
  `, media.desktop`
    top: 0;
  `, props => props.showMobileControls ? 'flex' : 'none', props => props.theme.colors.background_card, props => props.theme.shadows.elevation_2, props => props.showMobileControls ? props.theme.spacing.s : '0', props => props.theme.shadows.shadow_hover, props => props.theme.colors.background_inverse, props => props.showMobileControls ? '-12px' : '3px', props => props.showMobileControls ? '-12px' : '3px', media.desktop`
    &:hover {
      .swiper-button-prev,
      .swiper-button-next {
        display: flex;
      }
    }
  `, media.desktop`
      filter: blur(2px);
    `, props => props.theme.colors.icon_default);