var __jsx = React.createElement;
import React from 'react';
import { ActionCellIcon } from '../ActionCell.style';
export const SuccessIcon = _ref => {
  let {
    color
  } = _ref;
  return __jsx(ActionCellIcon, {
    width: "20",
    height: "20",
    viewBox: "0 0 20 20",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, __jsx("path", {
    "fill-rule": "evenodd",
    "clip-rule": "evenodd",
    d: "M17.4114 6.90805L10.0388 15.3093L4.58594 9.85648L6.00015 8.44227L9.94356 12.3857L15.9081 5.58887L17.4114 6.90805Z",
    fill: color
  }));
};