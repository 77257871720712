export const ADVICE_ERROR_PATH = '/advice/error';
export const strings = {
  button: 'Retry investment',
  description: "We're sorry, but we encountered an error with your investment request. If you return to the recommendations screen, you will be able to view the latest recommendations there and restart the process.",
  title: 'There was an error with your investment request'
};
export const testIds = {
  button: 'advice-error-page-button',
  description: 'advice-error-page-description',
  title: 'advice-error-page-title'
};