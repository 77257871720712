import { HttpMethods } from '../../enums/http';
import { restApi, Tags } from '../common';
export const etcApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.EtcPurchaseRequest]
}).injectEndpoints({
  endpoints: builder => {
    const getInvestmentPurchaseRequests = builder.query({
      query: investmentId => ({
        url: `/a/api/etc/purchase-requests?investmentId=${investmentId}`,
        method: HttpMethods.Get
      }),
      providesTags: [Tags.EtcPurchaseRequest],
      transformResponse: response => {
        var _response$investments, _response$investments2;
        return response === null || response === void 0 ? void 0 : (_response$investments = response.investments) === null || _response$investments === void 0 ? void 0 : (_response$investments2 = _response$investments[0]) === null || _response$investments2 === void 0 ? void 0 : _response$investments2.requests;
      }
    });
    return {
      getInvestmentPurchaseRequests
    };
  }
});