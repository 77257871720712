import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["children", "small", "htmlFor"];
var __jsx = React.createElement;
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { LinkStyles } from '../link';
import { media } from '../../themes';
import { TextBaseStyle } from './TextBase';
export const Label = _ref => {
  let {
      children,
      small,
      htmlFor
    } = _ref,
    knobs = _objectWithoutProperties(_ref, _excluded);
  const tag = useMemo(() => {
    return htmlFor ? 'label' : 'span';
  }, [htmlFor]);
  if (small) {
    return __jsx(LabelSmall, _extends({
      htmlFor: htmlFor,
      as: tag
    }, knobs), children);
  }
  return __jsx(LabelElement, _extends({
    htmlFor: htmlFor,
    as: tag
  }, knobs), children);
};
const normal = css(["font-weight:normal;"]);
const semiBold = css(["font-weight:500;"]);
export const LabelBase = css(["margin:0;", ";font-family:", ";", " color:", " !important;fill:", " !important;a{", "}"], TextBaseStyle, props => props.theme.typography.primary, props => props.semiBold ? semiBold : normal, props => props.inverse ? props.theme.colors.text_label_inverse : props.theme.colors.text_label, props => props.inverse ? props.theme.colors.text_label_inverse : props.theme.colors.text_label, LinkStyles);
export const LabelStyle = css(["", ";font-size:14px;line-height:16px;"], LabelBase);
export const LabelStyleSmall = css(["", ";font-size:10px !important;line-height:12px;", ""], LabelBase, media.tablet`
    font-size: 12px !important;
    line-height: 14px;
  `);
const LabelElement = styled.label.withConfig({
  displayName: "Label__LabelElement",
  componentId: "sc-1n9idvm-0"
})(["", ""], LabelStyle);
const LabelSmall = styled.label.withConfig({
  displayName: "Label__LabelSmall",
  componentId: "sc-1n9idvm-1"
})(["", ""], LabelStyleSmall);