import WelcomeImg from './assets/welcome.png';
import BrowseImg from './assets/browse.png';
import InvestInFewClicks from './assets/invest-in-a-few-clicks.png';
import MonitorYourInvestments from './assets/monitor-your-investments.png';
import KnowledgeableSupport from './assets/knowledgeable-support.png';
export const ETC_PRODUCT_TOUR_MODAL = 'ira-guide-modal';
export const getSlides = function () {
  let providerPlatform = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return [{
    img: WelcomeImg,
    title: 'Welcome to Yieldstreet',
    description: 'Your myEQUITY account is now connected to Yieldstreet',
    config: {
      hideSliderDots: true,
      alignCentre: true
    }
  }, {
    img: BrowseImg,
    title: 'Browse IRA investments',
    description: 'Highly-vetted opportunities across 10 alternative asset classes are now at your fingertips.'
  }, {
    img: InvestInFewClicks,
    title: 'Invest in a few clicks',
    description: `Funding is automatically transferred from your ${providerPlatform} account. For a more seamless experience, ensure you have available funding on ${providerPlatform} before making an investment.`
  }, {
    img: MonitorYourInvestments,
    title: `Monitor your investments from ${providerPlatform} and Yieldstreet`,
    description: `Your investments will display in your ${providerPlatform} portfolio. For greater detail, check your Yieldstreet Dashboard.`
  }, {
    img: KnowledgeableSupport,
    title: 'Knowledgeable support when you need it',
    description: 'Our dedicated Investor Relations team at Yieldstreet is here to help every step of the way.'
  }];
};