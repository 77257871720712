export const createIntlCurrency = function () {
  let {
    currency = 'USD',
    language = 'en-US'
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return new Intl.NumberFormat(language, {
    style: 'currency',
    currency
  });
};
export const toUsCurrency = function () {
  let value = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  return createIntlCurrency().format(value);
};