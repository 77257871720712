import _objectDestructuringEmpty from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectDestructuringEmpty.js";
import map from 'lodash/map';
import { HttpMethods } from '../../enums';
import { SocketManagerReconnectionStrategy, websocketManager } from '../../websocket';
import { restApi, Tags } from '../common';
import { selectConfig } from '../config';
import { GetRecommendededOffersSourceType } from './marketplace.model';
import { getAudiencePresetRequest } from './helper';
export const marketplaceApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.Marketplace, Tags.MarketplacePaginatedOfferings, Tags.MarketplaceRecommendations, Tags.MarketplaceProgress, Tags.MarketplaceAudiences]
}).injectEndpoints({
  endpoints: builder => {
    const getOfferings = builder.query({
      query: _ref => {
        let {
          accreditation,
          offeringType,
          noteUrlHash
        } = _ref;
        return {
          url: `/a/api/marketplace/${offeringType}`,
          method: HttpMethods.Get,
          params: {
            accreditation,
            noteUrlHash
          }
        };
      },
      providesTags: [Tags.Marketplace]
    });
    const getPaginatedOfferings = builder.query({
      query: data => ({
        url: `/a/api/marketplace/offerings`,
        method: HttpMethods.Post,
        data
      }),
      providesTags: [Tags.MarketplacePaginatedOfferings]
    });
    const getRecommendations = builder.query({
      query: _ref2 => {
        let {
          userId,
          source = GetRecommendededOffersSourceType.Goals,
          limit
        } = _ref2;
        return {
          url: `/a/api/marketplace/recommendations/${userId}`,
          method: HttpMethods.Get,
          params: {
            source,
            limit
          }
        };
      },
      providesTags: [Tags.MarketplaceRecommendations],
      transformResponse: response => response.offerings
    });
    const getProgress = builder.query({
      queryFn: () => ({
        data: {}
      }),
      extraOptions: props => {
        const options = {
          cacheKey: props.key
        };
        return options;
      },
      providesTags: [Tags.MarketplaceProgress],
      async onCacheEntryAdded(request, _ref3) {
        let {
          cacheDataLoaded,
          cacheEntryRemoved,
          getState,
          updateCachedData
        } = _ref3;
        const {
          wssHost
        } = selectConfig(getState());
        const onMessage = (_, ev) => {
          const data = JSON.parse(ev.data);
          updateCachedData(draft => Object.assign(draft, {
            [data.noteUrlHash]: data.progress
          }));
        };
        const key = request.key;
        try {
          await cacheDataLoaded;
          const params = map(request.noteUrlHashes, urlHash => ({
            urlHash
          }));
          websocketManager.remove(key);
          websocketManager.add(key, {
            url: `${wssHost}/a/api/ws/marketplace/progress/:urlHash`,
            params,
            reconnectionStrategy: SocketManagerReconnectionStrategy.retry
          }, {
            onMessage
          });
          await cacheEntryRemoved;
        } finally {
          websocketManager.unsubscribe(key);
        }
      }
    });

    /**
     * This needs to be a query as it's get functionality wrapped around post due to data property.
     */
    const getResolveAudiences = builder.query({
      query: preset => {
        const payload = getAudiencePresetRequest(preset);
        return {
          url: '/a/api/marketplace/audiences/resolve',
          method: HttpMethods.Post,
          data: payload
        };
      }
    });
    const saveRecommendedOfferings = builder.mutation({
      query: _ref4 => {
        let data = Object.assign({}, (_objectDestructuringEmpty(_ref4), _ref4));
        return {
          url: `/a/api/marketplace/recommendations`,
          method: HttpMethods.Put,
          data
        };
      },
      invalidatesTags: () => [{
        type: Tags.MarketplaceRecommendations
      }]
    });
    return {
      getOfferings,
      getRecommendations,
      getProgress,
      getResolveAudiences,
      getPaginatedOfferings,
      saveRecommendedOfferings
    };
  }
});