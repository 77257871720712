import _Box from "@mui/material/Box";
var __jsx = React.createElement;
import React, { useCallback } from 'react';
import { NewModal, useModalContext } from '@yieldstreet/ui-kit';
import { PipeList } from '../PipeList/PipeList';
import { InfoModal, OFFERING_HERO_MODAL_ID } from '../InfoModal';
const TieredMetricTree = _ref => {
  let {
    dataCy,
    data,
    modalType
  } = _ref;
  const {
    showModal
  } = useModalContext();
  const modalKey = `${OFFERING_HERO_MODAL_ID}-${modalType}`;
  const handleInfoModal = useCallback(() => {
    showModal(modalKey, data);
  }, [showModal, data, modalKey]);
  return __jsx(_Box, {
    "data-cy": dataCy,
    className: "tiered-metric-tree-wrapper"
  }, data.tree && __jsx(PipeList, {
    dataCy: `${dataCy}-pipelist`,
    values: data.tree,
    openModal: handleInfoModal
  }), __jsx(NewModal, {
    modalId: modalKey
  }, __jsx(InfoModal, {
    tooltip: data === null || data === void 0 ? void 0 : data.params
  })));
};
export default TieredMetricTree;