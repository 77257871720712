const ADVICE_ANALYSIS_PATH = '/advice/analyze';

/** advice rive hook parameters */
const RiveParams = {
  StateMachineName: 'stages',
  TickerCompletedInputName: 'ticker_completed'
};
const AdviceAnalysisTestIds = {
  Wrapper: 'advice-analysis-wrapper',
  RiveWrapper: 'rive-wrapper'
};

/**
 * `AnimationStates` is a map that helps components determine if the
 *  1) CSS ticker animation should run,
 *  2) If the rive animation should outro and
 *  3) which captions to display throughout the animation timeline.
 *
 * The object keys (except for `ready_for_outro`) are defined by the rive animation file and therefore coupled with it.
 * The object values map the rive states to `camelCase` to be more dogmatically used within components.
 * @see ./assets/advice-doughnut-loader.riv
 */
const AnimationStates = {
  // when intro is complete, the rive animation enters the `ready_for_ticker` state
  ready_for_ticker: 'readyForTicker',
  // custom state; to be triggered when CSS animation is complete
  ready_for_outro: 'readyForOutro',
  // when outro is complete, the rive animation enters the `exit` state
  exit: 'exit'
};
export { AnimationStates, ADVICE_ANALYSIS_PATH, RiveParams, AdviceAnalysisTestIds };