var __jsx = React.createElement;
import React, { useEffect } from 'react';
import CloseIcon from './close-icon.svg';
import InverseCloseIcon from './close-icon-inverse.svg';
import { ModalBackground, ModalBody, ModalHeader, ModalContent, Icon } from './Modal.style';

/**
 * @deprecated Please do not use for new projects. Use newest-modal/Modal.tsx instead. 10/20/2022
 */
export const Modal = props => {
  const {
    className,
    disableCloseOnClickOutside = false,
    children,
    hideXMark,
    clickClose,
    inverseXMark = false,
    height,
    width
  } = props;
  const handleClickOutside = event => {
    if (event.target.getAttribute('data-click-id') === 'modal-bg' && clickClose) {
      clickClose();
    }
  };
  useEffect(() => {
    if (!disableCloseOnClickOutside) {
      document.addEventListener('click', handleClickOutside);
      return () => {
        document.removeEventListener('click', handleClickOutside);
      };
    }
  });
  return __jsx(ModalBackground, {
    "data-click-id": "modal-bg",
    className: className
  }, __jsx(ModalBody, {
    "data-cy": "modal-body-wrapper",
    height: height,
    width: width,
    className: "modal-body-wrapper"
  }, __jsx(ModalHeader, {
    hideXMark: hideXMark
  }, __jsx(Icon, {
    tabIndex: 0,
    src: inverseXMark ? InverseCloseIcon : CloseIcon,
    onClick: clickClose
  })), __jsx(ModalContent, {
    className: "modal-content"
  }, children)));
};