var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { media } from '../../themes/media';
import { Title } from '../../layout/title/Title';
import { Paragraph } from '../../content/text/Paragraph';
import { Label } from '../../content/text/Label';
import CheckIcon from '../../assets/graphics/icons/blue-circle-blue-check.png';
import DashedIcon from '../../assets/graphics/icons/checkmark_dashed.svg';
export const PageCompleteSetup = _ref => {
  let {
    title,
    subtitle,
    description,
    cta,
    children,
    fullSizeChildren = false,
    showIcon = true,
    inverse = true,
    altIcon,
    textCenter
  } = _ref;
  return __jsx(Container, null, __jsx(Background, {
    inverse: inverse
  }, __jsx(ContentWrapper, {
    textCenter: textCenter
  }, __jsx(TitleWrapper, {
    textCenter: textCenter
  }, showIcon && altIcon ? __jsx(AltIcon, {
    src: DashedIcon,
    alt: "check",
    "data-cy": "page-complete-setup-alt-icon"
  }) : __jsx(Icon, {
    src: CheckIcon,
    alt: "check",
    "data-cy": "page-complete-setup-icon"
  }), __jsx(Title, {
    tagType: 3,
    inverse: inverse
  }, title)), subtitle && __jsx(SubtitleWrapper, null, __jsx(Paragraph, {
    inverse: inverse
  }, subtitle)), description && __jsx(Label, {
    inverse: inverse
  }, description), cta, !fullSizeChildren && children), fullSizeChildren && children));
};
const Icon = styled.img.withConfig({
  displayName: "PageCompleteSetup__Icon",
  componentId: "sc-s5kbkz-0"
})(["margin-bottom:20px;", ""], media.desktop`
    position: absolute;
    margin-left: -80px;
    margin-top: 7px;
    margin-bottom: 0;
  `);
const AltIcon = styled.img.withConfig({
  displayName: "PageCompleteSetup__AltIcon",
  componentId: "sc-s5kbkz-1"
})(["margin-bottom:40px;"]);
const Container = styled.div.withConfig({
  displayName: "PageCompleteSetup__Container",
  componentId: "sc-s5kbkz-2"
})(["display:flex;flex-direction:row;min-height:100vh;"]);
const ContentWrapper = styled.div.withConfig({
  displayName: "PageCompleteSetup__ContentWrapper",
  componentId: "sc-s5kbkz-3"
})(["max-width:490px;margin:60px auto auto;padding:0 20px;", ""], media.tablet`
    margin: 100px auto auto;
    ${props => props.textCenter && 'text-align: center; max-width: 650px;'}
  `);
const TitleWrapper = styled.div.withConfig({
  displayName: "PageCompleteSetup__TitleWrapper",
  componentId: "sc-s5kbkz-4"
})(["", ""], props => props.textCenter && 'text-align: center;');
const SubtitleWrapper = styled.div.withConfig({
  displayName: "PageCompleteSetup__SubtitleWrapper",
  componentId: "sc-s5kbkz-5"
})(["margin-bottom:10px;"]);
const Background = styled.div.withConfig({
  displayName: "PageCompleteSetup__Background",
  componentId: "sc-s5kbkz-6"
})(["background:", ";width:100%;"], props => props.theme.colors[props.inverse ? 'background_inverse' : 'background_page']);