import { IdentityDocumentType, InvestorEntityType, InvestorType } from '@yieldstreet/platform-kit';
export const getInvestorLabel = investorType => {
  let nameLabel;
  let namePlaceholder;
  let firstNameLabel;
  let firstNamePlaceholder;
  let lastNameLabel;
  let lastNamePlaceholder;
  let addressLabel;
  let dateLabel;
  let documentLabel;
  let documentNumberFormat;
  const trustNameLabel = 'Trust name';
  switch (investorType) {
    case InvestorType.INDIVIDUAL:
    case InvestorType.IRA:
      firstNameLabel = 'Legal first name';
      firstNamePlaceholder = 'First name';
      lastNameLabel = 'Legal last name';
      lastNamePlaceholder = 'Last Name';
      addressLabel = 'Primary address';
      dateLabel = 'Date of birth';
      documentLabel = 'Social security number';
      documentNumberFormat = '###-##-####';
      break;
    case InvestorType.TRUST_SSN:
      firstNameLabel = trustNameLabel;
      firstNamePlaceholder = trustNameLabel;
      addressLabel = 'Trust address';
      dateLabel = 'Date of formation';
      documentLabel = 'SSN';
      documentNumberFormat = '###-##-####';
      break;
    case InvestorType.ENTITY:
      firstNameLabel = 'Entity name';
      firstNamePlaceholder = 'Entity name';
      addressLabel = 'Entity address';
      dateLabel = 'Date of incorporation';
      documentLabel = 'EIN';
      documentNumberFormat = '##-#######';
      break;
    default:
      firstNameLabel = trustNameLabel;
      firstNamePlaceholder = trustNameLabel;
      addressLabel = 'Trust address';
      dateLabel = 'Date of formation';
      documentLabel = 'EIN';
      documentNumberFormat = '##-#######';
      break;
  }
  return {
    nameLabel,
    namePlaceholder,
    firstNameLabel,
    firstNamePlaceholder,
    lastNameLabel,
    lastNamePlaceholder,
    addressLabel,
    dateLabel,
    documentLabel,
    documentNumberFormat
  };
};
export const getAccountPayload = _ref => {
  let {
    values,
    investorType,
    iraType,
    retirementAccount = false,
    userEmailAddress,
    contact = {}
  } = _ref;
  switch (investorType) {
    case InvestorType.INDIVIDUAL:
      return {
        name: values.name,
        firstName: values.firstName,
        lastName: values.lastName,
        type: InvestorEntityType.INDIVIDUAL,
        documentType: IdentityDocumentType.SSN
      };
    case InvestorType.TRUST_SSN:
      return {
        name: values.firstName,
        trustEmail: userEmailAddress,
        trustPhone: values.phone || contact.phone,
        entityScope: 'unknown',
        entityType: InvestorEntityType.TRUST,
        type: InvestorEntityType.TRUST_SSN,
        documentType: IdentityDocumentType.SSN,
        beneficialOwners: true,
        retirementAccount: retirementAccount
      };
    case InvestorType.TRUST_EIN:
      return {
        name: values.firstName,
        entityType: InvestorEntityType.TRUST,
        type: InvestorEntityType.TRUST_EIN,
        documentType: IdentityDocumentType.EIN,
        retirementAccount: retirementAccount
      };
    case InvestorType.ENTITY:
      return {
        name: values.firstName,
        entityType: values.entityType,
        type: values.entityType,
        documentType: IdentityDocumentType.EIN,
        beneficialOwners: true,
        retirementAccount: retirementAccount
      };
    case InvestorType.S_DIRA:
      return {
        name: values.firstName,
        entityType: InvestorEntityType.IRA,
        type: iraType === 'ROTH' ? InvestorEntityType.ROTH_IRA : InvestorEntityType.TRADITIONAL_IRA,
        documentType: IdentityDocumentType.EIN,
        employerName: values.employerName,
        isIra: true,
        iraAccountType: iraType,
        retirementAccount: retirementAccount
      };
    case InvestorType.IRA:
      return {
        name: values.name,
        firstName: values.firstName,
        documentType: IdentityDocumentType.SSN,
        type: iraType === 'ROTH' ? InvestorEntityType.ROTH_IRA : InvestorEntityType.TRADITIONAL_IRA,
        entityType: InvestorEntityType.TRUST,
        employerName: values.employerName,
        isIra: true,
        iraAccountType: iraType,
        retirementAccount: retirementAccount
      };
    default:
      return {
        name: values.firstName,
        entityType: InvestorEntityType.TRUST,
        type: 'ENTT',
        documentType: IdentityDocumentType.EIN,
        retirementAccount: retirementAccount
      };
  }
};