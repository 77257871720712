import { useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { overviewApi } from '@yieldstreet/platform-kit';
import { ALL } from '../../investment-details';
import { DateRanges, getDatesForRange } from '../../../utils';
export const usePortfolioStrategy = _ref => {
  let {
    investorId,
    managementStrategy,
    userId
  } = _ref;
  const [startDate, endDate] = getDatesForRange({
    range: DateRanges.LifeTime
  });
  const portfolioOverviewQueryConfig = useMemo(() => {
    if (!userId) {
      return skipToken;
    }
    return {
      end: endDate,
      investorAccountId: investorId === ALL ? undefined : investorId,
      managementStrategy,
      start: startDate,
      userId
    };
  }, [endDate, investorId, managementStrategy, startDate, userId]);
  const {
    data: {
      investmentStrategies
    } = {},
    isError,
    isFetching
  } = overviewApi.useGetPortfolioOverviewQuery(portfolioOverviewQueryConfig);
  const strategy = useMemo(() => investmentStrategies === null || investmentStrategies === void 0 ? void 0 : investmentStrategies.find(investmentStrategy => Number(investmentStrategy.investorAccountId) === Number(investorId)), [investmentStrategies, investorId]);
  return {
    isError,
    isFetching,
    strategy
  };
};