var __jsx = React.createElement;
import React, { useMemo } from 'react';
import { Label } from '../../../content';
import { getNavItemSubMenu } from '../../common';
import { HeaderSubMenuItem } from '../HeaderItemLoggedOut';
import { SubMenuSection, SubMenuSectionItems } from './SubmenuSection.style';
export const SubmenuSection = _ref => {
  let {
    inverse,
    item,
    navProps
  } = _ref;
  const items = useMemo(() => {
    return getNavItemSubMenu(item, navProps) || [];
  }, [item]);
  return __jsx(SubMenuSection, {
    "data-cy": `submenu-section-${item.key}`
  }, item.title && __jsx(Label, {
    inverse: inverse,
    small: true
  }, item.title), __jsx(SubMenuSectionItems, {
    grid: items.length > 3
  }, items.map(subItem => __jsx(HeaderSubMenuItem, {
    key: `subItem-${subItem.key}`,
    menuItem: subItem,
    inverse: inverse
  }))));
};