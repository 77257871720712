var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { theme2 } from '../../themes';
export const LinearProgressBar = _ref => {
  let {
    percentCompleted = 0
  } = _ref;
  return __jsx(LinearProgressBarBackground, null, __jsx(LinearProgressBarFilling, {
    percentCompleted: percentCompleted
  }));
};
export const LinearProgressBarBackground = styled.div.withConfig({
  displayName: "LinearProgressBar__LinearProgressBarBackground",
  componentId: "sc-7e2u7u-0"
})(["height:10px;width:100%;background-color:", ";border-radius:10px;"], theme2.colors.background_progress_bar);
export const LinearProgressBarFilling = styled.div.withConfig({
  displayName: "LinearProgressBar__LinearProgressBarFilling",
  componentId: "sc-7e2u7u-1"
})(["height:100%;width:", ";background-color:", ";border-radius:inherit;"], props => `${props.percentCompleted}%`, theme2.colors.active);