var __jsx = React.createElement;
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { NavigationTabList } from '@yieldstreet/ui-kit';
import { renderLink } from 'sharedComponents/Links/Links';
import { portfolioNavLinks } from './PortfolioHeaderNav.util';
import { PortfolioHeaderNavWrapper } from './PortfolioHeaderNav.style';
export const PortfolioHeaderNav = () => {
  const {
    location
  } = useHistory();
  const {
    investorId,
    strategy
  } = useParams();
  return __jsx(PortfolioHeaderNavWrapper, null, __jsx(NavigationTabList, {
    currentPath: `${location === null || location === void 0 ? void 0 : location.pathname}`,
    navigationConfig: portfolioNavLinks(investorId, strategy),
    renderLink: renderLink
  }));
};