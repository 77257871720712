import { SnackbarTypes } from './Snackbar.model';
export const snackbarInfoMock = {
  key: 'info',
  title: '1 New Investment',
  description: 'Diversified Art Debt Portfolio II investment is now active.',
  testId: 'story-book-info',
  linkText: 'test',
  type: SnackbarTypes.Info,
  isVisible: true,
  handleLink: () => {},
  closeSnackbar: () => {}
};
export const snackbarSuccessMock = {
  key: 'success',
  title: 'Success',
  description: 'Success.',
  testId: 'story-book-success',
  linkText: 'test',
  type: SnackbarTypes.Success,
  isVisible: true,
  handleLink: () => {},
  closeSnackbar: () => {}
};
export const snackbarErrorMock = {
  key: 'error',
  title: 'Error',
  description: 'Error.',
  testId: 'story-book-error',
  linkText: 'test',
  type: SnackbarTypes.Error,
  isVisible: true,
  handleLink: () => {},
  closeSnackbar: () => {}
};
export const snackbarWarningMock = {
  key: 'warning',
  title: 'Warning',
  description: 'Warning.',
  testId: 'story-book-warning',
  linkText: 'test',
  type: SnackbarTypes.Warning,
  isVisible: true,
  handleLink: () => {},
  closeSnackbar: () => {}
};
export const snackbarListMock = [snackbarInfoMock, snackbarSuccessMock, snackbarErrorMock, snackbarWarningMock];