export const formatOfferingHeroStatusMessage = (messages, statusValues) => {
  const {
    isActive,
    open,
    comingSoon,
    closed,
    matured
  } = messages;
  const {
    label
  } = statusValues;
  if (!isActive) {
    return false;
  }
  switch (label.toUpperCase()) {
    case 'COMING SOON':
      return comingSoon;
    case 'CLOSED':
      return closed;
    case 'FULLY REPAID':
      return matured;
    default:
      return open;
  }
};