var __jsx = React.createElement;
import React from 'react';
import { Paragraph } from '@yieldstreet/ui-kit';
import { Form } from 'formik';
import styled from 'styled-components';
import { AdviceCard } from 'sharedComponents/Advice';
import { useAdvice } from 'utils/advice';
import { AssetClassesField, EmploymentFields, TargetReturnField } from '../fields';
import { FormHeaderWrapper, SubmitButtonWrapper, SubmitButton, FormBody, Wrapper } from './Forms.style';
const GeneralPreferencesForm = _ref => {
  let {
    formikProps
  } = _ref;
  const {
    isAdviceReady
  } = useAdvice();
  return __jsx(Wrapper, null, __jsx(FormHeaderWrapper, null, __jsx(Paragraph, null, "Help us understand your needs so we can provide you the most useful information about our investments.")), !isAdviceReady && __jsx(AdviceCardWrapper, null, __jsx(AdviceCard, {
    contained: {
      withoutPageGrid: true
    }
  })), __jsx(FormBody, null, __jsx(Form, null, __jsx(AssetClassesField, {
    formikProps: formikProps
  }), __jsx(TargetReturnField, {
    formikProps: formikProps
  }), __jsx(EmploymentFields, {
    formikProps: formikProps
  }), __jsx(SubmitButtonWrapper, null, __jsx(SubmitButton, {
    type: "submit",
    disabled: !formikProps.dirty,
    loading: formikProps === null || formikProps === void 0 ? void 0 : formikProps.isSubmitting
  }, "Save changes")))));
};
const AdviceCardWrapper = styled.div.withConfig({
  displayName: "GeneralPreferencesForm__AdviceCardWrapper",
  componentId: "sc-12odix7-0"
})(["display:block;margin-bottom:24px;"]);
export { GeneralPreferencesForm };