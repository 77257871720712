var __jsx = React.createElement;
import React from 'react';
import { NewestModal } from '@yieldstreet/ui-kit';
import OfferingContainer from '../../../pages/offering/OfferingContainer';
import { testIds } from './OfferingModal.model';
import { InnerModalContainer } from './OfferingModal.style';
export const OfferingModal = _ref => {
  let {
    modalId,
    onClose,
    urlHash,
    rollover
  } = _ref;
  return __jsx(NewestModal, {
    modalId: modalId,
    onClose: onClose
  }, __jsx(InnerModalContainer, testIds.innerContainer.attr, __jsx(OfferingContainer, {
    urlHash: urlHash,
    rollover: rollover
  })));
};