import styled from 'styled-components';
import { media } from '../../themes';
export const Container = styled.div.withConfig({
  displayName: "BeanBoxstyle__Container",
  componentId: "sc-4rrzsr-0"
})(["cursor:pointer;position:relative;padding:0;border-radius:16px;overflow:hidden;background:", ";box-shadow:", ";display:flex;flex-direction:row-reverse;gap:20px;justify-content:flex-end;", ""], props => props.theme.gradients.radial_primary, props => props.theme.shadows.elevation_2, media.desktop`
    max-width: 285px;
    padding: 20px;
    padding-bottom: 0px;
    border-radius: 8px;

    flex-direction: column;
    gap: 30px;
    justify-content: center;
  `);
export const Title = styled.div.withConfig({
  displayName: "BeanBoxstyle__Title",
  componentId: "sc-4rrzsr-1"
})(["max-width:50%;text-align:initial;margin:auto 0;", ""], media.desktop`
    max-width: 85%;
    margin: none;
  `);
export const Arrow = styled.img.withConfig({
  displayName: "BeanBoxstyle__Arrow",
  componentId: "sc-4rrzsr-2"
})(["position:absolute;top:20px;right:20px;width:20px;"]);
export const Image = styled.img.withConfig({
  displayName: "BeanBoxstyle__Image",
  componentId: "sc-4rrzsr-3"
})(["max-height:105px;", ""], media.desktop`
    margin: 0 25px;
    max-height: 170px;
  `);