import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { adviceEngineApi, investorEntityApi, InvestorType, PortfolioStrategyValues } from '@yieldstreet/platform-kit';
import { PageLoader, Label, Paragraph, Heading, Button, ActionCell, ActionType, Tooltip } from '@yieldstreet/ui-kit';
import { useInvestorEntityFlags, useManagedPortfolioEligibilityContent } from '@yieldstreet/tool-kit';
import { useRouteInvestorEntity } from 'pages/investorEntity/utils';
import { Link } from 'sharedComponents/Links/Links';
import { PORTFOLIO_INVESTOR_ID_PARAM, PORTFOLIO_MD_OVERVIEW_SCREEN_PATH } from 'pages/portfolio';
import { NumberFormat } from 'sharedComponents/NumberFormat/NumberFormat';
import { testIds } from './Portfolios.model';
import { HeaderWrapper, BalanceWrapper, FooterWrapper, AccountInfoWrapper, DetailInfo, HR, WalletBlock, ManagedSetupDetails, TooltipWrapper } from './Portfolios.style';
import { useInvestorEntityWallet } from '../../hooks';
import tractionNumbers from '../../../../../../../static-files/tractionNumbers.json';
import { CITIZENSHIP_STEP, CREATE_ACCOUNT_PATH } from 'pages/newCreateInvestorAccount/CreateInvestorAccount.model';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { investmentStrategy } from 'pages/managedPortfolio/Strategy/Selection/StrategyCard/StrategyCard.model';
const {
  useCreateManagedAccountMutation
} = investorEntityApi;
export const ManagedPortfolio = () => {
  var _managedGate$action, _managedGate$action2, _portfolioData$manage, _portfolioData$manage2, _managedGate$action3, _managedGate$action3$;
  const history = useHistory();
  const {
    investorEntity,
    investorEntityStatus
  } = useRouteInvestorEntity();
  const [createManagedAccount, createManagedAccountRequestState] = useCreateManagedAccountMutation();
  const {
    gateContent: managedGate,
    isLoading: eligibilityLoading
  } = useManagedPortfolioEligibilityContent({
    investorEntityId: investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.id.toString()
  });
  const {
    managedWallet,
    isLoadingWallets
  } = useInvestorEntityWallet(investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.id);
  const {
    managedInvestorAccount
  } = useInvestorEntityFlags(investorEntity);
  const managedPortfolioQueryConfig = useMemo(() => {
    if (!(investorEntity !== null && investorEntity !== void 0 && investorEntity.id)) {
      return skipToken;
    }
    return {
      investorEntityId: investorEntity.id.toString()
    };
  }, [investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.id]);
  const {
    data: portfolioData
  } = adviceEngineApi.useGetManagedPortfolioQuery(managedPortfolioQueryConfig);

  // Mananged Account Helpers

  const hasManagedAction = managedGate === null || managedGate === void 0 ? void 0 : (_managedGate$action = managedGate.action) === null || _managedGate$action === void 0 ? void 0 : _managedGate$action.cta;
  const hasManagedGate = !(managedGate !== null && managedGate !== void 0 && (_managedGate$action2 = managedGate.action) !== null && _managedGate$action2 !== void 0 && _managedGate$action2.cta) && (managedGate === null || managedGate === void 0 ? void 0 : managedGate.title);
  const hasManagedBlockingGate = hasManagedGate && managedGate.blocking;

  // View Helpers

  const isLoading = useMemo(() => isLoadingWallets || !investorEntity || eligibilityLoading, [isLoadingWallets, investorEntity, eligibilityLoading]);
  const showManagedAccount = !!managedInvestorAccount;
  const showManagedAccountSetup = !managedInvestorAccount && !hasManagedGate;
  const entityReadyToInvest = investorEntityStatus === null || investorEntityStatus === void 0 ? void 0 : investorEntityStatus.readyToInvest;
  const accountSetupComplete = !eligibilityLoading && entityReadyToInvest && !hasManagedAction && !hasManagedBlockingGate;
  const pendingAccountActions = entityReadyToInvest && hasManagedAction;
  useEffect(() => {
    if (createManagedAccountRequestState.isSuccess) {
      history.push(`${CREATE_ACCOUNT_PATH}/${InvestorType.INDIVIDUAL}/${PortfolioStrategyValues.Discretionary}/${createManagedAccountRequestState.data.investorAccount.investorEntityId}/${CITIZENSHIP_STEP}`);
    }
  }, [createManagedAccountRequestState, history]);
  if (isLoading) {
    return __jsx(WalletBlock, null, __jsx(PageLoader, {
      center: true,
      testId: testIds.pageLoader.id
    }));
  }
  if (showManagedAccountSetup) {
    return __jsx(WalletBlock, {
      altBg: true,
      testId: testIds.managedSetup.id
    }, __jsx(HeaderWrapper, null, __jsx(Heading, _extends({
      inverse: true,
      type: 5
    }, testIds.accountTitle.attr), "Managed")), __jsx(AccountInfoWrapper, testIds.managedSetupDetails.attr, __jsx(ManagedSetupDetails, null, __jsx(Paragraph, {
      inverse: true
    }, "Let Yieldstreet build and manage a portfolio based on your investment objectives."), __jsx(Label, {
      inverse: true,
      small: true
    }, "Individual accounts only | Minimum: $25K | Learn more"))), __jsx(FooterWrapper, null, __jsx(Button, _extends({}, testIds.managedSetupButton.attr, {
      small: true,
      reducedPadding: true,
      disabled: !entityReadyToInvest,
      onClick: () => createManagedAccount({
        investorEntityId: `${investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.id}`
      })
    }), "Set up portfolio")), !entityReadyToInvest && __jsx(Label, {
      inverse: true,
      small: true
    }, "Account action pending. Once it\u2019s resolved, account setup will be unlocked"));
  }
  if (hasManagedBlockingGate) {
    return __jsx(WalletBlock, {
      testId: testIds.managedAccount.id
    }, __jsx(HeaderWrapper, null, __jsx(Heading, _extends({
      type: 5
    }, testIds.accountTitle.attr), "Managed")), __jsx(Paragraph, {
      semiBold: true
    }, managedGate.title), __jsx(Paragraph, null, managedGate.description));
  }
  return __jsx(React.Fragment, null, showManagedAccount && __jsx(WalletBlock, {
    testId: testIds.managedAccount.id
  }, __jsx(HeaderWrapper, null, __jsx(Heading, _extends({
    type: 5
  }, testIds.accountTitle.attr), "Managed"), accountSetupComplete && __jsx(Link, _extends({}, testIds.viewPortfolio.attr, {
    to: PORTFOLIO_MD_OVERVIEW_SCREEN_PATH.replace(PORTFOLIO_INVESTOR_ID_PARAM, `${managedInvestorAccount === null || managedInvestorAccount === void 0 ? void 0 : managedInvestorAccount.id}`)
  }), "View portfolio")), __jsx(BalanceWrapper, null, __jsx(DetailInfo, null, __jsx(TooltipWrapper, null, __jsx(Label, {
    small: true
  }, "Available balance"), __jsx(Tooltip, {
    buttonType: "button",
    place: "top",
    tooltipKey: testIds.managedTooltip.id,
    testId: testIds.managedTooltip.id
  }, "While these funds cannot be withdrawn at this time, they continue to earn interest and may be used to top up your portfolio's cash reserve, as needed.")), __jsx(NumberFormat, _extends({
    decimalScale: 2,
    displayType: "text",
    fixedDecimalScale: true,
    prefix: "$",
    thousandSeparator: true,
    value: Number(managedWallet === null || managedWallet === void 0 ? void 0 : managedWallet.availableBalance) > 0 ? managedWallet === null || managedWallet === void 0 ? void 0 : managedWallet.availableBalance : 0
  }, testIds.cashBalance.attr))), __jsx(DetailInfo, null, __jsx(Label, {
    small: true
  }, "Interest rate"), __jsx(Paragraph, testIds.interestRate.attr, tractionNumbers.walletInterestRate))), __jsx(HR, null), __jsx(FooterWrapper, null, !entityReadyToInvest && __jsx(Paragraph, null, "Account action pending. Once it\u2019s resolved, account setup will continue"), accountSetupComplete && (portfolioData === null || portfolioData === void 0 ? void 0 : (_portfolioData$manage = portfolioData.managedPortfolioStrategy) === null || _portfolioData$manage === void 0 ? void 0 : _portfolioData$manage.investmentStrategyType) && __jsx(AccountInfoWrapper, null, __jsx(DetailInfo, null, __jsx(Label, {
    small: true
  }, "Strategy"), __jsx(Paragraph, testIds.managedStrategy.attr, investmentStrategy[portfolioData === null || portfolioData === void 0 ? void 0 : (_portfolioData$manage2 = portfolioData.managedPortfolioStrategy) === null || _portfolioData$manage2 === void 0 ? void 0 : _portfolioData$manage2.investmentStrategyType].title))), pendingAccountActions && __jsx(ActionCell, {
    showIcon: true,
    testId: testIds.actionCell.id,
    label: (managedGate === null || managedGate === void 0 ? void 0 : (_managedGate$action3 = managedGate.action) === null || _managedGate$action3 === void 0 ? void 0 : (_managedGate$action3$ = _managedGate$action3.cta) === null || _managedGate$action3$ === void 0 ? void 0 : _managedGate$action3$.text) || '',
    type: ActionType.ActionRequired,
    onClick: e => {
      var _managedGate$action4, _managedGate$action4$;
      e === null || e === void 0 ? void 0 : e.stopPropagation();
      (_managedGate$action4 = managedGate.action) === null || _managedGate$action4 === void 0 ? void 0 : (_managedGate$action4$ = _managedGate$action4.cta) === null || _managedGate$action4$ === void 0 ? void 0 : _managedGate$action4$.callback();
    }
  }))));
};