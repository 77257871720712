import _Box from "@mui/material/Box";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var __jsx = React.createElement;
import React, { useMemo } from 'react';
import { NewestModal, useModal } from '@yieldstreet/ui-kit';
import { Modals } from 'constants/modal.model';
import { useFilters, useQueryFilter } from './hooks';
import { testIds as setupTestId } from './Filters.model';
import { FilterModalContainer, FilterModalMain, FilterModalMainHeader, SortOption } from './Filters.style';
export const SortModalContent = _ref => {
  let {
    sortOptions,
    queryFilterParams,
    initOptions,
    testIds
  } = _ref;
  const {
    query
  } = useQueryFilter(queryFilterParams);
  const {
    updateSearch
  } = useFilters({});
  const {
    close
  } = useModal(Modals.SortMobile);
  const {
    [queryFilterParams.Sort]: sortBy = initOptions.sortBy || '',
    [queryFilterParams.Order]: orderBy = initOptions.orderBy || ''
  } = query;
  const renderSortOptions = useMemo(() => {
    const onOptionChange = (key, direction) => {
      updateSearch(_objectSpread(_objectSpread({}, query), {}, {
        [queryFilterParams.Sort]: key,
        [queryFilterParams.Order]: direction
      }));
      close();
    };
    return sortOptions.map(_ref2 => {
      let {
        key,
        label,
        direction
      } = _ref2;
      return __jsx(SortOption, {
        key: `sort-option-${key}-${direction}`,
        active: sortBy === key && direction === orderBy,
        onClick: () => {
          onOptionChange(key, direction);
        }
      }, label);
    });
  }, [sortOptions, updateSearch, query, queryFilterParams.Sort, queryFilterParams.Order, close, sortBy, orderBy]);
  return __jsx(FilterModalContainer, testIds.filterWrapper.attr, __jsx(FilterModalMain, null, __jsx(FilterModalMainHeader, null, "Sort by"), renderSortOptions));
};
export const SortMobileModal = _ref3 => {
  let {
    sortOptions,
    queryFilterParams,
    initOptions,
    testId
  } = _ref3;
  const {
    close
  } = useModal(Modals.SortMobile);
  const testIds = setupTestId(testId);
  return __jsx(NewestModal, {
    modalId: Modals.SortMobile,
    onClose: close
  }, __jsx(_Box, null, __jsx(SortModalContent, {
    initOptions: initOptions,
    sortOptions: sortOptions,
    queryFilterParams: queryFilterParams,
    testIds: testIds
  })));
};