import styled from 'styled-components';
import { TitleTag, Paragraph } from '../../content/text';
import { media } from '../../themes';
export const Wrapper = styled.div.withConfig({
  displayName: "OfferingBannerstyle__Wrapper",
  componentId: "sc-jdlkyr-0"
})(["border-radius:4px;overflow:hidden;background:", ";", ";"], props => props.theme.colors.background_inverse, props => props.theme.shadows.elevation_2);
export const TopBar = styled.div.withConfig({
  displayName: "OfferingBannerstyle__TopBar",
  componentId: "sc-jdlkyr-1"
})(["align-items:flex-start;align-self:stretch;display:flex;background-color:", ";padding:", " ", ";gap:", ";"], props => props.theme.colors.active_alt, props => props.theme.spacing.xs, props => props.theme.spacing.sm, props => props.theme.spacing.xs);
export const Content = styled.div.withConfig({
  displayName: "OfferingBannerstyle__Content",
  componentId: "sc-jdlkyr-2"
})(["padding:", ";gap:", ";display:flex;flex-direction:row;justify-content:space-between;align-content:stretch;align-items:center;flex-wrap:wrap;", ";"], props => props.theme.spacing.sm, props => props.theme.spacing.m, media.tablet`
    flex-wrap: nowrap;
    padding: ${props => props.theme.spacing.m};
  `);
export const Secondary = styled.div.withConfig({
  displayName: "OfferingBannerstyle__Secondary",
  componentId: "sc-jdlkyr-3"
})(["flex:0 0 100%;max-width:100%;", ";"], media.tablet`
    flex: 0 1 auto;
    max-width: 55%;
  `);
export const Subtitle = styled(TitleTag).withConfig({
  displayName: "OfferingBannerstyle__Subtitle",
  componentId: "sc-jdlkyr-4"
})([""]);

// Secondary
export const SecondaryViewPort = styled.div.withConfig({
  displayName: "OfferingBannerstyle__SecondaryViewPort",
  componentId: "sc-jdlkyr-5"
})(["width:100%;display:flex;flex-direction:row;flex-wrap:nowrap;justify-content:flex-start;align-content:stretch;align-items:stretch;gap:", ";scroll-snap-type:both mandatory;overscroll-behavior-y:contain;", ";::-webkit-scrollbar{display:none;}"], props => props.theme.spacing.s, media.tablet`
    gap: ${props => props.theme.spacing.ml};
  `);
export const SecondaryItem = styled.div.withConfig({
  displayName: "OfferingBannerstyle__SecondaryItem",
  componentId: "sc-jdlkyr-6"
})(["display:flex;flex:0 0 auto;flex-direction:column;flex-wrap:nowrap;justify-content:flex-start;align-content:stretch;align-items:stretch;min-width:100px;gap:", ";scroll-snap-stop:always;scroll-snap-align:start;"], props => props.theme.spacing.xs);
export const DividerItem = styled.div.withConfig({
  displayName: "OfferingBannerstyle__DividerItem",
  componentId: "sc-jdlkyr-7"
})(["display:flex;flex:0 0 1px;flex-direction:column;flex-wrap:nowrap;background-color:#3e3e40;&:last-child{display:none;}"]);
export const SecondaryItemTitle = styled.div.withConfig({
  displayName: "OfferingBannerstyle__SecondaryItemTitle",
  componentId: "sc-jdlkyr-8"
})(["position:relative;padding-right:", ";white-space:nowrap;display:flex;flex-direction:row;flex-wrap:nowrap;justify-content:center;align-content:stretch;align-items:stretch;gap:", ";"], props => props.theme.spacing.m, props => props.theme.spacing.xxs);
export const SecondaryItemTitleTooltipWrapper = styled.div.withConfig({
  displayName: "OfferingBannerstyle__SecondaryItemTitleTooltipWrapper",
  componentId: "sc-jdlkyr-9"
})(["position:absolute;right:-", ";"], props => props.theme.spacing.xxs);
export const SecondaryItemContent = styled(Paragraph).withConfig({
  displayName: "OfferingBannerstyle__SecondaryItemContent",
  componentId: "sc-jdlkyr-10"
})([""]);