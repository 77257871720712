var __jsx = React.createElement;
import React from 'react';
import { PortfolioFiltersActions, PortfolioFiltersWrapper } from '../components';
import { PortfolioFiltersDesktopForm } from './PortfolioFiltersDesktopForm';
export const PortfolioFiltersDesktop = _ref => {
  let {
    actions,
    filters,
    handleFilterChange,
    handleFilterSubmit
  } = _ref;
  return __jsx(PortfolioFiltersWrapper, null, __jsx(PortfolioFiltersDesktopForm, {
    filters: filters,
    handleFilterChange: handleFilterChange,
    handleFilterSubmit: handleFilterSubmit
  }), __jsx(PortfolioFiltersActions, {
    actions: actions,
    isMobile: false
  }));
};