import { HttpMethods } from '../../enums';
import { restApi } from '../common';
const BASE_URL = `/api/investor/public/investor-entity`;
export const identityApi = restApi.injectEndpoints({
  endpoints: builder => {
    const validateFootprintToken = builder.mutation({
      query: _ref => {
        let {
          investorEntityId,
          token
        } = _ref;
        return {
          url: `${BASE_URL}/${investorEntityId}/identity/footprint/validate`,
          method: HttpMethods.Post,
          data: {
            token
          }
        };
      }
    });
    const createFootprintToken = builder.mutation({
      query: _ref2 => {
        let {
          investorEntityId
        } = _ref2;
        return {
          url: `${BASE_URL}/${investorEntityId}/identity/footprint/session`,
          method: HttpMethods.Post
        };
      }
    });
    const refreshIdentityVerificationStatus = builder.mutation({
      query: _ref3 => {
        let {
          investorEntityId
        } = _ref3;
        return {
          url: `${BASE_URL}/${investorEntityId}/identity/refresh-status`,
          method: HttpMethods.Post
        };
      }
    });
    return {
      createFootprintToken,
      refreshIdentityVerificationStatus,
      validateFootprintToken
    };
  }
});