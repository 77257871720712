import { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { marketplaceApi } from '@yieldstreet/platform-kit';
import { resolvePresetAudiences } from 'store/actions/audiences';
const {
  useGetResolveAudiencesQuery
} = marketplaceApi;
export const useAllowedAudiences = _ref => {
  let {
    preset,
    allowedAudiences
  } = _ref;
  const dispatch = useDispatch();
  const {
    currentData,
    isSuccess
  } = useGetResolveAudiencesQuery(preset);
  useEffect(() => {
    // keep dispaching the audiences to the store
    // backward compatibility for older components

    dispatch(resolvePresetAudiences(preset));
  }, [preset, dispatch]);
  const isAllowed = useMemo(() => {
    return isEmpty(allowedAudiences) || allowedAudiences.some(aud => currentData === null || currentData === void 0 ? void 0 : currentData.resolution[aud]);
  }, [currentData, allowedAudiences]);
  return {
    isAllowed,
    audiences: currentData === null || currentData === void 0 ? void 0 : currentData.resolution,
    audiencesFetched: isSuccess
  };
};
export default useAllowedAudiences;