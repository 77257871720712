import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["children"];
var __jsx = React.createElement;
import React from 'react';
import Link from '@mui/material/Link';
import { Link as RouterDomLink } from 'react-router-dom';
export const RouterLink = _ref => {
  let {
      children
    } = _ref,
    ownProps = _objectWithoutProperties(_ref, _excluded);
  return (
    // @ts-expect-error
    __jsx(Link, _extends({}, ownProps, {
      component: RouterDomLink
    }), children)
  );
};