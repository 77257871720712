import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { Paragraph, Heading, SpacingEnum } from '@yieldstreet/ui-kit';
import { testIds } from './ProductTourSlide.model';
import { SliderDot, SliderDotsWrapper, ImgWrapper, SliderWrapper } from './ProductTourSlide.style';
export const ProductTourSlide = _ref => {
  var _item$config, _item$config2;
  let {
    item,
    config
  } = _ref;
  return __jsx(SliderWrapper, _extends({
    alignCentre: item === null || item === void 0 ? void 0 : (_item$config = item.config) === null || _item$config === void 0 ? void 0 : _item$config.alignCentre
  }, testIds.base.attr), __jsx(ImgWrapper, null, __jsx("img", _extends({
    src: item.img,
    alt: item.title,
    "aria-hidden": true
  }, testIds.image.attr))), !(item !== null && item !== void 0 && (_item$config2 = item.config) !== null && _item$config2 !== void 0 && _item$config2.hideSliderDots) && __jsx(SliderDotsWrapper, testIds.sliderDots.attr, Array.from({
    length: config === null || config === void 0 ? void 0 : config.sliderDots
  }).map((_, index) => __jsx(SliderDot, _extends({
    key: `item-${_}`
  }, index === (config === null || config === void 0 ? void 0 : config.currentSlide) && {
    isActive: true
  })))), __jsx(Heading, _extends({
    type: 4
  }, testIds.title.attr, {
    marginBottom: SpacingEnum.xs
  }), item.title), __jsx(Paragraph, _extends({
    secondary: true
  }, testIds.description.attr), item.description));
};