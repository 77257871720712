import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React, { useState } from 'react';
import { Chart } from '../components/Chart';
import { ExpandedAmountSelector } from '../components/ExpandedAmountSelector';
import { Background, ChartContainer, ControlsHolder, Disclaimer } from './DealSimulator.style';
const PortfolioChart = _ref => {
  let {
    investment,
    initAmount
  } = _ref;
  const {
    0: showTotalInvestment,
    1: setShowTotalInvestment
  } = useState(false);
  const {
    0: amount,
    1: setAmount
  } = useState(initAmount);
  return __jsx(React.Fragment, null, __jsx(ChartContainer, null, __jsx(Chart, {
    investments: [investment],
    amounts: [amount],
    showTotalInvestment: showTotalInvestment
  })), __jsx(ControlsHolder, null, __jsx(ExpandedAmountSelector, {
    title: investment.name,
    annualizedYield: investment.rate,
    term: `${investment.term} ${investment.termSuffix} `,
    min: investment.min,
    max: investment.max,
    amount: amount,
    onChange: newAmount => setAmount(+newAmount),
    onTouchMove: () => setShowTotalInvestment(true),
    onTouchEnd: () => setShowTotalInvestment(false)
  })));
};
export const DealSimulator = _ref2 => {
  let {
    investment,
    initAmount = 5000
  } = _ref2;
  return __jsx(React.Fragment, null, __jsx(_Typography, {
    variant: "h3"
  }, "Deal simulator"), __jsx(_Typography, {
    variant: "body1",
    sx: {
      my: 4
    }
  }, "See how you can benefit from building a portfolio of alternative investments."), __jsx(Background, {
    "data-cy": "deal-simulator-chart"
  }, __jsx(PortfolioChart, {
    investment: investment,
    initAmount: initAmount
  }), __jsx(Disclaimer, {
    variant: "body2"
  }, "All investments involve risk, including loss of principal.", ' ', __jsx("a", {
    href: "/simulator-disclosures/"
  }, "View Disclosures"))));
};