// import { HttpMethods } from '../../enums/http';
import { restApi, Tags } from '../common';
import { HttpMethods } from '../../enums';
export const offeringPromotionApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.OfferingPromo]
}).injectEndpoints({
  endpoints: builder => {
    const promotionEligibility = builder.query({
      query: data => ({
        url: `/api/offering/offering-promotions/eligibility`,
        method: HttpMethods.Post,
        data
      }),
      transformResponse: result => result.response
    });
    return {
      promotionEligibility
    };
  }
});