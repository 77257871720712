import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { Button } from '../../../content/button';
import { HeaderItem } from '../HeaderItem';
import { userNav, HelpCenter } from '../navigationConfig';
import { NavigationLink } from '../../common';
import { MobileOnly, DesktopOnly } from '../../../utils';
import { HeaderItemLoggedOut } from '../HeaderItemLoggedOut';
import { Container, UserMenuContainer, SignupHolder, LoginLinkWrapper } from './LoginArea.style';
export const LoginArea = props => {
  const {
    activeItem,
    activeSubItem,
    hideLogin,
    hideSignup,
    hideUserNav,
    userState,
    customSignUpButton
  } = props;
  return __jsx(Container, {
    id: "login-area",
    "data-cy": "login-area"
  }, !hideUserNav && __jsx(React.Fragment, null, __jsx(DesktopOnly, null, __jsx(UserMenuContainer, null, userNav.map(item => __jsx(HeaderItem, _extends({
    key: item.key,
    activeItem: activeItem,
    activeSubItem: activeSubItem,
    menuItem: item,
    desktopAlignment: "right"
  }, props))))), __jsx(MobileOnly, null, __jsx(UserMenuContainer, null, userNav.map(item => __jsx(HeaderItem, _extends({
    key: item.key,
    activeItem: activeItem,
    activeSubItem: activeSubItem,
    menuItem: item,
    desktopAlignment: "right",
    isMobile: true
  }, props)))))), !(userState !== null && userState !== void 0 && userState.loggedIn) ? __jsx(SignupHolder, null, __jsx(DesktopOnly, null, __jsx(HeaderItemLoggedOut, _extends({
    key: "help-center",
    activeItem: activeItem,
    activeSubItem: activeSubItem,
    menuItem: HelpCenter,
    isSidebarItem: true
  }, props))), !hideLogin && __jsx(DesktopOnly, null, __jsx(LoginLinkWrapper, {
    hideSignup: hideSignup
  }, __jsx(NavigationLink, _extends({}, props, {
    navItem: {
      id: 'header-login',
      key: 'login',
      route: '/login',
      title: 'Log in'
    },
    raw: true
  }), __jsx(Button, {
    buttonType: "secondary",
    small: true,
    trackingId: "header-login",
    "data-cy": "header-login",
    tabIndex: -1
  }, "Login")))), !hideSignup && __jsx(NavigationLink, _extends({}, props, {
    navItem: {
      id: 'header-signup-link',
      key: 'signup',
      route: '/get-started/'
    },
    raw: true
  }), __jsx(Button, {
    buttonType: "basic",
    small: true,
    "data-cy": "header-signup-link",
    trackingId: "header-signup",
    tabIndex: -1
  }, "Sign up")), customSignUpButton) : '');
};