import { Component } from 'react';
import { withRouter } from 'react-router-dom';
const asyncScrollToTop = () => window.scrollTo(0, 0);
export const scrollToTop = asyncScrollToTop;
class ScrollTop extends Component {
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      asyncScrollToTop();
    }
  }
  render() {
    return null;
  }
}
export default withRouter(ScrollTop);