import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["userId"];
import axios from 'axios';
import forEach from 'lodash/forEach';
import { restApi, Tags } from '../../common';
import { HttpMethods, PortfolioFilters } from '../../../enums';
export const overviewApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.PortfolioOverview]
}).injectEndpoints({
  endpoints: build => {
    const getPortfolioOverview = build.query({
      query: _ref => {
        let {
            userId
          } = _ref,
          params = _objectWithoutProperties(_ref, _excluded);
        return {
          url: `/api/portfolio/overview/${userId}`,
          method: HttpMethods.Get,
          params
        };
      },
      providesTags: [Tags.PortfolioOverview]
    });
    const getAllPortfolioOverview = build.query({
      queryFn: async (_ref2, _ref3) => {
        let {
          end,
          includePrincipalWriteOff,
          includeRepaidCapital,
          includeRepaidPrincipal,
          investorAccountIds,
          noteUrlHash,
          offeringData,
          start,
          userId,
          managementStrategy
        } = _ref2;
        let {
          extra
        } = _ref3;
        const axiosInstance = extra || axios;
        return await Promise.all(investorAccountIds === null || investorAccountIds === void 0 ? void 0 : investorAccountIds.map(investorAccountId => axiosInstance.get(`/api/portfolio/overview/${userId}`, {
          headers: {
            'investor-account-id': investorAccountId
          },
          params: {
            investorAccountId,
            end,
            includePrincipalWriteOff,
            includeRepaidCapital,
            includeRepaidPrincipal,
            noteUrlHash,
            offeringData,
            start,
            userId,
            managementStrategy
          }
        }))).then(_ref4 => {
          let [...responses] = _ref4;
          const data = {};
          forEach(responses, x => {
            var _x$request, _x$request$_headers, _x$config$headers;
            const investorAccountId = ((_x$request = x.request) === null || _x$request === void 0 ? void 0 : (_x$request$_headers = _x$request._headers) === null || _x$request$_headers === void 0 ? void 0 : _x$request$_headers['investor-account-id']) || ((_x$config$headers = x.config.headers) === null || _x$config$headers === void 0 ? void 0 : _x$config$headers['investor-account-id']) || PortfolioFilters.ALL;
            Object.assign(data, {
              [investorAccountId]: x.data
            });
          });
          return {
            data
          };
        }).catch(errors => {
          return {
            error: errors
          };
        });
      },
      providesTags: [Tags.PortfolioOverview]
    });
    return {
      getPortfolioOverview,
      getAllPortfolioOverview
    };
  }
});