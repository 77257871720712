import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useMemo } from 'react';
import { useTiers, tieredPricingContent } from '@yieldstreet/tool-kit';
import { Container, TieredPricingList } from './TieredPricingContainer.style';
import { TieredPricingItem } from '../TieredPricingItem/TieredPricingItem';
import { tieredTestIds } from '../TieredPricing.model';
export const TieredPricingContainer = _ref => {
  let {
    currentValue = 0
  } = _ref;
  const tiers = useTiers(currentValue);
  const showNonEligable = useMemo(() => {
    return currentValue < tiers[1].amount[0];
  }, [currentValue, tiers]);
  return __jsx(Container, tieredTestIds.container.attr, __jsx("span", null, __jsx(_Typography, _extends({
    variant: "h4"
  }, tieredTestIds.title.attr), tieredPricingContent.Title), __jsx(_Typography, _extends({
    variant: "body2"
  }, tieredTestIds.descriptionBefore.attr), tieredPricingContent.DescriptionBefore)), __jsx(TieredPricingList, tieredTestIds.list.attr, tiers.map((tier, idx) => __jsx(TieredPricingItem, {
    key: idx,
    type: tier.type,
    amount: tier.amount,
    reduction: tier.reduction
  }))), showNonEligable && __jsx(_Typography, _extends({
    variant: "body2"
  }, tieredTestIds.descriptionAfter.attr), tieredPricingContent.DescriptionAfter));
};