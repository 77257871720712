export const portfolioInvestmentsByOffering = {
  userId: 1,
  currentValueBreakdown: [{
    assetClass: 'PVBC',
    noteUrlHash: 'J0kt1w',
    percentage: 26.8,
    title: 'Igor - playcore invest flow',
    value: 51947.95
  }, {
    assetClass: 'ARTF',
    noteUrlHash: 'J6Q4Jw',
    percentage: 9.6,
    title: 'Post War & Contemporary Art Portfolio II',
    value: 18685.73
  }],
  investmentAmountBreakdown: [{
    assetClass: 'PVBC',
    noteUrlHash: 'J0kt1w',
    percentage: 24,
    title: 'Igor - playcore invest flow',
    value: 50000
  }, {
    assetClass: 'ARTF',
    noteUrlHash: 'J6Q4Jw',
    percentage: 7.2,
    title: 'Post War & Contemporary Art Portfolio II',
    value: 15000
  }]
};