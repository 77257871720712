import { AdviceIndustries, AdviceCurrentIncome, AdviceEmploymentStatus, AdviceExpectedYield, AdviceInterestedAssetClasses, AdviceInvestmentStyle, AdviceNetWorth, AdvicePreferredTimeHorizon, AdviceQuestionKeys, AdviceRiskTolerance, AdviceQualifiedClient, AdviceQualifiedPurchaser, AdviceInvestmentAmount } from '@yieldstreet/platform-kit';
import { CURRENT_INCOME_OPTIONS, EMPLOYMENT_STATUS_OPTIONS, EXPECTED_YIELD_OPTIONS, INDUSTRY_OPTIONS, INTERESTED_ASSET_CLASSES_OPTIONS, INVESTMENT_AMOUNT_OPTIONS, INVESTMENT_STYLE_OPTIONS, NET_WORTH_OPTIONS, PREFERRED_TIME_HORIZON_OPTIONS, QUALIFIED_CLIENT_OPTIONS, QUALIFIED_PURCHASER_OPTIONS, RISK_TOLERANCE_OPTIONS, RISK_TOLERANCE_ALTERNATIVE_OPTIONS } from './adviceQuestionsOptions';
import { adviceTooltips } from './adviceTooltips';
export const AdviceQuestionsConfig = {
  Employer: {
    // no `options` or `values`, expect value is free form text
    key: AdviceQuestionKeys.Employer,
    label: 'Employer'
  },
  Position: {
    // no `options` or `values`, expect value is free form text
    key: AdviceQuestionKeys.Position,
    label: 'Position'
  },
  QualifiedPurchaser: {
    key: AdviceQuestionKeys.QualifiedPurchaser,
    label: "I'm a qualified purchaser",
    options: QUALIFIED_PURCHASER_OPTIONS,
    values: AdviceQualifiedPurchaser,
    tooltip: adviceTooltips[AdviceQuestionKeys.QualifiedPurchaser]
  },
  QualifiedClient: {
    key: AdviceQuestionKeys.QualifiedClient,
    label: "I'm a qualified client",
    options: QUALIFIED_CLIENT_OPTIONS,
    values: AdviceQualifiedClient,
    tooltip: adviceTooltips[AdviceQuestionKeys.QualifiedClient]
  },
  NetWorth: {
    key: AdviceQuestionKeys.NetWorth,
    label: 'Net worth',
    options: NET_WORTH_OPTIONS,
    values: AdviceNetWorth
  },
  CurrentIncome: {
    key: AdviceQuestionKeys.CurrentIncome,
    label: 'Income',
    options: CURRENT_INCOME_OPTIONS,
    values: AdviceCurrentIncome
  },
  EmploymentStatus: {
    key: AdviceQuestionKeys.EmploymentStatus,
    label: 'Employment status',
    options: EMPLOYMENT_STATUS_OPTIONS,
    values: AdviceEmploymentStatus
  },
  InvestmentStyle: {
    key: AdviceQuestionKeys.InvestmentStyle,
    label: 'Investment style',
    options: INVESTMENT_STYLE_OPTIONS,
    values: AdviceInvestmentStyle
  },
  InvestmentAmount: {
    key: AdviceQuestionKeys.InvestmentAmount,
    label: 'Investment amount',
    options: INVESTMENT_AMOUNT_OPTIONS,
    values: AdviceInvestmentAmount
  },
  RiskTolerance: {
    key: AdviceQuestionKeys.RiskTolerance,
    label: 'Risk tolerance',
    options: RISK_TOLERANCE_OPTIONS,
    alternativeOptions: RISK_TOLERANCE_ALTERNATIVE_OPTIONS,
    values: AdviceRiskTolerance
  },
  PreferredTimeHorizon: {
    key: AdviceQuestionKeys.PreferredTimeHorizon,
    label: 'Time horizon',
    options: PREFERRED_TIME_HORIZON_OPTIONS,
    values: AdvicePreferredTimeHorizon
  },
  ExpectedYield: {
    key: AdviceQuestionKeys.ExpectedYield,
    label: 'Minimum target rate',
    options: EXPECTED_YIELD_OPTIONS,
    values: AdviceExpectedYield
  },
  Industry: {
    key: AdviceQuestionKeys.Industry,
    label: 'Industry',
    options: INDUSTRY_OPTIONS,
    values: AdviceIndustries
  },
  InterestedAssetClasses: {
    key: AdviceQuestionKeys.InterestedAssetClasses,
    label: 'Asset classes',
    options: INTERESTED_ASSET_CLASSES_OPTIONS,
    values: AdviceInterestedAssetClasses
  }
};