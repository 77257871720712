import { promoKeyMock, offeringPromoGateMock, offeringPromoGateInvalidMock, freeInvestmentPromoMock, offeringPromoMock } from '@yieldstreet/platform-kit';
import { PROMOTION_CTAS, PROMOTION_GATE_ERRORS, PROMOTION_ELEGIBILITY_ERROR } from './useOfferingPromotion.model';
export const noPromoStateMock = {
  promoKey: undefined,
  promoGate: undefined,
  promoDetails: undefined,
  promoChecked: true,
  promoEligible: false,
  promoCTA: undefined,
  promoError: undefined,
  isFreeInvestmentPromo: false,
  promoOfferingDetails: undefined
};
export const elgibilePromoStateMock = {
  promoKey: promoKeyMock,
  promoGate: undefined,
  promoDetails: freeInvestmentPromoMock,
  promoChecked: true,
  promoEligible: true,
  promoCTA: PROMOTION_CTAS.FREE_INVESTMENT,
  promoError: undefined,
  isFreeInvestmentPromo: true,
  promoOfferingDetails: offeringPromoMock
};
export const inelgibilePromoStateMock = {
  promoKey: promoKeyMock,
  promoGate: offeringPromoGateMock,
  promoDetails: freeInvestmentPromoMock,
  promoChecked: true,
  promoEligible: false,
  promoCTA: PROMOTION_CTAS.FREE_INVESTMENT,
  promoError: PROMOTION_GATE_ERRORS.LIMIT_REACHED,
  isFreeInvestmentPromo: false,
  promoOfferingDetails: offeringPromoMock
};
export const invalidPromoStateMock = {
  promoKey: promoKeyMock,
  promoGate: offeringPromoGateInvalidMock,
  promoDetails: undefined,
  promoChecked: true,
  promoEligible: false,
  promoCTA: undefined,
  promoError: PROMOTION_GATE_ERRORS.PROMOTION_INVALID,
  promoOfferingDetails: undefined,
  isFreeInvestmentPromo: false
};
export const failedPromoStateMock = {
  promoKey: promoKeyMock,
  promoGate: undefined,
  promoDetails: undefined,
  promoChecked: false,
  promoEligible: false,
  promoCTA: undefined,
  promoError: PROMOTION_ELEGIBILITY_ERROR,
  promoOfferingDetails: undefined,
  isFreeInvestmentPromo: false
};