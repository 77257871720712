import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["onChange", "defaultValue"];
var __jsx = React.createElement;
import React from 'react';
import { NumericFormat } from 'react-number-format';
import { TextInput } from './TextInput';
import { withFormikWeb } from '../common';
export const CurrencyFormatInput = props => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
      onChange,
      defaultValue
    } = props,
    otherProps = _objectWithoutProperties(props, _excluded);
  return __jsx(NumericFormat, _extends({}, otherProps, {
    onValueChange: _ref => {
      let {
        value
      } = _ref;
      onChange && onChange({
        target: {
          value
        }
      });
    },
    thousandSeparator: true,
    valueIsNumericString: true,
    autoComplete: "off"
  }));
};
export const CurrencyTextInput = props => {
  return __jsx(TextInput, _extends({
    prefix: "$"
  }, props, {
    inputComponent: CurrencyFormatInput
  }));
};
export const FormCurrencyTextInput = withFormikWeb(CurrencyTextInput);