import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useState, useMemo, useEffect, useCallback } from 'react';
import debounce from "lodash/debounce";
import filter from "lodash/filter";
import first from "lodash/first";
import isEmpty from "lodash/isEmpty";
import { communicationApi, getCacheParams, CommunicationMessageType, FetchMessagesUpdateStrategy } from '@yieldstreet/platform-kit';
const {
  useDismissMessageMutation,
  useDismissMessagesMutation,
  useFetchMessagesQuery,
  useGetMessagesQuery,
  useReadMessageMutation,
  useReadMessagesMutation
} = communicationApi;
const attachAnchor = (messages, options) => {
  if (!isEmpty(messages) && options && (options === null || options === void 0 ? void 0 : options.fetchingStrategy) === FetchMessagesUpdateStrategy.AGGREGATE && (options === null || options === void 0 ? void 0 : options.page) > 1) {
    const {
      messageSpecId,
      sortDate
    } = first(messages);
    return {
      id: messageSpecId,
      timestamp: sortDate
    };
  }
  return undefined;
};
export const useMessages = _ref => {
  let {
    fetchConfig: initFetchConfig,
    hookConfig: initHookConfig
  } = _ref;
  const {
    0: fetchConfig,
    1: setFetchConfig
  } = useState(_objectSpread({
    page: 1,
    limit: 5,
    types: [CommunicationMessageType.Activity],
    updates: FetchMessagesUpdateStrategy.AGGREGATE
  }, initFetchConfig || {}));
  const updateFetchConfig = useCallback(updatedConfig => setFetchConfig(_objectSpread(_objectSpread({}, fetchConfig), updatedConfig)), [fetchConfig, setFetchConfig]);
  const {
    0: hookParams,
    1: setHookParams
  } = useState(_objectSpread({
    autoRead: false,
    autoReadDelay: 3000
  }, initHookConfig));
  const {
    data: messages,
    refetch: refetchMessages
  } = useGetMessagesQuery(getCacheParams(fetchConfig));
  const {
    data: messagesQuery,
    isFetching,
    isLoading,
    isError
  } = useFetchMessagesQuery(fetchConfig, {
    refetchOnMountOrArgChange: true,
    skip: !(fetchConfig !== null && fetchConfig !== void 0 && fetchConfig.authToken) && fetchConfig.updates === FetchMessagesUpdateStrategy.AGGREGATE || fetchConfig.updates === FetchMessagesUpdateStrategy.LIVE_ONLY
  });
  const [readMessage] = useReadMessageMutation();
  const [readMessages] = useReadMessagesMutation();
  const [dismissMessage] = useDismissMessageMutation();
  const [dismissMessages] = useDismissMessagesMutation();
  const isCountReached = useMemo(() => messagesQuery && (messagesQuery === null || messagesQuery === void 0 ? void 0 : messagesQuery.currentPage) >= (messagesQuery === null || messagesQuery === void 0 ? void 0 : messagesQuery.totalPages), [messagesQuery]);
  const fetchMessages = params => {
    if (fetchConfig !== null && fetchConfig !== void 0 && fetchConfig.page) {
      const page = (params === null || params === void 0 ? void 0 : params.page) || (fetchConfig === null || fetchConfig === void 0 ? void 0 : fetchConfig.page) + 1;
      updateFetchConfig(_objectSpread(_objectSpread({}, params), {}, {
        page: page,
        anchor: attachAnchor(messages, {
          fetchingStrategy: fetchConfig === null || fetchConfig === void 0 ? void 0 : fetchConfig.updates,
          page
        })
      }));
    }
  };
  const onReadMessage = useCallback(function (messageSpecId, read) {
    let isGlobalCache = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    return readMessage({
      messageSpecId,
      messageArgs: isGlobalCache ? undefined : fetchConfig,
      read
    });
  }, [fetchConfig, readMessage]);
  const onReadMessages = useCallback(function (messageSpecIds, read) {
    let isGlobalCache = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    return readMessages({
      messageSpecIds,
      messageArgs: isGlobalCache ? undefined : fetchConfig,
      read
    });
  }, [fetchConfig, readMessages]);
  const onDismissMessage = useCallback((messageSpecId, dismissed) => dismissMessage({
    messageSpecId,
    messageArgs: fetchConfig,
    dismissed
  }), [dismissMessage, fetchConfig]);
  const onDismissMessages = useCallback((messageSpecIds, dismissed) => dismissMessages({
    messageSpecIds,
    messageArgs: fetchConfig,
    dismissed
  }), [dismissMessages, fetchConfig]);
  const unreadMessages = useMemo(() => filter(messages, ['read', false]), [messages]);
  const unseenMessages = useMemo(() => filter(messages, ['seen', false]), [messages]);
  const undismissedMessages = useMemo(() => filter(messages, ['dismissed', false]), [messages]);

  /**
   * @desc AutoRead
   * This useEffect handles that autoRead functionality. It will mark the messages as seen
   * (optimistically) whilst also dispatching an API request as to mark the message as read.
   * This functionality is debounced as not to pollute the browser with multiple API calls.
   */
  useEffect(() => {
    const {
      autoRead,
      autoReadDelay,
      autoReadGlobalCache = true
    } = hookParams;
    if (autoRead && !isEmpty(unreadMessages)) {
      const execAutoRead = debounce(() => {
        readMessages({
          messageSpecIds: unreadMessages.map(message => message.messageSpecId),
          messageArgs: autoReadGlobalCache ? undefined : fetchConfig,
          read: true
        });
      }, autoReadDelay);
      execAutoRead();
    }
  }, [fetchConfig, hookParams, readMessages, unreadMessages]);
  return {
    dismissMessage: onDismissMessage,
    dismissMessages: onDismissMessages,
    fetchMessages,
    isCountReached,
    isError,
    isFetching,
    isLoading,
    messages,
    readMessage: onReadMessage,
    readMessages: onReadMessages,
    refetchMessages,
    setHookParams,
    undismissedMessages,
    unreadMessages,
    unseenMessages
  };
};