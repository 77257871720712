import { useMemo } from 'react';
import isEmpty from "lodash/isEmpty";
import { InvestorLevelGateCode, UserLevelGateCode } from '@yieldstreet/platform-kit';
import { getGateCopy } from './useGate.model';
export const useGate = _ref => {
  let {
    eligibilityData
  } = _ref;
  const gate = useMemo(() => {
    var _eligibilityData$elig, _eligibilityData$elig2, _eligibilityData$elig3, _eligibilityData$elig4, _eligibilityData$elig5, _eligibilityData$elig6;
    let investorLevelGateCode;
    let attributes;
    if (!eligibilityData || !((_eligibilityData$elig = eligibilityData.eligibility) !== null && _eligibilityData$elig !== void 0 && _eligibilityData$elig.investorData)) {
      return;
    }
    const userGateCode = [UserLevelGateCode.ACCOUNT_GATE, UserLevelGateCode.NO_INVESTOR_ACCOUNT, UserLevelGateCode.SELF_QP].includes((_eligibilityData$elig2 = eligibilityData.eligibility) === null || _eligibilityData$elig2 === void 0 ? void 0 : (_eligibilityData$elig3 = _eligibilityData$elig2.userGate) === null || _eligibilityData$elig3 === void 0 ? void 0 : _eligibilityData$elig3.gateCode) && ((_eligibilityData$elig4 = eligibilityData.eligibility) === null || _eligibilityData$elig4 === void 0 ? void 0 : (_eligibilityData$elig5 = _eligibilityData$elig4.userGate) === null || _eligibilityData$elig5 === void 0 ? void 0 : _eligibilityData$elig5.gateCode);
    const investorAccountData = (_eligibilityData$elig6 = eligibilityData.eligibility) === null || _eligibilityData$elig6 === void 0 ? void 0 : _eligibilityData$elig6.investorData;
    const accountsBlockedByInvestorGate = investorAccountData === null || investorAccountData === void 0 ? void 0 : investorAccountData.filter(account => {
      var _account$gateData;
      return [InvestorLevelGateCode.ACCOUNT_TYPE, InvestorLevelGateCode.PENDING_MICRO_DEPOSITS, InvestorLevelGateCode.PENDING_BANK_ACCOUNT_VERIFICATION, InvestorLevelGateCode.MISSING_BANK_ACCOUNTS, InvestorLevelGateCode.OFFERING_STATUS, InvestorLevelGateCode.STATE_INELIGIBLE, InvestorLevelGateCode.INVESTOR_ENTITY_PROVIDER_ACTION, InvestorLevelGateCode.KYC_INELIGIBLE].includes((_account$gateData = account.gateData) === null || _account$gateData === void 0 ? void 0 : _account$gateData.gateCode);
    });
    if (!isEmpty(accountsBlockedByInvestorGate)) {
      let investorGateCode;
      const hasNoBankAccounts = accountsBlockedByInvestorGate.filter(account => {
        var _account$gateData2;
        return ((_account$gateData2 = account.gateData) === null || _account$gateData2 === void 0 ? void 0 : _account$gateData2.gateCode) === InvestorLevelGateCode.MISSING_BANK_ACCOUNTS;
      });
      const hasPendingMicroDeposits = accountsBlockedByInvestorGate.filter(account => {
        var _account$gateData3;
        return ((_account$gateData3 = account.gateData) === null || _account$gateData3 === void 0 ? void 0 : _account$gateData3.gateCode) === InvestorLevelGateCode.PENDING_MICRO_DEPOSITS;
      });
      const hasAutomaticMicroDeposits = accountsBlockedByInvestorGate.filter(account => {
        var _account$gateData4;
        return ((_account$gateData4 = account.gateData) === null || _account$gateData4 === void 0 ? void 0 : _account$gateData4.gateCode) === InvestorLevelGateCode.PENDING_BANK_ACCOUNT_VERIFICATION;
      });
      const isEntityProviderGate = accountsBlockedByInvestorGate.filter(account => {
        var _account$gateData5;
        return ((_account$gateData5 = account.gateData) === null || _account$gateData5 === void 0 ? void 0 : _account$gateData5.gateCode) === InvestorLevelGateCode.INVESTOR_ENTITY_PROVIDER_ACTION;
      });
      const isOfferingStatusGate = accountsBlockedByInvestorGate.filter(account => {
        var _account$gateData6;
        return ((_account$gateData6 = account.gateData) === null || _account$gateData6 === void 0 ? void 0 : _account$gateData6.gateCode) === InvestorLevelGateCode.OFFERING_STATUS;
      });
      const isStateIneligibleGate = accountsBlockedByInvestorGate.filter(account => {
        var _account$gateData7, _account$gateData7$at;
        if (((_account$gateData7 = account.gateData) === null || _account$gateData7 === void 0 ? void 0 : (_account$gateData7$at = _account$gateData7.attributes) === null || _account$gateData7$at === void 0 ? void 0 : _account$gateData7$at.reasonCode) === InvestorLevelGateCode.STATE_INELIGIBLE) {
          var _account$gateData8;
          attributes = (_account$gateData8 = account.gateData) === null || _account$gateData8 === void 0 ? void 0 : _account$gateData8.attributes;
          return true;
        }
        return false;
      });
      if (!isEmpty(isStateIneligibleGate)) {
        investorGateCode = InvestorLevelGateCode.STATE_INELIGIBLE;
      } else if (!isEmpty(hasNoBankAccounts)) {
        investorGateCode = InvestorLevelGateCode.MISSING_BANK_ACCOUNTS;
      } else if (!isEmpty(hasPendingMicroDeposits)) {
        investorGateCode = InvestorLevelGateCode.PENDING_MICRO_DEPOSITS;
      } else if (!isEmpty(hasAutomaticMicroDeposits)) {
        investorGateCode = InvestorLevelGateCode.PENDING_BANK_ACCOUNT_VERIFICATION;
      } else if (!isEmpty(isEntityProviderGate)) {
        investorGateCode = InvestorLevelGateCode.INVESTOR_ENTITY_PROVIDER_ACTION;
      } else if (!isEmpty(isOfferingStatusGate)) {
        investorGateCode = InvestorLevelGateCode.OFFERING_STATUS;
      } else {
        investorGateCode = InvestorLevelGateCode.ACCOUNT_TYPE;
      }
      investorLevelGateCode = investorAccountData.length && accountsBlockedByInvestorGate.length === investorAccountData.length ? investorGateCode : null;
    }
    const gateCode = userGateCode || investorLevelGateCode;
    if (gateCode) {
      var _investorAccountData$;
      return {
        gateCopy: getGateCopy(gateCode),
        gateCode,
        gateAttributes: {
          investorEntityId: (_investorAccountData$ = investorAccountData[0]) === null || _investorAccountData$ === void 0 ? void 0 : _investorAccountData$.investorEntityId,
          attributes
        }
      };
    }
    return null;
  }, [eligibilityData]);
  return gate;
};