import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import Big from 'big.js';
import { InvestmentsListStatusTypes } from '../../../../useInvestmentsList.model';
export const getTotals = _ref => {
  var _active$totals, _pending$totals, _active$totals2, _pending$totals2, _commitmentPending$co, _commitmentPending$co2, _commitmentActive$com, _commitmentActive$com2, _active$investments, _pending$investments, _matured$investments;
  let {
    active,
    matured,
    pending,
    commitmentPending,
    commitmentActive
  } = _ref;
  if (!active || !matured || !pending || !commitmentPending || !commitmentActive) {
    return {};
  }
  const activeCurrentValue = new Big(((_active$totals = active.totals) === null || _active$totals === void 0 ? void 0 : _active$totals.currentValue) || 0);
  const pendingCurrentValue = new Big(((_pending$totals = pending.totals) === null || _pending$totals === void 0 ? void 0 : _pending$totals.currentValue) || 0);
  const activeInvestedAmount = new Big(((_active$totals2 = active.totals) === null || _active$totals2 === void 0 ? void 0 : _active$totals2.investmentAmount) || 0);
  const pendingInvestedAmount = new Big(((_pending$totals2 = pending.totals) === null || _pending$totals2 === void 0 ? void 0 : _pending$totals2.investmentAmount) || 0);
  const pendingCommitmentsLength = (commitmentPending === null || commitmentPending === void 0 ? void 0 : (_commitmentPending$co = commitmentPending.commitments) === null || _commitmentPending$co === void 0 ? void 0 : (_commitmentPending$co2 = _commitmentPending$co.filter(commitment => !active.investments.find(investment => investment.noteUrlHash === commitment.noteUrlHash))) === null || _commitmentPending$co2 === void 0 ? void 0 : _commitmentPending$co2.length) || 0;
  const activeCommitmentsLength = (commitmentActive === null || commitmentActive === void 0 ? void 0 : (_commitmentActive$com = commitmentActive.commitments) === null || _commitmentActive$com === void 0 ? void 0 : (_commitmentActive$com2 = _commitmentActive$com.filter(commitment => !active.investments.find(investment => investment.noteUrlHash === commitment.noteUrlHash))) === null || _commitmentActive$com2 === void 0 ? void 0 : _commitmentActive$com2.length) || 0;
  return {
    [InvestmentsListStatusTypes.Current]: {
      currentValue: +activeCurrentValue.plus(+pendingCurrentValue),
      investmentAmount: +activeInvestedAmount.plus(+pendingInvestedAmount),
      investmentCount: ((_active$investments = active.investments) === null || _active$investments === void 0 ? void 0 : _active$investments.length) + ((_pending$investments = pending.investments) === null || _pending$investments === void 0 ? void 0 : _pending$investments.length) + pendingCommitmentsLength + activeCommitmentsLength
    },
    [InvestmentsListStatusTypes.Past]: _objectSpread(_objectSpread({}, matured.totals), {}, {
      investmentCount: (_matured$investments = matured.investments) === null || _matured$investments === void 0 ? void 0 : _matured$investments.length
    })
  };
};