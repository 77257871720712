import { useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { InvestmentStatus, PortfolioStrategyValues, portfolioGraphQLApi } from '@yieldstreet/platform-kit';
import { getInvestorAccountId } from '../../utils';
import { formatData, sortData } from './util';
import { InvestmentsListStatusTypes, InvestmentsListQueryParams } from './useInvestmentsList.model';
export const useInvestmentsList = _ref => {
  let {
    investorId,
    managementStrategy = PortfolioStrategyValues.Self,
    params,
    userId,
    config
  } = _ref;
  const investmentsQueryConfig = useMemo(() => {
    var _params$actionableIte;
    if (!userId) {
      return skipToken;
    }
    return {
      actionableItemsOnly: (_params$actionableIte = params.actionableItemsOnly) !== null && _params$actionableIte !== void 0 ? _params$actionableIte : false,
      investorId: getInvestorAccountId(investorId, managementStrategy),
      managementStrategy,
      userId,
      status: params[InvestmentsListQueryParams.Status] === InvestmentsListStatusTypes.Current ? [InvestmentStatus.Active, InvestmentStatus.Pending] : [InvestmentStatus.Matured]
    };
  }, [investorId, managementStrategy, params, userId]);
  const {
    data,
    isError,
    isFetching,
    refetch
  } = portfolioGraphQLApi.useGetPortfolioInvestmentsQuery(investmentsQueryConfig, config);

  /**
   * Data is formatted only when there is no error,
   * is not loading and when data from rtk changes.
   */
  const formattedData = useMemo(() => {
    if (isError || isFetching || !data) {
      return {};
    }
    return formatData(data, managementStrategy);
  }, [data, isError, isFetching, managementStrategy]);

  /**
   * This is used to run custom sorting through `sortData` util.
   * Data is sorted when changes occur in formatted data, order
   * type, sort type and status.
   */
  const {
    investments,
    lastCommentary,
    totals
  } = useMemo(() => {
    var _formattedData$totals;
    const sortedData = sortData({
      data: formattedData,
      orderKey: params[InvestmentsListQueryParams.Order],
      sortKey: params[InvestmentsListQueryParams.Sort],
      status: params[InvestmentsListQueryParams.Status]
    });
    return {
      investments: sortedData,
      lastCommentary: formattedData === null || formattedData === void 0 ? void 0 : formattedData.lastCommentary,
      totals: formattedData === null || formattedData === void 0 ? void 0 : (_formattedData$totals = formattedData.totals) === null || _formattedData$totals === void 0 ? void 0 : _formattedData$totals[params[InvestmentsListQueryParams.Status]]
    };
  }, [formattedData, params]);
  return {
    hasInvestments: !!(investments !== null && investments !== void 0 && investments.length),
    investments,
    isError,
    isFetching,
    lastCommentary,
    totals,
    refetch
  };
};