import styled from 'styled-components';
import { media } from '@yieldstreet/ui-kit';
import { NewModalWrapper } from 'pages/profile/shared/shared';
export const Wrapper = styled(NewModalWrapper).withConfig({
  displayName: "EditBankNicknameModelstyle__Wrapper",
  componentId: "sc-xbl84j-0"
})(["background:", ";"], props => props.theme.colors.background_card);
export const Content = styled.div.withConfig({
  displayName: "EditBankNicknameModelstyle__Content",
  componentId: "sc-xbl84j-1"
})(["margin-top:", ";display:flex;flex-direction:column;gap:", ";", ""], props => props.theme.spacing['2xl'], props => props.theme.spacing.sm, media.desktop`
    margin-top: unset;
    gap: ${props => props.theme.spacing.l};
  `);
export const ButtonWrapper = styled.div.withConfig({
  displayName: "EditBankNicknameModelstyle__ButtonWrapper",
  componentId: "sc-xbl84j-2"
})(["display:flex;flex-direction:column-reverse;gap:", ";", ""], props => props.theme.spacing.sm, media.desktop`
    flex-direction: row;
  `);
export const TextInputWrapper = styled.div.withConfig({
  displayName: "EditBankNicknameModelstyle__TextInputWrapper",
  componentId: "sc-xbl84j-3"
})(["", ""], media.desktop`
    max-width: 340px;
  `);