var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { Modal } from '../../elements/modal';
import { media } from '../../themes/media';
export const VideoModal = _ref => {
  let {
    url,
    isVisible,
    onClickClose
  } = _ref;
  return isVisible ? __jsx(Wrapper, null, __jsx(SModal, {
    clickClose: onClickClose,
    inverseXMark: true
  }, __jsx(Iframe, {
    src: `${url}?autoplay=1`,
    allow: "autoplay",
    frameBorder: "0"
  }))) : null;
};
const Wrapper = styled.div.withConfig({
  displayName: "VideoModal__Wrapper",
  componentId: "sc-v1n3lm-0"
})(["display:flex;text-align:left;flex-direction:column;", ";"], media.desktop`
    max-width: 668px;
  `);
const SModal = styled(Modal).withConfig({
  displayName: "VideoModal__SModal",
  componentId: "sc-v1n3lm-1"
})(["background:rgba(0,0,0,0.3);> div{background:transparent;padding:0;}", ";"], media.desktop`
    > div {
      height: auto;
    }
  `);
const Iframe = styled.iframe.withConfig({
  displayName: "VideoModal__Iframe",
  componentId: "sc-v1n3lm-2"
})(["width:335px;height:193px;border:0;position:relative;", ";"], media.tablet`
    width: 660px;
    height: 372px;
  `);