import _Button from "@mui/material/Button";
import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { mapToTestId } from './EmptyState.model';
import { EmptyStateWrapper } from './EmptyState.style';
export const EmptyState = _ref => {
  let {
    buttonText,
    dataCy,
    onClick,
    subtitle,
    testId,
    title,
    trackingId = ''
  } = _ref;
  const testIds = mapToTestId(dataCy || testId);
  return __jsx(EmptyStateWrapper, testIds.base.attr, __jsx(_Typography, _extends({
    variant: "h4"
  }, testIds.title.attr), title), __jsx(_Typography, _extends({
    variant: "body2",
    sx: {
      mt: 2
    }
  }, testIds.subtitle.attr), subtitle), __jsx(_Button, {
    "data-cy": trackingId,
    "data-trackingId": trackingId,
    fullWidth: true,
    onClick: onClick,
    sx: {
      mt: 10,
      px: {
        md: 5
      },
      width: {
        sm: 'fit-content'
      }
    }
  }, buttonText));
};