import { Container, media } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
export const NavigationAnchor = styled.div.withConfig({
  displayName: "Navigationstyle__NavigationAnchor",
  componentId: "sc-1nd95nb-0"
})(["visibility:hidden;pointer-events:none;"]);
export const NavigationContainer = styled.div.withConfig({
  displayName: "Navigationstyle__NavigationContainer",
  componentId: "sc-1nd95nb-1"
})(["z-index:5;width:100%;background-color:", ";border-bottom:1px solid ", ";padding:", " 0;", ""], props => props.theme.colors.background_card, props => props.theme.colors.border_default, props => props.theme.spacing.m, props => props.isSticky ? `
    position: sticky;
    top: 64px;
  ` : `
    margin-top: -15px;
  `);
export const NavigationInner = styled(Container).withConfig({
  displayName: "Navigationstyle__NavigationInner",
  componentId: "sc-1nd95nb-2"
})(["display:flex;flex-direction:row;flex-wrap:nowrap;justify-content:space-between;gap:", ";margin:0 -20px 0 -20px;padding-right:0;", " ", ""], props => props.theme.spacing.s, media.tablet`
    padding-right: 15px;
  `, media.large`
    padding-right: 35px;
    margin: auto;
  `);
export const NavigationLinks = styled.div.withConfig({
  displayName: "Navigationstyle__NavigationLinks",
  componentId: "sc-1nd95nb-3"
})(["display:flex;flex-direction:row;flex-wrap:nowrap;justify-content:flex-start;align-content:center;align-items:center;gap:", ";overflow-x:auto;overflow-y:hidden;scroll-snap-type:x mandatory;&::-webkit-scrollbar{display:none;}"], props => props.theme.spacing.s);
export const NavigationAction = styled.div.withConfig({
  displayName: "Navigationstyle__NavigationAction",
  componentId: "sc-1nd95nb-4"
})(["display:flex;"]);
export const NavigationLinkIcon = styled.img.withConfig({
  displayName: "Navigationstyle__NavigationLinkIcon",
  componentId: "sc-1nd95nb-5"
})([""]);