var __jsx = React.createElement;
import React from 'react';
import { Paragraph } from '../../content/text';
import { PageSection } from '../../layout';
import { Container, ContentHolder, TextWrapper, SHeading, TraitItemWrapper, TraitItem, TraitIcon, Bubble, ItemHeading, BlocksLine, TraitLine } from './Traits.style';
export const Traits = _ref => {
  let {
    traitItems,
    title,
    subtitle,
    blocks,
    dataCy
  } = _ref;
  return __jsx(PageSection, null, __jsx(Container, {
    "data-cy": dataCy || 'traits-container'
  }, __jsx(ContentHolder, null, __jsx(TextWrapper, null, title ? __jsx(SHeading, {
    type: 2
  }, title) : null, subtitle ? __jsx(Paragraph, null, subtitle) : null)), __jsx(TraitItemWrapper, {
    blocks: blocks
  }, traitItems.map((item, index) => {
    const {
      title,
      text,
      icon
    } = item;
    return __jsx(React.Fragment, null, __jsx(TraitItem, {
      blocks: blocks,
      key: `trait-${index}`
    }, icon ? __jsx(TraitIcon, {
      src: icon
    }) : __jsx(Bubble, null, __jsx(Paragraph, {
      semiBold: true
    }, `0${index + 1}`)), __jsx(ItemHeading, {
      type: 4
    }, title), __jsx(Paragraph, null, text)), blocks ? __jsx(BlocksLine, null) : __jsx(TraitLine, null));
  }))));
};