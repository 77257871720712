import isNil from 'lodash/isNil';
const defaultConfig = {
  size: 12,
  gutter: 20,
  spacing: 0
};
export const getConfig = function () {
  let theme = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let key = arguments.length > 1 ? arguments[1] : undefined;
  if (theme.grid && !isNil(theme.grid[key])) {
    return theme.grid[key];
  }
  return defaultConfig[key];
};