import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["touched", "valid", "children", "config"];
var __jsx = React.createElement;
import React from 'react';
import { useTheme } from '@mui/material/styles';
import CheckIcon from '../../assets/graphics/icons/checkmark.svg';
import CloseIcon from '../../assets/graphics/icons/close-icon-small.svg';
import { useTestIds } from './ValidationIndicator.model';
import { Icon, Indicator } from './ValidationIndicator.style';
export const ValidationIndicator = _ref => {
  let {
      touched,
      valid,
      children,
      config
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  const theme = useTheme();
  const testIds = useTestIds(props);
  const defaultConfig = {
    invalid: _objectSpread({
      icon: CloseIcon,
      color: theme.palette.error.light
    }, config === null || config === void 0 ? void 0 : config.invalid),
    valid: _objectSpread({
      icon: CheckIcon,
      color: theme.palette.success.light
    }, config === null || config === void 0 ? void 0 : config.valid)
  };
  return __jsx(Indicator, testIds.base.attr, __jsx(Icon, _extends({
    touched: touched,
    color: valid ? defaultConfig.valid.color : defaultConfig.invalid.color,
    icon: valid ? defaultConfig.valid.icon : defaultConfig.invalid.icon
  }, testIds.icon.attr)), __jsx(_Typography, _extends({
    variant: "caption",
    color: "textSecondary"
  }, testIds.label.attr), children));
};