import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import dayjs from 'dayjs';
import numeral from 'numeral';
import { bankAccountGateActions, gateType, gateStatus, gateOwner, GateActions, PromotionType, PaymentMethodType, isPromoType } from '@yieldstreet/platform-kit';
import { allocationConfirmedAlternativeMonthlyCadenceCopy } from './investmentUpdates.model';
import { interpolate } from '../utils';
const formattedDate = date => {
  if (!date) {
    return '';
  }
  let newDate = dayjs(date);
  if (typeof date === 'number') {
    const dateLength = date.toString().length;
    if (dateLength === 10) {
      newDate = dayjs.unix(date);
    }
  }
  return newDate.isSame(new Date(), 'year') ? newDate.format('MMM D') : newDate.format('MMM D, YYYY');
};
const getAIQStatus = socketData => {
  var _socketData$investmen;
  const AIQGate = socketData === null || socketData === void 0 ? void 0 : (_socketData$investmen = socketData.investmentGates) === null || _socketData$investmen === void 0 ? void 0 : _socketData$investmen.find(gate => gate.type === gateType.aiq);
  if (AIQGate) {
    const {
      owner,
      status,
      timestamp
    } = AIQGate;
    let aiqStatus = {
      date: timestamp || ''
    };
    if (status === gateStatus.failed) {
      return _objectSpread(_objectSpread({}, aiqStatus), {}, {
        status: 'AIQfailed'
      });
    }
    if (owner === gateOwner.ys && status === gateStatus.active) {
      return _objectSpread(_objectSpread({}, aiqStatus), {}, {
        status: 'AIQPending'
      });
    }
    if (owner === gateOwner.user && status !== gateStatus.cleared) {
      return _objectSpread(_objectSpread({}, aiqStatus), {}, {
        status: 'AIQMissing'
      });
    }
  }
  return undefined;
};
const getPaymentFailure = (depositGate, allocationPaymentGate) => {
  if ((depositGate === null || depositGate === void 0 ? void 0 : depositGate.status) === gateStatus.failed && (depositGate === null || depositGate === void 0 ? void 0 : depositGate.owner) === gateOwner.user) {
    return {
      status: true,
      date: depositGate.timestamp || ''
    };
  }
  if ((allocationPaymentGate === null || allocationPaymentGate === void 0 ? void 0 : allocationPaymentGate.status) === gateStatus.failed && (allocationPaymentGate === null || allocationPaymentGate === void 0 ? void 0 : allocationPaymentGate.owner) === gateOwner.user) {
    return {
      status: true,
      date: allocationPaymentGate.timestamp || ''
    };
  }
  return false;
};
const getSuccessStatus = (type, socketData) => {
  var _socketData$investmen2, _socketData$investmen3, _socketData$investmen4;
  const gate = socketData === null || socketData === void 0 ? void 0 : (_socketData$investmen2 = socketData.investmentGates) === null || _socketData$investmen2 === void 0 ? void 0 : _socketData$investmen2.find(gate => gate.type === type);
  const dateConfirmed = socketData === null || socketData === void 0 ? void 0 : (_socketData$investmen3 = socketData.investment) === null || _socketData$investmen3 === void 0 ? void 0 : _socketData$investmen3.dateConfirmed;
  const dateActive = socketData === null || socketData === void 0 ? void 0 : (_socketData$investmen4 = socketData.investment) === null || _socketData$investmen4 === void 0 ? void 0 : _socketData$investmen4.dateActive;
  const date = type === gateType.requestNotAllocated ? dateConfirmed : dateActive;
  if ((gate === null || gate === void 0 ? void 0 : gate.status) === gateStatus.cleared) {
    return {
      status: true,
      date: date || ''
    };
  }
  return false;
};
const getRequestReceivedTitle = socketData => {
  const {
    promotion
  } = socketData || {};
  if (promotion && isPromoType(PromotionType.FREE_INVESTMENT, promotion)) {
    return 'Your free shares will go active soon';
  }
  return 'Investment request received';
};
const getRequestReceivedDescription = _ref => {
  let {
    socketDataPaymentMethod,
    depositInitiated,
    wireTransfer,
    ACHPayment,
    rolloverUsed,
    anticipatedSettlementDate,
    promotion
  } = _ref;
  // Free investment promotion
  if (promotion && isPromoType(PromotionType.FREE_INVESTMENT, promotion)) {
    return 'You’ve claimed your free shares and we will start processing the request.';
  }

  // Rollover used
  if (rolloverUsed) {
    return 'Since you opted to rollover an existing investment, we will process your investment as soon as the funds from the principal rolling over is processed.';
  }
  // Sufficient wallet balance
  if (socketDataPaymentMethod === 'SYNP' && !depositInitiated) {
    return 'We have received your investment request and will start processing it as soon as we pull the funds from your Yieldstreet wallet.';
  }
  // Deposit initiated to fund wallet
  if (depositInitiated) {
    return `We have received your investment request and will start processing after we've received your funds. Your incoming deposit is expected to settle on ${anticipatedSettlementDate} and will be used to fund your investment.`;
  }
  // Wire or ACH (no wallet) payment
  if (wireTransfer || ACHPayment) {
    return `We have received your investment request and will start processing after we've received your funds.`;
  }
  return 'We have received your investment request and will start processing soon.';
};
export const getInvestmentUpdates = _ref2 => {
  var _allocationPaymentGat, _allocationPaymentGat2;
  let {
    socketData,
    isConfirmInvestmentScreen = false,
    iraFundingInstructions,
    iraPurchaseFailedInstructions,
    options,
    shouldShowAlternativeCopy = false
  } = _ref2;
  const {
    investment,
    investmentGates,
    paymentMethod,
    promotion
  } = socketData || {};
  let progressTableData = [];
  const signedSuccessfully = ['SGND', 'ALOC', 'ACTV'].includes((investment === null || investment === void 0 ? void 0 : investment.status) || 'SGND') || isConfirmInvestmentScreen;
  const rolloverUsed = investment === null || investment === void 0 ? void 0 : investment.rollover;
  const noSourceBankAccountGate = investmentGates === null || investmentGates === void 0 ? void 0 : investmentGates.find(gate => gate.type === gateType.noSourceBankAccount);
  const requiresFundsGate = investmentGates === null || investmentGates === void 0 ? void 0 : investmentGates.find(gate => gate.type === gateType.insufficientwalletbalance || gate.type === gateType.paymentNotInitialized);
  const depositInitiated = investmentGates === null || investmentGates === void 0 ? void 0 : investmentGates.find(gate => gate.type === gateType.depositPaymentPending);
  const allocationPaymentGate = investmentGates === null || investmentGates === void 0 ? void 0 : investmentGates.find(gate => gate.type === gateType.allocationPaymentPending);
  const twoDayDelay = (investment === null || investment === void 0 ? void 0 : investment.processOn) === 2;
  const wireTransfer = !iraFundingInstructions && (paymentMethod === PaymentMethodType.WIRE || (allocationPaymentGate === null || allocationPaymentGate === void 0 ? void 0 : (_allocationPaymentGat = allocationPaymentGate.attributes) === null || _allocationPaymentGat === void 0 ? void 0 : _allocationPaymentGat.paymentMethod) === PaymentMethodType.WIRE);
  const ACHPayment = paymentMethod === PaymentMethodType.ACH || (allocationPaymentGate === null || allocationPaymentGate === void 0 ? void 0 : (_allocationPaymentGat2 = allocationPaymentGate.attributes) === null || _allocationPaymentGat2 === void 0 ? void 0 : _allocationPaymentGat2.paymentMethod) === PaymentMethodType.ACH;
  const AIQstatus = getAIQStatus(socketData);
  const paymentFailure = getPaymentFailure(allocationPaymentGate, depositInitiated);
  const allocationConfirmed = getSuccessStatus(gateType.requestNotAllocated, socketData);
  const investmentActive = getSuccessStatus(gateType.requestNotActive, socketData);
  const bankAccountLinked = getSuccessStatus(gateType.bankAccountPending, socketData);
  const anticipatedSettlementDate = formattedDate(investment === null || investment === void 0 ? void 0 : investment.expectedDpstPaymentSettlementDate);
  const template = allocationConfirmedAlternativeMonthlyCadenceCopy[wireTransfer ? PaymentMethodType.WIRE : PaymentMethodType.ACH].pizzaTracker;
  const allocationConfirmedAlternativeCopy = interpolate(template, {
    amount: numeral((investment === null || investment === void 0 ? void 0 : investment.amount) || 0).format('($0,0.00)')
  }, true);

  // Investment is received
  if (signedSuccessfully) {
    progressTableData.push({
      date: isConfirmInvestmentScreen ? dayjs().format('MMM D') : formattedDate(investment === null || investment === void 0 ? void 0 : investment.dateSubmitted),
      title: getRequestReceivedTitle(socketData),
      description: getRequestReceivedDescription({
        socketDataPaymentMethod: paymentMethod,
        depositInitiated,
        wireTransfer,
        ACHPayment,
        rolloverUsed,
        anticipatedSettlementDate,
        promotion
      }),
      expandable: false
    });
  }
  const bankAccountGate = investmentGates === null || investmentGates === void 0 ? void 0 : investmentGates.find(gate => gate.type === gateType.bankAccountPending);

  // For the bank account gate we can identify if user is pending to link a bank or to verify micro-deposits
  // if it's the latter, we can safely assume bank has already been linked
  if (bankAccountLinked && bankAccountLinked.status) {
    progressTableData.push({
      date: formattedDate(bankAccountLinked.date),
      title: 'Link a bank account',
      description: 'You’ve successfully linked your account to Yieldstreet.',
      expandable: false
    });
  } else if (bankAccountGate) {
    var _bankAccountGate$attr;
    const isMicroDepositGate = ((_bankAccountGate$attr = bankAccountGate.attributes) === null || _bankAccountGate$attr === void 0 ? void 0 : _bankAccountGate$attr.bankStep) === bankAccountGateActions.verifyDeposits;
    if (isMicroDepositGate) {
      var _options$GateActions$, _options$GateActions$2, _options$GateActions$3;
      progressTableData.push({
        date: formattedDate(bankAccountGate.timestamp),
        title: 'Link a bank account',
        description: "You've begun the process to link your account.",
        expandable: false,
        error: false,
        button: false
      });
      progressTableData.push({
        date: formattedDate(bankAccountGate.timestamp),
        title: 'Verify micro-deposits',
        description: 'To finalize linking your account, please verify the two amounts deposited into the bank account you linked.',
        expandable: false,
        error: true,
        button: true,
        buttonCTA: 'Verify micro-deposits',
        onCTAClick: options && ((_options$GateActions$ = options[GateActions.BANK_ACCOUNT_PENDING]) === null || _options$GateActions$ === void 0 ? void 0 : _options$GateActions$.onCTAClick),
        buttonLoading: options && ((_options$GateActions$2 = options[GateActions.BANK_ACCOUNT_PENDING]) === null || _options$GateActions$2 === void 0 ? void 0 : _options$GateActions$2.buttonLoading),
        buttonDisabled: options && ((_options$GateActions$3 = options[GateActions.BANK_ACCOUNT_PENDING]) === null || _options$GateActions$3 === void 0 ? void 0 : _options$GateActions$3.buttonLoading),
        mobileNavigation: 'mole.BankAccountMicroDeposit'
      });
    } else {
      var _options$GateActions$4;
      progressTableData.push({
        date: formattedDate(bankAccountGate.timestamp),
        title: 'Link a bank account',
        description: "You'll need to link a bank account to fund your investment",
        expandable: false,
        error: true,
        button: true,
        buttonCTA: 'Link a bank account',
        onCTAClick: options && ((_options$GateActions$4 = options[GateActions.BANK_ACCOUNT_PENDING]) === null || _options$GateActions$4 === void 0 ? void 0 : _options$GateActions$4.onCTAClick),
        mobileNavigation: 'mole.BankMethodChoices',
        passProps: {
          investorAccountId: investment === null || investment === void 0 ? void 0 : investment.investorAccountId
        }
      });
    }
  }
  if (depositInitiated) {
    progressTableData.push({
      date: formattedDate(depositInitiated === null || depositInitiated === void 0 ? void 0 : depositInitiated.timestamp),
      title: 'Transfer initiated',
      description: 'Once your deposit arrives at Yieldstreet, your investment will be active.',
      expandable: false
    });
  } else if (requiresFundsGate && requiresFundsGate.status !== gateStatus.cleared && requiresFundsGate.owner !== gateOwner.ys && !ACHPayment) {
    var _options$GateActions$5, _options$GateActions$6, _options$GateActions$7, _options$GateActions$8, _options$GateActions$9;
    progressTableData.push({
      date: dayjs().format('MMM D'),
      title: 'Fund your investment',
      description: 'In order to make your first investment, select which of the accounts you just linked and initiate your investment funding',
      button: true,
      buttonCTA: 'Submit transfer',
      mobileNavigation: 'mole.TrackInvestment',
      onCTAClick: options && ((_options$GateActions$5 = options[GateActions.FUND_INVESTMENT]) === null || _options$GateActions$5 === void 0 ? void 0 : _options$GateActions$5.onCTAClick),
      children: options && ((_options$GateActions$6 = options[GateActions.FUND_INVESTMENT]) === null || _options$GateActions$6 === void 0 ? void 0 : _options$GateActions$6.children),
      forcedOpen: true,
      expandable: false,
      startOpen: true,
      error: true,
      errorMessage: options && ((_options$GateActions$7 = options[GateActions.FUND_INVESTMENT]) === null || _options$GateActions$7 === void 0 ? void 0 : _options$GateActions$7.errorMessage),
      buttonLoading: options && ((_options$GateActions$8 = options[GateActions.FUND_INVESTMENT]) === null || _options$GateActions$8 === void 0 ? void 0 : _options$GateActions$8.buttonLoading),
      buttonDisabled: options && ((_options$GateActions$9 = options[GateActions.FUND_INVESTMENT]) === null || _options$GateActions$9 === void 0 ? void 0 : _options$GateActions$9.buttonDisabled)
    });
  }
  if (noSourceBankAccountGate && noSourceBankAccountGate.status !== gateStatus.cleared && noSourceBankAccountGate.owner !== gateOwner.ys) {
    var _options$GateActions$10, _options$GateActions$11, _options$GateActions$12, _options$GateActions$13, _options$GateActions$14;
    progressTableData.push({
      date: dayjs().format('MMM D'),
      title: 'Add funding source',
      description: 'In order to make your first investment, select which of the accounts you just linked and initiate your investment funding',
      button: true,
      buttonCTA: 'Link Account',
      mobileNavigation: 'mole.TrackInvestment',
      onCTAClick: options && ((_options$GateActions$10 = options[GateActions.NO_SOURCE_BANK_ACCOUNT]) === null || _options$GateActions$10 === void 0 ? void 0 : _options$GateActions$10.onCTAClick),
      children: options && ((_options$GateActions$11 = options[GateActions.NO_SOURCE_BANK_ACCOUNT]) === null || _options$GateActions$11 === void 0 ? void 0 : _options$GateActions$11.children),
      forcedOpen: true,
      expandable: false,
      startOpen: true,
      error: true,
      errorMessage: options && ((_options$GateActions$12 = options[GateActions.NO_SOURCE_BANK_ACCOUNT]) === null || _options$GateActions$12 === void 0 ? void 0 : _options$GateActions$12.errorMessage),
      buttonLoading: options && ((_options$GateActions$13 = options[GateActions.NO_SOURCE_BANK_ACCOUNT]) === null || _options$GateActions$13 === void 0 ? void 0 : _options$GateActions$13.buttonLoading),
      buttonDisabled: options && ((_options$GateActions$14 = options[GateActions.NO_SOURCE_BANK_ACCOUNT]) === null || _options$GateActions$14 === void 0 ? void 0 : _options$GateActions$14.buttonDisabled)
    });
  }
  const rolloverPendingGate = investmentGates === null || investmentGates === void 0 ? void 0 : investmentGates.find(gate => gate.type === gateType.rolloverPending);

  // Deposit initiated gate
  if (depositInitiated) {
    var _options$GateActions$15;
    const depositDate = !isConfirmInvestmentScreen ? depositInitiated === null || depositInitiated === void 0 ? void 0 : depositInitiated.timestamp : undefined;
    const depositChildren = options && ((_options$GateActions$15 = options[GateActions.DEPOSIT_PAYMENT_PENDING]) === null || _options$GateActions$15 === void 0 ? void 0 : _options$GateActions$15.children);
    progressTableData.push({
      date: isConfirmInvestmentScreen ? dayjs().format('MMM D') : formattedDate(depositDate),
      title: twoDayDelay ? 'Deposit initiated + 2-day delay' : 'Deposit initiated',
      description: twoDayDelay ? 'Since your Wallet did not have sufficient funds, we queued a transfer of funds. As requested, we will initiate a deposit in 2 business days.' : 'Since your Wallet did not have sufficient funds, we queued a transfer of funds.',
      expandable: true,
      children: !!depositChildren ? depositChildren : null
    });
  }
  if (iraFundingInstructions && !iraPurchaseFailedInstructions) {
    progressTableData.push({
      date: dayjs().format('MMM D'),
      title: 'Wire transfer required from Equity Trust Company',
      description: iraFundingInstructions,
      error: false,
      expandable: false
    });
  }
  if (iraFundingInstructions && iraPurchaseFailedInstructions) {
    var _options$GateActions$16;
    progressTableData.push({
      date: dayjs().format('MMM D'),
      title: 'Purchase request failed',
      description: iraPurchaseFailedInstructions,
      error: true,
      expandable: false,
      button: true,
      buttonCTA: 'Contact us',
      onCTAClick: options && ((_options$GateActions$16 = options[GateActions.ETC_PURCHASE_REQUEST_FAILED]) === null || _options$GateActions$16 === void 0 ? void 0 : _options$GateActions$16.onCTAClick)
    });
  }

  // If payment method is WIRE
  if (wireTransfer) {
    var _options$GateActions$17;
    const date = formattedDate(investment === null || investment === void 0 ? void 0 : investment.dateSubmitted);
    const wireChildren = options && ((_options$GateActions$17 = options[GateActions.WIRE_TRANSFER]) === null || _options$GateActions$17 === void 0 ? void 0 : _options$GateActions$17.children);
    progressTableData.push({
      date: isConfirmInvestmentScreen ? dayjs().format('MMM D') : date,
      title: 'Wire transfer required',
      description: shouldShowAlternativeCopy ? 'Before we can process your investment, we must receive your funds via wire transfer. Using the instructions below, please wire funds prior to the 15th of next month to avoid any cancellation of your investment.' : 'Before we can process your investment, we must receive your funds via wire transfer. Using the instructions below, please wire funds in the next 5 business days to avoid any cancellation of your investment.',
      expandable: true,
      error: true,
      children: !!wireChildren ? wireChildren : null,
      startOpen: true
    });
  }

  // Or ACH
  if (ACHPayment && !rolloverPendingGate) {
    var _options$GateActions$18;
    const date = formattedDate(investment === null || investment === void 0 ? void 0 : investment.dateSubmitted);
    const achChildren = options && ((_options$GateActions$18 = options[GateActions.ACH_PAYMENT]) === null || _options$GateActions$18 === void 0 ? void 0 : _options$GateActions$18.children);
    if (!shouldShowAlternativeCopy) {
      progressTableData.push({
        date: isConfirmInvestmentScreen ? dayjs().format('MMM D') : date,
        title: 'ACH Transfer',
        description: `You've authorized us to initiate a withdrawal from your bank account. Please note that any error or issues with this transfer may result in the cancellation of your investment request.`,
        expandable: true,
        children: !!achChildren ? achChildren : null
      });
    }
  }

  // Below we have messages that are related to the payment/AIQ status; If there are no blockers here we push the final message
  if (paymentFailure && paymentFailure.status) {
    var _options$GateActions$19;
    progressTableData.push({
      date: formattedDate(paymentFailure.date),
      title: 'Deposit payment failed',
      description: 'Your deposit to fund this investment has failed. Please retry your deposit in order to proceed.',
      expandable: false,
      error: true,
      button: true,
      buttonCTA: 'Transfer funds',
      onCTAClick: options && ((_options$GateActions$19 = options[GateActions.PAYMENT_FAILURE]) === null || _options$GateActions$19 === void 0 ? void 0 : _options$GateActions$19.onCTAClick),
      mobileNavigation: 'mole.TransferFundsNavigator'
    });
  }
  if ((AIQstatus === null || AIQstatus === void 0 ? void 0 : AIQstatus.status) === 'AIQPending') {
    progressTableData.push({
      date: formattedDate(AIQstatus.date),
      title: 'Accreditation status pending',
      description: 'We are currently reviewing your accreditation status based on the documents previously provided. We will reach out if there are any issues with verifying your accreditation status.',
      expandable: false
    });
  }
  if ((AIQstatus === null || AIQstatus === void 0 ? void 0 : AIQstatus.status) === 'AIQfailed') {
    var _options$GateActions$20;
    progressTableData.push({
      date: formattedDate(AIQstatus.date),
      title: 'Accreditation status failed',
      description: 'Based on the documentation provided, we cannot verify you as an accredited investor. Please submit additional documentation to verify your accreditation status. If we do not receive your accreditation documents within 5 business days, we may cancel your investment request. If you have any questions, please reach out to investor relations.',
      expandable: false,
      error: true,
      button: true,
      buttonCTA: 'Upload documents',
      onCTAClick: options && ((_options$GateActions$20 = options[GateActions.AIQ_FAILED]) === null || _options$GateActions$20 === void 0 ? void 0 : _options$GateActions$20.onCTAClick),
      mobileNavigation: 'mole.SettingsContainer'
    });
  }
  if ((AIQstatus === null || AIQstatus === void 0 ? void 0 : AIQstatus.status) === 'AIQMissing') {
    var _options$GateActions$21;
    progressTableData.push({
      date: formattedDate(AIQstatus.date),
      title: 'Accreditation documents required',
      description: 'Per SEC requirements, please verify your accreditation status in order for Yieldstreet to process your investment request. If we do not receive your accreditation documents within 5 business days, we may cancel your investment request. If you have any questions, please reach out to investor relations.',
      expandable: false,
      error: true,
      button: true,
      buttonCTA: 'Verify accreditation',
      onCTAClick: options && ((_options$GateActions$21 = options[GateActions.AIQ_MISSING]) === null || _options$GateActions$21 === void 0 ? void 0 : _options$GateActions$21.onCTAClick),
      mobileNavigation: 'mole.SettingsContainer'
    });
  }
  if (allocationConfirmed && allocationConfirmed.status) {
    progressTableData.push({
      date: formattedDate(allocationConfirmed.date),
      title: 'Investment allocation confirmed',
      description: shouldShowAlternativeCopy ? allocationConfirmedAlternativeCopy : 'Congratulations! We have confirmed receipt of your funds, and your allocation has now been confirmed. This investment will go active shortly.',
      expandable: false
    });
  }
  if (investmentActive && investmentActive.status) {
    progressTableData.push({
      date: formattedDate(investmentActive.date),
      title: 'Investment active',
      description: 'Congratulations! Your investment is now active!',
      expandable: false
    });
  }
  if (rolloverPendingGate) {
    progressTableData.push({
      date: formattedDate(rolloverPendingGate.timestamp),
      title: 'Rollover scheduled',
      description: 'We will use the final payment from your maturing investment to fund this rollover.',
      expandable: false,
      error: false,
      button: false
    });
  }
  return progressTableData;
};