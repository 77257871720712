import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { ContentContainer, MainContainer, OfferingCardBaseContainer, TopContainer } from './OfferingCardBase.style';
import { theme2 } from '../../../themes';
import { SCard } from '../OfferingCardV2.style';
export const OfferingCardBase = props => {
  const {
    topComponent,
    mainComponent,
    contentComponent
  } = props;
  return __jsx(SCard, _extends({
    bgColor: theme2.colors.background_card
  }, props), __jsx(OfferingCardBaseContainer, null, __jsx(TopContainer, null, topComponent ? topComponent : null), __jsx(MainContainer, null, mainComponent ? mainComponent : null), __jsx(ContentContainer, null, contentComponent ? contentComponent : null)));
};