var __jsx = React.createElement;
import React from 'react';
import { FormSelectInput } from '@yieldstreet/ui-kit';
import { AdviceQuestionsConfig } from 'utils/advice';
import { FieldSectionWrapper, FieldSectionHeader } from './Fields.style';
const {
  InvestmentStyle,
  PreferredTimeHorizon,
  RiskTolerance
} = AdviceQuestionsConfig;
export const InvestmentPreferencesFields = _ref => {
  let {
    formikProps
  } = _ref;
  return __jsx(React.Fragment, null, __jsx(FieldSectionHeader, {
    description: "This information helps us recommend investments that match your preferred investment approach."
  }, "Investment preferences"), __jsx(FieldSectionWrapper, null, __jsx(FormSelectInput, {
    name: RiskTolerance.key,
    label: RiskTolerance.label,
    options: RiskTolerance.options,
    formikProps: formikProps
  }), __jsx(FormSelectInput, {
    name: InvestmentStyle.key,
    label: InvestmentStyle.label,
    options: InvestmentStyle.options,
    formikProps: formikProps
  }), __jsx(FormSelectInput, {
    name: PreferredTimeHorizon.key,
    label: PreferredTimeHorizon.label,
    options: PreferredTimeHorizon.options,
    formikProps: formikProps
  })));
};