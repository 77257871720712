import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
// Once we start migration we can remove this file, it's just a placeholder as example
// The reducers must be added to configureStore.js on combineReducers
import update from 'immutability-helper';
const defaultInitState = {
  isFetching: false,
  error: false
};
export const createAPIReducer = function (actions, actionKey) {
  let config = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  const {
    successHandler,
    failureHandler,
    initData,
    extendReducer
  } = config;
  const initState = _objectSpread(_objectSpread({}, defaultInitState), initData || {});
  return function () {
    let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initState;
    let action = arguments.length > 1 ? arguments[1] : undefined;
    if (extendReducer) {
      const resultState = extendReducer(state, action);
      if (resultState) {
        return resultState;
      }
    }
    switch (action.type) {
      case actions[`${actionKey}_ATTEMPT`]:
        {
          return update(state, {
            isFetching: {
              $set: true
            },
            error: {
              $set: false
            }
          });
        }
      case actions[`${actionKey}_SUCCESS`]:
        {
          return update(state, _objectSpread({
            isFetching: {
              $set: false
            }
          }, successHandler ? successHandler(action, state) : {
            data: {
              $set: action.response
            }
          }));
        }
      case actions[`${actionKey}_FAILURE`]:
        {
          return update(state, _objectSpread({
            isFetching: {
              $set: false
            },
            error: {
              $set: true
            }
          }, failureHandler ? failureHandler(action, state) : {
            errorData: {
              $set: action.error
            }
          }));
        }
      case actions[`${actionKey}_RESET`]:
        {
          return initState;
        }
      default:
        return state;
    }
  };
};
export default createAPIReducer;