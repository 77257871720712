import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useMemo } from 'react';
import { TransactionsListQueryParams } from '@yieldstreet/tool-kit';
import { useFilters, useQueryFilter } from 'hooks';
import { paginationParams } from 'pages/portfolio/shared/PortfolioFilters';
export const useTransactionsListParams = () => {
  // TODO: update when useQueryFilter types are added/fixed
  // @ts-expect-error
  const {
    query
  } = useQueryFilter(TransactionsListQueryParams);
  const {
    clearAllFilters
  } = useFilters({
    query,
    allQueryKeys: [[TransactionsListQueryParams.Investment], [TransactionsListQueryParams.Type], [TransactionsListQueryParams.Year], [TransactionsListQueryParams.Status]]
  });

  /**
   * Default filters are used when the user first lands on the page wo params.
   */
  const defaultFilters = useMemo(() => ({
    [TransactionsListQueryParams.Investment]: query[TransactionsListQueryParams.Investment] || undefined,
    [TransactionsListQueryParams.Type]: query[TransactionsListQueryParams.Type] || undefined,
    [TransactionsListQueryParams.Year]: query[TransactionsListQueryParams.Year] || undefined,
    [TransactionsListQueryParams.Status]: query[TransactionsListQueryParams.Status] || undefined
  }), [query]);
  const params = useMemo(() => _objectSpread(_objectSpread({}, defaultFilters), paginationParams), [defaultFilters]);
  return {
    params,
    resetParams: clearAllFilters
  };
};