import _Collapse from "@mui/material/Collapse";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useMemo, useState, useEffect } from 'react';
import SuccessIcon from '../../assets/graphics/icons/checkmark.svg';
import CloseIcon from '../../assets/graphics/icons/close.svg';
import WarningIcon from '../../assets/graphics/icons/warning.svg';
import ErrorIcon from '../../assets/graphics/icons/error.svg';
import BoldErrorIcon from '../../assets/graphics/icons/boldError.svg';
import { ExternalLink } from '../../content';
import { scrollToQuery } from '../../utils';
import { useTestIds } from './Notification.model';
import { NotificationWrapper, NotificationInner, NotificationIcon, NotificationAction, NotificationCloseButton, NotificationText, NotificationTitle, NotificationDescription } from './Notification.style';
export const Notification = _ref => {
  let {
    severity,
    title,
    description,
    link,
    linkText,
    inline,
    noMargin,
    width,
    onClick,
    testId = 'notification',
    iconAlignment = 'center',
    showIcon = true,
    scroll = true,
    scrollTo = 'center'
  } = _ref;
  const {
    0: open,
    1: setOpen
  } = useState(true);
  const testIds = useTestIds({
    testId
  });
  useEffect(() => {
    if (scroll) {
      scrollToQuery(testIds.base.selector, scrollTo);
    }
  }, [scroll, scrollTo, testIds]);
  const renderIcon = useMemo(() => {
    let icon;
    switch (severity) {
      case 'success':
        icon = SuccessIcon;
        break;
      case 'warning':
        icon = WarningIcon;
        break;
      case 'error':
        icon = title ? BoldErrorIcon : ErrorIcon;
        break;
    }
    return icon ? __jsx(NotificationIcon, _extends({}, testIds.icon.attr, {
      src: icon,
      alt: `${severity}: ${title}`
    })) : null;
  }, [severity, testIds.icon.attr, title]);
  const renderLink = useMemo(() => {
    if (link) {
      return typeof link === 'string' && linkText ? __jsx(ExternalLink, _extends({}, testIds.link.attr, {
        id: "notification-external-link",
        href: link
      }), linkText) : link;
    }
    return null;
  }, [testIds, link, linkText]);
  return __jsx(_Collapse, _extends({}, testIds.base.attr, {
    in: open
  }), __jsx(NotificationWrapper, {
    severity: severity,
    inline: inline,
    noMargin: noMargin,
    width: width,
    iconAlignment: iconAlignment
  }, __jsx(NotificationInner, null, showIcon && renderIcon, __jsx(NotificationText, null, title && __jsx(NotificationTitle, testIds.title.attr, title), description && __jsx(NotificationDescription, testIds.description.attr, description), renderLink)), !inline && __jsx(NotificationAction, null, __jsx(NotificationCloseButton, testIds.closeButton.attr, __jsx(NotificationCloseButton, {
    onClick: () => {
      onClick && onClick();
      setOpen(false);
    }
  }, __jsx("img", {
    src: CloseIcon,
    alt: "close"
  }))))));
};