var __jsx = React.createElement;
import React from 'react';
import { TableHeaderItem, Tr, Th, THead } from 'pages/portfolio/common/Table';
import { headerItems, testIds } from './TransactionsListDesktopTableHeader.model';
export const TransactionsListDesktopTableHeader = () => __jsx(THead, testIds.base.attr, __jsx(Tr, {
  sx: {
    height: 0
  }
}, headerItems.map((item, idx) => __jsx(Th, {
  key: `${idx}-${item.label}`,
  "data-cy": `${testIds.headerItem.id}-${item.label}`
}, __jsx(TableHeaderItem, {
  label: item.label
})))));