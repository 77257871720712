var __jsx = React.createElement;
import React from 'react';
import { SQRCode, QRCodeContainer } from './QRCode.style';
export const QRCode = _ref => {
  let {
    size = 230,
    value
  } = _ref;
  return __jsx(QRCodeContainer, null, __jsx(SQRCode, {
    value: value,
    size: size,
    level: "M"
  }));
};