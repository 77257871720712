import { InvestorEntityStepVerificationStatus } from '@yieldstreet/platform-kit';
const FailedCopy = {
  title: 'We are not able to verify your identity',
  cta: 'Contact investor relations',
  text: `Your application was denied by Plaid, our identity verification provider. Please
  contact us to resolve.`
};
const PendingCopy = {
  title: 'Your identity verification is in progress',
  cta: 'Contact investor relations',
  text: `Your application is under further review. Please allow up to 24 hours for completion.
  If it takes longer, please contact our investor relations team.`
};
export const copy = {
  [InvestorEntityStepVerificationStatus.PENDING]: PendingCopy,
  [InvestorEntityStepVerificationStatus.FAILED]: FailedCopy
};