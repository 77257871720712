import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["userId", "investorId"],
  _excluded2 = ["investorId", "userId"];
import { restApi, Tags } from '../../common';
import { HttpMethods } from '../../../enums';
export const paymentsApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.PortfolioPayments, Tags.PortfolioPaymentsYears, Tags.PortfolioTransactionPayments]
}).injectEndpoints({
  endpoints: build => {
    const getPaymentActivity = build.query({
      query: _ref => {
        let {
            userId,
            investorId
          } = _ref,
          params = _objectWithoutProperties(_ref, _excluded);
        return {
          url: `/api/portfolio/${userId}/payment-activity`,
          method: HttpMethods.Get,
          params: _objectSpread(_objectSpread({}, !!investorId ? {
            investorAccountId: investorId
          } : {}), params)
        };
      },
      providesTags: [Tags.PortfolioPayments]
    });
    const getPayments = build.query({
      query: _ref2 => {
        let {
            investorId,
            userId
          } = _ref2,
          params = _objectWithoutProperties(_ref2, _excluded2);
        return {
          url: `/a/api/portfolio/payments/${userId}`,
          method: HttpMethods.Get,
          params: _objectSpread({
            investorAccountId: investorId
          }, params)
        };
      },
      providesTags: [Tags.PortfolioTransactionPayments]
    });
    const getPaymentsYears = build.query({
      query: _ref3 => {
        let {
          investorId,
          loanId,
          noteUrlHash,
          page,
          pageSize,
          paymentType,
          returnsOnly,
          status,
          userId,
          managementStrategy
        } = _ref3;
        return {
          url: `/a/api/portfolio/payments/${userId}/available-years`,
          method: HttpMethods.Get,
          params: {
            investorAccountId: investorId,
            loanId,
            noteUrlHash,
            page,
            pageSize,
            paymentType,
            returnsOnly,
            status,
            managementStrategy
          }
        };
      },
      providesTags: [Tags.PortfolioPaymentsYears],
      transformResponse: _ref4 => {
        let {
          years
        } = _ref4;
        return {
          years
        };
      }
    });
    const submitManagedPortfolioDeposit = build.mutation({
      query: data => ({
        url: `/api/advice-engine/managed-portfolio/${data.investorEntityId}`,
        method: HttpMethods.Post,
        data
      })
    });
    return {
      getPaymentActivity,
      getPayments,
      getPaymentsYears,
      submitManagedPortfolioDeposit
    };
  }
});