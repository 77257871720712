var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { media } from '../../../themes';
import { Heading, Paragraph } from '../../../content/text';
export const ImageWithContent = _ref => {
  let {
    title,
    image,
    imageAlt,
    alignment = 'left',
    className,
    children,
    inverse,
    imagePosition = 'top',
    hideImageMobile,
    imageComponent
  } = _ref;
  return __jsx(OuterContainer, {
    className: className,
    alignment: alignment,
    image: image,
    title: title,
    imagePosition: imagePosition
  }, imageComponent && __jsx("div", null, imageComponent), image && __jsx(ImageHolder, {
    hideImageMobile: hideImageMobile,
    className: "image-with-content-image-holder"
  }, __jsx(ImageWrapper, null, __jsx(Image, {
    alt: imageAlt,
    src: image
  }))), __jsx("div", null, __jsx(Headline, {
    type: 4,
    inverse: inverse,
    className: "image-with-content-title"
  }, title), __jsx(Content, {
    size: "small",
    inverse: inverse,
    className: "image-with-content-children"
  }, children)));
};
const OuterContainer = styled.div.withConfig({
  displayName: "ImageWithContent__OuterContainer",
  componentId: "sc-o4442g-0"
})(["text-align:", ";", " position:relative;display:flex;flex-direction:", ";flex-grow:1;flex-basis:0;"], props => props.alignment, media.desktop`
    padding: 0 20px;
  `, props => props.imagePosition === 'top' ? 'column' : 'row');
const Headline = styled(Heading).withConfig({
  displayName: "ImageWithContent__Headline",
  componentId: "sc-o4442g-1"
})(["padding:0;margin:10px 0;"]);
const Content = styled(Paragraph).withConfig({
  displayName: "ImageWithContent__Content",
  componentId: "sc-o4442g-2"
})([""]);
const ImageHolder = styled.div.withConfig({
  displayName: "ImageWithContent__ImageHolder",
  componentId: "sc-o4442g-3"
})(["", " position:relative;height:120px;margin:30px 0;", ""], props => props.hideImageMobile && 'display: none;', media.desktop`
    margin-top: 0;
  `);
const ImageWrapper = styled.div.withConfig({
  displayName: "ImageWithContent__ImageWrapper",
  componentId: "sc-o4442g-4"
})(["position:absolute;width:100%;bottom:0;"]);
const Image = styled.img.withConfig({
  displayName: "ImageWithContent__Image",
  componentId: "sc-o4442g-5"
})(["max-height:120px;"]);