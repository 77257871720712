import { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import isFunction from "lodash/isFunction";
import { useFlagsControl, useAuth } from '@yieldstreet/tool-kit';
import { useSDIRAUser } from 'pages/providers/sdira/hooks';
import { FORK_PAGE_PATH } from 'pages/forkPage/ForkPage.model';
import { useAuthRedirect } from './useAuthRedirect';
import { SIGNUP_ACCREDITATION_ROUTE, SIGNUP_PHONE_ROUTE } from './useAuth.model';
import { getLocationRedirect, getTrackingRedirect } from './useAuth.utils';
export const useAuthCompleted = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const {
    userState,
    isAuthLoading,
    isAuthenticated,
    isAccreditationSubmitted,
    isPhoneSubmitted,
    isSignupSession
  } = useAuth();
  const redirectTo = useAuthRedirect();
  const {
    provider
  } = useSDIRAUser();
  const {
    0: authCompleted,
    1: setAuthCompleted
  } = useState(null);
  const {
    0: authRedirect,
    1: setAuthRedirect
  } = useState(null);
  const handleRedirect = useCallback(redirectRoutes => {
    const redirectRoute = redirectRoutes.find(route => route);
    const route = isFunction(redirectRoute) ? redirectRoute() : redirectRoute;
    redirectTo(route);
  }, [redirectTo]);
  const {
    flags: {
      forkPage,
      signupPhone
    } = {},
    evalFlag
  } = useFlagsControl({
    forkPage: false,
    signupPhone: false
  });
  const forkPageRoute = useCallback(() => {
    if (!provider) {
      evalFlag('forkPage');
      return forkPage ? FORK_PAGE_PATH : undefined;
    }
  }, [forkPage, evalFlag, provider]);
  useEffect(() => {
    if (isAuthenticated && !isAuthLoading && authCompleted) {
      if (!isAccreditationSubmitted) {
        redirectTo(SIGNUP_ACCREDITATION_ROUTE, authRedirect || true);
        return;
      }
      if (!isPhoneSubmitted && signupPhone && !provider) {
        evalFlag('signupPhone');
        if (signupPhone) {
          redirectTo(SIGNUP_PHONE_ROUTE, authRedirect || true);
          return;
        }
      }
      if (authRedirect) {
        redirectTo(authRedirect);
        return;
      }

      // Redirect to into the logged in experience after checking redirect rules
      if (isSignupSession) {
        // Signup
        handleRedirect([getLocationRedirect(location), getTrackingRedirect(), forkPageRoute]);
      } else {
        // Login
        handleRedirect([getLocationRedirect(location)]);
      }
    }
  }, [dispatch, history, location, userState, redirectTo, isAuthLoading, isAuthenticated, isAccreditationSubmitted, isPhoneSubmitted, isSignupSession, authRedirect, authCompleted, handleRedirect, forkPageRoute, signupPhone, evalFlag, provider]);
  return useCallback(redirect => {
    setAuthCompleted(true);
    setAuthRedirect(redirect);
  }, [setAuthCompleted, setAuthRedirect]);
};