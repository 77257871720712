var __jsx = React.createElement;
import React from 'react';
import { Bar, BarContainer, Count, StepCounterContainer } from './StepCounter.style';
export const StepCounter = _ref => {
  let {
    currentStep,
    maxStep
  } = _ref;
  return __jsx(StepCounterContainer, null, __jsx(Count, null, currentStep, " / ", maxStep), __jsx(BarContainer, null, [...Array(maxStep)].map((_e, index) => {
    return __jsx(Bar, {
      key: index,
      complete: index + 1 === currentStep
    });
  })));
};