import _Link from "@mui/material/Link";
import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React, { useMemo, useCallback, useEffect } from 'react';
import { FilterTypeEnum } from '@yieldstreet/tool-kit';
import { DesktopOnly } from '@yieldstreet/ui-kit';
import isNil from "lodash/isNil";
import { TEST_IDS } from './FilterView.model';
import { FilterHeader, FilterHeaderItem, RangeSelectFilterWrapper, RangeSelectFilterSliderInput } from './FilterView.style';
import { getFormattedValueString } from 'utils/filters/filters';
export const RangeSelectFilter = _ref => {
  let {
    onChange,
    value,
    filter
  } = _ref;
  const {
    description = '',
    config,
    type,
    options
  } = filter;
  const {
    min: initMin,
    max: initMax,
    steps = false,
    formatter = v => v
  } = config;
  const {
    0: min,
    1: max
  } = useMemo(() => {
    return [isNil(value === null || value === void 0 ? void 0 : value[0]) ? undefined : +(value === null || value === void 0 ? void 0 : value[0]), isNil(value === null || value === void 0 ? void 0 : value[1]) ? undefined : +(value === null || value === void 0 ? void 0 : value[1])];
  }, [value]);
  const isValid = useMemo(() => {
    let valid = true;
    if (!isNil(min)) {
      valid = min >= initMin;
    }
    if (valid && !isNil(max)) {
      valid = max <= initMax;
    }
    if (valid && !isNil(min) && !isNil(max)) {
      valid = min <= max;
    }
    return valid;
  }, [min, max, initMin, initMax]);
  const reset = useCallback(() => {
    onChange([undefined, undefined]);
  }, [onChange]);
  useEffect(() => {
    if (!isValid) {
      reset();
    }
  }, [isValid, reset]);
  const getFormattedLabel = useCallback(value => {
    return getFormattedValueString({
      value,
      type,
      config,
      options
    });
  }, [type, config, options]);
  const maybeFormattedMarks = useMemo(() => {
    if (!steps) {
      return false;
    }
    if (!Array.isArray(steps)) {
      return steps;
    }
    return steps.map(mark => ({
      label: formatter(mark),
      value: mark
    }));
  }, [formatter, steps]);
  const valueForSlider = useMemo(() => [isNil(min) ? initMin : min, isNil(max) ? initMax : max], [min, max, initMin, initMax]);
  return __jsx("div", {
    "data-cy": TEST_IDS[FilterTypeEnum.Range]
  }, __jsx(DesktopOnly, null, __jsx(FilterHeader, null, __jsx(FilterHeaderItem, null, __jsx(_Typography, {
    variant: "body2"
  }, description)), __jsx(FilterHeaderItem, null, __jsx(_Typography, {
    variant: "body2"
  }, __jsx(_Link, {
    onClick: reset
  }, "Reset"))))), __jsx(RangeSelectFilterWrapper, null, isValid && __jsx(RangeSelectFilterSliderInput, {
    fullWidth: true,
    min: initMin,
    max: initMax,
    step: initMin,
    onChange: onChange,
    marks: maybeFormattedMarks,
    value: valueForSlider,
    formatValue: getFormattedLabel
  })));
};