import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { Tracer } from '@yieldstreet/tool-kit';
import axios from 'axios';
import merge from "lodash/merge";
import fetchErrorHandler from 'utils/fetchErrorHandler';
const apiReduxTracer = new Tracer('api-redux');
export const CALL_API = Symbol('Call API');
export class ErrorWithResponse extends Error {
  constructor(message, payload) {
    super(message);
    this.message = message;
    this.payload = payload;
  }
}
export default (function () {
  let axiosInstance = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : axios;
  return _ref => {
    let {
      dispatch
    } = _ref;
    return next => action => {
      const callAPI = action[CALL_API];
      const isAPICall = typeof callAPI !== 'undefined';
      if (!isAPICall) {
        return next(action);
      }
      const {
        endpoint,
        types,
        options,
        getResponse = r => r,
        attemptPayload,
        successPayload,
        failurePayload
      } = callAPI;
      if (types.length !== 3) {
        throw new Error(`API middleware requires 3 action types, ${types.length} were given.`);
      }
      return apiReduxTracer.traceAsync(span => {
        const [attemptType, responseType, failureType] = types;
        dispatch(_objectSpread(_objectSpread({
          type: attemptType
        }, attemptPayload), {}, {
          span
        }));
        return axiosInstance(_objectSpread(_objectSpread({
          url: endpoint
        }, options), {}, {
          span
        })).then(response => {
          var _response$data;
          span.setAttributes({
            status: response.status
          });
          if ((response === null || response === void 0 ? void 0 : (_response$data = response.data) === null || _response$data === void 0 ? void 0 : _response$data.success) === false) {
            return {
              response: getResponse(response),
              error: response.data
            };
          }
          return getResponse(response);
        }).then(response => {
          if (response && response.error) {
            return dispatch(_objectSpread(_objectSpread({
              response: response.response,
              error: response.error,
              type: failureType
            }, failurePayload), {}, {
              span
            }));
          }
          return dispatch(merge({
            response,
            type: responseType,
            requestPayload: options === null || options === void 0 ? void 0 : options.data,
            span
          }, successPayload));
        }).catch(error => {
          var _error$response, _error$response2, _error$response2$conf, _error$response3, _error$response3$conf;
          const status = error === null || error === void 0 ? void 0 : (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.status;
          span.setAttributes({
            error,
            status
          });
          dispatch(_objectSpread(_objectSpread({
            error: fetchErrorHandler(error),
            beError: error,
            type: failureType
          }, failurePayload), {}, {
            span
          }));

          // eslint-disable-next-line no-console
          console.log(`Error from endpoint ${endpoint} - code ${status} at ${error === null || error === void 0 ? void 0 : (_error$response2 = error.response) === null || _error$response2 === void 0 ? void 0 : (_error$response2$conf = _error$response2.config) === null || _error$response2$conf === void 0 ? void 0 : _error$response2$conf.method} for ${error === null || error === void 0 ? void 0 : (_error$response3 = error.response) === null || _error$response3 === void 0 ? void 0 : (_error$response3$conf = _error$response3.config) === null || _error$response3$conf === void 0 ? void 0 : _error$response3$conf.url} `);
          throw error;
        });
      }, 'dispatchAPI', {
        endpoint,
        types,
        options
      });
    };
  };
});