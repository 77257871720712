import React from "react";
var __jsx = React.createElement;
import { useFormFlow, useApiErrorMessage } from '@yieldstreet/tool-kit';
import { PageLoader } from '@yieldstreet/ui-kit';
import countries from 'i18n-iso-countries';
import map from "lodash/map";
import { useFlowLocation } from 'hooks/formFlow/useFlowLocation';
import { FlowWrapper } from '../FlowWrapper';
import { useOnUrlChange } from '../../CreateInvestorAccount.model';
countries.registerLocale(require('i18n-iso-countries/langs/en.json'));
const countriesNames = countries.getNames('en');
export const countriesOptions = map(countriesNames, (country, key) => ({
  value: key,
  label: country
}));
export const RenderFlowWrapper = _ref => {
  let {
    children,
    formConfig,
    flowConfig,
    submitConfig,
    submitCallback,
    isLoading,
    testIds,
    history,
    showSubtitle,
    onSubmitSuccess
  } = _ref;
  const flowState = useFlowLocation({
    useOnUrlChange
  });
  const {
    Form,
    isFirstStep,
    goToPreviousStep
  } = useFormFlow({
    formConfig,
    flowConfig,
    flowState,
    submitConfig,
    submitCallback,
    onSubmitSuccess
  });
  const updateInvestorErrorMessage = useApiErrorMessage(submitConfig);
  return isLoading ? __jsx(PageLoader, testIds.pageLoader.attr) : __jsx(FlowWrapper, {
    flowConfig: flowConfig,
    step: flowState.step,
    goToPreviousStep: isFirstStep ? () => history.push('/management/accounts') : goToPreviousStep,
    error: updateInvestorErrorMessage,
    showSubtitle: showSubtitle
  }, __jsx(Form, null, children));
};