var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';

/* Utilities */
import { media } from '../../themes/media';
/* Base styles */
import { Paragraph } from '../../content/text';

/* assets */
import studentHouse from './assets/office.png';
import industrial from './assets/plant.png';
import multifamily from './assets/house.png';
import hotel from './assets/pool.png';

/* Shares value properties */
const imageAndSpaceWidth = '182px';

/* =========================================
 *    Desktop industry sector tiles module
 * ========================================= */

export const IndustrySectorsDesktop = () => {
  return __jsx(ParentContainer, null, __jsx(SectorSectionDesktop, null, __jsx(SectorColumn, null, __jsx(SectorCallout, {
    image: hotel,
    imageAlt: "hotel real estate investing",
    text: "Hotel"
  }), __jsx(SectorCallout, {
    image: studentHouse,
    imageAlt: "student housing real estate investing",
    text: "Student Housing"
  })), __jsx(SectorColumn, null, __jsx(SectorCallout, {
    image: multifamily,
    imageAlt: "multifamily real estate investing",
    text: "Multifamily",
    contrast: true
  }), __jsx(SectorCallout, {
    image: industrial,
    imageAlt: "industrial real estate investing, avatar originator yielstreet real estate investing",
    text: "Industrial",
    contrast: true
  }))));
};

/* ===================================
 *    Mobile industry sector tiles
 * =================================== */

export const IndustrySectorsMobile = () => {
  return __jsx(SectorSectionMobile, null, __jsx(SectorCallout, {
    image: hotel,
    imageAlt: "hotel real estate investing",
    text: "Hotel"
  }), __jsx(SectorCallout, {
    image: multifamily,
    imageAlt: "multifamily real estate investing",
    text: "Multifamily",
    orientation: "rtl"
  }), __jsx(SectorCallout, {
    image: studentHouse,
    imageAlt: "student housing real estate investing",
    text: "Student Housing",
    contrast: true
  }), __jsx(SectorCallout, {
    image: industrial,
    imageAlt: "industrial real estate investing, avatar originator yielstreet real estate investing",
    text: "Industrial",
    orientation: "rtl",
    contrast: true
  }));
};

/* ===================================
 *    Sector callout UI element
 * =================================== */

/* shared types */

export const SectorCallout = _ref => {
  let {
    image,
    text,
    imageAlt = '',
    orientation = 'ltr',
    contrast = false
  } = _ref;
  return __jsx(CalloutContainer, {
    flexPosition: orientation
  }, __jsx(CalloutImage, {
    src: image,
    alt: imageAlt
  }), __jsx(CalloutText, {
    inverse: contrast
  }, text));
};

/* ===== Layout UI elements ===== */

const ParentContainer = styled.div.withConfig({
  displayName: "industrySectors__ParentContainer",
  componentId: "sc-k74ogt-0"
})(["display:none;", ""], media.desktop`
    display:flex;
    justify-content:stretch;
    margin-right: 180px;
    width: calc(100% - 180px);
    position: absolute;
    right: 0;
    bottom: 120px;
  `);
const SectorSectionDesktop = styled.div.withConfig({
  displayName: "industrySectors__SectorSectionDesktop",
  componentId: "sc-k74ogt-1"
})(["display:flex;"]);
const SectorSectionMobile = styled.div.withConfig({
  displayName: "industrySectors__SectorSectionMobile",
  componentId: "sc-k74ogt-2"
})(["display:flex;flex-direction:column;margin-bottom:40px;", " ", ""], media.tablet`
    margin-bottom:20px;
  `, media.desktop`
    display:none;
  `);
const SectorColumn = styled.div.withConfig({
  displayName: "industrySectors__SectorColumn",
  componentId: "sc-k74ogt-3"
})(["display:inline-flex;flex-direction:column;justify-content:space-between;&:last-child{transform:translateX(-20%);}"]);

/* ----------------- */

const CalloutContainer = styled.div.withConfig({
  displayName: "industrySectors__CalloutContainer",
  componentId: "sc-k74ogt-4"
})(["display:flex;margin-bottom:20px;align-items:center;flex-direction:", ";&:last-child{margin-bottom:0;}", ""], props => props.flexPosition === 'ltr' ? 'row' : 'row-reverse', media.desktop`
    margin-bottom:0px;
    &:last-child {
      margin-left: ${imageAndSpaceWidth}
    }
  `);

/* ===== Component UI elements ===== */

const CalloutImage = styled.img.withConfig({
  displayName: "industrySectors__CalloutImage",
  componentId: "sc-k74ogt-5"
})(["width:55%;max-width:375px;", ""], media.desktop`
    width:${imageAndSpaceWidth};
  `);

/* ===== Type styles ==== */

const CalloutText = styled(Paragraph).withConfig({
  displayName: "industrySectors__CalloutText",
  componentId: "sc-k74ogt-6"
})(["padding:0px 20px;"]);