import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["center"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { Spinner } from '../spinner';
import { useTestIds } from './PageLoader.model';
export const PageLoader = _ref => {
  let {
      center
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  const testIds = useTestIds(props);
  return __jsx(LoaderContainer, _extends({
    center: center,
    "data-js-empty-loader": true,
    role: "progressbar"
  }, testIds.base.attr), __jsx(Spinner, null));
};
export const LoaderContainer = styled.div.withConfig({
  displayName: "PageLoader__LoaderContainer",
  componentId: "sc-lgapop-0"
})(["position:relative;width:100%;height:100%;", " opacity:0;animation:fade-in ease 0.5s;animation-delay:0.5s;animation-iteration-count:1;animation-fill-mode:forwards;@keyframes fade-in{0%{opacity:0;}100%{opacity:1;}}"], props => props.center ? `
      align-items: center;
      display: flex;
      justify-content: center;
    ` : `
      padding-top: 25vh;
      text-align: center;
    `);