import { HttpMethods } from '../../enums/http';
import { restApi, Tags } from '../common';
import { transformAllInvestorEntityStatusResponse } from './investor-entity-status.utils';
export const investorEntityStatusApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.InvestorEntityStatus]
}).injectEndpoints({
  endpoints: builder => {
    const getAllInvestorEntityStatus = builder.query({
      query: () => {
        return {
          url: '/a/api/investor-entity/all/status',
          method: HttpMethods.Get
        };
      },
      providesTags: [Tags.InvestorEntityStatus],
      transformResponse: transformAllInvestorEntityStatusResponse
    });
    const getInvestorEntityStatus = builder.query({
      query: _ref => {
        let {
          investorEntityId
        } = _ref;
        return {
          url: `/a/api/investor-entity/${investorEntityId}/status`,
          method: HttpMethods.Get
        };
      },
      transformResponse: response => {
        return response.status;
      },
      providesTags: [Tags.InvestorEntityStatus]
    });
    return {
      getAllInvestorEntityStatus,
      getInvestorEntityStatus
    };
  }
});