import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useMemo } from 'react';
import { TierLevels } from '@yieldstreet/platform-kit';
import isEmpty from "lodash/isEmpty";
import lowerCase from "lodash/lowerCase";
import startCase from "lodash/startCase";
import { formatCurrencyWithoutDecimal, formatThousandShortNotation } from '../../../utils/number';
import { getRemainingGracePeriod } from './useTierProgress.util';
export const useTierProgress = _ref => {
  let {
    metaData,
    messageFormatterFn,
    config
  } = _ref;
  return useMemo(() => {
    if (isEmpty(metaData) || !metaData) {
      return {
        start: '',
        end: '',
        message: [],
        currentPortfolioValue: '',
        percentage: 0,
        progressColour: [],
        expiry: null
      };
    }
    const {
      level,
      rangeStart,
      rangeEnd,
      leftForNextTier,
      portfolioValue,
      percentageInTier,
      expiry
    } = metaData;
    const start = formatThousandShortNotation(rangeStart);
    const end = formatThousandShortNotation(rangeEnd);
    const currentPortfolioValue = formatCurrencyWithoutDecimal(portfolioValue);
    const percentage = !expiry ? percentageInTier : 0;
    const progressData = {
      start,
      end,
      currentPortfolioValue,
      percentage
    };
    const tierMessage = {
      level: startCase(lowerCase(level)),
      rangeStart: formatCurrencyWithoutDecimal(rangeStart),
      expiry: expiry && getRemainingGracePeriod(expiry),
      amount: formatCurrencyWithoutDecimal(leftForNextTier)
    };
    switch (level) {
      case TierLevels.GOLD:
        return _objectSpread(_objectSpread({}, progressData), {}, {
          progressColour: config.colours[TierLevels.GOLD],
          message: messageFormatterFn(_objectSpread({
            nextTierLevel: startCase(lowerCase(TierLevels.PLATINUM))
          }, tierMessage))
        });
      case TierLevels.PLATINUM:
        return _objectSpread(_objectSpread({}, progressData), {}, {
          progressColour: config.colours[TierLevels.PLATINUM],
          message: messageFormatterFn(_objectSpread({
            nextTierLevel: startCase(lowerCase(TierLevels.DIAMOND))
          }, tierMessage))
        });
      case TierLevels.DIAMOND:
        return _objectSpread(_objectSpread({}, progressData), {}, {
          end: null,
          start: '$500K+',
          progressColour: config.colours[TierLevels.DIAMOND],
          message: messageFormatterFn(_objectSpread({}, tierMessage))
        });
      default:
        return _objectSpread(_objectSpread({}, progressData), {}, {
          start: '$0',
          progressColour: config.colours[TierLevels.DEFAULT],
          message: messageFormatterFn(_objectSpread({
            nextTierLevel: startCase(lowerCase(TierLevels.GOLD))
          }, tierMessage))
        });
    }
  }, [metaData, messageFormatterFn, config]);
};