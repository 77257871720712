var __jsx = React.createElement;
import React from 'react';
import { Route, Switch, useLocation } from 'react-router';
import { KYCMigrationBankLinking } from './bank-linking';
import { INVESTOR_ENTITY_KYC_MIGRATION_BANK_LINK_PATH } from './KYCMigrationContainer.model';
export const KYCMigrationContainer = () => {
  const location = useLocation();
  return __jsx(Switch, {
    location: location
  }, __jsx(Route, {
    path: INVESTOR_ENTITY_KYC_MIGRATION_BANK_LINK_PATH,
    component: KYCMigrationBankLinking
  }));
};