import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useCallback, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { FormPhoneTextInput, Button, PageLoader } from '@yieldstreet/ui-kit';
import { userProfileApi, userApi, InvestorType, investorEntityApi } from '@yieldstreet/platform-kit';
import { useFormFlow, useApiErrorMessage, useApiEventTracking, BusinessEvent } from '@yieldstreet/tool-kit';
import { useFlowLocation } from 'hooks/formFlow/useFlowLocation';
import { testIds, formValidation } from './Phone.model';
import { InputSecondaryTextWrapper } from './Phone.style';
import { useOnUrlChange, PHONE_STEP } from '../../CreateInvestorAccount.model';
import { ButtonContainer, FormWrapper, SubmitWrapper } from '../../CreateInvestorAccount.style';
import { FlowWrapper } from '../FlowWrapper';
export const Phone = _ref => {
  let {
    flowConfig
  } = _ref;
  const {
    accountType,
    investorEntityId
  } = useParams();
  const history = useHistory();
  const {
    useUpdateUserProfilePhoneMutation
  } = userProfileApi;
  const {
    useUpdateInvestorEntityMutation
  } = investorEntityApi;
  const {
    useGetUserContactInfoQuery
  } = userApi;
  const {
    currentData: userContactData,
    isLoading: isLoadingUserContact
  } = useGetUserContactInfoQuery();
  const [updateInvestorAccount, updateAccountResult] = useUpdateInvestorEntityMutation();
  const [updateUserProfilePhone] = useUpdateUserProfilePhoneMutation();
  const contact = userContactData === null || userContactData === void 0 ? void 0 : userContactData.contact;
  const updateUserPhone = accountType === InvestorType.INDIVIDUAL && !(contact !== null && contact !== void 0 && contact.phone);
  const renderForm = formikProps => __jsx(FormWrapper, testIds.formWrapper.attr, __jsx(InputSecondaryTextWrapper, null, __jsx(FormPhoneTextInput, {
    name: "phoneNumber",
    label: "Phone number",
    formikProps: formikProps,
    fullWidth: true,
    required: true,
    testId: testIds.phoneField.id
  })), __jsx(SubmitWrapper, null, __jsx(ButtonContainer, null, __jsx(Button, _extends({
    type: "submit",
    trackingId: testIds.submitButton.id,
    loading: updateAccountResult.isLoading,
    disabled: updateAccountResult.isLoading
  }, testIds.submitButton.attr), "Continue"))));
  const submitCallback = useCallback(values => {
    if (updateUserPhone) {
      updateUserProfilePhone(values);
    }
    updateInvestorAccount(_objectSpread({
      investorEntityId
    }, values));
  }, [updateUserPhone, investorEntityId, updateInvestorAccount, updateUserProfilePhone]);
  const formPhoneConfig = useMemo(() => ({
    fieldNames: ['phoneNumber'],
    validation: formValidation,
    formikConfig: {
      initialValues: {
        phoneNumber: (contact === null || contact === void 0 ? void 0 : contact.phoneRaw) || '',
        smsEnabled: true,
        requestedDownloadApp: false
      }
    }
  }), [contact]);
  const flowState = useFlowLocation({
    useOnUrlChange
  });
  const {
    Form,
    goToPreviousStep,
    isFirstStep
  } = useFormFlow({
    formConfig: formPhoneConfig,
    flowConfig,
    flowState,
    submitConfig: updateAccountResult,
    submitCallback
  });
  const updateInvestorErrorMessage = useApiErrorMessage(updateAccountResult);
  useApiEventTracking(updateAccountResult, BusinessEvent.INVESTOR_CREATION_SUBMITTED, {
    step: PHONE_STEP
  }, updateInvestorErrorMessage);
  return isLoadingUserContact ? __jsx(PageLoader, null) : __jsx(FlowWrapper, {
    flowConfig: flowConfig,
    step: flowState.step,
    investorAccountType: accountType,
    goToPreviousStep: isFirstStep ? () => history.push('/management/accounts') : goToPreviousStep,
    error: updateInvestorErrorMessage
  }, __jsx(Form, null, formikProps => renderForm(formikProps)));
};