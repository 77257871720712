import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["children", "filled", "padding"];
var __jsx = React.createElement;
import React from 'react';
import { SpacingEnum } from '../../themes';
import { StyledSkeletonContainer } from './Skeleton.style';
export const SkeletonContainer = _ref => {
  let {
      children,
      filled,
      padding = SpacingEnum.s
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  return __jsx(StyledSkeletonContainer, _extends({
    filled: filled,
    padding: padding
  }, props), children);
};