export const documentTypes = {
  ACCR: 'Letter from Third Party',
  TAXR: 'Tax Return',
  W2: 'Form W-2: Wage and Tax Statement',
  T99: 'Form 1099: Miscellaneous Income',
  K1: 'Form 1065 (Schedule K-1)',
  TSOT: 'Other Tax Statement',
  ASBR: 'Asset Statement: Brokerage',
  ASBA: 'Asset Statement: Bank',
  ASIR: 'Asset Statement: IRA',
  ASOT: 'Asset Statement: Other',
  CSRP: 'Credit Statement: Credit Report',
  CSOT: 'Credit Statement: Other',
  LEGL: 'Legal Agreement'
};