import Big from 'big.js';
import isNumber from "lodash/isNumber";
import isString from "lodash/isString";
import { BASE_RADIUS } from './theme.model';
const multiplyBaseRadius = multiplier => `${new Big(multiplier).mul(BASE_RADIUS)}px`;
export const getBorderRadius = multiplier => {
  if (isNumber(multiplier)) {
    return multiplyBaseRadius(multiplier);
  }
  if (isString(multiplier)) {
    const asNumber = +multiplier;
    return isNumber(asNumber) ? multiplyBaseRadius(asNumber) : 0;
  }
  return 0;
};