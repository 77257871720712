import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React, { useCallback } from 'react';
import { useModalContext, NewModal } from '@yieldstreet/ui-kit';
import { InfoModal } from '../InfoModal';
import questionIcon from 'assets/icons/images/question.svg';
import { LiquidityContent, LiquidityWrapper, QuestionTip } from './LiquidityDescription.style';
import { DATA_CY_PREFIX, LIQUIDITY_MODAL_ID } from './LiquidityDescription.model';
const LiquidityDescription = _ref => {
  let {
    description
  } = _ref;
  const {
    showModal
  } = useModalContext(LIQUIDITY_MODAL_ID);
  const handleInfoModal = useCallback(() => {
    showModal(LIQUIDITY_MODAL_ID);
  }, [showModal]);
  return __jsx(LiquidityWrapper, {
    "data-cy": `${DATA_CY_PREFIX}-wrapper`
  }, __jsx(LiquidityContent, null, __jsx(_Typography, {
    component: "p",
    variant: "body2",
    sx: theme => ({
      color: theme.palette.text.paragraphPrimary.inverse,
      pl: 2.5
    })
  }, description === null || description === void 0 ? void 0 : description.title)), __jsx(QuestionTip, {
    src: questionIcon,
    onClick: () => handleInfoModal()
  }), __jsx(NewModal, {
    modalId: LIQUIDITY_MODAL_ID
  }, __jsx(InfoModal, {
    tooltip: description
  })));
};
export default LiquidityDescription;