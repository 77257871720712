import isString from "lodash/isString";
import { cookiesGet } from 'redux-cookies';
import { v4 as uuidv4 } from 'uuid';
import { setAnalyticsAnonymousId, setAnalyticsUserId } from 'store/actions/user';
import { setSegmentAnonymousId } from 'utils/thirdPartyCode';
import { isServer } from 'utils/env';
export const setAnalyticsIds = function (dispatch) {
  let userState = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const cookieAnonymousId = parseUUID(parseSegmentCookie(dispatch(cookiesGet('ajs_anonymous_id'))));
  const cookieUserId = parseSegmentCookie(dispatch(cookiesGet('ajs_user_id')));
  const {
    analyticsAnonymousId: stateAnonymousId,
    analyticsUserId: stateUserId
  } = userState;
  const analyticsUserId = stateUserId || cookieUserId;
  let anonymousId = stateAnonymousId || cookieAnonymousId;
  if (!anonymousId || !isString(anonymousId)) {
    anonymousId = uuidv4();
  }
  dispatch(setAnalyticsAnonymousId(anonymousId));
  dispatch(setAnalyticsUserId(analyticsUserId));
  if (!isServer) {
    setSegmentAnonymousId(anonymousId);
  }
};
const parseSegmentCookie = value => {
  const trimChar = '%22';
  if (value && value.length) {
    let parsedValue = value;
    parsedValue = parsedValue.endsWith(trimChar) ? parsedValue.slice(0, -trimChar.length) : parsedValue;
    parsedValue = parsedValue.startsWith(trimChar) ? parsedValue.slice(trimChar.length) : parsedValue;
    return parsedValue;
  }
  return null;
};
const parseUUID = value => {
  if (value) {
    const UUIDRegex = /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/;
    const parseMatch = UUIDRegex.exec(value);
    return parseMatch ? parseMatch.shift() : null;
  }
};