var __jsx = React.createElement;
import React from 'react';
import { PortfolioCard } from 'pages/portfolio/common/PortfolioCard';
import { TransactionsListCardHeader } from './TransactionsListCardHeader';
import { TransactionsListCardBody } from './TransactionsListCardBody';
import { TransactionsListCardFooter } from './TransactionsListCardFooter';
import { testIds } from './TransactionsListCard.model';
export const TransactionsListCard = _ref => {
  let {
    isFetching,
    transaction,
    showFooter = true
  } = _ref;
  return __jsx(PortfolioCard, {
    testId: testIds.transactionsListCard.id
  }, __jsx(TransactionsListCardHeader, {
    transaction: transaction,
    isFetching: isFetching
  }), __jsx(TransactionsListCardBody, {
    transaction: transaction,
    isFetching: isFetching
  }), showFooter && __jsx(TransactionsListCardFooter, {
    transaction: transaction,
    isFetching: isFetching
  }));
};