var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { media } from '../../../themes';
import { Paragraph, Label } from '../../../content/text';
import { LinkStyles } from '../../../content/link/styles';
export const AssetClassTotalListItem = _ref => {
  let {
    title,
    children
  } = _ref;
  return __jsx(ItemHolder, null, __jsx(ListTitle, null, title), __jsx(ListContent, null, children));
};
const ItemHolder = styled.div.withConfig({
  displayName: "AssetClassTotalPhotoDetail__ItemHolder",
  componentId: "sc-1j1yibl-0"
})(["display:flex;flex-wrap:nowrap;margin-bottom:5px;"]);
const ListTitle = styled(Label).withConfig({
  displayName: "AssetClassTotalPhotoDetail__ListTitle",
  componentId: "sc-1j1yibl-1"
})([""]);
const ListContent = styled(Label).withConfig({
  displayName: "AssetClassTotalPhotoDetail__ListContent",
  componentId: "sc-1j1yibl-2"
})(["flex-grow:1;text-align:right;"]);
export const AssetClassTotalPhotoDetail = _ref2 => {
  let {
    title,
    image,
    alignment = 'left',
    contentAlignment = 'left',
    category,
    children,
    inverse
  } = _ref2;
  return __jsx(OuterContainer, null, __jsx(ImageHolder, {
    alignment: alignment
  }, image && __jsx(ImageWrapper, null, image)), __jsx(ContentHolder, null, category && __jsx(Category, {
    alignment: alignment
  }, category), __jsx(Title, {
    semiBold: true,
    secondary: true,
    alignment: alignment,
    inverse: inverse
  }, title), __jsx(Content, {
    contentAlignment: contentAlignment
  }, children)));
};
const OuterContainer = styled.div.withConfig({
  displayName: "AssetClassTotalPhotoDetail__OuterContainer",
  componentId: "sc-1j1yibl-3"
})(["background:", ";flex-grow:1;flex-basis:0;", ""], props => props.theme.colors.background_default, media.desktop`
    display: flex;
    flex-direction: column;
    max-width: 379px;
    &:first-child { 
      margin: 0 10px 0 0;
    }
    &:last-child { 
      margin: 0 0 0 10px;
    }
    &:not(:first-child):not(:last-child) {
      margin: 0 10px;
    }
  `);
const Title = styled(Paragraph).withConfig({
  displayName: "AssetClassTotalPhotoDetail__Title",
  componentId: "sc-1j1yibl-4"
})(["text-align:", ";margin:30px 0 10px 0;width:80%;flex-grow:1;", ""], props => props.alignment, media.desktop`
    margin-top: 0;
  `);
const ContentHolder = styled.div.withConfig({
  displayName: "AssetClassTotalPhotoDetail__ContentHolder",
  componentId: "sc-1j1yibl-5"
})(["padding:20px;display:flex;flex-grow:1;flex-direction:column;"]);
const ImageHolder = styled.div.withConfig({
  displayName: "AssetClassTotalPhotoDetail__ImageHolder",
  componentId: "sc-1j1yibl-6"
})(["text-align:", ";"], props => props.alignment);
const Content = styled.div.withConfig({
  displayName: "AssetClassTotalPhotoDetail__Content",
  componentId: "sc-1j1yibl-7"
})(["margin:10px 0 0 0;text-align:", ";button,a{", " margin-top:40px;display:inline-block;}"], props => props.contentAlignment, LinkStyles);
const ImageWrapper = styled.div.withConfig({
  displayName: "AssetClassTotalPhotoDetail__ImageWrapper",
  componentId: "sc-1j1yibl-8"
})([""]);
const Category = styled(Label).withConfig({
  displayName: "AssetClassTotalPhotoDetail__Category",
  componentId: "sc-1j1yibl-9"
})(["text-align:", ";display:block;margin-bottom:5px;"], props => props.alignment);