import { CALL_API } from '../middleware/Middleware';
export const INVESTMENT_REQUEST_ATTEMPT = 'ys/investmentRequest/ATTEMPT';
export const INVESTMENT_REQUEST_SUCCESS = 'ys/investmentRequest/SUCCESS';
export const INVESTMENT_REQUEST_FAILURE = 'ys/investmentRequest/FAILURE';
export const submitInvestmentRequest = (investmentRequestId, details, noteUrlHash) => dispatch => {
  const endpoint = `/a/api/investment-request/offerings/${noteUrlHash}/investments/${investmentRequestId}`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [INVESTMENT_REQUEST_ATTEMPT, INVESTMENT_REQUEST_SUCCESS, INVESTMENT_REQUEST_FAILURE],
      getResponse: res => res.data,
      successPayload: {
        investmentRequestId,
        details
      },
      options: {
        method: 'PUT',
        data: details
      }
    }
  });
};
export const SUBMIT_TRANSFER_ATTEMPT = 'ys/submitTransfer/ATTEMPT';
export const SUBMIT_TRANSFER_SUCCESS = 'ys/submitTransfer/SUCCESS';
export const SUBMIT_TRANSFER_FAILURE = 'ys/submitTransfer/FAILURE';
export const submitTransfer = (investmentRequestId, bankAccount, noteUrlHash) => dispatch => {
  const endpoint = `/a/api/investment-request/offerings/${noteUrlHash}/investments/${investmentRequestId}/post`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [SUBMIT_TRANSFER_ATTEMPT, SUBMIT_TRANSFER_SUCCESS, SUBMIT_TRANSFER_FAILURE],
      getResponse: res => res.data,
      options: {
        method: 'POST',
        data: {
          bankAccountId: bankAccount.bankAccountId
        }
      }
    }
  });
};
export const PRE_INVESTMENT_REQUEST_ATTEMPT = 'ys/preInvestmentRequest/ATTEMPT';
export const PRE_INVESTMENT_REQUEST_SUCCESS = 'ys/preInvestmentRequest/SUCCESS';
export const PRE_INVESTMENT_REQUEST_FAILURE = 'ys/preInvestmentRequest/FAILURE';
export const submitPreInvestmentRequest = (noteUrlHash, payload) => dispatch => {
  const endpoint = `/a/api/investment-request/offerings/${noteUrlHash}/investments/pre`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [PRE_INVESTMENT_REQUEST_ATTEMPT, PRE_INVESTMENT_REQUEST_SUCCESS, PRE_INVESTMENT_REQUEST_FAILURE],
      getResponse: res => res.data,
      options: {
        method: 'POST',
        data: payload
      }
    }
  });
};
export const GET_INVESTMENT_REQUEST_ATTEMPT = 'ys/getInvestmentRequest/ATTEMPT';
export const GET_INVESTMENT_REQUEST_SUCCESS = 'ys/getInvestmentRequest/SUCCESS';
export const GET_INVESTMENT_REQUEST_FAILURE = 'ys/getInvestmentRequest/FAILURE';
export const getPreInvestmentRequest = noteUrlHash => dispatch => {
  const endpoint = `/a/api/investment-request/offerings/${noteUrlHash}/investments?status=PREQ`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_INVESTMENT_REQUEST_ATTEMPT, GET_INVESTMENT_REQUEST_SUCCESS, GET_INVESTMENT_REQUEST_FAILURE],
      getResponse: res => res.data,
      options: {
        method: 'GET'
      }
    }
  });
};
export const SET_PRE_INVESTMENT_REQUEST_DETAILS = 'ys/preInvestmentRequest/SET';
export const setPreInvestmentRequestDetails = preInvestmentDetails => dispatch => dispatch({
  type: SET_PRE_INVESTMENT_REQUEST_DETAILS,
  preInvestmentDetails
});
export const CLEAR_PRE_INVESTMENT_REQUEST_DETAILS = 'ys/preInvestmentRequest/CLEAR';
export const clearPreInvestmentRequestDetails = preInvestmentDetails => dispatch => dispatch({
  type: CLEAR_PRE_INVESTMENT_REQUEST_DETAILS,
  preInvestmentDetails
});
export const RESET_INVESTMENT_REQUEST = 'ys/investmentRequest/RESET';
export const reset = () => dispatch => dispatch({
  type: RESET_INVESTMENT_REQUEST
});
export const GET_OFFERING_INFO_ATTEMPT = 'ys/getOfferingInfo/ATTEMPT';
export const GET_OFFERING_INFO_SUCCESS = 'ys/getOfferingInfo/SUCCESS';
export const GET_OFFERING_INFO_FAILURE = 'ys/getOfferingInfo/FAILURE';
export const getOfferingInfo = noteUrlHash => dispatch => {
  const endpoint = `/a/api/investment/${noteUrlHash}/investor`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_OFFERING_INFO_ATTEMPT, GET_OFFERING_INFO_SUCCESS, GET_OFFERING_INFO_FAILURE],
      getResponse: res => res.data,
      options: {
        method: 'GET'
      }
    }
  });
};