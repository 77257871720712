import { Paragraph } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
export const Wrapper = styled.div.withConfig({
  displayName: "NoteBannerSectionstyle__Wrapper",
  componentId: "sc-1xzownd-0"
})(["width:100%;margin:", " auto;max-width:1180px;"], props => props.theme.spacing.m);
export const InnerWrapper = styled.div.withConfig({
  displayName: "NoteBannerSectionstyle__InnerWrapper",
  componentId: "sc-1xzownd-1"
})(["display:flex;align-items:baseline;flex-direction:column;gap:", ";padding:", ";background-color:", ";border:1px solid ", ";border-radius:4px;"], props => props.theme.spacing.s, props => props.theme.spacing.sm, props => props.theme.colors.background_light, props => props.theme.colors.border_default);
export const Title = styled(Paragraph).withConfig({
  displayName: "NoteBannerSectionstyle__Title",
  componentId: "sc-1xzownd-2"
})([""]);
export const Note = styled(Paragraph).withConfig({
  displayName: "NoteBannerSectionstyle__Note",
  componentId: "sc-1xzownd-3"
})(["margin-top:10px;", ""], props => props.theme.colors.text_secondary_paragraph);
export const BulletNote = styled(Note).withConfig({
  displayName: "NoteBannerSectionstyle__BulletNote",
  componentId: "sc-1xzownd-4"
})([""]);
export const TextWrapper = styled.div.withConfig({
  displayName: "NoteBannerSectionstyle__TextWrapper",
  componentId: "sc-1xzownd-5"
})(["text-align:left;"]);
export const Image = styled.img.withConfig({
  displayName: "NoteBannerSectionstyle__Image",
  componentId: "sc-1xzownd-6"
})([""]);