import debounce from "lodash/debounce";
import throttle from "lodash/throttle";
import { useCallback } from 'react';
const throttledDebounce = _ref => {
  let {
    debounceFn,
    debounceDelay,
    throttleDelay
  } = _ref;
  return throttle(debounce(arg => debounceFn(arg), debounceDelay, {
    leading: true,
    trailing: true
  }), throttleDelay + debounceDelay, {
    leading: false,
    trailing: true
  });
};
export const useThrottledDebounce = _ref2 => {
  let {
    debounceFn,
    debounceDelay,
    throttleDelay
  } = _ref2;
  return useCallback(throttledDebounce({
    debounceFn,
    debounceDelay,
    throttleDelay
  }), []);
};