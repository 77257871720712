import { FundType, OfferingStatus } from '@yieldstreet/platform-kit';
export function isFortyActFund(fundType) {
  return fundType === FundType.FortyActFund;
}
export function isRegAPlus(fundType) {
  return fundType === FundType.RegAPlus;
}
export function isFortyActOrRegAPlus(fundType) {
  return [FundType.RegAPlus, FundType.FortyActFund].includes(fundType);
}
export function isInternalOrThirdPartyFund(fundType) {
  return [FundType.InternalFund, FundType.ThirdPartyFund].includes(fundType);
}
export function getOfferingTermSuffix(term) {
  if (!term) {
    return '';
  }
  return term > 1 ? 'months' : 'month';
}
export function getOfferingTerm(offering) {
  var _offering$offeringHer;
  const {
    term,
    status
  } = offering;
  if (offering !== null && offering !== void 0 && (_offering$offeringHer = offering.offeringHero) !== null && _offering$offeringHer !== void 0 && _offering$offeringHer.customTerm) {
    var _offering$offeringHer2, _offering$offeringHer3;
    return {
      term: offering === null || offering === void 0 ? void 0 : (_offering$offeringHer2 = offering.offeringHero) === null || _offering$offeringHer2 === void 0 ? void 0 : _offering$offeringHer2.customTerm,
      termSuffix: (offering === null || offering === void 0 ? void 0 : (_offering$offeringHer3 = offering.offeringHero) === null || _offering$offeringHer3 === void 0 ? void 0 : _offering$offeringHer3.customTermSuffix) || ''
    };
  }
  if ([OfferingStatus.open, OfferingStatus.pending, OfferingStatus.pendingFullyCommitted, OfferingStatus.closed, OfferingStatus.payout].includes(status)) {
    return {
      term: offering === null || offering === void 0 ? void 0 : offering.computedTerm,
      termSuffix: getOfferingTermSuffix(offering === null || offering === void 0 ? void 0 : offering.computedTerm)
    };
  }
  if (status === OfferingStatus.repaid) {
    return {
      term: offering === null || offering === void 0 ? void 0 : offering.actualTerm,
      termSuffix: getOfferingTermSuffix(offering === null || offering === void 0 ? void 0 : offering.actualTerm)
    };
  }
  return {
    term: term,
    termSuffix: getOfferingTermSuffix(term)
  };
}