var __jsx = React.createElement;
import React from 'react';
import { FormRadioGroupInput } from '@yieldstreet/ui-kit';
import { AdviceQuestionsConfig } from 'utils/advice';
import { FieldSectionWrapper, FieldSectionHeader } from './Fields.style';
const {
  ExpectedYield
} = AdviceQuestionsConfig;
export const TargetReturnField = _ref => {
  let {
    formikProps
  } = _ref;
  return __jsx(React.Fragment, null, __jsx(FieldSectionHeader, {
    description: "Select the minimum target return you would expect to earn on your investments."
  }, "Target Return"), __jsx(FieldSectionWrapper, null, __jsx(FormRadioGroupInput, {
    name: ExpectedYield.key,
    options: ExpectedYield.options,
    formikProps: formikProps,
    direction: "row"
  })));
};