import { createTheme } from '@mui/material/styles';
import { components } from './components';
import { light } from './schemes';
import { updateHeaders } from './typography';
import { BASE_RADIUS, BASE_SPACING } from './theme.model';
import { getBorderRadius } from './theme.util';
const cssTHEME = createTheme({
  cssVariables: {
    disableCssColorScheme: true,
    cssVarPrefix: 'ys'
  },
  colorSchemes: {
    light,
    dark: false
  },
  components,
  shape: {
    borderRadius: BASE_RADIUS,
    radius: getBorderRadius
  },
  spacing: BASE_SPACING,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536
    }
  },
  typography: {
    fontFamily: 'Linik Sans, Arial, sans-serif',
    button: {
      fontSize: '16px',
      lineHeight: '22px'
    },
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    caption2: {
      fontSize: '10px'
    }
  }
});
export const CSS_THEME = updateHeaders(cssTHEME);

// This is important to bridge the gap between styled-components and mui styles in css function typings propagation.