import { setSegmentTrack } from 'utils/thirdPartyCode';
export const onCreateAccount = (isAccreditedSignup, investorAccountId, investorAccountType) => {
  setSegmentTrack({
    event: 'Account Created',
    properties: {
      orderId: investorAccountId,
      type: investorAccountType
    }
  });
  setSegmentTrack({
    event: isAccreditedSignup ? 'ai.investorAccount.accountCreation.created' : 'nai.investorAccount.accountCreation.created'
  });
};