import { Audiences, PaymentMethodType } from '../../enums';
import { OfferingPromotionGateCode, OfferingPromotionStatus, PromotionType } from './offering-promotion.model';
export const promoKeyMock = '100-free-investment';
export const urlHashMock = 'dhrswg';
export const freeInvestmentPromoMock = {
  id: '30f10630-4320-11ed-8b8f-092402ffe4c2',
  key: promoKeyMock,
  type: PromotionType.FREE_INVESTMENT,
  config: {
    investmentAmountValue: 100,
    paymentMethod: PaymentMethodType.ACH,
    sourceBankAccountId: '5678'
  }
};
export const offeringPromoMock = {
  id: '30f10630-4320-11ed-8b8f-092402ffe4c2',
  promotionId: '30f10630-4320-11ed-8b8f-092402ffe4c2',
  noteUrlHash: urlHashMock,
  status: OfferingPromotionStatus.ACTIVE,
  maximumCountLimit: 1,
  maximumInvestmentCount: 100,
  audiences: {
    [Audiences.repeatInvestor]: {
      NOTE_URL_HASH: urlHashMock
    },
    [Audiences.firstTimeInvestor]: {}
  }
};
export const offeringPromoGateMock = {
  code: OfferingPromotionGateCode.LIMIT_REACHED
};
export const offeringPromoGateInvalidMock = {
  code: OfferingPromotionGateCode.PROMOTION_INVALID
};
export const offeringPromoElgibilityMock = {
  gate: null,
  promotion: freeInvestmentPromoMock,
  offeringPromotion: offeringPromoMock
};
export const offeringPromoElgibilityGatedMock = {
  gate: offeringPromoGateMock,
  promotion: freeInvestmentPromoMock,
  offeringPromotion: offeringPromoMock
};
export const offeringPromoElgibilityGatedInvalidMock = {
  gate: offeringPromoGateInvalidMock,
  promotion: null,
  offeringPromotion: null
};
export const bulkPromoElgibilityMock = {
  [urlHashMock]: offeringPromoElgibilityMock
};
export const bulkPromoElgibilityGatedMock = {
  [urlHashMock]: offeringPromoElgibilityGatedMock
};
export const bulkPromoElgibilityGatedInvalidMock = {
  [urlHashMock]: offeringPromoElgibilityGatedInvalidMock
};