export const investmentRequestsMock = [{
  investment: {
    id: 'c188e880-6bd3-11ed-b6f6-cf3d9562f8d7',
    waitlisted: false,
    rollover: false,
    status: 'HOLD',
    amount: 1000,
    noteUrlHash: 'e5jyfw',
    userId: '492941',
    timestamp: 1684417020000,
    investorAccountId: '394817',
    paymentMethod: 'SYNP',
    bankAccountId: '508988',
    payInBankAccountId: '508988',
    payInPaymentMethod: 'SYNP',
    fundingAmount: 1000,
    fundingSourceId: '509169'
  },
  user: {
    id: '492941',
    displayName: 'Sai Sai',
    firstName: 'Sai',
    lastName: 'Sai',
    active: true
  },
  investorAccount: {
    id: '394817',
    name: 'Sai Sai 2',
    active: true,
    type: 'INDV'
  },
  offering: {
    noteUrlHash: 'e5jyfw',
    title: 'Growth & Income REIT',
    spvBankAccountName: 'REIT FUND',
    spvBankAccountNumber: '2222222222',
    shortName: 'REIT FUND'
  },
  code: 'held',
  message: 'Investment request c188e880-6bd3-11ed-b6f6-cf3d9562f8d7 has been held.',
  investmentGates: [{
    type: 'INVESTMENT_REQUEST_NOT_ALLOCATED',
    status: 'ACTIVE',
    priority: 50,
    owner: 'YS',
    timestamp: 1669279289000,
    visibility: 'ALL'
  }, {
    type: 'INVESTMENT_REQUEST_NOT_ACTIVE',
    status: 'ACTIVE',
    priority: 51,
    owner: 'YS',
    timestamp: 1669279289000,
    visibility: 'ALL'
  }, {
    type: 'ALLOCATION_PAYMENT_PENDING',
    status: 'CLEARED',
    priority: 30,
    owner: 'YS',
    timestamp: 1681827069000,
    visibility: 'ALL',
    clearReason: 'RESOLVED',
    attributes: {
      paymentMethod: 'SYNP'
    }
  }, {
    type: 'DEPOSIT_PAYMENT_PENDING',
    status: 'CLEARED',
    priority: 30,
    owner: 'YS',
    timestamp: 1669279662000,
    visibility: 'ALL',
    clearReason: 'RESOLVED'
  }, {
    type: 'INVESTMENT_REQUEST_HELD',
    status: 'CLEARED',
    priority: 49,
    owner: 'YS',
    timestamp: 1683290522000,
    visibility: 'ADMIN',
    clearReason: 'RESOLVED'
  }, {
    type: 'INVESTMENT_REQUEST_HELD',
    status: 'CLEARED',
    priority: 49,
    owner: 'YS',
    timestamp: 1683643634000,
    visibility: 'ADMIN',
    clearReason: 'RESOLVED'
  }, {
    type: 'INVESTMENT_REQUEST_HELD',
    status: 'CLEARED',
    priority: 49,
    owner: 'YS',
    timestamp: 1683644014000,
    visibility: 'ADMIN',
    clearReason: 'RESOLVED'
  }, {
    type: 'INVESTMENT_REQUEST_HELD',
    status: 'ACTIVE',
    priority: 49,
    owner: 'YS',
    timestamp: 1684417022000,
    visibility: 'ADMIN'
  }]
}, {
  investment: {
    id: 'e358c2f0-6bd3-11ed-b6f6-cf3d9562f8d7',
    waitlisted: false,
    rollover: false,
    status: 'SGND',
    amount: 5000,
    noteUrlHash: 'e5jyfw',
    userId: '492941',
    timestamp: 1683644013000,
    investorAccountId: '394817',
    paymentMethod: 'SYNP',
    bankAccountId: '508988',
    payInBankAccountId: '508988',
    payInPaymentMethod: 'SYNP',
    fundingAmount: 5000,
    fundingSourceId: '509169'
  },
  paymentMethod: 'SYNP',
  investmentFundingSource: {
    bank_account_id: '508988',
    amount: '0.00'
  },
  walletFundingRequest: {
    bank_account_id: '509169',
    amount: '5000.00',
    transferDate: '05/18/2023'
  },
  user: {
    id: '492941',
    displayName: 'Sai Sai',
    firstName: 'Sai',
    lastName: 'Sai',
    active: true
  },
  investorAccount: {
    id: '394817',
    name: 'Sai Sai 2',
    active: true,
    type: 'INDV'
  },
  offering: {
    noteUrlHash: 'e5jyfw',
    title: 'Growth & Income REIT',
    spvBankAccountName: 'REIT FUND',
    spvBankAccountNumber: '2222222222',
    shortName: 'REIT FUND'
  },
  code: 'removed-hold',
  message: 'Investment request e358c2f0-6bd3-11ed-b6f6-cf3d9562f8d7 has been removed from hold.',
  investmentGates: [{
    type: 'ALLOCATION_PAYMENT_PENDING',
    status: 'CLEARED',
    priority: 30,
    owner: 'YS',
    timestamp: 1681827071000,
    visibility: 'ALL',
    clearReason: 'RESOLVED',
    attributes: {
      paymentMethod: 'SYNP'
    }
  }, {
    type: 'DEPOSIT_PAYMENT_PENDING',
    status: 'CLEARED',
    priority: 30,
    owner: 'YS',
    timestamp: 1669279737000,
    visibility: 'ALL',
    clearReason: 'RESOLVED'
  }, {
    type: 'INVESTMENT_REQUEST_NOT_ALLOCATED',
    status: 'ACTIVE',
    priority: 50,
    owner: 'YS',
    timestamp: 1669279347000,
    visibility: 'ALL'
  }, {
    type: 'INVESTMENT_REQUEST_NOT_ACTIVE',
    status: 'ACTIVE',
    priority: 51,
    owner: 'YS',
    timestamp: 1669279347000,
    visibility: 'ALL'
  }, {
    type: 'INSUFFICIENT_WALLET_BALANCE',
    status: 'ACTIVE',
    priority: 18,
    owner: 'USER',
    timestamp: 1681827073000,
    visibility: 'ADMIN'
  }, {
    type: 'INVESTMENT_REQUEST_HELD',
    status: 'CLEARED',
    priority: 49,
    owner: 'YS',
    timestamp: 1683643634000,
    visibility: 'ADMIN',
    clearReason: 'RESOLVED'
  }, {
    type: 'INVESTMENT_REQUEST_HELD',
    status: 'CLEARED',
    priority: 49,
    owner: 'YS',
    timestamp: 1683644014000,
    visibility: 'ADMIN',
    clearReason: 'RESOLVED'
  }]
}];