import _objectDestructuringEmpty from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectDestructuringEmpty.js";
import { restApi, Tags } from '../common';
import { HttpMethods } from '../../enums/http';
export const userProfileApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.UserProfile, Tags.UserContact]
}).injectEndpoints({
  endpoints: builder => {
    const getUserProfile = builder.query({
      query: () => ({
        url: `/a/api/investor/profile`,
        method: HttpMethods.Get
      }),
      providesTags: [Tags.UserProfile]
    });
    const updateUserProfile = builder.mutation({
      query: _ref => {
        let {
          userId,
          data
        } = _ref;
        return {
          url: `/a/api/user/${userId}/investor-profile`,
          method: HttpMethods.Patch,
          data
        };
      },
      invalidatesTags: [Tags.UserProfile]
    });
    const updateUserProfilePhone = builder.mutation({
      query: _ref2 => {
        let data = Object.assign({}, (_objectDestructuringEmpty(_ref2), _ref2));
        return {
          url: `/a/api/investor/profile/phone`,
          method: HttpMethods.Put,
          data
        };
      },
      invalidatesTags: [Tags.UserProfile, Tags.UserContact]
    });
    return {
      getUserProfile,
      updateUserProfile,
      updateUserProfilePhone
    };
  }
});