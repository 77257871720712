import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isEmpty from "lodash/isEmpty";
import { PageLoader } from '@yieldstreet/ui-kit/layouts';
import { DesktopOnly, MobileOnly } from '@yieldstreet/ui-kit';
import { PageContainer } from '@yieldstreet/ui-kit/layouts';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { getUserBankInfo } from 'store/actions/bank';
import { useAdvice } from 'utils/advice';
import { useBankLinkingModal } from 'pages/bankLinking/hooks/useBanklinkingModal';
import { Communication } from 'sharedComponents/Communication';
import FreeInvestmentBanner from 'sharedComponents/OfferingPromo/Banners/FreeInvestment/FreeInvestment';
import { REIT_URL_HASH, REIT_FREE_SHARES_PROMO_CODE } from '../freeShares/FreeShares.model';
import { useBankAccounts } from '../investorEntity/screens/Management/routes/BankAccounts/hooks/useBankAccounts';
import { useReadyToInvest } from './utils/useReadyToInvest';
import { testIds } from './FirstInvestmentLanding.model';
import { CommsContainer, HeaderWrapper, LinkContainer, OfferingsContainer, OfferingsWrapper, ParagraphWrapper, SCarousel, TitleWrapper } from './FirstInvestmentLanding.style';
export const FirstInvestmentLanding = _ref => {
  var _userBankInfo$userSta, _userBankInfo$userSta2;
  let {
    investorEntityId,
    loading,
    portfolioSizeAbove1M,
    userState
  } = _ref;
  const {
    firstName,
    accreditedSignUp
  } = userState;
  const {
    0: linkedBankAccount,
    1: setLinkedBankAccount
  } = useState(false);
  const {
    microDepositBankAccounts,
    isEntityBankAccountsLoading
  } = useBankAccounts();
  const dispatch = useDispatch();
  const {
    userBankInfo
  } = useSelector(_ref2 => {
    let {
      bank
    } = _ref2;
    return bank;
  });
  const {
    hasAdvice,
    isAdviceReady
  } = useAdvice();
  const {
    offers,
    description,
    callToAction = {},
    carousel
  } = useReadyToInvest({
    portfolioSizeAbove1M,
    accreditedSignUp,
    hasAdvice,
    isAdviceReady
  });
  const content = !(microDepositBankAccounts !== null && microDepositBankAccounts !== void 0 && microDepositBankAccounts.length) ? {
    title: `you're all set to make your first investment`,
    description
  } : {
    title: `you're almost ready to make your first investment`,
    description: `Once micro-deposits are confirmed, you will be ready to invest. In the meantime, you can start to plan your initial investment.`
  };
  const bankLinkingModal = useBankLinkingModal({
    investorEntityId,
    onSuccess: () => setLinkedBankAccount(true)
  });
  const hideButton = !isEmpty(userBankInfo === null || userBankInfo === void 0 ? void 0 : (_userBankInfo$userSta = userBankInfo[userState === null || userState === void 0 ? void 0 : userState.id]) === null || _userBankInfo$userSta === void 0 ? void 0 : (_userBankInfo$userSta2 = _userBankInfo$userSta.bankAccounts) === null || _userBankInfo$userSta2 === void 0 ? void 0 : _userBankInfo$userSta2.filter(bankAccount => (bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.accountType) !== 'YWLT' && Number(bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.holderId) === Number(investorEntityId)));
  useEffect(() => {
    dispatch(getUserBankInfo(userState === null || userState === void 0 ? void 0 : userState.id));
  }, [dispatch, userState === null || userState === void 0 ? void 0 : userState.id]);
  useEffect(() => {
    if (!bankLinkingModal.isOpen && linkedBankAccount && !hideButton) {
      setLinkedBankAccount(false);
      dispatch(getUserBankInfo(userState === null || userState === void 0 ? void 0 : userState.id));
    }
  }, [dispatch, hideButton, bankLinkingModal.isOpen, linkedBankAccount, userState === null || userState === void 0 ? void 0 : userState.id]);
  if (loading || isEntityBankAccountsLoading) {
    return __jsx(PageLoader, testIds.pageLoader.attr);
  }
  return __jsx(PageContainer, {
    background: "dark"
  }, __jsx(TitleWrapper, null, __jsx(HeaderWrapper, null, __jsx(Typography, _extends({
    variant: "h3"
  }, testIds.heading.attr), `${firstName}, ${content.title}`)), __jsx(ParagraphWrapper, null, __jsx(Typography, {
    variant: "body1"
  }, content.description))), __jsx(CommsContainer, null, __jsx(Communication, {
    location: "post_kyc_landing"
  }), __jsx(FreeInvestmentBanner, {
    urlHash: REIT_URL_HASH,
    promoKey: REIT_FREE_SHARES_PROMO_CODE,
    investmentAsset: "real estate",
    investmentTitle: "Growth & Income REIT"
  })), __jsx(DesktopOnly, null, __jsx(OfferingsContainer, null, __jsx(OfferingsWrapper, null, offers))), __jsx(MobileOnly, null, carousel ? __jsx(SCarousel, {
    wrapAround: true,
    dragging: true,
    slidesToShow: 1,
    framePadding: "10px",
    cellSpacing: 10,
    cellAlign: "right"
  }, offers.map((component, idx) => {
    return __jsx(OfferingsWrapper, {
      key: idx
    }, component);
  })) : __jsx(OfferingsContainer, null, __jsx(OfferingsWrapper, null, offers))), !isEmpty(callToAction) && !hideButton && __jsx(LinkContainer, null, __jsx(Button, _extends({
    buttonType: "secondary",
    onClick: () => {
      bankLinkingModal.open();
    }
  }, testIds.landingAnchor.attr), callToAction.buttonCTA)));
};