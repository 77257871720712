export const investor = {
  userId: 4,
  userFirstName: 'Aglae',
  userLastName: 'Waelchi',
  userEmail: 'aglae.waelchi.4@ys.com',
  userNickname: '82827ad0b40ec275ca6b41c4fbe03716',
  investorAccountId: 1,
  investorAccountName: 'Luz Schuster',
  walletEnabled: true,
  investorAccountType: 'INDV',
  entityType: null,
  documentType: 'SSN',
  hasMultipleOwners: false,
  accredited: true,
  hasWallet: true,
  readyToInvest: true,
  setupComplete: true,
  ysIra: false,
  userFullName: 'Aglae Waelchi',
  entityTypeVariable: 'INDV'
};