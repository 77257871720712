import _Link from "@mui/material/Link";
import _Typography from "@mui/material/Typography";
import _IconButton from "@mui/material/IconButton";
var __jsx = React.createElement;
import React from 'react';
import { getSnackbarTestIds } from '@yieldstreet/tool-kit';
import CloseIcon from '@mui/icons-material/Close';
import { SnackbarItem as SnackbarItemComponent } from './Snackbar.style';
import { TransitionDuration } from './Snackbar.model';
export const SnackbarItem = _ref => {
  let {
    autoHideDuration,
    closeSnackbar,
    dataCy,
    description,
    handleLink,
    isVisible,
    linkText,
    testId,
    title,
    type
  } = _ref;
  const testIds = getSnackbarTestIds(dataCy || testId || '');
  const onClose = () => {
    closeSnackbar === null || closeSnackbar === void 0 ? void 0 : closeSnackbar();
  };
  const action = __jsx(_IconButton, {
    "aria-label": "Close snackbar",
    color: "inherit",
    "data-cy": testIds.actionClose,
    onClick: onClose,
    sx: {
      p: 0.5
    }
  }, __jsx(CloseIcon, null));
  const message = __jsx(React.Fragment, null, title && __jsx(_Typography, {
    "data-cy": testIds.title,
    semiBold: true
  }, title), description && __jsx(_Typography, {
    "data-cy": testIds.description,
    sx: {
      mt: 1
    },
    variant: "body2"
  }, description), linkText && handleLink && __jsx(_Link, {
    "data-cy": testIds.link,
    onClick: handleLink,
    sx: {
      mt: 2
    }
  }, linkText));
  return __jsx(SnackbarItemComponent, {
    action: action,
    autoHideDuration: autoHideDuration,
    "data-cy": testIds.container,
    message: message,
    onClose: (_event, reason) => reason === 'timeout' && onClose(),
    open: isVisible,
    transitionDuration: TransitionDuration,
    type: type
  });
};