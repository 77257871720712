import { media } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
export const Wrapper = styled.div.withConfig({
  displayName: "MetricCardsSectionstyle__Wrapper",
  componentId: "sc-nio2em-0"
})(["margin:", " auto;"], props => props.theme.spacing.m);
export const InnerWrapper = styled.div.withConfig({
  displayName: "MetricCardsSectionstyle__InnerWrapper",
  componentId: "sc-nio2em-1"
})([""]);
export const CardsWrapper = styled.div.withConfig({
  displayName: "MetricCardsSectionstyle__CardsWrapper",
  componentId: "sc-nio2em-2"
})([""]);
export const CardsInnerWrapper = styled.div.withConfig({
  displayName: "MetricCardsSectionstyle__CardsInnerWrapper",
  componentId: "sc-nio2em-3"
})(["display:flex;flex-wrap:wrap;gap:", ";", ";", ";"], props => props.theme.spacing.sm, media.tablet`
    flex-direction: column;
    justify-content: flex-start;
  `, media.large`
    flex-direction: row;
  `);