var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';

/* Utilities */
import { media } from '../../themes/media';

/* Layout elements */
import { PageSection } from '../../layout/page-section';

/* UI elemenets */
import { IndustrySectorsDesktop, IndustrySectorsMobile } from './industrySectors';
import { AUMStatsSection } from './AUMStats';
import { CopyBlock } from './copyBlock';
import { Callout } from './callouts';

/* ====================================
 *    'Grow you AUM with us' section
 * ==================================== */

export const GrowYourAUM = _ref => {
  let {
    title,
    subtitle,
    description,
    AUMStats,
    callout
  } = _ref;
  return __jsx(PageSection, null, __jsx(ContentContainer, null, __jsx(CopyBlock, {
    title: title,
    subtitle: subtitle,
    description: description
  }), __jsx(IndustrySectorsMobile, null), __jsx(IndustrySectorsDesktop, null), __jsx(AUMStatsSection, {
    listItems: AUMStats
  }), __jsx(Callout, {
    copy: callout
  })));
};

/* ===== Layout UI elements ===== */

const ContentContainer = styled.div.withConfig({
  displayName: "GrowYourAUM__ContentContainer",
  componentId: "sc-toowqv-0"
})(["display:block;padding-top:20px;position:relative;padding:20px;&:before,&:after{display:none;content:'';position:absolute;width:100%;background-color:", ";height:20px;", " & *{box-sizing:border-box !important;}}&:before{top:0;}&:after{bottom:0;}", " background:linear-gradient( to bottom,", " 1%,", " 47%,", " 47%,", " 100% );"], props => props.theme.colors.background_card, media.desktop`
      display:block;
      height:40px;
    `, media.desktop`
    padding:0px;
    max-width:1250px;
    margin: 0 auto;
    padding-top:88px;
    padding-bottom:480px;
    display: flex;
    background: linear-gradient(
      to right,
      ${props => props.theme.colors.background_card} 1%,
      ${props => props.theme.colors.background_card} 50%,
      ${props => props.theme.colors.background_inverse} 50%,
      ${props => props.theme.colors.background_inverse} 100%
      );
  `, props => props.theme.colors.background_card, props => props.theme.colors.background_card, props => props.theme.colors.background_inverse, props => props.theme.colors.background_inverse);