import { restApi, Tags } from '../common';
import { HttpMethods } from '../../enums/http';
export const userAddressApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.UserProfileAddress, Tags.UserContact]
}).injectEndpoints({
  endpoints: builder => {
    const getUserAddress = builder.query({
      query: _ref => {
        let {
          userId
        } = _ref;
        return {
          url: `/a/api/user/${userId}/address`,
          method: HttpMethods.Get
        };
      },
      providesTags: [Tags.UserProfileAddress]
    });
    const updateUserAddress = builder.mutation({
      query: _ref2 => {
        let {
          userId,
          address
        } = _ref2;
        return {
          url: `/a/api/user/${userId}/address`,
          method: HttpMethods.Post,
          data: address
        };
      },
      invalidatesTags: [Tags.UserProfileAddress, Tags.UserContact]
    });
    return {
      getUserAddress,
      updateUserAddress
    };
  }
});