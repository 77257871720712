import { genTestIds } from '@yieldstreet/tool-kit';
export const pageInfoConst = {
  default: {
    title: `Let's get started.`,
    individualTitle: 'First, please confirm your legal name',
    entityTitle: `Please confirm the entity's name`,
    subtitle: `The name you enter should match what's listed on documents such as your driver's license.`
  },
  ira: {
    title: 'Select your IRA account type',
    subtitle: 'Historically strong private market returns can grow tax-free or tax-deferred in a Yieldstreet IRA.'
  },
  details: {
    title: 'Verify your identity'
  },
  address: {
    title: 'Contact information',
    subtitle: 'Please provide a valid U.S. address.'
  },
  phone: {
    title: 'Mobile phone number',
    subtitle: 'Please provide a valid mobile number.'
  },
  citizenship: {
    title: 'Citizenship',
    subtitle: 'Please list all countries of which you are a citizen.'
  },
  taxResidence: {
    title: 'Tax residence',
    subtitle: "Yieldstreet's investments are currently only available for U.S. taxpayers. Please confirm your tax residence is correct."
  },
  employmentInfo: {
    title: `What's your employment status?`,
    subtitle: `We're required to collect this information for regulatory purposes.`
  },
  atomicFork: {
    title: 'Does any of the following apply to you or members of your household or immediate family?',
    subtitle: 'The following situations may require additional verification to ensure eligibility for certain Yieldstreet investments.'
  },
  politicallyExposedPerson: {
    title: 'Are you or a family member a Politically Exposed Person?'
  },
  finraAffiliated: {
    title: 'Are you affiliated with the Financial Industry Regulatory Authority (FINRA)?'
  },
  controlCorporations: {
    title: 'Do you have a controlling stake in a public company?',
    subtitle: 'This applies if you are an officer, executive or have a greater than 10% ownership in a public company. Please list all that apply.'
  },
  exchanges: {
    title: 'Are you affiliated with any of the below exchanges?',
    subtitle: 'Select all that apply.'
  }
};
const {
  getTestIds
} = genTestIds(['wrapper', 'heading', 'paragraph', 'errorNotication']);
export const testIds = getTestIds('create-investor-account-flow-wrapper');