import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
const _excluded = ["investorEntityId"];
import { HttpMethods } from '../../enums/http';
import { restApi, Tags } from '../common';
export const bankAccountApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.BankAccount, Tags.PlaidLinkBank, Tags.InvestorEntityStatus, Tags.ProviderBankLinkMigrationStatus]
}).injectEndpoints({
  endpoints: builder => {
    const getEntityBankAccounts = builder.query({
      query: _ref => {
        let {
          investorEntityId
        } = _ref;
        return {
          url: `/a/api/investor-entity/${investorEntityId}/bank-account`,
          method: HttpMethods.Get
        };
      },
      providesTags: [Tags.BankAccount]
    });
    const bankAccountLogin = builder.mutation({
      query: _ref2 => {
        let {
          request,
          investorEntityId
        } = _ref2;
        return {
          url: `/a/api/investor-entity/${investorEntityId}/bank-account/login`,
          method: HttpMethods.Post,
          data: request
        };
      },
      invalidatesTags: [Tags.BankAccount],
      transformResponse: response => {
        return response;
      }
    });
    const getBankAccounts = builder.query({
      extraOptions: _props => {
        const options = {
          cacheKey: ''
        };
        return options;
      },
      query: _ref3 => {
        let {
          userId
        } = _ref3;
        return {
          url: `/a/api/user/${userId}/bank-accounts`,
          method: HttpMethods.Get
        };
      },
      providesTags: [Tags.BankAccount]
    });
    const setDefaultBankAccount = builder.mutation({
      query: _ref4 => {
        let {
          bankAccountId,
          investorEntityId
        } = _ref4;
        return {
          url: `/a/api/investor-entity/${investorEntityId}/bank-account/${bankAccountId}/default`,
          method: HttpMethods.Post
        };
      },
      invalidatesTags: [Tags.BankAccount]
    });
    const bankAccountMfa = builder.mutation({
      query: _ref5 => {
        let {
          request,
          investorEntityId
        } = _ref5;
        return {
          url: `/a/api/investor-entity/${investorEntityId}/bank-account/mfa`,
          method: HttpMethods.Post,
          data: request
        };
      },
      invalidatesTags: [Tags.BankAccount]
    });
    const verifyMicroDeposits = builder.mutation({
      query: _ref6 => {
        let {
          amounts,
          holderId,
          bankAccountId
        } = _ref6;
        return {
          url: `/a/api/investor-entity/${holderId}/bank-account/${bankAccountId}/verify-micro-deposits`,
          method: HttpMethods.Post,
          data: {
            amounts
          }
        };
      }
    });
    const getExternalBanks = builder.query({
      query: () => {
        return {
          url: `/a/api/dic/banks`,
          method: HttpMethods.Get
        };
      },
      providesTags: [Tags.BankAccount],
      transformResponse: response => {
        const {
          banks
        } = response;
        const filteredBanks = banks.filter(bank => bank.code !== 'hsbc').sort((bank1, bank2) => {
          var _bank1$name, _bank2$name;
          return ((bank1 === null || bank1 === void 0 ? void 0 : (_bank1$name = bank1.name) === null || _bank1$name === void 0 ? void 0 : _bank1$name.toLowerCase()) || '') > ((bank2 === null || bank2 === void 0 ? void 0 : (_bank2$name = bank2.name) === null || _bank2$name === void 0 ? void 0 : _bank2$name.toLowerCase()) || '') ? 1 : -1;
        });
        return _objectSpread(_objectSpread({}, response), {}, {
          banks: filteredBanks
        });
      }
    });
    const addManualBankAccount = builder.mutation({
      query: _ref7 => {
        let {
            investorEntityId
          } = _ref7,
          payload = _objectWithoutProperties(_ref7, _excluded);
        return {
          url: `/a/api/investor-entity/${investorEntityId}/bank-account/manual`,
          method: HttpMethods.Post,
          data: payload
        };
      },
      invalidatesTags: [Tags.BankAccount]
    });
    const createPlaidToken = builder.query({
      query: data => ({
        url: `/a/investor-entity/bank-accounts/linked/plaid/create-token`,
        method: HttpMethods.Post,
        data
      })
    });
    const addPlaidLinkBank = builder.mutation({
      query: data => ({
        url: `/a/investor-entity/bank-accounts/linked/plaid/on-link-completed`,
        method: HttpMethods.Post,
        data
      }),
      invalidatesTags: [Tags.BankAccount, Tags.PlaidLinkBank, Tags.InvestorEntityStatus, Tags.ProviderBankLinkMigrationStatus]
    });
    const updateBankNickname = builder.mutation({
      query: _ref8 => {
        let {
          bankAccount,
          nickname
        } = _ref8;
        return {
          url: `/a/api/user/ba/${bankAccount.id}`,
          method: HttpMethods.Post,
          data: _objectSpread(_objectSpread({}, bankAccount), {}, {
            nickname
          })
        };
      },
      invalidatesTags: [Tags.BankAccount]
    });
    const removeBankAccount = builder.mutation({
      query: _ref9 => {
        let {
          bankAccountId
        } = _ref9;
        return {
          url: `/a/investor/bankAccount/${bankAccountId}`,
          method: HttpMethods.Delete
        };
      },
      invalidatesTags: [Tags.BankAccount]
    });
    return {
      getEntityBankAccounts,
      setDefaultBankAccount,
      bankAccountLogin,
      bankAccountMfa,
      getBankAccounts,
      verifyMicroDeposits,
      getExternalBanks,
      addManualBankAccount,
      createPlaidToken,
      addPlaidLinkBank,
      updateBankNickname,
      removeBankAccount
    };
  }
});