import { allToken, userApi } from '@yieldstreet/platform-kit';
import { skipToken } from '@reduxjs/toolkit/query';
import { usePreviousValue } from '../utils';
import { useFlags } from '../flags';
export const useAuth = () => {
  var _userContact$contact;
  const {
    data: userState,
    isFetching: userStateFetching
  } = userApi.useGetUserStateQuery(allToken);
  const isAuthLoaded = !!userState;
  const isAuthenticated = userState === null || userState === void 0 ? void 0 : userState.loggedIn;
  const userContactParams = isAuthenticated ? allToken : skipToken;
  const {
    data: userContact,
    isFetching: userContactFetching
  } = userApi.useGetUserContactInfoQuery(userContactParams);
  const prevUserState = usePreviousValue(userState);
  const {
    signupPhone
  } = useFlags({
    signupPhone: false
  });
  const isAccreditationSubmitted = userState === null || userState === void 0 ? void 0 : userState.accreditationStatusSubmitted;
  const isPhoneSubmitted = !!(userContact !== null && userContact !== void 0 && (_userContact$contact = userContact.contact) !== null && _userContact$contact !== void 0 && _userContact$contact.phone);
  const isAuthLoading = userStateFetching || userContactFetching;
  const isAuthReady = isAuthenticated && isAccreditationSubmitted && (
  // only consider phone submitted as condition for ready if flag is on
  !signupPhone || signupPhone && isPhoneSubmitted);
  const isSignupSession = (userState === null || userState === void 0 ? void 0 : userState.source) === 'registration';
  const isLoginSession = (userState === null || userState === void 0 ? void 0 : userState.source) === 'login';
  const isImpersonatedSession = !!(userState !== null && userState !== void 0 && userState.impersonated);

  // user was authenticated while this component was mounted
  const isJustAuthenticated = isAuthenticated && prevUserState && !prevUserState.loggedIn;

  // user was already autenticated when component was mounted
  const isAlreadyAuthenticated = isAuthenticated && !prevUserState;
  return {
    userState,
    userContact,
    isAuthLoaded,
    isAuthenticated,
    isAccreditationSubmitted,
    isPhoneSubmitted,
    isAuthLoading,
    isAuthReady,
    isSignupSession,
    isLoginSession,
    isImpersonatedSession,
    isJustAuthenticated,
    isAlreadyAuthenticated
  };
};