var __jsx = React.createElement;
import React, { useState } from 'react';
import styled from 'styled-components';
import { ParagraphLink, Paragraph } from '../../content/text';
import { media } from '../../themes';
import axios from 'axios';
import { IconPicker } from '../../utils/icon-picker';
import { LightBox } from '../../utils/light-box';
import playbutton from '../../assets/graphics/case-specific/play_button.svg';
const characterLimitHit = (charLimit, arr) => {
  if (arr && arr.join().length > charLimit) {
    return true;
  } else return false;
};
export const ParagraphBoxSection = _ref => {
  let {
    section
  } = _ref;
  const {
      charLimit,
      content,
      iconName,
      interactiveMap,
      imageCaption,
      image,
      subtitle,
      title,
      video
    } = section,
    {
      0: showMore,
      1: setShowMore
    } = useState(false),
    {
      0: showVideoModal,
      1: setShowVideoModal
    } = useState(false),
    {
      0: showImageModal,
      1: setShowImageModal
    } = useState(false),
    {
      0: showMapModal,
      1: setShowMapModal
    } = useState(false),
    {
      0: videoThumbnail,
      1: setVideoThumbnail
    } = useState('');
  const getVideoThumbnail = videoURL => {
    const urlParams = videoURL.split('/'),
      videoId = urlParams[urlParams.length - 1];
    videoURL && axios.get(`//vimeo.com/api/v2/video/${videoId}.json`).then(res => {
      var _res$data$;
      setVideoThumbnail(res === null || res === void 0 ? void 0 : (_res$data$ = res.data[0]) === null || _res$data$ === void 0 ? void 0 : _res$data$.thumbnail_large);
    });
  };
  video && getVideoThumbnail(video);
  let truncatedContent = [];
  if (characterLimitHit(charLimit, content)) {
    let charLimitReached = 0;
    content.forEach(paragraph => {
      let truncatedContentLength = truncatedContent.join().length;
      if (truncatedContentLength >= charLimit) {
        return;
      } else if (truncatedContentLength + paragraph.length < charLimit) {
        truncatedContent.push(paragraph);
      } else if (charLimitReached < 1) {
        let remainingChar = charLimit - truncatedContentLength;
        truncatedContent.push(paragraph.substr(0, paragraph.lastIndexOf(' ', remainingChar - 3)) + '...');
        charLimitReached = 1;
        return;
      }
    });
  } else {
    truncatedContent = content;
  }
  let fullWidth = false;
  if (!image && !interactiveMap && !video) {
    fullWidth = true;
  }
  return __jsx(Wrapper, null, __jsx(InnerWrapper, null, __jsx(TitleContainer, null, iconName && __jsx(IconPicker, {
    className: "paragraph-box-icon",
    iconName: iconName
  }), title && __jsx(Title, {
    className: "paragraph-box-title",
    icon: iconName,
    semiBold: true
  }, __jsx("span", {
    dangerouslySetInnerHTML: {
      __html: title
    }
  }))), subtitle && __jsx(SubTitle, {
    className: "paragraph-box-subtitle",
    secondary: true
  }, __jsx("span", {
    dangerouslySetInnerHTML: {
      __html: subtitle
    }
  })), __jsx(ContentWrapper, null, __jsx(TextWrapper, null, !showMore && truncatedContent && truncatedContent.map((paragraph, idx) => __jsx(ParagraphBody, {
    className: "paragraph-box-paragraph-body",
    fullWidth: fullWidth,
    small: true,
    key: `${title}-${idx}`
  }, __jsx("span", {
    dangerouslySetInnerHTML: {
      __html: paragraph
    }
  }))), characterLimitHit(charLimit, content) && !showMore && __jsx(ReadMoreLink, {
    className: "paragraph-box-read-more-link",
    small: true,
    onClick: () => setShowMore(true)
  }, "Read more"), showMore && content.map((paragraph, idx) => __jsx(ParagraphBody, {
    className: "paragraph-box-read-more-paragraph-body",
    fullWidth: fullWidth,
    small: true,
    key: `${title}-${idx}`
  }, __jsx("span", {
    dangerouslySetInnerHTML: {
      __html: paragraph
    }
  }))), showMore && __jsx(ReadMoreLink, {
    className: "paragraph-box-read-less-link",
    small: true,
    onClick: () => setShowMore(false)
  }, "Show less")), video && !image && !interactiveMap && __jsx(VideoWrapper, null, __jsx(VideoOverlayTrigger, {
    className: "paragraph-box-video-trigger",
    onClick: () => setShowVideoModal(true)
  }, __jsx(PlayButton, {
    src: playbutton
  })), __jsx(VideoThumbnail, {
    className: "paragraph-box-video-thumbnail",
    src: videoThumbnail
  })), image && !video && !interactiveMap && __jsx(ImageThumbnail, {
    className: "paragraph-box-image-thumbnail",
    src: image,
    onClick: () => setShowImageModal(true)
  }), interactiveMap && !video && !image && __jsx(MapWrapper, {
    className: "paragraph-box-map-trigger",
    onClick: () => setShowMapModal(true)
  }, __jsx(MapThumbnail, {
    className: "paragraph-box-map-thumbnail",
    src: interactiveMap
  })))), showVideoModal && __jsx(StyledModal, {
    caption: imageCaption,
    clickClose: () => setShowVideoModal(false)
  }, __jsx(ModalVideoIframe, {
    className: "paragraph-box-video-modal",
    src: `${video}?autoplay=1`,
    allow: "autoplay",
    frameBorder: "0"
  })), showImageModal && __jsx(StyledModal, {
    caption: imageCaption,
    clickClose: () => setShowImageModal(false)
  }, __jsx(ModalImage, {
    className: "paragraph-box-image-modal",
    src: image
  })), showMapModal && __jsx(StyledModal, {
    caption: imageCaption,
    clickClose: () => setShowMapModal(false)
  }, __jsx(ModalMapIframe, {
    className: "paragraph-box-map-modal",
    src: interactiveMap
  })));
};
const Wrapper = styled.div.withConfig({
  displayName: "ParagraphBoxSection__Wrapper",
  componentId: "sc-b33sot-0"
})([""]);
const InnerWrapper = styled.div.withConfig({
  displayName: "ParagraphBoxSection__InnerWrapper",
  componentId: "sc-b33sot-1"
})(["max-width:1180px;margin:0 auto;padding:20px 20px 40px;", ";", ";"], media.tablet`
    padding: 20px 35px 40px;
  `, media.large`
    padding: 20px 0 30px;
  `);
const ContentWrapper = styled.div.withConfig({
  displayName: "ParagraphBoxSection__ContentWrapper",
  componentId: "sc-b33sot-2"
})(["display:flex;flex-direction:column;", ";"], media.desktop`
    flex-direction: row;
  `);
const TextWrapper = styled.div.withConfig({
  displayName: "ParagraphBoxSection__TextWrapper",
  componentId: "sc-b33sot-3"
})([""]);
const TitleContainer = styled.div.withConfig({
  displayName: "ParagraphBoxSection__TitleContainer",
  componentId: "sc-b33sot-4"
})(["display:flex;flex-direction:row;"]);
const Title = styled(Paragraph).withConfig({
  displayName: "ParagraphBoxSection__Title",
  componentId: "sc-b33sot-5"
})(["margin-bottom:20px;margin-left:", ";"], props => props.icon && `20px`);
const SubTitle = styled(Paragraph).withConfig({
  displayName: "ParagraphBoxSection__SubTitle",
  componentId: "sc-b33sot-6"
})(["margin:-10px 0 10px;"]);
const ParagraphBody = styled(Paragraph).withConfig({
  displayName: "ParagraphBoxSection__ParagraphBody",
  componentId: "sc-b33sot-7"
})(["margin-bottom:20px;", ";"], media.desktop`
    max-width: ${props => !props.fullWidth && `580px`};
  `);
const ReadMoreLink = styled(Paragraph).withConfig({
  displayName: "ParagraphBoxSection__ReadMoreLink",
  componentId: "sc-b33sot-8"
})(["", ";width:fit-content;margin:0 0 20px;", ";"], ParagraphLink, media.desktop`
    margin: 20px 0 0;
  `);
const VideoWrapper = styled.div.withConfig({
  displayName: "ParagraphBoxSection__VideoWrapper",
  componentId: "sc-b33sot-9"
})(["position:relative;margin:0 auto;height:250px;width:100%;object-fit:cover;max-width:374px;", ";"], media.desktop`
    margin-left: 180px;
    height: 250px;
    width: 380px;
  `);
const VideoOverlayTrigger = styled.div.withConfig({
  displayName: "ParagraphBoxSection__VideoOverlayTrigger",
  componentId: "sc-b33sot-10"
})(["cursor:pointer;background:linear-gradient(180deg,#000000 0%,rgba(255,255,255,0) 100%);position:absolute;width:100%;height:100%;z-index:2;text-align:left;display:flex;flex-direction:row;align-items:center;justify-content:center;"]);
const VideoThumbnail = styled.img.withConfig({
  displayName: "ParagraphBoxSection__VideoThumbnail",
  componentId: "sc-b33sot-11"
})(["width:100%;height:100%;position:absolute;"]);
const PlayButton = styled.img.withConfig({
  displayName: "ParagraphBoxSection__PlayButton",
  componentId: "sc-b33sot-12"
})(["width:45px;", ""], media.desktop`
    width: 57px;
  `);
const StyledModal = styled(LightBox).withConfig({
  displayName: "ParagraphBoxSection__StyledModal",
  componentId: "sc-b33sot-13"
})(["> div{background:transparent;}"]);
const ModalVideoIframe = styled.iframe.withConfig({
  displayName: "ParagraphBoxSection__ModalVideoIframe",
  componentId: "sc-b33sot-14"
})(["border:0;position:relative;width:334px;height:213px;", ";"], media.tablet`
    width: 660px;
    height: 372px;
  `);
const ImageThumbnail = styled.img.withConfig({
  displayName: "ParagraphBoxSection__ImageThumbnail",
  componentId: "sc-b33sot-15"
})(["cursor:pointer;position:relative;margin:0 auto;height:250px;width:100%;max-width:374px;border:1px solid ", ";object-position:top;object-fit:cover;", ";"], props => props.theme.colors.border_default, media.desktop`
    margin-left: 180px;
    height: 250px;
    width: 380px;
  `);
const ModalImage = styled.img.withConfig({
  displayName: "ParagraphBoxSection__ModalImage",
  componentId: "sc-b33sot-16"
})(["max-width:100%;max-height:100%;"]);
const MapWrapper = styled(VideoWrapper).withConfig({
  displayName: "ParagraphBoxSection__MapWrapper",
  componentId: "sc-b33sot-17"
})(["cursor:pointer;box-shadow:0px 4px 9px rgba(0,0,0,0.17);"]);
const MapThumbnail = styled.iframe.withConfig({
  displayName: "ParagraphBoxSection__MapThumbnail",
  componentId: "sc-b33sot-18"
})(["pointer-events:none;border:none;height:100%;width:100%;"]);
const ModalMapIframe = styled(ModalVideoIframe).withConfig({
  displayName: "ParagraphBoxSection__ModalMapIframe",
  componentId: "sc-b33sot-19"
})(["", ";"], media.tablet`
    width: 700px;
    height: 453px;
  `);