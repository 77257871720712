import { useEffect, useRef } from 'react';

// On React 18 useEffects run twice under strict mode
// There situations when you absolutely need a useEffect to run once (e.g. calling an API call)
// The following is hook to prevent an effect from running twice under strict mode
// It is VERY IMPORTANT that this is used ONLY WHEN ABSOLUTELY NEEDED.
// https://medium.com/@arm.ninoyan/fixed-react-18-useeffect-runs-twice-8480f0bd837f

const isDevelopmentRun = !"production" || false;
// uncomment next expression if you use Vite for building
// second condition is added to ensure we are not in the test environment
// as the @testing-library/react 'render' renders the component once
// const isDevelopmentRun = import.meta.env.DEV && import.meta.env.MODE !== 'test';

export const useLegacyEffect = (effect, deps) => {
  const isMountedRef = useRef(!isDevelopmentRun);
  useEffect(() => {
    if (!isMountedRef.current) {
      isMountedRef.current = true;
      return undefined;
    }
    return effect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};