var __jsx = React.createElement;
import React, { useCallback, useEffect, useState } from 'react';
import footprint, { FootprintComponentKind } from '@onefootprint/footprint-js';
import { useHistory } from 'react-router';
import { identityApi } from '@yieldstreet/platform-kit';
import { PageLoader, PageSheet, Paragraph } from '@yieldstreet/ui-kit';
import { testIds } from './Footprint.model';
import { FootprintWrapper, PageContainer } from './Footprint.styles';
import { useRouteInvestorEntityId } from 'pages/investorEntity/utils';
import { useKYCNextStep } from '../../utils';
import { ErrorView } from 'pages/portfolio/common/ErrorView';
export const Footprint = () => {
  const {
    0: isLoading,
    1: setIsLoading
  } = useState(true);
  const {
    0: isDone,
    1: setIsDone
  } = useState(false);
  const history = useHistory();
  const {
    useValidateFootprintTokenMutation,
    useCreateFootprintTokenMutation
  } = identityApi;
  const investorEntityId = useRouteInvestorEntityId();
  const [validateFootprintToken] = useValidateFootprintTokenMutation();
  const [createFootprintToken, {
    data: tokenData,
    isError
  }] = useCreateFootprintTokenMutation();
  const {
    isLoading: isNextStepLoading,
    redirectToNextStep
  } = useKYCNextStep(!isDone);
  useEffect(() => {
    if (!investorEntityId) {
      return;
    }
    createFootprintToken({
      investorEntityId
    });
  }, [createFootprintToken, investorEntityId]);
  const onComplete = useCallback(token => {
    validateFootprintToken({
      token,
      investorEntityId
    });
    setIsDone(true);
  }, [validateFootprintToken, investorEntityId]);
  const onAuth = useCallback(token => {
    validateFootprintToken({
      token,
      investorEntityId
    });
  }, [validateFootprintToken, investorEntityId]);
  const onCancel = useCallback(() => {
    history.goBack();
  }, [history]);
  const onClose = useCallback(() => {
    redirectToNextStep && redirectToNextStep();
  }, [redirectToNextStep]);
  useEffect(() => {
    if (tokenData !== null && tokenData !== void 0 && tokenData.authToken && !isDone) {
      const element = footprint.init({
        kind: FootprintComponentKind.Verify,
        variant: 'modal',
        authToken: tokenData.authToken,
        onComplete,
        onAuth,
        onCancel,
        onClose
      });
      element.render().then(() => setIsLoading(false));
    }
  }, [isLoading, tokenData, onComplete, onAuth, onCancel, isDone, redirectToNextStep, onClose]);
  const showLoader = isLoading || isNextStepLoading;
  const showRedirectMessage = isNextStepLoading && !isLoading;
  return __jsx(PageSheet, {
    title: "Yieldstreet KYC",
    testId: testIds.base.id
  }, __jsx(PageContainer, null, isError ? __jsx(ErrorView, {
    ctaText: "Contact Investor Relations",
    ctaAction: () => {
      var _Intercom, _ref;
      return (_Intercom = (_ref = window).Intercom) === null || _Intercom === void 0 ? void 0 : _Intercom.call(_ref, 'show');
    }
  }) : __jsx(FootprintWrapper, null, showLoader && __jsx(PageLoader, {
    testId: testIds.loader.id
  }), showRedirectMessage && __jsx(Paragraph, null, "You will be redirected soon"))));
};