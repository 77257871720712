import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import update from 'immutability-helper';
import get from "lodash/get";
import * as actions from 'store/actions/accounts';
const initialState = {
  savedAccount: false,
  savingAccount: false,
  accountById: null,
  accountByIdFetched: false,
  accountByIdIsFetching: false,
  accounts: [],
  filteredAccounts: [],
  accountStatus: {},
  accountStatusAllFetching: false,
  accountStatusAllFetched: false,
  accreditationDetails: {},
  investorBankAccounts: {},
  isFetching: false,
  fetchError: false,
  requiredDocuments: {},
  beneficialOwners: null,
  beneficialRequiredDocuments: null,
  beneficialDocumentsStatus: null,
  verification: {},
  verificationStatusError: false,
  verificationEnd: false,
  accountsById: {},
  userProfileById: {},
  userOnboardingStatus: null,
  recurringDeposits: [],
  editAcctError: null,
  editAccountErrorMessage: null,
  editAcctComplete: false,
  createAcctError: null,
  createAccountErrorMessage: null,
  createAcctComplete: false,
  inviteBeneficialOwnerErr: null,
  inviteBeneficialOwnerComplete: false,
  verificationSuccess: null,
  verificationMessage: null,
  verificationError: null,
  depositVerificationStatus: null,
  depositVerificationMessage: null,
  depositVerificationSuccess: null,
  beneficialInviteTokenIsFetching: false,
  beneficialInviteTokenError: null,
  beneficialInviteTokenValidity: null,
  smsTokenIsFetching: false,
  smsTokenError: null,
  smsTokenValidity: null,
  kycDocumentWithSmsTokenIsFetching: false,
  kycDocumentWithSmsTokenError: null,
  kycDocumentWithSmsToken: null,
  depositVerificationIsFetching: false,
  alreadyRedirected: false,
  accountsFetched: false,
  accountsIsFetching: false,
  iraLinkAccount: null,
  availableLinkAccounts: null,
  availableLinkAccountsFetching: false,
  readyToInvestIsFetching: false,
  readyToInvest: null,
  custodians: [],
  custodiansFetching: false,
  distributionReinvestment: null,
  distributionReinvestmentIsFetching: false,
  walletFetching: false,
  rolloverData: [],
  rolloverDataFetched: false,
  rolloverDataError: null,
  individualAccountLimitReached: false
};
export const accountsAnonymizer = {
  accounts: ['array', 'investorAccount'],
  filteredAccounts: ['array', 'investorAccount'],
  accountsById: ['object', ['array', 'investorAccount']],
  userProfileById: ['object', 'userProfile'],
  investorBankAccounts: ['object', ['array', 'bankAccount']],
  accountRecurringDeposits: ['array', 'recurringPayment'],
  beneficialOwners: ['array', 'beneficialOwnerSummary'],
  readyToInvest: {
    investorAccounts: ['array', 'investorAccount']
  }
};
const accounts = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case actions.GET_FILTERED_INVESTOR_ACCOUNTS_ATTEMPT:
    case actions.GET_INVESTOR_ACCOUNTS_BY_ID_ATTEMPT:
    case actions.GET_USER_PROFILE_BY_ID_ATTEMPT:
    case actions.GET_INVESTOR_BANK_ACCT_ATTEMPT:
    case actions.GET_INVESTOR_ACCREDITATION_ATTEMPT:
    case actions.REJECT_VERIFICATION_ATTEMPT:
    case actions.CONFIRM_VERIFICATION_ATTEMPT:
      {
        return update(state, {
          isFetching: {
            $set: true
          }
        });
      }
    case actions.CREATE_IRA_ACCOUNT_ATTEMPT:
      {
        return update(state, {
          savedAccount: {
            $set: false
          },
          savingAccount: {
            $set: true
          },
          isFetching: {
            $set: true
          },
          [`${action.actionType}AcctComplete`]: {
            $set: false
          },
          [`${action.actionType}AcctError`]: {
            $set: null
          }
        });
      }
    case actions.DEPOSIT_VERIFICATION_ATTEMPT:
      {
        return update(state, {
          isFetching: {
            $set: true
          },
          depositVerificationStatus: {
            $set: null
          },
          depositVerificationMessage: {
            $set: null
          },
          depositVerificationIsFetching: {
            $set: true
          }
        });
      }
    case actions.GET_INVESTOR_ACCOUNT_BY_ID_ATTEMPT:
      {
        return update(state, {
          accountByIdIsFetching: {
            $set: true
          },
          isFetching: {
            $set: true
          }
        });
      }
    case actions.GET_INVESTOR_ACCOUNT_BY_ID_SUCCESS:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          accountByIdIsFetching: {
            $set: false
          },
          accountByIdFetched: {
            $set: true
          },
          accountById: {
            $set: action.response
          }
        });
      }
    case actions.GET_INVESTOR_ACCOUNT_BY_ID_FAILURE:
      return update(state, {
        accountByIdIsFetching: {
          $set: false
        },
        isFetching: {
          $set: false
        }
      });
    case actions.GET_FILTERED_INVESTOR_ACCOUNTS_SUCCESS:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          filteredAccounts: {
            $set: action.response
          }
        });
      }
    case actions.GET_INVESTOR_ACCOUNTS_BY_ID_SUCCESS:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          accountsById: {
            $merge: {
              [action.userId]: action.response
            }
          },
          accountsFetched: {
            $set: true
          }
        });
      }
    case actions.GET_ALL_INVESTOR_STATUSES_ATTEMPT:
      {
        return update(state, {
          accountStatusAllFetching: {
            $set: true
          }
        });
      }
    case actions.GET_ALL_INVESTOR_STATUSES_SUCCESS:
      {
        return update(state, {
          accountStatusAllFetching: {
            $set: false
          },
          accountStatus: {
            $set: action.response
          },
          accountStatusAllFetched: {
            $set: true
          }
        });
      }
    case actions.GET_ALL_INVESTOR_STATUSES_FAILURE:
      {
        return update(state, {
          accountStatusAllFetching: {
            $set: false
          },
          accountStatusAllFetched: {
            $set: true
          }
        });
      }
    case actions.GET_USER_PROFILE_BY_ID_SUCCESS:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          userProfileById: {
            $merge: {
              [action.userId]: action.response
            }
          }
        });
      }
    case actions.GET_INVESTOR_BANK_ACCT_SUCCESS:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          investorBankAccounts: {
            $merge: {
              [action.id]: action.response
            }
          }
        });
      }
    case actions.GET_INVESTOR_ACCREDITATION_SUCCESS:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          accreditationDetails: {
            $merge: {
              [action.response.id]: action.response
            }
          }
        });
      }
    case actions.CREATE_IRA_ACCOUNT_SUCCESS:
      {
        const newAcc = {
          id: action.response
        };
        return update(state, {
          isFetching: {
            $set: false
          },
          accounts: {
            $push: [newAcc]
          },
          createAcctComplete: {
            $set: true
          }
        });
      }
    case actions.GET_VERIFICATION_ATTEMPT:
      {
        return update(state, {
          isFetching: {
            $set: true
          },
          verification: {
            $set: {}
          },
          verificationStatusError: {
            $set: false
          }
        });
      }
    case actions.GET_VERIFICATION_SUCCESS:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          verification: {
            $set: action.response
          },
          verificationStatusError: {
            $set: false
          }
        });
      }
    case actions.GET_VERIFICATION_FAILURE:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          verification: {
            $set: {}
          },
          verificationStatusError: {
            $set: true
          }
        });
      }
    case actions.REJECT_VERIFICATION_SUCCESS:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          verificationEnd: {
            $set: true
          }
        });
      }
    case actions.CONFIRM_VERIFICATION_SUCCESS:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          verificationEnd: {
            $set: true
          },
          verificationSuccess: {
            $set: true
          }
        });
      }
    case actions.DEPOSIT_VERIFICATION_SUCCESS:
      {
        const investorBankAccIdx = state.investorBankAccounts[action.investorId].findIndex(acct => acct.id === action.bankAcctId);
        const accIdx = state.accounts.findIndex(acc => acc.id === action.investorId);
        return update(state, {
          isFetching: {
            $set: false
          },
          investorBankAccounts: {
            [action.investorId]: {
              [investorBankAccIdx]: {
                depositVerificationPending: {
                  $set: false
                }
              }
            }
          },
          accounts: {
            [accIdx]: {
              hasWallet: {
                $set: true
              }
            }
          },
          accountStatus: {
            [action.investorId]: {
              bankAccounts: {
                status: {
                  $set: 'CONFIRMED'
                },
                message: {
                  $set: 'Confirmed'
                }
              },
              readyToInvest: {
                $set: true
              }
            }
          },
          depositVerificationSuccess: {
            $set: true
          },
          depositVerificationIsFetching: {
            $set: false
          }
        });
      }
    case actions.GET_FILTERED_INVESTOR_ACCOUNTS_FAILURE:
    case actions.GET_INVESTOR_ACCOUNTS_BY_ID_FAILURE:
    case actions.GET_USER_PROFILE_BY_ID_FAILURE:
    case actions.GET_INVESTOR_ACCREDITATION_FAILURE:
    case actions.REJECT_VERIFICATION_FAILURE:
      {
        return update(state, {
          isFetching: {
            $set: false
          }
        });
      }
    case actions.CREATE_IRA_ACCOUNT_FAILURE:
      {
        const accts = action.response ? {
          accounts: {
            $push: [action.response]
          }
        } : {};
        return update(state, _objectSpread({
          savedAccount: {
            $set: false
          },
          savingAccount: {
            $set: false
          },
          isFetching: {
            $set: false
          },
          [`${action.actionType}AcctError`]: {
            $set: get(action, 'beError.response.data', {
              message: action.error.message || action.error
            })
          },
          [`${action.actionType}AccountErrorMessage`]: {
            $set: action.error.message || action.error
          }
        }, accts));
      }
    case actions.CONFIRM_VERIFICATION_FAILURE:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          verificationSuccess: {
            $set: false
          },
          verificationMessage: {
            $set: action.error
          },
          verificationError: {
            $set: action.beError
          }
        });
      }
    case actions.DEPOSIT_VERIFICATION_FAILURE:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          depositVerificationSuccess: {
            $set: false
          },
          depositVerificationMessage: {
            $set: action.error.message
          },
          depositVerificationIsFetching: {
            $set: false
          }
        });
      }
    case actions.RESET_FORM:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          fetchError: {
            $set: false
          }
        });
      }
    case actions.RESET_SAVED_ACCOUNT:
      {
        return update(state, {
          savedAccount: {
            $set: false
          },
          savingAccount: {
            $set: false
          }
        });
      }
    case actions.RESET_STORE:
      {
        return initialState;
      }
    case actions.GET_USER_WALLET_VALUES_ATTEMPT:
      return update(state, {
        walletFetching: {
          $set: true
        }
      });
    case actions.GET_USER_WALLET_VALUES_SUCCESS:
      const getWalletBalance = id => {
        const wallet = action.response.find(wallet => wallet.investorAccountId === id);
        return wallet ? wallet.availableBalance : 0;
      };
      const newFilteredAccounts = state.filteredAccounts.map(acct => _objectSpread(_objectSpread({}, acct), {}, {
        walletBalance: getWalletBalance(acct.id)
      }));
      return update(state, {
        walletFetching: {
          $set: false
        },
        filteredAccounts: {
          $set: newFilteredAccounts
        }
      });
    case actions.GET_USER_WALLET_VALUES_FAILURE:
      return update(state, {
        walletFetching: {
          $set: false
        }
      });
    case actions.GET_ROLLOVER_ATTEMPT:
      {
        return update(state, {
          rolloverDataFetched: {
            $set: false
          },
          rolloverData: {
            $set: []
          }
        });
      }
    case actions.GET_ROLLOVER_SUCCESS:
      {
        return update(state, {
          rolloverDataFetched: {
            $set: true
          },
          rolloverData: {
            $set: action.response.investments
          }
        });
      }
    case actions.GET_ROLLOVER_FAILURE:
      {
        var _action$response;
        return update(state, {
          rolloverDataFetched: {
            $set: true
          },
          rolloverDataError: {
            $set: action === null || action === void 0 ? void 0 : (_action$response = action.response) === null || _action$response === void 0 ? void 0 : _action$response.message
          }
        });
      }
    default:
      return state;
  }
};
export default accounts;