import { TierLevels } from '@yieldstreet/platform-kit';
import tier_ys_diamond from '@yieldstreet/ui-kit/assets/logos/tier_ys_diamond_logo.svg';
import tier_ys_gold from '@yieldstreet/ui-kit/assets/logos/tier_ys_gold_logo.svg';
import tier_ys_platinum from '@yieldstreet/ui-kit/assets/logos/tier_ys_platinum_logo.svg';
import ysDefault from '@yieldstreet/ui-kit/assets/logos/tier_ys_default_icon.svg';
import ysDiamond from '@yieldstreet/ui-kit/assets/logos/tier_ys_diamond_icon.svg';
import ysGold from '@yieldstreet/ui-kit/assets/logos/tier_ys_gold_icon.svg';
import ysPlatinum from '@yieldstreet/ui-kit/assets/logos/tier_ys_platinum_icon.svg';
export const tierImageMapper = {
  [TierLevels.GOLD]: {
    icon: ysGold,
    logo: tier_ys_gold,
    alt: 'Yieldstreet Gold'
  },
  [TierLevels.PLATINUM]: {
    icon: ysPlatinum,
    logo: tier_ys_platinum,
    alt: 'Yieldstreet Platinum'
  },
  [TierLevels.DIAMOND]: {
    icon: ysDiamond,
    logo: tier_ys_diamond,
    alt: 'Yieldstreet Diamond'
  },
  [TierLevels.DEFAULT]: {
    icon: ysDefault,
    alt: 'Yieldstreet Basic'
  }
};