import _Button from "@mui/material/Button";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var __jsx = React.createElement;
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { DesktopLinkWrapper, MobileTabletLinkWrapper, SLink, Wrapper, SButton } from './InvestmentVote.style';
import { DesktopOnly, MobileOnly, Paragraph } from '@yieldstreet/ui-kit';
import { useTheme } from 'styled-components';
import { VOTE_VALUES } from './InvestmentVote.model';
const InvestmentVote = _ref => {
  var _formikProps$values;
  let {
    onChange,
    name,
    label,
    url,
    formikProps
  } = _ref;
  const theme = useTheme();
  const value = (_formikProps$values = formikProps.values) === null || _formikProps$values === void 0 ? void 0 : _formikProps$values[name];
  const renderButtons = () => {
    const getTheme = () => {
      if ((value === null || value === void 0 ? void 0 : value.vote) === VOTE_VALUES.accept) {
        return theme.colors.background_success;
      }
    };
    return __jsx(_Button, {
      variant: "outlined",
      style: _objectSpread(_objectSpread({}, SButton), {}, {
        backgroundColor: getTheme()
      }),
      "data-cy": "investment-vote-accept",
      onClick: () => onChange(name, VOTE_VALUES.accept)
    }, "Accept");
  };
  return __jsx(React.Fragment, null, __jsx(DesktopOnly, null, __jsx(Wrapper, null, __jsx(DesktopLinkWrapper, null, __jsx(SLink, {
    href: url,
    target: "_blank"
  }, label), __jsx(FontAwesomeIcon, {
    icon: faExternalLinkAlt
  })), renderButtons())), __jsx(MobileOnly, null, __jsx(MobileTabletLinkWrapper, null, __jsx(Paragraph, {
    margin: "s",
    size: "small",
    semiBold: true
  }, label), __jsx(Wrapper, null, __jsx(SLink, {
    href: url,
    target: "_blank"
  }, "View Details"), renderButtons()))));
};
export default InvestmentVote;