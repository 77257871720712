import { useMemo } from 'react';
const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const getLastCommentaryDate = originalDate => {
  if (!originalDate) {
    return '-';
  }
  const date = new Date(originalDate);
  const monthIndex = date.getMonth();
  const month = MONTHS[monthIndex];
  const year = date.getFullYear();
  return `${month} ${year}`;
};
export const useLastCommentary = _ref => {
  let {
    data,
    urlHash,
    userId
  } = _ref;
  const {
    date,
    lastCommentary
  } = useMemo(() => {
    const lastCommentary = urlHash ? data === null || data === void 0 ? void 0 : data[urlHash] : undefined;
    return {
      lastCommentary,
      date: getLastCommentaryDate(lastCommentary === null || lastCommentary === void 0 ? void 0 : lastCommentary.sortDate)
    };
  }, [data, urlHash]);
  return {
    lastCommentary,
    date,
    link: `/activity-details/${userId}/${lastCommentary === null || lastCommentary === void 0 ? void 0 : lastCommentary.id}`
  };
};