import { media } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
export const Wrapper = styled.div.withConfig({
  displayName: "WordPressCardsSectionstyle__Wrapper",
  componentId: "sc-ajdkcj-0"
})(["margin:0 auto 30px;position:relative;", ";"], media.tablet`
    margin: 0 auto 35px;
  `);
export const InnerWrapper = styled.div.withConfig({
  displayName: "WordPressCardsSectionstyle__InnerWrapper",
  componentId: "sc-ajdkcj-1"
})(["display:flex;", ";", ";"], media.tablet`
    padding: 0 35px;
  `, media.large`
    padding: 0;
  `);
export const CardsWrapper = styled.div.withConfig({
  displayName: "WordPressCardsSectionstyle__CardsWrapper",
  componentId: "sc-ajdkcj-2"
})(["display:flex;flex-wrap:wrap;flex-direction:column;justify-content:center;", ";", ";"], media.phoneLandscape`
    flex-direction: row;
    margin: 0 -10px 20px;
    :before {
        bottom: 270px;
        top: 335px;
      }
  `, media.desktop`
    margin: 0 -10px;
  `);