var __jsx = React.createElement;
import React from 'react';
import { CloseIcon, Wrapper } from './CloseButton.style';
import closeIcon from './closeIcon.svg';
export const CloseButton = _ref => {
  let {
    onClick,
    'data-cy': dataCy,
    className
  } = _ref;
  return __jsx(Wrapper, {
    "data-cy": dataCy,
    onClick: onClick,
    className: className
  }, __jsx(CloseIcon, {
    src: closeIcon,
    alt: "close-icon"
  }));
};