import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["children", "type"];
var __jsx = React.createElement;
import React from 'react';
import { SurfaceType } from '@yieldstreet/ui-kit';
import { InformationBlockWrapper } from './InformationBlock.style';
export const InformationBlock = _ref => {
  let {
      children,
      type = SurfaceType.Highlight
    } = _ref,
    surfaceProps = _objectWithoutProperties(_ref, _excluded);
  return __jsx(InformationBlockWrapper, _extends({
    type: type
  }, surfaceProps), children);
};