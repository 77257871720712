import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { PerformanceStatus } from '@yieldstreet/platform-kit';
import { getInvestmentNavigation } from '../../../getInvestmentNavigation';
import { getStatusLabelByPerfStatus } from '../../../getStatusLabelByPerfStatus';
import { InvestmentsListOriginTypes, PerformanceStatusTypes } from '../../../../useInvestmentsList.model';
const getPerformanceStatus = _ref => {
  let {
    record,
    origin
  } = _ref;
  switch (origin) {
    case InvestmentsListOriginTypes.Active:
      return record.performanceStatus;
    case InvestmentsListOriginTypes.Pending:
      /**
       * TODO: Currently `portfolioAvailable` is only in PENDING investments.
       * For other statuses or types, the property is not in the response.
       * This will be added and we can remove the `!== false` check.
       */
      return record.portfolioAvailable !== false ? PerformanceStatusTypes.Pending : PerformanceStatusTypes.Processing;
    default:
      return PerformanceStatus.NONE;
  }
};
const enhanceInvestments = _ref2 => {
  let {
    investments,
    investmentsGates,
    origin,
    managementStrategy
  } = _ref2;
  if (!investments || !origin) {
    return [];
  }
  return investments.map(investment => {
    return _objectSpread(_objectSpread({}, investment), {}, {
      performanceStatusLabel: getStatusLabelByPerfStatus(getPerformanceStatus({
        record: investment,
        origin
      })),
      navigation: getInvestmentNavigation({
        origin,
        record: investment,
        recordGates: investmentsGates,
        managementStrategy
      })
    });
  });
};

/**
 * TODO: The same as `enhanceInvestments`.
 *
 * @deprecated - Use enhanceInvestments instead
 */
const enhanceCommitments = _ref3 => {
  let {
    commitments,
    commitmentsGates,
    origin,
    managementStrategy
  } = _ref3;
  if (!commitments || !origin) {
    return [];
  }
  return commitments.map(commitment => {
    return _objectSpread(_objectSpread({}, commitment), {}, {
      performanceStatusLabel: getStatusLabelByPerfStatus(getPerformanceStatus({
        record: commitment,
        origin
      })),
      navigation: getInvestmentNavigation({
        origin,
        record: commitment,
        recordGates: commitmentsGates,
        managementStrategy
      })
    });
  });
};
export const getEnhancedInvestments = _ref4 => {
  let {
    active,
    investmentsGates,
    matured,
    pending,
    commitmentPending,
    commitmentActive,
    managementStrategy
  } = _ref4;
  const activeInvestments = enhanceInvestments({
    investments: active,
    investmentsGates,
    origin: InvestmentsListOriginTypes.Active,
    managementStrategy
  });
  const maturedInvestments = enhanceInvestments({
    investments: matured,
    investmentsGates,
    origin: InvestmentsListOriginTypes.Matured,
    managementStrategy
  });
  const pendingInvestments = enhanceInvestments({
    investments: pending,
    investmentsGates,
    origin: InvestmentsListOriginTypes.Pending,
    managementStrategy
  });
  const pendingCommitments = enhanceCommitments({
    commitments: commitmentPending,
    commitmentsGates: investmentsGates,
    origin: InvestmentsListOriginTypes.Pending,
    managementStrategy
  });
  const activeCommitments = enhanceCommitments({
    commitments: commitmentActive,
    commitmentsGates: investmentsGates,
    origin: InvestmentsListOriginTypes.Active,
    managementStrategy
  });
  return {
    activeCommitments,
    activeInvestments,
    maturedInvestments,
    pendingCommitments,
    pendingInvestments
  };
};