import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { Tooltip, Heading, Paragraph } from '@yieldstreet/ui-kit';
import { Background, SHeroBanner, ItemsWrapper, Item, TitleContent } from './Essentials.style';
import { items, testIds } from './Essentials.model';
export const Essentials = () => __jsx(Background, null, __jsx(SHeroBanner, {
  title: "Essentials",
  headingType: 3,
  sideContent: __jsx(ItemsWrapper, testIds.itemsWrapper.attr, items.map((item, idx) => __jsx(Item, {
    key: item.title,
    testId: `${testIds.item.id}-${idx}`
  }, __jsx(TitleContent, null, __jsx(Heading, _extends({
    type: 5
  }, testIds.itemTitle.attr), item.title), item !== null && item !== void 0 && item.tooltipText ? __jsx(Tooltip, {
    inverse: true,
    tooltipKey: `${idx}`,
    place: "top",
    testId: `${testIds.itemTooltip.id}-${item.title}`
  }, __jsx(Paragraph, {
    inverse: true,
    size: "small"
  }, item.tooltipText)) : null), __jsx(Paragraph, _extends({
    size: "small"
  }, testIds.itemParagraph.attr), item.description)))),
  testId: testIds.heroBanner.id
}));