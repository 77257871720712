import { useRef, useEffect } from 'react';

// return value from previous render
export const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
};

// return previous changed value
export const usePreviousValue = value => {
  const refValue = useRef();
  const prev = usePrevious(value);
  if (value !== prev) {
    refValue.current = prev;
  }
  return refValue.current;
};

// return whether value has changed during component mount
export const useValueChanged = value => {
  const prevValue = usePreviousValue(value);
  return value !== prevValue;
};