import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["label", "value", "error", "onChange", "caption", "className", "disabled"];
var __jsx = React.createElement;
import React from 'react';
import { SFormControlLabel, SFormHelperText, SFormControl, withFormikWeb, FormError } from '../common';
import { SSwitch } from './styles';
export const ToggleInput = props => {
  const {
      label,
      value,
      error,
      onChange,
      caption,
      className,
      disabled
    } = props,
    otherProps = _objectWithoutProperties(props, _excluded);
  return __jsx(SFormControl, {
    error: !!error,
    className: className,
    disabled: disabled
  }, __jsx(SFormControlLabel, {
    label: label,
    value: value,
    control: __jsx(SSwitch, _extends({}, otherProps, {
      "data-cy": otherProps.testId || 'toggle-input',
      color: "primary",
      checked: value,
      onChange: onChange && (e => onChange(e.target.checked))
    }))
  }), error || caption ? __jsx(SFormHelperText, {
    margin: "dense"
  }, error ? __jsx(FormError, {
    type: "toggle-input",
    testId: "error",
    name: (props === null || props === void 0 ? void 0 : props.name) || 'toggle',
    error: error
  }) : caption) : null);
};
export const FormToggleInput = withFormikWeb(ToggleInput);