import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import qs from 'qs';
import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { QUERY_STRING_CONFIG } from './useFilters.model';
export const useFilters = _ref => {
  let {
    query,
    allQueryKeys
  } = _ref;
  const history = useHistory();
  const {
    pathname,
    hash = ''
  } = useLocation();
  const getQueryString = q => qs.stringify(q, QUERY_STRING_CONFIG);
  const queryString = useMemo(() => getQueryString(query), [query]);
  const updateSearch = useCallback(item => {
    const param = _objectSpread(_objectSpread({}, query), item);
    history.push(`${pathname}?${getQueryString(param)}${hash}`);
  }, [hash, history, pathname, query]);
  const clearAllFilters = useCallback(() => {
    if (!allQueryKeys) {
      return;
    }
    const clearedFilters = {};
    allQueryKeys.forEach(param => clearedFilters[param] = undefined);
    updateSearch(clearedFilters);
  }, [updateSearch, allQueryKeys]);
  return {
    searchFilters: queryString,
    updateSearch,
    clearAllFilters
  };
};