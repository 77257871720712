var __jsx = React.createElement;
import React, { useMemo } from 'react';
import MetricsCard from './MetricsCard';
import { Wrapper, InnerWrapper, CardsWrapper, CardsInnerWrapper } from './MetricCardsSection.style';
const MetricCardsSection = _ref => {
  let {
    section,
    isLoggedIn
  } = _ref;
  const {
    content
  } = section;
  const renderCards = useMemo(() => content.map((card, idx) => {
    const {
      title,
      fields
    } = card;
    return __jsx(MetricsCard, {
      key: `${title}-${idx}`,
      title: title,
      fields: fields,
      isLoggedIn: isLoggedIn
    });
  }), [content, isLoggedIn]);
  return __jsx(Wrapper, null, __jsx(InnerWrapper, null, __jsx(CardsWrapper, null, __jsx(CardsInnerWrapper, null, renderCards))));
};
export default MetricCardsSection;