var __jsx = React.createElement;
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Paragraph } from '../../content/text/Paragraph';
import { media } from '../../themes/media';
export const BulletStrip = _ref => {
  let {
    bulletItems,
    bulletColor = 'primary',
    inverse = true,
    semiBold = true
  } = _ref;
  return __jsx(ItemsContainer, null, bulletItems.map((item, index) => {
    const isLastItem = index === bulletItems.length - 1;
    return __jsx(Fragment, {
      key: index
    }, __jsx(Text, {
      inverse: inverse,
      semiBold: semiBold
    }, item), !isLastItem && __jsx(Ellipsis, {
      bulletColor: bulletColor
    }));
  }));
};
const ItemsContainer = styled.div.withConfig({
  displayName: "BulletStrip__ItemsContainer",
  componentId: "sc-1qeakym-0"
})(["display:flex;flex-direction:column;justify-content:center;text-align:center;", ""], media.desktop`
    flex-direction: row;
  `);
const Text = styled(Paragraph).withConfig({
  displayName: "BulletStrip__Text",
  componentId: "sc-1qeakym-1"
})(["margin-bottom:10px;", ""], media.desktop`
    margin-bottom: 0;
  `);
const Ellipsis = styled.div.withConfig({
  displayName: "BulletStrip__Ellipsis",
  componentId: "sc-1qeakym-2"
})(["", ""], media.desktop`
    margin: 0 20px;
    align-self: center;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: ${props => props.bulletColor === 'primary' ? props.theme.colors.accent_medium : props.theme.colors.accent_light};
  `);