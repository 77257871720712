import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["label", "value", "options", "error", "name", "onChange", "className", "caption", "disabled", "descriptionCheckedOnly", "justify", "direction", "testId"];
var __jsx = React.createElement;
import React from 'react';
import { withFormik } from '@yieldstreet/tool-kit';
import { withQuestion, SFormLabel, SFormGroup, SFormControl, SFormHelperText, FormError, useLabelId } from '../common';
import { CheckboxInput } from '../checkbox-input';
export const CheckboxGroupInput = props => {
  const {
      label,
      value,
      options,
      error,
      name,
      onChange,
      className,
      caption,
      disabled,
      descriptionCheckedOnly,
      justify,
      direction,
      testId
    } = props,
    otherProps = _objectWithoutProperties(props, _excluded);
  const labelId = useLabelId(name);
  const handleChange = option => checked => {
    let selectedOptions = options.filter(option => value && value.includes(option.value));
    if (checked) {
      selectedOptions = selectedOptions.concat(option);
    } else if (value) {
      selectedOptions = selectedOptions.filter(selectedOption => selectedOption.value !== option.value);
    }
    const selectedValues = selectedOptions.map(selectedOption => selectedOption.value);
    onChange && onChange(selectedValues, selectedOptions);
  };
  return __jsx(SFormControl, {
    "data-cy": "checkbox-group-input",
    "data-checkbox-group": true,
    error: !!error,
    className: className,
    disabled: disabled,
    "aria-labelledby": labelId
  }, !!label && __jsx(SFormLabel, {
    "data-checkbox-group-label": true,
    id: labelId
  }, label), __jsx(SFormGroup, {
    "data-checkbox-group-options": true,
    justify: justify,
    direction: direction
  }, options.map(option => __jsx(CheckboxInput, _extends({}, otherProps, {
    key: option.value,
    testId: (option === null || option === void 0 ? void 0 : option.testId) || testId || option.value,
    value: value && value.includes(option.value),
    label: option.label,
    name: `${name}-checkbox-${option.label}`,
    disabled: option.disabled || disabled,
    onChange: handleChange(option),
    description: option.description,
    descriptionCheckedOnly: descriptionCheckedOnly
  })))), error || caption ? __jsx(SFormHelperText, {
    margin: "dense"
  }, error ? __jsx(FormError, {
    testId: "error",
    type: "checkbox-group-input",
    name: (props === null || props === void 0 ? void 0 : props.name) || 'checkbox-group',
    error: error
  }) : caption) : null);
};
export const FormCheckboxGroupInput = withFormik(CheckboxGroupInput);
export const QuestionCheckboxGroupInput = withQuestion(CheckboxGroupInput, 'primary');
export const QuestionSecondaryCheckboxGroupInput = withQuestion(CheckboxGroupInput, 'secondary');
export const TagsCheckboxGroupInput = withQuestion(CheckboxGroupInput, 'tags');
export const FormTagsCheckboxGroupInput = withFormik(TagsCheckboxGroupInput);
export const FormQuestionCheckboxGroupInput = withFormik(QuestionCheckboxGroupInput);
export const FormQuestionSecondaryCheckboxGroupInput = withFormik(QuestionSecondaryCheckboxGroupInput);