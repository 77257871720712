import parsePhoneNumber from 'libphonenumber-js';
export const semiAnonymizePhone = value => {
  if (value) {
    const [prefix, suffix] = value.split('-');
    return `${prefix.replace(/[0-9a-z]/gi, '*')}-${suffix}`;
  }
  return '';
};
export const semiAnonymizePhoneRaw = function (value) {
  let returnMasked = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
  if (value) {
    if (returnMasked) {
      return `(***) ***-${value.substring(value.length - 4, value.length)}`;
    }
    const {
      countryCallingCode,
      nationalNumber
    } = parsePhoneNumber(value) || {};
    const prefix = nationalNumber === null || nationalNumber === void 0 ? void 0 : nationalNumber.substring(0, nationalNumber.length - 4).replace(/[0-9a-z]/gi, '*');
    const suffix = nationalNumber === null || nationalNumber === void 0 ? void 0 : nationalNumber.substring(nationalNumber.length - 4, nationalNumber.length);
    return `+${countryCallingCode}${prefix}${suffix}`;
  }
  return '';
};