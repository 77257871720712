var __jsx = React.createElement;
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { PortfolioStrategyValues } from '@yieldstreet/platform-kit';
import { Spinner, NewModal, useModalContext, useResponsiveFriendly } from '@yieldstreet/ui-kit';
import { useDiscretionaryFlags } from 'hooks';
import { ALL_ACCOUNTS, content, INVESTOR_ACCOUNT_DEEP_LINK, testIds, viewPortMobile } from './InvestorAccountContainer.model';
import { InvestorAccountDesktopWrapper, InvestorAccountDropdownChevron, InvestorAccountDropdownSelector, InvestorAccountDropdownWrapper, InvestorAccountSelectWrapper, InvestorAccountHeading, InvestorAccountMobileWrapper, InvestorAccountSelectedInvestor, InvestorAccountSelectedInvestorBorder, InvestorAccountViewingAs, InvestorAccountLoading, InvestorAccountWrapper } from './InvestorAccountContainer.style';
import { InvestorAccountDropdown } from './InvestorAccountDropdown';
import { InvestorAccountEmpty } from './InvestorAccountEmpty';
import { useInvestorAccounts } from './useInvestorAccounts';
import { getDropdownLabel, mapAccountTotal } from './utils';
import { useInvestorAccountsTotal } from './hooks';
export const InvestorAccountContainer = _ref => {
  let {
    userId,
    investorId,
    onChange,
    managementStrategy = PortfolioStrategyValues.Self
  } = _ref;
  const ref = useRef(null);
  const {
    0: isDropdownOpen,
    1: setIsDropdownOpen
  } = useState(false);
  const {
    0: selectedInvestor,
    1: setSelectedInvestor
  } = useState(null);
  const {
    showModal,
    hideModal
  } = useModalContext();
  const deviceViewPort = useResponsiveFriendly();
  const {
    showManagedPortfolio
  } = useDiscretionaryFlags();
  const {
    hasInvestorAccounts,
    hasMultipleInvestorAccounts,
    investorAccountList,
    isLoading,
    selectedInvestorAccount
  } = useInvestorAccounts({
    investorId,
    managementStrategy
  });
  const {
    investorAccountsTotal,
    isFetching: isFetchingInvestorAccountsTotal
  } = useInvestorAccountsTotal({
    investorAccountList,
    managementStrategy,
    userId
  });
  const handleSelectedInvestor = useCallback(investor => {
    setSelectedInvestor(investor);
    hideModal();
    setIsDropdownOpen(false);
    onChange(investor);
  }, [onChange, hideModal]);
  const isMobile = useMemo(() => deviceViewPort === viewPortMobile, [deviceViewPort]);
  const handleBlur = useCallback(() => {
    if (!isMobile) {
      setIsDropdownOpen(false);
    }
  }, [isMobile]);
  useEffect(() => {
    if (hasInvestorAccounts && (selectedInvestorAccount === null || selectedInvestorAccount === void 0 ? void 0 : selectedInvestorAccount.id) !== (selectedInvestor === null || selectedInvestor === void 0 ? void 0 : selectedInvestor.id)) {
      setSelectedInvestor(selectedInvestorAccount);
    }
    if (hasInvestorAccounts && !isLoading && !selectedInvestorAccount) {
      handleSelectedInvestor(ALL_ACCOUNTS);
    }
  }, [isLoading, selectedInvestor, selectedInvestorAccount, hasInvestorAccounts, handleSelectedInvestor]);
  const handleDropdown = () => {
    if (isDropdownOpen) {
      hideModal();
      setIsDropdownOpen(false);
    } else {
      setIsDropdownOpen(true);
      ref.current.focus();
      isMobile && showModal(INVESTOR_ACCOUNT_DEEP_LINK);
    }
  };
  const accounts = mapAccountTotal(investorAccountList, investorAccountsTotal);
  const InvestorAccountStateWrapper = () => {
    return __jsx("div", {
      "data-cy": testIds.investorAccountDropdownMenu
    }, (isLoading || isFetchingInvestorAccountsTotal) && __jsx(Spinner, {
      "data-cy": testIds.investorAccountIsLoading
    }), !isLoading && !isFetchingInvestorAccountsTotal && (investorAccountList === null || investorAccountList === void 0 ? void 0 : investorAccountList.length) && __jsx(InvestorAccountDropdown, {
      accounts: accounts,
      selectedInvestor: selectedInvestor,
      onSelectedInvestor: handleSelectedInvestor
    }));
  };
  const InvestorAccountViewWrapper = () => {
    return __jsx(React.Fragment, null, isMobile && __jsx(NewModal, {
      modalId: INVESTOR_ACCOUNT_DEEP_LINK,
      onClose: handleDropdown
    }, __jsx(InvestorAccountMobileWrapper, {
      isLoading: isLoading
    }, !isLoading && (investorAccountList === null || investorAccountList === void 0 ? void 0 : investorAccountList.length) && __jsx(InvestorAccountHeading, null, content.selectAccount), __jsx(InvestorAccountStateWrapper, null))), isDropdownOpen && !isMobile && __jsx(InvestorAccountDesktopWrapper, null, __jsx(InvestorAccountStateWrapper, null)));
  };
  return __jsx(InvestorAccountWrapper, {
    "data-cy": testIds.investorAccountWrapper
  }, isLoading && __jsx(InvestorAccountLoading, {
    "data-cy": testIds.investorAccountIsLoading
  }, __jsx("rect", {
    x: "0",
    y: "0",
    rx: "0",
    ry: "0",
    width: "100",
    height: "100"
  })), !isLoading && !selectedInvestorAccount && __jsx(InvestorAccountEmpty, null), !isLoading && selectedInvestorAccount && __jsx(React.Fragment, null, showManagedPortfolio && __jsx(InvestorAccountViewingAs, {
    "data-cy": testIds.investorAccountViewingAs
  }, getDropdownLabel(managementStrategy)), __jsx(InvestorAccountSelectWrapper, {
    "data-cy": testIds.investorAccountSelectWrapper,
    onBlur: handleBlur,
    ref: ref,
    tabIndex: 0
  }, __jsx(InvestorAccountDropdownWrapper, {
    hasMultipleInvestorAccounts: hasMultipleInvestorAccounts
  }, __jsx(InvestorAccountSelectedInvestorBorder, null, __jsx(InvestorAccountSelectedInvestor, {
    onClick: () => hasMultipleInvestorAccounts ? handleDropdown() : undefined,
    "data-cy": testIds.investorAccountSelectedInvestor
  }, selectedInvestor === null || selectedInvestor === void 0 ? void 0 : selectedInvestor.name)), hasMultipleInvestorAccounts && __jsx(InvestorAccountDropdownSelector, {
    onClick: handleDropdown,
    "data-cy": testIds.investorAccountDropdownIcon
  }, __jsx(InvestorAccountDropdownChevron, {
    isOpen: isDropdownOpen
  }))), __jsx(InvestorAccountViewWrapper, null))));
};