import { withFormik } from '@yieldstreet/tool-kit';
import { scrollToQuery } from '../../utils/scroll';
export const withFormikWeb = inputComponent => {
  // This HOC is used to add web specific callbacks to the
  // generic `withFormik` HOC from toolkit

  return withFormik(inputComponent, {
    onSubmitError: _ref => {
      let {
        name
      } = _ref;
      return scrollToQuery(`[data-cy='${name}']`, 'center');
    }
  });
};