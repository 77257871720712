import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
export const MuiButton = {
  defaultProps: {
    variant: 'contained',
    size: 'large'
  },
  styleOverrides: {
    root: _ref => {
      let {
        theme,
        size
      } = _ref;
      return _objectSpread(_objectSpread({
        variants: [{
          props: {
            variant: 'contained',
            color: 'primary'
          },
          style: _ref2 => {
            var _theme$palette$Button, _theme$palette$Button2, _theme$palette$Button3, _theme$palette$Button4, _theme$palette$Button5, _theme$palette$Button6, _theme$palette$Button7, _theme$palette$Button8;
            let {
              theme
            } = _ref2;
            return {
              color: (_theme$palette$Button = theme.palette.Button) === null || _theme$palette$Button === void 0 ? void 0 : (_theme$palette$Button2 = _theme$palette$Button.contained.primary) === null || _theme$palette$Button2 === void 0 ? void 0 : _theme$palette$Button2.color,
              '&:hover': {
                backgroundColor: (_theme$palette$Button3 = theme.palette.Button) === null || _theme$palette$Button3 === void 0 ? void 0 : (_theme$palette$Button4 = _theme$palette$Button3.contained) === null || _theme$palette$Button4 === void 0 ? void 0 : (_theme$palette$Button5 = _theme$palette$Button4.primary) === null || _theme$palette$Button5 === void 0 ? void 0 : _theme$palette$Button5.hover
              },
              backgroundColor: (_theme$palette$Button6 = theme.palette.Button) === null || _theme$palette$Button6 === void 0 ? void 0 : (_theme$palette$Button7 = _theme$palette$Button6.contained) === null || _theme$palette$Button7 === void 0 ? void 0 : (_theme$palette$Button8 = _theme$palette$Button7.primary) === null || _theme$palette$Button8 === void 0 ? void 0 : _theme$palette$Button8.background
            };
          }
        }, {
          props: {
            variant: 'contained',
            color: 'secondary'
          },
          style: _ref3 => {
            var _theme$palette$Button9, _theme$palette$Button10, _theme$palette$Button11, _theme$palette$Button12, _theme$palette$Button13, _theme$palette$Button14, _theme$palette$Button15, _theme$palette$Button16;
            let {
              theme
            } = _ref3;
            return {
              color: (_theme$palette$Button9 = theme.palette.Button) === null || _theme$palette$Button9 === void 0 ? void 0 : (_theme$palette$Button10 = _theme$palette$Button9.contained.secondary) === null || _theme$palette$Button10 === void 0 ? void 0 : _theme$palette$Button10.color,
              '&:hover': {
                backgroundColor: (_theme$palette$Button11 = theme.palette.Button) === null || _theme$palette$Button11 === void 0 ? void 0 : (_theme$palette$Button12 = _theme$palette$Button11.contained) === null || _theme$palette$Button12 === void 0 ? void 0 : (_theme$palette$Button13 = _theme$palette$Button12.secondary) === null || _theme$palette$Button13 === void 0 ? void 0 : _theme$palette$Button13.hover
              },
              backgroundColor: (_theme$palette$Button14 = theme.palette.Button) === null || _theme$palette$Button14 === void 0 ? void 0 : (_theme$palette$Button15 = _theme$palette$Button14.contained) === null || _theme$palette$Button15 === void 0 ? void 0 : (_theme$palette$Button16 = _theme$palette$Button15.secondary) === null || _theme$palette$Button16 === void 0 ? void 0 : _theme$palette$Button16.background
            };
          }
        }, {
          props: {
            variant: 'outlined',
            color: 'tertiary'
          },
          style: _ref4 => {
            var _theme$palette$Button17, _theme$palette$Button18, _theme$palette$Button19;
            let {
              theme
            } = _ref4;
            return {
              border: `1px solid ${(_theme$palette$Button17 = theme.palette.Button.outlined.tertiary) === null || _theme$palette$Button17 === void 0 ? void 0 : _theme$palette$Button17.border}`,
              color: (_theme$palette$Button18 = theme.palette.Button.outlined.tertiary) === null || _theme$palette$Button18 === void 0 ? void 0 : _theme$palette$Button18.color,
              '&:hover': {
                background: (_theme$palette$Button19 = theme.palette.Button.outlined.tertiary) === null || _theme$palette$Button19 === void 0 ? void 0 : _theme$palette$Button19.hover
              }
            };
          }
        }, {
          props: {
            variant: 'contained',
            color: 'marketing'
          },
          style: _ref5 => {
            var _theme$palette$Button20, _theme$palette$Button21, _theme$palette$Button22;
            let {
              theme
            } = _ref5;
            return {
              backgroundColor: (_theme$palette$Button20 = theme.palette.Button.contained.marketing) === null || _theme$palette$Button20 === void 0 ? void 0 : _theme$palette$Button20.background,
              color: (_theme$palette$Button21 = theme.palette.Button.contained.marketing) === null || _theme$palette$Button21 === void 0 ? void 0 : _theme$palette$Button21.color,
              '&:hover': {
                backgroundColor: (_theme$palette$Button22 = theme.palette.Button.contained.marketing) === null || _theme$palette$Button22 === void 0 ? void 0 : _theme$palette$Button22.hover
              }
            };
          }
        }]
      }, size === 'large' && {
        padding: theme.spacing(2, 4)
      }), {}, {
        textTransform: 'none',
        borderRadius: '15rem',
        boxShadow: 'none',
        '&:hover': {
          boxShadow: 'none'
        }
      });
    }
  }
};