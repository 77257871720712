import { InvestorEntityGateCode, UserGateCode } from '@yieldstreet/platform-kit';
import { ELIGIBILITY_GATE_CONTENT } from './eligibilityGateContent';
import { EligibilityGateLevel, getGateContent } from './eligibilityGateContent.model';
export const getEligibilityGateContent = (managementStrategy, _ref, investorEntity, investorEntityStatus) => {
  let {
    userGate,
    investorEntityGate,
    investorAccountGate
  } = _ref;
  const {
    [EligibilityGateLevel.USER]: userGateContent,
    [EligibilityGateLevel.INVESTOR_ENTITY]: investorEntityGateContent,
    [EligibilityGateLevel.INVESTOR_ACCOUNT]: investorAccountGateContent
  } = ELIGIBILITY_GATE_CONTENT[managementStrategy];
  if (userGate && userGate.gateCode !== UserGateCode.INVESTOR_ENTITY_GATE) {
    const content = userGateContent(userGate)[userGate.gateCode];
    return getGateContent(content, userGate);
  }
  if (investorEntity && investorEntityStatus && investorEntityGate && (investorEntityGate === null || investorEntityGate === void 0 ? void 0 : investorEntityGate.gateCode) !== InvestorEntityGateCode.INVESTOR_ACCOUNT_GATE) {
    const content = investorEntityGateContent(investorEntity, investorEntityStatus, investorEntityGate)[investorEntityGate.gateCode];
    return getGateContent(content, investorEntityGate);
  }
  if (investorAccountGate && investorEntity && investorEntityStatus) {
    const content = investorAccountGateContent(investorEntity, investorEntityStatus, investorAccountGate)[investorAccountGate.gateCode];
    return getGateContent(content, investorAccountGate);
  }
  return;
};