var __jsx = React.createElement;
import React from 'react';
import { SnackbarContext, useSnackbarContext } from '@yieldstreet/tool-kit';
export const SnackbarProvider = _ref => {
  let {
    children
  } = _ref;
  const {
    snackbarQueue,
    enqueueSnackbar,
    closeSnackbar
  } = useSnackbarContext();
  return __jsx(SnackbarContext.Provider, {
    value: {
      snackbarQueue,
      enqueueSnackbar,
      closeSnackbar
    }
  }, children);
};