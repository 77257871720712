import { useMemo } from 'react';
import { sortManagedAssets, usePortfolioStrategy } from '../overview';
import { getAssets, getDefaultAssets, mapToInvestmentAssets, showDefaultAssets } from './useInvestmentsAssets.util';
export const useInvestmentsAssets = _ref => {
  let {
    assetsBreakdown,
    icons,
    investorId,
    managementStrategy,
    userId
  } = _ref;
  const {
    strategy
  } = usePortfolioStrategy({
    investorId,
    managementStrategy,
    userId
  });
  const filteredInvestmentsassets = useMemo(() => {
    const defaultInvestmentAssets = getDefaultAssets({
      defaultInvestmentAssets: mapToInvestmentAssets(icons),
      strategy
    });
    return showDefaultAssets(assetsBreakdown) ? defaultInvestmentAssets : getAssets({
      assetsBreakdown,
      defaultInvestmentAssets
    });
  }, [assetsBreakdown, icons, strategy]);
  return sortManagedAssets(filteredInvestmentsassets || []);
};