var __jsx = React.createElement;
import React from 'react';
import { FormSelectInput, FormTextInput } from '@yieldstreet/ui-kit';
import { AdviceQuestionsConfig } from 'utils/advice';
import { FieldSectionWrapper, FieldSectionHeader } from './Fields.style';
const {
  CurrentIncome,
  EmploymentStatus,
  Employer,
  Position,
  Industry
} = AdviceQuestionsConfig;
const getIsEmployed = formikProps => {
  var _formikProps$values, _formikProps$values$E;
  return (formikProps === null || formikProps === void 0 ? void 0 : (_formikProps$values = formikProps.values) === null || _formikProps$values === void 0 ? void 0 : (_formikProps$values$E = _formikProps$values[EmploymentStatus.key]) === null || _formikProps$values$E === void 0 ? void 0 : _formikProps$values$E.toString()) === EmploymentStatus.values.Employed;
};
export const EmploymentFields = _ref => {
  let {
    formikProps
  } = _ref;
  const isEmployed = getIsEmployed(formikProps);
  return __jsx(React.Fragment, null, __jsx(FieldSectionHeader, {
    description: "Tell us more about your employment experience and professional background."
  }, "Employment"), __jsx(FieldSectionWrapper, {
    noMarginBottom: isEmployed
  }, __jsx(FormSelectInput, {
    name: EmploymentStatus.key,
    label: EmploymentStatus.label,
    options: EmploymentStatus.options,
    formikProps: formikProps
  })), isEmployed && __jsx(FieldSectionWrapper, null, __jsx(FormTextInput, {
    name: Employer.key,
    label: Employer.label,
    type: "text",
    formikProps: formikProps
  }), __jsx(FormTextInput, {
    name: Position.key,
    label: Position.label,
    type: "text",
    formikProps: formikProps
  }), __jsx(FormSelectInput, {
    name: Industry.key,
    label: Industry.label,
    options: Industry.options,
    formikProps: formikProps
  }), __jsx(FormSelectInput, {
    name: CurrentIncome.key,
    label: CurrentIncome.label,
    options: CurrentIncome.options,
    formikProps: formikProps
  })));
};