import { MuiAccordion } from './muiAccordion';
import { MuiAlert } from './muiAlert';
import { MuiButton } from './muiButton';
import { MuiCssBaseline } from './muiCssBaseline';
import { MuiFilledInput } from './muiFilledInput';
import { MuiLink } from './muiLink';
import { MuiLoadingButton } from './muiLoadingButton';
import { MuiSelect } from './muiSelect';
import { MuiStack } from './muiStack';
import { MuiTextField } from './muiTextField';
import { MuiTypography } from './muiTypography';
export const components = {
  MuiAccordion,
  MuiAlert,
  MuiButton,
  MuiCssBaseline,
  MuiFilledInput,
  MuiLink,
  MuiLoadingButton,
  MuiSelect,
  MuiStack,
  MuiTextField,
  MuiTypography
};