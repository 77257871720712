import { CALL_API } from '../middleware/Middleware';
export const GET_METRICS_ATTEMPT = 'ys/metrics/ATTEMPT';
export const GET_METRICS_SUCCESS = 'ys/metrics/SUCCESS';
export const GET_METRICS_FAILURE = 'ys/metrics/FAILURE';
export const getBatchOfferingMetrics = offerings => dispatch => {
  offerings.forEach(offering => {
    const endpoint = `/a/api/marketplace/metrics/${offering.urlHash}`;
    return dispatch({
      [CALL_API]: {
        endpoint,
        types: [GET_METRICS_ATTEMPT, GET_METRICS_SUCCESS, GET_METRICS_FAILURE],
        getResponse: res => res.data,
        successPayload: {
          urlHash: offering.urlHash
        }
      }
    });
  });
};
export const getSingleMetric = urlHash => dispatch => {
  const endpoint = `/a/api/marketplace/metrics/${urlHash}`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_METRICS_ATTEMPT, GET_METRICS_SUCCESS, GET_METRICS_FAILURE],
      getResponse: res => res.data,
      successPayload: {
        urlHash: urlHash
      }
    }
  });
};