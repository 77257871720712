import { useModal } from '@yieldstreet/ui-kit';
import { Modals } from 'constants/modal.model';
import { useRouteInvestorEntityId } from 'pages/investorEntity/utils';
export const useDefaultBankModal = function () {
  let {
    investorEntityId,
    onClose,
    onSuccess
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const routeInvestorEntityId = useRouteInvestorEntityId();
  return useModal(Modals.DefaultBank, {
    props: {
      investorEntityId: investorEntityId || routeInvestorEntityId
    },
    onClose,
    onSuccess
  });
};