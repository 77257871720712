var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';

/* Utilities */
import { media } from '../../themes/media';
/* Base styles */
import { Paragraph } from '../../content/text';

/* ============================
 *    Callout module
 * ============================ */

export const Callout = _ref => {
  let {
    copy
  } = _ref;
  return __jsx(CalloutUI, null, __jsx(CalloutText, null, copy));
};

/* ===== Component UI elements ===== */

const CalloutUI = styled.div.withConfig({
  displayName: "callouts__CalloutUI",
  componentId: "sc-14zmvzw-0"
})(["box-sizing:border-box;padding:20px 0px;background-color:", ";margin:0 auto;position:relative;top:60px;width:100%;text-align:center;", " ", ""], props => props.theme.colors.background_card, media.tablet`
    width:80%;
  `, media.desktop`
    text-align:left;
    height:220px;
    width:200px;
    position:absolute;
    top:auto;
    right:0;
    bottom:120px;
    padding:40px 15px;
  `);

/* ===== Type styles ==== */

const CalloutText = styled(Paragraph).withConfig({
  displayName: "callouts__CalloutText",
  componentId: "sc-14zmvzw-1"
})(["width:80%;margin:0 auto;max-width:420px;"]);