var __jsx = React.createElement;
import React, { useEffect } from 'react';
import { ConsentManager as SegmentNativeConsentManager } from '@segment/consent-manager';
import inEU from '@segment/in-eu';
import styled from 'styled-components';
import { Button } from '../../../../content';
import { SEGMENT_CDN_HOST } from './SegmentConsent.model';
export const SegmentConsentManager = _ref => {
  let {
    segmentWriteKey
  } = _ref;
  useEffect(() => {
    // Google Tag Manager is loaded as a Segment destination
    // We set the gtag function to avoid errors if app uses it before GTM is loaded
    window.dataLayer = window.dataLayer || [];
    window.gtag = function () {
      window.dataLayer.push(arguments);
    };
    window.gtag('js', new Date());

    // The default consent is set to 'granted' for all categories for GTM when using Segment Consent Manager
    // This is to ensure that even if Google consent mode is enabled in GTM and all tags are set to require consent,
    // the tags will still fire, since Segment Consent Manager doesn't integrate with Google Consent Mode.

    window.gtag('consent', 'default', {
      ad_personalization: 'granted',
      ad_storage: 'granted',
      ad_user_data: 'granted',
      analytics_storage: 'granted',
      functionality_storage: 'granted',
      personalization_storage: 'granted',
      security_storage: 'granted'
    });
  }, []);
  const bannerContent = __jsx("span", {
    "data-cy": "cookies-consent-text"
  }, "We use cookies (and other similar technologies) to collect data to improve your experience on our site. ", __jsx("br", null), "By using our website, you're agreeing to the collection of data as described in our", ' ', __jsx("a", {
    href: "/privacy/",
    target: "_blank"
  }, "Privacy Policy"), ".");
  const bannerSubContent = 'You can change your preferences at any time.';
  const preferencesDialogTitle = 'Website Data Collection Preferences';
  const preferencesDialogContent = 'We use data collected by cookies and JavaScript libraries to improve your browsing experience, analyze site traffic, deliver personalized advertisements, and increase the overall performance of our site.';
  const cancelDialogTitle = 'Are you sure you want to cancel?';
  const cancelDialogContent = "Your preferences have not been saved. By continuing to use our website, you're agreeing to our Website Data Collection Policy.";
  return __jsx(ConsentManagerContainer, null, __jsx(SegmentNativeConsentManager, {
    writeKey: segmentWriteKey,
    cdnHost: SEGMENT_CDN_HOST,
    shouldRequireConsent: inEU,
    bannerContent: bannerContent,
    bannerSubContent: bannerSubContent,
    bannerActionsBlock: _ref2 => {
      let {
        acceptAll,
        denyAll
      } = _ref2;
      return __jsx(ConsentButtonContainer, null, __jsx(Button, {
        small: true,
        reducedPadding: true,
        onClick: () => acceptAll(),
        "data-cy": "cookies-consent-accept-all"
      }, "Accept All"), __jsx(Button, {
        buttonType: "secondary",
        small: true,
        inverse: true,
        reducedPadding: true,
        onClick: () => denyAll(),
        "data-cy": "cookies-consent-deny-all"
      }, "Deny All"));
    },
    preferencesDialogTitle: preferencesDialogTitle,
    preferencesDialogContent: preferencesDialogContent,
    cancelDialogTitle: cancelDialogTitle,
    cancelDialogContent: cancelDialogContent,
    onError: error => {
      if (false) {
        // eslint-disable-next-line no-console
        console.warn({
          error
        });
      }
    }
  }));
};
const ConsentManagerContainer = styled.div.withConfig({
  displayName: "SegmentConsentManager__ConsentManagerContainer",
  componentId: "sc-1akzlgb-0"
})(["position:fixed;bottom:0;width:100%;z-index:999;> div{padding:15px;padding-right:45px;background:", ";text-align:left;}"], props => props.theme.colors.background_inverse_light);
const ConsentButtonContainer = styled.div.withConfig({
  displayName: "SegmentConsentManager__ConsentButtonContainer",
  componentId: "sc-1akzlgb-1"
})(["display:inline-block;margin-top:5px;button:not(:first-child){margin-left:15px;}"]);