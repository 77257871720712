import * as actions from 'store/actions/audiences';
import createAPIReducer from './common/createAPIReducer';
export default createAPIReducer(actions, 'RESOLVE_AUDIENCES', {
  initData: {
    data: {
      audiences: {}
    },
    fetched: false
  },
  successHandler: action => ({
    data: {
      $merge: action.response,
      audiences: {
        $merge: action.response.audiences
      }
    },
    fetched: {
      $set: true
    }
  })
});