import { wordpressApi, ArticleType } from '@yieldstreet/platform-kit';
export const useWPCards = articleSlug => {
  const {
    useWpOfferingArticlesQuery
  } = wordpressApi;
  const {
    data: articleResponse,
    isFetching: isFetchingWPArticle
  } = useWpOfferingArticlesQuery({
    postType: [ArticleType.Posts, ArticleType.LearningTool],
    articleSlug
  });
  const getTitle = () => {
    var _articleResponse$wpAr, _title;
    if (!(articleResponse !== null && articleResponse !== void 0 && articleResponse.wpArticle)) {
      return '';
    }
    let title = (_articleResponse$wpAr = articleResponse.wpArticle) === null || _articleResponse$wpAr === void 0 ? void 0 : _articleResponse$wpAr.title.rendered.replace('&#8211;', '–').replace('&#8217;', '’');
    if (((_title = title) === null || _title === void 0 ? void 0 : _title.length) > 70) {
      title = title.substring(0, 70).replace(/(?:\r\n|\r|\n)/g, '').concat('', '...');
    }
    return title;
  };
  const getDescription = () => {
    var _articleResponse$wpAr2, _description;
    if (!(articleResponse !== null && articleResponse !== void 0 && articleResponse.wpArticle)) {
      return '';
    }
    let description = (_articleResponse$wpAr2 = articleResponse.wpArticle) === null || _articleResponse$wpAr2 === void 0 ? void 0 : _articleResponse$wpAr2.excerpt.rendered.replace('<p>', '').replace('</p>', '').replace('[&hellip;]', '').replace('&nbsp;', '').replace(/&#.*;/gi, '');
    if (((_description = description) === null || _description === void 0 ? void 0 : _description.length) > 200) {
      description = description.substring(0, 200).replace(/(?:\r\n|\r|\n)/g, '').concat('', '...');
    } else if (description) {
      description.replace(/(?:\r\n|\r|\n)/g, '').concat('', '...');
    }
    return description;
  };
  const getImage = () => {
    var _articleResponse$wpAr3, _articleResponse$wpAr4, _articleResponse$wpAr5, _sizes$full;
    if (!(articleResponse !== null && articleResponse !== void 0 && articleResponse.wpArticle)) {
      return '';
    }
    const sizes = (_articleResponse$wpAr3 = articleResponse.wpArticle) === null || _articleResponse$wpAr3 === void 0 ? void 0 : (_articleResponse$wpAr4 = _articleResponse$wpAr3._embedded['wp:featuredmedia'][0]) === null || _articleResponse$wpAr4 === void 0 ? void 0 : (_articleResponse$wpAr5 = _articleResponse$wpAr4.media_details) === null || _articleResponse$wpAr5 === void 0 ? void 0 : _articleResponse$wpAr5.sizes;
    if (!sizes) {
      return 'GENERIC';
    }
    if (sizes !== null && sizes !== void 0 && sizes.large) {
      var _sizes$large;
      return (_sizes$large = sizes.large) === null || _sizes$large === void 0 ? void 0 : _sizes$large.source_url;
    }
    return sizes === null || sizes === void 0 ? void 0 : (_sizes$full = sizes.full) === null || _sizes$full === void 0 ? void 0 : _sizes$full.source_url;
  };
  return {
    isFetchingWPArticle,
    title: getTitle(),
    description: getDescription(),
    image: getImage()
  };
};