export const mockContentCards = {
  cards: [{
    id: '1',
    viewed: true,
    title: 'What Every Investor Needs to Know About EDGAR',
    imageUrl: 'https://braze-images.com/appboy/communication/marketing/content_cards_message_variations/images/65cd1a8e6a39060056c192d7/46ec5d8376e9f2c1fef9f139a17207eb557c0538/original.png?1707940498',
    description: 'message',
    categories: [],
    url: '/investment-recommendations/',
    linkText: 'Open',
    aspectRatio: 1,
    extras: {
      summary: 'bottom',
      platform: 'web'
    },
    pinned: false,
    dismissible: true,
    clicked: true,
    dismissed: false,
    isControl: false
  }, {
    id: '2',
    viewed: true,
    title: 'What Every Investor Needs to Know About EDGAR',
    imageUrl: 'https://braze-images.com/appboy/communication/marketing/content_cards_message_variations/images/65cd1a8e6a39060056c192d7/46ec5d8376e9f2c1fef9f139a17207eb557c0538/original.png?1707940498',
    description: 'message',
    categories: [],
    url: '/investment-recommendations/',
    linkText: 'Open',
    aspectRatio: 1,
    extras: {
      summary: 'bottom',
      platform: 'mobile'
    },
    pinned: false,
    dismissible: true,
    clicked: true,
    dismissed: false,
    isControl: false
  }]
};