import { useMemo, useState } from 'react';
import { AudiencePresetTypes, TierLevels } from '@yieldstreet/platform-kit';
import { useAudiences } from '@yieldstreet/tool-kit';
export const useTiersLetter = content => {
  var _audiencesData$audien, _audiencesData$audien2;
  const {
    0: hasOptedIn,
    1: setHasOptedIn
  } = useState(false);
  const {
    audiencesData,
    audiencesIsError,
    audiencesIsLoading,
    refetchAudiences
  } = useAudiences(AudiencePresetTypes.PORTFOLIO);
  const {
    isOptedIn: tierIsOptedIn,
    level: tierLevel
  } = (audiencesData === null || audiencesData === void 0 ? void 0 : (_audiencesData$audien = audiencesData.audiences) === null || _audiencesData$audien === void 0 ? void 0 : (_audiencesData$audien2 = _audiencesData$audien.TIER) === null || _audiencesData$audien2 === void 0 ? void 0 : _audiencesData$audien2.meta) || {};

  // FIX ME
  // @ts-expect-error
  const tierContent = content[tierLevel];
  return useMemo(() => {
    // FIX ME
    // @ts-expect-error
    const hasTier = [TierLevels.GOLD, TierLevels.PLATINUM, TierLevels.DIAMOND].includes(tierLevel);
    const hasAudience = !audiencesIsError && !audiencesIsLoading;
    const isOnboardingTier = !tierIsOptedIn && hasTier;
    const showTierModal = !audiencesIsLoading && !hasOptedIn && tierContent && hasAudience && isOnboardingTier;
    return {
      hasOptedIn,
      refetchAudiences,
      setHasOptedIn,
      showTierModal,
      tierContent,
      tierLevel
    };
  }, [audiencesIsError, audiencesIsLoading, hasOptedIn, refetchAudiences, tierContent, tierIsOptedIn, tierLevel]);
};