import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useMemo } from 'react';
import flatten from "lodash/flatten";
import { skipToken } from '@reduxjs/toolkit/query';
import { allToken, AudiencePresetTypes, investmentsApi, investorEntityApi, PortfolioStrategyValues } from '@yieldstreet/platform-kit';
import { offeringDefaultQuery, investmentsDefaultQuery, currentInvestorDefaultQuery } from './usePortfolioComposition.model';
import { useByOpenOffers } from './use-by-open-offers';
import { useAudiences } from '../../../hooks';
export const usePortfolioComposition = _ref => {
  var _investmentsByAssetCl, _investmentsByAssetCl2;
  let {
    investmentsQuery = investmentsDefaultQuery,
    offeringsQuery = offeringDefaultQuery,
    currentInvestorQuery = currentInvestorDefaultQuery,
    userId,
    managementStrategy = PortfolioStrategyValues.Self
  } = _ref;
  const {
    useGetInvestmentsByAssetClassQuery,
    useGetInvestmentsByOfferingQuery,
    useGetInvestmentsByStatusQuery
  } = investmentsApi;
  const {
    useGetInvestorEntityQuery
  } = investorEntityApi;
  const investmentsQueryConfig = useMemo(() => userId ? _objectSpread(_objectSpread({
    userId,
    managementStrategy
  }, investmentsQuery), currentInvestorQuery) : skipToken, [userId, investmentsQuery, currentInvestorQuery]);
  const offeringQueryConfig = useMemo(() => userId ? _objectSpread(_objectSpread({
    userId,
    managementStrategy
  }, offeringsQuery), currentInvestorQuery) : skipToken, [userId, offeringsQuery, currentInvestorQuery]);
  const {
    currentData: investmentsByAssetClassData,
    isError: investmentsByAssetClassIsError,
    isFetching: investmentsByAssetClassIsLoading
  } = useGetInvestmentsByAssetClassQuery(investmentsQueryConfig);
  const {
    data: investmentsByOfferingData,
    isError: investmentsByOfferingIsError,
    isFetching: investmentsByOfferingIsLoading
  } = useGetInvestmentsByOfferingQuery(offeringQueryConfig);
  const {
    data: investmentsByStatusData,
    isError: investmentsByStatusIsError,
    isFetching: investmentsByStatusIsLoading
  } = useGetInvestmentsByStatusQuery(investmentsQueryConfig);
  const {
    data: investorEntities
  } = useGetInvestorEntityQuery(allToken);
  const {
    audiencesResolution
  } = useAudiences(AudiencePresetTypes.PORTFOLIO);
  const assetClasses = (_investmentsByAssetCl = investmentsByAssetClassData === null || investmentsByAssetClassData === void 0 ? void 0 : (_investmentsByAssetCl2 = investmentsByAssetClassData.currentValueBreakdown) === null || _investmentsByAssetCl2 === void 0 ? void 0 : _investmentsByAssetCl2.map(asset => asset.assetClass)) !== null && _investmentsByAssetCl !== void 0 ? _investmentsByAssetCl : [];
  const dataByOpenOffers = useByOpenOffers(assetClasses, audiencesResolution === null || audiencesResolution === void 0 ? void 0 : audiencesResolution.allNAI);
  const investmentsList = useMemo(() => {
    const investmentsByStatus = {
      active: (investmentsByStatusData === null || investmentsByStatusData === void 0 ? void 0 : investmentsByStatusData.byStatus.ACTIVE.investments) || [],
      matured: (investmentsByStatusData === null || investmentsByStatusData === void 0 ? void 0 : investmentsByStatusData.byStatus.MATURED.investments) || [],
      pending: (investmentsByStatusData === null || investmentsByStatusData === void 0 ? void 0 : investmentsByStatusData.byStatus.PENDING.investments) || []
    };
    return flatten(Object.values(investmentsByStatus));
  }, [investmentsByStatusData]);
  const currentValueBreakdownByOffering = investmentsByOfferingData === null || investmentsByOfferingData === void 0 ? void 0 : investmentsByOfferingData.currentValueBreakdown.map(offerBreakdown => {
    const investment = investmentsList.find(investment => (investment === null || investment === void 0 ? void 0 : investment.noteUrlHash) === offerBreakdown.noteUrlHash);
    return _objectSpread(_objectSpread({}, offerBreakdown), {}, {
      investorAccountId: investment === null || investment === void 0 ? void 0 : investment.investorAccountId
    });
  });
  const investmentsByAssetClass = {
    investmentsByAssetClassData,
    investmentsByAssetClassIsError,
    investmentsByAssetClassIsLoading
  };
  const investmentsByOffering = {
    investmentsByOfferingData: _objectSpread(_objectSpread({}, investmentsByOfferingData), {}, {
      currentValueBreakdown: currentValueBreakdownByOffering
    }),
    investmentsByOfferingIsError,
    investmentsByOfferingIsLoading
  };
  const investmentsByStatus = {
    investmentsByStatusData,
    investmentsByStatusIsError,
    investmentsByStatusIsLoading
  };
  return {
    investmentsByAssetClass,
    investmentsByOffering,
    investmentsByStatus,
    investorEntities,
    dataByOpenOffers
  };
};