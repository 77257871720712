var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import showIcon from '../../../assets/graphics/icons/show.svg';
import hideIcon from '../../../assets/graphics/icons/hide.svg';
export const ShowHideToggle = _ref => {
  let {
    show,
    onChange,
    name
  } = _ref;
  return __jsx(ShowHideLink, {
    "data-js-show-hide-toggle": true,
    onClick: () => onChange(!show)
  }, __jsx("img", {
    src: show ? hideIcon : showIcon,
    alt: show ? `Show ${name}` : `Hide ${name}`
  }));
};
const ShowHideLink = styled.a.withConfig({
  displayName: "ShowHideToggle__ShowHideLink",
  componentId: "sc-d7e8u5-0"
})(["display:inline-flex;cursor:pointer;"]);