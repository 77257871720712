import { InvestorEntityProvider, ManagementStrategy, InvestorEntityTypeValue, InvestorEntityType } from '@yieldstreet/platform-kit';
export const useInvestorEntityFlags = investorEntity => {
  var _investorEntity$provi;
  const selfDirectedInvestorAccount = investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.investorAccounts.find(act => act.managementStrategy === ManagementStrategy.Self);
  const managedInvestorAccount = investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.investorAccounts.find(act => act.managementStrategy === ManagementStrategy.Discretionary);
  const isETCAccount = (investorEntity === null || investorEntity === void 0 ? void 0 : (_investorEntity$provi = investorEntity.provider) === null || _investorEntity$provi === void 0 ? void 0 : _investorEntity$provi.type) === InvestorEntityProvider.ETC;
  const isYSIRA = (investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.type.name) === (InvestorEntityType.TRADITIONAL_IRA || InvestorEntityType.ROTH_IRA);
  const hasManagedAccount = !!managedInvestorAccount;
  const canTransferFunds = !isETCAccount && (investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.walletEnabled);
  const isIndividualAccount = (investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.type.value) === InvestorEntityTypeValue.INDV;
  const isLegacyIRA = (investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.type.name) === InvestorEntityType.LEGACY_IRA;
  return {
    selfDirectedInvestorAccount,
    managedInvestorAccount,
    isETCAccount,
    isLegacyIRA,
    isYSIRA,
    hasManagedAccount,
    canTransferFunds,
    isIndividualAccount
  };
};