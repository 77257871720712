import styled, { css } from 'styled-components';
import { media } from '../../themes';
import { Heading, Paragraph } from '../../content';
import { SectionContainer } from '../../layout';
export const SSectionContainer = styled(SectionContainer).withConfig({
  displayName: "AdvantagesSectionstyles__SSectionContainer",
  componentId: "sc-1qdlt34-0"
})(["padding:0;", ""], media.desktop`
    padding: 0 var(--sectionContainerPaddingHorizontal);
  `);
const responsivePadding = css(["", ""], _ref => {
  let {
    hasSmallItems
  } = _ref;
  const mediaSize = hasSmallItems ? 'large' : 'desktop';
  return css(["", ""], media[mediaSize]`
          ${props => `padding: 0 ${props.theme.spacing['2xl']}`}
        `);
});
export const Container = styled.div.withConfig({
  displayName: "AdvantagesSectionstyles__Container",
  componentId: "sc-1qdlt34-1"
})(["", ""], props => props.hasPadding && responsivePadding);
export const TextWrapper = styled.div.withConfig({
  displayName: "AdvantagesSectionstyles__TextWrapper",
  componentId: "sc-1qdlt34-2"
})(["max-width:720px;", ""], props => props.align === 'center' && 'margin: 0 auto');
export const SParagraph = styled(Paragraph).withConfig({
  displayName: "AdvantagesSectionstyles__SParagraph",
  componentId: "sc-1qdlt34-3"
})(["", ""], _ref2 => {
  let {
    theme
  } = _ref2;
  return css(["margin-top:", ";margin-bottom:", ";", ""], theme.spacing.xs, theme.spacing.sm, media.desktop`
      margin-bottom: ${theme.spacing.m};
    `);
});
export const SHeading = styled(Heading).withConfig({
  displayName: "AdvantagesSectionstyles__SHeading",
  componentId: "sc-1qdlt34-4"
})(["color:", ";"], props => props.theme.colors.text_label);
export const AdvantagesContainer = styled.div.withConfig({
  displayName: "AdvantagesSectionstyles__AdvantagesContainer",
  componentId: "sc-1qdlt34-5"
})(["", ""], _ref3 => {
  let {
    theme,
    hasSmallItems
  } = _ref3;
  return css(["display:flex;flex-direction:column;row-gap:", ";margin-top:", ";", " ", ""], theme.spacing.l, theme.spacing.l, media.desktop`
      display: grid;
      grid-template-columns: ${hasSmallItems ? 'repeat(4, 232px)' : 'repeat(3, 270px)'};
      margin-top: ${theme.spacing.ml};
      justify-content: space-between;
      row-gap: ${theme.spacing.xl};
    `, media.large`
      column-gap: ${theme.spacing.xl};
    `);
});
export const Advantage = styled.div.withConfig({
  displayName: "AdvantagesSectionstyles__Advantage",
  componentId: "sc-1qdlt34-6"
})(["", ""], media.desktop`
    display: flex;
    flex-direction: column;
  `);
export const Icon = styled.img.withConfig({
  displayName: "AdvantagesSectionstyles__Icon",
  componentId: "sc-1qdlt34-7"
})(["margin-bottom:", ";width:", ";"], props => props.theme.spacing.sm, props => props.iconsSize);
export const Stat = styled.div.withConfig({
  displayName: "AdvantagesSectionstyles__Stat",
  componentId: "sc-1qdlt34-8"
})(["", ""], _ref4 => {
  let {
    theme
  } = _ref4;
  return css(["display:flex;column-gap:", ";border-top:1px solid ", ";padding-top:", ";align-items:center;word-break:normal;", ""], theme.spacing.sm, theme.colors.border_default, theme.spacing.sm, media.desktop`
      padding-top: ${theme.spacing.m};
      margin-top: auto;
    `);
});