var __jsx = React.createElement;
import React, { useEffect, useMemo, useRef } from 'react';
import { NavigationTabListTestIds as testIds } from './NavigationTabList.model';
import { NavigationTabListContainer } from './NavigationTabList.style';
import { NavigationLink, findActiveNavItem } from '../common';
import { TabList, Tab } from '../../elements';
import { getNavItemVisible, useNavItemProps } from '../common';
export const NavigationTabList = props => {
  const {
    navigationConfig,
    currentPath,
    renderLink,
    vertical,
    textWrap,
    testId
  } = props;
  const navProps = useNavItemProps(props);
  const activeTab = useMemo(() => findActiveNavItem(navigationConfig, {}, currentPath), [navigationConfig, currentPath]);
  const scrollRef = useRef(null);
  useEffect(() => {
    if (scrollRef !== null && scrollRef !== void 0 && scrollRef.current) {
      scrollRef.current.scrollIntoView({
        behaviour: 'smooth',
        block: 'nearest',
        inline: 'center'
      });
    }
  }, [scrollRef]);
  const renderNavigationTab = navigationItem => __jsx(NavigationLink, {
    key: navigationItem.key,
    navItem: navigationItem,
    renderLink: renderLink
  }, __jsx(Tab, {
    testId: testIds.tab,
    title: (navigationItem === null || navigationItem === void 0 ? void 0 : navigationItem.title) || navigationItem.key,
    icon: navigationItem.icon,
    isActive: activeTab === navigationItem,
    numericBadge: navigationItem === null || navigationItem === void 0 ? void 0 : navigationItem.numericBadge,
    ref: activeTab === navigationItem ? scrollRef : null,
    vertical: vertical,
    textWrap: textWrap
  }));
  return __jsx(NavigationTabListContainer, {
    vertical: vertical,
    "data-cy": `${testId ? testId : testIds.container}`
  }, __jsx(TabList, {
    vertical: vertical
  }, navigationConfig.map(navigationItem => {
    if (getNavItemVisible(navigationItem, navProps)) {
      return renderNavigationTab(navigationItem);
    } else {
      return null;
    }
  })));
};