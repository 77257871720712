var __jsx = React.createElement;
import React, { useEffect } from 'react';
import { CallbackLink } from './CallbackLink';
const zendeskWatcher = () => {
  const zendeskInterval = setInterval(() => {
    const zendeskIframe = document.querySelector('iframe#launcher');
    if (!!zendeskIframe) {
      const zendeskStyle = zendeskIframe.getAttribute('style');
      zendeskIframe === null || zendeskIframe === void 0 ? void 0 : zendeskIframe.classList.add('mui-fixed');
      zendeskIframe === null || zendeskIframe === void 0 ? void 0 : zendeskIframe.setAttribute('style', `${zendeskStyle} width: auto !important`);
      clearInterval(zendeskInterval);
    }
  }, 1000);
};
export const ZendeskLink = _ref => {
  let {
    children,
    showForm,
    className
  } = _ref;
  useEffect(() => {
    zendeskWatcher();
  }, []);
  return __jsx(CallbackLink, {
    className: className,
    callback: () => zendeskLaunch(showForm)
  }, children);
};

// Wrapped zendesk API calls in a function since zendesk script might not be fully loaded when method is called
// See documentation here https://developer.zendesk.com/embeddables/docs/widget/api
const zendeskCall = callback => window.zE && window.zE(() => {
  callback(window.zE);
});
const suppressHelp = suppress => zendeskCall(zE => zE('webWidget', 'updateSettings', {
  webWidget: {
    helpCenter: {
      suppress: suppress
    }
  }
}));
export const zendeskLaunch = showForm => {
  showForm && suppressHelp(true);
  zendeskCall(zE => zE.activate());
  showForm && suppressHelp(false);
};
export const zendeskIdentify = userState => {
  zendeskCall(zE => zE.identify({
    name: userState.displayName,
    email: userState.emailAddress
  }));
};
export const zendeskClear = () => zendeskCall(zE => zE('webWidget', 'clear'));