import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { InvestorEntityTypeName } from '@yieldstreet/platform-kit';
import { PageLoader, Label, Paragraph, Heading } from '@yieldstreet/ui-kit';
import { useInvestorEntityFlags } from '@yieldstreet/tool-kit';
import { useDiscretionaryFlags } from 'hooks';
import { useRouteInvestorEntity } from 'pages/investorEntity/utils';
import { Link } from 'sharedComponents/Links/Links';
import { NumberFormat } from 'sharedComponents/NumberFormat/NumberFormat';
import { PORTFOLIO_INVESTOR_ID_PARAM, PORTFOLIO_SD_OVERVIEW_SCREEN_PATH } from 'pages/portfolio';
import { testIds } from './Portfolios.model';
import { HeaderWrapper, BalanceWrapper, FooterWrapper, AccountInfoWrapper, DetailInfo, HR, WalletBlock } from './Portfolios.style';
import { useInvestorEntityWallet } from '../../hooks';
import tractionNumbers from '../../../../../../../static-files/tractionNumbers.json';
import { AccountInfo } from './AccountInfo';
import { useInvestorEntityAction } from '../../utils';
export const SelfDirectPortfolio = () => {
  const {
    investorEntity,
    investorEntityStatus
  } = useRouteInvestorEntity();
  const {
    showManagedPortfolio
  } = useDiscretionaryFlags();
  const {
    nextAction: nextEntityAction
  } = useInvestorEntityAction({
    investorEntity,
    investorEntityStatus
  });

  // View Helpers

  const hasEntityAction = !!nextEntityAction;
  const entityReadyToInvest = investorEntityStatus === null || investorEntityStatus === void 0 ? void 0 : investorEntityStatus.readyToInvest;
  const {
    selfDirectedWallet,
    isLoadingWallets
  } = useInvestorEntityWallet(investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.id);
  const {
    isETCAccount,
    selfDirectedInvestorAccount
  } = useInvestorEntityFlags(investorEntity);
  if (isLoadingWallets || !investorEntity) {
    return __jsx(WalletBlock, null, __jsx(PageLoader, {
      center: true,
      testId: testIds.pageLoader.id
    }));
  }
  return __jsx(WalletBlock, {
    testId: testIds.selfAccount.id
  }, __jsx(HeaderWrapper, null, __jsx(Heading, _extends({
    type: 5
  }, testIds.accountTitle.attr), showManagedPortfolio ? 'Direct' : InvestorEntityTypeName[investorEntity.type.name]), entityReadyToInvest && !hasEntityAction && __jsx(Link, _extends({}, testIds.viewPortfolio.attr, {
    to: PORTFOLIO_SD_OVERVIEW_SCREEN_PATH.replace(PORTFOLIO_INVESTOR_ID_PARAM, `${selfDirectedInvestorAccount === null || selfDirectedInvestorAccount === void 0 ? void 0 : selfDirectedInvestorAccount.id}`)
  }), "View portfolio")), __jsx(BalanceWrapper, null, __jsx(DetailInfo, null, __jsx(Label, {
    small: true
  }, "Available balance"), __jsx(NumberFormat, _extends({
    decimalScale: 2,
    displayType: "text",
    fixedDecimalScale: true,
    prefix: "$",
    thousandSeparator: true,
    value: Number(selfDirectedWallet === null || selfDirectedWallet === void 0 ? void 0 : selfDirectedWallet.availableBalance) > 0 ? selfDirectedWallet === null || selfDirectedWallet === void 0 ? void 0 : selfDirectedWallet.availableBalance : 0
  }, testIds.cashBalance.attr))), !isETCAccount && __jsx(DetailInfo, null, __jsx(Label, {
    small: true
  }, "Interest rate"), __jsx(Paragraph, testIds.interestRate.attr, tractionNumbers.walletInterestRate))), __jsx(HR, null), __jsx(FooterWrapper, null, !entityReadyToInvest && __jsx(Paragraph, null, "Account action pending. Once it\u2019s resolved, account setup will continue"), entityReadyToInvest && __jsx(AccountInfoWrapper, null, __jsx(AccountInfo, null))));
};