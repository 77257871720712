import { PaymentStatus } from '../../../enums/portfolio/payment-status';
export const mockPaymentActivityApiGetResponse = {
  userId: 1,
  activity: [{
    date: '2022-08-31',
    interest: 110,
    netInterest: 100,
    netIncome: 200,
    fexp: 10,
    cash: 500.98,
    cashLegacyFunds: 100.98,
    reInvest: 10,
    total: 620.98,
    // netInterest + cash + fexp + reInvest
    totalWithoutFexp: 610.98,
    // netInterest + cash + reInvest
    totalNetFexpNetKnownCapital: 410.98,
    // netInterest + netIncome + cashLegacyFunds + reInvest
    investmentsPerGraphItem: {
      '--DfDw': {
        status: 'ACTIVE',
        noteUrlHash: '--DfDw',
        title: 'Short term notes 1',
        assetClass: 'SHTN',
        launchDate: '2022-08-22',
        amount: 0,
        investmentType: 'DEAL'
      },
      stfIIg: {
        status: 'MATURED',
        noteUrlHash: 'stfIIg',
        title: 'YS fund test',
        assetClass: 'ARTF',
        launchDate: '2022-08-22',
        amount: 203.98,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2022-09-30',
    interest: 110,
    netInterest: 100,
    netIncome: 200,
    fexp: 10,
    cash: 200.98,
    cashLegacyFunds: 100.98,
    reInvest: 10,
    total: 320.98,
    // netInterest + cash + fexp + reInvest
    totalWithoutFexp: 310.98,
    // netInterest + cash + reInvest
    totalNetFexpNetKnownCapital: 410.98,
    // netInterest + netIncome + cashLegacyFunds + reInvest
    investmentsPerGraphItem: {
      '--DfDw': {
        status: 'ACTIVE',
        noteUrlHash: '--DfDw',
        title: 'Short term notes 1',
        assetClass: 'SHTN',
        launchDate: '2022-08-22',
        amount: 0,
        investmentType: 'DEAL'
      },
      stfIIg: {
        status: 'MATURED',
        noteUrlHash: 'stfIIg',
        title: 'YS fund test',
        assetClass: 'ARTF',
        launchDate: '2022-08-22',
        amount: 0.1,
        investmentType: 'FUND'
      }
    }
  }, {
    date: '2022-10-31',
    interest: 110,
    netInterest: 100,
    netIncome: 200,
    fexp: 10,
    cash: 200.98,
    cashLegacyFunds: 100.98,
    reInvest: 10,
    total: 320.98,
    // netInterest + cash + fexp + reInvest
    totalWithoutFexp: 310.98,
    // netInterest + cash + reInvest
    totalNetFexpNetKnownCapital: 410.98,
    // netInterest + netIncome + cashLegacyFunds + reInvest
    investmentsPerGraphItem: {
      '--DfDw': {
        status: 'ACTIVE',
        noteUrlHash: '--DfDw',
        title: 'Short term notes 1',
        assetClass: 'SHTN',
        launchDate: '2022-08-22',
        amount: 0,
        investmentType: 'DEAL'
      }
    }
  }],
  total: {
    interest: 340,
    netInterest: 300,
    netIncome: 600,
    fexp: 30,
    cash: 602.94,
    cashLegacyFunds: 302.94,
    reInvest: 30,
    total: 962.94,
    // netInterest + cash + fexp + reInvest
    totalWithoutFexp: 932.94,
    // netInterest + cash + reInvest
    totalNetFexpNetKnownCapital: 1232.94 // netInterest + netIncome + cashLegacyFunds + reInvest
  },
  investments: [{
    status: 'MATURED',
    noteUrlHash: 'stfIIg',
    title: 'YS fund test',
    assetClass: 'ARTF',
    launchDate: '2022-08-22',
    amount: 204.08,
    investmentType: 'FUND'
  }]
};
export const mockPaymentsApiGetPaymentsYearsResponse = {
  success: true,
  years: [2018, 2019, 2020, 2021, 2022]
};
export const mockPaymentsApiGetClearedPaymentsResponse = {
  currentPage: 1,
  totalCount: 1210,
  totalPages: 61,
  items: [{
    anticipatedSettlementDate: null,
    bankName: 'Schultz Group',
    bankNickname: 'Synapse YS AEF II LLC',
    clearDate: 1606399200000,
    currencyCode: 'USD',
    date: 'September 29, 2023',
    destAcctName: '',
    destName: 'Art Equity Fund II',
    effectiveDate: 1606399200000,
    fundDistributionFees: null,
    fundDistributionType: null,
    interest: 0,
    investmentPending: false,
    investorAccountId: 346822,
    legalEntityType: 'FUND',
    loanId: 0,
    notes: '',
    noteTitle: 'Art Equity Fund II',
    noteUrlHash: 'OiKSnA',
    otherName: 'Art Equity Fund II',
    principal: 10000,
    processDate: 'September 29, 2023',
    processOn: null,
    ratePerShare: null,
    reInvestedShares: null,
    reInvestmentSharePrice: null,
    sourceName: 'Entity Tester',
    status: PaymentStatus.Cleared,
    totalAmount: 10000,
    transactionAmount: 10000,
    transactionDate: 'September 29, 2023',
    transactionInterest: 0,
    transactionPrincipal: 10000,
    transactionType: 'INVESTMENT',
    transactionTypeDisplayName: 'Investment',
    transferDate: 'September 29, 2023',
    isFund: true,
    investmentMatured: false
  }, {
    anticipatedSettlementDate: null,
    bankName: 'Schultz Group',
    bankNickname: 'Synapse YS AEF II LLC',
    clearDate: 1606399200000,
    currencyCode: 'USD',
    date: 'October 4, 2022',
    destAcctName: '',
    destName: 'Art Equity Fund II',
    effectiveDate: 1606399200000,
    fundDistributionFees: null,
    fundDistributionType: null,
    interest: 0,
    investmentPending: false,
    investorAccountId: 346822,
    legalEntityType: 'FUND',
    loanId: 0,
    notes: '',
    noteTitle: 'Art Equity Fund II',
    noteUrlHash: 'OiKSnA',
    otherName: 'Art Equity Fund II',
    principal: 10000,
    processDate: 'October 4, 2022',
    processOn: null,
    ratePerShare: null,
    reInvestedShares: null,
    reInvestmentSharePrice: null,
    sourceName: 'Entity Tester',
    status: PaymentStatus.Cleared,
    totalAmount: 10000,
    transactionAmount: 10000,
    transactionDate: 'October 4, 2022',
    transactionInterest: 0,
    transactionPrincipal: 10000,
    transactionType: 'INVESTMENT',
    transactionTypeDisplayName: 'Investment',
    transferDate: 'October 4, 2022',
    isFund: true,
    investmentMatured: false
  }, {
    anticipatedSettlementDate: null,
    bankName: 'Koelpin-Roob',
    bankNickname: 'Yieldstreet Wallet',
    clearDate: 1606399200000,
    currencyCode: 'USD',
    date: 'March 2, 2022',
    destAcctName: '',
    destName: 'Delpha Towne',
    effectiveDate: 1606399200000,
    fundDistributionFees: null,
    fundDistributionType: null,
    interest: 0.09,
    investmentPending: null,
    investorAccountId: 1,
    legalEntityType: 'FUND',
    loanId: 0,
    notes: 'Interest Accrual',
    noteTitle: 'null',
    noteUrlHash: 'OiKSnA',
    otherName: '',
    principal: 0,
    processDate: 'March 2, 2022',
    processOn: null,
    ratePerShare: null,
    reInvestedShares: null,
    reInvestmentSharePrice: null,
    sourceName: 'YieldStreet Wallet Interest',
    status: PaymentStatus.Cleared,
    totalAmount: 0.09,
    transactionAmount: -0.09,
    transactionDate: 'March 2, 2022',
    transactionInterest: -0.09,
    transactionPrincipal: 0,
    transactionType: 'WALLET_INTEREST',
    transactionTypeDisplayName: 'Wallet interest',
    transferDate: 'March 2, 2022',
    isFund: false,
    investmentMatured: false
  }, {
    date: 'February 27, 2025',
    userId: 493246,
    createdDate: '2025-02-27',
    anticipatedSettlementDate: 'March 6, 2025',
    effectiveDate: '2025-02-27T14:00:00Z',
    processDate: 'February 27, 2025',
    processOn: 0,
    transactionDate: 'February 27, 2025',
    transferDate: 'February 27, 2025',
    clearDate: null,
    loanId: 0,
    investorAccountId: 394636,
    sourceName: 'Plaid Checking',
    destName: 'ATOMIC Sleeve',
    transactionType: 'DEPOSIT',
    principal: 0,
    interest: 0,
    totalAmount: 1e5,
    currencyCode: 'USD',
    transactionPrincipal: 0,
    transactionInterest: 0,
    notes: 'Managed Deposit Payment',
    status: PaymentStatus.Cleared,
    investmentPending: null,
    investmentMatured: null,
    noteTitle: null,
    noteUrlHash: null,
    legalEntityType: null,
    fundDistributionType: null,
    ratePerShare: null,
    reInvestedShares: null,
    reInvestmentSharePrice: null,
    fundDistributionFees: null,
    bankName: '',
    bankNickname: 'ATOMIC Sleeve',
    isFund: null,
    paymentMethod: 'ACH',
    fundConfig: null,
    referenceNumber: null,
    investorBankAccountName: 'Plaid Checking',
    transactionAmount: 0,
    otherName: 'Plaid Checking',
    destAcctName: '',
    transactionTypeDisplayName: 'Deposit'
  }],
  success: true
};
export const mockPaymentsApiGetClearedPaymentsEmptyResponse = {
  currentPage: 1,
  totalCount: 1210,
  totalPages: 61,
  items: [],
  success: true
};
export const mockPaymentsApiGetProcessingPaymentsResponse = {
  currentPage: 1,
  totalCount: 171,
  totalPages: 9,
  items: [{
    anticipatedSettlementDate: null,
    bankName: '',
    bankNickname: 'Synapse Theorem LE',
    clearDate: 1606399200000,
    currencyCode: 'USD',
    date: 'October 5, 2022',
    destAcctName: '',
    destName: "Theorem - don't use",
    effectiveDate: 1606399200000,
    fundDistributionFees: null,
    fundDistributionType: null,
    interest: 0,
    investmentPending: true,
    investorAccountId: 1,
    legalEntityType: 'FUND',
    loanId: 0,
    notes: '',
    noteTitle: "Theorem - don't use",
    noteUrlHash: 'ZSbVxQ',
    otherName: "Theorem - don't use",
    principal: 1000,
    processDate: 'October 5, 2022',
    processOn: null,
    ratePerShare: null,
    reInvestedShares: null,
    reInvestmentSharePrice: null,
    sourceName: 'Delpha Towne',
    status: PaymentStatus.Pending,
    totalAmount: 1000,
    transactionAmount: 1000,
    transactionDate: 'October 5, 2022',
    transactionInterest: 0,
    transactionPrincipal: 1000,
    transactionType: 'INVESTMENT',
    transactionTypeDisplayName: 'Investment',
    transferDate: 'October 5, 2022',
    isFund: false,
    investmentMatured: false
  }, {
    anticipatedSettlementDate: null,
    bankName: '',
    bankNickname: 'Synapse Test EqLE',
    clearDate: 1606399200000,
    currencyCode: 'USD',
    date: 'October 5, 2022',
    destAcctName: '',
    destName: 'Test EQ',
    effectiveDate: 1606399200000,
    fundDistributionFees: null,
    fundDistributionType: null,
    interest: 0,
    investmentPending: false,
    investorAccountId: 1,
    legalEntityType: 'FUND',
    loanId: 0,
    notes: '',
    noteTitle: 'Test EQ',
    noteUrlHash: '6tdLLg',
    otherName: 'Test EQ',
    principal: 2500,
    processDate: 'October 5, 2022',
    processOn: null,
    ratePerShare: null,
    reInvestedShares: null,
    reInvestmentSharePrice: null,
    sourceName: 'Delpha Towne',
    status: PaymentStatus.Pending,
    totalAmount: 2500,
    transactionAmount: 2500,
    transactionDate: 'October 5, 2022',
    transactionInterest: 0,
    transactionPrincipal: 2500,
    transactionType: 'INVESTMENT',
    transactionTypeDisplayName: 'Investment',
    transferDate: 'October 5, 2022',
    isFund: false,
    investmentMatured: false
  }, {
    date: 'February 27, 2025',
    userId: 493246,
    createdDate: '2025-02-27',
    anticipatedSettlementDate: 'March 6, 2025',
    effectiveDate: '2025-02-27T14:00:00Z',
    processDate: 'February 27, 2025',
    processOn: 0,
    transactionDate: 'February 27, 2025',
    transferDate: 'February 27, 2025',
    clearDate: null,
    loanId: 0,
    investorAccountId: 394636,
    sourceName: 'Plaid Checking',
    destName: 'ATOMIC Sleeve',
    transactionType: 'DEPOSIT',
    principal: 0,
    interest: 0,
    totalAmount: 1e5,
    currencyCode: 'USD',
    transactionPrincipal: 0,
    transactionInterest: 0,
    notes: 'Managed Deposit Payment',
    status: PaymentStatus.Pending,
    investmentPending: null,
    investmentMatured: null,
    noteTitle: null,
    noteUrlHash: null,
    legalEntityType: null,
    fundDistributionType: null,
    ratePerShare: null,
    reInvestedShares: null,
    reInvestmentSharePrice: null,
    fundDistributionFees: null,
    bankName: '',
    bankNickname: 'ATOMIC Sleeve',
    isFund: null,
    paymentMethod: 'ACH',
    fundConfig: null,
    referenceNumber: null,
    investorBankAccountName: 'Plaid Checking',
    transactionAmount: 0,
    otherName: 'Plaid Checking',
    destAcctName: '',
    transactionTypeDisplayName: 'Deposit'
  }],
  success: true
};