import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { FormTextInput, FormSelectInput } from '@yieldstreet/ui-kit';
import { USStatesSelectOptions } from './StateSelection.model';
export const StateSelection = _ref => {
  let {
    disabled = false,
    country,
    formikProps,
    formSelectProps,
    formTextInputProps
  } = _ref;
  return country === 'US' ? __jsx(FormSelectInput, _extends({
    label: "State",
    placeholder: "Select state",
    formikProps: formikProps,
    options: USStatesSelectOptions,
    fullWidth: true,
    allowNone: true,
    disabled: disabled
  }, formSelectProps)) : __jsx(FormTextInput, _extends({
    type: "text",
    label: "State",
    formikProps: formikProps,
    fullWidth: true,
    disabled: disabled
  }, formTextInputProps));
};