var __jsx = React.createElement;
import React from 'react';
import styled, { css } from 'styled-components';
import { getConfig } from './common';
import { sizeCss } from '../../../utils/sizes';
import { ColContainer } from './Col';
import { GridProvider } from './Provider';
export const RowContainer = styled.div.withConfig({
  displayName: "Row__RowContainer",
  componentId: "sc-1hs2q83-0"
})(["box-sizing:border-box;display:flex;flex:1 1 auto;flex-wrap:wrap;flex-direction:", ";justify-content:", ";", " ", ""], props => props.reverse ? 'row-reverse' : 'row', props => props.justify || 'stretch', props => sizeCss(_ref => {
  let {
    columnGutter
  } = _ref;
  return css(["margin-right:", "px;margin-left:", "px;> ", "{padding-right:", "px;padding-left:", "px;}"], columnGutter / 2 * -1, columnGutter / 2 * -1, ColContainer, columnGutter / 2, columnGutter / 2);
}, {
  columnGutter: getConfig(props.theme, 'gutter')
}), props => sizeCss(_ref2 => {
  let {
    columnSpacing
  } = _ref2;
  return css(["&:first-child{margin-top:", "px;}&:last-child{margin-bottom:", "px;}> ", "{padding-top:", "px;padding-bottom:", "px;}"], columnSpacing / 2 * -1, columnSpacing / 2 * -1, ColContainer, columnSpacing / 2, columnSpacing / 2);
}, {
  columnSpacing: getConfig(props.theme, 'spacing')
}));
export const Row = _ref3 => {
  let {
    children,
    config,
    reverse,
    justify
  } = _ref3;
  return __jsx(GridProvider, {
    config: config
  }, __jsx(RowContainer, {
    reverse: reverse,
    justify: justify
  }, children));
};