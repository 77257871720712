import { PromotionType, OfferingPromotionGateCode } from '@yieldstreet/platform-kit';
export const PROMOTION_CTAS = {
  [PromotionType.FREE_INVESTMENT]: 'Unlock shares'
};
export const PROMOTION_GATE_ERRORS = {
  [OfferingPromotionGateCode.LIMIT_REACHED]: 'You have reached the limit of use for this promotion.',
  [OfferingPromotionGateCode.NOT_ELIGIBLE]: 'You are not eligible to use this promotion.',
  [OfferingPromotionGateCode.PROMOTION_INACTIVE]: 'Promotion not available anymore.',
  [OfferingPromotionGateCode.PROMOTION_INVALID]: 'Invalid promotion code.'
};
export const PROMOTION_ELEGIBILITY_ERROR = 'Promotion eligibility check failed.';