import { HttpMethods } from '../../enums/http';
import { restApi, Tags } from '../common';
export const marketplaceMetricsApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.MarketplaceMetrics]
}).injectEndpoints({
  endpoints: builder => {
    const getSingleOfferingMetrics = builder.query({
      query: noteUrlHash => ({
        url: `/a/api/marketplace/metrics/${noteUrlHash}`,
        method: HttpMethods.Get
      }),
      providesTags: [Tags.MarketplaceMetrics]
    });
    return {
      getSingleOfferingMetrics
    };
  }
});