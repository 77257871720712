var __jsx = React.createElement;
import React, { useCallback } from 'react';
import isFunction from "lodash/isFunction";
import { useHistory } from 'react-router';
import ChevronLeft from '../../../assets/graphics/icons/chevron-left.svg';
import { PageSheetBackButtonWrapper } from './PageSheetBackButton.style';
export const PageSheetBackButton = _ref => {
  let {
    onClick
  } = _ref;
  const history = useHistory();
  const handleClick = useCallback(() => {
    if (isFunction(onClick)) {
      onClick();
    }
    history.goBack();
  }, [history, onClick]);
  return __jsx(PageSheetBackButtonWrapper, {
    "data-cy": "page-sheet-back-button",
    onClick: handleClick
  }, __jsx("img", {
    src: ChevronLeft
  }));
};