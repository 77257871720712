export const carouselSection = {
  type: 'carousel',
  title: 'Impala focus',
  autoplayInterval: 60000,
  content: [{
    slideTitle: 'Market dynamic',
    slideText: 'The Loan is structured with an upfront interest reserve of $110K to cover the shortfall in debt service payments for the first 12 months of the Loan. In the event that the lease amendment and extension become effective.'
  }, {
    slideTitle: 'Collateral coverage',
    slideText: 'The Loan is structured with an upfront interest reserve of $110K to cover the shortfall in debt service payments for the first 12 months of the Loan. In the event that the lease amendment and extension become effective.'
  }, {
    slideTitle: 'Upfront reserves',
    slideText: 'The Loan is structured with an upfront interest reserve of $110K to cover the shortfall in debt service payments for the first 12 months of the Loan. In the event that the lease amendment and extension become effective.'
  }, {
    slideTitle: 'Sponsor guarantee',
    slideText: 'The Loan is structured with an upfront interest reserve of $110K to cover the shortfall in debt service payments for the first 12 months of the Loan. In the event that the lease amendment and extension become effective.'
  }, {
    slideTitle: 'Originator experience',
    slideText: 'The Loan is structured with an upfront interest reserve of $110K to cover the shortfall in debt service payments for the first 12 months of the Loan. In the event that the lease amendment and extension become effective.'
  }]
};