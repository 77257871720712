import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useMemo } from 'react';
import { tierData, TieredPricingItemType } from './tieredPricing.model';
import { formatCurrency } from '../investment/investment.utils';
export const formatAmounts = function () {
  let amount = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [0, 0];
  let alt = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  if (amount[0] > -1 && amount[1] !== Infinity) {
    return `${formatCurrency(amount[0])} - ${formatCurrency(amount[1])}`;
  } else {
    return `${formatCurrency(amount[0])} ${alt}`;
  }
};
export const useTiers = currentValue => {
  return useMemo(() => tierData.map(tier => _objectSpread(_objectSpread({}, tier), {}, {
    type: currentValue >= tier.amount[0] && currentValue <= tier.amount[1] && currentValue !== 0 ? TieredPricingItemType.ACTIVE : TieredPricingItemType.INACTIVE
  })), [currentValue, tierData]);
};