import styled from 'styled-components';
import { rgba } from 'polished';
import { media } from '../../themes';
export const ModalHeader = styled.div.withConfig({
  displayName: "Modalstyle__ModalHeader",
  componentId: "sc-1lxdwb3-0"
})(["text-align:right;z-index:2;position:relative;", ";"], props => props.hideXMark && `display: none`);
export const ModalBackground = styled.div.withConfig({
  displayName: "Modalstyle__ModalBackground",
  componentId: "sc-1lxdwb3-1"
})(["position:fixed;height:100%;width:100%;background:", ";top:0;left:0;z-index:999;display:flex;align-items:center;justify-content:center;padding:0;", ""], props => rgba(props.theme.colors.background_inverse, 0.8), media.tablet`
    padding: 1rem;
  `);
export const ModalBody = styled.div.withConfig({
  displayName: "Modalstyle__ModalBody",
  componentId: "sc-1lxdwb3-2"
})(["background:white;width:", ";height:", ";padding:1rem;overflow-y:auto;display:flex;flex-direction:column;max-height:100%;", ";"], props => props.width ? `${props.width} !important` : '100%', props => props.height ? `${props.height} !important` : 'auto', media.tablet`
    ${props => `max-width: ${props.width ? props.width : '750px'}`}
  `);
export const ModalContent = styled.div.withConfig({
  displayName: "Modalstyle__ModalContent",
  componentId: "sc-1lxdwb3-3"
})(["position:relative;flex:1;"]);
export const Icon = styled.img.withConfig({
  displayName: "Modalstyle__Icon",
  componentId: "sc-1lxdwb3-4"
})(["cursor:pointer;"]);