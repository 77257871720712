import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var __jsx = React.createElement;
import { Paragraph } from '@yieldstreet/ui-kit';
import React from 'react';
import InvestmentVote from '../InvestmentVote/InvestmentVote';
import { VOTE_VALUES } from '../InvestmentVote/InvestmentVote.model';
import { SLink } from '../InvestmentVote/InvestmentVote.style';
import { SBox, ContentWrapper, Wrapper } from './InvestmentVoteList.style';
const InvestmentVoteList = _ref => {
  let {
    formikProps,
    votes
  } = _ref;
  const handleVote = (field, value) => {
    var _formikProps$values;
    const foundValue = (_formikProps$values = formikProps.values) === null || _formikProps$values === void 0 ? void 0 : _formikProps$values[field];
    if (foundValue) {
      formikProps.setFieldValue(field, undefined);
    } else {
      formikProps.setFieldValue(field, value);
    }
  };
  const handleAcceptAll = () => {
    const dynamicObject = (votes || []).reduce((acc, _ref2) => {
      let {
        campaignId,
        noteUrlHash
      } = _ref2;
      return _objectSpread(_objectSpread({}, acc), {}, {
        [campaignId]: {
          vote: VOTE_VALUES.accept,
          noteUrlHash
        }
      });
    }, {});
    formikProps.setValues(dynamicObject);
  };
  return __jsx(SBox, null, __jsx(Wrapper, null, __jsx(Paragraph, null, "Investments"), __jsx(SLink, {
    onClick: handleAcceptAll
  }, "Accept all")), __jsx(ContentWrapper, null, votes === null || votes === void 0 ? void 0 : votes.map(vote => __jsx(InvestmentVote, {
    key: vote.campaignId,
    formikProps: formikProps,
    name: vote.campaignId,
    url: vote.description,
    onChange: (campaignId, value) => handleVote(campaignId, {
      vote: value,
      noteUrlHash: vote.noteUrlHash
    }),
    label: vote.noteTitle
  }))));
};
export default InvestmentVoteList;