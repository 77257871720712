import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _defineProperty from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
const _excluded = ["bank"];
var __jsx = React.createElement;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { BankAccountExternalPermission, InvestorAccountActionCode, bankAccountApi } from '@yieldstreet/platform-kit';
import { media, Modal, Label, Heading, Paragraph, Button, Notification, FormTextInput, PageLoader } from '@yieldstreet/ui-kit';
import { createYupObjectSchema } from '@yieldstreet/tool-kit';
import { Form, Formik } from 'formik';
import isEmpty from "lodash/isEmpty";
import styled from 'styled-components';
import * as Yup from 'yup';
import { getUserBankInfo } from 'store/actions/bank';
import { testIds } from './DepositVerificationModal.model';
import { withPreInvestmentConfig } from 'utils/investmentEligibility';
export class DepositVerificationModal extends Component {
  constructor() {
    super(...arguments);
    _defineProperty(this, "state", {
      error: false,
      errorMsg: 'Error',
      isLoading: false,
      bankAccount: null,
      isSuccess: false
    });
    _defineProperty(this, "submit", _ref => {
      let {
        firstAmount,
        secondAmount,
        bankAccount
      } = _ref;
      const {
        verifyMicroDeposits
      } = this.props;
      const {
        holderId,
        id
      } = bankAccount;
      if (this.isLockedOrInactiveAccount()) {
        this.setState({
          error: true,
          errorMsg: `Account ${bankAccount.nickname} locked. Contact investments@yieldstreet.com`
        });
      } else if (firstAmount && secondAmount) {
        const data = {
          amounts: [`.${firstAmount}`, `.${secondAmount}`],
          holderId,
          bankAccountId: id
        };
        this.setState({
          loading: true
        });
        verifyMicroDeposits(data).then(res => {
          const {
            data
          } = res;
          this.setState({
            isSuccess: data.success
          });
          this.setState({
            loading: false
          });
          if (data.message && !data.success) {
            this.setState({
              error: true
            });
            this.setState({
              errorMsg: data.message
            });
          }
        });
      } else {
        this.setState({
          error: true,
          errorMsg: 'You need to fill all the fields to continue.'
        });
      }
    });
    _defineProperty(this, "resetErrors", () => {
      this.setState({
        error: false
      });
    });
    _defineProperty(this, "isLockedOrInactiveAccount", bankAccount => bankAccount && (bankAccount.externalPermission === BankAccountExternalPermission.LOCKED || bankAccount.externalPermission === BankAccountExternalPermission.INACTIVE));
  }
  componentDidMount() {
    const {
      getBankAccounts,
      userId,
      getUserBankInfo,
      userBankInfo
    } = this.props;
    getBankAccounts({
      userId
    });
    userId && isEmpty(userBankInfo) && getUserBankInfo(userId);
  }
  componentDidUpdate() {
    const {
      hideModal,
      redirectOnComplete = true,
      onDepositVerified
    } = this.props;
    const {
      isLoading,
      isSuccess
    } = this.state;
    if (!isLoading) {
      if (isSuccess) {
        return this.props.handlePreInvestRedirect(() => {
          onDepositVerified && onDepositVerified();
          hideModal();
          if (redirectOnComplete) this.props.history.replace(`/management/bank-accounts/success`);
        });
      }
    }
  }
  render() {
    var _getBankAccountsData$;
    const {
      error,
      errorMsg,
      isLoading
    } = this.state;
    const {
      hideModal,
      getBankAccountsData,
      bankAccountId
    } = this.props;
    const bankAccount = (getBankAccountsData === null || getBankAccountsData === void 0 ? void 0 : (_getBankAccountsData$ = getBankAccountsData.bankAccounts) === null || _getBankAccountsData$ === void 0 ? void 0 : _getBankAccountsData$.find(account => account.id === bankAccountId)) || [];
    return __jsx(React.Fragment, null, __jsx(DespositVerificationContainer, {
      height: "auto",
      clickClose: hideModal,
      className: "deposit-verification"
    }, !this.isLockedOrInactiveAccount(bankAccount) && __jsx(Wrapper, null, !isEmpty(bankAccount) ? __jsx(React.Fragment, null, (error || this.isLockedOrInactiveAccount()) && !isLoading ? __jsx(Notification, {
      "data-cy": testIds.errorNotification.id,
      severity: "error",
      trackingClass: "deposit-verification-error",
      description: this.isLockedOrInactiveAccount() ? `Account ${bankAccount.nickname} ${bankAccount.externalPermission.toLowerCase()}. Contact investments@yieldstreet.com` : errorMsg,
      inline: true
    }) : null, __jsx(DepositVerificationTitle, _extends({
      type: 3
    }, testIds.title.attr), "Complete bank verification"), __jsx(AccountInfo, null, __jsx(AccountInfoItem, null, __jsx(AccountInfoItemLabel, null, "Bank"), __jsx(AccountInfoItemValue, testIds.bankName.attr, bankAccount.bankName)), __jsx(AccountInfoItem, null, __jsx(AccountInfoItemLabel, null, "Account"), __jsx(AccountInfoItemValue, testIds.accountNumber.attr, "Ending in", ' ', bankAccount.maskedAccountNumber.substr(bankAccount.maskedAccountNumber.length - 4)))), __jsx(DepositDescription, null, "Please enter the amounts for each micro-deposit you received."), __jsx(Formik, {
      initialValues: {
        firstAmount: '',
        secondAmount: ''
      },
      onSubmit: values => this.submit(_objectSpread({
        bankAccount
      }, values)),
      validationSchema: createYupObjectSchema({
        firstAmount: Yup.number().required('This field is required').integer('This field accepts numbers only').typeError('This field accepts numbers only'),
        secondAmount: Yup.number().required('This field is required').integer('This field accepts numbers only').typeError('This field accepts numbers only')
      })
    }, formikProps => {
      const {
        values
      } = formikProps;
      return __jsx(Form, null, __jsx(DepositVerificationForm, null, __jsx(DepositVerificationFormItem, null, __jsx(FormTextInput, {
        id: "firstAmount",
        name: "firstAmount",
        label: "Deposit Amount #1",
        type: "tel",
        maxLength: "2",
        minLength: "2",
        decimalScale: 0,
        prefix: "$0.",
        formikProps: formikProps,
        format: "##",
        allowNegative: false,
        testId: testIds.depositAmount1.id,
        className: "firstAmount",
        autoComplete: "off"
      })), __jsx(DepositVerificationFormItem, null, __jsx(FormTextInput, {
        id: "secondAmount",
        name: "secondAmount",
        label: "Deposit Amount #2",
        type: "tel",
        maxLength: "2",
        minLength: "2",
        decimalScale: 0,
        prefix: "$0.",
        formikProps: formikProps,
        format: "##",
        allowNegative: false,
        testId: testIds.depositAmount2.id,
        className: "secondAmount",
        autoComplete: "off",
        onKeyPress: e => {
          if (e.key === 'Enter') {
            this.submit(_objectSpread({
              bankAccount
            }, values));
            e.stopPropagation();
          }
          return null;
        },
        onChange: this.resetErrors
      }))), __jsx(DepositVerificationButtons, null, __jsx(StyledButton, _extends({
        mobileLimit: true,
        onClick: hideModal,
        buttonType: "secondary"
      }, testIds.cancelButton.attr), "Cancel"), __jsx(StyledButton, _extends({
        mobileLimit: true,
        className: "deposit-verification-button",
        onSubmit: values => this.submit(_objectSpread({
          bankAccount
        }, values)),
        loading: isLoading,
        disabled: this.isLockedOrInactiveAccount() || isLoading
      }, testIds.submitButton.attr), "Submit")));
    })) : __jsx(PageLoader, {
      testId: testIds.pageLoader.id
    }))));
  }
}
const Wrapper = styled.div.withConfig({
  displayName: "DepositVerificationModal__Wrapper",
  componentId: "sc-163pkz8-0"
})(["padding:5px 40px 20px 20px;"]);
const AccountInfo = styled.div.withConfig({
  displayName: "DepositVerificationModal__AccountInfo",
  componentId: "sc-163pkz8-1"
})(["display:flex;flex-direction:row;gap:15%;"]);
const AccountInfoItem = styled.div.withConfig({
  displayName: "DepositVerificationModal__AccountInfoItem",
  componentId: "sc-163pkz8-2"
})([""]);
const AccountInfoItemLabel = styled(Label).withConfig({
  displayName: "DepositVerificationModal__AccountInfoItemLabel",
  componentId: "sc-163pkz8-3"
})(["padding-bottom:5px;"]);
const AccountInfoItemValue = styled(Paragraph).withConfig({
  displayName: "DepositVerificationModal__AccountInfoItemValue",
  componentId: "sc-163pkz8-4"
})([""]);
const DepositDescription = styled(Paragraph).withConfig({
  displayName: "DepositVerificationModal__DepositDescription",
  componentId: "sc-163pkz8-5"
})(["padding:40px 0 20px 0;"]);
const DespositVerificationContainer = styled(Modal).withConfig({
  displayName: "DepositVerificationModal__DespositVerificationContainer",
  componentId: "sc-163pkz8-6"
})(["background:rgba(0,0,0,0.8);"]);
const DepositVerificationTitle = styled(Heading).withConfig({
  displayName: "DepositVerificationModal__DepositVerificationTitle",
  componentId: "sc-163pkz8-7"
})(["padding-bottom:10px;"]);
const DepositVerificationForm = styled.div.withConfig({
  displayName: "DepositVerificationModal__DepositVerificationForm",
  componentId: "sc-163pkz8-8"
})(["", ";"], media.tablet`
    display: flex;
  `);
const DepositVerificationFormItem = styled.div.withConfig({
  displayName: "DepositVerificationModal__DepositVerificationFormItem",
  componentId: "sc-163pkz8-9"
})(["& input{width:137px;}margin-right:20px;"]);
const DepositVerificationButtons = styled.div.withConfig({
  displayName: "DepositVerificationModal__DepositVerificationButtons",
  componentId: "sc-163pkz8-10"
})(["margin-top:20px;display:flex;flex-direction:column;gap:15px;", ""], media.desktop`
    margin-top: 40px;
    display: flex;
    flex-direction: row;
  `);
const StyledButton = styled(Button).withConfig({
  displayName: "DepositVerificationModal__StyledButton",
  componentId: "sc-163pkz8-11"
})(["", ";"], media.tablet`
    max-width: 205px;
  `);
const mapStateToProps = _ref2 => {
  var _bankAccountApi$endpo;
  let {
      bank
    } = _ref2,
    state = _objectWithoutProperties(_ref2, _excluded);
  return {
    getBankAccountsData: ((_bankAccountApi$endpo = bankAccountApi.endpoints.getBankAccounts.select()(state)) === null || _bankAccountApi$endpo === void 0 ? void 0 : _bankAccountApi$endpo.data) || {},
    userBankInfo: bank.userBankInfo
  };
};
const mapDispatchToProps = {
  getBankAccounts: bankAccountApi.endpoints.getBankAccounts.initiate,
  getUserBankInfo: getUserBankInfo,
  verifyMicroDeposits: bankAccountApi.endpoints.verifyMicroDeposits.initiate
};
export default connect(mapStateToProps, mapDispatchToProps)(withPreInvestmentConfig(DepositVerificationModal, InvestorAccountActionCode.VERIFY_MICRO_DEPOSITS));