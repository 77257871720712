import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { restApi, Tags } from '../common';
import { HttpMethods } from '../../enums/http';
import { SocketManagerReconnectionStrategy, websocketManager } from '../../websocket';
import { selectConfig } from '../config';
import { COMMUNICATION_BASE_URL, FetchMessagesUpdateStrategy } from './communication.model';
import { updateMessage, updateMessages, updateMessageArgs, updateCache, getQueryParams, getCacheParams, constructTagId, onSocketMessage, onSocketOpen } from './communication.util';
export const communicationApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.Communication]
}).injectEndpoints({
  endpoints: builder => {
    const getMessages = builder.query({
      queryFn: () => ({
        data: []
      }),
      providesTags: [Tags.Communication],
      async onCacheEntryAdded(params, _ref) {
        let {
          cacheDataLoaded,
          cacheEntryRemoved,
          getState,
          dispatch
        } = _ref;
        if (params !== null && params !== void 0 && params.authToken && (params === null || params === void 0 ? void 0 : params.updates) !== FetchMessagesUpdateStrategy.INITIAL_ONLY) {
          const state = getState();
          const {
            wssHost
          } = selectConfig(state);
          const webSocketEvents = {
            onOpen: () => onSocketOpen(Tags.Communication, params.authToken),
            onMessage: (_, ev) => onSocketMessage(ev, dispatch, params)
          };
          try {
            await cacheDataLoaded;
            websocketManager.add(Tags.Communication, {
              url: `${wssHost}/api/communication/ws/messages`,
              reconnectionStrategy: SocketManagerReconnectionStrategy.retry
            }, webSocketEvents);
            await cacheEntryRemoved;
          } finally {
            websocketManager.unsubscribe(Tags.Communication, webSocketEvents);
          }
        }
      }
    });
    const fetchMessages = builder.query({
      query: params => {
        return params.updates !== FetchMessagesUpdateStrategy.LIVE_ONLY ? {
          url: `${COMMUNICATION_BASE_URL}`,
          method: HttpMethods.Get,
          params: getQueryParams(params)
        } : {};
      },
      providesTags: (result, _error, args) => result ? [{
        type: Tags.Communication,
        id: constructTagId(args)
      }, Tags.Communication] : [Tags.Communication],
      transformResponse: (response, _meta, args) => _objectSpread(_objectSpread({}, response), {}, {
        items: updateMessageArgs(response === null || response === void 0 ? void 0 : response.items, args)
      }),
      async onQueryStarted(params, _ref2) {
        let {
          dispatch,
          queryFulfilled
        } = _ref2;
        try {
          const {
            data
          } = await queryFulfilled;
          dispatch(communicationApi.util.updateQueryData('getMessages', getCacheParams(params), draft => params.page && params.page > 1 ? updateCache(draft, (data === null || data === void 0 ? void 0 : data.items) || []) : [...((data === null || data === void 0 ? void 0 : data.items) || [])]));
        } catch {}
      }
    });
    const readMessage = builder.mutation({
      query: _ref3 => {
        let {
          messageSpecId,
          read
        } = _ref3;
        return {
          url: `${COMMUNICATION_BASE_URL}/${messageSpecId}/read`,
          method: HttpMethods.Post,
          data: {
            read
          }
        };
      },
      async onQueryStarted(_ref4, _ref5) {
        let {
          messageSpecId,
          messageArgs,
          read
        } = _ref4;
        let {
          dispatch
        } = _ref5;
        dispatch(communicationApi.util.updateQueryData('getMessages', getCacheParams(messageArgs), draft => updateMessage(draft, messageSpecId, {
          seen: read
        })));
      }
    });
    const readMessages = builder.mutation({
      query: _ref6 => {
        let {
          messageSpecIds,
          read
        } = _ref6;
        return {
          url: `${COMMUNICATION_BASE_URL}/read`,
          method: HttpMethods.Post,
          data: {
            read,
            messageSpecIds
          }
        };
      },
      async onQueryStarted(_ref7, _ref8) {
        let {
          messageSpecIds,
          messageArgs,
          read
        } = _ref7;
        let {
          dispatch
        } = _ref8;
        dispatch(communicationApi.util.updateQueryData('getMessages', getCacheParams(messageArgs), draft => updateMessages(draft, messageSpecIds, {
          seen: read
        })));
      }
    });
    const dismissMessage = builder.mutation({
      query: _ref9 => {
        let {
          messageSpecId,
          dismissed
        } = _ref9;
        return {
          url: `${COMMUNICATION_BASE_URL}/${messageSpecId}/dismiss`,
          method: HttpMethods.Post,
          data: {
            dismiss: dismissed
          }
        };
      },
      async onQueryStarted(_ref10, _ref11) {
        let {
          messageSpecId,
          messageArgs,
          dismissed
        } = _ref10;
        let {
          dispatch
        } = _ref11;
        dispatch(communicationApi.util.updateQueryData('getMessages', getCacheParams(messageArgs), draft => updateMessage(draft, messageSpecId, {
          dismissed
        })));
      }
    });
    const dismissMessages = builder.mutation({
      query: _ref12 => {
        let {
          messageSpecIds,
          dismissed
        } = _ref12;
        return {
          url: `${COMMUNICATION_BASE_URL}/dismiss`,
          method: HttpMethods.Post,
          data: {
            dismiss: dismissed,
            messageSpecIds
          }
        };
      },
      async onQueryStarted(_ref13, _ref14) {
        let {
          messageSpecIds,
          messageArgs,
          dismissed
        } = _ref13;
        let {
          dispatch
        } = _ref14;
        dispatch(communicationApi.util.updateQueryData('getMessages', getCacheParams(messageArgs), draft => updateMessages(draft, messageSpecIds, {
          dismissed
        })));
      }
    });
    return {
      getMessages,
      fetchMessages,
      readMessage,
      readMessages,
      dismissMessage,
      dismissMessages
    };
  }
});