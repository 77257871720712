import { HttpMethods } from '../../../enums/http';
import { restApi, Tags } from '../../common';
export const tiersApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.PortfolioTiers, Tags.PortfolioTiersOptIn]
}).injectEndpoints({
  endpoints: builder => {
    const tierOptIn = builder.mutation({
      query: _ref => {
        let {
          userId,
          tierLevel
        } = _ref;
        return {
          url: `/api/portfolio/${userId}/tiers/${tierLevel}/optIn`,
          method: HttpMethods.Post
        };
      },
      invalidatesTags: [Tags.PortfolioTiers, Tags.PortfolioTiersOptIn]
    });
    return {
      tierOptIn
    };
  }
});