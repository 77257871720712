import { useMemo } from 'react';
import Big from 'big.js';
export const getCapitalCallsCalculation = _ref => {
  let {
    committedAmount,
    capitalDrawnPercentage
  } = _ref;
  if (!committedAmount || !capitalDrawnPercentage) {
    return;
  }
  const cAmount = new Big(committedAmount);
  const pFundedNow = new Big(capitalDrawnPercentage).div(100);
  const pFundedLater = new Big(100).minus(capitalDrawnPercentage).toNumber();
  const value = Math.trunc(+cAmount.times(pFundedNow).times(100)) / 100;
  return {
    fundedNow: +value,
    fundedLater: +cAmount.minus(value),
    percentFundedLater: pFundedLater
  };
};
export const useCapitalCallsCalculation = (committedAmount, capitalDrawnPercentage) => {
  return useMemo(() => getCapitalCallsCalculation({
    committedAmount,
    capitalDrawnPercentage
  }), [committedAmount, capitalDrawnPercentage]);
};