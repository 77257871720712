import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["data"];
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { bankAccountApi, BankAccountType, PortfolioStrategyValues } from '@yieldstreet/platform-kit';
const {
  useGetEntityBankAccountsQuery
} = bankAccountApi;
export const useInvestorEntityWallet = investorEntityId => {
  const {
    data: walletData,
    isLoading: isLoadingWallets
  } = useGetEntityBankAccountsQuery(investorEntityId ? {
    investorEntityId
  } : skipToken, {
    selectFromResult: _ref => {
      var _data$bankAccounts;
      let {
          data
        } = _ref,
        rest = _objectWithoutProperties(_ref, _excluded);
      return _objectSpread(_objectSpread({}, rest), {}, {
        data: data === null || data === void 0 ? void 0 : (_data$bankAccounts = data.bankAccounts) === null || _data$bankAccounts === void 0 ? void 0 : _data$bankAccounts.filter(act => act.accountType === BankAccountType.YWLT)
      });
    }
  });
  const selfDirectedWallet = walletData === null || walletData === void 0 ? void 0 : walletData.find(act => act.investorAccountManagementStrategy === PortfolioStrategyValues.Self);
  const managedWallet = walletData === null || walletData === void 0 ? void 0 : walletData.find(act => act.investorAccountManagementStrategy === PortfolioStrategyValues.Discretionary);
  return {
    selfDirectedWallet,
    managedWallet,
    isLoadingWallets
  };
};