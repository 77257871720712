import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var __jsx = React.createElement;
import React, { useMemo, useState, useCallback } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import isEmpty from "lodash/isEmpty";
import pickBy from "lodash/pickBy";
import identity from "lodash/identity";
import moment from 'moment';
import { PageLoader, FormTextInput, Button, FormSelectInput, PageStep, PageStepContentTemplate, PageContainer } from '@yieldstreet/ui-kit';
import { investorEntityApi, userApi, iraApi, InvestorType, PortfolioStrategyValues, investorEntityStatusApi, InvestorEntityStepProvider, ManagementStrategy } from '@yieldstreet/platform-kit';
import { useFormFlow, useApiErrorMessage, useApiEventTracking, BusinessEvent, useFlags } from '@yieldstreet/tool-kit';
import { useFlowLocation } from 'hooks/formFlow/useFlowLocation';
import { getInvestorLabel } from '../../CreateInvestorAccount.utils';
import { useOnUrlChange, NAME_STEP, NEW_ACCOUNT_STEP } from '../../CreateInvestorAccount.model';
import { FormWrapper, SubmitWrapper, ButtonContainer } from '../../CreateInvestorAccount.style';
import { FlowWrapper } from '../FlowWrapper';
import { formNames, entityTypeList, testIds } from './Name.model';
import { InputSecondaryTextWrapper } from './Name.style';
import { buildPostData, getFormNameConfig, getPageStepContent } from './Name.utils.js';
import { IraType } from './iraFlow/IraType';
import { IraSourceAccount } from './iraFlow/IraSourceAccount';
import { useUserState } from 'utils/hooks';
import { INVESTOR_ENTITY_KYB_PATH, INVESTOR_ENTITY_KYC_PATH } from 'pages/investorEntity';
import { getInvestorEntityRoute } from 'pages/investorEntity/utils';
export const Name = _ref => {
  var _investorEntitiesData, _submitResult$data;
  let {
    flowConfig,
    managementStrategy
  } = _ref;
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const {
    search
  } = location;
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);
  const {
    id
  } = useUserState();
  const {
    genieTrustSsn: allowGenieTrustSsn,
    genieTrustEin: allowGenieTrustEin,
    footprintKybFlow: allowGenieBusinessEntity
  } = useFlags({
    genieTrustSsn: false,
    genieTrustEin: false,
    footprintKybFlow: false
  });
  const {
    accountType,
    investorEntityId
  } = params;
  const isIraAccountType = accountType === InvestorType.IRA;
  const isIndividual = accountType === InvestorType.INDIVIDUAL;
  const allowTrustWithSsn = accountType === 'trust-with-ssn' && allowGenieTrustSsn;
  const allowTrustWithEin = accountType === 'trust-with-ein' && allowGenieTrustEin;
  const allowBusinessEntity = accountType === InvestorType.ENTITY && allowGenieBusinessEntity;
  const {
    0: showForm,
    1: setShowForm
  } = useState(ManagementStrategy.Discretionary !== managementStrategy);
  const {
    0: isIraFlow,
    1: setIsIraFlow
  } = useState(false);
  const {
    0: iraType,
    1: setIraType
  } = useState('');
  const {
    firstNameLabel,
    firstNamePlaceholder,
    lastNameLabel,
    lastNamePlaceholder
  } = getInvestorLabel(accountType);
  const {
    useGetInvestorEntityQuery,
    useCreateInvestorEntityMutation,
    useUpdateInvestorEntityMutation
  } = investorEntityApi;
  const {
    useGetAllInvestorEntityStatusQuery
  } = investorEntityStatusApi;
  const {
    useGetUserIraSourceAccountQuery,
    useGetUserContactInfoQuery
  } = userApi;
  const {
    useCreateIraAccountMutation,
    useCopyIraAccountMutation
  } = iraApi;
  const [createIraAccount, createIraAccountResult] = useCreateIraAccountMutation();
  const [copyIraAccount, copyIraAccountResult] = useCopyIraAccountMutation();
  const [createInvestorEntity, createInvestorEntityResult] = useCreateInvestorEntityMutation();
  const [updateInvestorEntity, updateInvestorEntityResult] = useUpdateInvestorEntityMutation();
  const {
    currentData: userContactData,
    isLoading: isLoadingUserContact
  } = useGetUserContactInfoQuery();
  const {
    currentData: userIraSourceAccount,
    isLoading: isLoadingUserIraSourceAccount
  } = useGetUserIraSourceAccountQuery({
    userId: id
  });
  const {
    currentData: investorEntitiesData,
    isLoading: isLoadingInvestorAccounts
  } = useGetInvestorEntityQuery();
  const {
    currentData: investorEntityStatusData
  } = useGetAllInvestorEntityStatusQuery();
  const contact = userContactData === null || userContactData === void 0 ? void 0 : userContactData.contact;
  const syncContact = ![InvestorType.TRUST_SSN, InvestorType.TRUST_EIN, InvestorType.ENTITY].includes(accountType);
  const hasAccount = (investorEntitiesData === null || investorEntitiesData === void 0 ? void 0 : (_investorEntitiesData = investorEntitiesData.entities) === null || _investorEntitiesData === void 0 ? void 0 : _investorEntitiesData.length) > 0;
  const hasInvestorId = investorEntityId !== NEW_ACCOUNT_STEP;
  const updateAccount = hasAccount && (investorEntitiesData === null || investorEntitiesData === void 0 ? void 0 : investorEntitiesData.entities.find(acc => Number(acc === null || acc === void 0 ? void 0 : acc.id) === Number(investorEntityId)));
  const initialValues = useMemo(() => {
    const init = {
      name: syncContact ? `${contact === null || contact === void 0 ? void 0 : contact.firstName} ${contact === null || contact === void 0 ? void 0 : contact.lastName}` : '',
      firstName: syncContact ? contact === null || contact === void 0 ? void 0 : contact.firstName : '',
      lastName: syncContact ? contact === null || contact === void 0 ? void 0 : contact.lastName : '',
      lastNameOrSign: ''
    };
    if (updateAccount) {
      const name = updateAccount === null || updateAccount === void 0 ? void 0 : updateAccount.name;
      const firstName = updateAccount === null || updateAccount === void 0 ? void 0 : updateAccount.firstName;
      const lastName = syncContact ? updateAccount === null || updateAccount === void 0 ? void 0 : updateAccount.lastName : '';
      return Object.assign(init, {
        name: name || '',
        firstName: firstName || '',
        lastName: lastName || '',
        lastNameOrSign: updateAccount.lastNameOrSign || '',
        addressLine1: updateAccount.addressLine1 || '',
        addressLine2: updateAccount.addressLine2 || '',
        city: updateAccount.city || '',
        stateStr: updateAccount.state || '',
        postalCode: updateAccount.postalCode || '',
        dateOfRecord: updateAccount.dateOfRecord ? moment(updateAccount.dateOfRecord, 'YYYY-MM-DD').format('MM/DD/YYYY') : ''
      });
    }
    return pickBy(init, identity);
  }, [syncContact, contact, updateAccount]);
  const submitCallback = useCallback((values, actions) => {
    if (isEmpty(values)) {
      return;
    }
    Object.keys(values).forEach(fieldName => !(values !== null && values !== void 0 && values[fieldName]) && (actions === null || actions === void 0 ? void 0 : actions.setFieldTouched(fieldName, false)));
    const data = buildPostData({
      investorType: accountType,
      values,
      iraType,
      contact,
      retirementAccount: Boolean(queryParams.get('retirementAccount'))
    });
    updateAccount ? updateInvestorEntity(_objectSpread(_objectSpread({
      investorEntityId
    }, data), {}, {
      id: investorEntityId
    })) : createInvestorEntity(_objectSpread(_objectSpread({}, data), {}, {
      managementStrategies: managementStrategy === PortfolioStrategyValues.Discretionary ? [PortfolioStrategyValues.Self, PortfolioStrategyValues.Discretionary] : [PortfolioStrategyValues.Self]
    }));
  }, [accountType, contact, createInvestorEntity, investorEntityId, iraType, queryParams, updateAccount, updateInvestorEntity, managementStrategy]);
  const {
    isLoading: isCreateSubmitting,
    isSuccess: isCreateSuccess
  } = createInvestorEntityResult;
  const {
    isLoading: isUpdateSubmitting,
    isSuccess: isUpdateSuccess
  } = updateInvestorEntityResult;
  const isSubmitting = isCreateSubmitting || isUpdateSubmitting;
  const isSuccess = isCreateSuccess || isUpdateSuccess;
  const isLoadingInfo = isLoadingUserContact || isLoadingInvestorAccounts || isLoadingUserIraSourceAccount;
  const editIraForm = () => setIsIraFlow(false);
  const handleCreateIra = iraType => {
    setIsIraFlow(true);
    setIraType(iraType);
  };
  const renderFields = formikProps => {
    if (isIraAccountType && !iraType && !updateAccount) {
      return __jsx(IraType, {
        handleCreateIra: handleCreateIra,
        error: false
      });
    } else {
      const isEntityType = accountType === InvestorType.ENTITY;
      const individualTypes = [InvestorType.INDIVIDUAL, InvestorType.IRA];
      const isIndividual = individualTypes.includes(accountType);
      return __jsx(InputSecondaryTextWrapper, null, isIndividual ? __jsx(React.Fragment, null, __jsx(FormTextInput, {
        type: "text",
        name: formNames.firstName,
        label: firstNameLabel,
        placeholder: firstNamePlaceholder,
        autoComplete: "given-name",
        formikProps: formikProps,
        testId: testIds.firstNameField.id,
        autoFocus: true
      }), __jsx(FormTextInput, {
        type: "text",
        name: formNames.lastName,
        label: lastNameLabel,
        placeholder: lastNamePlaceholder,
        autoComplete: "family-name",
        formikProps: formikProps,
        testId: testIds.lastNameField.id
      })) : __jsx(React.Fragment, null, __jsx(FormTextInput, {
        type: "text",
        name: formNames.firstName,
        label: firstNameLabel,
        placeholder: firstNamePlaceholder,
        autoComplete: "given-name",
        formikProps: formikProps,
        testId: testIds.firstNameField.id,
        autoFocus: true
      }), isEntityType ? __jsx(FormSelectInput, {
        name: "entityType",
        label: "Entity type",
        placeholder: "Select entity type",
        formikProps: formikProps,
        options: entityTypeList,
        testId: testIds.entityNameField.id,
        fullWidth: true
      }) : null));
    }
  };
  const renderForm = formikProps => {
    return __jsx(FormWrapper, testIds.form.attr, renderFields(formikProps), __jsx(SubmitWrapper, null, __jsx(ButtonContainer, null, (!isIraAccountType || iraType || hasInvestorId) && __jsx(Button, _extends({
      type: "submit",
      loading: isSubmitting || isSuccess,
      disabled: isLoadingInfo || isSubmitting || isSuccess,
      className: testIds.submitButton.id
    }, testIds.submitButton.attr), "Continue"))));
  };
  const flowState = useFlowLocation({
    useOnUrlChange: () => {
      var _createInvestorEntity;
      return useOnUrlChange(createInvestorEntityResult === null || createInvestorEntityResult === void 0 ? void 0 : (_createInvestorEntity = createInvestorEntityResult.data) === null || _createInvestorEntity === void 0 ? void 0 : _createInvestorEntity.investorEntity.id);
    }
  });
  const handleInnerStep = () => {
    if (isIraAccountType && updateAccount) {
      return null;
    } else if (isIraAccountType && !iraType) {
      return 'ira';
    } else {
      return flowState.step;
    }
  };
  const submitResult = updateAccount ? updateInvestorEntityResult : createInvestorEntityResult;
  const submitInvestorEntityId = `${submitResult === null || submitResult === void 0 ? void 0 : (_submitResult$data = submitResult.data) === null || _submitResult$data === void 0 ? void 0 : _submitResult$data.investorEntity.id}`;
  const formNameConfig = useMemo(() => getFormNameConfig(initialValues, accountType), [accountType, initialValues]);
  const onSubmitSuccess = useCallback(_ref2 => {
    let {
      goToNextStep
    } = _ref2;
    const investorEntityStatus = investorEntityStatusData && investorEntityStatusData[submitInvestorEntityId];
    const identityProvider = investorEntityStatus === null || investorEntityStatus === void 0 ? void 0 : investorEntityStatus.identity.provider;
    if (investorEntityStatus) {
      if (!identityProvider || identityProvider === InvestorEntityStepProvider.YS) {
        goToNextStep();
      } else {
        if (isIndividual) {
          const r = getInvestorEntityRoute(submitInvestorEntityId, INVESTOR_ENTITY_KYC_PATH);
          history.push(`${r}?managementStrategy=${managementStrategy}`);
        } else if (allowTrustWithSsn || allowTrustWithEin || allowBusinessEntity) {
          history.push(getInvestorEntityRoute(submitInvestorEntityId, INVESTOR_ENTITY_KYB_PATH));
        } else {
          goToNextStep();
        }
      }
    }
  }, [investorEntityStatusData, submitInvestorEntityId, isIndividual, allowTrustWithSsn, allowTrustWithEin, allowBusinessEntity, history, managementStrategy]);
  const {
    Form
  } = useFormFlow({
    formConfig: formNameConfig,
    flowConfig,
    flowState,
    submitConfig: submitResult,
    submitCallback,
    onSubmitSuccess
  });
  const createInvestorErrorMessage = useApiErrorMessage(createInvestorEntityResult);
  const updateInvestorErrorMessage = useApiErrorMessage(updateInvestorEntityResult);
  const createIraAccountErrorMessage = useApiErrorMessage(createIraAccountResult);
  const copyIraAccountErrorMessage = useApiErrorMessage(copyIraAccountResult);
  useApiEventTracking(createInvestorEntityResult, BusinessEvent.INVESTOR_CREATION_SUBMITTED, {
    step: NAME_STEP
  }, updateInvestorErrorMessage);
  useApiEventTracking(updateInvestorEntityResult, BusinessEvent.INVESTOR_CREATION_SUBMITTED, {
    step: NAME_STEP
  }, updateInvestorErrorMessage);
  if (!showForm) {
    return __jsx(PageContainer, null, __jsx(PageStep, null, __jsx(PageStepContentTemplate, getPageStepContent({
      onClick: () => setShowForm(true)
    }))));
  }
  return isLoadingInfo ? __jsx(PageContainer, null, __jsx(PageLoader, null)) : __jsx(FlowWrapper, {
    flowConfig: flowConfig,
    step: handleInnerStep(),
    investorAccountType: accountType,
    goToPreviousStep: () => history.push('/management/accounts'),
    error: createInvestorErrorMessage || updateInvestorErrorMessage || createIraAccountErrorMessage || copyIraAccountErrorMessage
  }, isIraFlow && userIraSourceAccount.accounts.length && !updateAccount ? __jsx(IraSourceAccount, {
    userIraSourceAccounts: userIraSourceAccount.accounts,
    iraType: iraType,
    editIraForm: editIraForm,
    createIraAccount: createIraAccount,
    copyIraAccount: copyIraAccount,
    isCreatingIraAccount: createIraAccountResult.isLoading,
    isCopyingIraAccount: copyIraAccountResult.isLoading
  }) : __jsx(Form, null, formikProps => renderForm(formikProps)));
};