import { useMemo } from 'react';
import { rules } from './useInvestmentChips.utils';
export const getInvestmentChips = _ref => {
  let {
    minimumInvestment,
    maximumInvestment,
    investorType
  } = _ref;
  const matchingRule = rules.find(rule => rule.rule(investorType, minimumInvestment, maximumInvestment));
  return (matchingRule === null || matchingRule === void 0 ? void 0 : matchingRule.template) || [];
};
export const useInvestmentChips = _ref2 => {
  let {
    minimumInvestment,
    maximumInvestment,
    investorType
  } = _ref2;
  return useMemo(() => {
    return getInvestmentChips({
      minimumInvestment,
      maximumInvestment,
      investorType
    });
  }, [investorType, minimumInvestment, maximumInvestment]);
};