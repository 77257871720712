import { useCallback, useEffect, useRef } from 'react';
export const useWindowEventListener = (eventType, eventDataCallback) => {
  const registeredEventListener = useRef();
  const cleanupEventListener = useCallback(() => {
    if (registeredEventListener.current) {
      const {
        eventType: registeredType,
        eventCallback: registeredCallback
      } = registeredEventListener.current;
      window.removeEventListener(registeredType, registeredCallback);
    }
  }, []);
  useEffect(() => {
    var _window;
    cleanupEventListener();
    const eventCallback = e => eventDataCallback(e.data);
    (_window = window) === null || _window === void 0 ? void 0 : _window.addEventListener(eventType, eventCallback);
    registeredEventListener.current = {
      eventType,
      eventCallback
    };
  }, [eventType, eventDataCallback, cleanupEventListener]);
  useEffect(() => cleanupEventListener, [cleanupEventListener]);
};