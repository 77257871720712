var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { PageSection } from '../../layout/page-section';
import { media } from '../../themes/media';
export const LogoBanner = _ref => {
  let {
    logos,
    alignCenter = false,
    reducedMargin = false,
    miniMargin = false,
    flexWrap = true
  } = _ref;
  return __jsx(PageSection, null, __jsx(Container, {
    className: "logo-banner-container",
    alignCenter: alignCenter,
    reducedMargin: reducedMargin,
    miniMargin: miniMargin,
    flexWrap: flexWrap
  }, logos.map((logo, i) => logo.href ? __jsx(ImageWrapper, {
    reducedMargin: reducedMargin,
    miniMargin: miniMargin
  }, __jsx("a", {
    key: i,
    href: logo.href,
    rel: "noopener noreferrer",
    target: "_blank"
  }, __jsx("img", {
    alt: logo.alt,
    src: logo.image,
    width: logo.width,
    height: logo.height
  }))) : __jsx(ImageWrapper, {
    className: "logo-banner-image-wrapper",
    reducedMargin: reducedMargin,
    miniMargin: miniMargin
  }, __jsx("img", {
    key: i,
    alt: logo.alt,
    src: logo.image,
    width: logo.width,
    height: logo.height
  })))));
};
const Container = styled.div.withConfig({
  displayName: "LogoBanner__Container",
  componentId: "sc-ygrp2r-0"
})(["display:flex;margin:", ";flex-wrap:", ";justify-content:center;", ""], props => props.reducedMargin ? `0` : `100px 0 40px 0`, props => props.flexWrap ? 'wrap' : 'nowrap', props => props.alignCenter && `align-items: center;`);
const ImageWrapper = styled.div.withConfig({
  displayName: "LogoBanner__ImageWrapper",
  componentId: "sc-ygrp2r-1"
})(["min-width:20%;text-align:center;margin:10px;", ""], media.desktop`
    min-width: auto;
    margin: 0 ${props => props.reducedMargin ? `24px` : `50px`};
    margin: 0 ${props => props.miniMargin ? `16px` : `50px`};
  `);