var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { PageSection } from '../../layout/page-section';
import { Paragraph } from '../../content/text';
import { media } from '../../themes/media';
export const ArticleHeader = _ref => {
  let {
    title,
    subTitle,
    image,
    sponsored,
    children
  } = _ref;
  return __jsx(PageSection, null, __jsx(OuterContainer, null, sponsored, __jsx(ContentHolder, null, __jsx(BioHolder, null, __jsx(BioImage, null, image), __jsx(BioContent, null, __jsx(Paragraph, {
    small: true
  }, __jsx("strong", null, title)), __jsx(Paragraph, {
    small: true
  }, subTitle))), __jsx(Paragraph, null, children))));
};
const OuterContainer = styled.div.withConfig({
  displayName: "ArticleHeader__OuterContainer",
  componentId: "sc-115j1v6-0"
})(["position:relative;padding:0 20px 0 20px;", ";"], media.desktop`
    padding: 0 130px 0 130px;
  `);
const ContentHolder = styled.div.withConfig({
  displayName: "ArticleHeader__ContentHolder",
  componentId: "sc-115j1v6-1"
})(["&:only-child{padding:80px 0 0 0;}padding:60px 0 0 0;"]);
const BioHolder = styled.div.withConfig({
  displayName: "ArticleHeader__BioHolder",
  componentId: "sc-115j1v6-2"
})(["background:", ";width:calc(100% - 40px);display:flex;position:absolute;align-items:center;top:-50px;left:20px;", ""], props => props.theme.colors.background_inverse_secondary, media.tablet`
    left: auto;
    width: auto;
    padding-right: 20px;
  `);
const BioImage = styled.div.withConfig({
  displayName: "ArticleHeader__BioImage",
  componentId: "sc-115j1v6-3"
})(["margin:20px;padding:7px;background:", ";display:inherit;img{display:block;width:44px;}"], props => props.theme.colors.background_default);
const BioContent = styled.div.withConfig({
  displayName: "ArticleHeader__BioContent",
  componentId: "sc-115j1v6-4"
})(["flex-wrap:wrap;p{color:", ";}"], props => props.theme.colors.text_background_inverse);