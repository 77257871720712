import _Chip from "@mui/material/Chip";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React, { useState, useCallback } from 'react';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { TransactionsListDrawer } from 'pages/portfolio/Transactions/TransactionsList/TransactionsListDrawer';
import { TBody, Tr, Td } from 'pages/portfolio/common/Table';
import { toUsCurrency } from 'utils/currency-formatters';
import { PaymentStatusColor } from '../../TransactionsListData.model';
import { TransactionsListDesktopTableBodyLoading } from '../TransactionsListDesktopTableBodyLoading';
import { useTransactionDetails } from '../../../hooks';
import { handlePaymentStatusLabel } from '../../../TransactionsList.utils';
import { testIds } from './TransactionsListDesktopTableBody.model';
export const Transaction = _ref => {
  let {
    transaction
  } = _ref;
  const transactionDetails = useTransactionDetails({
    transaction
  });
  const {
    destName,
    fieldDate,
    isRollover,
    notes,
    showNotes,
    sourceName,
    status,
    totalAmount,
    transactionTypeDisplayName
  } = transactionDetails;
  return __jsx(React.Fragment, null, __jsx(Td, _extends({
    sx: {
      textWrapMode: 'nowrap'
    }
  }, testIds.date.attr), __jsx(_Typography, {
    variant: "body2"
  }, fieldDate)), __jsx(Td, testIds.sourceName.attr, __jsx(_Typography, {
    variant: "body2"
  }, sourceName)), __jsx(Td, testIds.destName.attr, __jsx(_Typography, {
    variant: "body2"
  }, destName)), __jsx(Td, _extends({
    sx: {
      textWrapMode: 'nowrap'
    }
  }, testIds.type.attr), __jsx(_Typography, {
    variant: "body2"
  }, showNotes && notes ? __jsx(DescriptionOutlinedIcon, {
    sx: {
      fontSize: 14,
      mr: 1
    }
  }) : null, isRollover ? 'Rollover' : transactionTypeDisplayName)), status ? __jsx(Td, testIds.status.attr, __jsx(_Chip, {
    label: handlePaymentStatusLabel(status),
    color: PaymentStatusColor[status]
  })) : null, __jsx(Td, _extends({
    sx: {
      textWrapMode: 'nowrap'
    }
  }, testIds.amount.attr), toUsCurrency(totalAmount)));
};
export const TransactionsListDesktopTableBody = _ref2 => {
  let {
    transactions,
    isFetching
  } = _ref2;
  const {
    0: open,
    1: setOpen
  } = useState(false);
  const {
    0: drawerData,
    1: setDrawerData
  } = useState();
  const toggleDrawer = useCallback(openDrawer => {
    setOpen(openDrawer);
  }, []);
  if (isFetching) {
    return __jsx(TransactionsListDesktopTableBodyLoading, {
      testAttr: testIds.loader.attr
    });
  }
  return __jsx(React.Fragment, null, __jsx(TransactionsListDrawer, {
    drawerData: drawerData,
    open: open,
    onOpen: () => toggleDrawer(true),
    onClose: () => toggleDrawer(false)
  }), __jsx(TBody, testIds.base.attr, transactions === null || transactions === void 0 ? void 0 : transactions.map((transaction, idx) => {
    const {
      noteUrlHash
    } = transaction;
    return __jsx(Tr, _extends({
      key: idx,
      id: `${idx}-${noteUrlHash}`,
      onClick: () => {
        setDrawerData(transaction);
        toggleDrawer(true);
      },
      sx: {
        cursor: 'pointer'
      }
    }, testIds.row.attr), __jsx(Transaction, {
      transaction: transaction
    }));
  })));
};