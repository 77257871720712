import _Box from "@mui/material/Box";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["small"];
var __jsx = React.createElement;
import React from 'react';
import { SidePhotoContent } from '@yieldstreet/ui-kit';
import appStoreBlack from 'assets/images/images/app-store-black.svg';
import appStoreWhite from 'assets/images/images/app-store-white.svg';
import bgPluses from 'assets/images/images/bg_pluses.svg';
import googlePlayBlack from 'assets/images/images/google-play-black.svg';
import googlePlayWhite from 'assets/images/images/google-play-white.svg';
import mobileAppImg from 'assets/images/images/mobile-app-img-fpp.svg';
import { usePlatformPromo } from '../../../hooks/usePlatformPromo';
import { SidePhotoBg } from '../SidedPhoto.style';
import { Image, MobileButtonContainer, StoreAnchor, StoreButton } from './SidedPhotoMobile.style';
export const SidedPhotoMobile = _ref => {
  let {
      small
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  const {
    displayedMessage,
    trackAction,
    dismiss
  } = usePlatformPromo(props);
  return __jsx(_Box, {
    sx: {
      mb: 4
    }
  }, __jsx(SidePhotoBg, {
    bgColor: displayedMessage.configuration.bgColor,
    bgPluses: displayedMessage.configuration.plusBg && bgPluses,
    small: small
  }, __jsx(SidePhotoContent, _extends({
    removePaddingSide: true,
    removeFooterSpacing: true,
    removeHeaderSpacing: true,
    title: displayedMessage.configuration.title,
    mainImage: __jsx("div", null, __jsx(Image, {
      small: small,
      src: mobileAppImg
    })),
    small: small,
    inverse: displayedMessage.configuration.inverse,
    dismiss: displayedMessage.configuration.dismissible ? dismiss : undefined
  }, displayedMessage), __jsx(MobileButtonContainer, {
    small: small
  }, __jsx(StoreAnchor, {
    href: "https://apps.apple.com/us/app/yieldstreet-alt-investments/id1437754463",
    target: "_blank",
    rel: "noopener noreferrer",
    onClick: () => {
      trackAction('ClickAppStore');
    }
  }, __jsx(StoreButton, {
    small: small,
    src: displayedMessage.configuration.buttonColor === 'white' ? appStoreWhite : appStoreBlack
  })), __jsx(StoreAnchor, {
    href: "https://play.google.com/store/apps/details?id=com.yieldstreet",
    target: "_blank",
    rel: "noopener noreferrer",
    onClick: () => {
      trackAction('ClickGooglePlay');
    }
  }, __jsx(StoreButton, {
    small: small,
    src: displayedMessage.configuration.buttonColor === 'white' ? googlePlayWhite : googlePlayBlack
  }))))));
};