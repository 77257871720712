import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from '../../../content/button';
import { DesktopOnly, MobileOnly } from '../../../utils';
import { NavigationLink } from '../../common';
import { HelpCenter, mainNavLoggedOut } from '../navigationConfig';
import { Hamburger } from '../Hamburger';
import { LoginArea } from '../LoginArea/LoginArea';
import { Logo } from '../Logo';
import { HeaderItemLoggedOut } from '../HeaderItemLoggedOut';
import { Backdrop, MobileLogoWrap, MobileMenuFooter, MobileMenuFooterHolder, NavContainer, Separator, Container, Flex, HamburgerWrap, LogoWrap, Menu, MenuContainer, MenuItemsContainer, Wrapper } from './HeaderViewLoggedOut.style';
export const HeaderLoggedOut = props => {
  const {
    audiences,
    tier,
    userState,
    currentPath,
    notificationGroup,
    preContent,
    midContent,
    postContent
  } = props;
  const menuLinkElement = useRef(null);
  const {
    0: sideMenuOpen,
    1: setSideMenuOpen
  } = useState(false);
  const {
    0: dropdownOpen,
    1: setDropdownOpen
  } = useState(false);
  const toggleMenuOpen = useCallback(() => {
    setSideMenuOpen(!sideMenuOpen);
  }, [sideMenuOpen]);
  const onClickOutside = useCallback(e => {
    if (menuLinkElement.current && e.target) {
      if (!menuLinkElement.current.contains(e.target)) {
        setDropdownOpen(false);
      }
    }
  }, []);
  useEffect(() => {
    if (window) {
      window.addEventListener('click', onClickOutside);
      return () => window.removeEventListener('click', onClickOutside);
    }
  }, [onClickOutside]);
  useEffect(() => {
    setSideMenuOpen(false);
    setDropdownOpen(false);
  }, [currentPath]);
  return __jsx(Wrapper, {
    "data-cy": "header-view"
  }, __jsx(Backdrop, {
    visible: dropdownOpen || sideMenuOpen
  }), preContent, __jsx(Container, null, __jsx(Flex, null, __jsx(HamburgerWrap, null, __jsx(Hamburger, {
    opened: sideMenuOpen,
    onChange: toggleMenuOpen
  })), __jsx(LogoWrap, {
    onChange: toggleMenuOpen,
    "data-cy": "yieldstreet-logo"
  }, __jsx(NavigationLink, _extends({}, props, {
    navItem: {
      key: 'home',
      id: 'yieldstreet-logo',
      route: userState && userState.loggedIn ? '/offerings' : '/',
      staticRoute: true,
      ariaLabel: 'Yieldstreet logo'
    },
    raw: true
  }), __jsx(Logo, null)))), __jsx(MenuContainer, {
    sideMenuOpen: sideMenuOpen
  }, __jsx(Menu, {
    sideMenuOpen: sideMenuOpen,
    ref: menuLinkElement
  }, __jsx(DesktopOnly, null, __jsx(MenuItemsContainer, null, mainNavLoggedOut.map(item => __jsx(HeaderItemLoggedOut, _extends({
    key: item.key,
    menuItem: item,
    currentPath: currentPath,
    notificationGroup: notificationGroup,
    setDropdownOpen: setDropdownOpen
  }, props))))), __jsx(MobileOnly, null, __jsx(NavContainer, null, __jsx(MobileLogoWrap, {
    onChange: toggleMenuOpen
  }, __jsx(NavigationLink, _extends({}, props, {
    navItem: {
      key: 'home',
      route: userState && userState.loggedIn ? '/offerings' : '/',
      staticRoute: true
    },
    raw: true,
    className: "nav-logo"
  }), __jsx(Logo, null))), __jsx(HamburgerWrap, null, __jsx(Hamburger, {
    opened: sideMenuOpen,
    onChange: toggleMenuOpen
  }))), __jsx(MenuItemsContainer, null, __jsx(Separator, null), mainNavLoggedOut.map(item => __jsx(HeaderItemLoggedOut, _extends({
    key: item.key,
    menuItem: item,
    currentPath: currentPath,
    notificationGroup: notificationGroup,
    isSidebarItem: true,
    isMobile: true,
    setDropdownOpen: setDropdownOpen,
    setSideMenuOpen: setSideMenuOpen
  }, props))), __jsx(HeaderItemLoggedOut, _extends({
    key: "help-center",
    menuItem: HelpCenter,
    currentPath: currentPath,
    notificationGroup: notificationGroup,
    isSidebarItem: true,
    isMobile: true,
    setDropdownOpen: setDropdownOpen,
    setSideMenuOpen: setSideMenuOpen
  }, props))), __jsx(MobileMenuFooter, null, __jsx(Separator, null), __jsx(MobileMenuFooterHolder, null, __jsx(NavigationLink, _extends({}, props, {
    navItem: {
      id: 'header-nav-signuplink',
      key: 'signup',
      route: '/get-started/'
    },
    raw: true
  }), __jsx(Button, {
    buttonType: "basic",
    trackingId: "mobile-menu-signup",
    small: true
  }, "Sign up")), __jsx(NavigationLink, _extends({}, props, {
    navItem: {
      id: 'header-nav-login',
      key: 'login',
      route: '/login',
      title: 'Log in'
    },
    raw: true
  }), __jsx(Button, {
    buttonType: "secondary",
    trackingId: "mobile-menu-login",
    small: true
  }, "Login"))))))), __jsx(LoginArea, _extends({
    audiences: audiences,
    tier: tier
  }, props))), midContent, postContent);
};