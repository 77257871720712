import { LinkStyles, Paragraph } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
export const SParagraph = styled(Paragraph).withConfig({
  displayName: "ETCKYCContainerstyle__SParagraph",
  componentId: "sc-4le2ax-0"
})(["margin:15px 0 40px;"]);
export const NotificationWrapper = styled.div.withConfig({
  displayName: "ETCKYCContainerstyle__NotificationWrapper",
  componentId: "sc-4le2ax-1"
})(["margin-bottom:", ";text-align:left;"], props => props.theme.spacing.s);
export const Wrapper = styled.div.withConfig({
  displayName: "ETCKYCContainerstyle__Wrapper",
  componentId: "sc-4le2ax-2"
})(["background:", ";width:100%;"], props => props.theme.colors.gray7);
export const SupportLink = styled.a.withConfig({
  displayName: "ETCKYCContainerstyle__SupportLink",
  componentId: "sc-4le2ax-3"
})(["", ""], LinkStyles);