import update from 'immutability-helper';
import * as actions from 'store/actions/location';
const initialState = {
  currentPathname: '',
  currentSearch: '',
  redirectUrl: null,
  redirectPermanent: false
};
const locationReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case actions.SET_LOCATION_REDIRECT:
      {
        return update(state, {
          redirectUrl: {
            $set: action.redirectUrl || null
          },
          redirectPermanent: {
            $set: action.redirectPermanent || false
          }
        });
      }
    case actions.RESET_LOCATION_REDIRECT:
      {
        return update(state, {
          redirectUrl: {
            $set: null
          },
          redirectPermanent: {
            $set: false
          }
        });
      }
    case actions.SET_CURRENT_LOCATION:
      {
        return update(state, {
          currentPathname: {
            $set: action.currentPathname || ''
          },
          currentSearch: {
            $set: action.currentSearch || ''
          }
        });
      }
    default:
      return state;
  }
};
export default locationReducer;