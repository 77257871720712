import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["commitmentId"];
import { HttpMethods } from '../../../enums';
import { restApi, Tags } from '../../common';
const BASE_URL = '/api/investment-request';
export const investmentRequestApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.InvestmentRequest]
}).injectEndpoints({
  endpoints: builder => {
    const getInvestmentRequests = builder.query({
      query: () => ({
        url: `${BASE_URL}/investments`,
        method: HttpMethods.Get
      }),
      providesTags: [Tags.InvestmentRequest]
    });
    const createCommitment = builder.mutation({
      query: _ref => {
        let {
          noteUrlHash,
          committedAmount
        } = _ref;
        return {
          url: `${BASE_URL}/commitments`,
          method: HttpMethods.Post,
          data: {
            noteUrlHash,
            committedAmount
          }
        };
      },
      invalidatesTags: [Tags.InvestmentRequest]
    });
    const getCommitment = builder.query({
      query: _ref2 => {
        let {
          commitmentId
        } = _ref2;
        return {
          url: `${BASE_URL}/commitments/${commitmentId}`,
          method: HttpMethods.Get
        };
      },
      providesTags: [Tags.InvestmentRequest]
    });
    const signCommitment = builder.mutation({
      query: _ref3 => {
        let {
            commitmentId
          } = _ref3,
          data = _objectWithoutProperties(_ref3, _excluded);
        return {
          url: `${BASE_URL}/commitments/${commitmentId}`,
          method: HttpMethods.Put,
          data
        };
      },
      invalidatesTags: [Tags.InvestmentRequest]
    });
    return {
      getInvestmentRequests,
      createCommitment,
      getCommitment,
      signCommitment
    };
  }
});