import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["children"];
var __jsx = React.createElement;
import React from 'react';
import { useTestIds } from './PageContainer.model';
import { SBox } from './PageContainer.style';
export const PageContainer = _ref => {
  let {
      children
    } = _ref,
    baseProps = _objectWithoutProperties(_ref, _excluded);
  const testIds = useTestIds(baseProps);
  return __jsx(SBox, _extends({}, baseProps, testIds.base.attr), children);
};