import _objectDestructuringEmpty from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectDestructuringEmpty.js";
import { HttpMethods } from '../../enums';
import { restApi, Tags } from '../common';
export const userApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.UserState, Tags.UserContact]
}).injectEndpoints({
  endpoints: builder => {
    const getUserState = builder.query({
      extraOptions: _props => {
        const options = {
          cacheKey: ''
        };
        return options;
      },
      query: () => ({
        url: `/a/api/user/state`,
        method: HttpMethods.Get
      }),
      keepUnusedDataFor: Infinity,
      providesTags: [Tags.UserState]
    });
    const submitAccreditationStatus = builder.mutation({
      query: data => ({
        url: `/a/api/user/accreditation-status`,
        method: HttpMethods.Post,
        data
      }),
      invalidatesTags: [Tags.UserState]
    });
    const getUserWallets = builder.query({
      query: _ref => {
        let {
          userId
        } = _ref;
        return {
          url: `/a/api/user/${userId}/wallets`,
          method: HttpMethods.Get
        };
      }
    });
    const getUserIraSourceAccount = builder.query({
      query: _ref2 => {
        let {
          userId
        } = _ref2;
        return {
          url: `/a/api/user/${userId}/ira-source-account`,
          method: HttpMethods.Get
        };
      }
    });
    const getUserContactInfo = builder.query({
      query: () => ({
        url: `/a/api/user/contact/get`,
        method: HttpMethods.Get
      }),
      providesTags: [Tags.UserContact]
    });
    const saveUserContact = builder.mutation({
      query: _ref3 => {
        let data = Object.assign({}, (_objectDestructuringEmpty(_ref3), _ref3));
        return {
          url: '/a/api/user/contact/save',
          method: HttpMethods.Post,
          data
        };
      },
      invalidatesTags: [Tags.UserContact]
    });
    return {
      getUserState,
      getUserWallets,
      getUserContactInfo,
      getUserIraSourceAccount,
      saveUserContact,
      submitAccreditationStatus
    };
  }
});