import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useMemo, useState, useEffect } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { useLocation } from 'react-router-dom';
import { Notification, useModal } from '@yieldstreet/ui-kit';
import { useFlags } from '@yieldstreet/tool-kit';
import CarouselSection from 'sharedComponents/JsonPages/sections/CarouselSection/CarouselSection';
import DisclaimerSection from 'sharedComponents/JsonPages/sections/DisclaimerSection/DisclaimerSection';
import MediaCardsSection from 'sharedComponents/JsonPages/sections/MediaCardsSection/MediaCardsSection';
import MetricCardsSection from 'sharedComponents/JsonPages/sections/MetricCardsSection/MetricCardsSection';
import NoteBannerSection from 'sharedComponents/JsonPages/sections/NoteBannerSection/NoteBannerSection';
import ParagraphBoxSection from 'sharedComponents/JsonPages/sections/ParagraphBoxSection/ParagraphBoxSection';
import SurveySection from 'sharedComponents/JsonPages/sections/SurveySection/SurveySection';
import { WordPressCardsSection } from 'sharedComponents/JsonPages/sections/WordPressCardsSection/WordPressCardsSection';
import OfferingTagsSection from 'sharedComponents/JsonPages/sections/OfferingTagsSection/OfferingTagsSection';
import { Modals } from 'constants/modal.model';
import OfferingHeroContainer from '../OfferingHeroContainer/OfferingHeroContainer';
import { BannerInner, BannerWrapper, ContentContainer, ContentInner, VisibilityDiv } from './OfferingV5.style';
import TrendingOfferingComponent from './TrendingOffering';
import { Navigation, scrollToContainer } from '../Navigation';
import { OfferingV5ContainerSection } from './OfferingV5ContainerSection';
import { CapitalCallBanner } from '../CapitalCallBanner/CapitalCallBanner';
const OfferingV5 = _ref => {
  let {
    offering,
    userState,
    metrics,
    history,
    rollover
  } = _ref;
  const {
    v5Data
  } = offering;
  const containers = (v5Data === null || v5Data === void 0 ? void 0 : v5Data.containers) || [];
  const userRoles = (userState === null || userState === void 0 ? void 0 : userState.roles) || [];
  const isAdmin = userRoles.includes('YS_ADMIN');
  const {
    hash
  } = useLocation();
  const {
    0: sectionInView,
    1: setSectionInView
  } = useState(null);
  const {
    0: isHeroInView,
    1: setIsHeroInView
  } = useState(true);
  const {
    0: isFooterInView,
    1: setIsFooterInView
  } = useState(false);
  const {
    isOpen: isOpenRollover
  } = useModal(Modals.Rollover);
  const {
    isOpen: isOpenAdvice
  } = useModal(Modals.Advice);
  const {
    CapitalCall: showCapitalCallBanner
  } = useFlags({
    CapitalCall: false
  });
  const urlHash = offering === null || offering === void 0 ? void 0 : offering.urlHash;
  const trendingOffering = useMemo(() => urlHash && __jsx(TrendingOfferingComponent, {
    noteUrlHash: urlHash
  }), [urlHash]);
  const visibilityDivOnChange = isFooterInView => {
    setIsFooterInView(!isFooterInView);
  };
  const isModalView = isOpenRollover || isOpenAdvice;
  useEffect(() => {
    if (!isModalView) {
      scrollToContainer(hash);
    }
  });
  return __jsx(React.Fragment, null, __jsx(VisibilitySensor, {
    scrollCheck: true,
    onChange: args => {
      setIsHeroInView(args);
    }
  }, __jsx(OfferingHeroContainer, {
    offering: offering,
    metrics: metrics,
    history: history,
    isFooterInView: isFooterInView,
    rollover: rollover
  })), __jsx(ContentContainer, {
    "data-cy": "offering-content-container",
    modalView: isModalView
  }, !isModalView && __jsx(Navigation, {
    offering: offering,
    showBackToTop: !isHeroInView,
    sectionInView: sectionInView
  }), __jsx(ContentInner, null, showCapitalCallBanner && offering.capitalCallEnabled && __jsx(BannerWrapper, null, __jsx(BannerInner, null, __jsx(CapitalCallBanner, null))), containers.length > 0 ? containers.map((container, idx) => __jsx(OfferingV5ContainerSection, {
    dataCy: container.key || `container-${idx}`,
    key: container.key || `container-${idx}`,
    container: container,
    sections: renderSections(userState, history, offering, container === null || container === void 0 ? void 0 : container.sections),
    userState: userState,
    onViewChange: (containerSlug, isSectionInView) => {
      if (isSectionInView) {
        setSectionInView(containerSlug);
      }
    }
  })) : isAdmin && __jsx(Notification, {
    severity: "warning",
    description: "Oops, looks like this offering doesn't have any containers on its set up. Make sure to add some at the",
    link: `/admin/offerings/create/offeringBuilder/${offering.urlHash}`,
    linkText: "Offering Builder.",
    testId: "no-containers-notification"
  })), trendingOffering), __jsx(VisibilitySensor, {
    offset: {
      top: -5000
    },
    scrollCheck: true,
    onChange: visibilityDivOnChange
  }, __jsx(VisibilityDiv, null)));
};
const renderSections = (userState, history, offering, sections) => {
  return sections && sections.length > 0 && sections.map((section, idx) => sectionRender(userState, history, offering, section, idx));
};
const sectionRender = (userState, history, offering, section, idx) => {
  const {
    urlHash,
    documents,
    legalEntityType,
    spvName,
    status,
    v5Data
  } = offering;
  const {
    type
  } = section;
  const isValid = ['paragraphBox', 'mediaCards', 'metricCards', 'survey', 'carousel', 'relatedImages', 'disclaimer', 'noteBanner', 'wordPressCards', 'offeringTags'].includes(type);
  if (isValid) {
    const commonSectionProps = {
      section,
      key: section.key || `${type}-${idx}`
    };
    switch (type) {
      case 'paragraphBox':
        return __jsx(ParagraphBoxSection, commonSectionProps);
      case 'metricCards':
        return __jsx(MetricCardsSection, _extends({}, commonSectionProps, {
          isLoggedIn: userState === null || userState === void 0 ? void 0 : userState.loggedIn
        }));
      case 'survey':
        return __jsx(SurveySection, _extends({
          userState: userState,
          urlHash: urlHash
        }, commonSectionProps));
      case 'carousel':
        return __jsx(CarouselSection, commonSectionProps);
      case 'mediaCards':
        return __jsx(MediaCardsSection, _extends({
          documents: documents,
          userState: userState,
          history: history,
          status: status
        }, commonSectionProps));
      case 'disclaimer':
        return __jsx(DisclaimerSection, _extends({
          legalEntityType: legalEntityType,
          spvName: spvName
        }, commonSectionProps));
      case 'noteBanner':
        return __jsx(NoteBannerSection, commonSectionProps);
      case 'wordPressCards':
        return __jsx(WordPressCardsSection, {
          section: section,
          key: section.key || `${type}-${idx}`,
          testId: section.key || `${type}-${idx}`
        });
      case 'offeringTags':
        return __jsx(OfferingTagsSection, {
          offeringTags: v5Data === null || v5Data === void 0 ? void 0 : v5Data.offeringTags
        });
      default:
        return null;
    }
  }
  return null;
};
export default OfferingV5;