import { isPromoType, PromotionType } from '@yieldstreet/platform-kit';
import forEach from 'lodash/forEach';
import { PROMOTION_CTAS, PROMOTION_GATE_ERRORS, PROMOTION_ELEGIBILITY_ERROR } from './useOfferingPromotion.model';
export const getOfferingPromotionState = (promoKey, eligibilityResult, eligibilityError) => {
  var _promoDetails;
  let promoDetails;
  let promoOfferingDetails;
  let promoGate;
  if (eligibilityResult) {
    forEach(eligibilityResult, _ref => {
      let {
        gate,
        promotion,
        offeringPromotion
      } = _ref;
      if (!promoDetails && promotion) {
        promoDetails = promotion;
      }
      if (!promoOfferingDetails && offeringPromotion) {
        promoOfferingDetails = offeringPromotion;
      }
      if (!promoGate && gate) {
        promoGate = gate;
      }
    });
  }
  const promoEligible = !!(promoDetails && !promoGate);
  const promoChecked = !!(promoKey && !!eligibilityResult || !promoKey);
  const promoType = (_promoDetails = promoDetails) === null || _promoDetails === void 0 ? void 0 : _promoDetails.type;
  const promoCTA = promoType && PROMOTION_CTAS[promoType];
  const promoError = promoGate && PROMOTION_GATE_ERRORS[promoGate.code] || eligibilityError && PROMOTION_ELEGIBILITY_ERROR;
  const isFreeInvestmentPromo = !!(promoEligible && promoDetails && isPromoType(PromotionType.FREE_INVESTMENT, promoDetails));
  return {
    promoKey,
    promoGate,
    promoDetails,
    promoChecked,
    promoEligible,
    promoCTA,
    promoError,
    promoOfferingDetails,
    isFreeInvestmentPromo
  };
};