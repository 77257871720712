import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["ongoing", "actionable", "eligible", "final", "problem", "pending"];
import { useMemo } from 'react';
import { useProviderMigration } from './useProviderMigration';
export const useProviderInvestorEntityMigration = function (investorEntityId) {
  let {
    checkKYCMigrated,
    checkBankLinkMigrated
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    migrationInvestorEntities,
    isLoading,
    refetchKYCStatus,
    refetchBankLinkStatus
  } = useProviderMigration({
    checkInvestorKYCMigrated: checkKYCMigrated ? investorEntityId : null,
    checkInvestorBankLinkMigrated: checkBankLinkMigrated ? investorEntityId : null
  });
  return useMemo(() => {
    const migrationConfig = migrationInvestorEntities === null || migrationInvestorEntities === void 0 ? void 0 : migrationInvestorEntities.find(_ref => {
      let {
        id
      } = _ref;
      return investorEntityId === id;
    });
    const _ref2 = migrationConfig || {},
      {
        ongoing: isMigrationOngoing,
        actionable: isMigrationActionable,
        eligible: isMigrationEligible,
        final: isMigrationFinal,
        problem: isMigrationProblem,
        pending: isMigrationPending
      } = _ref2,
      config = _objectWithoutProperties(_ref2, _excluded);
    return _objectSpread(_objectSpread({
      isMigrationOngoing,
      isMigrationActionable,
      isMigrationEligible,
      isMigrationFinal,
      isMigrationPending,
      isMigrationProblem,
      isLoading
    }, config), {}, {
      refetchKYCStatus,
      refetchBankLinkStatus
    });
  }, [migrationInvestorEntities, investorEntityId, isLoading, refetchKYCStatus, refetchBankLinkStatus]);
};