import styled, { css } from 'styled-components';
import { media } from '../../themes';
import { ModalAlignment } from './Modal.model';
export const Center = css(["display:flex;flex-direction:column;flex-wrap:nowrap;justify-content:center;align-content:center;align-items:center;"]);
export const Modal = styled.div.withConfig({
  displayName: "Modalstyle__Modal",
  componentId: "sc-1yt7d69-0"
})(["z-index:1000;", ""], media.phoneLandscape`
    padding-right: 50px;
  `);
export const Content = styled.div.withConfig({
  displayName: "Modalstyle__Content",
  componentId: "sc-1yt7d69-1"
})(["position:relative;width:100%;margin:auto;overflow:hidden;min-height:20vh;", " ", ""], media.desktop`
    min-width: 375px;
  `, media.phoneLandscape`
    display: flex;
    justify-content: flex-end;
    border-radius: 8px;
    width: fit-content;
  `);
export const ModalWrapper = styled.div.withConfig({
  displayName: "Modalstyle__ModalWrapper",
  componentId: "sc-1yt7d69-2"
})(["overflow:auto;position:fixed;top:0;bottom:0;left:0;right:0;transition:all 0.2s ease;z-index:999;visibility:", ";opacity:", ";", ";"], props => props.visible ? 'visible' : 'hidden', props => props.visible ? 1 : 0, _ref => {
  let {
    align
  } = _ref;
  return align === ModalAlignment.centre ? Center : media.phoneLandscape`padding: 105px 0;`;
});