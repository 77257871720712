import { CALL_API } from '../middleware/Middleware';
export const GET_OFFERING_ATTEMPT = 'ys/offering/ATTEMPT';
export const GET_OFFERING_SUCCESS = 'ys/offering/SUCCESS';
export const GET_OFFERING_FAILURE = 'ys/offering/FAILURE';
export const getOffering = urlHash => dispatch => {
  const endpoint = `/a/api/offering/${urlHash}`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_OFFERING_ATTEMPT, GET_OFFERING_SUCCESS, GET_OFFERING_FAILURE],
      getResponse: res => res.data.note,
      successPayload: {
        urlHash
      }
    }
  });
};
export const GET_OFFERING_AVAILABILITY_ATTEMPT = 'ys/offering/availability/ATTEMPT';
export const GET_OFFERING_AVAILABILITY_SUCCESS = 'ys/offering/availability/SUCCESS';
export const GET_OFFERING_AVAILABILITY_FAILURE = 'ys/offering/availability/FAILURE';
export const getOfferingAvailability = _ref => {
  let {
    urlHash
  } = _ref;
  return dispatch => {
    const endpoint = `/a/api/user/note/${urlHash}/available`;
    return dispatch({
      [CALL_API]: {
        endpoint,
        types: [GET_OFFERING_AVAILABILITY_ATTEMPT, GET_OFFERING_AVAILABILITY_SUCCESS, GET_OFFERING_AVAILABILITY_FAILURE],
        getResponse: res => res.data.availability
      }
    });
  };
};
export const GET_OFFERING_FEATURES_ATTEMPT = 'ys/offering/features/ATTEMPT';
export const GET_OFFERING_FEATURES_SUCCESS = 'ys/offering/features/SUCCESS';
export const GET_OFFERING_FEATURES_FAILURE = 'ys/offering/features/FAILURE';
export const getOfferingFeatures = urlHash => dispatch => {
  const endpoint = `/a/api/feature/note/${urlHash}`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_OFFERING_FEATURES_ATTEMPT, GET_OFFERING_FEATURES_SUCCESS, GET_OFFERING_FEATURES_FAILURE],
      getResponse: res => res.data.features
    }
  });
};
export const SAVE_CONFIRM_INVESTMENT_EXTRA_DATA = 'ys/save/confirm-investment-extra-data';
export const saveConfirmInvestmentExtraData = testData => dispatch => dispatch({
  type: SAVE_CONFIRM_INVESTMENT_EXTRA_DATA,
  data: testData
});
export const GET_OFFERING_ELIGIBILITY_ATTEMPT = 'ys/offering/eligibility/ATTEMPT';
export const GET_OFFERING_ELIGIBILITY_SUCCESS = 'ys/offering/eligibility/SUCCESS';
export const GET_OFFERING_ELIGIBILITY_FAILURE = 'ys/offering/eligibility/FAILURE';
export const getOfferingEligibility = (urlHash, data) => dispatch => {
  const endpoint = `/a/api/investment/${urlHash}/eligibility`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_OFFERING_ELIGIBILITY_ATTEMPT, GET_OFFERING_ELIGIBILITY_SUCCESS, GET_OFFERING_ELIGIBILITY_FAILURE],
      options: {
        method: 'POST',
        data
      },
      getResponse: res => res.data
    }
  });
};
export const GET_INVESTOR_POSITION_ATTEMPT = 'ys/offering/investor-position/ATTEMPT';
export const GET_INVESTOR_POSITION_SUCCESS = 'ys/offering/investor-position/SUCCESS';
export const GET_INVESTOR_POSITION_FAILURE = 'ys/offering/investor-position/FAILURE';
export const getInvestorPosition = (noteUrlHash, investorAccountId) => dispatch => {
  const endpoint = `/a/api/investment-request/offerings/${noteUrlHash}/investoraccount/${investorAccountId}/current-position`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_INVESTOR_POSITION_ATTEMPT, GET_INVESTOR_POSITION_SUCCESS, GET_INVESTOR_POSITION_FAILURE],
      getResponse: res => res.data.investorPosition
    }
  });
};
export const GET_INVESTOR_LIQUIDATION_ATTEMPT = 'ys/offering/investor-liquidation/ATTEMPT';
export const GET_INVESTOR_LIQUIDATION_SUCCESS = 'ys/offering/investor-liquidation/SUCCESS';
export const GET_INVESTOR_LIQUIDATION_FAILURE = 'ys/offering/investor-liquidation/FAILURE';
export const getInvestorLiquidation = (noteUrlHash, tenderId, investorAccountId) => dispatch => {
  const endpoint = `/a/api/investment-request/offerings/${noteUrlHash}/liquidations/${tenderId}/investoraccount/${investorAccountId}`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_INVESTOR_LIQUIDATION_ATTEMPT, GET_INVESTOR_LIQUIDATION_SUCCESS, GET_INVESTOR_LIQUIDATION_FAILURE],
      getResponse: res => res.data.liquidationRequest
    }
  });
};
export const GET_TENDER_OFFER_CONFIG_ATTEMPT = 'ys/offering/tender-offer-config/ATTEMPT';
export const GET_TENDER_OFFER_CONFIG_SUCCESS = 'ys/offering/tender-offer-config/SUCCESS';
export const GET_TENDER_OFFER_CONFIG_FAILURE = 'ys/offering/tender-offer-config/FAILURE';
export const getTenderOfferConfig = noteUrlHash => dispatch => {
  const endpoint = `/a/api/note/${noteUrlHash}/tender-offer-config`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_TENDER_OFFER_CONFIG_ATTEMPT, GET_TENDER_OFFER_CONFIG_SUCCESS, GET_TENDER_OFFER_CONFIG_FAILURE],
      getResponse: res => res.data.config
    }
  });
};