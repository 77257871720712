import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import dayjs from 'dayjs';
import isNil from "lodash/isNil";
import { DUE_DILIGENCE_FIELDS, EmploymentStatus, FormFields, FundSource, IdentificationDetails } from './ETCKYC.model';
const getEmployerAddressInitialValues = values => {
  if ((values === null || values === void 0 ? void 0 : values[FormFields.EmploymentStatus]) === EmploymentStatus.Employed) {
    var _values$employerAddre, _values$employerAddre2, _values$employerAddre3, _values$employerAddre4, _values$employerAddre5;
    return {
      [FormFields.EmployedEmployerAddressLine1]: values === null || values === void 0 ? void 0 : (_values$employerAddre = values.employerAddress) === null || _values$employerAddre === void 0 ? void 0 : _values$employerAddre.addressLine1,
      [FormFields.EmployedEmployerAddressLine2]: values === null || values === void 0 ? void 0 : (_values$employerAddre2 = values.employerAddress) === null || _values$employerAddre2 === void 0 ? void 0 : _values$employerAddre2.addressLine2,
      [FormFields.EmployedEmployerAddressCity]: values === null || values === void 0 ? void 0 : (_values$employerAddre3 = values.employerAddress) === null || _values$employerAddre3 === void 0 ? void 0 : _values$employerAddre3.city,
      [FormFields.EmployedEmployerAddressState]: values === null || values === void 0 ? void 0 : (_values$employerAddre4 = values.employerAddress) === null || _values$employerAddre4 === void 0 ? void 0 : _values$employerAddre4.state,
      [FormFields.EmployedEmployerAddressZipCode]: values === null || values === void 0 ? void 0 : (_values$employerAddre5 = values.employerAddress) === null || _values$employerAddre5 === void 0 ? void 0 : _values$employerAddre5.zipCode
    };
  }
  return {};
};
export const addUSPassportField = values => {
  if (values.identificationType === IdentificationDetails.Passport) {
    return {
      [FormFields.IdentificationoCountryOfIssuance]: 'United States'
    };
  }
  return {};
};
export const getInitialDataFromLocation = location => {
  var _location$state;
  if (location !== null && location !== void 0 && (_location$state = location.state) !== null && _location$state !== void 0 && _location$state.providerData) {
    var _location$state2, _owner$addresses, _owner$phones, _owner$phones$;
    const {
      owner,
      customerDueDiligence
    } = (location === null || location === void 0 ? void 0 : (_location$state2 = location.state) === null || _location$state2 === void 0 ? void 0 : _location$state2.providerData) || {};
    const address = (owner === null || owner === void 0 ? void 0 : (_owner$addresses = owner.addresses) === null || _owner$addresses === void 0 ? void 0 : _owner$addresses[0]) || {};
    return _objectSpread(_objectSpread(_objectSpread({}, mapFormFieldsToValues({
      fields: DUE_DILIGENCE_FIELDS,
      values: customerDueDiligence,
      defaultFieldValue: ''
    })), getEmployerAddressInitialValues(customerDueDiligence)), {}, {
      firstName: owner.firstName || '',
      lastName: owner.lastName || '',
      addressStreet: address.addressLine1 || '',
      addressLine2: address.addressLine2 || '',
      country: '',
      city: address.city || '',
      state: address.state || '',
      zipCode: address.zipCode || '',
      phoneNumber: (owner === null || owner === void 0 ? void 0 : (_owner$phones = owner.phones) === null || _owner$phones === void 0 ? void 0 : (_owner$phones$ = _owner$phones[0]) === null || _owner$phones$ === void 0 ? void 0 : _owner$phones$.phoneNumber) || '',
      documentNumber: '',
      dateOfBirth: '',
      consentedDisclosure: false,
      [FormFields.InitialFundSource]: getValuesFromCheckboxes(customerDueDiligence === null || customerDueDiligence === void 0 ? void 0 : customerDueDiligence[FormFields.InitialFundSource]),
      [FormFields.OngoingFundSource]: getValuesFromCheckboxes(customerDueDiligence === null || customerDueDiligence === void 0 ? void 0 : customerDueDiligence[FormFields.OngoingFundSource])
    });
  }
  return {};
};
export const getListOfOccupationCategoriesList = list => {
  return Object.keys(list).map(item => ({
    label: item,
    value: item
  }));
};
export const getListOfOccupationsByCategory = (list, category) => {
  var _list$category;
  return list === null || list === void 0 ? void 0 : (_list$category = list[category]) === null || _list$category === void 0 ? void 0 : _list$category.map(item => ({
    label: item,
    value: item
  }));
};
export const getValuesFromCheckboxes = values => Object.keys(FundSource).reduce((result, key) => {
  result[key] = !!values[key];
  return result;
}, {});
export const parseEmployerAddress = values => {
  if ((values === null || values === void 0 ? void 0 : values[FormFields.EmploymentStatus]) === EmploymentStatus.Employed) {
    return {
      [FormFields.EmployedEmployerAddress]: {
        addressLine1: values === null || values === void 0 ? void 0 : values[FormFields.EmployedEmployerAddressLine1],
        addressLine2: values === null || values === void 0 ? void 0 : values[FormFields.EmployedEmployerAddressLine2],
        city: values === null || values === void 0 ? void 0 : values[FormFields.EmployedEmployerAddressCity],
        state: values === null || values === void 0 ? void 0 : values[FormFields.EmployedEmployerAddressState],
        zipCode: values === null || values === void 0 ? void 0 : values[FormFields.EmployedEmployerAddressZipCode]
      }
    };
  }
  return {};
};
export const mapFormFieldsToValues = _ref => {
  let {
    fields,
    values,
    parseDate = false,
    defaultFieldValue = null,
    fieldsToIgnore = []
  } = _ref;
  return fields.reduce((result, field) => {
    if (fieldsToIgnore !== null && fieldsToIgnore !== void 0 && fieldsToIgnore.length && fieldsToIgnore.includes(field)) {
      return result;
    }
    const fieldValue = (values === null || values === void 0 ? void 0 : values[field]) || defaultFieldValue;
    if (!isNil(fieldValue)) {
      if (parseDate && [FormFields.IdentificationExpirationDate, FormFields.IdentificationIssueDate].includes(field)) {
        result[field] = dayjs(fieldValue, 'MM/DD/YYYY').format('YYYY-MM-DD');
      } else {
        result[field] = fieldValue;
      }
    }
    return result;
  }, {});
};