import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
export const REG_A_PLUS_OFFER = {
  version: 5,
  originator: {
    id: '',
    title: '',
    description: '',
    slug: '',
    profileImageUrl: ''
  },
  messages: {
    isActive: false,
    comingSoon: '',
    open: '',
    closed: '',
    matured: ''
  },
  offeringHero: {
    customTermLabel: '',
    customTerm: 'Perpetual',
    customRateLabel: 'Annualized return',
    customRateString: '11-15%'
  },
  offeringCardOverrides: {
    minInvestmentLabel: '',
    minInvestmentValue: '',
    assetClassLabel: '',
    assetClassValue: '',
    originatorLabel: '',
    originatorValue: ''
  },
  tieredRateTree: {
    label1: "Target net ann'l cash yield",
    value1: '~5%',
    label2: "Target net ann'l capital app",
    value2: '~6-10%',
    tooltipTitle: '',
    tooltipSubtitle: '',
    tooltipImage: '',
    tooltipDescription: 'Over the life of the investment, investors are expected to receive a target annualized return of 11% - 15%, net of Yieldstreet’s management fee and structuring fee as further described in the offering circular. Returns may be above or below the target range. Investors are expected to receive cash flows from two sources: ~5% of annualized income which is expected to be paid quarterly beginning second quarter 2022, and the balance of the returns are expected to be achieved via appreciation at time of re-sale of the underlying properties. ',
    description: ''
  },
  liquidityDescription: {
    tooltipTitle: '',
    tooltipSubtitle: '',
    tooltipImage: '',
    tooltipDescription: '',
    description: ''
  },
  updatedDate: {
    date: '',
    dateEpoch: ''
  },
  markdown: {
    isMarkdown: true
  },
  v5Data: {
    containers: [{
      title: 'Overview',
      subtitle: '',
      hideTitle: true,
      sections: [{
        type: 'paragraphBox',
        title: '',
        subtitle: '',
        iconName: '',
        video: '',
        image: '',
        imageCaption: '',
        mediaTitle: '',
        interactiveMap: '',
        charLimit: 1500,
        content: ['The Fund is a diversified public, non-traded REIT that seeks to primarily make equity investments in commercial real estate properties across key U.S. markets and property types. The Fund’s primary objective is capital appreciation with a secondary objective of current income. \n\\\n\\\n*No offers will be made to or accepted from investors residing in or located in Nebraska or North Dakota at this time.*'],
        carousel: []
      }]
    }, {
      title: 'Premise',
      subtitle: '',
      hideTitle: true,
      sections: [{
        type: 'paragraphBox',
        title: '',
        subtitle: '',
        iconName: '',
        video: '',
        image: '',
        imageCaption: '',
        mediaTitle: '',
        interactiveMap: '',
        charLimit: 1500,
        content: ["**Outsized potential returns relative to a portfolio comprised of only stocks and bonds**\n \n* With bond yields at or near their all time lows and equity valuations historically high, the 60/40 portfolio’s forward looking return estimates are only 1.4% according to AQR. \n* Not only is the forward looking outlook painting a poor picture for stocks and bonds, historical average returns generated by the S&P 500 over the past 25 years have also underperformed relative to private commercial real estate. \n* Adding private real estate to portfolios has the potential to not only generate above average returns but the asset classes' low correlation to global public equities and bonds can help to reduce the overall risk level of portfolios.  \n"],
        carousel: []
      }, {
        type: 'paragraphBox',
        title: '',
        subtitle: '',
        iconName: '',
        video: '',
        image: '',
        imageCaption: '',
        mediaTitle: '',
        interactiveMap: '',
        charLimit: 1500,
        content: ['**Diversified by property type, geography and investment type**'],
        carousel: [{
          imageUrl: '',
          imageCaption: ''
        }]
      }]
    }]
  },
  urlHash: 'e5jyfw',
  title: 'Growth and Income REIT',
  summary: 'The Fund is a public, non-traded Fund that seeks to primarily make equity investments in commercial real estate properties across key U.S. markets and property types.',
  bannerText: null,
  bannerDetailText: null,
  thumbnailImageUrl: 'https://iztw0uw7.cdn.imgeng.in/prod/NOTES/e5jyfw/azih0WNdHKvJKfEZKGwsRQyJ.jpg',
  underlyingSecurity: '',
  status: 'UNFN',
  legalEntityType: 'FUND',
  assetClass: 'REAL',
  assetSubClass: 'FNFP',
  paymentFrequency: 'QUAT',
  regulatoryType: 'PRVT',
  interestRate: 11,
  distributionRate: 11,
  noteAmount: 15000000,
  minimumPurchase: 5000,
  maximumPurchase: 1000000,
  purchaseIncrement: 1000,
  autoInvestMinimumPurchase: null,
  autoInvestMaximumPurchase: null,
  hasAutoInvestAllocation: false,
  computedTerm: 59,
  term: 60,
  actualTerm: null,
  termsRemaining: '60 Mo.',
  progressMessage: 'Launching Feb 24 at 4PM ET',
  originatorId: 72384,
  originatorName: 'Yieldstreet',
  originatorNickname: 'yieldstreet',
  prefunded: false,
  newInvestorsOnly: false,
  segmented: false,
  segment: null,
  partnerName: null,
  launchedDateTime: 1645736403000,
  fundingProgress: 0,
  remainingInvestment: 15000000,
  heroImageUrl: null,
  summaryTitle: null,
  description: null,
  descriptionFields: [],
  spvName: 'YS RE RAF I LLC',
  spvBankAccountName: 'YS RE RAF I LLC',
  spvBankAccountNumber: '1022030041',
  counterParty: null,
  originatorTypeDescription: null,
  originatorImageUrl: 'https://va4l.imgeng.in/https://ys-docs-production.s3.amazonaws.com/prod/PROFILES/72384/qkKnn4EE1FMTBmmnAoHIUPZE.png',
  borrowerName: null,
  borrowerTypeDescription: null,
  accreditationEligibility: 'ACCR',
  note506RuleType: null,
  facebookShare: null,
  linkedinShare: null,
  twitterShare: null,
  emailShare: null,
  documents: [],
  features: [{
    noteUrlHash: null,
    feature: 'HIGH_INTEREST_WALLET',
    enabled: true,
    createDt: 1639600128000,
    updateDt: 1639600128000
  }, {
    noteUrlHash: 'e5jyfw',
    feature: 'IR',
    enabled: true,
    createDt: 1645127605000,
    updateDt: 1645127605000
  }],
  acceptRollover: false,
  preferredAssetClass: false,
  legalAcknowledgementConfigContent: null,
  investable: false,
  statusDisplayName: 'Unfinished',
  paymentFrequencyDisplayName: 'Quarterly',
  assetClassDisplayName: 'Real Estate',
  launchedDateTimeString: '2022-02-24 16:00:03',
  launchedDateTimeFormatted: 'Feb 24 2022, 04:00 PM EST',
  SEOTitle: 'growth-and-income-reit',
  reserved: false
};
export const PRISM_FUND_OFFER_VERTICAL = {
  version: 5,
  originator: {
    id: '205061',
    title: 'Yieldstreet',
    description: '',
    slug: '',
    profileImageUrl: ''
  },
  messages: {
    isActive: true,
    comingSoon: 'Reopening soon',
    open: '',
    closed: '',
    matured: ''
  },
  offeringHero: {
    customTermLabel: '',
    customTerm: '--',
    customRateLabel: 'Distribution rate',
    customRateString: '8%'
  },
  offeringCardOverrides: {
    minInvestmentLabel: '',
    minInvestmentValue: '',
    assetClassLabel: '',
    assetClassValue: '',
    originatorLabel: '',
    originatorValue: ''
  },
  tieredRateTree: {
    tooltipTitle: '',
    tooltipSubtitle: '',
    tooltipImage: '',
    tooltipDescription: '',
    label1: '',
    value1: '',
    label2: '',
    value2: ''
  },
  updatedDate: {
    date: '',
    dateEpoch: ''
  },
  v5Data: {
    containers: [{
      title: 'Overview',
      subtitle: '',
      hideTitle: true,
      sections: [{
        type: 'noteBanner',
        title: '',
        note: '**Learn more at [Yieldstreetalternativeincomefund.com](https://yieldstreetalternativeincomefund.com/).** ',
        content: ['Access the Yieldstreet Alternative Income Fund Prospectus and other important information on our Yieldstreet Alternative Income Fund microsite.']
      }, {
        type: 'paragraphBox',
        title: '',
        subtitle: '',
        iconName: '',
        video: '',
        image: '',
        imageCaption: '',
        mediaTitle: '',
        interactiveMap: '',
        charLimit: '15000',
        content: ['Build a fixed-income portfolio spread across multiple asset classes with a single investment. Currently, the Fund has raised ~$96 million and has holdings in 5+ asset classes: Art, Commercial, Consumer, Legal, Real Estate, Corporates, and more.\n\\\n\\\n *Note: No offers will be made in or accepted from NE or ND at this time. See disclaimers below.*'],
        carousel: []
      }]
    }, {
      title: 'Other',
      subtitle: '',
      hideTitle: true,
      sections: [{
        type: 'wordPressCards',
        content: []
      }, {
        type: 'disclaimer',
        useLegalEntityDisclaimer: false,
        content: ["1. Reflects the quarterly distribution of $0.20 declared by the Fund's Board of Directors on August 12, 2021, to be paid on December 15, 2021, to stockholders of record as of December 8, 2021. The Fund's distribution may exceed its earnings. Therefore, a portion of the Fund's distribution may be a return of the money you originally invested and represent a return of capital to you for tax purposes.", "2. The Fund intends to offer to repurchase shares from investors at the discretion of the Fund's board of directors and depending upon the performance of the Fund. The Fund will limit the number of shares to be repurchased in any calendar year to 20% of shares outstanding, or 5% in each quarter. If the number of shares submitted for repurchase by investors exceeds the number of shares the Fund seeks to repurchase, the Fund will repurchase shares on a pro-rata basis, and not on a first-come, first-served basis. ", '3. The securities described in the prospectus are not offered for sale in the states of Nebraska or North Dakota or to persons resident or located in such states. No subscription for the sale of Fund shares will be accepted from any person resident or located in Nebraska or North Dakota.']
      }]
    }]
  },
  markdown: {
    isMarkdown: true
  },
  urlHash: 'LJouqg',
  title: 'Yieldstreet Alternative Income Fund',
  summary: 'Build a fixed-income portfolio spread across multiple asset classes with a single investment. Currently, the Fund has raised ~$96 million and has holdings in 5+ asset classes.',
  bannerText: '',
  bannerDetailText: null,
  thumbnailImageUrl: 'https://iztw0uw7.cdn.imgeng.in/prod/NOTES/LJouqg/_n-DXf4QJTJmBxVoemJyDOtb.jpg',
  underlyingSecurity: '',
  status: 'OPEN',
  legalEntityType: 'FUND',
  assetClass: 'FUND',
  assetClassDisplayName: 'Multi-Asset Class Fund',
  assetSubClass: 'MLTA',
  paymentFrequency: 'QUAT',
  interestRate: 8,
  noteAmount: 110000000,
  minimumPurchase: 500,
  maximumPurchase: 50000,
  purchaseIncrement: 1,
  autoInvestMinimumPurchase: null,
  autoInvestMaximumPurchase: null,
  hasAutoInvestAllocation: false,
  term: 29,
  computedTerm: 40,
  actualTerm: null,
  termsRemaining: '26 Mo.',
  progressMessage: '.',
  originatorId: 0,
  originatorName: null,
  originatorNickname: null,
  prefunded: false,
  newInvestorsOnly: false,
  segmented: false,
  segment: null,
  partnerName: '-',
  fundType: 'FORTY_ACT_FUND',
  accreditationEligibility: 'NONA',
  preferredAssetClass: false,
  showNewLabel: null,
  note506RuleType: 'R_506C',
  productType: 'MULTI_ASSET',
  statusDisplayName: 'Offering',
  paymentFrequencyDisplayName: 'Quarterly',
  SEOTitle: 'yieldstreet-prism-fund'
};
export const PRISM_FUND_OFFER_HORIZONTAL = _objectSpread(_objectSpread({}, PRISM_FUND_OFFER_VERTICAL), {}, {
  summary: 'Build a fixed-income portfolio spread across multiple asset classes with a single investment. Currently, the Fund has raised over $82.9 million and has holdings in 5+ asset classes: Art, Commercial, Consumer, Legal, Real Estate, Corporates, and more.'
});