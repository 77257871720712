import { genTestIds, MARKETPLACE_FILTERS_OPTIONS } from '@yieldstreet/tool-kit';
const {
  getTestIds
} = genTestIds(['filter-wrapper', 'filter-trigger', 'filter-apply-button', 'filter-clear-button', 'sort-wrapper', 'sort-trigger', 'sort-apply-button']);
export const testIds = function () {
  let testId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'default';
  return getTestIds(testId + '-modal');
};
export const NonSerializableOptions = {
  MARKETPLACE_FILTERS_OPTIONS: MARKETPLACE_FILTERS_OPTIONS
};