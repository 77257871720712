import _Alert from "@mui/material/Alert";
import _AlertTitle from "@mui/material/AlertTitle";
import _Stack from "@mui/material/Stack";
import _Link from "@mui/material/Link";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { useModal } from '@yieldstreet/ui-kit';
import { Modals } from 'constants/modal.model';
import { testIds } from './CapitalCallBanner.model';
export const CapitalCallBanner = () => {
  const {
    open
  } = useModal(Modals.CapitalCallInfo);
  return __jsx(_Alert, _extends({
    severity: "warning"
  }, testIds.content.attr, {
    action: __jsx(_Stack, {
      direction: "column",
      justifyContent: "center",
      height: "100%"
    }, __jsx(_Link, _extends({
      onClick: () => open()
    }, testIds.learnMoreButton.attr), "Learn more"))
  }), __jsx(_AlertTitle, null, "Capital calls will be used for this fund"), "Investors will be required to contribute a pro rata portion of their pledged commitments at intervals determined by Yieldstreet.");
};