import styled from 'styled-components';
import { Paragraph } from '@yieldstreet/ui-kit';
export const Wrapper = styled.div.withConfig({
  displayName: "FlowWrapperstyle__Wrapper",
  componentId: "sc-ay7aha-0"
})(["background:", ";width:100%;"], props => props.theme.colors.gray7);
export const SParagraph = styled(Paragraph).withConfig({
  displayName: "FlowWrapperstyle__SParagraph",
  componentId: "sc-ay7aha-1"
})(["margin:15px 0 40px;"]);
export const NotificationWraper = styled.div.withConfig({
  displayName: "FlowWrapperstyle__NotificationWraper",
  componentId: "sc-ay7aha-2"
})(["margin-bottom:", ";text-align:left;"], props => props.theme.spacing.s);
export const TooltipContent = styled(Paragraph).withConfig({
  displayName: "FlowWrapperstyle__TooltipContent",
  componentId: "sc-ay7aha-3"
})(["text-align:left;&:not(:last-child){margin-bottom:", ";}"], _ref => {
  let {
    theme
  } = _ref;
  return theme.spacing.m;
});