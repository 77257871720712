import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { Button, PageFlow, Paragraph, Title } from '@yieldstreet/ui-kit';
import { useHistory } from 'react-router';
import { testIds } from './ManagementFeeConsentVoteSubmitted.model';
import { ContentWrapper } from '../ManagementFeeConsentStart/ManagementFeeConsentStart.style';
const ManagementFeeConsentVoteSubmitted = () => {
  const {
    replace
  } = useHistory();
  const history = useHistory();
  const handleOnClick = () => {
    replace('/portfolio');
  };
  return __jsx(PageFlow, {
    backNavigation: true,
    backNavigationCallback: () => history.replace('/'),
    tabletMaxWidth: "700px"
  }, __jsx(ContentWrapper, null, __jsx(Title, testIds.title.attr, "Thank you for your participation!"), __jsx(Paragraph, {
    marginBottom: true
  }, "We've received and recorded your vote. We deeply appreciate your time and attention."), __jsx(Button, _extends({}, testIds.button.attr, {
    buttonType: "secondary",
    onClick: () => handleOnClick()
  }), "Go to portfolio")));
};
export default ManagementFeeConsentVoteSubmitted;