var __jsx = React.createElement;
import React, { useMemo } from 'react';
import { Link } from 'sharedComponents/Links/Links';
import MediaCard from './MediaCard';
import { Wrapper, InnerWrapper, CardsWrapper, Login } from './MediaCardsSection.style';
const MediaCardsSection = _ref => {
  let {
    section,
    documents,
    userState,
    history,
    status
  } = _ref;
  const {
    content,
    resources
  } = section;
  const {
    pathname
  } = history.location;
  const isPastOffering = useMemo(() => ['PNFL', 'CLSD', 'PAYO', 'MATD'].includes(status), [status]);
  return __jsx(Wrapper, null, __jsx(InnerWrapper, null, !userState.loggedIn && !isPastOffering && __jsx(Login, {
    "data-cy": "media-card-login-message"
  }, "Please ", __jsx(Link, {
    to: `/login?redirect=${encodeURIComponent(pathname)}`
  }, "log in"), " or", ' ', __jsx(Link, {
    to: "/signup-quiz"
  }, "sign up"), " to view additional Investment Documents for this offering."), __jsx(CardsWrapper, null, resources !== null && resources !== void 0 && resources.includes('all') ? documents === null || documents === void 0 ? void 0 : documents.map((doc, idx) => {
    const {
      type,
      name,
      url
    } = doc;
    return type !== 'preview' && __jsx(MediaCard, {
      testId: "media-card-document",
      key: `${name}-${idx}`,
      title: name,
      url: url,
      type: type,
      userState: userState,
      isPastOffering: isPastOffering
    });
  }) : resources === null || resources === void 0 ? void 0 : resources.map((resource, idx) => {
    return __jsx(MediaCard, {
      testId: "media-card-resource",
      key: `${resource}-${idx}`,
      title: resource,
      type: "standAloneDoc",
      userState: userState,
      documents: documents,
      isPastOffering: isPastOffering
    });
  }), content === null || content === void 0 ? void 0 : content.map((card, idx) => {
    const {
      customUrl,
      description,
      iconName,
      title,
      type
    } = card;
    return __jsx(MediaCard, {
      testId: "media-card-content",
      key: `${title}-${idx}`,
      title: title,
      url: customUrl,
      description: description,
      iconName: iconName,
      type: type,
      documents: documents,
      userState: userState,
      isPastOffering: isPastOffering
    });
  }))));
};
export default MediaCardsSection;