import { isApiResponseError } from '@yieldstreet/platform-kit';
import { useEffect } from 'react';
export const useApiFormErrors = (_ref, form) => {
  let {
    error
  } = _ref;
  useEffect(() => {
    if (error && isApiResponseError(error)) {
      var _error$response;
      const payload = (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.data;
      if (payload !== null && payload !== void 0 && payload.errors && form.current) {
        form.current.setErrors(payload === null || payload === void 0 ? void 0 : payload.errors);
      }
    }
  }, [error, form]);
};