import styled from 'styled-components';
import Stepper from '@material-ui/core/Stepper';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import StepConnector from '@material-ui/core/StepConnector';
export const CustomConnector = styled(StepConnector).withConfig({
  displayName: "ProgressBarV2style__CustomConnector",
  componentId: "sc-9mlcnk-0"
})([".MuiStepConnector-lineVertical{border:1px solid;width:1px;}.MuiStepConnector-line{border-color:", ";border-top-width:3px;border-radius:1px;margin-top:-2px;&.MuiStepConnector-lineVertical{border-radius:0px;}}&.MuiStepConnector-active .MuiStepConnector-line{border-color:", ";}&.MuiStepConnector-completed .MuiStepConnector-line{border-color:", ";}"], _ref => {
  let {
    theme
  } = _ref;
  return theme.colors.border_default;
}, _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.colors.progress_bar_primary;
}, _ref3 => {
  let {
    theme
  } = _ref3;
  return theme.colors.progress_bar_primary;
});
export const StepIconContainer = styled.div.withConfig({
  displayName: "ProgressBarV2style__StepIconContainer",
  componentId: "sc-9mlcnk-1"
})(["display:flex;align-items:center;width:24px;height:24px;"]);
export const CustomStepLabel = styled(StepLabel).withConfig({
  displayName: "ProgressBarV2style__CustomStepLabel",
  componentId: "sc-9mlcnk-2"
})([".MuiStepLabel-labelContainer > .MuiStepLabel-alternativeLabel{margin-top:", ";}&.MuiStepLabel-vertical{max-width:unset;margin-left:1px;.MuiStepLabel-labelContainer{margin-left:", ";}}max-width:180px;margin:auto;"], _ref4 => {
  let {
    theme
  } = _ref4;
  return theme.spacing.m;
}, _ref5 => {
  let {
    theme
  } = _ref5;
  return theme.spacing.sm;
});
export const CustomStepper = styled(Stepper).withConfig({
  displayName: "ProgressBarV2style__CustomStepper",
  componentId: "sc-9mlcnk-3"
})(["background:transparent !important;padding:0 !important;"]);
export const CustomStepContent = styled(StepContent).withConfig({
  displayName: "ProgressBarV2style__CustomStepContent",
  componentId: "sc-9mlcnk-4"
})(["&.MuiStepContent-root{", " padding-left:calc(20px + ", ");}.MuiCollapse-wrapperInner{margin-top:-", ";}"], _ref6 => {
  let {
    completed,
    last,
    theme
  } = _ref6;
  return `
      border-left: ${last ? '0px' : '2px'} solid ${completed ? theme.colors.progress_bar_primary : theme.colors.border_default};
    `;
}, _ref7 => {
  let {
    theme
  } = _ref7;
  return theme.spacing.sm;
}, _ref8 => {
  let {
    theme
  } = _ref8;
  return theme.spacing.xs;
});