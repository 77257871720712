import { useMemo } from 'react';
import isEmpty from "lodash/isEmpty";
import keys from "lodash/keys";
import last from "lodash/last";
import sumBy from "lodash/sumBy";
import { PortfolioStrategyValues, allocationApi } from '@yieldstreet/platform-kit';
export const useAllocation = _ref => {
  let {
    userId,
    investorAccountId,
    start,
    end,
    managementStrategy = PortfolioStrategyValues.Discretionary,
    config
  } = _ref;
  const {
    data,
    isLoading,
    isFetching,
    isError,
    refetch
  } = allocationApi.useGetAllocationTimelineQuery({
    userId,
    investorAccountId,
    start,
    end,
    managementStrategy
  }, config);
  const {
    isAllocationHistoryEmpty,
    isLoaded
  } = useMemo(() => ({
    isAllocationHistoryEmpty: isEmpty(data === null || data === void 0 ? void 0 : data.currentAllocationHistory),
    isLoaded: !isError && !isFetching
  }), [data, isError, isFetching]);
  const allocationTimelineTotal = useMemo(() => {
    const key = last(keys(data === null || data === void 0 ? void 0 : data.currentAllocationHistory));
    if (!isAllocationHistoryEmpty && key) {
      return {
        value: sumBy(data === null || data === void 0 ? void 0 : data.currentAllocationHistory[key], 'value') || 0,
        percentage: sumBy(data === null || data === void 0 ? void 0 : data.currentAllocationHistory[key], 'percentage') || 0
      };
    }
    return undefined;
  }, [data, isAllocationHistoryEmpty]);
  return {
    allocationTimeline: data === null || data === void 0 ? void 0 : data.currentAllocationHistory,
    allocationTimelineTotal: allocationTimelineTotal,
    isLoading,
    isLoaded,
    isFetching,
    isError,
    isEmpty: isAllocationHistoryEmpty,
    refetch
  };
};