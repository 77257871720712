import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["id", "label", "heading", "align", "image", "content", "hideMediaOnMobile", "inverse", "button", "background", "reverse", "largeWidth", "mediaFramed", "stats"];
var __jsx = React.createElement;
import React from 'react';
import { TextBlock } from '../../sections/text-block-component';
import { useTestIds } from './MarketingHeroBanner.model';
import { Background, ImageContainer, SContainer, TextBlockContainer } from './MarketingHeroBanner.style';
export const MarketingHeroBanner = _ref => {
  let {
      id,
      label,
      heading,
      align = 'start',
      image,
      content,
      hideMediaOnMobile,
      inverse,
      button,
      background,
      reverse,
      largeWidth,
      mediaFramed,
      stats
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  const testIds = useTestIds(props);
  return __jsx(Background, _extends({
    background: background,
    inverse: inverse
  }, testIds.base.attr), __jsx(SContainer, {
    reverse: reverse,
    image: image,
    mediaFramed: mediaFramed
  }, __jsx(TextBlockContainer, {
    largeWidth: largeWidth
  }, __jsx(TextBlock, {
    id: id,
    headingType: 1,
    label: label,
    heading: heading,
    content: content,
    align: align,
    inverse: !inverse,
    button: button,
    stats: stats
  })), image ? __jsx(ImageContainer, {
    hideMediaOnMobile: hideMediaOnMobile,
    mediaFramed: mediaFramed
  }, image) : null));
};