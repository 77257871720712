import { PORTFOLIO_PATH, PORTFOLIO_SD_PATH } from '../pages/portfolio';
import isString from "lodash/isString";
export const getOfferingUrl = (urlHash, SEOTitle) => `/offering/${urlHash}/${SEOTitle}`;
export const getUrlByFund = (noteUrlHash, fundType, investorAccountId) => {
  if (!noteUrlHash) {
    return '/offerings';
  }
  const baseUrl = !!fundType ? 'investment-details-fund' : 'investment-details';
  return `${PORTFOLIO_PATH}/${PORTFOLIO_SD_PATH}/${baseUrl}/${noteUrlHash}/${investorAccountId}`;
};
export const buildOfferingLink = offering => {
  if (!offering) {
    return '';
  }
  return `/offering/${offering.urlHash}/${encodeURI(offering.SEOTitle)}/`;
};
export const buildRedirectURI = _ref => {
  let {
    path,
    passRedirect,
    redirect
  } = _ref;
  let redirectPath = null;
  if (path && passRedirect) {
    redirectPath = isString(passRedirect) ? passRedirect : redirect;
  }
  return `${path}${redirectPath ? `?redirect=${redirectPath}` : ''}`;
};