import { OfferingStatus } from '../../enums';
import { offeringV5Mock } from '../offering-v5';
export const offeringProgressMock = {
  success: true,
  noteUrlHash: offeringV5Mock.urlHash,
  noteAmount: offeringV5Mock.noteAmount,
  requestedAmount: 10000,
  remainingInvestment: offeringV5Mock.remainingInvestment,
  remainingPercentage: 80,
  fundingProgress: 10,
  offeringStatus: OfferingStatus.open,
  uniqueInvestorCount: 5,
  segmented: false
};