import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["children", "type", "tagType"];
var __jsx = React.createElement;
import React, { useMemo } from 'react';
import { css } from 'styled-components';
import { media } from '../../themes';
import { TitleHeading } from './TitleHeading';
import { TextBaseStyle } from './TextBase';
export const getHeaderStyle = type => {
  switch (type) {
    case 5:
      return H5;
    case 4:
      return H4;
    case 3:
      return H3;
    case 2:
      return H2;
    case 1:
      return H1;
  }
};
const getHeaderTag = tagType => {
  switch (tagType) {
    case 5:
      return 'h5';
    case 4:
      return 'h4';
    case 3:
      return 'h3';
    case 2:
      return 'h2';
    case 1:
      return 'h1';
  }
};
export const Heading = _ref => {
  let {
      children,
      type = 1,
      tagType
    } = _ref,
    otherProps = _objectWithoutProperties(_ref, _excluded);
  const tag = useMemo(() => {
    return getHeaderTag(tagType || type);
  }, [type, tagType]);
  return __jsx(TitleHeading, _extends({
    type: type,
    tagType: tag
  }, otherProps), children);
};
export const HeadingBase = css(["margin:0;", ";font-family:", ";color:", ";"], TextBaseStyle, props => props.theme.typography.primary, props => props.inverse ? props.theme.colors.text_header_inverse : props.theme.colors.text_header);
export const H1 = css(["letter-spacing:var(--h1LetterSpacing,normal);font-weight:var(--h1FontWeight,400);font-size:var(--h1FontSize,38px);line-height:var(--h1LineHeight,46px);", " ", " ", ""], media.tablet`
    font-size: var(--h1FontSize, 46px);
    line-height: var(--h1LineHeight, 54px);
  `, media.desktop`
    font-size: var(--h1FontSize, 52px);
    line-height: var(--h1LineHeight, 62px);
  `, HeadingBase);
export const H2 = css(["letter-spacing:var(--h2LetterSpacing,normal);font-weight:var(--h2FontWeight,400);font-size:var(--h2FontSize,28px);line-height:var(--h2LineHeight,36px);", " ", " ", ""], media.tablet`
    font-size: var(--h2FontSize, 36px);
    line-height: var(--h2LineHeight, 46px);
  `, media.desktop`
    font-size: var(--h2FontSize, 42px);
    line-height: var(--h2LineHeight, 50px);
  `, HeadingBase);
export const H3 = css(["letter-spacing:var(--h3LetterSpacing,normal);font-weight:var(--h3FontWeight,400);font-size:var(--h3FontSize,24px);line-height:var(--h3LineHeight,30px);", " ", " ", ""], media.tablet`
    font-size: var(--h3FontSize, 30px);
    line-height: var(--h3LineHeight, 38px);
  `, media.desktop`
    font-size: var(--h3FontSize, 34px);
    line-height: var(--h3LineHeight, 42px);
  `, HeadingBase);
export const H4 = css(["letter-spacing:var(--h4LetterSpacing,normal);font-weight:var(--h4FontWeight,500);font-size:var(--h4FontSize,20px);line-height:var(--h4LineHeight,28px);", " ", " ", ""], media.tablet`
    font-size: var(--h4FontSize, 24px);
    line-height: var(--h4LineHeight, 32px);
  `, media.desktop`
    font-size: var(--h4FontSize, 24px);
    line-height: var(--h4LineHeight, 32px);
  `, HeadingBase);
export const H5 = css(["letter-spacing:var(--h5LetterSpacing,normal);font-weight:var(--h5FontWeight,500);font-size:var(--h5FontSize,18px);line-height:var(--h5LineHeight,24px);", " ", " ", ""], media.tablet`
    font-size: var(--h5FontSize, 18px);
    line-height: var(--h5LineHeight, 24px);
  `, media.desktop`
    font-size: var(--h5FontSize, 18px);
    line-height: var(--h5LineHeight, 24px);
  `, HeadingBase);