import styled from 'styled-components';
import { SectionContainer } from './../../layout/section-container/SectionContainer';
import { media } from '../../themes/media';
import { ExternalLink, Heading } from '../../content';
import { NAV_HEIGHT } from '../../navigation/header/HeaderViewLoggedOut/HeaderViewLoggedOut.style';
export const Wrapper = styled(SectionContainer).withConfig({
  displayName: "ContentTabsstyle__Wrapper",
  componentId: "sc-dqeq8c-0"
})(["display:flex;flex-direction:column;overflow:visible;padding:0;", ""], media.desktop`
    flex-direction: row-reverse;
    padding: 49px 0;
    overflow: hidden;
  `);
export const ImageWrapper = styled.div.withConfig({
  displayName: "ContentTabsstyle__ImageWrapper",
  componentId: "sc-dqeq8c-1"
})(["background-color:", ";position:sticky;overflow:hidden;top:", ";", " img{display:block;max-width:100%;}"], _ref => {
  let {
    theme
  } = _ref;
  return theme.colors.background_default;
}, () => `${NAV_HEIGHT}px`, media.desktop`
    flex-shrink: 0;
    max-width: 576px;
    position: relative;
    top: 0;
  `);
export const Image = styled.div.withConfig({
  displayName: "ContentTabsstyle__Image",
  componentId: "sc-dqeq8c-2"
})(["background-color:", ";position:", ";top:0;opacity:", ";transition:opacity 0.5s ease-in-out;width:100%;"], _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.colors.background_default;
}, props => props.visible ? 'static' : 'absolute', props => props.visible ? '1' : '0');
export const TabsWrapper = styled.div.withConfig({
  displayName: "ContentTabsstyle__TabsWrapper",
  componentId: "sc-dqeq8c-3"
})(["margin-top:", ";", ""], _ref3 => {
  let {
    theme
  } = _ref3;
  return theme.spacing.m;
}, media.desktop`
    display:flex;
    flex-direction: column;
    margin-right: ${_ref4 => {
  let {
    theme
  } = _ref4;
  return theme.spacing.xl;
}};
    margin-top: 0;
  `);
export const Tab = styled.div.withConfig({
  displayName: "ContentTabsstyle__Tab",
  componentId: "sc-dqeq8c-4"
})(["cursor:pointer;&:hover{background:rgba(223,236,255,0.12);}border-left:solid 4px ", ";padding:", ";user-select:none;text-align:left;"], _ref5 => {
  let {
    theme,
    active
  } = _ref5;
  return active ? theme.colors.border_outline : theme.colors.border_default;
}, _ref6 => {
  let {
    theme
  } = _ref6;
  return `${theme.spacing.m} ${theme.spacing.ml}`;
});
export const IconWrapper = styled.div.withConfig({
  displayName: "ContentTabsstyle__IconWrapper",
  componentId: "sc-dqeq8c-5"
})([""]);
export const Title = styled(Heading).withConfig({
  displayName: "ContentTabsstyle__Title",
  componentId: "sc-dqeq8c-6"
})(["margin:", ";"], _ref7 => {
  let {
    theme
  } = _ref7;
  return `${theme.spacing.sm} 0 ${theme.spacing.xs}`;
});
export const Button = styled(ExternalLink).withConfig({
  displayName: "ContentTabsstyle__Button",
  componentId: "sc-dqeq8c-7"
})(["display:inline-block;margin-top:", ";"], _ref8 => {
  let {
    theme
  } = _ref8;
  return theme.spacing.sm;
});