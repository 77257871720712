import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useState, useEffect } from 'react';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useModalContext, useResponsiveFriendly, Markdown } from '@yieldstreet/ui-kit';
import isEmpty from "lodash/isEmpty";
import OfferingIcon from '../../shared/IconComponent';
import { testIds } from './ParagraphBoxSection.model';
import { Wrapper, InnerWrapper, ContentWrapper, TextWrapper, TitleContainer, Title, SSubTitle, StyledModal, ModalVideoIframe, ImageThumbnail, Image, ModalImage, MapWrapper, MapThumbnail, ModalMapIframe, MediaWrapper, MediaTitle, CarouselWrapper, ModalCarouselWrapper, SCarousel, ModalCarousel, CarouselArrow, ParagraphBody } from './ParagraphBoxSection.style';
const buildVideoUrl = _ref => {
  let {
    mainVideoUrl,
    fullVideoUrl,
    isYoutube,
    optionalUrlParams,
    setVideoUrl
  } = _ref;
  if (optionalUrlParams.length === 1) {
    setVideoUrl(`${fullVideoUrl}?autoplay=0`);
  } else if (isYoutube) {
    setVideoUrl(`${fullVideoUrl}&autoplay=0`);
  } else {
    if (optionalUrlParams[1].match(/t=/gi)) {
      setVideoUrl(`${mainVideoUrl}?autoplay=0#${optionalUrlParams[1]}`);
    } else {
      setVideoUrl(`${fullVideoUrl}?autoplay=0`);
    }
  }
};
export const handleRenderCenterRightControls = _ref2 => {
  let {
    nextSlide
  } = _ref2;
  return __jsx(CarouselArrow, _extends({
    onClick: nextSlide,
    pointRight: true
  }, testIds.carouselNext.attr), __jsx(FontAwesomeIcon, {
    icon: faChevronLeft,
    size: "lg"
  }));
};
export const handleRenderCenterLeftControls = _ref3 => {
  let {
    previousSlide
  } = _ref3;
  return __jsx(CarouselArrow, _extends({
    onClick: previousSlide
  }, testIds.carouselPrev.attr), __jsx(FontAwesomeIcon, {
    icon: faChevronLeft,
    size: "lg"
  }));
};
const ParagraphBoxSection = _ref4 => {
  let {
    section
  } = _ref4;
  const {
      content,
      iconName,
      interactiveMap,
      imageCaption,
      image,
      subtitle,
      title,
      video,
      mediaTitle,
      carousel
    } = section,
    {
      0: showImageModal,
      1: setShowImageModal
    } = useState(false),
    {
      0: showMapModal,
      1: setShowMapModal
    } = useState(false),
    {
      0: videoUrl,
      1: setVideoUrl
    } = useState(''),
    {
      0: imagesToShow,
      1: setImagesToShow
    } = useState(),
    {
      0: captionsToShow,
      1: setCaptionsToShow
    } = useState(),
    {
      0: carouselIndex,
      1: setCarouseIndex
    } = useState(0),
    viewPortSize = useResponsiveFriendly();
  const {
    currentModalId
  } = useModalContext();
  const modalView = !!(currentModalId !== null && currentModalId !== void 0 && currentModalId.length);
  useEffect(() => {
    if (video) {
      const optionalParamChars = /[?#]/gi;
      const optionalUrlParams = video.split(optionalParamChars);
      const mainUrl = optionalUrlParams[0];
      const isYoutube = !!video.match(/youtube/gi);
      buildVideoUrl({
        mainVideoUrl: mainUrl,
        fullVideoUrl: video,
        isYoutube,
        optionalUrlParams,
        setVideoUrl
      });
    }
  }, [video]);
  let fullWidth = false;
  if (!image && !interactiveMap && !video) {
    fullWidth = true;
  }
  return __jsx(Wrapper, null, __jsx(InnerWrapper, null, __jsx(ContentWrapper, null, __jsx(TextWrapper, null, __jsx(TitleContainer, null, iconName && __jsx(OfferingIcon, _extends({
    iconName: iconName
  }, testIds.icon.attr)), title && __jsx(Title, _extends({
    type: 4,
    icon: iconName
  }, testIds.title.attr), __jsx(Markdown, {
    source: title
  }))), subtitle && __jsx(SSubTitle, _extends({
    secondary: true
  }, testIds.subtitle.attr), __jsx(Markdown, {
    source: subtitle
  })), content.map((text, idx) => __jsx(ParagraphBody, _extends({
    key: idx,
    fullWidth: fullWidth
  }, testIds.body.attr), __jsx(Markdown, {
    source: text
  })))), !isEmpty(carousel) && carousel[0].imageUrl && !video && !image && !interactiveMap && __jsx(CarouselWrapper, testIds.carouselWrapper.attr, mediaTitle && __jsx(MediaTitle, {
    semiBold: true
  }, mediaTitle), __jsx(SCarousel, _extends({
    slideIndex: carouselIndex,
    afterSlide: slideIndex => setCarouseIndex(slideIndex),
    wrapAround: true,
    slidesToShow: 1,
    framePadding: "10px",
    cellSpacing: 10,
    cellAlign: "right",
    heightMode: "max",
    frameOverflow: "visible",
    speed: 600,
    renderBottomCenterControls: null,
    renderCenterRightControls: viewPortSize !== 'mobile' ? handleRenderCenterRightControls : null,
    renderCenterLeftControls: viewPortSize !== 'mobile' ? handleRenderCenterLeftControls : null
  }, testIds.carousel.attr), carousel.map(carouselItem => {
    const {
      imageUrl
    } = carouselItem;
    return __jsx(ImageThumbnail, _extends({
      modalView: modalView,
      onClick: !modalView ? () => {
        setShowImageModal(true);
        setImagesToShow(carousel.map(carouselItem => carouselItem.imageUrl));
        setCaptionsToShow(carousel.map(carouselItem => carouselItem.imageCaption));
      } : null
    }, testIds.carouselImageThumbnail.attr), __jsx(Image, {
      src: imageUrl
    }));
  }))), video && !image && !interactiveMap && (isEmpty(carousel) || !carousel[0].imageUrl) && __jsx(ModalVideoIframe, {
    "data-cy": testIds.video.id,
    src: videoUrl,
    frameBorder: "0"
  }), image && !video && !interactiveMap && (isEmpty(carousel) || !carousel[0].imageUrl) && __jsx(MediaWrapper, null, mediaTitle && __jsx(MediaTitle, {
    semiBold: true
  }, mediaTitle), __jsx(ImageThumbnail, _extends({
    modalView: modalView,
    onClick: !modalView ? () => {
      setShowImageModal(true);
      setImagesToShow(image);
      setCaptionsToShow(imageCaption);
    } : null
  }, testIds.imageThumbnail.attr), __jsx(Image, {
    src: image
  }))), interactiveMap && !video && !image && (isEmpty(carousel) || !carousel[0].imageUrl) && __jsx(MediaWrapper, null, mediaTitle && __jsx(MediaTitle, {
    semiBold: true
  }, mediaTitle), __jsx(MapWrapper, _extends({
    onClick: !modalView ? () => setShowMapModal(true) : null
  }, testIds.mapTrigger.attr), __jsx(MapThumbnail, _extends({
    src: interactiveMap
  }, testIds.mapThumbnail.attr)))))), showImageModal && __jsx(StyledModal, {
    caption: typeof captionsToShow === 'string' || captionsToShow.length === 1 ? captionsToShow : captionsToShow[carouselIndex],
    clickClose: () => setShowImageModal(false)
  }, typeof imagesToShow === 'string' || imagesToShow.length === 1 ? __jsx(ModalImage, _extends({
    src: imagesToShow
  }, testIds.imageModal.attr)) : __jsx(ModalCarouselWrapper, null, __jsx(ModalCarousel, _extends({
    slideIndex: carouselIndex,
    afterSlide: slideIndex => setCarouseIndex(slideIndex),
    wrapAround: true,
    slidesToShow: 1,
    framePadding: "10px",
    cellSpacing: 10,
    cellAlign: "right",
    heightMode: "max",
    speed: 600,
    renderCenterRightControls: viewPortSize !== 'mobile' ? handleRenderCenterRightControls : null,
    renderCenterLeftControls: viewPortSize !== 'mobile' ? handleRenderCenterLeftControls : null
  }, testIds.imageModalCarousel.attr), imagesToShow.map(imageToShow => __jsx(ModalImage, _extends({
    src: imageToShow
  }, testIds.imageModal.attr)))))), showMapModal && __jsx(StyledModal, {
    caption: imageCaption,
    clickClose: () => setShowMapModal(false)
  }, __jsx(ModalMapIframe, _extends({
    src: interactiveMap
  }, testIds.mapModal.attr))));
};
export default ParagraphBoxSection;