var __jsx = React.createElement;
import React from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import styled from 'styled-components';
import { media } from '../../themes/media';
import { OfferingCard } from '../../elements/offering-card';
import { Heading } from '../../content/text';
import 'swiper/css';
import 'swiper/css/pagination';
export const OfferingCardSection = _ref => {
  let {
    title,
    titleType = 3,
    className,
    children,
    ViewAllWrapper,
    slidesPerView = 3,
    desktopAlignment = 'center',
    inverse
  } = _ref;
  return __jsx(Wrapper, {
    className: className
  }, title && __jsx(Title, {
    type: titleType,
    desktopAlignment: desktopAlignment,
    inverse: inverse
  }, title), __jsx(SSwiper, {
    spaceBetween: 20,
    breakpoints: {
      768: {
        slidesPerView: 2
      },
      1200: {
        slidesPerView: slidesPerView
      }
    },
    pagination: {
      clickable: true
    },
    centerInsufficientSlides: true,
    modules: [Pagination]
  }, React.Children.map(children, child => {
    return __jsx(SwiperSlide, null, child);
  }), ViewAllWrapper && __jsx(SwiperSlide, null, __jsx(ViewAllWrapper, null, __jsx(OfferingCard, {
    viewAllCard: true
  })))));
};
const Wrapper = styled.div.withConfig({
  displayName: "OfferingCardSection__Wrapper",
  componentId: "sc-1xxtoda-0"
})(["margin:0 auto;max-width:768px;", ""], media.large`
    max-width: 1159px;
  `);
const Title = styled(Heading).withConfig({
  displayName: "OfferingCardSection__Title",
  componentId: "sc-1xxtoda-1"
})(["padding:20px;width:100%;text-align:center;", ""], media.desktop`
    text-align: ${props => props.desktopAlignment};
    padding: ${props => props.desktopAlignment === 'center' ? '20px' : '20px 0'};
    margin: ${props => props.desktopAlignment === 'center' ? '0 auto 40px' : '0 0 40px 0'};
  `);
const SSwiper = styled(Swiper).withConfig({
  displayName: "OfferingCardSection__SSwiper",
  componentId: "sc-1xxtoda-2"
})(["margin:-8px -8px 0 !important;padding:8px 8px 0 !important;.swiper-wrapper{padding-bottom:55px;}.swiper-slide{height:auto;padding-bottom:12px;}.swiper-pagination-bullet{height:15px;width:15px;}.swiper-pagination-bullet-active{background-color:", ";}"], props => props.theme.colors.active);