import { getDatesForRange, DateRanges, useInvestorEntityFlags, useInvestorEntity } from '@yieldstreet/tool-kit';
import { overviewApi } from '@yieldstreet/platform-kit';
import handleNumber from 'sharedComponents/utils/handleNumber';
import { skipToken } from '@reduxjs/toolkit/dist/query';
const {
  useGetPortfolioOverviewQuery
} = overviewApi;
const range = getDatesForRange({
  range: DateRanges.LifeTime
});
export const useAccountBalance = _ref => {
  let {
    userId,
    investorEntityId
  } = _ref;
  const {
    investorEntity
  } = useInvestorEntity(investorEntityId);
  const {
    selfDirectedInvestorAccount
  } = useInvestorEntityFlags(investorEntity);
  const portfolioOverviewArgs = userId && selfDirectedInvestorAccount ? {
    userId,
    investorAccountId: `${selfDirectedInvestorAccount.id}`,
    start: range[0],
    end: range[1]
  } : skipToken;
  const {
    data: portfolioData
  } = useGetPortfolioOverviewQuery(portfolioOverviewArgs);
  const accountBalance = handleNumber(portfolioData === null || portfolioData === void 0 ? void 0 : portfolioData.accountSummary.total);
  return accountBalance;
};