import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useEffect } from 'react';
import { PageLoader, PageSheet } from '@yieldstreet/ui-kit';
import { useUnitApplicationForm } from '@yieldstreet/tool-kit';
import { testIds } from './UnitKYC.model';
import { ExternalIFrame } from './UnitKYC.style';
import { useRouteInvestorEntityId } from '../../../../utils';
import { useKYCNextStep } from '../../utils';
import { useWindowEventListener } from 'utils/hooks';
export const UnitKYC = () => {
  const investorEntityId = useRouteInvestorEntityId();
  const {
    isLoading: isUnitApplicationLoading,
    isReady,
    isDone,
    windowURL,
    onWindowLoad,
    onWindowMessage
  } = useUnitApplicationForm(investorEntityId);
  const {
    isLoading: isNextStepLoading,
    redirectToNextStep
  } = useKYCNextStep(!isDone);
  useEffect(() => {
    if (isDone) {
      redirectToNextStep();
    }
  }, [isDone, redirectToNextStep]);
  const isLoading = isUnitApplicationLoading || isNextStepLoading;
  useWindowEventListener('message', onWindowMessage);
  return __jsx(PageSheet, {
    title: "Yieldstreet KYC",
    testId: testIds.base.id
  }, isLoading && __jsx(PageLoader, {
    testId: testIds.loader.id
  }), isReady && __jsx(ExternalIFrame, _extends({
    width: "100%",
    height: "100%",
    src: windowURL,
    title: "Unit KYC",
    allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;",
    allowFullScreen: true,
    visible: !isLoading,
    onLoad: onWindowLoad
  }, testIds.iframe.attr)));
};