import { useEffect } from 'react';
export const useOutsideClick = (ref, setShowing) => {
  const handleClickOutside = event => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowing(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};