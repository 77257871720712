import { Heading, LinkStyles, Container } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
export const Inner = styled(Container).withConfig({
  displayName: "ContainerSectionstyle__Inner",
  componentId: "sc-aw6sz4-0"
})(["padding-top:", ";padding-bottom:", ";"], props => props.theme.spacing['2xl'], props => props.theme.spacing['2xl']);
export const Wrapper = styled.div.withConfig({
  displayName: "ContainerSectionstyle__Wrapper",
  componentId: "sc-aw6sz4-1"
})(["& ", "{border-bottom:1px solid ", ";}&:first-child{& ", "{padding-top:", ";}}&:first-child,&:last-child{& ", "{border-bottom:none;}}"], Inner, props => props.theme.colors.border_default, Inner, props => props.theme.spacing.xl, Inner);
export const HeadingWrapper = styled.div.withConfig({
  displayName: "ContainerSectionstyle__HeadingWrapper",
  componentId: "sc-aw6sz4-2"
})(["&:not(:last-child){margin-bottom:", ";}"], props => props.theme.spacing.m);
export const SubTitle = styled(Heading).withConfig({
  displayName: "ContainerSectionstyle__SubTitle",
  componentId: "sc-aw6sz4-3"
})(["display:", ";&:not(:first-child){margin-top:", ";}a{", ";}"], props => (props === null || props === void 0 ? void 0 : props.hideSubtitle) && `none`, props => props.theme.spacing.sm, LinkStyles);
export const SectionsWrapper = styled.div.withConfig({
  displayName: "ContainerSectionstyle__SectionsWrapper",
  componentId: "sc-aw6sz4-4"
})([""]);