import { CommitmentStatus, InvestmentStatusTypes, InvestmentTypes, LiquidationStatusTypes, ReinvestmentDistributionStatusTypes } from '@yieldstreet/platform-kit';
import { PerformanceStatusTypes } from '../../useInvestmentsList.model';
import { PERFORMANCE_STATUS_IN_DEFAULT, PERFORMANCE_STATUS_IN_WATCHLIST } from './getStatusLabelByInvestmentType.model';

/**
 * Returns label when investmentType is INVESTMENT.
 */
export const getInvestmentStatus = (investmentStatus, performanceStatus) => {
  switch (investmentStatus) {
    case InvestmentStatusTypes.Pending:
      return PerformanceStatusTypes.Pending;
    case InvestmentStatusTypes.Confirmed:
      return getActiveLabel(performanceStatus);
    default:
      return '-';
  }
};
export const getCommitmentStatus = (commitmentStatus, performanceStatus) => {
  switch (commitmentStatus) {
    case CommitmentStatus.Pending:
      return PerformanceStatusTypes.Pending;
    case CommitmentStatus.Active:
      return getActiveLabel(performanceStatus);
    default:
      return '-';
  }
};
/**
 * Returns label when investmentType is LIQUIDATION.
 */
const getInvestmentLiquidationStatus = investmentStatus => {
  switch (investmentStatus) {
    case LiquidationStatusTypes.Created:
    case LiquidationStatusTypes.Processing:
      return PerformanceStatusTypes.Pending;
    case LiquidationStatusTypes.Completed:
      return PerformanceStatusTypes.Active;
    default:
      return '-';
  }
};

/**
 * Returns label when investmentType is REINVESTED_DISTRIBUTION.
 */
const getReinvestDistributionStatus = (investmentStatus, performanceStatus) => {
  switch (investmentStatus) {
    case ReinvestmentDistributionStatusTypes.Pending:
      return PerformanceStatusTypes.Pending;
    case ReinvestmentDistributionStatusTypes.Cleared:
      return getActiveLabel(performanceStatus);
    default:
      return '-';
  }
};

/**
 * Handles case when INVESTMENT & REINVESTED_DISTRIBUTION are in cleared/confirmed states.
 */
const getActiveLabel = performanceStatus => {
  const isDefault = PERFORMANCE_STATUS_IN_DEFAULT.includes(performanceStatus);
  const isWatchlist = PERFORMANCE_STATUS_IN_WATCHLIST.includes(performanceStatus);
  if (isWatchlist) {
    return PerformanceStatusTypes.Watchlist;
  }
  if (isDefault) {
    return PerformanceStatusTypes.Default;
  }
  return PerformanceStatusTypes.Active;
};
export const getStatusLabelByInvestmentType = _ref => {
  let {
    investmentStatus,
    commitmentStatus,
    investmentType,
    performanceStatus
  } = _ref;
  switch (investmentType) {
    case InvestmentTypes.Commitment:
      return getCommitmentStatus(commitmentStatus, performanceStatus);
    case InvestmentTypes.Investment:
      return getInvestmentStatus(investmentStatus, performanceStatus);
    case InvestmentTypes.Liquidation:
      return getInvestmentLiquidationStatus(investmentStatus);
    case InvestmentTypes.ReinvestDistribution:
      return getReinvestDistributionStatus(investmentStatus, performanceStatus);
    default:
      return '-';
  }
};