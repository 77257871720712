import { InvestorType, PortfolioStrategyValues } from '@yieldstreet/platform-kit';
import { AppAction, appActionManager, eventManager } from '@yieldstreet/tool-kit';
import { INVESTOR_ENTITY_MANAGEMENT_PATH } from 'pages/investorEntity';
import { INVESTOR_ENTITY_ID_PARAM, MANAGED_PORTFOLIO_DEPOSIT_PATH, MANAGED_PORTFOLIO_FUND_FULL_PATH, MANAGED_PORTFOLIO_SELECTION_PATH } from 'pages/managedPortfolio/ManagedPortfolio.model';
import { getManagedPortfolioPathRoot } from 'pages/managedPortfolioQuiz/ManagedPortfolioQuiz.model';
import { CREATE_ACCOUNT_PATH, INELIGIBLE_STEP } from 'pages/newCreateInvestorAccount/CreateInvestorAccount.model';
import { ACCOUNTS_MANAGEMENT_BANK_ACCOUNTS_FLOW } from 'pages/investorEntity/screens/Management/routes/BankAccounts/BankAccounts.model';
import ENV_CONFIG from 'utils/env/config';
import { setSegmentTrack } from 'utils/thirdPartyCode';
import { MANAGEMENT_STRATEGY_PARAM } from './pages/investorEntity/utils/route.model';
import { PORTFOLIO_MD_TRANSACTIONS_PATH } from 'pages/portfolio';
import { datadogRum } from '@datadog/browser-rum';
import { BANK_FLOW_LINK_A_BANK_ACCOUNT } from 'pages/bankLinking/BankLinkingPageFlow.model';
import { KYC_MIGRATION_MODAL } from 'pages/investorEntity/screens/KYC/migration/modal/KYCMigrationModal.model';
import { MANAGED_PORTFOLIO_COMPLETION_PATH } from './pages/managedPortfolioCompletion';
export const initEventManager = _ref => {
  let {
    browserResult
  } = _ref;
  eventManager.init((event, properties) => {
    setSegmentTrack({
      event,
      properties
    });
    datadogRum.addAction(event, properties);
  }, {
    app: 'web',
    mobile: !!browserResult.mobile
  }, ENV_CONFIG.DEBUG);
};
export const initAppActionManager = _ref2 => {
  let {
    history
  } = _ref2;
  appActionManager.init({
    [AppAction.COMPLETE_SETUP]: (_, _ref3) => {
      let {
        history
      } = _ref3;
      history.push(`${INVESTOR_ENTITY_MANAGEMENT_PATH}`);
    },
    [AppAction.SETUP_PORTFOLIO]: (_, _ref4) => {
      let {
        history
      } = _ref4;
      history.push(`${INVESTOR_ENTITY_MANAGEMENT_PATH}`);
    },
    [AppAction.CREATE_INVESTOR_ENTITY]: (_ref5, _ref6) => {
      let {
        managementStrategy = PortfolioStrategyValues.Discretionary
      } = _ref5;
      let {
        history
      } = _ref6;
      history.push(`${CREATE_ACCOUNT_PATH}/?managementStrategy=${managementStrategy}`);
    },
    [AppAction.CREATE_INVESTOR_ACCOUNT]: (_ref7, _ref8) => {
      let {
        investorEntityId,
        investorEntityType,
        managementStrategy = PortfolioStrategyValues.Discretionary
      } = _ref7;
      let {
        history
      } = _ref8;
      history.push(`${CREATE_ACCOUNT_PATH}/${investorEntityType}/${managementStrategy}/${investorEntityId}`);
    },
    [AppAction.START_KYC]: (_ref9, _ref10) => {
      let {
        investorEntityId,
        managementStrategy = PortfolioStrategyValues.Discretionary
      } = _ref9;
      let {
        history
      } = _ref10;
      history.push(`/investor/kyc/${investorEntityId}/?managementStrategy=${managementStrategy}`);
    },
    [AppAction.START_KYC_ETC]: (_ref11, _ref12) => {
      let {
        investorEntityId
      } = _ref11;
      let {
        history
      } = _ref12;
      history.push(`/investor/kyc/${investorEntityId}/provider/etc`);
    },
    [AppAction.SUBMIT_EXTRA_KYC_INFO]: (_ref13, _ref14) => {
      let {
        investorEntityId,
        managementStrategy = PortfolioStrategyValues.Discretionary
      } = _ref13;
      let {
        history
      } = _ref14;
      history.push(`${CREATE_ACCOUNT_PATH}/${InvestorType.INDIVIDUAL}/${managementStrategy}/${investorEntityId}`);
    },
    [AppAction.SUBMIT_THIRD_PARTY_KYC]: (_ref15, _ref16) => {
      let {
        investorEntityId
      } = _ref15;
      let {
        history
      } = _ref16;
      history.push(MANAGED_PORTFOLIO_COMPLETION_PATH.replace(INVESTOR_ENTITY_ID_PARAM, investorEntityId));
    },
    [AppAction.LINK_BANK_ACCOUNT]: (_ref17, _ref18) => {
      let {
        investorEntityId,
        managementStrategy = PortfolioStrategyValues.Discretionary
      } = _ref17;
      let {
        history
      } = _ref18;
      const redirectURI = BANK_FLOW_LINK_A_BANK_ACCOUNT.replace(INVESTOR_ENTITY_ID_PARAM, investorEntityId).replace(MANAGEMENT_STRATEGY_PARAM, managementStrategy);
      history.push(redirectURI);
    },
    [AppAction.VERIFY_MICRO_DEPOSITS]: (_ref19, _ref20) => {
      let {
        investorEntityId
      } = _ref19;
      let {
        history
      } = _ref20;
      const redirectURI = ACCOUNTS_MANAGEMENT_BANK_ACCOUNTS_FLOW.replace(INVESTOR_ENTITY_ID_PARAM, investorEntityId);
      history.push(redirectURI);
    },
    [AppAction.SUBMIT_MANAGED_PORTFOLIO_ANSWERS]: (_ref21, _ref22) => {
      let {
        investorEntityId,
        step
      } = _ref21;
      let {
        history
      } = _ref22;
      if (step) {
        history.push(`${getManagedPortfolioPathRoot(investorEntityId)}/step`);
        return;
      }
      history.push(getManagedPortfolioPathRoot(investorEntityId));
    },
    [AppAction.SUBMIT_MANAGED_PORTFOLIO_STRATEGY]: (_ref23, _ref24) => {
      let {
        investorEntityId
      } = _ref23;
      let {
        history
      } = _ref24;
      const redirectURI = MANAGED_PORTFOLIO_SELECTION_PATH.replace(INVESTOR_ENTITY_ID_PARAM, investorEntityId);
      history.push(redirectURI);
    },
    [AppAction.TRANSACTIONS]: (_ref25, _ref26) => {
      let {
        investorEntityId
      } = _ref25;
      let {
        history
      } = _ref26;
      history.push(`${PORTFOLIO_MD_TRANSACTIONS_PATH}/${investorEntityId}`);
    },
    [AppAction.CONTINUE_KYC]: (_ref27, _ref28) => {
      let {
        investorEntityId,
        managementStrategy = PortfolioStrategyValues.Discretionary
      } = _ref27;
      let {
        history
      } = _ref28;
      history.push(`/investor/kyc/${investorEntityId}/${managementStrategy}`);
    },
    [AppAction.CONTINUE_KYC_ETC]: (_ref29, _ref30) => {
      let {
        investorEntityId
      } = _ref29;
      let {
        history
      } = _ref30;
      history.push(`/investor/kyc/${investorEntityId}/provider/etc`);
    },
    [AppAction.UNSUPPORTED_MANAGED_PORTFOLIO]: (_ref31, _ref32) => {
      let {
        investorEntityId
      } = _ref31;
      let {
        history
      } = _ref32;
      history.push(`${CREATE_ACCOUNT_PATH}/${InvestorType.INDIVIDUAL}/${PortfolioStrategyValues.Discretionary}/${investorEntityId}/${INELIGIBLE_STEP}`);
    },
    [AppAction.DEPOSIT_REQUIRED]: (_ref33, _ref34) => {
      let {
        investorEntityId
      } = _ref33;
      let {
        history
      } = _ref34;
      history.push(MANAGED_PORTFOLIO_FUND_FULL_PATH.replace(':investorEntityId', investorEntityId));
    },
    [AppAction.DEPOSIT_RESUBMIT_REQUIRED]: (_ref35, _ref36) => {
      let {
        investorEntityId
      } = _ref35;
      let {
        history
      } = _ref36;
      history.push(MANAGED_PORTFOLIO_DEPOSIT_PATH.replace(':investorEntityId', investorEntityId));
    },
    [AppAction.SUBMIT_ACCREDITATION]: (_ref37, _ref38) => {
      let {
        investorEntityId
      } = _ref37;
      let {
        history
      } = _ref38;
      history.push(`/settings/accreditation/aiq/${investorEntityId}`);
    },
    [AppAction.EDIT_ACCREDITATION]: (_ref39, _ref40) => {
      let {
        investorEntityId,
        accreditationId
      } = _ref39;
      let {
        history
      } = _ref40;
      history.push(`/settings/accreditation/aiq/income/${investorEntityId}/edit/select/${accreditationId}`);
    },
    [AppAction.EDIT_ACCREDITATION_THIRD_PARTY]: (_ref41, _ref42) => {
      let {
        investorEntityId,
        accreditationId
      } = _ref41;
      let {
        history
      } = _ref42;
      history.push(`/settings/accreditation/aiq/thirdparty/${investorEntityId}/info/${accreditationId}`);
    },
    [AppAction.EDIT_ACCREDITATION_NET_WORTH]: (_ref43, _ref44) => {
      let {
        investorEntityId,
        accreditationId
      } = _ref43;
      let {
        history
      } = _ref44;
      history.push(`/settings/accreditation/aiq/networth/${investorEntityId}/info/${accreditationId}`);
    },
    [AppAction.EDIT_ACCREDITATION_LICENSED_BROKER]: (_ref45, _ref46) => {
      let {
        investorEntityId,
        accreditationId
      } = _ref45;
      let {
        history
      } = _ref46;
      history.push(`/settings/accreditation/aiq/license/${investorEntityId}/${accreditationId}`);
    },
    [AppAction.UPDATE_ACCREDITATION_DOCUMENTATION]: (_ref47, _ref48) => {
      let {
        investorEntityId,
        accreditationId
      } = _ref47;
      let {
        history
      } = _ref48;
      history.push(`/settings/accreditation/aiq/income/${investorEntityId}/edit/select/${accreditationId}`);
    },
    [AppAction.UPDATE_ACCREDITATION_DOCUMENTATION_THIRD_PARTY]: (_ref49, _ref50) => {
      let {
        investorEntityId,
        accreditationId
      } = _ref49;
      let {
        history
      } = _ref50;
      history.push(`/settings/accreditation/aiq/thirdparty/${investorEntityId}/info/${accreditationId}`);
    },
    [AppAction.UPDATE_ACCREDITATION_DOCUMENTATION_NET_WORTH]: (_ref51, _ref52) => {
      let {
        investorEntityId,
        accreditationId
      } = _ref51;
      let {
        history
      } = _ref52;
      history.push(`/settings/accreditation/aiq/networth/${investorEntityId}/info/${accreditationId}`);
    },
    [AppAction.UPDATE_ACCREDITATION_DOCUMENTATION_LICENSED_BROKER]: (_ref53, _ref54) => {
      let {
        investorEntityId,
        accreditationId
      } = _ref53;
      let {
        history
      } = _ref54;
      history.push(`/settings/accreditation/aiq/license/${investorEntityId}/${accreditationId}`);
    },
    [AppAction.RENEW_ACCREDITATION]: (_ref55, _ref56) => {
      let {
        investorEntityId
      } = _ref55;
      let {
        history
      } = _ref56;
      history.push(`/settings/accreditation/aiq/${investorEntityId}`);
    },
    [AppAction.RENEW_ACCREDITATION_INCOME]: (_ref57, _ref58) => {
      let {
        investorEntityId
      } = _ref57;
      let {
        history
      } = _ref58;
      history.push(`/settings/accreditation/aiq/income/${investorEntityId}/renewal`);
    },
    [AppAction.REACTIVATE_ACCOUNT]: (_, _ref59) => {
      let {
        history
      } = _ref59;
      history.push({
        hash: `#${KYC_MIGRATION_MODAL}`
      });
    }
  }, {
    history
  });
};