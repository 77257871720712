import _Button from "@mui/material/Button";
import _Stack from "@mui/material/Stack";
import _Box from "@mui/material/Box";
import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["date", "title", "description", "button", "buttonCTA", "onCTAClick", "expandable", "buttonLoading", "buttonDisabled", "startOpen", "children", "error", "lastRow", "forcedOpen"];
var __jsx = React.createElement;
import React, { useState } from 'react';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoadingButton from '@mui/lab/LoadingButton';
import { MuiMarkdown } from 'mui-markdown';
import checkboxError from '../assets/checkbox_error.svg';
import { useTestIds, getTestIds } from './ProgressTableRow.model';
import { Row, Desktop, Mobile, LeftElement, ErrorIcon, Description, DesktopSubTitle, IconHolder, AnimatedContent, MobileSubTitle } from './ProgressTableRow.style';
export const ProgressTableRow = _ref => {
  let {
      date,
      title,
      description,
      button = false,
      buttonCTA,
      onCTAClick,
      expandable,
      buttonLoading,
      buttonDisabled = false,
      startOpen,
      children,
      error = false,
      lastRow,
      forcedOpen
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  const {
    0: show,
    1: setShow
  } = useState(startOpen || false);
  const testIds = useTestIds(props);
  const handleShow = () => {
    if (forcedOpen || show) return;
    setShow(true);
  };
  const handleArrowClick = () => {
    if (forcedOpen) return;
    setShow(!show);
  };
  return __jsx(Row, _extends({
    lastRow: lastRow,
    onClick: handleShow
  }, testIds.base.attr), __jsx(Desktop, testIds.desktopContainer.attr, __jsx(_Stack, {
    direction: "row"
  }, __jsx(LeftElement, {
    direction: "row"
  }, error && __jsx(ErrorIcon, _extends({
    src: checkboxError
  }, testIds.errorIcon.attr))), __jsx(_Box, null, __jsx(_Typography, _extends({
    variant: "body1",
    fontWeight: "medium"
  }, testIds.title.attr), title), description && __jsx(DesktopSubTitle, _extends({
    variant: "body1",
    color: "textSecondary"
  }, testIds.subtitle.attr), __jsx(MuiMarkdown, null, description)), !!expandable && children && __jsx(AnimatedContent, {
    show: show
  }, __jsx(Description, testIds.content.attr, children)), forcedOpen && __jsx(AnimatedContent, {
    show: show
  }, __jsx(Description, testIds.content.attr, children)))), button && __jsx(_Box, null, __jsx(LoadingButton, _extends({}, testIds.cta.attr, {
    sx: {
      mt: 5
    },
    onClick: onCTAClick,
    loading: buttonLoading,
    disabled: buttonDisabled
  }), buttonCTA)), expandable && __jsx(IconHolder, {
    onClick: handleArrowClick
  }, show && __jsx(ExpandLessIcon, testIds.expandableIconUp.attr), !show && __jsx(ExpandMoreIcon, testIds.expandableIconDown.attr))), __jsx(Mobile, testIds.mobileContainer.attr, __jsx(_Stack, null, __jsx(_Box, null, __jsx(_Typography, _extends({
    variant: "body1",
    fontWeight: "medium"
  }, testIds.title.attr), title), __jsx(AnimatedContent, {
    show: show
  }, date && __jsx(_Typography, {
    sx: {
      mt: 2
    },
    variant: "body2",
    color: "textSecondary"
  }, `Updated on ${date}`), description && __jsx(MobileSubTitle, _extends({
    color: "textSecondary"
  }, testIds.subtitle.attr), __jsx(MuiMarkdown, null, description)), children && __jsx(Description, _extends({
    variant: "body1",
    fontWeight: "medium"
  }, testIds.content.attr), children), button && __jsx(_Box, null, __jsx(_Button, _extends({}, testIds.cta.attr, {
    sx: {
      mt: 6
    },
    onClick: onCTAClick
  }), buttonCTA))))), __jsx(IconHolder, {
    error: error,
    onClick: handleArrowClick
  }, error && __jsx(ErrorIcon, _extends({
    src: checkboxError
  }, testIds.errorIcon.attr)), show && __jsx(ExpandLessIcon, testIds.expandableIconUp.attr), !show && __jsx(ExpandMoreIcon, testIds.expandableIconDown.attr))));
};
ProgressTableRow.getTestIds = getTestIds;