export const portfolioPaymentActivity = {
  userId: 8697,
  activity: [{
    date: '2017-04-30',
    interest: 21.77,
    netInterest: 21.77,
    netIncome: 21.77,
    fexp: 0,
    totalWithoutFexp: 21.77,
    totalNetFexpNetKnownCapital: 21.77,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 21.77,
    // @ts-ignore
    investmentsPerGraphItem: []
  }, {
    date: '2017-06-30',
    interest: 193.52,
    netInterest: 193.52,
    netIncome: 193.52,
    fexp: 0,
    totalWithoutFexp: 193.52,
    totalNetFexpNetKnownCapital: 193.52,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 193.52,
    // @ts-ignore
    investmentsPerGraphItem: []
  }],
  total: {
    interest: 57994.84,
    netInterest: 55734.84,
    netIncome: 55734.84,
    fexp: 2260.0,
    totalWithoutFexp: 55734.84,
    totalNetFexpNetKnownCapital: 55734.84,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 57994.84
  }
};