import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { bankAccountApi } from '@yieldstreet/platform-kit';
import { useApiErrorMessage } from '@yieldstreet/tool-kit';
import { Button, Heading, NewModal, Notification, Paragraph, useModalContext } from '@yieldstreet/ui-kit';
import { DELETE_BANK_ACCOUNT } from 'constants/modal.const';
import { testIds } from './DeleteBankAccountModal.model';
import { ButtonWrapper, Content, Wrapper } from '../EditBankNickname/EditBankNicknameModel.style';
const {
  useRemoveBankAccountMutation
} = bankAccountApi;
export const DeleteBankAccountModal = () => {
  const {
    hideModal,
    passProps
  } = useModalContext();
  // ModelContext wont be typed for now
  const {
    bankAccount
  } = passProps;
  const [removeBankAccount, removeBankState] = useRemoveBankAccountMutation();
  const errorMessage = useApiErrorMessage(removeBankState, 'Account removal failed');
  const deleteBankAccount = async () => {
    var _update$data;
    const update = await removeBankAccount({
      bankAccountId: bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.id
    });
    if (!('error' in update) && (_update$data = update.data) !== null && _update$data !== void 0 && _update$data.success) {
      hideModal();
    }
  };
  return __jsx(NewModal, {
    modalId: DELETE_BANK_ACCOUNT
  }, __jsx(Wrapper, null, __jsx(Content, null, __jsx(Heading, {
    type: 3
  }, "Confirm account removal"), removeBankState.isError && __jsx(Notification, {
    severity: "error",
    inline: true,
    title: errorMessage,
    description: "Couldn\u2019t remove this bank account. It is related to one or more investments.",
    testId: testIds.notification.id
  }), __jsx(Paragraph, null, "Are you sure you want to remove ", bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.nickname, " from Yieldstreet? This action cannot be undone."), __jsx(ButtonWrapper, null, __jsx(Button, _extends({}, testIds.cancelButton.attr, {
    buttonType: "secondary",
    trackingId: "transfer-modal-delete-account-cancel",
    onClick: () => hideModal(),
    className: "cancelButton"
  }), "Cancel"), __jsx(Button, _extends({}, testIds.confirmButton.attr, {
    disabled: removeBankState.isLoading,
    loading: removeBankState.isLoading,
    trackingId: "transfer-modal-delete-account-confirm",
    className: "confirmButton",
    onClick: deleteBankAccount
  }), "Yes, delete account")))));
};