import { useCallback } from 'react';
import { parse } from 'query-string';
import { useLocation, useHistory } from 'react-router-dom';
import ENV_CONFIG from 'utils/env/config';
import { DEFAULT_ROUTE } from './useAuth.model';
import { buildRedirectURI } from '../urlBuilder';
import { isE2E } from 'utils/env/const';
export const useAuthRedirect = () => {
  const history = useHistory();
  const location = useLocation();
  return useCallback(function () {
    let path = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : DEFAULT_ROUTE;
    let passRedirect = arguments.length > 1 ? arguments[1] : undefined;
    const {
      redirect
    } = parse(location === null || location === void 0 ? void 0 : location.search);
    const url = buildRedirectURI({
      path,
      passRedirect,
      redirect
    });
    if (window && window.parent && window.self !== window.top && !isE2E) {
      window.parent.postMessage({
        action: 'redirect',
        url
      }, `https://${ENV_CONFIG.HOST}`);
    } else {
      history.push(url);
    }
  }, [history, location.search]);
};