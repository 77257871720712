import { useMemo } from 'react';
import isEmpty from "lodash/isEmpty";
import { ActionBadgeTypes, InvestmentActionTypes, testIds } from './useInvestmentAction.model';
export const getInvestmentAction = investment => {
  if (investment !== null && investment !== void 0 && investment.requiresUserAction) {
    return {
      badgeType: ActionBadgeTypes.Error,
      badgeLabel: 'Action required',
      badgeTestId: investment === null || investment === void 0 ? void 0 : investment.navigation.urls.tracking,
      investmentActionType: InvestmentActionTypes.ActionRequired,
      url: investment === null || investment === void 0 ? void 0 : investment.navigation.urls.tracking,
      wrapperTestId: testIds.actionRequired.id,
      wrapperTestAttr: testIds.actionRequired.attr
    };
  }
  if (investment !== null && investment !== void 0 && investment.allowRollover) {
    return {
      badgeType: ActionBadgeTypes.Default,
      badgeLabel: 'Rollover available',
      badgeTestId: investment === null || investment === void 0 ? void 0 : investment.navigation.urls.rollover,
      investmentActionType: InvestmentActionTypes.Rollover,
      url: investment === null || investment === void 0 ? void 0 : investment.navigation.urls.rollover,
      wrapperTestId: testIds.rollover.id,
      wrapperTestAttr: testIds.rollover.attr
    };
  }
  if (!isEmpty(investment === null || investment === void 0 ? void 0 : investment.rolloversScheduled)) {
    return {
      badgeType: ActionBadgeTypes.Info,
      badgeLabel: 'Rollover scheduled',
      badgeTestId: investment === null || investment === void 0 ? void 0 : investment.navigation.urls.details,
      investmentActionType: InvestmentActionTypes.RolloversScheduled,
      url: investment === null || investment === void 0 ? void 0 : investment.navigation.urls.details,
      wrapperTestId: testIds.rolloversScheduled.id,
      wrapperTestAttr: testIds.rolloversScheduled.attr
    };
  }
  return {
    badgeType: ActionBadgeTypes.Default,
    badgeLabel: null,
    badgeTestId: null,
    investmentActionType: InvestmentActionTypes.Details,
    url: investment === null || investment === void 0 ? void 0 : investment.navigation.urls.details,
    wrapperTestId: testIds.details.id,
    wrapperTestAttr: testIds.details.attr
  };
};
export const useInvestmentAction = investment => useMemo(() => getInvestmentAction(investment), [investment]);