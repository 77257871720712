var __jsx = React.createElement;
import React, { useEffect, useMemo } from 'react';
import { FormSelectInput, CheckboxInput, Tooltip, Notification, Paragraph, ExternalLink } from '@yieldstreet/ui-kit';
import { AdviceQuestionsConfig } from 'utils/advice';
import { CheckboxContainer, TooltipWrapper, NotificationWrapper, NotificationContent } from './EligibilityFields.style';
import { FieldSectionWrapper, FieldSectionHeader } from './Fields.style';
const {
  NetWorth,
  QualifiedPurchaser: QP,
  QualifiedClient: QC
} = AdviceQuestionsConfig;
const getNetWorthConditions = _ref => {
  var _answersData$findLast, _answersData$findLast2;
  let {
    formValues,
    answersData
  } = _ref;
  const netWorth = formValues[NetWorth.key];
  return {
    qualifiedPurchaser: {
      enabled: netWorth === NetWorth.values._5M_PLUS,
      notEditable: !(answersData !== null && answersData !== void 0 && (_answersData$findLast = answersData.findLast(item => (item === null || item === void 0 ? void 0 : item.questionKey) === QP.key)) !== null && _answersData$findLast !== void 0 && _answersData$findLast.editable)
    },
    qualifiedClient: {
      enabled: netWorth === NetWorth.values._1M_5M || netWorth === NetWorth.values._5M_PLUS,
      notEditable: !(answersData !== null && answersData !== void 0 && (_answersData$findLast2 = answersData.findLast(item => (item === null || item === void 0 ? void 0 : item.questionKey) === QC.key)) !== null && _answersData$findLast2 !== void 0 && _answersData$findLast2.editable)
    }
  };
};
export const EligibilityFields = _ref2 => {
  let {
    formikProps,
    answersData
  } = _ref2;
  const {
    setFieldValue,
    values: formValues
  } = formikProps;
  const {
    qualifiedClient,
    qualifiedPurchaser
  } = getNetWorthConditions({
    formValues,
    answersData
  });
  const getRestrictedFieldCopy = useMemo(() => {
    if (qualifiedPurchaser.notEditable && qualifiedClient.notEditable) return 'qualified purchaser and a qualified client';
    if (qualifiedPurchaser.notEditable) return 'qualified purchaser';
    if (qualifiedClient.notEditable) return 'qualified client';
  }, [qualifiedClient.notEditable, qualifiedPurchaser.notEditable]);
  const shouldWarnRestriction = useMemo(() =>
  /**
   * Shows warning only when respective field is enabled and is not editable
   */
  (answersData === null || answersData === void 0 ? void 0 : answersData.length) && (qualifiedClient.notEditable || qualifiedPurchaser.notEditable), [qualifiedClient, qualifiedPurchaser, answersData]);
  const handleCheckboxChange = (field, Values) => checked => {
    const value = checked ? Values.True : Values.False;
    setFieldValue(field, value);
  };
  useEffect(() => {
    /** This effect ensures the field are dynamically set to
     * be "FALSE" when changing them.
     * */
    if (!qualifiedPurchaser.enabled) {
      setFieldValue(QP.key, QP.values.False);
    }
    if (!qualifiedClient.enabled) {
      setFieldValue(QC.key, QC.values.False);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qualifiedClient.enabled, qualifiedPurchaser.enabled]);
  return __jsx(React.Fragment, null, __jsx(FieldSectionHeader, {
    description: "This information helps us recommend investments that you may be eligible to invest in."
  }, "Eligibility"), __jsx(FieldSectionWrapper, null, __jsx(FormSelectInput, {
    name: NetWorth.key,
    label: NetWorth.label,
    options: NetWorth.options,
    formikProps: formikProps
  }), __jsx(CheckboxContainer, null, __jsx(CheckboxInput, {
    name: QP.key,
    label: QP.label,
    onChange: handleCheckboxChange(QP.key, QP.values),
    value: formValues[QP.key] === QP.values.True,
    disabled: qualifiedPurchaser.notEditable || !qualifiedPurchaser.enabled
  }), QP.tooltip && __jsx(TooltipWrapper, null, __jsx(Tooltip, {
    testId: QP.key,
    place: "top",
    tooltipKey: QP.key,
    buttonType: "button"
  }, QP.tooltip))), __jsx(CheckboxContainer, null, __jsx(CheckboxInput, {
    name: QC.key,
    label: QC.label,
    onChange: handleCheckboxChange(QC.key, QC.values),
    value: formValues[QC.key] === QC.values.True,
    disabled: qualifiedClient.notEditable || !qualifiedClient.enabled
  }), QC.tooltip && __jsx(TooltipWrapper, null, __jsx(Tooltip, {
    buttonType: "button",
    place: "top",
    tooltipKey: QC.key,
    testId: QC.key
  }, QC.tooltip)))), shouldWarnRestriction && __jsx(NotificationWrapper, null, __jsx(Notification, {
    title: "Attention",
    severity: "warning",
    noMargin: true,
    description: __jsx(NotificationContent, null, __jsx(Paragraph, null, "Since you recently indicated you are not a ", getRestrictedFieldCopy, ", please contact", ' ', __jsx(ExternalLink, {
      id: "restriction-notification",
      href: "mailto:investments@yieldstreet.com"
    }, "Investor Relations"), ' ', "to update your status."))
  })));
};