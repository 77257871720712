var __jsx = React.createElement;
import React from 'react';
import { Paragraph } from '../../content/text';
import { Image, PhotoCard, PlusButton, TextContainer } from './MarketingPhotoCard.styles';
export const MarketingPhotoCard = _ref => {
  let {
    small,
    imgUrl,
    title,
    subTitle,
    onClick
  } = _ref;
  return __jsx(PhotoCard, {
    small: small,
    onClick: onClick
  }, __jsx(Image, {
    src: imgUrl,
    small: small
  }), __jsx(TextContainer, {
    small: small
  }, __jsx(Paragraph, {
    semiBold: true
  }, title), __jsx(Paragraph, {
    chart: true
  }, subTitle)), onClick && __jsx(PlusButton, {
    small: small
  }, "+"));
};