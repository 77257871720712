import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { Icons } from './Icon.model';
import { IconWrapper } from './Icon.style';
export const Icon = props => {
  const {
    iconType
  } = props;
  const icon = Icons[iconType];
  return __jsx(IconWrapper, _extends({}, props, {
    icon: icon
  }));
};