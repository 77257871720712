var __jsx = React.createElement;
import React from 'react';
import { FiltersButton } from './PortfolioFiltersButton.style';
export const PortfolioFiltersButton = _ref => {
  let {
    buttonType,
    children,
    disabled,
    isMobile,
    onClick
  } = _ref;
  return __jsx(FiltersButton, {
    buttonType: buttonType,
    disabled: disabled,
    isMobile: isMobile,
    onClick: onClick
  }, children);
};