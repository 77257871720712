import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useCallback, useEffect, useRef, useState } from 'react';
import { pollCheck as pollCheckRaw } from './pollCheck';
export const usePollCheck = (pollRequest, checkCallback, skipAutoCheck, initResult, backOffOptions) => {
  const autoCheck = !skipAutoCheck;
  const initCheck = autoCheck && !!initResult;

  // set initial states based on initial param values
  // this is to avoid always having default state values for first render (avoid flicker)
  // until polling is triggered during the next cycle by the below useEffect
  const {
    0: isCheckSuccess,
    1: setIsCheckSuccess
  } = useState(() => initCheck && checkCallback(initResult));
  const {
    0: isCheckDone,
    1: setIsCheckDone
  } = useState(() => initCheck && isCheckSuccess);
  const {
    0: isChecking,
    1: setIsChecking
  } = useState(() => autoCheck && !isCheckSuccess);
  const {
    0: checkData,
    1: setCheckData
  } = useState(() => initCheck && isCheckSuccess ? initResult : null);

  // reference boolean used as guard in case of unmounted component
  const pollActive = useRef(false);
  const triggerAutoCheck = autoCheck && !isCheckDone;

  // Here we need to reset the backoff.
  const resetPoll = useCallback(() => {
    setIsCheckDone(false);
  }, []);
  const pollCheck = useCallback(async () => {
    // prevent multiple concurrent backoff checks
    if (pollActive.current) {
      return;
    }

    // if initial result available check before start polling
    if (initResult && checkCallback(initResult)) {
      setIsCheckSuccess(true);
      setIsCheckDone(true);
      setCheckData(initResult);
      return;
    }

    // set pending states
    pollActive.current = true;
    setIsChecking(true);

    // start poll check
    const result = await pollCheckRaw(pollRequest, checkCallback, _objectSpread(_objectSpread({}, backOffOptions), {}, {
      // check if poll active before every retry in case component is unmounted
      retry: () => pollActive.current
    }));

    // update states to success if component is mounted
    if (pollActive.current) {
      setIsChecking(false);
      setIsCheckSuccess(!!result);
      setIsCheckDone(true);
      setCheckData(result);
      pollActive.current = false;
    }

    // return last retry result in case of success
    return result;
  }, [backOffOptions, initResult, checkCallback, pollRequest]);
  useEffect(() => {
    if (triggerAutoCheck) {
      pollCheck();
    }
  }, [pollCheck, triggerAutoCheck]);
  useEffect(() => () => {
    // on unmount set poll active guard to false to stop retries
    pollActive.current = false;
  }, []);
  return {
    checkData,
    isCheckDone,
    isCheckFailed: isCheckDone && !isChecking && !isCheckSuccess,
    isChecking: isChecking || triggerAutoCheck,
    isCheckSuccess,
    pollCheck,
    resetPoll
  };
};