import { LegalEntityType, PortfolioStatementProviderType } from '../../../enums';
import { FundType } from '../../../types';
export const portfolioStatementsResponseMock = {
  userId: '4',
  statement: {
    title: 'Monthly Statement',
    subTitle: 'Aug 2022',
    investorAccounts: [{
      investorAccountId: '1',
      name: 'Delpha Towne',
      address: {
        country: 'US',
        state: 'NY',
        city: 'Lake Javier',
        line1: 'Rosetta Forges',
        line2: '',
        zipCode: '20037'
      },
      summary: {
        principalOutstanding: {
          preDefault: 10325.71,
          postDefault: 31699.97,
          total: 42025.68
        },
        interestAccrued: {
          preDefault: 987.22,
          postDefault: 9141.14,
          total: 10128.36
        },
        clearedBalance: 45.93,
        investmentBalance: 189041.06,
        liquidationPaid: 0,
        liquidationDiscount: 0,
        asOfDate: '2022-08-31',
        fundsTotal: 136887.02,
        pendingInvestments: 250,
        totalAccountValue: 189336.99
      },
      breakdown: {
        inDefault: true,
        funds: {
          starting: 136887.02,
          added: 0,
          deducted: 0,
          ending: 136887.02
        },
        principal: {
          starting: 42025.68,
          added: 0,
          deducted: 0,
          ending: 42025.68
        },
        interest: {
          starting: 9796.32,
          added: 332.04,
          deducted: 0,
          ending: 10128.36,
          netPaid: 0,
          fexpPaid: 0
        },
        wallet: {
          starting: 45.93,
          added: 0,
          deducted: 0,
          ending: 45.93
        },
        totals: {
          starting: 188754.95,
          added: 332.04,
          deducted: 0,
          ending: 189086.99
        }
      },
      investments: {
        investments: {
          PENDING: [{
            legalEntityType: LegalEntityType.BPDN,
            fundType: null,
            assetClass: 'SHTN',
            noteUrlHash: '3t8JSg',
            noteTitle: 'AccreditedNon Offering BPDN Cypress I',
            inDefault: false,
            dateOfFirstDefault: null,
            dateInvested: '2022-08-12',
            effectiveMaturityDate: null,
            finalTerm: null,
            irr: 0,
            investment: null,
            fund: null,
            investmentAmount: 2000,
            liquidationPaid: null,
            liquidationDiscount: null
          }, {
            legalEntityType: LegalEntityType.FUND,
            fundType: FundType.InternalFund,
            assetClass: 'ARTF',
            noteUrlHash: 'yQ-XWg',
            noteTitle: 'Art Equity Fund',
            inDefault: false,
            dateOfFirstDefault: null,
            dateInvested: '2022-01-07',
            effectiveMaturityDate: null,
            finalTerm: null,
            irr: 0,
            investment: null,
            fund: {
              sharesStarting: 2500,
              sharesEnding: 2500,
              navStarting: 10,
              navEnding: 10,
              starting: 25000,
              ending: 25000
            },
            investmentAmount: 25000,
            liquidationPaid: null,
            liquidationDiscount: null
          }, {
            legalEntityType: LegalEntityType.BPDN,
            fundType: null,
            assetClass: 'SHTN',
            noteUrlHash: '4uOmDQ',
            noteTitle: 'serafim sy bpdn',
            inDefault: false,
            dateOfFirstDefault: null,
            dateInvested: '2022-04-20',
            effectiveMaturityDate: null,
            finalTerm: null,
            irr: 0,
            investment: null,
            fund: null,
            investmentAmount: 300,
            liquidationPaid: null,
            liquidationDiscount: null
          }],
          ACTIVE: [{
            legalEntityType: LegalEntityType.BPDN,
            fundType: null,
            assetClass: 'TRPT',
            noteUrlHash: 'RUadew',
            noteTitle: 'Vessel Deconstruction VI',
            inDefault: true,
            dateOfFirstDefault: '2020-03-06',
            dateInvested: '2019-09-29',
            effectiveMaturityDate: null,
            finalTerm: null,
            irr: 0,
            investment: {
              principal: {
                starting: 10000,
                added: 0,
                deducted: 0,
                ending: 10000
              },
              interest: {
                starting: 2474.24,
                added: 88.26,
                deducted: 0,
                ending: 2562.5,
                netPaid: 0,
                fexpPaid: 0
              },
              totals: {
                starting: 12474.24,
                added: 88.26,
                deducted: 0,
                ending: 12562.5
              }
            },
            fund: null,
            investmentAmount: 10000,
            liquidationPaid: 0,
            liquidationDiscount: 0
          }, {
            legalEntityType: LegalEntityType.BPDN,
            fundType: null,
            assetClass: 'TRPT',
            noteUrlHash: '5ZJ19Q',
            noteTitle: 'Vessel Deconstruction III',
            inDefault: true,
            dateOfFirstDefault: '2020-03-06',
            dateInvested: '2019-04-22',
            effectiveMaturityDate: null,
            finalTerm: null,
            irr: 0,
            investment: {
              principal: {
                starting: 10000,
                added: 0,
                deducted: 0,
                ending: 10000
              },
              interest: {
                starting: 2639.37,
                added: 88.26,
                deducted: 0,
                ending: 2727.63,
                netPaid: 0,
                fexpPaid: 0
              },
              totals: {
                starting: 12639.37,
                added: 88.26,
                deducted: 0,
                ending: 12727.63
              }
            },
            fund: null,
            investmentAmount: 10000,
            liquidationPaid: 0,
            liquidationDiscount: 0
          }, {
            legalEntityType: LegalEntityType.BPDN,
            fundType: null,
            assetClass: 'ARTF',
            noteUrlHash: 'g9Cy-g',
            noteTitle: 'Diversified Art Portfolio I',
            inDefault: false,
            dateOfFirstDefault: null,
            dateInvested: '2019-11-13',
            effectiveMaturityDate: null,
            finalTerm: null,
            irr: 0,
            investment: {
              principal: {
                starting: 2234.1,
                added: 0,
                deducted: 0,
                ending: 2234.1
              },
              interest: {
                starting: 125.02,
                added: 18.28,
                deducted: 0,
                ending: 143.3,
                netPaid: 0,
                fexpPaid: 0
              },
              totals: {
                starting: 2359.12,
                added: 18.28,
                deducted: 0,
                ending: 2377.4
              }
            },
            fund: null,
            investmentAmount: 10000,
            liquidationPaid: 0,
            liquidationDiscount: 0
          }, {
            legalEntityType: LegalEntityType.BPDN,
            fundType: null,
            assetClass: 'TRPT',
            noteUrlHash: 'sa1oHg',
            noteTitle: 'Short Term Vessel Refinancing',
            inDefault: true,
            dateOfFirstDefault: '2020-03-06',
            dateInvested: '2019-03-26',
            effectiveMaturityDate: null,
            finalTerm: null,
            irr: 0,
            investment: {
              principal: {
                starting: 536.25,
                added: 0,
                deducted: 0,
                ending: 536.25
              },
              interest: {
                starting: 484.6,
                added: 4.74,
                deducted: 0,
                ending: 489.34,
                netPaid: 0,
                fexpPaid: 0
              },
              totals: {
                starting: 1020.85,
                added: 4.74,
                deducted: 0,
                ending: 1025.59
              }
            },
            fund: null,
            investmentAmount: 10000,
            liquidationPaid: 0,
            liquidationDiscount: 0
          }],
          MATURED: []
        }
      },
      transactions: [],
      url: 'http://atomic-gateway:9000/api/atomic-gateway/statement/394655/monthly/4/2024'
    }, {
      investorAccountId: '205137',
      name: 'Hailie Lesch',
      address: {
        country: 'US',
        state: 'NY',
        city: 'Lake Reina',
        line1: 'Price Causeway',
        line2: '',
        zipCode: ''
      },
      summary: {
        principalOutstanding: {
          preDefault: 0,
          postDefault: 0,
          total: 0
        },
        interestAccrued: {
          preDefault: 0,
          postDefault: 0,
          total: 0
        },
        clearedBalance: 534.34,
        investmentBalance: 50000,
        liquidationPaid: 0,
        liquidationDiscount: 0,
        asOfDate: '2022-08-31',
        fundsTotal: 50000,
        pendingInvestments: 0,
        totalAccountValue: 50534.34
      },
      breakdown: {
        inDefault: false,
        funds: {
          starting: 50000,
          added: 0,
          deducted: 0,
          ending: 50000
        },
        principal: {
          starting: 0,
          added: 0,
          deducted: 0,
          ending: 0
        },
        interest: {
          starting: 0,
          added: 0,
          deducted: 0,
          ending: 0,
          netPaid: 0,
          fexpPaid: 0
        },
        wallet: {
          starting: 534.34,
          added: 0,
          deducted: 0,
          ending: 534.34
        },
        totals: {
          starting: 50534.34,
          added: 0,
          deducted: 0,
          ending: 50534.34
        }
      },
      investments: {
        investments: {
          PENDING: [],
          ACTIVE: [{
            legalEntityType: LegalEntityType.FUND,
            fundType: FundType.InternalFund,
            assetClass: 'ARTF',
            noteUrlHash: 'yQ-XWg',
            noteTitle: 'Art Equity Fund',
            inDefault: false,
            dateOfFirstDefault: null,
            dateInvested: '2022-01-07',
            effectiveMaturityDate: null,
            finalTerm: null,
            irr: 0,
            investment: null,
            fund: {
              sharesStarting: 2500,
              sharesEnding: 2500,
              navStarting: 10,
              navEnding: 10,
              starting: 25000,
              ending: 25000
            },
            investmentAmount: 25000,
            liquidationPaid: null,
            liquidationDiscount: null
          }, {
            legalEntityType: LegalEntityType.FUND,
            fundType: FundType.InternalFund,
            assetClass: 'LEGL',
            noteUrlHash: 'OkGumg',
            noteTitle: 'Legal Finance Fund II',
            inDefault: false,
            dateOfFirstDefault: null,
            dateInvested: '2021-06-07',
            effectiveMaturityDate: null,
            finalTerm: null,
            irr: 0,
            investment: null,
            fund: {
              sharesStarting: 2500,
              sharesEnding: 2500,
              navStarting: 10,
              navEnding: 10,
              starting: 25000,
              ending: 25000
            },
            investmentAmount: 25000,
            liquidationPaid: null,
            liquidationDiscount: null
          }],
          MATURED: []
        }
      },
      transactions: [],
      url: 'http://atomic-gateway:9000/api/atomic-gateway/statement/394655/monthly/4/2024'
    }],
    rollup: {
      summary: {
        principalOutstanding: {
          preDefault: 10325.71,
          postDefault: 31699.97,
          total: 42025.68
        },
        interestAccrued: {
          preDefault: 987.22,
          postDefault: 9141.14,
          total: 10128.36
        },
        clearedBalance: 1160.54,
        investmentBalance: 239041.06,
        liquidationPaid: 0,
        liquidationDiscount: 0,
        asOfDate: '2022-08-31',
        fundsTotal: 186887.02,
        pendingInvestments: 250,
        totalAccountValue: 480322.93
      },
      breakdown: {
        inDefault: true,
        funds: {
          starting: 186887.02,
          added: 0,
          deducted: 0,
          ending: 186887.02
        },
        principal: {
          starting: 42025.68,
          added: 0,
          deducted: 0,
          ending: 42025.68
        },
        interest: {
          starting: 9796.32,
          added: 332.04,
          deducted: 0,
          ending: 10128.36,
          netPaid: 0,
          fexpPaid: 0
        },
        wallet: {
          starting: 1160.54,
          added: 0,
          deducted: 0,
          ending: 1160.54
        },
        totals: {
          starting: 239869.56,
          added: 332.04,
          deducted: 0,
          ending: 240201.6
        }
      }
    }
  }
};
export const portfolioStatementProviderResponseMock = {
  success: true,
  response: {
    providerCount: 2,
    providerList: [PortfolioStatementProviderType.PERSHING, PortfolioStatementProviderType.YS]
  }
};