import { HttpMethods } from '../../enums/http';
import { restApi, Tags } from '../common';
const BASE_URL = '/a/api';
export const investorEntityProviderMigrationApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.ProviderKYCMigrationStatus, Tags.ProviderBankLinkMigrationStatus, Tags.InvestorEntityStatus, Tags.BankAccount]
}).injectEndpoints({
  endpoints: builder => {
    const startProviderMigration = builder.mutation({
      query: _ref => {
        let {
          investorEntityId
        } = _ref;
        return {
          url: `${BASE_URL}/kyc/investor-entity/${investorEntityId}/migrate`,
          method: HttpMethods.Post
        };
      },
      invalidatesTags: [Tags.ProviderKYCMigrationStatus, Tags.ProviderBankLinkMigrationStatus, Tags.InvestorEntityStatus, Tags.BankAccount]
    });
    const getKYCProviderMigrationStatus = builder.query({
      query: () => ({
        url: `${BASE_URL}/kyc/migration/eligibility`,
        method: HttpMethods.Get
      }),
      providesTags: [Tags.ProviderKYCMigrationStatus]
    });
    const getBankLinkProviderMigrationStatus = builder.query({
      query: () => ({
        url: `${BASE_URL}/linked-bank-accounts/migration`,
        method: HttpMethods.Get
      }),
      providesTags: [Tags.ProviderBankLinkMigrationStatus],
      transformResponse: response => response.value
    });
    return {
      startProviderMigration,
      getKYCProviderMigrationStatus,
      getBankLinkProviderMigrationStatus
    };
  }
});