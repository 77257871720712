import { BankAccountProvider, BankAccountProvisionState } from '../../types';
import { BankAccountExternalPermission, BankAccountHolderType, BankAccountType, ExternalIdentityProvider, PaymentMethodType, PortfolioStrategyValues } from '../../enums';
export const bankAccount = {
  id: 90729,
  userId: 220951,
  holderId: 201466,
  holderType: BankAccountHolderType.INVESTOR_ACCOUNT,
  provisionState: BankAccountProvisionState.CONFIRMED,
  holderName: 'John Doe',
  nickname: 'SynapsePay Test Checking Account - 8901',
  accountType: BankAccountType.BUSI,
  accountBalance: 800,
  accruedInterest: 0,
  pendingMoneyIn: 0,
  pendingMoneyOut: 0,
  pendingBalance: null,
  availableBalance: null,
  totalBalance: null,
  availableWithdrawals: 0,
  nameOnAccount: 'John Doe',
  bankName: 'CAPITAL ONE N A ',
  bankRoutingNumber: 'XXXXX0407',
  maskedAccountNumber: 'XXXXX8901',
  tokenizedAccountNumber: '1,SYNAPSEPAY,',
  specialInstructions: null,
  paymentMethod: PaymentMethodType.ACH,
  externalId: '5ea84f6ce1a80666fe65b12a',
  lastPayInUsageDate: null,
  lastPayOutUsageDate: null,
  depositVerificationPending: false,
  removable: true,
  usages: [],
  externalPermission: BankAccountExternalPermission.CREDIT_AND_DEBIT,
  externalIdentityProvider: ExternalIdentityProvider.SYNAPSEPAY,
  microDepositsReinitiatedCount: 0,
  externalVerificationAttempts: 0,
  smallDepositsAllowed: false,
  accountTypeDisplayName: 'Business Checking',
  paymentMethodDisplayName: 'ACH Bank Transfer',
  microDepositsReinitiateRemaining: 3,
  verificationAttemptsRemaining: 5,
  bankAccountNameWithLast4Digits: 'CAPITAL ONE N A - 8901',
  minimumDeposit: 50000,
  minimumWithdrawal: 0.01,
  incrementalDeposit: 25000,
  limitPerTransaction: 250000,
  investorAccountManagementStrategy: PortfolioStrategyValues.Self,
  provider: BankAccountProvider.SYNAPSE
};