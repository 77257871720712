var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { Heading, ParagraphBase, ParagraphChildren, ParagraphRegularText } from '../../content/text';
import { Button } from '../../content/button';
import { Container } from '../container';
import { CSSTransition } from 'react-transition-group';
import { media } from '../../themes';
export const PageNotification = _ref => {
  let {
    title,
    children,
    cta,
    onCTAClick,
    inverse,
    animate,
    dataCy
  } = _ref;
  return __jsx(CSSTransition, {
    in: animate,
    classNames: "fade",
    timeout: 600,
    appear: true
  }, __jsx(Background, {
    inverse: inverse
  }, __jsx(NotificationContainer, {
    "data-cy": dataCy
  }, __jsx(Content, null, title && __jsx(Heading, {
    type: 5,
    inverse: inverse
  }, title), __jsx(ChildrenContent, {
    inverse: inverse
  }, children)), cta && __jsx(CTA, null, __jsx(Button, {
    buttonType: "secondary",
    inverse: inverse,
    onClick: onCTAClick
  }, cta)))));
};
const NotificationContainer = styled(Container).withConfig({
  displayName: "PageNotification__NotificationContainer",
  componentId: "sc-a4mr85-0"
})(["display:flex;flex-direction:column;justify-content:space-between;padding-top:20px;padding-bottom:20px;", ";"], media.tablet`
    flex-direction: row;
  `);
const Background = styled.div.withConfig({
  displayName: "PageNotification__Background",
  componentId: "sc-a4mr85-1"
})(["background:", ";width:100%;word-break:break-word;&.fade-appear{transition:all 300ms ease-in-out;transform:scaleY(0);transform-origin:top;> ", "{transition:all 300ms ease-in-out;transition-delay:300ms;opacity:0;}}&.fade-appear-active{transform:scaleY(1);> ", "{opacity:1;}}"], props => props.inverse ? props.theme.colors.background_inverse_secondary : props.theme.colors.input_button_background, NotificationContainer, NotificationContainer);
const ChildrenContent = styled.div.withConfig({
  displayName: "PageNotification__ChildrenContent",
  componentId: "sc-a4mr85-2"
})(["p{color:", ";", " ", " ", " &:not(:first-child){padding-top:10px;}&:first-child{padding-top:5px;}}"], props => props.inverse ? props.theme.colors.text_paragraph_inverse : props.theme.colors.text_paragraph, ParagraphBase, ParagraphChildren, ParagraphRegularText);
const Content = styled.div.withConfig({
  displayName: "PageNotification__Content",
  componentId: "sc-a4mr85-3"
})(["display:inline-flex;flex-direction:column;flex:1;max-width:700px;"]);
const CTA = styled.div.withConfig({
  displayName: "PageNotification__CTA",
  componentId: "sc-a4mr85-4"
})(["display:inline-flex;flex-direction:column;margin-top:20px;", ";"], media.tablet`
    margin-left: 30px;
    margin-top: 0;
    justify-content: center;
  `);