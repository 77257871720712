var __jsx = React.createElement;
import React from 'react';
import { Heading, Markdown } from '@yieldstreet/ui-kit';
import { Wrapper, HeadingWrapper, SubTitle, SectionsWrapper, Inner } from './ContainerSection.style';
const ContainerSection = _ref => {
  let {
    container,
    sections,
    dataCy,
    userState,
    isOffering,
    id
  } = _ref;
  let {
    title,
    subtitle,
    hideTitle,
    backgroundColor = '#ffffff',
    headingType = 3
  } = container;
  let hideSubtitle = false;
  if (container.title === 'Resources' && isOffering && !userState.loggedIn) {
    hideSubtitle = true;
  }
  let numberHeading = parseInt(headingType);
  return __jsx(Wrapper, {
    id: id,
    "data-cy": dataCy,
    style: {
      backgroundColor: backgroundColor
    }
  }, __jsx(Inner, null, !hideTitle && __jsx(HeadingWrapper, null, title && __jsx(Heading, {
    type: numberHeading,
    "data-cy": `${dataCy}-title`
  }, title), subtitle && __jsx(SubTitle, {
    type: 5,
    hideSubtitle: hideSubtitle
  }, __jsx(Markdown, {
    source: subtitle
  }))), __jsx(SectionsWrapper, null, sections)));
};
export default ContainerSection;