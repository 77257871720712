var __jsx = React.createElement;
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import { ACCORDION_TEST_ID, ACCORDION_HEADER_TEST_ID, ACCORDION_ICON_TEST_ID, ACCORDION_LABEL_TEST_ID, ACCORDION_BODY_TEST_ID, ACCORDION_STATES } from './Accordion.model';
import arrow from '@yieldstreet/ui-kit/assets/graphics/icons/arrow.svg';
export const Accordion = _ref => {
  let {
    children,
    header,
    open,
    onChange,
    initial = ACCORDION_STATES.collapsed,
    dataCy = ACCORDION_TEST_ID
  } = _ref;
  return __jsx("div", {
    "data-cy": dataCy
  }, __jsx(StyledAccordionHeader, {
    onClick: () => onChange(!open),
    "data-cy": ACCORDION_HEADER_TEST_ID
  }, __jsx("div", {
    "data-cy": ACCORDION_LABEL_TEST_ID
  }, header), __jsx(motion.img, {
    "data-cy": ACCORDION_ICON_TEST_ID,
    src: arrow,
    style: {
      pointerEvents: 'none'
    },
    animate: {
      transform: open ? 'rotate(180deg)' : 'rotate(0deg)'
    }
  })), __jsx(AnimatePresence, {
    initial: true
  }, open && __jsx(motion.div, {
    layout: "accordion",
    "data-cy": ACCORDION_BODY_TEST_ID,
    initial: initial,
    animate: "open",
    exit: "collapsed",
    variants: {
      open: {
        opacity: 1,
        height: 'auto'
      },
      collapsed: {
        opacity: 0,
        height: 0
      }
    }
  }, children)));
};
export const StyledAccordionHeader = styled.div.withConfig({
  displayName: "Accordion__StyledAccordionHeader",
  componentId: "sc-x66qix-0"
})(["display:flex;flex-direction:row;flex-wrap:nowrap;justify-content:space-between;align-content:center;align-items:center;padding:12px 0;cursor:pointer;"]);