import { HttpMethods } from '../../enums/http';
import { restApi, Tags } from '../common';
const BASE_URL = '/api/unit';
export const unitApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.UnitApplication, Tags.UnitKYCMigrationStatus, Tags.UnitBankLinkMigrationStatus, Tags.InvestorEntityStatus, Tags.BankAccount]
}).injectEndpoints({
  endpoints: builder => {
    const getUnitApplication = builder.query({
      query: _ref => {
        let {
          investorEntityId
        } = _ref;
        return {
          url: `${BASE_URL}/kyc/investor-entity/${investorEntityId}/application-form`,
          method: HttpMethods.Get
        };
      },
      providesTags: [Tags.UnitApplication],
      transformResponse: response => response.value
    });
    const startUnitKYCMigration = builder.mutation({
      query: _ref2 => {
        let {
          investorEntityId
        } = _ref2;
        return {
          url: `/a/api/kyc/investor-entity/${investorEntityId}/migrate`,
          method: HttpMethods.Post
        };
      },
      invalidatesTags: [Tags.UnitKYCMigrationStatus, Tags.UnitBankLinkMigrationStatus, Tags.InvestorEntityStatus, Tags.BankAccount]
    });
    const getUnitKYCMigrationStatus = builder.query({
      query: () => ({
        url: `${BASE_URL}/kyc/migration`,
        method: HttpMethods.Get
      }),
      providesTags: [Tags.UnitKYCMigrationStatus],
      transformResponse: response => response.value
    });
    const getUnitBankLinkMigrationStatus = builder.query({
      query: () => ({
        url: `/a/api/linked-bank-accounts/migration`,
        method: HttpMethods.Get
      }),
      providesTags: [Tags.UnitBankLinkMigrationStatus],
      transformResponse: response => response.value
    });
    return {
      getUnitApplication,
      startUnitKYCMigration,
      getUnitKYCMigrationStatus,
      getUnitBankLinkMigrationStatus
    };
  }
});