var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { Paragraph } from '../../content';
import TipSvg from './icon.svg';
import WarningSvg from './warningIcon.svg';
import InfoSvg from './trustmarkInfo.svg';
import SecuritySvg from './trustmarkSecurity.svg';
const getIcon = type => {
  switch (type) {
    case 'warning':
      return WarningSvg;
    case 'info':
      return InfoSvg;
    case 'security':
      return SecuritySvg;
    case 'tip':
    default:
      return TipSvg;
  }
};
export const InfoFact = _ref => {
  let {
    className,
    alt,
    children,
    type = 'tip',
    center
  } = _ref;
  return __jsx(Container, {
    className: className
  }, __jsx(Icon, {
    center: center
  }, __jsx("img", {
    alt: alt || 'icon',
    src: getIcon(type),
    width: "45",
    height: "45"
  })), __jsx(Content, {
    small: true
  }, children));
};
const Container = styled.div.withConfig({
  displayName: "InfoFact__Container",
  componentId: "sc-ldoqvd-0"
})(["display:flex;"]);
const Icon = styled.div.withConfig({
  displayName: "InfoFact__Icon",
  componentId: "sc-ldoqvd-1"
})(["align-self:", ";img{display:block;}"], props => props.center ? 'center' : 'flex-start');
const Content = styled(Paragraph).withConfig({
  displayName: "InfoFact__Content",
  componentId: "sc-ldoqvd-2"
})(["padding:0 0 0 15px;flex:1;"]);