import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { useModal } from '@yieldstreet/ui-kit';
import { Modals } from 'constants/modal.model';
import sortIcon from './assets/sort.svg';
import { testIds as setupTestId } from './Filters.model';
import { ModalTriggerButtonInner, ModalTriggerButtonOuter, ModalTriggerButton } from './Filters.style';
export const SortMobile = _ref => {
  let {
    sortOptions,
    queryFilterParams,
    initOptions,
    testId
  } = _ref;
  const {
    open,
    isOpen
  } = useModal(Modals.SortMobile, {
    props: {
      sortOptions,
      queryFilterParams,
      initOptions,
      testId
    }
  });
  const testIds = setupTestId(testId);
  return __jsx(ModalTriggerButtonOuter, {
    modalIsActive: isOpen
  }, __jsx(ModalTriggerButton, _extends({}, testIds.sortTrigger.attr, {
    onClick: () => {
      open();
    }
  }), __jsx(ModalTriggerButtonInner, null, __jsx("span", null, "Sort by"), __jsx("img", {
    width: "20px",
    src: sortIcon,
    alt: "Sort by"
  }))));
};