import styled from 'styled-components';
export const SBox = styled.div.withConfig({
  displayName: "InvestmentVoteListstyle__SBox",
  componentId: "sc-1scl0ux-0"
})(["background-color:", ";box-shadow:", ";border:1px solid ", ";border-radius:20px;text-align:left;display:flex;flex-direction:column;overflow:scroll;"], props => props.theme.colors.background_card, props => props.theme.shadows.shadow_default, props => props.theme.colors.border_default);
export const Wrapper = styled.div.withConfig({
  displayName: "InvestmentVoteListstyle__Wrapper",
  componentId: "sc-1scl0ux-1"
})(["display:flex;justify-content:space-between;padding:", " ", ";"], props => props.theme.spacing.m, props => props.theme.spacing.s);
export const ContentWrapper = styled.div.withConfig({
  displayName: "InvestmentVoteListstyle__ContentWrapper",
  componentId: "sc-1scl0ux-2"
})(["display:flex;flex-direction:column;box-shadow:", ";"], props => props.theme.shadows.shadow_default);
export const LoadingWrapper = styled.div.withConfig({
  displayName: "InvestmentVoteListstyle__LoadingWrapper",
  componentId: "sc-1scl0ux-3"
})(["margin:", ";"], props => props.theme.spacing.m);