import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useMemo } from 'react';
import { allToken, investmentApi, InvestorEntityTypeValue, ManagementStrategy, PortfolioStrategyValues } from '@yieldstreet/platform-kit';
import { getEligibilityGates } from './getEligibilityGates';
import filter from "lodash/filter";
import find from "lodash/find";
import forEach from "lodash/forEach";
import isEmpty from "lodash/isEmpty";
import { AppAction, appActionManager } from '../app-actions';
import { useAllInvestorEntities } from '../hooks';
import { getEligibilityGateContent } from './getEligibilityGateContent';
export const useAllManagedPortfolioEligibilityContent = () => {
  const {
    data: {
      eligibility
    } = {},
    isFetching: eligibilityIsFetching
  } = investmentApi.useManagedPortfolioEligibilityQuery(allToken);
  const {
    investorEntities,
    investorEntityStatuses,
    isLoading
  } = useAllInvestorEntities();
  const isFetching = useMemo(() => eligibilityIsFetching || isLoading, [eligibilityIsFetching, isLoading]);
  const managedMeta = useMemo(() => {
    let managedAction;
    let managedGate;

    /* Get gates with onlyIfAllGates this will not return any gate
    if 1 account is fully functional and users should be allowed
    to go to their portfolio */

    const gates = eligibility && getEligibilityGates(eligibility, {
      onlyIfAllGated: true
    });
    if (isFetching || isEmpty(gates)) {
      return {};
    }
    const userGate = gates && getEligibilityGateContent(PortfolioStrategyValues.Discretionary, gates);

    /* If no investor entties early return to show Set up portfolio */

    if (!(investorEntities !== null && investorEntities !== void 0 && investorEntities.length)) {
      return {
        action: userGate === null || userGate === void 0 ? void 0 : userGate.action,
        gate: userGate !== null && userGate !== void 0 && userGate.blocking ? userGate : undefined
      };
    }
    const hasIndividual = !!filter(investorEntities, {
      type: {
        value: InvestorEntityTypeValue.INDV
      }
    }).length;

    /* If no individual account types set action to Setup Prtoflio create entity */

    if (!hasIndividual) {
      managedAction = appActionManager.getAction(AppAction.CREATE_INVESTOR_ENTITY, {});
    }
    let entityDictionary = {};

    /* Create an entity dictionary to base decisions on  */

    forEach(investorEntities, investorEntity => {
      const investorEntityStatus = find(investorEntityStatuses, {
        investorEntityId: investorEntity.id
      });
      const gate = eligibility && getEligibilityGates(eligibility, {
        investorEntityId: investorEntity.id.toString()
      });
      const gateData = gate ? getEligibilityGateContent(PortfolioStrategyValues.Discretionary, gate, investorEntity, investorEntityStatus) : undefined;
      entityDictionary = Object.assign(_objectSpread(_objectSpread({}, entityDictionary), {}, {
        [`${investorEntity.id}`]: {
          type: investorEntity.type.value,
          hasManaged: !!filter(investorEntity.investorAccounts, {
            managementStrategy: ManagementStrategy.Discretionary
          }).length,
          gateData,
          blockingGate: gateData === null || gateData === void 0 ? void 0 : gateData.blocking
        }
      }));
    });
    const individualAccounts = filter(entityDictionary, entity => entity.type === InvestorEntityTypeValue.INDV);
    const nonBlockingGate = find(individualAccounts, account => {
      var _account$gateData, _account$gateData2, _account$gateData2$ac;
      if (account !== null && account !== void 0 && account.gateData && !(account !== null && account !== void 0 && (_account$gateData = account.gateData) !== null && _account$gateData !== void 0 && _account$gateData.blocking) && !(account !== null && account !== void 0 && (_account$gateData2 = account.gateData) !== null && _account$gateData2 !== void 0 && (_account$gateData2$ac = _account$gateData2.action) !== null && _account$gateData2$ac !== void 0 && _account$gateData2$ac.cta)) {
        return account;
      }
    });

    /* If 1 of the INDV entities has a gate that is not
    blocking no CTA should show and user should be allwoed to portfolio */

    if (nonBlockingGate) {
      return;
    }

    /* If only 1 individual entity show any gate action that is next */

    if (individualAccounts.length === 1) {
      var _individualAccounts$;
      managedAction = (_individualAccounts$ = individualAccounts[0].gateData) === null || _individualAccounts$ === void 0 ? void 0 : _individualAccounts$.action;
      managedGate = individualAccounts[0].gateData;
    }
    const hasManaged = !!filter(entityDictionary, entity => entity.hasManaged && !entity.blockingGate).length;

    /* If more than 1 individual entity show setup porfolio if none have a Discretionary account
    else show Complete setup both actions should take you to the account screen */

    if (individualAccounts.length > 1 && hasManaged) {
      managedAction = appActionManager.getAction(AppAction.COMPLETE_SETUP, {});
    } else if (individualAccounts.length > 1) {
      managedAction = appActionManager.getAction(AppAction.SETUP_PORTFOLIO, {});
    }
    return {
      action: managedAction,
      gate: managedGate
    };
  }, [eligibility, investorEntities, isFetching, investorEntityStatuses]);
  return _objectSpread(_objectSpread({}, managedMeta), {}, {
    isFetching
  });
};