const wait = delay => new Promise(result => setTimeout(result, delay));
/**
 * @deprecated Please use pollCheck instead
 */
export const poll = function (action, _ref) {
  let {
    delay = 0,
    limit = 0,
    condition
  } = _ref;
  let state = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    cancelled: false
  };
  const promise = new Promise((resolve, reject) => {
    return action().then(result => {
      if (state.cancelled) {
        return;
      }
      if (limit > 0 && (!condition || condition && !condition(result))) {
        return wait(delay).then(() => {
          if (state.cancelled) {
            return;
          }
          return poll(action, {
            delay,
            limit: limit - 1,
            condition
          }, state).promise;
        }).then(result => !state.cancelled && result && resolve(result)).catch(error => !state.cancelled && reject(error));
      }
      return resolve(result);
    }).catch(error => !state.cancelled && reject(error));
  });
  return {
    promise,
    cancel: () => {
      state.cancelled = true;
    }
  };
};