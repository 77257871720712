import update from 'immutability-helper';
import * as actions from 'store/actions/portfolioPaymentActivity';
export const initialState = {
  data: {},
  isFetching: false,
  error: false,
  recentPayments: {},
  recentPaymentsError: false,
  recentPaymentsIsFetching: false
};
const portfolioPaymentActivity = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case actions.PORTFOLIO_PAYMENT_ACTIVITY_ATTEMPT:
      {
        return update(state, {
          isFetching: {
            $set: true
          },
          error: {
            $set: false
          }
        });
      }
    case actions.PORTFOLIO_PAYMENT_ACTIVITY_SUCCESS:
      {
        const {
          response,
          userId,
          investorAccountId,
          timePeriod,
          noteUrlHash
        } = action;
        const data = noteUrlHash ? {
          [`${userId}_${investorAccountId}_${timePeriod}_${noteUrlHash}`]: response
        } : {
          [`${userId}_${investorAccountId}_${timePeriod}`]: response
        };
        return update(state, {
          data: {
            $merge: data
          },
          isFetching: {
            $set: false
          },
          error: {
            $set: false
          }
        });
      }
    case actions.PORTFOLIO_PAYMENT_ACTIVITY_FAILURE:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          error: {
            $set: true
          }
        });
      }
    default:
      return state;
  }
};
export default portfolioPaymentActivity;