var __jsx = React.createElement;
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Button, ExternalLink, Heading } from '../../content';
import { media } from '../../themes/media';
import { PromoBanner } from '../promo-banner';
import { customThemeObj } from './config';
export const FeatureBanner = _ref => {
  let {
    title,
    content,
    ctaText,
    type = 1,
    onActionClick,
    onCloseClick
  } = _ref;
  const customTheme = customThemeObj[type];
  const renderButton = useMemo(() => (customTheme === null || customTheme === void 0 ? void 0 : customTheme.buttonType) === 'link' ? __jsx(ExternalLink, {
    inverse: customTheme === null || customTheme === void 0 ? void 0 : customTheme.isInverse,
    onClick: onActionClick
  }, ctaText) : __jsx(Button, {
    buttonType: customTheme === null || customTheme === void 0 ? void 0 : customTheme.buttonType,
    onClick: onActionClick,
    inverse: customTheme === null || customTheme === void 0 ? void 0 : customTheme.isInverse
  }, ctaText), [ctaText, customTheme === null || customTheme === void 0 ? void 0 : customTheme.buttonType, customTheme === null || customTheme === void 0 ? void 0 : customTheme.isInverse, onActionClick]);
  switch (type) {
    case 4:
      return __jsx(PromoBanner, {
        image: customTheme === null || customTheme === void 0 ? void 0 : customTheme.image,
        imageFull: true,
        imageFullBasis: 60,
        title: title,
        content: content,
        cta: renderButton,
        inverse: customTheme === null || customTheme === void 0 ? void 0 : customTheme.isInverse,
        onCloseClick: onCloseClick
      });
    case 1:
    case 2:
    case 3:
    default:
      return __jsx(Container, {
        customTheme: customTheme === null || customTheme === void 0 ? void 0 : customTheme.container
      }, __jsx(CloseButton, {
        customTheme: customTheme === null || customTheme === void 0 ? void 0 : customTheme.iconStyle,
        src: customTheme === null || customTheme === void 0 ? void 0 : customTheme.closeIcon,
        onClick: onCloseClick
      }), __jsx(TextWrapper, {
        customTheme: customTheme === null || customTheme === void 0 ? void 0 : customTheme.textWrapper
      }, title && __jsx(Title, {
        type: (customTheme === null || customTheme === void 0 ? void 0 : customTheme.titleType) || 3,
        inverse: customTheme === null || customTheme === void 0 ? void 0 : customTheme.isInverse
      }, title), content), __jsx(ButtonWrapper, {
        customTheme: customTheme === null || customTheme === void 0 ? void 0 : customTheme.buttonWrapper
      }, renderButton));
  }
};
const Container = styled.div.withConfig({
  displayName: "FeatureBanner__Container",
  componentId: "sc-bqdp3-0"
})(["position:relative;display:flex;background:green;", ";"], props => props.customTheme);
const TextWrapper = styled.div.withConfig({
  displayName: "FeatureBanner__TextWrapper",
  componentId: "sc-bqdp3-1"
})(["text-align:left;", " ", ";"], media.desktop`
    max-width: 70%;
  `, props => props.customTheme);
const Title = styled(Heading).withConfig({
  displayName: "FeatureBanner__Title",
  componentId: "sc-bqdp3-2"
})(["margin-bottom:10px;"]);
const ButtonWrapper = styled.div.withConfig({
  displayName: "FeatureBanner__ButtonWrapper",
  componentId: "sc-bqdp3-3"
})(["", ";"], props => props.customTheme);
const CloseButton = styled.img.withConfig({
  displayName: "FeatureBanner__CloseButton",
  componentId: "sc-bqdp3-4"
})(["cursor:pointer;position:absolute;width:15px;top:15px;right:15px;", ";"], props => props.customTheme);