var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';

// import { UserState } from '@yieldstreet/platform-kit';

import { Paragraph } from '../../content/text';
import Icon from './assets/download-icon.svg';
export const DownloadCard = _ref => {
  let {
    title,
    description
  } = _ref;
  return __jsx(Wrapper, null, __jsx(InnerWrapper, null, __jsx(TextWrapper, null, title ? __jsx(TitleContainer, null, __jsx(Title, {
    semiBold: true,
    className: "media-card-title"
  }, title)) : null, description ? __jsx(Description, {
    className: "media-card-description"
  }, description) : null), __jsx("img", {
    src: Icon
  })));
};
const Wrapper = styled.div.withConfig({
  displayName: "DownloadCard__Wrapper",
  componentId: "sc-1lbi2xb-0"
})(["cursor:pointer;overflow-wrap:break-word;border:1px solid ", ";box-shadow:", ";transition:box-shadow 0.3s ease-in-out;&:hover{box-shadow:", ";}margin:10px 0;padding:22px;flex-wrap:wrap;background-color:", ";border-radius:2px;"], props => props.theme.colors.border_default, props => props.theme.shadows.shadow_default, props => props.theme.shadows.shadow_hover, props => props.theme.colors.background_card);
const InnerWrapper = styled.div.withConfig({
  displayName: "DownloadCard__InnerWrapper",
  componentId: "sc-1lbi2xb-1"
})(["display:flex;align-items:center;"]);
const TextWrapper = styled.div.withConfig({
  displayName: "DownloadCard__TextWrapper",
  componentId: "sc-1lbi2xb-2"
})(["flex:1;padding-right:20px;"]);
const TitleContainer = styled.div.withConfig({
  displayName: "DownloadCard__TitleContainer",
  componentId: "sc-1lbi2xb-3"
})(["display:flex;flex-direction:row;overflow-wrap:anywhere;&:not(:only-child){padding-bottom:10px;}cursor:pointer;"]);
const Title = styled(Paragraph).withConfig({
  displayName: "DownloadCard__Title",
  componentId: "sc-1lbi2xb-4"
})([""]);
const Description = styled(Paragraph).withConfig({
  displayName: "DownloadCard__Description",
  componentId: "sc-1lbi2xb-5"
})(["cursor:pointer;"]);