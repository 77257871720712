import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["icon", "iconCentered", "title", "children", "buttons"];
var __jsx = React.createElement;
import React from 'react';
import Box from '@mui/material/Box';
import { Wrapper, IconWrapper, ButtonWrapper } from './ProcessInfo.styles';
import { useTestIds } from './ProcessInfo.model';
export const ProcessInfo = _ref => {
  let {
      icon,
      iconCentered,
      title,
      children,
      buttons
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  const testIds = useTestIds(props);
  return __jsx(Wrapper, testIds.base.attr, icon && __jsx(IconWrapper, _extends({
    centered: iconCentered
  }, testIds.icon.attr), icon), __jsx(_Typography, _extends({
    variant: "h4"
  }, testIds.title.attr, {
    sx: {
      mb: 6
    }
  }), title), __jsx(Box, null, children), buttons && __jsx(ButtonWrapper, null, buttons));
};