import React from "react";
var __jsx = React.createElement;
import { useAdvice } from 'utils/advice';
import { AdvicePreferencesForm } from './AdvicePreferencesForm';
export const AdvicePreferences = () => {
  const {
    isAdviceReady
  } = useAdvice();
  return __jsx(React.Fragment, null, __jsx(AdvicePreferencesForm, {
    formName: "GeneralPreferences"
  }), isAdviceReady && __jsx(AdvicePreferencesForm, {
    formName: "Recommendations"
  }));
};