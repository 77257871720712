import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { useHistory } from 'react-router-dom';
import { HeroBanner, Heading, Paragraph } from '@yieldstreet/ui-kit';
import { PortfolioStrategyValues } from '@yieldstreet/platform-kit';
import { CREATE_ACCOUNT_PATH, MANAGEMENT_STRATEGY } from '../../../CreateInvestorAccount.model';
import { Background, Content, SParagraph, SButton, ButtonContainer } from './Hero.style';
import { testIds } from './Hero.model';
export const Hero = () => {
  const history = useHistory();
  return __jsx(Background, null, __jsx(Content, null, __jsx(HeroBanner, null, __jsx(Heading, _extends({
    type: 2,
    inverse: true
  }, testIds.heading.attr), "Private markets investing packed into one robust portfolio"), __jsx(SParagraph, _extends({
    inverse: true
  }, testIds.paragraph.attr), "Yieldstreet 360 builds and manages a diversified private markets portfolio on your behalf."), __jsx(ButtonContainer, null, __jsx(SButton, _extends({
    fullWidth: true,
    onClick: () => history.replace(`${CREATE_ACCOUNT_PATH}?${MANAGEMENT_STRATEGY}=${PortfolioStrategyValues.Discretionary}`)
  }, testIds.button.attr), "Continue"), __jsx(Paragraph, {
    size: "small",
    inverse: true,
    secondary: true
  }, "Individual accounts only")))));
};