var __jsx = React.createElement;
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Heading, Label, Paragraph } from '../../content';
import { FormTextInput } from '../../form';
import { Spinner } from '../../layout/spinner';
import { media } from '../../themes';
export const EmailCapture = _ref => {
  let {
    title,
    subtitle,
    buttonText,
    disclaimer,
    onSubmit,
    className
  } = _ref;
  const {
    0: submitted,
    1: setSubmitted
  } = useState(false);
  const {
    0: isSubmitting,
    1: setIsSubmitting
  } = useState(false);
  const handleSubmit = async values => {
    setIsSubmitting(true);
    onSubmit && (await onSubmit({
      email: values.email
    }));
    setIsSubmitting(false);
    setSubmitted(true);
  };
  return __jsx(Wrapper, {
    className: className
  }, __jsx(Content, null, __jsx(Heading, {
    type: 3,
    tagType: 4
  }, title), __jsx(Subtitle, null, subtitle), submitted ? __jsx(Paragraph, {
    small: true
  }, "Thanks for submitting your email! You\u2019ll now receive the latest alternative investing insights straight to your inbox!") : __jsx(React.Fragment, null, __jsx(Formik, {
    initialValues: {
      email: ''
    },
    onSubmit: handleSubmit
  }, formikProps => __jsx(Form, null, __jsx(SidedButton, null, __jsx(FormTextInput, {
    name: "email",
    type: "email",
    required: true,
    formikProps: formikProps,
    label: "Email address",
    placeholder: "Email address",
    fullWidth: true
  }), __jsx(SButton, {
    type: "submit",
    reducedPadding: true,
    buttonType: "primary"
  }, isSubmitting ? __jsx(Spinner, {
    small: true
  }) : buttonText)))), __jsx(Disclaimer, null, disclaimer))));
};
const Wrapper = styled.div.withConfig({
  displayName: "EmailCapture__Wrapper",
  componentId: "sc-10vkcdn-0"
})(["display:flex;flex-direction:column;align-items:center;margin:auto;text-align:center;width:100%;"]);
const Content = styled.div.withConfig({
  displayName: "EmailCapture__Content",
  componentId: "sc-10vkcdn-1"
})(["", ""], media.desktop`
    max-width: 540px;
  `);
const SidedButton = styled.div.withConfig({
  displayName: "EmailCapture__SidedButton",
  componentId: "sc-10vkcdn-2"
})(["display:flex;flex-direction:column;", ""], media.desktop`
    flex-direction: row;
    height: 60px;
    width: 540px;
    justify-content: center;
  `);
const SButton = styled(Button).withConfig({
  displayName: "EmailCapture__SButton",
  componentId: "sc-10vkcdn-3"
})(["width:100%;", ""], media.desktop`
    margin-left: 20px;
    min-width: 169px;
    padding: 14px;
    width: unset;
  `);
const Subtitle = styled(Paragraph).withConfig({
  displayName: "EmailCapture__Subtitle",
  componentId: "sc-10vkcdn-4"
})(["margin:10px 0 40px;"]);
const Disclaimer = styled(Label).withConfig({
  displayName: "EmailCapture__Disclaimer",
  componentId: "sc-10vkcdn-5"
})(["display:block;margin:20px 0;"]);