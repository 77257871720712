import axios from 'axios';
import forEach from 'lodash/forEach';
import { restApi, Tags } from '../common';
import { HttpMethods } from '../../enums/http';
export const investorAccountsApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.BankAccount, Tags.InvestorAccount, Tags.InvestorAccountNoteConfig, Tags.DistributionReinvestment, Tags.InvestorAccountWallet, Tags.AdviceManagedPortfolio, Tags.PortfolioTransactionPayments, Tags.PortfolioInvestmentsByStatus]
}).injectEndpoints({
  endpoints: builder => {
    const createProviderInvestorAccount = builder.mutation({
      query: _ref => {
        let {
          provider
        } = _ref;
        return {
          url: `/a/api/investor-account/provider/${provider}`,
          method: HttpMethods.Post
        };
      },
      invalidatesTags: [Tags.InvestorAccount]
    });
    const getInvestorAccountNoteConfig = builder.query({
      query: params => ({
        url: `/a/api/investor/account/note/config`,
        method: HttpMethods.Get,
        body: params
      }),
      providesTags: [Tags.InvestorAccountNoteConfig]
    });
    const getDistributionReinvestment = builder.query({
      queryFn: async (_ref2, _ref3) => {
        let {
          investorAccountId,
          urlHashes
        } = _ref2;
        let {
          extra
        } = _ref3;
        const axiosInstance = extra || axios;
        const apis = [];
        forEach(urlHashes, urlHash => {
          apis.push(axiosInstance.get(`/a/api/investor/account/${investorAccountId}/note/${urlHash}/config`, {
            params: {
              urlHash
            }
          }));
        });
        return await axios.all(apis).then(axios.spread(function () {
          const data = {};
          for (var _len = arguments.length, responses = new Array(_len), _key = 0; _key < _len; _key++) {
            responses[_key] = arguments[_key];
          }
          forEach(responses, x => {
            var _x$config, _x$config$params;
            Object.assign(data, {
              [(_x$config = x.config) === null || _x$config === void 0 ? void 0 : (_x$config$params = _x$config.params) === null || _x$config$params === void 0 ? void 0 : _x$config$params.urlHash]: x.data
            });
          });
          return {
            data
          };
        })).catch(errors => {
          return {
            data: {},
            error: errors
          };
        });
      },
      providesTags: [Tags.DistributionReinvestment]
    });
    return {
      createProviderInvestorAccount,
      getInvestorAccountNoteConfig,
      getDistributionReinvestment
    };
  }
});