import _Alert from "@mui/material/Alert";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _Chip from "@mui/material/Chip";
import _Tooltip from "@mui/material/Tooltip";
var __jsx = React.createElement;
import React from 'react';
import { BankAccountProvisionState, BankAccountExternalPermission } from '@yieldstreet/platform-kit';
import { Label, Paragraph, useModalContext } from '@yieldstreet/ui-kit';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ExternalBankAccountWrapper, BankAccountActions, BankAccountDetails, BankAccountItem, BankAccountMeta, BankAccountMetaWrapper, MaskedNumber } from './ExternalBankAccount.style';
import { DELETE_BANK_ACCOUNT, EDIT_BANK_NAME } from 'constants/modal.const';
import { testIds } from './ExternalBankAccount.model';
export const ExternalBankAccount = _ref => {
  let {
    bankAccount
  } = _ref;
  const {
    showModal
  } = useModalContext();
  const isDegraded = bankAccount.provisionState === BankAccountProvisionState.DEGRADED;
  const canDelete = bankAccount.removable;
  const isDefault = bankAccount.defaultAccount;
  return __jsx(ExternalBankAccountWrapper, _extends({}, testIds.bankAccount.attr, {
    isDisabled: isDegraded && !isDefault || (bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.externalPermission) === BankAccountExternalPermission.LOCKED
  }), __jsx(BankAccountDetails, null, __jsx(BankAccountMetaWrapper, null, __jsx(BankAccountMeta, {
    size: "60%"
  }, __jsx(Label, {
    small: true
  }, "Bank account name"), __jsx(Paragraph, testIds.accountName.attr, bankAccount.nickname)), (bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.defaultAccount) && __jsx(BankAccountMeta, null, (bankAccount === null || bankAccount === void 0 ? void 0 : bankAccount.externalPermission) === BankAccountExternalPermission.LOCKED ? __jsx(_Chip, {
    label: "Preferred",
    size: "small",
    onDelete: () => {},
    deleteIcon: __jsx(_Tooltip, {
      title: "Future investment distributions for this account can not be sent to this bank"
    }, __jsx(InfoOutlinedIcon, null)),
    color: "warning"
  }) : __jsx(_Chip, {
    label: "Preferred",
    size: "small",
    onDelete: () => {},
    deleteIcon: __jsx(_Tooltip, {
      title: "Future investment distributions for this account will be sent to this bank"
    }, __jsx(InfoOutlinedIcon, null)),
    color: "secondary"
  })))), __jsx(BankAccountDetails, null, __jsx(Label, {
    small: true
  }, "Bank name"), __jsx(Paragraph, testIds.bankName.attr, bankAccount.bankName)), __jsx(BankAccountDetails, null, __jsx(Label, {
    small: true
  }, "Account number"), __jsx(BankAccountItem, null, __jsx(MaskedNumber, testIds.accountNumber.attr, bankAccount.maskedAccountNumber.replaceAll('X', '**')), __jsx(BankAccountActions, null, !isDegraded && __jsx(EditIcon, _extends({
    sx: {
      cursor: 'pointer'
    },
    fontSize: "small"
  }, testIds.editAction.attr, {
    onClick: () => showModal(EDIT_BANK_NAME, {
      bankAccount
    })
  })), canDelete && !isDegraded && __jsx(DeleteIcon, _extends({
    sx: {
      cursor: 'pointer'
    },
    fontSize: "small"
  }, testIds.deleteAction.attr, {
    onClick: () => showModal(DELETE_BANK_ACCOUNT, {
      bankAccount
    })
  }))))), isDegraded && __jsx(BankAccountDetails, null, __jsx(_Alert, _extends({}, testIds.degradedAlert.attr, {
    severity: "warning",
    sx: {
      fontSize: '12px'
    },
    action: canDelete && __jsx(DeleteIcon, _extends({
      sx: {
        cursor: 'pointer'
      },
      fontSize: "small"
    }, testIds.deleteAction.attr, {
      onClick: () => showModal(DELETE_BANK_ACCOUNT, {
        bankAccount
      })
    }))
  }), "Account disconnected")));
};