var __jsx = React.createElement;
import React from 'react';
import { BannerContainer, BannerContent, Title, Description, BannerText, ImageWrapper, Image, CTAHolder } from './PromoBanner.style';
export const PromoBanner = _ref => {
  let {
    testId,
    image,
    imageFull,
    imageFullBasis,
    title,
    content,
    cta,
    inverse
  } = _ref;
  return __jsx(BannerContainer, {
    inverse: inverse,
    "data-cy": `${testId ? testId : 'banner'}`
  }, __jsx(BannerContent, {
    imageFull: imageFull,
    bannerBasis: imageFullBasis ? `${100 - imageFullBasis}%` : 'unset'
  }, __jsx(BannerText, null, __jsx(Title, {
    inverse: inverse,
    type: 4
  }, title), __jsx(Description, {
    inverse: inverse
  }, content), cta && __jsx(CTAHolder, null, cta))), image && __jsx(ImageWrapper, {
    imageFull: imageFull
  }, __jsx(Image, {
    imageFull: imageFull,
    src: image
  })));
};