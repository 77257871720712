import { investorEntityApi, InvestorEntityStepVerificationStatus } from '@yieldstreet/platform-kit';
import { useApiErrorMessage, useInvestorEntityStatusPollCheck } from '@yieldstreet/tool-kit';
import { skipToken } from '@reduxjs/toolkit/query';
const {
  useCreateProviderInvestorEntityMutation,
  useUpdateEtcProviderInvestorEntityMutation,
  useGetEtcProviderInvestorEntityQuery,
  useLazyGetEtcProviderOccupationsListQuery
} = investorEntityApi;
export const useETCKYC = _ref => {
  let {
    investorEntityId
  } = _ref;
  const {
    data: etcData,
    isLoading
  } = useGetEtcProviderInvestorEntityQuery(investorEntityId ? {
    investorEntityId
  } : skipToken);
  const [createInvestorEntity, createInvestorEntityState] = useCreateProviderInvestorEntityMutation();
  const [getEtcOccupationsList, {
    data: etcOccupationsList,
    isFetching: etcOccupationsListIsFetching,
    error: etcOccupationsListIsError
  }] = useLazyGetEtcProviderOccupationsListQuery();
  const createErrorMessage = useApiErrorMessage(createInvestorEntityState);
  const [updateInvestorEntity, updateInvestorEntityState] = useUpdateEtcProviderInvestorEntityMutation();
  const updateErrorMessage = useApiErrorMessage(updateInvestorEntityState);
  const {
    isLoading: isPolling
  } = useInvestorEntityStatusPollCheck(investorEntityId, _ref2 => {
    let {
      identity
    } = _ref2;
    return (identity === null || identity === void 0 ? void 0 : identity.status) !== InvestorEntityStepVerificationStatus.FAILED;
  }, !investorEntityId && !(updateInvestorEntityState !== null && updateInvestorEntityState !== void 0 && updateInvestorEntityState.isSuccess) && !(createInvestorEntityState !== null && createInvestorEntityState !== void 0 && createInvestorEntityState.isSuccess));
  const isSubmitting = (createInvestorEntityState === null || createInvestorEntityState === void 0 ? void 0 : createInvestorEntityState.isLoading) || (updateInvestorEntityState === null || updateInvestorEntityState === void 0 ? void 0 : updateInvestorEntityState.isLoading) || isPolling;
  const isSuccess = (createInvestorEntityState === null || createInvestorEntityState === void 0 ? void 0 : createInvestorEntityState.isSuccess) || (updateInvestorEntityState === null || updateInvestorEntityState === void 0 ? void 0 : updateInvestorEntityState.isSuccess);
  return {
    createInvestorEntity,
    createInvestorEntityState,
    errorMessage: updateErrorMessage || createErrorMessage,
    etcData,
    isLoading: isLoading || etcOccupationsListIsFetching,
    isSubmitting,
    isSuccess,
    isError: etcOccupationsListIsError,
    updateInvestorEntity,
    updateInvestorEntityState,
    getEtcOccupationsList,
    etcOccupationsList
  };
};