import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["userId"];
import { restApi, Tags } from '../../common';
import { HttpMethods } from '../../../enums/http';
export const navChartApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.PortfolioNavChart]
}).injectEndpoints({
  endpoints: build => {
    const getNavChart = build.query({
      query: _ref => {
        let {
            userId
          } = _ref,
          params = _objectWithoutProperties(_ref, _excluded);
        return {
          url: `/api/portfolio/${userId}/nav-chart`,
          method: HttpMethods.Get,
          params
        };
      },
      providesTags: [Tags.PortfolioNavChart]
    });
    return {
      getNavChart
    };
  }
});