import { genTestIds } from '@yieldstreet/tool-kit';
export const iraTypes = [{
  value: 'TRAD',
  label: 'Traditional IRA',
  description: 'Eligible source accounts: 403(b), 401(k), or 457 Plan, Traditional IRA, SEP or tax-sheltered annuity, including inherited accounts.'
}, {
  value: 'ROTH',
  label: 'Roth IRA',
  description: 'Eligible source accounts: Roth 403(b), Roth 401(k), Roth 457 Plan, Roth IRA or Roth SEP, including inherited accounts.'
}];
const {
  getTestIds
} = genTestIds(['wrapper', 'radioGroup']);
export const testIds = getTestIds('ira-type');