import { DistributionOption, PerformanceStatus } from '../../../../enums';
import { FundProvider, InvestmentEngine, gateType, TenderStatus, InvestmentTypes, InvestmentStatusTypes } from '../../../../types';
export const portfolioOverviewFundMatured = {
  userId: 185796,
  accountSummary: {
    outstandingPrincipal: 0,
    outstandingPrincipalPreDefault: 0,
    outstandingPrincipalPostDefault: 0,
    accruedInvestmentInterest: 0,
    accruedInvestmentInterestPreDefault: 0,
    accruedInvestmentInterestPostDefault: 0,
    balanceInDefault: 0,
    balanceByPerformanceStatus: {
      PERFORMING: 0.0,
      DEFAULT: 0.0,
      MODIFIED_OUTLOOK: 0.0,
      PARTIAL_DEFAULT: 0.0,
      MARINE_DEFAULT: 0.0
    },
    investmentBalance: 0.0,
    pendingInvestments: 0.0,
    availableBalance: 0.0,
    clearedBalance: 0.0,
    pendingDeposits: 0.0,
    reserveBalance: 0,
    total: 0.0,
    weightedInvestmentRate: 15.0,
    irr: 12.07,
    interestEarnedLast30: 0.0,
    fundsTotal: 0.0,
    funds: [{
      investorAccountId: '187482',
      noteUrlHash: 'UbooYg',
      fundCode: 'UbooYg',
      fundId: '0b9d6d50-1703-11ec-a3d4-471d988fde5e',
      total: 50000.0,
      nav: 0,
      navDate: '2023-06-26',
      sharesCount: 0.0,
      canToggleDistributionOption: false,
      dividendDistributionOption: DistributionOption.Cash,
      commitmentBreakdown: {
        unfunded: 0,
        funded: 50000.0,
        total: 50000.0
      },
      fundInvestmentStatus: 'MATURED',
      fundConfig: {
        fees: {
          MANAGEMENT_FEE: {
            rate: 2.5
          }
        },
        feesBalanceProviders: {},
        balanceCalculationType: null,
        capitalBalanceReductionType: null,
        positionsEffectiveDistributionImpact: null
      },
      commitmentDetails: []
    }]
  },
  capital: null,
  investorEntities: [],
  interest: {
    beginningAccruedInterest: 0,
    interestEarned: 0,
    compoundInterestEarned: 0,
    interestPaid: 0,
    fexpPaid: 0,
    fexpBalance: 0,
    netPaid: 0,
    walletInterestPaid: 0,
    compoundInterestPaid: 0,
    endingAccruedInterest: 0,
    interestEarnedPreDefault: 0,
    interestEarnedPostDefault: 0,
    earningsTimeline: []
  },
  principal: {
    beginningOutstandingPrincipal: 0,
    totalInvested: 0,
    repaidPrincipal: 0,
    endingOutstandingPrincipal: 0,
    endingOutstandingPrincipalPreDefault: 0,
    endingOutstandingPrincipalPostDefault: 0,
    liquidationPaid: 0,
    liquidationDiscount: 0,
    isInDefault: false,
    principalTimeline: []
  },
  investmentDetail: {
    fundId: '0b9d6d50-1703-11ec-a3d4-471d988fde5e',
    fundType: 'INTERNAL_FUND',
    noteUrlHash: 'UbooYg',
    title: 'Williamsburg Multi-Family Restructuring Equity',
    distributionRate: 15.0,
    assetClass: 'REAL',
    assetClassDescription: 'Real Estate',
    paymentType: 'Event-Based',
    termPhrase: '11 Mo.',
    advisor: 'YieldStreet Management LLC',
    subAdvisor: 'Invictus',
    interestRate: 0,
    taxDocument: 'K-1',
    taxMonth: 'SEPTEMBER',
    firstYearExpense: 150,
    annualFlatExpense: 70,
    irr: 44.27,
    investedDate: '2021-09-17',
    activeDate: '2021-09-22',
    launchedDate: '2021-09-17',
    terminationDate: '2022-08-08',
    interestDefaultedDate: null,
    principalDefaultedDate: null,
    dateOfFirstDefault: null,
    originator: null,
    dividendDistributionOption: DistributionOption.Cash,
    canToggleDistributionOption: false,
    performanceStatus: PerformanceStatus.PERFORMING,
    performanceStatusNote: '',
    spvType: null,
    offeringData: {
      offeringHero: {
        customRateString: '15-17%'
      }
    },
    investments: [{
      type: InvestmentTypes.Investment,
      dateInvested: '2021-09-17',
      dateActive: '2021-09-22',
      shareCount: 5000.0,
      sharePrice: 10,
      status: InvestmentStatusTypes.Matured,
      amount: 50000.0
    }],
    tenderOfferStatus: TenderStatus.UNAVAILABLE,
    tenderOfferUserEligible: false,
    tenderOfferId: null,
    totalInvestments: 50000.0,
    totalRepaid: 69098.97,
    netTotalRepaid: 68948.97,
    fundProvider: FundProvider.Ys,
    engine: InvestmentEngine.Funds
  },
  investmentGatesDetail: {
    requiresUserAttention: false,
    gates: [{
      id: '8bbc53b4-17fe-11ec-8970-3312d4e40269',
      irServiceInvestmentId: '84ff2b10-17fe-11ec-8276-498111da2bb5',
      externalInvestmentId: 103993,
      noteUrlHash: 'UbooYg',
      investorAccountId: '187482',
      adminUserId: '',
      visibility: 'ALL',
      priority: 30,
      timestamp: 1648819187,
      date: '2022-04-01',
      type: gateType.allocationPaymentPending,
      status: 'CLEARED',
      clearReason: 'INVESTMENT_ALLOCATED',
      owner: 'YS'
    }]
  },
  wallets: [],
  investmentStrategies: []
};