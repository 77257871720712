export const portfolioWallets = [{
  externalWalletId: null,
  interestRate: 0.002,
  investorAccountId: '9006',
  pendingWithdrawals: 0,
  walletId: '1f0f7c71-2588-11ea-9894-091c1603fd71'
}, {
  externalWalletId: '91074',
  interestRate: null,
  investorAccountId: '201789',
  pendingWithdrawals: 1,
  walletId: '41de9a33-31bc-430b-a100-8ef0c905b467'
}, {
  externalWalletId: '93498',
  interestRate: null,
  investorAccountId: '205502',
  pendingWithdrawals: 2,
  walletId: '8082e2f9-baf3-4300-9e3e-87d8fc2dd6b5'
}, {
  externalWalletId: '91085',
  interestRate: 0.002,
  investorAccountId: '201792',
  pendingWithdrawals: 3,
  walletId: '18532ed4-1920-4813-9d47-d2b9746b9f1d'
}];