var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import 'react-tippy/dist/tippy.css';
import { LabelStyle } from '../../content/text/Label';
import { Tooltip } from 'react-tippy';
import { theme2 } from '../../themes';
export const preventClick = event => {
  event.preventDefault();
  event.stopPropagation();
};
export const Tags = _ref => {
  let {
    tags = [],
    hasMoreTags = false,
    hasCloseButton = false,
    onShowMore = () => null,
    hasLegacyOfferingTags
  } = _ref;
  return __jsx(React.Fragment, null, __jsx(TagsWrapper, {
    hasCloseButton: hasCloseButton,
    onClick: hasCloseButton ? preventClick : undefined
  }, hasLegacyOfferingTags && tags.length > 0 ? __jsx(React.Fragment, null, tags.map(tag => __jsx(BaseTag, {
    key: `tags-${hasMoreTags ? 'all' : 'summary'}-${tag}`,
    onClick: !hasCloseButton ? preventClick : undefined
  }, tag))) : tags.length > 0 && __jsx(React.Fragment, null, tags.map((tag, index) => __jsx("span", {
    key: index
  }, tag.description ? __jsx(BaseTag, {
    onClick: preventClick
  }, __jsx(Tooltip, {
    position: "top",
    trigger: "mouseenter",
    arrow: true,
    html: __jsx(TooltipContent, {
      style: {
        fontFamily: theme2.typography.primary
      }
    }, tag.description)
  }, tag.name)) : __jsx(BaseTag, null, tag.name)))), hasMoreTags && __jsx(EllipsisTag, {
    "data-cy": "past-offerings-card-more-tags-ellipsis",
    onClick: onShowMore,
    title: "See more tags"
  }, "...")));
};
const TooltipContent = styled.div.withConfig({
  displayName: "Tags__TooltipContent",
  componentId: "sc-10nnf7i-0"
})(["font-size:13px;max-width:200px;"]);
const TagsWrapper = styled.div.withConfig({
  displayName: "Tags__TagsWrapper",
  componentId: "sc-10nnf7i-1"
})(["display:flex;flex-wrap:wrap;gap:10px;padding:", ";"], props => props.hasCloseButton ? '20px 35px 20px 20px' : '0');
const EllipsisTag = styled.button.withConfig({
  displayName: "Tags__EllipsisTag",
  componentId: "sc-10nnf7i-2"
})(["", ";background:", ";cursor:pointer;padding:5px 20px;word-break:initial;border:unset;text-align:center;overflow:hidden;"], LabelStyle, props => props.theme.colors.background_page);
export const BaseTag = styled.span.withConfig({
  displayName: "Tags__BaseTag",
  componentId: "sc-10nnf7i-3"
})(["", ";background:", ";padding:5px 10px;word-break:initial;text-align:center;display:inline-block;"], LabelStyle, props => props.theme.colors.background_page);