import { PerformanceStatus, DistributionOption, PaymentMethodType } from '../../../../enums';
import { InvestmentEngine, FundProvider, gateType, TenderStatus, InvestmentTypes, InvestmentStatusTypes } from '../../../../types';
export const portfolioOverviewFundYsActive = {
  userId: 109,
  accountSummary: {
    outstandingPrincipal: 0,
    outstandingPrincipalPreDefault: 0,
    outstandingPrincipalPostDefault: 0,
    accruedInvestmentInterest: 0,
    accruedInvestmentInterestPreDefault: 0,
    accruedInvestmentInterestPostDefault: 0,
    balanceInDefault: 0,
    balanceByPerformanceStatus: {
      PERFORMING: 50000.0,
      MODIFIED_OUTLOOK: 0.0,
      DEFAULT: 0.0,
      MARINE_DEFAULT: 0.0,
      PARTIAL_DEFAULT: 0.0
    },
    investmentBalance: 50000.0,
    pendingInvestments: 0.0,
    availableBalance: 0.0,
    clearedBalance: 0.0,
    pendingDeposits: 0.0,
    reserveBalance: 0,
    total: 50000.0,
    weightedInvestmentRate: 13.0,
    irr: 6.65,
    interestEarnedLast30: 0.0,
    fundsTotal: 50000.0,
    funds: [{
      investorAccountId: '57',
      noteUrlHash: 'E7hYFw',
      fundCode: 'E7hYFw',
      fundId: 'a78186b0-7e06-11ec-b93b-9720b1d8faa0',
      total: 50000.0,
      nav: 10,
      navDate: '2023-06-25',
      sharesCount: 5000.0,
      canToggleDistributionOption: false,
      dividendDistributionOption: DistributionOption.Cash,
      commitmentBreakdown: {
        unfunded: 0,
        funded: 50000.0,
        total: 50000.0
      },
      fundInvestmentStatus: 'CONFIRMED',
      fundConfig: {
        fees: {
          MEMBER_EXPENSE: {
            rate: 0.25
          },
          MANAGEMENT_FEE: {
            rate: 1
          }
        },
        feesBalanceProviders: {},
        balanceCalculationType: null,
        capitalBalanceReductionType: null,
        positionsEffectiveDistributionImpact: null
      },
      commitmentDetails: []
    }]
  },
  capital: null,
  investorEntities: [],
  interest: {
    beginningAccruedInterest: 0,
    interestEarned: 0,
    compoundInterestEarned: 0,
    interestPaid: 0,
    fexpPaid: 0,
    fexpBalance: 0,
    netPaid: 0,
    walletInterestPaid: 0,
    compoundInterestPaid: 0,
    endingAccruedInterest: 0,
    interestEarnedPreDefault: 0,
    interestEarnedPostDefault: 0,
    earningsTimeline: []
  },
  principal: {
    beginningOutstandingPrincipal: 0,
    totalInvested: 0,
    repaidPrincipal: 0,
    endingOutstandingPrincipal: 0,
    endingOutstandingPrincipalPreDefault: 0,
    endingOutstandingPrincipalPostDefault: 0,
    liquidationPaid: 0,
    liquidationDiscount: 0,
    isInDefault: false,
    principalTimeline: []
  },
  investmentDetail: {
    fundId: 'a78186b0-7e06-11ec-b93b-9720b1d8faa0',
    fundType: 'THIRD_PARTY_FUND',
    noteUrlHash: 'E7hYFw',
    title: 'Onex Structured Credit Fund I',
    distributionRate: 13.0,
    assetClass: 'PRCR',
    assetClassDescription: 'Private Credit',
    paymentType: 'Quarterly',
    termPhrase: '38 Mo.',
    advisor: 'YieldStreet Management LLC',
    subAdvisor: 'Onex',
    interestRate: 0,
    taxDocument: 'K-1',
    taxMonth: 'SEPTEMBER',
    firstYearExpense: 0,
    annualFlatExpense: 0,
    irr: 0.0,
    investedDate: '2022-02-09',
    activeDate: '2022-02-10',
    launchedDate: '2022-01-27',
    terminationDate: '2026-07-27',
    interestDefaultedDate: null,
    principalDefaultedDate: null,
    dateOfFirstDefault: null,
    originator: null,
    dividendDistributionOption: DistributionOption.Cash,
    canToggleDistributionOption: false,
    performanceStatus: PerformanceStatus.PERFORMING,
    performanceStatusNote: '',
    spvType: null,
    offeringData: {},
    investments: [{
      type: InvestmentTypes.Investment,
      dateInvested: '2022-02-09',
      dateActive: '2022-02-10',
      shareCount: 5000.0,
      sharePrice: 10,
      status: InvestmentStatusTypes.Confirmed,
      amount: 50000.0
    }],
    tenderOfferStatus: TenderStatus.UNAVAILABLE,
    tenderOfferUserEligible: false,
    tenderOfferId: null,
    totalInvestments: 50000.0,
    totalRepaid: 3544.93,
    netTotalRepaid: 3402.81,
    fundProvider: FundProvider.Ys,
    engine: InvestmentEngine.Funds
  },
  investmentGatesDetail: {
    requiresUserAttention: false,
    gates: [{
      id: '8b5edd30-89c7-11ec-ae9b-75b3b78098e4',
      irServiceInvestmentId: '77133f10-89c7-11ec-b826-e757a2a193e6',
      externalInvestmentId: 137787,
      noteUrlHash: 'E7hYFw',
      investorAccountId: '57',
      adminUserId: '',
      visibility: 'ALL',
      priority: 30,
      timestamp: 1648834292,
      date: '2022-04-01',
      type: gateType.allocationPaymentPending,
      status: 'CLEARED',
      clearReason: 'INVESTMENT_ALLOCATED',
      owner: 'YS',
      attributes: {
        paymentMethod: PaymentMethodType.SYNP
      }
    }]
  },
  wallets: [],
  investmentStrategies: []
};