export const CompleteInvestmentCopy = {
  paymentMethod: {
    wireTransferRequired: 'Wire Transfer Required.',
    walletTransactionLimitReached: 'Wallet transaction limit reached.',
    reachedMaximumTransactions: 'You have reached the maximum number of transactions allowed out of your Yieldstreet Wallet this month. You are required to use a different payment method.',
    paymentMethod: 'Payment method',
    paymentMethodPlaceholder: 'Choose Payment Method',
    authorizationText: 'I authorize Yieldstreet to debit the account indicated for the amount noted. I will not dispute Yieldstreet debiting my account, so long as the transaction corresponds to the terms in this online form and my agreement with Yieldstreet.'
  },
  ACH: {
    footnote1: `
    I authorize Yieldstreet to debit the account indicated for the amount noted on {{ACHInitiationDate}}, or thereafter, subject to the investment 
    confirmation cadence for this investment. Please ensure that required funds are available in your account. Once I authorize this payment, 
    I understand there cannot be any changes or corrections, and I agree to be bound by all applicable terms and provisions of the NACHA Rules 
    as they pertain to these transactions.`,
    footnote2: ``,
    footnote3: `Transactions made after 3:00 PM ET or on a weekend or holiday will be processed the next business day.
     It is recommended that you print a copy of this authorization and maintain it for your records.`
  }
};