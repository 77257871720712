import styled from 'styled-components';
import { Paragraph } from '../../content';
export const BadgeContainer = styled.div.withConfig({
  displayName: "BadgeV2style__BadgeContainer",
  componentId: "sc-10c4z04-0"
})(["background-color:", ";border-radius:20px;padding:6px 16px 6px 8px;display:inline-flex;align-items:center;flex-direction:row;gap:7px;width:auto;max-width:100%;overflow:hidden;min-width:40px;"], props => props.backgroundColor || props.theme.colors.background_card);
export const SParagraph = styled(Paragraph).withConfig({
  displayName: "BadgeV2style__SParagraph",
  componentId: "sc-10c4z04-1"
})(["color:", " !important;font-size:12px;white-space:nowrap;text-overflow:ellipsis;width:100%;max-width:100%;overflow:hidden;"], props => props.textColor || props.theme.colors.text_header);
export const IconContainer = styled.div.withConfig({
  displayName: "BadgeV2style__IconContainer",
  componentId: "sc-10c4z04-2"
})(["height:20px;min-width:20px;max-width:30px;"]);