import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useMemo } from 'react';
import { useRollover } from './useRollover';
export const useRolloverSheet = _ref => {
  var _rollover$rolloverDat, _rollover$rolloverDat2;
  let {
    amount,
    urlHash,
    investorId,
    destinationUrlHash
  } = _ref;
  const rollover = useRollover({
    urlHash,
    investorId,
    destinationUrlHash
  });
  const maxRolloverAmount = (rollover === null || rollover === void 0 ? void 0 : (_rollover$rolloverDat = rollover.rolloverData) === null || _rollover$rolloverDat === void 0 ? void 0 : (_rollover$rolloverDat2 = _rollover$rolloverDat.totals) === null || _rollover$rolloverDat2 === void 0 ? void 0 : _rollover$rolloverDat2.maxRolloverAmount) || 0;
  const additionalFunds = useMemo(() => {
    if (amount > maxRolloverAmount) {
      return amount - maxRolloverAmount;
    }
    return 0;
  }, [amount, maxRolloverAmount]);
  return _objectSpread(_objectSpread({}, rollover), {}, {
    isRollover: !!(rollover !== null && rollover !== void 0 && rollover.rolloverData),
    maxRolloverAmount,
    additionalFunds,
    needsAdditionalFunds: !!additionalFunds
  });
};