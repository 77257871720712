import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import maxBy from "lodash/maxBy";
import pick from "lodash/pick";
export const extractWalletInterestRate = wallets => {
  var _maxBy;
  return ((_maxBy = maxBy(wallets, 'interestRate')) === null || _maxBy === void 0 ? void 0 : _maxBy.interestRate) * 100;
};
export const extractAccountSummaryAttributes = (accountSummary, balanceByPerformanceStatus) => {
  let attributes = ['total', 'investmentBalance', 'pendingInvestments', 'clearedBalance', 'reserveBalance', 'irr', 'weightedInvestmentRate', 'funds'];
  if (balanceByPerformanceStatus) {
    attributes.push('balanceByPerformanceStatus');
  }
  return pick(_objectSpread(_objectSpread({}, accountSummary), {}, {
    balanceByPerformanceStatus
  }), attributes);
};