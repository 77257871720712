import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useCallback, useState, useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import isEmpty from "lodash/isEmpty";
import { getInvestorAccountId } from '@yieldstreet/tool-kit';
import { paymentsApi, PortfolioPaymentStatuses, PortfolioStrategyValues, PaymentStatus } from '@yieldstreet/platform-kit';
import { ALL } from 'pages/portfolio/shared/PortfolioFilters';
export const useTransactionsListData = _ref => {
  let {
    investorId,
    managementStrategy,
    params,
    userId
  } = _ref;
  const {
    0: transactionsPage,
    1: setTransactionsPage
  } = useState(0);
  const getPaymentsParams = useCallback(_ref2 => {
    let {
      page,
      status,
      pageSize,
      skipFilterStatus
    } = _ref2;
    if (!status || !userId) {
      return skipToken;
    }
    const handleStatusFilter = (params === null || params === void 0 ? void 0 : params.status) === ALL || isEmpty(params === null || params === void 0 ? void 0 : params.status) ? status : params === null || params === void 0 ? void 0 : params.status;
    return {
      investorId: getInvestorAccountId(investorId, managementStrategy),
      managementStrategy,
      noteUrlHash: (params === null || params === void 0 ? void 0 : params.noteUrlHash) === ALL || isEmpty(params === null || params === void 0 ? void 0 : params.noteUrlHash) ? undefined : params === null || params === void 0 ? void 0 : params.noteUrlHash,
      page,
      pageSize: pageSize || (params === null || params === void 0 ? void 0 : params.pageSize),
      paymentType: (params === null || params === void 0 ? void 0 : params.paymentType) === ALL || isEmpty(params === null || params === void 0 ? void 0 : params.paymentType) ? undefined : params === null || params === void 0 ? void 0 : params.paymentType,
      status: skipFilterStatus ? status : handleStatusFilter,
      userId,
      year: (params === null || params === void 0 ? void 0 : params.year) === ALL || isEmpty(params === null || params === void 0 ? void 0 : params.year) ? undefined : params === null || params === void 0 ? void 0 : params.year
    };
  }, [investorId, managementStrategy, params === null || params === void 0 ? void 0 : params.noteUrlHash, params === null || params === void 0 ? void 0 : params.pageSize, params === null || params === void 0 ? void 0 : params.paymentType, params === null || params === void 0 ? void 0 : params.status, params === null || params === void 0 ? void 0 : params.year, userId]);
  const {
    data: transactionsData,
    error: transactionsError,
    isError: transactionsIsError,
    isFetching: transactionsIsFetching
  } = paymentsApi.useGetPaymentsQuery(getPaymentsParams({
    page: transactionsPage + 1,
    status: [PortfolioPaymentStatuses.CLEARED, PortfolioPaymentStatuses.PROCESSING, PortfolioPaymentStatuses.FAILED]
  }), {
    refetchOnMountOrArgChange: managementStrategy === PortfolioStrategyValues.Discretionary
  });
  const {
    items: transactions,
    totalPages: transactionsTotalPages = 1,
    totalCount
  } = transactionsData || {};
  const {
    data: returnedTransactionsData,
    isError: returnedTransactionsIsError,
    isFetching: returnedTransactionsIsFetching
  } = paymentsApi.useGetPaymentsQuery(getPaymentsParams({
    status: PortfolioPaymentStatuses.FAILED,
    pageSize: 100,
    skipFilterStatus: true
  }), {
    refetchOnMountOrArgChange: managementStrategy === PortfolioStrategyValues.Discretionary
  });
  const {
    items: returnedTransactions
  } = returnedTransactionsData || {};
  const changeTransactionsPage = useCallback((_, newPage) => {
    if (newPage < 0 || newPage > transactionsTotalPages || newPage === transactionsPage) {
      return;
    }
    setTransactionsPage(newPage);
  }, [transactionsPage, transactionsTotalPages]);
  const sortedTransactions = useMemo(() => {
    if (transactions) {
      const newTransactions = transactions.map(transaction => {
        return _objectSpread({
          iteratorDate: transaction.status === PaymentStatus.Cleared ? transaction.clearDate : transaction.processDate
        }, transaction);
      });
      return newTransactions.sort((a, b) => new Date(b.iteratorDate).getTime() - new Date(a.iteratorDate).getTime());
    }
    return null;
  }, [transactions]);
  return {
    error: transactionsError,
    isError: transactionsIsError,
    isFetching: transactionsIsFetching,
    returnedTransactions,
    returnedTransactionsIsFetching,
    returnedTransactionsIsError,
    setTransactionsPage: changeTransactionsPage,
    totalCount,
    transactions: sortedTransactions,
    transactionsPage,
    transactionsTotalPages
  };
};