var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import Podcast from '../../assets/graphics/icons/podcast.svg';
import Cash from '../../assets/graphics/icons/commercial_finance.svg';
import Download from '../../assets/graphics/icons/file_download.svg';
import Risk from '../../assets/graphics/icons/risk.svg';
import Asset from '../../assets/graphics/icons/asset.svg';
import Structure from '../../assets/graphics/icons/structure.svg';
import Note from '../../assets/graphics/icons/note.svg';
const iconSelection = name => {
  switch (name) {
    case 'podcast':
      return Podcast;
    case 'cash':
      return Cash;
    case 'download':
      return Download;
    case 'risk':
      return Risk;
    case 'bank':
      return Asset;
    case 'structure':
      return Structure;
    case 'note':
      return Note;
    default:
      return Note;
  }
};
export const IconPicker = _ref => {
  let {
    iconName
  } = _ref;
  return __jsx(IconContainer, {
    className: "iconImage"
  }, __jsx(IconImage, {
    src: iconSelection(iconName.toLowerCase()),
    alt: "yieldstreet icon"
  }));
};
const IconContainer = styled.div.withConfig({
  displayName: "IconPicker__IconContainer",
  componentId: "sc-1sh4xn7-0"
})([""]);
const IconImage = styled.img.withConfig({
  displayName: "IconPicker__IconImage",
  componentId: "sc-1sh4xn7-1"
})(["max-width:20px;max-height:20px;height:20px;width:20px;"]);