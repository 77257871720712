import { useRef, useEffect, useCallback } from 'react';
import { poll } from './poll';

/**
 * @deprecated Please use usePollCheck instead
 */
export const usePoll = () => {
  const controls = useRef([]);
  useEffect(() => {
    return () => {
      controls.current.forEach(control => control.cancel());
      controls.current = [];
    };
  }, []);
  const safeRetry = useCallback((action, config) => {
    const control = poll(action, config);
    controls.current.push(control);
    return control.promise;
  }, []);
  return safeRetry;
};