import styled from 'styled-components';
export const OfferingCardBaseContainer = styled.div.withConfig({
  displayName: "OfferingCardBasestyle__OfferingCardBaseContainer",
  componentId: "sc-1crzhnf-0"
})(["display:flex;flex-direction:column;gap:", ";height:100%;max-height:385px;overflow:hidden;width:100%;"], props => props.theme.spacing.sm);
export const TopContainer = styled.div.withConfig({
  displayName: "OfferingCardBasestyle__TopContainer",
  componentId: "sc-1crzhnf-1"
})(["max-height:140px;overflow:hidden;width:100%;"]);
export const MainContainer = styled.div.withConfig({
  displayName: "OfferingCardBasestyle__MainContainer",
  componentId: "sc-1crzhnf-2"
})(["max-height:115px;width:100%;"]);
export const ContentContainer = styled.div.withConfig({
  displayName: "OfferingCardBasestyle__ContentContainer",
  componentId: "sc-1crzhnf-3"
})(["max-height:92px;width:100%;"]);