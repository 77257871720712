var __jsx = React.createElement;
import React, { useCallback, useRef, useState } from 'react';
import { Paragraph } from '../../content';
import { useOutsideClick } from '../../hooks/use-outside-click';
import DotsVert from '../../assets/graphics/icons/dots-vertical.svg';
import { DropdownMenuHeader, DropdownMenuIcon, DropdownMenuItem, DropdownMenuList, DropdownMenuWrapper } from './DropdownMenu.style';
export const DropdownMenu = _ref => {
  let {
    label,
    icon = DotsVert,
    options = []
  } = _ref;
  const {
    0: open,
    1: setOpen
  } = useState(false);
  const dropdownListRef = useRef(null);
  useOutsideClick(dropdownListRef, setOpen);
  const handleHeaderClick = useCallback(() => {
    setOpen(state => !state);
  }, []);
  const handleItemClick = useCallback(itemAction => {
    itemAction();
    handleHeaderClick();
  }, [handleHeaderClick]);
  return __jsx(DropdownMenuWrapper, null, __jsx(DropdownMenuHeader, {
    onClick: handleHeaderClick
  }, label && __jsx(Paragraph, null, label), __jsx(DropdownMenuIcon, {
    alt: "Menu dots",
    src: icon
  })), __jsx(DropdownMenuList, {
    ref: dropdownListRef,
    open: open
  }, options.map(action => __jsx(DropdownMenuItem, {
    key: action.label,
    onClick: () => {
      handleItemClick(action.onClick);
    }
  }, action.label))));
};