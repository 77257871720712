var __jsx = React.createElement;
import React from 'react';
import { NumericFormat } from 'react-number-format';
import { BadgeType, OfferingStatus } from '@yieldstreet/platform-kit';
import { formatProgressPercentRemaining, getOfferingTerm, isFortyActFund, isFortyActOrRegAPlus } from '@yieldstreet/tool-kit';
import { BadgeV2, theme2 } from '@yieldstreet/ui-kit';
import { IconType } from '@yieldstreet/ui-kit/legacy/elements/icon/Icon.model.ts';
import { testIds } from './OfferingListV2.model';
import marketplaceOfferingDefault from 'assets/images/images/marketplaceOfferingDefault.png';
import { buildOfferingLink } from 'utils/urlBuilder';
export const getBadgeConfig = (offering, alreadyInvestedIn) => {
  var _offering$updatedDate;
  let badges = [];
  const {
    showNewLabel,
    offeringFundingProgress: progress
  } = offering;
  const status = progress && progress.offeringStatus ? progress.offeringStatus : offering.status;
  const requestedAmount = (progress === null || progress === void 0 ? void 0 : progress.requestedAmount) || 0;
  const noteAmount = (progress === null || progress === void 0 ? void 0 : progress.noteAmount) || 0;
  const availability = requestedAmount / noteAmount;
  const rawPercentage = ((progress === null || progress === void 0 ? void 0 : progress.remainingInvestment) || 0) / noteAmount;
  const remainingPercentageFormatted = formatProgressPercentRemaining(rawPercentage, status || '');
  const isWaitlistOpen = status === OfferingStatus.open && availability >= 1;
  const isPrismOrReit = isFortyActOrRegAPlus(offering === null || offering === void 0 ? void 0 : offering.fundType);
  const isOpenOffer = status === OfferingStatus.open;
  const isAlwaysOpen = isPrismOrReit && isOpenOffer && !isWaitlistOpen;
  const updatedDate = offering === null || offering === void 0 ? void 0 : (_offering$updatedDate = offering.updatedDate) === null || _offering$updatedDate === void 0 ? void 0 : _offering$updatedDate.date;
  const isComingSoon = status === OfferingStatus.preoffering;
  if (showNewLabel) {
    badges.push(
    //@ts-expect-error
    __jsx(BadgeV2, {
      testId: testIds.badge.id,
      iconColor: theme2.colors.icon_important,
      icon: IconType.BLAST,
      text: "New",
      type: BadgeType.DEFAULT
    }));
  }
  if (alreadyInvestedIn) {
    badges.push(
    //@ts-expect-error
    __jsx(BadgeV2, {
      testId: testIds.badge.id,
      iconColor: theme2.colors.icon_personal,
      icon: IconType.CURRENCY,
      text: "Invested",
      type: BadgeType.PERSONAL
    }));
  }
  if (isComingSoon) {
    badges.push(
    //@ts-expect-error
    __jsx(BadgeV2, {
      testId: testIds.badge.id,
      iconColor: theme2.colors.icon_regular,
      icon: IconType.TIME,
      text: "Coming soon",
      type: BadgeType.DEFAULT
    }));
  }
  if (isAlwaysOpen) {
    badges.push(
    //@ts-expect-error
    __jsx(BadgeV2, {
      testId: testIds.badge.id,
      iconColor: theme2.colors.icon_regular,
      icon: IconType.CALENDAR,
      text: "Always open",
      type: BadgeType.DEFAULT
    }));
  }
  if (!isFortyActFund(offering === null || offering === void 0 ? void 0 : offering.fundType) && isOpenOffer && !isWaitlistOpen && rawPercentage <= 0.1 && !isAlwaysOpen) {
    badges.push(
    //@ts-expect-error
    __jsx(BadgeV2, {
      testId: testIds.badge.id,
      iconColor: theme2.colors.icon_important,
      icon: IconType.FLAME,
      text: `${remainingPercentageFormatted} remaining`,
      type: BadgeType.DEFAULT
    }));
  }
  if (updatedDate) {
    badges.push(
    //@ts-expect-error
    __jsx(BadgeV2, {
      testId: testIds.badge.id,
      iconColor: theme2.colors.icon_regular,
      icon: IconType.UPDATED,
      text: updatedDate,
      type: BadgeType.DEFAULT
    }));
  }

  // We show only 2 badges max. per offering
  return badges.slice(0, 2);
};
const handleBigNumber = value => {
  let formatter;
  try {
    // TODO: Remove this once we polyfill
    // @ts-ignore
    formatter = new Intl.NumberFormat('en-US', {
      notation: 'compact',
      maximumFractionDigits: 2
    });
  } catch (error) {
    formatter = new Intl.NumberFormat('en-US', {
      maximumFractionDigits: 2
    });
  }
  return formatter.format(+value);
};
export const getMarketplaceOfferingConfig = function (offering) {
  var _offering$offeringCar, _offering$offeringHer, _offering$offeringCar2;
  let isListView = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  let alreadyInvestedOfferings = arguments.length > 2 ? arguments[2] : undefined;
  const offeringAlreadyInvested = !!(alreadyInvestedOfferings !== null && alreadyInvestedOfferings !== void 0 && alreadyInvestedOfferings.find(investedOffering => investedOffering.noteUrlHash === offering.urlHash));
  const termData = getOfferingTerm(offering);
  const minInvestment = ((_offering$offeringCar = offering.offeringCardOverrides) === null || _offering$offeringCar === void 0 ? void 0 : _offering$offeringCar.minInvestmentValue) || offering.minimumPurchase;
  const termLabel = (offering === null || offering === void 0 ? void 0 : (_offering$offeringHer = offering.offeringHero) === null || _offering$offeringHer === void 0 ? void 0 : _offering$offeringHer.customTermLabel) || 'Term';
  const termValue = isFortyActOrRegAPlus(offering === null || offering === void 0 ? void 0 : offering.fundType) && isListView ? 'N/A' : `${termData.term} ${termData.termSuffix}`;
  return {
    thumbSrc: (offering === null || offering === void 0 ? void 0 : offering.thumbnailImageUrl) || marketplaceOfferingDefault,
    assetClass: (offering === null || offering === void 0 ? void 0 : (_offering$offeringCar2 = offering.offeringCardOverrides) === null || _offering$offeringCar2 === void 0 ? void 0 : _offering$offeringCar2.assetClassValue) || (offering === null || offering === void 0 ? void 0 : offering.assetClassDisplayName) || '',
    offeringTitle: offering === null || offering === void 0 ? void 0 : offering.title,
    metrics: [{
      label: 'Min. investment',
      value: __jsx(NumericFormat, {
        value: handleBigNumber(minInvestment),
        displayType: 'text',
        thousandSeparator: true,
        prefix: '$',
        suffix: minInvestment > 999 ? 'K' : ''
      })
    }, {
      label: termLabel,
      value: termValue
    }, {
      label: 'Payment schedule',
      value: offering === null || offering === void 0 ? void 0 : offering.paymentFrequencyDisplayName
    }],
    thumbAlt: offering === null || offering === void 0 ? void 0 : offering.title,
    badges: getBadgeConfig(offering, offeringAlreadyInvested),
    href: buildOfferingLink(offering),
    testId: testIds.offeringCard.id,
    urlHash: offering.urlHash
  };
};