import detailsIconSrc from './assets/details.svg';
import overviewIconSrc from './assets/overview.svg';
import premiseIconSrc from './assets/premise.svg';
import originatorIconSrc from './assets/originator.svg';
import essentialsIconSrc from './assets/essentials.svg';
import docsIconSrc from './assets/docs.svg';
import contentIconSrc from './assets/content.svg';
export const NAVIGATION_OFFSET = 50;
export const NAVIGATION_ANCHOR_ID = 'back-to-top-anchor';
export const navIcons = {
  overview: overviewIconSrc,
  premise: premiseIconSrc,
  originator: originatorIconSrc,
  essentials: essentialsIconSrc,
  docs: docsIconSrc,
  content: contentIconSrc,
  details: detailsIconSrc
};
export const testIds = {
  container: 'offering-navigation-container',
  stickyContainer: 'offering-navigation-sticky-container',
  link: 'offering-navigation-link',
  backToTop: 'offering-navigation-back-to-top-link'
};