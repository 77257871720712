var __jsx = React.createElement;
import React from 'react';
import { ExternalLink, Label, Paragraph } from '@yieldstreet/ui-kit';
import { InvestorType } from '@yieldstreet/platform-kit';
import styled from 'styled-components';
import SecurityLogo from 'sharedComponents/SecurityLogo';
import ToggleTooltip from 'sharedComponents/ToggleTooltip';
import { ADDRESS_STEP, PHONE_STEP, DETAILS_STEP, CITIZENSHIP_STEP, TAX_RESIDENCE_STEP, EMPLOYMENT_INFORMATION_STEP, ATOMIC_FORK_STEP, POLITICALLY_EXPOSED_PERSON_STEP, FINRA_AFFILIATED_STEP, CONTROL_CORPORATIONS_STEP, EXCHANGES_STEP } from '../../CreateInvestorAccount.model';
import { pageInfoConst } from './FlowWrapper.model';
import { TooltipContent } from './FlowWrapper.style';
export const getPageInfo = _ref => {
  let {
    step,
    investorAccountType = null
  } = _ref;
  let title;
  let subtitle;
  let backNavigationText;
  let backNavigation;
  let belowContent;
  switch (step) {
    case 'ira':
      title = pageInfoConst.ira.title;
      subtitle = pageInfoConst.ira.subtitle;
      belowContent = __jsx(TooltipWrapper, null, __jsx(ToggleTooltip, {
        stepLabel: "Matching your source account to YS account type",
        stepInfo: __jsx(Paragraph, null, "When setting up your Yieldstreet IRA, it\u2019s best to make sure your source account is a similar account (ie: if you select Traditional IRA for your Yieldstreet account, transfer in funds from a Traditional IRA source account). This will help reduce any delays setting up your account.")
      }), __jsx(LeftLabel, {
        small: true
      }, "In order to help the government fight the funding of terrorism and money laundering activities, Federal law requires all financial institutions to obtain, verify, and record information that identifies each client who opens an account. When you open a Yieldstreet IRA, a corresponding custodial IRA account will be opened with Pacific Premier Trust (\"PPT\"), and accordingly Yieldstreet will provide PPT with personal information such as your name, date of birth, Social Security number, and other information that will allow PPT to identify you. We may also ask to see your driver's license or other forms of identification."));
      break;
    case 'pricing':
      title = 'Now through March 31, your annual IRA fee is waived for 2023*';
      subtitle = 'In the future, we may charge an annual account fee based on your Yieldstreet IRA balance.';
      belowContent = __jsx(TooltipWrapper, null, __jsx(Label, {
        marginBottom: "sm"
      }, "*To have your annual IRA account fee waived for 2023, you must open a Yieldstreet IRA by March 31, 2023 and fund your account by June 30, 2023. Existing IRA accounts without funding or investments must be funded by June 30, 2023 to qualify for waived fees."), __jsx("br", null), __jsx("br", null), __jsx(Label, null, "Beginning in 2024, we may charge a flat annual account fee between $299-$399. The fee would be based on cumulative deposits throughout the calendar year and would be charged on a prorated basis. Investors will be notified in advance of any fee changes."), __jsx("br", null), __jsx("br", null), __jsx(Label, null, "Members of Yieldstreet Diamond are entitled to a fee-free IRA."));
      break;
    case DETAILS_STEP:
      title = pageInfoConst.details.title;
      belowContent = __jsx(TooltipWrapper, null, __jsx(ToggleTooltip, {
        stepLabel: "Why do we ask for this information?",
        stepInfo: __jsx(Paragraph, null, "The Patriot Act requires us to collect this information as part of the \u201CKnow Your Customer\u201D process. This information is also needed for tax purposes.")
      }), __jsx(SecurityLogo, null));
      break;
    case ADDRESS_STEP:
      title = pageInfoConst.address.title;
      subtitle = pageInfoConst.address.subtitle;
      belowContent = __jsx(TooltipWrapper, null, __jsx(ToggleTooltip, {
        stepLabel: "Why do we ask for this information?",
        stepInfo: __jsx(Paragraph, null, "Our Investor Relations team may use this information occasionally to contact you about important investment issues. Your data is safe and protected with Yieldstreet. We follow digital security best practices, and will never sell your information to a third party")
      }), __jsx(SecurityLogo, null));
      break;
    case PHONE_STEP:
      title = pageInfoConst.phone.title;
      subtitle = pageInfoConst.phone.subtitle;
      belowContent = __jsx(TooltipWrapper, null, __jsx(ToggleTooltip, {
        stepLabel: "Why do we ask for this information?",
        stepInfo: __jsx(Paragraph, null, "Your data is safe and protected with Yieldstreet. We follow digital security best practices and will never sell your information to a third party. Your phone number may be used for two-factor authentication or for our Investor Relations team to contact you to offer assistance with account set-up or your investments.")
      }), __jsx(SecurityLogo, null));
      break;
    case CITIZENSHIP_STEP:
      title = pageInfoConst.citizenship.title;
      subtitle = pageInfoConst.citizenship.subtitle;
      backNavigation = false;
      belowContent = __jsx(TooltipWrapper, null, __jsx(SecurityLogo, null));
      break;
    case TAX_RESIDENCE_STEP:
      title = pageInfoConst.taxResidence.title;
      subtitle = pageInfoConst.taxResidence.subtitle;
      backNavigation = false;
      belowContent = __jsx(TooltipWrapper, null, __jsx(ToggleTooltip, {
        stepLabel: "How do I answer this question?",
        stepInfo: __jsx(Paragraph, null, "All U.S. citizens are considered tax residents of the United States, so we have filled this in for you based on your previous answer. For state residency, the state where you will live for the majority of the current tax year should be entered.")
      }), __jsx(SecurityLogo, null));
      break;
    case EMPLOYMENT_INFORMATION_STEP:
      title = pageInfoConst.employmentInfo.title;
      subtitle = pageInfoConst.employmentInfo.subtitle;
      backNavigation = false;
      belowContent = __jsx(TooltipWrapper, null, __jsx(SecurityLogo, null));
      break;
    case ATOMIC_FORK_STEP:
      title = pageInfoConst.atomicFork.title;
      subtitle = pageInfoConst.atomicFork.subtitle;
      backNavigation = false;
      belowContent = __jsx(TooltipWrapper, null, __jsx(ToggleTooltip, {
        stepLabel: "What do each of these options mean?",
        stepInfo: __jsx(React.Fragment, null, __jsx(TooltipContent, null, "A Politically Exposed Person is someone who, through their prominent position or influence, is more likely to be targeted for bribery or corruption. Examples include government or high-ranking military officials, diplomats or judicial appointees. Business associates and immediate family members of such individuals are also included. For more detail, you can find a", ' ', __jsx(ExternalLink, {
          href: "https://risk.lexisnexis.com/insights-resources/article/what-is-a-politically-exposed-person",
          target: "_blank",
          rel: "noopener noreferrer"
        }, "resource on this topic here.")), __jsx(TooltipContent, null, "The term \"affiliate of a FINRA member\" refers to any entity that controls, is controlled by or is under common control with any FINRA member. For more information on this definition,", ' ', __jsx(ExternalLink, {
          href: "https://www.finra.org/sites/default/files/NoticeDocument/p347347.pdf",
          target: "_blank",
          rel: "noopener noreferrer"
        }, "please see here.")), __jsx(TooltipContent, null, "Controlling stake applies if you are an office, executive or 10% stakeholder in a public company."), __jsx(TooltipContent, null, "The term \"affiliate of an Exchange\" refers to any entity that controls, is controlled by or is under common control with any exchange member."))
      }), __jsx(SecurityLogo, null));
      break;
    case POLITICALLY_EXPOSED_PERSON_STEP:
      title = pageInfoConst.politicallyExposedPerson.title;
      subtitle = '';
      backNavigation = false;
      belowContent = __jsx(TooltipWrapper, null, __jsx(ToggleTooltip, {
        stepLabel: "What is a Politically Exposed Person?",
        stepInfo: __jsx(Paragraph, null, "A Politically Exposed Person is someone who, through their prominent position or influence, is more likely to be targeted for bribery or corruption. Examples include government or high-ranking military officials, diplomats or judicial appointees. Business associates and immediate family members of such individuals are also included. For more detail, you can find a", ' ', __jsx(ExternalLink, {
          href: "https://risk.lexisnexis.com/insights-resources/article/what-is-a-politically-exposed-person",
          target: "_blank",
          rel: "noopener noreferrer"
        }, "resource on this topic here."))
      }), __jsx(SecurityLogo, null));
      break;
    case FINRA_AFFILIATED_STEP:
      title = pageInfoConst.finraAffiliated.title;
      subtitle = '';
      backNavigation = false;
      belowContent = __jsx(TooltipWrapper, null, __jsx(ToggleTooltip, {
        stepLabel: "What is a FINRA affiliate?",
        stepInfo: __jsx(Paragraph, null, "The term \"affiliate of a FINRA member\" refers to any entity that controls, is controlled by or is under common control with any FINRA member. For more information on this definition,", ' ', __jsx(ExternalLink, {
          href: "https://www.finra.org/sites/default/files/NoticeDocument/p347347.pdf",
          target: "_blank",
          rel: "noopener noreferrer"
        }, "please see here."))
      }), __jsx(SecurityLogo, null));
      break;
    case CONTROL_CORPORATIONS_STEP:
      title = pageInfoConst.controlCorporations.title;
      subtitle = pageInfoConst.controlCorporations.subtitle;
      backNavigation = false;
      belowContent = __jsx(TooltipWrapper, null, __jsx(SecurityLogo, null));
      break;
    case EXCHANGES_STEP:
      title = pageInfoConst.exchanges.title;
      subtitle = pageInfoConst.exchanges.subtitle;
      backNavigation = false;
      belowContent = __jsx(TooltipWrapper, null, __jsx(ToggleTooltip, {
        stepLabel: "What does it mean to be affiliated with an exchange?",
        stepInfo: __jsx(Paragraph, null, "The term \"affiliate of an Exchange\" refers to any entity that controls, is controlled by or is under common control with any exchange member.")
      }), __jsx(SecurityLogo, null));
      break;
    default:
      title = __jsx(React.Fragment, null, investorAccountType === InvestorType.INDIVIDUAL ? pageInfoConst.default.individualTitle : __jsx(React.Fragment, null, pageInfoConst.default.title, __jsx("br", null), pageInfoConst.default.entityTitle));
      subtitle = pageInfoConst.default.subtitle;
      belowContent = __jsx(TooltipWrapper, null, __jsx(ToggleTooltip, {
        stepLabel: "Why do we ask for this information?",
        stepInfo: __jsx(Paragraph, null, "We ask all investors to confirm their identity as part of the required \u201CKnow Your Customer\u201D process, which you can", ' ', __jsx(ExternalLink, {
          href: "/support/article/how-does-the-yieldstreet-site-secure-my-information/",
          target: "_blank",
          rel: "noopener noreferrer"
        }, "read about here."))
      }));
      break;
  }
  return {
    title,
    subtitle,
    backNavigationText,
    backNavigation,
    belowContent
  };
};
const TooltipWrapper = styled.div.withConfig({
  displayName: "FlowWrapperutils__TooltipWrapper",
  componentId: "sc-jn3qrp-0"
})(["margin-top:60px;max-width:400px;margin:auto;text-align:left;"]);
const LeftLabel = styled(Label).withConfig({
  displayName: "FlowWrapperutils__LeftLabel",
  componentId: "sc-jn3qrp-1"
})(["text-align:left;"]);