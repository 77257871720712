import React from "react";
var __jsx = React.createElement;
import { Line, LineText, LineValue, QuestionTip } from './PipeLine.style';
import questionIcon from 'assets/icons/images/question.svg';
export const PipeLine = _ref => {
  let {
    dataCy,
    label,
    value,
    tooltipId,
    tooltipDescription,
    openModal
  } = _ref;
  return __jsx(Line, {
    "data-cy": dataCy
  }, __jsx(LineText, null, label), __jsx(LineValue, null, value), tooltipId && tooltipDescription && __jsx(QuestionTip, {
    src: questionIcon,
    onClick: () => openModal(tooltipId)
  }));
};