import _Snackbar from "@mui/material/Snackbar";
import _Slide from "@mui/material/Slide";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useState } from 'react';
import styled from 'styled-components';
import { Paragraph } from '../../content/text';
export const ToastNotification = _ref => {
  let {
    autoHideDuration = null,
    icon,
    text,
    title,
    className,
    transitionDuration
  } = _ref;
  const {
    0: open,
    1: setOpen
  } = useState(true);
  const toggleOpen = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    return setOpen(!open);
  };
  return __jsx(_Snackbar, {
    open: open,
    autoHideDuration: autoHideDuration,
    onClose: toggleOpen,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left'
    },
    TransitionComponent: props => __jsx(_Slide, _extends({}, props, {
      direction: "up",
      timeout: transitionDuration
    })),
    className: className
  }, __jsx(Wrapper, null, __jsx(IconWrapper, null, icon), __jsx(TextWrapper, null, __jsx(LabelText, {
    small: true
  }, title), __jsx(Paragraph, {
    semiBold: true,
    small: true
  }, text))));
};
const Wrapper = styled.div.withConfig({
  displayName: "ToastNotification__Wrapper",
  componentId: "sc-19caogi-0"
})(["background:", ";border-radius:5px;box-shadow:0px 2px 8px rgba(0,0,0,0.12),0px 8px 24px rgba(0,0,0,0.2);display:flex;align-items:center;min-width:335px;"], props => props.theme.colors.background_card);
const IconWrapper = styled.div.withConfig({
  displayName: "ToastNotification__IconWrapper",
  componentId: "sc-19caogi-1"
})(["align-items:center;background:", ";display:flex;justify-content:center;margin:8px;height:48px;width:48px;"], props => props.theme.colors.background_page);
const TextWrapper = styled.div.withConfig({
  displayName: "ToastNotification__TextWrapper",
  componentId: "sc-19caogi-2"
})(["margin:10px 10px 10px 0;"]);
const LabelText = styled(Paragraph).withConfig({
  displayName: "ToastNotification__LabelText",
  componentId: "sc-19caogi-3"
})(["color:", ";"], props => props.theme.colors.text_label);