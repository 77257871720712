import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["investorId"];
import { HttpMethods } from '../../../enums';
import { restApi, Tags } from '../../common';
export const iraApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.Ira]
}).injectEndpoints({
  endpoints: builder => {
    const createIraAccount = builder.mutation({
      query: data => ({
        url: `/a/api/ira/account`,
        method: HttpMethods.Post,
        data
      }),
      invalidatesTags: [Tags.Ira]
    });
    const copyIraAccount = builder.mutation({
      query: _ref => {
        let {
            investorId
          } = _ref,
          data = _objectWithoutProperties(_ref, _excluded);
        return {
          url: `/a/api/ira/account/${investorId}/copy-account`,
          method: HttpMethods.Post,
          data
        };
      },
      invalidatesTags: [Tags.Ira]
    });
    const postIraPricing = builder.mutation({
      query: _ref2 => {
        let {
          investorId,
          tier
        } = _ref2;
        return {
          url: `/a/api/ira/account/${investorId}/pricing`,
          method: HttpMethods.Post,
          data: {
            tier
          }
        };
      },
      invalidatesTags: [Tags.Ira]
    });
    return {
      createIraAccount,
      copyIraAccount,
      postIraPricing
    };
  }
});