import styled, { css } from 'styled-components';
const selectColor = _ref => {
  let {
    inverse
  } = _ref;
  if (inverse) {
    return 'text_link_inverse';
  }
  return 'text_link';
};
const DefaultLinkBackgroundStyle = css(["background-image:linear-gradient( to right,", " 45%,transparent 45%,transparent 55%,", " 55% );"], props => props.theme.colors[selectColor(props)], props => props.theme.colors[selectColor(props)]);
export const LinkTextStyles = css(["", " background-repeat:no-repeat;background-size:225% 0.1em;background-position:100% 100%;padding-bottom:1px;text-decoration:none;color:", ";"], props => props.isBreadcrumb ? '' : DefaultLinkBackgroundStyle, props => props.theme.colors[selectColor(props)]);
export const LinkTextHoverStyles = css(["background-position:0 100%;transition-property:background-position;transition-duration:0.4s;transition-timing-function:ease-in-out;color:", ";"], props => props.theme.colors[selectColor(props)]);
export const LinkTextActiveStyles = css(["background-image:linear-gradient( to right,", " 0%,", " 100% );"], props => props.theme.colors.active, props => props.theme.colors.active);
export const BreadcrumbStyle = css(["&:hover{text-decoration:underline;text-underline-position:from-font;}"]);
export const RegularLinkStyle = css(["", ""], css(["&:hover{", "}&:active{", "}"], LinkTextHoverStyles, LinkTextActiveStyles));
export const LinkStyles = css(["cursor:pointer;font-family:", ";", ""], props => props.theme.typography.secondary, css(["", " ", ""], LinkTextStyles, props => props.isBreadcrumb ? BreadcrumbStyle : RegularLinkStyle));
export const Link = styled.a.withConfig({
  displayName: "styles__Link",
  componentId: "sc-1dkmjcr-0"
})(["", ""], LinkStyles);