import _Box from "@mui/material/Box";
import _Button from "@mui/material/Button";
import _Link from "@mui/material/Link";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React, { useMemo, useState, useCallback } from 'react';
import { NewestModal } from '@yieldstreet/ui-kit';
import { AnimatePresence } from 'framer-motion';
import { getFormattedValueString, getValue } from 'utils/filters/filters';
import { Modals } from 'constants/modal.model';
import { useFilters, useQueryFilter } from 'hooks';
import { Accordion } from '../Accordion';
import { FilterView } from './FilterView';
import { testIds as setupTestId, NonSerializableOptions } from './Filters.model';
import { FilterModalContainer, FilterModalMain, FilterModalMainHeader, FilterModalMainSection, FilterModalBottom, ModalAccordionHeader, ModalAccordionValue } from './Filters.style';
export const FiltersMobileModalMainSection = _ref => {
  let {
    filter,
    search,
    onChange
  } = _ref;
  const {
    0: open,
    1: setOpen
  } = useState(false);
  const {
    type,
    key,
    config,
    options
  } = filter;
  const value = useMemo(() => {
    return getValue(key, search);
  }, [key, search]);
  const valueString = useMemo(() => {
    return getFormattedValueString({
      value,
      type,
      config,
      options
    });
  }, [value, type, config, options]);
  const AccordionHeader = useMemo(() => __jsx(ModalAccordionHeader, null, __jsx(_Typography, {
    variant: "body1",
    sx: {
      fontWeight: 'medium'
    }
  }, filter.label), __jsx(AnimatePresence, null, !open && valueString && __jsx(ModalAccordionValue, {
    initial: {
      opacity: 0
    },
    animate: {
      opacity: 1
    },
    exit: {
      opacity: 0
    }
  }, valueString))), [filter, open, valueString]);
  return __jsx(FilterModalMainSection, {
    key: `section-${filter.key}-filter`
  }, __jsx(Accordion, {
    open: open,
    onChange: v => {
      setOpen(v);
    },
    header: AccordionHeader,
    key: `${filter.key}-filter`,
    "data-cy": `${filter.key}-filter`
  }, __jsx(FilterView, {
    filter: filter,
    value: value,
    onChange: onChange
  })));
};
export const FiltersMobileContent = _ref2 => {
  let {
    filterOptions,
    onClose,
    queryFilterParams,
    testIds
  } = _ref2;
  const {
    query,
    allQueryKeys
  } = useQueryFilter(queryFilterParams);
  const {
    updateSearch,
    clearAllFilters
  } = useFilters({
    query,
    allQueryKeys
  });
  const {
    0: nextQuery,
    1: setNextQuery
  } = useState(query);
  const apply = useCallback(() => {
    updateSearch(nextQuery);
    onClose();
  }, [nextQuery, onClose, updateSearch]);
  const clear = useCallback(() => {
    clearAllFilters();
    setNextQuery({});
  }, [clearAllFilters, setNextQuery]);
  const memoizedFilters = useMemo(() => {
    const onChange = item => {
      setNextQuery(prevState => _objectSpread(_objectSpread({}, prevState), item));
    };
    return filterOptions.map(filter => __jsx(FiltersMobileModalMainSection, {
      filter: filter,
      search: nextQuery,
      onChange: onChange
    }));
  }, [filterOptions, nextQuery, setNextQuery]);
  return __jsx(FilterModalContainer, testIds.filterWrapper.attr, __jsx(FilterModalMain, null, __jsx(FilterModalMainHeader, null, "Filters"), memoizedFilters), __jsx(FilterModalBottom, null, __jsx("div", null, __jsx(_Link, _extends({
    sx: {
      whiteSpace: 'nowrap'
    },
    onClick: clear
  }, testIds.filterClearButton.attr), "Clear All")), __jsx(_Button, _extends({
    onClick: apply
  }, testIds.filterApplyButton.attr), "Apply Filters")));
};
export const FiltersMobileModal = _ref3 => {
  let {
    filterOptions,
    onClose,
    queryFilterParams,
    testId
  } = _ref3;
  const testIds = setupTestId(testId);
  return __jsx(NewestModal, {
    modalId: Modals.FiltersMobile,
    onClose: onClose
  }, __jsx(_Box, null, __jsx(FiltersMobileContent, {
    filterOptions: typeof filterOptions === 'string' ? NonSerializableOptions[filterOptions] : filterOptions,
    onClose: onClose,
    queryFilterParams: queryFilterParams,
    testIds: testIds
  })));
};