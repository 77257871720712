var __jsx = React.createElement;
import React from 'react';
import isFunction from 'lodash/isFunction';
import isPlainObject from 'lodash/isPlainObject';
import { MobileOnly, DesktopOnly } from '../../utils';
export const isSizeVariation = value => {
  return isPlainObject(value);
};
export const normalizeSizeVariation = value => {
  if (isSizeVariation(value)) {
    return value;
  } else {
    return {
      mobile: value,
      desktop: value
    };
  }
};
export const getSizeVariation = (value, isMobile) => {
  if (isSizeVariation(value)) {
    if (isMobile) {
      return value.mobile;
    } else {
      return value.desktop;
    }
  } else {
    return value;
  }
};
export const renderSizeVariation = (value, renderValue) => {
  if (isSizeVariation(value)) {
    const mobileRender = renderValue(value.mobile);
    const desktopRender = renderValue(value.desktop);
    return __jsx(React.Fragment, null, desktopRender && __jsx(DesktopOnly, null, desktopRender), mobileRender && __jsx(MobileOnly, null, mobileRender));
  } else {
    return renderValue(value);
  }
};
export const isNavigationValue = value => {
  return isFunction(value);
};
export const getNavigationValue = (value, props, item) => {
  if (isNavigationValue(value)) {
    return value(props, item);
  } else {
    return value;
  }
};