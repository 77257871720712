var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { media } from '../../../themes';
import { Paragraph } from '../../../content/text';
export const PhotoTiles = _ref => {
  let {
    title,
    image,
    cta,
    alignment = 'left',
    inverse
  } = _ref;
  return __jsx(OuterContainer, null, __jsx(ContentHolder, {
    alignment: alignment
  }, __jsx(Title, {
    semiBold: true,
    alignment: alignment,
    inverse: inverse
  }, title), cta), __jsx(ImageHolder, {
    alignment: alignment
  }, image && __jsx(ImageWrapper, null, image)));
};
const OuterContainer = styled.div.withConfig({
  displayName: "PhotoTiles__OuterContainer",
  componentId: "sc-pfbu1p-0"
})(["flex-grow:1;flex-basis:0;", ""], media.desktop`
    max-width: 280px;
    &:first-child { 
      margin: 0 10px 0 0;
    }
    &:last-child { 
      margin: 0 0 0 10px;
    }
    &:not(:first-child):not(:last-child){
      margin: 0 10px;
    }
  `);
const ContentHolder = styled.div.withConfig({
  displayName: "PhotoTiles__ContentHolder",
  componentId: "sc-pfbu1p-1"
})(["text-align:", ";background:", ";padding:20px 10px;margin-top:20px;", ""], props => props.alignment, props => props.theme.colors.table_row_background_alt, media.desktop`
    margin-top: 0;
  `);
const Title = styled(Paragraph).withConfig({
  displayName: "PhotoTiles__Title",
  componentId: "sc-pfbu1p-2"
})(["text-align:", ";margin:0 0 5px 0;", ""], props => props.alignment, media.desktop`
    margin-top: 0;
  `);
const ImageHolder = styled.div.withConfig({
  displayName: "PhotoTiles__ImageHolder",
  componentId: "sc-pfbu1p-3"
})(["text-align:", ";img{width:100%;", "}"], props => props.alignment, media.desktop`
      width: auto;
    `);
const ImageWrapper = styled.div.withConfig({
  displayName: "PhotoTiles__ImageWrapper",
  componentId: "sc-pfbu1p-4"
})([""]);