import styled, { css } from 'styled-components';
import { Heading, Paragraph } from '../../content/text';
import { media, friendlyMedia } from '../../themes';
import { SectionContainer } from '../../layout';
export const Container = styled.div.withConfig({
  displayName: "StatsViewstyle__Container",
  componentId: "sc-lwpxjj-0"
})(["border-radius:8px;overflow:hidden;display:flex;flex-direction:column;", ";"], media.desktop`
    flex-direction: row;
  `);
export const BackgroundImage = styled.div.withConfig({
  displayName: "StatsViewstyle__BackgroundImage",
  componentId: "sc-lwpxjj-1"
})(["background:url('", "');background-position:center top;", ""], _ref => {
  let {
    image
  } = _ref;
  return image;
}, media.desktop`
    width: 384px;
  `);
export const LeftContainer = styled.div.withConfig({
  displayName: "StatsViewstyle__LeftContainer",
  componentId: "sc-lwpxjj-2"
})(["width:100%;div{height:300px;background-position:center;background-size:cover;}", ";"], media.desktop`
    max-width: 384px;

    div {
      height: 100%;
    }
  `);
export const RightContainer = styled.div.withConfig({
  displayName: "StatsViewstyle__RightContainer",
  componentId: "sc-lwpxjj-3"
})(["padding:52px 24px;background:", ";", ";"], _ref2 => {
  let {
    inverse,
    theme
  } = _ref2;
  return inverse ? theme.gradients.radial_primary : theme.colors.background_light;
}, media.desktop`
    width: ${_ref3 => {
  let {
    width
  } = _ref3;
  return width ? width : '100%';
}};
    padding: ${_ref4 => {
  let {
    centerAlign
  } = _ref4;
  return centerAlign ? '64px 40px' : '95px 64px';
}};
    text-align: ${_ref5 => {
  let {
    centerAlign
  } = _ref5;
  return centerAlign ? 'center' : 'left';
}};
  `);
export const SHeading = styled(Heading).withConfig({
  displayName: "StatsViewstyle__SHeading",
  componentId: "sc-lwpxjj-4"
})(["margin-bottom:8px;"]);
export const SParagraph = styled(Paragraph).withConfig({
  displayName: "StatsViewstyle__SParagraph",
  componentId: "sc-lwpxjj-5"
})(["color:", ";"], _ref6 => {
  let {
    inverse,
    theme
  } = _ref6;
  return inverse ? theme.colors.text_header_inverse : theme.colors.text_secondary_paragraph;
});
export const SSectionContainer = styled(SectionContainer).withConfig({
  displayName: "StatsViewstyle__SSectionContainer",
  componentId: "sc-lwpxjj-6"
})(["", ""], friendlyMedia.mobile`
    ${_ref7 => {
  let {
    noMobilePadding
  } = _ref7;
  return noMobilePadding && 'padding: 0;';
}}
  `);
export const StatItem = styled.div.withConfig({
  displayName: "StatsViewstyle__StatItem",
  componentId: "sc-lwpxjj-7"
})(["display:flex;flex-direction:column;", ""], media.desktop`
    text-align: center;
    flex-basis: 200px;

    ${_ref8 => {
  let {
    hasImage
  } = _ref8;
  return hasImage ? css(["align-items:flex-start;&:first-child:nth-last-child(n + 4),&:first-child:nth-last-child(n + 4) ~ &{flex-basis:300px;}"]) : '';
}}
  `);
export const StatsWrapper = styled.div.withConfig({
  displayName: "StatsViewstyle__StatsWrapper",
  componentId: "sc-lwpxjj-8"
})(["display:flex;flex-direction:column;justify-content:space-between;flex-wrap:wrap;row-gap:16px;margin:", ";", ""], _ref9 => {
  let {
    hasImage
  } = _ref9;
  return hasImage ? '32px 0 0 0' : '40px 0 0 0';
}, media.desktop`
    flex-direction: row;
    justify-content: space-evenly;
  `);