import { restApi, Tags } from '../../common';
import { HttpMethods } from '../../../enums';
export const audienceApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.PortfolioSelfAudience]
}).injectEndpoints({
  endpoints: build => {
    const getPortfolioSelfAudience = build.query({
      query: _ref => {
        let {
          userId
        } = _ref;
        return {
          url: `/api/portfolio/self/${userId}/hasSelfPortfolioAudience`,
          method: HttpMethods.Get
        };
      },
      providesTags: [Tags.PortfolioSelfAudience]
    });
    return {
      getPortfolioSelfAudience
    };
  }
});