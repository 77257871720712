export const note = {
  id: 50654,
  domainId: 1,
  urlHash: 'hCgEDw',
  status: 'UNFN',
  offeringId: null,
  title: 'a - test - overview',
  summary: null,
  summaryTitle: null,
  description: null,
  shortName: null,
  assetClass: null,
  assetSubClass: null,
  originatorId: 0,
  partnerName: null,
  underlyingSecurity: '',
  progressMessage: null,
  bannerText: null,
  oddDayCount: 365,
  compoundInterestFrequency: 'NONE',
  paymentFrequency: null,
  interestMethod: 'ACTUAL_365',
  scheduleType: 'NONE',
  amount: 0,
  aggregateNoteAmount: 1000,
  firstPaymentDate: null,
  interestRate: 0,
  distributionRate: null,
  fundRegulatoryType: null,
  issueDate: null,
  maturityDate: null,
  effectiveMaturityDate: null,
  minimumPurchase: null,
  purchaseIncrement: null,
  maximumPurchase: null,
  yieldstreetFee: null,
  originatorFee: 0,
  fundExpensesFeeType: 'FLAT',
  term: null,
  launchedDateTime: null,
  fullyFundedDate: null,
  startDate: null,
  prefunded: false,
  offsetInterestAccrual: false,
  legalEntityId: 0,
  legalEntityName: null,
  legalEntityType: 'FUND',
  spvType: null,
  externalIdentityId: null,
  legalEntityExternalIdentity: null,
  computationEngine: 'FUNDS',
  dealStructure: 'OTHER',
  additionalPayment: true,
  dealId: null,
  newInvestorsOnly: false,
  segmented: false,
  segmentId: 0,
  visibility: 'PBLC',
  accreditationEligibility: 'ACCR',
  note506RuleType: null,
  documents: [],
  bankAccounts: [],
  descriptions: [],
  overrideJson: null,
  createDate: '2023-04-28T21:43:18Z',
  updateDate: '2023-04-28T21:43:18Z',
  maxOverAllocation: 0.1,
  allowRollover: false,
  acceptRollover: false,
  risk: 'CONSERVATIVE',
  investmentStyle: 'GROWTH',
  preventIraFromInvesting: true,
  legalAcknowledgementConfig: null,
  offeringThumbnail: null,
  heroImage: null,
  previousSeries: null,
  fundId: 'b0176540-e60d-11ed-a3be-4de05ccd2f7e',
  fundCode: 'hCgEDw',
  variableRate: false,
  distributionReinvestment: false,
  fundType: 'REG_A_PLUS',
  fundProviderType: 'THEOREM',
  firstYearExpenses: 0,
  otherYearsExpenses: 0,
  defaultExpenses: true,
  productType: 'MULTI_ASSET',
  fundConfig: {},
  autoCloseOnOverAllocated: true,
  waitlistEnabled: true,
  autoAllocationEnabled: false,
  capitalCallEnabled: false,
  taxDocument: 'TEN_99_DIV',
  taxMonth: 'MARCH',
  feeStructure: 'DEFAULT',
  capitalDrawnPercentage: null,
  capitalDrawnStrategy: null,
  evergreenEnabled: false,
  tenderOfferType: 'REIT',
  titleWithShortName: 'a - test - overview — null',
  launchedDateTimeString: null,
  launchedDateTimeFormatted: null,
  engine: 'FUNDS',
  launchedDate: null
};