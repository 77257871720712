import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import map from "lodash/map";
import omit from "lodash/omit";
import isMatch from "lodash/isMatch";
import uniqBy from "lodash/uniqBy";
import { websocketManager, SocketAuthenticate } from '../../websocket';
import { communicationApi } from './communication';
/**
 * @const getQueryParams
 * @desc Modifies the query parameters as to construct the
 * correct anchor tags. Anchor tags are required due to the use of websockets
 * in conjuction to Rest API calls for fetching messages.
 */
export const getQueryParams = params => {
  if (params.anchor) {
    const {
      id,
      timestamp
    } = params.anchor;
    return _objectSpread(_objectSpread({}, omit(params, ['anchor', 'authToken', 'refreshTimestamp'])), {
      'anchor.id': id,
      'anchor.timestamp': timestamp
    });
  }
  return omit(params, ['authToken', 'refreshTimestamp']);
};

/**
 * @const getCacheParams
 * @desc Omits page, limit and anchor parameters due to the messages being
 * aggregated as one list as part of the getMessages RTK query. The latter does
 * not dispatch any API calls whatsoever.
 */
export const getCacheParams = params => omit(params, ['page', 'limit', 'anchor']);

/**
 * @const updateMessage
 * @desc updates the message properties in the getMessages RTK query. This is namely
 * used when a message is either being marked as read or dismissed. Messages here are
 * optimisically being updated whilst an async call is performed on the backend.
 */
export const updateMessage = (messages, messageSpecId, delta) => map(messages, message => message.messageSpecId === messageSpecId ? _objectSpread(_objectSpread({}, message), delta) : message);
export const updateMessages = (messages, messageSpecIds, delta) => map(messages, message => messageSpecIds.includes(message.messageSpecId) ? _objectSpread(_objectSpread({}, message), delta) : message);

/**
 * @const updateCache
 * @desc Aggregates the incoming messages and existing messages in cache,
 * also safeguards for any potential messages with the same spec ID.
 */
export const updateCache = function (draft, delta) {
  let tail = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  const uniqByKey = 'messageSpecId';
  if (!(delta !== null && delta !== void 0 && delta.length)) {
    return draft;
  }
  return tail ? uniqBy([...draft, ...delta], uniqByKey) : uniqBy([...delta, ...draft], uniqByKey);
};

/**
 * @const updateMessageArgs
 * @desc Maps through the messages and adds the request
 * message arguments to each message.
 */
export const updateMessageArgs = function () {
  let messages = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let args = arguments.length > 1 ? arguments[1] : undefined;
  return map(messages, message => _objectSpread(_objectSpread({}, message), {}, {
    messageArgs: args,
    seen: message.read
  }));
};

/**
 * @const constructTagId
 * @desc Constructs a tagID based on FetchMessagesApiRequestParams
 */
export const constructTagId = args => JSON.stringify(args);

/**
 * @const onSocketOpen
 * @desc Autheticates the user.
 */
export const onSocketOpen = (key, authToken) => websocketManager.send(key, SocketAuthenticate(authToken));

/**
 * @const onSocketMessage
 * @desc Updates the getMessages cache upon receiving a message via the
 * web socket. Message cache is only updated if the message params matches
 * the current cache.
 */
export const onSocketMessage = (ev, dispatch, params) => {
  const message = JSON.parse(ev.data);
  const messageParams = extractMessageParams(message);
  if (isMatch(messageParams, sanitiseFetchParams(params))) {
    const messageWithArgs = updateMessageArgs([message], messageParams);
    dispatch(communicationApi.util.updateQueryData('getMessages', getCacheParams(params), draft => updateCache(draft, messageWithArgs, false)));
  }
};
const extractMessageParams = message => {
  const {
    configuration
  } = message;
  return {
    types: [message.messageType],
    investorAccountId: configuration.investorAccountId || undefined,
    noteUrlHashes: configuration.noteUrlHash ? [configuration.noteUrlHash] : undefined,
    category: configuration.category,
    read: message.read,
    dismissed: message.dismissed
  };
};

/**
 * @const sanitiseFetchParams
 * @desc Sanitises FetchMessagesApiRequestParams params as to be able
 * to match extracted message params. This is namely as we have some
 * fetch parameters which aren't available within the recieved messages.
 */
const sanitiseFetchParams = params => omit(params, ['authToken', 'updates']);