var __jsx = React.createElement;
import React from 'react';
import { ProcessInfo, ExternalProviderVisual } from '@yieldstreet/ui-kit';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import { content, testIds } from './BankLinkingIntro.model';
export const BankLinkingIntro = _ref => {
  let {
    isReady,
    onClick
  } = _ref;
  const {
    externalProviderFromIcon,
    externalProviderToIcon,
    externalProviderButton,
    externalProviderTitle,
    externalProviderSubtitle
  } = content;
  return __jsx(ProcessInfo, {
    testId: testIds.base.id,
    iconCentered: true,
    icon: __jsx(ExternalProviderVisual, {
      fromIcon: externalProviderFromIcon,
      toIcon: externalProviderToIcon,
      showArrow: true
    }),
    title: externalProviderTitle,
    buttons: __jsx(LoadingButton, {
      "data-cy": "continue-to-plaid",
      "data-tracking-id": "continue-to-plaid",
      loading: !isReady,
      disabled: !isReady,
      onClick: onClick
    }, externalProviderButton)
  }, __jsx(Typography, {
    variant: "body2",
    color: "textSecondary"
  }, externalProviderSubtitle));
};