import { useMemo } from 'react';
import isNumber from "lodash/isNumber";
import Big from 'big.js';
export const useInvestmentsBalanceBreakdown = accountSummary => {
  var _accountSummary$balan, _accountSummary$balan2;
  const defaultBalance = useMemo(() => {
    const {
      DEFAULT,
      MARINE_DEFAULT,
      PARTIAL_DEFAULT
    } = (accountSummary === null || accountSummary === void 0 ? void 0 : accountSummary.balanceByPerformanceStatus) || {
      DEFAULT: 0,
      MARINE_DEFAULT: 0,
      PARTIAL_DEFAULT: 0
    };
    const values = [DEFAULT, MARINE_DEFAULT, PARTIAL_DEFAULT];
    let balance = new Big(0);
    values.forEach(value => {
      if (isNumber(value)) balance = balance.plus(value);
    });
    return balance.toNumber();
  }, [accountSummary === null || accountSummary === void 0 ? void 0 : accountSummary.balanceByPerformanceStatus]);
  return {
    active: (accountSummary === null || accountSummary === void 0 ? void 0 : (_accountSummary$balan = accountSummary.balanceByPerformanceStatus) === null || _accountSummary$balan === void 0 ? void 0 : _accountSummary$balan.PERFORMING) || 0,
    default: defaultBalance,
    pending: (accountSummary === null || accountSummary === void 0 ? void 0 : accountSummary.pendingInvestments) || 0,
    reserve: (accountSummary === null || accountSummary === void 0 ? void 0 : accountSummary.reserveBalance) || 0,
    total: accountSummary === null || accountSummary === void 0 ? void 0 : accountSummary.total,
    watchlist: (accountSummary === null || accountSummary === void 0 ? void 0 : (_accountSummary$balan2 = accountSummary.balanceByPerformanceStatus) === null || _accountSummary$balan2 === void 0 ? void 0 : _accountSummary$balan2.MODIFIED_OUTLOOK) || 0
  };
};