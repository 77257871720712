import styled from 'styled-components';
import { FormRadioGroupInput, media } from '@yieldstreet/ui-kit';
export const FormWrapper = styled.div.withConfig({
  displayName: "ETCKYCstyle__FormWrapper",
  componentId: "sc-1sk5r0j-0"
})(["max-width:400px;margin:auto;", ""], media.tablet`
        width: 400px;
    `);
export const SubmitWrapper = styled.div.withConfig({
  displayName: "ETCKYCstyle__SubmitWrapper",
  componentId: "sc-1sk5r0j-1"
})(["display:block;"]);
export const ButtonContainer = styled.div.withConfig({
  displayName: "ETCKYCstyle__ButtonContainer",
  componentId: "sc-1sk5r0j-2"
})(["display:flex;flex-direction:column;margin-top:", ";> button:first-of-type{margin-bottom:10px;}"], _ref => {
  let {
    theme
  } = _ref;
  return theme.spacing.ml;
});
export const SFormRadioGroupInput = styled(FormRadioGroupInput).withConfig({
  displayName: "ETCKYCstyle__SFormRadioGroupInput",
  componentId: "sc-1sk5r0j-3"
})([".MuiFormGroup-root{flex-direction:row;}"]);
export const OptionContainer = styled.div.withConfig({
  displayName: "ETCKYCstyle__OptionContainer",
  componentId: "sc-1sk5r0j-4"
})(["margin:40px 0;"]);
export const CheckboxesWrapper = styled.div.withConfig({
  displayName: "ETCKYCstyle__CheckboxesWrapper",
  componentId: "sc-1sk5r0j-5"
})(["background:", ";padding:", ";text-align:left;margin-bottom:", ";"], props => props.theme.colors.background_card, props => props.theme.spacing.sm, props => props.theme.spacing.sm);
export const CheckboxesWrapperTtitle = styled.div.withConfig({
  displayName: "ETCKYCstyle__CheckboxesWrapperTtitle",
  componentId: "sc-1sk5r0j-6"
})(["display:flex;flex-direction:column;margin-bottom:", ";"], props => props.theme.spacing.sm);