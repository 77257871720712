import { useMemo } from 'react';
import { investmentsApi } from '@yieldstreet/platform-kit';
import { getInvestorAccountId } from '@yieldstreet/tool-kit';
import { ALL_INVESTMENTS } from './usePortfolioInvestmentsFilter.model';
export const usePortfolioInvestmentsFilter = _ref => {
  let {
    investorId,
    userId,
    managementStrategy,
    hideAll = false
  } = _ref;
  const {
    data,
    isError,
    isFetching
  } = investmentsApi.useGetInvestmentsByStatusQuery({
    investorAccountId: getInvestorAccountId(investorId, managementStrategy),
    userId,
    managementStrategy
  });
  const investmentsOptions = useMemo(() => {
    /**
     * Return All investments only in early exit.
     */
    if (!data || isError || isFetching) {
      return hideAll ? [] : [ALL_INVESTMENTS];
    }

    /**
     * Get all the user's investments.
     */
    const {
      ACTIVE,
      MATURED,
      PENDING
    } = data.byStatus || {};
    const activeInvestments = (ACTIVE === null || ACTIVE === void 0 ? void 0 : ACTIVE.investments) || [];
    const maturedInvestments = (MATURED === null || MATURED === void 0 ? void 0 : MATURED.investments) || [];
    const pendingInvestments = (PENDING === null || PENDING === void 0 ? void 0 : PENDING.investments) || [];
    const allInvestments = [...activeInvestments, ...maturedInvestments, ...pendingInvestments];

    /**
     * Created a sorted list of investments (by name),
     * formatted according to the expected options shape.
     */
    const sortedInvestments = allInvestments === null || allInvestments === void 0 ? void 0 : allInvestments.reduce((acc, cur) => {
      const alreadyInAcc = acc === null || acc === void 0 ? void 0 : acc.find(el => el.value === cur.noteUrlHash);
      if (!alreadyInAcc) {
        acc.push({
          label: cur.noteTitle,
          value: cur.noteUrlHash,
          id: cur.noteUrlHash //needed to be used by filters
        });
      }
      return acc;
    }, []).sort((a, b) => {
      var _a$label, _b$label;
      return ((_a$label = a.label) === null || _a$label === void 0 ? void 0 : _a$label.toUpperCase()) < ((_b$label = b.label) === null || _b$label === void 0 ? void 0 : _b$label.toUpperCase()) ? -1 : 1;
    });
    if (hideAll) {
      return sortedInvestments;
    }
    return [ALL_INVESTMENTS, ...sortedInvestments];
  }, [data, isError, isFetching, hideAll]);
  return {
    investmentsOptions,
    isFetchingInvestments: isFetching
  };
};