var __jsx = React.createElement;
import React, { useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { getDocumentsApi, PortfolioDocumentType } from '@yieldstreet/platform-kit';
import { Paragraph, Heading } from '@yieldstreet/ui-kit';
import { Form } from 'formik';
import styled from 'styled-components';
import { useAdvice } from 'utils/advice';
import { EligibilityFields, InvestmentPreferencesFields } from '../fields';
import { FormHeaderWrapper, SubmitButtonWrapper, SubmitButton, FormBody, Wrapper } from './Forms.style';
import { testIds } from './RecommendationsForm.model';
import { useUserState } from 'utils/hooks';
const RecommendationsForm = _ref => {
  var _documentsData$docume, _documentsData$docume2, _documentsData$docume3, _documentsData$docume4;
  let {
    formikProps,
    answersData
  } = _ref;
  const {
    useGetDocumentsQuery
  } = getDocumentsApi;
  const {
    isAdviceClient
  } = useAdvice();
  const {
    id
  } = useUserState() || {};
  const documentConfig = useMemo(() => {
    return id ? {
      userId: id,
      type: PortfolioDocumentType.ACLI
    } : skipToken;
  }, [id]);
  const {
    data: documentsData
  } = useGetDocumentsQuery(documentConfig);
  const documentUrl = documentsData === null || documentsData === void 0 ? void 0 : (_documentsData$docume = documentsData.documents) === null || _documentsData$docume === void 0 ? void 0 : (_documentsData$docume2 = _documentsData$docume[0]) === null || _documentsData$docume2 === void 0 ? void 0 : _documentsData$docume2.url;
  const hasDocument = Boolean(documentsData === null || documentsData === void 0 ? void 0 : (_documentsData$docume3 = documentsData.documents) === null || _documentsData$docume3 === void 0 ? void 0 : (_documentsData$docume4 = _documentsData$docume3[0]) === null || _documentsData$docume4 === void 0 ? void 0 : _documentsData$docume4.url);
  const showClientAgreement = hasDocument && isAdviceClient;
  return __jsx(Wrapper, null, __jsx(FormHeaderWrapper, null, __jsx(Heading, {
    type: 3
  }, "Recommendations"), showClientAgreement ? __jsx(Paragraph, {
    "data-cy": testIds.clientAgreement
  }, "As a Yieldstreet client,", ' ', __jsx(DownloadLink, {
    href: documentUrl,
    target: "_blank",
    rel: "noopener noreferrer"
  }, "click here to access a copy of your client agreement.")) : __jsx(Paragraph, {
    "data-cy": testIds.titleDescription
  }, "These preferences may be used to offer investment recommendations.")), __jsx(FormBody, null, __jsx(Form, null, __jsx(InvestmentPreferencesFields, {
    formikProps: formikProps
  }), __jsx(EligibilityFields, {
    formikProps: formikProps,
    answersData: answersData
  }), __jsx(SubmitButtonWrapper, null, __jsx(SubmitButton, {
    type: "submit",
    disabled: !formikProps.dirty,
    loading: formikProps.isSubmitting
  }, "Save changes")))));
};
const DownloadLink = styled.a.withConfig({
  displayName: "RecommendationsForm__DownloadLink",
  componentId: "sc-1fxceo0-0"
})([""]);
export { RecommendationsForm };