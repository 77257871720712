var __jsx = React.createElement;
import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Container, Content, HeadWrapper, SubHeadingWrapper } from './Cardbox.style';
export const Cardbox = _ref => {
  let {
    children,
    extra,
    subHeading,
    testId,
    title
  } = _ref;
  return __jsx(Container, {
    testId: testId,
    withPadding: false
  }, (extra || subHeading || title) && __jsx(HeadWrapper, null, __jsx(Stack, {
    sx: {
      flexGrow: 2
    }
  }, __jsx(Typography, {
    variant: "h4"
  }, title), subHeading && __jsx(SubHeadingWrapper, null, subHeading)), extra && __jsx(Box, null, extra)), __jsx(Content, {
    className: "cardbox-content"
  }, children));
};