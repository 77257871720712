var __jsx = React.createElement;
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import ContainerSection from 'sharedComponents/JsonPages/ContainerSection';
import { getContainerNavigationProps } from '../Navigation';
export const OfferingV5ContainerSection = _ref => {
  let {
    container,
    sections,
    userState,
    dataCy,
    onViewChange
  } = _ref;
  const {
    slug
  } = getContainerNavigationProps(container);
  return __jsx(React.Fragment, null, __jsx(VisibilitySensor, {
    offset: {
      top: 300,
      bottom: 300
    },
    partialVisibility: true,
    scrollCheck: true,
    onChange: args => {
      onViewChange(slug, args);
    }
  }, __jsx(ContainerSection, {
    id: slug,
    dataCy: dataCy,
    container: container,
    sections: sections,
    isOffering: true,
    userState: userState
  })));
};