import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["topBar", "title", "subtitle", "secondary", "icon"];
var __jsx = React.createElement;
import React, { useMemo } from 'react';
import { Label, Paragraph } from '../../content';
import { Tooltip } from '../../elements';
import { OfferingTitle } from '../offering-title';
import { TopBar, Wrapper, Content, Secondary, Subtitle, SecondaryItem, SecondaryItemTitle, SecondaryItemContent, DividerItem, SecondaryItemTitleTooltipWrapper, SecondaryViewPort } from './OfferingBanner.style';
import { useTestIds } from './OfferingBanner.model';
export const OfferingBanner = _ref => {
  let {
      topBar,
      title,
      subtitle,
      secondary,
      icon
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  const testIds = useTestIds(props);
  const secondaryItems = useMemo(() => {
    return secondary === null || secondary === void 0 ? void 0 : secondary.map((_ref2, idx) => {
      let {
        title,
        content,
        tooltip
      } = _ref2;
      return __jsx(React.Fragment, {
        key: `${testIds.contentSecondaryItem.id}-${idx}`
      }, __jsx(SecondaryItem, testIds.contentSecondaryItem.attr, __jsx(SecondaryItemTitle, null, __jsx(Label, {
        small: true,
        inverse: true
      }, title), tooltip && __jsx(SecondaryItemTitleTooltipWrapper, null, __jsx(Tooltip, {
        testId: testIds.contentToolTip.id
      }, tooltip))), __jsx(SecondaryItemContent, {
        inverse: true
      }, content)), __jsx(DividerItem, null));
    });
  }, [secondary, testIds]);
  return __jsx(Wrapper, testIds.base.attr, __jsx(TopBar, testIds.topBar.attr, __jsx(Paragraph, {
    size: "chart"
  }, topBar)), __jsx(Content, testIds.content.attr, __jsx(OfferingTitle, {
    icon: icon,
    subtitle: __jsx(Subtitle, _extends({
      inverse: true
    }, testIds.contentSubtitle.attr), subtitle),
    title: title,
    inverse: true,
    testId: testIds.contentMain.id
  }), __jsx(Secondary, testIds.contentSecondary.attr, __jsx(SecondaryViewPort, null, secondaryItems))));
};