import styled from 'styled-components';
import { media } from '../../themes';
export const MarketingContainer = styled.div.withConfig({
  displayName: "MarketingContainer",
  componentId: "sc-17sds9u-0"
})(["--sectionContainerPaddingVertical:24px;--sectionContainerPaddingHorizontal:24px;--h1FontWeight:400;--h1FontSize:30px;--h1LineHeight:38px;--h1LetterSpacing:-1px;--h2FontWeight:400;--h2FontSize:24px;--h2LineHeight:30px;--h2LetterSpacing:-0.5px;--h3FontWeight:400;--h3FontSize:20px;--h3LineHeight:26px;--h3LetterSpacing:normal;--h4FontWeight:500;--h4FontSize:18px;--h4LineHeight:24px;--h4LetterSpacing:0.3px;--h5FontWeight:500;--h5FontSize:18px;--h5LineHeight:24px;--h5LetterSpacing:0.3px;", " ", ""], media.desktop`
    --h1FontWeight: 500;
    --h1FontSize: 42px;
    --h1LineHeight: 48px;

    --h2FontWeight: 400;
    --h2FontSize: 32px;
    --h2LineHeight: 38px;

    --h3FontWeight: 500;
    --h3FontSize: 22px;
    --h3LineHeight: 28px;

    --h4FontWeight: 400;
    --h4FontSize: 18px;
    --h4LineHeight: 24px;

    --h5FontWeight: 400;
    --h5FontSize: 18px;
    --h5LineHeight: 24px;
  `, media.large`
    --sectionContainerPaddingVertical: 49px;
    --sectionContainerPaddingHorizontal: 49px;
  `);