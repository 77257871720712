var __jsx = React.createElement;
import React from 'react';
import styled, { css } from 'styled-components';
import { media } from '../../themes/media';
import { Heading, Paragraph } from '../../content';
import InverseCloseIcon from '../../assets/graphics/icons/close-icon-inverse.svg';
export const PromoBanner = _ref => {
  let {
    testId,
    image,
    imageFull,
    imageFullBasis,
    title,
    content,
    cta,
    inverse,
    onCloseClick
  } = _ref;
  return __jsx(BannerContainer, {
    inverse: inverse,
    "data-cy": `${testId ? testId : 'banner'}`
  }, __jsx(CloseButton, {
    src: InverseCloseIcon,
    onClick: onCloseClick
  }), __jsx(BannerContent, {
    imageFull: imageFull,
    bannerBasis: imageFullBasis ? `${100 - imageFullBasis}%` : 'unset'
  }, __jsx(BannerText, null, __jsx(Title, {
    inverse: inverse,
    type: 4
  }, title), __jsx(Description, {
    inverse: inverse
  }, content), cta && __jsx(CTAHolder, null, cta))), image && __jsx(ImageWrapper, {
    imageFull: imageFull
  }, __jsx(Image, {
    imageFull: imageFull,
    src: image
  })));
};
const BannerContainer = styled.div.withConfig({
  displayName: "PromoBanner__BannerContainer",
  componentId: "sc-yoowz4-0"
})(["position:relative;display:inline-flex;width:100%;flex-direction:column-reverse;", " background:", ";flex:1;align-items:stretch;"], media.desktop`
    flex-direction: row;
  `, props => props.inverse ? props.theme.colors.background_inverse : props.theme.colors.background_card);
const BannerContent = styled.div.withConfig({
  displayName: "PromoBanner__BannerContent",
  componentId: "sc-yoowz4-1"
})(["display:flex;flex-direction:row;justify-content:space-between;padding:20px;", ""], media.desktop`
    ${props => props.imageFull && `flex-basis: ${props.bannerBasis};`}
    padding: 40px;
  `);
const BannerText = styled.div.withConfig({
  displayName: "PromoBanner__BannerText",
  componentId: "sc-yoowz4-2"
})(["display:flex;flex-direction:column;"]);
const Title = styled(Heading).withConfig({
  displayName: "PromoBanner__Title",
  componentId: "sc-yoowz4-3"
})(["margin-bottom:20px;"]);
const Description = styled(Paragraph).withConfig({
  displayName: "PromoBanner__Description",
  componentId: "sc-yoowz4-4"
})(["flex-direction:column;flex-grow:1;margin-bottom:30px;", ""], media.desktop`
    flex-grow: 0;
  `);
const CTAHolder = styled.div.withConfig({
  displayName: "PromoBanner__CTAHolder",
  componentId: "sc-yoowz4-5"
})(["button{width:100%;", ";}"], media.desktop`
      width: auto;
    `);
const ImageWrapperFull = css(["", ";"], props => props.imageFull && css(["padding:0;flex:1;"]));
const ImageFull = css(["", ";"], props => props.imageFull && css(["object-fit:cover;"]));
const ImageWrapper = styled.div.withConfig({
  displayName: "PromoBanner__ImageWrapper",
  componentId: "sc-yoowz4-6"
})(["display:inline-flex;padding:30px 20px;padding-bottom:0;", " ", ""], ImageWrapperFull, media.desktop`
    padding: 40px;
    padding-left: 0;
    ${ImageWrapperFull}
  `);
const Image = styled.img.withConfig({
  displayName: "PromoBanner__Image",
  componentId: "sc-yoowz4-7"
})(["width:100%;", ""], ImageFull);
const CloseButton = styled.img.withConfig({
  displayName: "PromoBanner__CloseButton",
  componentId: "sc-yoowz4-8"
})(["cursor:pointer;position:absolute;width:15px;top:15px;right:15px;"]);