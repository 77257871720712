var _window, _window2, _window2$navigator, _window3, _window4, _window4$location;
var __jsx = React.createElement;
// Polyfill for support of native ES6 functions on IE9 forward
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';

// Polyfill for preload support for stylesheets on Firefox
import 'fg-loadcss/dist/cssrelpreload';
import React, { useEffect, StrictMode } from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { FrontloadProvider, createFrontloadState } from 'react-frontload';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { loadableReady } from '@loadable/component';
import { init } from '@sentry/browser';
import browser from 'browser-detect';
import { createBrowserHistory } from 'history';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import '@castleio/castle-js/dist/castle.browser.js';
import App from './App';
import createStore from './store/createStore';
import { unregister } from './registerServiceWorker';
import setupAxios from './setupAxios';
import setupClient from './setupClient';
import { ReCaptchaProvider } from './sharedComponents/ReCaptcha';
import ENV_CONFIG, { initEnvConfig } from './utils/env/config';
import { MobileAppContext } from './utils/mobileApp';
import { ignoreErrors } from './utils/SentrySetup';
/* global _castle */
_castle('setAppId', ENV_CONFIG.APP_ID);

/* eslint-disable react-hooks-ssr/react-hooks-global-ssr */
const appContainer = document.getElementById('root');
const publicPath = appContainer.dataset.publicPath;
const isServerRendered = appContainer.hasChildNodes() === true && !!((_window = window) !== null && _window !== void 0 && _window._serverPreload);
const userAgent = (_window2 = window) === null || _window2 === void 0 ? void 0 : (_window2$navigator = _window2.navigator) === null || _window2$navigator === void 0 ? void 0 : _window2$navigator.userAgent;
const isMobileApp = userAgent === 'yieldstreet-mobile';

// set the environment config based on the hostname.
// refer to `./utils/env` for more information.
initEnvConfig(((_window3 = window) === null || _window3 === void 0 ? void 0 : _window3.__PROXY_HOST__) || ((_window4 = window) === null || _window4 === void 0 ? void 0 : (_window4$location = _window4.location) === null || _window4$location === void 0 ? void 0 : _window4$location.host));
ENV_CONFIG.SENTRY_DSN && init({
  dsn: ENV_CONFIG.SENTRY_DSN,
  ignoreErrors
});
const browserHistory = createBrowserHistory({
  basename: publicPath
});
setupClient({
  browserHistory
});
const key = 'css';
const cache = createCache({
  key,
  prepend: true
});
const ClientApp = () => {
  useEffect(() => {
    if (isServerRendered) {
      const jssStyles = document.querySelector('#jss-server-side');
      if (jssStyles) {
        jssStyles.parentElement.removeChild(jssStyles);
      }
    }
  }, []);
  const store = createStore();
  setupAxios(store);
  const frontloadState = createFrontloadState.client({
    context: {},
    logging: false
  });
  return __jsx(StrictMode, null, __jsx(Provider, {
    store: store
  }, __jsx(Router, {
    history: browserHistory
  }, __jsx(FrontloadProvider, {
    initialState: frontloadState
  }, __jsx(ReCaptchaProvider, null, __jsx(MobileAppContext.Provider, {
    value: isMobileApp
  }, __jsx(CacheProvider, {
    value: cache
  }, __jsx(App, {
    publicPath: publicPath
  }))))))));
};
const renderApp = () => {
  // Check for browser support
  const result = browser();
  if (result.name === 'ie') {
    window.location.replace('/unsupported-browser');
    return;
  }
  if (isServerRendered) {
    loadableReady(() => {
      hydrateRoot(appContainer, __jsx(ClientApp, null), {
        onRecoverableError: (error, errorInfo) => {
          if (false) {
            // eslint-disable-next-line no-console
            console.error('Uncaught hydration', error, errorInfo.componentStack);
          }
        }
      });
    });
  } else {
    const root = createRoot(appContainer); // createRoot(container!) for TypeScript
    root.render(__jsx(ClientApp, null));
  }
};
renderApp();
unregister();
if (module.hot) {
  module.hot.accept();
}