import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var __jsx = React.createElement;
import React, { useMemo } from 'react';
import Step from '@material-ui/core/Step';
import { ProgressBarV2Status, useTestIds } from './ProgressBarV2.model';
import { CustomConnector, CustomStepLabel, CustomStepper, CustomStepContent } from './ProgressBarV2.style';
import { checkNextStepActive, getStepIcon, isCompleteStatus } from './ProgressBarV2.utils';
import { Label, Paragraph } from '../../content';
export const ProgressBarV2 = _ref => {
  let {
    orientation = 'horizontal',
    steps,
    testId = 'progress-bar-v2'
  } = _ref;
  const testIds = useTestIds({
    testId
  });
  const isVertical = useMemo(() => orientation === 'vertical', [orientation]);
  const renderSteps = useMemo(() => steps.map((_ref2, idx) => {
    let {
      label,
      description,
      status
    } = _ref2;
    return __jsx(Step, {
      completed: status === ProgressBarV2Status.CLEARED,
      active: status === ProgressBarV2Status.ACTIVE,
      key: label,
      expanded: isVertical
    }, __jsx(CustomStepLabel, {
      StepIconComponent: props => getStepIcon(_objectSpread(_objectSpread({}, props), {}, {
        status,
        testId
      }))
    }, __jsx(Paragraph, _extends({
      size: "small",
      semiBold: isCompleteStatus(status),
      marginBottom: !isVertical && 'xs'
    }, testIds.label.attr), label), !isVertical && __jsx(Label, _extends({
      small: true
    }, testIds.description.attr), description)), isVertical && __jsx(CustomStepContent, {
      completed: checkNextStepActive(steps, idx),
      last: idx === steps.length - 1
    }, __jsx(Label, _extends({
      small: true
    }, testIds.description.attr), description)));
  }), [isVertical, steps, testId, testIds]);
  return __jsx(CustomStepper, _extends({
    orientation: isVertical ? 'vertical' : 'horizontal',
    alternativeLabel: !isVertical,
    connector: __jsx(CustomConnector, null)
  }, testIds.container.attr), renderSteps);
};