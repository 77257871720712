import { getGateData } from '../getGateData';
/**
 * Returns url for investments with action items.
 */
export const getTrackInvestmentUrl = _ref => {
  let {
    record,
    recordGates
  } = _ref;
  if (!record || !recordGates) {
    return;
  }
  const gate = getGateData({
    record,
    recordGates
  });
  return gate ? `/track-investment/${record.noteUrlHash}/${gate.irServiceInvestmentId}` : '';
};