var __jsx = React.createElement;
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { HeroBanner, Paragraph, Heading, PageLoader } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
import { isDev, isLocal } from 'utils/env';
const NotFound = _ref => {
  let {
    redirectExternal,
    location
  } = _ref;
  const doRedirect = !(isDev || isLocal);
  useEffect(() => {
    if (doRedirect) {
      const redirectUrl = redirectExternal ? location.pathname.toLowerCase() : '/404';
      window.location.replace(redirectUrl);
    }
  }, [redirectExternal, doRedirect, location]);
  return doRedirect ? __jsx(PageLoader, null) : __jsx(Wrapper, {
    "data-cy": "not-found"
  }, __jsx(HeroBanner, {
    title: `${redirectExternal ? 'External page' : 'Not found'}`,
    bannerSize: "extralarge",
    inverse: true
  }, __jsx(Content, null, __jsx(Heading, {
    type: 3,
    inverse: true
  }, location.pathname)), __jsx(Content, null, __jsx(Paragraph, {
    inverse: true
  }, "You ", __jsx("strong", null, "SHOULD NOT"), " see this page if you are accessing the app on QA/STG/SBX/PROD environments. ", __jsx("strong", null, "Please report a bug if this is the case. "))), __jsx(Content, null, __jsx(Paragraph, {
    inverse: true
  }, "You ", __jsx("strong", null, "SHOULD ONLY"), " see this page if the app is running in a development environment, to prevent external redirects.")), __jsx(Content, null, __jsx(Heading, {
    type: 5,
    inverse: true
  }, "Otherwise, this route", ' ', redirectExternal ? `should redirect externally with a page reload` : `should not reach this app, will redirect to a 404`, "."))));
};
const Wrapper = styled.div.withConfig({
  displayName: "NotFound__Wrapper",
  componentId: "sc-1wy18sn-0"
})(["background-color:", ";position:absolute;height:100%;width:100%;"], props => props.theme.colors.background_inverse);
const Content = styled.div.withConfig({
  displayName: "NotFound__Content",
  componentId: "sc-1wy18sn-1"
})(["margin-bottom:20px;"]);
export default withRouter(NotFound);