import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var __jsx = React.createElement;
import React, { useEffect, useMemo } from 'react';
import { usePlaidBankAccountModal } from './usePlaidBankAccountModal';
export const PlaidBankAccountModal = _ref => {
  let {
    onClose,
    onSuccess,
    investorEntityId,
    bankAccountId,
    showPlaidModal
  } = _ref;
  const config = useMemo(() => {
    if (!showPlaidModal) {
      return {
        investorEntityId: ''
      };
    }
    return {
      investorEntityId,
      bankAccountId
    };
  }, [investorEntityId, bankAccountId, showPlaidModal]);
  const {
    ready,
    open
  } = usePlaidBankAccountModal(_objectSpread({
    onClose,
    onSuccess
  }, config));

  // This will open only in case of microdeposit flow
  useEffect(() => {
    if (showPlaidModal && ready && config.investorEntityId && config.bankAccountId) {
      open();
    }
  }, [open, ready, config, showPlaidModal]);
  return __jsx(React.Fragment, null);
};