import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { Link } from 'react-router-dom';
import { intercomTargetAttr } from '@yieldstreet/tool-kit';
import { Td } from '../Table.style';
export const TableCell = _ref => {
  let {
    children,
    intercomTarget = null,
    islastCell,
    testAttr,
    to,
    padding
  } = _ref;
  return __jsx(Td, _extends({
    padding: padding,
    style: {
      textAlign: islastCell ? 'right' : 'left'
    }
  }, intercomTargetAttr(intercomTarget), testAttr), to ? __jsx(Link, {
    style: {
      display: 'block'
    },
    to: to
  }, children) : children);
};