var __jsx = React.createElement;
import React from 'react';
import { SpinnerWrapper } from './Spinner.style';
export const Spinner = _ref => {
  let {
    disabled,
    inverse,
    small,
    testId = 'uik-spinner'
  } = _ref;
  return __jsx(SpinnerWrapper, {
    "data-cy": testId,
    disabled: disabled,
    inverse: inverse,
    small: small
  });
};