var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { BadgeIcons } from './badge.model';
import { Paragraph, Label } from '../../content';
import flameIcon from '../../assets/graphics/icons/flame.svg';
import updateIcon from '../../assets/graphics/icons/update.svg';
import newIcon from '../../assets/graphics/icons/new_badge.svg';
import calendarIcon from '../../assets/graphics/icons/calendar_badge.svg';
import hourglassIcon from '../../assets/graphics/icons/hourglass.svg';
const getIcon = iconName => {
  switch (iconName) {
    case BadgeIcons.FLAME:
      return flameIcon;
    case BadgeIcons.UPDATE:
      return updateIcon;
    case BadgeIcons.NEW:
      return newIcon;
    case BadgeIcons.ALWAYS:
      return calendarIcon;
    case BadgeIcons.LAUNCHING:
      return hourglassIcon;
  }
};
export const Badge = _ref => {
  let {
    isTag = false,
    config,
    className,
    dataCy = 'badge'
  } = _ref;
  const {
    text,
    icon
  } = config;
  if (!text) return null;
  if (isTag) {
    dataCy = dataCy == 'badge' ? 'tag' : dataCy;
    return __jsx(TagContainer, {
      className: className,
      "data-cy": dataCy
    }, __jsx(Label, {
      "data-cy": `${dataCy}-text`
    }, text));
  }
  return __jsx(BadgeContainer, {
    "data-cy": dataCy
  }, icon && __jsx(Icon, {
    "data-cy": `${dataCy}-icon`,
    src: getIcon(icon)
  }), __jsx(SParagraph, {
    "data-cy": `${dataCy}-text`,
    small: true,
    semiBold: true
  }, text));
};
const BadgeContainer = styled.div.withConfig({
  displayName: "badge__BadgeContainer",
  componentId: "sc-18zhdo5-0"
})(["background:", ";border:1px solid ", ";border-radius:4px;padding:5px 10px;display:flex;align-items:center;flex-direction:row;flex-wrap:nowrap;min-height:26px;"], props => props.theme.colors.background_card, props => props.theme.colors.border_default);
const TagContainer = styled.div.withConfig({
  displayName: "badge__TagContainer",
  componentId: "sc-18zhdo5-1"
})(["background:", ";border-radius:1px;padding:5px 8px;display:flex;align-items:center;flex-direction:row;flex-wrap:nowrap;min-height:30px;"], props => props.theme.colors.background_page);
const SParagraph = styled(Paragraph).withConfig({
  displayName: "badge__SParagraph",
  componentId: "sc-18zhdo5-2"
})(["color:", ";font-size:12px;"], props => props.theme.colors.text_header);
const Icon = styled.img.withConfig({
  displayName: "badge__Icon",
  componentId: "sc-18zhdo5-3"
})(["margin-right:5px;flex:1;"]);