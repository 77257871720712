import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { eventManager } from '../events';
import { useEffect } from 'react';
import { usePrevious } from '../utils';
import { getApiErrorMessage } from '../errors';
export const useApiEventTracking = (apiState, event, properties, errorMessage) => {
  const {
    data,
    error
  } = apiState;
  const prevData = usePrevious(data);
  const prevError = usePrevious(error);
  useEffect(() => {
    if (data && data !== prevData) {
      eventManager.track(event, properties);
    }
  }, [data, prevData, event, properties]);
  useEffect(() => {
    if (error && error !== prevError) {
      eventManager.trackFailure(event, _objectSpread({
        error: errorMessage || getApiErrorMessage(error)
      }, properties));
    }
  }, [error, prevError, event, properties, errorMessage]);
};