import { AssetClassLabel, AssetClassType } from '../../enums';
export const portfolioInvestmentsByAssetClass = {
  userId: 1,
  currentValueBreakdown: [{
    assetClass: AssetClassType.Cash,
    title: 'test',
    value: 100,
    percentage: 10,
    name: 'test'
  }, {
    assetClass: AssetClassType.RealEstate,
    title: AssetClassType.RealEstate,
    value: 900,
    percentage: 90,
    name: 'REAL'
  }],
  investmentAmountBreakdown: [{
    assetClass: AssetClassType.Cash,
    title: 'test',
    value: 100,
    percentage: 10,
    name: 'test'
  }, {
    assetClass: AssetClassType.Crypto,
    title: 'Crypto',
    value: 900,
    percentage: 90,
    name: 'test'
  }]
};
export const portfolioInvestmentAssetCurrentValueBreakdown = {
  currentValueBreakdown: [{
    assetClass: AssetClassType.Cash,
    title: 'test',
    value: 100,
    percentage: 10,
    name: 'test'
  }, {
    assetClass: AssetClassType.Crypto,
    title: AssetClassLabel.Crypto,
    value: 900,
    percentage: 90,
    name: 'test'
  }]
};
export const managedPortfolioInvestmentsByAssetClass = {
  userId: 1,
  currentValueBreakdown: [{
    assetClass: AssetClassType.PrivateCredit,
    percentage: 48.18,
    title: AssetClassLabel.PrivateCredit,
    value: 20000
  }, {
    assetClass: AssetClassType.RealEstate,
    percentage: 23.18,
    title: AssetClassLabel.RealEstate,
    value: 10835
  }, {
    assetClass: AssetClassType.PrivateEquity,
    percentage: 93.66,
    title: AssetClassLabel.PrivateEquity,
    value: 30000
  }],
  investmentAmountBreakdown: [{
    assetClass: AssetClassType.PrivateCredit,
    percentage: 48.18,
    title: AssetClassLabel.PrivateCredit,
    value: 20000
  }, {
    assetClass: AssetClassType.RealEstate,
    percentage: 23.18,
    title: AssetClassLabel.RealEstate,
    value: 10835
  }, {
    assetClass: AssetClassType.PrivateEquity,
    percentage: 93.66,
    title: AssetClassLabel.PrivateEquity,
    value: 30000
  }]
};
export const managedPortfolioInvestmentsByAssetClassCashOnly = {
  userId: 1,
  currentValueBreakdown: [{
    assetClass: AssetClassType.Cash,
    percentage: 100,
    title: AssetClassType.Cash,
    value: 50000
  }],
  investmentAmountBreakdown: [{
    assetClass: AssetClassType.Cash,
    percentage: 100,
    title: AssetClassType.Cash,
    value: 50000
  }]
};
export const managedPortfolioInvestmentsByAssetClassEmpty = {
  userId: 1,
  currentValueBreakdown: [],
  investmentAmountBreakdown: []
};