import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["prefix", "suffix", "inputRef", "inputComponent", "inputProps", "label", "placeholder", "error", "onChange", "hiddenLabel", "caption", "className", "fullWidth", "width", "maskClassName", "name", "disabled", "testId"];
var __jsx = React.createElement;
import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import { genTestIds } from '@yieldstreet/tool-kit';
import { SFormControl, SFormHelperText, FormError, withFormikWeb, useLabelId } from '../common';
import { SFilledInput, SInputLabel } from '../text-input/styles';
const {
  useTestIds
} = genTestIds();
export const TextInput = props => {
  const {
      prefix,
      suffix,
      inputRef,
      inputComponent,
      inputProps,
      label,
      placeholder,
      error,
      onChange,
      hiddenLabel,
      caption,
      className,
      fullWidth,
      width,
      maskClassName,
      name,
      disabled,
      testId
    } = props,
    otherProps = _objectWithoutProperties(props, _excluded);
  const labelId = useLabelId(name);
  const testIds = useTestIds({
    testId: testId || name || 'text'
  });
  return __jsx(SFormControl, {
    variant: "filled",
    hiddenLabel: hiddenLabel,
    error: !!error,
    className: className,
    fullWidth: fullWidth,
    disabled: disabled,
    style: {
      width
    }
  }, label && !hiddenLabel && __jsx(SInputLabel, {
    className: maskClassName,
    id: labelId
  }, label), __jsx(SFilledInput, _extends({}, otherProps, {
    onChange: onChange && (e => onChange(e.target && e.target.value, e)),
    name: name,
    inputRef: inputRef,
    inputComponent: inputComponent,
    inputProps: _objectSpread(_objectSpread(_objectSpread({}, testIds.base.attr), {}, {
      'aria-labelledby': labelId,
      placeholder: hiddenLabel ? label : placeholder
    }, otherProps), inputProps),
    startAdornment: prefix && __jsx(InputAdornment, {
      position: "start"
    }, prefix),
    endAdornment: suffix && __jsx(InputAdornment, {
      position: "end"
    }, suffix)
  })), error || caption ? __jsx(SFormHelperText, null, error ? __jsx(FormError, {
    type: "text-input",
    testId: "error",
    name: name || 'text',
    error: error
  }) : caption) : null);
};
export const FormTextInput = withFormikWeb(TextInput);