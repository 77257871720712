import { useMemo } from 'react';
import Big from 'big.js';
export const useCommitmentsBreakdown = _ref => {
  var _aggregatedCommitment, _aggregatedCommitment2, _aggregatedCommitment3;
  let {
    accountSummary
  } = _ref;
  const aggregatedCommitments = useMemo(() => {
    var _accountSummary$funds;
    return accountSummary === null || accountSummary === void 0 ? void 0 : (_accountSummary$funds = accountSummary.funds) === null || _accountSummary$funds === void 0 ? void 0 : _accountSummary$funds.reduce((acc, fund) => {
      var _fund$commitmentBreak, _fund$commitmentBreak2;
      if (!fund.commitmentBreakdown) {
        return acc;
      }
      return {
        total: acc === null || acc === void 0 ? void 0 : acc.total.plus(fund.commitmentBreakdown.total || 0),
        funded: acc === null || acc === void 0 ? void 0 : acc.funded.plus(((_fund$commitmentBreak = fund.commitmentBreakdown) === null || _fund$commitmentBreak === void 0 ? void 0 : _fund$commitmentBreak.funded) || 0),
        unfunded: acc === null || acc === void 0 ? void 0 : acc.unfunded.plus(((_fund$commitmentBreak2 = fund.commitmentBreakdown) === null || _fund$commitmentBreak2 === void 0 ? void 0 : _fund$commitmentBreak2.unfunded) || 0)
      };
    }, {
      total: new Big(0),
      funded: new Big(0),
      unfunded: new Big(0)
    });
  }, [accountSummary]);
  return {
    total: aggregatedCommitments === null || aggregatedCommitments === void 0 ? void 0 : (_aggregatedCommitment = aggregatedCommitments.total) === null || _aggregatedCommitment === void 0 ? void 0 : _aggregatedCommitment.toNumber(),
    funded: aggregatedCommitments === null || aggregatedCommitments === void 0 ? void 0 : (_aggregatedCommitment2 = aggregatedCommitments.funded) === null || _aggregatedCommitment2 === void 0 ? void 0 : _aggregatedCommitment2.toNumber(),
    unfunded: aggregatedCommitments === null || aggregatedCommitments === void 0 ? void 0 : (_aggregatedCommitment3 = aggregatedCommitments.unfunded) === null || _aggregatedCommitment3 === void 0 ? void 0 : _aggregatedCommitment3.toNumber()
  };
};