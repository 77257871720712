import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { DEFINITIONS, FAQ, ALTERNATIVE_INVESTING, INVESTMENT_BASICS, INVESTOR_TOOLKIT, LT_CATEGORY_TAG } from '../../pages/resources/shared/blog.consts';
import { CALL_API } from '../middleware/Middleware';
const POST_PAGE = 1;
const POST_TYPE = 'learningtool';
const PER_PAGE_BLOG_ARTICLES = 9;
export const GET_BLOG_LT_ARTICLES_ATTEMPT = 'ys/blogLTArticles/ATTEMPT';
export const GET_BLOG_LT_ARTICLES_SUCCESS = 'ys/blogLTArticles/SUCCESS';
export const GET_BLOG_LT_ARTICLES_FAILURE = 'ys/blogLTArticles/FAILURE';
export const getBlogLTArticles = _ref => {
  let {
    page = POST_PAGE,
    perPage = PER_PAGE_BLOG_ARTICLES,
    postType = POST_TYPE,
    param = LT_CATEGORY_TAG
  } = _ref;
  return dispatch => {
    const endpoint = `/wp-json/wp/v2/${postType}/?_embed&page=${page}&per_page=${perPage}&lt_category=${param}`;
    return dispatch({
      [CALL_API]: {
        endpoint,
        types: [GET_BLOG_LT_ARTICLES_ATTEMPT, GET_BLOG_LT_ARTICLES_SUCCESS, GET_BLOG_LT_ARTICLES_FAILURE],
        getResponse: res => res
      }
    });
  };
};
export const GET_FEATURED_ARTICLES_ATTEMPT = 'ys/featuredArticles/ATTEMPT';
export const GET_FEATURED_ARTICLES_SUCCESS = 'ys/featuredArticles/SUCCESS';
export const GET_FEATURED_ARTICLES_FAILURE = 'ys/featuredArticles/FAILURE';
const FEATURED_ARTICLES_TAG = 366;
const PER_PAGE_FEATURED_ARTICLES = 6;
export const getFeaturedArticles = _ref2 => {
  let {
    perPage = PER_PAGE_FEATURED_ARTICLES,
    postType = POST_TYPE,
    tags = FEATURED_ARTICLES_TAG
  } = _ref2;
  return dispatch => {
    const endpoint = `/wp-json/wp/v2/${postType}/?_embed&tags=${tags}&per_page=${perPage}`;
    return dispatch({
      [CALL_API]: {
        endpoint,
        types: [GET_FEATURED_ARTICLES_ATTEMPT, GET_FEATURED_ARTICLES_SUCCESS, GET_FEATURED_ARTICLES_FAILURE],
        getResponse: res => res
      }
    });
  };
};
export const GET_BLOG_LT_CATEGORIES_ATTEMPT = 'ys/blogLTCategories/ATTEMPT';
export const GET_BLOG_LT_CATEGORIES_SUCCESS = 'ys/blogLTCategories/SUCCESS';
export const GET_BLOG_LT_CATEGORIES_FAILURE = 'ys/blogLTCategories/FAILURE';
export const fetchBlogLTCategories = () => dispatch => {
  const endpoint = `/wp-json/wp/v2/lt_category?per_page=100`;
  const filterArray = [DEFINITIONS, FAQ, ALTERNATIVE_INVESTING, INVESTOR_TOOLKIT, INVESTMENT_BASICS];
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_BLOG_LT_CATEGORIES_ATTEMPT, GET_BLOG_LT_CATEGORIES_SUCCESS, GET_BLOG_LT_CATEGORIES_FAILURE],
      getResponse: res => {
        const {
          data
        } = res;
        if (data.length > 0) {
          const filteredByCategoryId = data.filter(item => !filterArray.includes(item.id));
          return _objectSpread(_objectSpread({}, res), {}, {
            data: filteredByCategoryId
          });
        }
        return res;
      }
    }
  });
};
export const GET_BLOG_LT_ARTICLES_RESET = 'ys/blogLTArticles/RESET';
export const resetBlogLTArticles = () => dispatch => dispatch({
  type: GET_BLOG_LT_ARTICLES_RESET
});