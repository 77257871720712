import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import findIndex from "lodash/findIndex";
export const useQuizSteps = _ref => {
  let {
    steps,
    baseUrl,
    onQuizEnd,
    routerOptions
  } = _ref;
  const {
    location,
    replace
  } = useHistory();
  const {
    currentIndex,
    stack
  } = useMemo(() => {
    var _location$state, _location$state2;
    const currentPath = location.pathname.split('/').pop(-1);
    let currentIndex = findIndex(steps, step => step.url === currentPath);
    currentIndex = currentIndex < 0 ? 0 : currentIndex;
    return {
      currentIndex,
      cameFromNextStep: currentIndex < ((_location$state = location.state) === null || _location$state === void 0 ? void 0 : _location$state.previousIndex),
      stack: ((_location$state2 = location.state) === null || _location$state2 === void 0 ? void 0 : _location$state2.stack) || []
    };
  }, [location, steps]);
  const handleProgress = useCallback((direction, hash) => {
    /** Depending on the direction, updatedProgress will be either higher or lower than the currentIndex
     * this value is used down below to retrieve the correct step
     */
    const updatedProgress = currentIndex + direction;
    if (updatedProgress < 0 || updatedProgress > steps.length) {
      return;
    }

    /** End of the quiz */
    if (updatedProgress === steps.length) {
      onQuizEnd && onQuizEnd();
      return;
    }
    const updatedNextStep = steps[updatedProgress];

    /**
     * NEXT STEP URL being set up here grabbing the value from the currentStep and
     * if a hashed was passed, add it to the end
     */
    const nextStepURL = `${baseUrl}/${updatedNextStep.url}${hash ? `#${hash}` : ''}`;

    /**
     * In case of going backwards, pop an entry from the stack and push
     * Quiz to the popped step;
     *
     * Otherwise, add the current step url to the stack and push towards the next step url
     */
    if (direction < 0) {
      const previousStepURL = stack.pop();
      replace(previousStepURL, _objectSpread(_objectSpread({}, routerOptions), {}, {
        previousIndex: currentIndex,
        stack
      }));
    } else {
      const currentUrl = location.pathname;
      const currentHash = location.hash;
      const currentPathName = currentUrl;
      stack.push(currentPathName + currentHash);
      replace(nextStepURL, _objectSpread(_objectSpread({}, routerOptions), {}, {
        previousIndex: currentIndex,
        stack
      }));
    }
  }, [currentIndex, replace, stack, location, routerOptions, steps, baseUrl, onQuizEnd]);
  const currentStep = steps[currentIndex];
  return {
    currentStep,
    handleProgress,
    currentIndex
  };
};