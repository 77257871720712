import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { HttpMethods } from '../../enums/http';
import { restApi, BASE_REST_CONFIG, Tags } from '../common';
export const usersApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.UsersRecurringPayments]
}).injectEndpoints(_objectSpread(_objectSpread({}, BASE_REST_CONFIG), {}, {
  endpoints: builder => {
    const getUsersRecurringPayments = builder.query({
      query: _ref => {
        let {
          userId
        } = _ref;
        return {
          url: `/a/api/users/${userId}/recurring-payments`,
          method: HttpMethods.Get
        };
      },
      providesTags: [Tags.UsersRecurringPayments],
      transformResponse: response => response.recurringPayments
    });
    return {
      getUsersRecurringPayments
    };
  }
}));