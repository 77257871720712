var __jsx = React.createElement;
import React from 'react';
import { Container } from './MarketingCinematicContainer.model';
export const MarketingCinematicContainer = _ref => {
  let {
    children,
    withBorder
  } = _ref;
  return __jsx(Container, {
    withBorder: withBorder
  }, children);
};