import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { buildAxiosFetch } from '@lifeomic/axios-fetch';
import { GraphQLClient, ClientError } from 'graphql-request';
import axios from 'axios';
import { BASE_CONFIG } from './base-config';
export const client = new GraphQLClient('/api/api-aggregator/graphql', {
  fetch: buildAxiosFetch(axios)
});
export let pendingTimeout = null;
export let pendingRequests = [];
const processPendingRequests = async () => {
  const processingRequests = pendingRequests;
  pendingRequests = [];
  pendingTimeout = null;
  try {
    const responses = await client.batchRequests(processingRequests.map(_ref => {
      let {
        params
      } = _ref;
      return params;
    }));
    processingRequests.forEach((_ref2, index) => {
      let {
        resolve
      } = _ref2;
      const {
        data,
        errors
      } = responses[index];
      if (errors) {
        resolve({
          error: errors
        });
      } else {
        resolve({
          data
        });
      }
    });
  } catch (error) {
    if (error instanceof ClientError) {
      const {
        name,
        message,
        stack,
        request,
        response
      } = error;
      const payload = {
        error: {
          name,
          message,
          stack
        },
        meta: {
          request,
          response
        }
      };
      processingRequests.forEach(_ref3 => {
        let {
          resolve
        } = _ref3;
        return resolve(payload);
      });
    }
    throw error;
  }
};
export const baseGraphQLQuery = () => {
  return async params => {
    try {
      return new Promise((resolve, reject) => {
        pendingRequests.push({
          params,
          resolve,
          reject
        });
        if (!pendingTimeout) {
          pendingTimeout = setTimeout(processPendingRequests);
        }
      });
    } catch (error) {
      throw error;
    }
  };
};
export const BASE_GRAPHQL_CONFIG = _objectSpread(_objectSpread({}, BASE_CONFIG), {}, {
  baseQuery: baseGraphQLQuery()
});