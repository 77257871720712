import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { palette, theme2 } from './theme2';
const cadrePalette = _objectSpread(_objectSpread({}, palette), {}, {
  'cadre-500': '#7861ED',
  'cadre-800': '#423582'
});
export const themeCadre = _objectSpread(_objectSpread({}, theme2), {}, {
  name: 'Theme Cadre',
  colors: _objectSpread(_objectSpread({}, theme2.colors), {}, {
    input_button_background: cadrePalette['cadre-500'],
    input_button_background_hover: cadrePalette['cadre-800'],
    input_button_background_active: cadrePalette['cadre-500'],
    input_button_text: palette.white
  })
});