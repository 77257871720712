import { marketplaceApi } from '@yieldstreet/platform-kit';
const {
  useGetPaginatedOfferingsQuery
} = marketplaceApi;
export const useMarketplaceOfferings = _ref => {
  let {
    statuses,
    page,
    pageSize,
    investmentStrategy,
    minInvestmentHigh,
    minInvestmentLow,
    attributes,
    maxTerm,
    minTerm,
    order,
    sortBy,
    assetClasses,
    assetTypes,
    preventIraFromInvesting,
    timestampForPulldownRefetch,
    refetchOnMountOrArgChange,
    includeFundingProgress,
    rolloverFunding,
    accreditationEligibility,
    excludeFundedOfferings
  } = _ref;
  const {
    offerings,
    isLoading,
    totalPages,
    hasMore,
    dataAttributes,
    isError,
    isFetching,
    currentPage,
    isUninitialized,
    refetch
  } = useGetPaginatedOfferingsQuery({
    statuses,
    page,
    pageSize,
    investmentStrategy,
    minInvestmentHigh,
    minInvestmentLow,
    attributes,
    maxTerm,
    minTerm,
    order,
    sortBy,
    assetClasses,
    assetTypes,
    preventIraFromInvesting,
    timestampForPulldownRefetch,
    includeFundingProgress,
    rolloverFunding,
    accreditationEligibility,
    excludeFundedOfferings
  }, {
    refetchOnMountOrArgChange,
    selectFromResult: _ref2 => {
      let {
        data,
        isLoading,
        isUninitialized,
        isError,
        isFetching
      } = _ref2;
      return {
        offerings: (data === null || data === void 0 ? void 0 : data.offerings) || [],
        dataAttributes: data === null || data === void 0 ? void 0 : data.attributes,
        totalPages: data === null || data === void 0 ? void 0 : data.totalPages,
        currentPage: data === null || data === void 0 ? void 0 : data.currentPage,
        isLoading: isLoading,
        isUninitialized,
        isError,
        hasMore: data === null || data === void 0 ? void 0 : data.hasMore,
        isFetching
      };
    }
  });
  return {
    hasMore,
    isFetching,
    isLoading,
    isError,
    offerings,
    totalPages,
    dataAttributes,
    currentPage,
    isUninitialized,
    refetch
  };
};