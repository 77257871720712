var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';

/* Utilities */
import { media } from '../../themes/media';
/* Base styles */
import { Heading, Paragraph } from '../../content/text';
/* Assets */
import AvatarFinancialLogo from './assets/logo 1.svg';

/* ============================
 *    Main copy block module
 * ============================ */

export const CopyBlock = _ref => {
  let {
    title,
    subtitle,
    description
  } = _ref;
  return __jsx(MainCopySection, null, __jsx(Headline, {
    type: 3
  }, title), __jsx(SubTitle, {
    semiBold: true
  }, subtitle), __jsx(MainTextContainer, null, __jsx(MainBodyCopy, {
    normal: true
  }, description), __jsx(MainBodyImg, {
    src: AvatarFinancialLogo
  })));
};

/* ===== Layout UI elements ===== */

const MainTextContainer = styled.div.withConfig({
  displayName: "copyBlock__MainTextContainer",
  componentId: "sc-pla55e-0"
})(["display:flex;align-items:flex-start;margin-bottom:25px;justify-content:space-between;", ""], media.desktop`
    align-items: flex-end;
  `);
const MainCopySection = styled.section.withConfig({
  displayName: "copyBlock__MainCopySection",
  componentId: "sc-pla55e-1"
})(["box-sizing:border-box;", ""], media.desktop`
    flex-basis:50%;
    padding-right:50px;
  `);

/* ===== Component UI elements ===== */

const MainBodyImg = styled.img.withConfig({
  displayName: "copyBlock__MainBodyImg",
  componentId: "sc-pla55e-2"
})(["width:100px;"]);

/* ===== Type styles ==== */

const Headline = styled(Heading).withConfig({
  displayName: "copyBlock__Headline",
  componentId: "sc-pla55e-3"
})(["margin-bottom:20px;"]);
const SubTitle = styled(Paragraph).withConfig({
  displayName: "copyBlock__SubTitle",
  componentId: "sc-pla55e-4"
})(["margin-bottom:20px;", " ", ""], media.mobile`
    display:none;
  `, media.desktop`
    display:block;
  `);
const MainBodyCopy = styled(Paragraph).withConfig({
  displayName: "copyBlock__MainBodyCopy",
  componentId: "sc-pla55e-5"
})(["margin-right:20px;", ""], media.tablet`
    max-width:569px;
  `);