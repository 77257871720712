var __jsx = React.createElement;
import React from 'react';
import { Container } from '../container';
import ErrorImage from './assets/Error.png';
import WarningImage from './assets/Warning.png';
import { Wrapper, Image, SHeading, ButtonsWrapper } from './PageError.style';
import { testIds } from './PageError.model';
const handleImage = pageType => {
  switch (pageType) {
    case 'warning':
      return WarningImage;
    case 'error':
    default:
      return ErrorImage;
  }
};
export const PageError = _ref => {
  let {
    title,
    content,
    type = 'error',
    buttons
  } = _ref;
  return __jsx(Container, null, __jsx(Wrapper, null, __jsx(Image, {
    src: handleImage(type),
    "data-cy": `${testIds.image}-${type}`
  }), __jsx(SHeading, {
    type: 3,
    "data-cy": testIds.title
  }, title), content, __jsx(ButtonsWrapper, null, buttons)));
};