import isArray from "lodash/isArray";
import { CALL_API } from '../middleware/Middleware';
export const GET_OPEN_OFFERINGS_ATTEMPT = 'ys/offering/open/ATTEMPT';
export const GET_OPEN_OFFERINGS_SUCCESS = 'ys/offering/open/SUCCESS';
export const GET_OPEN_OFFERINGS_FAILURE = 'ys/offering/open/FAILURE';
export const getOpenOfferings = filter => dispatch => {
  const endpoint = openOfferingsUrl(filter);
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_OPEN_OFFERINGS_ATTEMPT, GET_OPEN_OFFERINGS_SUCCESS, GET_OPEN_OFFERINGS_FAILURE],
      getResponse: res => res.data
    }
  });
};
export const GET_UPCOMING_OFFERINGS_ATTEMPT = 'ys/offering/upcoming/ATTEMPT';
export const GET_UPCOMING_OFFERINGS_SUCCESS = 'ys/offering/upcoming/SUCCESS';
export const GET_UPCOMING_OFFERINGS_FAILURE = 'ys/offering/upcoming/FAILURE';
export const getUpcomingOfferings = filter => dispatch => {
  const endpoint = upcomingOfferingsUrl(filter);
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_UPCOMING_OFFERINGS_ATTEMPT, GET_UPCOMING_OFFERINGS_SUCCESS, GET_UPCOMING_OFFERINGS_FAILURE],
      getResponse: res => res.data
    }
  });
};
export const GET_MARKETPLACE_DISCOVERY_OFFERINGS_ATTEMPT = 'ys/marketplaceDiscoveryOfferings/ATTEMPT';
export const GET_MARKETPLACE_DISCOVERY_OFFERINGS_SUCCESS = 'ys/marketplaceDiscoveryOfferings/SUCCESS';
export const GET_MARKETPLACE_DISCOVERY_OFFERINGS_FAILURE = 'ys/marketplaceDiscoveryOfferings/FAILURE';
export const getMarketplaceDiscoveryOfferings = (currentPage, pageSize, category) => dispatch => {
  const endpoint = `/a/api/marketplace/discovery?page=${currentPage}&pageSize=${pageSize}&category=${category}`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_MARKETPLACE_DISCOVERY_OFFERINGS_ATTEMPT, GET_MARKETPLACE_DISCOVERY_OFFERINGS_SUCCESS, GET_MARKETPLACE_DISCOVERY_OFFERINGS_FAILURE],
      getResponse: res => res.data,
      successPayload: {
        category
      }
    }
  });
};
export const GET_MARKETPLACE_RECOMMENDATIONS_ATTEMPT = 'ys/marketplaceRecommendations/ATTEMPT';
export const GET_MARKETPLACE_RECOMMENDATIONS_SUCCESS = 'ys/marketplaceRecommendations/SUCCESS';
export const GET_MARKETPLACE_RECOMMENDATIONS_FAILURE = 'ys/marketplaceRecommendations/FAILURE';
export const getMarketplaceRecommendations = userId => dispatch => {
  const endpoint = `/a/api/marketplace/recommendations/${userId}`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_MARKETPLACE_RECOMMENDATIONS_ATTEMPT, GET_MARKETPLACE_RECOMMENDATIONS_SUCCESS, GET_MARKETPLACE_RECOMMENDATIONS_FAILURE],
      getResponse: res => res.data
    }
  });
};

// Helpers
const buildParams = params => {
  const paramString = params.filter(_ref => {
    let {
      value
    } = _ref;
    return value !== undefined && value !== null;
  }).map(_ref2 => {
    let {
      key,
      value
    } = _ref2;
    if (isArray(value)) {
      return value.map(valueItem => `${key}[]=${valueItem}`).join('&');
    } else {
      return `${key}=${value}`;
    }
  }).join('&');
  return paramString.length ? `?${paramString}` : '';
};
const upcomingOfferingsUrl = function () {
  let {
    accreditation,
    assetClass,
    productType
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const baseURL = '/a/api/marketplace/coming-soon';
  return `${baseURL}${buildParams([{
    key: 'accreditation',
    value: accreditation
  }, {
    key: 'assetClass',
    value: assetClass
  }, {
    key: 'productType',
    value: productType
  }])}`;
};
const openOfferingsUrl = function () {
  let {
    accreditation,
    assetClass,
    productType
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const baseURL = '/a/api/marketplace/open';
  return `${baseURL}${buildParams([{
    key: 'accreditation',
    value: accreditation
  }, {
    key: 'assetClass',
    value: assetClass
  }, {
    key: 'productType',
    value: productType
  }])}`;
};