import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import update from 'immutability-helper';
import * as actions from 'store/actions/bank';
const initialState = {
  bankAccounts: [],
  fetchedBankAccounts: false,
  isFetching: false,
  fetchError: false,
  userBankInfo: {},
  userBankInfoFetching: false,
  userBankInfoFetched: false,
  depositVerificationMessage: {},
  depositVerificationSuccess: {}
};
export const bankAnonymizer = {
  bankAccounts: ['array', 'bankAccount'],
  userBankInfo: ['object', {
    bankAccounts: ['array', 'bankAccount']
  }]
};
const bank = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case actions.GET_BANK_ACCOUNT_ATTEMPT:
      {
        return update(state, {
          isFetching: {
            $set: true
          },
          fetchError: {
            $set: false
          },
          fetchedBankAccounts: {
            $set: false
          }
        });
      }
    case actions.GET_BANK_ACCOUNT_SUCCESS:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          bankAccounts: {
            $set: action.response.bankAccounts
          },
          fetchedBankAccounts: {
            $set: true
          }
        });
      }
    case actions.GET_BANK_ACCOUNT_FAILURE:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          fetchError: {
            $set: true
          },
          fetchedBankAccounts: {
            $set: true
          }
        });
      }
    case actions.GET_USER_BANK_INFO_ATTEMPT:
      {
        return update(state, {
          userBankInfoFetching: {
            $set: true
          },
          userBankInfoFetched: {
            $set: false
          }
        });
      }
    case actions.GET_USER_BANK_INFO_SUCCESS:
      {
        return update(state, {
          userBankInfoFetching: {
            $set: false
          },
          userBankInfoFetched: {
            $set: true
          },
          userBankInfo: {
            $merge: {
              [action.userId]: action.response
            }
          }
        });
      }
    case actions.GET_USER_BANK_INFO_FAILURE:
      {
        return update(state, {
          userBankInfoFetching: {
            $set: false
          },
          userBankInfoFetched: {
            $set: true
          }
        });
      }
    case actions.RESET_BANK_DATA:
      {
        return initialState;
      }
    case actions.UPDATE_BANKS_NICKNAME_ATTEMPT:
      {
        return update(state, {
          isFetching: {
            $set: true
          }
        });
      }
    case actions.UPDATE_BANKS_NICKNAME_SUCCESS:
      {
        const bankAccounts = state.userBankInfo[Object.keys(state.userBankInfo)[0]].bankAccounts;
        const bankAcctIndex = bankAccounts && bankAccounts.findIndex(acc => acc.id === action.response.id);
        return update(state, {
          isFetching: {
            $set: false
          },
          userBankInfo: {
            [Object.keys(state.userBankInfo)[0]]: {
              bankAccounts: {
                [bankAcctIndex]: {
                  nickname: {
                    $set: action.response.nickname
                  }
                }
              }
            }
          }
        });
      }
    case actions.UPDATE_BANKS_NICKNAME_FAILURE:
      {
        return update(state, {
          isFetching: {
            $set: false
          }
        });
      }
    case actions.DEPOSIT_VERIFICATION_ATTEMPT:
      {
        const depositVerificationMessage = {
          [action.bankAcctId]: null
        };
        return update(state, {
          isFetching: {
            $set: true
          },
          depositVerificationMessage: {
            $merge: _objectSpread({}, depositVerificationMessage)
          }
        });
      }
    case actions.DEPOSIT_VERIFICATION_SUCCESS:
      {
        const investorBankAccIdx = state.userBankInfo[action.userId].bankAccounts.findIndex(acct => acct.id === action.bankAcctId);
        const depositVerificationSuccess = {
          [action.bankAcctId]: true
        };
        return update(state, {
          isFetching: {
            $set: false
          },
          userBankInfo: {
            [action.userId]: {
              bankAccounts: {
                [investorBankAccIdx]: {
                  depositVerificationPending: {
                    $set: false
                  }
                }
              }
            }
          },
          depositVerificationSuccess: {
            $merge: _objectSpread({}, depositVerificationSuccess)
          }
        });
      }
    case actions.DEPOSIT_VERIFICATION_FAILURE:
      {
        const depositVerificationSuccess = {
          [action.bankAcctId]: false
        };
        const depositVerificationMessage = {
          [action.bankAcctId]: action.error
        };
        return update(state, {
          isFetching: {
            $set: false
          },
          depositVerificationSuccess: {
            $merge: _objectSpread({}, depositVerificationSuccess)
          },
          depositVerificationMessage: {
            $merge: _objectSpread({}, depositVerificationMessage)
          }
        });
      }
    default:
      return state;
  }
};
export default bank;