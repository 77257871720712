var __jsx = React.createElement;
import React from 'react';
import { getLabelTagConfig } from '@yieldstreet/tool-kit';
import styled from 'styled-components';
import { Badge, Button, Currency, Heading, Paragraph } from '../..';
import BackgroundViewAll from '../../assets/graphics/backgrounds/offering-all-card-bg.jpg';
import { TagsList } from './TagsList';
const Card = _ref => {
  var _offering$v5Data;
  let {
    offering,
    onClick,
    imageUrl,
    className,
    showSummary
  } = _ref;
  if (offering === undefined) {
    return null;
  }
  const badges = getLabelTagConfig(offering);
  const offeringTags = offering === null || offering === void 0 ? void 0 : (_offering$v5Data = offering.v5Data) === null || _offering$v5Data === void 0 ? void 0 : _offering$v5Data.offeringTags;
  const hasLegacyOfferingTags = offeringTags && typeof offeringTags[0] === 'string';
  const renderBadges = badges => __jsx(BadgesWrapper, null, badges.map(badge => {
    const {
      text,
      icon,
      testingID
    } = badge;
    return __jsx(BadgeWrapper, {
      key: `${testingID || text} badge`
    }, __jsx(Badge, {
      "data-cy": `${testingID || text} badge`,
      config: {
        text,
        icon
      }
    }));
  }));
  return __jsx(FlexWrapper, {
    "data-cy": "offering-overview-wrapper",
    className: className,
    onClick: onClick
  }, __jsx(ImgWrapper, {
    className: "OfferingOverview__ImgItem"
  }, renderBadges(badges), __jsx(OfferingImage, {
    loading: "lazy",
    src: imageUrl
  })), __jsx(InfoWrapper, null, __jsx(Heading, {
    type: 4
  }, offering.title), showSummary && offering.summary ? __jsx(OfferingSummary, null, offering.summary) : null), __jsx(DetailsWrapper, null, __jsx(DetailItem, null, __jsx(DetailLabel, {
    small: true
  }, "Min. investment"), __jsx(DetailDescription, {
    small: true,
    semiBold: true
  }, __jsx(Currency, {
    value: parseFloat(offering.minimumPurchase)
  }))), __jsx(DetailItem, {
    lastItem: !offering.originator ? true : false
  }, __jsx(DetailLabel, {
    small: true
  }, "Asset class"), __jsx(DetailDescription, {
    small: true,
    semiBold: true
  }, offering.assetClassDisplayName)), offering.originatorName && __jsx(DetailItem, {
    lastItem: true
  }, __jsx(DetailLabel, {
    small: true
  }, "Originator"), __jsx(DetailDescription, {
    small: true,
    semiBold: true
  }, offering.originatorName)), offeringTags && __jsx(DetailTags, null, __jsx(TagsList, {
    tags: offeringTags,
    hasLegacyOfferingTags: hasLegacyOfferingTags
  }))));
};
const ViewAllCard = _ref2 => {
  let {
    onClick
  } = _ref2;
  return __jsx(FlexWrapper, {
    "data-cy": "offering-overview-wrapper"
  }, __jsx(SbuttonWrapper, null, __jsx(SButton, {
    onClick: onClick,
    buttonType: "secondary"
  }, "View all offerings")), __jsx(BlurWrapper, null, __jsx(ImgWrapper, {
    className: "OfferingOverview__ImgItem"
  }, __jsx(OfferingImage, {
    loading: "lazy",
    src: BackgroundViewAll
  })), __jsx(InfoWrapper, null, __jsx(Heading, {
    type: 4
  }, "Offering Title")), __jsx(DetailsWrapper, null, __jsx(DetailItem, null, __jsx(DetailLabel, {
    small: true
  }, "Min. investment"), __jsx(DetailDescription, {
    small: true
  }, "$10k")), __jsx(DetailItem, null, __jsx(DetailLabel, {
    small: true
  }, "Asset class"), __jsx(DetailDescription, {
    small: true
  }, "Asset class")), __jsx(DetailItem, {
    lastItem: true
  }, __jsx(DetailLabel, {
    small: true
  }, "Originator"), __jsx(DetailDescription, {
    small: true
  }, "originatorName")), __jsx(DetailTags, null, __jsx(TagWrapper, null, __jsx(Badge, {
    isTag: true,
    config: {
      text: 'offeringTag'
    }
  }))))));
};
export const OfferingCard = _ref3 => {
  let {
    offering,
    onClick,
    imageUrl,
    viewAllCard,
    className,
    showSummary
  } = _ref3;
  if (viewAllCard) {
    return __jsx(ViewAllCard, {
      onClick: onClick
    });
  }
  return __jsx(Card, {
    offering: offering,
    onClick: onClick,
    imageUrl: imageUrl,
    className: className,
    showSummary: showSummary
  });
};
const FlexWrapper = styled.div.withConfig({
  displayName: "OfferingCard__FlexWrapper",
  componentId: "sc-mogkdp-0"
})(["display:flex;flex-direction:column;width:auto;position:relative;z-index:2;padding:10px;background:", ";box-shadow:0px 4px 8px rgba(0,0,0,0.06);border-radius:2px;transition:box-shadow 0.3s;min-height:376px;height:100%;max-width:376px;width:100%;margin:0 auto;:hover{box-shadow:0px 4px 8px rgba(0,0,0,0.2);", "}"], props => props.theme.colors.background_card, props => props.onClick ? 'cursor: pointer;' : '');
const ImgWrapper = styled.div.withConfig({
  displayName: "OfferingCard__ImgWrapper",
  componentId: "sc-mogkdp-1"
})(["position:relative;"]);
const BlurWrapper = styled.div.withConfig({
  displayName: "OfferingCard__BlurWrapper",
  componentId: "sc-mogkdp-2"
})(["filter:blur(6px);opacity:0.5;"]);
const SbuttonWrapper = styled.div.withConfig({
  displayName: "OfferingCard__SbuttonWrapper",
  componentId: "sc-mogkdp-3"
})(["align-items:center;height:100%;display:flex;justify-content:center;position:absolute;width:100%;z-index:1;left:0;top:0;"]);
const SButton = styled(Button).withConfig({
  displayName: "OfferingCard__SButton",
  componentId: "sc-mogkdp-4"
})(["max-width:195px;"]);
const OfferingImage = styled.img.withConfig({
  displayName: "OfferingCard__OfferingImage",
  componentId: "sc-mogkdp-5"
})(["position:relative;object-position:center;object-fit:cover;width:100%;height:120px;"]);
const BadgesWrapper = styled.div.withConfig({
  displayName: "OfferingCard__BadgesWrapper",
  componentId: "sc-mogkdp-6"
})(["display:flex;flex-wrap:wrap;position:absolute;z-index:2;top:10px;left:10px;margin:0 -5px 10px;"]);
const BadgeWrapper = styled.div.withConfig({
  displayName: "OfferingCard__BadgeWrapper",
  componentId: "sc-mogkdp-7"
})(["display:flex;margin:0 5px 5px;"]);
const InfoWrapper = styled.div.withConfig({
  displayName: "OfferingCard__InfoWrapper",
  componentId: "sc-mogkdp-8"
})(["margin:20px 0;padding:0 10px;height:auto;display:flex;flex-direction:column;min-height:64px;"]);
const OfferingSummary = styled(Paragraph).withConfig({
  displayName: "OfferingCard__OfferingSummary",
  componentId: "sc-mogkdp-9"
})(["margin-top:5px;"]);
const DetailsWrapper = styled.div.withConfig({
  displayName: "OfferingCard__DetailsWrapper",
  componentId: "sc-mogkdp-10"
})(["max-width:100%;padding:0 10px;margin:auto 0;"]);
const DetailItem = styled.div.withConfig({
  displayName: "OfferingCard__DetailItem",
  componentId: "sc-mogkdp-11"
})(["display:flex;justify-content:space-between;margin-bottom:10px;"]);
const DetailLabel = styled(Paragraph).withConfig({
  displayName: "OfferingCard__DetailLabel",
  componentId: "sc-mogkdp-12"
})([""]);
const DetailDescription = styled(Paragraph).withConfig({
  displayName: "OfferingCard__DetailDescription",
  componentId: "sc-mogkdp-13"
})(["text-align:right;"]);
const TagWrapper = styled.div.withConfig({
  displayName: "OfferingCard__TagWrapper",
  componentId: "sc-mogkdp-14"
})(["display:flex;"]);
const DetailTags = styled.div.withConfig({
  displayName: "OfferingCard__DetailTags",
  componentId: "sc-mogkdp-15"
})(["display:flex;flex-wrap:wrap;margin-top:20px;"]);