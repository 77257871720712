import _objectDestructuringEmpty from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectDestructuringEmpty.js";
import { HttpMethods } from '../../enums/http';
import { restApi, Tags } from '../common';
export const autoInvestPreferencesApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.AutoInvestPreferences]
}).injectEndpoints({
  endpoints: builder => {
    const getAutoInvestPreferences = builder.query({
      query: () => ({
        url: `/a/api/auto-invest/preferences`,
        method: HttpMethods.Get
      }),
      providesTags: [Tags.AutoInvestPreferences]
    });
    const setAutoInvestPreferences = builder.mutation({
      query: _ref => {
        let data = Object.assign({}, (_objectDestructuringEmpty(_ref), _ref));
        return {
          url: `/a/api/auto-invest/register`,
          method: HttpMethods.Put,
          data
        };
      },
      invalidatesTags: [Tags.AutoInvestPreferences]
    });
    return {
      getAutoInvestPreferences,
      setAutoInvestPreferences
    };
  }
});