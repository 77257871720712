import styled from 'styled-components';
import { media } from '@yieldstreet/ui-kit';
export const CardWrapper = styled.div.withConfig({
  displayName: "Namestyle__CardWrapper",
  componentId: "sc-glvzmk-0"
})(["margin-bottom:", ";text-align:left;", ""], props => props.theme.spacing.m, media.tablet`
    margin-bottom: ${props => props.theme.spacing.l};
  `);
export const CheckboxSecondaryWrapper = styled.div.withConfig({
  displayName: "Namestyle__CheckboxSecondaryWrapper",
  componentId: "sc-glvzmk-1"
})(["margin-top:40px;text-align:left;"]);
export const InputSecondaryTextWrapper = styled.div.withConfig({
  displayName: "Namestyle__InputSecondaryTextWrapper",
  componentId: "sc-glvzmk-2"
})(["display:flex;flex-direction:column;margin:auto;max-width:100%;"]);