var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { media } from '../../../themes';
import { Paragraph } from '../../../content/text';
export const PhotoWithContent = _ref => {
  let {
    title,
    image,
    alignment = 'left',
    contentAlignment = 'left',
    children,
    inverse
  } = _ref;
  return __jsx(OuterContainer, null, __jsx(Title, {
    semiBold: true,
    alignment: alignment,
    inverse: inverse
  }, title), __jsx(ImageHolder, {
    alignment: alignment
  }, image && __jsx(ImageWrapper, null, image)), __jsx(Content, {
    contentAlignment: contentAlignment,
    small: true,
    inverse: inverse
  }, children));
};
const OuterContainer = styled.div.withConfig({
  displayName: "PhotoWithContent__OuterContainer",
  componentId: "sc-4xzoou-0"
})(["flex-grow:1;flex-basis:0;", ""], media.desktop`
    max-width: 280px;
    &:first-child { 
      margin: 0 10px 0 0;
    }
    &:last-child { 
      margin: 0 0 0 10px;
    }
    &:not(:first-child):not(:last-child){
      margin: 0 10px;
    }
  `);
const Title = styled(Paragraph).withConfig({
  displayName: "PhotoWithContent__Title",
  componentId: "sc-4xzoou-1"
})(["text-align:", ";margin:30px 0 10px 0;", ""], props => props.alignment, media.desktop`
    margin-top: 0;
  `);
const ImageHolder = styled.div.withConfig({
  displayName: "PhotoWithContent__ImageHolder",
  componentId: "sc-4xzoou-2"
})(["text-align:", ";"], props => props.alignment);
const Content = styled(Paragraph).withConfig({
  displayName: "PhotoWithContent__Content",
  componentId: "sc-4xzoou-3"
})(["margin:10px 0 0 0;text-align:", ";"], props => props.contentAlignment);
const ImageWrapper = styled.div.withConfig({
  displayName: "PhotoWithContent__ImageWrapper",
  componentId: "sc-4xzoou-4"
})([""]);