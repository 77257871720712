import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { Tracer } from '@yieldstreet/tool-kit';
import ENV_CONFIG from './utils/env/config';
import browser from 'browser-detect';
import { onFSReady, getFSUrl } from 'utils/thirdPartyCode';
import { init } from '@sentry/browser';
import { ignoreErrors } from './utils/SentrySetup';
import { datadogRum } from '@datadog/browser-rum';
import { TrackingConsent } from '@datadog/browser-core';
import { registerInstrumentations } from '@opentelemetry/instrumentation';
import { XMLHttpRequestInstrumentation } from '@opentelemetry/instrumentation-xml-http-request';
import setupTracing, { traceResource } from './setupTracing';
import { initAppActionManager, initEventManager } from 'setupApp';
import { isE2E } from 'utils/env/const';
import { addTrustArcConsentListener, ConsentCategory, isConsentCategoryAllowed } from '@yieldstreet/ui-kit';
export default _ref => {
  let {
    browserHistory
  } = _ref;
  // Tracing
  const tracerProvider = new WebTracerProvider({
    resource: traceResource
  });
  setupTracing(tracerProvider);
  registerInstrumentations({
    instrumentations: [new XMLHttpRequestInstrumentation({
      ignoreUrls: [/^(?!(\/a\/api)|(\/api))(.*)/]
    })]
  });
  onFSReady(() => {
    Tracer.setGlobalAttributes({
      fullStoryUrl: getFSUrl()
    });
    Tracer.setGlobalCallbacks({
      onError: span => span.setAttributes({
        fullStoryErrorUrl: getFSUrl(true)
      })
    });
  });

  // Sentry
  !isE2E && ENV_CONFIG.SENTRY_DSN && init({
    dsn: ENV_CONFIG.SENTRY_DSN,
    ignoreErrors
  });

  // Datadog RUM
  !isE2E && ENV_CONFIG.DATADOG_RUM_APPLICATION_ID && ENV_CONFIG.DATADOG_RUM_CLIENT_TOKEN && datadogRum.init({
    applicationId: ENV_CONFIG.DATADOG_RUM_APPLICATION_ID,
    clientToken: ENV_CONFIG.DATADOG_RUM_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'chimichurri',
    env: ENV_CONFIG.HOST,
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    // By default the RUM SDK will not send any data to Datadog
    // See TrustArc Consent handler below that will set the tracking consent
    // depending on whether the user has accepted FUNCTIONAL cookies category
    trackingConsent: TrackingConsent.NOT_GRANTED,
    defaultPrivacyLevel: 'mask-user-input',
    enableExperimentalFeatures: ['feature_flags'],
    excludedActivityUrls: [/^https:\/\/(?!(((?!(analytics|analytics-api))([0-9a-z-]*)).yieldstreet(.com|.net|.cloud)|(localhost([:0-9]*))))/],
    allowedTracingUrls: [{
      match: /^https:\/\/((([0-9a-z-]*).yieldstreet(.com|.net|.cloud)|(localhost([:0-9]*)))((\/a\/api)|(\/api)))/,
      propagatorTypes: ['tracecontext']
    }]
  });
  addTrustArcConsentListener(() => {
    if (isConsentCategoryAllowed(ConsentCategory.FUNCTIONAL)) {
      datadogRum.setTrackingConsent(TrackingConsent.GRANTED);
    } else {
      datadogRum.setTrackingConsent(TrackingConsent.NOT_GRANTED);
    }
  });

  // Tracking Events
  const browserResult = browser();
  initEventManager({
    browserResult
  });

  // App Actions
  initAppActionManager({
    history: browserHistory
  });
};