import capitalize from "lodash/capitalize";
import { PortfolioStrategy, PortfolioStrategyValues } from '@yieldstreet/platform-kit';
const getStrategyLabel = managementStrategy => {
  switch (managementStrategy) {
    case PortfolioStrategyValues.Discretionary:
      return PortfolioStrategy.Managed;
    case PortfolioStrategyValues.Self:
    default:
      return PortfolioStrategy.SelfDirected;
  }
};
export const getDropdownLabel = managementStrategy => capitalize(`${getStrategyLabel(managementStrategy)} portfolio`);