import styled, { css } from 'styled-components';
import { Heading, media, Paragraph } from '@yieldstreet/ui-kit';
export const BannerContainer = styled.div.withConfig({
  displayName: "PromoBannerstyle__BannerContainer",
  componentId: "sc-1u43m7k-0"
})(["position:relative;display:inline-flex;width:100%;flex-direction:column-reverse;border-radius:4px;", " background:", ";flex:1;align-items:stretch;"], media.desktop`
    flex-direction: row;
    border-radius: 8px;
  `, props => props.inverse ? props.theme.colors.background_inverse : props.theme.colors.background_card);
export const BannerContent = styled.div.withConfig({
  displayName: "PromoBannerstyle__BannerContent",
  componentId: "sc-1u43m7k-1"
})(["display:flex;flex-direction:row;justify-content:space-between;padding:20px;", ""], media.desktop`
  ${props => props.imageFull && `flex-basis: ${props.bannerBasis};`}
    max-width: 40%;
    padding: 40px;
  `);
export const BannerText = styled.div.withConfig({
  displayName: "PromoBannerstyle__BannerText",
  componentId: "sc-1u43m7k-2"
})(["display:flex;flex-direction:column;"]);
export const Title = styled(Heading).withConfig({
  displayName: "PromoBannerstyle__Title",
  componentId: "sc-1u43m7k-3"
})(["margin-bottom:20px;"]);
export const Description = styled(Paragraph).withConfig({
  displayName: "PromoBannerstyle__Description",
  componentId: "sc-1u43m7k-4"
})(["flex-direction:column;flex-grow:1;margin-bottom:30px;", ""], media.desktop`
    flex-grow: 0;
  `);
export const CTAHolder = styled.div.withConfig({
  displayName: "PromoBannerstyle__CTAHolder",
  componentId: "sc-1u43m7k-5"
})(["button{width:100%;", ";}"], media.desktop`
      width: auto;
    `);
export const ImageWrapperFull = css(["", ";"], props => props.imageFull && css(["padding:0;flex:1;"]));
export const ImageFull = css(["", ";"], props => props.imageFull && css(["object-fit:cover;"]));
export const ImageWrapper = styled.div.withConfig({
  displayName: "PromoBannerstyle__ImageWrapper",
  componentId: "sc-1u43m7k-6"
})(["display:inline-flex;padding:30px 20px;padding-bottom:0;", " ", ""], ImageWrapperFull, media.desktop`
    padding: 40px;
    padding-left: 0;
    ${ImageWrapperFull}
  `);
export const Image = styled.img.withConfig({
  displayName: "PromoBannerstyle__Image",
  componentId: "sc-1u43m7k-7"
})(["width:100%;border-radius:8px 8px 0 0;", " ", ""], ImageFull, media.desktop`
    border-radius: 0 8px 8px 0;
  `);