import { useContext } from 'react';
import { PortfolioTaxContext } from './usePortfolioTaxContext';
export const usePortfolioTax = () => {
  const {
    addOrRemoveTaxDocumentId,
    downloadTaxDocuments,
    clearTaxDocumentIds,
    isDocumentSelected,
    setTaxDocumentIds,
    taxDocumentIds,
    hasDocumentSelected,
    isDownloadingTaxDocuments
  } = useContext(PortfolioTaxContext);
  return {
    addOrRemoveTaxDocumentId,
    downloadTaxDocuments,
    clearTaxDocumentIds,
    isDocumentSelected,
    setTaxDocumentIds,
    taxDocumentIds,
    hasDocumentSelected,
    isDownloadingTaxDocuments
  };
};