import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["children"];
var __jsx = React.createElement;
import React from 'react';
import styled, { css } from 'styled-components';
import { LinkStyles } from '../link';
import { TextBaseStyle } from './TextBase';
export const Table = _ref => {
  let {
      children
    } = _ref,
    knobs = _objectWithoutProperties(_ref, _excluded);
  return __jsx(TableElement, _extends({
    as: "span"
  }, knobs), children);
};
const normal = css(["font-weight:normal;"]);
const semiBold = css(["font-weight:500;"]);
export const TableBase = css(["font-family:", ";", " color:", ";margin:0;a{", "}"], props => props.theme.typography.primary, props => props.semiBold ? semiBold : normal, props => props.inverse ? props.theme.colors.text_paragraph_inverse : props.theme.colors.text_paragraph, LinkStyles);
export const TableStyle = css(["font-size:14px;line-height:19px;", ""], TableBase);
const TableElement = styled.span.withConfig({
  displayName: "Table__TableElement",
  componentId: "sc-i3rt8e-0"
})(["", ";", ";"], TableStyle, TextBaseStyle);