export const MuiTextField = {
  defaultProps: {
    variant: 'filled'
  },
  styleOverrides: {
    root: () => ({
      variants: [{
        props: {
          variant: 'outlined',
          color: 'primary'
        },
        style: _ref => {
          let {
            theme
          } = _ref;
          return {
            fieldset: {
              borderColor: theme.palette.border.default
            },
            '&:hover, .Mui-focused': {
              fieldset: {
                border: `1px solid ${theme.palette.border.strong} !important`
              }
            }
          };
        }
      }]
    })
  }
};