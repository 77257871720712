var __jsx = React.createElement;
import React from 'react';
import { PortfolioFiltersDesktop } from './PortfolioFiltersDesktop';
import { PortfolioFiltersMobile } from './PortfolioFiltersMobile';
export const PortfolioFilters = _ref => {
  let {
    actions,
    filters,
    handleFilterChange,
    handleFilterSubmit,
    isMobile,
    title
  } = _ref;
  const Component = isMobile ? PortfolioFiltersMobile : PortfolioFiltersDesktop;
  return __jsx(Component, {
    actions: actions,
    filters: filters,
    handleFilterChange: handleFilterChange,
    handleFilterSubmit: handleFilterSubmit,
    isMobile: isMobile,
    title: title
  });
};