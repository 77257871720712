import styled from 'styled-components';
import { media } from '@yieldstreet/ui-kit';
export const FormWrapper = styled.div.withConfig({
  displayName: "CreateInvestorAccountstyle__FormWrapper",
  componentId: "sc-ndyux4-0"
})(["max-width:400px;margin:auto;", " ", ""], props => props.hasMarginTop ? `margin-top: ${props.theme.spacing.m};` : '', media.tablet`
    width: 400px;
    ${props => props.hasMarginTop ? `margin-top: ${props.theme.spacing.l};` : ''}
`);
export const SubmitWrapper = styled.div.withConfig({
  displayName: "CreateInvestorAccountstyle__SubmitWrapper",
  componentId: "sc-ndyux4-1"
})(["display:block;"]);
export const ButtonContainer = styled.div.withConfig({
  displayName: "CreateInvestorAccountstyle__ButtonContainer",
  componentId: "sc-ndyux4-2"
})(["display:flex;flex-direction:column;margin-top:", ";> button:first-of-type{margin-bottom:10px;}"], _ref => {
  let {
    theme
  } = _ref;
  return theme.spacing.ml;
});