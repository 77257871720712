import { HttpMethods } from '../../enums/http';
import { Tags, restApi } from '../common';
export const pushApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.Push]
}).injectEndpoints({
  endpoints: builder => {
    const pushRegister = builder.mutation({
      query: params => {
        return {
          url: '/a/api/push/device',
          method: HttpMethods.Post,
          params
        };
      },
      invalidatesTags: [Tags.Push]
    });
    const pushUnregister = builder.mutation({
      query: params => {
        return {
          url: '/a/api/push/device',
          method: HttpMethods.Delete,
          params
        };
      },
      invalidatesTags: [Tags.Push]
    });
    return {
      pushRegister,
      pushUnregister
    };
  }
});