var __jsx = React.createElement;
import React from 'react';
import { Route, Switch, useLocation } from 'react-router';
import { UnitKYC } from './unit';
import { ETCKYCContainer, ETCAgreement } from './ETC';
import { PlaidKYC } from './plaid';
import { KYCMigrationContainer } from '../migration';
import { INVESTOR_ENTITY_KYC_MIGRATION_PATH } from '../migration/KYCMigrationContainer.model';
import { CREATE_INVESTOR_ENTITY_KYC_PROVIDER_ETC_INTRO_PATH, CREATE_INVESTOR_ENTITY_KYC_PROVIDER_ETC_PATH, CREATE_INVESTOR_ENTITY_KYC_PROVIDER_ETC_AGREEMENT_PATH, INVESTOR_ENTITY_KYC_PROVIDER_ETC_PATH, INVESTOR_ENTITY_KYB_PROVIDER_FOOTPRINT_PATH, INVESTOR_ENTITY_KYC_PROVIDER_UNIT_PATH, INVESTOR_ENTITY_KYC_PROVIDER_PLAID_PATH, INVESTOR_ENTITY_KYC_PROVIDER_PLAID_DISCRETIONARY_PATH } from './KYCProvidersContainer.model';
import { Footprint } from './footprint/Footprint';
import { ETCIntro } from './ETC/ETCIntro';
export const KYCProvidersContainer = () => {
  const location = useLocation();
  return __jsx(Switch, {
    location: location
  }, __jsx(Route, {
    path: INVESTOR_ENTITY_KYC_MIGRATION_PATH,
    component: KYCMigrationContainer
  }), __jsx(Route, {
    path: INVESTOR_ENTITY_KYC_PROVIDER_UNIT_PATH,
    component: UnitKYC
  }), __jsx(Route, {
    path: [INVESTOR_ENTITY_KYC_PROVIDER_PLAID_DISCRETIONARY_PATH, INVESTOR_ENTITY_KYC_PROVIDER_PLAID_PATH],
    component: PlaidKYC
  }), __jsx(Route, {
    path: INVESTOR_ENTITY_KYC_PROVIDER_ETC_PATH,
    component: ETCKYCContainer
  }), __jsx(Route, {
    path: INVESTOR_ENTITY_KYB_PROVIDER_FOOTPRINT_PATH,
    component: Footprint
  }), __jsx(Route, {
    path: CREATE_INVESTOR_ENTITY_KYC_PROVIDER_ETC_INTRO_PATH,
    component: ETCIntro
  }), __jsx(Route, {
    path: CREATE_INVESTOR_ENTITY_KYC_PROVIDER_ETC_AGREEMENT_PATH,
    component: ETCAgreement
  }), __jsx(Route, {
    path: [INVESTOR_ENTITY_KYC_PROVIDER_ETC_PATH, CREATE_INVESTOR_ENTITY_KYC_PROVIDER_ETC_PATH],
    component: ETCKYCContainer
  }));
};