import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { media } from '../../themes/media';
import { Heading, Label, Paragraph } from '../../content/text';
import { Button } from '../../content/button';
import { ExternalLink, LinkTextHoverStyles, LinkTextActiveStyles } from '../../content/link';
import { useResponsiveFriendly } from '../../utils/responsive';
import arrow from '../../assets/graphics/icons/arrow.svg';
import { Tooltip } from '../tooltip';
export const Card = _ref => {
  let {
    testId,
    image,
    icon,
    fullImageMobile,
    title,
    subtitle,
    description,
    cta,
    buttonCTA,
    tagTitle,
    tooltipDesc,
    tooltipKey,
    upperTitle,
    mobileDescription,
    titleDecoration,
    onClick
  } = _ref;
  const viewPort = useResponsiveFriendly();
  const isMobile = viewPort === 'mobile';
  return __jsx(React.Fragment, null, image && (fullImageMobile || !isMobile) && __jsx(ImageWrapper, null, __jsx(Image, {
    src: image
  })), __jsx(CardContent, {
    onClick: onClick,
    "data-cy": `${testId ? testId : 'card'}`
  }, icon && __jsx(IconWrapper, null, __jsx(Icon, {
    src: icon
  })), __jsx(CardText, null, __jsx(Label, null, upperTitle), __jsx(Title, {
    titleDecoration: titleDecoration,
    type: 5
  }, title), __jsx(TagWrapper, null, tagTitle && __jsx(Tag, null, __jsx(Label, {
    semiBold: true,
    inverse: true,
    small: true
  }, tagTitle)), tooltipDesc && __jsx(Tooltip, {
    testId: "card-tooltip",
    children: tooltipDesc,
    place: "top",
    tooltipKey: tooltipKey
  })), __jsx(Subtitle, null, subtitle), __jsx(Description, {
    mobileDescription: mobileDescription,
    small: true,
    fullImageMobile: !!fullImageMobile
  }, description), cta && __jsx(CTA, {
    fullImageMobile: !!fullImageMobile
  }, buttonCTA ? __jsx(Button, null, cta) : __jsx(ExternalLink, null, cta))), !fullImageMobile && __jsx(Arrow, {
    src: arrow
  })));
};
export const CardSelection = _ref2 => {
  let {
    options,
    buttonCTAs,
    cardBorder,
    fullImageMobile = false,
    tabletColumns = 2,
    desktopColumns = 3,
    mobileDescription = false
  } = _ref2;
  return __jsx(CardsContainer, {
    fullImageMobile: fullImageMobile
  }, options.map((option, index) => __jsx(CardWrapper, {
    key: index,
    tabletColumns: tabletColumns,
    desktopColumns: desktopColumns,
    cardBorder: cardBorder,
    fullImageMobile: fullImageMobile
  }, __jsx(Card, _extends({}, option, {
    buttonCTA: buttonCTAs,
    fullImageMobile: fullImageMobile,
    mobileDescription: mobileDescription
  })))));
};
const CardsContainer = styled.div.withConfig({
  displayName: "CardSelection__CardsContainer",
  componentId: "sc-7angxa-0"
})(["display:flex;flex-direction:column;margin:0 -20px;", " ", ""], media.phoneLandscape`
      ${props => props.fullImageMobile && 'flex-direction: row;'}
  `, media.tablet`
    ${props => !props.fullImageMobile && 'flex-direction: row;'}
    flex-wrap: wrap;
    padding: 0 10px;
  `);
const CardWrapper = styled.div.withConfig({
  displayName: "CardSelection__CardWrapper",
  componentId: "sc-7angxa-1"
})(["background:", ";margin:", ";display:flex;flex-direction:column;cursor:pointer;transition:all 0.2s ease-in-out;box-shadow:", ";flex-basis:100%;", " ", " ", " ", " ", ""], props => props.theme.colors.background_card, _ref3 => {
  let {
    theme
  } = _ref3;
  return `${theme.spacing.xxs} ${theme.spacing.m}`;
}, props => props.theme.shadows.shadow_default, props => props.cardBorder && `border: 1px solid ${props.theme.colors.border_default};`, media.phoneLandscape`
    ${props => props.fullImageMobile && 'margin: 10px;'}
  `, media.tablet`
    &:hover {
      transform: translateY(-2px);
      box-shadow: ${props => props.theme.shadows.shadow_hover};

      a {
        ${LinkTextHoverStyles}
      }

      button {
        & .text {
          ${LinkTextHoverStyles}
        }
      }
    }
    &:active {
      transform: translateY(2px);
      box-shadow: ${props => props.theme.shadows.shadow_active};

      a {
        ${LinkTextActiveStyles}
      }
    }
  `, media.tablet`
    ${props => !props.fullImageMobile && 'margin: 10px;'}
    flex-basis: calc((100% / ${props => props.tabletColumns}) - 20px);
  `, media.desktop`
    flex-basis: calc((100% / ${props => props.desktopColumns}) - 20px);
  `);
const CardContent = styled.div.withConfig({
  displayName: "CardSelection__CardContent",
  componentId: "sc-7angxa-2"
})(["display:flex;flex-direction:row;justify-content:space-between;padding:20px;height:100%;width:100%;"]);
const CardText = styled.div.withConfig({
  displayName: "CardSelection__CardText",
  componentId: "sc-7angxa-3"
})(["display:flex;flex-direction:column;"]);
const Title = styled(Heading).withConfig({
  displayName: "CardSelection__Title",
  componentId: "sc-7angxa-4"
})(["text-decoration:", ";", ";"], props => props.titleDecoration || 'none', media.tablet`
    margin-bottom: 5px;
  `);
const Subtitle = styled(Label).withConfig({
  displayName: "CardSelection__Subtitle",
  componentId: "sc-7angxa-5"
})(["cursor:pointer;", ""], media.tablet`
    margin-bottom: 20px;
  `);
const Description = styled(Paragraph).withConfig({
  displayName: "CardSelection__Description",
  componentId: "sc-7angxa-6"
})(["flex-grow:1;margin-bottom:20px;", " ", " ", ""], props => props.mobileDescription && 'margin-top: 10px;', props => props.fullImageMobile || props.mobileDescription ? 'display: block;' : 'display: none;', media.tablet`
    margin-bottom: 40px;
    margin-top: 0px;
    display: block;
  `);
const CTA = styled.div.withConfig({
  displayName: "CardSelection__CTA",
  componentId: "sc-7angxa-7"
})(["width:fit-content;", " ", ""], props => !props.fullImageMobile && 'display: none;', media.tablet`
    display: block;
  `);
const Arrow = styled.img.withConfig({
  displayName: "CardSelection__Arrow",
  componentId: "sc-7angxa-8"
})(["height:10px;transform:rotate(-90deg);align-self:center;", ""], media.tablet`
    display: none;
  `);
const TagWrapper = styled.div.withConfig({
  displayName: "CardSelection__TagWrapper",
  componentId: "sc-7angxa-9"
})(["display:flex;flex-direction:row;align-items:center;"]);
const Tag = styled.div.withConfig({
  displayName: "CardSelection__Tag",
  componentId: "sc-7angxa-10"
})(["background:", ";padding:0 6px 2px;margin-right:5px;"], props => props.theme.colors.background_inverse);
const ImageWrapper = styled.div.withConfig({
  displayName: "CardSelection__ImageWrapper",
  componentId: "sc-7angxa-11"
})(["width:100%;display:block;max-height:140px;overflow:hidden;", ";"], media.tablet`
    max-height: unset;
    overflow: unset;
  `);
const Image = styled.img.withConfig({
  displayName: "CardSelection__Image",
  componentId: "sc-7angxa-12"
})(["width:100%;"]);
const IconWrapper = styled.div.withConfig({
  displayName: "CardSelection__IconWrapper",
  componentId: "sc-7angxa-13"
})(["height:120px;margin-right:auto;display:block;"]);
const Icon = styled.img.withConfig({
  displayName: "CardSelection__Icon",
  componentId: "sc-7angxa-14"
})(["margin-bottom:20px;margin-right:auto;"]);