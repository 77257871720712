import { Heading, Paragraph, media } from '@yieldstreet/ui-kit';
import Carousel from 'nuka-carousel';
import styled from 'styled-components';
export const Wrapper = styled.div.withConfig({
  displayName: "CarouselSectionstyle__Wrapper",
  componentId: "sc-qv8j42-0"
})(["margin:0 auto 30px;position:relative;", ";"], media.tablet`
    margin: 0 auto 35px;
  `);
export const InnerWrapper = styled.div.withConfig({
  displayName: "CarouselSectionstyle__InnerWrapper",
  componentId: "sc-qv8j42-1"
})(["display:flex;", ";", ";"], media.tablet`
    background: ${props => props.theme.colors.background_page};
    margin: 0 auto;
    padding: 0 35px;
    min-height: 400px;
  `, media.large`
    border-radius: 15px;
    padding: 20px;
  `);
export const ControlWrapper = styled.div.withConfig({
  displayName: "CarouselSectionstyle__ControlWrapper",
  componentId: "sc-qv8j42-2"
})(["display:flex;flex-direction:column;justify-content:center;align-items:flex-start;width:40%;margin:20px 10%;color:", ";", ";"], props => props.theme.colors.text_background_inverse, media.tablet`
    margin: 20px 0;
  `);
export const ControlHeading = styled(Heading).withConfig({
  displayName: "CarouselSectionstyle__ControlHeading",
  componentId: "sc-qv8j42-3"
})(["margin-bottom:30px;"]);
export const SlideControl = styled(Heading).withConfig({
  displayName: "CarouselSectionstyle__SlideControl",
  componentId: "sc-qv8j42-4"
})(["cursor:pointer;margin-bottom:20px;font-weight:", ";"], props => props !== null && props !== void 0 && props.isActive ? 600 : 'normal');
export const DesktopContentWrapper = styled.div.withConfig({
  displayName: "CarouselSectionstyle__DesktopContentWrapper",
  componentId: "sc-qv8j42-5"
})(["display:block;"]);
export const MobileContentWrapper = styled.div.withConfig({
  displayName: "CarouselSectionstyle__MobileContentWrapper",
  componentId: "sc-qv8j42-6"
})(["background:", ";box-shadow:0 4px 8px rgba(0,0,0,0.1);margin:20px 6px;min-height:251px;"], props => props.theme.colors.background_card);
export const MobileCarouselHeadingWrapper = styled.div.withConfig({
  displayName: "CarouselSectionstyle__MobileCarouselHeadingWrapper",
  componentId: "sc-qv8j42-7"
})(["padding:20px;height:68px;background:", ";"], props => props.theme.colors.background_page);
export const MobileCarouselHeading = styled(ControlHeading).withConfig({
  displayName: "CarouselSectionstyle__MobileCarouselHeading",
  componentId: "sc-qv8j42-8"
})(["margin-bottom:0;"]);
export const MobileCarouselContent = styled(Paragraph).withConfig({
  displayName: "CarouselSectionstyle__MobileCarouselContent",
  componentId: "sc-qv8j42-9"
})(["padding:22px 20px 51px;"]);
export const SCarousel = styled(Carousel).withConfig({
  displayName: "CarouselSectionstyle__SCarousel",
  componentId: "sc-qv8j42-10"
})(["display:block;background:", ";margin:0;", ";.slider-control-centerright,.slider-control-centerleft{display:none;}.slider-control-bottomcenter{width:100% !important;text-align:center;bottom:-25px !important;", ";ul{justify-content:center !important;}}.paging-item{button{padding:0 4px !important;opacity:unset !important;fill:transparent !important;}&.active{.paging-dot{background:", " !important;}}.paging-dot{width:10px !important;height:10px !important;background:", " !important;border-radius:10px !important;}}.slider-list{width:100% !important;margin-bottom:40px !important;}.slider-slide{vertical-align:middle !important;}.slider-frame{margin:0 !important;", ";}"], props => props.theme.colors.background_default, media.tablet`
    margin: 10px 0;
    overflow: hidden !important;
    z-index: 2;
  `, media.tablet`
      display: none;
    `, props => props.theme.colors.chart_1, props => props.theme.colors.border_strong, media.tablet`
      max-width: 70% !important;
      margin: auto !important;
      position: absolute !important;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: visible !important;
    `);