import { HttpMethods } from '../../enums/http';
import { Tags, restApi } from '../common';
export const fomoMetricsApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.FomoMetrics]
}).injectEndpoints({
  endpoints: builder => {
    const getMetricNumber = builder.query({
      query: _ref => {
        let {
          metricName,
          noteId,
          from,
          to
        } = _ref;
        return {
          url: `/api/fomo/metric/${metricName}`,
          method: HttpMethods.Get,
          params: {
            noteId,
            from,
            to
          }
        };
      },
      providesTags: [Tags.FomoMetrics],
      transformResponse: function () {
        var _response$items, _response$items$;
        for (var _len = arguments.length, data = new Array(_len), _key = 0; _key < _len; _key++) {
          data[_key] = arguments[_key];
        }
        // TODO: check linter config to ensure we are targeting modern
        // es versions and eventually allow `_` for unused params.
        // eslint-disable-next-line
        const [{
          response
        }, _, {
          metricName
        }] = data;
        return response === null || response === void 0 ? void 0 : (_response$items = response.items) === null || _response$items === void 0 ? void 0 : (_response$items$ = _response$items[0]) === null || _response$items$ === void 0 ? void 0 : _response$items$[metricName];
      }
    });
    return {
      getMetricNumber
    };
  }
});