import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var __jsx = React.createElement;
import React, { useMemo, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button, PageLoader } from '@yieldstreet/ui-kit';
import { investorEntityApi, userApi } from '@yieldstreet/platform-kit';
import { useFormFlow, useApiErrorMessage, useApiEventTracking, BusinessEvent } from '@yieldstreet/tool-kit';
import FormAddressInputGroup from 'sharedComponents/FormAddressInputGroup';
import { useFlowLocation } from 'hooks/formFlow/useFlowLocation';
import { FormWrapper } from '../../CreateInvestorAccount.style';
import { getInvestorLabel } from '../../CreateInvestorAccount.utils';
import { useOnUrlChange, ADDRESS_STEP } from '../../CreateInvestorAccount.model';
import { FlowWrapper } from '../FlowWrapper';
import { ButtonContainer } from './Address.style';
import { formValidation, getInitialValues, testIds, TEST_ID_KEY } from './Address.model';
export const Address = _ref => {
  var _accountsResponse$ent;
  let {
    flowConfig
  } = _ref;
  const {
    investorEntityId,
    accountType
  } = useParams();
  const history = useHistory();
  const {
    useSaveUserContactMutation
  } = userApi;
  const {
    useGetInvestorEntityQuery,
    useUpdateInvestorEntityMutation
  } = investorEntityApi;
  const [updateInvestorAccount, updateInvestorAccountResult] = useUpdateInvestorEntityMutation();
  const [saveUserContact] = useSaveUserContactMutation();
  const {
    data: accountsResponse,
    isLoading: isLoadingInitialData
  } = useGetInvestorEntityQuery();
  const accountData = accountsResponse === null || accountsResponse === void 0 ? void 0 : (_accountsResponse$ent = accountsResponse.entities) === null || _accountsResponse$ent === void 0 ? void 0 : _accountsResponse$ent.find(entity => entity.id.toString() === investorEntityId);
  const syncContact = !['trust-with-ssn', 'trust-with-ein', 'entity'].includes(accountType);
  const {
    addressLabel
  } = getInvestorLabel(accountType);
  const submitCallback = useCallback(values => {
    const submitBody = {
      addressLine1: values.addressLine1,
      addressLine2: values === null || values === void 0 ? void 0 : values.addressLine2,
      city: values.city,
      state: values.stateStr.toUpperCase(),
      country: values === null || values === void 0 ? void 0 : values.country
    };
    if (syncContact) {
      saveUserContact(_objectSpread(_objectSpread({}, submitBody), {}, {
        zipCode: values.postalCode
      }));
    }
    updateInvestorAccount({
      investorEntityId,
      address: _objectSpread(_objectSpread({}, submitBody), {}, {
        postalCode: values.postalCode
      })
    });
  }, [investorEntityId, saveUserContact, syncContact, updateInvestorAccount]);
  const renderForm = formikProps => __jsx(FormWrapper, null, __jsx(FormAddressInputGroup, {
    label: addressLabel,
    formikProps: formikProps,
    nameMap: {
      state: 'stateStr'
    },
    testId: TEST_ID_KEY
  }), __jsx(ButtonContainer, null, __jsx(Button, _extends({
    type: "submit",
    trackingId: testIds.submitButton.id,
    loading: updateInvestorAccountResult === null || updateInvestorAccountResult === void 0 ? void 0 : updateInvestorAccountResult.isLoading,
    disabled: updateInvestorAccountResult === null || updateInvestorAccountResult === void 0 ? void 0 : updateInvestorAccountResult.isLoading,
    className: "saveSubmitButton"
  }, testIds.submitButton.attr), "Continue")));
  const formAddressConfig = useMemo(() => ({
    validation: formValidation,
    formikConfig: {
      initialValues: _objectSpread({}, getInitialValues(accountData)),
      enableReinitialize: false
    }
  }), [accountData]);
  const flowState = useFlowLocation({
    useOnUrlChange
  });
  const {
    Form,
    goToPreviousStep,
    isFirstStep
  } = useFormFlow({
    formConfig: formAddressConfig,
    flowConfig,
    flowState,
    submitConfig: updateInvestorAccountResult,
    submitCallback
  });
  const updateInvestorErrorMessage = useApiErrorMessage(updateInvestorAccountResult);
  useApiEventTracking(updateInvestorAccountResult, BusinessEvent.INVESTOR_CREATION_SUBMITTED, {
    step: ADDRESS_STEP
  }, updateInvestorErrorMessage);
  return isLoadingInitialData ? __jsx(PageLoader, testIds.pageLoader.attr) : __jsx(FlowWrapper, {
    flowConfig: flowConfig,
    step: flowState.step,
    investorAccountType: accountType,
    goToPreviousStep: isFirstStep ? () => history.push('/management/accounts') : goToPreviousStep,
    error: updateInvestorErrorMessage
  }, __jsx(Form, null, formikProps => renderForm(formikProps)));
};