import orderBy from "lodash/orderBy";
import { TaxListSortTypes } from '../../useTaxList.model';
export const sortData = _ref => {
  let {
    data = [],
    orderKey,
    sortKey
  } = _ref;
  switch (sortKey) {
    case TaxListSortTypes.InvestmentName:
    case TaxListSortTypes.InvestorAccountName:
    case TaxListSortTypes.Type:
    case TaxListSortTypes.Year:
      return orderBy(data, sortKey, orderKey);
    case TaxListSortTypes.PublishedDate:
      return orderBy(data, [TaxListSortTypes.PublishedDate, TaxListSortTypes.ExpectedDate], [orderKey, orderKey]);
    default:
      break;
  }
};