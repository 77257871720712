import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { ProviderMigrationStatus, getApiErrorMessage } from '@yieldstreet/tool-kit';
import { Button } from '@yieldstreet/ui-kit';
import { InvestorEntityActionStatus } from 'pages/investorEntity/components/InvestorEntityActionList';
import { KYC_MIGRATION_PATH } from './KYCMigration.model';
import { BANK_FLOW_LINK_A_BANK_ACCOUNT, BANK_FLOW_LINK_A_BANK_ACCOUNT_DEFAULT } from '../../../../../bankLinking/BankLinkingPageFlow.model';
export const getMigrationActionButton = function (_ref) {
  let {
    migrationConfig,
    startMigration,
    startMigrationState,
    launchMigrationRoute
  } = _ref;
  let buttonProps = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    id,
    status
  } = migrationConfig;
  const {
    isLoading,
    originalArgs
  } = startMigrationState;
  const isMutationInvestor = (originalArgs === null || originalArgs === void 0 ? void 0 : originalArgs.investorEntityId) === id;
  const isMutationLoading = isMutationInvestor && isLoading;
  switch (status) {
    case ProviderMigrationStatus.KYC_REQUIRED:
      return __jsx(Button, _extends({}, buttonProps, {
        buttonType: "primary",
        loading: isMutationLoading,
        disabled: isMutationLoading || isLoading,
        onClick: () => startMigration({
          investorEntityId: id
        })
      }), "Reactivate account");
    case ProviderMigrationStatus.KYC_INCOMPLETE:
      return __jsx(Button, _extends({}, buttonProps, {
        buttonType: "primary",
        disabled: isLoading,
        onClick: () => launchMigrationRoute(id, KYC_MIGRATION_PATH)
      }), "Continue");
    case ProviderMigrationStatus.KYC_PENDING:
      return __jsx(Button, _extends({}, buttonProps, {
        disabled: true
      }), "Pending");
    case ProviderMigrationStatus.BANK_LINK_REQUIRED:
      return __jsx(Button, _extends({}, buttonProps, {
        buttonType: "primary",
        disabled: isLoading,
        onClick: () => launchMigrationRoute(id, BANK_FLOW_LINK_A_BANK_ACCOUNT)
      }), "Continue");
    case ProviderMigrationStatus.DEFAULT_BANK_REQUIRED:
      return __jsx(Button, _extends({}, buttonProps, {
        buttonType: "primary",
        disabled: isLoading,
        onClick: () => launchMigrationRoute(id, BANK_FLOW_LINK_A_BANK_ACCOUNT_DEFAULT)
      }), "Continue");
    case ProviderMigrationStatus.COMING_SOON:
      return __jsx(Button, _extends({}, buttonProps, {
        disabled: true
      }), "Coming soon");
  }
};
export const getMigrationActionConfig = params => {
  var _migrationConfig$bank;
  const {
    migrationConfig,
    startMigrationState
  } = params;
  const {
    id,
    name,
    type,
    status
  } = migrationConfig;
  const defaultBankAccount = migrationConfig === null || migrationConfig === void 0 ? void 0 : (_migrationConfig$bank = migrationConfig.bankLinkDetails) === null || _migrationConfig$bank === void 0 ? void 0 : _migrationConfig$bank.defaultBankAccount;
  const {
    error,
    originalArgs
  } = startMigrationState;
  const isMutationInvestor = (originalArgs === null || originalArgs === void 0 ? void 0 : originalArgs.investorEntityId) === id;
  const actionError = isMutationInvestor && error && getApiErrorMessage(error);
  const actionButton = getMigrationActionButton(params, {
    reducedPadding: true,
    small: true
  });
  let statusConfig = {};
  switch (status) {
    case ProviderMigrationStatus.KYC_FAILED:
      statusConfig = {
        status: InvestorEntityActionStatus.FAILURE,
        statusText: 'Reactivation failed'
      };
      break;
    case ProviderMigrationStatus.KYC_ERROR:
      statusConfig = {
        status: InvestorEntityActionStatus.FAILURE,
        statusText: 'Reactivation failed'
      };
      break;
    case ProviderMigrationStatus.COMPLETED:
      statusConfig = {
        status: InvestorEntityActionStatus.SUCCESS,
        statusText: 'Account reactivated'
      };
      break;
    case ProviderMigrationStatus.PENDING_MICRO_DEPOSIT:
      statusConfig = {
        statusText: 'Partially reactivated'
      };
      break;
    case ProviderMigrationStatus.NOT_APPLICABLE:
      statusConfig = {
        statusText: 'No change needed'
      };
      break;
  }
  return _objectSpread(_objectSpread({
    id,
    name,
    description: type
  }, statusConfig), {}, {
    actionButton,
    actionError,
    defaultBankAccount
  });
};