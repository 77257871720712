export const updateHeaders = cssTHEME => {
  cssTHEME.typography.h1 = {
    fontSize: 38,
    [cssTHEME.breakpoints.up('sm')]: {
      fontSize: 46
    },
    [cssTHEME.breakpoints.up('lg')]: {
      fontSize: 46
    }
  };
  cssTHEME.typography.h2 = {
    fontSize: 28,
    [cssTHEME.breakpoints.up('sm')]: {
      fontSize: 36
    },
    [cssTHEME.breakpoints.up('lg')]: {
      fontSize: 42
    }
  };
  cssTHEME.typography.h3 = {
    fontSize: 24,
    [cssTHEME.breakpoints.up('sm')]: {
      fontSize: 30
    },
    [cssTHEME.breakpoints.up('lg')]: {
      fontSize: 34
    }
  };
  cssTHEME.typography.h4 = {
    fontSize: '20px',
    fontWeight: 500,
    [cssTHEME.breakpoints.up('sm')]: {
      fontSize: '24px'
    }
  };
  cssTHEME.typography.h5 = {
    fontSize: '18px',
    [cssTHEME.breakpoints.up('sm')]: {
      fontSize: '20px'
    }
  };
  cssTHEME.typography.h6 = {
    fontSize: '18px'
  };
  return cssTHEME;
};