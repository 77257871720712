import { SignupExperienceAltsValues } from '@yieldstreet/platform-kit';
import { TEST_ID_ATTR } from '../../testing';
export const SignupExperienceAltsOptions = [{
  value: SignupExperienceAltsValues.YES,
  label: 'Yes, I have experience with alternatives',
  investorDesc: 'You’re a seasoned alternative investor looking to diversify your portfolio with alternatives.',
  [TEST_ID_ATTR]: 'quiz-experience-YES'
}, {
  value: SignupExperienceAltsValues.NO,
  label: "No, I haven't invested in alternatives yet",
  investorDesc: 'You’re new to alternatives looking to diversify your portfolio with alternatives.',
  [TEST_ID_ATTR]: 'quiz-experience-NO'
}];
export const SignupExperienceAltsLabels = {
  title: 'Have you invested in private market alternatives before?',
  subtitle: 'Alternatives include categories like Real Estate, Art, Private Credit, Venture Capital, Private Equity, Legal, and Transportation. They are not publicly traded stocks, bonds, or funds.'
};