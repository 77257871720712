import _Alert from "@mui/material/Alert";
import _Divider from "@mui/material/Divider";
import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _Box from "@mui/material/Box";
import _Link from "@mui/material/Link";
var __jsx = React.createElement;
import React, { useMemo } from 'react';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import find from "lodash/find";
import isUndefined from "lodash/isUndefined";
import { useHistory, useLocation } from 'react-router-dom';
import { BankAccountType, InvestorEntityTypeName, bankAccountApi } from '@yieldstreet/platform-kit';
import { DesktopOnly, MobileOnly, NavigationTabList, PageFlow, SurfaceType } from '@yieldstreet/ui-kit';
import { useDiscretionaryFlags } from 'hooks';
import ArrowBack from 'assets/icons/images/chevron-left.svg';
import { renderLink } from 'sharedComponents/Links/Links';
import { getInvestorEntityRoute, useRouteInvestorEntityId, useRouteInvestorEntity } from 'pages/investorEntity/utils';
import { INVESTOR_ENTITY_MANAGEMENT_PATH } from 'pages/investorEntity/InvestorEntityContainer.model';
import { useUserState } from 'utils/hooks';
import { BackButtonHeader, Wrapper, InformationWrapper, InfoBlock, ContentWrapper } from './AccountLayout.style';
import { links, testIds } from './AccountLayout.model';
import { INVESTOR_ENTITY_MNGT_ROOT_PATH } from '../../ManagementContainer.model';
import { useInvestorEntityAction, useInvestorEntityActionHandler } from '../../utils';
dayjs.extend(localizedFormat);
export const AccountLayout = _ref => {
  let {
    children
  } = _ref;
  const investorEntityId = useRouteInvestorEntityId();
  const {
    investorEntity,
    investorEntityStatus,
    refetch
  } = useRouteInvestorEntity();
  const userState = useUserState();
  const history = useHistory();
  const location = useLocation();
  const {
    showManagedPortfolio
  } = useDiscretionaryFlags();
  const {
    nextAction
  } = useInvestorEntityAction({
    investorEntity,
    investorEntityStatus,
    onStepSuccess: refetch
  });
  const {
    handleOnActionClick
  } = useInvestorEntityActionHandler();
  const notificationConfig = useMemo(() => {
    if (investorEntityStatus !== null && investorEntityStatus !== void 0 && investorEntityStatus.readyToInvest || !nextAction) {
      return;
    }
    const result = {
      severity: 'warning',
      title: 'Pending account action:',
      link: __jsx(_Link, {
        onClick: () => handleOnActionClick({
          action: nextAction === null || nextAction === void 0 ? void 0 : nextAction.action,
          link: nextAction === null || nextAction === void 0 ? void 0 : nextAction.link,
          investorEntityId: investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.id
        })
      }, nextAction.title)
    };
    return result;
  }, [investorEntityStatus, nextAction, handleOnActionClick, investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.id]);
  const {
    data: bankAccounts
  } = bankAccountApi.useGetEntityBankAccountsQuery({
    investorEntityId
  });
  const isEntityWalletVisible = useMemo(() => {
    return !isUndefined(find(bankAccounts === null || bankAccounts === void 0 ? void 0 : bankAccounts.bankAccounts, account => (account === null || account === void 0 ? void 0 : account.accountType) === BankAccountType.YWLT));
  }, [bankAccounts]);
  return __jsx(React.Fragment, null, __jsx(DesktopOnly, null, __jsx(BackButtonHeader, _extends({
    onClick: () => history.push(INVESTOR_ENTITY_MANAGEMENT_PATH)
  }, testIds.backButton.attr), __jsx(_Box, {
    component: "img",
    width: 10,
    height: 10,
    mr: 3,
    src: ArrowBack
  }), __jsx(_Link, null, "Back")), __jsx(Wrapper, null, __jsx(_Typography, _extends({
    variant: "h2",
    maxWidth: 710
  }, testIds.heading.attr), investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.name), __jsx(InformationWrapper, {
    small: true,
    type: SurfaceType.Outline
  }, __jsx(InfoBlock, testIds.createdInfo.attr, __jsx(_Typography, {
    component: "span",
    variant: "body2"
  }, "Created"), __jsx(_Typography, _extends({
    variant: "body1"
  }, testIds.createdDate.attr), dayjs(investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.createdDate).format('ll'))), __jsx(_Divider, {
    variant: "middle",
    orientation: "vertical"
  }), __jsx(InfoBlock, testIds.accountTypeInfo.attr, __jsx(_Typography, {
    component: "span",
    variant: "body2"
  }, "Type"), __jsx(_Typography, _extends({
    variant: "body1"
  }, testIds.accountType.attr), (investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.type.name) && InvestorEntityTypeName[investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.type.name])))), notificationConfig && __jsx(_Alert, _extends({
    severity: notificationConfig.severity
  }, testIds.notification.attr), __jsx(_Typography, {
    component: "span",
    variant: "body2",
    sx: {
      fontWeight: 500
    }
  }, notificationConfig.title), __jsx(_Box, null, notificationConfig.link)), __jsx(ContentWrapper, null, __jsx(_Box, null, __jsx(NavigationTabList, {
    userState: userState,
    currentPath: `${location === null || location === void 0 ? void 0 : location.pathname}`,
    navigationConfig: links(investorEntityId, showManagedPortfolio, isEntityWalletVisible),
    renderLink: renderLink,
    textWrap: "nowrap",
    vertical: true
  })), __jsx(_Box, {
    sx: {
      width: '100%',
      marginLeft: 20
    }
  }, children))), __jsx(MobileOnly, null, notificationConfig && __jsx(_Alert, _extends({
    severity: notificationConfig.severity
  }, testIds.notification.attr), __jsx(_Typography, {
    component: "span",
    variant: "body2",
    sx: {
      fontWeight: 500
    }
  }, notificationConfig.title), __jsx(_Box, null, notificationConfig.link)), __jsx(PageFlow, {
    className: "pageFlow",
    backNavigation: true,
    backNavigationText: "Back",
    backNavigationCallback: () => history.push(location.pathname === getInvestorEntityRoute(investorEntityId, INVESTOR_ENTITY_MNGT_ROOT_PATH) ? INVESTOR_ENTITY_MANAGEMENT_PATH : getInvestorEntityRoute(investorEntityId, INVESTOR_ENTITY_MNGT_ROOT_PATH)),
    light: true,
    showLogo: false
  }, children)));
};