import { HttpMethods } from '../../enums/http';
import { restApi, Tags } from '../common';
const BASE_URL = '/a/api/payments-methods';
export const paymentMethodsApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.PaymentMethods]
}).injectEndpoints({
  endpoints: builder => {
    const paymentMethods = builder.query({
      query: _ref => {
        let {
          investorAccountId,
          investmentAmount,
          urlHashes,
          rolloverAmountAvailable,
          rolloverInvestmentIds,
          rolloverSourceUrlHash
        } = _ref;
        return {
          url: `${BASE_URL}/${investorAccountId}`,
          method: HttpMethods.Get,
          params: {
            investmentAmount,
            urlHash: urlHashes,
            rolloverAmountAvailable,
            rolloverInvestmentIds,
            rolloverSourceUrlHash
          }
        };
      },
      providesTags: [Tags.PaymentMethods]
    });
    return {
      paymentMethods
    };
  }
});