import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _defineProperty from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import * as api from '@opentelemetry/api';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
export class Tracer {
  constructor(tracerName) {
    let _spanCallbacks = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    this._spanCallbacks = _spanCallbacks;
    _defineProperty(this, "_tracer", void 0);
    this._tracer = api.trace.getTracer(tracerName);
  }
  static setGlobalAttributes(attributes) {
    this._globalAttributes = omitBy(_objectSpread(_objectSpread({}, this._globalAttributes), attributes), isUndefined);
  }
  static setGlobalCallbacks(callbacks) {
    this._globalSpanCallbacks = omitBy(_objectSpread(_objectSpread({}, this._globalSpanCallbacks), callbacks), isUndefined);
  }
  executeCallback(key, span) {
    const globalCallback = Tracer._globalSpanCallbacks[key];
    if (globalCallback) {
      globalCallback(span);
    }
    const tracerCallback = this._spanCallbacks[key];
    if (tracerCallback) {
      tracerCallback(span);
    }
  }
  startSpan(spanName) {
    let attributes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let parent = arguments.length > 2 ? arguments[2] : undefined;
    let worker = arguments.length > 3 ? arguments[3] : undefined;
    const result = this._tracer.startActiveSpan(spanName, {
      attributes: _objectSpread(_objectSpread({}, Tracer._globalAttributes), omitBy(attributes, isUndefined))
    }, parent ? api.trace.setSpan(api.context.active(), parent) : api.context.active(), span => {
      this.executeCallback('onStart', span);
      return worker(span);
    });
    return result;
  }
  handleSpanSuccess(span) {
    span.setStatus({
      code: api.SpanStatusCode.OK
    });
    this.executeCallback('onSuccess', span);
  }
  handleSpanError(span, error) {
    if (error && span.isRecording()) {
      const errorString = isString(error) ? error : error.stack;
      span.recordException(error);
      span.setStatus({
        code: api.SpanStatusCode.ERROR,
        message: errorString
      });
      this.executeCallback('onError', span);
    }
  }
  endSpan(span) {
    if (span.isRecording()) {
      this.executeCallback('onEnd', span);
      span.end();
    }
  }
  trace(worker, spanName, attributes, parent) {
    return this.startSpan(spanName, attributes, parent, span => {
      try {
        const result = worker(span);
        this.handleSpanSuccess(span);
        return result;
      } catch (error) {
        this.handleSpanError(span, error);
        throw error;
      } finally {
        this.endSpan(span);
      }
    });
  }
  traceAsync(worker, spanName, attributes, parent) {
    return this.startSpan(spanName, attributes, parent, async span => {
      try {
        const result = await worker(span);
        this.handleSpanSuccess(span);
        return result;
      } catch (error) {
        this.handleSpanError(span, error);
        throw error;
      } finally {
        this.endSpan(span);
      }
    });
  }
}
_defineProperty(Tracer, "_globalSpanCallbacks", {});
_defineProperty(Tracer, "_globalAttributes", {});