import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import update from 'immutability-helper';
import * as actions from 'store/actions/user';
const loggedOutUserState = {
  loggedIn: false
};
const initialState = {
  isFetching: false,
  isAuthenticating: false,
  authError: false,
  authErrorFields: null,
  authErrorMessage: null,
  authResult: null,
  twoFAStatusError: false,
  twoFAStatusFetching: false,
  twoFAStatus: null,
  resetPw: false,
  resetPwError: false,
  resetPwErrorMessage: 'Error',
  profile: {
    showBeneficiary: false,
    hasPreferredAssetClass: false,
    hasPassword: undefined
  },
  profileFetching: false,
  profileFetched: false,
  profileError: false,
  userHeaders: {},
  userState: loggedOutUserState,
  userStateTime: null,
  userStateIsFetching: false,
  tempUserState: null,
  userName: null,
  userNameIsFetching: false,
  beneficiaryData: {
    primary: null,
    contingent: null
  },
  beneficiaryFetching: false,
  beneficiaryVerifying: false,
  showEmailConfirmation: false,
  analyticsAnonymousId: null,
  analyticsUserId: null,
  review: {
    data: {
      accessToken: null,
      location: null,
      device: null,
      createdAt: null,
      reviewedAt: null,
      status: null
    },
    error: null,
    isFetching: false
  },
  userAccreditationEligibility: null,
  userAccreditationEligibilityFetching: null,
  twoFA: {
    register: {
      loading: false,
      success: false,
      error: null
    },
    confirm: {
      loading: false,
      success: false,
      error: null
    }
  }
};
export const userAnonymizer = {
  twoFAStatus: 'user2FAStatus',
  profile: 'userProfile',
  userName: 'string',
  userState: 'userState',
  userHeaders: {
    cookie: 'string'
  }
};
const userReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let action = arguments.length > 1 ? arguments[1] : undefined;
  // The below is done to avoid creating a state object every time (even when there is no changes)
  const stateProps = _objectSpread({}, state);
  state = Object.assign(state, initialState, stateProps);
  switch (action.type) {
    case actions.REGISTER_PASSWORD_ATTEMPT:
    case actions.LOGIN_PASSWORD_ATTEMPT:
    case actions.LOGIN_SOCIAL_ATTEMPT:
      {
        return update(state, {
          isAuthenticating: {
            $set: true
          },
          authError: {
            $set: false
          },
          authErrorFields: {
            $set: null
          },
          authErrorMessage: {
            $set: null
          }
        });
      }
    case actions.LOGIN_REVIEW_ATTEMPT:
      {
        return update(state, {
          isFetching: {
            $set: true
          },
          error: {
            $set: false
          }
        });
      }
    case actions.LOGIN_REVIEW_UPDATE_ATTEMPT:
      {
        return update(state, {
          isFetching: {
            $set: true
          },
          error: {
            $set: false
          }
        });
      }
    case actions.LOGOUT_ATTEMPT:
      {
        return update(state, {
          isFetching: {
            $set: true
          },
          tempUserState: {
            $set: state.userState
          },
          userState: {
            $set: {
              loggedIn: false
            }
          }
        });
      }
    case actions.GET_2FA_STATUS_ATTEMPT:
      {
        return update(state, {
          twoFAStatusFetching: {
            $set: true
          }
        });
      }
    case actions.RESET_PASSWORD_ATTEMPT:
    case actions.UPDATE_PASSWORD_ATTEMPT:
      {
        return update(state, {
          resetPw: {
            $set: false
          },
          resetPwError: {
            $set: false
          },
          isFetching: {
            $set: true
          }
        });
      }
    case actions.EDIT_USER_NAME_ATTEMPT:
      {
        return update(state, {
          userNameIsFetching: {
            $set: true
          }
        });
      }
    case actions.EDIT_USER_NAME_SUCCESS:
      {
        return update(state, {
          userNameIsFetching: {
            $set: false
          },
          userName: {
            $set: action.response
          },
          userState: {
            displayName: {
              $set: `${action.requestPayload.firstName} ${action.requestPayload.lastName}`
            },
            firstName: {
              $set: action.requestPayload.firstName
            },
            lastName: {
              $set: action.requestPayload.lastName
            }
          }
        });
      }
    case actions.EDIT_USER_NAME_FAILURE:
      {
        return update(state, {
          userNameIsFetching: {
            $set: false
          }
        });
      }
    case actions.GET_USER_PROFILE_ATTEMPT:
      {
        return update(state, {
          profileFetching: {
            $set: true
          },
          profileError: {
            $set: false
          },
          profileFetched: {
            $set: false
          }
        });
      }
    case actions.GET_USER_BENEFICIARY_ATTEMPT:
      {
        return update(state, {
          beneficiaryFetching: {
            $set: true
          }
        });
      }
    case actions.VERIFY_BENEFICIARY_ATTEMPT:
      {
        return update(state, {
          beneficiaryVerifying: {
            $set: true
          }
        });
      }
    case actions.REGISTER_PASSWORD_SUCCESS:
    case actions.LOGIN_PASSWORD_SUCCESS:
    case actions.LOGIN_SOCIAL_SUCCESS:
      {
        /**
         * We must check if res.data exists bc it doesn't
         * on locked accounts.
         */
        const data = action.response.data;
        if (!data) {
          return state;
        }
        return update(state, {
          isAuthenticating: {
            $set: false
          },
          authResult: {
            $set: data
          }
        });
      }
    case actions.LOGOUT_SUCCESS:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          tempUserState: {
            $set: null
          }
        });
      }
    case actions.LOGIN_REVIEW_SUCCESS:
      {
        return update(state, {
          review: {
            isFetching: {
              $set: false
            },
            data: {
              $set: action.response.review
            }
          }
        });
      }
    case actions.LOGIN_REVIEW_UPDATE_SUCCESS:
      {
        return update(state, {
          review: {
            isFetching: {
              $set: false
            },
            data: {
              $set: action.response.review
            }
          }
        });
      }
    case actions.GET_2FA_STATUS_SUCCESS:
      {
        return update(state, {
          twoFAStatus: {
            $set: action.response
          },
          twoFAStatusFetching: {
            $set: false
          }
        });
      }
    case actions.RESET_PASSWORD_SUCCESS:
    case actions.UPDATE_PASSWORD_SUCCESS:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          resetPw: {
            $set: true
          }
        });
      }
    case actions.GET_USER_PROFILE_SUCCESS:
      {
        return update(state, {
          profileFetching: {
            $set: false
          },
          profileFetched: {
            $set: true
          },
          profile: {
            $set: _objectSpread(_objectSpread({}, action.response.profile), {}, {
              showBeneficiary: action.response.showBeneficiary,
              hasPreferredAssetClass: action.response.hasPreferredAssetClass,
              hasPassword: action.response.hasPassword
            })
          }
        });
      }
    case actions.GET_USER_BENEFICIARY_SUCCESS:
      {
        return update(state, {
          beneficiaryFetching: {
            $set: false
          },
          beneficiaryData: {
            $set: action.response
          }
        });
      }
    case actions.VERIFY_BENEFICIARY_SUCCESS:
      {
        return update(state, {
          beneficiaryVerifying: {
            $set: false
          }
        });
      }
    case actions.REGISTER_PASSWORD_FAILURE:
    case actions.LOGIN_PASSWORD_FAILURE:
    case actions.LOGIN_SOCIAL_FAILURE:
      {
        var _action$beError, _action$beError$respo, _action$beError$respo2;
        return update(state, {
          isAuthenticating: {
            $set: false
          },
          authError: {
            $set: true
          },
          authErrorFields: {
            $set: ((_action$beError = action.beError) === null || _action$beError === void 0 ? void 0 : (_action$beError$respo = _action$beError.response) === null || _action$beError$respo === void 0 ? void 0 : (_action$beError$respo2 = _action$beError$respo.data) === null || _action$beError$respo2 === void 0 ? void 0 : _action$beError$respo2.errors) || null
          },
          authErrorMessage: {
            $set: action.error
          }
        });
      }
    case actions.RESET_USER_AUTH:
      {
        return update(state, {
          authResult: {
            $set: null
          },
          authError: {
            $set: false
          },
          authErrorFields: {
            $set: null
          },
          authErrorMessage: {
            $set: null
          }
        });
      }
    case actions.LOGOUT_FAILURE:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          authError: {
            $set: true
          },
          authErrorMessage: {
            $set: action.error
          },
          userState: {
            $set: state.tempUserState
          }
        });
      }
    case actions.LOGIN_REVIEW_FAILURE:
      {
        return update(state, {
          review: {
            isFetching: {
              $set: false
            },
            error: {
              $set: action.error
            }
          }
        });
      }
    case actions.LOGIN_REVIEW_UPDATE_FAILURE:
      {
        return update(state, {
          review: {
            isFetching: {
              $set: false
            },
            error: {
              $set: action.error
            }
          }
        });
      }
    case actions.GET_2FA_STATUS_FAILURE:
      {
        return update(state, {
          twoFAStatusFetching: {
            $set: false
          },
          twoFAStatusError: {
            $set: true
          }
        });
      }
    case actions.RESET_PASSWORD_FAILURE:
    case actions.UPDATE_PASSWORD_FAILURE:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          resetPwError: {
            $set: true
          },
          resetPwErrorMessage: {
            $set: action.error
          }
        });
      }
    case actions.GET_USER_PROFILE_FAILURE:
      {
        return update(state, {
          profileFetching: {
            $set: false
          },
          profileFetched: {
            $set: true
          },
          profileError: {
            $set: true
          }
        });
      }
    case actions.GET_USER_BENEFICIARY_FAILURE:
      {
        return update(state, {
          beneficiaryFetching: {
            $set: false
          }
        });
      }
    case actions.VERIFY_BENEFICIARY_FAILURE:
      {
        return update(state, {
          beneficiaryVerifying: {
            $set: false
          }
        });
      }
    case actions.TWOFA_STATUS_RESET:
      {
        return update(state, {
          twoFAStatus: {
            $set: null
          }
        });
      }
    case actions.TWOFA_REGISTER_STATUS_RESET:
      {
        return update(state, {
          twoFA: {
            register: {
              loading: {
                $set: false
              },
              success: {
                $set: false
              },
              error: {
                $set: null
              }
            }
          }
        });
      }
    case actions.TWOFA_CONFIRM_STATUS_RESET:
      {
        return update(state, {
          twoFA: {
            confirm: {
              loading: {
                $set: false
              },
              success: {
                $set: false
              },
              error: {
                $set: null
              }
            }
          }
        });
      }
    case actions.PASSWORD_STATE_RESET:
      {
        return update(state, {
          resetPwError: {
            $set: false
          },
          resetPw: {
            $set: false
          }
        });
      }
    case actions.RESET_USER_NAME:
      {
        return update(state, {
          userName: {
            $set: null
          }
        });
      }
    case actions.SET_USER_STATE:
      {
        return update(state, {
          userState: {
            $set: action.userState || loggedOutUserState
          }
        });
      }
    case actions.SHOW_USER_EMAIL_CONFIRMATION:
      {
        return update(state, {
          showEmailConfirmation: {
            $set: true
          }
        });
      }
    case actions.HIDE_USER_EMAIL_CONFIRMATION:
      {
        return update(state, {
          showEmailConfirmation: {
            $set: false
          }
        });
      }
    case actions.SET_USER_HEADERS:
      {
        return update(state, {
          userHeaders: {
            $set: action.headers
          }
        });
      }
    case actions.CLEAR_USER_HEADERS:
      {
        return update(state, {
          userHeaders: {
            $set: null
          }
        });
      }
    case actions.SET_ANALYTICS_ANONYMOUS_ID:
      {
        return update(state, {
          analyticsAnonymousId: {
            $set: action.anonymousId
          }
        });
      }
    case actions.SET_ANALYTICS_USER_ID:
      {
        return update(state, {
          analyticsUserId: {
            $set: action.userId
          }
        });
      }
    case actions.GET_USER_ACCREDITATION_ELIGIBILITY_ATTEMPT:
      {
        return update(state, {
          userAccreditationEligibilityFetching: {
            $set: true
          },
          userAccreditationEligibility: {
            $set: null
          }
        });
      }
    case actions.GET_USER_ACCREDITATION_ELIGIBILITY_SUCCESS:
      {
        return update(state, {
          userAccreditationEligibilityFetching: {
            $set: false
          },
          userAccreditationEligibility: {
            $set: action.response
          }
        });
      }
    case actions.GET_USER_ACCREDITATION_ELIGIBILITY_FAILURE:
      {
        return update(state, {
          userAccreditationEligibilityFetching: {
            $set: false
          }
        });
      }
    case actions.REGISTER_2FA_SUCCESS:
      {
        return update(state, {
          twoFA: {
            register: {
              loading: {
                $set: false
              },
              success: {
                $set: true
              }
            }
          }
        });
      }
    case actions.REGISTER_2FA_ATTEMPT:
      {
        return update(state, {
          twoFA: {
            register: {
              loading: {
                $set: true
              },
              success: {
                $set: false
              }
            }
          }
        });
      }
    case actions.REGISTER_2FA_FAILURE:
      {
        return update(state, {
          twoFA: {
            register: {
              loading: {
                $set: false
              },
              error: {
                $set: action.error
              },
              success: {
                $set: false
              }
            }
          }
        });
      }
    case actions.CONFIRM_2FA_SUCCESS:
      {
        return update(state, {
          twoFA: {
            confirm: {
              loading: {
                $set: false
              },
              success: {
                $set: true
              }
            }
          }
        });
      }
    case actions.CONFIRM_2FA_ATTEMPT:
      {
        return update(state, {
          twoFA: {
            confirm: {
              loading: {
                $set: true
              },
              success: {
                $set: false
              }
            }
          }
        });
      }
    case actions.CONFIRM_2FA_FAILURE:
      {
        return update(state, {
          twoFA: {
            confirm: {
              loading: {
                $set: false
              },
              error: {
                $set: action.error
              },
              success: {
                $set: false
              }
            }
          }
        });
      }
    default:
      return state;
  }
};
export default userReducer;