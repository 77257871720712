import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import sortBy from "lodash/sortBy";
import { createYupObjectSchema } from '@yieldstreet/tool-kit';
import { countriesOptions } from 'pages/newCreateInvestorAccount/screens/KYC/KYC.utils';
import { US_COUNTRY, pageStepContent } from './Citizenship.model';
export const formValidation = createYupObjectSchema({
  citizenshipCountries: {
    validationType: 'default-array'
  }
});

// Create a map of country values to labels for faster lookup
const countryLabelMap = new Map(countriesOptions.map(country => [country.value, country.label]));
export const sortCountries = function () {
  let countries = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  // Convert countries to a Set for faster lookup
  const countriesSet = new Set(countries);

  // Separate US_COUNTRY and other countries and sort by label
  const otherCountries = countriesOptions.reduce((acc, countryObj) => {
    if (countriesSet.has(countryObj.value) && countryObj.value !== US_COUNTRY) {
      acc.push({
        country: countryObj.value
      });
    }
    return acc;
  }, []);
  const sortedCountries = sortBy(otherCountries, countryObj => countryLabelMap.get(countryObj.country));

  // Add US_COUNTRY to the start if it's in the countries array
  if (countriesSet.has(US_COUNTRY)) {
    return [{
      country: US_COUNTRY
    }, ...sortedCountries];
  }
  return sortedCountries;
};
export const getPageStepContent = _ref => {
  let {
    onClick
  } = _ref;
  return _objectSpread(_objectSpread({}, pageStepContent), {}, {
    cta: _objectSpread(_objectSpread({}, pageStepContent.cta), {}, {
      callback: onClick
    })
  });
};