import _Box from "@mui/material/Box";
var __jsx = React.createElement;
import React from 'react';
import { ContentHolder, CTAHolder, Headline, Image, ImageHolder, InfoWrapper, OuterContainer, Paragraph } from './ProductCard.style';
export const ProductCard = _ref => {
  let {
    accreditationRequired,
    children,
    cta,
    image,
    imageAlt,
    imageWidth,
    minInvestment,
    noMargin,
    sideCTA,
    sideImage,
    title
  } = _ref;
  return __jsx(OuterContainer, {
    noMargin: noMargin,
    sideCTA: sideCTA,
    sideImage: sideImage
  }, __jsx(ImageHolder, {
    sideImage: sideImage
  }, image && __jsx(_Box, null, __jsx(Image, {
    alt: imageAlt,
    src: image,
    width: imageWidth
  }))), __jsx(ContentHolder, {
    sideCTA: sideCTA
  }, __jsx(_Box, {
    sx: {
      mb: 3
    }
  }, title && __jsx(Headline, {
    variant: "h5"
  }, title), children), __jsx(InfoWrapper, null, __jsx(Paragraph, {
    variant: "body2"
  }, "Accreditation"), __jsx(Paragraph, {
    semiBold: true,
    variant: "body2"
  }, accreditationRequired ? 'Required' : 'Not Required')), __jsx(InfoWrapper, null, __jsx(Paragraph, {
    variant: "body2"
  }, "Min. Investment"), __jsx(Paragraph, {
    semiBold: true,
    variant: "body2"
  }, minInvestment)), cta && __jsx(CTAHolder, {
    sideCTA: sideCTA,
    sideImage: sideImage
  }, cta)));
};