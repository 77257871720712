import { getAdviceOffers, NON_ACCREDITED_OFFERS } from './offerings';
export const getPage = function () {
  let isAdviceReady = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  const PAGES = {
    nonAccredited: {
      offers: NON_ACCREDITED_OFFERS,
      description: 'For non-accredited investors, we have two exciting offerings available to you to start investing on Yieldstreet',
      callToAction: {
        type: 'button',
        buttonCTA: 'or fund your account first'
      },
      carousel: false
    },
    advice: {
      offers: getAdviceOffers(isAdviceReady),
      description: 'Discover the many ways we provide our new investors with the information they need to make their first investment.',
      carousel: false
    }
  };
  return PAGES;
};
const getOfferType = accreditedSignUp => {
  if (!accreditedSignUp) return 'nonAccredited';
  return 'advice';
};
export const useReadyToInvest = _ref => {
  let {
    accreditedSignUp,
    isAdviceReady
  } = _ref;
  const investorType = getOfferType(accreditedSignUp);
  return getPage(isAdviceReady)[investorType];
};