import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { DATE_FORMAT, DateRanges, LIFE_TIME_START } from './getDatesForRange.model';
export const getDatesForRange = _ref => {
  let {
    range,
    today: todayProp
  } = _ref;
  const today = todayProp ? todayProp : dayjs();
  dayjs.extend(quarterOfYear);
  let startDate, endDate;
  switch (range) {
    case DateRanges.LastMonth:
      startDate = dayjs(today).subtract(1, 'months').startOf('month');
      endDate = dayjs(startDate).add(1, 'months');
      break;
    case DateRanges.LastQuarter:
      startDate = dayjs(today).subtract(1, 'quarter').startOf('quarter');
      endDate = dayjs(startDate).add(1, 'quarters');
      break;
    case DateRanges.LastYear:
      startDate = dayjs(today).subtract(1, 'years').startOf('year');
      endDate = dayjs(startDate).add(1, 'years');
      break;
    case DateRanges.YearToDate:
      startDate = dayjs(today).startOf('year');
      endDate = dayjs(today).add(1, 'days');
      break;
    case DateRanges.LifeTime:
    default:
      startDate = dayjs(LIFE_TIME_START);
      endDate = dayjs(today).add(1, 'days');
      break;
  }
  return [startDate.format(DATE_FORMAT), endDate.format(DATE_FORMAT)];
};