var __jsx = React.createElement;
import React, { useMemo } from 'react';
import map from "lodash/map";
import { Wrapper, InnerWrapper, CardsWrapper } from './WordPressCardsSection.style';
import { testIds } from './WordPressCardsSection.model';
import { WordPressCard } from './WordPressCard';
export const WordPressCardsSection = _ref => {
  let {
    section,
    testId
  } = _ref;
  const {
    content
  } = section;
  const renderWPCards = useMemo(() => map(content, (contentItem, idx) => {
    const {
      articleUrl
    } = contentItem;
    const articleSlug = articleUrl.match(/(?<=\/)([^/]+)\/?$/i) && articleUrl.match(/(?<=\/)([^/]+)\/?$/i)[0];
    if (!articleSlug) {
      return null;
    }
    return __jsx("div", {
      "data-cy": testIds.cardWrapper.id,
      key: `${testIds.cardWrapper.id}-${idx}`
    }, __jsx(WordPressCard, {
      articleSlug: articleSlug,
      articleUrl: articleUrl
    }));
  }), [content]);
  if (!(content !== null && content !== void 0 && content.length)) return null;
  return __jsx(Wrapper, {
    "data-cy": testId
  }, __jsx(InnerWrapper, {
    "data-cy": testIds.cardsWrapper.id
  }, __jsx(CardsWrapper, null, renderWPCards)));
};