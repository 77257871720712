import { DropDownType } from '@yieldstreet/platform-kit';
import { media } from '@yieldstreet/ui-kit';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
export const FieldLayoutGroup = styled.div.withConfig({
  displayName: "DropDownstyle__FieldLayoutGroup",
  componentId: "sc-1arqllk-0"
})(["", ""], props => props.inline ? `
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: center;
    align-items: baseline;
    gap: 4px;
  ` : `
    display: block;
  `);
export const FieldLabel = styled.div.withConfig({
  displayName: "DropDownstyle__FieldLabel",
  componentId: "sc-1arqllk-1"
})(["font-size:14px;"]);
export const FieldMain = styled(motion.div).withConfig({
  displayName: "DropDownstyle__FieldMain",
  componentId: "sc-1arqllk-2"
})(["margin-right:", ";font-style:normal;font-weight:400;font-size:100%;color:", ";", ""], props => props.theme.spacing.s, props => props.theme.colors.text_link, props => props.fullWidth ? `
    max-width: auto;
  ` : `
    max-width: ${props !== null && props !== void 0 && props.maxWidth ? props.maxWidth + 'px' : '240px'};
  `);
export const FieldValue = styled(motion.div).withConfig({
  displayName: "DropDownstyle__FieldValue",
  componentId: "sc-1arqllk-3"
})(["width:100%;font-weight:500;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;"]);
export const FieldBase = css(["color:", ";border-top-right-radius:2px;border-top-left-radius:2px;height:60px;padding:0 ", ";cursor:pointer;white-space:nowrap;transition:all 0.2s;display:flex;flex-direction:row;flex-wrap:nowrap;justify-content:center;align-content:stretch;align-items:center;font-size:16px;line-height:22px;"], props => props.theme.colors.border_strong_alt, props => props.theme.spacing.s);
export const FieldDefault = css(["", ";", " ", " ", ""], FieldBase, props => {
  if (props.withBorder) {
    return `
      border: 1px solid ${props.theme.colors.input_button_basic_inverse_background_active};
border-bottom-color: ${props.active ? props.theme.colors.border_strong_alt : props.theme.colors.border_default};
`;
  }
}, props => {
  if (props.responsive) {
    return media.tablet`
        height: 60px;
        padding: 0 ${props => props.theme.spacing.xs};

        ${FieldMain} {
          font-size: 75%;
          ${props => props.fullWidth ? `
            max-width: auto;
          ` : `
            max-width: 90px;
          `}
        }
       `;
  }
}, props => {
  if (props.responsive) {
    return media.desktop`
          height: 60px;
          padding: 0 ${props => props.theme.spacing.s};

          ${FieldMain} {
            font-size: 100%;
            ${props => props.fullWidth ? `
              max-width: auto;
            ` : `
              max-width: ${props !== null && props !== void 0 && props.maxWidth ? props.maxWidth + 'px' : '240px'};
            `}
          }
        `;
  }
});
export const FieldPill = css(["", ";border-radius:8px;padding:", ";background:", ";height:auto;"], FieldBase, props => `6px ${props.theme.spacing.s}`, props => props.theme.colors.input_button_basic_inverse_background_active);
export const Field = styled.div.withConfig({
  displayName: "DropDownstyle__Field",
  componentId: "sc-1arqllk-4"
})(["", ";"], props => props.type === DropDownType.PILL ? FieldPill : FieldDefault);
export const FieldIcon = styled(motion.img).withConfig({
  displayName: "DropDownstyle__FieldIcon",
  componentId: "sc-1arqllk-5"
})(["pointer-events:none;"]);
export const fieldValueAnimation = inline => ({
  exit: 'empty',
  initial: 'empty',
  animate: 'filled',
  variants: {
    filled: {
      opacity: 1,
      transform: `translateY(0px)`
    },
    empty: {
      opacity: 0,
      transform: `translateY(${inline ? 0 : 12}px)`
    }
  }
});
export const fieldIconAnimation = active => ({
  animate: {
    transform: active ? 'rotate(180deg)' : 'rotate(0deg)'
  }
});