import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React, { useMemo, useRef, useState } from 'react';
import isNumber from "lodash/isNumber";
import sum from "lodash/sum";
import last from "lodash/last";
import { rgba } from 'polished';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { portfolioCalculator, totalAnnualizedReturn } from '@yieldstreet/tool-kit';
import { Currency } from '../../../multi-item-banner/sub-components/common/currency/Currency';
import { DesktopOnly, MobileOnly } from '../../../../utils';
import { SwitchSelector } from '../SwitchSelector';
import { ButtonContainer, DetailsContainer, EarningsContainer, Label, MobileTargetContainer, OuterContainer, Paragraph, TargetContainer, TotalInvestment } from './Chart.style';
import { ChartColors, ChartDisplay, ColumnColors, TabsDesktop, TabsMobile, TabsMobileDesc } from './Chart.model';
export const Chart = _ref => {
  let {
    amounts,
    investments,
    showTotalInvestment
  } = _ref;
  const chartComponentRef = useRef(null);
  const {
    0: chartDisplay,
    1: setChartDisplay
  } = useState(ChartDisplay.BOTH);
  const calculatedAmounts = useMemo(() => investments.map((investment, index) => amounts && isNumber(amounts[index]) ? amounts[index] : investment.defaultAmount), [investments, amounts]);
  const {
    investmentsData,
    globalEarnings
  } = useMemo(() => portfolioCalculator(investments, calculatedAmounts), [investments, calculatedAmounts]);
  const totals = useMemo(() => {
    return {
      earnings: last(globalEarnings) || 0,
      investment: sum(calculatedAmounts),
      targetAnnualized: totalAnnualizedReturn(investments, calculatedAmounts)
    };
  }, [calculatedAmounts, globalEarnings, investments]);
  const options = useMemo(() => {
    let series = [];
    if (chartDisplay === ChartDisplay.PAYMENTS || chartDisplay === ChartDisplay.BOTH) {
      series = investmentsData.map((investment, index) => {
        return {
          type: 'column',
          name: investment.name,
          data: investment.payments,
          color: ColumnColors[index]
        };
      });
    }
    const categoriesSize = Math.max(globalEarnings.length - 2, 0);
    const categories = ['Today', ...Array(categoriesSize).fill('')];
    investments.forEach(investment => {
      categories[investment.term - 1] = `${investment.term} ${investment.termSuffix}`;
    });
    if (chartDisplay === ChartDisplay.EARNINGS || chartDisplay === ChartDisplay.BOTH) {
      series = [...series, {
        lineColor: ChartColors[0],
        fillOpacity: 0.5,
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1
          },
          stops: [[0, rgba(ChartColors[0], 0.7)], [1, rgba(ChartColors[1], 0)]]
        },
        threshold: null,
        name: 'Earnings',
        type: 'area',
        data: globalEarnings
      }];
    }
    return {
      credits: {
        enabled: false
      },
      chart: {
        height: 208,
        marginRight: 15
      },
      legend: {
        enabled: false
      },
      title: {
        text: ''
      },
      tooltip: {
        valueDecimals: 0,
        valuePrefix: '$'
      },
      yAxis: {
        labels: {
          // eslint-disable-next-line no-template-curly-in-string
          format: '${value}'
        },
        gridLineDashStyle: 'dash',
        min: 0,
        title: {
          text: ''
        },
        stackLabels: {
          enabled: false
        }
      },
      xAxis: {
        labels: {
          autoRotation: [0],
          allowOverlap: false,
          overflow: 'allow',
          padding: 10,
          align: 'center',
          y: 25
        },
        categories: categories
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false
          }
        },
        area: {
          marker: {
            enabled: false
          }
        }
      },
      series
    };
  }, [chartDisplay, globalEarnings, investments, investmentsData]);
  return __jsx(React.Fragment, null, __jsx(OuterContainer, null, __jsx(DetailsContainer, null, __jsx(EarningsContainer, null, __jsx(Label, {
    variant: "body2"
  }, "Total earnings"), __jsx(_Typography, {
    variant: "h4"
  }, __jsx(Currency, {
    doNotRound: true,
    forcePlaces: 2,
    value: totals.earnings
  })), investments.length > 1 ? __jsx(MobileOnly, null, __jsx(MobileTargetContainer, null, __jsx(Label, {
    variant: "body2"
  }, "Target annualized return ", `  `), __jsx(Label, {
    semiBold: true,
    sx: {
      paddingLeft: 1
    },
    variant: "body2"
  }, totals.targetAnnualized, "%"))) : ''), investments.length > 1 ? __jsx(React.Fragment, null, __jsx(TargetContainer, null, __jsx(DesktopOnly, null, __jsx(Label, {
    variant: "body2"
  }, "Total investment"), __jsx(_Typography, {
    variant: "h5"
  }, __jsx(Currency, {
    doNotRound: true,
    forcePlaces: 4,
    value: totals.investment
  })))), __jsx(TargetContainer, null, __jsx(DesktopOnly, null, __jsx(Label, {
    variant: "body2"
  }, "Target annualized return"), __jsx(_Typography, {
    variant: "h5"
  }, totals.targetAnnualized, "%")))) : '', __jsx(ButtonContainer, null, __jsx(MobileOnly, null, __jsx(SwitchSelector, {
    tabs: TabsMobile,
    onClick: tab => setChartDisplay(+tab.key),
    activeKey: chartDisplay
  }), __jsx(Label, {
    variant: "body2"
  }, TabsMobileDesc[chartDisplay])), __jsx(DesktopOnly, null, __jsx(SwitchSelector, {
    tabs: TabsDesktop,
    onClick: tab => setChartDisplay(+tab.key),
    activeKey: chartDisplay
  })))), __jsx(HighchartsReact, {
    highcharts: Highcharts,
    options: options,
    ref: chartComponentRef
  })), showTotalInvestment && __jsx(TotalInvestment, null, __jsx(Paragraph, {
    semiBold: true,
    variant: "body1"
  }, "Total investment"), __jsx(Paragraph, {
    semiBold: true,
    variant: "body1"
  }, __jsx(Currency, {
    doNotRound: true,
    forcePlaces: 4,
    value: totals.investment
  }))));
};