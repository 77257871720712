var __jsx = React.createElement;
import React from 'react';
import { SurfaceType } from '@yieldstreet/ui-kit';
import { PortfolioCardSurface } from './PortfolioCard.style';
export const PortfolioCard = _ref => {
  let {
    children,
    testId
  } = _ref;
  return __jsx(PortfolioCardSurface, {
    type: SurfaceType.Outline,
    testId: testId,
    withPadding: false
  }, children);
};