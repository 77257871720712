import { useMemo } from 'react';
import { useUserState } from 'utils/hooks';
import { ThirdPartyProviderType } from 'utils/auth/useAuthWelcome.model';
import { SDIRAProviders } from './useSDIRAUser.model';
export const useSDIRAUser = () => {
  const {
    userMeta,
    tags,
    issuer
  } = useUserState();
  const {
    connected
  } = userMeta || {};
  return useMemo(() => {
    if (connected && [...SDIRAProviders, ThirdPartyProviderType.CADRE].includes(connected)) {
      return {
        provider: connected,
        tags,
        issuer
      };
    }
    return {};
  }, [connected, tags, issuer]);
};