import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
// import { Tracer, traceAxios } from '@yieldstreet/tool-kit';
import axios from 'axios';
import merge from "lodash/merge";
import { setGlobalConfig, requestLogger, responseLogger, errorLogger } from 'axios-logger';
import { SET_LOCATION_REDIRECT } from 'store/actions/location';
import { setAnalyticsAnonymousId } from 'store/actions/user';
import { isLocal, isServer, isTest } from 'utils/env';
import ENV_CONFIG from 'utils/env/config';
import { setSegmentAnonymousId } from 'utils/thirdPartyCode';
import { resetState } from 'store/configureStore';

/* global _castle */

export default (function (store) {
  let axiosInstance = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : axios;
  let additionalHeaders = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  // const apiTracer = new Tracer('api');
  // traceAxios(axios, apiTracer);

  // clear any pre-existing interceptors
  axiosInstance.interceptors.request.handlers.length = 0;
  axiosInstance.interceptors.response.handlers.length = 0;
  if (isServer) {
    setGlobalConfig({
      dateFormat: 'UTC:dd/mmm/yyyy:HH:MM:ss.l o',
      params: true,
      data: false
    });
    axiosInstance.interceptors.request.use(requestLogger, errorLogger);
    axiosInstance.interceptors.response.use(responseLogger, errorLogger);
  }
  if (!isServer && !isTest) {
    axiosInstance.interceptors.request.use(async options => {
      if (!options.url.match(/zendesk/gi)) {
        const requestToken = await _castle('createRequestToken');
        options.headers['X-Castle-Client-Id'] = requestToken;
        options.headers['X-Castle-Request-Token'] = requestToken;
      }
      return options;
    });
  }
  axiosInstance.interceptors.request.use(options => {
    if (store) {
      const state = store.getState();
      let requestHost = ``;
      let requestHeaders = _objectSpread(_objectSpread({}, options.headers || {}), additionalHeaders);
      if (isServer) {
        const {
          cookie
        } = state.user.userHeaders;
        const host = isLocal ? `https://${ENV_CONFIG.HOST}` :
        // if deployed within the cluster change the host to internal url
        `http://istio-ingressgateway.istio-system`;
        requestHost = !options.url.startsWith(host) ? host : '';
        requestHeaders = _objectSpread({
          cookie: cookie || {},
          origin: requestHost,
          'user-agent': 'chimichurri-node'
        }, requestHeaders);
      }
      return merge({}, options, {
        method: options.method || 'GET',
        url: `${requestHost}${options.url}`,
        headers: requestHeaders
      });
    }
    return options;
  });
  axiosInstance.interceptors.response.use(response => {
    const anonymousId = response.headers['x-anonymous-id'];
    if (anonymousId) {
      if (store) {
        store.dispatch(setAnalyticsAnonymousId(anonymousId));
      }
      if (!isServer) {
        setSegmentAnonymousId(anonymousId);
      }
    }
    return response;
  }, error => {
    if (store) {
      var _error$response;
      const state = store.getState();
      const status = error === null || error === void 0 ? void 0 : (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.status;
      const {
        currentPathname,
        currentSearch
      } = state.location;
      if (status === 401 && !['/login', '/login-redirect', '/impersonate'].find(path => currentPathname.startsWith(path)) && currentSearch.indexOf('redirect') === -1) {
        const redirectUrl = `/login-redirect${currentPathname ? `?redirect=${encodeURIComponent(currentPathname)}${encodeURIComponent(currentSearch)}` : ''}`;
        store.dispatch(resetState());
        store.dispatch({
          type: SET_LOCATION_REDIRECT,
          redirectUrl
        });
      }
    }
    throw error;
  });
});