var __jsx = React.createElement;
import React from 'react';
import { Markdown } from '@yieldstreet/ui-kit';
import { Wrapper, InnerWrapper, Title, Note, BulletNote, TextWrapper, Image } from './NoteBannerSection.style';
import star from '../../shared/images/star.svg';
const NoteBannerSection = _ref => {
  let {
    section
  } = _ref;
  const {
    title,
    note,
    content,
    featuredBanner
  } = section;
  return __jsx(Wrapper, null, __jsx(InnerWrapper, null, featuredBanner && __jsx(Image, {
    src: star
  }), __jsx(TextWrapper, null, __jsx(Title, {
    "data-cy": "banner-title",
    semiBold: true
  }, title), __jsx(Note, {
    "data-cy": "banner-note"
  }, __jsx(Markdown, {
    source: note
  })), content.map((bulletText, idx) => {
    return __jsx(BulletNote, {
      "data-cy": "banner-bulletnote",
      key: `banner-note-bullet-text-${idx}`
    }, __jsx(Markdown, {
      source: `${bulletText}`
    }));
  }))));
};
export default NoteBannerSection;