import _Chip from "@mui/material/Chip";
var __jsx = React.createElement;
import React from 'react';
import Grid from '@mui/material/Grid2';
import { BankAccountProvisionState } from '@yieldstreet/platform-kit';
export const BankAccountItemSlot = acc => {
  return __jsx(Grid, {
    container: true,
    alignItems: "center",
    size: "grow",
    spacing: {
      sm: 4
    }
  }, (acc === null || acc === void 0 ? void 0 : acc.provisionState) === BankAccountProvisionState.PENDING && `Micro-deposit confirmation needed to complete set-up for account`, acc === null || acc === void 0 ? void 0 : acc.maskedAccountNumber.replaceAll('X', '*'), (acc === null || acc === void 0 ? void 0 : acc.defaultAccount) && __jsx(_Chip, {
    label: "Preferred",
    size: "small",
    color: "secondary"
  }));
};