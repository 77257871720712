import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { PageContainer, Heading, Paragraph, Button, Link, Label } from '@yieldstreet/ui-kit';
import { testIds } from './ETCIntro.model';
import iconItemOne from './assets/item1.svg';
import iconItemTwo from './assets/item2.svg';
import iconItemThree from './assets/item3.svg';
import etclogo from './assets/etclogo.svg';
import { BackNavigation, Arrow, Hero, HeroContentWrapper, StyledParagraph, AboutIra, AboutIraItem, ItemIcon, PoweredByEtcWrapper, LogoSection, EtcLogo, IraPromoWrapper, IraPromoContent, PromoParagraph, Disclaimer } from './ETCIntro.style';
import { useFlags } from '@yieldstreet/tool-kit';
export const ETCIntro = () => {
  const history = useHistory();
  const {
    retirementEntityCreationEtcDocusign: newIraCreationFlow
  } = useFlags({
    retirementEntityCreationEtcDocusign: false
  });
  if (!newIraCreationFlow) {
    return __jsx(Redirect, {
      to: "/investor/manage"
    });
  }
  return __jsx(PageContainer, null, __jsx(BackNavigation, _extends({
    onClick: () => history.push('/create-investor-account'),
    role: "button"
  }, testIds.backButton.attr), __jsx(Arrow, {
    icon: faChevronLeft
  })), __jsx(Hero, null, __jsx(HeroContentWrapper, null, __jsx(Heading, {
    type: 1,
    inverse: true
  }, "Add private markets to your retirement portfolio"), __jsx(StyledParagraph, {
    inverse: true
  }, "Yieldstreet and our partner custodian Equity Trust make it simple to diversify your retirement portfolio with private markets investments.", __jsx("sup", null, "10")), __jsx(Button, _extends({
    buttonType: "primary",
    onClick: () => history.push('/create-investor-account?iraAccount=true')
  }, testIds.continueButton.attr), "Continue"))), __jsx(AboutIra, null, __jsx(AboutIraItem, null, __jsx(ItemIcon, {
    src: iconItemOne,
    "aria-hidden": true
  }), __jsx(Heading, {
    type: 5,
    inverse: true
  }, "Broad range of alternatives\xA0"), __jsx(Paragraph, {
    inverse: true
  }, "Diversify your IRA with real estate, private credit, private equity, Short Term Notes, and more.")), __jsx(AboutIraItem, null, __jsx(ItemIcon, {
    src: iconItemTwo,
    "aria-hidden": true
  }), __jsx(Heading, {
    type: 5,
    inverse: true
  }, "Support for all major accounts"), __jsx(Paragraph, {
    inverse: true
  }, "We support 401(k) rollovers, as well as transfers from traditional, Roth, SEP, or SIMPLE IRAs.\xA0")), __jsx(AboutIraItem, null, __jsx(ItemIcon, {
    src: iconItemThree,
    "aria-hidden": true
  }), __jsx(Heading, {
    type: 5,
    inverse: true
  }, "White-glove onboarding"), __jsx(Paragraph, {
    inverse: true
  }, "Your dedicated representative will handle everything needed to set up and fund your account.\xA0"))), __jsx(PoweredByEtcWrapper, null, __jsx(LogoSection, null, __jsx(Heading, {
    type: 3
  }, "Powered by"), __jsx(EtcLogo, {
    src: etclogo,
    alt: "Equity Trust logo"
  })), __jsx(Paragraph, null, "Equity Trust is an industry-leading custodian for self-directed IRAs, managing more than $52B in assets across 368K+ accounts. With 50 years of experience, Equity Trust ranks among the top self-directed IRA custodians in the U.S.")), __jsx(IraPromoWrapper, null, __jsx(IraPromoContent, null, __jsx(Heading, {
    type: 4
  }, "Simple balance-based account fee"), __jsx(PromoParagraph, null, "Yieldstreet members enjoy reduced fees on their Equity Trust IRA account."), __jsx("div", null, __jsx(Link, _extends({
    href: "https://www.yieldstreet.com/ira/#ira-fee-modal",
    target: "_blank"
  }, testIds.promoDetailsLink.attr), "View details")))), __jsx(Disclaimer, null, __jsx(Label, null, "10. Diversification does not ensure a profit or protect against a loss in a declining market.")));
};