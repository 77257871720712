import _Box from "@mui/material/Box";
import _Avatar from "@mui/material/Avatar";
import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["title", "subtitle", "icon", "inverse", "compact"];
var __jsx = React.createElement;
import React, { useMemo } from 'react';
import { Wrapper, Copy } from './OfferingTitle.style';
import { useTestIds } from './OfferingTitle.model';
import marketplaceOfferingDefault from './assets/marketplaceOfferingDefault.png';
export const OfferingTitle = _ref => {
  let {
      title: titleProp,
      subtitle: subtitleProp,
      icon,
      inverse,
      compact
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  const testIds = useTestIds(props);
  const thumbnail = (icon === null || icon === void 0 ? void 0 : icon.src) || marketplaceOfferingDefault;
  const subtitle = useMemo(() => {
    if (!subtitleProp) {
      return null;
    }
    if (typeof subtitleProp === 'string') {
      return __jsx(_Typography, _extends({
        variant: "caption",
        color: inverse ? 'textQuinary' : 'textSecondary'
      }, testIds.contentSubtitle.attr), subtitleProp);
    }
    return subtitleProp;
  }, [inverse, testIds, subtitleProp]);
  const title = useMemo(() => {
    if (!titleProp) {
      return null;
    }
    if (typeof titleProp === 'string') {
      return __jsx(_Typography, _extends({
        variant: compact ? 'h5' : 'h4',
        color: inverse ? 'textSenary' : 'primary'
      }, testIds.contentTitle.attr), titleProp);
    }
    return titleProp;
  }, [compact, inverse, testIds, titleProp]);
  return __jsx(Wrapper, testIds.base.attr, __jsx(_Box, null, __jsx(_Avatar, _extends({
    src: thumbnail,
    variant: "rounded"
  }, testIds.contentIcon.attr))), subtitle || title ? __jsx(Copy, null, title, subtitle) : null);
};