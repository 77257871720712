var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { media } from '../../../themes';
import { Heading, Paragraph } from '../../../content/text';
export const ImageWithTitle = _ref => {
  let {
    image,
    imageAlt,
    imageHeight,
    imageWidth,
    inverse,
    peakItemOnMobile,
    textWrap,
    title,
    heading,
    tagType = 5,
    headingType = 5
  } = _ref;
  return __jsx(OuterContainer, {
    peakItemOnMobile: peakItemOnMobile,
    image: image,
    title: title
  }, __jsx(ImageHolder, null, image && __jsx(ImageWrapper, null, __jsx(Image, {
    width: imageWidth,
    height: imageHeight,
    alt: imageAlt,
    src: image
  }))), heading && __jsx(SHeading, {
    type: headingType,
    tagType: tagType,
    inverse: inverse
  }, heading), title && __jsx(Headline, {
    inverse: inverse,
    semiBold: true,
    small: true,
    textWrap: textWrap
  }, title));
};
const OuterContainer = styled.div.withConfig({
  displayName: "ImageWithTitle__OuterContainer",
  componentId: "sc-1po3q92-0"
})(["text-align:left;flex:0 0 calc(100% / ", ");padding:0 20px 0 0;", " ", ""], props => props.peakItemOnMobile ? 3.2 : 3, media.tablet`
    flex: 1 1 25%;
    text-align: center;

  `, media.desktop`
    padding: 0 20px;
    flex: none;
    flex: 1 0;
  `);
const Headline = styled(Paragraph).withConfig({
  displayName: "ImageWithTitle__Headline",
  componentId: "sc-1po3q92-1"
})(["padding:0;margin:10px 0;white-space:", ";"], props => props.textWrap ? 'normal' : 'nowrap');
const SHeading = styled(Heading).withConfig({
  displayName: "ImageWithTitle__SHeading",
  componentId: "sc-1po3q92-2"
})(["white-space:", ";"], props => props.textWrap ? 'normal' : 'nowrap');
const ImageHolder = styled.div.withConfig({
  displayName: "ImageWithTitle__ImageHolder",
  componentId: "sc-1po3q92-3"
})(["position:relative;height:60px;margin:20px 0;", ""], media.desktop`
    margin-top: 0;
  `);
const ImageWrapper = styled.div.withConfig({
  displayName: "ImageWithTitle__ImageWrapper",
  componentId: "sc-1po3q92-4"
})(["position:absolute;width:100%;bottom:0;"]);
const Image = styled.img.withConfig({
  displayName: "ImageWithTitle__Image",
  componentId: "sc-1po3q92-5"
})(["max-height:60px;"]);