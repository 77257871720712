import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { ManagedPortfolioAssetClasses, ManagedPortfolioRisks, ManagedPortfolioStrategies, StrategySuggestionStatus } from '../../types';
import { AssetTypes } from '../../enums';
export const adviceSuggestionsMock = {
  suggestedStrategies: [ManagedPortfolioStrategies.INCOME],
  strategySuggestionStatus: StrategySuggestionStatus.COMPLETED,
  availableStrategies: [{
    strategyId: '',
    title: 'title',
    description: 'description',
    investmentStrategyType: ManagedPortfolioStrategies.INCOME,
    estimatedIncomeReturns: {
      from: 9,
      to: 10,
      level: 3
    },
    estimatedAppreciations: {
      from: 3,
      to: 4,
      level: 1
    },
    estimatedTotalReturns: {
      from: 9,
      to: 12,
      level: 2
    },
    risk: ManagedPortfolioRisks.LOW,
    reservePercentage: 0.02,
    minimumDepositAmount: 50000,
    assetTypeAllocations: [{
      allocationPercentage: 0.32,
      assetType: AssetTypes.Credit
    }, {
      allocationPercentage: 0.66,
      assetType: AssetTypes.Equity
    }, {
      allocationPercentage: 0.02,
      assetType: AssetTypes.Cash
    }],
    assetClassAllocations: [{
      allocationPercentage: 60,
      assetClass: ManagedPortfolioAssetClasses.PRIVATE_EQUITY
    }, {
      allocationPercentage: 15,
      assetClass: ManagedPortfolioAssetClasses.PRIVATE_CREDIT
    }, {
      allocationPercentage: 25,
      assetClass: ManagedPortfolioAssetClasses.REAL_ESTATE
    }],
    eligibleOfferings: [{
      priority: 200,
      noteUrlHash: 'hash'
    }],
    liquidityRisk: ManagedPortfolioRisks.MEDIUM
  }, {
    strategyId: '',
    title: 'title',
    description: 'description',
    investmentStrategyType: ManagedPortfolioStrategies.GROWTH,
    estimatedIncomeReturns: {
      from: 9,
      to: 11,
      level: 3
    },
    estimatedAppreciations: {
      from: 5,
      to: 7,
      level: 3
    },
    estimatedTotalReturns: {
      from: 11,
      to: 13,
      level: 3
    },
    reservePercentage: 0.02,
    risk: ManagedPortfolioRisks.HIGH,
    minimumDepositAmount: 50000,
    assetTypeAllocations: [{
      allocationPercentage: 0.22,
      assetType: AssetTypes.Credit
    }, {
      allocationPercentage: 0.16,
      assetType: AssetTypes.Equity
    }, {
      allocationPercentage: 0.32,
      assetType: AssetTypes.Cash
    }],
    assetClassAllocations: [{
      allocationPercentage: 35,
      assetClass: ManagedPortfolioAssetClasses.PRIVATE_EQUITY
    }, {
      allocationPercentage: 50,
      assetClass: ManagedPortfolioAssetClasses.PRIVATE_CREDIT
    }, {
      allocationPercentage: 15,
      assetClass: ManagedPortfolioAssetClasses.REAL_ESTATE
    }],
    eligibleOfferings: [{
      priority: 200,
      noteUrlHash: 'hash'
    }],
    liquidityRisk: ManagedPortfolioRisks.MEDIUM
  }, {
    strategyId: '',
    title: 'title',
    description: 'description',
    investmentStrategyType: ManagedPortfolioStrategies.BALANCED,
    estimatedIncomeReturns: {
      from: 9,
      to: 10,
      level: 2
    },
    estimatedAppreciations: {
      from: 1,
      to: 2,
      level: 1
    },
    estimatedTotalReturns: {
      from: 10,
      to: 12,
      level: 2
    },
    reservePercentage: 0.02,
    risk: ManagedPortfolioRisks.MEDIUM,
    minimumDepositAmount: 50000,
    assetTypeAllocations: [{
      allocationPercentage: 0.12,
      assetType: AssetTypes.Credit
    }, {
      allocationPercentage: 0.46,
      assetType: AssetTypes.Equity
    }, {
      allocationPercentage: 0.34,
      assetType: AssetTypes.Cash
    }],
    assetClassAllocations: [{
      allocationPercentage: 50,
      assetClass: ManagedPortfolioAssetClasses.PRIVATE_EQUITY
    }, {
      allocationPercentage: 20,
      assetClass: ManagedPortfolioAssetClasses.PRIVATE_CREDIT
    }, {
      allocationPercentage: 30,
      assetClass: ManagedPortfolioAssetClasses.REAL_ESTATE
    }],
    eligibleOfferings: [{
      priority: 200,
      noteUrlHash: 'hash'
    }],
    liquidityRisk: ManagedPortfolioRisks.MEDIUM
  }]
};
export const adviceSuggestionsMockNoSuggestion = _objectSpread(_objectSpread({}, adviceSuggestionsMock), {}, {
  suggestedStrategies: [],
  strategySuggestionStatus: StrategySuggestionStatus.COMPLETED
});
export const mockAdviceManagedPortfolio = {
  success: true,
  response: {
    portfolioId: 'ea8722f2-22bc-4b06-ade7-b9cc4b8acfbc',
    managedPortfolioStrategy: {
      strategyId: '51629950-a978-11ee-97e1-c17978ce9d6b',
      title: 'Balanced',
      description: 'This strategy balances income generation and capital appreciation.',
      assetClassAllocations: [{
        allocationPercentage: 0.343,
        assetClass: 'PRIVATE_CREDIT'
      }, {
        allocationPercentage: 0.441,
        assetClass: 'PRIVATE_EQUITY'
      }, {
        allocationPercentage: 0.196,
        assetClass: 'REAL_ASSETS'
      }],
      eligibleOfferings: [{
        noteUrlHash: 'pveqf01',
        assetClass: 'PRIVATE_EQUITY',
        priority: 100
      }, {
        noteUrlHash: 'pvcf01',
        assetClass: 'PRIVATE_CREDIT',
        priority: 100
      }, {
        noteUrlHash: 'reasf01',
        assetClass: 'REAL_ASSETS',
        priority: 100
      }],
      investmentStrategyType: 'BALANCED',
      estimatedIncomeReturn: '5',
      estimatedAppreciation: '5-6',
      estimatedTotalReturn: '10-11',
      liquidityRisk: 'MEDIUM',
      risk: 'MEDIUM',
      reservePercentage: 0.02,
      minimumDepositAmount: 50000
    },
    walletBalance: {
      balance: 0,
      reserveBalance: 1e3
    },
    aggregatePosition: {
      positionByAssetClass: {},
      totalValue: 0
    },
    positions: [],
    allocationOutcomes: [{
      amount: '23000.00',
      assetClass: 'PRIVATE_EQUITY',
      noteUrlHash: 'pveqf01'
    }, {
      amount: '15000.00',
      assetClass: 'PRIVATE_CREDIT',
      noteUrlHash: 'pvcf01'
    }, {
      amount: '11000.00',
      assetClass: 'REAL_ASSETS',
      noteUrlHash: 'reasf01'
    }],
    reserveTransfers: [{
      transferId: 'e874513d-fb20-4d77-a950-44c9e5176f42',
      amount: '1000.00'
    }],
    lastUpdate: 1704372204436
  }
};