var __jsx = React.createElement;
import React from 'react';
import { Route, Switch, useLocation } from 'react-router';
import { KYCContainer, ManagementContainer } from './screens';
import { INVESTOR_ENTITY_KYB_PATH, INVESTOR_ENTITY_KYC_PATH, INVESTOR_ENTITY_KYC_BASE_PATH, INVESTOR_ENTITY_MANAGEMENT_PATH } from './InvestorEntityContainer.model';
export const InvestorEntityContainer = () => {
  const location = useLocation();
  return __jsx(Switch, {
    location: location
  }, __jsx(Route, {
    strict: true,
    path: [INVESTOR_ENTITY_KYC_PATH, INVESTOR_ENTITY_KYB_PATH],
    component: KYCContainer
  }), __jsx(Route, {
    strict: true,
    path: INVESTOR_ENTITY_KYC_BASE_PATH,
    component: KYCContainer
  }), __jsx(Route, {
    strict: true,
    path: INVESTOR_ENTITY_MANAGEMENT_PATH,
    component: ManagementContainer
  }));
};