import { media, Modal, Button, Heading, ButtonContainer as ButtonWrapper } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
import SubmitCancelButton from 'sharedComponents/SubmitCancelButton';
export const ProfileModal = styled(Modal).withConfig({
  displayName: "shared__ProfileModal",
  componentId: "sc-x8ei5z-0"
})(["visibility:", ";", ";> div{opacity:", ";transform:translateY(", ");", ";height:auto;max-width:916px;", ";", ";}"], props => props !== null && props !== void 0 && props.visible ? 'visible' : 'hidden', props => !(props !== null && props !== void 0 && props.visible) && `z-index: -1;`, props => props !== null && props !== void 0 && props.visible ? 1 : 0, props => props !== null && props !== void 0 && props.visible ? 0 : '200px', props => (props === null || props === void 0 ? void 0 : props.visible) && `transition: ease-in-out 0.5s;`, media.tablet`
      width: 680px;
    `, media.desktop`
      width: 100%;
    `);
export const SModal = styled(Modal).withConfig({
  displayName: "shared__SModal",
  componentId: "sc-x8ei5z-1"
})(["> div{height:auto;max-width:916px;", ";", ";}"], media.tablet`
      width: 680px;
    `, media.desktop`
      width: 100%;
    `);
export const ModalWrapper = styled.div.withConfig({
  displayName: "shared__ModalWrapper",
  componentId: "sc-x8ei5z-2"
})(["padding:0 10px;", ";", ";"], media.phoneLandscape`
    max-width: unset;
    padding: 0 30px 0 30px;
  `, media.desktop`
    width: 100%;
  `);
export const NewModalWrapper = styled.div.withConfig({
  displayName: "shared__NewModalWrapper",
  componentId: "sc-x8ei5z-3"
})(["padding:", ";background:", ";min-height:100vh;", " ", " ", ""], props => props.theme.spacing.m, props => props.theme.colors.background_page, media.mobile`
    min-height: auto;
  `, media.phoneLandscape`
    width: 500px !important;
    padding: ${props => props.theme.spacing.xl};
  `, media.desktop`
    width: 650px !important;
    padding: ${props => props.theme.spacing['2xl']};
  `);
export const FormWrapper = styled.div.withConfig({
  displayName: "shared__FormWrapper",
  componentId: "sc-x8ei5z-4"
})(["display:flex;flex-direction:column;"]);
export const SButtonContainer = styled(ButtonWrapper).withConfig({
  displayName: "shared__SButtonContainer",
  componentId: "sc-x8ei5z-5"
})(["margin-top:30px;margin-bottom:0;"]);
export const ModalHeader = styled(Heading).withConfig({
  displayName: "shared__ModalHeader",
  componentId: "sc-x8ei5z-6"
})(["margin-bottom:", ";"], props => props.theme.spacing.l);
export const ModalTitle = styled.p.withConfig({
  displayName: "shared__ModalTitle",
  componentId: "sc-x8ei5z-7"
})(["padding-bottom:6px;"]);
export const ModalContent = styled.div.withConfig({
  displayName: "shared__ModalContent",
  componentId: "sc-x8ei5z-8"
})([""]);
export const LoaderWrapper = styled.div.withConfig({
  displayName: "shared__LoaderWrapper",
  componentId: "sc-x8ei5z-9"
})(["display:inline-block;height:100px;width:100%;"]);
export const Desc = styled.p.withConfig({
  displayName: "shared__Desc",
  componentId: "sc-x8ei5z-10"
})(["", ";"], props => props.padding && `padding: ${props.padding}`);
export const DescSpacing = styled(Desc).withConfig({
  displayName: "shared__DescSpacing",
  componentId: "sc-x8ei5z-11"
})(["margin-top:10px;"]);
export const ButtonContainer = styled.div.withConfig({
  displayName: "shared__ButtonContainer",
  componentId: "sc-x8ei5z-12"
})(["display:flex;flex-direction:column-reverse;", ";"], media.tablet`
    flex-direction: row;
  `);
export const CancelBtn = styled(SubmitCancelButton).withConfig({
  displayName: "shared__CancelBtn",
  componentId: "sc-x8ei5z-13"
})(["height:46px;min-width:unset;max-width:100%;width:100%;", ";"], media.tablet`
    margin-right: 15px;
    width: 160px;
  `);
export const NextBtn = styled(SubmitCancelButton).withConfig({
  displayName: "shared__NextBtn",
  componentId: "sc-x8ei5z-14"
})(["max-width:100%;width:100%;", " ", ";"], props => props.padding && `padding: ${props.padding};`, media.tablet`
    width: 200px;
  `);
export const ModalError = styled.div.withConfig({
  displayName: "shared__ModalError",
  componentId: "sc-x8ei5z-15"
})(["margin:-15px 0 15px;padding:15px 0 15px 35px;max-width:385px;color:", ";border:solid 1px ", ";position:relative;"], props => props.theme.colors.notice_error, props => props.theme.colors.notice_error);
export const SButton = styled(Button).withConfig({
  displayName: "shared__SButton",
  componentId: "sc-x8ei5z-16"
})(["width:100%;margin-bottom:15px;", ""], media.tablet`
    ${props => `
      ${props.left ? 'margin-left: 15px' : 'margin-right: 15px'};
      ${props.fullWidth ? 'width: 100%' : 'width: unset'};
    `}
    margin-bottom: 0;
  `);
export const CancelButton = styled(Button).withConfig({
  displayName: "shared__CancelButton",
  componentId: "sc-x8ei5z-17"
})([""]);
export const Resend = styled.span.withConfig({
  displayName: "shared__Resend",
  componentId: "sc-x8ei5z-18"
})(["cursor:pointer;margin-left:5px;"]);
export const FlexContainer = styled.div.withConfig({
  displayName: "shared__FlexContainer",
  componentId: "sc-x8ei5z-19"
})(["display:flex;flex-direction:row;"]);