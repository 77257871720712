import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { Cadence, PaymentMethodType } from '@yieldstreet/platform-kit';
import { cadenceCopies, alternativeCadenceCopies } from './confirmationProcess.model';
export const useOfferingCadenceCopy = function (cadence) {
  var _copy$PaymentMethodTy, _copy$paymentMethod;
  let paymentMethod = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : PaymentMethodType.SYNP;
  let shouldShowAlternativeCopy = arguments.length > 2 ? arguments[2] : undefined;
  const copy = shouldShowAlternativeCopy ? alternativeCadenceCopies : cadenceCopies;
  const defaultCopy = copy === null || copy === void 0 ? void 0 : (_copy$PaymentMethodTy = copy[PaymentMethodType === null || PaymentMethodType === void 0 ? void 0 : PaymentMethodType.SYNP]) === null || _copy$PaymentMethodTy === void 0 ? void 0 : _copy$PaymentMethodTy[Cadence.DAILY];
  const cadenceCopy = (copy === null || copy === void 0 ? void 0 : (_copy$paymentMethod = copy[paymentMethod]) === null || _copy$paymentMethod === void 0 ? void 0 : _copy$paymentMethod[cadence]) || defaultCopy;
  return _objectSpread(_objectSpread({}, cadenceCopy), {}, {
    rollover: 'We have received your investment request and will start processing soon.'
  });
};