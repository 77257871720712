var __jsx = React.createElement;
import React from 'react';
import { Markdown } from '@yieldstreet/ui-kit';
import iconThumbsDown from 'assets/icons/images/thumbs_down.svg';
import iconThumbsUp from 'assets/icons/images/thumbs_up.svg';
import { Wrapper, InnerWrapper, Title, SubTitle, ThumbsText, TextWrapper, ThumbsWrapper, ThumbsButton } from './SurveySection.style';
import { testIds } from './SurveySection.model';
const SurveySection = _ref => {
  let {
    section,
    userState,
    urlHash
  } = _ref;
  const {
    title,
    description,
    questionLabel,
    surveyId
  } = section;
  return surveyId && __jsx(Wrapper, null, __jsx(InnerWrapper, null, __jsx(TextWrapper, null, __jsx(Title, {
    inverse: true
  }, title), __jsx(SubTitle, {
    small: true
  }, __jsx(Markdown, {
    source: description
  }))), __jsx(ThumbsWrapper, null, __jsx(ThumbsText, {
    small: true
  }, questionLabel), __jsx(ThumbsButton, {
    src: iconThumbsDown,
    onClick: () => window.open(`https://www.surveymonkey.com/r/${surveyId}?User_id=${userState.id ? userState.id : ''}&offering=${urlHash}&like_dislike=0`, '_blank'),
    "data-cy": testIds.surveyThumbsDown
  }), __jsx(ThumbsButton, {
    src: iconThumbsUp,
    onClick: () => window.open(`https://www.surveymonkey.com/r/${surveyId}?User_id=${userState.id ? userState.id : ''}&offering=${urlHash}&like_dislike=1`, '_blank'),
    "data-cy": testIds.surveyThumbsUp
  }))));
};
export default SurveySection;