import get from 'lodash/get';
import { isInvestorEntityStepDone, isInvestorEntityStepPending } from './useInvestorEntityStepDone';
const getSaiProgress = function () {
  let bankIsPending = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return {
    kyc: 20,
    id: 40,
    bank: bankIsPending ? 20 : 30,
    aiq: 30
  };
};
const getNaiProgress = function () {
  let bankIsPending = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  return {
    kyc: 30,
    id: 70,
    bank: bankIsPending ? 20 : 30
  };
};
const getProgress = function () {
  let accreditedSignUp = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  let step = arguments.length > 1 ? arguments[1] : undefined;
  let bankIsPending = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  const saiProgress = getSaiProgress(bankIsPending);
  const naiProgress = getNaiProgress(bankIsPending);
  return get(accreditedSignUp ? saiProgress : naiProgress, step, 0);
};
const updateProgress = (details, progress, step, accreditedSignUp) => {
  const isBankStep = step === 'bank';
  if (isInvestorEntityStepDone(details, isBankStep)) {
    progress += getProgress(accreditedSignUp, step);
  } else if (isBankStep && isInvestorEntityStepPending(details)) {
    progress += getProgress(accreditedSignUp, step, true);
  }
  return progress;
};
export const getInvestorEntityProgress = accountStatus => {
  let progress = 0;
  if (accountStatus) {
    const {
      identity,
      bankAccounts,
      accreditation,
      accreditedSignUp
    } = accountStatus;
    progress = updateProgress(identity, progress, 'id', accreditedSignUp);
    progress = updateProgress(bankAccounts, progress, 'bank', accreditedSignUp);
    progress = updateProgress(accreditation, progress, 'aiq', accreditedSignUp);
    if (progress === 0) {
      const saiProgress = getSaiProgress();
      const naiProgress = getNaiProgress();
      progress = accreditedSignUp ? saiProgress.kyc : naiProgress.kyc;
    }
    return progress;
  }
  return null;
};