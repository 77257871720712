import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { LegalEntityType, DISCLAIMER_INFO } from '@yieldstreet/platform-kit';
import { Interpolation } from '@yieldstreet/tool-kit';
import { Wrapper, InnerWrapper, DisclaimerBlock } from './DisclaimerSection.style';
import { testIds } from './DisclaimerSection.model';
const DisclaimerSection = _ref => {
  var _DISCLAIMER_INFO$disc4;
  let {
    section,
    legalEntityType,
    spvName
  } = _ref;
  const {
    useLegalEntityDisclaimer,
    content
  } = section;
  const renderDisclaimer = () => {
    var _DISCLAIMER_INFO$disc, _DISCLAIMER_INFO$disc2, _DISCLAIMER_INFO$spec, _DISCLAIMER_INFO$spec2, _DISCLAIMER_INFO$disc3;
    switch (legalEntityType) {
      case LegalEntityType.SPVM:
        return __jsx(DisclaimerBlock, _extends({
          size: "small"
        }, testIds.disclaimerBlock.attr), __jsx(Interpolation, {
          template: (_DISCLAIMER_INFO$disc = DISCLAIMER_INFO.disclaimer) === null || _DISCLAIMER_INFO$disc === void 0 ? void 0 : _DISCLAIMER_INFO$disc[legalEntityType],
          values: {
            spvName
          }
        }));
      case LegalEntityType.SHTN:
        return __jsx(React.Fragment, null, __jsx(DisclaimerBlock, _extends({
          size: "small"
        }, testIds.disclaimerBlock.attr), __jsx(Interpolation, {
          template: (_DISCLAIMER_INFO$disc2 = DISCLAIMER_INFO.disclaimer) === null || _DISCLAIMER_INFO$disc2 === void 0 ? void 0 : _DISCLAIMER_INFO$disc2[legalEntityType],
          values: {
            ysStNotes: DISCLAIMER_INFO.ysStNotes,
            disclaimerCopyDateSHTN: DISCLAIMER_INFO.disclaimerCopyDateSHTN
          }
        })), (_DISCLAIMER_INFO$spec = DISCLAIMER_INFO.specificExtraDisclaimer) === null || _DISCLAIMER_INFO$spec === void 0 ? void 0 : (_DISCLAIMER_INFO$spec2 = _DISCLAIMER_INFO$spec[legalEntityType]) === null || _DISCLAIMER_INFO$spec2 === void 0 ? void 0 : _DISCLAIMER_INFO$spec2.map((copy, idx) => {
          const key = `${testIds.extraDisclaimerCopySpecific.id}-${idx}`;
          return __jsx(DisclaimerBlock, _extends({
            key: key,
            size: "small"
          }, testIds.extraDisclaimerCopySpecific.attr), copy);
        }));
      case LegalEntityType.BPDN:
        return __jsx(DisclaimerBlock, _extends({
          size: "small"
        }, testIds.disclaimerBlock.attr), __jsx(Interpolation, {
          template: (_DISCLAIMER_INFO$disc3 = DISCLAIMER_INFO.disclaimer) === null || _DISCLAIMER_INFO$disc3 === void 0 ? void 0 : _DISCLAIMER_INFO$disc3[legalEntityType],
          values: {
            spvName,
            disclaimerCopyDateBPDN: DISCLAIMER_INFO.disclaimerCopyDateBPDN
          }
        }));
      default:
        return null;
    }
  };
  return __jsx(Wrapper, testIds.carouselWrapper.attr, __jsx(InnerWrapper, null, useLegalEntityDisclaimer && renderDisclaimer(), content.map((additionalCopy, idx) => {
    const key = `${testIds.disclaimerAdditionalType.id}-${idx}`;
    return __jsx(DisclaimerBlock, _extends({
      key: key,
      size: "small"
    }, testIds.disclaimerAdditionalType.attr), additionalCopy);
  }), (_DISCLAIMER_INFO$disc4 = DISCLAIMER_INFO.disclaimerExtraBottomCopy) === null || _DISCLAIMER_INFO$disc4 === void 0 ? void 0 : _DISCLAIMER_INFO$disc4.map((copy, idx) => {
    const key = `${testIds.extraDisclaimerCopy.id}-${idx}`;
    return __jsx(DisclaimerBlock, _extends({
      key: key,
      size: "small"
    }, testIds.extraDisclaimerCopy.attr), copy);
  })));
};
export default DisclaimerSection;