import { useState, useCallback, useMemo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { cookiesGet, cookiesSet, cookiesExpire } from 'redux-cookies';
import { getSignupStepCount, getSignupStepFlow, isValidSignupStep, storeTrackingMeta } from '@yieldstreet/tool-kit';
import useActions from 'utils/hooks/useActions';
import useQuiz from 'utils/hooks/useQuiz';
const actions = {
  cookiesGet,
  cookiesSet,
  cookiesExpire
};
export const useSignupQuiz = () => {
  const {
    cookiesGet,
    cookiesSet,
    cookiesExpire
  } = useActions(actions);
  const {
    pathname
  } = useLocation();
  const quizKey = 'signup-quiz';
  const quizIdKey = `${quizKey}-id`;
  const currentQuizId = useMemo(() => {
    return cookiesGet(quizIdKey);
  }, [cookiesGet, quizIdKey]);
  const currentUrlStep = useMemo(() => {
    const regex = /\/[^/]+\/([^/]+)/g;
    const match = regex.exec(pathname);
    if (match !== null) {
      return match[1];
    } else {
      return null;
    }
  }, [pathname]);
  const {
    quizId,
    quizAnswers,
    getQuizAnswer,
    saveQuizAnswer,
    isFetchingAnswers
  } = useQuiz(quizKey, {
    currentQuizId
  });
  useEffect(() => {
    if (!currentQuizId && quizId) {
      cookiesSet(quizIdKey, quizId);
    }
  }, [currentQuizId, cookiesSet, quizId, quizIdKey]);
  useEffect(() => {
    var _localStorage;
    if (quizId && quizId !== !!((_localStorage = localStorage) !== null && _localStorage !== void 0 && _localStorage.getItem('signup_quiz_id'))) {
      storeTrackingMeta({
        signup_quiz_id: quizId
      });
    }
  }, [quizId]);
  const stepFlow = useMemo(() => getSignupStepFlow(), []);
  const {
    0: currentStep,
    1: setCurrentStep
  } = useState(currentUrlStep);
  const currentStepObj = (stepFlow === null || stepFlow === void 0 ? void 0 : stepFlow[currentStep]) || {};
  const currentStepCount = useMemo(() => getSignupStepCount(currentStep), [currentStep]);
  const clearSignupQuiz = useCallback(() => {
    cookiesExpire(quizIdKey);
  }, [cookiesExpire, quizIdKey]);
  const lastAnsweredStep = useMemo(() => {
    if (!isFetchingAnswers && quizAnswers) {
      const answeredSteps = Object.keys(quizAnswers).filter(step => isValidSignupStep(step));
      const lastAnswered = answeredSteps.reduce((acc, stepName) => {
        const stepCount = getSignupStepCount(stepName);
        if (!acc.stepName || acc.stepCount.currentStep < stepCount.currentStep) {
          return {
            stepName,
            stepCount
          };
        }
        return acc;
      }, {});
      return stepFlow === null || stepFlow === void 0 ? void 0 : stepFlow[lastAnswered.stepName];
    }
  }, [isFetchingAnswers, quizAnswers, stepFlow]);
  return {
    quizId,
    quizKey,
    stepFlow,
    currentStep,
    currentStepObj,
    currentStepCount,
    setCurrentStep,
    clearSignupQuiz,
    getQuizAnswer,
    saveQuizAnswer,
    isFetchingAnswers,
    lastAnsweredStep
  };
};