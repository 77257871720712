import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _defineProperty from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import merge from 'lodash/merge';
import forEach from 'lodash/forEach';
import omit from 'lodash/omit';
import filter from 'lodash/filter';
import { SocketManagerReconnectionStrategy } from './socket-manager.model';
import { socketHeartbeat } from './socket-manager.utils';
class WebsocketManager {
  constructor() {
    _defineProperty(this, "connections", {});
    _defineProperty(this, "timeoutId", void 0);
  }
  get() {
    return this.connections;
  }
  send(key, data, params) {
    const connection = this.connections[key];
    if (params) {
      var _connection$sockets$k;
      const key = typeof params === 'string' ? params : this.getComplexKey(params);
      connection === null || connection === void 0 ? void 0 : (_connection$sockets$k = connection.sockets[key]) === null || _connection$sockets$k === void 0 ? void 0 : _connection$sockets$k.send(data);
    } else {
      forEach(connection === null || connection === void 0 ? void 0 : connection.sockets, x => x.send(data));
    }
  }
  add(key, config, events) {
    var _connection$events;
    const sockets = {};
    const params = config.params || {
      1: null
    };
    const foundConnection = this.connections[key];
    if (foundConnection && !config.params) {
      this.subscribe(key, events);
      return;
    }
    const connection = foundConnection || {
      config,
      events: [],
      sockets: {}
    };
    (_connection$events = connection.events) === null || _connection$events === void 0 ? void 0 : _connection$events.push(events);
    forEach(params, param => {
      const socketKey = this.getComplexKey(param);
      const url = this.sanitizeUrl(config.url, param);
      const socket = this.connect(connection, key, socketKey, url, config);
      Object.assign(sockets, {
        [socketKey]: socket
      });
    });
    this.connections = merge(this.connections, {
      [key]: _objectSpread(_objectSpread({}, connection), {}, {
        sockets
      })
    });
    this.heartbeat();
  }
  subscribe(key, events) {
    const connection = this.connections[key];
    if (!connection) {
      return;
    }
    connection.events.push(events);
  }
  unsubscribe(key, events) {
    var _connection2, _connection3;
    const connection = () => this.connections[key];
    if (!events && connection()) {
      this.remove(key);
      return;
    }
    const updatedEvents = filter((_connection2 = connection()) === null || _connection2 === void 0 ? void 0 : _connection2.events, e => e !== events);
    if (!updatedEvents.length) {
      this.remove(key);
      return;
    }
    if ((_connection3 = connection()) !== null && _connection3 !== void 0 && _connection3.events) {
      connection().events = updatedEvents;
    }
  }
  remove(key) {
    var _this$connections$key;
    const connectedSockets = (_this$connections$key = this.connections[key]) === null || _this$connections$key === void 0 ? void 0 : _this$connections$key.sockets;
    const keys = Object.keys(connectedSockets || {});
    for (const selectedKey of keys) {
      try {
        const socket = connectedSockets === null || connectedSockets === void 0 ? void 0 : connectedSockets[selectedKey];
        if (socket && socket.readyState === socket.OPEN) {
          socket.close();
        }
      } catch {}
    }
    this.connections = omit(this.connections, key);
  }
  removeAll() {
    forEach(this.connections, (_connection, key) => {
      this.remove(key);
    });
    this.connections = {};
    clearTimeout(this.timeoutId);
  }
  getComplexKey(object) {
    if (!object) return '*';
    return Object.values(object).join('-');
  }
  connect(connection, connectionKey, socketKey, url, config) {
    const socket = new WebSocket(url);
    socket.onopen = ev => {
      var _this$connections$con;
      forEach((_this$connections$con = this.connections[connectionKey]) === null || _this$connections$con === void 0 ? void 0 : _this$connections$con.events, e => {
        var _e$onOpen;
        (_e$onOpen = e.onOpen) === null || _e$onOpen === void 0 ? void 0 : _e$onOpen.call(this, socket, ev);
      });
    };
    socket.onmessage = ev => {
      var _this$connections$con2;
      forEach((_this$connections$con2 = this.connections[connectionKey]) === null || _this$connections$con2 === void 0 ? void 0 : _this$connections$con2.events, e => {
        var _e$onMessage;
        (_e$onMessage = e.onMessage) === null || _e$onMessage === void 0 ? void 0 : _e$onMessage.call(this, socket, ev);
      });
    };
    socket.onerror = ev => {
      var _this$connections$con3;
      forEach((_this$connections$con3 = this.connections[connectionKey]) === null || _this$connections$con3 === void 0 ? void 0 : _this$connections$con3.events, e => {
        var _e$onError;
        (_e$onError = e.onError) === null || _e$onError === void 0 ? void 0 : _e$onError.call(this, socket, ev);
      });
    };
    socket.onclose = ev => {
      //Before retry, we need to check WHY close was called.
      if (this.connections[connectionKey]) {
        // an error has occcured //  call handle retry;
        this.handleRetryMechanism(connection, connectionKey, socketKey, url, config);
      } else {
        forEach(connection.events, e => {
          var _e$onClose;
          (_e$onClose = e.onClose) === null || _e$onClose === void 0 ? void 0 : _e$onClose.call(this, socket, ev);
        });
      }
    };
    return socket;
  }
  handleRetryMechanism(connection, connectionKey, socketKey, url, config) {
    const SMART_RETRY_TIMEOUT_INTERVAL = 10000;
    if (!config.reconnectionStrategy || config.reconnectionStrategy === SocketManagerReconnectionStrategy.ignore) {
      this.remove(connectionKey);
    } else if (config.reconnectionStrategy === SocketManagerReconnectionStrategy.retry) {
      setTimeout(args => {
        var _this$connections, _this$connections$con4;
        const socket = this.connect.bind(args);
        if ((_this$connections = this.connections) !== null && _this$connections !== void 0 && (_this$connections$con4 = _this$connections[connectionKey]) !== null && _this$connections$con4 !== void 0 && _this$connections$con4.sockets[socketKey]) {
          var _this$connections2, _this$connections2$co;
          Object.assign(((_this$connections2 = this.connections) === null || _this$connections2 === void 0 ? void 0 : (_this$connections2$co = _this$connections2[connectionKey]) === null || _this$connections2$co === void 0 ? void 0 : _this$connections2$co.sockets) || {}, {
            [socketKey]: socket
          });
        }
      }, SMART_RETRY_TIMEOUT_INTERVAL, connection, connectionKey, socketKey, url, config);
    }
  }
  heartbeat() {
    const TIMEOUT_INTERVAL = 30000;
    if (this.timeoutId) {
      return;
    }
    const handleHeartbeat = connections => {
      forEach(connections, connection => {
        if (!Object.values((connection === null || connection === void 0 ? void 0 : connection.sockets) || {}).length) {
          this.timeoutId = 0;
          return;
        }
        forEach(connection === null || connection === void 0 ? void 0 : connection.sockets, socket => {
          try {
            if (socket.readyState === socket.OPEN) {
              socket.send(socketHeartbeat);
            }
          } catch {}
        });
      });
      this.timeoutId = setTimeout(handleHeartbeat, TIMEOUT_INTERVAL, this.connections);
    };
    this.timeoutId = setTimeout(handleHeartbeat, TIMEOUT_INTERVAL, this.connections);
  }
  sanitizeUrl(url, params) {
    forEach(params, (set, key) => url = url.replace(`:${key}`, set));
    return url;
  }
}
export const websocketManager = new WebsocketManager();