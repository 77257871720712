import styled, { css } from 'styled-components';
import { Label, media } from '@yieldstreet/ui-kit';
import { InformationBlock } from '../../components/InformationBlock/InformationBlock';
export const Wrapper = styled.div.withConfig({
  displayName: "Portfoliosstyle__Wrapper",
  componentId: "sc-15svo5f-0"
})(["display:flex;flex-direction:column;width:100%;text-align:left;"]);
const WrapperStyle = css(["display:flex;flex-direction:row;"]);
export const CardsWrapper = styled.div.withConfig({
  displayName: "Portfoliosstyle__CardsWrapper",
  componentId: "sc-15svo5f-1"
})(["display:flex;flex-direction:column;gap:", ";", ""], _ref => {
  let {
    theme
  } = _ref;
  return theme.spacing.m;
}, media.large`
    flex-direction: row;
  `);
export const HeaderWrapper = styled.div.withConfig({
  displayName: "Portfoliosstyle__HeaderWrapper",
  componentId: "sc-15svo5f-2"
})(["", " justify-content:space-between;"], WrapperStyle);
export const BalanceWrapper = styled.div.withConfig({
  displayName: "Portfoliosstyle__BalanceWrapper",
  componentId: "sc-15svo5f-3"
})(["", " justify-content:space-between;", ""], WrapperStyle, media.large`
    justify-content: unset;
    gap: ${_ref2 => {
  let {
    theme
  } = _ref2;
  return theme.spacing.ml;
}};
  `);
export const FooterWrapper = styled.div.withConfig({
  displayName: "Portfoliosstyle__FooterWrapper",
  componentId: "sc-15svo5f-4"
})(["display:flex;flex-direction:column;gap:", ";", ""], _ref3 => {
  let {
    theme
  } = _ref3;
  return theme.spacing.m;
}, media.large`
    gap: unset;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  `);
export const AccountInfoWrapper = styled.div.withConfig({
  displayName: "Portfoliosstyle__AccountInfoWrapper",
  componentId: "sc-15svo5f-5"
})(["", " gap:", ";"], WrapperStyle, _ref4 => {
  let {
    theme
  } = _ref4;
  return theme.spacing.ml;
});
export const LabelWrapper = styled.div.withConfig({
  displayName: "Portfoliosstyle__LabelWrapper",
  componentId: "sc-15svo5f-6"
})(["", ""], media.large`
    max-width: 235px;
  `);
export const DetailInfo = styled.div.withConfig({
  displayName: "Portfoliosstyle__DetailInfo",
  componentId: "sc-15svo5f-7"
})(["display:flex;flex-direction:column;gap:", ";"], _ref5 => {
  let {
    theme
  } = _ref5;
  return theme.spacing.xxs;
});
export const HR = styled.hr.withConfig({
  displayName: "Portfoliosstyle__HR",
  componentId: "sc-15svo5f-8"
})(["display:block;border:0;border-top:1px solid ", ";width:100%;"], props => props.theme.colors.border_default);
export const ShowIcon = styled.img.withConfig({
  displayName: "Portfoliosstyle__ShowIcon",
  componentId: "sc-15svo5f-9"
})(["cursor:pointer;vertical-align:bottom;margin-left:", ";"], _ref6 => {
  let {
    theme
  } = _ref6;
  return theme.spacing.xxs;
});
export const WalletBlock = styled(InformationBlock).withConfig({
  displayName: "Portfoliosstyle__WalletBlock",
  componentId: "sc-15svo5f-10"
})(["flex-basis:50%;"]);
export const ManagedSetupDetails = styled.div.withConfig({
  displayName: "Portfoliosstyle__ManagedSetupDetails",
  componentId: "sc-15svo5f-11"
})(["display:flex;flex-direction:column;gap:", ";"], _ref7 => {
  let {
    theme
  } = _ref7;
  return theme.spacing.m;
});
export const LinkLabel = styled(Label).withConfig({
  displayName: "Portfoliosstyle__LinkLabel",
  componentId: "sc-15svo5f-12"
})(["cursor:pointer;text-decoration:underline;"]);
export const TooltipWrapper = styled.div.withConfig({
  displayName: "Portfoliosstyle__TooltipWrapper",
  componentId: "sc-15svo5f-13"
})(["display:flex;gap:", ";"], _ref8 => {
  let {
    theme
  } = _ref8;
  return theme.spacing.xs;
});