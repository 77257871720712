import styled from 'styled-components';
import { media } from '../../themes/media';
import { SliderInput } from '../../form';
import Arrow from './assets/Vector.svg';
export const Wrapper = styled.div.withConfig({
  displayName: "CalculatorSliderstyle__Wrapper",
  componentId: "sc-iat9d8-0"
})(["display:flex;flex-direction:column;padding:", ";background-color:", ";&:first-child{border-radius:4px 4px 0 0;}", ""], _ref => {
  let {
    theme
  } = _ref;
  return theme.spacing.m;
}, _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.colors.background_default;
}, media.large`
    width: 380px;
  `);
export const SSliderInput = styled(SliderInput).withConfig({
  displayName: "CalculatorSliderstyle__SSliderInput",
  componentId: "sc-iat9d8-1"
})(["margin-bottom:0 !important;", " > span{display:none;}p{background-color:", ";border-radius:4px;padding:6px 10px;", "}span{color:", ";}.MuiSlider-root{margin-bottom:0;.MuiSlider-track{color:#02c9a5;transition:unset;}.MuiSlider-thumb{background:#02c9a5;border-radius:4px;height:18px;position:relative;transition-property:height,width,box-shadow;transition-duration:0.3s;width:34px;&:hover{height:23px;width:39px;}&:before,&:after{background-image:url('", "');background-size:cover;content:'';height:8px;position:absolute;bottom:auto;width:5px;}&:before{left:5px;transform:scaleX(-1);}&:after{left:auto;right:5px;}}}"], media.desktop`
    margin-top: auto !important;
  `, props => props.theme.colors.background_page, media.desktop`
      display: block;
    `, props => props.theme.colors.chart_10, Arrow);
export const BoxContent = styled.div.withConfig({
  displayName: "CalculatorSliderstyle__BoxContent",
  componentId: "sc-iat9d8-2"
})(["display:flex;justify-content:space-between;margin-bottom:", ";column-gap:", ";"], _ref3 => {
  let {
    theme
  } = _ref3;
  return theme.spacing.sm;
}, _ref4 => {
  let {
    theme
  } = _ref4;
  return theme.spacing.m;
});