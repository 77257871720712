import { Paragraph } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
import { statusColor } from './InvestorEntityActionList.model';
export const InvestorEntityList = styled.div.withConfig({
  displayName: "InvestorEntityActionListstyle__InvestorEntityList",
  componentId: "sc-psb1ru-0"
})(["display:inline-block;width:100%;"]);
export const InvestorEntityItem = styled.div.withConfig({
  displayName: "InvestorEntityActionListstyle__InvestorEntityItem",
  componentId: "sc-psb1ru-1"
})(["width:100%;margin-bottom:", ";background:", ";"], props => props.theme.spacing.s, props => props.theme.colors.background_card);
export const InvestorEntityItemRow = styled.div.withConfig({
  displayName: "InvestorEntityActionListstyle__InvestorEntityItemRow",
  componentId: "sc-psb1ru-2"
})(["display:inline-flex;justify-content:space-between;align-items:center;width:100%;padding:", ";text-align:left;gap:", ";"], props => props.theme.spacing.sm, props => props.theme.spacing.sm);
export const InvestorEntityDetails = styled.div.withConfig({
  displayName: "InvestorEntityActionListstyle__InvestorEntityDetails",
  componentId: "sc-psb1ru-3"
})(["display:inline-block;"]);
export const InvestorEntityContent = styled.div.withConfig({
  displayName: "InvestorEntityActionListstyle__InvestorEntityContent",
  componentId: "sc-psb1ru-4"
})(["display:inline-block;"]);
export const InvestorEntityAction = styled.div.withConfig({
  displayName: "InvestorEntityActionListstyle__InvestorEntityAction",
  componentId: "sc-psb1ru-5"
})(["display:inline-block;text-wrap:nowrap;"]);
export const InvestorEntityStatus = styled(Paragraph).withConfig({
  displayName: "InvestorEntityActionListstyle__InvestorEntityStatus",
  componentId: "sc-psb1ru-6"
})(["display:inline-flex;padding-left:", ";", ""], props => props.theme.spacing.sm, props => props.status ? `color: ${statusColor[props.status](props.theme)};` : '');
export const InvestorEntityStatusIcon = styled.img.withConfig({
  displayName: "InvestorEntityActionListstyle__InvestorEntityStatusIcon",
  componentId: "sc-psb1ru-7"
})(["margin-right:", ";"], props => props.theme.spacing.xs);
export const InvestorEntityError = styled(Paragraph).withConfig({
  displayName: "InvestorEntityActionListstyle__InvestorEntityError",
  componentId: "sc-psb1ru-8"
})(["margin-top:", ";color:", ";"], props => props.theme.spacing.xs, props => props.theme.colors.text_error_main);