import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Paragraph, media } from '@yieldstreet/ui-kit';
import heroBgDesktop from './assets/herobg.png';
import heroBgMobile from './assets/herobgmobile.png';
export const BackNavigation = styled.div.withConfig({
  displayName: "ETCIntrostyle__BackNavigation",
  componentId: "sc-16khckr-0"
})(["align-items:center;cursor:pointer;display:flex;height:30px;width:20px;position:relative;z-index:10;"]);
export const Arrow = styled(FontAwesomeIcon).withConfig({
  displayName: "ETCIntrostyle__Arrow",
  componentId: "sc-16khckr-1"
})(["margin:0;width:10px;"]);
export const Hero = styled.div.withConfig({
  displayName: "ETCIntrostyle__Hero",
  componentId: "sc-16khckr-2"
})(["margin-top:", ";width:100%;height:600px;border-radius:", ";display:flex;align-items:flex-end;background:linear-gradient(179.91deg,rgba(0,0,0,0) 0.02%,rgba(0,0,0,0.4) 62.54%),url(", ") no-repeat;background-size:cover;", " ", ""], _ref => {
  let {
    theme
  } = _ref;
  return theme.spacing.m;
}, _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.radius.xl;
}, heroBgMobile, media.tablet`
    height: 480px;
    background: linear-gradient(179.91deg, rgba(0, 0, 0, 0) 0.02%, rgba(0, 0, 0, 0.4) 62.54%),
      url(${heroBgDesktop}) no-repeat top right;
    background-size: cover;
  `, media.desktop`
    align-items: flex-start;
    background:linear-gradient(277.57deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 76.12%),
      url(${heroBgDesktop}) no-repeat top right;
    background-size: cover;
  `);
export const HeroContentWrapper = styled.div.withConfig({
  displayName: "ETCIntrostyle__HeroContentWrapper",
  componentId: "sc-16khckr-3"
})(["max-width:660px;", " margin:", ";"], media.desktop`
    margin: ${_ref3 => {
  let {
    theme
  } = _ref3;
  return `${theme.spacing['4xl']} 0 0 ${theme.spacing['2xl']}`;
}};
  `, _ref4 => {
  let {
    theme
  } = _ref4;
  return `${theme.spacing.l} ${theme.spacing.m}`;
});
export const StyledParagraph = styled(Paragraph).withConfig({
  displayName: "ETCIntrostyle__StyledParagraph",
  componentId: "sc-16khckr-4"
})(["margin:", ";"], _ref5 => {
  let {
    theme
  } = _ref5;
  return `${theme.spacing.sm} 0 ${theme.spacing.l} 0`;
});
export const AboutIra = styled.div.withConfig({
  displayName: "ETCIntrostyle__AboutIra",
  componentId: "sc-16khckr-5"
})(["background:", ";border-radius:", ";margin-top:", ";padding:", ";width:100%;display:flex;flex-direction:column;gap:", ";", " ", ""], _ref6 => {
  let {
    theme
  } = _ref6;
  return theme.colors.background_park_green_500;
}, _ref7 => {
  let {
    theme
  } = _ref7;
  return theme.radius.xl;
}, _ref8 => {
  let {
    theme
  } = _ref8;
  return theme.spacing.l;
}, _ref9 => {
  let {
    theme
  } = _ref9;
  return `${theme.spacing.ml} ${theme.spacing.m}`;
}, _ref10 => {
  let {
    theme
  } = _ref10;
  return theme.spacing.l;
}, media.tablet`
    flex-direction: row;
    gap: ${_ref11 => {
  let {
    theme
  } = _ref11;
  return theme.spacing.m;
}};
  `, media.desktop`
    gap: ${_ref12 => {
  let {
    theme
  } = _ref12;
  return theme.spacing['3xl'];
}};
    padding: ${_ref13 => {
  let {
    theme
  } = _ref13;
  return `${theme.spacing['2xl']} ${theme.spacing['4xl']}`;
}};
  `);
export const AboutIraItem = styled.div.withConfig({
  displayName: "ETCIntrostyle__AboutIraItem",
  componentId: "sc-16khckr-6"
})([""]);
export const ItemIcon = styled.img.withConfig({
  displayName: "ETCIntrostyle__ItemIcon",
  componentId: "sc-16khckr-7"
})(["width:48px;height:48px;margin-bottom:", ";"], _ref14 => {
  let {
    theme
  } = _ref14;
  return theme.spacing.sm;
});
export const PoweredByEtcWrapper = styled.div.withConfig({
  displayName: "ETCIntrostyle__PoweredByEtcWrapper",
  componentId: "sc-16khckr-8"
})(["display:flex;border:1px solid ", ";border-radius:", ";padding:", ";width:100%;margin-top:", ";flex-direction:column;", " ", ""], _ref15 => {
  let {
    theme
  } = _ref15;
  return theme.colors.border_default;
}, _ref16 => {
  let {
    theme
  } = _ref16;
  return theme.radius.xl;
}, _ref17 => {
  let {
    theme
  } = _ref17;
  return theme.spacing.m;
}, _ref18 => {
  let {
    theme
  } = _ref18;
  return theme.spacing.l;
}, media.tablet`
    flex-direction: row;
    align-items: center;
  `, media.large`
    padding: ${_ref19 => {
  let {
    theme
  } = _ref19;
  return `${theme.spacing['3xl']} ${theme.spacing['4xl']}`;
}};
  `);
export const LogoSection = styled.div.withConfig({
  displayName: "ETCIntrostyle__LogoSection",
  componentId: "sc-16khckr-9"
})(["display:flex;flex-direction:column;margin-right:", ";", " ", ""], _ref20 => {
  let {
    theme
  } = _ref20;
  return theme.spacing.xl;
}, media.desktop`
    align-items: center;
    flex-direction: row;
    flex-shrink: 0;
  `, media.large`
    margin-right: ${_ref21 => {
  let {
    theme
  } = _ref21;
  return theme.spacing['4xl'];
}};
    width: 480px;
  `);
export const EtcLogo = styled.img.withConfig({
  displayName: "ETCIntrostyle__EtcLogo",
  componentId: "sc-16khckr-10"
})(["max-width:220px;margin:", ";", " ", ""], _ref22 => {
  let {
    theme
  } = _ref22;
  return `${theme.spacing.m} 0`;
}, media.tablet`
    max-width: auto;
    margin-bottom: 0;
  `, media.desktop`
    margin: 0 0 0 ${_ref23 => {
  let {
    theme
  } = _ref23;
  return theme.spacing.m;
}};
  `);
export const IraPromoWrapper = styled.div.withConfig({
  displayName: "ETCIntrostyle__IraPromoWrapper",
  componentId: "sc-16khckr-11"
})(["border-radius:", ";background:", ";padding:", ";margin-top:", ";", ""], _ref24 => {
  let {
    theme
  } = _ref24;
  return theme.radius.xl;
}, _ref25 => {
  let {
    theme
  } = _ref25;
  return theme.colors.background_park_green_50;
}, _ref26 => {
  let {
    theme
  } = _ref26;
  return theme.spacing.m;
}, _ref27 => {
  let {
    theme
  } = _ref27;
  return theme.spacing.l;
}, media.tablet`
    padding: ${_ref28 => {
  let {
    theme
  } = _ref28;
  return theme.spacing.l;
}};
  `);
export const IraPromoContent = styled.div.withConfig({
  displayName: "ETCIntrostyle__IraPromoContent",
  componentId: "sc-16khckr-12"
})(["max-width:690px;width:100%;text-align:left;display:flex;flex-direction:column;margin:0 auto;", ""], media.tablet`
    text-align: center;
  `);
export const PromoParagraph = styled(Paragraph).withConfig({
  displayName: "ETCIntrostyle__PromoParagraph",
  componentId: "sc-16khckr-13"
})(["margin:", " 0;"], _ref29 => {
  let {
    theme
  } = _ref29;
  return theme.spacing.sm;
});
export const Disclaimer = styled.div.withConfig({
  displayName: "ETCIntrostyle__Disclaimer",
  componentId: "sc-16khckr-14"
})(["margin-top:20px;"]);