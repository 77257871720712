export const PortfolioFilterKeys = {
  investorId: 'investorId',
  noteUrlHash: 'noteUrlHash',
  paymentType: 'paymentType',
  performanceStatus: 'performanceStatus',
  reportType: 'reportType',
  year: 'year',
  settlement: 'settlement',
  status: 'status'
};

/**
 * Eventually, pagnation can be handled from query params as well.
 * Since we do not have such functionality in  the UI yet, these
 * can remain as static values.
 */
export const paginationParams = {
  page: 1,
  pageSize: 20
};