import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { Heading, Paragraph } from '../../content';
import { SBox, ImageContent, TextContent } from './ProductType.style';
import { useTestIds } from './ProductType.model';
export const ProductType = _ref => {
  let {
    image,
    title,
    content,
    testId,
    onClick
  } = _ref;
  const testIds = useTestIds({
    testId: testId
  });
  return __jsx(SBox, _extends({
    onClick: onClick
  }, testIds.base.attr), __jsx(ImageContent, _extends({
    bgImg: image
  }, testIds.image.attr)), __jsx(TextContent, null, __jsx(Heading, _extends({
    type: 5
  }, testIds.title.attr), title), __jsx(Paragraph, testIds.content.attr, content)));
};