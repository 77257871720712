import styled from 'styled-components';
export const CardBaseContainer = styled.div.withConfig({
  displayName: "Cardstyle__CardBaseContainer",
  componentId: "sc-t8824c-0"
})(["border:1px solid ", ";border-radius:12px;height:100%;display:inline-block;padding:", ";width:100%;background:", ";"], props => props.theme.colors.border_default, props => props.theme.spacing.sm, props => props.bgColor || 'transparent');
export const CardBaseWrapper = styled(CardBaseContainer).attrs(props => ({
  as: props.isLink ? 'a' : 'div'
})).withConfig({
  displayName: "Cardstyle__CardBaseWrapper",
  componentId: "sc-t8824c-1"
})(["", " ", ""], props => props.isLink && `
    color: inherit;
    text-decoration: none;
  `, props => props.clickable && `
   cursor: pointer;
  `);