export const getUserAnalytics = userState => {
  var _userState$userMeta;
  return {
    userId: userState.hashedId,
    name: userState.displayName,
    firstName: userState.firstName,
    lastName: userState.lastName,
    email: userState.emailAddress,
    accredited: userState.accreditedSignUp,
    createdAt: userState.createdAt,
    intercomUserHash: (_userState$userMeta = userState.userMeta) === null || _userState$userMeta === void 0 ? void 0 : _userState$userMeta.intercom_user_hash_web
  };
};