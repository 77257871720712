export const getGateData = _ref => {
  let {
    record,
    recordGates
  } = _ref;
  if (!record || !recordGates) {
    return;
  }
  const {
    investorAccountId,
    noteUrlHash
  } = record;
  const investorGates = recordGates === null || recordGates === void 0 ? void 0 : recordGates[investorAccountId];
  if (!investorGates) {
    return;
  }
  return investorGates[noteUrlHash];
};