import isNil from "lodash/isNil";
import { isPlainObject } from '@reduxjs/toolkit';
export const customSerializeQuery = _ref => {
  let {
    endpointName,
    queryArgs,
    endpointDefinition
  } = _ref;
  const extraOptions = (endpointDefinition === null || endpointDefinition === void 0 ? void 0 : endpointDefinition.extraOptions) && endpointDefinition.extraOptions(queryArgs);
  let cacheKey = extraOptions ? extraOptions === null || extraOptions === void 0 ? void 0 : extraOptions.cacheKey : queryArgs;
  if (typeof cacheKey === 'symbol' || isNil(queryArgs)) {
    cacheKey = '';
  }

  // https://github.com/reduxjs/redux-toolkit/blob/master/packages/toolkit/src/query/defaultSerializeQueryArgs.ts
  return `${endpointName}(${JSON.stringify(cacheKey, (_key, value) => isPlainObject(value) ? Object.keys(value).sort().reduce((acc, key) => {
    acc[key] = value[key];
    return acc;
  }, {}) : value)})`;
};