import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["checkboxArr"];
var __jsx = React.createElement;
import React, { useMemo } from 'react';
import camelCase from "lodash/camelCase";
import { ProgressBarCheckbox } from './components/progress-bar-checkbox';
import { Wrapper } from './ProgressBar.style';
import { useTestIds, getTestIds } from './ProgressBar.model';
export const ProgressBar = _ref => {
  let {
      checkboxArr
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  const testIds = useTestIds(props);
  const renderCheckboxes = useMemo(() => checkboxArr.map((checkbox, idx) => {
    const testId = `${testIds.base.id}-${camelCase(checkbox.copy)}`;
    return __jsx(ProgressBarCheckbox, _extends({
      key: `${testId}-${idx}`,
      testId: testId,
      lastElement: idx === checkboxArr.length - 1,
      numOfElements: checkboxArr.length
    }, checkbox));
  }), [checkboxArr, testIds.base.id]);
  return __jsx(Wrapper, testIds.base.attr, renderCheckboxes);
};
ProgressBar.getTestIds = getTestIds;