var __jsx = React.createElement;
import React from 'react';
import styled, { css } from 'styled-components';
import { Heading, Paragraph } from '../..';
import { media } from '../../themes/media';
import { Label } from '../../content/text';
import CardHiglightBg from '../../assets/graphics/backgrounds/highlight-card-bg.png';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
export const CardListWithHighlight = _ref => {
  let {
    cardsData,
    fixedHighlight = false
  } = _ref;
  const renderCard = card => {
    if (card) {
      return __jsx(Card, {
        highlighted: card.highlighted
      }, __jsx(Logo, {
        highlighted: card.highlighted
      }, card.img), __jsx(Heading, {
        inverse: card.highlighted,
        type: card.highlighted ? 2 : 4
      }, card.heading), __jsx(TextBorder, {
        highlighted: card.highlighted,
        inverse: card.highlighted,
        secondary: true,
        small: !card.highlighted
      }, card.headingDescription), card.highlighted ? __jsx(Heading, {
        inverse: true,
        type: 5
      }, card.secondLabel) : __jsx(Paragraph, {
        small: true
      }, card.secondLabel), __jsx(SecondLabelDescription, {
        inverse: card.highlighted,
        small: true
      }, card.secondLabelDescription), card.button && __jsx(ButtonWrapper, {
        highlighted: card.highlighted
      }, card.button));
    }
    return null;
  };
  const getHighlitedCard = () => {
    const highlightedItem = cardsData.find(item => item.highlighted);
    if (highlightedItem !== null && highlightedItem !== void 0 && highlightedItem.node) {
      return highlightedItem.node;
    }
    return renderCard(highlightedItem);
  };
  return __jsx(Wrapper, null, fixedHighlight && getHighlitedCard(), __jsx(SSwiper, {
    slidesPerView: "auto",
    enabled: fixedHighlight
  }, cardsData.map((card, index) => {
    if (card.highlighted && fixedHighlight) {
      return null;
    }
    return __jsx(SwiperSlide, {
      key: `card-${index}`
    }, card.node ? card.node : renderCard(card));
  })));
};
const Wrapper = styled.div.withConfig({
  displayName: "CardListWithHighlight__Wrapper",
  componentId: "sc-1sypo5k-0"
})(["align-items:center;display:flex;justify-content:flex-start;", " > div.swiper-container{margin-left:0;margin-right:0;}"], media.tablet`
    justify-content: center;
  `);
const SSwiper = styled(Swiper).withConfig({
  displayName: "CardListWithHighlight__SSwiper",
  componentId: "sc-1sypo5k-1"
})(["margin-left:0;margin-right:0;.swiper-wrapper{align-items:center;display:flex;}.swiper-slide{width:auto;}", " ", ""], media.tablet`
    .swiper-wrapper {
      justify-content: center;
    }
  `, media.large`
    .swiper-slide:first-child > div {
      margin-left: 0 !important;
    }
  `);
export const Card = styled.div.withConfig({
  displayName: "CardListWithHighlight__Card",
  componentId: "sc-1sypo5k-2"
})(["cursor:pointer;display:flex;flex-direction:column;margin-left:10px;position:relative;text-align:center;z-index:2;", " ", ""], media.desktop`
    cursor: default;
    margin-left: 20px;
  `, props => props.highlighted ? css(["background:url('", "') no-repeat 0px ", ";background-size:cover;color:", ";height:388px;width:190px;margin-left:0;", ""], CardHiglightBg, props.theme.colors.background_inverse_light, props.theme.colors.white, media.large`
              margin-left: 20px;
              height: 438px;
              width: 372px;
          `) : css(["background:", ";height:263px;width:105px;", ""], props.theme.colors.background_card, media.large`
              height: 263px;
              width: 177px;
          `));
export const Logo = styled.div.withConfig({
  displayName: "CardListWithHighlight__Logo",
  componentId: "sc-1sypo5k-3"
})(["align-items:center;display:flex;justify-content:center;margin:0 auto;", ""], props => props.highlighted ? css(["height:114px;width:190px;> img{max-width:145px;}", ""], media.large`
        height: 152px;
        width: 372px;

        > img {
          max-width: 216px;
          width: 100%;
        }
      `) : css(["width:105px;height:77px;> img{max-width:85px;}", ""], media.large`
        height: 97px;
        width: 177px;

        > img {
          max-width: 157px;
        }
      `));
export const TextBorder = styled(Paragraph).withConfig({
  displayName: "CardListWithHighlight__TextBorder",
  componentId: "sc-1sypo5k-4"
})(["border-bottom:1px solid ", ";margin-bottom:20px;padding-bottom:20px;", ""], props => props.theme.colors.border_default, props => props.highlighted ? css(["margin:0 37px 20px 37px;width:117px;", ""], media.large`
        margin: 0 99px 20px 99px;
        width: 174px;
      `) : css(["margin:0 10px 20px 10px;width:85px;", ""], media.large`
        margin: 0 20px 20px 20px;
        width: 137px;
      `));
export const SecondLabelDescription = styled(Label).withConfig({
  displayName: "CardListWithHighlight__SecondLabelDescription",
  componentId: "sc-1sypo5k-5"
})(["padding:0 20px;word-break:keep-all;", ""], media.large`
      padding: 0;
      word-break: inherit;
    `);
export const ButtonWrapper = styled.div.withConfig({
  displayName: "CardListWithHighlight__ButtonWrapper",
  componentId: "sc-1sypo5k-6"
})(["display:block;margin:40px auto 0 auto;button{min-width:auto;}"]);