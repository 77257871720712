import styled from 'styled-components';
export const InputWrapper = styled.div.withConfig({
  displayName: "PortfolioFiltersDesktopstyle__InputWrapper",
  componentId: "sc-ei8ty9-0"
})(["display:flex;flex:1;"]);
export const inputStyles = {
  margin: '0 8px 0 0',
  width: '165px',
  borderRadius: '8px',
  borderBottom: '1px solid #D8DCE2'
};