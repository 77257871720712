import _Button from "@mui/material/Button";
import _Typography from "@mui/material/Typography";
import _Box from "@mui/material/Box";
var __jsx = React.createElement;
import React from 'react';
import inverseLock from '@yieldstreet/ui-kit/assets/graphics/icons/lock_encrypted_inverse.svg';
import MetaData from 'sharedComponents/MetaData';
import { Container, ContentWrapper } from './ReservedOffering.style';
const ReservedOffering = _ref => {
  let {
    title,
    subtitle,
    cta,
    destination,
    metaData
  } = _ref;
  return __jsx(React.Fragment, null, __jsx(MetaData, {
    metaData: metaData
  }), __jsx(Container, {
    "data-cy": "reserved-offering"
  }, __jsx(ContentWrapper, null, __jsx(_Box, {
    component: "img",
    sx: {
      height: '40px'
    },
    src: inverseLock,
    alt: "lock icon"
  }), __jsx(_Typography, {
    component: "h1",
    variant: "h2",
    color: "textSenary",
    sx: {
      mb: 6,
      mt: 6
    }
  }, title), __jsx(_Typography, {
    variant: "body2",
    color: "textSenary"
  }, subtitle), __jsx(_Button, {
    "data-cy": "reserved-offering-button",
    href: destination,
    sx: {
      mt: 3
    }
  }, cta))));
};
export default ReservedOffering;