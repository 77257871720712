export const light = {
  palette: {
    /**
     * Add extended light theme palette components here...
     *
     * @example
     * StatusBadge: {
     *  active: {
     *    background: swatches.BRAND.patinaGreen['100'],
     *    color: swatches.BRAND.midnightGreen['700'],
     *  },
     *  default: {
     *    background: swatches.SEMANTIC.error['100'],
     *    color: swatches.SEMANTIC.error['900'],
     *  },
     * },
     */
  }
};