export const CHARTS = {
  chart1: {
    '50': '#fff2f1',
    '100': '#fed7d2',
    '200': '#fec4bd',
    '300': '#fea99f',
    '400': '#fd998c',
    '500': '#fd7f6f',
    '600': '#e67465',
    '700': '#b45a4f',
    '800': '#8b463d',
    '900': '#6a352f'
  },
  chart2: {
    '50': '#fff8ef',
    '100': '#ffe8cc',
    '200': '#ffddb3',
    '300': '#ffcd90',
    '400': '#ffc47b',
    '500': '#ffb55a',
    '600': '#e8a552',
    '700': '#b58140',
    '800': '#8c6432',
    '900': '#6b4c26'
  },
  chart3: {
    '50': '#fffdf0',
    '100': '#fffacf',
    '200': '#fff7b8',
    '300': '#fff498',
    '400': '#fff184',
    '500': '#ffee65',
    '600': '#e8d95c',
    '700': '#b5a948',
    '800': '#8c8338',
    '900': '#6b642a'
  },
  chart4: {
    '50': '#f7fcef',
    '100': '#e7f5ce',
    '200': '#dcf1b6',
    '300': '#cbea95',
    '400': '#c1e681',
    '500': '#b2e061',
    '600': '#a2cc58',
    '700': '#7e9f45',
    '800': '#627b35',
    '900': '#4b5e29'
  },
  chart5: {
    '50': '#f1f9f5',
    '100': '#d4ece0',
    '200': '#bfe3d1',
    '300': '#a1d6bd',
    '400': '#8fceb0',
    '500': '#73c29c',
    '600': '#69b18e',
    '700': '#528a6f',
    '800': '#3f6b56',
    '900': '#305142'
  },
  chart6: {
    '50': '#f3fbf9',
    '100': '#dbf1ee',
    '200': '#caebe5',
    '300': '#b1e2d9',
    '400': '#a2dcd2',
    '500': '#8bd3c7',
    '600': '#7ec0b5',
    '700': '#63968d',
    '800': '#4c746d',
    '900': '#3a5954'
  },
  chart7: {
    '50': '#f2f7fb',
    '100': '#d7e7f2',
    '200': '#c4dbec',
    '300': '#a9cae3',
    '400': '#98c0dd',
    '500': '#7eb0d5',
    '600': '#73a0c2',
    '700': '#597d97',
    '800': '#456175',
    '900': '#354a59'
  },
  chart8: {
    '50': '#f9f8fb',
    '100': '#ebe9f4',
    '200': '#e1dfee',
    '300': '#d3d0e7',
    '400': '#cbc7e2',
    '500': '#beb9db',
    '600': '#ada8c7',
    '700': '#87839b',
    '800': '#696678',
    '900': '#504e5c'
  },
  chart9: {
    '50': '#fffafc',
    '100': '#feeff7',
    '200': '#fee8f3',
    '300': '#feddee',
    '400': '#fdd6ea',
    '500': '#fdcce5',
    '600': '#e6bad0',
    '700': '#b491a3',
    '800': '#8b707e',
    '900': '#6a5660'
  },
  chart10: {
    '50': '#f8f2f9',
    '100': '#ebd7eb',
    '200': '#e1c4e1',
    '300': '#d3a9d3',
    '400': '#ca98cb',
    '500': '#bd7ebe',
    '600': '#ac73ad',
    '700': '#865987',
    '800': '#684569',
    '900': '#4f3550'
  }
};