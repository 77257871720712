var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { media } from '../../../themes/media';
export const CardContainer = props => {
  const {
    children,
    className
  } = props;
  return __jsx(OuterContainer, {
    className: className
  }, children);
};
const OuterContainer = styled.div.withConfig({
  displayName: "CardContainer__OuterContainer",
  componentId: "sc-1wuj1fm-0"
})(["background:", ";box-shadow:", ";padding:30px;display:flex;flex-direction:column;justify-content:space-between;flex:1 0;text-align:left;margin:0 0 20px 0;max-width:475px;", ""], props => props.theme.colors.background_card, props => props.theme.shadows.shadow_default, media.desktop`
    margin: 0 10px 0 10px;
  `);