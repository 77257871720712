import _FormControl from "@mui/material/FormControl";
import _Select from "@mui/material/Select";
import _Box from "@mui/material/Box";
import _InputLabel from "@mui/material/InputLabel";
var __jsx = React.createElement;
import React, { useMemo } from 'react';
import { MarketplaceFilterQueryParameters as QueryParameters } from '@yieldstreet/platform-kit';
import { SortOption } from './Filters.style';
import { useFilters, useQueryFilter } from './hooks';
export const Sort = _ref => {
  let {
    sortOptions,
    queryFilterParams,
    initOptions
  } = _ref;
  const {
    query
  } = useQueryFilter(queryFilterParams);
  const {
    updateSearch
  } = useFilters({
    query
  });
  const {
    [QueryParameters.Sort]: sortBy = initOptions.sortBy,
    [QueryParameters.Order]: orderBy = initOptions.orderBy
  } = query;
  const valueLabel = useMemo(() => {
    const selectedOption = sortOptions.find(_ref2 => {
      let {
        key,
        direction
      } = _ref2;
      return key === sortBy && direction === orderBy;
    });
    return selectedOption === null || selectedOption === void 0 ? void 0 : selectedOption.label;
  }, [sortOptions, sortBy, orderBy]);
  const renderSortOptions = useMemo(() => {
    const onOptionChange = (key, direction) => {
      updateSearch({
        [QueryParameters.Sort]: key,
        [QueryParameters.Order]: direction
      });
    };
    return sortOptions.map(_ref3 => {
      let {
        key,
        label,
        direction
      } = _ref3;
      return __jsx(SortOption, {
        "data-cy": `sort-option-${key}-${direction}`,
        key: `sort-option-${key}-${direction}`,
        active: sortBy === key && direction === orderBy,
        onClick: () => {
          onOptionChange(key, direction);
        }
      }, label);
    });
  }, [sortBy, orderBy, sortOptions, updateSearch]);
  return __jsx(_FormControl, {
    sx: {
      m: 1,
      minWidth: 160
    },
    "data-cy": "sort-context-menu",
    size: "small"
  }, __jsx(_InputLabel, {
    id: "filter-dropdown-label",
    size: "small"
  }, "Sort by"), __jsx(_Select, {
    labelId: "filter-dropdown-label",
    "data-cy": "sort-dropdown",
    autoWidth: true,
    value: valueLabel,
    renderValue: () => valueLabel
  }, __jsx(_Box, {
    sx: {
      p: 5
    }
  }, renderSortOptions)));
};