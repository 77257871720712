export const mockPaymentActivityDebt = {
  userId: 4,
  activity: [{
    date: '2022-09-30',
    interest: 130.06,
    netInterest: 127.0,
    netIncome: 0,
    fexp: 3.06,
    totalWithoutFexp: 127.0,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 130.06,
    totalNetFexpNetKnownCapital: 127.0,
    investmentsPerGraphItem: {
      QCRiBw: {
        status: 'ACTIVE',
        noteUrlHash: 'QCRiBw',
        title: 'Scottsdale Resort Financing',
        assetClass: 'REAL',
        launchDate: '2022-04-14',
        amount: 127.0,
        investmentType: 'DEAL'
      }
    }
  }, {
    date: '2022-10-31',
    interest: 92.61,
    netInterest: 90.54,
    netIncome: 0,
    fexp: 2.07,
    totalWithoutFexp: 90.54,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 92.61,
    totalNetFexpNetKnownCapital: 90.54,
    investmentsPerGraphItem: {
      QCRiBw: {
        status: 'ACTIVE',
        noteUrlHash: 'QCRiBw',
        title: 'Scottsdale Resort Financing',
        assetClass: 'REAL',
        launchDate: '2022-04-14',
        amount: 90.54,
        investmentType: 'DEAL'
      }
    }
  }, {
    date: '2022-11-30',
    interest: 100.09,
    netInterest: 97.93,
    netIncome: 0,
    fexp: 2.16,
    totalWithoutFexp: 97.93,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 100.09,
    totalNetFexpNetKnownCapital: 97.93,
    investmentsPerGraphItem: {
      QCRiBw: {
        status: 'ACTIVE',
        noteUrlHash: 'QCRiBw',
        title: 'Scottsdale Resort Financing',
        assetClass: 'REAL',
        launchDate: '2022-04-14',
        amount: 97.93,
        investmentType: 'DEAL'
      }
    }
  }, {
    date: '2022-12-31',
    interest: 102.56,
    netInterest: 100.47,
    netIncome: 0,
    fexp: 2.09,
    totalWithoutFexp: 100.47,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 102.56,
    totalNetFexpNetKnownCapital: 100.47,
    investmentsPerGraphItem: {
      QCRiBw: {
        status: 'ACTIVE',
        noteUrlHash: 'QCRiBw',
        title: 'Scottsdale Resort Financing',
        assetClass: 'REAL',
        launchDate: '2022-04-14',
        amount: 100.47,
        investmentType: 'DEAL'
      }
    }
  }, {
    date: '2023-01-31',
    interest: 91.19,
    netInterest: 89.4,
    netIncome: 0,
    fexp: 1.79,
    totalWithoutFexp: 89.4,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 91.19,
    totalNetFexpNetKnownCapital: 89.4,
    investmentsPerGraphItem: {
      QCRiBw: {
        status: 'ACTIVE',
        noteUrlHash: 'QCRiBw',
        title: 'Scottsdale Resort Financing',
        assetClass: 'REAL',
        launchDate: '2022-04-14',
        amount: 89.4,
        investmentType: 'DEAL'
      }
    }
  }, {
    date: '2023-02-28',
    interest: 110.92,
    netInterest: 108.76,
    netIncome: 0,
    fexp: 2.16,
    totalWithoutFexp: 108.76,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 110.92,
    totalNetFexpNetKnownCapital: 108.76,
    investmentsPerGraphItem: {
      QCRiBw: {
        status: 'ACTIVE',
        noteUrlHash: 'QCRiBw',
        title: 'Scottsdale Resort Financing',
        assetClass: 'REAL',
        launchDate: '2022-04-14',
        amount: 108.76,
        investmentType: 'DEAL'
      }
    }
  }, {
    date: '2023-03-31',
    interest: 101.8,
    netInterest: 99.87,
    netIncome: 0,
    fexp: 1.93,
    totalWithoutFexp: 99.87,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 101.8,
    totalNetFexpNetKnownCapital: 99.87,
    investmentsPerGraphItem: {
      QCRiBw: {
        status: 'ACTIVE',
        noteUrlHash: 'QCRiBw',
        title: 'Scottsdale Resort Financing',
        assetClass: 'REAL',
        launchDate: '2022-04-14',
        amount: 99.87,
        investmentType: 'DEAL'
      }
    }
  }, {
    date: '2023-04-30',
    interest: 113.55,
    netInterest: 111.39,
    netIncome: 0,
    fexp: 2.16,
    totalWithoutFexp: 111.39,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 113.55,
    totalNetFexpNetKnownCapital: 111.39,
    investmentsPerGraphItem: {
      QCRiBw: {
        status: 'ACTIVE',
        noteUrlHash: 'QCRiBw',
        title: 'Scottsdale Resort Financing',
        assetClass: 'REAL',
        launchDate: '2022-04-14',
        amount: 111.39,
        investmentType: 'DEAL'
      }
    }
  }, {
    date: '2023-05-31',
    interest: 111.07,
    netInterest: 108.99,
    netIncome: 0,
    fexp: 2.08,
    totalWithoutFexp: 108.99,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 111.07,
    totalNetFexpNetKnownCapital: 108.99,
    investmentsPerGraphItem: {
      QCRiBw: {
        status: 'ACTIVE',
        noteUrlHash: 'QCRiBw',
        title: 'Scottsdale Resort Financing',
        assetClass: 'REAL',
        launchDate: '2022-04-14',
        amount: 108.99,
        investmentType: 'DEAL'
      }
    }
  }, {
    date: '2023-06-30',
    interest: 116.71,
    netInterest: 114.56,
    netIncome: 0,
    fexp: 2.15,
    totalWithoutFexp: 114.56,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 116.71,
    totalNetFexpNetKnownCapital: 114.56,
    investmentsPerGraphItem: {
      QCRiBw: {
        status: 'ACTIVE',
        noteUrlHash: 'QCRiBw',
        title: 'Scottsdale Resort Financing',
        assetClass: 'REAL',
        launchDate: '2022-04-14',
        amount: 114.56,
        investmentType: 'DEAL'
      }
    }
  }],
  total: {
    interest: 1070.56,
    netInterest: 1048.91,
    netIncome: 0,
    fexp: 21.65,
    totalWithoutFexp: 1048.91,
    totalNetFexpNetKnownCapital: 1048.91,
    cash: 0,
    cashLegacyFunds: 0,
    reInvest: 0,
    total: 1070.56
  },
  investments: [{
    status: 'ACTIVE',
    noteUrlHash: 'QCRiBw',
    title: 'Scottsdale Resort Financing',
    assetClass: 'REAL',
    launchDate: '2022-04-14',
    amount: 1048.91,
    investmentType: 'DEAL'
  }]
};