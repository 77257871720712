import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useState, useCallback, useMemo } from 'react';
import { HeaderItem } from '../HeaderItem';
import { LoginArea } from '../LoginArea/LoginArea';
import { mainNav, userNav } from '../navigationConfig';
import { Logo } from '../Logo';
import { Hamburger } from '../Hamburger';
import { Submenu } from '../../submenu';
import { useNavItemProps, getNavItemSubMenu, findActiveNavItem, NavigationLink } from '../../common';
import { Wrapper, Container, Flex, HamburgerWrap, LogoWrap, MenuContainer, Menu, MenuItemsContainer, SignButtons } from '../styles';
import { MobileOnly, DesktopOnly } from '../../../utils';
import { Paragraph } from '../../../content/text/Paragraph';
import Google from '../../../assets/logos/google_store_inverse.svg';
import Apple from '../../../assets/logos/apple_store_inverse.svg';
import { LoginButton, MobileMenuFooter } from './HeaderView.style';
export const Header = props => {
  const {
    audiences,
    tier,
    userState,
    currentPath,
    notificationGroup,
    preContent,
    midContent,
    postContent
  } = props;
  const navProps = useNavItemProps(props);
  const {
    0: sideMenuOpen,
    1: setSideMenuOpen
  } = useState(false);
  const toggleMenuOpen = useCallback(() => {
    setSideMenuOpen(!sideMenuOpen);
  }, [sideMenuOpen]);
  const activeItem = useMemo(() => {
    return findActiveNavItem([...mainNav, ...userNav], navProps, currentPath);
  }, [navProps, currentPath]);
  const activeSubMenu = useMemo(() => {
    return activeItem && getNavItemSubMenu(activeItem, navProps);
  }, [activeItem, navProps]);
  const activeSubItem = useMemo(() => {
    return activeSubMenu && findActiveNavItem(activeSubMenu, navProps, currentPath);
  }, [activeSubMenu, navProps, currentPath]);
  return __jsx(Wrapper, {
    "data-cy": "header-view"
  }, preContent, __jsx(Container, null, __jsx(Flex, null, __jsx(HamburgerWrap, null, __jsx(Hamburger, {
    opened: sideMenuOpen,
    onChange: toggleMenuOpen,
    inverse: true
  })), __jsx(LogoWrap, {
    onChange: toggleMenuOpen
  }, __jsx(NavigationLink, _extends({}, props, {
    navItem: {
      key: 'home',
      id: 'yieldstreet-logo',
      route: userState && userState.loggedIn ? '/offerings' : '/',
      staticRoute: true,
      ariaLabel: 'Yieldstreet logo'
    },
    raw: true
  }), __jsx(Logo, {
    inverse: true
  })))), __jsx(MenuContainer, {
    sideMenuOpen: sideMenuOpen,
    onClick: toggleMenuOpen
  }, __jsx(Menu, {
    sideMenuOpen: sideMenuOpen
  }, userState && !userState.loggedIn && __jsx(SignButtons, null, __jsx(NavigationLink, _extends({}, props, {
    navItem: {
      id: 'header-login',
      key: 'login',
      route: '/login'
    },
    raw: true
  }), __jsx(LoginButton, {
    color: "primary",
    fullWidth: true,
    "data-cy": "header-view-button"
  }, "Login"))), __jsx(DesktopOnly, null, __jsx(MenuItemsContainer, null, mainNav.map(item => __jsx(HeaderItem, _extends({
    key: item.key,
    activeItem: activeItem,
    activeSubItem: activeSubItem,
    menuItem: item,
    currentPath: currentPath,
    notificationGroup: notificationGroup
  }, props))))), __jsx(MobileOnly, null, __jsx(MenuItemsContainer, null, mainNav.map(item => __jsx(HeaderItem, _extends({
    key: item.key,
    activeItem: activeItem,
    activeSubItem: activeSubItem,
    menuItem: item,
    currentPath: currentPath,
    notificationGroup: notificationGroup,
    isSidebarItem: true,
    isMobile: true
  }, props)))), __jsx(MobileMenuFooter, null, __jsx(Paragraph, {
    inverse: true
  }, "Download the Yieldstreet mobile app"), __jsx("a", {
    rel: "noreferrer noopener",
    href: "https://apps.apple.com/us/app/yieldstreet-alt-investments/id1437754463",
    target: "_blank"
  }, __jsx("img", {
    src: Apple
  })), __jsx("a", {
    rel: "noreferrer noopener",
    href: "https://play.google.com/store/apps/details?id=com.yieldstreet",
    target: "_blank"
  }, __jsx("img", {
    src: Google
  })))))), __jsx(LoginArea, _extends({
    activeItem: activeItem,
    activeSubItem: activeSubItem,
    audiences: audiences,
    tier: tier
  }, props))), midContent, activeItem && activeSubMenu && !activeItem.itemsDropdown && __jsx(Submenu, _extends({
    activeKey: activeSubItem === null || activeSubItem === void 0 ? void 0 : activeSubItem.key,
    menu: activeSubMenu
  }, props, {
    preContent: null,
    postContent: null
  })), postContent);
};