var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { SectionContainer } from '../../layout/section-container/SectionContainer';
import { media } from '../../themes/media';
import { PageSection } from '../../layout/page-section';
import { Heading } from '../../content/text';
export const CtaBanner = _ref => {
  let {
    title,
    headingTagType = 3,
    headingType = 3,
    children,
    inverse,
    contentAlignment,
    mobileContentAlignment,
    className
  } = _ref;
  return __jsx(OuterContainer, null, __jsx(PageSection, null, __jsx(InnerContainer, null, __jsx(Container, {
    className: className,
    mobileContentAlignment: mobileContentAlignment,
    contentAlignment: contentAlignment
  }, __jsx(Headline, {
    tagType: headingTagType,
    type: headingType,
    inverse: inverse
  }, title), __jsx(Content, null, children)))));
};
const OuterContainer = styled.div.withConfig({
  displayName: "CtaBanner__OuterContainer",
  componentId: "sc-dpvk0c-0"
})([""]);
const InnerContainer = styled(SectionContainer).withConfig({
  displayName: "CtaBanner__InnerContainer",
  componentId: "sc-dpvk0c-1"
})(["", ";padding:0;"], media.tablet`
    padding: 0 35px;
  `);
const Headline = styled(Heading).withConfig({
  displayName: "CtaBanner__Headline",
  componentId: "sc-dpvk0c-2"
})([""]);
const Container = styled.div.withConfig({
  displayName: "CtaBanner__Container",
  componentId: "sc-dpvk0c-3"
})(["background:", ";width:100%;height:100%;margin:0 auto;padding:40px 20px;display:flex;text-align:", ";flex-direction:column;", ""], props => props.theme.colors.background_inverse_secondary, props => props.mobileContentAlignment === 'center' ? 'center' : 'left', media.desktop`
    padding: 60px 40px;
    align-items: center;
    justify-content: space-between;
    text-align: ${props => props.contentAlignment === 'center' ? 'center' : 'left'};
    flex-direction:  ${props => props.contentAlignment === 'center' ? 'column' : 'row'};

    ${Headline} {
      width: ${props => props.contentAlignment === 'center' ? '80%' : '50%'};
    }
  `);
const Content = styled.div.withConfig({
  displayName: "CtaBanner__Content",
  componentId: "sc-dpvk0c-4"
})([""]);