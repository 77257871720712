import { useMemo } from 'react';
import forEach from "lodash/forEach";
import { UseActivityMessagesConfigDefault } from './useActivityMessages.model';
export const useActivityMessages = _ref => {
  let {
    activityMapperFn,
    config,
    messages
  } = _ref;
  const configuration = Object.assign(UseActivityMessagesConfigDefault, config);
  return useMemo(() => {
    const messageList = {};
    if (!(messages !== null && messages !== void 0 && messages.length) || !activityMapperFn) {
      return {
        activityMessages: messageList
      };
    }
    let stackPointer = 0;
    forEach(messages, message => {
      var _messageDate$toISOStr, _message$configuratio, _messageList$extracte, _messageList$extracte2, _groupedMessages$0$co, _mappedMessage$config;
      const messageDate = new Date(message === null || message === void 0 ? void 0 : message.sortDate);
      const extractedDate = messageDate === null || messageDate === void 0 ? void 0 : (_messageDate$toISOStr = messageDate.toISOString()) === null || _messageDate$toISOStr === void 0 ? void 0 : _messageDate$toISOStr.split('T')[0];
      const currentMessageCategory = message === null || message === void 0 ? void 0 : (_message$configuratio = message.configuration) === null || _message$configuratio === void 0 ? void 0 : _message$configuratio.category;
      const mappedMessage = activityMapperFn(message);
      if (!messageList[extractedDate]) {
        messageList[extractedDate] = {
          date: extractedDate,
          groupedMessages: [{
            cards: [mappedMessage]
          }]
        };
        stackPointer = 0;
        return true;
      }
      const groupedMessages = (_messageList$extracte = messageList[extractedDate].groupedMessages) === null || _messageList$extracte === void 0 ? void 0 : (_messageList$extracte2 = _messageList$extracte[stackPointer]) === null || _messageList$extracte2 === void 0 ? void 0 : _messageList$extracte2.cards;
      if (currentMessageCategory && configuration.stackableCategories.includes(currentMessageCategory) && (groupedMessages === null || groupedMessages === void 0 ? void 0 : groupedMessages.length) <= configuration.stackLimit && (groupedMessages === null || groupedMessages === void 0 ? void 0 : (_groupedMessages$0$co = groupedMessages[0].config) === null || _groupedMessages$0$co === void 0 ? void 0 : _groupedMessages$0$co.iconType) === (mappedMessage === null || mappedMessage === void 0 ? void 0 : (_mappedMessage$config = mappedMessage.config) === null || _mappedMessage$config === void 0 ? void 0 : _mappedMessage$config.iconType)) {
        var _messageList$extracte3;
        (_messageList$extracte3 = messageList[extractedDate].groupedMessages[stackPointer]) === null || _messageList$extracte3 === void 0 ? void 0 : _messageList$extracte3.cards.push(mappedMessage);
        if (!message.read && (groupedMessages === null || groupedMessages === void 0 ? void 0 : groupedMessages.length) > 1) {
          messageList[extractedDate].groupedMessages[stackPointer].stackConfig = {
            hasUnreadMessages: true
          };
        }
      } else {
        messageList[extractedDate].groupedMessages.push({
          cards: [mappedMessage]
        });
        stackPointer++;
      }
    });
    return {
      activityMessages: messageList
    };
  }, [activityMapperFn, configuration.stackLimit, configuration.stackableCategories, messages]);
};