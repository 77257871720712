var __jsx = React.createElement;
import React from 'react';
import { useLocation } from 'react-router-dom';
import { ConsentManager } from '@yieldstreet/ui-kit';
import ENV_CONFIG from 'utils/env/config';
import SentryBoundaryError from 'sharedComponents/SentryBoundaryError';
const AppWrapper = _ref => {
  let {
    children
  } = _ref;
  const location = useLocation();
  return __jsx(SentryBoundaryError, {
    location: location
  }, children, __jsx(ConsentManager, {
    segmentWriteKey: ENV_CONFIG.SEGMENT_WRITE_KEY
  }));
};
export default AppWrapper;