import get from "lodash/get";
const fetchErrorHandler = error => {
  let messageError = null;
  if (get(error, 'response.status', null)) switch (error.response.status) {
    case 400:
      messageError = 'Bad Request';
      break;
    case 401:
      messageError = 'You must be authenticated to access this page';
      break;
    case 404:
      messageError = 'Not found the requested url';
      break;
    case 500:
      messageError = 'Internal Server Error'; //change this text
      break;
    default:
  } else {
    messageError = 'Something went wrong, please try again later';
  }
  //Logic for previous hasCustomError here
  if (error.message) {
    if (messageError === 'Network Error') {
      messageError = 'Please check your internet connection. We are not able to process your request in.';
    } else {
      messageError = 'Sorry, we are not able to process your request in due to a system error. Please try again later.';
    }
  }
  return get(error, 'data.message', null) || get(error, 'response.data.message', null) || get(error, 'data.error', null) || get(error, 'response.data.error', null) || get(error, 'response.data.errors', null) || messageError || 'Error.';
};
export default fetchErrorHandler;