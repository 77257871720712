import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { HttpMethods } from '../../enums/http';
import { Tags, restApi } from '../common';
export const authApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.UserState, Tags.UserTwoFactorAuthStatus]
}).injectEndpoints({
  endpoints: builder => {
    const passwordRegister = builder.mutation({
      query: data => {
        return {
          url: `/a/api/user/register`,
          method: HttpMethods.Post,
          data
        };
      },
      invalidatesTags: [Tags.UserState]
    });
    const passwordLogin = builder.mutation({
      query: data => {
        return {
          url: `/a/api/user/login`,
          method: HttpMethods.Post,
          data
        };
      },
      invalidatesTags: [Tags.UserState, Tags.UserTwoFactorAuthStatus]
    });
    const socialLogin = builder.mutation({
      query: _ref => {
        let {
          token,
          persistent,
          anonymousId,
          recaptchaV3,
          authData,
          userDetails
        } = _ref;
        return {
          url: '/api/auth/login',
          method: HttpMethods.Post,
          data: _objectSpread(_objectSpread({
            persistent,
            anonymousId,
            recaptchaV3
          }, authData), userDetails),
          headers: {
            Authorization: `Bearer ${token}`
          }
        };
      },
      invalidatesTags: [Tags.UserState, Tags.UserTwoFactorAuthStatus]
    });
    const socialIdentityLink = builder.mutation({
      query: _ref2 => {
        let {
          password
        } = _ref2;
        return {
          url: '/api/auth/link-identity',
          method: HttpMethods.Post,
          data: {
            password
          }
        };
      },
      invalidatesTags: [Tags.UserState, Tags.UserTwoFactorAuthStatus]
    });
    const impersonateLogin = builder.mutation({
      query: data => {
        return {
          url: '/api/admin/auth/impersonation/login',
          method: HttpMethods.Post,
          data
        };
      },
      invalidatesTags: [Tags.UserState]
    });
    const logout = builder.mutation({
      query: () => {
        return {
          url: '/a/api/user/logout',
          method: HttpMethods.Get
        };
      },
      invalidatesTags: [Tags.UserState]
    });
    const otpLogin = builder.mutation({
      query: _ref3 => {
        let {
          token
        } = _ref3;
        return {
          url: '/a/api/user/login-otp',
          method: HttpMethods.Post,
          data: {
            token
          }
        };
      },
      invalidatesTags: [Tags.UserState, Tags.UserTwoFactorAuthStatus]
    });
    return {
      passwordRegister,
      passwordLogin,
      socialLogin,
      socialIdentityLink,
      impersonateLogin,
      logout,
      otpLogin
    };
  }
});
export const loginApi = authApi;