import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { investorEntities } from '../../../investor-entity';
import { portfolioCapital } from './portfolio-capital.mock';
import { portfolioInterest } from './portfolio-interest.mock';
import { portfolioPrincipal } from './portfolio-principal.mock';
import { portfolioSummary } from './portfolio-summary.mock';
import { portfolioWallets } from './portfolio-wallets.mock';
export const portfolioOverview = {
  accountSummary: portfolioSummary,
  interest: portfolioInterest,
  principal: portfolioPrincipal,
  capital: portfolioCapital,
  userId: 4,
  wallets: portfolioWallets,
  investorEntities: [],
  investmentDetail: null,
  investmentGatesDetail: {
    requiresUserAttention: false,
    gates: []
  },
  investmentStrategies: [{
    id: '5904e0b9-8d6d-4520-bfbc-85e74d89107e',
    name: 'Income',
    description: 'Generates income through recurring distribution payments.',
    investorAccountId: `${investorEntities.entities[0].investorAccounts[1].id}`
  }]
};
export const getPortfolioOverviewMock = props => {
  const portfolioOverviewMock = _objectSpread({}, portfolioOverview);
  for (const prop in props) {
    // @ts-ignore
    portfolioOverviewMock[prop] = props[prop];
  }
  return portfolioOverviewMock;
};