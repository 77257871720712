var __jsx = React.createElement;
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { resolvePresetAudiences } from 'store/actions/audiences';
import { useUserState } from 'utils/hooks';
import useActions from 'utils/hooks/useActions';
import { FirstInvestmentLanding } from './FirstInvestmentLanding';
const actions = {
  resolvePresetAudiences
};
const FirstInvestmentLandingContainer = _ref => {
  let {
    match
  } = _ref;
  const {
    resolvePresetAudiences
  } = useActions(actions);
  const {
    investorEntityId
  } = match.params;
  useEffect(() => {
    resolvePresetAudiences('accountSetup');
    resolvePresetAudiences('login');
  }, [resolvePresetAudiences]);
  const userState = useUserState();
  const userAudiences = useSelector(_ref2 => {
    let {
      audiences
    } = _ref2;
    return audiences;
  });
  const {
    isFetching
  } = userAudiences;
  const {
    portfolioSizeAbove1M
  } = userAudiences.data;
  return __jsx(FirstInvestmentLanding, {
    loading: isFetching,
    userState: userState,
    investorEntityId: investorEntityId,
    portfolioSizeAbove1M: portfolioSizeAbove1M
  });
};
export default FirstInvestmentLandingContainer;