var __jsx = React.createElement;
import React from 'react';
import { BeanBox } from '../bean-box/BeanBox';
import { BeanBoxGroup } from './BeanBoxList.style';
export const BeanBoxList = _ref => {
  let {
    options,
    testId
  } = _ref;
  return __jsx(BeanBoxGroup, {
    "data-cy": `${testId}-bean-group`
  }, options.map((opt, idx) => __jsx(BeanBox, {
    id: idx,
    title: opt.title,
    img: opt.img,
    onClick: opt.onClick,
    testId: testId
  })));
};