import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { media, H4, ParagraphSmall } from '@yieldstreet/ui-kit';
export const Form = styled.div.withConfig({
  displayName: "SynapseLegalTextstyle__Form",
  componentId: "sc-15co23a-0"
})([""]);
export const KycText = styled.div.withConfig({
  displayName: "SynapseLegalTextstyle__KycText",
  componentId: "sc-15co23a-1"
})(["", " padding-bottom:20px;"], H4);
export const SSNCaption = styled.div.withConfig({
  displayName: "SynapseLegalTextstyle__SSNCaption",
  componentId: "sc-15co23a-2"
})(["margin:30px 16px 20px 0;font-size:14px;", ";", ";"], media.phoneLandscape`
    ${ParagraphSmall}
    margin: 30px 0 20px;
    width: 500px;
  `, media.tablet`
    width: 700px;
  `);
export const FullAddress = styled.div.withConfig({
  displayName: "SynapseLegalTextstyle__FullAddress",
  componentId: "sc-15co23a-3"
})(["display:flex;flex-direction:column;width:100%;visibility:", ";position:", ";"], props => props.showFullAddressFields ? 'visible' : 'hidden', props => props.showFullAddressFields ? 'static' : 'absolute');
export const Flex = styled.div.withConfig({
  displayName: "SynapseLegalTextstyle__Flex",
  componentId: "sc-15co23a-4"
})(["display:flex;width:100%;flex-direction:column;", ";"], media.tablet`
    flex-direction: row;
    & > div {
      flex-grow: 1;
      padding-right: 20px;
    }
    & > div:last-child {
      padding-right: 0;
    }
  `);
export const InputTextWrapper = styled.div.withConfig({
  displayName: "SynapseLegalTextstyle__InputTextWrapper",
  componentId: "sc-15co23a-5"
})(["display:flex;flex-direction:column;max-width:100%;", ";", ";", ";"], media.phoneLandscape`
    max-width: 60%;
  `, media.tablet`
    max-width: 40%;
  `, media.desktop`
    max-width: 33%;
  `);
export const InputSecondaryTextWrapper = styled.div.withConfig({
  displayName: "SynapseLegalTextstyle__InputSecondaryTextWrapper",
  componentId: "sc-15co23a-6"
})(["display:flex;flex-direction:column;margin:auto;max-width:100%;"]);
export const CheckboxText = styled.span.withConfig({
  displayName: "SynapseLegalTextstyle__CheckboxText",
  componentId: "sc-15co23a-7"
})(["", " padding-top:20px;"], ParagraphSmall);
export const ParagraphListWrapper = styled.div.withConfig({
  displayName: "SynapseLegalTextstyle__ParagraphListWrapper",
  componentId: "sc-15co23a-8"
})(["padding-left:20px;"]);
export const ParagraphListPadding = styled.div.withConfig({
  displayName: "SynapseLegalTextstyle__ParagraphListPadding",
  componentId: "sc-15co23a-9"
})(["padding-bottom:20px;"]);
export const CheckboxWrapper = styled.div.withConfig({
  displayName: "SynapseLegalTextstyle__CheckboxWrapper",
  componentId: "sc-15co23a-10"
})(["margin-bottom:40px;margin-top:40px;", ";"], media.desktop`
    max-width: 70%;
  `);
export const CheckboxFlexWrapper = styled.div.withConfig({
  displayName: "SynapseLegalTextstyle__CheckboxFlexWrapper",
  componentId: "sc-15co23a-11"
})(["display:flex;", ""], props => props.hidden && `flex-direction: column;`);
export const CheckboxSecondaryWrapper = styled.div.withConfig({
  displayName: "SynapseLegalTextstyle__CheckboxSecondaryWrapper",
  componentId: "sc-15co23a-12"
})(["margin-top:40px;text-align:left;"]);
export const FormWrapper = styled.div.withConfig({
  displayName: "SynapseLegalTextstyle__FormWrapper",
  componentId: "sc-15co23a-13"
})([""]);
export const Paragraph = styled.p.withConfig({
  displayName: "SynapseLegalTextstyle__Paragraph",
  componentId: "sc-15co23a-14"
})([""]);
export const CondensedParagraph = styled.div.withConfig({
  displayName: "SynapseLegalTextstyle__CondensedParagraph",
  componentId: "sc-15co23a-15"
})(["", ""], ParagraphSmall);
export const SubmitWrapper = styled.div.withConfig({
  displayName: "SynapseLegalTextstyle__SubmitWrapper",
  componentId: "sc-15co23a-16"
})(["display:block;"]);
export const StyledLink = styled(Link).withConfig({
  displayName: "SynapseLegalTextstyle__StyledLink",
  componentId: "sc-15co23a-17"
})(["text-align:center;", ";"], media.tablet`
    text-align: left;
  `);
export const FlexContent = styled.div.withConfig({
  displayName: "SynapseLegalTextstyle__FlexContent",
  componentId: "sc-15co23a-18"
})(["display:flex;"]);
export const ButtonContainer = styled.div.withConfig({
  displayName: "SynapseLegalTextstyle__ButtonContainer",
  componentId: "sc-15co23a-19"
})(["display:flex;flex-direction:column;margin-top:30px;> button:first-of-type{margin-bottom:10px;}"]);