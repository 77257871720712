var __jsx = React.createElement;
import React, { useMemo } from 'react';
import styled, { css, useTheme } from 'styled-components';
import isNumber from 'lodash/isNumber';
import { getConfig } from './common';
import { sizeCss, sizeMap, isValueMap } from '../../../utils/sizes';
import { GridProvider } from './Provider';
export const ColContainer = styled.div.withConfig({
  displayName: "Col__ColContainer",
  componentId: "sc-19p8wjh-0"
})(["box-sizing:border-box;flex:0 0 auto;", " ", ""], props => sizeCss(_ref => {
  let {
    gridSize,
    columnSize,
    contentDisplay
  } = _ref;
  // eslint-disable-next-line no-nested-ternary
  const percentWidth = columnSize ? isNumber(columnSize) ? 100 / gridSize * columnSize : 100 : 0;
  return css(["", ""], percentWidth ? // Number
  css(["display:", ";flex-direction:column;flex-basis:", "%;max-width:", "%;"], contentDisplay, percentWidth, percentWidth) : // Hide element
  css(["display:none;"]));
}, {
  gridSize: getConfig(props.theme, 'size'),
  columnSize: props.size,
  contentDisplay: props.display
}, {
  columnSize: true,
  contentDisplay: 'flex'
}), props => props.offset && sizeCss(_ref2 => {
  let {
    gridSize,
    columnOffset
  } = _ref2;
  return css(["margin-left:", "%;"], 100 / gridSize * columnOffset);
}, {
  gridSize: getConfig(props.theme, 'size'),
  columnOffset: props.offset
}));
const getColSize = (theme, size) => {
  if (!size) {
    return 0;
  }
  if (isNumber(size)) {
    return size;
  }
  if (isValueMap(size)) {
    const gridSize = getConfig(theme, 'size');
    return sizeMap(key => {
      const colSize = size[key];
      if (isNumber(colSize)) {
        return colSize;
      } else {
        return isValueMap(gridSize) ? gridSize[key] : gridSize;
      }
    });
  }
  return 0;
};
export const Col = _ref3 => {
  let {
    children,
    size,
    offset,
    display
  } = _ref3;
  const theme = useTheme();
  const config = useMemo(() => {
    const colSize = getColSize(theme, size);
    if (colSize) {
      return {
        size: colSize
      };
    }
  }, [size, theme]);
  return __jsx(ColContainer, {
    display: display,
    size: size,
    offset: offset
  }, __jsx(GridProvider, {
    config: config
  }, children));
};