var __jsx = React.createElement;
import React from 'react';
import { PageSection } from '../../layout';
import { Container, Content, ContentHolder, Headline, InnerContainer, Media, Middle, OuterContainer, Paragraph, SideContent, Slogan, SloganText, SSectionContainer } from './HeroBanner.style';
const getTitleVariant = (bannerSize, headingType) => {
  if (headingType) {
    return `h${headingType}`;
  }
  return bannerSize === 'small' ? 'h3' : 'h1';
};
export const HeroBanner = _ref => {
  let {
    bannerSize = 'normal',
    children,
    className,
    contentAlignment = 'left',
    headingTagType,
    headingType,
    hideMediaOnMobile,
    image,
    inverse,
    reverse,
    sideContent,
    slogan,
    tag,
    testId,
    title,
    video
  } = _ref;
  return __jsx(OuterContainer, {
    className: className,
    slogan: slogan,
    "data-cy": testId
  }, __jsx(PageSection, null, __jsx(SSectionContainer, null, __jsx(InnerContainer, {
    contentAlignment: contentAlignment
  }, __jsx(Container, {
    reverse: reverse,
    contentAlignment: contentAlignment
  }, __jsx(Middle, {
    bannerSize: bannerSize,
    contentAlignment: contentAlignment,
    className: "hero-banner-content-wrapper"
  }, __jsx(ContentHolder, {
    className: "hero-banner-content-holder",
    contentAlignment: contentAlignment
  }, tag && __jsx(Paragraph, {
    className: "hero-banner-content-tag",
    inverse: inverse,
    semiBold: true,
    variant: "body1"
  }, tag), title && __jsx(Headline, {
    component: headingTagType ? `h${headingTagType}` : 'h1'
    // @ts-expect-error - FIX ME
    ,
    variant: getTitleVariant(bannerSize, headingType),
    contentAlignment: contentAlignment,
    "data-cy": `${testId}-title`,
    inverse: inverse
  }, title), __jsx(Content, null, children))), sideContent && __jsx(SideContent, {
    contentAlignment: contentAlignment,
    className: "hero-banner-side-content"
  }, sideContent), !!video && __jsx(Media, {
    hideMediaOnMobile: hideMediaOnMobile
  }, video), !!image && __jsx(Media, {
    hideMediaOnMobile: hideMediaOnMobile,
    className: "hero-banner-image"
  }, image))), slogan && __jsx(Slogan, null, __jsx(SloganText, null, slogan)))));
};