import styled from 'styled-components';
export const TextContent = styled.div.withConfig({
  displayName: "CalculatorHeaderstyle__TextContent",
  componentId: "sc-1ot5b04-0"
})(["text-align:left;"]);
export const HeaderContent = styled.div.withConfig({
  displayName: "CalculatorHeaderstyle__HeaderContent",
  componentId: "sc-1ot5b04-1"
})(["display:flex;margin-bottom:", ";"], _ref => {
  let {
    theme
  } = _ref;
  return theme.spacing.xs;
});