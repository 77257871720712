import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useMemo } from 'react';
import { useParams, useLocation } from 'react-router';
import { useInvestorEntity, useInvestorEntityStatusPollCheck, getRoute } from '@yieldstreet/tool-kit';
import { PortfolioStrategyValues } from '@yieldstreet/platform-kit';
export const useRouteInvestorEntityId = () => {
  const params = useParams();
  const {
    investorEntityId
  } = params;
  return investorEntityId;
};
export const useRouteManagementStrategy = () => {
  const params = useParams();
  const {
    search
  } = useLocation();
  const searchParams = new URLSearchParams(search);
  return searchParams.get('managementStrategy') || (params === null || params === void 0 ? void 0 : params.managementStrategy);
};
export const getInvestorEntityRoute = (investorEntityId, route, managementStrategy) => {
  return getRoute(route, {
    investorEntityId,
    managementStrategy: managementStrategy || PortfolioStrategyValues.Self
  });
};
export const useInvestorEntityRoute = route => {
  const investorEntityId = useRouteInvestorEntityId();
  const managementStrategy = useRouteManagementStrategy();
  return useMemo(() => route ? getInvestorEntityRoute(investorEntityId, route, managementStrategy) : null, [investorEntityId, managementStrategy, route]);
};
export const useRouteInvestorEntity = () => {
  const investorEntityId = useRouteInvestorEntityId();
  const investorEntityState = useInvestorEntity(investorEntityId);
  return _objectSpread({
    investorEntityId
  }, investorEntityState);
};
export const useRouteInvestorEntityStatusPollCheck = (statusCheckCallback, skipAutoCheck) => {
  const investorEntityId = useRouteInvestorEntityId();
  return useInvestorEntityStatusPollCheck(investorEntityId, statusCheckCallback, skipAutoCheck);
};