import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useSelector } from 'react-redux';
export const useAudiencesTier = () => {
  const audiencesTier = useSelector(_ref => {
    var _audiences$data, _audiences$data$audie;
    let {
      audiences
    } = _ref;
    return audiences === null || audiences === void 0 ? void 0 : (_audiences$data = audiences.data) === null || _audiences$data === void 0 ? void 0 : (_audiences$data$audie = _audiences$data.audiences) === null || _audiences$data$audie === void 0 ? void 0 : _audiences$data$audie.tier;
  });
  return audiencesTier !== null && audiencesTier !== void 0 && audiencesTier.active ? _objectSpread({}, audiencesTier.meta) : {};
};
export default useAudiencesTier;