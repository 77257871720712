import { OfferingStatus } from '@yieldstreet/platform-kit';
import { isFortyActOrRegAPlus } from '../offering';
import { OfferingHeroLabels } from './formatOfferingHeroStatus.model';
export const formatOfferingHeroStatus = offerStatus => {
  const {
    status,
    percentageRemaining,
    waitlistOpen,
    fundType
  } = offerStatus;
  let openAlmostGone = false;
  if (percentageRemaining && percentageRemaining > 0 && percentageRemaining < 0.3) {
    openAlmostGone = true;
  }
  if (status === OfferingStatus.open && isFortyActOrRegAPlus(fundType)) {
    return {
      label: OfferingHeroLabels.alwaysOpen,
      webColor: 'notice_success',
      mobileColor: 'noticeSuccess'
    };
  }
  if (status === OfferingStatus.open && waitlistOpen) {
    return {
      label: OfferingHeroLabels.waitlistOpen,
      webColor: 'border_default',
      mobileColor: 'borderDefault'
    };
  }
  if (status === OfferingStatus.open && openAlmostGone) {
    return {
      label: OfferingHeroLabels.almostGone,
      webColor: 'chart_7',
      mobileColor: 'inDefault'
    };
  }
  switch (status) {
    case OfferingStatus.unfinished:
    case OfferingStatus.preoffering:
      return {
        label: OfferingHeroLabels.comingSoon,
        webColor: 'background_highlight',
        mobileColor: 'backgroundHighlight'
      };
    case OfferingStatus.open:
      return {
        label: OfferingHeroLabels.open,
        webColor: 'notice_success',
        mobileColor: 'noticeSuccess'
      };
    case OfferingStatus.pending:
    case OfferingStatus.pendingFullyCommitted:
    case OfferingStatus.closed:
    case OfferingStatus.payout:
      return {
        label: OfferingHeroLabels.closed,
        webColor: 'border_default',
        mobileColor: 'borderDefault'
      };
    case OfferingStatus.repaid:
      return {
        label: OfferingHeroLabels.fullyRepaid,
        webColor: 'accent_strong',
        mobileColor: 'accentStrong'
      };
    default:
      return {
        label: '',
        webColor: '',
        mobileColor: ''
      };
  }
};