var __jsx = React.createElement;
import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGate } from '@yieldstreet/tool-kit';
import { useModal } from '@yieldstreet/ui-kit';
import { investmentApi, marketplaceApi, OfferingStatus } from '@yieldstreet/platform-kit';
import { RolloverDockedHero } from 'sharedComponents/invest/RolloverDockedHero';
import { useGetRollover } from 'hooks/useGetRollover';
import { Modals } from 'constants/modal.model';
import DockedHero from '../v5/heading/DockedHero';
import OfferingHeroV5 from '../v5/heading/OfferingHeroV5';
const OfferingHeroContainer = _ref => {
  let {
    offering,
    metrics,
    history,
    isFooterInView,
    rollover
  } = _ref;
  const {
    isOpen: isOpenRollover
  } = useModal(Modals.Rollover);
  const {
    isOpen: isOpenAdvice
  } = useModal(Modals.Advice);
  const {
    useBulkInvestmentEligibilityMutation
  } = investmentApi;
  const {
    useGetProgressQuery
  } = marketplaceApi;
  const {
    userState
  } = useSelector(_ref2 => {
    let {
      user
    } = _ref2;
    return user;
  });
  const loggedIn = userState.loggedIn;
  const [getEligibility, {
    eligibilityData,
    isLoadingEligibility
  }] = useBulkInvestmentEligibilityMutation({
    selectFromResult: _ref3 => {
      let {
        data,
        isLoading,
        isUninitialized
      } = _ref3;
      return {
        eligibilityData: data,
        isLoadingEligibility: isUninitialized || isLoading
      };
    }
  });
  const getProgressConfig = useMemo(() => {
    if (!offering || ![OfferingStatus.open, OfferingStatus.preoffering].includes(offering.status)) {
      return skipToken;
    }
    return {
      key: offering.urlHash,
      noteUrlHashes: [offering.urlHash]
    };
  }, [offering]);
  const {
    data: progress
  } = useGetProgressQuery(getProgressConfig);
  const {
    rolloverDataFetched,
    isError: isErrorRollover,
    rolloverInvestmentIds
  } = useGetRollover();
  const {
    status,
    remainingInvestment,
    rawPercentageRemaining
  } = useMemo(() => {
    if (!progress) {
      return {
        status: offering.status,
        remainingInvestment: null,
        rawPercentageRemaining: null
      };
    }
    const offeringProgress = progress[offering.urlHash];
    const status = (offeringProgress === null || offeringProgress === void 0 ? void 0 : offeringProgress.offeringStatus) || (offering === null || offering === void 0 ? void 0 : offering.status);
    return {
      status,
      remainingInvestment: offeringProgress === null || offeringProgress === void 0 ? void 0 : offeringProgress.remainingInvestment,
      rawPercentageRemaining: (offeringProgress === null || offeringProgress === void 0 ? void 0 : offeringProgress.remainingInvestment) / (offeringProgress === null || offeringProgress === void 0 ? void 0 : offeringProgress.noteAmount)
    };
  }, [progress, offering]);
  const gate = useGate({
    eligibilityData
  });
  useEffect(() => {
    if (!loggedIn || !rolloverDataFetched && !isErrorRollover) {
      return;
    }
    getEligibility({
      investments: [{
        urlHash: offering.urlHash,
        amount: 0 // Remove when this is handled from BE [sc-115087]
      }],
      rolloverInvestmentIds
    });
  }, [offering, loggedIn, rolloverDataFetched, isErrorRollover, rolloverInvestmentIds, getEligibility]);
  if (isOpenAdvice) {
    return __jsx(DockedHero, {
      offering: offering,
      show: true,
      loggedIn: loggedIn,
      sticky: true,
      status: status,
      thumbnailImageUrl: offering === null || offering === void 0 ? void 0 : offering.thumbnailImageUrl
    });
  }
  if (isOpenRollover) {
    return __jsx(RolloverDockedHero, {
      rollover: rollover,
      offering: offering,
      status: status,
      loggedIn: loggedIn
    });
  }
  return __jsx(OfferingHeroV5, {
    offering: offering,
    loggedIn: loggedIn,
    history: history,
    gate: gate,
    pastMetric: metrics,
    status: status,
    rawPercentage: rawPercentageRemaining,
    remainingInvestment: remainingInvestment,
    offeringEligibilityFetched: !isLoadingEligibility,
    isLoadingPercentage: !(progress !== null && progress !== void 0 && progress[offering === null || offering === void 0 ? void 0 : offering.urlHash]),
    isFooterInView: isFooterInView
  });
};
export default OfferingHeroContainer;