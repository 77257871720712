import styled, { css } from 'styled-components';
import { media, spacing } from '../../themes';
export const TextContainer = styled.div.withConfig({
  displayName: "TextBlockstyles__TextContainer",
  componentId: "sc-90frsn-0"
})(["display:flex;flex-direction:column;justify-content:center;align-items:", ";text-align:", ";", ""], props => props.mobileAlign || props.align, props => props.mobileAlign || props.align, media.desktop`
    align-items: ${props => props.align};
    text-align: ${props => props.align};
  `);
export const ParagraphContainer = styled.div.withConfig({
  displayName: "TextBlockstyles__ParagraphContainer",
  componentId: "sc-90frsn-1"
})(["p{color:", ";margin-bottom:", ";}"], props => props.inverse ? props.theme.colors.text_secondary_paragraph_inverse : props.theme.colors.text_secondary_paragraph, spacing.sm);
export const HeadingContainer = styled.div.withConfig({
  displayName: "TextBlockstyles__HeadingContainer",
  componentId: "sc-90frsn-2"
})(["", ""], _ref => {
  let {
    compact
  } = _ref;
  return compact ? css(["margin-bottom:", ";margin-top:", ";"], spacing.s, spacing.s) : css(["margin-bottom:", ";margin-top:", ";"], spacing.sm, spacing.sm);
});
export const ButtonContainer = styled.div.withConfig({
  displayName: "TextBlockstyles__ButtonContainer",
  componentId: "sc-90frsn-3"
})(["margin-top:", ";"], props => props.compact ? spacing.xs : spacing.sm);
export const StatsContainer = styled.div.withConfig({
  displayName: "TextBlockstyles__StatsContainer",
  componentId: "sc-90frsn-4"
})(["display:flex;column-gap:", ";justify-content:space-between;margin-top:", ";margin-bottom:", ";"], spacing.ml, spacing.sm, spacing.s);
export const StatItem = styled.div.withConfig({
  displayName: "TextBlockstyles__StatItem",
  componentId: "sc-90frsn-5"
})(["display:flex;flex-direction:column;gap:", ";"], spacing.xs);