export const MuiTypography = {
  styleOverrides: {
    root: _ref => {
      let {
        ownerState
      } = _ref;
      return {
        variants: [{
          props: {
            inverse: true
          },
          style: _ref2 => {
            var _theme$palette, _theme$palette2, _ownerState$color;
            let {
              theme
            } = _ref2;
            return {
              //@ts-ignore
              color: (_theme$palette = theme.palette) === null || _theme$palette === void 0 ? void 0 : (_theme$palette2 = _theme$palette[(_ownerState$color = ownerState === null || ownerState === void 0 ? void 0 : ownerState.color) !== null && _ownerState$color !== void 0 ? _ownerState$color : 'primary']) === null || _theme$palette2 === void 0 ? void 0 : _theme$palette2.contrastText
            };
          }
        }]
      };
    }
  }
};