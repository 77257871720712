import _AlertTitle from "@mui/material/AlertTitle";
import _Alert from "@mui/material/Alert";
import _Link from "@mui/material/Link";
var __jsx = React.createElement;
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useProviderMigration } from '@yieldstreet/tool-kit';
import { investorEntityApi, InvestorEntityType } from '@yieldstreet/platform-kit';
import { RouterLink } from '@yieldstreet/ui-kit/navigation';
import { useBankAccounts } from 'pages/investorEntity/screens/Management/routes/BankAccounts/hooks/useBankAccounts';
import { useBankLinkingModal } from 'pages/bankLinking';
import useEmailVerification from 'pages/accountSettings/shared/hooks/useEmailVerification';
import { useProviderInvestorEntity } from 'pages/providers/sdira/hooks';
import { PROVIDER_SDIRA_CREATE_INVESTOR_ACCOUNT } from 'pages/providers/sdira/SDIRACreateInvestorEntity';
import { KYCMigrationNotification } from 'pages/investorEntity/screens/KYC/migration';
import { getLinkBankRoute } from './PostNavigationContent.model';
import { ThirdPartyProviderType } from '../../../utils/auth';
export const PostNavigationContent = () => {
  const history = useHistory();
  const {
    isMigrationEligible
  } = useProviderMigration();
  const {
    showNotification,
    title,
    description,
    success,
    resendEmail
  } = useEmailVerification();
  const {
    provider,
    isLoading,
    createBlocked
  } = useProviderInvestorEntity();
  const {
    data: {
      entities = []
    } = {}
  } = investorEntityApi.useGetInvestorEntityQuery();
  const entityId = useMemo(() => {
    var _entities$find;
    return entities === null || entities === void 0 ? void 0 : (_entities$find = entities.find(entity => entity.type.name === InvestorEntityType.INDIVIDUAL)) === null || _entities$find === void 0 ? void 0 : _entities$find.id;
  }, [entities]);
  const {
    degradedBankAccounts = [],
    refetchEntityBankAccounts
  } = useBankAccounts(entityId);
  const isDegradedAccountDefault = useMemo(() => degradedBankAccounts === null || degradedBankAccounts === void 0 ? void 0 : degradedBankAccounts.find(degradedBankAccount => degradedBankAccount.defaultAccount), [degradedBankAccounts]);
  const bankLinkingModal = useBankLinkingModal({
    investorEntityId: entityId,
    onSuccess: () => {
      refetchEntityBankAccounts();
    }
  });
  if (entityId && isDegradedAccountDefault) {
    return __jsx(React.Fragment, null, __jsx(_Alert, {
      severity: "warning"
    }, "Your preferred bank account connection needs to be refreshed.", ' ', __jsx(_Link, {
      onClick: () => {
        history.push(getLinkBankRoute(entityId));
        bankLinkingModal.open();
      }
    }, "Reconnect your account now"), ' ', "to continue receiving payments and funding investment requests."));
  }
  if (isMigrationEligible) {
    return __jsx(KYCMigrationNotification, null);
  }

  // SDIRA user hasn't created their investor account yet.
  if (provider !== ThirdPartyProviderType.CADRE && !createBlocked && !isLoading) {
    return __jsx(_Alert, {
      severity: "warning"
    }, __jsx(_AlertTitle, null, "Action Needed"), "Finalize connecting your Equity Trust account to Yieldstreet.\xA0", __jsx(RouterLink, {
      id: "sdira-complete-investor-account",
      "data-cy": "sdira-complete-investor-account",
      to: PROVIDER_SDIRA_CREATE_INVESTOR_ACCOUNT
    }, "Continue"));
  }

  // User hasn't verified their email yet.
  if (showNotification) {
    return __jsx(_Alert, {
      severity: "warning"
    }, __jsx(_AlertTitle, null, title), description, "\xA0", !success ? __jsx(React.Fragment, null, __jsx(_Link, {
      id: "resend-verification-email",
      "data-cy": "resend-verification-email",
      onClick: () => resendEmail()
    }, "Resend verification.")) : null);
  }
  return null;
};