import { ArticleType, wordpressApi } from '@yieldstreet/platform-kit';
export const consentDisclosureText = `I have read and accepted the [Electronic Disclosure and Consent](/electronic-disclosure-and-consent), [Privacy Policy](/privacy) and [Terms of Service](/terms) and I consent to the use of electronic records in connection with this application.`;
export const useConsentDisclosureVersions = () => {
  const {
    useWpOfferingArticlesQuery
  } = wordpressApi;
  const getVersion = data => {
    var _data$wpArticle, _data$wpArticle$_link, _data$wpArticle$_link2, _data$wpArticle2;
    const count = (data === null || data === void 0 ? void 0 : (_data$wpArticle = data.wpArticle) === null || _data$wpArticle === void 0 ? void 0 : (_data$wpArticle$_link = _data$wpArticle._links) === null || _data$wpArticle$_link === void 0 ? void 0 : (_data$wpArticle$_link2 = _data$wpArticle$_link['version-history'][0]) === null || _data$wpArticle$_link2 === void 0 ? void 0 : _data$wpArticle$_link2.count) || 0;
    const documentVersion = (data === null || data === void 0 ? void 0 : (_data$wpArticle2 = data.wpArticle) === null || _data$wpArticle2 === void 0 ? void 0 : _data$wpArticle2.ys_version_tag) || 0;
    return `v${documentVersion}-rev.${count}`;
  };
  const {
    isLoading: isLoadingDisclosure,
    data: disclosureData
  } = useWpOfferingArticlesQuery({
    articleSlug: 'electronic-disclosure-and-consent',
    postType: ArticleType.Pages
  });
  const {
    isLoading: isLoadingTerms,
    data: termsData
  } = useWpOfferingArticlesQuery({
    articleSlug: 'terms',
    postType: ArticleType.Pages
  });
  const {
    isLoading: isLoadingPrivacy,
    data: privacyData
  } = useWpOfferingArticlesQuery({
    articleSlug: 'privacy',
    postType: ArticleType.Pages
  });
  const versions = {
    disclosure: getVersion(disclosureData),
    terms: getVersion(termsData),
    privacy: getVersion(privacyData)
  };
  return {
    isLoading: isLoadingDisclosure && isLoadingTerms && isLoadingPrivacy,
    versions,
    stringifiedVersions: JSON.stringify(versions)
  };
};