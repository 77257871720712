var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';
import { LinkStyles } from '@yieldstreet/ui-kit';
export const Link = styled(RouterLink).withConfig({
  displayName: "Links__Link",
  componentId: "sc-zvfq65-0"
})(["", ""], LinkStyles);
export const renderLink = _ref => {
  let {
    navItem,
    raw,
    inverse
  } = _ref;
  const {
    id,
    title,
    route,
    callback
  } = navItem;
  const onClick = callback && (() => callback(navItem));
  return raw ? __jsx(RouterLink, {
    id: id,
    "data-cy": id,
    to: route,
    onClick: onClick
  }, title) : __jsx(Link, {
    id: id,
    "data-cy": id,
    to: route,
    onClick: onClick,
    inverse: inverse
  }, title);
};