import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { ActionIcon, useTestIds } from './ActionCell.model';
import { ActionButton, ActionContent } from './ActionCell.style';

/**
 * @todo This component will be eventually migrated to MUI Chip
 */
export const ActionCell = props => {
  const {
    label,
    type,
    showIcon,
    onClick
  } = props;
  const testIds = useTestIds(props);
  return __jsx(ActionButton, _extends({}, testIds.component.attr, {
    type: type,
    onClick: onClick
  }), __jsx(ActionContent, testIds.base.attr, __jsx("span", testIds.label.attr, label), showIcon ? ActionIcon(type) : null));
};