import { SnapshotInvestmentStatus } from '../../enums';
export const portfolioSnapshot = {
  userId: 62857,
  investments: [{
    noteUrlHash: 'LJouqg',
    title: 'Yieldstreet Prism Fund',
    date: '2022-01-21',
    value: 52283.0,
    status: SnapshotInvestmentStatus.active,
    assetClass: {
      name: 'FUND',
      description: 'Multi-Asset Class Fund'
    }
  }, {
    noteUrlHash: 'f9oXxg',
    title: 'Supply Chain Financing I',
    date: '2022-02-01',
    value: 35000.0,
    status: SnapshotInvestmentStatus.active,
    assetClass: {
      name: 'COML',
      description: 'Commercial'
    }
  }, {
    noteUrlHash: 'Of-K0w',
    title: 'Short Term Note Series XIV',
    date: '2022-03-30',
    status: SnapshotInvestmentStatus.active,
    value: 35000.0,
    assetClass: {
      name: 'SHTN',
      description: 'Short Term Note'
    }
  }, {
    noteUrlHash: 'V5Icyw',
    title: 'Kings Mountain Industrial Property Financing',
    date: '2022-01-07',
    status: SnapshotInvestmentStatus.active,
    value: 10000.0,
    assetClass: {
      name: 'REAL',
      description: 'Real Estate'
    }
  }, {
    noteUrlHash: '07okvQ',
    title: 'Short Term Note Series XXII',
    date: '2022-03-21',
    status: SnapshotInvestmentStatus.active,
    value: 5200.0,
    assetClass: {
      name: 'SHTN',
      description: 'Short Term Note'
    }
  }],
  totalActiveInvestments: 3,
  totalMaturedInvestments: 2,
  activeWeightedReturn: 8.1,
  irr: 6.15
};