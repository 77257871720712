import styled, { css } from 'styled-components';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
export const SFormHelperText = styled(FormHelperText).withConfig({
  displayName: "formStyles__SFormHelperText",
  componentId: "sc-538od0-0"
})(["&.MuiFormHelperText-root{font-family:", ";font-size:12px;line-height:14px;&.Mui-error{color:", ";}&.MuiFormHelperText-contained{margin-top:3px;margin-left:12px;margin-right:0;}}"], props => props.theme.typography.secondary, _ref => {
  let {
    theme
  } = _ref;
  return theme.colors.notice_error;
});
export const LabelStyles = css(["color:", ";&.Mui-focused{color:", " !important;}&.Mui-error{color:", " !important;}&.Mui-disabled{color:", ";}&.Mui-focused{color:", ";}font-family:", " !important;line-height:1.8;"], _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.colors.text_label;
}, _ref3 => {
  let {
    theme
  } = _ref3;
  return theme.colors.text_label;
}, _ref4 => {
  let {
    theme
  } = _ref4;
  return theme.colors.notice_error;
}, props => props.theme.colors.input_disabled_text, props => props.theme.colors.text_label, props => props.theme.typography.secondary);
export const SFormControl = styled(FormControl).withConfig({
  displayName: "formStyles__SFormControl",
  componentId: "sc-538od0-1"
})(["&.MuiFormControl-root{margin-bottom:10px;text-align:left !important;}"]);
export const SFormControlLabel = styled(FormControlLabel).withConfig({
  displayName: "formStyles__SFormControlLabel",
  componentId: "sc-538od0-2"
})(["& .MuiTypography-root{", "}"], LabelStyles);
export const SFormControlLabelRadio = styled(FormControlLabel).withConfig({
  displayName: "formStyles__SFormControlLabelRadio",
  componentId: "sc-538od0-3"
})(["& .MuiTypography-root{", " color:", ";}"], LabelStyles, _ref5 => {
  let {
    theme
  } = _ref5;
  return theme.colors.text_paragraph;
});
export const SFormLabel = styled(FormLabel).withConfig({
  displayName: "formStyles__SFormLabel",
  componentId: "sc-538od0-4"
})(["&.MuiFormLabel-root{", "}"], LabelStyles);
export const SFormGroup = styled(FormGroup).withConfig({
  displayName: "formStyles__SFormGroup",
  componentId: "sc-538od0-5"
})(["&[data-checkbox-group-options='true'],&[data-radio-group-options='true']{", " ", "}&.MuiFormGroup-root{padding-top:5px;& .MuiFormControl-root{margin-bottom:0;}}"], _ref6 => {
  let {
    justify
  } = _ref6;
  return justify ? `justify-content: ${justify};` : '';
}, _ref7 => {
  let {
    direction
  } = _ref7;
  return direction ? `flex-direction: ${direction};` : '';
});