import styled from 'styled-components';
export const PageSheetWrapper = styled.div.withConfig({
  displayName: "PageSheetstyle__PageSheetWrapper",
  componentId: "sc-1ubc8o1-0"
})(["position:fixed;top:0;left:0;display:flex;flex-direction:column;width:100%;height:100%;background:", ";overflow:hidden;z-index:999;"], _ref => {
  let {
    theme
  } = _ref;
  return theme.colors.background_card;
});
export const PageSheetHeader = styled.header.withConfig({
  displayName: "PageSheetstyle__PageSheetHeader",
  componentId: "sc-1ubc8o1-1"
})(["display:flex;flex-direction:row;align-items:center;justify-content:space-between;width:100%;height:", ";padding:0 ", ";border-bottom:1px solid ", ";position:relative;"], _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.spacing['2xl'];
}, _ref3 => {
  let {
    theme
  } = _ref3;
  return theme.spacing.m;
}, _ref4 => {
  let {
    theme
  } = _ref4;
  return theme.colors.border_default;
});
export const PageSheetContent = styled.div.withConfig({
  displayName: "PageSheetstyle__PageSheetContent",
  componentId: "sc-1ubc8o1-2"
})(["flex:1;overflow-y:auto;"]);