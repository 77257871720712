import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useMemo, useCallback, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { FieldArray } from 'formik';
import { Button, PageContainer, PageStep, PageStepContentTemplate } from '@yieldstreet/ui-kit';
import { investorEntityApi } from '@yieldstreet/platform-kit';
import { Link } from 'sharedComponents/Links/Links';
import { FormWrapper, ButtonContainer } from '../../../CreateInvestorAccount.style';
import { RenderFlowWrapper } from '../KYC.utils';
import { AddButton } from '../Affiliation/screens/ControlCorporations/ControlCorporations.style';
import { CitizenshipSelector } from './CitizenshipSelector/CitizenshipSelector';
import { CITIZEN, NON_RESIDENT, US_COUNTRY, testIds } from './Citizenship.model';
import { formValidation, getPageStepContent, sortCountries } from './Citizenship.utils';
const {
  useGetInvestorEntityQuery,
  useUpdateInvestorEntityMutation
} = investorEntityApi;
export const Citizenship = _ref => {
  var _entitiesResponse$ent;
  let {
    flowConfig
  } = _ref;
  const {
    investorEntityId
  } = useParams();
  const history = useHistory();
  const {
    0: showForm,
    1: setShowForm
  } = useState(false);
  const [updateInvestorEntity, updateInvestorEntityResult] = useUpdateInvestorEntityMutation();
  const {
    data: entitiesResponse,
    isLoading: isLoadingInvestorEntity,
    isFetching: isFetchingInvestorEntity
  } = useGetInvestorEntityQuery();
  const entityData = entitiesResponse === null || entitiesResponse === void 0 ? void 0 : (_entitiesResponse$ent = entitiesResponse.entities) === null || _entitiesResponse$ent === void 0 ? void 0 : _entitiesResponse$ent.find(entity => entity.id.toString() === investorEntityId);
  const isLoading = isFetchingInvestorEntity || updateInvestorEntityResult.isLoading;
  const renderForm = useCallback(formikProps => __jsx(FormWrapper, null, __jsx(FieldArray, {
    name: "citizenshipCountries"
  }, _ref2 => {
    let {
      remove,
      push
    } = _ref2;
    return __jsx("div", null, formikProps.values.citizenshipCountries.map((element, index) => __jsx(CitizenshipSelector, {
      key: `${index}-${element.country}`,
      formikProps: formikProps,
      index: index,
      remove: remove
    })), __jsx(AddButton, _extends({
      type: "button",
      onClick: event => {
        event.preventDefault();
        push({
          country: ''
        });
      }
    }, testIds.addButton.attr), __jsx(Link, null, "Add another country")), __jsx(ButtonContainer, null, __jsx(Button, _extends({
      key: "submitButton",
      type: "submit",
      fullWidth: true,
      loading: updateInvestorEntityResult.isLoading,
      disabled: isLoading
    }, testIds.submitButton.attr), "Continue")));
  })), [isLoading, updateInvestorEntityResult.isLoading]);
  const submitCallback = useCallback(values => {
    const hasUSCountry = values.citizenshipCountries.find(citizenshipCountry => citizenshipCountry.country === US_COUNTRY);
    const countries = values.citizenshipCountries.map(filter => filter.country);
    updateInvestorEntity({
      investorEntityId,
      countryOfCitizenship: countries,
      residenceStatus: hasUSCountry ? CITIZEN : NON_RESIDENT
    });
  }, [investorEntityId, updateInvestorEntity]);
  const handleInitialValues = useCallback(() => {
    if (entityData !== null && entityData !== void 0 && entityData.countryOfCitizenship) {
      return sortCountries(entityData.countryOfCitizenship);
    } else return [{
      country: US_COUNTRY
    }];
  }, [entityData === null || entityData === void 0 ? void 0 : entityData.countryOfCitizenship]);
  const formResidenceConfig = useMemo(() => ({
    validation: formValidation,
    formikConfig: {
      initialValues: {
        citizenshipCountries: handleInitialValues()
      },
      enableReinitialize: false
    }
  }), [handleInitialValues]);
  if (!showForm) {
    return __jsx(PageContainer, null, __jsx(PageStep, null, __jsx(PageStepContentTemplate, getPageStepContent({
      onClick: () => setShowForm(true)
    }))));
  }
  return __jsx(RenderFlowWrapper, {
    formConfig: formResidenceConfig,
    flowConfig: flowConfig,
    submitConfig: updateInvestorEntityResult,
    submitCallback: submitCallback,
    isLoading: isLoadingInvestorEntity,
    testIds: testIds,
    history: history
  }, formikProps => renderForm(formikProps));
};