import styled from 'styled-components';
import { media, Label, Heading, Paragraph, Link, Button, LabelStyleSmall } from '@yieldstreet/ui-kit';
export const Image = styled.div.withConfig({
  displayName: "OfferingHeroV5style__Image",
  componentId: "sc-1sbvjfp-0"
})(["--alpha:0.69;", " background:linear-gradient( 0deg,rgba(0,0,0,var(--alpha)),rgba(0,0,0,var(--alpha)) ),url(", ") center/cover;display:flex;min-height:440px;"], media.tablet`
    --alpha: 0.8;
`, props => props.backgroundImage);
export const ContentWrapper = styled.div.withConfig({
  displayName: "OfferingHeroV5style__ContentWrapper",
  componentId: "sc-1sbvjfp-1"
})(["max-width:1180px;margin:20px auto;display:flex;justify-content:space-between;width:100%;padding:0 20px;flex-wrap:wrap;", ";", ";"], media.tablet`
  padding: 0 35px;
  flex-wrap: nowrap;
`, media.large`
  padding: 0;
`);
export const DetailsWrapper = styled.div.withConfig({
  displayName: "OfferingHeroV5style__DetailsWrapper",
  componentId: "sc-1sbvjfp-2"
})(["max-width:760px;margin:auto 0;"]);
export const AssetClassOriginatorWrapper = styled.div.withConfig({
  displayName: "OfferingHeroV5style__AssetClassOriginatorWrapper",
  componentId: "sc-1sbvjfp-3"
})(["max-height:500px;overflow:hidden;margin-bottom:10px;", ";"], media.tablet`
  margin-bottom: 20px;
`);
export const WhiteLabel = styled(Label).withConfig({
  displayName: "OfferingHeroV5style__WhiteLabel",
  componentId: "sc-1sbvjfp-4"
})(["color:", " !important;"], props => props.theme.colors.text_paragraph_inverse);
export const Dot = styled.span.withConfig({
  displayName: "OfferingHeroV5style__Dot",
  componentId: "sc-1sbvjfp-5"
})(["color:", ";margin:0 9px;"], props => props.theme.colors.text_paragraph_inverse);
export const DockedHeroDot = styled(Dot).withConfig({
  displayName: "OfferingHeroV5style__DockedHeroDot",
  componentId: "sc-1sbvjfp-6"
})(["color:", ";display:block;", ";"], props => props.theme.colors.text_label_inverse, media.desktop`
  display: none;
`);
export const TitleAndMetricsWrapper = styled.div.withConfig({
  displayName: "OfferingHeroV5style__TitleAndMetricsWrapper",
  componentId: "sc-1sbvjfp-7"
})(["display:flex;flex-direction:column;justify-content:space-between;"]);
export const Title = styled(Heading).withConfig({
  displayName: "OfferingHeroV5style__Title",
  componentId: "sc-1sbvjfp-8"
})(["", ";", ""], media.tablet`
  margin-bottom: 10px;
  width: 416px;
`, media.desktop`
  margin-bottom: 20px;
  width: 100%;
`);
export const MetricsWrapper = styled.div.withConfig({
  displayName: "OfferingHeroV5style__MetricsWrapper",
  componentId: "sc-1sbvjfp-9"
})(["--component-spacing:", ";display:flex;flex-direction:column;gap:var(--component-spacing);flex-wrap:wrap;margin-bottom:var(--component-spacing);", ";", ";"], props => props.hasTree ? 'unset' : '30px', media.tablet`
    margin-bottom: 0;
  `, media.desktop`
    display: flex;
    flex-wrap: nowrap;
    flex-direction: row;
    gap: 10px;
  `);
export const MetricsContent = styled.div.withConfig({
  displayName: "OfferingHeroV5style__MetricsContent",
  componentId: "sc-1sbvjfp-10"
})([""]);
export const MetricsInnerContent = styled.div.withConfig({
  displayName: "OfferingHeroV5style__MetricsInnerContent",
  componentId: "sc-1sbvjfp-11"
})(["display:flex;align-items:center;", ";"], media.desktop`
  display: unset;
`);
export const StatusWrapper = styled.div.withConfig({
  displayName: "OfferingHeroV5style__StatusWrapper",
  componentId: "sc-1sbvjfp-12"
})(["display:flex;flex-direction:column;align-self:center;width:100%;", ";"], media.tablet`
  width: 340px;
`);
export const StatusBox = styled.div.withConfig({
  displayName: "OfferingHeroV5style__StatusBox",
  componentId: "sc-1sbvjfp-13"
})(["height:67px;width:100%;background-color:", ";display:flex;justify-content:space-between;border-bottom:", ";.offering-hero-v5-status{white-space:nowrap;}", ";"], props => props.theme.colors.background_inverse_light, props => props.statusMessage ? `none` : `4px solid ${props.theme.colors[props.barColor]}`, media.tablet`
  height: 93px;
`);
export const Status = styled(Label).withConfig({
  displayName: "OfferingHeroV5style__Status",
  componentId: "sc-1sbvjfp-14"
})(["color:", " !important;"], props => props.theme.colors.input_disabled_text);
export const StatusMessage = styled.div.withConfig({
  displayName: "OfferingHeroV5style__StatusMessage",
  componentId: "sc-1sbvjfp-15"
})(["width:100%;height:40px;padding:14px 0 10px 20px;font-size:14px;background-color:", ";"], props => props.theme.colors[props.barColor]);
export const TextWrapper = styled.div.withConfig({
  displayName: "OfferingHeroV5style__TextWrapper",
  componentId: "sc-1sbvjfp-16"
})(["padding:10px 20px;", ";"], media.tablet`
  padding: 20px;
`);
export const RemainingBox = styled.div.withConfig({
  displayName: "OfferingHeroV5style__RemainingBox",
  componentId: "sc-1sbvjfp-17"
})(["height:100%;width:109px;border-left:1px solid ", ";"], props => props.theme.colors.border_strong);
export const InvestButton = styled(Button).withConfig({
  displayName: "OfferingHeroV5style__InvestButton",
  componentId: "sc-1sbvjfp-18"
})(["width:100%;text-align:left;white-space:nowrap;align-self:flex-end;margin-top:", ";"], props => props.topMargin && '15px');
export const MinMaxInvestment = styled(Paragraph).withConfig({
  displayName: "OfferingHeroV5style__MinMaxInvestment",
  componentId: "sc-1sbvjfp-19"
})(["display:block;margin-top:10px;text-align:center;color:", " !important;"], props => props.theme.colors.text_label_inverse);
export const LoginParagraph = styled(Paragraph).withConfig({
  displayName: "OfferingHeroV5style__LoginParagraph",
  componentId: "sc-1sbvjfp-20"
})(["text-align:center;margin-top:25px;"]);
export const PastMetricsContainer = styled.div.withConfig({
  displayName: "OfferingHeroV5style__PastMetricsContainer",
  componentId: "sc-1sbvjfp-21"
})(["display:", ";background-color:", ";"], props => props.isPastMetric ? 'block' : 'none', props => props.theme.colors.background_inverse_light);
export const ActiveMetrics = styled.div.withConfig({
  displayName: "OfferingHeroV5style__ActiveMetrics",
  componentId: "sc-1sbvjfp-22"
})(["margin-top:10px;"]);
export const FullyRepaidMetrics = styled(ActiveMetrics).withConfig({
  displayName: "OfferingHeroV5style__FullyRepaidMetrics",
  componentId: "sc-1sbvjfp-23"
})([""]);
export const PastMetrics = styled.div.withConfig({
  displayName: "OfferingHeroV5style__PastMetrics",
  componentId: "sc-1sbvjfp-24"
})(["display:block;justify-content:space-around;text-align:center;"]);
export const FundAnchor = styled.span.withConfig({
  displayName: "OfferingHeroV5style__FundAnchor",
  componentId: "sc-1sbvjfp-25"
})(["cursor:pointer;", " padding:0 0 0 8px;background:none;color:", " !important;vertical-align:super;"], LabelStyleSmall, props => props.theme.colors.text_paragraph_inverse);
export const TitleBadgeWrapper = styled.div.withConfig({
  displayName: "OfferingHeroV5style__TitleBadgeWrapper",
  componentId: "sc-1sbvjfp-26"
})(["display:flex;align-items:center;margin-bottom:20px;.badge{margin:0 5px 0 5px;}", ""], media.tablet`
  margin-bottom: 0;
  .badge {
    display: none;
  }
`);
export const SpinnerWrapper = styled.div.withConfig({
  displayName: "OfferingHeroV5style__SpinnerWrapper",
  componentId: "sc-1sbvjfp-27"
})(["padding-top:8px;"]);
export const LargeSpinnerWrapper = styled.div.withConfig({
  displayName: "OfferingHeroV5style__LargeSpinnerWrapper",
  componentId: "sc-1sbvjfp-28"
})(["margin-top:20px;height:60px;"]);
export const ReservedMessage = styled.div.withConfig({
  displayName: "OfferingHeroV5style__ReservedMessage",
  componentId: "sc-1sbvjfp-29"
})(["width:100%;border-radius:2px;display:flex;flex-direction:column;background-color:", ";margin-top:20px;padding:15px 20px;"], props => props.backgroundColor ? props.theme.colors[props.backgroundColor] : props.theme.colors.background_inverse_secondary);
export const ReservedTitle = styled.div.withConfig({
  displayName: "OfferingHeroV5style__ReservedTitle",
  componentId: "sc-1sbvjfp-30"
})(["display:flex;flex-direction:row;margin-bottom:10px;"]);
export const Lock = styled.img.withConfig({
  displayName: "OfferingHeroV5style__Lock",
  componentId: "sc-1sbvjfp-31"
})(["margin-right:10px;"]);
export const LiquidityWrapper = styled.div.withConfig({
  displayName: "OfferingHeroV5style__LiquidityWrapper",
  componentId: "sc-1sbvjfp-32"
})(["display:flex;margin-top:12px;", ";", ";", ";"], media.mobile`
  position: unset;
  max-width: unset;
`, media.tablet`
  position: unset;
  max-width: unset;
`, media.desktop`
  position: absolute;
  max-width: 34%;
`);
export const LiquidityContent = styled.div.withConfig({
  displayName: "OfferingHeroV5style__LiquidityContent",
  componentId: "sc-1sbvjfp-33"
})(["display:flex;align-items:center;margin-top:6px;margin-bottom:9px;border-left:2px solid ", ";"], props => props.theme.colors.border_default);
export const LiquidityDescriptionLabel = styled(Paragraph).withConfig({
  displayName: "OfferingHeroV5style__LiquidityDescriptionLabel",
  componentId: "sc-1sbvjfp-34"
})(["padding-left:10px;"]);
export const QuestionTip = styled.img.withConfig({
  displayName: "OfferingHeroV5style__QuestionTip",
  componentId: "sc-1sbvjfp-35"
})(["cursor:pointer;margin:8px 0 0 12px;align-self:baseline;"]);
export const HiddenValue = styled.img.withConfig({
  displayName: "OfferingHeroV5style__HiddenValue",
  componentId: "sc-1sbvjfp-36"
})(["", ";"], media.desktop`
  object-position: unset;
  margin-bottom: 0;
`);
export const MarketingRulesWrapper = styled.div.withConfig({
  displayName: "OfferingHeroV5style__MarketingRulesWrapper",
  componentId: "sc-1sbvjfp-37"
})(["max-width:386px;margin:0 0 ", ";"], props => props.theme.spacing.xs);
export const MarketingRulesLabel = styled(Label).withConfig({
  displayName: "OfferingHeroV5style__MarketingRulesLabel",
  componentId: "sc-1sbvjfp-38"
})(["color:", " !important;"], props => props.theme.colors.text_paragraph_inverse);
export const MarketingRulesLink = styled(Link).withConfig({
  displayName: "OfferingHeroV5style__MarketingRulesLink",
  componentId: "sc-1sbvjfp-39"
})(["box-shadow:", " !important;color:", " !important;background-image:", ";"], props => props.theme.colors.text_paragraph_inverse, props => props.theme.colors.text_paragraph_inverse, props => `linear-gradient( to right, ${props.theme.colors.text_paragraph_inverse} 45%, transparent 45%, transparent 55%, ${props.theme.colors.text_paragraph_inverse} 55% ) !important`);
export const DockedMetricValue = styled(Paragraph).withConfig({
  displayName: "OfferingHeroV5style__DockedMetricValue",
  componentId: "sc-1sbvjfp-40"
})(["display:none;", ";"], media.desktop`
    display: block;
  `);
export const DockedMetricValueMobile = styled(Label).withConfig({
  displayName: "OfferingHeroV5style__DockedMetricValueMobile",
  componentId: "sc-1sbvjfp-41"
})(["margin-left:8px;display:block;", ";"], media.desktop`
    display: none;
  `);
export const QuarterWidthButton = styled(Button).withConfig({
  displayName: "OfferingHeroV5style__QuarterWidthButton",
  componentId: "sc-1sbvjfp-42"
})(["width:162px;height:48px;padding:0px;margin-top:110px;min-width:162px;"]);
export const AnimationDuration = 200;
export const GradientBg = styled.div.withConfig({
  displayName: "OfferingHeroV5style__GradientBg",
  componentId: "sc-1sbvjfp-43"
})(["background:linear-gradient(180deg,rgba(255,255,255,0) 15.46%,#ffffff 88.17%);height:200px;width:100%;position:", ";bottom:0px;z-index:25;display:flex;align-items:center;justify-content:space-between;margin-left:-20px;padding-left:29px;&.fade-enter,&.fade-appear{opacity:0;}&.fade-enter-active,&.fade-appear-active{opacity:1;transition:opacity ", "ms;}&.fade-exit{opacity:1;}&.fade-exit-active{opacity:0;transition:opacity ", "ms;}"], props => props.sticky ? 'sticky' : 'fixed', AnimationDuration, AnimationDuration);