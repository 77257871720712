import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["investorEntities", "investorEntityStatuses"],
  _excluded2 = ["data"];
import { useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { InvestorEntityStepProvider, InvestorEntityStepVerificationStatus, allToken, investorEntityStatusApi } from '@yieldstreet/platform-kit';
import { useAllInvestorEntities } from './useAllInvestorEntities';
import { useApiQueryPollCheck } from '../../async-effects';
import { useAuth } from '../../auth';
const buildInvestorEntityStatus = (investorEntities, investorEntityStatuses) => investorEntities.map(investorEntity => ({
  investorEntity,
  investorEntityStatus: investorEntityStatuses[investorEntity.id]
}));
export const getInvestorEntity = (investorEntityId, investorEntities, investorEntityStatuses) => {
  return buildInvestorEntityStatus(investorEntities, investorEntityStatuses).find(_ref => {
    let {
      investorEntity
    } = _ref;
    return Number(investorEntity.id) === Number(investorEntityId);
  });
};
export const getInvestorEntityByInvestorAccountId = (investorAccountId, investorEntities) => investorEntities.find(entity => entity.investorAccounts.find(acct => Number(acct.id) === Number(investorAccountId)));
export const useInvestorEntity = investorEntityId => {
  const allInvestorsState = useAllInvestorEntities();
  return useMemo(() => {
    const {
        investorEntities,
        investorEntityStatuses
      } = allInvestorsState,
      allState = _objectWithoutProperties(allInvestorsState, _excluded);
    const result = investorEntities && investorEntityStatuses && getInvestorEntity(investorEntityId, investorEntities, investorEntityStatuses);
    return _objectSpread(_objectSpread({}, result), allState);
  }, [allInvestorsState, investorEntityId]);
};
export const useInvestorEntityStatusPollCheck = (investorEntityId, statusCheckCallback, skipAutoCheck) => {
  const {
    isAuthenticated
  } = useAuth();
  const token = isAuthenticated ? allToken : skipToken;
  const investorEntityStatusesResult = investorEntityStatusApi.useGetAllInvestorEntityStatusQuery(token);
  const _useApiQueryPollCheck = useApiQueryPollCheck(investorEntityStatusesResult, _ref2 => {
      let {
        [investorEntityId]: investorEntityStatus
      } = _ref2;
      return investorEntityStatus && statusCheckCallback(investorEntityStatus);
    }, skipAutoCheck),
    {
      data: investorEntityStatuses
    } = _useApiQueryPollCheck,
    pollCheckState = _objectWithoutProperties(_useApiQueryPollCheck, _excluded2);
  const {
    [investorEntityId]: investorEntityStatus
  } = investorEntityStatuses || {};
  return _objectSpread({
    data: investorEntityStatus
  }, pollCheckState);
};
export const isInvestorEntityStatusActionable = _ref3 => {
  let {
    provider,
    status
  } = _ref3;
  return [InvestorEntityStepVerificationStatus.ACTION_NEEDED, InvestorEntityStepVerificationStatus.MISSING].includes(status) ||
  // very very very dirty and temporary synapse hack
  provider === InvestorEntityStepProvider.YS && status === InvestorEntityStepVerificationStatus.FAILED;
};