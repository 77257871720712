import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useRef } from 'react';
import isEmpty from "lodash/isEmpty";
import { StepProgressBar } from '../../elements/step-progress-bar';
import { Button, Heading, Label, Paragraph } from '../../content';
import { BarLabelWrapper, BarWrapper, ButtonWrapper, ContentWrapepr, HeadingContainer, PageStepContainer, ScrollContent } from './PageStep.style';
import { pageStepTestIds } from './PageStep.model';
export const PageStepContent = _ref => {
  var _cta$type;
  let {
    title,
    description,
    cta
  } = _ref;
  return __jsx(ContentWrapepr, null, __jsx(HeadingContainer, null, __jsx(Heading, _extends({
    type: 2
  }, pageStepTestIds.title.attr), title)), __jsx(Paragraph, pageStepTestIds.description.attr, description), !isEmpty(cta) && __jsx(ButtonWrapper, null, __jsx(Button, _extends({}, pageStepTestIds.cta.attr, {
    fullWidth: true,
    buttonType: (_cta$type = cta === null || cta === void 0 ? void 0 : cta.type) !== null && _cta$type !== void 0 ? _cta$type : 'primary',
    onClick: cta === null || cta === void 0 ? void 0 : cta.callback
  }), cta === null || cta === void 0 ? void 0 : cta.text)));
};
export const PageStepScroll = _ref2 => {
  let {
    step
  } = _ref2;
  const scrollRef = useRef();
  const hasStepItems = !isEmpty(step === null || step === void 0 ? void 0 : step.items);
  const hasStepText = !isEmpty(step === null || step === void 0 ? void 0 : step.text);
  return __jsx(React.Fragment, null, __jsx(ScrollContent, {
    ref: scrollRef
  }, __jsx(BarWrapper, null, hasStepItems && __jsx(StepProgressBar, _extends({}, pageStepTestIds.step.attr, {
    step: step === null || step === void 0 ? void 0 : step.current,
    items: step === null || step === void 0 ? void 0 : step.items,
    scrollRef: scrollRef
  })))), hasStepText && __jsx(BarLabelWrapper, null, __jsx(Label, pageStepTestIds.stepText.attr, step === null || step === void 0 ? void 0 : step.text)));
};
export const PageStepContentTemplate = _ref3 => {
  let {
    title,
    description,
    cta,
    step
  } = _ref3;
  return __jsx(React.Fragment, null, __jsx(PageStepContent, {
    title: title,
    description: description,
    cta: cta
  }), __jsx(PageStepScroll, {
    step: step
  }));
};
export const PageStep = _ref4 => {
  let {
    children
  } = _ref4;
  return __jsx(PageStepContainer, pageStepTestIds.container.attr, children);
};