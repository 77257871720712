import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["label", "value", "error", "options", "onChange", "onClick", "className", "caption", "disabled", "descriptionCheckedOnly", "justify", "direction", "testId"];
var __jsx = React.createElement;
import React from 'react';
import { withFormikWeb, withQuestion, SFormLabel, SFormGroup, SFormControl, SFormHelperText, FormError } from '../common';
import { RadioInput } from '../radio-input';
export const RadioGroupInput = props => {
  const {
      label,
      value,
      error,
      options,
      onChange,
      onClick,
      className,
      caption,
      disabled,
      descriptionCheckedOnly,
      justify,
      direction,
      testId
    } = props,
    otherProps = _objectWithoutProperties(props, _excluded);
  const handleChange = option => checked => {
    onChange && checked && onChange(option.value, option);
  };
  return __jsx(SFormControl, {
    "data-radio-group": true,
    error: !!error,
    className: className,
    disabled: disabled
  }, label && __jsx(SFormLabel, {
    "data-radio-group-label": true
  }, label), __jsx(SFormGroup, {
    "data-radio-group-options": true,
    justify: justify,
    direction: direction,
    "data-cy": testId
  }, options.map(option => __jsx(RadioInput, _extends({}, otherProps, {
    key: option.value,
    testId: `${testId}-option-${option.value}`,
    value: option.value === value,
    label: option.label,
    name: option.label,
    caption: option.caption,
    disabled: option.disabled || disabled,
    onChange: handleChange(option),
    onClick: e => onClick && onClick(e, option),
    description: option.description,
    descriptionCheckedOnly: descriptionCheckedOnly,
    image: option === null || option === void 0 ? void 0 : option.image,
    className: "radio-input"
  })))), error || caption ? __jsx(SFormHelperText, {
    margin: "dense"
  }, error ? __jsx(FormError, {
    testId: "radio-group-input-error",
    type: "radio-group-input",
    name: (props === null || props === void 0 ? void 0 : props.name) || 'radio-group',
    error: error
  }) : caption) : null);
};
export const FormRadioGroupInput = withFormikWeb(RadioGroupInput);
export const QuestionRadioGroupInput = withQuestion(RadioGroupInput, 'primary');
export const QuestionSecondaryRadioGroupInput = withQuestion(RadioGroupInput, 'secondary');
export const QuestionTertiaryRadioGroupInput = withQuestion(RadioGroupInput, 'tertiary');
export const FormikQuestionSecondaryRadioGroupInput = withFormikWeb(QuestionSecondaryRadioGroupInput);
export const FormQuestionRadioGroupInput = withFormikWeb(QuestionRadioGroupInput);