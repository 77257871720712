import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useMemo, useCallback, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { FormSelectInput, FormTextInput, Button } from '@yieldstreet/ui-kit';
import { investorEntityApi } from '@yieldstreet/platform-kit';
import FormAddressInputGroup from 'sharedComponents/FormAddressInputGroup';
import { FormWrapper, ButtonContainer } from '../../../CreateInvestorAccount.style';
import { RenderFlowWrapper } from '../KYC.utils';
import { employmentFormValidation, employmentInitialValues, isEmployed, isSelfEmployed } from './EmploymentInfo.utils';
import { employmentStatusOptions, industryOptions, occupationOptions, testIds } from './EmploymentInfo.model';
import { AddressInput } from './EmploymentInfo.style';
const {
  useGetInvestorEntityQuery,
  useUpdateInvestorEntityMutation
} = investorEntityApi;
export const EmploymentInfo = _ref => {
  var _entitiesResponse$ent;
  let {
    flowConfig
  } = _ref;
  const {
    investorEntityId
  } = useParams();
  const history = useHistory();
  const [updateInvestorEntity, updateInvestorEntityResult] = useUpdateInvestorEntityMutation();
  const {
    data: entitiesResponse,
    isLoading: isLoadingInvestorEntity,
    isFetching: isFetchingInvestorEntity
  } = useGetInvestorEntityQuery();
  const entityData = entitiesResponse === null || entitiesResponse === void 0 ? void 0 : (_entitiesResponse$ent = entitiesResponse.entities) === null || _entitiesResponse$ent === void 0 ? void 0 : _entitiesResponse$ent.find(entity => entity.id.toString() === investorEntityId);
  const {
    0: employmentStatus,
    1: setEmploymentStatus
  } = useState((entityData === null || entityData === void 0 ? void 0 : entityData.employmentStatus) || '');
  const isSelfEmployedOrEmployed = useMemo(() => isSelfEmployed(employmentStatus) || isEmployed(employmentStatus), [employmentStatus]);
  const isUserEmployed = isEmployed(employmentStatus);
  const isUserSelfEmployed = isSelfEmployed(employmentStatus);
  const isLoading = isFetchingInvestorEntity || isLoadingInvestorEntity || updateInvestorEntityResult.isLoading;
  const renderForm = useCallback(formikProps => __jsx(FormWrapper, {
    hasMarginTop: !isSelfEmployedOrEmployed
  }, __jsx(FormSelectInput, {
    label: "Employment status",
    placeholder: "Employment status",
    name: "employmentStatusInput",
    formikProps: formikProps,
    options: employmentStatusOptions,
    testId: testIds.employmentStatusField.id,
    onChange: value => setEmploymentStatus(value),
    fullWidth: true
  }), isSelfEmployedOrEmployed && __jsx(React.Fragment, null, __jsx(FormSelectInput, {
    label: "Industry",
    placeholder: "Industry",
    name: "employmentIndustry",
    formikProps: formikProps,
    options: industryOptions,
    testId: testIds.employmentIndustryField.id,
    fullWidth: true
  })), isUserEmployed && __jsx(React.Fragment, null, __jsx(FormTextInput, {
    type: "text",
    label: "Employer name",
    placeholder: "Employer name",
    name: "employerName",
    formikProps: formikProps,
    testId: testIds.employerNameField.id,
    fullWidth: true
  })), isSelfEmployedOrEmployed && __jsx(React.Fragment, null, __jsx(FormSelectInput, {
    label: "Your title at the company",
    placeholder: "Your title at the company",
    name: "employmentTitle",
    formikProps: formikProps,
    options: occupationOptions,
    testId: testIds.employmentTitleField.id,
    fullWidth: true
  })), isUserEmployed && __jsx(React.Fragment, null, __jsx(AddressInput, {
    "data-cy": testIds.formAddressInputGroup.id
  }, __jsx(FormAddressInputGroup, {
    label: "Employer address",
    formikProps: formikProps,
    nameMap: {
      state: 'stateStr'
    },
    testId: testIds.formAddressInputGroup.id
  }))), __jsx(ButtonContainer, null, __jsx(Button, _extends({
    disabled: !employmentStatus || isLoading,
    type: "submit",
    fullWidth: true,
    loading: updateInvestorEntityResult.isLoading
  }, testIds.submitButton.attr), "Continue"))), [employmentStatus, isUserEmployed, isSelfEmployedOrEmployed, isLoading, updateInvestorEntityResult.isLoading]);
  const submitCallback = useCallback(values => {
    let employedBody = {};
    if (isUserEmployed) {
      const addressBody = {
        addressLine1: values.addressLine1,
        addressLine2: values === null || values === void 0 ? void 0 : values.addressLine2,
        city: values.city,
        state: values.stateStr.toUpperCase(),
        country: values.country
      };
      employedBody = {
        employerAddress: _objectSpread(_objectSpread({}, addressBody), {}, {
          postalCode: values.postalCode
        }),
        employmentIndustry: values.employmentIndustry,
        employerName: values.employerName,
        employmentTitle: values.employmentTitle
      };
    }
    if (isUserSelfEmployed) {
      employedBody = {
        employmentIndustry: values.employmentIndustry,
        employmentTitle: values.employmentTitle
      };
    }
    updateInvestorEntity(_objectSpread({
      investorEntityId,
      employmentStatus: values.employmentStatusInput
    }, employedBody));
  }, [investorEntityId, isUserEmployed, isUserSelfEmployed, updateInvestorEntity]);
  const formEmploymentInfoConfig = useMemo(() => ({
    validation: employmentFormValidation(employmentStatus),
    formikConfig: {
      initialValues: employmentInitialValues({
        entityData,
        employmentStatus
      }),
      enableReinitialize: false
    }
  }), [entityData, employmentStatus]);
  return __jsx(RenderFlowWrapper, {
    formConfig: formEmploymentInfoConfig,
    flowConfig: flowConfig,
    submitConfig: updateInvestorEntityResult,
    submitCallback: submitCallback,
    isLoading: isLoading,
    testIds: testIds,
    history: history
  }, formikProps => renderForm(formikProps));
};