var __jsx = React.createElement;
import React from 'react';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Logo from '../../assets/logos/logo-ys-2022.png';
import { Label } from '../../content/text';
import { PageContainer } from '../page-container';
import { DEFAULT_HEADER_MIN_HEIGHT, SMALL_HEADER_MIN_HEIGHT, testIds } from './PageFlow.model';
import { Arrow, BackNavigation, ContentHolder, Counter, Footer, Header, LogoContainer, LogoImg } from './PageFlow.style';
export const PageFlow = _ref => {
  let {
    backNavigation,
    backNavigationCallback,
    backNavigationText,
    children,
    counter,
    light = false,
    showFooter = true,
    showLogo = true,
    smallHeader = false,
    tabletMaxWidth = '500px'
  } = _ref;
  const headerMinHeight = () => {
    if (!showLogo && !backNavigation && !counter) {
      return '0';
    }
    return smallHeader ? DEFAULT_HEADER_MIN_HEIGHT : SMALL_HEADER_MIN_HEIGHT;
  };
  const copyrightYear = new Date().getFullYear();
  return __jsx(PageContainer, {
    light: light
  }, __jsx(Header, {
    minHeight: headerMinHeight()
  }, backNavigation ? __jsx(BackNavigation, {
    "data-cy": testIds.backNavigation,
    onClick: e => {
      e.preventDefault();
      backNavigationCallback && backNavigationCallback();
    }
  }, __jsx(Arrow, {
    icon: faChevronLeft
  }), backNavigationText) : null, showLogo && __jsx(LogoContainer, {
    "data-cy": testIds.logoContainer
  }, __jsx("a", {
    href: "/"
  }, __jsx(LogoImg, {
    src: Logo,
    height: 50
  }))), counter ? __jsx(Counter, {
    "data-cy": testIds.counter
  }, counter) : null), __jsx(ContentHolder, {
    id: "content-holder",
    $showLogo: showLogo,
    $minHeight: headerMinHeight(),
    "data-cy": testIds.contentHolder,
    $maxWidth: tabletMaxWidth
  }, children), __jsx(Footer, null, showFooter && __jsx(Label, {
    small: true,
    "data-cy": testIds.footer
  }, "Copyright \xA9 ", copyrightYear, " Yieldstreet, Inc.")));
};