var __jsx = React.createElement;
import React from 'react';
import { useSnackbar } from '@yieldstreet/tool-kit';
import { SnackbarPortal } from './Snackbar.model';
import { SnackbarItem } from './SnackbarItem';
import { SnackbarItemWrapper } from './Snackbar.style';
import { Portal } from '../portal';
export const Snackbar = _ref => {
  let {
    maxSnack
  } = _ref;
  const {
    snackbarQueue,
    closeSnackbar
  } = useSnackbar();
  return __jsx(Portal, {
    portalId: SnackbarPortal
  }, __jsx(SnackbarItemWrapper, null, snackbarQueue.map((snackbar, index) => {
    const isVisible = !maxSnack || index < maxSnack;
    const handleClose = () => closeSnackbar(snackbar.key);
    return __jsx(SnackbarItem, {
      key: snackbar.key,
      isVisible: isVisible,
      type: snackbar.type,
      title: snackbar.title,
      description: snackbar.description,
      autoHideDuration: snackbar.autoHideDuration,
      linkText: snackbar.linkText,
      testId: snackbar.dataCy || snackbar.testId,
      handleLink: snackbar.handleLink,
      closeSnackbar: handleClose
    });
  })));
};