import { PerformanceStatus } from '@yieldstreet/platform-kit';
import { PerformanceStatusTypes } from '../../useInvestmentsList.model';

/**
 * Since the performance status that is received is different than
 * what we need to show on the UI, we need to map the original status
 * to a UI version.
 */
export const getStatusLabelByPerfStatus = originalStatus => {
  switch (originalStatus) {
    /**
     * Pending + Processing investments should not use the original performanceStatus
     * but instead force the pending status to all the investments returned
     * in the PENDING list from getInvestmentsByStatus.
     */
    case PerformanceStatusTypes.Pending:
    case PerformanceStatusTypes.Processing:
      return originalStatus;
    case PerformanceStatus.PERFORMING:
      return PerformanceStatusTypes.Active;
    case PerformanceStatus.MODIFIED_OUTLOOK:
      return PerformanceStatusTypes.Watchlist;
    case PerformanceStatus.DEFAULT:
    case PerformanceStatus.MARINE_DEFAULT:
    case PerformanceStatus.PARTIAL_DEFAULT:
      return PerformanceStatusTypes.Default;
    default:
      return '-';
  }
};