import { useMemo } from 'react';
export const useInvestmentDocuments = (offeringInfo, docType) => {
  const documents = useMemo(() => {
    const rawDocuments = (offeringInfo === null || offeringInfo === void 0 ? void 0 : offeringInfo.documents) || [];
    if (typeof docType === 'string') {
      var _rawDocuments$find;
      return ((_rawDocuments$find = rawDocuments.find(doc => doc.docType === docType)) === null || _rawDocuments$find === void 0 ? void 0 : _rawDocuments$find.url) || '#';
    }
    let docsObject = {};
    docType.forEach(doc => {
      rawDocuments.forEach(rawDoc => {
        if (doc === rawDoc.docType) {
          docsObject[doc] = rawDoc === null || rawDoc === void 0 ? void 0 : rawDoc.url;
        } else {
          docsObject[doc] = '#';
        }
      });
    });
    return docsObject;
  }, [offeringInfo, docType]);
  return documents;
};