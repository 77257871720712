import { backOff } from 'exponential-backoff';
export const pollCheck = async (pollRequest, checkCallback, backOffOptions) => {
  // wrap in try catch because if backoff retries fail it will result in exception
  // we want the exception handled becasue this is not a failure scenario
  try {
    // start backoff retries
    const result = await backOff(() => pollRequest().then(result => {
      if (checkCallback(result)) {
        // if check succeeds simply return result so that promise is resolved
        // in case successful promise resolve retries will automatically stop
        return result;
      } else {
        // if check fails throw error with result so that promise is rejected
        // in case of promise failure the check if retried in next backoff cycle
        throw result;
      }
    }), backOffOptions);

    // return last retry result in case of success
    return result;
  } catch (e) {
    // failed backoff means that check failed for all retries
    // catch exception and return null
    return null;
  }
};