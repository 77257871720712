import { useUserState } from 'utils/hooks';
import { ThirdPartyProviderType } from './useAuthWelcome.model';
import { SDIRAProviders } from 'pages/providers/sdira/hooks';
export const useAuthWelcome = () => {
  const {
    issuer
  } = useUserState();
  if (SDIRAProviders.includes(issuer)) {
    return ThirdPartyProviderType.SDIRA;
  }
  if (issuer === ThirdPartyProviderType.CADRE) {
    return ThirdPartyProviderType.CADRE;
  }
  return null;
};