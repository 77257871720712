import { BusinessEvent, eventManager } from '@yieldstreet/tool-kit';
export const trackMessageView = displayedMessage => {
  if (displayedMessage) {
    const {
      configuration
    } = displayedMessage;
    const {
      campaignName,
      location,
      componentType
    } = configuration;
    eventManager.track(BusinessEvent.USER_COMMS_PLATFORM_PROMO_VIEW, {
      name: campaignName,
      location,
      type: componentType
    });
  }
};
export const trackMessageDismiss = displayedMessage => {
  if (displayedMessage) {
    const {
      configuration
    } = displayedMessage;
    const {
      campaignName,
      location,
      componentType
    } = configuration;
    eventManager.track(BusinessEvent.USER_COMMS_PLATFORM_PROMO_DISMISS, {
      name: campaignName,
      location,
      type: componentType
    });
  }
};
export const trackMessageAction = (displayedMessage, cta) => {
  if (displayedMessage) {
    const {
      configuration
    } = displayedMessage;
    const {
      campaignName,
      location,
      componentType
    } = configuration;
    eventManager.track(BusinessEvent.USER_COMMS_PLATFORM_PROMO_ACTION, {
      name: campaignName,
      location,
      type: componentType,
      cta
    });
  }
};