import { CALL_API } from '../middleware/Middleware';
export const GET_TERMS_ATTEMPT = 'ys/terms/ATTEMPT';
export const GET_TERMS_SUCCESS = 'ys/terms/SUCCESS';
export const GET_TERMS_FAILURE = 'ys/terms/FAILURE';
export const getTerms = () => dispatch => {
  const endpoint = `/wp-json/wp/v2/learningtool/?lt_category=13&per_page=100`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_TERMS_ATTEMPT, GET_TERMS_SUCCESS, GET_TERMS_FAILURE],
      getResponse: res => res.data
    }
  });
};