import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useApiQueryPollCheck } from '../../../async-effects';
import { unitApi } from '@yieldstreet/platform-kit';
import { UNIT_KYC_COMPLETE, UnitWindowStatus, isApplicationUserInputReady, isApplicationUserInputDone } from './useUnitApplicationForm.model';
import { useState, useEffect, useCallback, useMemo } from 'react';
export const useUnitApplicationForm = investorEntityId => {
  const unitKYCApplicationResult = unitApi.useGetUnitApplicationQuery({
    investorEntityId
  });
  const {
    0: windowStatus,
    1: setWindowStatus
  } = useState();
  const {
    0: windowURL,
    1: setWindowURL
  } = useState();
  const {
    data: {
      status,
      applicationUrl
    } = {}
  } = unitKYCApplicationResult;
  useEffect(() => {
    // storing initial application url in a fixed state to prevent window refreshes
    // since Unit application url changes on every query refetch in case of poll check
    if (!windowURL) {
      setWindowURL(applicationUrl);
    }
  }, [applicationUrl, windowURL, setWindowURL]);
  const inputState = useMemo(() => {
    const isReady = isApplicationUserInputReady(status);
    const isDone = isApplicationUserInputDone(status) || windowStatus === UnitWindowStatus.DONE;
    const isLoading = !isReady || !windowStatus;
    return {
      isLoading,
      isReady,
      isDone
    };
  }, [status, windowStatus]);

  // poll until application form is ready for user input
  useApiQueryPollCheck(unitKYCApplicationResult, _ref => {
    let {
      status
    } = _ref;
    return isApplicationUserInputReady(status);
  });

  // when window has been redirected poll until application form user input is done
  useApiQueryPollCheck(unitKYCApplicationResult, _ref2 => {
    let {
      status
    } = _ref2;
    return isApplicationUserInputDone(status);
  }, windowStatus !== UnitWindowStatus.REDIRECTED);

  // first window load means the unit application form will be loaded
  // second window load means the redirect to YS callback url loaded
  const onWindowLoad = useCallback(() => setWindowStatus(!windowStatus ? UnitWindowStatus.LOADED : UnitWindowStatus.REDIRECTED), [windowStatus, setWindowStatus]);
  const onWindowMessage = useCallback(data => (data === null || data === void 0 ? void 0 : data.action) === UNIT_KYC_COMPLETE && setWindowStatus(UnitWindowStatus.DONE), [setWindowStatus]);
  return _objectSpread(_objectSpread({}, inputState), {}, {
    windowURL,
    windowStatus,
    onWindowLoad,
    onWindowMessage
  });
};