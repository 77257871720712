import _Alert from "@mui/material/Alert";
import _AlertTitle from "@mui/material/AlertTitle";
var __jsx = React.createElement;
import React from 'react';
import { RouterLink } from '@yieldstreet/ui-kit/navigation';
import { useUserState } from 'utils/hooks';
export const PreNavigationContent = () => {
  const userState = useUserState();

  // Temporary override for demo purposes
  return userState !== null && userState !== void 0 && userState.impersonated && userState.emailAddress !== 'demo@yieldstreet.com' ? __jsx(_Alert, {
    severity: "warning"
  }, __jsx(_AlertTitle, null, `Viewing site as ${userState.displayName}.`), __jsx(RouterLink, {
    to: userState.impersonated ? '/logout' : '/'
  }, "Exit preview")) : null;
};