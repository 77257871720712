var __jsx = React.createElement;
import React from 'react';
import { Container, Label, OuterContainer, Paragraph, Strategy } from './StrategySelector.style';
export const StrategySelector = _ref => {
  let {
    options,
    activeKey,
    onClick
  } = _ref;
  return __jsx(OuterContainer, null, __jsx(Container, null, options === null || options === void 0 ? void 0 : options.map((option, key) => __jsx(Strategy, {
    key: key,
    active: option.key === activeKey,
    onClick: () => {
      onClick && onClick(option);
    }
  }, __jsx(Paragraph, {
    variant: "body1",
    semiBold: true
  }, option.title), __jsx(Label, {
    variant: "body2",
    semiBold: true
  }, option.desc)))));
};