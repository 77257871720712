import styled from 'styled-components';
import { media } from '../../themes/media';
import { Heading } from '../../content';
export const Wrapper = styled.div.withConfig({
  displayName: "PageErrorstyle__Wrapper",
  componentId: "sc-v1zmax-0"
})(["display:flex;flex-direction:column;align-items:center;"]);
export const Image = styled.img.withConfig({
  displayName: "PageErrorstyle__Image",
  componentId: "sc-v1zmax-1"
})(["width:185px;height:148px;", ""], media.desktop`
    width: 300px;
    height: 240px;
  `);
export const SHeading = styled(Heading).withConfig({
  displayName: "PageErrorstyle__SHeading",
  componentId: "sc-v1zmax-2"
})(["padding:", ";"], _ref => {
  let {
    theme
  } = _ref;
  return `${theme.spacing.m} 0 ${theme.spacing.sm}`;
});
export const ButtonsWrapper = styled.div.withConfig({
  displayName: "PageErrorstyle__ButtonsWrapper",
  componentId: "sc-v1zmax-3"
})(["position:absolute;display:flex;flex-direction:column;row-gap:", ";bottom:", ";width:90%;", ""], props => props.theme.spacing.sm, props => props.theme.spacing.m, media.desktop`
    position: initial;
    flex-direction: row;
    justify-content: center;
    margin-top: ${props => props.theme.spacing.xl};
    column-gap: ${props => props.theme.spacing.sm};
  `);