import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useCallback, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button, FormMaskTextInput, FormTextInput, FormDocumentTextInput, FormCheckboxInput } from '@yieldstreet/ui-kit';
import { investorEntityApi, userApi, InvestorType } from '@yieldstreet/platform-kit';
import { useFormFlow, useApiErrorMessage, useApiEventTracking, BusinessEvent } from '@yieldstreet/tool-kit';
import { useFlowLocation } from 'hooks/formFlow/useFlowLocation';
import { testIds } from './Details.model';
import { getFormConfig, buildPostData } from './Details.utils';
import { CheckboxSecondaryWrapper, InputSecondaryTextWrapper } from './Details.style';
import { getInvestorLabel } from '../../CreateInvestorAccount.utils';
import { useOnUrlChange, DETAILS_STEP } from '../../CreateInvestorAccount.model';
import { ButtonContainer, FormWrapper, SubmitWrapper } from '../../CreateInvestorAccount.style';
import { FlowWrapper } from '../FlowWrapper';
import { useUserState } from 'utils/hooks';
import { SynapseLegalText } from './components/SynapseLegalText';
export const Details = _ref => {
  let {
    flowConfig
  } = _ref;
  const {
    accountType,
    investorEntityId
  } = useParams();
  const history = useHistory();
  const {
    emailAddress
  } = useUserState();
  const {
    useUpdateInvestorEntityMutation
  } = investorEntityApi;
  const {
    useGetUserContactInfoQuery
  } = userApi;
  const [updateInvestorAccount, updateAccountResult] = useUpdateInvestorEntityMutation();
  const {
    currentData: userContactData
  } = useGetUserContactInfoQuery();
  const {
    dateLabel,
    documentLabel,
    documentNumberFormat
  } = getInvestorLabel(accountType);
  const renderForm = formikProps => __jsx(FormWrapper, testIds.formWrapper.attr, __jsx(InputSecondaryTextWrapper, null, __jsx(FormMaskTextInput, {
    type: "text",
    label: dateLabel,
    name: "dateOfRecord",
    placeholder: "MM/DD/YYYY",
    format: "##/##/####",
    formikProps: formikProps,
    testId: testIds.dateOfRecordField.id,
    useFormattedValue: true
  }), accountType === InvestorType.IRA && __jsx(FormTextInput, {
    name: "employerName",
    label: "Employer name",
    placeholder: "Employer name",
    formikProps: formikProps,
    testId: testIds.employerNameField.id
  }), __jsx(FormDocumentTextInput, {
    name: "documentNumber",
    formikProps: formikProps,
    label: documentLabel,
    format: documentNumberFormat,
    testId: testIds.documentNumberField.id
  })), __jsx(CheckboxSecondaryWrapper, null, __jsx(FormCheckboxInput, {
    name: "legalAgreement",
    label: __jsx(SynapseLegalText, null),
    formikProps: formikProps,
    testId: testIds.legalCheckbox.id
  })), __jsx(SubmitWrapper, null, __jsx(ButtonContainer, null, __jsx(Button, _extends({
    type: "submit",
    trackingId: testIds.submitButton.id,
    loading: updateAccountResult.isLoading,
    disabled: updateAccountResult.isLoading || !formikProps.values.legalAgreement
  }, testIds.submitButton.attr), "Submit"))));
  const submitCallback = useCallback(values => {
    const detailsData = buildPostData({
      investorType: accountType,
      values,
      userEmailAddress: emailAddress,
      contact: userContactData === null || userContactData === void 0 ? void 0 : userContactData.contact
    });
    updateInvestorAccount(_objectSpread(_objectSpread({
      investorEntityId
    }, detailsData), {}, {
      id: investorEntityId
    }));
  }, [accountType, userContactData, emailAddress, investorEntityId, updateInvestorAccount]);
  const flowState = useFlowLocation({
    useOnUrlChange
  });
  const formDetailsConfig = useMemo(() => getFormConfig(accountType), [accountType]);
  const {
    Form,
    goToPreviousStep,
    isFirstStep
  } = useFormFlow({
    formConfig: formDetailsConfig,
    flowConfig,
    flowState,
    submitConfig: updateAccountResult,
    submitCallback
  });
  const updateInvestorErrorMessage = useApiErrorMessage(updateAccountResult);
  useApiEventTracking(updateAccountResult, BusinessEvent.INVESTOR_CREATION_SUBMITTED, {
    step: DETAILS_STEP
  }, updateInvestorErrorMessage);
  return __jsx(FlowWrapper, {
    flowConfig: flowConfig,
    step: flowState.step,
    investorAccountType: accountType,
    goToPreviousStep: isFirstStep ? () => history.push('/management/accounts') : goToPreviousStep,
    error: updateInvestorErrorMessage
  }, __jsx(Form, null, formikProps => renderForm(formikProps)));
};