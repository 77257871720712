import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["options"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { ExternalLink } from '../../content/link';
import { Heading, Paragraph } from '../../content/text';
export const CardRow = _ref => {
  let {
      options
    } = _ref,
    otherProps = _objectWithoutProperties(_ref, _excluded);
  return __jsx(React.Fragment, null, options === null || options === void 0 ? void 0 : options.map((option, idx) => __jsx(CardRowWrapper, _extends({}, otherProps, {
    key: idx
  }), __jsx(CardRowHeader, null, __jsx(Heading, {
    type: 5
  }, option.title), typeof option.subTitle === 'string' ? __jsx(Paragraph, null, option.subTitle) : __jsx(React.Fragment, null, option.subTitle.map((subTitle, idx) => __jsx(Paragraph, {
    key: idx
  }, subTitle)))), __jsx(ExternalLink, {
    "data-cy": (option === null || option === void 0 ? void 0 : option.testId) || 'card-row-link',
    onClick: option.onClick
  }, option.linkText))));
};
const CardRowWrapper = styled.div.withConfig({
  displayName: "CardRow__CardRowWrapper",
  componentId: "sc-gtor65-0"
})(["background:", ";padding:20px;display:flex;align-items:center;margin:0;"], props => props.theme.colors.background_default);
const CardRowHeader = styled.div.withConfig({
  displayName: "CardRow__CardRowHeader",
  componentId: "sc-gtor65-1"
})(["flex:1;overflow:hidden;p{text-overflow:ellipsis;overflow:hidden;padding-right:10px;}"]);