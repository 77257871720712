var __jsx = React.createElement;
import React, { useMemo } from 'react';
import { Col, PageGrid, Row, Heading } from '@yieldstreet/ui-kit';
import { useAdviceOnramp } from 'utils/advice';
import { CardWrapper, ImageOverlay, CardContent, Description, CTAButton, TextualContent } from './AdviceCard.style';
const AdviceCard = _ref => {
  let {
    contained
  } = _ref;
  const {
    cta,
    subHeading,
    heading,
    cardImage
  } = useAdviceOnramp();
  const card = useMemo(() => __jsx(CardWrapper, {
    "data-cy": "advice-card",
    isContained: contained
  }, __jsx(CardContent, {
    isContained: contained
  }, __jsx(ImageOverlay, {
    src: cardImage,
    isContained: contained
  }), __jsx(TextualContent, {
    "data-cy": "advice-card-textual-content"
  }, __jsx(Heading, {
    type: 4,
    "data-cy": "advice-card-heading"
  }, heading), __jsx(Description, {
    "data-cy": "advice-card-sub-heading"
  }, subHeading), __jsx(CTAButton, {
    onClick: cta.handleOnClick,
    "data-cy": "advice-card-cta"
  }, cta.text)))), [contained, cta, subHeading, heading, cardImage]);
  if (contained) {
    return __jsx(CardContainer, {
      withoutPageGrid: !!(contained !== null && contained !== void 0 && contained.withoutPageGrid)
    }, card);
  }
  return card;
};
const CardContainer = _ref2 => {
  let {
    children,
    withoutPageGrid
  } = _ref2;
  return __jsx(Row, null, __jsx(Col, null, withoutPageGrid ? children : __jsx(PageGrid, {
    config: {
      spacing: 20
    }
  }, children)));
};
export { AdviceCard };