var __jsx = React.createElement;
import React, { useCallback, useEffect } from 'react';
import { NewestModal } from '@yieldstreet/ui-kit';
import { Modals } from 'constants/modal.model';
import { useBankLinkingModal } from 'pages/bankLinking/hooks/useBanklinkingModal';
import { DefaultBank } from '../DefaultBank';
import { DefaultBankButtonAction, DefaultBankScreenType } from '../DefaultBank.model';
import { useDefaultBankScreenType } from '../hooks/useDefaultBankScreenType';
export const DefaultBankModal = _ref => {
  let {
    investorEntityId,
    onClose
  } = _ref;
  const screenType = useDefaultBankScreenType(investorEntityId);
  const bankLinkingModal = useBankLinkingModal({
    investorEntityId
  });
  useEffect(() => {
    if (screenType === DefaultBankScreenType.MicroDeposit) {
      onClose();
    }
  }, [onClose, screenType]);
  const onAction = useCallback(actionType => {
    if (actionType === DefaultBankButtonAction.DEFAULT_BANK_LINK_ANOTHER_BANK) {
      bankLinkingModal.open();
    }
    onClose();
  }, [bankLinkingModal, onClose]);
  const forceModalVisibility = screenType === DefaultBankScreenType.SetDefaultBank;
  return __jsx(NewestModal, {
    disableCloseOnClickOutside: forceModalVisibility,
    hideCloseButton: forceModalVisibility,
    modalId: Modals.DefaultBank,
    onClose: onClose
  }, __jsx(DefaultBank, {
    investorEntityId: investorEntityId,
    onAction: onAction
  }));
};