import { address } from '../common';
export const userProfile = {
  accreditedSignUp: true,
  addresses: [address],
  createDate: 'Apr 28, 2020 8:13:09 AM',
  id: 220691,
  phone: '(917) 222-2222',
  phoneRaw: '+19172222222',
  smsEnabled: false,
  updateDate: 'Apr 28, 2020 8:22:26 AM'
};