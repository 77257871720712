import { PaddingKeys, MarginKeys } from './Spacing.model';

/*
 * remove any props that are not spacing related and
 * reorder props keys to ensure correct css order
 * (padding, paddingHorizontal, paddingVertical)
 * */
export const sanitizeSpacingProps = props => {
  const sanitizedProps = {};
  [...PaddingKeys, ...MarginKeys].forEach(key => {
    if (props[key]) {
      sanitizedProps[key] = props[key];
    }
  });
  return sanitizedProps;
};