var __jsx = React.createElement;
import React from 'react';
import { Paragraph } from '../../content';
import { TextBoxWrapper, SHeading } from './TextBox.style';
export const TextBox = _ref => {
  let {
    title,
    text,
    icon,
    dataCy
  } = _ref;
  return __jsx(TextBoxWrapper, {
    "data-cy": dataCy || title
  }, icon ? __jsx("img", {
    src: icon
  }) : null, __jsx(SHeading, {
    type: 4
  }, title), __jsx(Paragraph, {
    size: "small"
  }, text));
};