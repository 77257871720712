import _Box from "@mui/material/Box";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { TopNotification } from '@yieldstreet/ui-kit';
import { usePlatformPromo } from '../../hooks/usePlatformPromo';
export const Banner = props => {
  const {
    displayedMessage,
    dismiss
  } = usePlatformPromo(props);
  return __jsx(_Box, {
    sx: {
      mb: 4
    }
  }, displayedMessage && __jsx(TopNotification, _extends({}, displayedMessage.configuration, {
    dismissAction: dismiss
  })));
};