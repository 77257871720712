import { genTestIds } from '@yieldstreet/tool-kit';
const {
  getTestIds
} = genTestIds(['welcome-controls', 'slider-controls', 'btn-show-button', 'btn-manual-explore', 'btn-next-slide', 'btn-previous-slide']);
export const testIds = getTestIds('product-tour-modal');
export const content = {
  showButton: `Show me around`,
  manualExplore: `I'll explore on my own`,
  controls: {
    nextButton: 'Next',
    previousButton: 'Previous'
  }
};