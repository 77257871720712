var __jsx = React.createElement;
import React, { useCallback } from 'react';
import isFunction from "lodash/isFunction";
import { InvestmentsListOrderTypes } from '@yieldstreet/tool-kit';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TableHeaderItemSort, TableHeaderItemWrapper } from './TableHeaderItem.style';
export const TableHeaderItem = _ref => {
  let {
    activeSortBy,
    label,
    handleSortClick,
    orderBy,
    sortBy
  } = _ref;
  /**
   * activeSortBy is the sortBy from useTaxList. This
   * is being used by the rest of the components. sortBy is the
   * type set in the header items config.
   */
  const isSorted = activeSortBy === sortBy;
  const onClick = useCallback(() => {
    if (isFunction(handleSortClick)) {
      handleSortClick(sortBy, orderBy);
    }
  }, [handleSortClick, orderBy, sortBy]);
  return __jsx(TableHeaderItemWrapper, {
    onClick: onClick
  }, __jsx("span", null, label), __jsx(TableHeaderItemSort, {
    isSorted: isSorted
  }, (!isSorted || orderBy === InvestmentsListOrderTypes.Ascending) && __jsx(ExpandLessIcon, {
    sx: {
      fontSize: '13px',
      mb: '-3px'
    }
  }), (!isSorted || orderBy === InvestmentsListOrderTypes.Descending) && __jsx(ExpandMoreIcon, {
    sx: {
      fontSize: '13px',
      mt: '-3px'
    }
  })));
};