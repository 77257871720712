import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["testId", "label", "value", "error", "onChange", "onClick", "className", "caption", "disabled", "description", "descriptionCheckedOnly", "image", "name"];
var __jsx = React.createElement;
import React from 'react';
import Radio from '@mui/material/Radio';
import { SFormHelperText, FormError, OptionControl, OptionLabel, OptionDescription, OptionControlLabel, withFormikWeb, useLabelId } from '../common';
export const RadioInput = props => {
  const {
      testId,
      label,
      value,
      error,
      onChange,
      onClick,
      className,
      caption,
      disabled,
      description,
      descriptionCheckedOnly,
      image,
      name
    } = props,
    otherProps = _objectWithoutProperties(props, _excluded);
  const labelId = useLabelId(name);
  return __jsx(OptionControl, {
    error: !!error,
    className: className,
    "data-radio": true,
    "data-checked": value,
    "data-disabled": disabled,
    id: labelId
  }, __jsx(OptionControlLabel, {
    className: "option-control-label",
    disabled: disabled,
    "data-cy": `option-control-${testId}` || 'option-control-label-testId',
    label: __jsx(React.Fragment, null, __jsx(OptionLabel, {
      "data-radio-label": true,
      className: "option-label"
    }, image && typeof image === 'string' ? __jsx("img", {
      src: image
    }) : image, label), (!descriptionCheckedOnly || value) && description && __jsx(OptionDescription, {
      small: true,
      "data-radio-description": true
    }, description)),
    value: value,
    onChange: onChange && ((_e, checked) => onChange(checked)),
    control: __jsx(Radio, _extends({
      onClick: e => onClick && onClick(e),
      "data-radio-input": true,
      "data-cy": testId || 'radio',
      name: name,
      color: "primary",
      checked: value
    }, otherProps))
  }), error || caption ? __jsx(SFormHelperText, {
    margin: "dense"
  }, error ? __jsx(FormError, {
    testId: "error",
    type: "radio-input",
    name: (props === null || props === void 0 ? void 0 : props.name) || 'radio',
    error: error
  }) : caption) : null);
};
export const FormRadioInput = withFormikWeb(RadioInput);