const commitment = {
  acceptedAt: '',
  commitmentId: 'testCommitmentId',
  committedAmount: 100,
  confirmedAt: '',
  createDt: 0,
  investorAccountId: '201924',
  noteUrlHash: 'testUrlHash',
  payInBankAccountId: '',
  payInPaymentMethod: '',
  preferredPaymentMethod: 'ACH',
  preferredBankAccountId: '',
  signedAt: '',
  status: 'CREATED'
};
export const getCommitmentMock = {
  commitment
};