import React from "react";
var __jsx = React.createElement;
import { useState } from 'react';
import { ExternalLink, Paragraph } from '@yieldstreet/ui-kit';
import { CheckboxFlexWrapper, CheckboxText, CondensedParagraph, ParagraphListPadding, ParagraphListWrapper } from './SynapseLegalText.style';
export const SynapseLegalText = () => {
  const {
    0: hidden,
    1: setHidden
  } = useState(false);
  const showReadMore = e => {
    e.stopPropagation();
    setHidden(true);
  };
  return __jsx(CheckboxFlexWrapper, {
    hidden: hidden
  }, __jsx(CondensedParagraph, {
    target: "_blank"
  }, "By opening your Yieldstreet account, you agree to our", ' ', __jsx(ExternalLink, {
    href: "/terms",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Terms of Use"), ' ', "and", ' ', __jsx(ExternalLink, {
    href: "/privacy",
    target: "_blank",
    rel: "noopener noreferrer"
  }, ' ', "Privacy Policy"), ". Your Yieldstreet Wallet is provided by Evolve Bank & Trust (\u201CBank\u201D), Member FDIC. By using your Wallet or any other bank-related services through our site, you also agree to Evolve Bank & Trust\u2019s", ' ', __jsx(ExternalLink, {
    href: "https://cdn2.yieldstreet.com/wp-content/uploads/2022/01/06082829/Customer-Account-Agreement-2022.pdf",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Deposit Agreement"), ' ', "and", ' ', __jsx(ExternalLink, {
    href: "/synapse-customer-agreement",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Synapse Brokerage LLC Customer Agreement."), ' ', !hidden && __jsx(ExternalLink, {
    onMouseDown: showReadMore
  }, "Read more")), hidden && __jsx(CheckboxText, null, __jsx(ParagraphListPadding, null, __jsx(Paragraph, null, "I certify, under penalty of perjury that:")), __jsx(ParagraphListWrapper, null, __jsx(ParagraphListPadding, null, __jsx(Paragraph, null, "1) The Taxpayer Identification Number or Social Security Number I provided is correct;")), __jsx(ParagraphListPadding, null, __jsx(Paragraph, null, "2) I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding; and")), __jsx(ParagraphListPadding, null, __jsx(Paragraph, null, "3) The bank services are provided by Evolve Bank & Trust, Member FDIC. By opening up a deposit account on Yieldstreet, you agree to Evolve\u2019s", ' ', __jsx(ExternalLink, {
    href: "https://cdn2.yieldstreet.com/wp-content/uploads/2022/01/06082829/Customer-Account-Agreement-2022.pdf",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Bank Deposit Agreement"), ' ', "and SynapseFi\u2019s", ' ', __jsx(ExternalLink, {
    href: "/terms",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Terms of Use"), ' ', "and", ' ', __jsx(ExternalLink, {
    href: "/privacy",
    target: "_blank",
    rel: "noopener noreferrer"
  }, "Privacy Policy"), "; and")), __jsx(Paragraph, null, "4) I am a U.S. citizen or other U.S. person.")), __jsx("br", null), __jsx(Paragraph, null, "The Internal Revenue Service does not require your consent to any provision of this document other than the certifications required to avoid backup withholding.")));
};