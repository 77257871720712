import { genTestIds } from '@yieldstreet/tool-kit';
export const backgroundVariant = {
  primary: 'background_card',
  secondary: 'background_light'
};
export const {
  getTestIds
} = genTestIds([]);
export const mapToTestIds = function () {
  let testId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '';
  return getTestIds(`${testId}-accordion`);
};