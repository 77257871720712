import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useMemo } from 'react';
import { allToken, offeringApi, portfolioGraphQLApi, userApi } from '@yieldstreet/platform-kit';
import { skipToken } from '@reduxjs/toolkit/query';
export const useRollover = _ref => {
  let {
    urlHash,
    investorId,
    destinationUrlHash
  } = _ref;
  const {
    data: user
  } = userApi.useGetUserStateQuery(allToken);
  const getRolloverAmountConfig = useMemo(() => {
    if (!investorId || !urlHash) {
      return skipToken;
    }
    return {
      userId: `${(user === null || user === void 0 ? void 0 : user.id) || ''}`,
      noteUrlHash: urlHash,
      investorId
    };
  }, [user, urlHash, investorId]);
  const {
    data: rolloverData,
    isUninitialized: isRolloverUninitialized,
    isLoading: isRolloverLoading,
    isError: rolloverError,
    isFetching: isRolloverFetching
  } = portfolioGraphQLApi.useGetRolloverAmountQuery(getRolloverAmountConfig);
  const getDetailsConfig = useMemo(() => {
    if (!urlHash) {
      return skipToken;
    }
    const hash = [urlHash];
    if (destinationUrlHash) {
      hash.push(destinationUrlHash);
    }
    return hash;
  }, [urlHash, destinationUrlHash]);
  const {
    data: offerings,
    isUninitialized: isOfferingUninitialized,
    isLoading: isOfferingLoading,
    isFetching: isOfferingFetching,
    isError: offeringError
  } = offeringApi.useDetailsQuery(getDetailsConfig);
  const offering = useMemo(() => {
    var _offerings$urlHash;
    if (!offerings) {
      return undefined;
    }
    return _objectSpread(_objectSpread({}, offerings === null || offerings === void 0 ? void 0 : (_offerings$urlHash = offerings[urlHash]) === null || _offerings$urlHash === void 0 ? void 0 : _offerings$urlHash.note), {}, {
      urlHash
    });
  }, [offerings, urlHash]);
  const destinationOffering = useMemo(() => {
    var _offerings$destinatio;
    if (!offerings || !destinationUrlHash) {
      return undefined;
    }
    return offerings === null || offerings === void 0 ? void 0 : (_offerings$destinatio = offerings[destinationUrlHash]) === null || _offerings$destinatio === void 0 ? void 0 : _offerings$destinatio.note;
  }, [offerings, destinationUrlHash]);
  return {
    rolloverData: rolloverData === null || rolloverData === void 0 ? void 0 : rolloverData.maxRolloverAmount,
    rolloverDataIsUninitialized: isRolloverUninitialized,
    rolloverDataIsLoading: isRolloverLoading || isRolloverFetching,
    rolloverDataError: rolloverError,
    rolloverOffering: offering,
    rolloverOfferingDestination: destinationOffering,
    rolloverOfferingIsUninitialized: isOfferingUninitialized,
    rolloverOfferingIsLoading: isOfferingLoading || isOfferingFetching,
    rolloverOfferingError: offeringError
  };
};