import styled from 'styled-components';
import { FormSelectInput } from '@yieldstreet/ui-kit';
export const AddressInput = styled.div.withConfig({
  displayName: "EmploymentInfostyle__AddressInput",
  componentId: "sc-1vaiipa-0"
})(["margin-top:", ";"], _ref => {
  let {
    theme
  } = _ref;
  return theme.spacing.m;
});
export const SFormSelectInput = styled(FormSelectInput).withConfig({
  displayName: "EmploymentInfostyle__SFormSelectInput",
  componentId: "sc-1vaiipa-1"
})(["", ""], props => props.isEmployedUser ? `margin-top: ${props.theme.spacing.l};` : '');