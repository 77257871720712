import { useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { overviewApi } from '@yieldstreet/platform-kit';
import { DateRanges, formatCurrency, getDatesForRange, getInvestorAccountId } from '@yieldstreet/tool-kit';
export const useInvestorAccountsTotal = _ref => {
  let {
    investorAccountList,
    managementStrategy,
    userId
  } = _ref;
  const investorAccountIds = useMemo(() => {
    var _investorAccountList$;
    return (_investorAccountList$ = investorAccountList === null || investorAccountList === void 0 ? void 0 : investorAccountList.map(account => getInvestorAccountId(account === null || account === void 0 ? void 0 : account.id, managementStrategy))) !== null && _investorAccountList$ !== void 0 ? _investorAccountList$ : [];
  }, [investorAccountList, managementStrategy]);
  const [lifeTimeStart, lifeTimeEnd] = getDatesForRange({
    range: DateRanges.LifeTime
  });
  const getAllPortfolioOverviewConfig = useMemo(() => {
    if (!userId) {
      return skipToken;
    }
    return {
      end: lifeTimeEnd,
      includeRepaidCapital: true,
      includeRepaidPrincipal: true,
      investorAccountIds,
      managementStrategy,
      start: lifeTimeStart,
      userId
    };
  }, [userId, lifeTimeEnd, lifeTimeStart, investorAccountIds, managementStrategy]);
  const {
    data,
    isFetching
  } = overviewApi.useGetAllPortfolioOverviewQuery(getAllPortfolioOverviewConfig);
  const investorAccountsTotal = useMemo(() => {
    const accountsTotal = {};
    if (data && !isFetching) {
      var _Object$keys;
      (_Object$keys = Object.keys(data)) === null || _Object$keys === void 0 ? void 0 : _Object$keys.forEach(key => {
        var _data$key, _data$key$accountSumm;
        accountsTotal[key] = formatCurrency((_data$key = data[key]) === null || _data$key === void 0 ? void 0 : (_data$key$accountSumm = _data$key.accountSummary) === null || _data$key$accountSumm === void 0 ? void 0 : _data$key$accountSumm.total);
      });
    }
    return accountsTotal;
  }, [data, isFetching]);
  return {
    investorAccountsTotal,
    isFetching
  };
};