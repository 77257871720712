import _Tooltip from "@mui/material/Tooltip";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { BadgeType } from '@yieldstreet/platform-kit';
import { Icon } from '../icon';
import { BadgeConfiguration, useTestIds } from './BadgeV2.model';
import { BadgeContainer, IconContainer, SParagraph } from './BadgeV2.style';

/**
 * @deprecated This component will eventually be migrated as part of Tag
 */
export const BadgeV2 = props => {
  const {
    iconColor,
    icon,
    text,
    type
  } = props;
  const testIds = useTestIds(props);
  if (!text) return null;
  const badgeStyle = BadgeConfiguration[type || BadgeType.DEFAULT];
  return __jsx(_Tooltip, {
    title: text,
    placement: "top"
  }, __jsx(BadgeContainer, _extends({
    backgroundColor: badgeStyle.backgroundColor
  }, testIds.badge.attr), icon && __jsx(IconContainer, testIds.icon.attr, __jsx(Icon, {
    iconType: icon,
    color: iconColor
  })), __jsx(React.Fragment, null, __jsx(SParagraph, {
    textColor: badgeStyle.textColor
  }, text))));
};