var __jsx = React.createElement;
import React from 'react';
import { Heading, Label, Paragraph } from '../../content';
import { TextBlock } from '../text-block-component';
import { MarketingContainer } from '../../layout';
import { Advantage, AdvantagesContainer, Container, Icon, SHeading, SParagraph, Stat, TextWrapper, SSectionContainer } from './AdvantagesSection.styles';
import { IconSize, childrenAmountSmallWidth } from './AdvantagesSection.model';
export const AdvantagesSection = _ref => {
  let {
    label,
    heading,
    content,
    items,
    iconsSize = IconSize.Small,
    align,
    mobileAlign,
    hasPadding
  } = _ref;
  const smallItems = items.length === childrenAmountSmallWidth;
  return __jsx(MarketingContainer, null, __jsx(SSectionContainer, null, __jsx(Container, {
    hasSmallItems: smallItems,
    hasPadding: hasPadding
  }, __jsx(TextWrapper, {
    align: align
  }, __jsx(TextBlock, {
    label: label,
    heading: heading,
    headingTagType: 2,
    headingType: 2,
    content: __jsx(Paragraph, null, content),
    align: align,
    mobileAlign: mobileAlign
  })), __jsx(AdvantagesContainer, {
    hasSmallItems: smallItems
  }, items.map((advantage, index) => {
    var _advantage$stat, _advantage$stat2;
    return __jsx(Advantage, {
      key: index
    }, __jsx(Icon, {
      src: advantage.icon,
      iconsSize: iconsSize,
      role: "presentation",
      alt: ""
    }), __jsx(Heading, {
      tagType: 4,
      type: 4
    }, advantage.title), __jsx(SParagraph, {
      small: true
    }, advantage.description), advantage.stat && __jsx(Stat, null, __jsx(SHeading, {
      type: 3,
      tagType: 3
    }, (_advantage$stat = advantage.stat) === null || _advantage$stat === void 0 ? void 0 : _advantage$stat.value), __jsx(Label, {
      small: true
    }, (_advantage$stat2 = advantage.stat) === null || _advantage$stat2 === void 0 ? void 0 : _advantage$stat2.label)));
  })))));
};