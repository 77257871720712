import { useRef, useEffect, useCallback } from 'react';
import { useFlags as useAllFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { datadogRum } from '@datadog/browser-rum';
import { BusinessEvent, eventManager } from '../../../../events';
export const useLDWebFlagsContext = () => {
  const ldClient = useLDClient();
  const allFlags = useAllFlags();
  const evalPending = useRef({});
  const evalFlag = useCallback((flag, defaultValue) => {
    // check if LD client is available
    if (ldClient) {
      // call LD variation evaluation
      const {
        value,
        reason
      } = ldClient.variationDetail(flag, defaultValue);
      const evalResult = {
        flag,
        value,
        details: reason
      };

      // call tracking with result
      eventManager.track(BusinessEvent.USER_FLAG_EVALUATED, evalResult);
      datadogRum.addFeatureFlagEvaluation(flag, value);
      return value;
    } else {
      const evalParams = {
        flag: flag,
        defaultValue
      };
      // if LD client not available
      // push params to call later
      evalPending.current[flag] = evalParams;
    }
  }, [ldClient]);
  useEffect(() => {
    // if any pending calls
    const evalCalls = Object.values(evalPending.current);
    if (ldClient && evalCalls.length) {
      // evaluate all pending calls
      evalCalls.forEach(_ref => {
        let {
          flag,
          defaultValue
        } = _ref;
        return evalFlag(flag, defaultValue);
      });
      // reset pending calls
      evalPending.current = {};
    }
  }, [ldClient, evalFlag]);
  const isLoading = !ldClient;
  return {
    allFlags,
    evalFlag,
    isLoading
  };
};