import _TextField from "@mui/material/TextField";
import _InputAdornment from "@mui/material/InputAdornment";
var __jsx = React.createElement;
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Snackbar } from '@yieldstreet/ui-kit';
import { SnackbarTypes, TEST_ID_ATTR, useSnackbar } from '@yieldstreet/tool-kit';
export const ClipboardText = _ref => {
  let {
    hideSnackbar,
    label,
    testId,
    value
  } = _ref;
  const {
    enqueueSnackbar
  } = useSnackbar();
  const InputCopyIcon = __jsx(_InputAdornment, {
    position: "end",
    sx: {
      cursor: 'pointer'
    },
    onClick: () => {
      navigator.clipboard.writeText(value).then(() => {
        !hideSnackbar && enqueueSnackbar({
          key: uuidv4(),
          title: 'Copied to clipboard',
          type: SnackbarTypes.Success,
          autoHideDuration: 3000
        });
      });
    }
  }, __jsx(ContentCopyIcon, null));
  return __jsx(React.Fragment, null, __jsx(Snackbar, null), __jsx(_TextField, {
    "aria-readonly": true,
    "data-cy": "",
    fullWidth: true,
    label: label,
    value: value,
    slotProps: {
      input: {
        endAdornment: InputCopyIcon
      },
      htmlInput: {
        [TEST_ID_ATTR]: testId,
        readOnly: true
      }
    }
  }));
};