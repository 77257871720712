import update from 'immutability-helper';
import * as actions from 'store/actions/quarterlyReports';
const initialState = {
  data: null,
  isFetching: false,
  error: false
};
const quarterlyReports = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case actions.GET_QUARTERLY_REPORT_ATTEMPT:
      {
        return update(state, {
          isFetching: {
            $set: true
          },
          error: {
            $set: false
          }
        });
      }
    case actions.GET_QUARTERLY_REPORT_SUCCESS:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          data: {
            $set: action.response
          }
        });
      }
    case actions.GET_QUARTERLY_REPORT_FAILURE:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          error: {
            $set: true
          }
        });
      }
    default:
      return state;
  }
};
export default quarterlyReports;