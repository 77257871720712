import { InvestorEntityType, InvestorEntityTypeName } from '@yieldstreet/platform-kit';
import { genTestIds } from '@yieldstreet/tool-kit';
import { PRE_INVESTMENT_STEPS } from 'pages/newCreateInvestorAccount/CreateInvestorAccount.model';
export const pageStepContent = {
  title: 'We need a little information about you',
  description: 'Before we start building your portfolio, we’re required by law to confirm the following information to verify your identity.',
  cta: {
    text: 'Get started',
    type: 'primary',
    callback: () => {}
  },
  step: {
    current: 1,
    items: PRE_INVESTMENT_STEPS
  }
};
export const formNames = {
  name: 'name',
  firstName: 'firstName',
  lastName: 'lastName',
  phone: 'phone',
  address: 'address'
};
export const entityTypeList = [{
  value: InvestorEntityType.SINGLE_MEMBER_LLC,
  label: 'Single member LLC'
}, {
  value: InvestorEntityType.SOLE_PROPRIETOR,
  label: 'Sole Proprietor'
}, {
  value: InvestorEntityType.PARTNERSHIP,
  label: InvestorEntityTypeName.PARTNERSHIP
}, {
  value: InvestorEntityType.S_CORPORATION,
  label: InvestorEntityTypeName.S_CORPORATION
}, {
  value: InvestorEntityType.C_CORPORATION,
  label: InvestorEntityTypeName.C_CORPORATION
}];
const {
  getTestIds
} = genTestIds(['form', 'submitButton', 'newInfoButton', 'errorNotification', 'nameField', 'firstNameField', 'lastNameField', 'entityNameField', 'prePopulatedCard']);
export const testIds = getTestIds('create-investor-account-name');