import _Link from "@mui/material/Link";
import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React, { useMemo, useCallback } from 'react';
import map from "lodash/map";
import { DesktopOnly } from '@yieldstreet/ui-kit';
import { FilterTypeEnum } from '@yieldstreet/tool-kit';
import { TEST_IDS } from './FilterView.model';
import { FilterHeader, FilterHeaderItem, SingleSelectFilterOption, SingleSelectFilterWrapper } from './FilterView.style';
export const SingleSelectFilter = _ref => {
  let {
    name = '',
    onChange = () => {},
    value,
    filter
  } = _ref;
  const {
    description = '',
    options
  } = filter;
  const renderOptions = useMemo(() => {
    if (!options) {
      return null;
    }
    return map(options, option => __jsx(SingleSelectFilterOption, {
      "data-cy": `${name}-${option.id}`,
      key: `${name}-${option.id}`,
      name: `${name}-${option.id}`,
      checked: option.id === value,
      onClick: () => {
        onChange(option.id === value ? null : option.id);
      }
    }, option.label && __jsx(_Typography, {
      variant: "body2",
      color: option.id === value ? 'textQuinary' : 'textPrimary',
      sx: {
        fontWeight: 'medium'
      }
    }, option.label), option.description && __jsx(_Typography, {
      component: "span",
      variant: "body2",
      color: option.id === value ? 'textQuinary' : 'textSecondary'
    }, option.description)));
  }, [name, onChange, options, value]);
  const reset = useCallback(() => {
    onChange(null);
  }, [onChange]);
  return __jsx("div", {
    "data-cy": TEST_IDS[FilterTypeEnum.SingleSelect]
  }, __jsx(DesktopOnly, null, __jsx(FilterHeader, null, __jsx(FilterHeaderItem, null, __jsx(_Typography, {
    variant: "body2"
  }, description)), __jsx(FilterHeaderItem, null, __jsx(_Typography, {
    variant: "body2"
  }, __jsx(_Link, {
    onClick: reset
  }, "Reset"))))), __jsx(SingleSelectFilterWrapper, null, renderOptions));
};