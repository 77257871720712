import styled from 'styled-components';
import { media } from '@yieldstreet/ui-kit';
export const ActionsWrapper = styled.div.withConfig({
  displayName: "DefaultBankstyle__ActionsWrapper",
  componentId: "sc-11uca7v-0"
})(["display:flex;flex-direction:column;gap:", ";"], _ref => {
  let {
    theme
  } = _ref;
  return theme.spacing.sm;
});
export const ContentWrapper = styled.div.withConfig({
  displayName: "DefaultBankstyle__ContentWrapper",
  componentId: "sc-11uca7v-1"
})(["width:100%;padding:", ";background:", ";", ""], _ref2 => {
  let {
    theme
  } = _ref2;
  return `${theme.spacing['2xl']} ${theme.spacing.sm}`;
}, _ref3 => {
  let {
    theme
  } = _ref3;
  return theme.colors.background_page;
}, media.phoneLandscape`
    display: flex;
    align-items: flex-start;
    justify-content: center;
		height: auto;
		padding: ${_ref4 => {
  let {
    theme
  } = _ref4;
  return theme.spacing.l;
}};
		text-align: center;
	`);
export const Content = styled.div.withConfig({
  displayName: "DefaultBankstyle__Content",
  componentId: "sc-11uca7v-2"
})(["margin:0 auto;width:100%;max-width:500px;"]);