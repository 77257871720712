export const navigationEmptyState = {
  params: {
    investorAccountId: '',
    irServiceInvestmentId: '',
    noteTitle: '',
    noteUrlHash: '',
    origin: ''
  },
  urls: {
    details: '',
    tracking: '',
    rollover: ''
  }
};