export const preventDocumentScrolling = () => {
  if (false) {
    return;
  }
  const docNode = document.getElementsByTagName('html')[0];
  if ((docNode === null || docNode === void 0 ? void 0 : docNode.nodeType) === 1) {
    docNode.style.cssText = `overflow: hidden;`;
  }
};
export const allowDocumentScrolling = () => {
  if (false) {
    return;
  }
  const docNode = document.getElementsByTagName('html')[0];
  if ((docNode === null || docNode === void 0 ? void 0 : docNode.nodeType) === 1) {
    docNode.style.cssText = ``;
  }
};