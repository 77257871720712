var __jsx = React.createElement;
import React, { useState } from 'react';
import styled from 'styled-components';
import { Heading, Paragraph } from '../../content/text';
import { Modal } from '../../elements/modal';
import { PageContainer } from '../../layout/page-container';
import { PageSection } from '../../layout';
import { media } from '../../themes/media';
import PlusIcon from './assets/plus.svg';
import QuotationIcon from './assets/icon.svg';
export const BioModalContainerV2 = _ref => {
  let {
    quote,
    quoteHeadingType = 4,
    quoteBy,
    bios
  } = _ref;
  const {
    0: showBio,
    1: setShowBio
  } = useState();
  return __jsx(PageSection, null, __jsx(PageContainer, null, __jsx(OuterContainer, null, __jsx(Container, null, __jsx(Bios, null, bios.map((bio, index) => __jsx(React.Fragment, null, __jsx(BioHolder, {
    key: `d-${index}`
  }, __jsx(ImageHolder, null, bio.image), __jsx(InfoHolder, null, __jsx(Paragraph, {
    small: true,
    semiBold: true
  }, bio.name), __jsx(Paragraph, {
    small: true
  }, bio.title)), __jsx(Link, {
    onClick: () => setShowBio(index)
  }, __jsx("img", {
    src: PlusIcon,
    width: "10",
    height: "10",
    alt: "Read Bio"
  }))), showBio === index && __jsx(MobileContent, {
    key: `m-${index}`
  }, bio.bio)))), __jsx(ContentHolder, null, __jsx(Icon, null, __jsx("img", {
    src: QuotationIcon,
    alt: "Quotation Marks",
    width: "55",
    height: "48"
  })), __jsx(SHeading, {
    type: quoteHeadingType
  }, quote), __jsx(Paragraph, null, quoteBy))), bios.map((bio, index) => showBio === index && __jsx(Modal, {
    clickClose: () => setShowBio(undefined),
    className: "modal",
    key: `modal-bio-${index}`
  }, __jsx(ModalHolder, null, __jsx(ModalLeftContainer, null, __jsx(ModalBioHolder, null, __jsx(ModalImageHolder, null, bio.image), __jsx(Paragraph, {
    small: true,
    semiBold: true
  }, bio.name), __jsx(Paragraph, {
    small: true
  }, bio.title))), __jsx(ModalRightContainer, null, bio.bio)))))));
};
const OuterContainer = styled.div.withConfig({
  displayName: "BioModalContainerV2__OuterContainer",
  componentId: "sc-1htsjzm-0"
})([".modal{display:none;", " > div{border:none;max-width:900px;}}"], media.desktop`
      display: -webkit-box;
    `);
const ModalHolder = styled.div.withConfig({
  displayName: "BioModalContainerV2__ModalHolder",
  componentId: "sc-1htsjzm-1"
})(["padding:20px 20px 20px 0;display:flex;"]);
const ModalLeftContainer = styled.div.withConfig({
  displayName: "BioModalContainerV2__ModalLeftContainer",
  componentId: "sc-1htsjzm-2"
})(["width:25%;"]);
const ModalRightContainer = styled.div.withConfig({
  displayName: "BioModalContainerV2__ModalRightContainer",
  componentId: "sc-1htsjzm-3"
})(["width:75%;p:not(:last-child){margin-bottom:20px;}"]);
const Container = styled.div.withConfig({
  displayName: "BioModalContainerV2__Container",
  componentId: "sc-1htsjzm-4"
})([""]);
const InfoHolder = styled.div.withConfig({
  displayName: "BioModalContainerV2__InfoHolder",
  componentId: "sc-1htsjzm-5"
})(["padding:0 20px;"]);
const ContentHolder = styled.div.withConfig({
  displayName: "BioModalContainerV2__ContentHolder",
  componentId: "sc-1htsjzm-6"
})(["width:100%;margin:60px auto;text-align:center;", ""], media.desktop`
    width: 40%;
  `);
const Icon = styled.div.withConfig({
  displayName: "BioModalContainerV2__Icon",
  componentId: "sc-1htsjzm-7"
})([""]);
const Bios = styled.div.withConfig({
  displayName: "BioModalContainerV2__Bios",
  componentId: "sc-1htsjzm-8"
})(["width:100%;display:flex;flex-direction:column;", " justify-content:center;"], media.desktop`
    flex-direction: row;
  `);
const SHeading = styled(Heading).withConfig({
  displayName: "BioModalContainerV2__SHeading",
  componentId: "sc-1htsjzm-9"
})(["margin-bottom:20px;"]);
const Link = styled.div.withConfig({
  displayName: "BioModalContainerV2__Link",
  componentId: "sc-1htsjzm-10"
})(["position:absolute;top:10px;cursor:pointer;right:10px;"]);
const MobileContent = styled.div.withConfig({
  displayName: "BioModalContainerV2__MobileContent",
  componentId: "sc-1htsjzm-11"
})(["display:block;text-align:left;margin-top:20px;p:not(:last-child){margin-bottom:20px;}", ""], media.desktop`
    display: none;
  `);
const BioHolder = styled.div.withConfig({
  displayName: "BioModalContainerV2__BioHolder",
  componentId: "sc-1htsjzm-12"
})(["position:relative;background:", ";border:1px solid ", ";width:100%;display:flex;padding:20px;margin:10px 0;", ""], props => props.theme.colors.background_card, props => props.theme.colors.border_default, media.desktop`
    width: 375px;
    margin: 0 20px;
  `);
const ImageHolder = styled.div.withConfig({
  displayName: "BioModalContainerV2__ImageHolder",
  componentId: "sc-1htsjzm-13"
})(["position:relative;width:60px;height:60px;overflow:hidden;flex-shrink:0;img{display:block;margin:0 auto;max-height:60px;}text-align:center;"]);
const ModalImageHolder = styled.div.withConfig({
  displayName: "BioModalContainerV2__ModalImageHolder",
  componentId: "sc-1htsjzm-14"
})(["position:relative;width:175px;margin:0 auto 20px auto;img{max-width:175px;}text-align:center;"]);
const ModalBioHolder = styled.div.withConfig({
  displayName: "BioModalContainerV2__ModalBioHolder",
  componentId: "sc-1htsjzm-15"
})(["text-align:center;", ""], media.desktop`
    padding: 0 20px 0 0;
  `);