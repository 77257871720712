import { v4 as uuidv4 } from 'uuid';
import { CALL_API } from '../middleware/Middleware';
export const FUND_DISTRIBUTION_OPTION_ATTEMPT = 'ys/fundDistributionOption/ATTEMPT';
export const FUND_DISTRIBUTION_OPTION_SUCCESS = 'ys/fundDistributionOption/SUCCESS';
export const FUND_DISTRIBUTION_OPTION_FAILURE = 'ys/fundDistributionOption/FAILURE';
export const getFundDistributionOption = (fundId, investorAccountId, dividendDistributionOption, capitalGainsDistributionOption) => dispatch => {
  const endpoint = `/api/fund/funds/${fundId}/accounts/${investorAccountId}`;
  const referenceId = uuidv4();
  return dispatch({
    [CALL_API]: {
      endpoint,
      options: {
        method: 'PATCH',
        data: {
          referenceId,
          dividendDistributionOption,
          capitalGainsDistributionOption
        }
      },
      types: [FUND_DISTRIBUTION_OPTION_ATTEMPT, FUND_DISTRIBUTION_OPTION_SUCCESS, FUND_DISTRIBUTION_OPTION_FAILURE],
      successPayload: {
        fundId,
        investorAccountId
      },
      failurePayload: {
        fundId,
        investorAccountId
      },
      getResponse: res => res.data
    }
  });
};