import _Box from "@mui/material/Box";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["small"];
var __jsx = React.createElement;
import React from 'react';
import { ButtonLink, SidePhotoContent } from '@yieldstreet/ui-kit';
import bgPluses from 'assets/images/images/bg_pluses.svg';
import { usePlatformPromo } from '../../../hooks/usePlatformPromo';
import { SidePhotoBg } from '../SidedPhoto.style';
import { Image } from './SidedPhotoDesktop.style';
export const SidedPhotoDesktop = _ref => {
  let {
      small
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  const {
    displayedMessage,
    trackAction,
    dismiss
  } = usePlatformPromo(props);
  return __jsx(_Box, {
    sx: {
      mb: 4
    }
  }, __jsx(SidePhotoBg, {
    bgColor: displayedMessage.configuration.bgColor,
    bgPluses: displayedMessage.configuration.plusBg && bgPluses,
    small: small
  }, __jsx(SidePhotoContent, _extends({
    removePaddingSide: true,
    removeFooterSpacing: true,
    removeHeaderSpacing: true,
    removeImageMargin: true,
    title: displayedMessage.configuration.title,
    backgroundImage: displayedMessage.configuration.isBackground && displayedMessage.configuration.imageLink,
    mainImage: __jsx("div", null, __jsx(Image, {
      small: small,
      src: displayedMessage.configuration.imageLink
    })),
    small: small,
    inverse: displayedMessage.configuration.inverse,
    dismiss: displayedMessage.configuration.dismissible ? dismiss : undefined
  }, displayedMessage), __jsx(ButtonLink, {
    buttonProps: {
      buttonType: displayedMessage.configuration.buttonType,
      inverse: displayedMessage.configuration.inverse
    },
    linkProps: {
      href: displayedMessage.configuration.path || displayedMessage.configuration.externalUrl,
      onClick: () => trackAction('ClickCTA'),
      rel: 'noopener noreferrer',
      target: displayedMessage.configuration.externalUrl ? '_blank' : ''
    }
  }, displayedMessage.configuration.buttonText))));
};