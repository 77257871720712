var __jsx = React.createElement;
import React, { useEffect } from 'react';
import { INVESTOR_ENTITY_KYC_PROVIDER_CONFIG } from 'pages/investorEntity/screens/KYC/providers';
import { getInvestorEntityRoute } from 'pages/investorEntity/utils';
import { useFlags } from '@yieldstreet/tool-kit';
import { investorEntityApi, InvestorEntityStepProvider, MarketplaceFilterQueryParameters } from '@yieldstreet/platform-kit';
import { PageLoader, PageError, Button, PageFlow, Paragraph, Heading, LoadingLogo, LoadingLogoType } from '@yieldstreet/ui-kit';
import { useAuthRedirect } from 'utils/auth';
import { useProviderInvestorEntity } from '../hooks';
import { testIds } from './SDIRACreateInvestorEntity.model';
import { PageWrapper, TextWrapper } from './SDIRACreateInvestorEntity.style';
import { ETC_PRODUCT_TOUR_MODAL } from '../../../marketplace/Marketplace/ETCProductTour/ETCProductTour.model';
export const SDIRACreateInvestorEntity = () => {
  const redirectTo = useAuthRedirect();
  const {
    provider,
    createBlocked,
    isLoading,
    investorEntity
  } = useProviderInvestorEntity();
  const [createProviderInvestorEntity, {
    isLoading: isCreatingInvestorEntity,
    error
  }] = investorEntityApi.useCreateProviderInvestorEntityMutation();
  const {
    enableEtcWalletGenie
  } = useFlags({
    enableEtcWalletGenie: false
  });
  useEffect(() => {
    if (!isLoading) {
      if (!createBlocked) {
        createProviderInvestorEntity({
          provider
        });
      } else {
        const route = getInvestorEntityRoute(investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.id, INVESTOR_ENTITY_KYC_PROVIDER_CONFIG[InvestorEntityStepProvider.ETC].route);
        const marketplaceRoute = `/offerings/?${MarketplaceFilterQueryParameters.IraEligible}=false#${ETC_PRODUCT_TOUR_MODAL}`;
        redirectTo('/set-password', enableEtcWalletGenie ? route : marketplaceRoute);
      }
    }
  }, [provider, createBlocked, isLoading, investorEntity, redirectTo, createProviderInvestorEntity, enableEtcWalletGenie]);
  if (error) {
    return __jsx(PageFlow, {
      showLogo: false,
      showFooter: false
    }, __jsx(PageWrapper, null, __jsx(PageError, {
      title: 'We were unable to create your account',
      content: __jsx(React.Fragment, null, __jsx(Paragraph, null, "Please wait a few minutes and try again."), __jsx(Paragraph, {
        marginTop: 'm'
      }, "If the problem persists, contact us on investments@yieldstreet.com.")),
      buttons: __jsx(Button, {
        buttonType: "basic",
        onClick: () => createProviderInvestorEntity({
          provider
        }),
        "data-cy": "retry-button"
      }, "Retry")
    })));
  }
  if (isCreatingInvestorEntity) {
    return __jsx(PageFlow, null, __jsx(PageWrapper, null, __jsx(LoadingLogo, {
      type: LoadingLogoType.DARK,
      size: {
        width: '200px',
        height: '200px'
      },
      testId: testIds.loader.id
    }), __jsx(TextWrapper, null, __jsx(Heading, {
      type: 4
    }, "Creating your investor account..."))));
  }
  return __jsx(PageLoader, null);
};