import { SignupQuizSteps } from '@yieldstreet/platform-kit';
export const getSignupStepFlow = () => {
  return {
    [SignupQuizSteps.EXPERIENCE_ALTS]: {
      nextStep: SignupQuizSteps.INVESTING_REASONS
    },
    [SignupQuizSteps.INVESTING_REASONS]: {
      prevStep: SignupQuizSteps.EXPERIENCE_ALTS,
      nextStep: SignupQuizSteps.INTERESTED_ASSET_CLASSES
    },
    [SignupQuizSteps.INTERESTED_ASSET_CLASSES]: {
      prevStep: SignupQuizSteps.INVESTING_REASONS,
      nextStep: SignupQuizSteps.PORTFOLIO
    },
    [SignupQuizSteps.PORTFOLIO]: {
      prevStep: SignupQuizSteps.INTERESTED_ASSET_CLASSES,
      nextStep: SignupQuizSteps.USUAL_INVESTING_WAY
    },
    [SignupQuizSteps.USUAL_INVESTING_WAY]: {
      prevStep: SignupQuizSteps.PORTFOLIO,
      redirect: SignupQuizSteps.SIGNUP
    }
  };
};
export const isValidSignupStep = step => {
  const validList = Object.keys(getSignupStepFlow());
  return validList.includes(step);
};
export const getSignupStepCount = step => {
  const flow = getSignupStepFlow();
  const stepOrder = Object.keys(flow);
  const currentStep = stepOrder.findIndex(a => a === step) + 1;
  let maxStep = stepOrder.reduce((previousValue, step) => {
    var _flow$step;
    // @ts-ignore
    return (_flow$step = flow[step]) !== null && _flow$step !== void 0 && _flow$step.informative ? previousValue : ++previousValue;
  }, 0);
  return {
    currentStep,
    maxStep
  };
};