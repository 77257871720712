import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["children", "rel", "id"];
var __jsx = React.createElement;
import React from 'react';
import { Link } from './styles';
export const ExternalLink = _ref => {
  let {
      children,
      rel = 'noopener noreferrer',
      id
    } = _ref,
    otherProps = _objectWithoutProperties(_ref, _excluded);
  return __jsx(Link, _extends({}, otherProps, {
    rel: rel,
    tabIndex: 0,
    "data-cy": id
  }), children);
};