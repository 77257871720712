export const testIds = {
  gatingMessageDescription: 'gating-message-description',
  gateTitle: 'gating-message-title',
  gateMessage: 'gating-message'
};
export const gateTexts = {
  createInvestorAccount: 'investor account',
  verifyIdentity: 'Continue verifying your identity',
  addBankAccount: 'bank account',
  verifyMicroDeposit: 'micro deposit',
  investmentPreferencesLink: 'Investment preferences',
  contactIR: 'contact Investor Relations',
  accreditationStatusLink: 'accreditation status',
  contactSupport: 'contact support'
};