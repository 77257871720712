import _Box from "@mui/material/Box";
import _Stack from "@mui/material/Stack";
import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { InvestorEntityTypeName } from '@yieldstreet/platform-kit';
import ChevronIcon from 'assets/icons/images/chevron-right.svg';
import { getInvestorEntityRoute } from 'pages/investorEntity/utils';
import { INVESTOR_ENTITY_MNGT_ROOT_PATH } from '../../ManagementContainer.model';
import { AccountBlock } from './EntityBlock.style';
import { testIds } from './EntityBlock.model';
import { Action } from './Action';
export const EntityBlock = _ref => {
  let {
    investorEntity,
    allEntitiesStatus,
    refetch
  } = _ref;
  const history = useHistory();
  const gotoEntityDetails = useCallback(() => history.push(getInvestorEntityRoute(`${investorEntity.id}`, INVESTOR_ENTITY_MNGT_ROOT_PATH)), [history, investorEntity.id]);
  return __jsx(AccountBlock, _extends({}, testIds.wrapper.attr, {
    onClick: () => gotoEntityDetails()
  }), __jsx(_Stack, {
    gap: 1
  }, __jsx(_Typography, _extends({
    variant: "h5"
  }, testIds.entityName.attr), investorEntity.name), __jsx(_Typography, _extends({
    variant: "body2"
  }, testIds.entityType.attr), InvestorEntityTypeName[investorEntity.type.name])), __jsx(_Stack, {
    direction: "row",
    justifyContent: "space-between",
    alignItems: "center"
  }, __jsx(Action, {
    investorEntity: investorEntity,
    allEntitiesStatus: allEntitiesStatus,
    refetch: refetch
  }), __jsx(_Box, _extends({
    component: "img",
    src: ChevronIcon
  }, testIds.arrowIcon.attr, {
    alt: "Arrow icon",
    sx: {
      width: {
        lg: 10
      },
      height: {
        lg: 10
      },
      '&:first-child': {
        marginLeft: '95%',
        marginBottom: 3
      }
    }
  }))));
};