import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["url"];
import { BASE_CONFIG } from './base-config';
import { queryParser } from './query-parser';
import { httpRequest } from './http-service';
export const baseRestQuery = function () {
  let {
    baseUrl
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    baseUrl: ''
  };
  return async (_ref, _ref2) => {
    let {
      extra
    } = _ref2;
    let {
        url
      } = _ref,
      params = _objectWithoutProperties(_ref, _excluded);
    const axiosInstance = extra;
    return await httpRequest(_objectSpread(_objectSpread({}, params), {}, {
      url: baseUrl + url,
      paramsSerializer: params => queryParser(params)
    }), axiosInstance).then(result => ({
      data: result.data,
      meta: result.headers
    })).catch(error => ({
      error
    }));
  };
};
export const BASE_REST_CONFIG = _objectSpread(_objectSpread({}, BASE_CONFIG), {}, {
  baseQuery: baseRestQuery()
});