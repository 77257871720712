import _Stack from "@mui/material/Stack";
import _Button from "@mui/material/Button";
import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useMemo } from 'react';
import groupBy from "lodash/groupBy";
import { useHistory } from 'react-router-dom';
import { NewestModal } from '@yieldstreet/ui-kit';
import { useBreakpoints } from '@yieldstreet/ui-kit/utils';
import { Modals } from 'constants/modal.model';
import { usePortfolioBaseConfig } from 'pages/portfolio/hooks/usePortfolioBaseConfig';
import { ACCOUNTS_MANAGEMENT_BANK_ACCOUNTS_FLOW } from 'pages/investorEntity/screens/Management/routes/BankAccounts/BankAccounts.model';
import { INVESTOR_ENTITY_ID_PARAM } from 'pages/managedPortfolio/ManagedPortfolio.model';
import { useTransactionsListData } from '../../hooks';
import { useTransactionsListParams } from '../../hooks/useTransactionsListParams';
import { ReturnPaymentsHeader } from './ReturnPaymentsHeader';
import { ReturnPaymentsDesktopBody } from './ReturnPaymentsDesktopBody';
import { ReturnPaymentsMobileBody } from './ReturnPaymentsMobileBody';
import { testIds } from './ReturnPaymentsModal.model';
export const ReturnPaymentsModal = _ref => {
  var _Object$entries;
  let {
    onClose
  } = _ref;
  const {
    isLgScreen,
    isXlScreen
  } = useBreakpoints();
  const {
    managementStrategy,
    userId
  } = usePortfolioBaseConfig();
  const {
    params
  } = useTransactionsListParams();
  const history = useHistory();
  const {
    returnedTransactions,
    returnedTransactionsIsFetching,
    returnedTransactionsIsError
  } = useTransactionsListData({
    params,
    userId,
    managementStrategy
  });
  const returnedTransactionsByEntity = useMemo(() => groupBy(returnedTransactions, 'investorEntityId'), [returnedTransactions]);
  const showMultipleEntities = useMemo(() => Object.keys(returnedTransactionsByEntity).length > 1, [returnedTransactionsByEntity]);
  return __jsx(NewestModal, _extends({
    modalId: Modals.ReturnPayments,
    onClose: onClose
  }, testIds.base.attr), __jsx(_Stack, {
    sx: {
      padding: 6,
      backgroundColor: 'white'
    }
  }, __jsx(ReturnPaymentsHeader, null), __jsx(_Stack, {
    gap: 10
  }, (_Object$entries = Object.entries(returnedTransactionsByEntity)) === null || _Object$entries === void 0 ? void 0 : _Object$entries.map(_ref2 => {
    let [investorEntityId, returnedTransactions] = _ref2;
    const investorEntityTransaction = returnedTransactions.find(transaction => transaction.investorEntityId.toString() === investorEntityId);
    const bankAccountUrl = ACCOUNTS_MANAGEMENT_BANK_ACCOUNTS_FLOW.replace(INVESTOR_ENTITY_ID_PARAM, investorEntityId);
    return __jsx(_Stack, null, __jsx(_Stack, {
      sx: {
        mb: 6
      }
    }, showMultipleEntities ? __jsx(_Typography, _extends({
      variant: "h6",
      sx: {
        mb: 4
      }
    }, testIds.investorEntityName.attr), investorEntityTransaction === null || investorEntityTransaction === void 0 ? void 0 : investorEntityTransaction.investorAccountName) : null, __jsx(_Button, _extends({
      disabled: returnedTransactionsIsFetching || !investorEntityId,
      sx: {
        width: {
          xs: 'initial',
          lg: 'fit-content'
        }
      },
      onClick: () => {
        onClose();
        history.push(bankAccountUrl);
      }
    }, testIds.button.attr), "Set preferred bank")), isLgScreen || isXlScreen ? __jsx(ReturnPaymentsDesktopBody, {
      returnedTransactions: returnedTransactions,
      isFetching: returnedTransactionsIsFetching,
      isError: returnedTransactionsIsError
    }) : __jsx(ReturnPaymentsMobileBody, {
      returnedTransactions: returnedTransactions,
      isFetching: returnedTransactionsIsFetching,
      isError: returnedTransactionsIsError
    }));
  }))));
};