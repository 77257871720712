import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["type", "children", "tagType"];
var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { TextBaseStyle } from './TextBase';
import { getHeaderStyle } from './Heading';
export const TitleHeading = _ref => {
  let {
      type,
      children,
      tagType = 'span'
    } = _ref,
    otherProps = _objectWithoutProperties(_ref, _excluded);
  return __jsx(TitleHeadingElement, _extends({
    type: type,
    as: tagType
  }, otherProps), children);
};
const TitleHeadingElement = styled.span.withConfig({
  displayName: "TitleHeading__TitleHeadingElement",
  componentId: "sc-11d70t2-0"
})(["", ";", ";display:block;"], TextBaseStyle, props => getHeaderStyle(props.type));