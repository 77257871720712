var __jsx = React.createElement;
import React from 'react';
import { Heading, LogoBanner } from '@yieldstreet/ui-kit';
import { Border, SHeroBanner, WebView, MobileView, SParagraph, SCarousel } from './FeaturedManagers.style';
import { logos } from './FeaturedManagers.model';
export const FeaturedManagers = () => __jsx(Border, null, __jsx(WebView, null, __jsx(SHeroBanner, {
  title: "Featured managers",
  headingType: 3,
  sideContent: __jsx(LogoBanner, {
    logos: logos
  })
})), __jsx(MobileView, null, __jsx(Heading, {
  type: 2
}, "Featured managers"), __jsx(SParagraph, {
  size: "small"
}, "Diversified funds by the following managers will serve as the anchor of your portfolio."), __jsx(SCarousel, {
  slidesToShow: 1.5,
  framePadding: "10px",
  cellSpacing: 20,
  defaultControlsConfig: {
    nextButtonStyle: {
      display: 'none'
    },
    prevButtonStyle: {
      display: 'none'
    }
  }
}, logos.map(logo => __jsx("img", {
  src: logo.image,
  key: logo.alt,
  alt: logo.alt
})))));