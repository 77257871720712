var __jsx = React.createElement;
import React from 'react';
import { useProviderMigrationLaunch } from '@yieldstreet/tool-kit';
import { useModalContext } from '@yieldstreet/ui-kit';
import { KYCMigrationModal } from './KYCMigrationModal';
import { KYC_MIGRATION_MODAL } from './KYCMigrationModal.model';
import { useIsMobileApp } from 'utils/mobileApp';
export const KYCMigrationModalLauncher = () => {
  const {
    showModal
  } = useModalContext();
  const isMobileApp = useIsMobileApp();
  useProviderMigrationLaunch(() => showModal(KYC_MIGRATION_MODAL), isMobileApp);
  return __jsx(KYCMigrationModal, null);
};