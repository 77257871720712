var __jsx = React.createElement;
import React from 'react';
import { Paragraph } from '@yieldstreet/ui-kit';
import { content, testIds } from './InvestorAccountEmpty.model';
import { InvestorAccountEmptyContent, InvestorAccountEmptyWrapper } from './InvestorAccountEmpty.style';
import warningIcon from './assets/warning-icon.svg';
export const InvestorAccountEmpty = () => __jsx(InvestorAccountEmptyWrapper, {
  "data-cy": testIds.investorAccountEmpty
}, __jsx("img", {
  src: warningIcon,
  alt: content.warningTitle
}), __jsx(InvestorAccountEmptyContent, null, __jsx(Paragraph, {
  semiBold: true
}, content.warningTitle), __jsx(Paragraph, {
  small: true
}, content.warningBody)));