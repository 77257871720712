export const jsonPages = {
  containers: [{
    title: 'Overview',
    subtitle: 'Link: [main document](https://www.yieldstreet.com/LINKTOMAINDOCUMENT)',
    hideTitle: false,
    sections: [{
      type: 'paragraphBox',
      title: '',
      video: '',
      image: 'https://cdn2.yieldstreet.com/wp-content/uploads/2020/09/09132245/sources-park-slope-urban-standard.jpg',
      imageCaption: 'captionnnnnnnnnnn',
      mediaTitle: 'media title',
      interactiveMap: '',
      charLimit: 1000,
      content: ['Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu risus quis varius quam. Non tellus orci ac auctor augue mauris augue neque. Eget sit amet tellus cras adipiscing. Vestibulum mattis ullamcorper velit sed ullamcorper. Ornare arcu dui vivamus arcu felis bibendum ut tristique et. Laoreet sit amet cursus sit amet dictum. Orci nulla pellentesque dignissim enim sit amet venenatis urna. In nisl nisi scelerisque eu ultrices v', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu risus quis varius quam. Non tellus orci ac auctor augue mauris augue neque. Eget sit amet tellus cras adipiscing. Vestibulum mattis ullamcorper velit sed ullamcorper. Ornare arcu dui vivamus arcu felis bibendum ut tristique et. Laoreet sit amet cursus sit amet dictum. Orci nulla pellentesque dignissim enim sit amet venenatis urna. In nisl nisi scelerisque eu ultrices v', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu risus quis varius quam. Non tellus orci ac auctor augue mauris augue neque. Eget sit amet tellus cras adipiscing. Vestibulum mattis ullamcorper velit sed ullamcorper. Ornare arcu dui vivamus arcu felis bibendum ut tristique et. Laoreet sit amet cursus sit amet dictum. Orci nulla pellentesque dignissim enim sit amet venenatis urna. In nisl nisi scelerisque eu ultrices v'],
      subtitle: '',
      iconName: '',
      carousel: []
    }, {
      type: 'mediaCards',
      resources: [],
      content: [{
        type: 'mainDocument',
        title: 'SNS',
        description: 'Lorem ipsum dolor sit amet, consectetur ',
        iconName: 'download',
        customUrl: '',
        previewPdfPage: ''
      }, {
        type: 'mainDocument',
        title: 'View risk factors',
        description: 'Lorem ipsum dolor sit amet, consectetur ',
        iconName: 'risk',
        customUrl: '',
        previewPdfPage: ''
      }]
    }, {
      type: 'metricCards',
      content: [{
        title: 'Yield',
        fields: [{
          label: 'Gross yield',
          description: '0.0%'
        }, {
          label: 'Yieldstreet fee',
          description: '0.0%'
        }, {
          label: 'Servicing fee',
          description: '0.0%'
        }, {
          label: 'Target yield',
          description: '0.0%',
          extraSpace: true
        }, {
          label: 'Interest Type',
          description: '_______',
          tooltipMessage: 'This is a message for the tooltip.'
        }]
      }, {
        title: 'Schedule',
        fields: [{
          label: 'Payment schedule',
          description: '________',
          color: 'dark',
          tooltipMessage: 'This is a message for the tooltip.'
        }, {
          label: 'Prefunded',
          description: '___',
          color: 'dark'
        }, {
          label: 'Maturity',
          description: 'Date',
          color: 'light',
          size: 'small',
          extraSpace: true
        }, {
          label: 'Loan maturity',
          description: 'YYYY-MM-DD',
          color: 'dark'
        }]
      }, {
        title: 'Structure',
        fields: [{
          label: 'Tax document',
          description: '_____'
        }, {
          label: 'Offering structure',
          description: '____'
        }, {
          label: 'Expenses',
          color: 'light',
          size: 'small',
          extraSpace: true
        }, {
          label: 'First year expense',
          description: '$___'
        }, {
          label: 'Annual flat expense',
          description: '$__'
        }]
      }]
    }, {
      type: 'survey',
      title: 'Help us improve your experience',
      description: 'Answering our questions will help us bring you better offerings and a more relevant experience',
      questionLabel: 'Do you like this offering?',
      surveyId: '9NYTWH7'
    }, {
      type: 'noteBanner',
      title: 'Important Notes',
      note: '',
      content: ['This is a sentence with **bold** and a [link](http://www.google.com)']
    }]
  }, {
    title: 'Essentials',
    subtitle: 'Please refer to the [main document](https://www.yieldstreet.com/LINKTOMAINDOCUMENT) for any additional details.',
    hideTitle: false,
    sections: [{
      type: 'paragraphBox',
      title: 'Lorem ipsum',
      subtitle: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
      iconName: 'flexibility',
      video: '',
      image: '',
      imageCaption: '',
      mediaTitle: '',
      interactiveMap: '',
      charLimit: 1000,
      content: ['Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu risus quis varius quam. Non tellus orci ac auctor augue mauris augue neque. Eget sit amet tellus cras adipiscing. Vestibulum mattis ullamcorper velit sed ullamcorper. Ornare arcu dui vivamus arcu felis bibendum ut tristique et. Laoreet sit amet cursus sit amet dictum. Orci nulla pellentesque dignissim enim sit amet venenatis urna. In nisl nisi scelerisque eu ultrices v'],
      carousel: [{
        imageUrl: 'https://cdn2.yieldstreet.com/wp-content/uploads/2020/09/09132245/sources-park-slope-urban-standard.jpg',
        imageCaption: 'caption 1'
      }, {
        imageUrl: 'https://cdn2.yieldstreet.com/wp-content/uploads/2020/09/09132246/uses-park-slope-urban-standard.jpg',
        imageCaption: 'caption 2'
      }, {
        imageUrl: 'https://cdn2.yieldstreet.com/wp-content/uploads/2020/09/15135950/portfolio-construction-transaction.jpg',
        imageCaption: 'caption 3'
      }]
    }, {
      type: 'paragraphBox',
      title: 'Lorem ipsum',
      subtitle: '',
      iconName: 'cashFlow',
      video: 'https://player.vimeo.com/video/430433955',
      image: '',
      imageCaption: '',
      mediaTitle: '',
      interactiveMap: '',
      charLimit: 1000,
      content: ['Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu risus quis varius quam. Non tellus orci ac auctor augue mauris augue neque. Eget sit amet tellus cras adipiscing. Vestibulum mattis ullamcorper velit sed ullamcorper. Ornare arcu dui vivamus arcu felis bibendum ut tristique et. Laoreet sit amet cursus sit amet dictum. Orci nulla pellentesque dignissim enim sit amet venenatis urna. In nisl nisi scelerisque eu ultrices v'],
      carousel: []
    }, {
      type: 'paragraphBox',
      title: 'Lorem ipsum',
      subtitle: '',
      iconName: 'sourcesUses',
      video: '',
      image: '',
      imageCaption: '',
      mediaTitle: '',
      interactiveMap: 'https://www.google.com/maps/embed?origin=mfe&pb=!1m3!2m1!1s12.927923,77.627108!6i15',
      charLimit: 1000,
      content: ['Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu risus quis varius quam. Non tellus orci ac auctor augue mauris augue neque. Eget sit amet tellus cras adipiscing. Vestibulum mattis ullamcorper velit sed ullamcorper. Ornare arcu dui vivamus arcu felis bibendum ut tristique et. Laoreet sit amet cursus sit amet dictum. Orci nulla pellentesque dignissim enim sit amet venenatis urna. In nisl nisi scelerisque eu ultrices v'],
      carousel: []
    }]
  }, {
    title: 'Highlights',
    subtitle: '',
    hideTitle: false,
    sections: [{
      type: 'carousel',
      title: '',
      autoplayInterval: 60000,
      content: [{
        slideTitle: 'Lorem ipsum',
        slideText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu risus quis varius quam. Non tellus orci ac auctor augue mauris augue neque. Eget sit amet tellus cras adipiscing. Vestibulum mattis ullamcorper velit sed ullamcorper. Ornare arcu dui vivamus arcu felis bibendum ut tristique et. Laoreet sit amet cursus sit amet dictum. Orci nulla pellentesque dignissim enim sit amet venenatis urna. In nisl nisi scelerisque eu ultrices v'
      }, {
        slideTitle: 'Lorem ipsum 2',
        slideText: 'Lorem ipsum 2'
      }]
    }]
  }, {
    title: 'Resources',
    subtitle: 'Lorem ipsum subtitle',
    hideTitle: false,
    sections: [{
      type: 'mediaCards',
      resources: ['all'],
      content: [{
        type: 'custom',
        title: 'Lorem ipsum',
        description: 'Lorem ipsum content description',
        iconName: 'note',
        customUrl: 'www.yieldstreet.com',
        previewPdfPage: ''
      }]
    }, {
      type: 'disclaimer',
      useLegalEntityDisclaimer: true,
      content: ['Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu risus quis varius quam. Non tellus orci ac auctor augue mauris augue neque. Eget sit amet tellus cras adipiscing. Vestibulum mattis ullamcorper velit sed ullamcorper. Ornare arcu dui vivamus arcu felis bibendum ut tristique et. Laoreet sit amet cursus sit amet dictum. Orci nulla pellentesque dignissim enim sit amet venenatis urna. In nisl nisi scelerisque eu ultrices v', 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Arcu risus quis varius quam. Non tellus orci ac auctor augue mauris augue neque. Eget sit amet tellus cras adipiscing. Vestibulum mattis ullamcorper velit sed ullamcorper. Ornare arcu dui vivamus arcu felis bibendum ut tristique et. Laoreet sit amet cursus sit amet dictum. Orci nulla pellentesque dignissim enim sit amet venenatis urna. In nisl nisi scelerisque eu ultrices v']
    }]
  }]
};