import { ALL } from '@yieldstreet/tool-kit';
export const content = {
  portfolio: 'Portfolio',
  selectAccount: 'Select Account'
};
export const testIds = {
  investorAccountWrapper: 'investor-account-wrapper',
  investorAccountViewingAs: 'investor-account-viewing-as',
  investorAccountSelectWrapper: 'investor-account-wrapper',
  investorAccountIsLoading: 'investor-account-is-loading',
  investorAccountDropdownIcon: 'investor-account-dropdown-icon',
  investorAccountSelectedInvestor: 'investor-account-selected-investor',
  investorAccountDropdownMenu: 'investor-account-dropdown-menu'
};
export const INVESTOR_ACCOUNT_DEEP_LINK = 'InvestorAccounts';
export const ALL_ACCOUNTS = {
  id: ALL,
  name: 'All accounts'
};
export const viewPortMobile = 'mobile';