import { stringify } from 'query-string';
import { CALL_API } from '../middleware/Middleware';
export const GET_INVESTOR_ACCOUNT_BY_ID_ATTEMPT = 'ys/account/investor/id/ATTEMPT';
export const GET_INVESTOR_ACCOUNT_BY_ID_SUCCESS = 'ys/account/investor/id/SUCCESS';
export const GET_INVESTOR_ACCOUNT_BY_ID_FAILURE = 'ys/account/investor/id/FAILURE';
export const getInvestorAccountById = accountId => dispatch => {
  const endpoint = `/a/api/investor/account/${accountId}`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_INVESTOR_ACCOUNT_BY_ID_ATTEMPT, GET_INVESTOR_ACCOUNT_BY_ID_SUCCESS, GET_INVESTOR_ACCOUNT_BY_ID_FAILURE],
      getResponse: res => res.data.account
    }
  });
};
export const GET_FILTERED_INVESTOR_ACCOUNTS_ATTEMPT = 'ys/accounts/filtered/investor/ATTEMPT';
export const GET_FILTERED_INVESTOR_ACCOUNTS_SUCCESS = 'ys/accounts/filtered/investor/SUCCESS';
export const GET_FILTERED_INVESTOR_ACCOUNTS_FAILURE = 'ys/accounts/filtered/investor/FAILURE';
export const getFilteredInvestorAccounts = accountFilters => dispatch => {
  const queryString = accountFilters ? `?${stringify(accountFilters)}` : '';
  let endpoint = `/a/api/investor/account${queryString}`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_FILTERED_INVESTOR_ACCOUNTS_ATTEMPT, GET_FILTERED_INVESTOR_ACCOUNTS_SUCCESS, GET_FILTERED_INVESTOR_ACCOUNTS_FAILURE],
      getResponse: res => res.data.accounts
    }
  });
};
export const GET_INVESTOR_ACCOUNTS_BY_ID_ATTEMPT = 'ys/accounts/investor/byId/ATTEMPT';
export const GET_INVESTOR_ACCOUNTS_BY_ID_SUCCESS = 'ys/accounts/investor/byId/SUCCESS';
export const GET_INVESTOR_ACCOUNTS_BY_ID_FAILURE = 'ys/accounts/investor/byId/FAILURE';
export const getInvestorAccountsById = userId => dispatch => {
  const endpoint = `/a/api/users/${userId}/accounts`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_INVESTOR_ACCOUNTS_BY_ID_ATTEMPT, GET_INVESTOR_ACCOUNTS_BY_ID_SUCCESS, GET_INVESTOR_ACCOUNTS_BY_ID_FAILURE],
      successPayload: {
        userId
      },
      getResponse: res => res.data.accounts
    }
  });
};
export const GET_ALL_INVESTOR_STATUSES_ATTEMPT = 'ys/accounts/all/investor/status/ATTEMPT';
export const GET_ALL_INVESTOR_STATUSES_SUCCESS = 'ys/accounts/all/investor/status/SUCCESS';
export const GET_ALL_INVESTOR_STATUSES_FAILURE = 'ys/accounts/all/investor/status/FAILURE';
export const GET_USER_PROFILE_BY_ID_ATTEMPT = 'ys/accounts/userProfile/byId/ATTEMPT';
export const GET_USER_PROFILE_BY_ID_SUCCESS = 'ys/accounts/userProfile/byId/SUCCESS';
export const GET_USER_PROFILE_BY_ID_FAILURE = 'ys/accounts/userProfile/byId/FAILURE';
export const getUserProfileById = userId => dispatch => {
  const endpoint = `/a/api/user/${userId}`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_USER_PROFILE_BY_ID_ATTEMPT, GET_USER_PROFILE_BY_ID_SUCCESS, GET_USER_PROFILE_BY_ID_FAILURE],
      successPayload: {
        userId
      },
      getResponse: res => res.data.profile
    }
  });
};
export const GET_INVESTOR_BANK_ACCT_ATTEMPT = 'ys/accounts/investor/bankAccts/ATTEMPT';
export const GET_INVESTOR_BANK_ACCT_SUCCESS = 'ys/accounts/investor/bankAccts/SUCCESS';
export const GET_INVESTOR_BANK_ACCT_FAILURE = 'ys/accounts/investor/bankAccts/FAILURE';
export const getInvestorBankAccounts = id => dispatch => {
  const endpoint = `/a/api/investor-entity/${id}/bank-account`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_INVESTOR_BANK_ACCT_ATTEMPT, GET_INVESTOR_BANK_ACCT_SUCCESS, GET_INVESTOR_BANK_ACCT_FAILURE],
      successPayload: {
        id
      },
      getResponse: res => {
        return res.data.bankAccounts;
      }
    }
  });
};
export const GET_INVESTOR_ACCREDITATION_ATTEMPT = 'ys/accounts/investor/accreditation/ATTEMPT';
export const GET_INVESTOR_ACCREDITATION_SUCCESS = 'ys/accounts/investor/accreditation/SUCCESS';
export const GET_INVESTOR_ACCREDITATION_FAILURE = 'ys/accounts/investor/accreditation/FAILURE';
export const getAccreditation = (investorId, aiqId) => dispatch => {
  const endpoint = `/a/api/investor-entity/${investorId}/aiq/${aiqId}`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_INVESTOR_ACCREDITATION_ATTEMPT, GET_INVESTOR_ACCREDITATION_SUCCESS, GET_INVESTOR_ACCREDITATION_FAILURE],
      getResponse: res => res.data.aiq
    }
  });
};
export const GET_VERIFICATION_ATTEMPT = 'ys/accounts/verification/ATTEMPT';
export const GET_VERIFICATION_SUCCESS = 'ys/accounts/verification/SUCCESS';
export const GET_VERIFICATION_FAILURE = 'ys/accounts/verification/FAILURE';
export const getVerification = token => dispatch => {
  const endpoint = `/a/api/aiq/verification/${token}`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_VERIFICATION_ATTEMPT, GET_VERIFICATION_SUCCESS, GET_VERIFICATION_FAILURE],
      getResponse: res => res.data
    }
  });
};
export const REJECT_VERIFICATION_ATTEMPT = 'ys/accounts/verification/reject/ATTEMPT';
export const REJECT_VERIFICATION_SUCCESS = 'ys/accounts/verification/reject/SUCCESS';
export const REJECT_VERIFICATION_FAILURE = 'ys/accounts/verification/reject/FAILURE';
export const rejectVerification = token => dispatch => {
  const endpoint = `/a/api/aiq/invalid/${token}`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [REJECT_VERIFICATION_ATTEMPT, REJECT_VERIFICATION_SUCCESS, REJECT_VERIFICATION_FAILURE],
      options: {
        method: 'POST'
      }
    }
  });
};
export const CONFIRM_VERIFICATION_ATTEMPT = 'ys/accounts/verification/confirm/ATTEMPT';
export const CONFIRM_VERIFICATION_SUCCESS = 'ys/accounts/verification/confirm/SUCCESS';
export const CONFIRM_VERIFICATION_FAILURE = 'ys/accounts/verification/confirm/FAILURE';
export const confirmVerification = (data, token) => dispatch => {
  const endpoint = `/a/api/aiq/verification/${token}`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [CONFIRM_VERIFICATION_ATTEMPT, CONFIRM_VERIFICATION_SUCCESS, CONFIRM_VERIFICATION_FAILURE],
      options: {
        method: 'POST',
        data
      }
    }
  });
};
export const DEPOSIT_VERIFICATION_ATTEMPT = 'ys/accounts/deposit/verification/ATTEMPT';
export const DEPOSIT_VERIFICATION_SUCCESS = 'ys/accounts/deposit/verification/SUCCESS';
export const DEPOSIT_VERIFICATION_FAILURE = 'ys/accounts/deposit/verification/FAILURE';
export const RESET_SAVED_ACCOUNT = 'ys/accounts/resetSavedAccount';
export const resetSavedAccount = () => dispatch => dispatch({
  type: RESET_SAVED_ACCOUNT
});
export const RESET_FORM = 'ys/accounts/resetForm';
export const resetForm = () => dispatch => dispatch({
  type: RESET_FORM
});
export const RESET_STORE = 'ys/accounts/reset';
export const reset = () => dispatch => dispatch({
  type: RESET_STORE
});
export const CREATE_IRA_ACCOUNT_ATTEMPT = 'ys/accounts/ira/create/ATTEMPT';
export const CREATE_IRA_ACCOUNT_SUCCESS = 'ys/accounts/ira/create/SUCCESS';
export const CREATE_IRA_ACCOUNT_FAILURE = 'ys/accounts/ira/create/FAILURE';
export const createIraAccount = type => dispatch => {
  const endpoint = '/a/api/ira/account';
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [CREATE_IRA_ACCOUNT_ATTEMPT, CREATE_IRA_ACCOUNT_SUCCESS, CREATE_IRA_ACCOUNT_FAILURE],
      options: {
        method: 'POST',
        data: {
          type
        }
      },
      attemptPayload: {
        actionType: 'create'
      },
      failurePayload: {
        actionType: 'create'
      },
      getResponse: res => res.data.generatedId
    }
  });
};
export const GET_USER_WALLET_VALUES_ATTEMPT = 'ys/user/wallets/ATTEMPT';
export const GET_USER_WALLET_VALUES_SUCCESS = 'ys/user/wallets/SUCCESS';
export const GET_USER_WALLET_VALUES_FAILURE = 'ys/user/wallets/FAILURE';
export const getUserWallet = userId => dispatch => {
  const endpoint = `/a/api/user/${userId}/wallets`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_USER_WALLET_VALUES_ATTEMPT, GET_USER_WALLET_VALUES_SUCCESS, GET_USER_WALLET_VALUES_FAILURE],
      getResponse: res => res.data.wallets
    }
  });
};
export const GET_ROLLOVER_ATTEMPT = 'ys/accounts/rollover/ATTEMPT';
export const GET_ROLLOVER_SUCCESS = 'ys/accounts/rollover/SUCCESS';
export const GET_ROLLOVER_FAILURE = 'ys/accounts/rollover/FAILURE';
export const getRolloverData = () => dispatch => {
  const endpoint = '/a/api/investment-request/investments/eligible-rollover';
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_ROLLOVER_ATTEMPT, GET_ROLLOVER_SUCCESS, GET_ROLLOVER_FAILURE],
      getResponse: res => res.data
    }
  });
};