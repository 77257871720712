var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import BulletImage from '../../assets/graphics/icons/checkmark.svg';
import { Heading, Paragraph } from '../../content/text';
import { media } from '../../themes/media';
export const BulletBanner = _ref => {
  let {
    title,
    bullets,
    direction = 'row',
    footerContent,
    bulletColor = 'primary',
    bulletSize = 'small',
    backgroundImg,
    headingTagType = 2,
    headingType = 2,
    className,
    fixedWidth = false
  } = _ref;
  return __jsx(Wrapper, {
    fixedWidth: fixedWidth,
    backgroundImg: backgroundImg,
    className: className
  }, __jsx(SHeading, {
    inverse: true,
    type: headingType,
    tagType: headingTagType,
    className: "title",
    fixedWidth: fixedWidth
  }, title), __jsx(BulletsWrapper, {
    className: "bulletsWrapper",
    direction: direction,
    fixedWidth: fixedWidth
  }, bullets.map((bullet, index) => __jsx(Bullet, {
    className: "bullet",
    fixedWidth: fixedWidth,
    key: `${bullet.title}-${index}`
  }, bullet.icon ? bullet.icon : __jsx(BulletIconHolder, {
    color: bulletColor,
    fixedWidth: fixedWidth,
    size: bulletSize,
    className: "bulletIconHolder"
  }, __jsx(BulletIcon, {
    src: BulletImage,
    size: bulletSize
  })), __jsx(BulletContent, {
    fixedWidth: fixedWidth,
    className: "bulletContent"
  }, bullet.title && __jsx(SHeadingBullet, {
    type: 5,
    inverse: true
  }, bullet.title), __jsx(Paragraph, {
    inverse: true
  }, bullet.text))))), footerContent && __jsx(FooterWrapper, null, footerContent));
};
const Wrapper = styled.div.withConfig({
  displayName: "BulletBanner__Wrapper",
  componentId: "sc-1kg5dkd-0"
})(["background-color:", ";padding:", ";", ""], props => props.theme.colors.background_inverse, props => props.fixedWidth ? '40px 35px' : '20px', media.desktop`
    ${props => props.fixedWidth && `
      padding: 80px 35px;
    `}

    background-repeat: no-repeat;
    background-position: -20% 175%, 120% 175%;
    background-image: ${props => `url(${props.backgroundImg}), url(${props.backgroundImg})`};
  `);
const SHeading = styled(Heading).withConfig({
  displayName: "BulletBanner__SHeading",
  componentId: "sc-1kg5dkd-1"
})(["", ""], media.desktop`
      text-align: center;
    `);
const BulletsWrapper = styled.div.withConfig({
  displayName: "BulletBanner__BulletsWrapper",
  componentId: "sc-1kg5dkd-2"
})(["align-items:center;display:flex;flex-direction:column;margin-top:", ";justify-content:center;", " ", ""], props => props.fixedWidth ? '60px' : '40px', media.tablet`
    align-items: flex-start;
  `, media.desktop`
    ${props => props.direction && `flex-direction: ${props.direction}`}
  `);
const Bullet = styled.div.withConfig({
  displayName: "BulletBanner__Bullet",
  componentId: "sc-1kg5dkd-3"
})(["display:flex;flex-direction:row;justify-content:flex-start;margin:", ";width:100%;", ""], props => props.fixedWidth ? '0 30px 30px' : '30px', media.tablet`
      width: auto;
  `);
const BulletContent = styled.div.withConfig({
  displayName: "BulletBanner__BulletContent",
  componentId: "sc-1kg5dkd-4"
})(["text-align:left;width:100%;", ""], media.desktop`
    ${props => props.fixedWidth && `
      width: 200px
    `};
  `);
const SHeadingBullet = styled(Heading).withConfig({
  displayName: "BulletBanner__SHeadingBullet",
  componentId: "sc-1kg5dkd-5"
})(["font-weight:500;margin-bottom:5px;"]);
const BulletIconHolder = styled.div.withConfig({
  displayName: "BulletBanner__BulletIconHolder",
  componentId: "sc-1kg5dkd-6"
})(["background:", ";border-radius:50%;width:", ";height:", ";flex-shrink:0;margin-right:", ";display:flex;justify-content:center;align-content:center;"], props => props.color === 'primary' ? props.theme.colors.input_button_background : props.theme.colors.icon_inverse, props => props.size === 'small' ? '27px' : '40px', props => props.size === 'small' ? '27px' : '40px', props => props.fixedWidth ? '20px' : '10px');
const BulletIcon = styled.img.withConfig({
  displayName: "BulletBanner__BulletIcon",
  componentId: "sc-1kg5dkd-7"
})(["width:", ";"], props => props.size === 'small' ? '15px' : '20px');
const FooterWrapper = styled.div.withConfig({
  displayName: "BulletBanner__FooterWrapper",
  componentId: "sc-1kg5dkd-8"
})(["text-align:left;width:100%;", ""], media.tablet`
    margin: auto;
    text-align: center;
  `);