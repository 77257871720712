import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  expirationTime: null
};
const investmentRequestTimerSlice = createSlice({
  name: 'investmentRequestTimer',
  initialState,
  reducers: {
    setInvestmentRequestExpirationTime: (state, action) => {
      state.expirationTime = Date.now() + action.payload;
    },
    resetInvestmentRequestExpirationTime: state => {
      state.expirationTime = null;
    }
  }
});
export const {
  setInvestmentRequestExpirationTime,
  resetInvestmentRequestExpirationTime
} = investmentRequestTimerSlice.actions;
export const investmentRequestTimerReducer = investmentRequestTimerSlice.reducer;