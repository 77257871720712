import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import pickBy from "lodash/pickBy";
import { getExtraUserContext } from './getFlagsContext.utils';
export const getFlagsContext = _ref => {
  let {
    anonymousId,
    userId,
    host,
    userState
  } = _ref;
  const userKey = userId || anonymousId;
  const userContext = _objectSpread({
    key: userKey,
    anonymous: !userId
  }, getExtraUserContext(userState));
  const subdomain = host === null || host === void 0 ? void 0 : host.split('.').shift();
  const featureKey = host !== null && host !== void 0 && host.endsWith('yieldstreet.cloud') ? subdomain : null;
  const featureContext = featureKey ? {
    key: featureKey
  } : null;
  const context = {
    kind: 'multi',
    user: userContext,
    feature: featureContext
  };
  return pickBy(context, value => value);
};