import { QueryStatus } from '@reduxjs/toolkit/query';
export function hasQueryInitialized(status) {
  return status !== QueryStatus.uninitialized;
}
export function isQueryFulfilled(status) {
  return status === QueryStatus.fulfilled;
}
export function isQueryRejected(status) {
  return status === QueryStatus.rejected;
}
export function isQueryPending(status) {
  return status === QueryStatus.pending;
}
export function hasQueryFetched(status) {
  return [QueryStatus.fulfilled, QueryStatus.rejected].includes(status);
}