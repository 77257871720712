import groupBy from "lodash/groupBy";
import { quizApi } from './quiz';
// makes a copy of answers and returns it with the updated answer
export const updateQuizAnswer = (answers, newAnswer) => {
  const answerIndex = answers.findIndex(_ref => {
    let {
      questionKey,
      investorEntityId
    } = _ref;
    if (newAnswer.investorEntityId) {
      return questionKey === newAnswer.questionKey && investorEntityId === newAnswer.investorEntityId;
    }
    return questionKey === newAnswer.questionKey;
  });
  if (answerIndex > -1) {
    const updatedAnswers = [...answers];
    updatedAnswers[answerIndex] = newAnswer;
    return updatedAnswers;
  } else {
    return [...answers, newAnswer];
  }
};
export const updateMultipleAnswers = (answers, newAnswers) => {
  let updatedAnswers = [...answers];
  newAnswers.forEach(newAnswer => {
    updatedAnswers = updateQuizAnswer(updatedAnswers, newAnswer);
  });
  return updatedAnswers;
};

// updateQuizAnswersQueries and updateQuizAnswerQueries are used for optimistically updating
// the RTK cache for the Quiz API because of eventual consistency in the quiz endpoints.
// Each of these functions will update the getQuizAnswersByInvestorEntityId, getQuizAnswersByUserId and getQuizAnswersByQuizId
// query caches for any investorEntityIds, userIds or quizIds found in the answers provided to the query to the
// put endpoint.

export const updateQuizAnswersQueries = (newAnswers, dispatch) => {
  const patchResults = [];
  const groupedNewAnswersByInvestorEntityId = groupBy(newAnswers, 'investorEntityId');
  Object.entries(groupedNewAnswersByInvestorEntityId).forEach(_ref2 => {
    let [investorEntityId, newAnswersByInvestorEntityId] = _ref2;
    const patchResult = dispatch(quizApi.util.updateQueryData('getQuizAnswersByInvestorEntityId', {
      investorEntityId
    }, draft => {
      Object.assign(draft, {
        answers: updateMultipleAnswers(draft.answers, newAnswersByInvestorEntityId)
      });
    }));
    patchResults.push(patchResult);
  });
  const groupedNewAnswersByUserId = groupBy(newAnswers, 'userId');
  Object.entries(groupedNewAnswersByUserId).forEach(_ref3 => {
    let [userId, newAnswersByUserId] = _ref3;
    const patchResult = dispatch(quizApi.util.updateQueryData('getQuizAnswersByUserId', {
      userId
    }, draft => {
      Object.assign(draft, {
        answers: updateMultipleAnswers(draft.answers, newAnswersByUserId)
      });
    }));
    patchResults.push(patchResult);
  });
  const groupedNewAnswersByQuizId = groupBy(newAnswers, 'quizId');
  Object.entries(groupedNewAnswersByQuizId).forEach(_ref4 => {
    let [quizId, newAnswersByQuizId] = _ref4;
    const patchResult = dispatch(quizApi.util.updateQueryData('getQuizAnswersByQuizId', {
      quizId
    }, draft => {
      Object.assign(draft, {
        answers: updateMultipleAnswers(draft.answers, newAnswersByQuizId)
      });
    }));
    patchResults.push(patchResult);
  });
  return patchResults;
};
export const updateQuizAnswerQueries = (newAnswer, dispatch) => {
  const patchResults = [];
  if (newAnswer.investorEntityId) {
    const patchResult = dispatch(quizApi.util.updateQueryData('getQuizAnswersByInvestorEntityId', {
      investorEntityId: newAnswer.investorEntityId
    }, draft => {
      if (draft) {
        Object.assign(draft, {
          answers: updateQuizAnswer(draft.answers, newAnswer)
        });
      }
    }));
    patchResults.push(patchResult);
  }
  if (newAnswer.userId) {
    const patchResult = dispatch(quizApi.util.updateQueryData('getQuizAnswersByUserId', {
      userId: newAnswer.userId
    }, draft => {
      if (draft) {
        Object.assign(draft, {
          answers: updateQuizAnswer(draft.answers, newAnswer)
        });
      }
    }));
    patchResults.push(patchResult);
  }
  if (newAnswer.quizId) {
    const patchResult = dispatch(quizApi.util.updateQueryData('getQuizAnswersByQuizId', {
      quizId: newAnswer.quizId
    }, draft => {
      if (draft) {
        Object.assign(draft, {
          answers: updateQuizAnswer(draft.answers, newAnswer)
        });
      }
    }));
    patchResults.push(patchResult);
  }
  return patchResults;
};