import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["label", "value", "onChange", "open", "error", "options", "allowNone", "className", "hiddenLabel", "caption", "fullWidth", "width", "hideIfSingleOption", "name", "disabled", "testId"];
var __jsx = React.createElement;
import React, { useState } from 'react';
import styled from 'styled-components';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SFormControl, SFormHelperText, FormError, withFormikWeb, useLabelId } from '../common';
import { SFilledInput, SInputLabel } from '../text-input/styles';
import ArrowBlack from '../../assets/graphics/icons/arrow.svg';
import { SelectMenuStyles, Arrow } from './SelectInput.style';
import { useTestIds } from './SelectInput.model';
export const SelectInput = styled(props => {
  const {
      label,
      value,
      onChange,
      open,
      error,
      options,
      allowNone,
      className,
      hiddenLabel,
      caption,
      fullWidth,
      width,
      hideIfSingleOption,
      name,
      disabled,
      testId
    } = props,
    otherProps = _objectWithoutProperties(props, _excluded);
  const {
    0: iconFacingUp,
    1: setIconFacinpUp
  } = useState(false);
  const handleChange = value => {
    const selectedOption = options.find(option => option.value === value);
    if (onChange) {
      onChange(value, selectedOption);
    }
  };
  const labelId = useLabelId(name);
  const showError = !!(error || caption);
  const valueIsDefined = value !== undefined && value !== null;
  if (hideIfSingleOption && options.length <= 1) {
    return null;
  }
  const testIds = useTestIds({
    testId: testId || name || 'select'
  });
  return __jsx(SFormControl, {
    variant: "filled",
    hiddenLabel: hiddenLabel,
    error: !!error,
    className: className,
    fullWidth: fullWidth,
    disabled: disabled,
    style: {
      width
    }
  }, label && !hiddenLabel && __jsx(SInputLabel, _extends({
    id: labelId
  }, testIds.label.attr), label), __jsx(Select, _extends({
    name: name,
    value: valueIsDefined ? value : '',
    onChange: e => handleChange(e.target.value),
    input: __jsx(SFilledInput, {
      inputProps: {
        'data-cy': testIds.input.id,
        'aria-labelledby': labelId
      }
    }),
    IconComponent: () => __jsx(Arrow, _extends({
      src: ArrowBlack,
      iconFacingUp: iconFacingUp
    }, testIds.arrow.attr)),
    onOpen: () => setIconFacinpUp(true),
    onClose: () => setIconFacinpUp(false),
    MenuProps: _objectSpread({
      PopoverClasses: {
        paper: className
      },
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center'
      },
      transformOrigin: {
        vertical: 'top',
        horizontal: 'center'
      },
      marginThreshold: 0
    }, open !== undefined ? {
      open
    } : {})
  }, testIds.base.attr, otherProps), allowNone && __jsx(MenuItem, _extends({
    key: "",
    value: ""
  }, testIds.optionEmpty.attr), __jsx("em", null, "None")), Array.isArray(options) && options.map(option => __jsx(MenuItem, _extends({
    key: option.value,
    value: option.value,
    disabled: option.disabled
  }, option.disabled ? testIds.optionDisabled.attr : testIds.option.attr), option.label))), showError ? __jsx(SFormHelperText, null, error ? __jsx(FormError, {
    type: "select-input",
    name: name || 'select',
    error: error,
    testId: testIds.error.id
  }) : caption) : null);
}).withConfig({
  displayName: "SelectInput",
  componentId: "sc-1evegmp-0"
})(["", ""], SelectMenuStyles);
export const FormSelectInput = withFormikWeb(SelectInput);