var __jsx = React.createElement;
import React from 'react';
import styled, { withTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Heading } from '../../content/text';
import { Container } from '../container';
const PageTitleComponent = _ref => {
  let {
    backButton,
    tagType,
    children,
    theme
  } = _ref;
  return __jsx(Background, null, __jsx(TitleContainer, null, backButton && __jsx(BackLink, null, __jsx(Arrow, {
    icon: faChevronLeft,
    color: theme.colors.text_paragraph_inverse
  }), backButton), __jsx(Heading, {
    type: 4,
    tagType: tagType || 1,
    inverse: true
  }, children)));
};
const TitleContainer = styled(Container).withConfig({
  displayName: "PageTitle__TitleContainer",
  componentId: "sc-k83zxn-0"
})(["padding-top:20px;padding-bottom:20px;"]);
const Background = styled.div.withConfig({
  displayName: "PageTitle__Background",
  componentId: "sc-k83zxn-1"
})(["border-top:2px solid ", ";background:", ";width:100%;word-break:break-word;"], props => props.theme.colors.border_inverse, props => props.theme.colors.background_inverse);
const BackLink = styled.div.withConfig({
  displayName: "PageTitle__BackLink",
  componentId: "sc-k83zxn-2"
})(["margin-bottom:10px;"]);
const Arrow = styled(FontAwesomeIcon).withConfig({
  displayName: "PageTitle__Arrow",
  componentId: "sc-k83zxn-3"
})(["margin:0 10px 0 0;"]);
export const PageTitle = withTheme(PageTitleComponent);