import update from 'immutability-helper';
import * as actions from 'store/actions/metrics';
const initialState = {
  items: {
    byId: {}
  },
  isFetching: false,
  fetchError: false
};
const metrics = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case actions.GET_METRICS_ATTEMPT:
      {
        return update(state, {
          isFetching: {
            $set: true
          },
          fetchError: {
            $set: false
          }
        });
      }
    case actions.GET_METRICS_SUCCESS:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          items: {
            byId: {
              $merge: {
                [action.urlHash]: action.response
              }
            }
          }
        });
      }
    case actions.GET_METRICS_FAILURE:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          fetchError: {
            $set: true
          }
        });
      }
    default:
      return state;
  }
};
export default metrics;