import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { ImageWithContent, Paragraph } from '@yieldstreet/ui-kit';
import Icon from '../assets/Icon.png';
import { Background, IconImg, SHeroBanner, ItemsWrapper } from './Glance.style';
import { items, testIds } from './Glance.model';
export const Glance = () => __jsx(Background, null, __jsx(SHeroBanner, {
  title: "At a glance",
  headingType: 3,
  inverse: true,
  sideContent: __jsx(ItemsWrapper, testIds.itemsWrapper.attr, items.map(item => __jsx(ImageWithContent, {
    key: item.title,
    title: item.title,
    imageComponent: __jsx(IconImg, {
      src: Icon
    }),
    inverse: true
  }, __jsx(Paragraph, _extends({
    size: "small",
    inverse: true
  }, testIds.itemParagraph.attr), item.content)))),
  testId: testIds.heroBanner.id
}));