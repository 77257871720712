var __jsx = React.createElement;
import React from 'react';
import { PORTFOLIO_MD_PATH, PORTFOLIO_SD_OVERVIEW_PATH, PORTFOLIO_MD_OVERVIEW_PATH, PORTFOLIO_SD_INVESTMENTS_PATH, PORTFOLIO_MD_INVESTMENTS_PATH, PORTFOLIO_SD_TRANSACTIONS_PATH, PORTFOLIO_MD_TRANSACTIONS_PATH, PORTFOLIO_SD_STATEMENTS_PATH, PORTFOLIO_MD_STATEMENTS_PATH, PORTFOLIO_SD_TAX_CENTER_PATH, PORTFOLIO_MD_TAX_CENTER_PATH } from '../../../';
import investmentsIcon from './assets/investments.svg';
import overviewIcon from './assets/overview.svg';
import statementsIcon from './assets/statements.svg';
import taxCenterIcon from './assets/tax-center.svg';
import transactionsIcon from './assets/transactions.svg';
export const portfolioNavLinks = (investorId, managementStrategyRouteParam) => {
  const slug = investorId ? `/${investorId}` : '';
  return managementStrategyRouteParam === PORTFOLIO_MD_PATH ? [{
    id: 'header-overview',
    key: 'overview',
    title: 'Overview',
    route: `${PORTFOLIO_MD_OVERVIEW_PATH}${slug}`,
    icon: __jsx("img", {
      src: overviewIcon,
      alt: "Portfolio Overview"
    })
  }, {
    id: 'header-investments',
    key: 'investments',
    title: 'Investments',
    route: `${PORTFOLIO_MD_INVESTMENTS_PATH}${slug}`,
    icon: __jsx("img", {
      src: investmentsIcon,
      alt: "Investments"
    })
  }, {
    id: 'header-transactions',
    key: 'transactions',
    title: 'Transactions',
    route: `${PORTFOLIO_MD_TRANSACTIONS_PATH}${slug}`,
    icon: __jsx("img", {
      src: transactionsIcon,
      alt: "Transactions"
    })
  }, {
    id: 'header-statements',
    key: 'statements',
    title: 'Statements',
    route: `${PORTFOLIO_MD_STATEMENTS_PATH}${slug}`,
    icon: __jsx("img", {
      src: statementsIcon,
      alt: "Statements"
    })
  }, {
    id: 'header-tax-center',
    key: 'tax-center',
    title: 'Tax center',
    route: `${PORTFOLIO_MD_TAX_CENTER_PATH}${slug}`,
    icon: __jsx("img", {
      src: taxCenterIcon,
      alt: "Tax Center"
    })
  }] : [{
    id: 'header-overview',
    key: 'overview',
    title: 'Overview',
    route: `${PORTFOLIO_SD_OVERVIEW_PATH}${slug}`,
    icon: __jsx("img", {
      src: overviewIcon,
      alt: "Portfolio Overview"
    })
  }, {
    id: 'header-investments',
    key: 'investments',
    title: 'Investments',
    route: `${PORTFOLIO_SD_INVESTMENTS_PATH}${slug}`,
    icon: __jsx("img", {
      src: investmentsIcon,
      alt: "Investments"
    })
  }, {
    id: 'header-transactions',
    key: 'transactions',
    title: 'Transactions',
    route: `${PORTFOLIO_SD_TRANSACTIONS_PATH}${slug}`,
    icon: __jsx("img", {
      src: transactionsIcon,
      alt: "Transactions"
    })
  }, {
    id: 'header-statements',
    key: 'statements',
    title: 'Statements',
    route: `${PORTFOLIO_SD_STATEMENTS_PATH}${slug}`,
    icon: __jsx("img", {
      src: statementsIcon,
      alt: "Statements"
    })
  }, {
    id: 'header-tax-center',
    key: 'tax-center',
    title: 'Tax center',
    route: `${PORTFOLIO_SD_TAX_CENTER_PATH}${slug}`,
    icon: __jsx("img", {
      src: taxCenterIcon,
      alt: "Tax Center"
    })
  }];
};