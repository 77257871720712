import forEach from 'lodash/forEach';
import map from 'lodash/map';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isBoolean from 'lodash/isBoolean';
import isNumber from 'lodash/isNumber';
export const queryParser = params => {
  if (!params) {
    return '';
  }
  const combinedParams = [];
  forEach(params, (value, key) => {
    if (isEmpty(value) && !isBoolean(value) && !isNumber(value)) {
      return;
    }
    if (isArray(value)) {
      combinedParams.push(...map(value, item => `${key}[]=${item}`));
    } else {
      combinedParams.push(`${key}=${value}`);
    }
  });
  return combinedParams.join('&');
};