var __jsx = React.createElement;
import React, { useMemo, useCallback } from 'react';
import { FilterTypeEnum } from '@yieldstreet/tool-kit';
import { MultiSelectFilter } from './MultiSelectFilter';
import { RangeSelectFilter } from './RangeSelectFilter';
import { SingleSelectFilter } from './SingleSelectFilter';
import { SwitchFilter } from './SwitchFilter';
import { getKeyString } from 'utils/filters/filters';
export const FilterView = _ref => {
  let {
    filter,
    value,
    onChange
  } = _ref;
  const {
    key,
    type
  } = filter;
  const name = useMemo(() => getKeyString(key), [key]);
  const handleChange = useCallback(v => {
    if (Array.isArray(key)) {
      const item = {};
      key.forEach((k, i) => item[k] = v === null || v === void 0 ? void 0 : v[i]);
      onChange(item);
    } else {
      onChange({
        [key]: v
      });
    }
  }, [key, onChange]);
  switch (type) {
    case FilterTypeEnum.Range:
      return __jsx(RangeSelectFilter, {
        name: name,
        onChange: handleChange,
        value: value,
        filter: filter
      });
    case FilterTypeEnum.MultiSelect:
      return __jsx(MultiSelectFilter, {
        name: name,
        onChange: handleChange,
        value: value,
        filter: filter
      });
    case FilterTypeEnum.SingleSelect:
      return __jsx(SingleSelectFilter, {
        name: name,
        onChange: handleChange,
        value: value,
        filter: filter
      });
    default:
      return __jsx(SwitchFilter, {
        name: name,
        onChange: handleChange,
        value: value === 'false',
        filter: filter
      });
  }
};