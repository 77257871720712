import { useMemo } from 'react';
import isEmpty from "lodash/isEmpty";
import { skipToken } from '@reduxjs/toolkit/query';
import { AccreditationEligibility, marketplaceApi } from '@yieldstreet/platform-kit';
import { OfferingType, PortfolioCompositionOpenOffers } from './useByOpenOffers.model';
export const useByOpenOffers = (investmentsByAssetClass, isNAI) => {
  var _useGetOfferingsQuery, _useGetOfferingsQuery2, _useGetOfferingsQuery3;
  const {
    useGetOfferingsQuery
  } = marketplaceApi;
  const investmentsByAssetClassIsEmpty = isEmpty(investmentsByAssetClass);
  const openOffers = (_useGetOfferingsQuery = (_useGetOfferingsQuery2 = useGetOfferingsQuery(!investmentsByAssetClassIsEmpty ? {
    offeringType: OfferingType.Open
  } : skipToken)) === null || _useGetOfferingsQuery2 === void 0 ? void 0 : (_useGetOfferingsQuery3 = _useGetOfferingsQuery2.data) === null || _useGetOfferingsQuery3 === void 0 ? void 0 : _useGetOfferingsQuery3.offerings) !== null && _useGetOfferingsQuery !== void 0 ? _useGetOfferingsQuery : [];
  return useMemo(() => {
    const openOffersLegendData = [];
    const openOffersList = {};
    openOffers === null || openOffers === void 0 ? void 0 : openOffers.forEach(offer => {
      const filterByAssetClass = !(investmentsByAssetClass !== null && investmentsByAssetClass !== void 0 && investmentsByAssetClass.includes(offer.assetClass)) && (offer === null || offer === void 0 ? void 0 : offer.assetClass);
      const filterByNAI = isNAI ? offer.accreditationEligibility === AccreditationEligibility.NONA : true;
      if (filterByAssetClass && filterByNAI) {
        var _openOffersList$offer;
        let currentAssetClassTotalOpenOffers = (openOffersList === null || openOffersList === void 0 ? void 0 : (_openOffersList$offer = openOffersList[offer.assetClass]) === null || _openOffersList$offer === void 0 ? void 0 : _openOffersList$offer.totalOpenOffers) || 0;
        openOffersList[offer.assetClass] = {
          assetClass: offer.assetClass,
          title: offer.assetClassDisplayName || '',
          totalOpenOffers: ++currentAssetClassTotalOpenOffers
        };
      }
    });
    Object.values(openOffersList).forEach((_ref, index) => {
      let {
        assetClass,
        title,
        totalOpenOffers
      } = _ref;
      openOffersLegendData.push({
        key: `${index}-${title}`,
        assetClass,
        title,
        metadata: `${totalOpenOffers} ${PortfolioCompositionOpenOffers}`,
        redirect: true
      });
    });
    return openOffersLegendData;
  }, [openOffers, investmentsByAssetClass, isNAI]);
};