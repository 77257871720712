import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { TierLevels } from '@yieldstreet/platform-kit';
import emailIcon from '../../assets/graphics/icons/email-inverse.svg';
import userIcon from './assets/userIcon.svg';
import diamondTierIcon from './assets/tier-diamond.svg';
import goldTierIcon from './assets/tier-gold.svg';
import platinumTierIcon from './assets/tier-platinum.svg';
export const HelpCenter = {
  id: 'header-support',
  key: 'support',
  title: 'Help center',
  route: '/support/'
};
const MultiClassFund = {
  id: 'header-multi-class-fund',
  key: 'multi-class-fund',
  title: 'Multi-Asset Class Fund',
  route: 'https://yieldstreetalternativeincomefund.com/',
  forceExternal: true,
  newTab: true
};
const ArtInvesting = {
  id: 'header-art-investing',
  key: 'art-investing',
  title: 'Art',
  route: '/investing-in-art/',
  staticRoute: true
};
const RealEstateInvesting = {
  id: 'header-real-estate-investing',
  key: 'real-estate-investing',
  title: 'Real Estate',
  route: '/real-estate-investing/',
  staticRoute: true
};
const ShortTermNotes = {
  id: 'header-short-term-notes',
  key: 'short-term-notes',
  title: 'Short Term Notes',
  route: '/short-term-notes/',
  staticRoute: true
};
const StrategiesInvesting = {
  id: 'header-yieldstreet-strategies',
  key: 'yieldstreet-strategies',
  title: 'Investment strategies',
  route: '/strategies/'
};
const FundamentalsInvesting = {
  id: 'header-yieldstreet-fundamentals',
  key: 'yieldstreet-fundamentals',
  title: 'Why private markets?',
  route: '/why-private-markets/'
};
const LearnNav = [{
  id: 'header-insights-1',
  key: 'strategies',
  title: '',
  accreditedOnly: false,
  items: [StrategiesInvesting]
}, {
  id: 'header-insights-2',
  key: 'fundamentals',
  title: '',
  accreditedOnly: false,
  items: [FundamentalsInvesting]
}, {
  id: 'header-new-to-alts',
  key: 'new-to-alts',
  title: 'Asset classes',
  accreditedOnly: false,
  items: [MultiClassFund, ArtInvesting, RealEstateInvesting, ShortTermNotes]
}, {
  id: 'header-insights',
  key: 'insights',
  title: 'Blog',
  accreditedOnly: false,
  items: [{
    id: 'header-yieldstreet-101',
    key: 'yieldstreet-101',
    title: 'Insight and Education',
    route: '/articles/'
  }]
}];
const AboutNav = [{
  id: 'header-company',
  key: 'company',
  title: 'Our Company',
  accreditedOnly: false,
  items: [{
    id: 'header-about-us',
    key: 'about-us',
    title: 'About us',
    route: '/about/',
    staticRoute: true
  }, {
    id: 'header-press',
    key: 'press',
    title: 'Press',
    route: '/press/',
    staticRoute: true
  }, {
    id: 'header-careers',
    key: 'careers',
    title: 'Careers',
    route: '/careers/',
    staticRoute: true
  }]
}];
export const mainNav = [{
  id: 'header-offerings',
  key: 'offerings',
  title: 'Investments',
  route: '/offerings/',
  accreditedOnly: false
}, {
  id: 'header-how-it-works',
  key: 'how-it-works',
  title: 'How it works',
  route: '/how-it-works/',
  staticRoute: true,
  guestOnly: true
}, {
  id: 'header-products',
  key: 'products',
  title: 'Products',
  guestOnly: true,
  itemsDropdown: true,
  items: [{
    id: 'header-overview',
    key: 'overview',
    title: 'Overview',
    route: '/investments/',
    staticRoute: false
  }, ArtInvesting, MultiClassFund, RealEstateInvesting, ShortTermNotes, {
    id: 'header-past-offerings',
    key: 'past-offerings',
    title: 'Past offerings',
    route: '/past-offerings/',
    staticRoute: false,
    seperator: true
  }]
}, {
  id: 'header-about-us',
  key: 'about-us',
  title: 'About us',
  guestOnly: true,
  itemsDropdown: true,
  items: [{
    id: 'header-company',
    key: 'company',
    title: 'Company',
    route: '/about/',
    staticRoute: true
  }]
}, {
  id: 'header-portfolio',
  key: 'portfolio',
  title: 'Portfolio',
  route: '/dashboard',
  private: true,
  items: () => undefined,
  itemsDropdown: true
}, {
  id: 'header-activity',
  key: 'activity',
  title: 'Activity',
  route: '/activity',
  private: true
}, {
  id: 'header-management',
  key: 'management',
  title: 'Accounts',
  route: '/management',
  private: true,
  items: () => undefined
}, {
  id: 'header-learn',
  key: 'learn',
  title: 'Education',
  items: LearnNav,
  itemsDropdown: true
}, _objectSpread({}, HelpCenter), {
  id: 'header-raise-capital',
  key: 'raise-capital',
  title: 'Partner with us',
  route: '/raise-capital/',
  staticRoute: true,
  guestOnly: true,
  visible: {
    desktop: false,
    mobile: true
  }
}, {
  id: 'header-invite',
  key: 'invite',
  route: '/invite',
  title: 'Invite a friend',
  icon: emailIcon,
  private: true,
  visible: _ref => {
    let {
      audiences
    } = _ref;
    return audiences !== null && audiences !== void 0 && audiences.repeatInvestor ? {
      desktop: false,
      mobile: true
    } : false;
  }
}];
export const mainNavLoggedOut = [{
  id: 'header-offerings',
  key: 'offerings',
  title: 'Investments',
  route: '/offerings/',
  accreditedOnly: false
}, {
  id: 'header-how-it-works',
  key: 'how-it-works',
  title: 'How it works',
  route: '/how-it-works/',
  staticRoute: true,
  guestOnly: true
}, {
  id: 'header-learn',
  key: 'learn',
  title: 'Education',
  items: LearnNav,
  accreditedOnly: false,
  itemsDropdown: true
}, {
  id: 'header-about',
  key: 'about',
  title: 'About',
  items: AboutNav,
  accreditedOnly: false,
  guestOnly: true,
  itemsDropdown: true
}];
export const userNav = [{
  id: 'header-invite',
  key: 'invite',
  route: '/invite',
  title: 'Invite a friend',
  type: 'button',
  visible: _ref2 => {
    let {
      audiences
    } = _ref2;
    return audiences !== null && audiences !== void 0 && audiences.repeatInvestor ? {
      desktop: true,
      mobile: false
    } : false;
  },
  icon: emailIcon,
  private: true
}, {
  id: 'header-profile',
  key: 'profile',
  private: true,
  route: '/profile',
  title: _ref3 => {
    let {
      userState
    } = _ref3;
    return userState ? {
      mobile: '',
      desktop: userState.firstName
    } : null;
  },
  icon: _ref4 => {
    let {
      isMobile,
      tier
    } = _ref4;
    if (isMobile) {
      return userIcon;
    }
    switch (tier === null || tier === void 0 ? void 0 : tier.level) {
      case TierLevels.GOLD:
        return goldTierIcon;
      case TierLevels.PLATINUM:
        return platinumTierIcon;
      case TierLevels.DIAMOND:
        return diamondTierIcon;
      default:
        return null;
    }
  },
  itemsDropdown: true,
  items: _ref5 => {
    let {
      tier
    } = _ref5;
    const itemList = [{
      id: 'header-sub-profile',
      key: 'sub-profile',
      title: 'Profile settings',
      route: '/profile/user/'
    }];
    const hasTier = [TierLevels.GOLD, TierLevels.PLATINUM, TierLevels.DIAMOND].includes(tier === null || tier === void 0 ? void 0 : tier.level);
    if (hasTier) {
      itemList.push({
        id: 'header-benefits',
        key: 'benefits',
        title: 'Yieldstreet benefits',
        route: '/profile/benefits/'
      });
    }
    itemList.push({
      id: 'header-logout',
      key: 'sub-logout',
      title: 'Log out',
      route: '/logout/'
    });
    return itemList;
  }
}];