import update from 'immutability-helper';
import { GET_PAST_OFFERINGS_ATTEMPT, GET_PAST_OFFERINGS_ASSET_ATTEMPT, GET_PAST_OFFERINGS_SUCCESS, GET_PAST_OFFERINGS_ASSET_SUCCESS, GET_PAST_OFFERINGS_FAILURE, GET_PAST_OFFERINGS_ASSET_FAILURE, CLEAR_PAST_OFFERINGS } from 'store/actions/pastOfferings';
const initialState = {
  pastOfferings: {
    collection: [],
    count: null,
    pages: 1,
    page: 1,
    isFetching: true,
    hasFetched: false,
    hasError: false
  },
  assetClasses: {
    collection: [],
    isFetching: true,
    hasFetched: false,
    hasError: false
  }
};
const pastOfferings = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case GET_PAST_OFFERINGS_ATTEMPT:
      {
        return update(state, {
          $merge: {
            pastOfferings: update(state.pastOfferings, {
              $merge: {
                isFetching: true,
                hasFetched: false
              }
            })
          }
        });
      }
    case GET_PAST_OFFERINGS_ASSET_ATTEMPT:
      {
        return update(state, {
          $merge: {
            assetClasses: update(state.assetClasses, {
              $merge: {
                isFetching: true,
                hasFetched: false
              }
            })
          }
        });
      }
    case GET_PAST_OFFERINGS_SUCCESS:
      {
        return update(state, {
          $merge: {
            pastOfferings: update(state.pastOfferings, {
              $merge: {
                isFetching: false,
                hasFetched: true,
                hasError: false,
                collection: state.pastOfferings.collection.concat(action.response.offerings),
                count: action.response.totalCount,
                pages: action.response.totalPages,
                page: action.response.currentPage
              }
            })
          }
        });
      }
    case GET_PAST_OFFERINGS_ASSET_SUCCESS:
      {
        return update(state, {
          $merge: {
            assetClasses: update(state.assetClasses, {
              $merge: {
                isFetching: false,
                hasFetched: true,
                hasError: false,
                collection: state.assetClasses.collection.concat(availableAssetClasses(action.response))
              }
            })
          }
        });
      }
    case GET_PAST_OFFERINGS_FAILURE:
      {
        return update(state, {
          $merge: {
            pastOfferings: update(state.pastOfferings, {
              $merge: {
                isFetching: false,
                hasFetched: false,
                hasError: true
              }
            })
          }
        });
      }
    case GET_PAST_OFFERINGS_ASSET_FAILURE:
      {
        return update(state, {
          $merge: {
            assetClasses: update(state.assetClasses, {
              $merge: {
                isFetching: false,
                hasFetched: false,
                hasError: true
              }
            })
          }
        });
      }
    case CLEAR_PAST_OFFERINGS:
      {
        return update(state, {
          $merge: {
            pastOfferings: update(state.pastOfferings, {
              $merge: {
                collection: [],
                count: null,
                pages: 1,
                page: 1,
                isFetching: true,
                hasFetched: false,
                hasError: false
              }
            })
          }
        });
      }
    default:
      return state;
  }
};
const availableAssetClasses = assetClasses => assetClasses.filter(ac => ac.available);
export default pastOfferings;