import _Button from "@mui/material/Button";
import _Stack from "@mui/material/Stack";
import _Box from "@mui/material/Box";
import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { NewestModal } from '@yieldstreet/ui-kit';
import { Modals } from 'constants/modal.model';
import iraBackgroundImg from './assets/IraModalImage.jpg';
import checkMark from './assets/checkmark.svg';
import { benefits, testIds } from './IraModal.model';
import { ButtonContainer, Content, SecondaryParagraph } from './IraModal.style';
export const IraModal = _ref => {
  let {
    onClose
  } = _ref;
  return __jsx(NewestModal, {
    modalId: Modals.IRA,
    onClose: onClose
  }, __jsx(_Stack, {
    sx: {
      maxWidth: 850
    }
  }, __jsx("img", {
    src: iraBackgroundImg
  }), __jsx(Content, null, __jsx(_Typography, _extends({
    variant: "h4"
  }, testIds.title.attr), "Add private markets to your retirement portfolio"), __jsx(_Typography, {
    variant: "body2",
    sx: {
      mt: 4
    }
  }, "We\u2019ve partnered with Equity Trust, a leading custodian, to make investing your retirement portfolio in private markets a breeze."), __jsx(_Stack, {
    gap: 4,
    sx: {
      mt: 4,
      mb: 8
    }
  }, benefits.map(benefit => __jsx(_Stack, {
    key: benefit.title,
    direction: "row",
    gap: 4
  }, __jsx("img", {
    src: checkMark
  }), __jsx(_Box, null, __jsx(_Typography, {
    variant: "body2",
    sx: {
      mb: 1,
      fontWeight: 500
    }
  }, benefit.title), __jsx(SecondaryParagraph, {
    variant: "body2"
  }, benefit.description))))), __jsx(ButtonContainer, null, __jsx(_Button, _extends({
    href: "/create-investor-account"
  }, testIds.getStarted.attr), "Get started"), __jsx(_Button, _extends({
    href: "/ira",
    variant: "text"
  }, testIds.learnMore.attr), "Learn more")))));
};