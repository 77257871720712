import _Box from "@mui/material/Box";
import _Button from "@mui/material/Button";
import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { useModal } from '@yieldstreet/ui-kit';
import { useHistory } from 'react-router-dom';
import { useFlags } from '@yieldstreet/tool-kit';
import { testIds } from './RolloverDockedHero.model';
import { InnerWrapper, Content, Main, Wrapper, Thumbnail } from './RolloverDockedHero.style';
import marketplaceOfferingDefault from '../../../assets/images/images/marketplaceOfferingDefault.png';
import { useOfferingHero } from '../../../pages/offering/v5/heading/hooks/useOfferingHero';
import { ActiveMetrics } from '../../../pages/offering/v5/heading/ActiveMetrics/ActiveMetrics';
import { Modals } from 'constants/modal.model';
export const RolloverDockedHero = _ref => {
  let {
    offering,
    status,
    loggedIn,
    rollover
  } = _ref;
  const flags = useFlags({
    rolloverV2: false
  });
  const {
    close
  } = useModal(Modals.Rollover);
  const history = useHistory();
  const title = offering === null || offering === void 0 ? void 0 : offering.title;
  const thumbnailImageUrl = (offering === null || offering === void 0 ? void 0 : offering.thumbnailImageUrl) || marketplaceOfferingDefault;
  const {
    isPublicFund,
    isFortyActFund,
    tieredTree,
    term,
    termSuffix
  } = useOfferingHero(offering);
  return __jsx(Wrapper, testIds.base.attr, __jsx(InnerWrapper, null, __jsx(Main, null, __jsx(Content, null, __jsx(Thumbnail, _extends({
    src: thumbnailImageUrl
  }, testIds.thumbnail.attr)), __jsx(_Typography, _extends({}, testIds.noteTitle.attr, {
    color: "textSenary",
    sx: {
      fontWeight: 'medium'
    }
  }), title)), __jsx(ActiveMetrics, {
    isPublicFund: isPublicFund,
    isFortyActFund: isFortyActFund,
    isConcise: true,
    offering: offering,
    term: term,
    termSuffix: termSuffix,
    isLoggedIn: loggedIn,
    status: status,
    tieredTree: tieredTree
  })), __jsx(_Box, null, __jsx(_Button, _extends({}, testIds.cta.attr, {
    onClick: () => {
      // close is needed to force the modal closed before page change and
      // avoid the issue described here: https://app.shortcut.com/yieldstreet/story/146800
      close();
      history.push(`/input-investment/${offering.urlHash}`, {
        rollover: flags !== null && flags !== void 0 && flags.rolloverV2 ? rollover : undefined
      });
    }
  }), "Select Investment"))));
};