import update from 'immutability-helper';
import * as actions from 'store/actions/marketplace';
import * as pastOfferingsActions from 'store/actions/pastOfferings';
const initialState = {
  openOfferings: [],
  openOfferingsCount: null,
  openOfferingsPages: 0,
  openOfferingsPage: 0,
  openOfferingsAttributes: {},
  openOfferingsError: false,
  pastOfferings: [],
  pastOfferingsCount: null,
  pastOfferingsPages: 0,
  pastOfferingsPage: 0,
  pastOfferingsError: false,
  upcomingOfferings: [],
  upcomingOfferingsCount: null,
  upcomingOfferingsPages: 0,
  upcomingOfferingsPage: 0,
  fetchingUpcomingOfferings: null,
  fetchingOpenOfferings: null,
  fetchingPastOfferings: false,
  pastAssetClasses: [],
  progress: {
    byId: {}
  },
  isFetching: false,
  hasFetched: false,
  fetchingAssetClass: false,
  fetchError: false,
  marketplaceDiscoveryOfferings: [],
  isFetchingMarketplaceDiscoveryOfferings: false,
  recommendedOfferings: [],
  haveRecommendedOfferingsFetched: false
};
const marketplace = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case actions.GET_OPEN_OFFERINGS_ATTEMPT:
      {
        return update(state, {
          openOfferingsError: {
            $set: false
          },
          fetchingOpenOfferings: {
            $set: true
          },
          isFetching: {
            $set: true
          },
          fetchError: {
            $set: false
          }
        });
      }
    case actions.GET_UPCOMING_OFFERINGS_ATTEMPT:
      {
        return update(state, {
          isFetching: {
            $set: true
          },
          fetchError: {
            $set: false
          },
          fetchingUpcomingOfferings: {
            $set: true
          }
        });
      }
    case pastOfferingsActions.GET_PAST_OFFERINGS_ASSET_ATTEMPT:
      {
        return update(state, {
          fetchingAssetClass: {
            $set: true
          }
        });
      }
    case pastOfferingsActions.GET_PAST_OFFERINGS_ATTEMPT:
      {
        return update(state, {
          pastOfferingsError: {
            $set: false
          },
          fetchingPastOfferings: {
            $set: true
          },
          fetchError: {
            $set: false
          }
        });
      }
    case actions.GET_OPEN_OFFERINGS_SUCCESS:
      {
        var _action$response;
        return update(state, {
          isFetching: {
            $set: false
          },
          hasFetched: {
            $set: true
          },
          fetchingOpenOfferings: {
            $set: false
          },
          openOfferingsError: {
            $set: false
          },
          openOfferings: {
            $set: action.response.offerings
          },
          openOfferingsCount: {
            $set: action.response.totalCount
          },
          openOfferingsPages: {
            $set: action.response.totalPages
          },
          openOfferingsPage: {
            $set: action.response.currentPage
          },
          openOfferingsAttributes: {
            $set: (_action$response = action.response) === null || _action$response === void 0 ? void 0 : _action$response.attributes
          }
        });
      }
    case actions.GET_UPCOMING_OFFERINGS_SUCCESS:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          hasFetched: {
            $set: true
          },
          upcomingOfferings: {
            $set: action.response.offerings
          },
          upcomingOfferingsCount: {
            $set: action.response.totalCount
          },
          upcomingOfferingsPages: {
            $set: action.response.totalPages
          },
          upcomingOfferingsPage: {
            $set: action.response.currentPage
          },
          fetchingUpcomingOfferings: {
            $set: false
          }
        });
      }
    case pastOfferingsActions.GET_PAST_OFFERINGS_SUCCESS:
      {
        return update(state, {
          pastOfferingsError: {
            $set: false
          },
          fetchingPastOfferings: {
            $set: false
          },
          pastOfferings: {
            $set: action.response.offerings
          },
          pastOfferingsCount: {
            $set: action.response.totalCount
          },
          pastOfferingsPages: {
            $set: action.response.totalPages
          },
          pastOfferingsPage: {
            $set: action.response.currentPage
          }
        });
      }
    case pastOfferingsActions.GET_PAST_OFFERINGS_ASSET_SUCCESS:
      {
        return update(state, {
          fetchingAssetClass: {
            $set: false
          },
          pastAssetClasses: {
            $set: availableAssetClasses(action.response)
          }
        });
      }
    case actions.GET_OPEN_OFFERINGS_FAILURE:
      {
        return update(state, {
          fetchingOpenOfferings: {
            $set: false
          },
          isFetching: {
            $set: false
          },
          hasFetched: {
            $set: true
          },
          fetchError: {
            $set: true
          },
          openOfferingsError: {
            $set: true
          }
        });
      }
    case actions.GET_UPCOMING_OFFERINGS_FAILURE:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          hasFetched: {
            $set: true
          },
          fetchError: {
            $set: true
          },
          fetchingUpcomingOfferings: {
            $set: false
          }
        });
      }
    case pastOfferingsActions.GET_PAST_OFFERINGS_FAILURE:
      {
        return update(state, {
          fetchingPastOfferings: {
            $set: false
          },
          pastOfferingsError: {
            $set: true
          },
          fetchError: {
            $set: true
          }
        });
      }
    case pastOfferingsActions.GET_PAST_OFFERINGS_ASSET_FAILURE:
      {
        return update(state, {
          fetchingAssetClass: {
            $set: false
          },
          fetchError: {
            $set: true
          }
        });
      }
    case actions.GET_MARKETPLACE_DISCOVERY_OFFERINGS_ATTEMPT:
      {
        return update(state, {
          isFetchingMarketplaceDiscoveryOfferings: {
            $set: true
          }
        });
      }
    case actions.GET_MARKETPLACE_DISCOVERY_OFFERINGS_SUCCESS:
      {
        return update(state, {
          isFetchingMarketplaceDiscoveryOfferings: {
            $set: false
          },
          marketplaceDiscoveryOfferings: {
            $merge: {
              [action.category]: action.response.offerings
            }
          }
        });
      }
    case actions.GET_MARKETPLACE_DISCOVERY_OFFERINGS_FAILURE:
      {
        return update(state, {
          isFetchingMarketplaceDiscoveryOfferings: {
            $set: false
          }
        });
      }
    case actions.GET_MARKETPLACE_RECOMMENDATIONS_ATTEMPT:
      {
        return update(state, {
          isFetching: {
            $set: true
          },
          fetchError: {
            $set: false
          },
          hasFetched: {
            $set: false
          }
        });
      }
    case actions.GET_MARKETPLACE_RECOMMENDATIONS_SUCCESS:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          hasFetched: {
            $set: true
          },
          haveRecommendedOfferingsFetched: {
            $set: true
          },
          recommendedOfferings: {
            $set: action.response.offerings
          }
        });
      }
    case actions.GET_MARKETPLACE_RECOMMENDATIONS_FAILURE:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          fetchError: {
            $set: true
          },
          haveRecommendedOfferingsFetched: {
            $set: true
          }
        });
      }
    default:
      return state;
  }
};
const availableAssetClasses = assetClasses => assetClasses.filter(ac => ac.available);
export default marketplace;