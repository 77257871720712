import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { InvestorLevelGateCode } from '@yieldstreet/platform-kit';
import { usePoll } from '@yieldstreet/tool-kit';
import find from "lodash/find";
import { PRE_INVEST_ACCOUNT_SETUP_COMPLETED_PATH } from 'pages/preInvestAccountSetup';
import { useGetInvestmentEligibility } from './useGetInvestmentEligibility';
import { useGetInvestmentEligibilityAction } from './useGetInvestmentEligibilityAction';
export const PRE_INVESTMENT_PENDING = 'preInvestmentPending';
export const usePreInvestmentConfig = (currentInvestorEntityId, currentAccountActionCode) => {
  const {
    0: preInvestment,
    1: setPreInvestment
  } = useState();
  const {
    push
  } = useHistory();
  const poll = usePoll();
  useEffect(() => {
    const preInvestmentPending = JSON.parse(localStorage.getItem(PRE_INVESTMENT_PENDING));
    if (preInvestmentPending) {
      if (new Date() < (preInvestmentPending === null || preInvestmentPending === void 0 ? void 0 : preInvestmentPending.expirationTime)) {
        setPreInvestment(preInvestmentPending);
      } else {
        localStorage.removeItem(PRE_INVESTMENT_PENDING);
      }
    }
  }, [setPreInvestment]);
  const {
    getInvestmentEligibilityAction
  } = useGetInvestmentEligibilityAction();
  const [getInvestmentEligibility] = useGetInvestmentEligibility();
  const handlePreInvestRedirect = useCallback(redirectHandler => {
    if (preInvestment) {
      const {
        investments,
        investmentType
      } = preInvestment;
      return poll(() => getInvestmentEligibility({
        investments,
        investmentType
      }), {
        limit: 5,
        delay: 500,
        condition: _ref => {
          let {
            gateData
          } = _ref;
          return investorAccountHasDifferentAction({
            updatedGateData: gateData,
            currentInvestorEntityId,
            currentAccountActionCode
          });
        }
      }).then(_ref2 => {
        let {
          isEligibleToInvest,
          gateData,
          preInvestmentState
        } = _ref2;
        if (isEligibleToInvest) {
          push(PRE_INVEST_ACCOUNT_SETUP_COMPLETED_PATH, {
            preInvestmentState
          });
        } else {
          const eligibilityActionConfig = getInvestmentEligibilityAction(gateData);
          if (eligibilityActionConfig) {
            eligibilityActionConfig.action();
          } else {
            redirectHandler(preInvestment);
          }
        }
      });
    } else {
      redirectHandler();
    }
  }, [preInvestment, poll, getInvestmentEligibility, getInvestmentEligibilityAction, currentAccountActionCode, currentInvestorEntityId, push]);
  return {
    handlePreInvestRedirect
  };
};
function investorAccountHasDifferentAction(_ref3) {
  var _currentInvestorAccou, _currentInvestorAccou2;
  let {
    updatedGateData,
    currentInvestorEntityId,
    currentAccountActionCode
  } = _ref3;
  const {
    investorData
  } = updatedGateData;
  const currentInvestorAccount = find(investorData, account => Number(account.investorEntityId) === Number(currentInvestorEntityId));
  const accountHasActionGate = (currentInvestorAccount === null || currentInvestorAccount === void 0 ? void 0 : (_currentInvestorAccou = currentInvestorAccount.gateData) === null || _currentInvestorAccou === void 0 ? void 0 : _currentInvestorAccou.gateCode) === InvestorLevelGateCode.ACCOUNT_ACTION;
  const investorActionCodeIsDifferent = (currentInvestorAccount === null || currentInvestorAccount === void 0 ? void 0 : (_currentInvestorAccou2 = currentInvestorAccount.action) === null || _currentInvestorAccou2 === void 0 ? void 0 : _currentInvestorAccou2.code) !== currentAccountActionCode;
  if (accountHasActionGate) {
    if (investorActionCodeIsDifferent || !currentAccountActionCode) {
      return true;
    }
    // investor account action is the same (continue polling)
    return false;
  }
  // the investor now has an eligible investor account - no more actions to take
  return true;
}
export const withPreInvestmentConfig = (Component, currentActionCode) => {
  return props => {
    const result = usePreInvestmentConfig(props.investorAccountId, currentActionCode);
    return __jsx(Component, _extends({}, props, result));
  };
};