import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useMemo, useState, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from '@yieldstreet/ui-kit';
import { investorEntityApi } from '@yieldstreet/platform-kit';
import { FormWrapper, ButtonContainer } from '../../../CreateInvestorAccount.style';
import { SFormQuestionSecondaryCheckboxGroupInput } from '../KYC.style';
import { RenderFlowWrapper } from '../KYC.utils';
import { funnelValidation, options, NONE, testIds } from './AtomicFork.model';
const {
  useUpdateInvestorEntityMutation
} = investorEntityApi;
export const AtomicFork = _ref => {
  let {
    flowConfig,
    onSubmitAtomicForkSuccess
  } = _ref;
  const {
    investorEntityId
  } = useParams();
  const history = useHistory();
  const [updateInvestorEntity, updateInvestorEntityResult] = useUpdateInvestorEntityMutation();
  const {
    0: selectedValues,
    1: setSelectedValues
  } = useState([]);
  const handleChange = useCallback(checkedValues => {
    if (selectedValues.includes(NONE)) {
      setSelectedValues(checkedValues.filter(value => value !== NONE));
      return;
    }
    if (checkedValues.includes(NONE)) {
      setSelectedValues([NONE]);
      return;
    }
    setSelectedValues(checkedValues);
  }, [setSelectedValues, selectedValues]);
  const submitCallback = useCallback(() => {
    const investorId = parseInt(investorEntityId, 10);
    let affiliationDetails;
    if (selectedValues.includes(NONE)) {
      affiliationDetails = {
        isPoliticallyExposed: false,
        isFamilyPoliticallyExposed: false,
        isFinraAffiliated: false,
        controlCorporations: [],
        affiliatedExchange: []
      };
    } else {
      affiliationDetails = {
        isPoliticallyExposed: null,
        isFamilyPoliticallyExposed: null,
        isFinraAffiliated: null,
        controlCorporations: null,
        affiliatedExchange: null
      };
    }
    updateInvestorEntity({
      investorEntityId: investorId,
      affiliationDetails
    });
  }, [investorEntityId, updateInvestorEntity, selectedValues]);
  const renderForm = useCallback(() => __jsx(FormWrapper, testIds.atomicOption.attr, __jsx(SFormQuestionSecondaryCheckboxGroupInput, {
    name: "atomicFunnelInput",
    value: selectedValues,
    onChange: checkedValues => handleChange(checkedValues),
    options: options
  }), __jsx(ButtonContainer, null, __jsx(Button, _extends({
    onClick: () => submitCallback(),
    type: "submit",
    fullWidth: true,
    disabled: !(selectedValues !== null && selectedValues !== void 0 && selectedValues.length) || updateInvestorEntityResult.isLoading,
    loading: updateInvestorEntityResult.isLoading
  }, testIds.submitButton.attr), "Continue"))), [selectedValues, updateInvestorEntityResult.isLoading, handleChange, submitCallback]);
  const funnelConfig = useMemo(() => ({
    validation: funnelValidation,
    formikConfig: {
      initialValues: {
        atomicFunnelInput: []
      },
      enableReinitialize: false
    }
  }), []);
  return __jsx(RenderFlowWrapper, {
    formConfig: funnelConfig,
    flowConfig: flowConfig,
    submitConfig: updateInvestorEntityResult,
    submitCallback: submitCallback,
    isLoading: false,
    onSubmitSuccess: onSubmitAtomicForkSuccess,
    testIds: testIds,
    history: history,
    showSubtitle: true
  }, () => renderForm());
};