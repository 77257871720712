var __jsx = React.createElement;
import React from 'react';
import { Table } from 'pages/portfolio/common/Table';
import { PageSurface } from 'pages/portfolio/common/PageSurface';
import { ErrorView } from 'pages/portfolio/common/ErrorView';
import { TransactionsListDesktopTableHeader } from 'pages/portfolio/Transactions/TransactionsList/TransactionsListData/TransactionsListDesktop/TransactionsListDesktopTableHeader';
import { ReturnPaymentsDesktopTableBody } from './ReturnPaymentsDesktopTableBody';
export const ReturnPaymentsDesktopBody = _ref => {
  let {
    returnedTransactions,
    isFetching,
    isError
  } = _ref;
  if (isError) {
    return __jsx(ErrorView, null);
  }
  return __jsx(PageSurface, null, __jsx(Table, null, __jsx(TransactionsListDesktopTableHeader, null), __jsx(ReturnPaymentsDesktopTableBody, {
    returnedTransactions: returnedTransactions,
    isFetching: isFetching
  })));
};