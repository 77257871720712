var __jsx = React.createElement;
/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks-ssr/react-hooks-global-ssr */
import React from 'react';
import { Tag } from '@yieldstreet/ui-kit';
import { OfferingTagWrapper } from './OfferingTagsSection.style';
const OfferingTagsSection = _ref => {
  let {
    offeringTags
  } = _ref;
  return __jsx(OfferingTagWrapper, null, offeringTags === null || offeringTags === void 0 ? void 0 : offeringTags.map(tag => __jsx(Tag, {
    content: tag.name,
    key: tag.name,
    tooltip: tag.description
  })));
};
export default OfferingTagsSection;