export const getEligibilityGates = (eligibility, config) => {
  var _eligibility$investor2;
  // user-level gate if exists
  const userGate = eligibility === null || eligibility === void 0 ? void 0 : eligibility.userGate;

  // check if any investor accounts has no gates
  if (config !== null && config !== void 0 && config.onlyIfAllGated) {
    var _eligibility$investor;
    const anyEntityNoGates = eligibility === null || eligibility === void 0 ? void 0 : (_eligibility$investor = eligibility.investorEntities) === null || _eligibility$investor === void 0 ? void 0 : _eligibility$investor.find(_ref => {
      let {
        gateData
      } = _ref;
      return !gateData;
    });
    if (anyEntityNoGates) {
      return {};
    }
  }

  // investor entity which matches investorEntityId or first one with gate if not specified
  const investorEntity = eligibility === null || eligibility === void 0 ? void 0 : (_eligibility$investor2 = eligibility.investorEntities) === null || _eligibility$investor2 === void 0 ? void 0 : _eligibility$investor2.find(_ref2 => {
    let {
      investorEntityId: entityId,
      gateData
    } = _ref2;
    return config !== null && config !== void 0 && config.investorEntityId ? config.investorEntityId === entityId : !!gateData;
  });

  // investor entity gate if exists
  const investorEntityGate = investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.gateData;

  // investor account which is available first assuming always one account if available
  // since both endpoints should return only their respective type of accounts
  const investorAccount = investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.investorAccounts.find(Boolean);

  // investor account gate if exists
  const investorAccountGate = investorAccount === null || investorAccount === void 0 ? void 0 : investorAccount.gateData;
  return {
    userGate,
    investorEntity,
    investorEntityGate,
    investorAccount,
    investorAccountGate
  };
};