var __jsx = React.createElement;
import React, { useState, Fragment, useEffect } from 'react';
import styled from 'styled-components';
import Carousel from 'nuka-carousel';
import { Paragraph, Heading } from '../../content/text';
import { media } from '../../themes';
import { useResponsiveFriendly } from '../../utils/responsive';
export const CarouselSection = _ref => {
  let {
    section
  } = _ref;
  const {
    title,
    autoplayInterval,
    content
  } = section;
  const {
    0: currentSlide,
    1: setCurrentSlide
  } = useState(0);
  const {
    0: displayCarousel,
    1: setDisplayCarousel
  } = useState(false);
  useEffect(() => {
    setDisplayCarousel(true);
  }, [setDisplayCarousel]);
  const viewPort = useResponsiveFriendly();
  return __jsx(Wrapper, {
    "data-cy": "offering-v5-section-carousel-wrapper"
  }, __jsx(InnerWrapper, null, viewPort !== 'mobile' && __jsx(ControlWrapper, {
    "data-cy": "offering-v5-section-carousel-control-wrapper"
  }, __jsx(WhiteHeading, {
    type: 3,
    "data-cy": "offering-v5-section-carousel-control-title"
  }, title), content.map((slide, idx) => {
    const {
      slideTitle
    } = slide;
    return __jsx(SlideControl, {
      "data-cy": "slide-control",
      type: 4,
      key: `slide-control-${slideTitle}-${idx}`,
      onClick: () => setCurrentSlide(idx),
      isActive: currentSlide === idx
    }, slideTitle);
  })), displayCarousel && __jsx(SCarousel, {
    afterSlide: slideIndex => setCurrentSlide(slideIndex),
    autoplay: true,
    autoplayInterval: autoplayInterval ? autoplayInterval : 5000,
    cellAlign: "center",
    cellSpacing: 400,
    "data-cy": "offering-v5-section-carousel-slide-wrapper",
    dragging: true,
    framePadding: "10px",
    heightMode: "current",
    initialSlideHeight: 400,
    slideIndex: currentSlide,
    slidesToShow: 1,
    vertical: viewPort !== 'mobile',
    wrapAround: true
  }, content.map((slide, idx) => {
    const {
      slideTitle,
      slideText
    } = slide;
    return __jsx(Fragment, {
      key: `slide-content-${slideTitle}-${idx}`
    }, viewPort !== 'mobile' && __jsx(DesktopContentWrapper, {
      "data-cy": "offering-v5-section-carousel-slide-content-desktop"
    }, __jsx(Paragraph, {
      small: true
    }, __jsx("span", {
      dangerouslySetInnerHTML: {
        __html: slideText
      }
    }))), viewPort === 'mobile' && __jsx(MobileContentWrapper, {
      "data-cy": "offering-v5-section-carousel-slide-content-mobile"
    }, __jsx(MobileCarouselHeadingWrapper, null, __jsx(MobileCarouselHeading, {
      type: 4
    }, slideTitle)), __jsx(MobileCarouselContent, null, __jsx("span", {
      dangerouslySetInnerHTML: {
        __html: slideText
      }
    }))));
  }))));
};
const Wrapper = styled.div.withConfig({
  displayName: "CarouselSection__Wrapper",
  componentId: "sc-lnvgm5-0"
})(["margin:0 auto 30px;position:relative;", ";"], media.tablet`
    margin: 0 auto 35px;
    background: ${props => props.theme.colors.chart_8};
  `);
const InnerWrapper = styled.div.withConfig({
  displayName: "CarouselSection__InnerWrapper",
  componentId: "sc-lnvgm5-1"
})(["display:flex;margin:0 20px;max-width:1180px;", ";", ";"], media.tablet`
    margin: 0 auto;
    padding: 0 35px;
    min-height: 400px;
  `, media.large`
    padding: 0;
  `);
const ControlWrapper = styled.div.withConfig({
  displayName: "CarouselSection__ControlWrapper",
  componentId: "sc-lnvgm5-2"
})(["display:flex;flex-direction:column;justify-content:center;align-items:flex-start;width:40%;margin:20px 10%;color:", ";", ";"], props => props.theme.colors.text_background_inverse, media.tablet`
    margin: 20px 0;
  `);
const WhiteHeading = styled(Heading).withConfig({
  displayName: "CarouselSection__WhiteHeading",
  componentId: "sc-lnvgm5-3"
})(["color:", ";margin-bottom:30px;"], props => props.theme.colors.text_background_inverse);
const SlideControl = styled(WhiteHeading).withConfig({
  displayName: "CarouselSection__SlideControl",
  componentId: "sc-lnvgm5-4"
})(["cursor:pointer;margin-bottom:20px;font-weight:", ";"], props => props.isActive ? 500 : 'normal');
const DesktopContentWrapper = styled.div.withConfig({
  displayName: "CarouselSection__DesktopContentWrapper",
  componentId: "sc-lnvgm5-5"
})(["display:block;"]);
const MobileContentWrapper = styled.div.withConfig({
  displayName: "CarouselSection__MobileContentWrapper",
  componentId: "sc-lnvgm5-6"
})(["background:", ";box-shadow:0px 4px 8px rgba(0,0,0,0.1);margin:20px 6px;min-height:251px;"], props => props.theme.colors.background_card);
const MobileCarouselHeadingWrapper = styled.div.withConfig({
  displayName: "CarouselSection__MobileCarouselHeadingWrapper",
  componentId: "sc-lnvgm5-7"
})(["padding:20px;height:68px;background:", ";"], props => props.theme.colors.chart_8);
const MobileCarouselHeading = styled(WhiteHeading).withConfig({
  displayName: "CarouselSection__MobileCarouselHeading",
  componentId: "sc-lnvgm5-8"
})(["margin-bottom:0;"]);
const MobileCarouselContent = styled(Paragraph).withConfig({
  displayName: "CarouselSection__MobileCarouselContent",
  componentId: "sc-lnvgm5-9"
})(["padding:22px 20px 51px;"]);
const SCarousel = styled(Carousel).withConfig({
  displayName: "CarouselSection__SCarousel",
  componentId: "sc-lnvgm5-10"
})(["display:block;background:", ";margin:0;", ";.slider-control-centerright,.slider-control-centerleft{display:none;}.slider-control-bottomcenter{width:100% !important;text-align:center;bottom:-25px !important;", ";ul{justify-content:center !important;}}.paging-item{button{padding:0 4px !important;opacity:unset !important;fill:transparent !important;}&.active{.paging-dot{background:", " !important;}}.paging-dot{width:10px !important;height:10px !important;background:", " !important;border-radius:10px !important;}}.slider-list{width:100% !important;margin-bottom:40px !important;}.slider-slide{vertical-align:middle !important;}.slider-frame{margin:0 !important;", ";}"], props => props.theme.colors.background_default, media.tablet`
    margin: 10px 0;
    overflow: hidden !important;
    z-index: 2;
  `, media.tablet`
      display: none;
    `, props => props.theme.colors.chart_1, props => props.theme.colors.border_strong, media.tablet`
      max-width: 70% !important;
      margin: auto !important;
      position: absolute !important;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      overflow: visible !important;
    `);