import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { SFormControl, SFormHelperText, FormError, withFormikWeb } from '../common';
import { PickerPopover, SDatePicker } from './styles';
import { CallbackLink } from '../../content';
export const DatePickerInput = props => {
  const {
    value,
    label,
    error,
    caption,
    className,
    width,
    inline,
    // disabled,
    // helperText,
    minDate,
    onChange,
    name
    // testId,
  } = props;
  const inputRef = useRef(null);
  const {
    0: pickerOpen,
    1: setPickerOpen
  } = useState(false);
  const {
    0: selectedDate,
    1: setSelectedDate
  } = useState(value);
  const defaultOnChange = date => {
    if (date) {
      setSelectedDate(date);
    }
  };
  return __jsx(SFormControl, {
    ref: inputRef,
    variant: "filled",
    error: !!error,
    className: className,
    style: {
      width
    }
  }, __jsx(LocalizationProvider, {
    dateAdapter: AdapterDateFns
  }, inline ? __jsx(DatePicker, {
    label: label,
    renderInput: props => {
      var _props$inputProps;
      return __jsx(CallbackLink, {
        callback: () => setPickerOpen(!pickerOpen)
      }, (_props$inputProps = props.inputProps) === null || _props$inputProps === void 0 ? void 0 : _props$inputProps.value);
    },
    PopperProps: {
      anchorEl: inputRef.current
    },
    PaperProps: {
      component: PickerPopover
    },
    value: selectedDate,
    open: pickerOpen,
    inputFormat: "MM/dd/yyyy",
    onOpen: () => setPickerOpen(true),
    onClose: () => setPickerOpen(false),
    minDate: minDate ? minDate : new Date(),
    onChange: onChange ? value => onChange(value) : defaultOnChange
  }) : __jsx(DatePicker, {
    label: label,
    renderInput: props => {
      return __jsx(SDatePicker, _extends({
        variant: "standard"
      }, props));
    },
    components: {
      OpenPickerIcon: props => __jsx(FontAwesomeIcon, _extends({
        icon: faCalendarAlt,
        size: "sm"
      }, props))
    },
    PopperProps: {
      anchorEl: inputRef.current
    },
    PaperProps: {
      component: PickerPopover
    },
    value: selectedDate,
    open: pickerOpen,
    inputFormat: "MM/dd/yyyy",
    onOpen: () => setPickerOpen(true),
    onClose: () => setPickerOpen(false),
    minDate: minDate ? minDate : new Date(),
    onChange: onChange ? value => onChange(value) : defaultOnChange
  })), error || caption ? __jsx(SFormHelperText, null, error ? __jsx(FormError, {
    testId: "error",
    type: "date-input",
    name: name || 'date',
    error: error
  }) : caption) : null);
};
export const FormDatePickerInput = withFormikWeb(DatePickerInput);