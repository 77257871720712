import replace from "lodash/replace";
import startCase from "lodash/startCase";
import toLower from "lodash/toLower";
import { AssetClassType } from '@yieldstreet/platform-kit';
import { formatPercentageDecimal } from '../../utils';
import { getAssetColors } from '../overview';
const getAssetClassType = assetTitle => {
  const name = replace(startCase(assetTitle), /\s/g, '');
  return AssetClassType[name] || null;
};
export const getAssetSeriesColor = function (assetTitle) {
  let opacity = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  const assetClassType = getAssetClassType(assetTitle);
  if (assetClassType) {
    return getAssetColors(opacity)[assetClassType] || null;
  }
  return null;
};
export const formatTargetAllocations = targetAllocations => targetAllocations.map(_ref => {
  let {
    assetClass,
    allocationPercentage
  } = _ref;
  return {
    assetClass: startCase(toLower(assetClass)),
    allocationPercentage: formatPercentageDecimal(allocationPercentage, 2)
  };
});
export const formatAssetClass = assetClass => startCase(toLower(assetClass));