import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { CALL_API } from '../middleware/Middleware';
export const FETCH_QUIZ_ANSWERS_ATTEMPT = 'ys/quiz/answers/fetch/ATTEMPT';
export const FETCH_QUIZ_ANSWERS_SUCCESS = 'ys/quiz/answers/fetch/SUCCESS';
export const FETCH_QUIZ_ANSWERS_FAILURE = 'ys/quiz/answers/fetch/FAILURE';
export const fetchQuizAnswers = quizId => dispatch => {
  const endpoint = `/api/quiz/${quizId}`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [FETCH_QUIZ_ANSWERS_ATTEMPT, FETCH_QUIZ_ANSWERS_SUCCESS, FETCH_QUIZ_ANSWERS_FAILURE],
      getResponse: res => res.data.answers || []
    }
  });
};
export const FETCH_USER_QUIZ_ANSWERS_ATTEMPT = 'ys/quiz/user/answers/fetch/ATTEMPT';
export const FETCH_USER_QUIZ_ANSWERS_SUCCESS = 'ys/quiz/user/answers/fetch/SUCCESS';
export const FETCH_USER_QUIZ_ANSWERS_FAILURE = 'ys/quiz/user/answers/fetch/FAILURE';
export const fetchUserQuizAnswers = () => dispatch => {
  const endpoint = `/api/quiz/user`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [FETCH_USER_QUIZ_ANSWERS_ATTEMPT, FETCH_USER_QUIZ_ANSWERS_SUCCESS, FETCH_USER_QUIZ_ANSWERS_FAILURE],
      getResponse: res => res.data.answers || []
    }
  });
};
export const SUBMIT_QUIZ_ANSWERS_ATTEMPT = 'ys/quiz/answers/submit/ATTEMPT';
export const SUBMIT_QUIZ_ANSWERS_SUCCESS = 'ys/quiz/answers/submit/SUCCESS';
export const SUBMIT_QUIZ_ANSWERS_FAILURE = 'ys/quiz/answers/submit/FAILURE';
export const submitQuizAnswer = answer => (dispatch, getState) => {
  const {
    userState: {
      id: userId = null
    },
    analyticsAnonymousId: anonymousUserId = null
  } = getState().user;
  const endpoint = `/api/quiz/answer`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [SUBMIT_QUIZ_ANSWERS_ATTEMPT, SUBMIT_QUIZ_ANSWERS_SUCCESS, SUBMIT_QUIZ_ANSWERS_FAILURE],
      options: {
        method: 'POST',
        data: _objectSpread({
          userId,
          anonymousUserId
        }, answer)
      },
      attemptPayload: answer
    }
  });
};