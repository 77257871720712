import { CALL_API } from '../middleware/Middleware';
export const GET_ORIGINATOR_ANCILLARY_ATTEMPT = 'ys/originator/request/ancillary/ATTEMPT';
export const GET_ORIGINATOR_ANCILLARY_SUCCESS = 'ys/originator/request/ancillary/SUCCESS';
export const GET_ORIGINATOR_ANCILLARY_FAILURE = 'ys/originator/request/ancillary/FAILURE';
export const getOriginatorAncillary = () => dispatch => {
  const endpoint = '/a/api/originator/request/ancillary';
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_ORIGINATOR_ANCILLARY_ATTEMPT, GET_ORIGINATOR_ANCILLARY_SUCCESS, GET_ORIGINATOR_ANCILLARY_FAILURE],
      getResponse: res => res.data
    }
  });
};
export const POST_ORIGINATOR_REQUEST_ATTEMPT = 'ys/post/originator/request/ATTEMPT';
export const POST_ORIGINATOR_REQUEST_SUCCESS = 'ys/post/originator/request/SUCCESS';
export const POST_ORIGINATOR_REQUEST_FAILURE = 'ys/post/originator/request/FAILURE';
export const postOriginatorRequest = _ref => {
  let {
    requestId,
    data
  } = _ref;
  return dispatch => {
    const endpoint = `/a/api/originator/request${!!requestId ? `/${requestId}` : ''}`;
    return dispatch({
      [CALL_API]: {
        endpoint,
        types: [POST_ORIGINATOR_REQUEST_ATTEMPT, POST_ORIGINATOR_REQUEST_SUCCESS, POST_ORIGINATOR_REQUEST_FAILURE],
        options: {
          method: 'POST',
          data
        },
        getResponse: res => res.data
      }
    });
  };
};
export const GET_ORIGINATOR_TITLE_ATTEMPT = 'ys/originatorTitle/ATTEMPT';
export const GET_ORIGINATOR_TITLE_SUCCESS = 'ys/originatorTitle/SUCCESS';
export const GET_ORIGINATOR_TITLE_FAILURE = 'ys/originatorTitle/FAILURE';
export const getOriginatorTitle = slug => dispatch => {
  const endpoint = `/a/api/originator/${slug}`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_ORIGINATOR_TITLE_ATTEMPT, GET_ORIGINATOR_TITLE_SUCCESS, GET_ORIGINATOR_TITLE_FAILURE],
      getResponse: res => res.data
    }
  });
};
export const GET_ORIGINATOR_DETAILS_ATTEMPT = 'ys/originatorDetails/ATTEMPT';
export const GET_ORIGINATOR_DETAILS_SUCCESS = 'ys/originatorDetails/SUCCESS';
export const GET_ORIGINATOR_DETAILS_FAILURE = 'ys/originatorDetails/FAILURE';
export const getOriginatorDetails = slug => dispatch => {
  const endpoint = `/a/api/originator/${slug}/description`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_ORIGINATOR_DETAILS_ATTEMPT, GET_ORIGINATOR_DETAILS_SUCCESS, GET_ORIGINATOR_DETAILS_FAILURE],
      getResponse: res => res.data
    }
  });
};
export const GET_ORIGINATOR_PAST_ACTIVITY_ATTEMPT = 'ys/originatorPastActivity/ATTEMPT';
export const GET_ORIGINATOR_PAST_ACTIVITY_SUCCESS = 'ys/originatorPastActivity/SUCCESS';
export const GET_ORIGINATOR_PAST_ACTIVITY_FAILURE = 'ys/originatorPastActivity/FAILURE';
export const getOriginatorPastActivity = slug => dispatch => {
  const endpoint = `/a/api/originator/${slug}/past-activity`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_ORIGINATOR_PAST_ACTIVITY_ATTEMPT, GET_ORIGINATOR_PAST_ACTIVITY_SUCCESS, GET_ORIGINATOR_PAST_ACTIVITY_FAILURE],
      getResponse: res => res.data
    }
  });
};
export const GET_ORIGINATOR_ATTEMPT = 'ys/originator/ATTEMPT';
export const GET_ORIGINATOR_SUCCESS = 'ys/originator/SUCCESS';
export const GET_ORIGINATOR_FAILURE = 'ys/originator/FAILURE';
export const getOriginator = slug => dispatch => {
  dispatch({
    type: GET_ORIGINATOR_ATTEMPT
  });
  return Promise.all([dispatch(getOriginatorTitle(slug)), dispatch(getOriginatorDetails(slug)), dispatch(getOriginatorPastActivity(slug))]).then(() => {
    dispatch({
      type: GET_ORIGINATOR_SUCCESS
    });
  }, error => {
    dispatch({
      type: GET_ORIGINATOR_FAILURE,
      error
    });
  });
};