import { genTestIds } from '@yieldstreet/tool-kit';
export const userFields = {
  fullName: 'Full Name',
  address: 'Address',
  birth: 'Date of Birth',
  linkText: 'Change'
};
export const formFields = {
  investorAccount: 'Investor Account',
  employerName: {
    name: 'employerName',
    label: 'Employer Name'
  },
  ssn: {
    name: 'ssn',
    label: 'Confirm Social Security Number'
  },
  submitButton: 'Continue'
};
const {
  getTestIds
} = genTestIds(['formWrapper', 'employerNameField', 'ssnField', 'submitButton']);
export const testIds = getTestIds('ira-source-account');