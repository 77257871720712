var __jsx = React.createElement;
import React from 'react';
import { media, Paragraph } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
const _notCheckboxOrRadio = ":not([data-checkbox='true']):not([data-radio='true'])";
const _notCheckboxGroupOrRadioGroup = ":not([data-checkbox-group='true']):not([data-radio-group='true'])";
const FieldSectionWrapper = styled.div.withConfig({
  displayName: "Fieldsstyle__FieldSectionWrapper",
  componentId: "sc-1w8efv8-0"
})(["display:flex;gap:16px;flex-flow:column wrap;width:100%;max-width:", ";padding-bottom:16px;margin-bottom:", ";align-items:flex-start;& .MuiFormControl-root", "{width:100%;}", ""], _ref => {
  let {
    maxWidth
  } = _ref;
  return maxWidth || '1000px';
}, props => props.noMarginBottom ? '0' : '40px', _notCheckboxOrRadio, media.tablet`
    flex-direction: row;

    & .MuiFormControl-root${_notCheckboxOrRadio}${_notCheckboxGroupOrRadioGroup} {
      width: 260px;
    }

  `);
const FieldSectionHeaderWrapper = styled.div.withConfig({
  displayName: "Fieldsstyle__FieldSectionHeaderWrapper",
  componentId: "sc-1w8efv8-1"
})(["margin-bottom:16px;"]);
const FieldSectionHeader = _ref2 => {
  let {
    children,
    description
  } = _ref2;
  return __jsx(FieldSectionHeaderWrapper, null, __jsx(Paragraph, {
    semiBold: true
  }, children), description && __jsx(Paragraph, {
    secondary: true
  }, description));
};
export { FieldSectionWrapper, FieldSectionHeader };