var __jsx = React.createElement;
import React from 'react';
import { FormTagsCheckboxGroupInput } from '@yieldstreet/ui-kit';
import { AdviceQuestionsConfig } from 'utils/advice';
import { FieldSectionWrapper, FieldSectionHeader } from './Fields.style';
const {
  InterestedAssetClasses
} = AdviceQuestionsConfig;
export const AssetClassesField = _ref => {
  let {
    formikProps
  } = _ref;
  return __jsx(React.Fragment, null, __jsx(FieldSectionHeader, {
    description: "Select the types of investments that interest you the most."
  }, InterestedAssetClasses.label), __jsx(FieldSectionWrapper, {
    maxWidth: "850px"
  }, __jsx(FormTagsCheckboxGroupInput, {
    name: InterestedAssetClasses.key,
    options: InterestedAssetClasses.options,
    formikProps: formikProps
  })));
};