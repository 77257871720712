import styled from 'styled-components';
export const OfferingThumbSkeleton = styled.div.withConfig({
  displayName: "OfferingCardV2Skeletonstyle__OfferingThumbSkeleton",
  componentId: "sc-3ljxvs-0"
})(["border-radius:6px;min-height:140px;width:100%;"]);
export const MainContainerSkeleton = styled.div.withConfig({
  displayName: "OfferingCardV2Skeletonstyle__MainContainerSkeleton",
  componentId: "sc-3ljxvs-1"
})(["align-items:flex-start;display:flex;flex-direction:column;justify-content:space-between;height:115px;"]);
export const TitleContainerSkeleton = styled.div.withConfig({
  displayName: "OfferingCardV2Skeletonstyle__TitleContainerSkeleton",
  componentId: "sc-3ljxvs-2"
})(["align-items:flex-start;display:flex;flex-direction:column;gap:", ";width:100%;"], props => props.theme.spacing.xxs);
export const AssetClassWrapperSkeleton = styled.div.withConfig({
  displayName: "OfferingCardV2Skeletonstyle__AssetClassWrapperSkeleton",
  componentId: "sc-3ljxvs-3"
})(["gap:", ";width:50%;"], props => props.theme.spacing.xxs);
export const ContentContainerSkeleton = styled.div.withConfig({
  displayName: "OfferingCardV2Skeletonstyle__ContentContainerSkeleton",
  componentId: "sc-3ljxvs-4"
})(["display:flex;flex-direction:column;height:100%;width:100%;gap:", ";"], props => props.theme.spacing.xs);
export const BadgeContainerSkeleton = styled.div.withConfig({
  displayName: "OfferingCardV2Skeletonstyle__BadgeContainerSkeleton",
  componentId: "sc-3ljxvs-5"
})(["display:flex;flex-direction:row;gap:", ";"], props => props.theme.spacing.xxs);
export const BadgeSkeleton = styled.div.withConfig({
  displayName: "OfferingCardV2Skeletonstyle__BadgeSkeleton",
  componentId: "sc-3ljxvs-6"
})(["height:30px;width:30%;"]);