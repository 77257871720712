export const Strategies = [{
  key: 0,
  title: 'Income',
  desc: 'Prioritize current income'
}, {
  key: 1,
  title: 'Growth',
  desc: 'Prioritize returns'
}, {
  key: 2,
  title: 'Balanced',
  desc: 'Blend income and growth'
}];