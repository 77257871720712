import _defineProperty from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
class EvalFlagComponents {
  constructor() {
    _defineProperty(this, "flagComponentMap", {});
    _defineProperty(this, "getAllFlagComponents", flag => this.flagComponentMap[flag] || []);
    _defineProperty(this, "clearAllFlagComponents", flag => {
      delete this.flagComponentMap[flag];
    });
    _defineProperty(this, "addFlagComponent", (flag, componentId) => {
      if (!this.flagHasEvalComponent(flag, componentId)) {
        this.flagComponentMap[flag] = [...this.getAllFlagComponents(flag), componentId];
      }
    });
    _defineProperty(this, "removeFlagComponent", (flag, componentId) => {
      if (this.flagHasEvalComponent(flag, componentId)) {
        var _this$flagComponentMa;
        (_this$flagComponentMa = this.flagComponentMap[flag]) === null || _this$flagComponentMa === void 0 ? void 0 : _this$flagComponentMa.filter(id => componentId !== id);
      }
    });
    _defineProperty(this, "flagHasEval", flag => {
      const flagComponents = this.getAllFlagComponents(flag);
      return !!flagComponents.length;
    });
    _defineProperty(this, "flagHasEvalComponent", (flag, componentId) => {
      const flagComponents = this.getAllFlagComponents(flag);
      return !!flagComponents.length && flagComponents.find(id => componentId === id);
    });
    _defineProperty(this, "resetAllFlags", () => this.flagComponentMap = {});
  }
}
export const evalFlagComponents = new EvalFlagComponents();