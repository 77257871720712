var __jsx = React.createElement;
import React, { useEffect } from 'react';
import styled, { withTheme } from 'styled-components';
import isArray from 'lodash/isArray';
import { setClientError } from '@yieldstreet/tool-kit';
const FormErrorInner = _ref => {
  let {
    type,
    error,
    name,
    testId
  } = _ref;
  useEffect(() => {
    const event = {
      errorType: type || '',
      errorMessage: isArray(error) ? error[0] : error || '',
      errorId: name || ''
    };
    setClientError(event, 'form-field-error');
  }, [error, type, name]);
  return error ? __jsx(FormErrorContainer, {
    "data-cy": testId || 'error'
  }, error) : null;
};
export const FormErrorContainer = styled.div.withConfig({
  displayName: "FormError__FormErrorContainer",
  componentId: "sc-1mlwjw-0"
})(["display:inline-flex;align-items:center;"]);
export const FormError = withTheme(FormErrorInner);