export const FOOTER_NAVIGATION = [{
  heading: 'Investors',
  menu: [{
    key: 'invest',
    title: 'Offerings',
    route: '/offerings/'
  }, {
    key: 'how-it-works',
    title: 'How it works',
    route: '/how-it-works/',
    staticRoute: true
  }, {
    key: 'alternative-income-fund',
    title: 'Alternative Income Fund',
    route: 'https://yieldstreetalternativeincomefund.com',
    forceExternal: true,
    newTab: true
  }, {
    key: 'ys-ira',
    title: 'IRA accounts',
    route: '/ira/',
    staticRoute: true
  }, {
    key: 'short-term-notes',
    title: 'Short Term Notes',
    route: '/short-term-notes/',
    staticRoute: true
  }, {
    key: 'real-estate-investing',
    title: 'Real Estate',
    route: '/real-estate-investing/',
    staticRoute: true
  }, {
    key: 'art-investing',
    title: 'Art',
    route: '/investing-in-art/',
    staticRoute: true
  }]
}, {
  heading: 'Resources',
  menu: [{
    key: 'contact',
    title: 'Contact us',
    id: 'intercom-link'
  }, {
    key: 'support',
    title: 'Support',
    route: '/support/'
  }, {
    key: 'education',
    title: 'Insight and education',
    route: '/articles/'
  }, {
    key: 'performance',
    title: 'Performance',
    route: '/statistics/',
    staticRoute: true
  }]
}, {
  heading: 'Company',
  menu: [{
    key: 'about',
    title: 'About us',
    route: '/about/',
    staticRoute: true
  }, {
    key: 'careers',
    title: 'Careers',
    route: '/careers/',
    staticRoute: true
  }, {
    key: 'press',
    title: 'Press',
    route: '/press/'
  }, {
    key: 'capital',
    title: 'Capital partnerships',
    route: '/raise-capital/',
    staticRoute: true
  }]
}];