import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import get from "lodash/get";
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import { ARTICLE_TYPE, VIDEO_TYPE, INFOGRAPHIC_TYPE, PRESS_CATEGORYID, YIELDSTREET_DEFAULT_TYPE } from '../../pages/resources/shared/blog.consts';
import { CALL_API } from '../middleware/Middleware';
export const GET_TEAM_MEMBERS_ATTEMPT = 'ys/teamMembers/ATTEMPT';
export const GET_TEAM_MEMBERS_SUCCESS = 'ys/teamMembers/SUCCESS';
export const GET_TEAM_MEMBERS_FAILURE = 'ys/teamMembers/FAILURE';
export const getTeamMembers = () => dispatch => {
  const teamCategories = {
    founders: 'founder',
    team: 'staff',
    investors: 'investor',
    advisors: 'advisor',
    investorsLeadership: 'investments-leadership',
    prisimBoard: 'prism-board'
  };
  const categorySlugs = Object.values(teamCategories).join();
  const endpoint = `/wp-json/wp/v2/team_member?_embed&filter[team_category]=${categorySlugs}&filter[orderby]=menu_order&filter[order]=ASC&per_page=100`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_TEAM_MEMBERS_ATTEMPT, GET_TEAM_MEMBERS_SUCCESS, GET_TEAM_MEMBERS_FAILURE],
      getResponse: res => {
        return {
          teamMembers: filterByTeamCategory(res.data, teamCategories.team),
          advisors: filterByTeamCategory(res.data, teamCategories.advisors),
          investors: filterByTeamCategory(res.data, teamCategories.investors),
          investorsLeadership: filterByTeamCategory(res.data, teamCategories.investorsLeadership),
          prisimBoard: filterByTeamCategory(res.data, teamCategories.prisimBoard),
          founders: filterByTeamCategory(res.data, teamCategories.founders)
        };
      }
    }
  });
};

// Helper Functions
const filterByTeamCategory = (memberList, teamCategoryId) => {
  return cleanupResults(memberList.filter(member => {
    const terms = filter(member._embedded['wp:term'], x => !isEmpty(x));
    return !isEmpty(filter(terms[0], {
      slug: teamCategoryId
    }));
  }));
};
const cleanupResults = results => {
  return results ? results.reduce((p, c) => {
    p.push({
      id: c.id,
      name: c.title.rendered,
      title: c.team_title,
      thumbnail: get(c, ['_embedded', 'wp:featuredmedia', 0, 'media_details', 'sizes', 'thumbnail', 'source_url'], null),
      full_image: get(c, ['_embedded', 'wp:featuredmedia', 0, 'source_url'], null),
      bio: c.content.rendered,
      twitter_url: c.twitter_url,
      linkedin_url: c.linkedin_url,
      quote: c.quote,
      site_url: c.site_url
    });
    return p;
  }, []) : [];
};
export const GET_ARTICLES_BY_SLUG_ATTEMPT = 'ys/articlesBySlug/ATTEMPT';
export const GET_ARTICLES_BY_SLUG_SUCCESS = 'ys/articlesBySlug/SUCCESS';
export const GET_ARTICLES_BY_SLUG_FAILURE = 'ys/articlesBySlug/FAILURE';
export const getArticlesBySlug = (articleSlug, postType) => dispatch => {
  const endpoint = `/wp-json/wp/v2/${postType}/?_embed&slug=${articleSlug}`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_ARTICLES_BY_SLUG_ATTEMPT, GET_ARTICLES_BY_SLUG_SUCCESS, GET_ARTICLES_BY_SLUG_FAILURE],
      getResponse: res => res.data
    }
  });
};
export const GET_ARTICLES_BY_SLUG_RESET = 'ys/articlesBySlug/RESET';
export const resetArticlesBySlug = () => dispatch => dispatch({
  type: GET_ARTICLES_BY_SLUG_RESET
});
export const GET_MULTIPLE_ARTICLES_BY_SLUG_ATTEMPT = 'ys/multipleArticlesBySlug/ATTEMPT';
export const GET_MULTIPLE_ARTICLES_BY_SLUG_SUCCESS = 'ys/multipleArticlesBySlug/SUCCESS';
export const GET_MULTIPLE_ARTICLES_BY_SLUG_FAILURE = 'ys/multipleArticlesBySlug/FAILURE';
export const getMultipleArticlesBySlug = (articleSlug, postType) => dispatch => {
  const endpoint = `/wp-json/wp/v2/${postType}/?_embed&slug=${articleSlug}`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_MULTIPLE_ARTICLES_BY_SLUG_ATTEMPT, GET_MULTIPLE_ARTICLES_BY_SLUG_SUCCESS, GET_MULTIPLE_ARTICLES_BY_SLUG_FAILURE],
      getResponse: res => res.data
    }
  });
};
export const GET_ASSET_All_TYPES_ATTEMPT = 'ys/assetAllTypes/ATTEMPT';
export const GET_ASSET_All_TYPES_SUCCESS = 'ys/assetAllTypes/SUCCESS';
export const GET_ASSET_All_TYPES_FAILURE = 'ys/assetAllTypes/FAILURE';
export const getAssetClassesAllTypes = function (assetClassOrTopic, assetClassOrTopicId) {
  let page = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  let perPage = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 3;
  return dispatch => {
    const endpoint = `/wp-json/wp/v2/learningtool/?_embed&${assetClassOrTopic}=${assetClassOrTopicId}&content_type=${ARTICLE_TYPE},${VIDEO_TYPE},${INFOGRAPHIC_TYPE}&per_page=${perPage}&page=${page}`;
    return dispatch({
      [CALL_API]: {
        endpoint,
        types: [GET_ASSET_All_TYPES_ATTEMPT, GET_ASSET_All_TYPES_SUCCESS, GET_ASSET_All_TYPES_FAILURE],
        getResponse: res => res
      }
    });
  };
};
export const GET_ASSET_All_TYPES_RESET = 'ys/assetAllTypes/RESET';
export const resetAssetClassesAllTypes = () => dispatch => dispatch({
  type: GET_ASSET_All_TYPES_RESET
});
export const GET_ASSET_ARTICLES_ATTEMPT = 'ys/assetArticles/ATTEMPT';
export const GET_ASSET_ARTICLES_SUCCESS = 'ys/assetArticles/SUCCESS';
export const GET_ASSET_ARTICLES_FAILURE = 'ys/assetArticles/FAILURE';
export const getAssetClassesArticles = function (assetClassOrTopic, assetClassOrTopicId) {
  let page = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  let perPage = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 9;
  return dispatch => {
    const endpoint = `/wp-json/wp/v2/learningtool/?_embed&${assetClassOrTopic}=${assetClassOrTopicId}&content_type=${ARTICLE_TYPE}&per_page=${perPage}&page=${page}`;
    return dispatch({
      [CALL_API]: {
        endpoint,
        types: [GET_ASSET_ARTICLES_ATTEMPT, GET_ASSET_ARTICLES_SUCCESS, GET_ASSET_ARTICLES_FAILURE],
        getResponse: res => res
      }
    });
  };
};
export const GET_ASSET_ARTICLES_RESET = 'ys/assetArticles/RESET';
export const resetAssetClassesArticles = () => dispatch => dispatch({
  type: GET_ASSET_ARTICLES_RESET
});
export const GET_ASSET_VIDEOS_ATTEMPT = 'ys/assetVideo/ATTEMPT';
export const GET_ASSET_VIDEOS_SUCCESS = 'ys/assetVideo/SUCCESS';
export const GET_ASSET_VIDEOS_FAILURE = 'ys/assetVideo/FAILURE';
export const getAssetClassesVideos = function (assetClassOrTopic, assetClassOrTopicId) {
  let page = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  let perPage = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 10;
  return dispatch => {
    const endpoint = `/wp-json/wp/v2/learningtool/?_embed&${assetClassOrTopic}=${assetClassOrTopicId}&content_type=${VIDEO_TYPE}&per_page=${perPage}&page=${page}`;
    return dispatch({
      [CALL_API]: {
        endpoint,
        types: [GET_ASSET_VIDEOS_ATTEMPT, GET_ASSET_VIDEOS_SUCCESS, GET_ASSET_VIDEOS_FAILURE],
        getResponse: res => res
      }
    });
  };
};
export const GET_ASSET_VIDEOS_RESET = 'ys/assetVideo/RESET';
export const resetAssetClassesVideos = () => dispatch => dispatch({
  type: GET_ASSET_VIDEOS_RESET
});
export const GET_ASSET_INFOGRAPHICS_ATTEMPT = 'ys/assetInfographic/ATTEMPT';
export const GET_ASSET_INFOGRAPHICS_SUCCESS = 'ys/assetInfographic/SUCCESS';
export const GET_ASSET_INFOGRAPHICS_FAILURE = 'ys/assetInfographic/FAILURE';
export const getAssetClassesInfographics = function (assetClassOrTopic, assetClassOrTopicId) {
  let page = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1;
  let perPage = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 10;
  return dispatch => {
    const endpoint = `/wp-json/wp/v2/learningtool/?_embed&${assetClassOrTopic}=${assetClassOrTopicId}&content_type=${INFOGRAPHIC_TYPE}&per_page=${perPage}&page=${page}`;
    return dispatch({
      [CALL_API]: {
        endpoint,
        types: [GET_ASSET_INFOGRAPHICS_ATTEMPT, GET_ASSET_INFOGRAPHICS_SUCCESS, GET_ASSET_INFOGRAPHICS_FAILURE],
        getResponse: res => res
      }
    });
  };
};
export const GET_ASSET_INFOGRAPHICS_RESET = 'ys/assetInfographic/RESET';
export const resetAssetClassesInfographics = () => dispatch => dispatch({
  type: GET_ASSET_INFOGRAPHICS_RESET
});
export const GET_PRESS_ARTICLES_ATTEMPT = 'ys/pressArticles/ATTEMPT';
export const GET_PRESS_ARTICLES_SUCCESS = 'ys/pressArticles/SUCCESS';
export const GET_PRESS_ARTICLES_FAILURE = 'ys/pressArticles/FAILURE';
export const getPressArticles = function () {
  let page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  let perPage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 10;
  return dispatch => {
    const endpoint = `/wp-json/wp/v2/posts?categories=${PRESS_CATEGORYID}&per_page=${perPage}&page=${page}`;
    return dispatch({
      [CALL_API]: {
        endpoint,
        types: [GET_PRESS_ARTICLES_ATTEMPT, GET_PRESS_ARTICLES_SUCCESS, GET_PRESS_ARTICLES_FAILURE],
        getResponse: res => res
      }
    });
  };
};
export const GET_PRESS_ARTICLES_RESET = 'ys/pressArticles/RESET';
export const resetPressArticles = () => dispatch => dispatch({
  type: GET_PRESS_ARTICLES_RESET
});
export const GET_YIELDSTREET_DEFAULTS_ARTICLES_ATTEMPT = 'ys/yieldstreetDefaultsArticles/ATTEMPT';
export const GET_YIELDSTREET_DEFAULTS_ARTICLES_SUCCESS = 'ys/yieldstreetDefaultsArticles/SUCCESS';
export const GET_YIELDSTREET_DEFAULTS_ARTICLES_FAILURE = 'ys/yieldstreetDefaultsArticles/FAILURE';
export const getYieldstreetDefaultsArticles = function () {
  let page = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  let perPage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 10;
  return dispatch => {
    const endpoint = `/wp-json/wp/v2/posts?categories=${YIELDSTREET_DEFAULT_TYPE}&per_page=${perPage}&page=${page}`;
    return dispatch({
      [CALL_API]: {
        endpoint,
        types: [GET_YIELDSTREET_DEFAULTS_ARTICLES_ATTEMPT, GET_YIELDSTREET_DEFAULTS_ARTICLES_SUCCESS, GET_YIELDSTREET_DEFAULTS_ARTICLES_FAILURE],
        getResponse: res => res
      }
    });
  };
};
export const GET_YIELDSTREET_DEFAULTS_ARTICLES_RESET = 'ys/yieldstreetDefaultsArticles/RESET';
export const resetYieldstreetDefaultsArticles = () => dispatch => dispatch({
  type: GET_YIELDSTREET_DEFAULTS_ARTICLES_RESET
});
export const GET_BLOG_ARTICLES_ATTEMPT = 'ys/blogArticles/ATTEMPT';
export const GET_BLOG_ARTICLES_SUCCESS = 'ys/blogArticles/SUCCESS';
export const GET_BLOG_ARTICLES_FAILURE = 'ys/blogArticles/FAILURE';
export const getBlogArticles = _ref => {
  let {
    page = 1,
    perPage = 10,
    postType = 'posts',
    param = null,
    paramData = null
  } = _ref;
  return dispatch => {
    let extraParams = param && paramData ? `&${param}=${paramData}` : '';
    if (param === 'categories' && paramData === PRESS_CATEGORYID) {
      extraParams = '';
    }
    const endpoint = `/wp-json/wp/v2/${postType}/?_embed&page=${page}&per_page=${perPage}&categories_exclude=${PRESS_CATEGORYID}${extraParams}`;
    return dispatch({
      [CALL_API]: {
        endpoint,
        types: [GET_BLOG_ARTICLES_ATTEMPT, GET_BLOG_ARTICLES_SUCCESS, GET_BLOG_ARTICLES_FAILURE],
        getResponse: res => res
      }
    });
  };
};
export const GET_BLOG_CATEGORIES_ATTEMPT = 'ys/blogCategories/ATTEMPT';
export const GET_BLOG_CATEGORIES_SUCCESS = 'ys/blogCategories/SUCCESS';
export const GET_BLOG_CATEGORIES_FAILURE = 'ys/blogCategories/FAILURE';
export const fetchBlogCategories = () => dispatch => {
  const endpoint = `/wp-json/wp/v2/categories`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_BLOG_CATEGORIES_ATTEMPT, GET_BLOG_CATEGORIES_SUCCESS, GET_BLOG_CATEGORIES_FAILURE],
      getResponse: res => {
        const {
          data
        } = res;
        if (data.length > 0) {
          const filteredByCategoryId = data.filter(item => item.id !== PRESS_CATEGORYID);
          return _objectSpread(_objectSpread({}, res), {}, {
            data: filteredByCategoryId
          });
        }
        return res;
      }
    }
  });
};
export const GET_BLOG_ARTICLES_RESET = 'ys/blogArticles/RESET';
export const fetchArticlesByCategorySlug = _ref2 => {
  let {
    categorySlug,
    page
  } = _ref2;
  return dispatch => {
    return dispatch(fetchBlogCategories()).then(res => {
      if (categorySlug) {
        const {
          data
        } = res === null || res === void 0 ? void 0 : res.response;
        const category = data.find(item => item.slug === categorySlug);
        return dispatch(getBlogArticles({
          page,
          param: 'categories',
          paramData: category.id
        }));
      }
      return dispatch(getBlogArticles({
        page
      }));
    });
  };
};
export const resetBlogArticles = () => dispatch => dispatch({
  type: GET_BLOG_ARTICLES_RESET
});