import styled from 'styled-components';
import { Button, Label, media } from '@yieldstreet/ui-kit';
export const AnchorButton = styled.button.withConfig({
  displayName: "EditMethodStepstyle__AnchorButton",
  componentId: "sc-h84r7k-0"
})(["background:none !important;border:none;color:black;text-decoration:underline;cursor:pointer;font-size:16px;padding:0;"]);
export const SLabelError = styled(Label).withConfig({
  displayName: "EditMethodStepstyle__SLabelError",
  componentId: "sc-h84r7k-1"
})(["color:", " !important;display:inline-block;width:100%;"], props => props.theme.colors.notice_error);
export const ButtonContainer = styled.div.withConfig({
  displayName: "EditMethodStepstyle__ButtonContainer",
  componentId: "sc-h84r7k-2"
})(["margin-top:", ";"], _ref => {
  let {
    theme
  } = _ref;
  return theme.spacing.m;
});
export const CenteredButton = styled.div.withConfig({
  displayName: "EditMethodStepstyle__CenteredButton",
  componentId: "sc-h84r7k-3"
})(["margin-top:", ";text-align:center;"], _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.spacing.m;
});
export const SButton = styled(Button).withConfig({
  displayName: "EditMethodStepstyle__SButton",
  componentId: "sc-h84r7k-4"
})(["width:100% !important;", ";"], media.tablet`
    width: auto !important;
  `);
export const Line = styled.div.withConfig({
  displayName: "EditMethodStepstyle__Line",
  componentId: "sc-h84r7k-5"
})(["border:0.5px solid ", ";margin:", ";"], _ref3 => {
  let {
    theme
  } = _ref3;
  return theme.colors.border_default;
}, _ref4 => {
  let {
    theme
  } = _ref4;
  return `${theme.spacing.l} 0 ${theme.spacing.m}`;
});
export const OtpCode = styled.div.withConfig({
  displayName: "EditMethodStepstyle__OtpCode",
  componentId: "sc-h84r7k-6"
})(["max-width:300px;margin:", " auto;"], props => props.theme.spacing.s);