import styled from 'styled-components';
import { ButtonContainer, Label, media } from '@yieldstreet/ui-kit';
import { Form } from 'formik';
import { Link } from 'sharedComponents/Links/Links';
export const Asterisk = styled.span.withConfig({
  displayName: "ConfirmationStepstyle__Asterisk",
  componentId: "sc-11c0gfm-0"
})(["display:inline-block;&:before{content:'***-***-';}"]);
export const SForm = styled(Form).withConfig({
  displayName: "ConfirmationStepstyle__SForm",
  componentId: "sc-11c0gfm-1"
})(["width:100%;"]);
export const SLabelError = styled(Label).withConfig({
  displayName: "ConfirmationStepstyle__SLabelError",
  componentId: "sc-11c0gfm-2"
})(["color:", " !important;display:inline-block;width:100%;"], props => props.theme.colors.notice_error);
export const SButtonContainer = styled(ButtonContainer).withConfig({
  displayName: "ConfirmationStepstyle__SButtonContainer",
  componentId: "sc-11c0gfm-3"
})(["margin:", ";text-align:center;", ";"], _ref => {
  let {
    theme
  } = _ref;
  return `${theme.spacing.m} 0 ${theme.spacing.l}`;
}, media.tablet`
    align-items: center;
  `);
export const Separator = styled.div.withConfig({
  displayName: "ConfirmationStepstyle__Separator",
  componentId: "sc-11c0gfm-4"
})(["width:100%;border:0.5px solid ", ";"], _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.colors.border_default;
});
export const AnchorButton = styled.button.withConfig({
  displayName: "ConfirmationStepstyle__AnchorButton",
  componentId: "sc-11c0gfm-5"
})(["background:none !important;border:none;font-size:16px;padding:0;"]);
export const ActionsWrapper = styled.div.withConfig({
  displayName: "ConfirmationStepstyle__ActionsWrapper",
  componentId: "sc-11c0gfm-6"
})(["display:flex;flex-direction:column;align-items:center;margin-top:", ";row-gap:", ";", ";"], _ref3 => {
  let {
    theme
  } = _ref3;
  return theme.spacing.m;
}, _ref4 => {
  let {
    theme
  } = _ref4;
  return theme.spacing.sm;
}, media.tablet`
    flex-direction: row;
    justify-content: flex-start;
    column-gap: ${_ref5 => {
  let {
    theme
  } = _ref5;
  return theme.spacing.m;
}};
  `);
export const SLink = styled(Link).withConfig({
  displayName: "ConfirmationStepstyle__SLink",
  componentId: "sc-11c0gfm-7"
})(["width:fit-content;"]);