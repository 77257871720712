var __jsx = React.createElement;
import React, { useEffect } from 'react';
import { PageSheetTitle } from './PageSheetTitle/PageSheetTitle';
import { PageSheetBackButton } from './PageSheetBackButton/PageSheetBackButton';
import { PageSheetActions } from './PageSheetActions/PageSheetActions';
import { PageSheetContent, PageSheetHeader, PageSheetWrapper } from './PageSheet.style';
import { allowDocumentScrolling, preventDocumentScrolling } from './PageSheet.util';
export const PageSheet = _ref => {
  let {
    actions,
    children,
    onBack,
    title,
    testId
  } = _ref;
  useEffect(() => {
    preventDocumentScrolling();
    return allowDocumentScrolling;
  }, []);
  return __jsx(PageSheetWrapper, {
    "data-cy": testId
  }, __jsx(PageSheetHeader, null, __jsx(PageSheetBackButton, {
    onClick: onBack
  }), __jsx(PageSheetTitle, null, title), actions && __jsx(PageSheetActions, {
    actions: actions
  })), __jsx(PageSheetContent, null, children));
};