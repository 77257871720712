var __jsx = React.createElement;
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { User2FAMethod } from '@yieldstreet/platform-kit';
import { useModalContext, PageLoader, NewModal } from '@yieldstreet/ui-kit';
import { ModalWrapper, InnerModalContainer, PageLoaderWrapper } from './TwoFaModal.style';
import { useTwoFaStatus } from './hooks/useTwoFaStatus';
import { EditMethodStep, EditSuccessStep } from './components/steps';
import { ConfirmationStep } from './components/steps/ConfirmationStep/ConfirmationStep';
import { TWOFA_MODAL_ID, TWOFA_STEPS } from './TwoFaModalContainer.model';
export const TwoFAModalContainer = _ref => {
  let {
    shouldBeVisible,
    onExit,
    onComplete,
    disableClose
  } = _ref;
  const {
    showModal,
    hideModal
  } = useModalContext();
  const {
    method: savedMethod,
    available
  } = useTwoFaStatus();
  const {
    0: method,
    1: setMethod
  } = useState(savedMethod || User2FAMethod.TEXT_MESSAGE);
  const {
    0: step,
    1: setStep
  } = useState(TWOFA_STEPS.REGISTER);
  const {
    0: visible,
    1: setVisible
  } = useState(false);
  const clearState = useCallback(() => {
    setMethod(savedMethod || User2FAMethod.TEXT_MESSAGE);
    setStep(TWOFA_STEPS.REGISTER);
  }, [setMethod, setStep, savedMethod]);
  const onSetupComplete = useCallback(() => {
    onComplete && onComplete();
  }, [onComplete]);
  useEffect(() => {
    if (!visible) clearState();
  }, [visible, clearState]);
  useEffect(() => {
    setVisible(shouldBeVisible);
  }, [shouldBeVisible]);
  useEffect(() => {
    if (visible) {
      showModal(TWOFA_MODAL_ID);
    }
  }, [visible, showModal, hideModal]);
  const onCloseModal = useCallback(() => {
    setVisible(false);
    onExit && onExit();
  }, [onExit]);
  useEffect(() => {
    savedMethod && setMethod(savedMethod);
  }, [savedMethod]);
  const renderStep = useMemo(() => {
    switch (step) {
      case TWOFA_STEPS.REGISTER:
        return __jsx(EditMethodStep, {
          method: method,
          setStep: setStep,
          setMethod: setMethod
        });
      case TWOFA_STEPS.CONFIRM:
        return __jsx(ConfirmationStep, {
          method: method,
          setStep: setStep
        });
      case TWOFA_STEPS.SUCCESS:
        return __jsx(EditSuccessStep, {
          method: method,
          onComplete: onSetupComplete,
          onCloseModal: onCloseModal
        });
      default:
        return;
    }
  }, [method, onCloseModal, onSetupComplete, step]);
  return __jsx(NewModal, {
    modalId: TWOFA_MODAL_ID,
    onClose: onCloseModal,
    hideCloseButton: disableClose && !(step !== TWOFA_STEPS.SUCCESS),
    disableCloseOnClickOutside: disableClose && !(step !== TWOFA_STEPS.SUCCESS)
  }, __jsx(InnerModalContainer, {
    "data-cy": "rendered-2fa-component"
  }, available ? __jsx(ModalWrapper, null, renderStep) : __jsx(PageLoaderWrapper, null, __jsx(PageLoader, {
    testId: "",
    center: true
  }))));
};