import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { Container, List, Title, Detail } from './TieredPricingItem.style';
import { tieredPricingContent, formatAmounts } from '@yieldstreet/tool-kit';
import { tieredTestIds } from '../TieredPricing.model';
export const TieredPricingItem = _ref => {
  let {
    type = 'idle',
    amount = [0, 0],
    reduction = '--'
  } = _ref;
  return __jsx(Container, _extends({}, tieredTestIds.item.attr, {
    type: type
  }), __jsx(List, tieredTestIds.listAmount.attr, __jsx(Title, tieredTestIds.titleAmount.attr, tieredPricingContent.InvestmentAmount), __jsx(Detail, _extends({}, tieredTestIds.detailAmount.attr, {
    modifier: 'amount'
  }), formatAmounts(amount, tieredPricingContent.AndAbove))), __jsx(List, tieredTestIds.listReduction.attr, __jsx(Title, tieredTestIds.titleReduction.attr, tieredPricingContent.FeeReduction), __jsx(Detail, _extends({}, tieredTestIds.detailReduction.attr, {
    modifier: 'reduction',
    center: true
  }), reduction)));
};