import { css } from 'styled-components';
import { sizeCss } from '../../utils/sizes';
import { PaddingKeyEnum, MarginKeyEnum, MarginKeys } from './Spacing.model';
import { sanitizeSpacingProps } from './Spacing.utils';
export const getSizeVar = function () {
  let key = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'm';
  return `--y-spacing-${key}`;
};
const styleMapper = props => {
  const getStyleAttribute = key => {
    return MarginKeys.includes(key) ? 'margin' : 'padding';
  };
  return Object.entries(props).map(entry => {
    const [attribute, spacingKey] = entry;
    if (spacingKey && typeof spacingKey === 'boolean' && false) {
      // eslint-disable-next-line no-console
      console.warn('Boolean values for spacing are being deprecated. Please use a spacing token instead.');
    }
    const sanitizedSpacingKey = spacingKey && typeof spacingKey === 'boolean' ? 'm' : spacingKey;
    if (!sanitizedSpacingKey) {
      return css([""]);
    }
    const cssSpacingVar = getSizeVar(sanitizedSpacingKey);
    switch (attribute) {
      case PaddingKeyEnum.All:
      case MarginKeyEnum.All:
        return css(["", ":var(", ");"], getStyleAttribute(attribute), cssSpacingVar);
      case PaddingKeyEnum.Horizontal:
      case MarginKeyEnum.Horizontal:
        return css(["", "-left:var(", ");", "-right:var(", ");"], getStyleAttribute(attribute), cssSpacingVar, getStyleAttribute(attribute), cssSpacingVar);
      case PaddingKeyEnum.Vertical:
      case MarginKeyEnum.Vertical:
        return css(["", "-top:var(", ");", "-bottom:var(", ");"], getStyleAttribute(attribute), cssSpacingVar, getStyleAttribute(attribute), cssSpacingVar);
      case PaddingKeyEnum.Top:
      case MarginKeyEnum.Top:
        return css(["", "-top:var(", ");"], getStyleAttribute(attribute), cssSpacingVar);
      case PaddingKeyEnum.Right:
      case MarginKeyEnum.Right:
        return css(["", "-right:var(", ");"], getStyleAttribute(attribute), cssSpacingVar);
      case PaddingKeyEnum.Bottom:
      case MarginKeyEnum.Bottom:
        return css(["", "-bottom:var(", ");"], getStyleAttribute(attribute), cssSpacingVar);
      case PaddingKeyEnum.Left:
      case MarginKeyEnum.Left:
        return css(["", "-left:var(", ");"], getStyleAttribute(attribute), cssSpacingVar);
      default:
        return css([""]);
    }
  });
};
const getSpacingStyle = props => {
  const sanitizedProps = sanitizeSpacingProps(props);
  return sizeCss(styleMapper, sanitizedProps, {});
};
export const SpacingStyle = css(["", ""], props => getSpacingStyle(props));