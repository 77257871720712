import { HttpMethods } from '../../enums';
import { restApi } from '../common';
export const genevaGatewayApi = restApi.enhanceEndpoints({
  addTagTypes: []
}).injectEndpoints({
  endpoints: build => {
    const downloadPCAPReport = build.mutation({
      query: _ref => {
        let {
          investorAccountId,
          fundId
        } = _ref;
        return {
          url: `/api/geneva-gateway/report/${fundId}/pcap`,
          method: HttpMethods.Post,
          data: {
            investorAccountId
          },
          responseType: 'blob'
        };
      },
      transformResponse: (response, headers) => {
        const regex = /filename="([^"]+)"/;
        const fileName = headers['content-disposition'].match(regex)[1];
        return {
          blob: response,
          fileName
        };
      }
    });
    return {
      downloadPCAPReport
    };
  }
});