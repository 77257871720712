import update from 'immutability-helper';
import * as actions from 'store/actions/assetClasses';
const initialState = {
  assetClasses: {},
  isFetching: false
};
const assetClasses = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case actions.GET_ASSETCLASSES_ATTEMPT:
      {
        return update(state, {
          isFetching: {
            $set: true
          }
        });
      }
    case actions.GET_ASSETCLASSES_SUCCESS:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          assetClasses: {
            $set: action.response
          }
        });
      }
    case actions.GET_ASSETCLASSES_FAILURE:
      {
        return update(state, {
          isFetching: {
            $set: false
          }
        });
      }
    default:
      return state;
  }
};
export default assetClasses;