var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { Heading, Paragraph } from '../../../content/text';
export const BulletTitleContent = _ref => {
  let {
    title,
    headingType = 5,
    tagType = 5,
    children,
    inverse
  } = _ref;
  return __jsx(OuterContainer, null, __jsx(ContentHolder, null, __jsx(Title, {
    type: headingType,
    tagType: tagType,
    inverse: inverse
  }, title), __jsx(Paragraph, {
    secondary: true,
    inverse: inverse
  }, children)));
};
const OuterContainer = styled.ul.withConfig({
  displayName: "BulletTitleContent__OuterContainer",
  componentId: "sc-1k5x9vl-0"
})(["display:flex;margin-bottom:30px;"]);
const ContentHolder = styled.li.withConfig({
  displayName: "BulletTitleContent__ContentHolder",
  componentId: "sc-1k5x9vl-1"
})([""]);
const Title = styled(Heading).withConfig({
  displayName: "BulletTitleContent__Title",
  componentId: "sc-1k5x9vl-2"
})(["margin-bottom:10px;"]);