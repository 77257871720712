import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { Button, PageFlow, Paragraph, Title } from '@yieldstreet/ui-kit';
import { testIds } from './ManagementFeeConsentStart.model';
import { ContentWrapper } from './ManagementFeeConsentStart.style';
import { useHistory } from 'react-router';
import { MANAGEMENT_FEE_CONSENT_VOTE_ROUTE } from '../ManagementFeeConsentVote/ManagementFeeConsentVote.model';
import { ButtonContainer, ButtonWrapper } from '../ManagementFeeConsentVote/ManagementFeeConsentVote.style';
const ManagementFeeConsentStart = () => {
  const history = useHistory();
  return __jsx(PageFlow, {
    tabletMaxWidth: "700px"
  }, __jsx(ContentWrapper, null, __jsx(Title, testIds.title.attr, "Please review proposed amendment to our funds' operating agreements"), __jsx(Paragraph, {
    marginBottom: true
  }, "Under many of our current fund operating agreements, direct expenses such as audit, tax and other third-party fees, as well as accrued management fees owed to Yieldstreet can only be paid out of the proceeds of the investments."), __jsx(Paragraph, {
    marginBottom: true
  }, "This amendment would allow an affiliate of Yieldstreet to provide debt financing to the fund that would enable it to pay these obligations at an earlier date."), __jsx(Paragraph, {
    marginBottom: true
  }, "Please consider that the proposed amendment plainly benefits Yieldstreet \u2013 by allowing for it to be paid fees at times and under circumstances where it otherwise may not be paid. For this reason, the proposed amendment is an example of the \u201Cconflicts of interest\u201D that we have always asked you to bear in mind when making any decision concerning your Yieldstreet investments."), __jsx(ButtonContainer, null, __jsx(ButtonWrapper, null, __jsx(Button, _extends({}, testIds.continueButton.attr, {
    onClick: () => {
      history.push(MANAGEMENT_FEE_CONSENT_VOTE_ROUTE);
    }
  }), "Start")))));
};
export default ManagementFeeConsentStart;