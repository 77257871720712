var __jsx = React.createElement;
import React, { useCallback } from 'react';
import styled from 'styled-components';
import ArrowBlack from '../../assets/graphics/icons/arrow.svg';
import ArrowGrey from '../../assets/graphics/icons/arrowGrey.svg';
export const Pagination = _ref => {
  let {
    total,
    current,
    onChange,
    linkComponent
  } = _ref;
  let pageItems = [];
  const step = 3;
  const renderNumber = useCallback((number, customElement) => {
    if (linkComponent) {
      return linkComponent(number, customElement || number);
    }
    return customElement || number;
  }, []);
  if (current && total) {
    const addPagination = (start, end) => {
      for (let idx = start; idx <= end && end; idx++) {
        pageItems && pageItems.push(__jsx(PaginationItem, {
          onClick: () => idx === current ? null : onChange && onChange(idx),
          key: idx,
          selected: idx === current,
          className: 'paginationitem paginationitem-' + idx
        }, renderNumber(idx)));
      }
    };
    const addFirst = () => {
      pageItems && pageItems.push(__jsx(PaginationItem, {
        onClick: () => 1 === current ? null : onChange && onChange(1),
        key: 1,
        selected: 1 === current,
        className: 'paginationitem paginationitem-1'
      }, renderNumber(1)));
      pageItems && pageItems.push(__jsx(PaginationItem, {
        disabled: true,
        key: "dots-1",
        className: 'paginationitem paginationitem-dots-1'
      }, "..."));
    };
    const addLast = () => {
      pageItems && pageItems.push(__jsx(PaginationItem, {
        disabled: true,
        key: "dots-2",
        className: 'paginationitem paginationitem-dots-2'
      }, "..."));
      pageItems && pageItems.push(__jsx(PaginationItem, {
        onClick: () => total === current ? null : onChange && onChange(total),
        key: total,
        selected: total === current,
        className: 'paginationitem paginationitem-' + total
      }, renderNumber(total)));
    };
    if (total - 1 < step * 2 + 3) {
      addPagination(1, total);
    } else if (current < step * 2 + 1) {
      addPagination(1, step * 2 + 3);
      addLast();
    } else if (current > total - step * 2) {
      addFirst();
      addPagination(total - step * 2 - 2, total);
    } else {
      addFirst();
      addPagination(current - step + 1, current + step + 1);
      addLast();
    }
  }
  const renderArrowLeft = () => {
    const prev = current && current - 1;
    const hasPrev = current && current > 1;
    return hasPrev && renderNumber(prev, __jsx(Arrow, {
      left: true,
      onClick: () => current && current > 1 && onChange && onChange(current - 1),
      src: current === 1 ? ArrowGrey : ArrowBlack
    }));
  };
  const renderArrowRight = useCallback(() => {
    const next = current ? current + 1 : 0;
    const hasNext = total && current && current < total;
    return hasNext && renderNumber(next, __jsx(Arrow, {
      onClick: () => onChange && onChange(next),
      right: true,
      src: current === total ? ArrowGrey : ArrowBlack
    }));
  }, [current, total]);
  return __jsx(Container, null, renderArrowLeft(), __jsx(Numbers, {
    className: "pagination"
  }, pageItems), renderArrowRight());
};
const Container = styled.div.withConfig({
  displayName: "Pagination__Container",
  componentId: "sc-1y50rrl-0"
})(["display:flex;justify-content:center;align-items:center;margin-top:15px;"]);
const Arrow = styled.img.withConfig({
  displayName: "Pagination__Arrow",
  componentId: "sc-1y50rrl-1"
})(["height:7px;width:14px;", " ", " cursor:pointer;"], props => props.left && `transform: rotate(90deg);`, props => props.right && `transform: rotate(270deg);`);
const Numbers = styled.ul.withConfig({
  displayName: "Pagination__Numbers",
  componentId: "sc-1y50rrl-2"
})(["display:flex;justify-content:center;flex-wrap:wrap;margin:0px 20px 0px 20px;"]);
const PaginationItem = styled.li.withConfig({
  displayName: "Pagination__PaginationItem",
  componentId: "sc-1y50rrl-3"
})(["color:", ";border:", ";border-radius:2px;font-size:13px;padding:3px 6px;margin:5px 5px 5px 0;&:last-child{margin-right:0px;}cursor:", ";"], props => props.theme.colors.gray2, props => props.selected && `1px solid black`, props => props.disabled || props.selected ? 'default' : 'pointer');