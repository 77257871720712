import { genTestIds } from '@yieldstreet/tool-kit';
export const BENEFITS_PATH = '/profile/benefits';
export const BENEFITS_OVERVIEW_PATH = '/loyalty-program';
const {
  getTestIds
} = genTestIds(['tiers-overview-link', 'tiers-overview-disclaimer']);
export const testIds = getTestIds('tiers-benefits-page');
export const content = {
  disclaimer: `Benefits subject to change without notice.
  Yieldstreet members are automatically enrolled in tiers based on their account balance (investments and Wallet balance as shown on your Yieldstreet Portfolio). If a member’s account balance drops below their current tier’s threshold, the member remains in the tier for 90 days before losing access to their benefits.`
};