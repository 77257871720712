import styled from 'styled-components';
import { Paragraph } from '@yieldstreet/ui-kit';
export const PastMetricWrapper = styled.div.withConfig({
  displayName: "PastMetricsstyle__PastMetricWrapper",
  componentId: "sc-lsft95-0"
})([""]);
export const TextWrapper = styled.div.withConfig({
  displayName: "PastMetricsstyle__TextWrapper",
  componentId: "sc-lsft95-1"
})([""]);

// IMPORTED

export const PastMetric = styled.div.withConfig({
  displayName: "PastMetricsstyle__PastMetric",
  componentId: "sc-lsft95-2"
})(["display:block;justify-content:space-around;text-align:center;"]);
export const PastMetricItem = styled.div.withConfig({
  displayName: "PastMetricsstyle__PastMetricItem",
  componentId: "sc-lsft95-3"
})(["display:flex;justify-content:space-between;padding:5px 0;"]);
export const RecentlyFunded = styled(Paragraph).withConfig({
  displayName: "PastMetricsstyle__RecentlyFunded",
  componentId: "sc-lsft95-4"
})(["margin:10px 0;"]);
export const MetricWrapper = styled.div.withConfig({
  displayName: "PastMetricsstyle__MetricWrapper",
  componentId: "sc-lsft95-5"
})(["margin-top:10px;"]);