import _Box from "@mui/material/Box";
var __jsx = React.createElement;
import React from 'react';
import kebabCase from "lodash/kebabCase";
import numeral from 'numeral';
import { DesktopOnly, MobileOnly } from '../../../../utils';
import { AmountSelectorHolder, AmountTitle, AmountValue, Label, LineValue, Paragraph, SSliderInput } from './AmountSelector.style';
export const AmountSelector = _ref => {
  let {
    amount,
    annualizedYield,
    color,
    max = 100000,
    min = 0,
    onChange,
    onTouchEnd,
    onTouchMove,
    step = 1000,
    term,
    title
  } = _ref;
  return __jsx(AmountSelectorHolder, null, __jsx(AmountTitle, null, __jsx(Paragraph, {
    semiBold: true
  }, title), __jsx(AmountValue, null, numeral(amount).format('$0,0'))), __jsx(LineValue, null, __jsx(Label, {
    variant: "body2"
  }, "Target annualized yield"), __jsx(Label, {
    variant: "body2"
  }, __jsx(React.Fragment, null, annualizedYield, "%"))), __jsx(LineValue, null, __jsx(Label, {
    variant: "body2"
  }, "Target term"), __jsx(Label, {
    variant: "body2"
  }, term)), __jsx(_Box, {
    onTouchMove: onTouchMove,
    onTouchEnd: onTouchEnd
  }, __jsx(MobileOnly, null, __jsx(SSliderInput, {
    formatValue: () => '',
    fullWidth: true,
    label: kebabCase(title),
    max: max,
    min: min,
    name: "slider",
    onChange: value => onChange && onChange(value),
    step: step,
    trackColor: color,
    value: amount
  })), __jsx(DesktopOnly, null, __jsx(SSliderInput, {
    formatValue: value => numeral(value).format('$0,0'),
    fullWidth: true,
    label: kebabCase(title),
    max: max,
    min: min,
    name: "slider",
    onChange: value => onChange && onChange(value),
    step: step,
    trackColor: color,
    value: amount
  }))));
};