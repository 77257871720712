import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
const _excluded = ["postType"],
  _excluded2 = ["menuName"];
import axios from 'axios';
import { restApi, BASE_REST_CONFIG } from '../common';
export const wordpressApi = restApi.injectEndpoints(_objectSpread(_objectSpread({}, BASE_REST_CONFIG), {}, {
  endpoints: builder => {
    const getWordpressArticles = builder.query({
      query: _ref => {
        let {
            postType
          } = _ref,
          params = _objectWithoutProperties(_ref, _excluded);
        return {
          url: `/wp-json/wp/v2/${postType}/?_embed`,
          method: 'GET',
          params
        };
      },
      transformResponse: (data, headers) => {
        const wpHeaders = {
          total: headers === null || headers === void 0 ? void 0 : headers['x-wp-total'],
          totalPages: headers === null || headers === void 0 ? void 0 : headers['x-wp-totalpages']
        };
        return {
          posts: data,
          headers: wpHeaders
        };
      }
    });
    const wpOfferingArticles = builder.query({
      queryFn: async _ref2 => {
        let {
          postType,
          articleSlug
        } = _ref2;
        const apis = [];
        if (Array.isArray(postType)) {
          postType.forEach(postTypeString => {
            apis.push(axios.get(`/wp-json/wp/v2/${postTypeString}/?_embed&slug=${articleSlug}`));
          });
        } else {
          apis.push(axios.get(`/wp-json/wp/v2/${postType}/?_embed&slug=${articleSlug}`));
        }
        return await axios.all(apis).then(axios.spread(function () {
          for (var _len = arguments.length, responses = new Array(_len), _key = 0; _key < _len; _key++) {
            responses[_key] = arguments[_key];
          }
          const validResults = responses.filter(response => response === null || response === void 0 ? void 0 : response.data);
          const wpArticle = validResults.reduce((acc, validResult) => acc.concat(validResult.data), []).find(article => article.slug === articleSlug);
          return {
            data: {
              wpArticle
            }
          };
        })).catch(errors => {
          return {
            error: errors
          };
        });
      }
    });
    const getWordpressSearchResults = builder.query({
      query: params => ({
        url: `/wp-json/wp/v2/search`,
        method: 'GET',
        params
      })
    });
    const getWordpressMenus = builder.query({
      query: _ref3 => {
        let {
            menuName
          } = _ref3,
          params = _objectWithoutProperties(_ref3, _excluded2);
        return {
          url: `/wp-json/menu/menu-items/${menuName}`,
          method: 'GET',
          params
        };
      }
    });
    const getWordpressCategories = builder.query({
      query: params => ({
        url: `/wp-json/wp/v2/categories`,
        method: 'GET',
        params
      })
    });
    const getWordpressTags = builder.query({
      query: params => ({
        url: `/wp-json/wp/v2/tags`,
        method: 'GET',
        params
      })
    });
    return {
      getWordpressArticles,
      getWordpressSearchResults,
      getWordpressMenus,
      getWordpressCategories,
      getWordpressTags,
      wpOfferingArticles
    };
  }
}));