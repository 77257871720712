import { CALL_API } from '../middleware/Middleware';
export const GET_INVESTMENT_PAYMENT_METHODS_ATTEMPT = 'ys/investment/paymentMethods/ATTEMPT';
export const GET_INVESTMENT_PAYMENT_METHODS_SUCCESS = 'ys/investment/paymentMethods/SUCCESS';
export const GET_INVESTMENT_PAYMENT_METHODS_FAILURE = 'ys/investment/paymentMethods/FAILURE';
export const getInvestmentPaymentMethods = (investorAccountId, investmentAmount, noteUrlHash) => dispatch => {
  const urlHashQuery = noteUrlHash ? `urlHash=${noteUrlHash}` : '';
  const endpoint = `/a/api/payments-methods/${investorAccountId}/${investmentAmount}?${urlHashQuery}`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_INVESTMENT_PAYMENT_METHODS_ATTEMPT, GET_INVESTMENT_PAYMENT_METHODS_SUCCESS, GET_INVESTMENT_PAYMENT_METHODS_FAILURE],
      getResponse: res => res.data
    }
  });
};