import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { createYupObjectSchema } from '@yieldstreet/tool-kit';
import { addressFormModel } from '../../Address/Address.model';
import { EMPLOYMENT_STATUS } from './EmploymentInfo.model';
export const isEmployed = employmentStatus => employmentStatus === EMPLOYMENT_STATUS.EMPLOYED;
export const isSelfEmployed = employmentStatus => employmentStatus === EMPLOYMENT_STATUS.SELF_EMPLOYED;
const getEmploymentFormData = employmentStatus => {
  if (isEmployed(employmentStatus)) {
    return _objectSpread({
      employmentIndustry: {
        validationType: 'string'
      },
      employerName: {
        validationType: 'string'
      },
      employmentTitle: {
        validationType: 'string'
      }
    }, addressFormModel);
  }
  if (isSelfEmployed(employmentStatus)) {
    return {
      employmentIndustry: {
        validationType: 'string'
      },
      employmentTitle: {
        validationType: 'string'
      }
    };
  }
  return null;
};
export const employmentFormValidation = employmentStatus => {
  const employmentFormData = getEmploymentFormData(employmentStatus);
  return createYupObjectSchema(_objectSpread({
    employmentStatusInput: {
      validationType: 'string'
    }
  }, employmentFormData));
};
const getEmploymentInitialValues = _ref => {
  let {
    entityData,
    employmentStatus
  } = _ref;
  if (isEmployed(employmentStatus)) {
    var _entityData$employerA, _entityData$employerA2, _entityData$employerA3, _entityData$employerA4, _entityData$employerA5, _entityData$employerA6;
    return {
      employmentIndustry: (entityData === null || entityData === void 0 ? void 0 : entityData.employmentIndustry) || '',
      employerName: (entityData === null || entityData === void 0 ? void 0 : entityData.employerName) || '',
      employmentTitle: (entityData === null || entityData === void 0 ? void 0 : entityData.employmentTitle) || '',
      addressLine1: entityData === null || entityData === void 0 ? void 0 : (_entityData$employerA = entityData.employerAddress) === null || _entityData$employerA === void 0 ? void 0 : _entityData$employerA.addressLine1,
      addressLine2: entityData === null || entityData === void 0 ? void 0 : (_entityData$employerA2 = entityData.employerAddress) === null || _entityData$employerA2 === void 0 ? void 0 : _entityData$employerA2.addressLine2,
      city: entityData === null || entityData === void 0 ? void 0 : (_entityData$employerA3 = entityData.employerAddress) === null || _entityData$employerA3 === void 0 ? void 0 : _entityData$employerA3.city,
      stateStr: entityData === null || entityData === void 0 ? void 0 : (_entityData$employerA4 = entityData.employerAddress) === null || _entityData$employerA4 === void 0 ? void 0 : _entityData$employerA4.state,
      country: entityData === null || entityData === void 0 ? void 0 : (_entityData$employerA5 = entityData.employerAddress) === null || _entityData$employerA5 === void 0 ? void 0 : _entityData$employerA5.countryCode,
      postalCode: entityData === null || entityData === void 0 ? void 0 : (_entityData$employerA6 = entityData.employerAddress) === null || _entityData$employerA6 === void 0 ? void 0 : _entityData$employerA6.postalCode
    };
  }
  if (isSelfEmployed(employmentStatus)) {
    return {
      employmentIndustry: (entityData === null || entityData === void 0 ? void 0 : entityData.employmentIndustry) || '',
      employmentTitle: (entityData === null || entityData === void 0 ? void 0 : entityData.employmentTitle) || ''
    };
  }
  return null;
};
export const employmentInitialValues = _ref2 => {
  let {
    entityData,
    employmentStatus
  } = _ref2;
  const employedValidation = getEmploymentInitialValues({
    entityData,
    employmentStatus
  });
  return _objectSpread({
    employmentStatusInput: employmentStatus
  }, employedValidation);
};