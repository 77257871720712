import { AdviceIndustries, AdviceCurrentIncome, AdviceEmploymentStatus, AdviceExpectedYield, AdviceInterestedAssetClasses, AdviceInvestmentStyle, AdviceNetWorth, AdvicePreferredTimeHorizon, AdviceQualifiedPurchaser, AdviceRiskTolerance, AdviceQualifiedClient, AdviceInvestmentAmount } from '@yieldstreet/platform-kit';
export const NET_WORTH_OPTIONS = [{
  value: AdviceNetWorth._0K_500K,
  label: '$0 - $500,000'
}, {
  value: AdviceNetWorth._500K_1M,
  label: '$500,001 - $1,000,000'
}, {
  value: AdviceNetWorth._1M_5M,
  label: '$1,000,001 - $5,000,000'
}, {
  value: AdviceNetWorth._5M_PLUS,
  label: '$5,000,000+'
}];
export const INVESTMENT_AMOUNT_OPTIONS = [{
  value: AdviceInvestmentAmount._50K_100K,
  label: '$50,000 - $100,000'
}, {
  value: AdviceInvestmentAmount._100K_250K,
  label: '$100,000 - $250,000'
}, {
  value: AdviceInvestmentAmount._250K_Plus,
  label: '$250,000+'
}];
export const QUALIFIED_PURCHASER_OPTIONS = [{
  value: AdviceQualifiedPurchaser.True,
  label: 'Yes'
}, {
  value: AdviceQualifiedPurchaser.False,
  label: 'No'
}];
export const QUALIFIED_CLIENT_OPTIONS = [{
  value: AdviceQualifiedClient.True,
  label: 'Yes'
}, {
  value: AdviceQualifiedClient.False,
  label: 'No'
}];
export const CURRENT_INCOME_OPTIONS = [{
  value: AdviceCurrentIncome._0K_200K,
  label: '$0 - $200,000'
}, {
  value: AdviceCurrentIncome._200K_500K,
  label: '$200,001 - $500,000'
}, {
  value: AdviceCurrentIncome._500K_1M,
  label: '$500,001 - $1,000,000'
}, {
  value: AdviceCurrentIncome._1M_PLUS,
  label: '$1,000,000+'
}];
export const EMPLOYMENT_STATUS_OPTIONS = [{
  value: AdviceEmploymentStatus.Employed,
  label: 'Employed'
}, {
  value: AdviceEmploymentStatus.Unemployed,
  label: 'Unemployed'
}, {
  value: AdviceEmploymentStatus.Retired,
  label: 'Retired'
}, {
  value: AdviceEmploymentStatus.Student,
  label: 'Student'
}];
export const RISK_TOLERANCE_OPTIONS = [{
  value: AdviceRiskTolerance.Moderate,
  label: 'Moderate',
  description: 'Lower potential returns and lower risk.'
}, {
  value: AdviceRiskTolerance.Aggressive,
  label: 'Aggressive',
  description: 'Higher potential returns and higher risk.'
}, {
  value: AdviceRiskTolerance.AggressivePlus,
  label: 'Aggressive Plus',
  description: 'Highest potential returns and highest risk.'
}];
export const RISK_TOLERANCE_ALTERNATIVE_OPTIONS = [{
  value: AdviceRiskTolerance.Moderate,
  label: 'Moderate',
  description: 'Offerings presented will generally skew toward lower returns and assumed risk relative to the full set of offerings made available on Yieldstreet’s platform.'
}, {
  value: AdviceRiskTolerance.Aggressive,
  label: 'Aggressive',
  description: 'Offerings presented will generally target a higher risk-return profile. While potentially more rewarding, these investments also have a larger potential downside relative to the full set of offerings made available on Yieldstreet’s platform and may typically include more equity products and single offerings.'
}, {
  value: AdviceRiskTolerance.AggressivePlus,
  label: 'Aggressive Plus',
  description: 'Offerings presented will generally skew toward the highest assumed risk relative to the full set of offerings made available on Yieldstreet’s platform, with the highest potential for 100% loss of principal and, as a result, generally higher target returns. They may typically include equity offerings that are considered to be high-risk, high-reward, such as venture capital or cryptocurrency funds.'
}];
export const INVESTMENT_STYLE_OPTIONS = [{
  value: AdviceInvestmentStyle.Income,
  label: 'Income',
  description: 'These offerings are expected to generate income through recurring distribution payments.'
}, {
  value: AdviceInvestmentStyle.Growth,
  label: 'Growth',
  description: 'These offerings are expected to appreciate during the target term.'
}, {
  value: AdviceInvestmentStyle.Balanced,
  label: 'Balanced',
  description: 'These offerings are expected to appreciate during the target term, while making some recurring distribution payments.'
}];
export const PREFERRED_TIME_HORIZON_OPTIONS = [{
  value: AdvicePreferredTimeHorizon._0_3Y,
  label: '0-3 Years'
}, {
  value: AdvicePreferredTimeHorizon._3_5Y,
  label: '3-5 Years'
}, {
  value: AdvicePreferredTimeHorizon._5Y,
  label: '5+ Years'
}];
export const EXPECTED_YIELD_OPTIONS = [{
  value: AdviceExpectedYield._3_5,
  label: '3 - 5%'
}, {
  value: AdviceExpectedYield._6_8,
  label: '6 - 8%'
}, {
  value: AdviceExpectedYield._9_11,
  label: '9 - 11%'
}, {
  value: AdviceExpectedYield._12_PLUS,
  label: '12+%'
}];
export const INTERESTED_ASSET_CLASSES_OPTIONS = [{
  value: AdviceInterestedAssetClasses.Art,
  label: 'Art'
}, {
  value: AdviceInterestedAssetClasses.Crypto,
  label: 'Crypto'
}, {
  value: AdviceInterestedAssetClasses.Legal,
  label: 'Legal'
}, {
  value: AdviceInterestedAssetClasses.MultiAssetClassFun,
  label: 'Multi-asset class fund'
}, {
  value: AdviceInterestedAssetClasses.PrivateCredit,
  label: 'Private credit'
}, {
  value: AdviceInterestedAssetClasses.PrivateEquity,
  label: 'Private equity'
}, {
  value: AdviceInterestedAssetClasses.RealEstate,
  label: 'Real estate'
}, {
  value: AdviceInterestedAssetClasses.ShortTermNotes,
  label: 'Short term notes'
}, {
  value: AdviceInterestedAssetClasses.Transportation,
  label: 'Transportation'
}, {
  value: AdviceInterestedAssetClasses.VentureCapital,
  label: 'Venture Capital'
}];
export const INDUSTRY_OPTIONS = [{
  value: AdviceIndustries.AccommodationAndFoodServices,
  label: 'Accommodation and Food Services'
}, {
  value: AdviceIndustries.AdminSupportWasteManagementAndRemediation,
  label: 'Administrative and Support and Waste Management and Remediation Services'
}, {
  value: AdviceIndustries.AgricultureForestFishingHunting,
  label: 'Agriculture, Forestry, Fishing and Hunting'
}, {
  value: AdviceIndustries.ArtsEntertainmentAndRecreation,
  label: 'Arts, Entertainment, and Recreation'
}, {
  value: AdviceIndustries.Construction,
  label: 'Construction'
}, {
  value: AdviceIndustries.Educational,
  label: 'Educational Services'
}, {
  value: AdviceIndustries.FinanceAndInsurance,
  label: 'Finance and Insurance'
}, {
  value: AdviceIndustries.HealthCareAndSocialAssistance,
  label: 'Health Care and Social Assistance'
}, {
  value: AdviceIndustries.Information,
  label: 'Information'
}, {
  value: AdviceIndustries.ManagementOfCompaniesAndEnterprises,
  label: 'Management of Companies and Enterprises'
}, {
  value: AdviceIndustries.Manufacturing,
  label: 'Manufacturing'
}, {
  value: AdviceIndustries.Mining,
  label: 'Mining'
}, {
  value: AdviceIndustries.Manufacturing,
  label: 'Manufacturing'
}, {
  value: AdviceIndustries.OtherServicesExcludingPublicAdmin,
  label: 'Other Services (except Public Administration)'
}, {
  value: AdviceIndustries.ProfessionalScientificAndTechnical,
  label: 'Professional, Scientific, and Technical Services'
}, {
  value: AdviceIndustries.PublicAdmin,
  label: 'Public Administration'
}, {
  value: AdviceIndustries.RealEstateRentalAndLeasing,
  label: 'Real Estate Rental and Leasing'
}, {
  value: AdviceIndustries.RetailTrade,
  label: 'Retail Trade'
}, {
  value: AdviceIndustries.TransportationAndWarehousing,
  label: 'Transportation and Warehousing'
}, {
  value: AdviceIndustries.Utilities,
  label: 'Utilities'
}, {
  value: AdviceIndustries.WholesaleTrade,
  label: 'Wholesale Trade'
}];