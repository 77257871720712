var __jsx = React.createElement;
import React from 'react';
import { useWPCards } from '@yieldstreet/tool-kit';
import { Paragraph } from '@yieldstreet/ui-kit';
import DefaultBlogImage from 'pages/resources/Blog/landing/img/BlogPhoto.png';
import { Wrapper, Container, Image, ContentWrapper, Title } from './WordPressCard.style';
export const WordPressCard = _ref => {
  let {
    articleUrl,
    articleSlug
  } = _ref;
  const {
    isFetchingWPArticle,
    title,
    description,
    image
  } = useWPCards(articleSlug);
  if (isFetchingWPArticle || !title) {
    return null;
  }
  return __jsx(Wrapper, {
    "data-cy": 'json-pages-section-wordpress-cards-section-card'
  }, __jsx("a", {
    href: articleUrl,
    target: "_blank",
    rel: "noopener noreferrer"
  }, __jsx(Container, null, __jsx(Image, {
    src: image !== 'GENERIC' ? image : DefaultBlogImage
  }), __jsx(ContentWrapper, null, __jsx(Title, {
    type: 5
  }, title), __jsx(Paragraph, null, description)))));
};