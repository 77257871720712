import { genTestIds } from '@yieldstreet/tool-kit';
export const EMPLOYMENT_STATUS = {
  EMPLOYED: 'EMPLOYED',
  UNEMPLOYED: 'UNEMPLOYED',
  RETIRED: 'RETIRED',
  STUDENT: 'STUDENT',
  SELF_EMPLOYED: 'SELF_EMPLOYED'
};
export const employmentStatusOptions = [{
  value: EMPLOYMENT_STATUS.EMPLOYED,
  label: 'Employed'
}, {
  value: EMPLOYMENT_STATUS.UNEMPLOYED,
  label: 'Unemployed'
}, {
  value: EMPLOYMENT_STATUS.RETIRED,
  label: 'Retired'
}, {
  value: EMPLOYMENT_STATUS.STUDENT,
  label: 'Student'
}, {
  value: EMPLOYMENT_STATUS.SELF_EMPLOYED,
  label: 'Self employed'
}];
export const employedUser = [EMPLOYMENT_STATUS.EMPLOYED, EMPLOYMENT_STATUS.SELF_EMPLOYED];
export const industryOptions = [{
  value: 'ACCOUNTING',
  label: 'Accounting'
}, {
  value: 'ADVERTISING_MARKETING',
  label: 'Advertising, marketing'
}, {
  value: 'AEROSPACE_DEFENSE',
  label: 'Aerospace, defense'
}, {
  value: 'AGRICULTURE_FORESTRY',
  label: 'Agriculture, forestry'
}, {
  value: 'AMUSEMENT_AND_RECREATION',
  label: 'Amusement and recreation'
}, {
  value: 'ANIMAL_SERVICES_AND_VETERINARY',
  label: 'Animal services and veterinary'
}, {
  value: 'ARCHITECTURE_DESIGN',
  label: 'Architecture, design'
}, {
  value: 'ARTS_ANTIQUES',
  label: 'Arts, antiques'
}, {
  value: 'ATHLETICS_FITNESS',
  label: 'Athletics, fitness'
}, {
  value: 'AUTOMOTIVE',
  label: 'Automotive'
}, {
  value: 'AVIATION',
  label: 'Aviation'
}, {
  value: 'BAR_NIGHTCLUB_ADULT_ENTERTAINMENT_CLUB',
  label: 'Bar, nightclub, adult entertainment club'
}, {
  value: 'CHILDCARE',
  label: 'Childcare'
}, {
  value: 'CLEANING_JANITORIAL_HOUSEKEEPING',
  label: 'Cleaning, janitorial, housekeeping'
}, {
  value: 'COMMUNICATIONS_TELECOMMUNICATIONS',
  label: 'Communications, telecommunications'
}, {
  value: 'CONSTRUCTION_CARPENTRY_LANDSCAPING',
  label: 'Construction, carpentry, landscaping'
}, {
  value: 'CONVENIENCE_STORE_LIQUOR_STORE_GAS_STATION',
  label: 'Convenience store, liquor store, gas station'
}, {
  value: 'CUSTOMER_SERVICE_AND_SUPPORT',
  label: 'Customer service and support'
}, {
  value: 'EDUCATION',
  label: 'Education'
}, {
  value: 'EMBASSY_CONSULATE',
  label: 'Embassy, consulate'
}, {
  value: 'ENERGY',
  label: 'Energy'
}, {
  value: 'ENGINEERING',
  label: 'Engineering'
}, {
  value: 'FASHION_CLOTHING',
  label: 'Fashion, clothing'
}, {
  value: 'FINANCIAL_SERVICES',
  label: 'Financial services'
}, {
  value: 'FIREARMS_AND_EXPLOSIVES',
  label: 'Firearms and explosives'
}, {
  value: 'GAMING_CASINO_CARD_CLUB',
  label: 'Gaming, casino, card club'
}, {
  value: 'GOVERNMENT_PUBLIC_ADMINISTRATION',
  label: 'Government, public administration'
}, {
  value: 'GROCERY_SUPERMARKET',
  label: 'Grocery, supermarket'
}, {
  value: 'HEALTHCARE_MEDICAL_SERVICES',
  label: 'Healthcare, medical services'
}, {
  value: 'HOTEL_HOSPITALITY',
  label: 'Hotel, hospitality'
}, {
  value: 'IMPORT_EXPORT',
  label: 'Import, export'
}, {
  value: 'INFORMATION_TECHNOLOGY',
  label: 'Information technology'
}, {
  value: 'INSURANCE',
  label: 'Insurance'
}, {
  value: 'JEWELRY_GEMS_AND_PRECIOUS_METALS',
  label: 'Jewelry gems and precious metals'
}, {
  value: 'LEGAL_SERVICES_PUBLIC_SAFETY',
  label: 'Legal services, public safety'
}, {
  value: 'LOGISTICS_SUPPLY_CHAIN',
  label: 'Logistics, supply chain'
}, {
  value: 'MANUFACTURING',
  label: 'Manufacturing'
}, {
  value: 'MARITIME',
  label: 'Maritime'
}, {
  value: 'MEDIA_ENTERTAINMENT',
  label: 'Media, entertainment'
}, {
  value: 'MINING_OIL_AND_GAS',
  label: 'Mining oil and gas'
}, {
  value: 'MONEY_SERVICES_BUSINESSES',
  label: 'Money services businesses'
}, {
  value: 'NON_PROFIT_NGO_CHARITY',
  label: 'Non-profit, NGO, charity'
}, {
  value: 'PARKING_AND_CAR_WASHES',
  label: 'Parking and car washes'
}, {
  value: 'PAWN_SHOPS_BROKERS',
  label: 'Pawn shops, brokers'
}, {
  value: 'PERSONAL_CARE_HYGIENE',
  label: 'Personal care, hygiene'
}, {
  value: 'PHARMACEUTICALS',
  label: 'Pharmaceuticals'
}, {
  value: 'PRINTING_PUBLISHING',
  label: 'Printing, publishing'
}, {
  value: 'PROFESSIONAL_CIVIC_ORGANIZATIONS',
  label: 'Professional, civic organizations'
}, {
  value: 'REAL_ESTATE',
  label: 'Real estate'
}, {
  value: 'RELIGIOUS_ORGANIZATION',
  label: 'Religious organization'
}, {
  value: 'REPAIR_SERVICES',
  label: 'Repair services'
}, {
  value: 'RESTAURANT_FOOD_SERVICE',
  label: 'Restaurant, food services'
}, {
  value: 'RETAIL_SALES_RETAIL_TRADE',
  label: 'Retail sales, retail trade'
}, {
  value: 'RETIREMENT',
  label: 'Retirement'
}, {
  value: 'SCIENCE_AND_BIOTECHNOLOGY',
  label: 'Science and biotechnology'
}, {
  value: 'SECURITY',
  label: 'Security'
}, {
  value: 'TRANSPORTATION',
  label: 'Transportation'
}, {
  value: 'TRAVEL',
  label: 'Travel'
}, {
  value: 'UTILITIES',
  label: 'Utilities'
}, {
  value: 'WHOLESALE_SALES_TRADE',
  label: 'Wholesale sales, trade'
}, {
  value: 'INDUSTRY_NOT_APPLICABLE_UNEMPLOYED',
  label: 'Industry not applicable, unemployed'
}, {
  value: 'OTHER_INDUSTRY',
  label: 'Other industry'
}];
export const occupationOptions = [{
  value: 'ACCOUNTANT',
  label: 'Accountant'
}, {
  value: 'ACTOR',
  label: 'Actor'
}, {
  value: 'ADJUSTER',
  label: 'Adjuster'
}, {
  value: 'AIR_TRAFFIC_CONTROLLER',
  label: 'Air traffic controller'
}, {
  value: 'ANALYST',
  label: 'Analyst'
}, {
  value: 'APPRAISER',
  label: 'Appraiser'
}, {
  value: 'ARCHITECT',
  label: 'Architect'
}, {
  value: 'ARTIST',
  label: 'Artist'
}, {
  value: 'ATHLETE',
  label: 'Athlete'
}, {
  value: 'ATTORNEY',
  label: 'Attorney'
}, {
  value: 'AUCTIONEER',
  label: 'Auctioneer'
}, {
  value: 'AUDITOR',
  label: 'Auditor'
}, {
  value: 'BANKER',
  label: 'Banker'
}, {
  value: 'BEAUTICIAN',
  label: 'Beautician'
}, {
  value: 'BOOKKEEPER',
  label: 'Bookkeeper'
}, {
  value: 'BROKER',
  label: 'Broker'
}, {
  value: 'BUSINESS_CONSULTANT',
  label: 'Business consultant'
}, {
  value: 'BUSINESS_EXECUTIVE',
  label: 'Business executive'
}, {
  value: 'BUSINESS_OWNER',
  label: 'Business owner'
}, {
  value: 'CAREGIVER',
  label: 'Caregiver'
}, {
  value: 'CARPENTER',
  label: 'Carpenter'
}, {
  value: 'CASHIER',
  label: 'Cashier'
}, {
  value: 'CHEF',
  label: 'Chef'
}, {
  value: 'COOK',
  label: 'Cook'
}, {
  value: 'CHIEF_EXECUTIVE_OFFICER',
  label: 'Chief executive officer'
}, {
  value: 'CHIEF_FINANCIAL_OFFICER',
  label: 'Chief financial officer'
}, {
  value: 'CHIEF_INFORMATION_OFFICER',
  label: 'Chief information officer'
}, {
  value: 'CHIEF_OPERATING_OFFICER',
  label: 'Chief operating officer'
}, {
  value: 'CHIEF_SECURITY_OFFICER',
  label: 'Chief security officer'
}, {
  value: 'CHIEF_TECHNOLOGY_OFFICER',
  label: 'Chief technology officer'
}, {
  value: 'CHIROPRACTOR',
  label: 'Chiropractor'
}, {
  value: 'CIVIL_SERVICE_WORKER',
  label: 'Civil service worker'
}, {
  value: 'CLERGY_MEMBER',
  label: 'Clergy member'
}, {
  value: 'CLERK',
  label: 'Clerk'
}, {
  value: 'COMPLIANCE_OFFICER',
  label: 'Compliance officer'
}, {
  value: 'CONSTRUCTION_WORKER',
  label: 'Construction worker'
}, {
  value: 'COUNSELOR',
  label: 'Counselor'
}, {
  value: 'CRAFTSPERSON',
  label: 'Craftsperson'
}, {
  value: 'CUSTOMER_SERVICE_REPRESENTATIVE',
  label: 'Customer service representative'
}, {
  value: 'DANCER',
  label: 'Dancer'
}, {
  value: 'DEALER',
  label: 'Dealer'
}, {
  value: 'DENTIST',
  label: 'Dentist'
}, {
  value: 'DESIGNER',
  label: 'Designer'
}, {
  value: 'DISTRIBUTOR',
  label: 'Distributor'
}, {
  value: 'DOCTOR',
  label: 'Doctor'
}, {
  value: 'DRIVER',
  label: 'Driver'
}, {
  value: 'EDITOR',
  label: 'Editor'
}, {
  value: 'ENGINEER',
  label: 'Engineer'
}, {
  value: 'EXECUTIVE_ASSISTANT',
  label: 'Executive assistant'
}, {
  value: 'EXECUTIVE_DIRECTOR',
  label: 'Executive director'
}, {
  value: 'EXPORTER',
  label: 'Exporter'
}, {
  value: 'EXTERMINATOR',
  label: 'Exterminator'
}, {
  value: 'FACTORY_WORKER',
  label: 'Factory worker'
}, {
  value: 'FARMER',
  label: 'Farmer'
}, {
  value: 'FINANCIAL_ADVISOR',
  label: 'Financial advisor'
}, {
  value: 'FINANCIAL_PLANNER',
  label: 'Financial planner'
}, {
  value: 'FIRE_FIGHTER',
  label: 'Fire fighter'
}, {
  value: 'FLIGHT_ATTENDANT',
  label: 'Flight attendant'
}, {
  value: 'FUNERAL_DIRECTOR',
  label: 'Funeral director'
}, {
  value: 'GENERAL_MANAGER',
  label: 'General manager'
}, {
  value: 'GOV_AFFAIRS_SPECIALIST',
  label: 'Government affairs specialist'
}, {
  value: 'GOV_SERVICE_EXECUTIVE',
  label: 'Government service executive'
}, {
  value: 'HAIR_STYLIST',
  label: 'Hair stylist'
}, {
  value: 'HUMAN_RESOURCES_REPRESENTATIVE',
  label: 'Human resources representative'
}, {
  value: 'IMPORTER',
  label: 'Importer'
}, {
  value: 'INSPECTOR',
  label: 'Inspector'
}, {
  value: 'INSTRUCTOR',
  label: 'Instructor'
}, {
  value: 'INVESTIGATOR',
  label: 'Investigator'
}, {
  value: 'INVESTOR',
  label: 'Investor'
}, {
  value: 'IT_CONSULTANT',
  label: 'IT consultant'
}, {
  value: 'IT_PROFESSIONAL',
  label: 'IT professional'
}, {
  value: 'JANITOR',
  label: 'Janitor'
}, {
  value: 'JEWELER',
  label: 'Jeweler'
}, {
  value: 'JOURNALIST',
  label: 'Journalist'
}, {
  value: 'JUDGE',
  label: 'Judge'
}, {
  value: 'LABORER',
  label: 'Laborer'
}, {
  value: 'LANDSCAPER',
  label: 'Landscaper'
}, {
  value: 'LAW_ENFORCEMENT_OFFICER',
  label: 'Law enforcement officer'
}, {
  value: 'MARKETER',
  label: 'Marketer'
}, {
  value: 'MECHANIC',
  label: 'Mechanic'
}, {
  value: 'MILITARY_OFFICER',
  label: 'Military officer'
}, {
  value: 'MORTICIAN',
  label: 'Mortician'
}, {
  value: 'NURSE',
  label: 'Nurse'
}, {
  value: 'OFFICE_ASSOCIATE',
  label: 'Office associate'
}, {
  value: 'OFFICE_MANAGER',
  label: 'Office manager'
}, {
  value: 'PERFORMER',
  label: 'Performer'
}, {
  value: 'PHARMACIST',
  label: 'Pharmacist'
}, {
  value: 'PHYSICAL_THERAPIST',
  label: 'Physical therapist'
}, {
  value: 'PHYSICIAN',
  label: 'Physician'
}, {
  value: 'PILOT',
  label: 'Pilot'
}, {
  value: 'POLITICIAN',
  label: 'Politician'
}, {
  value: 'PROFESSOR',
  label: 'Professor'
}, {
  value: 'PROJECT_MANAGER',
  label: 'Project manager'
}, {
  value: 'PUBLIC_RELATIONS_PROFESSIONAL',
  label: 'Public relations professional'
}, {
  value: 'RANCHER',
  label: 'Rancher'
}, {
  value: 'REAL_ESTATE_PROFESSIONAL',
  label: 'Real estate professional'
}, {
  value: 'RECEPTIONIST',
  label: 'Receptionist'
}, {
  value: 'REGISTERED_REPRESENTATIVE',
  label: 'Registered representative'
}, {
  value: 'REGULATORY_COMPLIANCE_SPECIALIST',
  label: 'Regulatory compliance specialist'
}, {
  value: 'RESEARCHER',
  label: 'Researcher'
}, {
  value: 'SALESPERSON',
  label: 'Salesperson'
}, {
  value: 'SCIENTIST',
  label: 'Scientist'
}, {
  value: 'SEAMSTRESS',
  label: 'Seamstress'
}, {
  value: 'SECURITY_GUARD',
  label: 'Security guard'
}, {
  value: 'SOCIAL_WORKER',
  label: 'Social worker'
}, {
  value: 'SURGEON',
  label: 'Surgeon'
}, {
  value: 'TAILOR',
  label: 'Tailor'
}, {
  value: 'TEACHER',
  label: 'Teacher'
}, {
  value: 'TECHNICIAN',
  label: 'Technician'
}, {
  value: 'TELLER',
  label: 'Teller'
}, {
  value: 'THERAPIST',
  label: 'Therapist'
}, {
  value: 'TRADESPERSON',
  label: 'Tradesperson'
}, {
  value: 'TRAINER',
  label: 'Trainer'
}, {
  value: 'UNDERWRITER',
  label: 'Underwriter'
}, {
  value: 'VETERINARIAN',
  label: 'Veterinarian'
}, {
  value: 'WAREHOUSE_WORKER',
  label: 'Warehouse worker'
}, {
  value: 'WRITER',
  label: 'Writer'
}];
const TEST_ID_KEY = 'kyc-employment-info';
const {
  getTestIds
} = genTestIds(['employmentStatusField', 'employmentIndustryField', 'employerNameField', 'employmentTitleField', 'formAddressInputGroup', 'submitButton', 'pageLoader']);
export const testIds = getTestIds(TEST_ID_KEY);