import { useEffect, useRef, useState } from 'react';
import { bankAccountApi, paymentMethodsApi } from '@yieldstreet/platform-kit';
export const useTrackInvestmentNextStep = (data, userId) => {
  const prevInvestmentGateRef = useRef();
  const {
    0: gates,
    1: setGates
  } = useState();
  const {
    useLazyGetBankAccountsQuery
  } = bankAccountApi;
  const {
    useLazyPaymentMethodsQuery
  } = paymentMethodsApi;
  const [getBankAccounts] = useLazyGetBankAccountsQuery();
  const [getPaymentMethods] = useLazyPaymentMethodsQuery();
  const verifyGate = gate => {
    switch (gate.type) {
      default:
        return gate.status === 'CLEARED';
    }
  };
  useEffect(() => {
    if (prevInvestmentGateRef !== null && prevInvestmentGateRef !== void 0 && prevInvestmentGateRef.current) {
      const receivedGates = (data === null || data === void 0 ? void 0 : data.investmentGates) || [];
      const prevGates = prevInvestmentGateRef.current;
      const newGates = prevGates.filter(gate => {
        const newGate = receivedGates.find(g => g.type === gate.type);
        return !newGate || verifyGate(newGate);
      });
      setGates(newGates);
    }
    prevInvestmentGateRef.current = data === null || data === void 0 ? void 0 : data.investmentGates;
  }, [data === null || data === void 0 ? void 0 : data.investmentGates]);
  useEffect(() => {
    if (!(gates !== null && gates !== void 0 && gates.length) || !data) {
      return;
    }
    gates.forEach(gate => {
      switch (gate.type) {
        case 'BANK_ACCOUNT_PENDING':
          {
            var _data$investment, _data$investment2, _data$investment3;
            getBankAccounts({
              userId
            });
            getPaymentMethods({
              investmentAmount: ((_data$investment = data.investment) === null || _data$investment === void 0 ? void 0 : _data$investment.amount) || 0,
              investorAccountId: +(((_data$investment2 = data.investment) === null || _data$investment2 === void 0 ? void 0 : _data$investment2.investorAccountId) || 0),
              urlHashes: [((_data$investment3 = data.investment) === null || _data$investment3 === void 0 ? void 0 : _data$investment3.noteUrlHash) || '']
            });
          }
      }
    });
  }, [data, gates, getBankAccounts, getPaymentMethods, userId]);
  return gates;
};