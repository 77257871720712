import { combineReducers } from 'redux';
import * as actions from 'store/actions/articles';
import createAPIReducer from './common/createAPIReducer';
const teamMembers = createAPIReducer(actions, 'GET_TEAM_MEMBERS', {
  initData: {
    teamMembers: [],
    advisors: [],
    founders: [],
    investors: [],
    investorsLeadership: [],
    prisimBoard: []
  },
  successHandler: _ref => {
    let {
      response
    } = _ref;
    return {
      teamMembers: {
        $set: response.teamMembers
      },
      advisors: {
        $set: response.advisors
      },
      founders: {
        $set: response.founders
      },
      investors: {
        $set: response.investors
      },
      investorsLeadership: {
        $set: response.investorsLeadership
      },
      prisimBoard: {
        $set: response.prisimBoard
      }
    };
  }
});
const articlesBySlug = createAPIReducer(actions, 'GET_ARTICLES_BY_SLUG', {
  initData: {
    article: null,
    articleNotFound: false
  },
  successHandler: _ref2 => {
    let {
      response
    } = _ref2;
    return {
      article: {
        $set: response.length && response[0]
      },
      articleNotFound: {
        $set: !response.length
      }
    };
  }
});
const multipleArticlesBySlug = createAPIReducer(actions, 'GET_MULTIPLE_ARTICLES_BY_SLUG', {
  initData: {
    articles: [],
    articlesNotFound: false
  },
  successHandler: _ref3 => {
    let {
      response
    } = _ref3;
    return {
      articles: {
        $merge: {
          [response.length && response[0].slug]: response.length && response[0]
        }
      },
      articlesNotFound: {
        $set: !response.length
      }
    };
  }
});
export const createPostReducer = actionKey => {
  return createAPIReducer(actions, actionKey, {
    initData: {
      posts: []
    },
    successHandler: _ref4 => {
      let {
        response
      } = _ref4;
      return {
        posts: {
          $set: response.data ? response.data : []
        },
        postsPageAmount: {
          $set: response.headers['x-wp-totalpages']
        }
      };
    }
  });
};
const assetAllTypes = createPostReducer('GET_ASSET_All_TYPES');
const assetArticles = createPostReducer('GET_ASSET_ARTICLES');
const assetVideos = createPostReducer('GET_ASSET_VIDEOS');
const assetInfographics = createPostReducer('GET_ASSET_INFOGRAPHICS');
const pressArticles = createPostReducer('GET_PRESS_ARTICLES');
const yieldstreetDefaultsArticles = createPostReducer('GET_YIELDSTREET_DEFAULTS_ARTICLES');
const blogArticles = createPostReducer('GET_BLOG_ARTICLES');
export const createCategoriesReducer = actionKey => {
  return createAPIReducer(actions, actionKey, {
    initData: {
      categories: []
    },
    successHandler: _ref5 => {
      let {
        response
      } = _ref5;
      return {
        categories: {
          $set: response.data || []
        }
      };
    }
  });
};
const blogCategories = createCategoriesReducer('GET_BLOG_CATEGORIES');
export default combineReducers({
  teamMembers,
  articlesBySlug,
  assetAllTypes,
  assetArticles,
  assetVideos,
  assetInfographics,
  pressArticles,
  yieldstreetDefaultsArticles,
  blogArticles,
  multipleArticlesBySlug,
  blogCategories
});