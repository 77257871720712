var __jsx = React.createElement;
import React, { useState } from 'react';
import styled from 'styled-components';
import { media } from '../../themes/media';
import { Heading, Paragraph } from '../../content/text';
import { Button, ButtonContainer } from '../../content/button';
import { PageSection } from '../../layout';
import CommercialImage from '../../assets/graphics/asset-classes/commerical_inverse.svg';
import RealEstateImage from '../../assets/graphics/asset-classes/real_estate_inverse.svg';
import DataScienceImage from '../../assets/graphics/case-specific/data_science_inverse.svg';
import IRAImage from '../../assets/graphics/case-specific/ira_inverse.svg';
import YSLogoImage from './assets/ys_logo_white.svg';
export const HowItWorks = () => {
  const {
    0: section,
    1: setSection
  } = useState(3);
  const sectionChange = section => {
    setSection(section);
  };
  return __jsx(Background, null, __jsx(PageSection, null, __jsx(Container, null, __jsx(ContentHolder, null, __jsx(Headline, {
    type: 3,
    inverse: true
  }, "Discover how far your money will take you"), __jsx(Content, {
    inverse: true
  }, "Regardless of where you start, we provide you with the tools needed to generate financial momentum\u2014helping you reach your next level faster."), __jsx(List, null, __jsx(ListItem, {
    onClick: () => sectionChange(3),
    active: section === 3
  }, __jsx(Paragraph, null, "Yieldstreet funds")), __jsx(ListItem, {
    onClick: () => sectionChange(2),
    active: section === 2
  }, __jsx(Paragraph, null, "Individual offerings")), __jsx(ListItem, {
    onClick: () => sectionChange(1),
    active: section === 1
  }, __jsx(Paragraph, null, "Yieldstreet IRA")), __jsx(ListItem, {
    onClick: () => sectionChange(0),
    active: section === 0
  }, __jsx(Paragraph, null, "Yieldstreet Wallet")), __jsx(ListItem, {
    onClick: () => sectionChange(4),
    active: section === 4
  }, __jsx(Paragraph, null, "Reach your next level")))), __jsx(RightHolder, null, __jsx(Circle, null, __jsx(Commercial, {
    onClick: () => sectionChange(0),
    active: section === 0
  }, __jsx(Image, {
    alt: "Yieldstreet Wallet",
    src: CommercialImage
  })), __jsx(IRA, {
    onClick: () => sectionChange(1),
    active: section === 1
  }, __jsx(Image, {
    alt: "Yieldstreet IRA",
    src: IRAImage
  })), __jsx(RealEstate, {
    onClick: () => sectionChange(2),
    active: section === 2
  }, __jsx(Image, {
    alt: "investing in individual offerings",
    src: RealEstateImage
  })), __jsx(DataScience, {
    onClick: () => sectionChange(3),
    active: section === 3
  }, __jsx(Image, {
    alt: "investing in Yieldstreet funds",
    src: DataScienceImage
  })), __jsx(YSLogo, {
    onClick: () => sectionChange(4),
    active: section === 4
  }, __jsx(Image, {
    alt: "invest to realize your next level",
    src: YSLogoImage
  })), __jsx(SubContentHolder, {
    active: section === 3
  }, __jsx(Image, {
    src: DataScienceImage
  }), __jsx(Title, null, "YieldStreet funds"), __jsx(Paragraph, {
    inverse: true,
    small: true
  }, "Make one allocation spread across multiple classes and sectors, allowing you to immediately diversify your portfolio.", __jsx("ul", null, __jsx("li", null, "Reinvest your dividends"), __jsx("li", null, "Immediate portfolio acceleration")), __jsx(ButtonContainer, null, __jsx("a", {
    href: "/offerings"
  }, __jsx(Button, {
    small: true
  }, "Invest"))))), __jsx(SubContentHolder, {
    active: section === 2
  }, __jsx(Image, {
    src: RealEstateImage
  }), __jsx(Title, null, "Individual offerings"), __jsx(Paragraph, {
    inverse: true,
    small: true
  }, "Invest across a variety of institutional quality assets like Art, Legal, Marine, Private Business Credit and Real Estate.", __jsx("ul", null, __jsx("li", null, "Rolling maturities"), __jsx("li", null, "Low stock market correlation")), __jsx(ButtonContainer, null, __jsx("a", {
    href: "/offerings"
  }, __jsx(Button, {
    small: true
  }, "Invest"))))), __jsx(SubContentHolder, {
    active: section === 1
  }, __jsx(Image, {
    src: IRAImage
  }), __jsx(Title, null, "Yieldstreet IRA"), __jsx(Paragraph, {
    inverse: true
  }, "As an investor-first company, we ensure that you\u2019re leveraging all of the tools available to you.", __jsx("ul", null, __jsx("li", null, "Tax-efficient"), __jsx("li", null, "Optimize your retirement accounts")), __jsx(ButtonContainer, null, __jsx("a", {
    href: "/ira"
  }, __jsx(Button, {
    small: true
  }, "Get started"))))), __jsx(SubContentHolder, {
    active: section === 0
  }, __jsx(Image, {
    src: CommercialImage
  }), __jsx(Title, null, "Yieldstreet Wallet"), __jsx(Paragraph, {
    inverse: true,
    small: true
  }, "Your Wallet acts as a flexible savings account and allows you to invest more quickly.", __jsx("ul", null, __jsx("li", null, "FDIC insured")), __jsx(ButtonContainer, null, __jsx("a", {
    href: "/signup-quiz"
  }, __jsx(Button, {
    small: true
  }, "Sign up"))))), __jsx(SubContentHolder, {
    active: section === 4
  }, __jsx(Image, {
    src: YSLogoImage
  }), __jsx(Title, null, "Reach your next level"), __jsx(Paragraph, {
    inverse: true,
    small: true
  }, "With Yieldstreet as your partner, you\u2019re generating passive income to help fund your dreams. Whether your ambitions are large or small, we accelerate your timeline.", __jsx(ButtonContainer, null, __jsx("a", {
    href: "/signup-quiz"
  }, __jsx(Button, {
    small: true
  }, "Sign up"))))))))));
};
const Background = styled.div.withConfig({
  displayName: "HowItWorks__Background",
  componentId: "sc-1ddgcih-0"
})(["background:", ";"], props => props.theme.colors.background_inverse);
const Container = styled.div.withConfig({
  displayName: "HowItWorks__Container",
  componentId: "sc-1ddgcih-1"
})(["margin:0 auto;padding:0 0 20px 0;display:flex;flex-wrap:wrap;", ""], media.desktop`
    padding: 0 0 40px 110px;
    flex-wrap: nowrap;
  `);
const ContentHolder = styled.div.withConfig({
  displayName: "HowItWorks__ContentHolder",
  componentId: "sc-1ddgcih-2"
})(["padding:20px 0 20px;width:100%;", ""], media.desktop`
    padding: 60px 0 60px;
    width: 40%;
  `);
const Headline = styled(Heading).withConfig({
  displayName: "HowItWorks__Headline",
  componentId: "sc-1ddgcih-3"
})(["margin:20px 0 20px 20px;"]);
const Content = styled(Paragraph).withConfig({
  displayName: "HowItWorks__Content",
  componentId: "sc-1ddgcih-4"
})(["margin:0 0 20px 20px;"]);
const List = styled.ul.withConfig({
  displayName: "HowItWorks__List",
  componentId: "sc-1ddgcih-5"
})(["list-style:none;padding:0px 20px;margin-top:40px;display:none;", ""], media.desktop`
    display: block;
  `);
const ListItem = styled.li.withConfig({
  displayName: "HowItWorks__ListItem",
  componentId: "sc-1ddgcih-6"
})(["p{color:", ";&:hover{color:", ";}}display:flex;flex-direction:column;padding:10px 20px;cursor:pointer;border-top:1px solid ", ";&:last-child{border-bottom:1px solid ", ";}"], props => props.active ? props.theme.colors.active : props.theme.colors.text_label, props => props.theme.colors.active, props => props.theme.colors.border_default, props => props.theme.colors.border_default);
const RightHolder = styled.div.withConfig({
  displayName: "HowItWorks__RightHolder",
  componentId: "sc-1ddgcih-7"
})(["width:100%;margin:20px;", ""], media.desktop`
    width: 60%;
    margin: 70px 40px 0 120px;
  `);
const Circle = styled.div.withConfig({
  displayName: "HowItWorks__Circle",
  componentId: "sc-1ddgcih-8"
})(["position:relative;", ""], media.desktop`
    border-radius: 50%;
    border: 2px solid white;
    width: 535px;
    height: 535px;
    box-sizing: border-box;
    padding: 93px 111px 0px 156px;
    margin-top: 20px;
  `);
const Image = styled.img.withConfig({
  displayName: "HowItWorks__Image",
  componentId: "sc-1ddgcih-9"
})(["width:80px;"]);
const AssetImages = styled.div.withConfig({
  displayName: "HowItWorks__AssetImages",
  componentId: "sc-1ddgcih-10"
})(["width:120px;position:absolute;border:20px solid ", ";background:", ";cursor:pointer;", "{opacity:", ";transition:opacity 0.5s linear;}display:none;", ""], props => props.theme.colors.background_inverse, props => props.theme.colors.background_inverse, Image, props => props.active ? `100%` : `30%`, media.desktop`
    display: block;
  `);
const Commercial = styled(AssetImages).withConfig({
  displayName: "HowItWorks__Commercial",
  componentId: "sc-1ddgcih-11"
})(["left:-35px;top:340px;"]);
const YSLogo = styled(AssetImages).withConfig({
  displayName: "HowItWorks__YSLogo",
  componentId: "sc-1ddgcih-12"
})(["left:-28px;top:84px;"]);
const IRA = styled(AssetImages).withConfig({
  displayName: "HowItWorks__IRA",
  componentId: "sc-1ddgcih-13"
})(["right:-21px;top:340px;"]);
const RealEstate = styled(AssetImages).withConfig({
  displayName: "HowItWorks__RealEstate",
  componentId: "sc-1ddgcih-14"
})(["right:-32px;top:89px;"]);
const DataScience = styled(AssetImages).withConfig({
  displayName: "HowItWorks__DataScience",
  componentId: "sc-1ddgcih-15"
})(["left:207px;top:-54px;"]);
const SubContentHolder = styled.div.withConfig({
  displayName: "HowItWorks__SubContentHolder",
  componentId: "sc-1ddgcih-16"
})(["", "{margin:0 0 20px;", "}display:block;", ""], Image, media.desktop`
      display: none;
      margin: 0;
    `, media.desktop`
    display: ${props => props.active ? 'block' : 'none'};
  `);
const Title = styled(Paragraph).withConfig({
  displayName: "HowItWorks__Title",
  componentId: "sc-1ddgcih-17"
})(["color:", ";margin-bottom:10px;"], props => props.theme.colors.active);