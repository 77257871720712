import isArray from "lodash/isArray";
import { CALL_API } from '../middleware/Middleware';
export const GET_PAST_OFFERINGS_ATTEMPT = 'ys/offering/past/ATTEMPT';
export const GET_PAST_OFFERINGS_SUCCESS = 'ys/offering/past/SUCCESS';
export const GET_PAST_OFFERINGS_FAILURE = 'ys/offering/past/FAILURE';
export const getPastOfferings = (filters, pagination) => dispatch => {
  const endpoint = pastOfferingsUrl(filters, pagination);
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_PAST_OFFERINGS_ATTEMPT, GET_PAST_OFFERINGS_SUCCESS, GET_PAST_OFFERINGS_FAILURE],
      getResponse: res => res.data
    }
  });
};
export const GET_PAST_OFFERINGS_ASSET_ATTEMPT = 'ys/offering/past/asset/ATTEMPT';
export const GET_PAST_OFFERINGS_ASSET_SUCCESS = 'ys/offering/past/asset/SUCCESS';
export const GET_PAST_OFFERINGS_ASSET_FAILURE = 'ys/offering/past/asset/FAILURE';
export const getPastOfferingAssetClasses = filters => dispatch => {
  const endpoint = pastOffsAssetClasses(filters);
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_PAST_OFFERINGS_ASSET_ATTEMPT, GET_PAST_OFFERINGS_ASSET_SUCCESS, GET_PAST_OFFERINGS_ASSET_FAILURE],
      getResponse: res => res.data.assetClasses
    }
  });
};
export const CLEAR_PAST_OFFERINGS = 'ys/offering/past/CLEAR';
export const clearPastOfferings = () => dispatch => dispatch({
  type: CLEAR_PAST_OFFERINGS
});

// Helpers
const buildParams = params => {
  const paramString = params.filter(_ref => {
    let {
      value
    } = _ref;
    return value !== undefined && value !== null;
  }).map(_ref2 => {
    let {
      key,
      value
    } = _ref2;
    if (isArray(value)) {
      return value.map(valueItem => `${key}[]=${valueItem}`).join('&');
    } else {
      return `${key}=${value}`;
    }
  }).join('&');
  return paramString.length ? `?${paramString}` : '';
};
const pastOfferingsUrl = function () {
  let {
    active,
    fullyRepaid,
    assetClass,
    accreditation
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let {
    page = 1,
    pageSize = 24
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  let requireMetrics = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  let includeMetrics = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : true;
  const baseURL = '/a/api/marketplace/other';
  return `${baseURL}${buildParams([{
    key: 'active',
    value: active
  }, {
    key: 'fullyRepaid',
    value: fullyRepaid
  }, {
    key: 'assetClass',
    value: assetClass
  }, {
    key: 'page',
    value: page
  }, {
    key: 'pageSize',
    value: pageSize
  }, {
    key: 'requireMetrics',
    value: requireMetrics
  }, {
    key: 'includeMetrics',
    value: includeMetrics
  }, {
    key: 'accreditation',
    value: accreditation
  }])}`;
};
const pastOffsAssetClasses = function () {
  let {
    active,
    fullyRepaid,
    assetClass
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const baseURL = '/a/api/marketplace/asset-classes?';
  return `${baseURL}${buildParams([{
    key: 'active',
    value: active
  }, {
    key: 'fullyRepaid',
    value: fullyRepaid
  }, {
    key: 'assetClass',
    value: assetClass
  }])}`;
};