import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import qs from 'qs';
export const extractParams = (params, queryParams) => {
  if (params !== null && params !== void 0 && params.length) {
    return params.reduce((result, param) => {
      if (queryParams !== null && queryParams !== void 0 && queryParams[param]) {
        return _objectSpread(_objectSpread({}, result), {}, {
          [param]: queryParams === null || queryParams === void 0 ? void 0 : queryParams[param]
        });
      }
      return result;
    }, {});
  }
  return {
    [params]: queryParams === null || queryParams === void 0 ? void 0 : queryParams[params]
  };
};
export const useQueryFilter = function () {
  let queryFilterParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const {
    search
  } = useLocation();
  const {
    query,
    allQueryKeys
  } = useMemo(() => {
    const allQueryKeys = Object.values(queryFilterParams);
    return {
      allQueryKeys,
      query: extractParams(allQueryKeys, qs.parse(search.substring(1)))
    };
  }, [search, queryFilterParams]);
  return {
    allQueryKeys,
    query
  };
};