import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["children", "config"];
var __jsx = React.createElement;
import React from 'react';
import { Container } from '../container';
import { GridProvider } from './grid/Provider';
export const PageGrid = _ref => {
  let {
      children,
      config
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  return __jsx(GridProvider, {
    config: config
  }, __jsx(Container, props, children));
};