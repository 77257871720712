import { CONFIGS } from '../const';
export const CONFIG_TEST = {
  KEY: CONFIGS.TEST,
  CASTLE_APP_ID: '967132165522131',
  LAUNCHDARKLY_SDK_KEY: 'sdk-1fac848b-18df-480a-ab44-ae92d376c8a9',
  LAUNCHDARKLY_CLIENT_ID: '627bcc00efd2f015588e5c03',
  GOOGLE_CLIENT_ID: '735310356717-5rbl6s11kbcpfuu5fprrkms6n733q2a8.apps.googleusercontent.com',
  GOOGLE_PLACES_KEY: 'AIzaSyDd0QgapeK4Ap0pMAJ-mN1hh9WEH4wiEhE',
  APPLE_CLIENT_ID: 'com.yieldstreet.YieldstreetWeb',
  RECAPTCHA_CLIENT_ID: '6LdNge0gAAAAAOsyDutrZXwDv2OmvSTI-a_hZnd0',
  SEGMENT_WRITE_KEY: 'KxujeXPvYj7vPVHUftA5Vgt9Djdzmtf3',
  DEBUG: true,
  BRAZE_API_KEY: 'dd3ef63f-8fb0-4b77-95cc-0ba5323399de',
  BRAZE_SDK_URL: 'sdk.iad-05.braze.com',
  // Datadog RUM setup keys
  // DATADOG_RUM_APPLICATION_ID: 'ab7c500b-160f-4a1c-979c-f7d4208a8fa6',
  // DATADOG_RUM_CLIENT_TOKEN: 'pub50493d97de3a901fc782f6e3069c0741',

  // Docusign setup keys
  DOCUSIGN_ENV: 'https://demo.docusign.net',
  DOCUSIGN_ACCOUNT_ID: '141755f8-0037-4534-b380-508d1ff15a74',
  DOCUSIGN_SCRIPT_URL: 'https://demo.docusign.net/clickapi/sdk/latest/docusign-click.js',
  /**
   * Docusign document keys - clickwrapId
   */
  // ETC agreement on complete invest flow
  DOCUSIGN_CLICKWRAP_ID_COMPLETE_INVEST: '6008fab9-6a57-484d-a4a9-3c2760d87477',
  // agreements on etc entity creation
  DOCUSIGN_CLICKWRAP_ID_ROTH_IRA: '493c08ff-e9f6-4235-98f3-2785a56a22c2',
  DOCUSIGN_CLICKWRAP_ID_SEP_IRA: '493c08ff-e9f6-4235-98f3-2785a56a22c2',
  DOCUSIGN_CLICKWRAP_ID_SIMPLE_IRA: '738a29f8-bb7a-4e1b-acfe-b3786a2e639a',
  DOCUSIGN_CLICKWRAP_ID_TRADITIONAL_IRA: '493c08ff-e9f6-4235-98f3-2785a56a22c2'
};