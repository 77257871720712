import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import map from "lodash/map";
import mapValues from "lodash/mapValues";
import identity from "lodash/identity";
export const mapToBalanceBreakdown = function () {
  let accountSummary = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let config = arguments.length > 1 ? arguments[1] : undefined;
  let filterEmpty = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  return mapValues(config, section => {
    const mappedItems = map(section === null || section === void 0 ? void 0 : section.items, item => {
      return _objectSpread(_objectSpread({}, item), {}, {
        value: accountSummary[item.key]
      });
    }).filter(identity);
    return _objectSpread(_objectSpread({}, section), {}, {
      // remove items that are configured to hide if empty and have 0/empty value
      items: filterEmpty ? mappedItems.filter(item => !item.hideWhenEmpty || item.value) : mappedItems
    });
  });
};