import { PortfolioStrategyValues } from '@yieldstreet/platform-kit';
import { getDatesForRange } from '@yieldstreet/tool-kit';
import { CALL_API } from '../middleware/Middleware';
export const PORTFOLIO_PAYMENT_ACTIVITY_ATTEMPT = 'ys/portfolioPaymentActivity/ATTEMPT';
export const PORTFOLIO_PAYMENT_ACTIVITY_SUCCESS = 'ys/portfolioPaymentActivity/SUCCESS';
export const PORTFOLIO_PAYMENT_ACTIVITY_FAILURE = 'ys/portfolioPaymentActivity/FAILURE';
export const getPortfolioPaymentActivity = function (userId, investorAccountId, timePeriod, noteUrlHash) {
  let managementStrategy = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : PortfolioStrategyValues.Self;
  return dispatch => {
    const [start, end] = getDatesForRange({
      range: timePeriod
    });
    const noteUrlHashParam = noteUrlHash ? `&noteUrlHash=${noteUrlHash}` : '';
    const investorAccountIdParam = `&investorAccountId=${investorAccountId}`;
    const endpoint = `/api/portfolio/${userId}/payment-activity?start=${start}&end=${end}${investorAccountId === 'ALL' ? '' : investorAccountIdParam}${noteUrlHashParam}&managementStrategy=${managementStrategy}`;
    return dispatch({
      [CALL_API]: {
        endpoint,
        types: [PORTFOLIO_PAYMENT_ACTIVITY_ATTEMPT, PORTFOLIO_PAYMENT_ACTIVITY_SUCCESS, PORTFOLIO_PAYMENT_ACTIVITY_FAILURE],
        successPayload: {
          userId,
          investorAccountId,
          timePeriod,
          noteUrlHash
        },
        failurePayload: {
          userId,
          investorAccountId,
          timePeriod,
          noteUrlHash
        },
        getResponse: res => res.data
      }
    });
  };
};