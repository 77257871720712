import styled, { css } from 'styled-components';
export const SelectMenuStyles = css(["&.MuiPaper-root{box-shadow:none;border-radius:", ";border:1px solid ", ";border-top:0;& .MuiMenu-list{padding:0;& .MuiMenuItem-root{padding-top:8px;padding-bottom:8px;font-family:", ";background-color:", ";&:not(:last-child){border-bottom:1px solid ", ";}&:hover{border-left:", ";border-left-style:solid;border-left-width:2px;}&.Mui-selected{border-left:", ";border-left-style:solid;border-left-width:2px;}}}}"], _ref => {
  let {
    theme
  } = _ref;
  return `0 0 ${theme.radius.md} ${theme.radius.md}`;
}, props => props.theme.colors.border_default, props => props.theme.typography.secondary, props => props.theme.colors.background_card, props => props.theme.colors.border_default, props => props.theme.colors.border_outline, props => props.theme.colors.active);
export const Arrow = styled.img.withConfig({
  displayName: "SelectInputstyle__Arrow",
  componentId: "sc-1y8ayi7-0"
})(["color:", ";right:0;padding:10px;pointer-events:none;position:absolute;", ""], props => props.theme.colors.text_paragraph, props => props.iconFacingUp ? 'transform: rotate(180deg);' : '');