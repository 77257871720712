import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useRef, useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { Paragraph } from '../../content';
import { MarketingContainer } from '../../layout';
import { useResponsiveFriendly } from '../../utils';
import { Button, IconWrapper, Image, ImageWrapper, Tab, TabsWrapper, Title, Wrapper } from './ContentTabs.style';
const visibilityOptions = {
  scrollDelay: 200,
  offset: {
    top: 500
  },
  scrollCheck: true
};
export const ContentTabs = _ref => {
  let {
    tabs,
    dataCy
  } = _ref;
  const viewPort = useResponsiveFriendly();
  const isMobile = viewPort === 'mobile';
  const imgRef = useRef(null);
  const {
    0: activeTab,
    1: setActiveTab
  } = useState(0);
  return __jsx(MarketingContainer, null, __jsx(Wrapper, {
    "data-cy": dataCy
  }, __jsx(ImageWrapper, {
    ref: imgRef
  }, tabs.map((tab, tabIndex) => __jsx(Image, {
    visible: tabIndex === activeTab,
    key: `tab-img-${tabIndex}`,
    "data-cy": `content-img-${tabIndex}`
  }, tab.image))), __jsx(TabsWrapper, null, tabs.map((tab, tabIndex) => __jsx(VisibilitySensor, _extends({}, visibilityOptions, {
    active: isMobile,
    onChange: isVisible => {
      isVisible && setActiveTab(tabIndex);
    },
    key: `content-tab-${tabIndex}`
  }), __jsx(Tab, {
    onMouseEnter: () => !isMobile && setActiveTab(tabIndex),
    onClick: () => isMobile && setActiveTab(tabIndex),
    active: tabIndex === activeTab,
    id: `content-tab-${tabIndex}`
  }, tab.icon && __jsx(IconWrapper, null, tab.icon), __jsx(Title, {
    type: 4
  }, tab.title), __jsx(Paragraph, {
    secondary: true
  }, tab.content), tab.buttonLink && __jsx(Button, {
    href: tab.buttonLink,
    target: "_blank"
  }, tab.buttonText)))))));
};