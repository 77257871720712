import { defaultTrackingParams } from '@yieldstreet/tool-kit';
export const AUTH_ERRORS = {
  VERIFY_2FA: 'We are unable to verify your code. Please reach out to us at investments@yieldstreet.com',
  ACCREDITATION: 'We are unable to submit your accreditation. Please reach out to us at investments@yieldstreet.com',
  PHONE: 'We are unable to submit your phone number. Please reach out to us at investments@yieldstreet.com',
  PASSWORD_REGISTER: 'We are unable to create your account. Please reach out to us at investments@yieldstreet.com',
  PASSWORD_LOGIN: 'We are unable to log you in. Please reach out to us at investments@yieldstreet.com',
  SOCIAL_LOGIN: 'We are unable to log you in. Please reach out to us at investments@yieldstreet.com',
  SOCIAL_LINK_IDENTITY: 'We are unable to link your social identity. Please reach out to us at investments@yieldstreet.com'
};
export const DEFAULT_ROUTE = '/dashboard';
export const LOGIN_REDIRECT_ROUTE = '/login-redirect';
export const LOGIN_LINK_IDENTITY_ROUTE = '/login/link-identity';
export const LOGIN_TWO_FA_ROUTE = '/login/twofa';
export const WELCOME_ROUTE = '/welcome';
export const SIGNUP_ACCREDITATION_ROUTE = '/signup/accreditation';
export const SIGNUP_PHONE_ROUTE = '/signup/phone';
export const SIGNUP_TRACKING_META = [...defaultTrackingParams, 'signup_quiz_id'];
export const SIGNUP_TRACKING_REDIRECTS = [
  /*
    UTM params matches that redirect user to custom route
    PCG config disabled because we now redirect to phone page with every signup
    Left it here as an example config of this functionality
  */
  // {
  //   metaKey: 'utm_source',
  //   metaValue: 'PCG',
  //   redirectUrl: '/signup/phone',
  // },
];