import { useCallback, useMemo } from 'react';
import { skipToken } from '@reduxjs/toolkit/query';
import { campaignApi } from '@yieldstreet/platform-kit';
import { campaigns } from './useCampaign.model';
export const useCampaign = _ref => {
  var _campaignEligibilityE, _submitCampaignVotesE;
  let {
    fundId,
    userId,
    investorAccountId
  } = _ref;
  const campaignEligibilityConfig = useMemo(() => {
    var _campaigns$;
    if (!fundId || !userId) {
      return skipToken;
    }
    return {
      fundId,
      campaignId: (_campaigns$ = campaigns[0]) === null || _campaigns$ === void 0 ? void 0 : _campaigns$.id,
      userId,
      investorAccountId
    };
  }, [fundId, campaigns, userId, investorAccountId]);
  const {
    data: campaignEligibilityData,
    error: campaignEligibilityError,
    isError: campaignEligibilityIsError,
    isFetching: campaignEligibilityIsFetching,
    isLoading: campaignEligibilityIsLoading,
    refetch: campaignEligibilityRefetch
  } = campaignApi.useGetCampaignEligibilityQuery(campaignEligibilityConfig);
  const [submitCampaignVotes, {
    data: submitCampaignVotesData,
    error: submitCampaignVotesError,
    isError: submitCampaignVotesIsError,
    isLoading: submitCampaignVotesIsLoading,
    isSuccess: submitCampaignVotesIsSuccess
  }] = campaignApi.useSubmitCampaignVotesMutation();
  const onSubmitCampaign = useCallback(voteList => {
    const votes = Object.entries(voteList).map((_ref2, index) => {
      var _campaigns$index;
      let [_key, value] = _ref2;
      return {
        value,
        campaignId: (_campaigns$index = campaigns[index]) === null || _campaigns$index === void 0 ? void 0 : _campaigns$index.id
      };
    });
    submitCampaignVotes({
      fundId,
      userId,
      investorAccountId,
      votes
    });
  }, [fundId, userId, investorAccountId, submitCampaignVotes]);
  return {
    campaignEligibilityData,
    campaignEligibilityError: campaignEligibilityError === null || campaignEligibilityError === void 0 ? void 0 : (_campaignEligibilityE = campaignEligibilityError.response) === null || _campaignEligibilityE === void 0 ? void 0 : _campaignEligibilityE.data,
    campaignEligibilityIsError,
    campaignEligibilityIsFetching,
    campaignEligibilityIsLoading,
    campaignEligibilityRefetch,
    onSubmitCampaign,
    submitCampaignVotesData,
    submitCampaignVotesError: submitCampaignVotesError === null || submitCampaignVotesError === void 0 ? void 0 : (_submitCampaignVotesE = submitCampaignVotesError.response) === null || _submitCampaignVotesE === void 0 ? void 0 : _submitCampaignVotesE.data,
    submitCampaignVotesIsError,
    submitCampaignVotesIsLoading,
    submitCampaignVotesIsSuccess
  };
};