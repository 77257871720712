import { useState, useMemo } from 'react';
import axios from 'axios';
import get from "lodash/get";
import { useUserState } from 'utils/hooks';
const useEmailVerification = () => {
  const userState = useUserState();
  const hasEmailVerified = useMemo(() => {
    return ((userState === null || userState === void 0 ? void 0 : userState.roles) || []).includes('investor.email.verified');
  }, [userState]);
  const {
    0: sendingEmail,
    1: setSendingEmail
  } = useState(false);
  const {
    0: errorMessage,
    1: setErrorMessage
  } = useState('');
  const {
    0: success,
    1: setSuccess
  } = useState(false);
  const {
    title,
    description
  } = useMemo(() => {
    if (errorMessage) {
      return {
        title: 'Failed to send email.',
        description: errorMessage
      };
    } else if (success) {
      return {
        title: 'Verification email resent.',
        description: 'Please check your inbox and confirm.'
      };
    } else {
      return {
        title: 'Your email address needs verification.',
        description: 'Please check your inbox and confirm. Missing the email?'
      };
    }
  }, [errorMessage, success]);
  const showNotification = (userState === null || userState === void 0 ? void 0 : userState.loggedIn) && !hasEmailVerified;
  const resendEmail = () => {
    if (!sendingEmail) {
      let error = '';
      setSendingEmail(true);
      return axios.post('/a/api/user/resendActivation', {
        email: userState === null || userState === void 0 ? void 0 : userState.emailAddress
      }).then(res => {
        if (!get(res, 'data.success', false)) {
          error = get(res, 'data.message', 'Failed to send the email verification. Please try again and contact investments@yieldstreet.com if this issue continues.');
        }
      }).catch(() => {
        error = 'We were not able to complete your request. Please try again and contact investments@yieldstreet.com if this issue continues.';
      }).finally(() => {
        setErrorMessage(error);
        setSuccess(!error);
        setSendingEmail(false);
      });
    }
  };
  return {
    showNotification,
    title,
    description,
    success,
    resendEmail
  };
};
export default useEmailVerification;