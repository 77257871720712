var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { TitleHeading, Paragraph } from '../../../content/text';
import { Currency } from './common/currency';
import { media } from '../../../themes/media';
export const Stats = _ref => {
  let {
    amount,
    subtitle,
    plainAmount,
    inverse,
    seperator = false,
    desktopAlignment = 'center',
    mobileAlignment = 'left',
    headingSuperscript,
    headingType = 2,
    tagType,
    className,
    horizontalLayout
  } = _ref;
  return __jsx(OuterContainer, {
    mobileAlignment: mobileAlignment,
    desktopAlignment: desktopAlignment,
    amount: amount,
    subtitle: subtitle,
    inverse: inverse,
    className: className,
    horizontalLayout: horizontalLayout
  }, __jsx(HeadlineHolder, {
    horizontalLayout: horizontalLayout
  }, __jsx(Headline, {
    type: headingType,
    tagType: tagType,
    inverse: inverse
  }, amount && __jsx(Currency, amount), plainAmount), headingSuperscript && __jsx(Superscript, {
    semiBold: true,
    inverse: inverse
  }, headingSuperscript)), seperator && __jsx(Separator, {
    desktopAlignment: desktopAlignment
  }), __jsx(Content, {
    className: "stats-subtitle",
    desktopAlignment: desktopAlignment,
    inverse: inverse,
    horizontalLayout: horizontalLayout
  }, subtitle));
};
const OuterContainer = styled.div.withConfig({
  displayName: "Stats__OuterContainer",
  componentId: "sc-1l3nke6-0"
})(["text-align:", ";margin:0 0 20px 0;flex-grow:1;flex-basis:0;flex:", ";", ""], props => props.mobileAlignment, props => props.horizontalLayout ? '50%' : '1 0 0%', media.desktop`
    margin: 0;
    text-align: ${props => props.desktopAlignment};
    display: ${props => props.horizontalLayout ? 'flex' : 'block'};
    flex-direction: ${props => props.horizontalLayout ? 'row' : 'none'};
  `);
const HeadlineHolder = styled.div.withConfig({
  displayName: "Stats__HeadlineHolder",
  componentId: "sc-1l3nke6-1"
})(["position:relative;margin:", ";", ""], props => props.horizontalLayout ? '0 0 4px' : '0px', media.desktop`
    margin: 0px;
  `);
const Headline = styled(TitleHeading).withConfig({
  displayName: "Stats__Headline",
  componentId: "sc-1l3nke6-2"
})(["padding:0;margin:10px 0 0 0;display:inline-block;", ""], media.desktop`
    margin: 10px 0;
  `);
const Superscript = styled(Paragraph).withConfig({
  displayName: "Stats__Superscript",
  componentId: "sc-1l3nke6-3"
})(["display:inline;font-family:", ";top:10px;position:absolute;font-size:smaller;width:auto !important;"], props => props.theme.typography.primary);
const Separator = styled.div.withConfig({
  displayName: "Stats__Separator",
  componentId: "sc-1l3nke6-4"
})(["width:30px;height:1px;margin:20px 0;background:", ";", ""], props => props.theme.colors.accent_strong, media.desktop`
    margin: ${props => props.desktopAlignment === 'center' ? '20px auto' : '20px 0'};
  `);
const Content = styled(Paragraph).withConfig({
  displayName: "Stats__Content",
  componentId: "sc-1l3nke6-5"
})(["font-size:", ";", ""], props => props.horizontalLayout ? '12px' : '16px', media.desktop`
    margin: ${props => props.desktopAlignment === 'center' ? '0 auto' : '0'};

    line-height: ${props => props.horizontalLayout ? '13px' : '22px'};
    text-align: ${props => props.horizontalLayout ? 'left' : 'center'};
    width: ${props => props.horizontalLayout ? '100px;' : '100%'};
    padding-left: ${props => props.horizontalLayout ? '10px;' : '0px'};
    align-self: ${props => props.horizontalLayout ? 'center' : 'none'};
  `);