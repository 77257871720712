export const getRoute = (routeTemplate, values) => {
  let result = routeTemplate;
  Object.keys(values).forEach(key => {
    const value = values[key];
    const optionalPattern = new RegExp(`:${key}\\?`, 'g');
    const requiredPattern = new RegExp(`:${key}(?!\\?)`, 'g');
    if (value !== undefined) {
      result = result.replace(optionalPattern, String(value));
      result = result.replace(requiredPattern, String(value));
    } else {
      result = result.replace(optionalPattern, '');
    }
  });
  result = result.replace(/\/:[^/?]+\?/g, '');
  return result;
};