import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { getAudiencePresetRequest, mapAudienceResponse } from '@yieldstreet/platform-kit';
import { CALL_API } from '../middleware/Middleware';
export const RESOLVE_AUDIENCES_ATTEMPT = 'ys/audiences/resolve/ATTEMPT';
export const RESOLVE_AUDIENCES_SUCCESS = 'ys/audiences/resolve/SUCCESS';
export const RESOLVE_AUDIENCES_FAILURE = 'ys/audiences/resolve/FAILURE';
export const resolveAudiences = data => dispatch => {
  const endpoint = '/api/marketplace/audiences/resolve';
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [RESOLVE_AUDIENCES_ATTEMPT, RESOLVE_AUDIENCES_SUCCESS, RESOLVE_AUDIENCES_FAILURE],
      options: {
        method: 'POST',
        data
      },
      getResponse: res => {
        return _objectSpread(_objectSpread({}, mapAudienceResponse(res.data.resolution)), {}, {
          audiences: mapAudienceResponse(res.data.audiences)
        });
      }
    }
  });
};
export const resolvePresetAudiences = preset => dispatch => {
  const payload = getAudiencePresetRequest(preset);
  return dispatch(resolveAudiences(payload));
};