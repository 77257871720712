import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { ContextMenuAlignment } from './ContextMenu.model';
export const ContextContainer = styled.div.withConfig({
  displayName: "ContextMenustyle__ContextContainer",
  componentId: "sc-1nga3ow-0"
})(["position:relative;z-index:2;"]);
export const ContextAction = styled.div.withConfig({
  displayName: "ContextMenustyle__ContextAction",
  componentId: "sc-1nga3ow-1"
})([""]);
export const ContextWindow = styled(motion.div).withConfig({
  displayName: "ContextMenustyle__ContextWindow",
  componentId: "sc-1nga3ow-2"
})(["", " position:absolute;top:100%;margin-top:", ";padding:20px;background:#ffffff;border:1px solid ", ";box-shadow:0 2px 5px -1px rgba(50,50,93,0.2);", ";"], _ref => {
  let {
    maxHeight
  } = _ref;
  return maxHeight && css(["max-height:", "px;overflow-y:auto;"], maxHeight);
}, _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.spacing.xs;
}, props => props.theme.colors.border_default, props => props.align === ContextMenuAlignment.RIGHT ? `
    right: 0;
  ` : `
    left: 0;
  `);
export const ContextWindowAnimation = {
  initial: 'out',
  animate: 'in',
  exit: 'out',
  variants: {
    in: {
      opacity: 1,
      transform: `translateY(0px)`
    },
    out: {
      opacity: 0,
      transform: `translateY(-36px)`,
      transition: {
        opacity: {
          duration: 0.3
        },
        transform: {
          duration: 0.4
        }
      }
    }
  }
};