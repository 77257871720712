import { genTestIds } from '@yieldstreet/tool-kit';
const {
  getTestIds
} = genTestIds(['pageLoader', 'pageSheet', 'nameInfo', 'name', 'mobilePhone', 'addressInfo', 'address', 'status', 'linkLabel', 'link', 'status-third-party']);
export const testIds = getTestIds('information');
export const etcInfo = {
  confirmed: {
    label: 'Verified'
  },
  pending: {
    label: 'Complete ETC setup',
    linkURL: 'https://www.myequity.com/myequity/login'
  }
};