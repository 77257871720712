export const PortfolioRepresentative = {
  success: true,
  representative: {
    id: 1,
    shortName: 'Mel',
    fullName: 'Melanie Alese',
    emailAddress: 'malese@yieldstreet.com',
    phoneNumber: null,
    profileImage: 'melanie',
    calendlyUrl: 'https://calendly.com/malese/vip-investor-15-min-meeting'
  }
};