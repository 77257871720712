import { useEffect } from 'react';
import { useAuth } from '@yieldstreet/tool-kit';
import { useAuthCompleted } from './useAuthCompleted';

// to be used in auth pages to redirect to YS experience
// in only in case the user is already authenticated

export const useAuthCheck = () => {
  const completeAuth = useAuthCompleted();
  const {
    isAlreadyAuthenticated
  } = useAuth();
  useEffect(() => {
    // check whether user is already authenticated
    // only the first time that user state is loaded
    if (isAlreadyAuthenticated) {
      completeAuth();
    }
  }, [completeAuth, isAlreadyAuthenticated]);
};