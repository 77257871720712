import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { useTestIds, StatusTagInfo } from './StatusTag.model';
import { Tag, TagContent, TagLabel, Icon } from './StatusTag.style';

/**
 * @deprecated This component must be deleted and replaced by ActionCell (MUI Chip)
 */
export const StatusTag = props => {
  const {
    status,
    label,
    small,
    showIcon
  } = props;
  const testIds = useTestIds(props);
  return __jsx(Tag, _extends({
    status: status
  }, testIds.base.attr), __jsx(TagContent, null, __jsx(TagLabel, _extends({
    small: small
  }, testIds.label.attr), label), showIcon ? __jsx(Icon, _extends({
    src: StatusTagInfo(status).icon,
    alt: "Status tag icon"
  }, testIds.icon.attr)) : null));
};