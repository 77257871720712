import { PerformanceStatus } from '../../../../enums';
import { InvestmentEngine, InvestmentStatusTypes, InvestmentTypes, TenderStatus } from '../../../../types';
export const portfolioOverviewDebtMatured = {
  userId: 104,
  accountSummary: {
    outstandingPrincipal: 0.0,
    outstandingPrincipalPreDefault: 0.0,
    outstandingPrincipalPostDefault: 0.0,
    accruedInvestmentInterest: 0.0,
    accruedInvestmentInterestPreDefault: 0.0,
    accruedInvestmentInterestPostDefault: 0.0,
    balanceInDefault: 0.0,
    balanceByPerformanceStatus: {
      PERFORMING: 0.0,
      MARINE_DEFAULT: 0.0,
      MODIFIED_OUTLOOK: 0.0,
      PARTIAL_DEFAULT: 0.0,
      DEFAULT: 0.0
    },
    investmentBalance: 0.0,
    pendingInvestments: 0.0,
    availableBalance: 0.0,
    clearedBalance: 0.0,
    pendingDeposits: 0.0,
    reserveBalance: 0,
    total: 0.0,
    weightedInvestmentRate: 0,
    irr: 11.72,
    interestEarnedLast30: 0.0,
    fundsTotal: 0,
    funds: []
  },
  investorEntities: [],
  capital: null,
  interest: {
    beginningAccruedInterest: 0,
    interestEarned: 10277.78,
    compoundInterestEarned: 0.0,
    interestPaid: 10277.78,
    fexpPaid: 150.0,
    fexpBalance: 0,
    netPaid: 10127.78,
    walletInterestPaid: 0.0,
    compoundInterestPaid: 0.0,
    endingAccruedInterest: 0.0,
    interestEarnedPreDefault: 10277.78,
    interestEarnedPostDefault: 0,
    earningsTimeline: [{
      date: '2019-11-30',
      accruedPreDefault: 0,
      accruedPostDefault: 0,
      totalPaid: 0,
      netPaid: 0,
      fexpPaid: 0,
      walletInterestPaid: 0,
      value: 0
    }, {
      date: '2019-12-31',
      accruedPreDefault: 361.11,
      accruedPostDefault: 0.0,
      totalPaid: 0.0,
      netPaid: 0.0,
      fexpPaid: 0.0,
      walletInterestPaid: 0.0,
      value: 361.11
    }, {
      date: '2020-01-31',
      accruedPreDefault: 861.11,
      accruedPostDefault: 0.0,
      totalPaid: 361.11,
      netPaid: 211.11,
      fexpPaid: 150.0,
      walletInterestPaid: 0.0,
      value: 1222.22
    }, {
      date: '2020-02-29',
      accruedPreDefault: 805.56,
      accruedPostDefault: 0.0,
      totalPaid: 1222.22,
      netPaid: 1072.22,
      fexpPaid: 150.0,
      walletInterestPaid: 0.0,
      value: 2027.78
    }, {
      date: '2020-03-31',
      accruedPreDefault: 861.11,
      accruedPostDefault: 0.0,
      totalPaid: 2027.78,
      netPaid: 1877.78,
      fexpPaid: 150.0,
      walletInterestPaid: 0.0,
      value: 2888.89
    }, {
      date: '2020-04-30',
      accruedPreDefault: 833.33,
      accruedPostDefault: 0.0,
      totalPaid: 2888.89,
      netPaid: 2738.89,
      fexpPaid: 150.0,
      walletInterestPaid: 0.0,
      value: 3722.22
    }, {
      date: '2020-05-31',
      accruedPreDefault: 861.11,
      accruedPostDefault: 0.0,
      totalPaid: 3722.22,
      netPaid: 3572.22,
      fexpPaid: 150.0,
      walletInterestPaid: 0.0,
      value: 4583.33
    }, {
      date: '2020-06-30',
      accruedPreDefault: 833.34,
      accruedPostDefault: 0.0,
      totalPaid: 4583.33,
      netPaid: 4433.33,
      fexpPaid: 150.0,
      walletInterestPaid: 0.0,
      value: 5416.67
    }, {
      date: '2020-07-31',
      accruedPreDefault: 861.11,
      accruedPostDefault: 0.0,
      totalPaid: 5416.67,
      netPaid: 5266.67,
      fexpPaid: 150.0,
      walletInterestPaid: 0.0,
      value: 6277.78
    }, {
      date: '2020-08-31',
      accruedPreDefault: 861.11,
      accruedPostDefault: 0.0,
      totalPaid: 6277.78,
      netPaid: 6127.78,
      fexpPaid: 150.0,
      walletInterestPaid: 0.0,
      value: 7138.89
    }, {
      date: '2020-09-30',
      accruedPreDefault: 833.33,
      accruedPostDefault: 0.0,
      totalPaid: 7138.89,
      netPaid: 6988.89,
      fexpPaid: 150.0,
      walletInterestPaid: 0.0,
      value: 7972.22
    }, {
      date: '2020-10-31',
      accruedPreDefault: 861.11,
      accruedPostDefault: 0.0,
      totalPaid: 7972.22,
      netPaid: 7822.22,
      fexpPaid: 150.0,
      walletInterestPaid: 0.0,
      value: 8833.33
    }, {
      date: '2020-11-30',
      accruedPreDefault: 833.34,
      accruedPostDefault: 0.0,
      totalPaid: 8833.33,
      netPaid: 8683.33,
      fexpPaid: 150.0,
      walletInterestPaid: 0.0,
      value: 9666.67
    }, {
      date: '2020-12-31',
      accruedPreDefault: 0.0,
      accruedPostDefault: 0.0,
      totalPaid: 10277.78,
      netPaid: 10057.78,
      fexpPaid: 220.0,
      walletInterestPaid: 0.0,
      value: 10277.78
    }, {
      date: '2021-01-05',
      accruedPreDefault: 0.0,
      accruedPostDefault: 0.0,
      totalPaid: 10277.78,
      netPaid: 10127.78,
      fexpPaid: 150.0,
      walletInterestPaid: 0.0,
      value: 10277.78
    }]
  },
  principal: {
    beginningOutstandingPrincipal: 0,
    totalInvested: 0.0,
    repaidPrincipal: 0.0,
    endingOutstandingPrincipal: 0.0,
    endingOutstandingPrincipalPreDefault: 0.0,
    endingOutstandingPrincipalPostDefault: 0.0,
    liquidationPaid: 0.0,
    liquidationDiscount: 0.0,
    isInDefault: false,
    principalTimeline: [{
      date: '2019-11-30',
      paid: 0,
      outstandingPreDefault: 0,
      outstandingPostDefault: 0,
      principalWriteOff: 0,
      liquidationPaid: 0,
      liquidationDiscount: 0,
      value: 0
    }, {
      date: '2019-12-31',
      paid: 0.0,
      outstandingPreDefault: 100000.0,
      outstandingPostDefault: 0.0,
      principalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 100000.0
    }, {
      date: '2020-01-31',
      paid: 0.0,
      outstandingPreDefault: 100000.0,
      outstandingPostDefault: 0.0,
      principalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 100000.0
    }, {
      date: '2020-02-29',
      paid: 0.0,
      outstandingPreDefault: 100000.0,
      outstandingPostDefault: 0.0,
      principalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 100000.0
    }, {
      date: '2020-03-31',
      paid: 0.0,
      outstandingPreDefault: 100000.0,
      outstandingPostDefault: 0.0,
      principalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 100000.0
    }, {
      date: '2020-04-30',
      paid: 0.0,
      outstandingPreDefault: 100000.0,
      outstandingPostDefault: 0.0,
      principalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 100000.0
    }, {
      date: '2020-05-31',
      paid: 0.0,
      outstandingPreDefault: 100000.0,
      outstandingPostDefault: 0.0,
      principalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 100000.0
    }, {
      date: '2020-06-30',
      paid: 0.0,
      outstandingPreDefault: 100000.0,
      outstandingPostDefault: 0.0,
      principalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 100000.0
    }, {
      date: '2020-07-31',
      paid: 0.0,
      outstandingPreDefault: 100000.0,
      outstandingPostDefault: 0.0,
      principalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 100000.0
    }, {
      date: '2020-08-31',
      paid: 0.0,
      outstandingPreDefault: 100000.0,
      outstandingPostDefault: 0.0,
      principalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 100000.0
    }, {
      date: '2020-09-30',
      paid: 0.0,
      outstandingPreDefault: 100000.0,
      outstandingPostDefault: 0.0,
      principalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 100000.0
    }, {
      date: '2020-10-31',
      paid: 0.0,
      outstandingPreDefault: 100000.0,
      outstandingPostDefault: 0.0,
      principalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 100000.0
    }, {
      date: '2020-11-30',
      paid: 0.0,
      outstandingPreDefault: 100000.0,
      outstandingPostDefault: 0.0,
      principalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 100000.0
    }, {
      date: '2020-12-31',
      paid: 0.0,
      outstandingPreDefault: 0.0,
      outstandingPostDefault: 0.0,
      principalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 0.0
    }, {
      date: '2021-01-05',
      paid: 0.0,
      outstandingPreDefault: 0.0,
      outstandingPostDefault: 0.0,
      principalWriteOff: 0.0,
      liquidationPaid: 0.0,
      liquidationDiscount: 0.0,
      value: 0.0
    }]
  },
  investmentDetail: {
    fundId: null,
    fundType: null,
    noteUrlHash: '8JpTXQ',
    title: 'Post-War and Pop Art Portfolio',
    distributionRate: 0,
    assetClass: 'ARTF',
    assetClassDescription: 'Art',
    paymentType: 'Monthly',
    termPhrase: '13 Mo.',
    advisor: null,
    subAdvisor: null,
    interestRate: 10.0,
    taxDocument: 'K1',
    firstYearExpense: 150,
    annualFlatExpense: 70,
    irr: 10.35,
    investedDate: '2019-12-15',
    activeDate: '2019-12-18',
    launchedDate: '2019-12-12',
    terminationDate: '2020-09-30',
    interestDefaultedDate: null,
    principalDefaultedDate: null,
    dateOfFirstDefault: null,
    originator: 'Athena Art Finance Corp.',
    dividendDistributionOption: null,
    canToggleDistributionOption: null,
    performanceStatus: PerformanceStatus.PERFORMING,
    performanceStatusNote: 'The underlying art loan, which was scheduled to mature on 10/30/20, will likely be extended further as the Originator, Athena, continues to make progress to refinance the loan. This art loan is expected to continue to pay a 10% target yield to investors. Apart from the maturity extension, all other initial terms of the loan remain unchanged.',
    spvType: '3C1',
    offeringData: {},
    investments: [{
      type: InvestmentTypes.Investment,
      dateInvested: '2019-12-15',
      dateActive: '2019-12-18',
      shareCount: 0,
      sharePrice: 0,
      status: InvestmentStatusTypes.Matured,
      amount: 100000.0
    }],
    tenderOfferStatus: TenderStatus.UNAVAILABLE,
    tenderOfferUserEligible: false,
    tenderOfferId: null,
    totalInvestments: 100000.0,
    totalRepaid: 10277.78,
    netTotalRepaid: 10127.78,
    fundProvider: null,
    engine: InvestmentEngine.Pflex
  },
  investmentGatesDetail: {
    requiresUserAttention: false,
    gates: []
  },
  wallets: [],
  investmentStrategies: []
};