var __jsx = React.createElement;
import React from 'react';
import { SurfaceType } from './Surface.model';
import { SurfaceWrapper } from './Surface.style';

// FIX ME: convert to mui paper and move to core
export const Surface = _ref => {
  let {
    children,
    className,
    small = false,
    type = SurfaceType.Default,
    testId,
    withPadding = true,
    noBorder = false
  } = _ref;
  return __jsx(SurfaceWrapper, {
    className: className,
    "data-cy": testId,
    small: small,
    type: type,
    withPadding: withPadding,
    noBorder: noBorder
  }, children);
};