var __jsx = React.createElement;
import React from 'react';
import { DesktopOnly, MobileOnly } from '../../../utils/styles/devices';
import { DropdownMenu } from '../../../elements/dropdown-menu';
import { Link } from '../../../content';
import { PageSheetActionsDesktopWrapper, PageSheetActionsMobileWrapper } from './PageSheetActions.style';
export const PageSheetActionsDesktop = _ref => {
  let {
    actions = []
  } = _ref;
  return __jsx(PageSheetActionsDesktopWrapper, null, actions.map(action => __jsx(Link, {
    onClick: action.onClick
  }, action.label)));
};
export const PageSheetActionsMobile = _ref2 => {
  let {
    actions = []
  } = _ref2;
  return __jsx(PageSheetActionsMobileWrapper, null, __jsx(DropdownMenu, {
    options: actions
  }));
};
export const PageSheetActions = _ref3 => {
  let {
    actions
  } = _ref3;
  return __jsx("div", {
    "data-cy": "page-sheet-actions"
  }, __jsx(MobileOnly, null, __jsx(PageSheetActionsMobile, {
    actions: actions
  })), __jsx(DesktopOnly, null, __jsx(PageSheetActionsDesktop, {
    actions: actions
  })));
};