import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useMemo } from 'react';
import { FormSelectInput } from '@yieldstreet/ui-kit';
import trashIcon from 'assets/icons/images/trash_black.svg';
import { getCitizenshipOptions } from './CitizenshipSelector.util';
import { SelectWrapper } from '../Citizenship.style';
import { testIds } from '../Citizenship.model';
import { DeleteButtonWrapper, DeleteButton, InputWrapper, Icon } from '../../Affiliation/screens/ControlCorporations/ControlCorporations.style';

/**
 * This component is used to render the select input for the citizenship countries.
 * It checks what other options are selected and excludes that country from the list.
 */
export const CitizenshipSelector = _ref => {
  let {
    formikProps,
    index,
    remove
  } = _ref;
  const citizenshipOptions = useMemo(() => getCitizenshipOptions({
    formikProps,
    index
  }), [formikProps, index]);
  return __jsx(InputWrapper, {
    key: `select-input-wrapper-${index}`
  }, __jsx(SelectWrapper, null, __jsx(FormSelectInput, {
    key: `select-input-${index}`,
    label: "Country of citizenship",
    placeholder: "Select a country",
    name: `citizenshipCountries.${index}.country`,
    formikProps: formikProps,
    options: citizenshipOptions,
    testId: testIds.citizenshipCountryField.id,
    fullWidth: true
  })), index > 0 && __jsx(DeleteButtonWrapper, null, __jsx(DeleteButton, _extends({
    key: `button-${index}`,
    onClick: event => {
      event.preventDefault();
      remove(index);
    }
  }, testIds.deleteButton.attr), __jsx(Icon, {
    src: trashIcon
  }))));
};