import styled from 'styled-components';
import { Heading } from '../../content';
export const TextBoxWrapper = styled.div.withConfig({
  displayName: "TextBoxstyle__TextBoxWrapper",
  componentId: "sc-12rvvcf-0"
})(["background-color:", ";border:1px solid ", ";border-radius:4px;padding:", ";"], _ref => {
  let {
    theme
  } = _ref;
  return theme.colors.background_light;
}, _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.colors.border_default;
}, _ref3 => {
  let {
    theme
  } = _ref3;
  return theme.spacing.ml;
});
export const SHeading = styled(Heading).withConfig({
  displayName: "TextBoxstyle__SHeading",
  componentId: "sc-12rvvcf-1"
})(["margin:", " 0 ", ";"], _ref4 => {
  let {
    theme
  } = _ref4;
  return theme.spacing.sm;
}, _ref5 => {
  let {
    theme
  } = _ref5;
  return theme.spacing.xs;
});