import { useCallback, useEffect, useState } from 'react';
import isEqual from "lodash/isEqual";
import { trackMessageAction, trackMessageDismiss, trackMessageView } from './usePlatformPromo.util';
export const usePlatformPromo = _ref => {
  let {
    messages = [],
    dismissMessages
  } = _ref;
  const {
    0: displayedMessage,
    1: setDisplayedMessage
  } = useState(messages[0]);
  useEffect(() => {
    const newMessage = messages[0];
    if (!isEqual(displayedMessage, newMessage)) {
      setDisplayedMessage(newMessage);
    }
  }, [messages, displayedMessage]);
  useEffect(() => {
    trackMessageView(displayedMessage);
  }, [displayedMessage]);
  const trackAction = useCallback(cta => {
    trackMessageAction(displayedMessage, cta);
  }, [displayedMessage]);
  const dismiss = useCallback(() => {
    dismissMessages([displayedMessage.messageSpecId], true);
    trackMessageDismiss(displayedMessage);
  }, [displayedMessage, dismissMessages]);
  return {
    displayedMessage,
    dismiss,
    trackAction
  };
};