import { useCallback, useEffect, useMemo } from 'react';
import { marketplaceApi, mapAudienceResponse } from '@yieldstreet/platform-kit';
export const useAudiences = AudiencePresetType => {
  const [getResolveAudiences, getResolveAudiencesResult] = marketplaceApi.useLazyGetResolveAudiencesQuery();
  const {
    data: audiencesData,
    isLoading,
    isFetching,
    isUninitialized,
    isError,
    isSuccess
  } = getResolveAudiencesResult;
  useEffect(() => {
    if (!audiencesData && !isLoading && !isError) {
      getResolveAudiences(AudiencePresetType, true);
    }
  }, [AudiencePresetType, audiencesData, getResolveAudiences, isError, isLoading]);
  const audiencesResolution = useMemo(() => {
    if (audiencesData) {
      return mapAudienceResponse(audiencesData.resolution);
    }
    return {};
  }, [audiencesData]);
  const refetchAudiences = useCallback(() => {
    getResolveAudiences(AudiencePresetType, false);
  }, [AudiencePresetType, getResolveAudiences]);
  return {
    audiencesData,
    audiencesResolution,
    audiencesIsLoading: isLoading || isUninitialized || isFetching,
    audiencesIsError: isError,
    audiencesIsSuccess: isSuccess,
    refetchAudiences
  };
};