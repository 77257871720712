var __jsx = React.createElement;
import React from 'react';
import { FlagsContext } from '@yieldstreet/tool-kit';
import { useLDWebFlagsContext, useLDWebIdentify } from '@yieldstreet/tool-kit/src/flags/vendor/launch-darkly/web';
import { useFlagsContext } from './useFlagsContext';
export const FlagsContextWrapper = _ref => {
  let {
    children
  } = _ref;
  const context = useFlagsContext();
  useLDWebIdentify(context);
  const flagsContext = useLDWebFlagsContext();
  return __jsx(FlagsContext.Provider, {
    value: flagsContext
  }, children);
};