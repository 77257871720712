import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
export const useBreakpoints = () => {
  const theme = useTheme();
  return {
    isXsScreen: useMediaQuery(theme.breakpoints.down('sm')),
    isSmScreen: useMediaQuery(theme.breakpoints.between('sm', 'md')),
    isMdScreen: useMediaQuery(theme.breakpoints.between('md', 'lg')),
    isLgScreen: useMediaQuery(theme.breakpoints.between('lg', 'xl')),
    isXlScreen: useMediaQuery(theme.breakpoints.up('xl'))
  };
};