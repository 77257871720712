import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { HttpMethods } from '../../enums';
import { restApi, Tags } from '../common';
export const penscoServiceApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.PenscoServiceFunds]
}).injectEndpoints({
  endpoints: build => {
    const getPenscoServiceFunds = build.query({
      query: _ref => {
        let {
          page,
          pageSize
        } = _ref;
        return {
          url: `/pensco-service/account/funding`,
          method: HttpMethods.Get,
          params: {
            page,
            pageSize
          }
        };
      },
      providesTags: [Tags.PenscoServiceFunds],
      transformResponse: _ref2 => {
        let {
          result
        } = _ref2;
        return _objectSpread({}, result);
      }
    });
    return {
      getPenscoServiceFunds
    };
  }
});