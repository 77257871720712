var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import Security from 'assets/images/images/security.svg';
const SecurityLogo = () => __jsx(Icon, {
  src: Security
});
const Icon = styled.img.withConfig({
  displayName: "SecurityLogo__Icon",
  componentId: "sc-1prn31l-0"
})(["display:block;margin:auto;margin-top:40px;"]);
export default SecurityLogo;