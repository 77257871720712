var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { media } from '../../themes/media';
import { TextBlock } from '../../sections/text-block-component';
import { SectionContainer } from '../../layout';
const handleImagePosition = position => {
  switch (position) {
    case 'oversize':
      return '-80px -49px';
    case 'expanded':
      return '-40px 0';
    case 'fit':
      return '0 0';
    case 'contained':
      return '64px';
    default:
      return '64px';
  }
};
export const MarketingContentBanner = _ref => {
  let {
    image,
    reverse,
    inverse,
    label,
    heading,
    content,
    button,
    mobileAlign = 'start',
    imagePosition = 'contained',
    transparent
  } = _ref;
  return __jsx(SectionContainer, null, __jsx(Background, {
    reverse: reverse,
    inverse: inverse,
    transparent: transparent
  }, __jsx(TextContainer, null, __jsx(TextBlock, {
    label: label,
    heading: heading,
    content: content,
    align: "start",
    mobileAlign: mobileAlign,
    inverse: inverse,
    button: button
  })), image ? __jsx(ImageContainer, {
    imagePosition: imagePosition
  }, __jsx(MainImage, null, image)) : null));
};
const Background = styled.div.withConfig({
  displayName: "MarketingContentBanner__Background",
  componentId: "sc-1em8c30-0"
})(["display:flex;flex-direction:column-reverse;background:", ";", " padding:20px 0px 40px 0px;", ""], props => props.inverse ? props.theme.gradients.radial_secondary : props.theme.colors.background_light, props => props.transparent && 'background: transparent;', media.desktop`
    flex-direction: ${props => props.reverse ? 'row-reverse' : 'row'};
    justify-content: space-evenly;;
    border-radius: 8px;
    align-items: center;
    padding: 0;
  `);
const TextContainer = styled.div.withConfig({
  displayName: "MarketingContentBanner__TextContainer",
  componentId: "sc-1em8c30-1"
})(["margin:24px 0 0 0;padding:0px 24px 0 24px;", ""], media.desktop`
    margin: 64px;
    width: 410px;
    padding: 0px;
  `);
const ImageContainer = styled.div.withConfig({
  displayName: "MarketingContentBanner__ImageContainer",
  componentId: "sc-1em8c30-2"
})(["padding:0 24px;position:relative;margin:auto;display:flex;align-items:center;", ""], media.desktop`
    margin: ${_ref2 => {
  let {
    imagePosition
  } = _ref2;
  return handleImagePosition(imagePosition);
}}
  `);
const MainImage = styled.div.withConfig({
  displayName: "MarketingContentBanner__MainImage",
  componentId: "sc-1em8c30-3"
})(["video{width:100%;}img{width:100%;}", ""], media.desktop`
    img{
      width: auto;
    }
    video {
      width: auto;
    }
  `);