import forIn from 'lodash/forIn';
import isPlainObject from 'lodash/isPlainObject';
import { serializeObject } from '../utils';
const getStateChanges = (prevState, nextState) => {
  const stateBefore = {};
  const stateAfter = {};
  forIn(nextState, (nextValue, key) => {
    const prevValue = prevState[key];
    if (nextValue !== prevValue) {
      if (isPlainObject(prevValue) && isPlainObject(nextValue)) {
        const {
          stateBefore: beforeValue,
          stateAfter: afterValue
        } = getStateChanges(prevValue, nextValue);
        stateBefore[key] = beforeValue;
        stateAfter[key] = afterValue;
      } else {
        stateBefore[key] = prevValue;
        stateAfter[key] = nextValue;
      }
    }
  });
  return {
    stateBefore,
    stateAfter
  };
};
export const traceMiddleware = (tracer, anonymizer) => store => next => action => {
  const {
    type,
    span: parentSpan
  } = action;
  const result = tracer.trace(span => {
    const prevState = store.getState();
    const result = next(action);
    const nextState = store.getState();
    const {
      stateBefore,
      stateAfter
    } = getStateChanges(prevState, nextState);
    const stateDiff = {
      stateBefore: anonymizer ? anonymizer(stateBefore) : stateBefore,
      stateAfter: anonymizer ? anonymizer(stateAfter) : stateAfter
    };
    span.setAttributes({
      stateBefore: serializeObject(stateDiff.stateBefore),
      stateAfter: serializeObject(stateDiff.stateAfter)
    });
    return result;
  }, 'dispatch', {
    type
  }, parentSpan);
  return result;
};