var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { media } from '../../themes/media';
import Background from '../../assets/graphics/backgrounds/info-hero-background.svg';
import { Heading, Paragraph } from '../../content/text';
import { PageSection } from '../../layout/page-section';
import { ButtonContainer } from '../../content/button';
export const InfoHeroBanner = props => {
  const {
    title,
    boldTitle,
    subtitle,
    bottomTitle,
    inverse,
    bottomContentLeft,
    bottomContentRight,
    mainImage,
    splashImage,
    imageBorder,
    cta,
    className
  } = props;
  return __jsx(BannerHolder, {
    className: className
  }, __jsx(PageSection, null, __jsx(Container, null, __jsx(TextContainer, {
    className: "text-container"
  }, __jsx(Headline, {
    type: 1,
    inverse: inverse
  }, title, " ", boldTitle && __jsx("strong", null, boldTitle)), __jsx(Content, null, subtitle, __jsx(ButtonContainer, null, cta))), __jsx(RightContainer, null, __jsx(ImageContainer, null, __jsx(MainImage, {
    imageBorder: imageBorder
  }, __jsx(PlusBackground, null), mainImage))))), __jsx(BottomContent, {
    className: "bottom-content-container"
  }, __jsx(PageSection, null, __jsx(BottomContainer, null, __jsx(SplashImage, null, splashImage), __jsx(SubHeadline, {
    type: 4,
    inverse: true
  }, bottomTitle), __jsx(BottomTextContainer, null, __jsx(BottomText, null, bottomContentLeft), __jsx(BottomText, null, bottomContentRight))))));
};
const BannerHolder = styled.div.withConfig({
  displayName: "InfoHeroBanner__BannerHolder",
  componentId: "sc-1aaf4bo-0"
})(["position:relative;"]);
const PlusBackground = styled.div.withConfig({
  displayName: "InfoHeroBanner__PlusBackground",
  componentId: "sc-1aaf4bo-1"
})(["position:absolute;width:200px;height:100%;left:-150px;z-index:10;display:none;", ""], media.desktop`
    display: block;
    background: url('${Background}') no-repeat 50% 73%;
  `);
const Container = styled.div.withConfig({
  displayName: "InfoHeroBanner__Container",
  componentId: "sc-1aaf4bo-2"
})(["display:flex;flex-wrap:wrap;min-height:468px;position:relative;", " padding:0 0 40px 0;"], media.desktop`
    min-height: 200px;
  `);
const TextContainer = styled.div.withConfig({
  displayName: "InfoHeroBanner__TextContainer",
  componentId: "sc-1aaf4bo-3"
})(["width:100%;top:50%;transform:translateY(-50%);position:absolute;", ""], media.desktop`
    position: unset;
    transform: unset;
    top: auto;
    width: 60%;
    padding: 0 40px 0 110px;
  `);
const Headline = styled(Heading).withConfig({
  displayName: "InfoHeroBanner__Headline",
  componentId: "sc-1aaf4bo-4"
})(["margin:20px 0 20px 20px;"]);
const Content = styled(Paragraph).withConfig({
  displayName: "InfoHeroBanner__Content",
  componentId: "sc-1aaf4bo-5"
})(["margin:0 20px 20px 20px;"]);
const RightContainer = styled.div.withConfig({
  displayName: "InfoHeroBanner__RightContainer",
  componentId: "sc-1aaf4bo-6"
})(["text-align:center;width:100%;", ""], media.desktop`
    margin: 20px 0 0 0;
    width: 40%; 
    padding: 0 60px;
  `);
const ImageContainer = styled.div.withConfig({
  displayName: "InfoHeroBanner__ImageContainer",
  componentId: "sc-1aaf4bo-7"
})(["", " margin:0 20px;", ""], media.desktop`
    position: relative;
  `, media.desktop`
    margin: 0;
  `);
const MainImage = styled.div.withConfig({
  displayName: "InfoHeroBanner__MainImage",
  componentId: "sc-1aaf4bo-8"
})(["position:absolute;top:0;left:0;z-index:-1;width:100%;height:100%;", " img{width:100%;height:100%;object-fit:cover;", "}"], media.desktop`
    right: 0;
    z-index: 1;
    height: auto;
  `, media.desktop`
      maxWidth: 467
      width: auto;
      ${props => props.imageBorder ? `border-bottom: 10px solid ${props.theme.colors.accent_strong};` : ''};
    `);
const BottomContent = styled.div.withConfig({
  displayName: "InfoHeroBanner__BottomContent",
  componentId: "sc-1aaf4bo-9"
})(["background:", ";min-height:338px;padding:0;", ""], props => props.theme.colors.background_inverse, media.desktop`
    padding: 80px 0 66px 0;
  `);
const SplashImage = styled.div.withConfig({
  displayName: "InfoHeroBanner__SplashImage",
  componentId: "sc-1aaf4bo-10"
})(["width:114px;height:114px;img{width:114px;height:114px;padding:20px;vertical-align:middle;text-align:center;}position:absolute;background:", ";right:20px;top:-57px;", ""], props => props.theme.colors.background_inverse, media.desktop`
    top: -124px;
    right: auto;
  `);
const BottomContainer = styled.div.withConfig({
  displayName: "InfoHeroBanner__BottomContainer",
  componentId: "sc-1aaf4bo-11"
})(["position:relative;padding:20px;width:100%;", ""], media.desktop`
    padding: 0 40px 0 130px;
    width: calc(100% - 540px);
  `);
const SubHeadline = styled(Heading).withConfig({
  displayName: "InfoHeroBanner__SubHeadline",
  componentId: "sc-1aaf4bo-12"
})(["margin:20px 0;"]);
const BottomTextContainer = styled.div.withConfig({
  displayName: "InfoHeroBanner__BottomTextContainer",
  componentId: "sc-1aaf4bo-13"
})(["display:flex;flex-direction:column;", ""], media.desktop`
    flex-direction: row;
  `);
const BottomText = styled.div.withConfig({
  displayName: "InfoHeroBanner__BottomText",
  componentId: "sc-1aaf4bo-14"
})(["width:100%;p{padding-bottom:10px;}", ""], media.desktop`
    width: 50%;
    &:not(:last-child) {
      padding-right: 50px;
    }
  `);