import { createListenerMiddleware } from '@reduxjs/toolkit';
export const testListenerMiddleware = createListenerMiddleware();
testListenerMiddleware.startListening({
  effect: (action, _listenerApi) => {
    // eslint-disable-next-line no-console
    console.log('[ACTION]::', JSON.stringify(action));
  },
  predicate: () => {
    return true;
  }
});