var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { Paragraph } from '../../content/text';
export const NotificationBanner = _ref => {
  let {
    children
  } = _ref;
  return __jsx(Slogan, null, __jsx(SloganText, {
    inverse: true
  }, children));
};
const Slogan = styled.div.withConfig({
  displayName: "NotificationBanner__Slogan",
  componentId: "sc-c55ao2-0"
})(["background:", ";text-align:center;width:100%;padding:10px 20px;"], props => props.theme.colors.chart_3);
const SloganText = styled(Paragraph).withConfig({
  displayName: "NotificationBanner__SloganText",
  componentId: "sc-c55ao2-1"
})(["line-height:1.5;vertical-align:middle;display:inline-block;"]);