import { useMemo } from 'react';
import { getInvestorEntityAction } from './useInvestorEntityAction';
export const getInvestorEntityNextAction = (investorEntity, investorEntityStatus, action) => {
  if (investorEntity && investorEntityStatus !== null && investorEntityStatus !== void 0 && investorEntityStatus.investorEntityAction) {
    const {
      investorEntityAction: {
        code
      }
    } = investorEntityStatus;
    return getInvestorEntityAction(code, investorEntity, investorEntityStatus, action);
  }
  return null;
};
export const useInvestorEntityNextAction = (investorEntity, investorEntityStatus, action) => {
  return useMemo(() => getInvestorEntityNextAction(investorEntity, investorEntityStatus, action), [investorEntity, investorEntityStatus, action]);
};