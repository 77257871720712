import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { radius } from './radius';
import { spacing } from './spacing';
export const palette = {
  black: '#1A1B1D',
  lightBlack: '#101014',
  arsenic: '#3E4046',
  white: '#FFFFFF',
  teal: '#00E6E6',
  lightTeal: '#96FFF0',
  lightTealAlt: '#abf7e4',
  aquamarine: '#71F0D9',
  aeroBlue: '#CCFFFF',
  frostedMint: '#E1FFF5',
  peach: '#FFDDC9',
  lightPeach: '#FFEFE6',
  strongPeach: '#FACDB9',
  gray1: '#F1F3F6',
  gray2: '#D8DCE2',
  gray3: '#B1B4B9',
  gray4: '#888C93',
  gray5: '#5E6268',
  gray6: '#3E3E40',
  lightGray: '#F7F8F9',
  darkGray: '#D9D9D9',
  deepOcean: '#005378',
  deepBlue: '#003840',
  lightDeepBlue: '#DBEDFE',
  // deepBlue at 10%
  lightBlue: '#31A8DB',
  blue: '#4BA3FB',
  bondiBLue: '#00A7B4',
  green: '#19C99A',
  darkGreen: '#0F5C4E',
  lightGreen: '#F1F9F1',
  // green at 10%
  salmon: '#F39487',
  paleYellow: '#F8F89C',
  chardonnay: '#FFBE83',
  atoll: '#09A1AD',
  lavenderPurple: '#9B83BA',
  wildRice: '#E9E980',
  //color name provided by http://chir.ag/projects/name-that-color/
  macAndCheese: '#FDC572',
  oxfordBlue: '#3C4757',
  egg: '#EFDB26',
  eden: '#105C4E',
  red: '#FA4D56',
  successGreen: '#7FC87D',
  warningYellow: '#FFFFAA',
  errorRed: '#F36A6A',
  lightRed: '#FDECEC',
  // errorRed at 10%
  chathamsBlue: '#0F477F',
  summerGreen: '#8BBAA1',
  // chart_11
  sanMarino: '#4870AB',
  // chart_12
  riptide: '#8BE6BA',
  // chart_13

  successDark: '#041B00',
  successMain: '#0C4A00',
  successLight: '#D6E5D2',
  warningDark: '#452D00',
  warningMain: '#E25C24',
  warningLight: '#FFECA9',
  errorDark: '#410E0B',
  errorMain: '#B3261E',
  errorLight: '#F9DEDC',
  infoDark: '#043449',
  infoMain: '#1EA1D9',
  infoLight: '#DCF3FD',
  // New theme colors
  'midnight_green-400': '#335859',
  'park_green-700': '#013536',
  'park_green-500': '#014a4c',
  'park_green-50': '#e6eded'
};
export const theme2 = {
  name: 'Brand Accelerate',
  version: 2,
  typography: {
    primary: 'Linik Sans, Arial, sans-serif',
    secondary: 'Linik Sans, Arial, sans-serif'
  },
  colors: {
    // Misc.

    active: palette.teal,
    active_alt: palette.lightTealAlt,
    // Text Colors

    text_background_inverse: palette.white,
    text_error_dark: palette.errorDark,
    text_error_main: palette.errorMain,
    text_header_inverse: palette.white,
    text_header: palette.black,
    text_info_dark: palette.infoDark,
    text_info_main: palette.infoMain,
    text_label_background_inverse: palette.gray4,
    text_label_inverse: palette.gray3,
    text_label: palette.gray5,
    text_link_background_inverse: palette.white,
    text_link_inverse: palette.white,
    text_link: palette.black,
    text_paragraph_inverse: palette.white,
    text_paragraph: palette.black,
    text_secondary_paragraph_inverse: palette.gray2,
    text_secondary_paragraph: palette.gray6,
    text_success_dark: palette.successDark,
    text_success_main: palette.successMain,
    text_warning_dark: palette.warningDark,
    text_warning_main: palette.warningMain,
    // Backgrounds

    background_card: palette.white,
    background_default: palette.white,
    background_error: palette.errorLight,
    background_hero_shadow: palette.gray3,
    background_highlight: palette.frostedMint,
    background_info: palette.infoLight,
    background_inverse_light: palette.lightBlack,
    background_inverse_secondary: palette.deepBlue,
    background_inverse: palette.black,
    background_light: palette.lightGray,
    background_page: palette.gray1,
    background_progress_bar: palette.lightGray,
    background_success: palette.successLight,
    background_warning: palette.warningLight,
    // Borders

    border_inverse: palette.white,
    border_default: palette.gray2,
    border_strong: palette.gray4,
    border_strong_alt: palette.gray5,
    border_outline: palette.black,
    border_extra_strong: palette.gray6,
    // Notifications

    notice_success: palette.successGreen,
    notice_warning: palette.warningYellow,
    notice_warning_background: palette.paleYellow,
    notice_warning_icon: palette.wildRice,
    notice_risk: palette.macAndCheese,
    notice_error: palette.errorRed,
    // Tables

    table_row_background: palette.white,
    table_row_background_alt: palette.gray1,
    // Inputs

    input_button_background: palette.lightTealAlt,
    input_button_background_hover: palette.aquamarine,
    input_button_background_active: palette.aeroBlue,
    input_button_text: palette.eden,
    //Secondary
    input_button_secondary_background: 'transparent',
    input_button_secondary_background_hover: palette.gray2,
    input_button_secondary_background_active: palette.black,
    input_button_secondary_text: palette.black,
    input_button_secondary_inverse_background_hover: palette.gray5,
    input_button_secondary_inverse_background_active: palette.white,
    input_button_secondary_inverse_text: palette.white,
    //Basic
    input_button_basic_background: palette.black,
    input_button_basic_background_hover: palette.gray6,
    input_button_basic_background_active: palette.gray5,
    input_button_basic_text: palette.white,
    input_button_basic_inverse_background: palette.white,
    input_button_basic_inverse_background_hover: palette.gray2,
    input_button_basic_inverse_background_active: palette.gray1,
    input_button_basic_inverse_text: palette.black,
    input_text: palette.eden,
    input_text_selected: palette.white,
    input_disabled_text: palette.gray4,
    input_disabled_background: palette.gray2,
    input_background: palette.white,
    input_background_selected: palette.black,
    // Graphics

    icon_default: palette.black,
    icon_inverse: palette.white,
    accent_strong: palette.teal,
    accent_medium: palette.lightTeal,
    accent_light: palette.frostedMint,
    label_1: palette.lightGreen,
    label_2: palette.lightRed,
    label_3: palette.lightDeepBlue,
    label_4: palette.oxfordBlue,
    // Charts

    chart_1: palette.lightTeal,
    chart_2: palette.deepBlue,
    chart_3: palette.blue,
    chart_4: palette.green,
    chart_5: palette.salmon,
    chart_6: palette.paleYellow,
    chart_7: palette.chardonnay,
    chart_8: palette.atoll,
    chart_9: palette.lavenderPurple,
    chart_10: palette.chathamsBlue,
    chart_11: palette.summerGreen,
    chart_12: palette.sanMarino,
    chart_13: palette.riptide,
    chart_14: palette.white,
    chart_15: palette.egg,
    chart_600: palette.bondiBLue,
    chart_700: palette.infoMain,
    chart_800: palette.deepOcean,
    // Navigation

    input_button_nav_background: palette.gray6,
    input_button_nav_background_hover: palette.gray5,
    input_button_nav_background_active: palette.black,
    // Action items indicator

    action_indicator_background: palette.lightRed,
    action_indicator_color: palette.black,
    // Badges
    badge_personal: palette.lightTealAlt,
    badge_personal_text: palette.darkGreen,
    badge_regular: palette.darkGray,
    // Tags
    tag_default_background_color: palette.gray1,
    tag_default_text_color: palette.black,
    tag_default_border_color: palette.gray2,
    // Icons
    icon_personal: palette.green,
    icon_regular: palette.lightBlue,
    icon_important: palette.red,
    // New theme colors for components
    progress_bar_primary: palette['midnight_green-400'],
    footer_primary: palette['park_green-700'],
    background_park_green_500: palette['park_green-500'],
    background_park_green_50: palette['park_green-50']
  },
  shadows: {
    shadow_default: '0px 4px 8px rgba(0, 0, 0, 0.06)',
    shadow_hover: '0px 6px 10px rgba(0, 0, 0, 0.12)',
    shadow_active: '0px 2px 6px rgba(0, 0, 0, 0.04)',
    shadow_hover_card: '0px 4px 8px rgb(0 0 0 / 0.2)',
    elevation_1: '0px 2px 5px -1px rgba(50, 50, 93, 0.2)',
    elevation_2: '0px 3px 7px -3px rgba(0, 0, 0, 0.12), 0px 6px 12px -2px rgba(50, 50, 93, 0.2)',
    elevation_3: '0px 8px 16px -8px rgba(0, 0, 0, 0.25), 0px 13px 27px -5px rgba(50, 50, 93, 0.2)',
    elevation_4: '0px 18px 36px -18px rgba(0, 0, 0, 0.25), 0px 30px 60px -12px rgba(50, 50, 93, 0.2)',
    elevation_5: '0px 30px 60px -30px rgba(0, 0, 0, 0.25), 0px 50px 100px -20px rgba(50, 50, 93, 0.2)'
  },
  gradients: {
    radial_primary: 'radial-gradient(94.87% 94.87% at 47.4% 94.87%, #2D3441 0%, #1A1B1D 100%)',
    radial_secondary: 'radial-gradient(94.87% 94.87% at 47.4% 94.87%, #102d39 0%, #1a1b1d 100%)'
  },
  grid: {
    size: 12,
    gutter: {
      mobile: 10,
      tablet: 20,
      desktop: 20
    },
    spacing: 0
  },
  spacing: _objectSpread({}, spacing),
  radius: _objectSpread({}, radius)
};