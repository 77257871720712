import _Box from "@mui/material/Box";
import _Typography from "@mui/material/Typography";
import _Button from "@mui/material/Button";
var __jsx = React.createElement;
import React from 'react';
import { useOfferingPromotion } from '@yieldstreet/tool-kit';
import { PromoBanner } from '../PromoBanner';
import Image from './assets/free-shares-banner.png';
const FreeInvestmentBanner = props => {
  var _promoDetails$config;
  const {
    urlHash,
    promoKey,
    investmentTitle,
    investmentAsset
  } = props;
  const promoState = useOfferingPromotion(urlHash, promoKey);
  const {
    isFreeInvestmentPromo,
    promoOfferingDetails,
    promoDetails
  } = promoState;
  const promoAmount = promoDetails === null || promoDetails === void 0 ? void 0 : (_promoDetails$config = promoDetails.config) === null || _promoDetails$config === void 0 ? void 0 : _promoDetails$config.investmentAmountValue;
  if (!isFreeInvestmentPromo || !promoAmount) {
    return null;
  }
  return promoOfferingDetails !== null && promoOfferingDetails !== void 0 && promoOfferingDetails.maximumInvestmentCount ? __jsx(_Box, {
    "data-cy": "free-investment-cap-banner",
    sx: {
      mb: 16
    }
  }, __jsx(PromoBanner, {
    cta: __jsx("a", {
      href: "/reit-free-shares-promo"
    }, __jsx(_Button, null, "Unlock your shares")),
    image: Image,
    imageFull: Image,
    content: __jsx(React.Fragment, null, __jsx(_Typography, {
      sx: {
        mb: 3
      },
      variant: "h4"
    }, "Your first investment \u2014 on us"), __jsx(_Typography, {
      variant: "body1"
    }, "For a limited time, unlock $", promoAmount, " in our ", investmentTitle, ". Promotion limited to ", promoOfferingDetails.maximumInvestmentCount, " investors."))
  })) : __jsx(_Box, {
    "data-cy": "free-investment-banner",
    sx: {
      mb: 16
    }
  }, __jsx(PromoBanner, {
    cta: __jsx("a", {
      href: "/reit-free-shares-promo"
    }, __jsx(_Button, null, "Unlock your shares")),
    image: Image,
    imageFull: Image,
    content: __jsx(React.Fragment, null, __jsx(_Typography, {
      sx: {
        mb: 3
      },
      variant: "h4"
    }, "Your first investment \u2014 on us. ", __jsx("br", null), "Unlock $", promoAmount, " in our ", investmentTitle, "."), __jsx(_Typography, {
      variant: "body1"
    }, "Add ", investmentAsset, " to your portfolio in minutes."))
  }));
};
export default FreeInvestmentBanner;