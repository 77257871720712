import { HttpMethods } from '../../enums/http';
import { Tags, restApi } from '../common';
const BASE_URL = '/a/api/note';
export const noteApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.NoteDocuments, Tags.NoteTenderOfferConfig]
}).injectEndpoints({
  endpoints: builder => {
    const getNoteDocuments = builder.query({
      query: _ref => {
        let {
          urlHash
        } = _ref;
        return {
          url: `${BASE_URL}/documents/${urlHash}`,
          method: HttpMethods.Get
        };
      },
      transformResponse: _ref2 => {
        let {
          documents
        } = _ref2;
        return {
          documents
        };
      },
      providesTags: [Tags.NoteDocuments]
    });
    const getTenderOfferConfig = builder.query({
      query: _ref3 => {
        let {
          urlHash
        } = _ref3;
        return {
          url: `${BASE_URL}/${urlHash}/tender-offer-config`,
          method: HttpMethods.Get
        };
      },
      transformResponse: res => res === null || res === void 0 ? void 0 : res.config,
      providesTags: [Tags.NoteTenderOfferConfig]
    });
    return {
      getNoteDocuments,
      getTenderOfferConfig
    };
  }
});