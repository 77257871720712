import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { PortfolioFilters } from '@yieldstreet/platform-kit';
import { getInvestorAccountId } from '@yieldstreet/tool-kit';
import { useQuery } from 'hooks';
import { paginationParams } from './usePortfolioFilters.model';
export const usePortfolioFilters = _ref => {
  let {
    filterKeys,
    managementStrategy
  } = _ref;
  const {
    investorId
  } = useParams();
  const {
    navigateWithQuery,
    queryParams,
    removeQueryParams,
    setQueryParams,
    urlSearchParams
  } = useQuery(filterKeys);
  const filters = useMemo(() => {
    const params = {};
    for (const param in queryParams) {
      const currentParam = queryParams[param];
      params[param] = currentParam ? currentParam : PortfolioFilters.ALL;
    }
    return _objectSpread(_objectSpread({
      investorId: getInvestorAccountId(investorId, managementStrategy)
    }, params), paginationParams);
  }, [investorId, managementStrategy, queryParams]);
  const handleFilterChange = useCallback((key, value) => {
    if (value === PortfolioFilters.ALL) {
      removeQueryParams([key]);
    } else {
      setQueryParams([{
        key,
        value
      }]);
    }
  }, [removeQueryParams, setQueryParams]);
  const handleFilterSubmit = useCallback(() => {
    navigateWithQuery();
  }, [navigateWithQuery]);
  return {
    filters,
    handleFilterChange,
    handleFilterSubmit,
    removeQueryParams,
    urlSearchParams
  };
};