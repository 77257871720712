import { offeringV5Mock } from '../offering-v5';
export const investments = [{
  id: 123241,
  investorId: 34355,
  investorFirstName: 'Investor',
  investorAccountId: 532523,
  noteId: 324235,
  noteUrlHash: offeringV5Mock.urlHash,
  noteTitle: offeringV5Mock.title,
  requestAmount: 10000,
  amount: 10000,
  payIn: '',
  payOut: '',
  status: '',
  subscriptionAgreementUrl: null,
  createDate: 34253255,
  updateDate: 2353235352,
  attachments: [{
    docType: 'OTHR',
    notes: '',
    url: ''
  }],
  autoInvest: false,
  transactionId: 325325352
}];