import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import dayjs from 'dayjs';
import orderBy from "lodash/orderBy";
import { PortfolioStatementProviderType, PortfolioStrategyValues } from '@yieldstreet/platform-kit';
import { StatementsListSortTypes } from './useStatementsList.model';
export const sortData = _ref => {
  let {
    data,
    sortKey,
    orderKey
  } = _ref;
  switch (sortKey) {
    case StatementsListSortTypes.Provider:
    case StatementsListSortTypes.Report:
      return orderBy(data, sortKey, orderKey);
    case StatementsListSortTypes.Date:
      const entriesWithTimestamp = data.map(entry => _objectSpread(_objectSpread({}, entry), {}, {
        timestamp: dayjs(entry.date).unix()
      }));
      return orderBy(entriesWithTimestamp, 'timestamp', orderKey);
    default:
      return data;
  }
};
export const getFilteredProviders = managementStrategy => {
  if (managementStrategy === PortfolioStrategyValues.Discretionary) {
    return [PortfolioStatementProviderType.PERSHING];
  }
  return [PortfolioStatementProviderType.YS];
};