import { AssetClassLabel, AssetClassType } from '../../../enums';
export const allocationTimeline = {
  userId: 4,
  currentAllocationHistory: {
    '2017-04-30': [{
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 120157.5,
      percentage: 100.0
    }],
    '2017-05-31': [{
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 30112.5000000000000000000000000000000036,
      percentage: 100.0
    }],
    '2017-06-30': [{
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 30112.5,
      percentage: 100.0
    }],
    '2017-07-31': [{
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 30112.5,
      percentage: 100.0
    }],
    '2017-08-31': [{
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 30112.5,
      percentage: 100.0
    }],
    '2017-09-30': [{
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 30112.5,
      percentage: 100.0
    }],
    '2017-10-31': [{
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 30112.5,
      percentage: 100.0
    }],
    '2017-11-30': [{
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 30112.5,
      percentage: 100.0
    }],
    '2017-12-31': [{
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 30112.5,
      percentage: 100.0
    }],
    '2018-01-31': [{
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 30112.5,
      percentage: 100.0
    }],
    '2018-02-28': [{
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 30112.5,
      percentage: 100.0
    }],
    '2018-03-31': [{
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 30112.5,
      percentage: 100.0
    }],
    '2018-04-30': [{
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 30112.5,
      percentage: 100.0
    }],
    '2018-05-31': [{
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 80214.794520547945197,
      percentage: 65.51
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 42222.79278,
      percentage: 34.49
    }],
    '2018-06-30': [{
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 120607.079452054794512,
      percentage: 87.03
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 17976.479245,
      percentage: 12.97
    }],
    '2018-07-31': [{
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 90546.03205479452054,
      percentage: 83.44
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 17976.479195,
      percentage: 16.56
    }],
    '2018-08-31': [{
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 40211.340821917808214,
      percentage: 69.11
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 17976.479145,
      percentage: 30.89
    }],
    '2018-09-30': [{
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 20272.24575342465755,
      percentage: 53.0
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 17976.479095,
      percentage: 47.0
    }],
    '2018-10-31': [{
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 30147.079178082191786,
      percentage: 52.73
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 27024.314635,
      percentage: 47.27
    }],
    '2018-11-30': [{
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 30142.4665753424657583,
      percentage: 62.64
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 17976.478995,
      percentage: 37.36
    }],
    '2018-12-31': [{
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 30098.6399999999999997125,
      percentage: 62.61
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 17976.478945,
      percentage: 37.39
    }],
    '2019-01-31': [{
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 20067.94369863013699,
      percentage: 52.75
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 17976.478895,
      percentage: 47.25
    }],
    '2019-02-28': [{
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 20061.3772602739726013013,
      percentage: 52.74
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 17976.478845,
      percentage: 47.26
    }],
    '2019-03-31': [{
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 30131.517534246575340438,
      percentage: 62.63
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 17976.49764,
      percentage: 37.37
    }],
    '2019-04-30': [{
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 170427.5665144596651398,
      percentage: 94.94
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 9088.988745,
      percentage: 5.06
    }],
    '2019-05-31': [{
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 189816.239558599695571108,
      percentage: 61.37
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 9156.148695,
      percentage: 2.96
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 110301.8055555555555483,
      percentage: 35.66
    }],
    '2019-06-30': [{
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 67884.138082191780825,
      percentage: 69.84
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 9223.308645,
      percentage: 9.49
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 20085.428888888888891111,
      percentage: 20.67
    }],
    '2019-07-31': [{
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 56992.606849315068499,
      percentage: 65.99
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 9290.468595,
      percentage: 10.76
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 20088.25666666666666,
      percentage: 23.26
    }],
    '2019-08-31': [{
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 66208.46068493150685,
      percentage: 69.12
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 9357.628545,
      percentage: 9.77
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 20225.77722222222221,
      percentage: 21.11
    }],
    '2019-09-30': [{
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 47274.933668188736677,
      percentage: 61.35
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 9424.788495,
      percentage: 12.23
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 20360.47777777777779,
      percentage: 26.42
    }],
    '2019-10-31': [{
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 230826.904520547945204642,
      percentage: 89.75
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 9491.948445,
      percentage: 3.69
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 16879.7104070833333394,
      percentage: 6.56
    }],
    '2019-11-30': [{
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 85223.894794520547946,
      percentage: 45.52
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 9559.108395,
      percentage: 5.11
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 92422.43429902777778,
      percentage: 49.37
    }],
    '2019-12-31': [{
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 42867.684003044140027,
      percentage: 31.8
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 9626.268345,
      percentage: 7.14
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 82303.817346666666664,
      percentage: 61.06
    }],
    '2020-01-31': [{
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 78277.547587519025875,
      percentage: 65.32
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 9693.428295,
      percentage: 8.09
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 31872.82725930555556,
      percentage: 26.6
    }],
    '2020-02-29': [{
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 48512.14052772070012525,
      percentage: 48.66
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 9760.588245,
      percentage: 9.79
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 41419.18466,
      percentage: 41.55
    }],
    '2020-03-31': [{
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 76092.4218240220700396,
      percentage: 59.94
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 9827.748195,
      percentage: 7.74
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 41023.73321013888889,
      percentage: 32.32
    }],
    '2020-04-30': [{
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 43506.2569081868341,
      percentage: 46.13
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 9894.908145,
      percentage: 10.49
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 40910.21774930555555,
      percentage: 43.38
    }],
    '2020-05-31': [{
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 54020.37302642884324,
      percentage: 31.24
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 9962.068095,
      percentage: 5.76
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 108945.92614694444445,
      percentage: 63.0
    }],
    '2020-06-30': [{
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 44071.03269012747337,
      percentage: 58.18
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 10029.228045,
      percentage: 13.24
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 21654.99326861111112,
      percentage: 28.59
    }],
    '2020-07-31': [{
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 11093.26,
      percentage: 7.71
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 44352.55319193112632,
      percentage: 30.81
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 10096.387995,
      percentage: 7.01
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 78409.33728125,
      percentage: 54.47
    }],
    '2020-08-31': [{
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 11156.54,
      percentage: 7.74
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 44637.90547455669721,
      percentage: 30.98
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 10163.547945,
      percentage: 7.05
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 78106.7387363888889,
      percentage: 54.22
    }],
    '2020-09-30': [{
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 12678.26,
      percentage: 7.41
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 44914.60075469368344,
      percentage: 26.24
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 10230.707895,
      percentage: 5.98
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 103350.84500555555556,
      percentage: 60.38
    }],
    '2020-10-31': [{
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 12735.95,
      percentage: 13.51
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 45199.40125649733636,
      percentage: 47.95
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 10297.867845,
      percentage: 10.93
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 26025.96986069444446,
      percentage: 27.61
    }],
    '2020-11-30': [{
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 13922.02,
      percentage: 15.78
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 45477.75653663432268,
      percentage: 51.56
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 10365.027795,
      percentage: 11.75
    }, {
      assetClass: AssetClassType.Cash,
      title: 'Cash',
      value: 294.28,
      percentage: 0.33
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 18142.7287548611111,
      percentage: 20.57
    }],
    '2020-12-31': [{
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 15008.71,
      percentage: 12.37
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 45760.91703843797565,
      percentage: 37.7
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 10432.187745,
      percentage: 8.6
    }, {
      assetClass: AssetClassType.Cash,
      title: 'Cash',
      value: 592.72,
      percentage: 0.49
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 49578.2156275,
      percentage: 40.85
    }],
    '2021-01-31': [{
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 15071.15,
      percentage: 6.34
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 56611.2012388717655,
      percentage: 23.82
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 10499.347695,
      percentage: 4.42
    }, {
      assetClass: AssetClassType.Cash,
      title: 'Cash',
      value: 14837.96,
      percentage: 6.24
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 140674.6199051388889,
      percentage: 59.18
    }],
    '2021-02-28': [{
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 15111.45,
      percentage: 10.95
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 56911.39237704528153,
      percentage: 41.26
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 10566.507645,
      percentage: 7.66
    }, {
      assetClass: AssetClassType.Cash,
      title: 'Cash',
      value: 0,
      percentage: 0.0
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 55353.764605555555549,
      percentage: 40.13
    }],
    '2021-03-31': [{
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 15112.6,
      percentage: 13.35
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 71592.64753638318124,
      percentage: 63.24
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 10633.667595,
      percentage: 9.39
    }, {
      assetClass: AssetClassType.Cash,
      title: 'Cash',
      value: 159.47,
      percentage: 0.14
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 15706.65741319444445,
      percentage: 13.87
    }],
    '2021-04-30': [{
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 15226.72,
      percentage: 11.2
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 61268.5222685749619,
      percentage: 45.07
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 10700.827545,
      percentage: 7.87
    }, {
      assetClass: AssetClassType.Cash,
      title: 'Cash',
      value: 667.2,
      percentage: 0.49
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 48070.835806944444456,
      percentage: 35.36
    }],
    '2021-05-31': [{
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 15321.05,
      percentage: 9.24
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 104199.6419791457383,
      percentage: 62.83
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 10767.987495,
      percentage: 6.49
    }, {
      assetClass: AssetClassType.Cash,
      title: 'Cash',
      value: 892.56,
      percentage: 0.54
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 34657.303960694444483,
      percentage: 20.9
    }],
    '2021-06-30': [{
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 15445.53,
      percentage: 12.19
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 53383.30493051560135,
      percentage: 42.14
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 10835.147445,
      percentage: 8.55
    }, {
      assetClass: AssetClassType.Cash,
      title: 'Cash',
      value: 218.19,
      percentage: 0.17
    }, {
      assetClass: AssetClassType.Legal,
      title: 'Legal',
      value: 25000.0,
      percentage: 19.73
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 21811.997791944444424,
      percentage: 17.22
    }],
    '2021-07-31': [{
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 15606.33,
      percentage: 9.88
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 51145.78868930555575,
      percentage: 32.39
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 10902.307395,
      percentage: 6.9
    }, {
      assetClass: AssetClassType.Cash,
      title: 'Cash',
      value: 5.3,
      percentage: 0.0
    }, {
      assetClass: AssetClassType.Legal,
      title: 'Legal',
      value: 25000.0,
      percentage: 15.83
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 55259.030464305555541,
      percentage: 34.99
    }],
    '2021-08-31': [{
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 15707.17,
      percentage: 11.55
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 52985.14867384893455,
      percentage: 38.95
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 10969.467345,
      percentage: 8.06
    }, {
      assetClass: AssetClassType.Cash,
      title: 'Cash',
      value: 131.23,
      percentage: 0.1
    }, {
      assetClass: AssetClassType.Legal,
      title: 'Legal',
      value: 25000.0,
      percentage: 18.38
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 31252.711594583333337,
      percentage: 22.97
    }],
    '2021-09-30': [{
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 15757.61,
      percentage: 11.33
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 56613.36185680555553,
      percentage: 40.69
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 11036.627295,
      percentage: 7.93
    }, {
      assetClass: AssetClassType.Cash,
      title: 'Cash',
      value: 172.08,
      percentage: 0.12
    }, {
      assetClass: AssetClassType.Legal,
      title: 'Legal',
      value: 25000.0,
      percentage: 17.97
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 30547.18252194444443,
      percentage: 21.96
    }],
    '2021-10-31': [{
      assetClass: AssetClassType.VentureCapital,
      title: 'Venture Capital',
      value: 0.0,
      percentage: 0.0
    }, {
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 15692.87,
      percentage: 9.48
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 49538.6896590972222,
      percentage: 29.92
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 11103.787245,
      percentage: 6.71
    }, {
      assetClass: AssetClassType.Cash,
      title: 'Cash',
      value: 887.1,
      percentage: 0.54
    }, {
      assetClass: AssetClassType.Legal,
      title: 'Legal',
      value: 25000.0,
      percentage: 15.1
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 63333.838520555555533,
      percentage: 38.26
    }],
    '2021-11-30': [{
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 15726.53,
      percentage: 12.45
    }, {
      assetClass: AssetClassType.VentureCapital,
      title: 'Venture Capital',
      value: 0.0,
      percentage: 0.0
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 49714.39370701388905,
      percentage: 39.35
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 11170.947195,
      percentage: 8.84
    }, {
      assetClass: AssetClassType.Cash,
      title: 'Cash',
      value: 6547.43,
      percentage: 5.18
    }, {
      assetClass: AssetClassType.Legal,
      title: 'Legal',
      value: 25000.0,
      percentage: 19.79
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 18191.153917638888869,
      percentage: 14.4
    }],
    '2021-12-31': [{
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 15759.44,
      percentage: 8.88
    }, {
      assetClass: AssetClassType.VentureCapital,
      title: 'Venture Capital',
      value: 25000.0,
      percentage: 14.09
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 51504.8926694044903,
      percentage: 29.03
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 11238.107145,
      percentage: 6.34
    }, {
      assetClass: AssetClassType.Other,
      title: 'Other',
      value: 15000.0,
      percentage: 8.46
    }, {
      assetClass: AssetClassType.Cash,
      title: 'Cash',
      value: 183.39,
      percentage: 0.1
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 33705.62129041666666,
      percentage: 19.0
    }, {
      assetClass: AssetClassType.Legal,
      title: 'Legal',
      value: 25000.0,
      percentage: 14.09
    }],
    '2022-01-31': [{
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 15892.79,
      percentage: 8.84
    }, {
      assetClass: AssetClassType.VentureCapital,
      title: 'Venture Capital',
      value: 25000.0,
      percentage: 13.91
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 49901.81135812500014,
      percentage: 27.77
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 11240.273595,
      percentage: 6.25
    }, {
      assetClass: AssetClassType.Other,
      title: 'Other',
      value: 15000.0,
      percentage: 8.35
    }, {
      assetClass: AssetClassType.Cash,
      title: 'Cash',
      value: 516.93,
      percentage: 0.29
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 37165.19173305555552,
      percentage: 20.68
    }, {
      assetClass: AssetClassType.Legal,
      title: 'Legal',
      value: 25000.0,
      percentage: 13.91
    }],
    '2022-02-28': [{
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 15923.62,
      percentage: 9.52
    }, {
      assetClass: AssetClassType.VentureCapital,
      title: 'Venture Capital',
      value: 25000.0,
      percentage: 14.94
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 25000.0,
      percentage: 14.94
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 0.0,
      percentage: 0.0
    }, {
      assetClass: AssetClassType.Crypto,
      title: 'Crypto',
      value: 25000.0,
      percentage: 14.94
    }, {
      assetClass: AssetClassType.Other,
      title: 'Other',
      value: 15000.0,
      percentage: 8.97
    }, {
      assetClass: AssetClassType.Cash,
      title: 'Cash',
      value: 45.84,
      percentage: 0.03
    }, {
      assetClass: AssetClassType.Legal,
      title: 'Legal',
      value: 25000.0,
      percentage: 14.94
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 36329.931176388888888,
      percentage: 21.72
    }],
    '2022-03-31': [{
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 15866.2,
      percentage: 9.61
    }, {
      assetClass: AssetClassType.VentureCapital,
      title: 'Venture Capital',
      value: 25000.0,
      percentage: 15.13
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 25000.0,
      percentage: 15.13
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 0.0,
      percentage: 0.0
    }, {
      assetClass: AssetClassType.Crypto,
      title: 'Crypto',
      value: 25000.0,
      percentage: 15.13
    }, {
      assetClass: AssetClassType.Other,
      title: 'Other',
      value: 15000.0,
      percentage: 9.08
    }, {
      assetClass: AssetClassType.Cash,
      title: 'Cash',
      value: 331.44,
      percentage: 0.2
    }, {
      assetClass: AssetClassType.Legal,
      title: 'Legal',
      value: 25000.0,
      percentage: 15.13
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 33987.201538888888886,
      percentage: 20.58
    }],
    '2022-04-30': [{
      assetClass: AssetClassType.VentureCapital,
      title: 'Venture Capital',
      value: 25000.0,
      percentage: 10.43
    }, {
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 15835.31,
      percentage: 6.61
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 25000.0,
      percentage: 10.43
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 70206.6619149955555373,
      percentage: 29.3
    }, {
      assetClass: AssetClassType.Crypto,
      title: 'Crypto',
      value: 25000.0,
      percentage: 10.43
    }, {
      assetClass: AssetClassType.Other,
      title: 'Other',
      value: 15000.0,
      percentage: 6.26
    }, {
      assetClass: AssetClassType.Cash,
      title: 'Cash',
      value: 350.98,
      percentage: 0.15
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 38237.873873888888891,
      percentage: 15.96
    }, {
      assetClass: AssetClassType.Legal,
      title: 'Legal',
      value: 25000.0,
      percentage: 10.43
    }],
    '2022-05-31': [{
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 16036.89,
      percentage: 8.28
    }, {
      assetClass: AssetClassType.VentureCapital,
      title: 'Venture Capital',
      value: 25000.0,
      percentage: 12.91
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 25000.0,
      percentage: 12.91
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 30300.11982097077778,
      percentage: 15.65
    }, {
      assetClass: AssetClassType.Crypto,
      title: 'Crypto',
      value: 25000.0,
      percentage: 12.91
    }, {
      assetClass: AssetClassType.Other,
      title: 'Other',
      value: 15000.0,
      percentage: 7.75
    }, {
      assetClass: AssetClassType.Cash,
      title: 'Cash',
      value: 954.16,
      percentage: 0.49
    }, {
      assetClass: AssetClassType.Legal,
      title: 'Legal',
      value: 25000.0,
      percentage: 12.91
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 31316.268747361111118,
      percentage: 16.18
    }],
    '2022-06-30': [{
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 16268.6,
      percentage: 7.8
    }, {
      assetClass: AssetClassType.VentureCapital,
      title: 'Venture Capital',
      value: 25000.0,
      percentage: 11.99
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 25000.0,
      percentage: 11.99
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 47757.7773477411110925,
      percentage: 22.9
    }, {
      assetClass: AssetClassType.Crypto,
      title: 'Crypto',
      value: 25000.0,
      percentage: 11.99
    }, {
      assetClass: AssetClassType.Other,
      title: 'Other',
      value: 15000.0,
      percentage: 7.19
    }, {
      assetClass: AssetClassType.Cash,
      title: 'Cash',
      value: 338.5,
      percentage: 0.16
    }, {
      assetClass: AssetClassType.Legal,
      title: 'Legal',
      value: 25000.0,
      percentage: 11.99
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 29147.494805416666667,
      percentage: 13.98
    }],
    '2022-07-31': [{
      assetClass: AssetClassType.VentureCapital,
      title: 'Venture Capital',
      value: 25000.0,
      percentage: 8.52
    }, {
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 16329.2,
      percentage: 5.57
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 25000.0,
      percentage: 8.52
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 136908.2782701072638615,
      percentage: 46.67
    }, {
      assetClass: AssetClassType.Crypto,
      title: 'Crypto',
      value: 25000.0,
      percentage: 8.52
    }, {
      assetClass: AssetClassType.Other,
      title: 'Other',
      value: 15000.0,
      percentage: 5.11
    }, {
      assetClass: AssetClassType.Cash,
      title: 'Cash',
      value: 63.16,
      percentage: 0.02
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 25029.217836944444445,
      percentage: 8.53
    }, {
      assetClass: AssetClassType.Legal,
      title: 'Legal',
      value: 25000.0,
      percentage: 8.52
    }],
    '2022-08-31': [{
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 16466.56,
      percentage: 3.92
    }, {
      assetClass: AssetClassType.VentureCapital,
      title: 'Venture Capital',
      value: 25000.0,
      percentage: 5.95
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 25000.0,
      percentage: 5.95
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 263912.929593294944435,
      percentage: 62.77
    }, {
      assetClass: AssetClassType.Crypto,
      title: 'Crypto',
      value: 25000.0,
      percentage: 5.95
    }, {
      assetClass: AssetClassType.Other,
      title: 'Other',
      value: 15000.0,
      percentage: 3.57
    }, {
      assetClass: AssetClassType.Cash,
      title: 'Cash',
      value: 49.13,
      percentage: 0.01
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 25000.0,
      percentage: 5.95
    }, {
      assetClass: AssetClassType.Legal,
      title: 'Legal',
      value: 25000.0,
      percentage: 5.95
    }],
    '2022-09-30': [{
      assetClass: AssetClassType.VentureCapital,
      title: 'Venture Capital',
      value: 25000.0,
      percentage: 11.75
    }, {
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 16560.1,
      percentage: 7.79
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 25000.0,
      percentage: 11.75
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 55500.09100206083334,
      percentage: 26.09
    }, {
      assetClass: AssetClassType.Crypto,
      title: 'Crypto',
      value: 25000.0,
      percentage: 11.75
    }, {
      assetClass: AssetClassType.Other,
      title: 'Other',
      value: 15000.0,
      percentage: 7.05
    }, {
      assetClass: AssetClassType.Cash,
      title: 'Cash',
      value: 627.96,
      percentage: 0.3
    }, {
      assetClass: AssetClassType.Legal,
      title: 'Legal',
      value: 25000.0,
      percentage: 11.75
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 25000.0,
      percentage: 11.75
    }],
    '2022-10-31': [{
      assetClass: AssetClassType.VentureCapital,
      title: 'Venture Capital',
      value: 25000.0,
      percentage: 8.0
    }, {
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 16500.33,
      percentage: 5.28
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 25000.0,
      percentage: 8.0
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 154977.079888543763871,
      percentage: 49.61
    }, {
      assetClass: AssetClassType.Crypto,
      title: 'Crypto',
      value: 25000.0,
      percentage: 8.0
    }, {
      assetClass: AssetClassType.Other,
      title: 'Other',
      value: 15000.0,
      percentage: 4.8
    }, {
      assetClass: AssetClassType.Cash,
      title: 'Cash',
      value: 936.62,
      percentage: 0.3
    }, {
      assetClass: AssetClassType.Legal,
      title: 'Legal',
      value: 25000.0,
      percentage: 8.0
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 25000.0,
      percentage: 8.0
    }],
    '2022-11-30': [{
      assetClass: AssetClassType.VentureCapital,
      title: 'Venture Capital',
      value: 25000.0,
      percentage: 5.56
    }, {
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 16643.29,
      percentage: 3.7
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 25000.0,
      percentage: 5.56
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 291979.46287645104168,
      percentage: 64.93
    }, {
      assetClass: AssetClassType.Crypto,
      title: 'Crypto',
      value: 25000.0,
      percentage: 5.56
    }, {
      assetClass: AssetClassType.Other,
      title: 'Other',
      value: 15000.0,
      percentage: 3.34
    }, {
      assetClass: AssetClassType.Cash,
      title: 'Cash',
      value: 1064.36,
      percentage: 0.24
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 25000.0,
      percentage: 5.56
    }, {
      assetClass: AssetClassType.Legal,
      title: 'Legal',
      value: 25000.0,
      percentage: 5.56
    }],
    '2022-12-31': [{
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 16727.54,
      percentage: 3.23
    }, {
      assetClass: AssetClassType.VentureCapital,
      title: 'Venture Capital',
      value: 25000.0,
      percentage: 4.83
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 25000.0,
      percentage: 4.83
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 293114.57614965896598,
      percentage: 56.63
    }, {
      assetClass: AssetClassType.Crypto,
      title: 'Crypto',
      value: 25000.0,
      percentage: 4.83
    }, {
      assetClass: AssetClassType.ShortTermNotes,
      title: 'Short Term Note',
      value: 67604.5,
      percentage: 13.06
    }, {
      assetClass: AssetClassType.Other,
      title: 'Other',
      value: 15000.0,
      percentage: 2.9
    }, {
      assetClass: AssetClassType.Cash,
      title: 'Cash',
      value: 152.07,
      percentage: 0.03
    }, {
      assetClass: AssetClassType.Legal,
      title: 'Legal',
      value: 25000.0,
      percentage: 4.83
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 25000.0,
      percentage: 4.83
    }],
    '2023-01-31': [{
      assetClass: AssetClassType.VentureCapital,
      title: 'Venture Capital',
      value: 25000.0,
      percentage: 5.41
    }, {
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 16607.94,
      percentage: 3.6
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 25000.0,
      percentage: 5.41
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 295992.50674603104315,
      percentage: 64.08
    }, {
      assetClass: AssetClassType.Crypto,
      title: 'Crypto',
      value: 25000.0,
      percentage: 5.41
    }, {
      assetClass: AssetClassType.ShortTermNotes,
      title: 'Short Term Note',
      value: 9020.635,
      percentage: 1.95
    }, {
      assetClass: AssetClassType.Other,
      title: 'Other',
      value: 15000.0,
      percentage: 3.25
    }, {
      assetClass: AssetClassType.Cash,
      title: 'Cash',
      value: 261.35,
      percentage: 0.06
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 25000.0,
      percentage: 5.41
    }, {
      assetClass: AssetClassType.Legal,
      title: 'Legal',
      value: 25000.0,
      percentage: 5.41
    }],
    '2023-02-28': [{
      assetClass: AssetClassType.VentureCapital,
      title: 'Venture Capital',
      value: 25000.0,
      percentage: 5.69
    }, {
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 16890.12,
      percentage: 3.85
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 25000.0,
      percentage: 5.69
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 272757.09379395037657,
      percentage: 62.12
    }, {
      assetClass: AssetClassType.Crypto,
      title: 'Crypto',
      value: 25000.0,
      percentage: 5.69
    }, {
      assetClass: AssetClassType.ShortTermNotes,
      title: 'Short Term Note',
      value: 9000.0000000000000000000000000000000011,
      percentage: 2.05
    }, {
      assetClass: AssetClassType.Other,
      title: 'Other',
      value: 15000.0,
      percentage: 3.42
    }, {
      assetClass: AssetClassType.Cash,
      title: 'Cash',
      value: 423.57,
      percentage: 0.1
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 25000.0,
      percentage: 5.69
    }, {
      assetClass: AssetClassType.Legal,
      title: 'Legal',
      value: 25000.0,
      percentage: 5.69
    }],
    '2023-03-31': [{
      assetClass: AssetClassType.VentureCapital,
      title: 'Venture Capital',
      value: 25000.0,
      percentage: 5.38
    }, {
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 17006.43,
      percentage: 3.66
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 25000.0,
      percentage: 5.38
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 301500.45540788627933,
      percentage: 64.85
    }, {
      assetClass: AssetClassType.Crypto,
      title: 'Crypto',
      value: 25000.0,
      percentage: 5.38
    }, {
      assetClass: AssetClassType.ShortTermNotes,
      title: 'Short Term Note',
      value: 4520.62,
      percentage: 0.97
    }, {
      assetClass: AssetClassType.Other,
      title: 'Other',
      value: 15000.0,
      percentage: 3.23
    }, {
      assetClass: AssetClassType.Cash,
      title: 'Cash',
      value: 1926.23,
      percentage: 0.41
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 25000.000010972222222222223,
      percentage: 5.38
    }, {
      assetClass: AssetClassType.Legal,
      title: 'Legal',
      value: 25000.0,
      percentage: 5.38
    }],
    '2023-04-30': [{
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 17112.36,
      percentage: 3.65
    }, {
      assetClass: AssetClassType.VentureCapital,
      title: 'Venture Capital',
      value: 25000.0,
      percentage: 5.34
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 25000.0,
      percentage: 5.34
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 295505.21084257719625,
      percentage: 63.11
    }, {
      assetClass: AssetClassType.Crypto,
      title: 'Crypto',
      value: 25000.0,
      percentage: 5.34
    }, {
      assetClass: AssetClassType.ShortTermNotes,
      title: 'Short Term Note',
      value: 13542.62,
      percentage: 2.89
    }, {
      assetClass: AssetClassType.Other,
      title: 'Other',
      value: 15000.0,
      percentage: 3.2
    }, {
      assetClass: AssetClassType.Cash,
      title: 'Cash',
      value: 2062.04,
      percentage: 0.44
    }, {
      assetClass: AssetClassType.Legal,
      title: 'Legal',
      value: 25000.0,
      percentage: 5.34
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 25000.00009013888888888888,
      percentage: 5.34
    }],
    '2023-05-31': [{
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 17271.44,
      percentage: 3.6
    }, {
      assetClass: AssetClassType.VentureCapital,
      title: 'Venture Capital',
      value: 25000.0,
      percentage: 5.21
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 25000.0,
      percentage: 5.21
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 307193.87026148048752,
      percentage: 64.08
    }, {
      assetClass: AssetClassType.Crypto,
      title: 'Crypto',
      value: 25000.0,
      percentage: 5.21
    }, {
      assetClass: AssetClassType.ShortTermNotes,
      title: 'Short Term Note',
      value: 13542.5906451612903213,
      percentage: 2.82
    }, {
      assetClass: AssetClassType.Other,
      title: 'Other',
      value: 15000.0,
      percentage: 3.13
    }, {
      assetClass: AssetClassType.Cash,
      title: 'Cash',
      value: 1381.07,
      percentage: 0.29
    }, {
      assetClass: AssetClassType.Legal,
      title: 'Legal',
      value: 25000.0,
      percentage: 5.21
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 25000.00017194444444444446,
      percentage: 5.21
    }],
    '2023-06-30': [{
      assetClass: AssetClassType.MultiAssetClassFund,
      title: 'Multi-Asset Class Fund',
      value: 17404.23,
      percentage: 4.64
    }, {
      assetClass: AssetClassType.VentureCapital,
      title: 'Venture Capital',
      value: 25000.0,
      percentage: 6.66
    }, {
      assetClass: AssetClassType.Transportation,
      title: 'Transportation',
      value: 25000.0,
      percentage: 6.66
    }, {
      assetClass: AssetClassType.RealEstate,
      title: AssetClassLabel.RealEstate,
      value: 157044.2517971244041828,
      percentage: 41.84
    }, {
      assetClass: AssetClassType.Crypto,
      title: 'Crypto',
      value: 25000.0,
      percentage: 6.66
    }, {
      assetClass: AssetClassType.ShortTermNotes,
      title: 'Short Term Note',
      value: 58562.5625,
      percentage: 15.6
    }, {
      assetClass: AssetClassType.Other,
      title: 'Other',
      value: 15000.0,
      percentage: 4.0
    }, {
      assetClass: AssetClassType.Cash,
      title: 'Cash',
      value: 2338.19,
      percentage: 0.62
    }, {
      assetClass: AssetClassType.Art,
      title: 'Art',
      value: 25000.00079861111111111111,
      percentage: 6.66
    }, {
      assetClass: AssetClassType.Legal,
      title: 'Legal',
      value: 25000.0,
      percentage: 6.66
    }]
  }
};
export const allocationTimelineEmpty = {
  userId: 4,
  currentAllocationHistory: {}
};