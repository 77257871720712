import styled from 'styled-components';
import { media } from '../../themes/media';
import { Heading } from '../../content';
export const BannerContainer = styled.div.withConfig({
  displayName: "PhotoBannerstyle__BannerContainer",
  componentId: "sc-esu0kc-0"
})(["margin:0 auto;width:100%;padding-bottom:", ";", ";"], _ref => {
  let {
    theme
  } = _ref;
  return theme.spacing.m;
}, media.desktop`
    padding-left: 110px;
    padding-right: 110px;
  `);
export const ContentHolder = styled.div.withConfig({
  displayName: "PhotoBannerstyle__ContentHolder",
  componentId: "sc-esu0kc-1"
})(["display:flex;background:", ";text-align:center;padding:", ";", ""], _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.gradients.radial_primary;
}, _ref3 => {
  let {
    theme
  } = _ref3;
  return `${theme.spacing['2xl']} ${theme.spacing.m}`;
}, media.desktop`
    text-align: initial;
    padding: ${_ref4 => {
  let {
    theme
  } = _ref4;
  return theme.spacing['2xl'];
}};
    column-gap: ${_ref5 => {
  let {
    theme
  } = _ref5;
  return theme.spacing['2xl'];
}};
  `);
export const SHeading = styled(Heading).withConfig({
  displayName: "PhotoBannerstyle__SHeading",
  componentId: "sc-esu0kc-2"
})(["margin-bottom:", ";"], _ref6 => {
  let {
    theme
  } = _ref6;
  return theme.spacing.sm;
});
export const ButtonContainer = styled.div.withConfig({
  displayName: "PhotoBannerstyle__ButtonContainer",
  componentId: "sc-esu0kc-3"
})(["margin-top:", ";"], _ref7 => {
  let {
    theme
  } = _ref7;
  return theme.spacing.ml;
});
export const ImageContainer = styled.div.withConfig({
  displayName: "PhotoBannerstyle__ImageContainer",
  componentId: "sc-esu0kc-4"
})(["display:none;", ""], media.large`
    display: initial;
  `);