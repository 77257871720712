import styled from 'styled-components';
import Carousel from 'nuka-carousel';
import { HeroBanner, Paragraph, media } from '@yieldstreet/ui-kit';
export const Border = styled.div.withConfig({
  displayName: "FeaturedManagersstyle__Border",
  componentId: "sc-acy9gt-0"
})(["border:solid 1px ", ";border-radius:", ";"], _ref => {
  let {
    theme
  } = _ref;
  return theme.colors.border_default;
}, _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.radius.xl;
});
export const SHeroBanner = styled(HeroBanner).withConfig({
  displayName: "FeaturedManagersstyle__SHeroBanner",
  componentId: "sc-acy9gt-1"
})([".hero-banner-content-holder{margin:", ";& > h3{margin:0px;}}.hero-banner-content-wrapper{min-height:240px;width:fit-content;}.hero-banner-side-content{width:100%;padding:0;}.logo-banner-container{margin:0 !important;gap:", ";.logo-banner-image-wrapper{margin:0;}}"], _ref3 => {
  let {
    theme
  } = _ref3;
  return `auto 0 auto ${theme.spacing.m}`;
}, _ref4 => {
  let {
    theme
  } = _ref4;
  return theme.spacing.s;
});
export const WebView = styled.div.withConfig({
  displayName: "FeaturedManagersstyle__WebView",
  componentId: "sc-acy9gt-2"
})(["display:none;", ""], media.desktop`
    display: flex;
  `);
export const MobileView = styled.div.withConfig({
  displayName: "FeaturedManagersstyle__MobileView",
  componentId: "sc-acy9gt-3"
})(["display:flex;flex-direction:column;padding:", ";text-align:left;", ""], _ref5 => {
  let {
    theme
  } = _ref5;
  return `${theme.spacing.m} ${theme.spacing.m} ${theme.spacing.xl}`;
}, media.desktop`
    display: none;
  `);
export const SParagraph = styled(Paragraph).withConfig({
  displayName: "FeaturedManagersstyle__SParagraph",
  componentId: "sc-acy9gt-4"
})(["margin-top:", ";margin-bottom:", ";"], _ref6 => {
  let {
    theme
  } = _ref6;
  return theme.spacing.xxs;
}, _ref7 => {
  let {
    theme
  } = _ref7;
  return theme.spacing.m;
});
export const SCarousel = styled(Carousel).withConfig({
  displayName: "FeaturedManagersstyle__SCarousel",
  componentId: "sc-acy9gt-5"
})([".slider-control-bottomcenter{bottom:auto !important;margin-top:", " !important;}.slider-frame{margin:10px 0 10px 10px !important;}&.active{.paging-dot{background:", " !important;}}"], _ref8 => {
  let {
    theme
  } = _ref8;
  return theme.spacing.s;
}, props => props.theme.colors.chart_1);