import _Box from "@mui/material/Box";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { FeatureBanner as BaseFeatureBanner } from '@yieldstreet/ui-kit';
import { useAudiences } from 'hooks';
import { usePlatformPromo } from '../../hooks/usePlatformPromo';
export const FeatureBanner = props => {
  const history = useHistory();
  const audiences = useAudiences();
  const {
    displayedMessage,
    trackAction,
    dismiss
  } = usePlatformPromo(props);
  const handleOnActionClick = useCallback(() => {
    trackAction('ClickCTA');
    history.push(displayedMessage.configuration.url);
  }, [displayedMessage.configuration.url, history, trackAction]);
  if (audiences.hasCryptoWallet) {
    return null;
  }
  return __jsx(_Box, {
    sx: {
      mb: 4
    }
  }, __jsx(BaseFeatureBanner, _extends({}, displayedMessage.configuration, {
    content: __jsx(_Typography, {
      inverse: [1, 2].includes(displayedMessage.configuration.type)
    }, displayedMessage.configuration.content),
    onCloseClick: dismiss,
    onActionClick: handleOnActionClick
  })));
};