import { InvestorEntityType, IdentityDocumentType } from '@yieldstreet/platform-kit';
export const getInvestorEntityType = investorEntity => {
  const {
    type
  } = investorEntity;
  const {
    entityType,
    identityDocumentType,
    iraAccountType,
    value
  } = type;
  if (['INDV'].includes(value)) {
    return 'individual';
  } else if (entityType === InvestorEntityType.IRA) {
    return 'sdira';
  } else if (entityType && [InvestorEntityType.LLC, InvestorEntityType.SOLE_PROPRIETOR, InvestorEntityType.PARTNERSHIP, InvestorEntityType.S_CORPORATION, InvestorEntityType.C_CORPORATION].includes(entityType)) {
    return 'entity';
  } else if (identityDocumentType === IdentityDocumentType.SSN && entityType === InvestorEntityType.TRUST) {
    return 'trust-with-ssn';
  } else if (iraAccountType) {
    return 'ira';
  } else if (!iraAccountType && identityDocumentType === IdentityDocumentType.EIN && entityType === InvestorEntityType.TRUST) {
    return 'trust-with-ein';
  }
  return 'individual';
};