var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { Heading, Label } from '../../../content/text';
import { ButtonContainer } from '../../../content/button/ButtonContainer';
import { media } from '../../../themes/media';
export const DealCard = props => {
  const {
    title,
    subtitle,
    image,
    children,
    cta
  } = props;
  return __jsx(OuterContainer, null, __jsx(TopContainer, null, __jsx(ImageHolder, null, image && __jsx(ImageWrapper, null, image)), __jsx(ContentHolder, null, __jsx(TitleHolder, null, subtitle ? __jsx(SLabel, null, subtitle) : '', title && __jsx(Headline, {
    type: 5
  }, title)))), __jsx(ContentHolder, null, __jsx(TextHolder, null, children, cta && __jsx(CTAHolder, null, cta))));
};
const OuterContainer = styled.div.withConfig({
  displayName: "DealCard__OuterContainer",
  componentId: "sc-1lvthvs-0"
})(["background:", ";box-shadow:", ";padding:20px;flex:none;flex:1 0;text-align:left;margin:0 0 20px 0;", ""], props => props.theme.colors.background_card, props => props.theme.shadows.shadow_default, media.desktop`
    margin: 0 0 0 20px;
  `);
const TopContainer = styled.div.withConfig({
  displayName: "DealCard__TopContainer",
  componentId: "sc-1lvthvs-1"
})(["flex-direction:row;display:flex;flex:0 0;"]);
const TitleHolder = styled.div.withConfig({
  displayName: "DealCard__TitleHolder",
  componentId: "sc-1lvthvs-2"
})([""]);
const Headline = styled(Heading).withConfig({
  displayName: "DealCard__Headline",
  componentId: "sc-1lvthvs-3"
})(["padding:0;margin-bottom:10px;"]);
const ImageHolder = styled.div.withConfig({
  displayName: "DealCard__ImageHolder",
  componentId: "sc-1lvthvs-4"
})(["img{width:100%;}margin-right:20px;overflow:hidden;", ""], media.desktop`
    width: 30%;
  `);
const ContentHolder = styled.div.withConfig({
  displayName: "DealCard__ContentHolder",
  componentId: "sc-1lvthvs-5"
})(["display:flex;flex:1;flex-direction:column;justify-content:space-between;"]);
const CTAHolder = styled(ButtonContainer).withConfig({
  displayName: "DealCard__CTAHolder",
  componentId: "sc-1lvthvs-6"
})([""]);
const ImageWrapper = styled.div.withConfig({
  displayName: "DealCard__ImageWrapper",
  componentId: "sc-1lvthvs-7"
})([""]);
const SLabel = styled(Label).withConfig({
  displayName: "DealCard__SLabel",
  componentId: "sc-1lvthvs-8"
})(["margin-bottom:10px;display:inline-block;padding:5px;background:", ";"], props => props.theme.colors.background_page);
const TextHolder = styled.div.withConfig({
  displayName: "DealCard__TextHolder",
  componentId: "sc-1lvthvs-9"
})(["margin-top:20px;dl{display:flex;flex-wrap:wrap;dt{flex:1 0 50%;margin-bottom:5px;}dd{flex:1 1;text-align:right;margin-bottom:5px;}}"]);