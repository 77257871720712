import { useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
export const useAuthRecaptcha = () => {
  const {
    executeRecaptcha
  } = useGoogleReCaptcha();
  return useCallback(async action => {
    if (executeRecaptcha) {
      try {
        return await executeRecaptcha(action);
      } catch (error) {}
    }
    return null;
  }, [executeRecaptcha]);
};