import _defineProperty from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import { AppActionCTAText } from './appActions.model';
class AppActionManager {
  constructor() {
    _defineProperty(this, "debugMode", false);
    _defineProperty(this, "globalParams", void 0);
    _defineProperty(this, "actionCallbacks", void 0);
    _defineProperty(this, "init", (actionCallbacks, globalParams, debugMode) => {
      this.actionCallbacks = actionCallbacks;
      this.debugMode = debugMode || false;
      this.globalParams = globalParams;
    });
    _defineProperty(this, "log", (action, params) => {
      // eslint-disable-next-line no-console
      this.debugMode && console.info(action, params);
    });
    _defineProperty(this, "callAction", (action, params) => {
      if (!this.actionCallbacks) {
        throw new Error('Make sure you initialize app action callbacks before triggering any action in the application.');
      }
      this.log(action, params);
      this.actionCallbacks[action](params || {}, this.globalParams || {});
    });
    _defineProperty(this, "getAction", (action, params) => ({
      action,
      params,
      cta: this.getActionCTA(action, params)
    }));
    _defineProperty(this, "getActionCTA", (action, params) => {
      if (!AppActionCTAText[action]) {
        return;
      }
      return {
        text: AppActionCTAText[action],
        callback: () => this.callAction(action, params)
      };
    });
  }
}
export const appActionManager = new AppActionManager();