import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { Button, Paragraph, Heading } from '@yieldstreet/ui-kit';
import twoFASuccess from '../../../assets/Complete.svg';
import { Icon, ParagraphWrapper } from '../../../TwoFaModal.style';
import { testIds } from './EditSuccessStep.model';
import { User2FAMethod } from '@yieldstreet/platform-kit';
import { AuthenticatorName } from '../EditMethodStep/EditMethodStep.model';
export const EditSuccessStep = _ref => {
  let {
    method,
    onCloseModal,
    onComplete
  } = _ref;
  return __jsx(React.Fragment, null, __jsx(Icon, _extends({
    src: twoFASuccess
  }, testIds.icon.attr)), __jsx(Heading, _extends({
    type: 4
  }, testIds.title.attr), method === User2FAMethod.TEXT_MESSAGE ? 'Authentication settings updated' : 'Authentication set-up complete'), __jsx(ParagraphWrapper, null, __jsx(Paragraph, testIds.description.attr, method === User2FAMethod.TEXT_MESSAGE ? `From now on, we'll send you a code that you'll use to verify your account before logging
          in. This is essential to the protection of your account. You can edit your verification
          method at any time via your User Profile.` : `From now on, you’ll use the ${AuthenticatorName[method]} app to access your verification number and use it to verify your account before logging in. This is essential to the protection of your account. You can edit your verification method at any time via your User Profile.`)), __jsx(Button, _extends({}, testIds.button.attr, {
    onClick: () => {
      onCloseModal();
      onComplete && onComplete();
    },
    type: "button"
  }), "Continue"));
};