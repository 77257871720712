import { useUserState } from '../hooks/useUserState';
import { useAudiences } from 'hooks/useAudiences';
import { useSelector } from 'react-redux';
export const getHasAdviceFeature = _ref => {
  let {
    audiences,
    userState
  } = _ref;
  const isAdviceEligible = !!(audiences !== null && audiences !== void 0 && audiences.adviceEligible);
  const accreditedSignUp = !!(userState !== null && userState !== void 0 && userState.accreditedSignUp);
  const hasAdvice = accreditedSignUp;
  return {
    hasAdvice,
    isAdviceEligible
  };
};

/**
 * Hook evaluates logic and returns common advice-related conditions
 *
 * @todo will eventually have to use this on mobile, when porting over advice-v1
 * Maybe add a variation of this to `@yieldstreet/tool-kit`? TBD
 */
export const useAdvice = () => {
  const audiencesFetched = useSelector(_ref2 => {
    let {
      audiences
    } = _ref2;
    return audiences === null || audiences === void 0 ? void 0 : audiences.fetched;
  });
  const audiences = useAudiences();
  const userState = useUserState();
  const {
    hasAdvice,
    isAdviceEligible
  } = getHasAdviceFeature({
    audiences,
    userState
  });
  const isAdviceClient = !!(hasAdvice && audiences !== null && audiences !== void 0 && audiences.adviceClient);
  const isAdviceReady = !!(hasAdvice && audiences !== null && audiences !== void 0 && audiences.adviceReady);
  const hasAdviceButNeedsAccount = hasAdvice && !isAdviceEligible;
  return {
    // i.e. should only show advice if feature flag is true and is SAI+
    hasAdvice,
    // i.e. should show advice but needs to create investor account
    hasAdviceButNeedsAccount,
    // i.e. should show recommendations if advice ready
    isAdviceReady,
    // i.e. should show client agreement
    isAdviceClient,
    audiencesFetched
  };
};