import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["fundId", "userId"],
  _excluded2 = ["userId"];
import { HttpMethods } from '../../enums/http';
import { restApi, Tags } from '../common';
export const campaignApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.CampaignStatus, Tags.Campaigns]
}).injectEndpoints({
  endpoints: build => {
    const getCampaignEligibility = build.query({
      query: _ref => {
        let {
          fundId,
          campaignId,
          userId,
          investorAccountId
        } = _ref;
        return {
          url: `/api/fund/funds/${fundId}/campaigns/${campaignId}/eligibilities/${userId}`,
          method: HttpMethods.Get,
          params: {
            investorAccountId
          }
        };
      },
      providesTags: [Tags.CampaignStatus]
    });
    const getCampaigns = build.query({
      query: _ref2 => {
        let {
          fundId,
          userId
        } = _ref2;
        return {
          url: `/api/fund/funds/${fundId}/campaigns/${userId}`,
          method: HttpMethods.Get
        };
      },
      providesTags: [Tags.Campaigns]
    });
    const submitCampaignVotes = build.mutation({
      query: _ref3 => {
        let {
            fundId,
            userId
          } = _ref3,
          data = _objectWithoutProperties(_ref3, _excluded);
        return {
          url: `/api/fund/funds/${fundId}/campaigns/votes/${userId}`,
          method: HttpMethods.Put,
          data
        };
      },
      invalidatesTags: [Tags.CampaignStatus]
    });
    const getAllCampaignEligibility = build.query({
      query: _ref4 => {
        let {
          userId,
          investorAccountId
        } = _ref4;
        return {
          url: `/api/fund/funds/campaigns/eligibilities/${userId}`,
          method: HttpMethods.Get,
          params: {
            investorAccountId
          }
        };
      }
    });
    const submitAllCampaignVotes = build.mutation({
      query: _ref5 => {
        let {
            userId
          } = _ref5,
          data = _objectWithoutProperties(_ref5, _excluded2);
        return {
          url: `/api/fund/funds/campaigns/votes/${userId}`,
          method: HttpMethods.Put,
          data
        };
      }
    });
    return {
      getCampaigns,
      getCampaignEligibility,
      submitCampaignVotes,
      getAllCampaignEligibility,
      submitAllCampaignVotes
    };
  }
});