import { useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { PortfolioStrategy, PortfolioStrategyValues } from '@yieldstreet/platform-kit';
import { getManagementStrategy } from './useManagementStrategy.utils';
export const useManagementStrategy = () => {
  const {
    strategy: managementStrategyRouteParam
  } = useParams();
  const {
    pathname
  } = useLocation();
  const managementStrategy = useMemo(() => {
    switch (managementStrategyRouteParam) {
      case PortfolioStrategy.SelfDirected:
        return PortfolioStrategyValues.Self;
      case PortfolioStrategy.Managed:
        return PortfolioStrategyValues.Discretionary;
      default:
        return getManagementStrategy(pathname);
    }
  }, [managementStrategyRouteParam, pathname]);
  return {
    managementStrategy,
    managementStrategyRouteParam
  };
};