import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { CALL_API } from '../middleware/Middleware';
export const GET_BANK_ACCOUNT_ATTEMPT = 'ys/bank/accounts/ATTEMPT';
export const GET_BANK_ACCOUNT_SUCCESS = 'ys/bank/accounts/SUCCESS';
export const GET_BANK_ACCOUNT_FAILURE = 'ys/bank/accounts/FAILURE';
export const getbankAccounts = () => dispatch => {
  const endpoint = '/a/api/user/ba/all';
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_BANK_ACCOUNT_ATTEMPT, GET_BANK_ACCOUNT_SUCCESS, GET_BANK_ACCOUNT_FAILURE],
      getResponse: res => res.data
    }
  });
};
export const GET_USER_BANK_INFO_ATTEMPT = 'ys/bank/user/ATTEMPT';
export const GET_USER_BANK_INFO_SUCCESS = 'ys/bank/user/SUCCESS';
export const GET_USER_BANK_INFO_FAILURE = 'ys/bank/user/FAILURE';
export const getUserBankInfo = userId => dispatch => {
  const endpoint = `/a/api/user/${userId}/bank-accounts`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [GET_USER_BANK_INFO_ATTEMPT, GET_USER_BANK_INFO_SUCCESS, GET_USER_BANK_INFO_FAILURE],
      successPayload: {
        userId
      },
      getResponse: res => res.data
    }
  });
};
export const UPDATE_BANKS_NICKNAME_ATTEMPT = 'ys/bank/bankNickname/update/ATTEMPT';
export const UPDATE_BANKS_NICKNAME_SUCCESS = 'ys/bank/bankNickname/update/SUCCESS';
export const UPDATE_BANKS_NICKNAME_FAILURE = 'ys/bank/bankNickname/update/FAILURE';
export const updateBanksNickname = (bankAccount, nickname) => dispatch => {
  const endpoint = `/a/api/user/ba/${bankAccount.id}`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [UPDATE_BANKS_NICKNAME_ATTEMPT, UPDATE_BANKS_NICKNAME_SUCCESS, UPDATE_BANKS_NICKNAME_FAILURE],
      options: {
        method: 'POST',
        data: _objectSpread(_objectSpread({}, bankAccount), {}, {
          nickname
        })
      },
      successPayload: {
        holderId: bankAccount.holderId
      },
      getResponse: res => res.data.bankAccount
    }
  });
};
export const DEPOSIT_VERIFICATION_ATTEMPT = 'ys/bank/deposit/verification/ATTEMPT';
export const DEPOSIT_VERIFICATION_SUCCESS = 'ys/bank/deposit/verification/SUCCESS';
export const DEPOSIT_VERIFICATION_FAILURE = 'ys/bank/deposit/verification/FAILURE';
export const depositVerification = (userId, investorId, bankAcctId, firstAmount, secondAmount) => dispatch => {
  const endpoint = `/a/api/investor-entity/${investorId}/bank-account/${bankAcctId}/verify-micro-deposits`;
  return dispatch({
    [CALL_API]: {
      endpoint,
      types: [DEPOSIT_VERIFICATION_ATTEMPT, DEPOSIT_VERIFICATION_SUCCESS, DEPOSIT_VERIFICATION_FAILURE],
      options: {
        method: 'POST',
        data: {
          amounts: [`.${firstAmount}`, `.${secondAmount}`]
        }
      },
      attemptPayload: {
        userId,
        bankAcctId
      },
      successPayload: {
        userId,
        bankAcctId
      },
      failurePayload: {
        userId,
        bankAcctId
      },
      getResponse: res => res.data
    }
  });
};
export const RESET_BANK_DATA = 'ys/bank/RESET';
export const reset = () => dispatch => {
  return dispatch({
    type: RESET_BANK_DATA
  });
};