var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { media } from '../../themes/media';
import { Heading, Paragraph, Table } from '../../content/text';
import { ButtonContainer } from '../../content/button';
import { PageSection } from '../../layout';
export const MultiItemBanner = props => {
  const {
    title,
    headingType = 3,
    tagType,
    children,
    inverse,
    subtitle,
    content,
    endtitle,
    cta,
    bottomExtraSpacing,
    itemAlignment = 'space-evenly',
    wrap = 'nowrap',
    mobileHorizontalScroll = false,
    mobileAlignment = 'left',
    desktopAlignment = 'center',
    itemOrientation,
    className
  } = props;
  return __jsx(OuterContainer, null, __jsx(Container, {
    bottomExtraSpacing: bottomExtraSpacing,
    className: className
  }, __jsx(ContentHolder, null, title && __jsx(Headline, {
    mobileAlignment: mobileAlignment,
    desktopAlignment: desktopAlignment,
    tagType: tagType || headingType,
    type: headingType,
    inverse: inverse,
    className: "multi-item-banner-title"
  }, title), subtitle && __jsx(SubTitle, {
    mobileAlignment: mobileAlignment,
    desktopAlignment: desktopAlignment,
    inverse: inverse,
    className: "multi-item-banner-subtitle"
  }, subtitle), __jsx(ItemContainer, {
    mobileHorizontalScroll: mobileHorizontalScroll,
    wrap: wrap,
    itemAlignment: itemAlignment,
    className: "multi-item-banner-children",
    itemOrientation: itemOrientation
  }, children), content && __jsx(Content, {
    desktopAlignment: desktopAlignment
  }, content), endtitle && __jsx(EndTitle, {
    desktopAlignment: desktopAlignment,
    regular: true,
    inverse: inverse
  }, endtitle), cta && __jsx(CtaHolder, null, __jsx(ButtonContainer, {
    alignment: "center"
  }, cta)))));
};
const OuterContainer = styled(PageSection).withConfig({
  displayName: "MultiItemBanner__OuterContainer",
  componentId: "sc-2f6zcc-0"
})([""]);
const Container = styled.div.withConfig({
  displayName: "MultiItemBanner__Container",
  componentId: "sc-2f6zcc-1"
})(["margin:0 auto;padding-bottom:", ";width:100%;", ""], props => props.bottomExtraSpacing ? `70px` : `20px`, media.desktop`
    padding-left: 110px;
    padding-right: 110px;
  `);
const ContentHolder = styled.div.withConfig({
  displayName: "MultiItemBanner__ContentHolder",
  componentId: "sc-2f6zcc-2"
})(["margin:0 auto;text-align:center;"]);
const Headline = styled(Heading).withConfig({
  displayName: "MultiItemBanner__Headline",
  componentId: "sc-2f6zcc-3"
})(["padding:40px 20px 15px 20px;width:100%;text-align:", ";", ""], props => props.mobileAlignment, media.desktop`
    text-align: ${props => props.desktopAlignment};
    padding: ${props => props.desktopAlignment === 'center' ? '40px 20px 15px 20px' : '40px 0 15px 0'};
    margin: ${props => props.desktopAlignment === 'center' ? '0 auto' : '0'};
  `);
const SubTitle = styled(Paragraph).withConfig({
  displayName: "MultiItemBanner__SubTitle",
  componentId: "sc-2f6zcc-4"
})(["padding:20px;width:100%;text-align:", ";", ""], props => props.mobileAlignment, media.desktop`
    text-align: ${props => props.desktopAlignment};
    width: 50%;
    padding: ${props => props.desktopAlignment === 'center' ? '20px' : '20px 0'};
    margin: ${props => props.desktopAlignment === 'center' ? '0 auto' : '0'};
  `);
const EndTitle = styled(Table).withConfig({
  displayName: "MultiItemBanner__EndTitle",
  componentId: "sc-2f6zcc-5"
})(["padding:20px;color:", ";width:100%;text-align:left;display:inline-block;", ""], props => props.theme.colors.text_label, media.desktop`
    text-align: ${props => props.desktopAlignment};
    width: 50%;
    padding: ${props => props.desktopAlignment === 'center' ? '20px' : '20px 0'};
    margin: ${props => props.desktopAlignment === 'center' ? '0 auto' : '0'};
  `);
const Content = styled(Paragraph).withConfig({
  displayName: "MultiItemBanner__Content",
  componentId: "sc-2f6zcc-6"
})(["padding:20px;width:100%;text-align:left;display:inline-block;", ""], media.desktop`
    text-align: ${props => props.desktopAlignment};
    width: 60%;
    padding: ${props => props.desktopAlignment === 'center' ? '20px' : '20px 0'};
    margin: ${props => props.desktopAlignment === 'center' ? '0 auto' : '0'};
  `);
const ItemContainer = styled.div.withConfig({
  displayName: "MultiItemBanner__ItemContainer",
  componentId: "sc-2f6zcc-7"
})(["display:flex;flex-direction:", ";flex-wrap:", ";justify-content:", ";margin:0 auto;padding:20px;", ";", ""], props => props.itemOrientation || props.wrap === 'nowrap' && !props.mobileHorizontalScroll ? `column` : `row`, props => props.wrap, props => props.itemAlignment, props => props.mobileHorizontalScroll ? `
  overflow-y: auto;    
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }` : '', media.desktop`
    flex-direction: ${props => props.itemOrientation || 'row'};
    padding: 20px 0;
  `);
const CtaHolder = styled.div.withConfig({
  displayName: "MultiItemBanner__CtaHolder",
  componentId: "sc-2f6zcc-8"
})(["padding:0 20px;", ""], media.desktop`
    padding: 0;
  `);