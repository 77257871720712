import { friendlyMedia as media, friendlySizes as sizes } from '../../themes/media';
import { css } from 'styled-components';
import mapValues from 'lodash/mapValues';
import isNil from 'lodash/isNil';
import isObject from 'lodash/isObject';
import { css as muiCss } from '@mui/material/styles';
import { CSS_THEME as muiTheme } from '../../../theme';
export const isValueMap = value => isObject(value);
export const getValue = (value, defaultValue) => {
  return isNil(value) ? defaultValue : value;
};
export const getSizeValue = (size, value, defaultValue) => {
  defaultValue = !isNil(defaultValue) ? getSizeValue(size, defaultValue) : defaultValue;
  if (!isNil(value) && isValueMap(value)) {
    const sizeValue = value[size];
    return getValue(sizeValue, defaultValue);
  }
  return getValue(value, defaultValue);
};
export const allValueProps = props => !Object.values(props).filter(prop => isValueMap(prop)).length;
export const getProps = (props, defaultProps) => mapValues(props, (value, key) => {
  return getValue(value, defaultProps && defaultProps[key]);
});
export const getSizeProps = (size, props, defaultProps) => mapValues(props, (value, key) => {
  return getSizeValue(size, value, defaultProps && defaultProps[key]);
});
export const sizeMap = sizeMapper => sizes.reduce((acc, size) => {
  acc[size] = sizeMapper(size);
  return acc;
}, {});
export const sizeCss = (styleMapper, props, defaultProps) => allValueProps(props) && (defaultProps && allValueProps(defaultProps) || !defaultProps) ? styleMapper(getProps(props, defaultProps)) : css(["", ""], sizes.map(size => media[size]`
            ${styleMapper(getSizeProps(size, props, defaultProps))}
          `));

/**
 * This replaces `sizeCss` for the MUI Styled Engine.
 */
export const sizeCssMui = (styleMapper, props, defaultProps) => allValueProps(props) && (defaultProps && allValueProps(defaultProps) || !defaultProps) ? styleMapper(getProps(props, defaultProps)) : muiCss`
      ${muiTheme.breakpoints.keys.map(size => `${size}
          ${styleMapper(getSizeProps(size, props, defaultProps))}
        `)}
    `;