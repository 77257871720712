var __jsx = React.createElement;
import React from 'react';
import { ExternalLink } from './ExternalLink';
export const CallbackLink = _ref => {
  let {
    id,
    children,
    inverse,
    isBreadcrumb,
    callback,
    className
  } = _ref;
  return __jsx(ExternalLink, {
    id: id,
    "data-cy": id || 'link',
    inverse: inverse,
    isBreadcrumb: isBreadcrumb,
    className: className,
    onClick: e => {
      e.preventDefault();
      callback && callback();
    }
  }, children);
};