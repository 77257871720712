import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var __jsx = React.createElement;
import React, { useCallback, useMemo } from 'react';
import { FormSelectInput } from '@yieldstreet/ui-kit';
import { Formik, Form } from 'formik';
import { inputStyles, InputWrapper } from './PortfolioFiltersDesktop.style';
export const PortfolioFiltersDesktopForm = _ref => {
  let {
    filters,
    handleFilterChange,
    handleFilterSubmit
  } = _ref;
  const handleFormChange = useCallback((key, value) => {
    handleFilterChange(key, value);
    handleFilterSubmit();
  }, [handleFilterChange, handleFilterSubmit]);
  const initialValues = useMemo(() => {
    const values = {};
    if (!(filters !== null && filters !== void 0 && filters.length)) {
      return values;
    }
    filters.forEach(filter => {
      values[filter.key] = filter.defaultValue;
    });
    return values;
  }, [filters]);
  return __jsx(Formik, {
    enableReinitialize: true,
    initialValues: initialValues,
    render: formikProps => {
      return __jsx(Form, null, __jsx(InputWrapper, null, filters.map(_ref2 => {
        let {
          disabled,
          key,
          label,
          options,
          value
        } = _ref2;
        return __jsx(FormSelectInput, {
          "data-cy": `portfolio-filter-${key}`,
          hiddenLabel: true,
          disabled: disabled,
          formikProps: formikProps,
          id: key,
          key: key,
          label: label,
          name: key,
          onChange: newValue => handleFormChange(key, newValue),
          options: options,
          style: _objectSpread({}, inputStyles),
          value: value
        });
      })));
    }
  });
};