import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["renderAdditional"],
  _excluded2 = ["items", "hideMain", "renderAdditional"];
var __jsx = React.createElement;
import React from 'react';
import { Label, Paragraph } from '@yieldstreet/ui-kit';
import { InvestorEntityList, InvestorEntityItem, InvestorEntityDetails, InvestorEntityAction, InvestorEntityContent, InvestorEntityStatus, InvestorEntityStatusIcon, InvestorEntityError, InvestorEntityItemRow } from './InvestorEntityActionList.style';
import { useTestIds, statusIcon } from './InvestorEntityActionList.model';
const InvestorEntityActionListItem = props => {
  const {
      renderAdditional
    } = props,
    propsToForward = _objectWithoutProperties(props, _excluded);
  const {
    name,
    description,
    status,
    statusText,
    actionError,
    actionButton,
    hideMain
  } = propsToForward;
  const testIds = useTestIds(props);
  return __jsx(InvestorEntityItem, testIds.item.attr, !hideMain && __jsx(InvestorEntityItemRow, null, __jsx(InvestorEntityDetails, null, __jsx(Paragraph, testIds.itemName.attr, name), __jsx(Label, testIds.itemDescription.attr, description), actionError && __jsx(InvestorEntityError, _extends({
    size: "small"
  }, testIds.itemActionError.attr), actionError)), __jsx(InvestorEntityContent, null, actionButton ? __jsx(InvestorEntityAction, testIds.itemActionButton.attr, actionButton) : __jsx(InvestorEntityStatus, _extends({
    size: "small",
    status: status
  }, testIds.itemActionStatus.attr), status && __jsx(InvestorEntityStatusIcon, {
    src: statusIcon[status]
  }), statusText ? statusText : null))), renderAdditional && renderAdditional(propsToForward));
};
export const InvestorEntityActionList = _ref => {
  let {
      items,
      hideMain,
      renderAdditional
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded2);
  const testIds = useTestIds(props);
  return __jsx(InvestorEntityList, testIds.base.attr, items.map(item => __jsx(InvestorEntityActionListItem, _extends({
    key: item === null || item === void 0 ? void 0 : item.id,
    testId: testIds.base.id,
    hideMain: hideMain,
    renderAdditional: renderAdditional
  }, item))));
};