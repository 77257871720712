var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { Currency, Heading, Paragraph } from '../..';
export const OfferingCardCustomData = _ref => {
  let {
    className,
    image,
    onClick,
    title,
    detailsList
  } = _ref;
  return __jsx(FlexWrapper, {
    "data-cy": "offering-overview-wrapper",
    className: className,
    onClick: onClick
  }, __jsx(ImgWrapper, {
    className: "OfferingOverview__ImgItem"
  }, __jsx(OfferingImageWrapper, null, image)), __jsx(InfoWrapper, null, __jsx(Heading, {
    type: 4
  }, title)), detailsList && __jsx(DetailsWrapper, null, detailsList.map(item => __jsx(DetailItem, null, __jsx(DetailLabel, {
    small: true
  }, item.label), __jsx(DetailDescription, {
    small: true,
    semiBold: true
  }, item.isCurrency ? __jsx(Currency, {
    value: parseFloat(item.value)
  }) : item.value)))));
};
const FlexWrapper = styled.div.withConfig({
  displayName: "OfferingCardCustomData__FlexWrapper",
  componentId: "sc-1wbc515-0"
})(["display:flex;flex-direction:column;width:auto;position:relative;z-index:2;padding:10px;background:", ";box-shadow:0px 4px 8px rgba(0,0,0,0.06);border-radius:2px;transition:box-shadow 0.3s;:hover{box-shadow:0px 4px 8px rgba(0,0,0,0.2);}min-height:376px;height:100%;max-width:376px;width:100%;margin:0 auto;"], props => props.theme.colors.background_card);
const ImgWrapper = styled.div.withConfig({
  displayName: "OfferingCardCustomData__ImgWrapper",
  componentId: "sc-1wbc515-1"
})(["position:relative;"]);
const OfferingImageWrapper = styled.div.withConfig({
  displayName: "OfferingCardCustomData__OfferingImageWrapper",
  componentId: "sc-1wbc515-2"
})(["position:relative;height:120px;"]);
const InfoWrapper = styled.div.withConfig({
  displayName: "OfferingCardCustomData__InfoWrapper",
  componentId: "sc-1wbc515-3"
})(["margin:20px 0;padding:0 10px;height:auto;display:flex;min-height:64px;"]);
const DetailsWrapper = styled.div.withConfig({
  displayName: "OfferingCardCustomData__DetailsWrapper",
  componentId: "sc-1wbc515-4"
})(["max-width:100%;padding:0 10px;margin:auto 0;"]);
const DetailItem = styled.div.withConfig({
  displayName: "OfferingCardCustomData__DetailItem",
  componentId: "sc-1wbc515-5"
})(["display:flex;justify-content:space-between;margin-bottom:10px;"]);
const DetailLabel = styled(Paragraph).withConfig({
  displayName: "OfferingCardCustomData__DetailLabel",
  componentId: "sc-1wbc515-6"
})([""]);
const DetailDescription = styled(Paragraph).withConfig({
  displayName: "OfferingCardCustomData__DetailDescription",
  componentId: "sc-1wbc515-7"
})(["text-align:right;"]);