import styled from 'styled-components';
import { media } from '../../themes';
export const ContentWrapepr = styled.div.withConfig({
  displayName: "PageStepstyle__ContentWrapepr",
  componentId: "sc-1a0kvjz-0"
})(["display:flex;flex-direction:column;align-items:center;text-align:center;gap:", ";max-width:375px;", ""], _ref => {
  let {
    theme
  } = _ref;
  return theme.spacing.sm;
}, media.tablet`
    width: 550px;
    max-width: unset;
  `);
export const ButtonWrapper = styled.div.withConfig({
  displayName: "PageStepstyle__ButtonWrapper",
  componentId: "sc-1a0kvjz-1"
})(["margin-top:", ";min-width:320px;"], _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.spacing.sm;
});
export const HeadingContainer = styled.div.withConfig({
  displayName: "PageStepstyle__HeadingContainer",
  componentId: "sc-1a0kvjz-2"
})([""]);
export const BarLabelWrapper = styled.div.withConfig({
  displayName: "PageStepstyle__BarLabelWrapper",
  componentId: "sc-1a0kvjz-3"
})(["text-align:center;"]);
export const ScrollContent = styled.div.withConfig({
  displayName: "PageStepstyle__ScrollContent",
  componentId: "sc-1a0kvjz-4"
})(["overflow:auto;scrollbar-width:none;width:inherit;"]);
export const BarWrapper = styled.div.withConfig({
  displayName: "PageStepstyle__BarWrapper",
  componentId: "sc-1a0kvjz-5"
})(["margin:", ";display:flex;flex-direction:column;gap:", ";text-align:center;width:500px;", " ", ""], _ref3 => {
  let {
    theme
  } = _ref3;
  return `188px ${theme.spacing.m} ${theme.spacing.m} ${theme.spacing.m}`;
}, _ref4 => {
  let {
    theme
  } = _ref4;
  return theme.spacing.ml;
}, media.phoneLandscape`
    margin-right: auto;
    margin-left: auto;
  `, media.tablet`
    width: 648px;
    margin: ${_ref5 => {
  let {
    theme
  } = _ref5;
  return `240px auto ${theme.spacing.m} auto`;
}};
  `);
export const PageStepContainer = styled.div.withConfig({
  displayName: "PageStepstyle__PageStepContainer",
  componentId: "sc-1a0kvjz-6"
})(["align-items:center;display:flex;flex-direction:column;justify-content:center;min-height:calc(100vh - 74px);width:100%;padding-top:", ";padding-bottom:", ";", ""], _ref6 => {
  let {
    theme
  } = _ref6;
  return theme.spacing.ml;
}, _ref7 => {
  let {
    theme
  } = _ref7;
  return theme.spacing.ml;
}, media.tablet`
    padding-top: ${_ref8 => {
  let {
    theme
  } = _ref8;
  return theme.spacing['2xl'];
}};
    padding-bottom: ${_ref9 => {
  let {
    theme
  } = _ref9;
  return theme.spacing['2xl'];
}};
  `);