import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["ratioY", "ratioX", "rounded", "filled"];
var __jsx = React.createElement;
import React from 'react';
import { StyledSkeletonThumbnail } from './Skeleton.style';
export const SkeletonThumbnail = _ref => {
  let {
      ratioY = 1,
      ratioX = 1,
      rounded = false,
      filled = true
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  return __jsx(StyledSkeletonThumbnail, _extends({
    ratioY: ratioY,
    ratioX: ratioX,
    rounded: rounded,
    filled: filled
  }, props));
};