import _Typography from "@mui/material/Typography";
import _Link from "@mui/material/Link";
var __jsx = React.createElement;
import React, { useMemo, useRef, useState } from 'react';
import { NewestModal } from '@yieldstreet/ui-kit';
import { Modals } from 'constants/modal.model';
import { Wrapper, Content, Menu, Title, FAQContainer, FAQ, ContentContainer, Divider } from './CapitalCallsModal.style';
import { CapitalCallFAQ } from './CapitalCallsModal.model';
export const CapitalCallsModal = _ref => {
  let {
    onClose
  } = _ref;
  const questionsRefs = useRef([]);
  const {
    0: activeQuestion,
    1: setActiveQuestion
  } = useState(null);
  const scrollToQuestion = index => {
    if (questionsRefs.current[index]) {
      var _questionsRefs$curren, _questionsRefs$curren2;
      setActiveQuestion(index);
      questionsRefs === null || questionsRefs === void 0 ? void 0 : (_questionsRefs$curren = questionsRefs.current) === null || _questionsRefs$curren === void 0 ? void 0 : (_questionsRefs$curren2 = _questionsRefs$curren[index]) === null || _questionsRefs$curren2 === void 0 ? void 0 : _questionsRefs$curren2.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
    }
  };
  const MenuComponent = useMemo(() => {
    return CapitalCallFAQ.map((faq, index) => {
      return __jsx(_Link, {
        key: `${faq.question}-menu`,
        onClick: () => scrollToQuestion(index),
        sx: theme => ({
          fontWeight: index === activeQuestion ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular,
          width: 'fit-content'
        })
      }, faq.question);
    });
  }, [activeQuestion]);
  const FAQComponent = useMemo(() => {
    return CapitalCallFAQ.map((faq, index) => {
      return __jsx(FAQ, {
        key: faq.question,
        ref: el => questionsRefs.current[index] = el
      }, __jsx(_Typography, {
        component: "h1",
        variant: "h6"
      }, faq.question), faq.answer.map(answer => __jsx(_Typography, {
        variant: "body1",
        key: answer
      }, answer)));
    });
  }, []);
  return __jsx(NewestModal, {
    modalId: Modals.CapitalCallInfo,
    onClose: onClose
  }, __jsx(Wrapper, null, __jsx(ContentContainer, null, __jsx(Menu, null, MenuComponent), __jsx(Content, null, __jsx(Title, null, __jsx(_Typography, {
    component: "h1",
    variant: "h3"
  }, "About Capital Calls"), __jsx(_Typography, {
    variant: "body1"
  }, "A capital call is the process by which a fund manager asks the fund investors to contribute their pro rata portion of their fund commitments.")), __jsx(Divider, null), __jsx(FAQContainer, null, FAQComponent)))));
};