import { parse } from 'query-string';
import get from 'lodash/get';
import pick from 'lodash/pick';
export const defaultTrackingParams = ['gclid', 'campaign_id', 'ad_id', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
export const parseQueryTrackingParams = function (queryString) {
  let selectedParams = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : defaultTrackingParams;
  const params = parse(queryString);
  return pick(params, selectedParams);
};
export const storeTrackingMeta = trackingParams => {
  Object.keys(trackingParams).forEach(paramKey => {
    const paramValue = get(trackingParams, [paramKey], '');
    try {
      localStorage.setItem(paramKey, paramValue);
      return true;
    } catch (exception) {
      return false;
    }
  });
};
const getMeta = trackingParams => {
  const trackingMeta = [];
  trackingParams.forEach(param => {
    try {
      const value = localStorage.getItem(param);
      if (value && value.length) {
        trackingMeta.push({
          key: param,
          value
        });
      }
      return true;
    } catch (exception) {
      return false;
    }
  });
  return trackingMeta;
};
export const getTrackingMeta = function () {
  let customParams = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : defaultTrackingParams;
  return getMeta(customParams);
};