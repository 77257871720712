import { HttpMethods } from '../../enums/http';
import { restApi, Tags } from '../common';
export const getRepresentativeApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.Representative]
}).injectEndpoints({
  endpoints: build => {
    const getRepresentative = build.query({
      query: () => {
        return {
          url: `/a/api/representative/current`,
          method: HttpMethods.Get
        };
      },
      providesTags: [Tags.Representative]
    });
    return {
      getRepresentative
    };
  }
});