import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var __jsx = React.createElement;
import React, { useCallback, useState } from 'react';
import moment from 'moment';
import isEmpty from "lodash/isEmpty";
import { Formik, Form } from 'formik';
import { useHistory } from 'react-router-dom';
import { Button, FormTextInput, FormDocumentTextInput, SelectInput, PageLoader, CardRow } from '@yieldstreet/ui-kit';
import { createYupObjectSchema } from '@yieldstreet/tool-kit';
import { onCreateAccount } from '../../../../accountSettings/CreateInvestorAccount/createInvestorAccountThirdPartyCode/createInvestorAccountThirdPartyCode';
import { FormWrapper } from '../../../CreateInvestorAccount.style';
import { CardWrapper } from '../Name.style';
import { FormFields } from './IraSourceAccount.style';
import { userFields, formFields, testIds } from './IraSourceAccount.model';
import { useUserState } from 'utils/hooks';
const FormSchema = createYupObjectSchema({
  employerName: {
    validationType: 'extended-name'
  },
  ssn: {
    validationType: 'document'
  }
});
const userDataFields = (userData, editIraForm) => [{
  title: userFields.fullName,
  subTitle: userData.name,
  onClick: () => editIraForm(),
  linkText: userFields.linkText
}, {
  title: userFields.address,
  subTitle: [userData.address.addressLine1, userData.address.addressLine2, `${userData.address.city}, ${userData.address.state} ${userData.address.postalCode}`]
}, {
  title: userFields.birth,
  subTitle: moment(userData === null || userData === void 0 ? void 0 : userData.birthDate).format('MM/DD/YYYY')
}];
const mapAccountOptions = accounts => accounts.map((acc, idx) => ({
  label: acc.name,
  value: `${idx}`
}));
export const IraSourceAccount = _ref => {
  let {
    userIraSourceAccounts,
    iraType,
    editIraForm,
    createIraAccount,
    copyIraAccount,
    isCreatingIraAccount,
    isCopyingIraAccount
  } = _ref;
  const history = useHistory();
  const {
    accreditedSignUp
  } = useUserState();
  const {
    0: selectedAccount,
    1: setSelectedAccount
  } = useState(null);
  const {
    0: selectedIdx,
    1: setSelectedIdx
  } = useState(null);
  if (userIraSourceAccounts && userIraSourceAccounts.length === 1 && !selectedAccount) {
    setSelectedAccount(userIraSourceAccounts[0]);
  }
  const createAccount = useCallback(values => {
    createIraAccount({
      type: iraType
    }).then(response => {
      if (response !== null && response !== void 0 && response.data.success) {
        const investorId = response === null || response === void 0 ? void 0 : response.data.generatedId;
        const body = _objectSpread(_objectSpread({}, values), {}, {
          investorId,
          individualAccountId: selectedAccount.id,
          ssn: values.ssn
        });
        copyIraAccount(body).then(() => {
          onCreateAccount(accreditedSignUp);
          history.push(`/create-investor-account/ira/${investorId}/pricing`);
        });
      } else {
        return null;
      }
    });
  }, [createIraAccount, iraType, selectedAccount, copyIraAccount, accreditedSignUp, history]);
  const mappedAccounts = !isEmpty(userIraSourceAccounts) && mapAccountOptions(userIraSourceAccounts);
  return mappedAccounts ? __jsx(FormWrapper, testIds.formWrapper.attr, userIraSourceAccounts.length > 1 && __jsx(SelectInput, {
    options: mappedAccounts,
    onChange: value => {
      setSelectedAccount(userIraSourceAccounts[value]);
      setSelectedIdx(value);
    },
    label: formFields.investorAccount,
    fullWidth: true,
    value: selectedIdx
  }), selectedAccount && __jsx(CardWrapper, null, __jsx(CardRow, {
    options: userDataFields(selectedAccount, editIraForm)
  })), __jsx("div", null, selectedAccount && __jsx(Formik, {
    initialValues: {
      employerName: '',
      ssn: ''
    },
    validationSchema: FormSchema,
    onSubmit: values => createAccount(values)
  }, formikProps => {
    return __jsx(Form, null, selectedAccount && __jsx(FormFields, null, __jsx(FormTextInput, {
      type: "text",
      name: formFields.employerName.name,
      label: formFields.employerName.label,
      formikProps: formikProps,
      testId: testIds.employerNameField.id
    }), __jsx(FormDocumentTextInput, {
      name: formFields.ssn.name,
      label: formFields.ssn.label,
      format: "###-##-####",
      formikProps: formikProps,
      testId: testIds.ssnField.id
    })), __jsx(Button, _extends({
      loading: isCreatingIraAccount || isCopyingIraAccount,
      trackingId: testIds.submitButton.id
    }, testIds.submitButton.attr, {
      type: "submit",
      disabled: !selectedAccount,
      fullWidth: true
    }), formFields.submitButton));
  }))) : __jsx(PageLoader, null);
};