import { PortfolioStrategyValues, investorEntityApi, investmentApi, allToken } from '@yieldstreet/platform-kit';
import find from "lodash/find";
import map from "lodash/map";
import flattenDeep from "lodash/flattenDeep";
import filter from "lodash/filter";
import includes from "lodash/includes";
import { useMemo } from 'react';
import { ALL_ACCOUNTS } from './useSelectInvestorAccounts.model';
const {
  useGetInvestorEntityQuery
} = investorEntityApi;
const {
  useManagedPortfolioEligibilityQuery
} = investmentApi;
export const useSelectInvestorAccounts = _ref => {
  var _investorEntityData$e;
  let {
    investorId,
    managementStrategy = PortfolioStrategyValues.Self
  } = _ref;
  const {
    data: investorEntityData,
    isFetching,
    isError: investorEntityDataIsError,
    isSuccess: investorEntityDataIsSuccess,
    refetch
  } = useGetInvestorEntityQuery(allToken);
  const hasManagedInvestorAccounts = investorEntityData === null || investorEntityData === void 0 ? void 0 : (_investorEntityData$e = investorEntityData.entities) === null || _investorEntityData$e === void 0 ? void 0 : _investorEntityData$e.some(entity => entity === null || entity === void 0 ? void 0 : entity.investorAccounts.some(account => account.managementStrategy === PortfolioStrategyValues.Discretionary));
  const {
    data: managedPortfolioEligibility,
    isFetching: managedPortfolioEligibilityIsFetching,
    isError: managedPortfolioEligibilityIsError,
    isSuccess: managedPortfolioEligibilityIsSuccess
  } = useManagedPortfolioEligibilityQuery(allToken, {
    skip: !hasManagedInvestorAccounts
  });
  const managedPortfoliosInvestorEntities = useMemo(() => {
    var _managedPortfolioElig;
    return managedPortfolioEligibility === null || managedPortfolioEligibility === void 0 ? void 0 : (_managedPortfolioElig = managedPortfolioEligibility.eligibility) === null || _managedPortfolioElig === void 0 ? void 0 : _managedPortfolioElig.investorEntities.reduce((acc, investorEntity) => {
      acc.push(investorEntity.investorEntityId.toString());
      return acc;
    }, []);
  }, [managedPortfolioEligibility]);
  const investorEntities = useMemo(() => {
    if (managementStrategy === PortfolioStrategyValues.Discretionary) {
      return filter(investorEntityData === null || investorEntityData === void 0 ? void 0 : investorEntityData.entities, entity => {
        const entityId = entity.id.toString();
        return includes(managedPortfoliosInvestorEntities, entityId);
      });
    }
    return investorEntityData === null || investorEntityData === void 0 ? void 0 : investorEntityData.entities;
  }, [investorEntityData, managedPortfoliosInvestorEntities, managementStrategy]);
  const filterInvestorAccounts = _ref2 => {
    let {
      investorAccounts,
      managementStrategy
    } = _ref2;
    return filter(investorAccounts, {
      managementStrategy
    });
  };
  const investorAccounts = useMemo(() => {
    return flattenDeep(map(investorEntities, investorEntity => map(filterInvestorAccounts({
      investorAccounts: investorEntity.investorAccounts,
      managementStrategy
    }), account => ({
      id: `${account.id}`,
      name: investorEntity.name,
      investorEntityId: investorEntity.id
    }))));
  }, [investorEntities, managementStrategy]);
  const hasValidManagedInvestorEntities = useMemo(() => {
    var _managed$map;
    const managed = filter(investorEntities, entity => {
      const entityId = entity.id.toString();
      return includes(managedPortfoliosInvestorEntities, entityId);
    });
    const accounts = (_managed$map = managed === null || managed === void 0 ? void 0 : managed.map(ent => ent === null || ent === void 0 ? void 0 : ent.investorAccounts)) !== null && _managed$map !== void 0 ? _managed$map : [];
    return accounts.some(account => account.some(acct => (acct === null || acct === void 0 ? void 0 : acct.managementStrategy) === PortfolioStrategyValues.Discretionary));
  }, [investorEntities, managedPortfoliosInvestorEntities]);
  const {
    hasMultipleInvestorAccounts,
    hasInvestorAccounts,
    hasMultipleInvestorEntities
  } = useMemo(() => {
    var _flattenDeep, _investorEntityData$e2;
    return {
      hasInvestorAccounts: investorAccounts.length > 0,
      hasMultipleInvestorAccounts: investorAccounts.length > 1,
      hasMultipleInvestorEntities: ((_flattenDeep = flattenDeep(investorEntityData === null || investorEntityData === void 0 ? void 0 : (_investorEntityData$e2 = investorEntityData.entities) === null || _investorEntityData$e2 === void 0 ? void 0 : _investorEntityData$e2.map(entity => {
        var _entity$investorAccou;
        return (entity === null || entity === void 0 ? void 0 : (_entity$investorAccou = entity.investorAccounts) === null || _entity$investorAccou === void 0 ? void 0 : _entity$investorAccou.filter(account => (account === null || account === void 0 ? void 0 : account.managementStrategy) === managementStrategy)) || [];
      }))) === null || _flattenDeep === void 0 ? void 0 : _flattenDeep.length) > 1
    };
  }, [investorAccounts.length, investorEntityData === null || investorEntityData === void 0 ? void 0 : investorEntityData.entities, managementStrategy]);
  const investorAccountList = useMemo(() => [...(hasMultipleInvestorAccounts && managementStrategy === PortfolioStrategyValues.Self ? [ALL_ACCOUNTS] : []), ...investorAccounts], [hasMultipleInvestorAccounts, managementStrategy, investorAccounts]);
  const selectedInvestorAccount = useMemo(() => find(investorAccountList, {
    id: investorId
  }) || investorAccountList[0], [investorId, investorAccountList]);
  return {
    isSuccess: investorEntityDataIsSuccess && (hasManagedInvestorAccounts && managedPortfolioEligibilityIsSuccess || !hasManagedInvestorAccounts),
    isLoading: isFetching || managedPortfolioEligibilityIsFetching,
    isError: investorEntityDataIsError || managedPortfolioEligibilityIsError,
    refetch,
    investorEntities,
    hasInvestorAccounts,
    hasValidManagedInvestorEntities,
    hasMultipleInvestorAccounts,
    hasMultipleInvestorEntities,
    investorAccountList,
    selectedInvestorAccount
  };
};