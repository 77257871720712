var __jsx = React.createElement;
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useBreakpoints } from '@yieldstreet/ui-kit/utils';
import { TransactionsListDesktopDrawer } from './TransactionsListDesktopDrawer';
import { TransactionsListMobileDrawer } from './TransactionsListMobileDrawer';
export const TransactionsListDrawer = _ref => {
  let {
    drawerData,
    open,
    onOpen,
    onClose
  } = _ref;
  const history = useHistory();
  const {
    isLgScreen,
    isXlScreen
  } = useBreakpoints();
  if (isLgScreen || isXlScreen) {
    return __jsx(TransactionsListDesktopDrawer, {
      drawerData: drawerData,
      open: open,
      history: history,
      onClose: onClose
    });
  }
  return __jsx(TransactionsListMobileDrawer, {
    drawerData: drawerData,
    open: open,
    history: history,
    onOpen: onOpen,
    onClose: onClose
  });
};