var __jsx = React.createElement;
import React from 'react';
import { WidgetSurfaceWrapper } from './WidgetSurface.style';
export const WidgetSurface = _ref => {
  let {
    children,
    className,
    testId,
    type,
    withPadding
  } = _ref;
  return __jsx(WidgetSurfaceWrapper, {
    className: className,
    testId: testId,
    type: type,
    withPadding: withPadding
  }, children);
};