import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import mapValues from 'lodash/mapValues';
import { anonymizerRegister } from './register';
export const anonymizeString = value => value ? value.replace(/[0-9a-z]/gi, '*') : '';
const isAnonymizeTypeMap = type => isObject(type);
const isAnonymizeKey = type => isString(type);
const isObjectAnonymizeKey = type => type in anonymizerRegister;
const isStructure = type => isArray(type);
const isArrayStructure = type => type[0] === 'array';
const getObjectAnonymizer = type => {
  return anonymizerRegister[type];
};
const anonymizeValue = (value, type) => {
  if (isNil(value)) {
    return value;
  } else if (isStructure(type)) {
    return anonymizeStructure(value, type);
  } else if (isAnonymizeTypeMap(type)) {
    return anonymizeObject(value, type);
  } else if (isAnonymizeKey(type)) {
    if (isObjectAnonymizeKey(type)) {
      const anonymizeType = getObjectAnonymizer(type);
      return anonymizeType(value);
    } else {
      return anonymizeString(value);
    }
  }
};
const anonymizeStructure = (value, structure) => {
  if (isArrayStructure(structure)) {
    return value.map(value => anonymizeValue(value, structure[1]));
  } else {
    return mapValues(value, value => anonymizeValue(value, structure[1]));
  }
};
export const anonymizeObject = (object, types) => {
  if (!isObject(object)) {
    return object;
  }
  const result = _objectSpread({}, object);
  Object.keys(types).forEach(key => {
    const value = object[key];
    const type = types[key];
    if (!isNil(value)) {
      result[key] = anonymizeValue(value, type);
    }
  });
  return result;
};