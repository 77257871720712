import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { allToken, userApi } from '@yieldstreet/platform-kit';
export const useUserState = () => {
  const {
    data: userState
  } = userApi.useGetUserStateQuery(allToken);
  return userState || {};
};
export const withUserState = Component => {
  return props => {
    const userState = useUserState();
    return __jsx(Component, _extends({
      userState: userState
    }, props));
  };
};