import update from 'immutability-helper';
import * as actions from 'store/actions/portfolioTransactions';
export const initialState = {
  data: {},
  isFetching: false,
  error: false,
  payments: {},
  paymentsFetching: false
};
const portfolioTransactions = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case actions.PORTFOLIO_GET_TRANSACTIONS_PAYMENTS_ATTEMPT:
      {
        return update(state, {
          paymentsFetching: {
            $set: true
          },
          error: {
            $set: false
          }
        });
      }
    case actions.PORTFOLIO_GET_TRANSACTIONS_PAYMENTS_SUCCESS:
      {
        return update(state, {
          payments: {
            $merge: action.response
          },
          paymentsFetching: {
            $set: false
          },
          error: {
            $set: false
          }
        });
      }
    case actions.PORTFOLIO_GET_TRANSACTIONS_PAYMENTS_FAILURE:
      {
        return update(state, {
          paymentsFetching: {
            $set: false
          },
          error: {
            $set: true
          }
        });
      }
    default:
      return state;
  }
};
export default portfolioTransactions;