import styled, { css } from 'styled-components';
import { FormTextInput, Button, media, FormRadioGroupInput } from '@yieldstreet/ui-kit';
export const InputWrapper = styled.div.withConfig({
  displayName: "ControlCorporationsstyle__InputWrapper",
  componentId: "sc-1nzyvpp-0"
})(["display:flex;column-gap:", ";"], _ref => {
  let {
    theme
  } = _ref;
  return theme.spacing.xs;
});
export const SFormTextInput = styled(FormTextInput).withConfig({
  displayName: "ControlCorporationsstyle__SFormTextInput",
  componentId: "sc-1nzyvpp-1"
})(["width:100%;"]);
const StyledButton = css(["background-color:transparent;border:none;&:hover{background-color:transparent;border:none;}"]);
export const AddButton = styled(Button).withConfig({
  displayName: "ControlCorporationsstyle__AddButton",
  componentId: "sc-1nzyvpp-2"
})(["", ";font-weight:400;padding-left:0;padding-right:0;"], StyledButton);
export const DeleteButton = styled(Button).withConfig({
  displayName: "ControlCorporationsstyle__DeleteButton",
  componentId: "sc-1nzyvpp-3"
})(["", ";margin:auto;padding:0;min-width:19px;"], StyledButton);
export const DeleteButtonWrapper = styled.div.withConfig({
  displayName: "ControlCorporationsstyle__DeleteButtonWrapper",
  componentId: "sc-1nzyvpp-4"
})(["margin-bottom:10px;display:flex;"]);
export const Icon = styled.img.withConfig({
  displayName: "ControlCorporationsstyle__Icon",
  componentId: "sc-1nzyvpp-5"
})(["display:block;"]);
export const SFormRadioGroupInput = styled(FormRadioGroupInput).withConfig({
  displayName: "ControlCorporationsstyle__SFormRadioGroupInput",
  componentId: "sc-1nzyvpp-6"
})([".MuiFormControl-root{margin-bottom:0;padding:0 !important;}.MuiFormControlLabel-root{padding:10px 30px 10px 40px !important;", "}"], media.tablet`
    padding: 20px 30px 20px 40px !important;
  `);