import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { NumericFormat } from 'react-number-format';
import handleNumber from 'sharedComponents/utils/handleNumber';
export const NumberFormat = props => {
  if (props.displayType === 'text') {
    const newValue = handleNumber(props.value, props.decimalScale);
    return __jsx(NumericFormat, _extends({}, props, {
      value: newValue
    }));
  }
  return __jsx(NumericFormat, props);
};