import { InvestmentType } from './types';
export const PortfolioConfig = [{
  title: 'Income',
  subtitle: 'Prioritize current income',
  investments: [{
    name: 'Short Term Notes',
    defaultAmount: 10000,
    rate: 4,
    term: 6,
    termSuffix: 'months',
    type: InvestmentType.MONTHLY
  }, {
    name: 'Supply Chain',
    defaultAmount: 10000,
    rate: 7.8,
    term: 6,
    termSuffix: 'months',
    type: InvestmentType.MONTHLY
  }, {
    name: 'Real Estate',
    defaultAmount: 10000,
    rate: 8.5,
    term: 36,
    termSuffix: 'months',
    type: InvestmentType.MONTHLY
  }]
}, {
  title: 'Growth',
  subtitle: 'Prioritize returns',
  investments: [{
    name: 'Crypto',
    defaultAmount: 10000,
    rate: 25,
    term: 12,
    termSuffix: 'months',
    type: InvestmentType.ENDOFTERM
  }, {
    name: 'Real Estate Equity',
    defaultAmount: 10000,
    rate: 14,
    term: 48,
    termSuffix: 'months',
    type: InvestmentType.ENDOFTERM
  }, {
    name: 'Art Equity',
    defaultAmount: 10000,
    rate: 18,
    term: 60,
    termSuffix: 'months',
    type: InvestmentType.ENDOFTERM
  }]
}, {
  title: 'Balanced',
  subtitle: 'Blend income and growth',
  investments: [{
    name: 'Supply Chain',
    defaultAmount: 10000,
    rate: 7.8,
    term: 6,
    termSuffix: 'months',
    type: InvestmentType.MONTHLY
  }, {
    name: 'Art Equity',
    defaultAmount: 10000,
    rate: 18,
    term: 60,
    termSuffix: 'months',
    type: InvestmentType.ENDOFTERM
  }, {
    name: 'Real Estate Equity',
    defaultAmount: 10000,
    rate: 15,
    lowerRate: 5.5,
    term: 60,
    termSuffix: 'months',
    type: InvestmentType.MIXED
  }]
}];