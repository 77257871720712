import includes from 'lodash/includes';
import { InvestorEntityStepVerificationStatus } from '@yieldstreet/platform-kit';
export const isInvestorEntityStepDone = function (stepDetails) {
  let confirmedOnly = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  return confirmedOnly ? stepDetails.status === InvestorEntityStepVerificationStatus.CONFIRMED : includes([InvestorEntityStepVerificationStatus.PENDING, InvestorEntityStepVerificationStatus.CONFIRMED], stepDetails.status);
};
export const isInvestorEntityStepPending = stepDetails => {
  return stepDetails.status === InvestorEntityStepVerificationStatus.PENDING;
};
export const isAccreditedInvestorEntityStepDone = (stepDetails, accreditedSignUp) => {
  return accreditedSignUp && isInvestorEntityStepDone(stepDetails) || !accreditedSignUp;
};