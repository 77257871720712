export const MuiAlert = {
  defaultProps: {
    variant: 'standard'
  },
  styleOverrides: {
    root: () => ({
      fontWeight: '400',
      variants: [{
        props: {
          severity: 'success',
          variant: 'standard'
        },
        style: _ref => {
          var _theme$palette$Alert, _theme$palette$Alert$, _theme$palette$Alert$2, _theme$palette$Alert2, _theme$palette$Alert3, _theme$palette$Alert4;
          let {
            theme
          } = _ref;
          return {
            backgroundColor: (_theme$palette$Alert = theme.palette.Alert) === null || _theme$palette$Alert === void 0 ? void 0 : (_theme$palette$Alert$ = _theme$palette$Alert.standard) === null || _theme$palette$Alert$ === void 0 ? void 0 : (_theme$palette$Alert$2 = _theme$palette$Alert$.success) === null || _theme$palette$Alert$2 === void 0 ? void 0 : _theme$palette$Alert$2.background,
            color: (_theme$palette$Alert2 = theme.palette.Alert) === null || _theme$palette$Alert2 === void 0 ? void 0 : (_theme$palette$Alert3 = _theme$palette$Alert2.standard) === null || _theme$palette$Alert3 === void 0 ? void 0 : (_theme$palette$Alert4 = _theme$palette$Alert3.success) === null || _theme$palette$Alert4 === void 0 ? void 0 : _theme$palette$Alert4.color
          };
        }
      }, {
        props: {
          severity: 'info',
          variant: 'standard'
        },
        style: _ref2 => {
          var _theme$palette$Alert5, _theme$palette$Alert6, _theme$palette$Alert7, _theme$palette$Alert8, _theme$palette$Alert9, _theme$palette$Alert10;
          let {
            theme
          } = _ref2;
          return {
            backgroundColor: (_theme$palette$Alert5 = theme.palette.Alert) === null || _theme$palette$Alert5 === void 0 ? void 0 : (_theme$palette$Alert6 = _theme$palette$Alert5.standard) === null || _theme$palette$Alert6 === void 0 ? void 0 : (_theme$palette$Alert7 = _theme$palette$Alert6.info) === null || _theme$palette$Alert7 === void 0 ? void 0 : _theme$palette$Alert7.background,
            color: (_theme$palette$Alert8 = theme.palette.Alert) === null || _theme$palette$Alert8 === void 0 ? void 0 : (_theme$palette$Alert9 = _theme$palette$Alert8.standard) === null || _theme$palette$Alert9 === void 0 ? void 0 : (_theme$palette$Alert10 = _theme$palette$Alert9.info) === null || _theme$palette$Alert10 === void 0 ? void 0 : _theme$palette$Alert10.color
          };
        }
      }, {
        props: {
          severity: 'warning',
          variant: 'standard'
        },
        style: _ref3 => {
          var _theme$palette$Alert11, _theme$palette$Alert12, _theme$palette$Alert13, _theme$palette$Alert14, _theme$palette$Alert15, _theme$palette$Alert16;
          let {
            theme
          } = _ref3;
          return {
            backgroundColor: (_theme$palette$Alert11 = theme.palette.Alert) === null || _theme$palette$Alert11 === void 0 ? void 0 : (_theme$palette$Alert12 = _theme$palette$Alert11.standard) === null || _theme$palette$Alert12 === void 0 ? void 0 : (_theme$palette$Alert13 = _theme$palette$Alert12.warning) === null || _theme$palette$Alert13 === void 0 ? void 0 : _theme$palette$Alert13.background,
            color: (_theme$palette$Alert14 = theme.palette.Alert) === null || _theme$palette$Alert14 === void 0 ? void 0 : (_theme$palette$Alert15 = _theme$palette$Alert14.standard) === null || _theme$palette$Alert15 === void 0 ? void 0 : (_theme$palette$Alert16 = _theme$palette$Alert15.warning) === null || _theme$palette$Alert16 === void 0 ? void 0 : _theme$palette$Alert16.color
          };
        }
      }, {
        props: {
          severity: 'error',
          variant: 'standard'
        },
        style: _ref4 => {
          var _theme$palette$Alert17, _theme$palette$Alert18, _theme$palette$Alert19, _theme$palette$Alert20, _theme$palette$Alert21, _theme$palette$Alert22;
          let {
            theme
          } = _ref4;
          return {
            backgroundColor: (_theme$palette$Alert17 = theme.palette.Alert) === null || _theme$palette$Alert17 === void 0 ? void 0 : (_theme$palette$Alert18 = _theme$palette$Alert17.standard) === null || _theme$palette$Alert18 === void 0 ? void 0 : (_theme$palette$Alert19 = _theme$palette$Alert18.error) === null || _theme$palette$Alert19 === void 0 ? void 0 : _theme$palette$Alert19.background,
            color: (_theme$palette$Alert20 = theme.palette.Alert) === null || _theme$palette$Alert20 === void 0 ? void 0 : (_theme$palette$Alert21 = _theme$palette$Alert20.standard) === null || _theme$palette$Alert21 === void 0 ? void 0 : (_theme$palette$Alert22 = _theme$palette$Alert21.error) === null || _theme$palette$Alert22 === void 0 ? void 0 : _theme$palette$Alert22.color
          };
        }
      }, {
        props: {
          severity: 'success',
          variant: 'filled'
        },
        style: _ref5 => {
          var _theme$palette$Alert23, _theme$palette$Alert24, _theme$palette$Alert25;
          let {
            theme
          } = _ref5;
          return {
            color: (_theme$palette$Alert23 = theme.palette.Alert) === null || _theme$palette$Alert23 === void 0 ? void 0 : (_theme$palette$Alert24 = _theme$palette$Alert23.filled) === null || _theme$palette$Alert24 === void 0 ? void 0 : (_theme$palette$Alert25 = _theme$palette$Alert24.success) === null || _theme$palette$Alert25 === void 0 ? void 0 : _theme$palette$Alert25.color
          };
        }
      }]
    })
  }
};