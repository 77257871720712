import { UserGateCode, InvestorEntityGateCode, InvestorAccountGateCode, PortfolioStrategyValues } from '@yieldstreet/platform-kit';
import { EligibilityGateLevel } from './eligibilityGateContent.model';
import { getInvestorEntityAppAction, getInvestorEntityType } from '../hooks';
import { AppAction, appActionManager } from '../app-actions';
const NEVER = {
  title: 'Account ineligible',
  description: 'Unfortunately, your account is not eligible for a managed portfolio. Please contact our support.'
};
const ERROR = {
  title: 'We’re sorry',
  description: 'Unfortunately, there was an error checking your eligibility for our offerings. Please contact our support.'
};
export const MANAGED_PORTFOLIO_GATE_CONTENT = {
  [EligibilityGateLevel.USER]: investorEntityGate => ({
    [UserGateCode.SELF_ACCREDITATION]: {
      title: 'Open to accredited investors only',
      description: 'Managed portfolios are open to accredited investors. If you are an accredited investor, please contact Investor Relations.',
      blocking: investorEntityGate === null || investorEntityGate === void 0 ? void 0 : investorEntityGate.blocking
    },
    [UserGateCode.NO_INVESTOR_ENTITY]: {
      title: 'Finish account set-up',
      description: 'Before you can invest in a managed portfolio, you need to create an investor account.',
      action: appActionManager.getAction(AppAction.CREATE_INVESTOR_ENTITY, {})
    },
    [UserGateCode.INVESTOR_ENTITY_GATE]: {
      title: 'Finish account set-up',
      description: 'Your investor account is not ready to invest. Before you can invest in a managed portfolio, you need to verify your identity. Your investor account is not ready to invest. Before we can allocate your funds to a managed portfolio, you need to link an external bank. ',
      action: appActionManager.getAction(AppAction.SETUP_PORTFOLIO, {})
    },
    [UserGateCode.NEVER]: NEVER,
    [UserGateCode.ERROR]: ERROR
  }),
  [EligibilityGateLevel.INVESTOR_ENTITY]: (investorEntity, investorEntityStatus, investorEntityGate) => ({
    [InvestorEntityGateCode.INVESTOR_ENTITY_TYPE]: {
      title: 'Open to individual accounts',
      description: 'Managed portfolios are open to individual accounts only. You can create an individual account from the Accounts screen.',
      blocking: investorEntityGate.blocking
    },
    [InvestorEntityGateCode.INVESTOR_ENTITY_ACTION]: _ref => {
      let {
        attributes: {
          action: {
            code
          }
        }
      } = _ref;
      return {
        title: 'Finish account set-up',
        description: 'Your investor account is not ready to invest. Before you can invest in a managed portfolio, you need to verify your identity. Your investor account is not ready to invest. Before we can allocate your funds to a managed portfolio, you need to link an external bank.',
        blocking: investorEntityGate.blocking,
        action: code ? getInvestorEntityAppAction(code, investorEntity, investorEntityStatus) : undefined
      };
    },
    [InvestorEntityGateCode.MISSING_EXTRA_KYC_INFO]: {
      title: 'Finish account set-up',
      description: 'Your investor account is not ready to invest. Before you can invest in a managed portfolio, you need to verify your identity.',
      action: appActionManager.getAction(AppAction.SUBMIT_EXTRA_KYC_INFO, {
        investorEntityId: investorEntity.id
      }),
      blocking: investorEntityGate.blocking
    },
    [InvestorEntityGateCode.KYC_INELIGIBLE]: {
      title: 'Account ineligible',
      description: 'Unfortunately, your account is not eligible for a managed portfolio. Please contact Investor Relations.',
      action: appActionManager.getAction(AppAction.UNSUPPORTED_MANAGED_PORTFOLIO, {
        investorEntityId: investorEntity.id
      }),
      blocking: investorEntityGate.blocking
    },
    [InvestorEntityGateCode.MISSING_MANAGED_INVESTOR_ACCOUNT]: {
      title: 'Finish account set-up',
      description: 'Before you can invest in a managed portfolio, you need to create an investor account.',
      action: appActionManager.getAction(AppAction.CREATE_INVESTOR_ACCOUNT, {
        investorEntityType: getInvestorEntityType(investorEntity),
        investorEntityId: investorEntity.id,
        managementStrategy: PortfolioStrategyValues.Discretionary
      }),
      blocking: investorEntityGate.blocking
    },
    [InvestorEntityGateCode.MISSING_MANAGED_PORTFOLIO_ANSWERS]: {
      title: 'Finish account set-up',
      description: 'Before you can invest in a managed portfolio, you need to fully complete the suitability questionnaire',
      blocking: investorEntityGate.blocking,
      action: appActionManager.getAction(AppAction.SUBMIT_MANAGED_PORTFOLIO_ANSWERS, {
        investorEntityId: investorEntity.id
      })
    },
    [InvestorEntityGateCode.MISSING_MANAGED_PORTFOLIO_STRATEGY]: {
      title: 'Finish account set-up',
      description: 'Before you can invest in a managed portfolio, you need to select one of the investment strategies.',
      action: appActionManager.getAction(AppAction.SUBMIT_MANAGED_PORTFOLIO_STRATEGY, {
        investorEntityId: investorEntity.id
      }),
      blocking: investorEntityGate.blocking
    },
    [InvestorEntityGateCode.THIRD_PARTY_ACTION]: {
      title: 'Finish account set-up',
      description: 'Before you can invest in a managed portfolio, you need to provide our third-party custodial partner with additional identity verification information.',
      action: appActionManager.getAction(AppAction.SUBMIT_THIRD_PARTY_KYC, {
        investorEntityId: investorEntity.id
      }),
      blocking: investorEntityGate.blocking
    },
    [InvestorEntityGateCode.INVESTOR_ACCOUNT_GATE]: {
      title: 'Finish account set-up',
      description: '',
      action: appActionManager.getAction(AppAction.COMPLETE_SETUP, {
        investorEntityId: investorEntity.id
      }),
      blocking: investorEntityGate.blocking
    },
    [InvestorEntityGateCode.NEVER]: NEVER,
    [InvestorEntityGateCode.ERROR]: ERROR
  }),
  [EligibilityGateLevel.INVESTOR_ACCOUNT]: (_investorEntity, _investorEntityStatus, investorEntityGate) => ({
    [InvestorAccountGateCode.NO_WALLET]: {
      title: 'Finish account set-up',
      description: 'To invest in this offering, you need to complete enhanced identity verification requirements and set up a Yieldstreet Wallet.',
      blocking: investorEntityGate === null || investorEntityGate === void 0 ? void 0 : investorEntityGate.blocking
    },
    [InvestorAccountGateCode.DEPOSIT_REQUIRED]: {
      title: 'Finish funding',
      description: '',
      action: appActionManager.getAction(AppAction.DEPOSIT_REQUIRED, {
        investorEntityId: _investorEntity.id
      }),
      blocking: investorEntityGate === null || investorEntityGate === void 0 ? void 0 : investorEntityGate.blocking
    },
    [InvestorAccountGateCode.DEPOSIT_PROCESSING]: {
      title: 'Deposit Processing',
      description: 'Your deposit is processing',
      blocking: investorEntityGate === null || investorEntityGate === void 0 ? void 0 : investorEntityGate.blocking
    },
    [InvestorAccountGateCode.DEPOSIT_RESUBMIT_REQUIRED]: {
      title: 'Deposit Failed',
      description: 'Your deposit failed',
      action: appActionManager.getAction(AppAction.DEPOSIT_RESUBMIT_REQUIRED, {
        investorEntityId: _investorEntity.id
      }),
      blocking: investorEntityGate === null || investorEntityGate === void 0 ? void 0 : investorEntityGate.blocking
    },
    [InvestorAccountGateCode.NEVER]: NEVER,
    [InvestorAccountGateCode.ERROR]: ERROR
  })
};
export const SELF_DIRECTED_GATE_CONTENT = {
  [EligibilityGateLevel.USER]: () => ({
    [UserGateCode.OFFERING_STATUS]: {
      title: 'Offering not available',
      description: 'This offering is not currently open for investment. Please contact Investor Relations.'
    },
    [UserGateCode.SEGMENTATION]: {
      title: 'Offering reserved',
      description: 'This offering is currently reserved but may open up to additional investors in the future. Please contact Investor Relations.'
    },
    [UserGateCode.SELF_ACCREDITATION]: {
      title: 'Open to accredited investors only',
      description: 'Managed portfolios are open to accredited investors. If you are an accredited investor, please contact Investor Relations.'
    },
    [UserGateCode.SELF_QP]: {
      title: 'Open to qualified clients only',
      description: 'This offering is open to qualified clients. If you are a qualified client, you can adjust this in Investment preferences.',
      action: appActionManager.getAction(AppAction.EDIT_USER_QUIZ_ANSWERS, {})
    },
    [UserGateCode.SELF_QC]: {
      title: 'Open to qualified purchasers only',
      description: 'This offering is open to qualified purchases. If you are a qualified purchase, you can adjust this in Investment preferences.',
      action: appActionManager.getAction(AppAction.EDIT_USER_QUIZ_ANSWERS, {})
    },
    [UserGateCode.NO_INVESTOR_ENTITY]: {
      title: 'Finish account set-up',
      description: 'Before you can invest in an offering, you need to create an investor account.',
      action: appActionManager.getAction(AppAction.CREATE_INVESTOR_ENTITY, {})
    },
    [UserGateCode.INVESTOR_ENTITY_GATE]: {
      title: 'Finish account set-up',
      description: 'Your investor account is not ready to invest. Before you can invest in an offering, you need to verify your identity. Your investor account is not ready to invest. Before we can allocate your funds to a managed portfolio, you need to link an external bank.',
      action: appActionManager.getAction(AppAction.SETUP_PORTFOLIO, {})
    },
    [UserGateCode.NEVER]: NEVER,
    [UserGateCode.ERROR]: ERROR
  }),
  [EligibilityGateLevel.INVESTOR_ENTITY]: (investorEntity, investorEntityStatus) => ({
    [InvestorEntityGateCode.INVESTOR_ENTITY_TYPE]: {
      title: 'Open to accredited investors only',
      description: 'Managed portfolios are open to accredited investors. If you are an accredited investor, please contact Investor Relations.'
    },
    [InvestorEntityGateCode.INVESTOR_ENTITY_PROVIDER]: {
      title: '[provider] accounts ineligible',
      description: 'Unfortunately, [provider] accounts are not eligible for this offering at this time.'
    },
    [InvestorEntityGateCode.INVESTOR_ENTITY_PROVIDER_ACTION]: {
      title: 'Finish [provider] set-up',
      description: 'Your account set-up at [provider] isn’t complete. Please contact [provider] if you need any assistance'
    },
    [InvestorEntityGateCode.INVESTOR_ENTITY_ACTION]: _ref2 => {
      let {
        attributes: {
          action: {
            code
          }
        }
      } = _ref2;
      return {
        title: 'Finish account set-up',
        description: 'Your investor account is not ready to invest. Before you can invest in an offering, you need to verify your identity. Your investor account is not ready to invest. Before we can allocate your funds to a managed portfolio, you need to link an external bank.',
        action: code ? getInvestorEntityAppAction(code, investorEntity, investorEntityStatus) : undefined
      };
    },
    [InvestorEntityGateCode.MISSING_SELF_DIRECTED_INVESTOR_ACCOUNT]: {
      title: 'Finish account set-up',
      description: 'Before you can invest in an offering, you need to create a self-directed investor account.',
      action: appActionManager.getAction(AppAction.CREATE_INVESTOR_ACCOUNT, {
        investorEntityType: investorEntity.type,
        investorEntityId: investorEntity.id,
        managementStrategy: PortfolioStrategyValues.Self
      })
    },
    [InvestorEntityGateCode.INVESTOR_ACCOUNT_GATE]: _ref3 => {
      let {
        attributes: {
          action: {
            code
          }
        }
      } = _ref3;
      return {
        title: 'Finish account set-up',
        description: 'Your investor account is not ready to invest. Before you can invest in an offering, you need to complete your account set-up.',
        action: code ? getInvestorEntityAppAction(code, investorEntity, investorEntityStatus) : undefined
      };
    },
    [InvestorEntityGateCode.MISSING_BANK_ACCOUNTS]: () => ({
      title: 'Finish account set-up',
      description: 'Your investor account is not ready to invest. Before you can invest in an offering, you need to link an external bank. Link a bank account',
      action: appActionManager.getAction(AppAction.LINK_BANK_ACCOUNT, {
        investorEntityId: investorEntity.id
      })
    }),
    [InvestorEntityGateCode.PENDING_MICRO_DEPOSITS]: () => ({
      title: 'Finish account set-up',
      description: 'Your investor account is not ready to invest. Before you can invest in an offering, you need to complete your external bank account set-up. Verify micro deposit',
      action: appActionManager.getAction(AppAction.VERIFY_MICRO_DEPOSITS, {
        investorEntityId: investorEntity.id
      })
    }),
    [InvestorEntityGateCode.PENDING_BANK_ACCOUNT_VERIFICATION]: () => ({
      title: 'Finish account set-up',
      description: 'Your bank account is still being verified by the provider, please try again later.',
      action: appActionManager.getAction(AppAction.VERIFY_MICRO_DEPOSITS, {
        investorEntityId: investorEntity.id
      })
    }),
    [InvestorEntityGateCode.NEVER]: NEVER,
    [InvestorEntityGateCode.ERROR]: ERROR
  }),
  [EligibilityGateLevel.INVESTOR_ACCOUNT]: investorEntity => ({
    [InvestorAccountGateCode.NO_WALLET]: {
      title: 'Finish account set-up',
      description: 'To invest in this offering, you need to complete enhanced identity verification requirements and set up a Yieldstreet Wallet.',
      action: appActionManager.getAction(AppAction.CREATE_WALLET, {
        investorEntityId: investorEntity.id,
        managementStrategy: PortfolioStrategyValues.Self
      })
    },
    [InvestorAccountGateCode.NEVER]: NEVER,
    [InvestorAccountGateCode.ERROR]: ERROR
  })
};
export const ELIGIBILITY_GATE_CONTENT = {
  [PortfolioStrategyValues.Discretionary]: MANAGED_PORTFOLIO_GATE_CONTENT,
  [PortfolioStrategyValues.Self]: SELF_DIRECTED_GATE_CONTENT
};