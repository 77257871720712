import styled from 'styled-components';
import { media } from '@yieldstreet/ui-kit';
export const FiltersWrapper = styled.div.withConfig({
  displayName: "PortfolioFiltersWrapperstyle__FiltersWrapper",
  componentId: "sc-1dfwbc7-0"
})(["display:flex;align-items:center;justify-content:space-between;padding:", ";", ""], _ref => {
  let {
    theme
  } = _ref;
  return `${theme.spacing.ml} 0 ${theme.spacing.s} 0`;
}, media.desktop`
    padding: ${_ref2 => {
  let {
    theme
  } = _ref2;
  return `${theme.spacing.ml} 0 ${theme.spacing.m} 0`;
}};
  `);