import { useMemo } from 'react';
import { allToken, investmentApi, ManagementStrategy, PortfolioStrategyValues } from '@yieldstreet/platform-kit';
import { useApiQueryPollCheck } from '../../async-effects';
import { useAllInvestorEntities, useInvestorAccount } from '../../hooks';
import { useManagedPortfolioEligibilityContent } from '../useManagedPortfolioEligibilityContent';
import { getEligibilityGates } from '../getEligibilityGates';
import { getInvestorEntityGates } from '../managedPortfolioEligibility.util';
const useManagedPortfolioEligibilityContentPollCheck = (eligibilityCheckCallback, skipAutoCheck) => {
  const managedPortfolioEligibilityResult = investmentApi.useManagedPortfolioEligibilityQuery(allToken);
  return useApiQueryPollCheck(managedPortfolioEligibilityResult, data => data && eligibilityCheckCallback(data), skipAutoCheck);
};
export const usePortfolioEligibilityCheck = _ref => {
  var _managedGate$action;
  let {
    investorEntityId,
    requiredActions,
    config
  } = _ref;
  const {
    gateContent: managedGate
  } = useManagedPortfolioEligibilityContent({
    investorEntityId
  });
  const {
    investorEntities,
    investorEntityStatuses
  } = useAllInvestorEntities();
  const {
    account: managedAccount,
    isLoading: managedAccountLoading
  } = useInvestorAccount(investorEntityId, ManagementStrategy.Discretionary);
  const currentGate = managedGate === null || managedGate === void 0 ? void 0 : (_managedGate$action = managedGate.action) === null || _managedGate$action === void 0 ? void 0 : _managedGate$action.action;
  const skipAutoCheck = config !== null && config !== void 0 && config.skipPollChecks ? config.skipPollChecks : currentGate && requiredActions.includes(currentGate);
  const skipManagedPortfolioEligibilityAutoCheck = managedAccountLoading || !managedAccount ? true : skipAutoCheck;
  const {
    isLoading: managedPortfolioEligibilityIsLoading
  } = useManagedPortfolioEligibilityContentPollCheck(_ref2 => {
    var _investorEntityGate$a, _investorEntityGate$a2;
    let {
      eligibility
    } = _ref2;
    const gates = eligibility && getEligibilityGates(eligibility, {
      investorEntityId
    });
    const investorEntityGate = getInvestorEntityGates(gates, investorEntities, investorEntityStatuses, PortfolioStrategyValues.Discretionary);
    return !!(investorEntityGate !== null && investorEntityGate !== void 0 && (_investorEntityGate$a = investorEntityGate.action) !== null && _investorEntityGate$a !== void 0 && _investorEntityGate$a.action) && requiredActions.includes(investorEntityGate === null || investorEntityGate === void 0 ? void 0 : (_investorEntityGate$a2 = investorEntityGate.action) === null || _investorEntityGate$a2 === void 0 ? void 0 : _investorEntityGate$a2.action);
  }, skipManagedPortfolioEligibilityAutoCheck);
  const isLoading = managedPortfolioEligibilityIsLoading || !skipManagedPortfolioEligibilityAutoCheck;
  const action = useMemo(() => {
    if (!investorEntityId || isLoading) {
      return;
    }
    if (currentGate && requiredActions.includes(currentGate) && managedGate !== null && managedGate !== void 0 && managedGate.action) {
      return managedGate === null || managedGate === void 0 ? void 0 : managedGate.action;
    }
  }, [currentGate, requiredActions, investorEntityId, isLoading, managedGate]);
  return {
    action,
    isLoading,
    currentGate
  };
};