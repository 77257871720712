import { HttpMethods } from '../../../enums/http';
import { restApi, Tags } from '../../common';
export const addressApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.Address]
}).injectEndpoints({
  endpoints: build => {
    const getUSStateList = build.query({
      query: () => ({
        url: `/a/api/dic/address/states`,
        method: HttpMethods.Get
      }),
      providesTags: [Tags.Address],
      transformResponse: response => response.states
    });
    return {
      getUSStateList
    };
  }
});