import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { APP_PERSIST_SLICE_INITIAL_STATE } from './appPersist.model';
export const updateMarketplacePersistance = createAsyncThunk('appPersist/marketplace', (args, _ref) => {
  let {
    fulfillWithValue
  } = _ref;
  return fulfillWithValue(args);
});
export const appPersist = createSlice({
  name: 'appPersist',
  initialState: APP_PERSIST_SLICE_INITIAL_STATE,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(updateMarketplacePersistance.fulfilled, (state, action) => {
      state.marketplace = _objectSpread(_objectSpread({}, state.marketplace), action.payload);
    });
  }
});
export const selectMarketplacePersist = state => {
  var _state$appPersist;
  return ((_state$appPersist = state.appPersist) === null || _state$appPersist === void 0 ? void 0 : _state$appPersist.marketplace) || APP_PERSIST_SLICE_INITIAL_STATE.marketplace;
};