import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["status"];
var __jsx = React.createElement;
import React from 'react';
import { IconImg } from './StatusIcon.styles';
import { useTestIds, statusIcons } from './StatusIcon.model';
export const StatusIcon = _ref => {
  let {
      status
    } = _ref,
    props = _objectWithoutProperties(_ref, _excluded);
  const testIds = useTestIds(props);
  return __jsx(IconImg, _extends({
    src: statusIcons[status]
  }, testIds.base.attr));
};