import styled from 'styled-components';
import { Paragraph } from '@yieldstreet/ui-kit';
export const ExternalBankAccountWrapper = styled.div.withConfig({
  displayName: "ExternalBankAccountstyle__ExternalBankAccountWrapper",
  componentId: "sc-1tyxk44-0"
})(["display:flex;flex:1;flex-direction:column;gap:", ";", ""], _ref => {
  let {
    theme
  } = _ref;
  return theme.spacing.m;
}, _ref2 => {
  let {
    isDisabled
  } = _ref2;
  return isDisabled && `
    label, p, button {
      pointer-events: none;
      opacity: 0.5;
    }
  `;
});
export const BankAccountActions = styled.div.withConfig({
  displayName: "ExternalBankAccountstyle__BankAccountActions",
  componentId: "sc-1tyxk44-1"
})(["display:flex;flex-direction:row;flex-wrap:wrap;gap:", ";"], _ref3 => {
  let {
    theme
  } = _ref3;
  return theme.spacing.m;
});
export const ActionIcon = styled.img.withConfig({
  displayName: "ExternalBankAccountstyle__ActionIcon",
  componentId: "sc-1tyxk44-2"
})(["cursor:pointer;"]);
export const BankAccountDetails = styled.div.withConfig({
  displayName: "ExternalBankAccountstyle__BankAccountDetails",
  componentId: "sc-1tyxk44-3"
})(["display:flex;flex:1;flex-direction:column;"]);
export const BankAccountMetaWrapper = styled.div.withConfig({
  displayName: "ExternalBankAccountstyle__BankAccountMetaWrapper",
  componentId: "sc-1tyxk44-4"
})(["display:flex;justify-content:space-between;align-items:flex-start;"]);
export const BankAccountMeta = styled.div.withConfig({
  displayName: "ExternalBankAccountstyle__BankAccountMeta",
  componentId: "sc-1tyxk44-5"
})(["display:flex;flex-direction:column;flex-basis:", ";"], props => props.size ? props.size : 'auto');
export const BankAccountItem = styled.div.withConfig({
  displayName: "ExternalBankAccountstyle__BankAccountItem",
  componentId: "sc-1tyxk44-6"
})(["display:flex;flex-direction:row;justify-content:space-between;"]);

/*
  our font has some ligatures around a few specials characters
  this will ignore it and keep the asterisks aligned
*/
export const MaskedNumber = styled(Paragraph).withConfig({
  displayName: "ExternalBankAccountstyle__MaskedNumber",
  componentId: "sc-1tyxk44-7"
})(["font-variant-ligatures:no-contextual;"]);
export const TooltipWrapper = styled.div.withConfig({
  displayName: "ExternalBankAccountstyle__TooltipWrapper",
  componentId: "sc-1tyxk44-8"
})(["align-self:center;height:20px;img{width:20px;}button{padding:0;height:unset;}"]);