var __jsx = React.createElement;
import React from 'react';
import { testIds } from './InvestorAccountDropdown.model';
import { InvestorAccountDropdownListItem, InvestorAccountDropdownWrapper, InvestorAccountDropdownListItemParagraph, DropdownTotalLabel } from './InvestorAccountDropdown.style';
const InvestorAccountItem = _ref => {
  let {
    data,
    isActive,
    onSelectedInvestor
  } = _ref;
  return __jsx(InvestorAccountDropdownListItem, {
    key: data === null || data === void 0 ? void 0 : data.id,
    isActive: isActive,
    onClick: () => onSelectedInvestor(data),
    "data-cy": testIds.investorAccountDropdownListItem
  }, __jsx(InvestorAccountDropdownListItemParagraph, {
    semiBold: isActive,
    inverse: isActive
  }, data.name), __jsx(DropdownTotalLabel, {
    isActive: isActive
  }, data === null || data === void 0 ? void 0 : data.total));
};
export const InvestorAccountDropdown = _ref2 => {
  let {
    accounts,
    selectedInvestor,
    onSelectedInvestor
  } = _ref2;
  return __jsx(InvestorAccountDropdownWrapper, {
    "data-cy": testIds.investorAccountDropdownWrapper
  }, accounts === null || accounts === void 0 ? void 0 : accounts.map(data => {
    const isActive = (data === null || data === void 0 ? void 0 : data.isActive) || (data === null || data === void 0 ? void 0 : data.id) === (selectedInvestor === null || selectedInvestor === void 0 ? void 0 : selectedInvestor.id);
    return __jsx(InvestorAccountItem, {
      data: data,
      isActive: isActive,
      onSelectedInvestor: onSelectedInvestor
    });
  }));
};