import { genTestIds } from '@yieldstreet/tool-kit';
import { ProgressBarV2Status } from '@yieldstreet/ui-kit';
export const COMMITMENT_ID_PARAM = ':commitmentId';
export const URL_HASH_PARAM = ':urlHash';
export const CAPITAL_CALL_RESULT_PATH = `/capital-calls-confirmation/${URL_HASH_PARAM}/${COMMITMENT_ID_PARAM}`;
export const nextSteps = [{
  title: '1. Commitment confirmation',
  description: 'Investments in this offering will be processed on the 1st business day of the following month. We will notify you via email once complete.'
}, {
  title: '2. First capital call',
  description: 'Our team will be in touch with you to fulfill your first capital call. You can expect an update from us in the next three months with an additional follow up 10 business days before the funding due date.'
}, {
  title: '3. Tracking progress',
  description: 'Check your portfolio to track your commitment progress and get updates on this fund and future capital calls.'
}];
export const progressSteps = _ref => {
  let {
    isUpfrontCapitalCall = false
  } = _ref;
  const progressSteps = [{
    label: 'Enter commitment',
    description: 'Determine your total commitment amount.',
    status: ProgressBarV2Status.CLEARED
  }, {
    label: 'Review & submit',
    description: 'Submit your commitment request.',
    status: ProgressBarV2Status.CLEARED
  }, {
    label: 'First capital call',
    description: 'Receive notice to fund your first capital call.',
    status: ProgressBarV2Status.IDLE
  }, {
    label: 'Additional capital call(s)',
    description: 'Receive notice to fund your remaining commitment.',
    status: ProgressBarV2Status.IDLE
  }];
  if (isUpfrontCapitalCall) {
    progressSteps.splice(2, 1);
  }
  return progressSteps;
};
const {
  getTestIds
} = genTestIds(['pageLoader', 'container', 'back', 'viewPortfolio', 'header-title', 'investor-name', 'commitment-amount', 'progress', 'status', 'next-steps-container', 'view-investments-button', 'marketplace-button', 'upfront-funding-amount']);
export const testIds = getTestIds('commitment-result');