var __jsx = React.createElement;
import React, { useState } from 'react';
import styled from 'styled-components';
import { ExternalLink } from '../../content/link';
import { Heading, Paragraph } from '../../content/text';
import { Modal } from '../../elements/modal';
import { PageContainer } from '../../layout/page-container';
import { PageSection } from '../../layout';
import { media } from '../../themes/media';
import LinkedInIcon from '../../assets/graphics/icons/linkedin.svg';
export const BioModalContainer = _ref => {
  let {
    quote,
    quoteHeadingType = 3,
    quoteBy,
    quoteByHeadingType = 4,
    bios
  } = _ref;
  const {
    0: showBio,
    1: setShowBio
  } = useState();
  return __jsx(Background, null, __jsx(PageSection, null, __jsx(PageContainer, null, __jsx(OuterContainer, null, __jsx(Container, null, __jsx(ContentHolder, null, __jsx(SHeading, {
    type: quoteHeadingType
  }, "\u201C", quote, "\u201D"), __jsx(SHeading, {
    type: quoteByHeadingType
  }, quoteBy)), __jsx(RightContent, null, bios.map((bio, index) => __jsx(React.Fragment, null, __jsx(BioHolder, {
    key: index
  }, __jsx(ImageHolder, null, bio.image, bio.linkedIn ? __jsx(ExternalLink, {
    href: bio.linkedIn,
    target: "_blank"
  }, __jsx(LinkedIn, null, __jsx("img", {
    src: LinkedInIcon
  }))) : ''), __jsx(Paragraph, {
    small: true,
    semiBold: true
  }, bio.name), __jsx(Paragraph, {
    small: true
  }, bio.title), __jsx(Link, {
    small: true
  }, __jsx(ExternalLink, {
    onClick: () => setShowBio(index)
  }, "Read bio"))), showBio === index && __jsx(MobileContent, null, bio.bio))))), bios.map((bio, index) => showBio === index && __jsx(Modal, {
    clickClose: () => setShowBio(undefined),
    className: "modal",
    key: index
  }, __jsx(ModalHolder, null, __jsx(ModalLeftContainer, null, __jsx(BioHolder, null, __jsx(ImageHolder, null, bio.image), __jsx(Paragraph, {
    small: true,
    semiBold: true
  }, bio.name), __jsx(Paragraph, {
    small: true
  }, bio.title))), __jsx(ModalRightContainer, null, bio.bio))))))));
};
const Background = styled.div.withConfig({
  displayName: "BioModalContainer__Background",
  componentId: "sc-1qib8ed-0"
})(["padding:40px 0 0 0;"]);
const OuterContainer = styled.div.withConfig({
  displayName: "BioModalContainer__OuterContainer",
  componentId: "sc-1qib8ed-1"
})([".modal{display:none;", " > div{border:none;max-width:900px;}}"], media.desktop`
      display: -webkit-box;
    `);
const ModalHolder = styled.div.withConfig({
  displayName: "BioModalContainer__ModalHolder",
  componentId: "sc-1qib8ed-2"
})(["padding:20px 20px 20px 0;display:flex;"]);
const ModalLeftContainer = styled.div.withConfig({
  displayName: "BioModalContainer__ModalLeftContainer",
  componentId: "sc-1qib8ed-3"
})(["width:25%;"]);
const ModalRightContainer = styled.div.withConfig({
  displayName: "BioModalContainer__ModalRightContainer",
  componentId: "sc-1qib8ed-4"
})(["width:75%;p:not(:last-child){margin-bottom:20px;}"]);
const Container = styled.div.withConfig({
  displayName: "BioModalContainer__Container",
  componentId: "sc-1qib8ed-5"
})(["display:flex;flex-direction:column;", ""], media.desktop`
    flex-direction: row;
  `);
const ContentHolder = styled.div.withConfig({
  displayName: "BioModalContainer__ContentHolder",
  componentId: "sc-1qib8ed-6"
})(["width:100%;", ""], media.desktop`
    width: 40%;
  `);
const RightContent = styled.div.withConfig({
  displayName: "BioModalContainer__RightContent",
  componentId: "sc-1qib8ed-7"
})(["width:100%;display:flex;flex-direction:column;", " justify-content:center;"], media.desktop`
    flex-direction: row;
    width: 60%;
  `);
const SHeading = styled(Heading).withConfig({
  displayName: "BioModalContainer__SHeading",
  componentId: "sc-1qib8ed-8"
})(["margin-bottom:20px;"]);
const Link = styled(Paragraph).withConfig({
  displayName: "BioModalContainer__Link",
  componentId: "sc-1qib8ed-9"
})(["margin-top:10px;"]);
const MobileContent = styled.div.withConfig({
  displayName: "BioModalContainer__MobileContent",
  componentId: "sc-1qib8ed-10"
})(["display:block;text-align:left;margin-top:40px;p:not(:last-child){margin-bottom:20px;}", ""], media.desktop`
    display: none;
  `);
const BioHolder = styled.div.withConfig({
  displayName: "BioModalContainer__BioHolder",
  componentId: "sc-1qib8ed-11"
})(["width:100%;margin:20px 0;", " text-align:center;"], media.desktop`
    width: 170px;
    margin: 0 20px;
  `);
const ImageHolder = styled.div.withConfig({
  displayName: "BioModalContainer__ImageHolder",
  componentId: "sc-1qib8ed-12"
})(["position:relative;width:175px;margin:0 auto;img{max-width:175px;}", " text-align:center;"], media.desktop`
    margin: 0 0 20px 0;
  `);
const LinkedIn = styled.div.withConfig({
  displayName: "BioModalContainer__LinkedIn",
  componentId: "sc-1qib8ed-13"
})(["position:absolute;right:0;bottom:10px;padding:5px 5px 4px 5px;background:", ";border-bottom:1px solid ", ";"], props => props.theme.colors.background_card, props => props.theme.colors.accent_strong);