import update from 'immutability-helper';
import * as actions from 'store/actions/investments';
const initialState = {
  investmentList: null,
  isFetching: false
};
const investment = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case actions.GET_ALL_INVESTMENTS_ATTEMPT:
      {
        return update(state, {
          isFetching: {
            $set: true
          }
        });
      }
    case actions.GET_ALL_INVESTMENTS_SUCCESS:
      {
        return update(state, {
          isFetching: {
            $set: false
          },
          investmentList: {
            $set: action.response
          }
        });
      }
    case actions.GET_ALL_INVESTMENTS_FAILURE:
      {
        return update(state, {
          isFetching: {
            $set: false
          }
        });
      }
    case actions.RESET_ALL_INVESTMENTS:
      {
        return initialState;
      }
    default:
      return state;
  }
};
export default investment;