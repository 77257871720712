import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useMemo } from 'react';
import { PageFlow, Heading, Notification, StepCounter } from '@yieldstreet/ui-kit';
import { Wrapper, SParagraph, NotificationWraper } from './FlowWrapper.style';
import { testIds } from './FlowWrapper.model';
import { getPageInfo } from './FlowWrapper.utils';
export const FlowWrapper = _ref => {
  var _flowConfig$steps2;
  let {
    children,
    flowConfig,
    step,
    goToPreviousStep,
    investorAccountType,
    error,
    showSubtitle = true
  } = _ref;
  const {
    title,
    subtitle,
    backNavigationText,
    backNavigation,
    belowContent
  } = getPageInfo({
    step,
    investorAccountType
  });
  const currentStepIndex = useMemo(() => {
    var _flowConfig$steps;
    return flowConfig === null || flowConfig === void 0 ? void 0 : (_flowConfig$steps = flowConfig.steps) === null || _flowConfig$steps === void 0 ? void 0 : _flowConfig$steps.findIndex(element => element.stepKey === step);
  }, [flowConfig, step]);
  return __jsx(PageFlow, {
    smallHeader: true,
    showLogo: false,
    showFooter: false,
    backNavigation: backNavigation !== null && backNavigation !== void 0 ? backNavigation : true,
    backNavigationText: backNavigationText,
    backNavigationCallback: goToPreviousStep,
    counter: flowConfig && currentStepIndex !== -1 ? __jsx(StepCounter, {
      currentStep: currentStepIndex + 1,
      maxStep: flowConfig === null || flowConfig === void 0 ? void 0 : (_flowConfig$steps2 = flowConfig.steps) === null || _flowConfig$steps2 === void 0 ? void 0 : _flowConfig$steps2.length
    }) : null
  }, __jsx(React.Fragment, null, __jsx(Wrapper, _extends({}, testIds.wrapper.attr, {
    id: "profile-create-investor-account"
  }), __jsx(Heading, _extends({
    type: 4
  }, testIds.heading.attr), title), showSubtitle && __jsx(SParagraph, testIds.paragraph.attr, subtitle), error ? __jsx(NotificationWraper, null, __jsx(Notification, _extends({
    description: error,
    severity: "error"
  }, testIds.errorNotication.attr))) : null, children, belowContent)));
};