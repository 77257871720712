export const MuiLink = {
  defaultProps: {
    color: 'primary'
  },
  styleOverrides: {
    root: _ref => {
      var _theme$palette$Link, _theme$palette$Link2, _theme$palette$Link3, _theme$palette$Link4, _theme$palette$Link5, _theme$palette$Link6;
      let {
        theme,
        ownerState
      } = _ref;
      return {
        cursor: 'pointer',
        color: (_theme$palette$Link = theme.palette.Link) === null || _theme$palette$Link === void 0 ? void 0 : (_theme$palette$Link2 = _theme$palette$Link[(ownerState === null || ownerState === void 0 ? void 0 : ownerState.color) || 'primary']) === null || _theme$palette$Link2 === void 0 ? void 0 : _theme$palette$Link2.color,
        backgroundImage: `linear-gradient( to right, ${(_theme$palette$Link3 = theme.palette.Link) === null || _theme$palette$Link3 === void 0 ? void 0 : (_theme$palette$Link4 = _theme$palette$Link3[(ownerState === null || ownerState === void 0 ? void 0 : ownerState.color) || 'primary']) === null || _theme$palette$Link4 === void 0 ? void 0 : _theme$palette$Link4.color} 45%,transparent 45%,transparent 55%, ${(_theme$palette$Link5 = theme.palette.Link) === null || _theme$palette$Link5 === void 0 ? void 0 : (_theme$palette$Link6 = _theme$palette$Link5[(ownerState === null || ownerState === void 0 ? void 0 : ownerState.color) || 'primary']) === null || _theme$palette$Link6 === void 0 ? void 0 : _theme$palette$Link6.color} 55% )`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '225% 0.1em',
        backgroundPosition: '100% 100%',
        textDecoration: 'none',
        padding: '1px',
        '&:hover': {
          backgroundPosition: '0 100%',
          transitionProperty: 'background-position',
          transitionDuration: '0.4s',
          transitionTimingFunction: 'ease-in-out'
        }
      };
    }
  }
};