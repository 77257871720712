var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { media } from '../../themes/media';
import { Heading, Paragraph } from '../../content/text';
import { PageSection } from '../../layout/page-section';
export const SideTableContent = props => {
  const {
    title,
    inverse,
    children,
    tablePosition,
    tableRows,
    disclaimer
  } = props;
  return __jsx(PageSection, null, __jsx(Container, {
    tablePosition: tablePosition
  }, __jsx(HeadingContainer, null, __jsx(Title, {
    type: 3,
    inverse: inverse
  }, title), __jsx(Content, null, children)), __jsx(TableContainer, null, tableRows.map(tableRow => __jsx(TableRow, {
    key: tableRow.value
  }, __jsx(Description, {
    type: 5
  }, tableRow.description), __jsx(Value, null, tableRow.value))), disclaimer && __jsx(Disclaimer, {
    small: true
  }, disclaimer))));
};
const Container = styled.div.withConfig({
  displayName: "SideTableContent__Container",
  componentId: "sc-2qxpbh-0"
})(["display:flex;padding:30px 20px 40px;flex-direction:column;justify-content:space-between;", " ", ""], media.tablet`
    padding: 30px 20px 40px;
  `, media.desktop`
  flex-direction: ${props => props.tablePosition === 'right' ? 'row' : 'row-reverse'};
    padding: 30px 110px 40px;
  `);
const HeadingContainer = styled.div.withConfig({
  displayName: "SideTableContent__HeadingContainer",
  componentId: "sc-2qxpbh-1"
})(["margin-top:10px;", ""], media.desktop`
    margin-left: 20px;
  `);
const Title = styled(Heading).withConfig({
  displayName: "SideTableContent__Title",
  componentId: "sc-2qxpbh-2"
})(["margin-bottom:40px;", ""], media.desktop`
    width: 75%;
  `);
const Content = styled.div.withConfig({
  displayName: "SideTableContent__Content",
  componentId: "sc-2qxpbh-3"
})(["", ""], media.desktop`
    width: 80%;
  `);
const TableContainer = styled.div.withConfig({
  displayName: "SideTableContent__TableContainer",
  componentId: "sc-2qxpbh-4"
})(["", ""], media.desktop`
    flex-basis: 60%;
    margin-right: 20px;
  `);
const TableRow = styled.div.withConfig({
  displayName: "SideTableContent__TableRow",
  componentId: "sc-2qxpbh-5"
})(["background:", ";:nth-child(even){background:", ";border-top:1px solid ", ";border-bottom:1px solid ", ";}padding:20px;width:100%;display:flex;flex-direction:row;justify-content:space-between;", ""], props => props.theme.colors.table_row_background, props => props.theme.colors.table_row_background_alt, props => props.theme.colors.border_default, props => props.theme.colors.border_default, media.tablet`
    padding: 25px;
  `);
const Description = styled(Heading).withConfig({
  displayName: "SideTableContent__Description",
  componentId: "sc-2qxpbh-6"
})(["flex-basis:60%;", ""], media.tablet`
    flex-basis: unset;
  `);
const Value = styled(Paragraph).withConfig({
  displayName: "SideTableContent__Value",
  componentId: "sc-2qxpbh-7"
})(["flex-basis:20%;text-align:right;align-self:center;", ""], media.tablet`
    flex-basis: unset;
  `);
const Disclaimer = styled(Paragraph).withConfig({
  displayName: "SideTableContent__Disclaimer",
  componentId: "sc-2qxpbh-8"
})(["margin-top:40px;"]);