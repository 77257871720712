import { CommunicationMessageCategory } from '@yieldstreet/platform-kit';
import { genTestIds } from '@yieldstreet/tool-kit';
export const ACTIVITY_PATH = '/activity';
export const ACTIVITY_SNACKBAR_AUTOHIDE_DURATION = 5000;
export const ActivityContent = {
  title: 'All activity',
  cta: 'Load more'
};
export const ActivityFormatTime = 'hh:mm A';
export const ActivityCtaDealMatured = '/portfolio/investments?status=past';
export const ActivityCtaInvestmentUpdate = '/activity-details';
export const ActivityFilterKeys = {
  category: 'category'
};
export const ActivityFilters = Object.values(ActivityFilterKeys);
export const ActivityNotificationContent = {
  title: 'New Activities',
  description: 'You have new messages in your activity feed. Refresh to see your latest updates.',
  linkText: 'Refresh'
};
const {
  getTestIds
} = genTestIds(['error-view', 'load-more-button', 'snackbar', 'wrapper']);
export const testIds = getTestIds('activity');
export const ActivityFilterableCategories = [CommunicationMessageCategory.Important, CommunicationMessageCategory.Payments, CommunicationMessageCategory.InvestmentUpdates];
export const ActivityCategory = {
  [CommunicationMessageCategory.Important]: 'Important',
  [CommunicationMessageCategory.Payments]: 'Payments',
  [CommunicationMessageCategory.InvestmentUpdates]: 'Investment Updates',
  [CommunicationMessageCategory.InvestmentProgress]: 'Investment Progress'
};
export const ActivityFilterCategoryQueryParam = category => category ? `?category=${category}` : '';
export const ActivityFilterCategory = category => ActivityFilterableCategories.includes(category) ? category : null;