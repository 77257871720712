var __jsx = React.createElement;
import React, { useMemo } from 'react';
import { FilterDropdown } from './FilterDropdown';
import { FilterContainer } from './Filters.style';
export const Filters = _ref => {
  let {
    filterOptions,
    queryFilterParams,
    setPagination
  } = _ref;
  const memoizedFilters = useMemo(() => filterOptions.map(filter => __jsx(FilterDropdown, {
    key: filter.key,
    filter: filter,
    queryFilterParams: queryFilterParams
    /* 
      TODO: Currently our filter's hooks don't handle with pagination,
        since for now only transactions screen has it.
        This will be added and we can remove the `setPagination` property.
    */,
    setPagination: setPagination
  })), [filterOptions, queryFilterParams, setPagination]);
  return __jsx(FilterContainer, null, memoizedFilters);
};