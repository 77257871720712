import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getFlagsContext } from '@yieldstreet/tool-kit';
import ENV_CONFIG from 'utils/env/config';
import { useUserState } from 'utils/hooks';
export const useFlagsContext = () => {
  const {
    analyticsUserId: userId,
    analyticsAnonymousId: anonymousId
  } = useSelector(_ref => {
    let {
      user
    } = _ref;
    return user;
  });
  const userState = useUserState();
  return useMemo(() => {
    return getFlagsContext({
      anonymousId,
      userId,
      host: ENV_CONFIG.HOST,
      userState
    });
  }, [anonymousId, userId, userState]);
};