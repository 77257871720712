import { HttpMethods } from '../../enums/http';
import { restApi, Tags } from '../common';
export const accreditationApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.OfferingEligibility]
}).injectEndpoints({
  endpoints: builder => {
    const uploadDocument = builder.mutation({
      query: _ref => {
        let {
          file,
          title,
          type
        } = _ref;
        const data = new FormData();
        data.append('file', file);
        data.append('title', title);
        data.append('type', type);
        return {
          url: '/a/api/document/temp/upload',
          method: HttpMethods.Post,
          headers: {
            'content-type': 'multipart/form-data'
          },
          data
        };
      }
    });
    const submitAutoAiq = builder.mutation({
      query: _ref2 => {
        let {
          request,
          investorAccountId
        } = _ref2;
        return {
          url: `/a/api/investor/account/${investorAccountId}/auto-aiq`,
          method: 'POST',
          data: request
        };
      }
    });
    const getAutoAiq = builder.query({
      query: _ref3 => {
        let {
          aiqId,
          investorAccountId
        } = _ref3;
        return {
          url: `/a/api/investor/account/${investorAccountId}/autoaiq/${aiqId}`,
          method: 'GET'
        };
      }
    });
    return {
      uploadDocument,
      submitAutoAiq,
      getAutoAiq
    };
  }
});