var __jsx = React.createElement;
import React from 'react';
import { Paragraph } from '@yieldstreet/ui-kit';
import calendar from './assets/calendar.svg';
import realEstate from './assets/real-estate.svg';
import share from './assets/share.svg';
export const REIT_URL_HASH = 'e5jyfw';
export const REIT_DETAILS_PATH = `/offering/${REIT_URL_HASH}`;
export const REIT_FREE_SHARES_PROMO_CODE = '100-free-investment';
const INVESTMENT_AMOUNT = 100;
export const REIT_FREE_SHARES_INVEST_PATH = `/complete-investment/${REIT_URL_HASH}/${INVESTMENT_AMOUNT}?promo=${REIT_FREE_SHARES_PROMO_CODE}`;
export const detailsList = [{
  icon: realEstate,
  description: __jsx(Paragraph, null, "Claim your free shares and become an investor in a Fund that seeks to primarily", ' ', __jsx("b", null, "make investments to own commercial real estate properties across key U.S. markets"), " and property types. The diversified nature of the Fund allows all investors to gain access to multiple commercial real estate offerings.")
}, {
  icon: calendar,
  description: __jsx(Paragraph, null, "The Fund\u2019s main investment objective is to ", __jsx("b", null, "generate capital appreciation"), " and, as a secondary objective, generate current income.")
}, {
  icon: share,
  description: __jsx(Paragraph, null, "The Fund intends to ", __jsx("b", null, "offer quarterly liquidity on your investment"), "; shares cannot be redeemed for cash immediately.")
}];
export const testIds = {
  banner: 'banner',
  offeringLink: 'offering-link',
  desktopImage: 'desktop-image',
  mobileImage: 'mobile-image',
  terms: 'terms',
  cta: 'cta',
  cancel: 'cancel'
};