import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { Tooltip } from '../tooltip';
import { TagContainer, TagContent, TagTooltip } from './Tag.style';
import { TagConfiguration, TagType, testIds } from './Tag.model';
export const Tag = props => {
  const {
    content,
    type,
    tooltip
  } = props;
  const tagType = TagConfiguration[type || TagType.DEFAULT];
  return __jsx(TagContainer, _extends({}, tagType, testIds.base.attr), __jsx(TagContent, testIds.content.attr, content), tooltip && __jsx(TagTooltip, testIds.tooltip.attr, __jsx(Tooltip, {
    tooltipKey: tooltip,
    testId: testIds.tooltip.id
  }, tooltip)));
};