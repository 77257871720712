import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import uniqBy from "lodash/uniqBy";
import { AssetClassLabel, AssetClassType, InvestmentStrategyType } from '@yieldstreet/platform-kit';
import { getAssetColors } from '../overview';
export const mapToInvestmentAssets = icons => [{
  assetClass: AssetClassType.RealEstate,
  color: getAssetColors()[AssetClassType.RealEstate],
  icon: icons === null || icons === void 0 ? void 0 : icons[AssetClassType.RealEstate],
  title: AssetClassLabel.RealEstate,
  percentage: 0,
  value: 0
}, {
  assetClass: AssetClassType.PrivateCredit,
  color: getAssetColors()[AssetClassType.PrivateCredit],
  icon: icons === null || icons === void 0 ? void 0 : icons[AssetClassType.PrivateCredit],
  title: AssetClassLabel.PrivateCredit,
  percentage: 0,
  value: 0
}, {
  assetClass: AssetClassType.PrivateEquity,
  color: getAssetColors()[AssetClassType.PrivateEquity],
  icon: icons === null || icons === void 0 ? void 0 : icons[AssetClassType.PrivateEquity],
  title: AssetClassLabel.PrivateEquity,
  percentage: 0,
  value: 0
}];
export const showDefaultAssets = assetsBreakdown => {
  const hasCashAssetsOnly = assetsBreakdown === null || assetsBreakdown === void 0 ? void 0 : assetsBreakdown.every(asset => asset.assetClass === AssetClassType.Cash);
  return !assetsBreakdown || !assetsBreakdown.length || hasCashAssetsOnly;
};
export const getDefaultAssets = _ref => {
  let {
    defaultInvestmentAssets,
    strategy
  } = _ref;
  if ((strategy === null || strategy === void 0 ? void 0 : strategy.name.toUpperCase()) === InvestmentStrategyType.Income) {
    return defaultInvestmentAssets.filter(_ref2 => {
      let {
        assetClass
      } = _ref2;
      return assetClass !== AssetClassType.PrivateEquity;
    });
  }
  return defaultInvestmentAssets;
};
export const getAssets = _ref3 => {
  let {
    assetsBreakdown,
    defaultInvestmentAssets
  } = _ref3;
  const mappedAssets = (assetsBreakdown === null || assetsBreakdown === void 0 ? void 0 : assetsBreakdown.reduce((assets, currentAsset) => {
    const matchedAsset = defaultInvestmentAssets.find(asset => asset.assetClass === currentAsset.assetClass);
    if (matchedAsset) {
      assets.push(_objectSpread(_objectSpread({}, matchedAsset), currentAsset));
    }
    return assets;
  }, [])) || [];
  return uniqBy([...mappedAssets, ...defaultInvestmentAssets], 'assetClass');
};