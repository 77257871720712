var __jsx = React.createElement;
import React from 'react';
import { createYupObjectSchema } from '@yieldstreet/tool-kit';
import * as Yup from 'yup';
import { ADVICE_RECOMMENDATIONS_PATH } from 'pages/advice';
import { Link } from 'sharedComponents/Links/Links';
import { AdviceQuestionsConfig } from 'utils/advice';
import { GeneralPreferencesForm } from './forms/GeneralPreferencesForm';
import { RecommendationsForm } from './forms/RecommendationsForm';
const {
  InterestedAssetClasses,
  EmploymentStatus,
  Employer,
  Industry,
  Position,
  CurrentIncome,
  NetWorth,
  PreferredTimeHorizon,
  ExpectedYield,
  InvestmentStyle,
  RiskTolerance,
  QualifiedPurchaser,
  QualifiedClient
} = AdviceQuestionsConfig;
const FIELD_REQUIRED_MESSAGE = 'This field is required';
const RecommendationsSchema = createYupObjectSchema({
  [NetWorth.key]: Yup.string().required(FIELD_REQUIRED_MESSAGE).nullable(),
  [PreferredTimeHorizon.key]: Yup.string().required(FIELD_REQUIRED_MESSAGE).nullable(),
  [RiskTolerance.key]: Yup.string().required(FIELD_REQUIRED_MESSAGE).nullable(),
  [InvestmentStyle.key]: Yup.string().required(FIELD_REQUIRED_MESSAGE).nullable(),
  [QualifiedClient.key]: Yup.string().nullable(),
  [QualifiedPurchaser.key]: Yup.string().nullable()
});
const EmploymentStatusSchema = {
  is: val => val === EmploymentStatus.values.Employed,
  then: Yup.string().required(FIELD_REQUIRED_MESSAGE).nullable(),
  otherwise: Yup.string().notRequired().nullable()
};
const GeneralPreferencesSchema = createYupObjectSchema({
  [InterestedAssetClasses.key]: Yup.array(Yup.string()).min(1, FIELD_REQUIRED_MESSAGE).nullable(),
  [ExpectedYield.key]: Yup.string().required(FIELD_REQUIRED_MESSAGE).nullable(),
  [EmploymentStatus.key]: Yup.string().required(FIELD_REQUIRED_MESSAGE).nullable(),
  [Employer.key]: Yup.string().when(EmploymentStatus.key, EmploymentStatusSchema),
  [Industry.key]: Yup.string().when(EmploymentStatus.key, EmploymentStatusSchema),
  [Position.key]: Yup.string().when(EmploymentStatus.key, EmploymentStatusSchema),
  [CurrentIncome.key]: Yup.string().when(EmploymentStatus.key, EmploymentStatusSchema)
});
const ADVICE_PREFERENCES_FORMS = {
  GeneralPreferences: {
    Form: GeneralPreferencesForm,
    initialValues: {
      [InterestedAssetClasses.key]: [],
      [CurrentIncome.key]: '',
      [ExpectedYield.key]: '',
      [EmploymentStatus.key]: '',
      [Employer.key]: '',
      [Industry.key]: '',
      [Position.key]: ''
    },
    questionKeys: [InterestedAssetClasses.key, CurrentIncome.key, ExpectedYield.key, EmploymentStatus.key, Employer.key, Industry.key, Position.key],
    validationSchema: GeneralPreferencesSchema,
    notification: {
      successProps: {
        title: 'Preferences updated.',
        'data-cy': 'save-investment-preferences-general-preferences-success'
      },
      errorProps: {
        'data-cy': 'save-investment-preferences-general-preferences-error'
      }
    }
  },
  Recommendations: {
    Form: RecommendationsForm,
    initialValues: {
      [NetWorth.key]: '',
      [PreferredTimeHorizon.key]: '',
      [RiskTolerance.key]: '',
      [InvestmentStyle.key]: ''
    },
    questionKeys: [NetWorth.key, PreferredTimeHorizon.key, RiskTolerance.key, InvestmentStyle.key, QualifiedClient.key, QualifiedPurchaser.key],
    validationSchema: RecommendationsSchema,
    notification: {
      successProps: {
        title: 'Preferences updated.',
        description: 'Check out your updated recommended investments.',
        link: __jsx(Link, {
          to: ADVICE_RECOMMENDATIONS_PATH
        }, "View recommendations"),
        'data-cy': 'save-investment-preferences-recommendations-success'
      },
      errorProps: {
        'data-cy': 'save-investment-preferences-recommendations-error'
      }
    }
  }
};
export { ADVICE_PREFERENCES_FORMS };