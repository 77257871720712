import { PortfolioStrategyValues } from '@yieldstreet/platform-kit';
import { getDatesForRange } from '@yieldstreet/tool-kit';
import { CALL_API } from '../middleware/Middleware';
export const INVESTMENT_DETAILS_ATTEMPT = 'ys/portfolioInvestmentDetails/ATTEMPT';
export const INVESTMENT_DETAILS_SUCCESS = 'ys/portfolioInvestmentDetails/SUCCESS';
export const INVESTMENT_DETAILS_FAILURE = 'ys/portfolioInvestmentDetails/FAILURE';
export const getPortfolioInvestmentDetails = function (userId, investorAccountId, timePeriod, noteUrlHash) {
  let offeringData = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : [];
  let managementStrategy = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : PortfolioStrategyValues.Self;
  return dispatch => {
    const [start, end] = getDatesForRange({
      range: timePeriod
    });
    const investorAccountIdParam = `&investorAccountId=${investorAccountId}`;
    const noteUrlHashParam = `&noteUrlHash=${noteUrlHash}&includeRepaidPrincipal=true&includePrincipalWriteOff=true`;
    let stringOfferingData = '';
    offeringData.forEach(offering => {
      stringOfferingData = stringOfferingData.concat(`offeringData=${offering}&`);
    });
    stringOfferingData = stringOfferingData.substring(0, stringOfferingData.length - 1);
    const endpoint = `/api/portfolio/overview/${userId}?${stringOfferingData}&managementStrategy=${managementStrategy}&start=${start}&end=${end}${investorAccountId === 'ALL' ? '' : investorAccountIdParam}${noteUrlHash ? noteUrlHashParam : ''}`;
    return dispatch({
      [CALL_API]: {
        endpoint,
        types: [INVESTMENT_DETAILS_ATTEMPT, INVESTMENT_DETAILS_SUCCESS, INVESTMENT_DETAILS_FAILURE],
        successPayload: {
          userId,
          investorAccountId,
          timePeriod,
          noteUrlHash
        },
        getResponse: res => res.data
      }
    });
  };
};
export const GET_NAV_CHART_ATTEMPT = 'ys/portfolioInvestmentDetails/getNavChart/ATTEMPT';
export const GET_NAV_CHART_SUCCESS = 'ys/portfolioInvestmentDetails/getNavChart/SUCCESS';
export const GET_NAV_CHART_FAILURE = 'ys/portfolioInvestmentDetails/getNavChart/FAILURE';
export const getNavChart = function (userId, timePeriod, investorAccountId, noteUrlHash) {
  let managementStrategy = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : PortfolioStrategyValues.Self;
  return dispatch => {
    const [end] = getDatesForRange({
      range: timePeriod
    });
    const investorAccountIdParam = `&investorAccountId=${investorAccountId}`;
    const noteUrlHashParam = `&noteUrlHash=${noteUrlHash}`;
    const endpoint = `/api/portfolio/${userId}/nav-chart?asOfDate=${end}${investorAccountId === 'ALL' ? '' : investorAccountIdParam}${noteUrlHash ? noteUrlHashParam : ''}&managementStrategy=${managementStrategy}`;
    return dispatch({
      [CALL_API]: {
        endpoint,
        types: [GET_NAV_CHART_ATTEMPT, GET_NAV_CHART_SUCCESS, GET_NAV_CHART_FAILURE],
        successPayload: {
          userId,
          investorAccountId,
          timePeriod,
          noteUrlHash
        },
        getResponse: res => res.data
      }
    });
  };
};