import _defineProperty from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
var __jsx = React.createElement;
import React from 'react';
import { Label, media } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
import closeIcon from '../shared/images/close.svg';
class Modal extends React.Component {
  constructor() {
    super(...arguments);
    _defineProperty(this, "handleClickOutside", event => {
      const isTargetBg = !![...event.target.classList].find(classItem => classItem.match(/Modal__ModalBackground/gi));
      if (isTargetBg && this.props.clickClose) {
        this.props.clickClose();
      }
    });
  }
  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
  }
  render() {
    return __jsx(ModalBackground, {
      className: this.props.className
    }, __jsx(ModalContainer, null, __jsx(ModalHeader, {
      hideXMark: this.props.hideXMark
    }, __jsx(Icon, {
      src: closeIcon,
      onClick: this.props.clickClose,
      className: "modal-close-icon",
      "data-cy": "modal-close-icon"
    })), __jsx(ModalContent, null, this.props.children), __jsx(ModalCaption, null, this.props.caption)));
  }
}
const ModalBackground = styled.div.withConfig({
  displayName: "Modal__ModalBackground",
  componentId: "sc-fizgdh-0"
})(["position:fixed;height:100%;width:100%;background:rgba(0,0,0,0.8);top:0;left:0;z-index:999;display:flex;align-items:center;justify-content:center;"]);
const ModalContainer = styled.div.withConfig({
  displayName: "Modal__ModalContainer",
  componentId: "sc-fizgdh-1"
})(["background:white;height:100%;width:100%;padding:15px;overflow-y:auto;max-height:98vh;width:auto;height:auto;overflow:visible;", ";"], media.tablet`
    max-width: 750px;
  `);
const ModalHeader = styled.div.withConfig({
  displayName: "Modal__ModalHeader",
  componentId: "sc-fizgdh-2"
})(["text-align:right;z-index:2;position:relative;", ";"], props => props.hideXMark && `display: none`);
const ModalContent = styled.div.withConfig({
  displayName: "Modal__ModalContent",
  componentId: "sc-fizgdh-3"
})(["position:relative;"]);
const ModalCaption = styled(Label).withConfig({
  displayName: "Modal__ModalCaption",
  componentId: "sc-fizgdh-4"
})(["color:", " !important;"], props => props.theme.colors.background_page);
const Icon = styled.img.withConfig({
  displayName: "Modal__Icon",
  componentId: "sc-fizgdh-5"
})(["cursor:pointer;margin-bottom:20px;"]);
export default Modal;