import { Paragraph, Heading, SubTitle, media } from '@yieldstreet/ui-kit';
import Carousel from 'nuka-carousel';
import styled from 'styled-components';
import Modal from '../../shared/Modal';
export const Wrapper = styled.div.withConfig({
  displayName: "ParagraphBoxSectionstyle__Wrapper",
  componentId: "sc-ibwhbe-0"
})(["background-color:", ";"], props => props.theme.colors.background_card);
export const InnerWrapper = styled.div.withConfig({
  displayName: "ParagraphBoxSectionstyle__InnerWrapper",
  componentId: "sc-ibwhbe-1"
})(["", ";", ";"], media.tablet`
    padding: ${props => props.theme.spacing.m} 0 ${props => props.theme.spacing.l};
  `, media.large`
    padding-bottom: ${props => props.theme.spacing.ml};
  `);
export const ContentWrapper = styled.div.withConfig({
  displayName: "ParagraphBoxSectionstyle__ContentWrapper",
  componentId: "sc-ibwhbe-2"
})(["display:flex;flex-direction:column;", ";"], media.desktop`
    flex-direction: row;
    justify-content: space-between;
  `);
export const TextWrapper = styled.div.withConfig({
  displayName: "ParagraphBoxSectionstyle__TextWrapper",
  componentId: "sc-ibwhbe-3"
})(["padding:", " 0 ", ";", ";"], props => props.theme.spacing.m, props => props.theme.spacing.l, media.tablet`
    padding: 0 ${props => props.theme.spacing.l} 0 0;
  `);
export const Title = styled(Heading).withConfig({
  displayName: "ParagraphBoxSectionstyle__Title",
  componentId: "sc-ibwhbe-4"
})(["margin-left:", ";"], props => (props === null || props === void 0 ? void 0 : props.icon) && props.theme.spacing.sm);
export const SSubTitle = styled(SubTitle).withConfig({
  displayName: "ParagraphBoxSectionstyle__SSubTitle",
  componentId: "sc-ibwhbe-5"
})(["margin-bottom:", ";color:", ";display:block;"], props => props.theme.spacing.s, props => props.theme.colors.text_label);
export const TitleContainer = styled.div.withConfig({
  displayName: "ParagraphBoxSectionstyle__TitleContainer",
  componentId: "sc-ibwhbe-6"
})(["display:flex;flex-direction:row;& ", "{margin-bottom:", ";}&:has(+ ", ") ", "{margin-bottom:", ";}", ";"], Title, props => props.theme.spacing.m, SSubTitle, Title, props => props.theme.spacing.s, media.desktop`
    max-width: 580px;
  `);
export const StyledModal = styled(Modal).withConfig({
  displayName: "ParagraphBoxSectionstyle__StyledModal",
  componentId: "sc-ibwhbe-7"
})(["> div{background:transparent;}"]);
export const ModalVideoIframe = styled.iframe.withConfig({
  displayName: "ParagraphBoxSectionstyle__ModalVideoIframe",
  componentId: "sc-ibwhbe-8"
})(["align-self:center;", ";"], media.tablet`
    width: 560px;
    height: 315px;
    margin: 0;
  `);
export const ImageThumbnail = styled.div.withConfig({
  displayName: "ParagraphBoxSectionstyle__ImageThumbnail",
  componentId: "sc-ibwhbe-9"
})(["cursor:", ";overflow:hidden;position:relative;height:auto;object-fit:cover;"], props => props !== null && props !== void 0 && props.modalView ? 'normal' : 'pointer');
export const Image = styled.img.withConfig({
  displayName: "ParagraphBoxSectionstyle__Image",
  componentId: "sc-ibwhbe-10"
})(["width:100%;"]);
export const ModalImage = styled.img.withConfig({
  displayName: "ParagraphBoxSectionstyle__ModalImage",
  componentId: "sc-ibwhbe-11"
})(["max-width:100%;"]);
export const MapWrapper = styled.div.withConfig({
  displayName: "ParagraphBoxSectionstyle__MapWrapper",
  componentId: "sc-ibwhbe-12"
})(["cursor:pointer;box-shadow:0 4px 9px rgba(0,0,0,0.17);position:relative;height:250px;object-fit:cover;"]);
export const MapThumbnail = styled.iframe.withConfig({
  displayName: "ParagraphBoxSectionstyle__MapThumbnail",
  componentId: "sc-ibwhbe-13"
})(["pointer-events:none;border:none;height:100%;width:100%;"]);
export const ModalMapIframe = styled(ModalVideoIframe).withConfig({
  displayName: "ParagraphBoxSectionstyle__ModalMapIframe",
  componentId: "sc-ibwhbe-14"
})(["", ";"], media.tablet`
    width: 700px;
    height: 453px;
  `);
export const MediaWrapper = styled.div.withConfig({
  displayName: "ParagraphBoxSectionstyle__MediaWrapper",
  componentId: "sc-ibwhbe-15"
})(["align-self:center;", ";", ";"], media.tablet`
    width: 90%;
  `, media.desktop`
    width: 560px;
  `);
export const MediaTitle = styled(Paragraph).withConfig({
  displayName: "ParagraphBoxSectionstyle__MediaTitle",
  componentId: "sc-ibwhbe-16"
})(["margin-bottom:", ";"], props => props.theme.spacing.m);
export const CarouselWrapper = styled.div.withConfig({
  displayName: "ParagraphBoxSectionstyle__CarouselWrapper",
  componentId: "sc-ibwhbe-17"
})(["position:relative;margin:0 auto;width:100%;overflow:hidden;", ";", ";", ";"], media.tablet`
    width: 100%;
    max-width: 500px;
  `, media.desktop`
    margin-left: 25px;
    min-width: 570px;
  `, media.large`
    margin-left: 25px;
    min-width: 570px;
  `);
export const ModalCarouselWrapper = styled.div.withConfig({
  displayName: "ParagraphBoxSectionstyle__ModalCarouselWrapper",
  componentId: "sc-ibwhbe-18"
})(["min-width:320px;min-height:450px;", ";", ";"], media.phoneLandscape`
    min-width: 480px;
  `, media.tablet`
    width: 640px;
  `);
export const SCarousel = styled(Carousel).withConfig({
  displayName: "ParagraphBoxSectionstyle__SCarousel",
  componentId: "sc-ibwhbe-19"
})(["display:block;max-width:374px;", ";.slider-list{width:100% !important;margin-bottom:0 !important;}.slider-frame{margin:0 !important;}.slider-slide{&:not(.slide-current){opacity:0.6;}}.slider-control-centerright{right:-126px !important;}.slider-control-centerleft{left:5px !important;}"], media.tablet`
    .slider-slide {
      margin-left: 70px !important;
    }
  `);
export const ModalCarousel = styled(Carousel).withConfig({
  displayName: "ParagraphBoxSectionstyle__ModalCarousel",
  componentId: "sc-ibwhbe-20"
})(["display:block;", ";.slider-control-bottomcenter{width:100% !important;text-align:center;bottom:5px !important;ul{justify-content:center !important;}}.paging-item{button{padding:0 4px !important;opacity:unset !important;fill:transparent !important;}&.active{.paging-dot{background:", " !important;}}.paging-dot{width:10px !important;height:10px !important;background:", " !important;border-radius:10px !important;}}.slider-list{width:100% !important;margin-bottom:60px !important;}.slider-frame{margin:0 !important;}.slider-control-centerright{right:-70px !important;}.slider-control-centerleft{left:-70px !important;}"], media.tablet`
    .slider-control-bottomcenter {
      display: none;
    }
  `, props => props.theme.colors.chart_1, props => props.theme.colors.border_strong);
export const CarouselArrow = styled.button.withConfig({
  displayName: "ParagraphBoxSectionstyle__CarouselArrow",
  componentId: "sc-ibwhbe-21"
})(["background-color:", ";border-radius:28px;height:50px;width:50px;border:none;.fa-chevron-left{", ";color:", ";}&:hover{cursor:pointer;}&:hover .fa-chevron-left{color:", ";}"], props => props.theme.colors.icon_default, props => props.pointRight && `transform: rotate(180deg)`, props => props.theme.colors.icon_inverse, props => props.theme.colors.active);
export const ParagraphBody = styled(Paragraph).withConfig({
  displayName: "ParagraphBoxSectionstyle__ParagraphBody",
  componentId: "sc-ibwhbe-22"
})(["margin-bottom:", ";", ";"], props => props.theme.spacing.m, media.desktop`
    max-width: ${props => !props.fullWidth && `580px`};
  `);