var __jsx = React.createElement;
import React from 'react';
import { Markdown } from '@yieldstreet/ui-kit';
import OfferingIcon from '../../shared/IconComponent';
import { Wrapper, TextWrapper, TitleContainer, Title, Description } from './MediaCard.style';
const MediaCard = _ref => {
  let {
    title,
    url,
    description,
    iconName,
    type,
    documents,
    userState,
    isPastOffering,
    testId
  } = _ref;
  const getUrl = type => {
    var _documents$find, _documents$find2, _documents$find3;
    switch (type) {
      case 'custom':
      case 'main':
      case 'other':
        return url;
      case 'mainDocument':
        return documents === null || documents === void 0 ? void 0 : (_documents$find = documents.find(doc => doc.type === 'main')) === null || _documents$find === void 0 ? void 0 : _documents$find.url;
      case 'mainDocumentPreview':
        return documents === null || documents === void 0 ? void 0 : (_documents$find2 = documents.find(doc => doc.type === 'preview')) === null || _documents$find2 === void 0 ? void 0 : _documents$find2.url;
      case 'standAloneDoc':
        return documents === null || documents === void 0 ? void 0 : (_documents$find3 = documents.find(doc => doc.name === title)) === null || _documents$find3 === void 0 ? void 0 : _documents$find3.url;
      default:
        return;
    }
  };
  if (type === 'main' || type === 'other' || type === 'standAloneDoc') {
    iconName = 'download';
  }
  if (userState.loggedIn && !isPastOffering || (!userState.loggedIn || isPastOffering) && type === 'custom') {
    return __jsx(Wrapper, {
      "data-cy": testId
    }, __jsx("a", {
      className: "media-card-link",
      href: getUrl(type),
      target: "_blank",
      rel: "noopener noreferrer"
    }, __jsx(TextWrapper, null, __jsx(TitleContainer, null, iconName && __jsx(OfferingIcon, {
      "data-cy": "media-card-icon",
      iconName: iconName
    }), __jsx(Title, {
      type: 5,
      "data-cy": "media-card-title",
      icon: iconName
    }, title)), description && __jsx(Description, {
      className: "media-card-description",
      "data-cy": "media-card-description"
    }, __jsx(Markdown, {
      source: description
    })))));
  } else return null;
};
export default MediaCard;