var __jsx = React.createElement;
import React, { useEffect, useCallback, useState, useRef } from 'react';
import { FormTextInput } from '@yieldstreet/ui-kit';
import useScript from '@charlietango/use-script';
import iso3166 from 'iso-3166-2';
import get from "lodash/get";
import { Flex } from './FormAddressInputGroup.style';
import { autocompleteTable, AddressFields } from './FormAddressInputGroup.model';
import { getGoogleAPIURL, useTestIds } from './FormAddressInputGroup.utils';
import { StateSelection } from './StateSelection';
const FormAddressInputGroup = _ref => {
  let {
    label,
    formikProps,
    namePrefix = '',
    nameMap = {},
    backendError = {},
    allowWorldwide = false,
    disabled = false,
    testId
  } = _ref;
  const testIds = useTestIds({
    testId: testId || namePrefix
  });
  const [ready] = useScript(getGoogleAPIURL());
  const autocompleteInput = useRef();
  const autocomplete = useRef(null);
  const autocompleteListener = useRef(null);
  const parseAddress = useCallback(place => {
    if (!place.address_components) {
      return;
    }
    const tinyPlace = {};
    for (var i = 0; i < place.address_components.length; i++) {
      const addressType = place.address_components[i].types[0];
      if (autocompleteTable[addressType]) {
        const val = place.address_components[i][autocompleteTable[addressType]];
        tinyPlace[addressType] = val;
      }
    }
    const countrySubdivision = iso3166.subdivision(tinyPlace.country, tinyPlace.administrative_area_level_1);
    return {
      addressLine1: `${tinyPlace.street_number || ''} ${tinyPlace.route || ''}`,
      city: tinyPlace.locality || tinyPlace.sublocality_level_1 || tinyPlace.administrative_area_level_2 || '',
      country: tinyPlace.country || '',
      zip: tinyPlace.postal_code || '',
      state: countrySubdivision ? countrySubdivision.regionCode : ''
    };
  }, []);
  const getFieldName = useCallback(name => `${namePrefix ? `${namePrefix}.` : ''}${nameMap[name] || name}`, [nameMap, namePrefix]);
  const getFieldValue = useCallback(name => get(formikProps.values, getFieldName(name)), [getFieldName, formikProps]);
  const setFieldValue = useCallback((name, value) => formikProps.setFieldValue(getFieldName(name), value), [getFieldName, formikProps]);
  const fillAutocomplete = useCallback(_ref2 => {
    let {
      addressLine1,
      city,
      zip,
      state,
      country
    } = _ref2;
    setFieldValue(AddressFields.ADDRESS_LINE_1, addressLine1);
    setFieldValue(AddressFields.CITY, city);
    setFieldValue(AddressFields.POSTAL_CODE, zip);
    setFieldValue(AddressFields.STATE, state);
    setFieldValue(AddressFields.COUNTRY, country);
  }, [setFieldValue]);
  const getBackendError = useCallback(field => {
    try {
      return backendError[getFieldName(field)][0];
    } catch (e) {
      return false;
    }
  }, [backendError, getFieldName]);
  const {
    0: initiated,
    1: setInitiated
  } = useState(false);
  const disableGoogleAutocomplete = useCallback(() => {
    if (autocomplete.current && autocompleteListener.current) {
      // Hacky way to disable google places autocomplete
      // https://stackoverflow.com/questions/9828856/how-to-toggle-the-google-maps-autocomplete-on-and-off
      window.google.maps.event.removeListener(autocompleteListener.current);
      window.google.maps.event.clearInstanceListeners(autocomplete.current);
      const dropdowns = document.querySelectorAll('.pac-container');
      if (dropdowns) {
        dropdowns.forEach(dropdown => dropdown.parentNode.removeChild(dropdown));
      }
      autocomplete.current = null;
      autocompleteListener.current = null;
    }
  }, []);
  const enableGoogleAutocomplete = useCallback(() => {
    var _window$google, _window$google$maps;
    if (autocompleteInput.current && !autocomplete.current && !autocompleteListener.current && (_window$google = window.google) !== null && _window$google !== void 0 && (_window$google$maps = _window$google.maps) !== null && _window$google$maps !== void 0 && _window$google$maps.places) {
      autocomplete.current = new window.google.maps.places.Autocomplete(autocompleteInput.current, {
        types: ['geocode'],
        componentRestrictions: allowWorldwide ? {} : {
          country: 'us'
        }
      });
      autocompleteListener.current = autocomplete.current.addListener('place_changed', () => {
        const place = autocomplete.current.getPlace();
        const address = parseAddress(place);
        if (address) {
          fillAutocomplete(address);
        }
      });
      const initObserver = new MutationObserver(() => {
        initObserver.disconnect();
        if (autocompleteInput.current && !autocompleteInput.current.getAttribute('value').length) {
          autocompleteInput.current.setAttribute('autocomplete', 'address-line1');
        }
      });
      initObserver.observe(autocompleteInput.current, {
        attributes: true,
        attributeFilter: ['autocomplete']
      });
    }
  }, [allowWorldwide, fillAutocomplete, parseAddress]);
  const onFindAddressChange = useCallback(value => {
    const input = autocompleteInput.current;
    const currentAutocomplete = input.getAttribute('autocomplete');
    if (value.length) {
      // Check input autocomplete attribute
      if (currentAutocomplete === 'address-line1') {
        input.setAttribute('autocomplete', 'off');
        // Detect when chrome auto-fill is triggered and disable google places autocomplete
        if (value.length > 1) {
          disableGoogleAutocomplete();
        }
      }
    } else {
      // Enable google places autocomplete if disabled by above check when value cleared
      enableGoogleAutocomplete();
      // Check input autocomplete attribute
      if (currentAutocomplete === 'off') {
        input.setAttribute('autocomplete', 'address-line1');
      }
    }
  }, [autocompleteInput, enableGoogleAutocomplete, disableGoogleAutocomplete]);
  useEffect(() => {
    window.initAutocomplete = () => {
      if (!initiated) {
        enableGoogleAutocomplete();
        setInitiated(true);
      }
    };
  }, [enableGoogleAutocomplete, setInitiated, initiated]);
  useEffect(() => {
    if (ready && !initiated) {
      enableGoogleAutocomplete();
      setInitiated(true);
    }
  }, [ready, enableGoogleAutocomplete, setInitiated, initiated]);
  useEffect(() => {
    if (!allowWorldwide && getFieldValue(AddressFields.COUNTRY) !== 'US') {
      setFieldValue(AddressFields.COUNTRY, 'US');
    }
  }, [getFieldValue, setFieldValue, allowWorldwide]);
  return __jsx(React.Fragment, null, __jsx(FormTextInput, {
    inputRef: autocompleteInput,
    type: "text",
    name: getFieldName(AddressFields.ADDRESS_LINE_1),
    autoComplete: "address-line1",
    label: label || 'Address',
    formikProps: formikProps,
    error: getBackendError(AddressFields.ADDRESS_LINE_1),
    onChange: onFindAddressChange,
    testId: testIds[AddressFields.ADDRESS_LINE_1].id,
    fullWidth: true,
    disabled: disabled
  }), __jsx(FormTextInput, {
    type: "text",
    name: getFieldName(AddressFields.ADDRESS_LINE_2),
    autoComplete: "address-line2",
    label: "Address (cont)",
    placeholder: "Apartment, suite, building, floor, etc. (Optional)",
    formikProps: formikProps,
    error: getBackendError(AddressFields.ADDRESS_LINE_2),
    testId: testIds[AddressFields.ADDRESS_LINE_2].id,
    fullWidth: true,
    disabled: disabled
  }), __jsx(FormTextInput, {
    type: "text",
    name: getFieldName(AddressFields.CITY),
    label: "City",
    formikProps: formikProps,
    error: getBackendError(AddressFields.CITY),
    testId: testIds[AddressFields.CITY].id,
    fullWidth: true,
    disabled: disabled
  }), __jsx(Flex, null, __jsx(StateSelection, {
    country: getFieldValue(AddressFields.COUNTRY),
    formikProps: formikProps,
    formSelectProps: {
      name: getFieldName(AddressFields.STATE),
      error: getBackendError(AddressFields.STATE),
      testId: testIds[AddressFields.STATE].id
    },
    formTextInputProps: {
      name: getFieldName(AddressFields.STATE),
      error: getBackendError(AddressFields.STATE),
      testId: testIds[AddressFields.STATE].id
    },
    disabled: disabled
  }), __jsx(FormTextInput, {
    type: "text",
    name: getFieldName(AddressFields.POSTAL_CODE),
    label: "Zip code",
    formikProps: formikProps,
    error: getBackendError(AddressFields.POSTAL_CODE),
    testId: testIds[AddressFields.POSTAL_CODE].id,
    fullWidth: true,
    disabled: disabled
  })), allowWorldwide && __jsx(FormTextInput, {
    type: "text",
    name: getFieldName(AddressFields.COUNTRY),
    label: "Country",
    formikProps: formikProps,
    error: getBackendError(AddressFields.COUNTRY),
    testId: testIds[AddressFields.COUNTRY].id,
    fullWidth: true,
    disabled: disabled
  }));
};
export default FormAddressInputGroup;