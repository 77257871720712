import { useMemo } from 'react';
import { paymentsApi, portfolioPaymentLabels, portfolioPaymentStatusLabel } from '@yieldstreet/platform-kit';
import { FilterTypeEnum } from '@yieldstreet/tool-kit';
import { PortfolioFilterKeys, usePortfolioFilters, usePortfolioInvestmentsFilter, usePortfolioYearsFilter } from 'pages/portfolio/shared/PortfolioFilters';
import { ALL_PAYMENT_TYPES, ALL_PAYMENT_STATUS } from './useTransactionsListFilters.model';
export const useTransactionsListFilters = _ref => {
  let {
    investorId,
    managementStrategy,
    userId
  } = _ref;
  /**
   * Init portfolio filters state.
   */
  const {
    filters,
    handleFilterChange,
    handleFilterSubmit,
    removeQueryParams
  } = usePortfolioFilters({
    managementStrategy,
    filterKeys: [PortfolioFilterKeys.noteUrlHash, PortfolioFilterKeys.paymentType, PortfolioFilterKeys.year, PortfolioFilterKeys.status]
  });

  /**
   * Investments options.
   */
  const {
    investmentsOptions
  } = usePortfolioInvestmentsFilter({
    investorId,
    userId,
    managementStrategy
  });

  /**
   * Get years options.
   */
  const {
    hasYears: hasTransactions,
    mostRecentYear,
    yearsOptions
  } = usePortfolioYearsFilter({
    investorId,
    query: paymentsApi.useGetPaymentsYearsQuery,
    userId,
    managementStrategy
  });

  /**
   * Payment type options.
   */
  const paymentTypeOptions = useMemo(() => {
    const options = Object.keys(portfolioPaymentLabels).map(key => ({
      label: portfolioPaymentLabels[key],
      value: key,
      id: key
    }));
    return [ALL_PAYMENT_TYPES, ...options];
  }, []);

  /**
   * Payment type options.
   */
  const statusOptions = useMemo(() => {
    const options = Object.keys(portfolioPaymentStatusLabel).map(key => ({
      label: portfolioPaymentStatusLabel[key],
      value: key,
      id: key
    }));
    return [ALL_PAYMENT_STATUS, ...options];
  }, []);

  /**
   * Filters dropdown config.
   */
  const filtersConfig = useMemo(() => [{
    disabled: !hasTransactions,
    key: PortfolioFilterKeys.noteUrlHash,
    label: 'Investment',
    options: investmentsOptions,
    type: FilterTypeEnum.SingleSelect
  }, {
    disabled: !hasTransactions,
    key: PortfolioFilterKeys.paymentType,
    label: 'Payment type',
    options: paymentTypeOptions,
    type: FilterTypeEnum.SingleSelect
  }, {
    disabled: !hasTransactions,
    key: PortfolioFilterKeys.year,
    label: 'Year',
    options: yearsOptions,
    type: FilterTypeEnum.SingleSelect
  }, {
    disabled: !hasTransactions,
    key: PortfolioFilterKeys.status,
    label: 'Status',
    options: statusOptions,
    type: FilterTypeEnum.SingleSelect
  }], [hasTransactions, investmentsOptions, paymentTypeOptions, yearsOptions, statusOptions]);
  return {
    filters,
    filtersConfig,
    handleFilterChange,
    handleFilterSubmit,
    hasTransactions,
    mostRecentYear,
    removeQueryParams
  };
};