import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import filter from "lodash/filter";
import map from "lodash/map";
import isEqual from "lodash/isEqual";
import { getRolloverData } from 'store/actions/accounts';
import { useUserState } from 'utils/hooks';
export const useGetRollover = investorAccountId => {
  const {
    loggedIn
  } = useUserState() || {};
  const rolloverSelector = createSelector(state => state.investorAccounts, investorAccounts => {
    const {
      rolloverDataFetched,
      rolloverDataError: isError,
      rolloverData
    } = investorAccounts;
    const rolloverInvestmentIds = map(rolloverData, x => x.id);
    return {
      rolloverDataFetched,
      isError,
      rolloverData,
      rolloverInvestmentIds
    };
  });
  const {
    rolloverDataFetched,
    rolloverDataError: isError,
    rolloverData,
    rolloverInvestmentIds
  } = useSelector(rolloverSelector, isEqual);
  const dispatch = useDispatch();
  useEffect(() => {
    if (loggedIn) {
      dispatch(getRolloverData());
    }
  }, [dispatch, loggedIn]);
  const filteredRollover = useMemo(() => {
    if (!investorAccountId) {
      return [];
    }
    return filter(rolloverData || [], rolloverInvestment => rolloverInvestment.investorAccountId === investorAccountId.toString());
  }, [investorAccountId, rolloverData]);
  const filteredRolloverByOffering = useMemo(() => {
    const groupedData = {};
    (filteredRollover || []).forEach(item => {
      if (!groupedData[item.noteUrlHash]) {
        groupedData[item.noteUrlHash] = [];
      }
      groupedData[item.noteUrlHash].push(item);
    });
    return Object.values(groupedData);
  }, [filteredRollover]);
  const filteredRolloverInvestmentIds = useMemo(() => map(filteredRolloverByOffering[0] || [], x => x === null || x === void 0 ? void 0 : x.id), [filteredRolloverByOffering]);
  return {
    rolloverData,
    filteredRollover: filteredRolloverByOffering[0] || [],
    rolloverDataFetched,
    rolloverInvestmentIds,
    filteredRolloverInvestmentIds,
    isError: !!isError
  };
};