import capitalize from "lodash/capitalize";
import { InvestmentTypes } from '@yieldstreet/platform-kit';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { HeaderLabels, HeaderTypes, IndexTypes } from './useInvestmentList.model';
const createInvestmentListHeader = _ref => {
  let {
    isPrismFund,
    isFundInvestment,
    hasLiquidation,
    renderPerformanceStatus
  } = _ref;
  const headerRows = [{
    type: HeaderTypes.Text,
    headerLabel: HeaderLabels.DateRequested,
    itemIndex: IndexTypes.DateInvested,
    showOnMobile: false,
    showOnTablet: true
  }];
  if (renderPerformanceStatus) {
    headerRows.push({
      type: HeaderTypes.Text,
      headerLabel: HeaderLabels.Status,
      itemIndex: IndexTypes.Status,
      showOnMobile: !hasLiquidation && !isPrismFund,
      showOnTablet: true
    });
  }
  headerRows.push({
    type: HeaderTypes.Text,
    headerLabel: HeaderLabels.DateActive,
    itemIndex: IndexTypes.DateActive,
    showOnMobile: true,
    showOnTablet: true
  });
  if (isFundInvestment) {
    headerRows.push({
      type: HeaderTypes.Number,
      headerLabel: HeaderLabels.Shares,
      itemIndex: IndexTypes.ShareCount,
      showOnMobile: false,
      showOnTablet: false
    }, {
      type: HeaderTypes.FourDecimalsCurrency,
      headerLabel: HeaderLabels.Nav,
      itemIndex: IndexTypes.SharePrice,
      showOnMobile: false,
      showOnTablet: false
    }, {
      type: HeaderTypes.CurrencyLiquidation,
      headerLabel: HeaderLabels.Amount,
      itemIndex: IndexTypes.Amount,
      showOnMobile: true,
      showOnTablet: true
    });
  } else {
    headerRows.push({
      type: hasLiquidation ? HeaderTypes.CurrencyLiquidation : HeaderTypes.Currency,
      headerLabel: HeaderLabels.Amount,
      itemIndex: IndexTypes.Amount,
      showOnMobile: true,
      showOnTablet: true
    });
  }
  if (isPrismFund || hasLiquidation) {
    headerRows.unshift({
      type: HeaderTypes.Text,
      headerLabel: HeaderLabels.Type,
      itemIndex: IndexTypes.Type,
      showOnMobile: true,
      showOnTablet: true
    });
  }
  return headerRows;
};
const createInvestmentListBody = _ref2 => {
  let {
    investments,
    isFundInvestment,
    isPrismFund,
    hasLiquidation,
    renderPerformanceStatus
  } = _ref2;
  if (!investments) return [];
  return investments.map(investment => {
    const isLiquidation = investment.type === InvestmentTypes.Liquidation;
    const dateInvested = investment.dateInvested ? dayjs(investment.dateInvested).format('MMM D, YYYY') : '-';
    const dateActive = investment.dateActive ? dayjs(investment.dateActive).format('MMM D, YYYY') : '-';
    const investType = capitalize(investment.type.replace('_', ' '));
    const amount = isLiquidation ? `(${investment.amount})` : investment.amount;
    const row = {};
    if (isFundInvestment || hasLiquidation) {
      if (isPrismFund || hasLiquidation) {
        row[IndexTypes.Type] = investType;
      }
    }
    row[IndexTypes.DateInvested] = dateInvested;
    if (renderPerformanceStatus) {
      row[IndexTypes.Status] = renderPerformanceStatus({
        investmentStatus: investment.status,
        investmentType: investment.type
      });
    }
    row[IndexTypes.DateActive] = dateActive;
    if (isFundInvestment) {
      var _investment$shareCoun, _investment$sharePric;
      row[IndexTypes.ShareCount] = (_investment$shareCoun = investment === null || investment === void 0 ? void 0 : investment.shareCount) !== null && _investment$shareCoun !== void 0 ? _investment$shareCoun : 0;
      row[IndexTypes.SharePrice] = (_investment$sharePric = investment === null || investment === void 0 ? void 0 : investment.sharePrice) !== null && _investment$sharePric !== void 0 ? _investment$sharePric : 0;
      row[IndexTypes.Amount] = amount;
    } else if (hasLiquidation) {
      row[IndexTypes.Amount] = amount;
    } else {
      row[IndexTypes.Amount] = investment.amount;
    }
    return row;
  });
};
const createCommitmentTableListBody = _ref3 => {
  let {
    commitments,
    renderPerformanceStatus
  } = _ref3;
  if (!commitments) return [];
  return commitments.map(commitment => {
    const commitmentDate = commitment.commitmentDate ? dayjs(commitment.commitmentDate).format('MMM D, YYYY') : '-';
    const activationDate = commitment.activationDate ? dayjs(commitment.activationDate).format('MMM D, YYYY') : '-';
    const amount = commitment.amount;
    const row = {};
    row[IndexTypes.CommitmentDate] = commitmentDate;
    if (renderPerformanceStatus) {
      row[IndexTypes.Status] = renderPerformanceStatus({
        commitmentStatus: commitment.status,
        investmentType: InvestmentTypes.Commitment
      });
    }
    row[IndexTypes.DateActive] = activationDate;
    row[IndexTypes.Amount] = amount;
    return row;
  });
};
export const useInvestmentList = _ref4 => {
  let {
    investments,
    isPrismFund,
    isFundInvestment,
    renderPerformanceStatus
  } = _ref4;
  // Determine if the list has a "LIQUIDATION" investment
  const hasLiquidation = investments.some(investment => investment.type === InvestmentTypes.Liquidation);
  const headerRows = useMemo(() => createInvestmentListHeader({
    hasLiquidation,
    isFundInvestment,
    isPrismFund,
    renderPerformanceStatus
  }), [hasLiquidation, isFundInvestment, isPrismFund, renderPerformanceStatus]);
  const mobileHeaderRows = useMemo(() => headerRows === null || headerRows === void 0 ? void 0 : headerRows.filter(row => row === null || row === void 0 ? void 0 : row.showOnMobile), [headerRows]);
  const mobileBodyRows = useMemo(() => createInvestmentListBody({
    hasLiquidation,
    investments,
    isFundInvestment,
    isPrismFund,
    renderPerformanceStatus
  }), [investments, hasLiquidation, isFundInvestment, isPrismFund, renderPerformanceStatus]);
  const bodyRows = useMemo(() => mobileBodyRows.map(row => Object.values(row)), [mobileBodyRows]);
  return {
    headerRows,
    bodyRows,
    mobileHeaderRows,
    mobileBodyRows
  };
};
export const useCommitmentList = _ref5 => {
  let {
    commitments,
    renderPerformanceStatus
  } = _ref5;
  const headerRows = useMemo(() => createCommitmentListHeader({
    renderPerformanceStatus
  }), [renderPerformanceStatus]);
  const mobileHeaderRows = useMemo(() => headerRows === null || headerRows === void 0 ? void 0 : headerRows.filter(row => row === null || row === void 0 ? void 0 : row.showOnMobile), [headerRows]);
  const mobileBodyRows = useMemo(() => createCommitmentTableListBody({
    commitments,
    renderPerformanceStatus
  }), [commitments, renderPerformanceStatus]);
  const bodyRows = useMemo(() => mobileBodyRows.map(row => Object.values(row)), [mobileBodyRows]);
  return {
    headerRows,
    bodyRows,
    mobileHeaderRows,
    mobileBodyRows
  };
};
const createCommitmentListHeader = _ref6 => {
  let {
    renderPerformanceStatus
  } = _ref6;
  const headerRows = [{
    type: HeaderTypes.Text,
    headerLabel: HeaderLabels.DateRequested,
    itemIndex: IndexTypes.CommitmentDate,
    showOnMobile: false,
    showOnTablet: true
  }];
  if (renderPerformanceStatus) {
    headerRows.push({
      type: HeaderTypes.Text,
      headerLabel: HeaderLabels.Status,
      itemIndex: IndexTypes.Status,
      showOnMobile: true,
      showOnTablet: true
    });
  }
  headerRows.push({
    type: HeaderTypes.Text,
    headerLabel: HeaderLabels.DateActive,
    itemIndex: IndexTypes.DateActive,
    showOnMobile: true,
    showOnTablet: true
  });
  headerRows.push({
    type: HeaderTypes.Currency,
    headerLabel: HeaderLabels.Amount,
    itemIndex: IndexTypes.Amount,
    showOnMobile: true,
    showOnTablet: true
  });
  return headerRows;
};