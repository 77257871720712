import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import checkboxActive from '../../assets/checkbox_active.svg';
import checkboxCompleted from '../../assets/checkbox_completed.svg';
import checkboxError from '../../assets/checkbox_error.svg';
import checkboxIdle from '../../assets/checkbox_idle.svg';
import { CheckboxStatuses, useTestIds } from '../../ProgressBar.model';
import { StatusContainer, CheckboxContainer, Status, Checkbox } from './ProgressBarCheckbox.style';
const getCheckbox = status => {
  switch (status) {
    case CheckboxStatuses.ACTIVE:
      return checkboxActive;
    case CheckboxStatuses.CLEARED:
      return checkboxCompleted;
    case CheckboxStatuses.FAILED:
      return checkboxError;
    case CheckboxStatuses.IDLE:
      return checkboxIdle;
    default:
      return checkboxActive;
  }
};
const getColor = status => {
  switch (status) {
    case CheckboxStatuses.CLEARED:
      return 'outline';
    default:
      return 'default';
  }
};
export const ProgressBarCheckbox = props => {
  const {
    copy,
    status,
    lastElement,
    numOfElements
  } = props;
  const testIds = useTestIds(props);
  const lineProps = {
    color: getColor(status),
    lastElement,
    numOfElements
  };
  return __jsx(StatusContainer, _extends({}, lineProps, testIds.item.attr), __jsx(CheckboxContainer, lineProps, __jsx(Checkbox, _extends({
    src: getCheckbox(status)
  }, testIds.checkbox.attr))), __jsx(Status, _extends({
    variant: "body2",
    sx: {
      mt: {
        xs: 0,
        md: 6
      }
    }
  }, testIds.copy.attr), copy));
};