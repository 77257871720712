import _SwipeableDrawer from "@mui/material/SwipeableDrawer";
import _Button from "@mui/material/Button";
import _Tooltip from "@mui/material/Tooltip";
import _Chip from "@mui/material/Chip";
import _Stack from "@mui/material/Stack";
import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { PortfolioStrategyValues } from '@yieldstreet/platform-kit';
import { toUsCurrency } from 'utils/currency-formatters';
import { useManagementStrategy } from 'pages/portfolio/hooks/useManagementStrategy';
import { PaymentStatusColor } from '../../TransactionsListData/TransactionsListData.model';
import { formattedDate, useTransactionDetails } from '../../hooks';
import { testIds } from './TransactionsListMobileDrawer.model';
import { handlePaymentStatusLabel } from '../../TransactionsList.utils';
export const TransactionsListMobileDrawer = _ref => {
  let {
    drawerData,
    open,
    onOpen,
    onClose,
    history
  } = _ref;
  const transactionsDetails = useTransactionDetails({
    transaction: drawerData
  });
  const {
    managementStrategy
  } = useManagementStrategy();
  const isManagedPortfolio = managementStrategy === PortfolioStrategyValues.Discretionary;
  const {
    anticipatedSettlementDate,
    destName,
    distribution,
    effectiveDate,
    fieldDate,
    investorBankAccountName,
    isPayment,
    isDeposit,
    isFund,
    interest,
    linkTo,
    linkFrom,
    notes,
    principal,
    referenceNumber,
    status,
    sourceName,
    showEffectiveDate,
    showSettlementDate,
    showNotes,
    totalAmount
  } = transactionsDetails;
  return __jsx(_SwipeableDrawer, _extends({
    open: open,
    onOpen: onOpen,
    onClose: onClose,
    anchor: "bottom",
    swipeAreaWidth: 60,
    sx: {
      maxHeight: '80%'
    }
  }, testIds.base.attr), __jsx(_Stack, {
    sx: {
      padding: 6
    },
    justifyContent: "space-between",
    gap: 6
  }, __jsx(_Stack, {
    gap: 6
  }, __jsx(_Typography, _extends({
    variant: "h4"
  }, testIds.totalAmount.attr), toUsCurrency(totalAmount)), __jsx(_Stack, {
    gap: 6,
    borderBottom: "1px solid",
    borderColor: "background.surface"
  }, __jsx(_Stack, testIds.sourceName.attr, __jsx(_Typography, {
    component: "span",
    variant: "caption"
  }, "From"), __jsx(_Typography, {
    variant: "h6"
  }, sourceName)), __jsx(_Stack, _extends({
    sx: {
      mb: 6
    }
  }, testIds.destName.attr), __jsx(_Typography, {
    component: "span",
    variant: "caption"
  }, "To"), __jsx(_Typography, {
    variant: "h6"
  }, destName))), __jsx(_Stack, {
    rowGap: 6
  }, __jsx(_Stack, {
    direction: "row",
    justifyContent: "space-between"
  }, __jsx(_Stack, _extends({
    flex: 1
  }, testIds.date.attr), __jsx(_Typography, {
    component: "span",
    variant: "caption"
  }, "Date"), __jsx(_Typography, {
    variant: "body1"
  }, fieldDate)), showEffectiveDate ? __jsx(_Stack, _extends({
    flex: 1
  }, testIds.effectiveDate.attr), __jsx(_Typography, {
    component: "span",
    variant: "caption"
  }, "Effective date"), __jsx(_Typography, {
    variant: "body1"
  }, effectiveDate ? formattedDate(effectiveDate) : '-')) : null), showSettlementDate ? __jsx(_Stack, testIds.expectedSettlementDate.attr, __jsx(_Typography, {
    component: "span",
    variant: "caption"
  }, "Expected settlement date"), __jsx(_Typography, {
    variant: "body1"
  }, anticipatedSettlementDate ? formattedDate(anticipatedSettlementDate) : '-')) : null, __jsx(_Stack, {
    direction: "row",
    justifyContent: "space-between"
  }, status ? __jsx(_Stack, _extends({
    flex: 1
  }, testIds.status.attr), __jsx(_Typography, {
    component: "span",
    variant: "caption"
  }, "Status"), __jsx(_Chip, {
    label: handlePaymentStatusLabel(status),
    color: PaymentStatusColor[status],
    sx: {
      width: 'fit-content'
    }
  })) : null, (!isManagedPortfolio || isDeposit) && __jsx(_Stack, _extends({
    flex: 1
  }, testIds.referenceNumber.attr), __jsx(_Typography, {
    component: "span",
    variant: "caption"
  }, "ACH Trace ID", __jsx(_Tooltip, {
    title: "To track the status of this payment, provide the ACH trace ID to your bank."
  }, __jsx(InfoOutlinedIcon, {
    sx: {
      fontSize: 15,
      ml: 1
    }
  }))), __jsx(_Typography, {
    variant: "body1"
  }, referenceNumber || '-'))), (!isManagedPortfolio || isDeposit) && __jsx(_Stack, testIds.investorBankAccountName.attr, __jsx(_Typography, {
    component: "span",
    variant: "caption"
  }, "Bank account"), __jsx(_Typography, {
    variant: "body1"
  }, investorBankAccountName || '-')), distribution ? __jsx(_Stack, testIds.distribution.attr, __jsx(_Typography, {
    component: "span",
    variant: "caption"
  }, "Distribution method"), __jsx(_Typography, {
    variant: "body1"
  }, distribution)) : null, showNotes && notes ? __jsx(_Stack, testIds.notes.attr, __jsx(_Typography, {
    component: "span",
    variant: "caption"
  }, "Payment note"), __jsx(_Typography, {
    variant: "body1"
  }, notes)) : null, isPayment && !isManagedPortfolio ? __jsx(_Stack, {
    direction: "row",
    justifyContent: "space-between"
  }, __jsx(_Stack, _extends({
    flex: 1
  }, testIds.principal.attr), __jsx(_Typography, {
    component: "span",
    variant: "caption"
  }, isFund ? 'Capital' : 'Principal'), __jsx(_Typography, {
    variant: "body1"
  }, principal ? toUsCurrency(principal) : '-')), __jsx(_Stack, _extends({
    flex: 1
  }, testIds.interest.attr), __jsx(_Typography, {
    component: "span",
    variant: "caption"
  }, isFund ? 'Income' : 'Interest'), __jsx(_Typography, {
    variant: "body1"
  }, interest ? toUsCurrency(interest) : '-'))) : null)), linkTo || linkFrom ? __jsx(_Button, _extends({
    onClick: () => history.push(linkTo.includes('/portfolio/') ? linkTo : linkFrom),
    variant: "outlined"
  }, testIds.button.attr), "View investment") : null));
};