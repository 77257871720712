var __jsx = React.createElement;
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Form, Formik } from 'formik';
import { Button, Heading, Paragraph } from '../../content';
import { FormTextInput } from '../../form';
import { Spinner } from '../../layout/spinner';
import { media } from '../../themes';
import { ModalTrigger } from '../../utils/modal-trigger/ModalTrigger';
import EmailCaptureSuccess from '../../assets/graphics/case-specific/email-capture-success.png';
export const EmailCaptureReward = _ref => {
  let {
    buttonText,
    className,
    modalTimeoutInSeconds,
    offsetSize = 0,
    onSubmit,
    rewardFile,
    rewardImg,
    storageId,
    subtitle,
    timeout = 0,
    title,
    triggerType
  } = _ref;
  const {
    0: showModal,
    1: setShowModal
  } = useState(true);
  const {
    0: submitted,
    1: setSubmitted
  } = useState(false);
  const {
    0: isSubmitting,
    1: setIsSubmitting
  } = useState(false);
  const handleSubmit = async values => {
    setIsSubmitting(true);
    onSubmit && (await onSubmit({
      email: values.email
    }));
    setIsSubmitting(false);
    setSubmitted(true);
  };
  useEffect(() => {
    if (submitted) {
      window.setTimeout(() => {
        const a = document.createElement('a');
        a.href = rewardFile;
        a.target = '_blank';
        a.download = '';
        document.body.appendChild(a);
        a.click();
        a.remove();
        setShowModal(false);
      }, 2000);
    }
  }, [rewardFile, submitted]);
  if (!showModal) {
    return null;
  }
  return __jsx(SModalTrigger, {
    className: className,
    modalTimeoutInSeconds: modalTimeoutInSeconds,
    offsetSize: offsetSize,
    storageId: storageId,
    timeout: timeout,
    triggerType: triggerType,
    width: "1040px"
  }, submitted ? __jsx(WrapperSuccess, null, __jsx(ImgWrapper, null, __jsx("img", {
    src: EmailCaptureSuccess,
    className: "img-success"
  })), __jsx(Heading, {
    type: 3,
    tagType: 4,
    marginTop: true
  }, "Thank you! Your download is starting."), __jsx(Paragraph, {
    marginTop: true,
    marginBottom: true
  }, "This free guide includes important definitions of", __jsx("br", null), " alternative investments, steps to vet investment", __jsx("br", null), " opportunities, and some real life examples."), __jsx(CloseButton, {
    type: "button",
    reducedPadding: true,
    buttonType: "primary",
    onClick: () => setShowModal(false)
  }, "Close")) : __jsx(Wrapper, null, __jsx(ImgWrapper, null, rewardImg), __jsx(WrapperForm, null, __jsx(Heading, {
    type: 3,
    tagType: 4
  }, title), __jsx(Subtitle, null, subtitle), __jsx(Formik, {
    initialValues: {
      email: ''
    },
    onSubmit: handleSubmit
  }, formikProps => __jsx(SForm, null, __jsx(FormTextInput, {
    name: "email",
    type: "email",
    required: true,
    formikProps: formikProps,
    label: "Email address",
    placeholder: "Email address",
    fullWidth: true
  }), __jsx(SubmitButton, {
    type: "submit",
    reducedPadding: true,
    buttonType: "primary"
  }, isSubmitting ? __jsx(Spinner, {
    small: true
  }) : buttonText))), __jsx(Cancel, {
    onClick: () => setShowModal(false)
  }, __jsx(CloseText, {
    secondary: true,
    marginTop: true
  }, "No thanks, my portfolio is fine as is.")))));
};
const SModalTrigger = styled(ModalTrigger).withConfig({
  displayName: "EmailCaptureReward__SModalTrigger",
  componentId: "sc-vfe6ce-0"
})([""]);
const Wrapper = styled.div.withConfig({
  displayName: "EmailCaptureReward__Wrapper",
  componentId: "sc-vfe6ce-1"
})(["align-items:center;display:flex;flex-direction:column;height:100%;overflow:hidden;", ""], media.desktop`
    justify-content: space-between;
    flex-direction: row-reverse;
    max-width: none;
  `);
const WrapperForm = styled.div.withConfig({
  displayName: "EmailCaptureReward__WrapperForm",
  componentId: "sc-vfe6ce-2"
})(["display:flex;flex:1;flex-direction:column;", ""], media.desktop`
    min-width: 430px;
  `);
const Cancel = styled(Button).withConfig({
  displayName: "EmailCaptureReward__Cancel",
  componentId: "sc-vfe6ce-3"
})(["&,&:hover{background:none;border:none;display:flex;padding:0;text-align:left;}"]);
const CloseText = styled(Paragraph).withConfig({
  displayName: "EmailCaptureReward__CloseText",
  componentId: "sc-vfe6ce-4"
})(["cursor:pointer;text-decoration:underline;"]);
const SubmitButton = styled(Button).withConfig({
  displayName: "EmailCaptureReward__SubmitButton",
  componentId: "sc-vfe6ce-5"
})(["width:100%;", ""], media.desktop`
    margin-left: 15px;
    min-width: 153px;
    padding: 14px;
    width: unset;
  `);
const Subtitle = styled(Paragraph).withConfig({
  displayName: "EmailCaptureReward__Subtitle",
  componentId: "sc-vfe6ce-6"
})(["margin:25px 0 40px;"]);
const ImgWrapper = styled.div.withConfig({
  displayName: "EmailCaptureReward__ImgWrapper",
  componentId: "sc-vfe6ce-7"
})(["align-items:center;display:flex;justify-content:center;margin-bottom:40px;position:relative;", " img{max-width:100%;", "}.img-success{animation:rotation 2s infinite linear;height:170px;", " @keyframes rotation{from{transform:rotate(0deg);}to{transform:rotate(359deg);}}}"], media.desktop`
    margin-bottom: 0;
  `, media.desktop`
      max-height: 440px;
    `, media.desktop`
      height: 114px;
    `);
const WrapperSuccess = styled.div.withConfig({
  displayName: "EmailCaptureReward__WrapperSuccess",
  componentId: "sc-vfe6ce-8"
})(["align-items:center;display:flex;flex-direction:column;margin:0 auto;height:100%;", ""], media.desktop`
    text-align: center;
  `);
const CloseButton = styled(Button).withConfig({
  displayName: "EmailCaptureReward__CloseButton",
  componentId: "sc-vfe6ce-9"
})(["margin-top:auto;width:100%;", ""], media.desktop`
    width: auto;
  `);
const SForm = styled(Form).withConfig({
  displayName: "EmailCaptureReward__SForm",
  componentId: "sc-vfe6ce-10"
})(["display:flex;flex-direction:column;margin-top:auto;", ""], media.desktop`
    flex-direction: row;
    height: 60px;
    justify-content: center;
  `);