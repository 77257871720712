var __jsx = React.createElement;
import React from 'react';
import { SkeletonTextAlign } from './Skeleton.model';
import { StyledSkeletonText } from './Skeleton.style';
import { SkeletonLine } from './SkeletonLine';
export const SkeletonText = _ref => {
  let {
    lines = 3,
    align = SkeletonTextAlign.Justify,
    filled = true,
    active
  } = _ref;
  const linesToRender = Array.from(Array(+lines), (_, index) => index + 1);
  return __jsx(StyledSkeletonText, {
    align: align
  }, [...linesToRender].map(idx => __jsx(SkeletonLine, {
    key: idx,
    filled: filled,
    active: active
  })));
};