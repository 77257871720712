var __jsx = React.createElement;
import React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useResponsiveFriendly } from '@yieldstreet/ui-kit';
import { getInvestorEntityRoute, useRouteInvestorEntityId } from 'pages/investorEntity/utils';
import { INVESTOR_ENTITY_MNGT_INFO_PATH } from '../../ManagementContainer.model';
import { AccountLayoutMobile } from '../../components/AccountLayout';
export const EmptyRoute = () => {
  const history = useHistory();
  const viewPortSize = useResponsiveFriendly();
  const investorEntityId = useRouteInvestorEntityId();
  useEffect(() => {
    if (viewPortSize === 'desktop') {
      history.push(getInvestorEntityRoute(investorEntityId, INVESTOR_ENTITY_MNGT_INFO_PATH));
    }
  }, [viewPortSize, history, investorEntityId]);
  return __jsx(AccountLayoutMobile, null);
};