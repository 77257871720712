import { media, Paragraph } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
export const Wrapper = styled.div.withConfig({
  displayName: "MediaCardsSectionstyle__Wrapper",
  componentId: "sc-ye589n-0"
})(["margin:0 auto;background-color:", ";padding-bottom:20px;"], props => props.theme.colors.background_card);
export const InnerWrapper = styled.div.withConfig({
  displayName: "MediaCardsSectionstyle__InnerWrapper",
  componentId: "sc-ye589n-1"
})([""]);
export const CardsWrapper = styled.div.withConfig({
  displayName: "MediaCardsSectionstyle__CardsWrapper",
  componentId: "sc-ye589n-2"
})(["", ";", ";", ";"], media.tablet`
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 -10px 20px;
    :before {
      bottom: 270px;
      top: 335px;
    }
  `, media.desktop`
    margin: 0 -10px;
    :before {
      left: calc(-60vw + 50%);
      height: 500px;
    }
  `, media.large`
    flex-direction: row;
    :before {
      bottom: 0;
      top: -35px;
      left: calc(-50vw + 50%);
    }
  `);
export const Login = styled(Paragraph).withConfig({
  displayName: "MediaCardsSectionstyle__Login",
  componentId: "sc-ye589n-3"
})(["width:100%;"]);