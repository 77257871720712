import { expirationTimer } from '@yieldstreet/platform-kit';
export const SET_TIMER_EXPIRED = 'ys/setTimerRunning/SET';
export const SET_TIMER_EXPIRED_RESET = 'ys/setTimerRunningReset/SET';
let expiredTimerInterval;
export const setTimerRunning = () => dispatch => {
  let counter = expirationTimer.EXPIRATION_TIMEOUT;
  dispatch({
    type: SET_TIMER_EXPIRED_RESET,
    expired: false
  });
  if (expiredTimerInterval) {
    clearInterval(expiredTimerInterval);
  }
  expiredTimerInterval = setInterval(() => {
    counter--;
    if (counter < 0) {
      clearInterval(expiredTimerInterval);
      dispatch({
        type: SET_TIMER_EXPIRED,
        expired: true
      });
    }
  }, expirationTimer.EXPIRATION_INTERVAL);
};