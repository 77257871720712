import { SignupInvestingValues } from '@yieldstreet/platform-kit';
export const SignupInvestingOptions = [{
  value: SignupInvestingValues.DIVERSIFY_MY_PORTFOLIO,
  label: 'Diversify my portfolio'
}, {
  value: SignupInvestingValues.GENERATE_INCOME,
  label: 'Generate income'
}, {
  value: SignupInvestingValues.GROW_LONG_TERM_WEALTH,
  label: 'Grow long term wealth'
}, {
  value: SignupInvestingValues.REDUCE_PORTFOLIO_VOLATILITY,
  label: 'Reduce portfolio volatility'
}];
export const SignupInvestingLabels = {
  title: 'Why do you want to invest in private market alternatives?'
};