export const sumEligibleRolloverAmount = eligibleRolloverArr => {
  return eligibleRolloverArr.map(item => item.amount).reduce((prev, next) => prev + next);
};
export const remainingRolloverPrincipal = (investmentAmount, rolloverAmount) => {
  if (rolloverAmount >= investmentAmount) {
    return rolloverAmount - investmentAmount;
  } else return 0;
};
export const getRolloverTotalLabels = (investmentAmount, rolloverAmount) => {
  if (rolloverAmount >= investmentAmount) {
    return {
      label: 'Principal remaining',
      amount: remainingRolloverPrincipal(investmentAmount, rolloverAmount)
    };
  } else return {
    label: 'Funds needed',
    amount: investmentAmount - rolloverAmount
  };
};
export const getAdditionalFundsNeeded = (investmentAmount, rolloverAmount, walletBalance) => {
  const rolloverFundsPlusWalletBalance = walletBalance + rolloverAmount;

  // The 3 possible scenarios during a rollover are outlined below:
  // 1. no additional funds needed
  if (rolloverAmount >= investmentAmount) {
    return {
      fundsNeeded: false,
      walletFundsRequired: 0,
      additionalFundsRequired: 0
    };
  }
  // 2. wallet balance covers remainder
  else if (rolloverFundsPlusWalletBalance >= investmentAmount) {
    return {
      fundsNeeded: true,
      walletFundsRequired: investmentAmount - rolloverAmount,
      additionalFundsRequired: 0
    };
  }
  // 3. wallet balance does not cover remainder and additional deposit needed
  else if (rolloverFundsPlusWalletBalance < investmentAmount) {
    return {
      fundsNeeded: true,
      walletFundsRequired: walletBalance,
      additionalFundsRequired: investmentAmount - rolloverFundsPlusWalletBalance
    };
  }
};