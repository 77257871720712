import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["center"];
var __jsx = React.createElement;
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useTestIds } from './PageLoader.model';
import { PageLoaderContainer } from './PageLoader.style';
export const PageLoader = _ref => {
  let {
      center
    } = _ref,
    baseProps = _objectWithoutProperties(_ref, _excluded);
  const testIds = useTestIds(baseProps);
  return __jsx(PageLoaderContainer, _extends({
    center: center,
    role: "progressbar"
  }, testIds.base.attr), __jsx(CircularProgress, {
    size: 32
  }));
};