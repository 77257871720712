import { HttpMethods } from '../../../enums';
import { restApi, Tags } from '../../common';
export const reportsApi = restApi.enhanceEndpoints({
  addTagTypes: [Tags.UserReports, Tags.UserReportsYears]
}).injectEndpoints({
  endpoints: build => {
    const getReports = build.query({
      query: _ref => {
        let {
          investorId,
          userId,
          reportType,
          year,
          managementStrategy,
          providers
        } = _ref;
        return {
          url: `/a/api/user/${userId}/reports`,
          method: HttpMethods.Get,
          params: {
            investorAccountId: investorId,
            type: reportType,
            year,
            managementStrategy,
            providers
          }
        };
      },
      providesTags: [Tags.UserReports],
      transformResponse: _ref2 => {
        let {
          reports
        } = _ref2;
        return {
          reports
        };
      }
    });
    const getReportsYears = build.query({
      query: _ref3 => {
        let {
          investorId,
          reportType,
          userId,
          managementStrategy,
          providers
        } = _ref3;
        return {
          url: `/a/api/user/${userId}/reports/available-years`,
          method: HttpMethods.Get,
          params: {
            investorAccountId: investorId,
            type: reportType,
            managementStrategy,
            providers
          }
        };
      },
      providesTags: [Tags.UserReportsYears],
      transformResponse: _ref4 => {
        let {
          years
        } = _ref4;
        return {
          years
        };
      }
    });
    return {
      getReports,
      getReportsYears
    };
  }
});