import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var __jsx = React.createElement;
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import isArray from "lodash/isArray";
import { v4 as uuidv4 } from 'uuid';
import { quizApi } from '@yieldstreet/platform-kit';
import { useQuizAnswers } from '@yieldstreet/tool-kit';
import { useAdvice } from '../advice/useAdvice';
import { useUserState } from './useUserState';
export const useQuiz = function (quizKey) {
  let {
    currentQuizId,
    investorEntityId
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const userState = useUserState();
  const {
    id: userId = null
  } = userState || {};
  const anonymousUserId = useSelector(_ref => {
    let {
      user
    } = _ref;
    return (user === null || user === void 0 ? void 0 : user.analyticsAnonymousId) || null;
  });
  const quizId = useMemo(() => currentQuizId !== null && currentQuizId !== void 0 ? currentQuizId : uuidv4(), [currentQuizId]);
  const {
    quizAnswers: allQuizAnswers,
    isFetchingAnswers,
    isLoadingAnswers
  } = useQuizAnswers({
    userId,
    investorEntityId,
    quizId: currentQuizId
  });
  const [postAnswer] = quizApi.usePostAnswerMutation();
  const {
    0: context,
    1: setContext
  } = useState({});
  const location = useLocation();
  useEffect(() => {
    const {
      language,
      userLanguage,
      userAgent
    } = navigator;
    const {
      referrer,
      title,
      URL
    } = document;
    const {
      pathname,
      search
    } = location;
    setContext({
      locale: language || userLanguage,
      page: {
        path: pathname,
        referrer,
        search,
        title,
        url: URL
      },
      userAgent
    });
  }, [location]);
  const saveQuizAnswer = useCallback(function (questionKey, answers) {
    let properties = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    postAnswer({
      quizId,
      quizKey,
      questionKey,
      answers: !isArray(answers) ? [answers] : answers,
      properties,
      context,
      anonymousUserId: userId ? undefined : anonymousUserId,
      userId,
      investorEntityId
    });
  }, [postAnswer, quizId, quizKey, context, anonymousUserId, userId, investorEntityId]);
  const quizAnswers = useMemo(() => {
    var _allQuizAnswers$answe;
    return allQuizAnswers === null || allQuizAnswers === void 0 ? void 0 : (_allQuizAnswers$answe = allQuizAnswers.answers) === null || _allQuizAnswers$answe === void 0 ? void 0 : _allQuizAnswers$answe.reduce((acc, quizAnswer) => {
      if (quizAnswer.quizKey === quizKey) {
        if (investorEntityId) {
          if (quizAnswer.investorEntityId === investorEntityId) {
            return _objectSpread(_objectSpread({}, acc), {}, {
              [quizAnswer.questionKey]: quizAnswer
            });
          }
        } else {
          return _objectSpread(_objectSpread({}, acc), {}, {
            [quizAnswer.questionKey]: quizAnswer
          });
        }
      }
      return acc;
    }, {});
  }, [investorEntityId, quizKey, allQuizAnswers]);
  const getQuizAnswer = useCallback((questionKey, multipleValues, defaultValue) => {
    const questionByKey = quizAnswers === null || quizAnswers === void 0 ? void 0 : quizAnswers[questionKey];
    const questionAnswers = questionByKey === null || questionByKey === void 0 ? void 0 : questionByKey.answers;
    if (questionAnswers) {
      return multipleValues ? questionAnswers : questionAnswers[0];
    }
    return defaultValue;
  }, [quizAnswers]);
  return {
    quizId,
    quizAnswers,
    isFetchingAnswers,
    isLoadingAnswers,
    getQuizAnswer,
    saveQuizAnswer
  };
};
export default useQuiz;
export const goalsQuizHOC = Component => {
  return props => {
    const {
      isFetchingAnswers,
      getQuizAnswer
    } = useQuiz('goals-quiz');
    const advice = useAdvice();
    return __jsx(Component, _extends({
      fetchingQuizAnswers: isFetchingAnswers,
      getQuizAnswer: getQuizAnswer,
      advice: advice
    }, props));
  };
};