import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var __jsx = React.createElement;
import React, { useEffect, useRef, useState, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import { getNavItemVisible, NavigationLink, useNavItemProps } from '../common';
import { DesktopOnly } from '../../utils';
import { Wrapper, MenuItem, SContainer, ScrollContainer, MenuContainer, MenuList, Line, ExtraContainer } from './Submenu.style';
export const Submenu = props => {
  const {
    menu,
    inverse,
    sticky,
    desktopStickyPx = 74,
    mobileStickyPx = 66,
    activeKey,
    useTabs,
    onClick,
    className,
    extra = null,
    addTransition = false,
    preContent,
    postContent
  } = props;
  const {
    0: activeItemProps,
    1: setActiveItemProps
  } = useState();
  const {
    0: fontsLoaded,
    1: setFontsLoaded
  } = useState(false);
  const refs = useRef({});
  const menuRef = useRef(null);
  const menuUpdate = useCallback(() => {
    var _menuRef$current;
    if (!activeKey || isEmpty(refs.current)) {
      setActiveItemProps(undefined);
      return;
    }
    const menuWidth = (_menuRef$current = menuRef.current) === null || _menuRef$current === void 0 ? void 0 : _menuRef$current.offsetWidth;
    const element = refs.current[activeKey];
    if (!element) {
      return;
    }
    const boundingClientRect = element.getBoundingClientRect();
    const width = boundingClientRect.width;
    const left = element.offsetLeft;
    const clientLeft = Math.round(boundingClientRect.left);
    if (menuWidth && clientLeft + width >= menuWidth || clientLeft < 0) {
      if (menuWidth && menuRef.current) {
        var _menuRef$current2, _menuRef$current3;
        const sum = menuWidth / 2 - (menuWidth - clientLeft) + ((_menuRef$current2 = menuRef.current) === null || _menuRef$current2 === void 0 ? void 0 : _menuRef$current2.scrollLeft) + width / 2;
        (_menuRef$current3 = menuRef.current) === null || _menuRef$current3 === void 0 ? void 0 : _menuRef$current3.scrollTo({
          left: sum,
          behavior: 'smooth'
        });
      }
    }
    setActiveItemProps({
      width: `${width}px`,
      left: `${left}px`
    });
  }, [activeKey]);

  // Fixes font slow loading issue with initial width calculation
  useEffect(() => {
    if (!fontsLoaded && activeKey) {
      setTimeout(() => {
        menuUpdate();
        setFontsLoaded(true);
      }, 350);
    }
  }, [activeKey, fontsLoaded, menuUpdate]);
  useEffect(() => {
    menuUpdate();
  }, [menuUpdate]);
  const navProps = useNavItemProps(props);
  const items = menu.map(item => {
    const {
      key
    } = item;
    const itemVisible = getNavItemVisible(item, navProps);
    return itemVisible ? __jsx(MenuItem, {
      ref: element => {
        if (!element) return;
        refs.current[`${element.getAttribute('data-key')}`] = element;
      },
      key: key,
      "data-key": key,
      isActive: activeKey === key,
      isTab: useTabs,
      semiBold: true,
      inverse: !inverse ? inverse : !(inverse && activeKey === key && useTabs)
    }, __jsx(NavigationLink, _extends({
      navItem: _objectSpread(_objectSpread({}, item), {}, {
        callback: () => onClick && onClick(key)
      }),
      raw: true
    }, props))) : null;
  });
  return __jsx(Wrapper, {
    "data-cy": "submenu",
    sticky: sticky,
    desktopStickyPx: desktopStickyPx,
    mobileStickyPx: mobileStickyPx,
    inverse: inverse,
    addTransition: addTransition
  }, preContent, __jsx(SContainer, null, __jsx(ScrollContainer, null, __jsx(MenuContainer, {
    className: className
  }, __jsx(MenuList, {
    useTabs: useTabs,
    ref: menuRef
  }, items, activeKey && __jsx(Line, _extends({
    "data-cy": "submenu-highlight",
    isTab: useTabs
  }, activeItemProps))), extra && __jsx(ExtraContainer, null, __jsx(DesktopOnly, null, extra))))), postContent);
};