import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { configureRTKMockStore, getMockEndpoint, getRTKMockStoreExtraMiddlewares, MAX_ROLLOVER_AMOUNT_MOCK, offeringApi, offeringV5, portfolioGraphQLApi } from '@yieldstreet/platform-kit';
export const MockProps = {
  urlHash: 'test',
  investorId: '123'
};
const MOCK_OFFERING = _objectSpread(_objectSpread({}, offeringV5), {}, {
  urlHash: MockProps.urlHash
});
export const getMockEndpoints = () => [getMockEndpoint(portfolioGraphQLApi, 'getRolloverAmount', _objectSpread({}, MAX_ROLLOVER_AMOUNT_MOCK)), getMockEndpoint(offeringApi, 'details', {
  [MOCK_OFFERING.urlHash]: {
    note: MOCK_OFFERING
  }
})];
export const getMockStore = () => configureRTKMockStore(getMockEndpoints(), {
  user: () => ({
    userState: {
      loggedIn: true
    }
  }),
  investorAccounts: () => ({})
}, getRTKMockStoreExtraMiddlewares(), []);