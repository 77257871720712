import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import { AdviceQuestionKeys } from '@yieldstreet/platform-kit';
import { ADVICE_HOW_IT_WORKS_PATH, ADVICE_RECOMMENDATIONS_PATH } from 'pages/advice';
import adviceChart from 'sharedComponents/Advice/assets/advice-chart.svg';
import adviceOfferingCardEligible from 'sharedComponents/Advice/assets/advice-offering-eligible.svg';
import adviceOfferingCardReady from 'sharedComponents/Advice/assets/advice-offering-ready.svg';
import adviceWidgetChart from './assets/chart-widget.svg';
import advicePaths from './assets/paths-widget.svg';
export const ADVICE_QUIZ_KEY = 'advice-quiz';
export const ADVICE_QUIZ_QUESTION_KEYS = Object.freeze(Object.values(AdviceQuestionKeys));
const VIEW_RECOMMENDATIONS = {
  heading: 'Your tailored recommendations are ready when you are.',
  subHeading: 'We’ve analyzed our open offerings and constructed a set of recommendations based on your preferences.',
  cardImage: adviceChart,
  offeringCardImage: adviceOfferingCardReady,
  widgetImage: adviceWidgetChart,
  cta: {
    link: ADVICE_RECOMMENDATIONS_PATH,
    text: 'View recommendations',
    subText: 'There is limited availability for some investments'
  }
};
const SETUP_PREFERENCES = {
  heading: 'Not sure where to invest?',
  subHeading: 'Answer a few questions to receive a customized set of recommendations in just 2-3 minutes.',
  shortSubHeading: 'Tap into the collective wisdom of our investment teams.',
  cardImage: adviceChart,
  offeringCardImage: adviceOfferingCardEligible,
  widgetImage: advicePaths,
  cta: {
    link: ADVICE_HOW_IT_WORKS_PATH,
    text: 'Unlock recommendations',
    subText: 'Set up preferences'
  }
};
const CREATE_INVESTOR_ACCOUNT = _objectSpread(_objectSpread({}, SETUP_PREFERENCES), {}, {
  cta: {
    link: '/create-investor-account',
    text: 'Unlock recommendations',
    subText: 'Create investor account'
  }
});
export const ADVICE_ONRAMP_CONTENT = {
  VIEW_RECOMMENDATIONS,
  SETUP_PREFERENCES,
  CREATE_INVESTOR_ACCOUNT
};