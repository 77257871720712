import _Typography from "@mui/material/Typography";
var __jsx = React.createElement;
import React from 'react';
import { CardContainer, CardContent, CardText, CTAHolder, Description, Image, ImageWrapper } from './ArticleCard.style';
export const ArticleCard = _ref => {
  let {
    cta,
    description,
    image,
    mini,
    testId,
    title
  } = _ref;
  return __jsx(CardContainer, {
    mini: mini,
    "data-cy": `${testId ? testId : 'card'}`
  }, image && __jsx(ImageWrapper, {
    mini: mini
  }, __jsx(Image, {
    image: image,
    mini: mini
  })), __jsx(CardContent, null, __jsx(CardText, null, __jsx(_Typography, {
    component: mini ? 'h5' : 'h4',
    variant: mini ? 'h5' : 'h4',
    sx: {
      mb: 1.5
    }
  }, title), __jsx(Description, {
    mini: mini,
    variant: "body2"
  }, description), cta && __jsx(CTAHolder, null, cta))));
};