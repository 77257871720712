import { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { getInvestorEntity, getInvestorEntityAction } from '@yieldstreet/tool-kit';
import { investorEntityApi, investorEntityStatusApi, InvestorLevelGateCode, UserLevelGateCode } from '@yieldstreet/platform-kit';
import { useBankLinkingModal } from 'pages/bankLinking';
import { useUserState } from 'utils/hooks';
const {
  NO_INVESTOR_ACCOUNT
} = UserLevelGateCode;
export const useGetInvestmentEligibilityAction = () => {
  const {
    push
  } = useHistory();
  const userState = useUserState();
  const bankLinkingModal = useBankLinkingModal({});
  const [getAccountsStatuses, {
    data: accountStatuses,
    isSuccess: accountStatusesLoaded
  }] = investorEntityStatusApi.useLazyGetAllInvestorEntityStatusQuery();
  const [getInvestorEntities, {
    data: investorEntities,
    isSuccess: accountsLoaded
  }] = investorEntityApi.useLazyGetInvestorEntityQuery();
  useEffect(() => {
    if (userState !== null && userState !== void 0 && userState.loggedIn) {
      getInvestorEntities();
      getAccountsStatuses();
    }
  }, [getAccountsStatuses, getInvestorEntities, userState]);
  const getInvestmentEligibilityAction = useCallback(gateData => {
    if (!accountStatusesLoaded || !accountsLoaded) {
      return null;
    }
    const {
      userGate,
      actionableAccount
    } = gateData;
    const {
      investorEntityId,
      gateCode,
      actionCode
    } = actionableAccount || {};
    const userGateActionConfig = makeUserGateActionConfig({
      push
    });

    /**
     * USER GATES
     */
    if ((userGate === null || userGate === void 0 ? void 0 : userGate.gateCode) === NO_INVESTOR_ACCOUNT) {
      return userGateActionConfig[NO_INVESTOR_ACCOUNT];
    }
    if (!actionableAccount) return null;

    /**
     * INVESTOR ACCOUNT GATES that are actionable
     */
    if (gateCode === InvestorLevelGateCode.ACCOUNT_ACTION) {
      const {
        investorEntity,
        investorEntityStatus
      } = getInvestorEntity(investorEntityId, investorEntities.entities, accountStatuses);
      const nextAction = getInvestorEntityAction(actionCode, investorEntity, investorEntityStatus, () => {
        bankLinkingModal.open({
          investorEntityId: investorEntity === null || investorEntity === void 0 ? void 0 : investorEntity.id
        });
      });
      if (nextAction) {
        return {
          description: nextAction.title.toLowerCase(),
          title: nextAction.title,
          // can eventually use `nextStep` from investorData
          action: nextAction.action ? nextAction.action : () => push(nextAction.link)
        };
      }
      return null;
    }
    return null;
  }, [accountStatuses, accountStatusesLoaded, accountsLoaded, bankLinkingModal, investorEntities === null || investorEntities === void 0 ? void 0 : investorEntities.entities, push]);
  return {
    getInvestmentEligibilityAction
  };
};
function makeUserGateActionConfig(_ref) {
  let {
    push
  } = _ref;
  return {
    [NO_INVESTOR_ACCOUNT]: {
      description: 'create your investor account',
      title: 'Create investor account',
      action: () => push(`/create-investor-account`)
    }
  };
}