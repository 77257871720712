var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import ActionItemsIndicatorMark from '../../assets/graphics/case-specific/action_items_indicator_mark.svg';
import { Paragraph } from '../../content';
import { media } from '../../themes/media';
export const ActionItemsIndicator = _ref => {
  let {
    iconOnly,
    largeIcon,
    onClick,
    text = 'Action items'
  } = _ref;
  const handleOnClick = () => {
    typeof onClick === 'function' && onClick();
  };
  return __jsx(IndicatorContainer, {
    "data-testid": "action-items-indicator",
    iconOnly: iconOnly,
    onClick: handleOnClick
  }, __jsx(IndicatorIcon, {
    alt: "Exclamation mark",
    largeIcon: largeIcon,
    src: ActionItemsIndicatorMark
  }), !iconOnly && __jsx(IndicatorText, {
    semiBold: true,
    small: true
  }, text));
};
const IndicatorContainer = styled.div.withConfig({
  displayName: "ActionItemsIndicator__IndicatorContainer",
  componentId: "sc-1jaykua-0"
})(["align-items:center;cursor:pointer;display:inline-flex;flex-shrink:0;margin:0 10px;padding:5px 0;", ""], media.desktop`
    ${props => !props.iconOnly && `
        background: ${props.theme.colors.action_indicator_background};
        padding-left: 10px;
        padding-right: 10px;
      `}
  `);
const IndicatorIcon = styled.img.withConfig({
  displayName: "ActionItemsIndicator__IndicatorIcon",
  componentId: "sc-1jaykua-1"
})(["height:", ";width:", ";"], props => props.largeIcon ? '23px' : '16px', props => props.largeIcon ? '23px' : '16px');
const IndicatorText = styled(Paragraph).withConfig({
  displayName: "ActionItemsIndicator__IndicatorText",
  componentId: "sc-1jaykua-2"
})(["color:", ";display:none;margin-left:8px;", ""], props => props.theme.colors.action_indicator_color, media.desktop`
    display: block;
  `);