export const SEMANTIC = {
  // Semantic | INFO | SUCCESS | ALERT | WARNING | ERROR
  // ALERT - not part of MUI
  info: {
    50: '#f0fbff',
    100: '#cff2ff',
    200: '#b8ecff',
    300: '#98e4ff',
    400: '#84deff',
    500: '#65d6ff',
    600: '#5cc3e8',
    700: '#4898b5',
    800: '#38768c',
    900: '#2a5a6b'
  },
  success: {
    50: '#f7fcef',
    100: '#e7f5ce',
    200: '#dcf1b6',
    300: '#cbea95',
    400: '#c1e681',
    500: '#b2e061',
    600: '#a2cc58',
    700: '#7e9f45',
    800: '#627b35',
    900: '#4b5e29'
  },
  alert: {
    50: '#fffdf0',
    100: '#fffacf',
    200: '#fff7b8',
    300: '#fff498',
    400: '#fff184',
    500: '#ffee65',
    600: '#e8d95c',
    700: '#b5a948',
    800: '#8c8338',
    900: '#6b642a'
  },
  warning: {
    50: '#fff8ef',
    100: '#ffe8cc',
    200: '#ffddb3',
    300: '#ffcd90',
    400: '#ffc47b',
    500: '#ffb55a',
    600: '#e8a552',
    700: '#b58140',
    800: '#8c6432',
    900: '#6b4c26'
  },
  error: {
    50: '#fff2f1',
    100: '#fed7d2',
    200: '#fec4bd',
    300: '#fea99f',
    400: '#fd998c',
    500: '#fd7f6f',
    600: '#e67465',
    700: '#b45a4f',
    800: '#8b463d',
    900: '#6a352f'
  }
};