import styled from 'styled-components';
export const PageSheetBackButtonWrapper = styled.div.withConfig({
  displayName: "PageSheetBackButtonstyle__PageSheetBackButtonWrapper",
  componentId: "sc-npn5vm-0"
})(["display:flex;align-items:center;justify-content:flex-start;width:", ";height:", ";cursor:pointer;"], _ref => {
  let {
    theme
  } = _ref;
  return theme.spacing.l;
}, _ref2 => {
  let {
    theme
  } = _ref2;
  return theme.spacing.l;
});