var __jsx = React.createElement;
import React from 'react';
import { Button } from '../../button';
import { Link } from './ButtonLink.style';
export const ButtonLink = _ref => {
  let {
    buttonProps,
    children,
    linkProps
  } = _ref;
  return __jsx(Link, linkProps, __jsx(Button, buttonProps, children));
};