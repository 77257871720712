var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { Heading, Paragraph } from '../../../content/text';
export const TitleWithSubtitle = _ref => {
  let {
    title,
    headingType = 3,
    children,
    seperator,
    inverse
  } = _ref;
  return __jsx(OuterContainer, {
    seperator: seperator
  }, __jsx(ContentHolder, null, __jsx(Title, {
    type: headingType,
    inverse: inverse
  }, title), __jsx(Paragraph, {
    secondary: true,
    inverse: inverse
  }, children)));
};
const OuterContainer = styled.div.withConfig({
  displayName: "TitleWithSubtitle__OuterContainer",
  componentId: "sc-7l9qpm-0"
})(["display:flex;position:relative;padding-bottom:20px;margin-bottom:20px;&::after{", ";content:'';position:absolute;bottom:0;left:0;width:20px;height:2px;}"], props => props.seperator ? `background: ${props.theme.colors.border_default}` : '');
const ContentHolder = styled.div.withConfig({
  displayName: "TitleWithSubtitle__ContentHolder",
  componentId: "sc-7l9qpm-1"
})([""]);
const Title = styled(Heading).withConfig({
  displayName: "TitleWithSubtitle__Title",
  componentId: "sc-7l9qpm-2"
})(["margin-bottom:20px;"]);