import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { FormCheckboxInput, FormError, Heading, Label, SFormControl } from '@yieldstreet/ui-kit';
import { CheckboxesWrapper, CheckboxesWrapperTtitle, FormErrorWrapper } from './MultipleCheckboxes.style';
import { useTestIds } from './MultipleCheckboxes.model';
export const MultipleCheckboxes = _ref => {
  let {
    title,
    description,
    name,
    options,
    formikProps,
    testId
  } = _ref;
  const {
    errors,
    touched
  } = formikProps;
  const errorMsg = errors === null || errors === void 0 ? void 0 : errors[name];
  const showError = touched === null || touched === void 0 ? void 0 : touched[name];
  const testIds = useTestIds({
    testId: testId || name || 'select'
  });
  return __jsx(SFormControl, null, __jsx(CheckboxesWrapper, null, __jsx(CheckboxesWrapperTtitle, null, __jsx(Heading, _extends({
    type: 5
  }, testIds.label.attr), title), __jsx(Label, testIds.description.attr, description)), options.map(item => __jsx(FormCheckboxInput, {
    name: `${name}[${item.value}]`,
    label: item.label,
    formikProps: formikProps,
    testId: `${testIds.option.id}-${item.value}`
  })), showError && __jsx(FormErrorWrapper, null, __jsx(FormError, {
    type: "checkbox-input",
    name: name,
    testId: "test",
    error: errorMsg
  }))));
};