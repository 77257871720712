import _Stack from "@mui/material/Stack";
import _Button from "@mui/material/Button";
import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { InformationBlock } from '../InformationBlock';
import { useTestIds, testIds } from './EmptyScreen.model';
export const EmptyScreen = props => {
  const {
    buttonAction,
    buttonText,
    disabled,
    heading,
    message,
    paragraph,
    showAction = true
  } = props;
  const buttonTestId = useTestIds(props);
  const commonMargins = {
    mt: 4,
    mb: 8
  };
  return __jsx(InformationBlock, null, __jsx(_Stack, _extends({
    sx: {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      margin: {
        xs: 'auto 0',
        md: '0'
      },
      minHeight: {
        md: 460
      }
    }
  }, testIds.wrapper.attr), __jsx(_Typography, _extends({
    variant: "h4"
  }, testIds.heading.attr), heading), __jsx(_Typography, _extends({
    sx: commonMargins,
    variant: "body1"
  }, testIds.paragraph.attr), paragraph), showAction && __jsx(_Button, _extends({
    disabled: disabled,
    onClick: buttonAction
  }, buttonTestId.button.attr), buttonText), message && __jsx(_Typography, _extends({
    sx: commonMargins
  }, testIds.message.attr), message)));
};