import { ALL_INVESTMENTS } from './usePortfolioInvestmentsFilter.model';
const noteUrlHash = value => `Note url hash ${value}`;
const title = value => `Title ${value}`;
export const mockInvestmentsInvestorId = 8;
export const mockInvestmentsUserId = 8;
export const mockInvestmentsFilterByStatus = {
  byStatus: {
    ACTIVE: {
      investments: [{
        investorId: 1,
        noteTitle: title(1),
        noteUrlHash: noteUrlHash(1),
        performanceStatus: 'PERFORMING'
      }, {
        investorId: 1,
        noteTitle: title(2),
        noteUrlHash: noteUrlHash(2),
        performanceStatus: 'DEFAULT'
      }, {
        investorId: 1,
        noteTitle: title(3),
        // duplicating this because the hook should remove duplicates
        noteUrlHash: noteUrlHash(3),
        performanceStatus: 'REPAID'
      }]
    },
    MATURED: {
      investments: [{
        investorId: 1,
        noteTitle: title(3),
        noteUrlHash: noteUrlHash(3),
        performanceStatus: 'PERFORMING'
      }, {
        investorId: 1,
        noteTitle: title(4),
        noteUrlHash: noteUrlHash(4),
        performanceStatus: 'SAMPLE'
      }]
    },
    PENDING: {
      investments: [{
        investorId: 1,
        noteTitle: title(4),
        // duplicating this because the hook should remove duplicates
        noteUrlHash: noteUrlHash(4),
        performanceStatus: 'TEST'
      }, {
        investorId: 1,
        noteTitle: title(5),
        noteUrlHash: noteUrlHash(5),
        performanceStatus: 'TEST'
      }, {
        investorId: 4,
        noteTitle: title(6),
        noteUrlHash: noteUrlHash(6),
        performanceStatus: 'PERFORMING'
      }]
    }
  }
};
const getData = () => {
  return Array.from(Array(6)).map((_, index) => ({
    label: title(index + 1),
    value: noteUrlHash(index + 1),
    id: noteUrlHash(index + 1)
  }));
};
export const mockInvestmentsFilterWithData = {
  investmentsOptions: [ALL_INVESTMENTS, ...getData()],
  isFetchingInvestments: false
};
export const mockInvestmentsFilterWithoutData = {
  investmentsOptions: [ALL_INVESTMENTS],
  isFetchingInvestments: false
};