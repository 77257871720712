import _Button from "@mui/material/Button";
import _Box from "@mui/material/Box";
import _Stack from "@mui/material/Stack";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import CloseIcon from '../../assets/graphics/icons/close.svg';
import CloseIconWhite from '../../assets/graphics/icons/close_white.svg';
import { testIds } from './OfferingAnnouncement.model';
import { ButtonContainer, Close, Container, Heading, OfferingDetail, Paragraph, Wrapper } from './OfferingAnnouncement.style';
export const OfferingAnnouncement = _ref => {
  let {
    assetClass,
    backgroundColor,
    buttonText,
    dismiss,
    dismissible,
    interest,
    inverse,
    offeringTitle,
    onClick,
    orientation = 'row',
    path,
    small,
    terms,
    title
  } = _ref;
  return __jsx(Wrapper, {
    backgroundColor: backgroundColor
  }, __jsx(Container, {
    small: small,
    orientation: orientation
  }, __jsx(_Box, null, __jsx(Heading, _extends({
    variant: "h5",
    inverse: inverse,
    sx: {
      mb: 5
    }
  }, testIds.title.attr), title), assetClass && __jsx(Paragraph, _extends({
    variant: "body2",
    inverse: inverse
  }, testIds.assetClass.attr), assetClass), __jsx(Heading, _extends({
    inverse: inverse,
    sx: {
      mb: 3
    },
    variant: small ? 'h4' : 'h3'
  }, testIds.offeringTitle.attr), offeringTitle), __jsx(_Stack, {
    direction: "row"
  }, interest && __jsx(OfferingDetail, {
    small: small
  }, __jsx(Paragraph, {
    variant: "body2",
    inverse: inverse
  }, "Annual return:"), ' ', small ? __jsx(Paragraph, _extends({
    inverse: inverse,
    semiBold: true,
    variant: "body2"
  }, testIds.interestSmall.attr), interest) : __jsx(Heading, _extends({
    variant: "h4",
    inverse: inverse
  }, testIds.interest.attr), interest)), terms && __jsx(OfferingDetail, {
    small: small
  }, __jsx(Paragraph, {
    variant: "body2",
    inverse: inverse
  }, "Term:"), ' ', small ? __jsx(Paragraph, _extends({
    variant: "body2",
    color: inverse ? 'text.paragraphPrimary.inverse' : '',
    semiBold: true
  }, testIds.termsSmall.attr), terms) : __jsx(Heading, _extends({
    variant: "h4",
    inverse: inverse
  }, testIds.terms.attr), terms)))), __jsx(ButtonContainer, {
    orientation: orientation
  }, __jsx(RouterLink, _extends({
    to: path || ''
  }, testIds.link.attr), __jsx(_Button, _extends({
    onClick: onClick
  }, testIds.button.attr), buttonText))), dismissible && __jsx(Close, {
    src: inverse ? CloseIconWhite : CloseIcon,
    onClick: dismiss
  })));
};