import update from 'immutability-helper';
import * as actions from 'store/actions/expirationTimer';
export const initialState = {
  expired: false
};
const expirationTimer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case actions.SET_TIMER_EXPIRED:
      {
        return update(state, {
          expired: {
            $set: action.expired
          }
        });
      }
    case actions.SET_TIMER_EXPIRED_RESET:
      {
        return update(state, {
          expired: {
            $set: action.expired
          }
        });
      }
    default:
      return state;
  }
};
export default expirationTimer;