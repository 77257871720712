import styled, { css } from 'styled-components';
import InputLabel from '@mui/material/InputLabel';
import FilledInput from '@mui/material/FilledInput';
import { LabelStyles } from '../common';
export const InputLabelStyles = css(["", " &.MuiInputLabel-filled{color:", ";}"], LabelStyles, props => props.theme.colors.text_label);
export const SInputLabel = styled(InputLabel).withConfig({
  displayName: "styles__SInputLabel",
  componentId: "sc-2625t1-0"
})(["&.MuiFormLabel-root{", "}"], InputLabelStyles);
export const FilledInputStyles = css(["font-family:", ";border:1px solid ", ";border-bottom:0;border-radius:2px;background-color:", ";&:hover{background-color:", ";}&.Mui-focused{background-color:", ";}&.Mui-disabled{background-color:", ";&:before{border-bottom-style:solid;}& .MuiFilledInput-input{color:", ";-webkit-text-fill-color:", ";}}&.MuiFilledInput-root{overflow:hidden;}&.MuiInputBase-multiline{padding:28px 12px 10px;}& .MuiInputBase-input{color:", ";&:not(.MuiInputBase-inputMultiline){padding:25px 12px 10px;}&.MuiInputBase-inputHiddenLabel{padding:19px 12px 19px;border:0;}&.MuiInputBase-inputAdornedStart{padding-left:6px;}&.MuiInputBase-inputAdornedEnd{padding-right:6px;}}&.MuiFilledInput-underline{min-height:60px;&.Mui-disabled{&:hover:before,&:before{border-bottom:1px solid ", ";}}&:before{border-bottom:1px solid ", ";}&:after{border-bottom:2px solid ", ";}&.Mui-error{&:hover:after,&:after{border-bottom:2px solid ", ";}}}& .MuiInputAdornment-filled{&.MuiInputAdornment-positionStart{margin-right:0;}&.MuiInputAdornment-positionEnd{margin-left:0;}}&.MuiFilledInput-adornedStart{padding-left:10px;}&.MuiFilledInput-adornedEnd{padding-right:10px;}"], props => props.theme.typography.secondary, props => props.theme.colors.background_page, props => props.theme.colors.input_background, props => props.theme.colors.input_background, props => props.theme.colors.input_background, props => props.theme.colors.input_disabled_background, props => props.theme.colors.input_disabled_text, props => props.theme.colors.input_disabled_text, props => props.theme.colors.text_paragraph, props => props.theme.colors.border_default, props => props.theme.colors.border_default, props => props.theme.colors.border_outline, props => props.theme.colors.notice_error);
export const SFilledInput = styled(FilledInput).withConfig({
  displayName: "styles__SFilledInput",
  componentId: "sc-2625t1-1"
})(["&.MuiInputBase-root{", "}"], FilledInputStyles);