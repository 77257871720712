import { media, Link } from '@yieldstreet/ui-kit';
import styled from 'styled-components';
export const SButton = {
  borderRadius: '20px',
  textTransform: 'none'
};
export const Wrapper = styled.div.withConfig({
  displayName: "InvestmentVotestyle__Wrapper",
  componentId: "sc-5vsy4d-0"
})(["display:flex;gap:10px;justify-content:space-between;padding:", ";", " align-items:center;"], props => props.theme.spacing.s, media.large`
    border-top: 1px solid ${props => props.theme.colors.border_default};
  `);
export const DesktopLinkWrapper = styled.div.withConfig({
  displayName: "InvestmentVotestyle__DesktopLinkWrapper",
  componentId: "sc-5vsy4d-1"
})(["display:none;gap:10px;", ""], media.large`
    display: flex;
  `);
export const MobileTabletLinkWrapper = styled.div.withConfig({
  displayName: "InvestmentVotestyle__MobileTabletLinkWrapper",
  componentId: "sc-5vsy4d-2"
})(["display:flex;gap:10px;flex-direction:column;border-top:1px solid ", ";", ""], props => props.theme.colors.border_default, media.large`
    display: none;
    `);
export const SLink = styled(Link).withConfig({
  displayName: "InvestmentVotestyle__SLink",
  componentId: "sc-5vsy4d-3"
})(["overflow:hidden;white-space:nowrap;text-overflow:ellipsis;max-width:400px;"]);