import { genTestIds } from '@yieldstreet/tool-kit';
export const items = [{
  title: 'Tailored strategies',
  content: 'Your portfolio is designed by a leading global investment firm to align with your goals and risk tolerance.'
}, {
  title: 'Extensive diversification',
  content: 'Your portfolio is expected to include 1000s of investments across private credit, real estate, private equity, and more.'
}, {
  title: 'Professional management',
  content: 'Your investment is actively managed by a team of seasoned professionals who have a fiduciary commitment to you.'
}];
const {
  getTestIds
} = genTestIds(['heroBanner', 'itemsWrapper', 'itemParagraph']);
export const testIds = getTestIds('ys360-glance');