import _Box from "@mui/material/Box";
import _Typography from "@mui/material/Typography";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { useLocation } from 'react-router-dom';
import { NavigationTabList } from '@yieldstreet/ui-kit';
import { renderLink } from 'sharedComponents/Links/Links';
import { useUserState } from 'utils/hooks';
import { HeaderWrapper } from './AccountNavigation.style';
import { accountSettingsNavLinks } from './AccountNavigation.util';
import { testIds } from './AccountNavigation.model';
export const AccountNavigation = _ref => {
  let {
    children
  } = _ref;
  const userState = useUserState();
  const location = useLocation();
  const sectionPadding = {
    paddingLeft: {
      xs: 5,
      lg: 0
    },
    paddingRight: {
      xs: 5,
      lg: 0
    }
  };
  return __jsx(React.Fragment, null, __jsx(HeaderWrapper, null, __jsx(_Box, {
    sx: sectionPadding
  }, __jsx(_Typography, _extends({
    variant: "h2"
  }, testIds.pageTitle.attr), "Accounts")), __jsx(NavigationTabList, {
    userState: userState,
    currentPath: location === null || location === void 0 ? void 0 : location.pathname,
    navigationConfig: accountSettingsNavLinks,
    renderLink: renderLink,
    testId: testIds.navigationTabs.id
  })), __jsx(_Box, {
    sx: sectionPadding
  }, children));
};