import _objectSpread from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
var __jsx = React.createElement;
import React, { useMemo } from 'react';
import { useTheme, ThemeProvider } from 'styled-components';
export const GridProvider = _ref => {
  let {
    children,
    config
  } = _ref;
  const globalTheme = useTheme();
  const theme = useMemo(() => {
    if (config) {
      return _objectSpread(_objectSpread({}, globalTheme), {}, {
        grid: _objectSpread(_objectSpread({}, globalTheme.grid), config)
      });
    }
    return globalTheme;
  }, [config, globalTheme]);
  return __jsx(ThemeProvider, {
    theme: theme
  }, children);
};