import styled from 'styled-components';
import { Paragraph, media } from '@yieldstreet/ui-kit';
export const AccreditationWrapper = styled.div.withConfig({
  displayName: "Accreditationstyle__AccreditationWrapper",
  componentId: "sc-27botj-0"
})(["width:100%;", ""], media.desktop`
    width: fit-content;
  `);
export const AccreditationBlock = styled.div.withConfig({
  displayName: "Accreditationstyle__AccreditationBlock",
  componentId: "sc-27botj-1"
})(["display:flex;flex-direction:row;align-items:center;justify-content:space-between;column-gap:", ";", ";", ";"], _ref => {
  let {
    theme
  } = _ref;
  return theme.spacing.sm;
}, media.desktop`
    column-gap: ${_ref2 => {
  let {
    theme
  } = _ref2;
  return theme.spacing['2xl'];
}};
  `, media.large`
    column-gap: ${_ref3 => {
  let {
    theme
  } = _ref3;
  return theme.spacing['5xl'];
}};
  `);
export const TextWrapper = styled.div.withConfig({
  displayName: "Accreditationstyle__TextWrapper",
  componentId: "sc-27botj-2"
})(["text-align:start;"]);
export const ExpirationDate = styled(Paragraph).withConfig({
  displayName: "Accreditationstyle__ExpirationDate",
  componentId: "sc-27botj-3"
})(["display:none;", ""], media.desktop`
    display: initial;
  `);