import PropTypes from 'prop-types';
import styled from 'styled-components';
import adviceLogo from '@yieldstreet/ui-kit/assets/logos/advice.svg';
const AdviceLogo = styled.img.attrs({
  src: adviceLogo,
  alt: 'Yieldstreet Advice'
}).withConfig({
  displayName: "AdviceLogo",
  componentId: "sc-1pn4nrt-0"
})(["", " ", ""], props => props.margin ? `margin: ${props.margin};` : '', props => props.maxWidth ? `max-width: ${props.maxWidth};` : '');
AdviceLogo.propTypes = {
  margin: PropTypes.string,
  maxWidth: PropTypes.string
};
export { AdviceLogo };