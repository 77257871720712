var __jsx = React.createElement;
import React from 'react';
import styled from 'styled-components';
import { Label, Paragraph } from '../..';
import QuoteImg from '../../assets/graphics/icons/quotes-testimonial.svg';
export const TestimonialCard = _ref => {
  let {
    testimonial,
    className
  } = _ref;
  return __jsx(Card, {
    className: className
  }, __jsx(IconWrapper, null, __jsx(Circle, {
    className: "quote-icon"
  })), __jsx(Content, null, __jsx(Testimonial, null, testimonial.content), __jsx(InvestorName, null, testimonial.investorName), __jsx(MemberSince, {
    small: true
  }, "Investor since ", testimonial.memmberSince)));
};
const Card = styled.div.withConfig({
  displayName: "TestimonialCard__Card",
  componentId: "sc-v68fqv-0"
})(["background-color:", ";border:1px solid ", ";border-radius:8px;box-shadow:", ";padding:40px;display:flex;flex-direction:column;max-width:70vw;min-height:418px;width:326px;"], props => props.theme.colors.background_card, props => props.theme.colors.border_default, props => props.theme.shadows.shadow_default);
const IconWrapper = styled.div.withConfig({
  displayName: "TestimonialCard__IconWrapper",
  componentId: "sc-v68fqv-1"
})(["margin-right:15px;position:relative;"]);
const Circle = styled.div.withConfig({
  displayName: "TestimonialCard__Circle",
  componentId: "sc-v68fqv-2"
})(["background-image:url(", ");background-size:cover;content:'';display:block;margin-bottom:15px;left:-15px;height:22px;width:28px;"], QuoteImg);
const Content = styled.div.withConfig({
  displayName: "TestimonialCard__Content",
  componentId: "sc-v68fqv-3"
})(["display:flex;flex:1;flex-direction:column;text-align:left;"]);
const Testimonial = styled(Paragraph).withConfig({
  displayName: "TestimonialCard__Testimonial",
  componentId: "sc-v68fqv-4"
})(["margin:8px 0 15px;margin-bottom:40px;"]);
const InvestorName = styled.div.withConfig({
  displayName: "TestimonialCard__InvestorName",
  componentId: "sc-v68fqv-5"
})(["font-family:", ";font-size:16px;margin-bottom:3px;margin-top:auto;"], props => props.theme.typography.primary);
const MemberSince = styled(Label).withConfig({
  displayName: "TestimonialCard__MemberSince",
  componentId: "sc-v68fqv-6"
})([""]);