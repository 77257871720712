import { PortfolioStrategyValues, investorEntityApi, investmentApi } from '@yieldstreet/platform-kit';
import find from "lodash/find";
import map from "lodash/map";
import flattenDeep from "lodash/flattenDeep";
import filter from "lodash/filter";
import includes from "lodash/includes";
import { useMemo } from 'react';
import { ALL_ACCOUNTS } from './InvestorAccountContainer.model';
export const useInvestorAccounts = _ref => {
  let {
    investorId,
    managementStrategy = PortfolioStrategyValues.Self
  } = _ref;
  const {
    data,
    isFetching
  } = investorEntityApi.useGetInvestorEntityQuery();
  const {
    data: managedPortfolioEligibility,
    isFetching: managedPortfolioEligibilityIsFetching
  } = investmentApi.useManagedPortfolioEligibilityQuery({}, {
    skip: managementStrategy === PortfolioStrategyValues.Self
  });
  const managedPortfoliosInvestorEntities = useMemo(() => {
    var _managedPortfolioElig;
    return managedPortfolioEligibility === null || managedPortfolioEligibility === void 0 ? void 0 : (_managedPortfolioElig = managedPortfolioEligibility.eligibility) === null || _managedPortfolioElig === void 0 ? void 0 : _managedPortfolioElig.investorEntities.reduce((acc, investorEntity) => {
      if (investorEntity.gateData === null || !investorEntity.gateData.blocking && !investorEntity.gateData.actionable) {
        acc.push(investorEntity.investorEntityId);
      }
      return acc;
    }, []);
  }, [managedPortfolioEligibility]);
  const investorEntities = useMemo(() => {
    if (managementStrategy === PortfolioStrategyValues.Discretionary) {
      return filter(data === null || data === void 0 ? void 0 : data.entities, entity => {
        return includes(managedPortfoliosInvestorEntities, entity.id);
      });
    }
    return data === null || data === void 0 ? void 0 : data.entities;
  }, [data, managedPortfoliosInvestorEntities, managementStrategy]);
  const filterInvestorAccounts = (investorAccounts, managementStrategy) => filter(investorAccounts, {
    managementStrategy
  });
  const investorAccounts = useMemo(() => {
    return flattenDeep(map(investorEntities, investorEntity => map(filterInvestorAccounts(investorEntity.investorAccounts, managementStrategy), account => ({
      id: `${account.id}`,
      name: investorEntity.name,
      investorEntityId: investorEntity.id
    }))));
  }, [investorEntities, managementStrategy]);
  const {
    hasMultipleInvestorAccounts,
    hasInvestorAccounts
  } = useMemo(() => ({
    hasInvestorAccounts: investorAccounts.length > 0,
    hasMultipleInvestorAccounts: investorAccounts.length > 1
  }), [investorAccounts]);
  const investorAccountList = useMemo(() => [...(hasMultipleInvestorAccounts && managementStrategy === PortfolioStrategyValues.Self ? [ALL_ACCOUNTS] : []), ...investorAccounts], [hasMultipleInvestorAccounts, managementStrategy, investorAccounts]);
  const selectedInvestorAccount = useMemo(() => find(investorAccountList, {
    id: investorId
  }) || investorAccountList[0], [investorId, investorAccountList]);
  return {
    isLoading: isFetching || managedPortfolioEligibilityIsFetching,
    hasInvestorAccounts,
    hasMultipleInvestorAccounts,
    investorAccountList,
    selectedInvestorAccount
  };
};