import styled from 'styled-components';
export const TagContainer = styled.div.withConfig({
  displayName: "Tagstyle__TagContainer",
  componentId: "sc-x6jfl9-0"
})(["align-items:center;border:1px solid ", ";border-radius:20px;background-color:", ";display:inline-flex;font-size:12px;flex-direction:row;gap:", ";padding:", " ", ";"], props => props.borderColor, props => props.backgroundColor, props => props.theme.spacing.xs, props => props.theme.spacing.xs, props => props.theme.spacing.s);
export const TagTooltip = styled.div.withConfig({
  displayName: "Tagstyle__TagTooltip",
  componentId: "sc-x6jfl9-1"
})(["align-self:center;display:flex;"]);
export const TagContent = styled.p.withConfig({
  displayName: "Tagstyle__TagContent",
  componentId: "sc-x6jfl9-2"
})(["color:", ";font-size:12px;font-weight:500;line-height:14px;"], props => props.textColor);