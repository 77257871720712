import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React, { useMemo } from 'react';
import { Label, Paragraph } from '../../content';
import { OfferingCardBase } from './base-layout/OfferingCardBase';
import { useTestIds } from './OfferingCardV2.model';
import { OfferingThumb, TitleContainer, AssetClass, OfferingTitle, BadgesContainer, ContentContainer, MetricWrapper, OfferingTitleWrapper, MainContainer } from './OfferingCardV2.style';
export const OfferingCardV2 = props => {
  const {
    thumbSrc,
    assetClass,
    offeringTitle,
    metrics,
    thumbAlt,
    badges
  } = props;
  const testIds = useTestIds(props);
  const ThumbComponent = useMemo(() => {
    return __jsx(OfferingThumb, _extends({
      alt: thumbAlt,
      src: thumbSrc
    }, testIds.thumb.attr));
  }, [thumbAlt, thumbSrc, testIds.thumb.attr]);
  const MainComponent = useMemo(() => __jsx(MainContainer, null, __jsx(TitleContainer, null, __jsx(AssetClass, testIds.assetClass.attr, assetClass), __jsx(OfferingTitleWrapper, null, __jsx(OfferingTitle, _extends({}, testIds.title.attr, {
    type: 4
  }), offeringTitle))), __jsx(BadgesContainer, testIds.badge.attr, badges ? badges : null)), [assetClass, offeringTitle, badges, testIds]);
  const ContentComponent = useMemo(() => {
    if (!!metrics && (metrics === null || metrics === void 0 ? void 0 : metrics.length) > 0) {
      return __jsx(ContentContainer, testIds.content.attr, metrics === null || metrics === void 0 ? void 0 : metrics.map(metric => {
        return __jsx(MetricWrapper, null, __jsx(Label, null, metric.label), __jsx(Paragraph, {
          semiBold: true,
          size: "small"
        }, metric.value));
      }));
    }
    return null;
  }, [metrics, testIds.content.attr]);
  return __jsx(OfferingCardBase, _extends({
    topComponent: ThumbComponent,
    mainComponent: MainComponent,
    contentComponent: ContentComponent
  }, props));
};