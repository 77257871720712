import _Tooltip from "@mui/material/Tooltip";
import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
import _objectWithoutProperties from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/objectWithoutProperties.js";
const _excluded = ["children", "title", "arrow", "placement", "inverse"];
var __jsx = React.createElement;
import React, { useMemo } from 'react';
import { tooltipStyles } from './TooltipV2.style';
import { testIds } from './TooltipV2.model';
export const TooltipV2 = props => {
  const {
      children,
      title,
      arrow,
      placement = 'bottom',
      inverse
    } = props,
    extraProps = _objectWithoutProperties(props, _excluded);
  const tooltipStyle = useMemo(() => tooltipStyles(inverse), [inverse]);
  return __jsx(_Tooltip, _extends({}, testIds.base.attr, {
    title: title,
    classes: {
      tooltip: tooltipStyle().tooltip,
      arrow: tooltipStyle().arrow
    },
    arrow: arrow,
    placement: placement
  }, extraProps), __jsx("span", null, children));
};