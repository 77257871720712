import _extends from "/code/node_modules/.pnpm/@babel+runtime@7.24.4/node_modules/@babel/runtime/helpers/esm/extends.js";
var __jsx = React.createElement;
import React from 'react';
import { useModal } from '@yieldstreet/ui-kit';
import { Modals } from 'constants/modal.model';
import { testIds as setupTestId } from './Filters.model';
import { ModalTriggerButtonInner, ModalTriggerButtonOuter, ModalTriggerButton } from './Filters.style';
import filterIcon from './assets/filter.svg';
export const FiltersMobile = _ref => {
  let {
    filterOptions,
    queryFilterParams,
    testId
  } = _ref;
  const {
    open,
    isOpen
  } = useModal(Modals.FiltersMobile, {
    props: {
      filterOptions,
      queryFilterParams,
      testId
    }
  });
  const testIds = setupTestId(testId);
  return __jsx(ModalTriggerButtonOuter, {
    modalIsActive: isOpen
  }, __jsx(ModalTriggerButton, _extends({}, testIds.filterTrigger.attr, {
    onClick: () => {
      open();
    }
  }), __jsx(ModalTriggerButtonInner, null, __jsx("span", null, "Filter by"), __jsx("img", {
    width: "20px",
    src: filterIcon,
    alt: "Filter by"
  }))));
};