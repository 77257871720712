import styled, { css } from 'styled-components';
import { media } from '../../themes';
const photoCardSmall = css(["flex-direction:row;height:100px;width:100%;", ";"], media.desktop`
    width: 368px;
  `);
const photoCardRegular = css(["flex-direction:column;height:330px;width:270px;", ";"], media.desktop`
    width: 270px;
  `);
const plusButtonSmall = css(["border-radius:0 4px;width:32px;min-height:32px;max-height:32px;"]);
const plusButtonRegular = css(["border-radius:4px 0;width:40px;min-height:40px;max-height:40px;right:0;"]);
export const PhotoCard = styled.div.withConfig({
  displayName: "MarketingPhotoCardstyles__PhotoCard",
  componentId: "sc-bzz2ar-0"
})(["position:relative;display:flex;background-color:", ";max-width:368px;border-radius:4px;box-shadow:", ";overflow:hidden;cursor:", ";", ";&:hover{cursor:pointer;> button{background-color:", ";}}"], props => props.theme.colors.background_light, props => props.theme.shadows.elevation_1, props => props.onClick && 'pointer', _ref => {
  let {
    small
  } = _ref;
  return small ? photoCardSmall : photoCardRegular;
}, props => props.theme.colors.input_button_background_hover);
export const Image = styled.img.withConfig({
  displayName: "MarketingPhotoCardstyles__Image",
  componentId: "sc-bzz2ar-1"
})(["width:", ";min-height:", ";"], props => props.small ? '108px' : '100%', props => props.small ? '100%' : '250px');
export const TextContainer = styled.div.withConfig({
  displayName: "MarketingPhotoCardstyles__TextContainer",
  componentId: "sc-bzz2ar-2"
})(["padding:0 0 0 16px;margin:auto 0;"]);
export const PlusButton = styled.button.withConfig({
  displayName: "MarketingPhotoCardstyles__PlusButton",
  componentId: "sc-bzz2ar-3"
})(["position:absolute;padding:0;display:flex;justify-content:center;align-items:center;border:0;font-size:22px;bottom:0;cursor:pointer;background-color:", ";color:", ";", " &:hover{background-color:", ";}&:active{background-color:", ";}"], props => props.theme.colors.input_button_background, props => props.theme.colors.input_button_text, _ref2 => {
  let {
    small
  } = _ref2;
  return small ? plusButtonSmall : plusButtonRegular;
}, props => props.theme.colors.input_button_background_hover, props => props.theme.colors.input_button_background_active);