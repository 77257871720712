import { genTestIds } from '@yieldstreet/tool-kit';
import errorIcon from './assets/errorIcon.svg';
const {
  getTestIds
} = genTestIds(['button']);
export const testIds = getTestIds('error-view');
export const ErrorViewDefaults = {
  icon: errorIcon,
  heading: 'Something went wrong',
  text: 'We are having trouble loading this data.',
  ctaText: 'Refresh',
  ctaAction: () => {
    if (true) {
      window.location.reload();
    }
  }
};